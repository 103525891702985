import React from 'react';
import PropTypes from 'prop-types';

import Label from 'infrastructure/js/components/Label/label.js';
import MaterialHelper from '../../../../../../utils/materialHelper'
//Style
require('./materialCell.scss');

export default class MaterialCell extends React.PureComponent {

  renderSubstituteMaterialMark = (material) => {
    if (material && material.isSubstituteMaterial) {
      return <div className="pl pl-substitute-material-mark"></div>
    }
    return null;
  };

  render() {
    let materialsList = this.props.value;
    if (!materialsList) {
      return '';
    }

    let materialRows = materialsList.map((material, index)=> {

      let isGray = material.isUncut && !material.isAllMaterialKitted;
      return (
      <div key={material.materialId + '-' + index} className={'kitting-materials-custom-cell'}>
        <span className='pl pl-check-circle-icon-blue blue-color' style={{opacity: (material.isAllMaterialKitted ? 1 : 0)}}>

        </span>
        <span className={'truncate-to-fit ' + (material.isCurrentCut ? 'purple-color ' : '') + (isGray ? 'gray-color' : '')}>
          <Label className="material-name"
                             text={`${material.expectedQuantity} x ${MaterialHelper.getMaterialFullLabel(material.materialName,material.materialId)}`}
                             tooltip={MaterialHelper.getMaterialFullLabel(material.materialName, material.materialId)} />
          {this.renderSubstituteMaterialMark(material)}
        </span>

      </div>)
    });

    return (<div>
      {materialRows}
    </div>);
  }
}

MaterialCell.propTypes = {
  value : PropTypes.array
};





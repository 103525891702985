import React from 'react';
import PropTypes from 'prop-types';

import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";

require('./weightCell.scss');

export default class WeightCell extends React.PureComponent {

  getWeight = (value) => {
    let weight = value.weight ? value.weight : 0;
    return UnitHelper.serverValueToUserValueWithLabel(unitTypes.WEIGHT, weight, 2);
  };

  render() {
    let {value} = this.props;

    return (<div className="weight-cell">
      <span>{this.getWeight(value)}</span>
    </div>);
  }
}

WeightCell.propTypes = {
  value: PropTypes.object,
};

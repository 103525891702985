import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
require('../weightWizard.scss');

class WeightValidationStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.assetpage.weightWizard.');
  }

  getDialogButtons() {

    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.onHide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.props.isFetchWeightLoading ? this.labels.get('footer.inProgress') : this.labels.get('footer.tryAgain'),
          bsStyle: 'primary',
          loading: this.props.isFetchWeightLoading,
          disabled: this.props.isFetchWeightLoading,
          action: this.props.handleSubmit(this.goOn),
        }
      ]
    };
  }

  goOn = () => {
    this.props.actions.goOn();
  };

  onHide = () => {
    this.props.actions.onWizardClose();
    let wizardActions = this.props.wizardActions;
    wizardActions.close();
  };

  render() {
    let titleText = this.labels.get('header.title');
    let animation = this.props.isFirstLaunch;
    return (
      <Dialog
        animation={animation}
        id="'weight-validation-step"
        className="weight-wizard"
        titleText={titleText}
        show={true}
        onHide={this.onHide}
        handleHashChange={false}
        footerButtons={this.getDialogButtons()}>

        <label className="body-title">{this.props.errorMessage}</label>
      </Dialog>
    );
  }
}
export default reduxForm({
    form: 'weightValidationStep',
  }
)(WeightValidationStep);



import React from 'react';
import PropTypes from 'prop-types';

import {Field} from 'redux-form';
//Style
require('./checkbox.scss');

export default class PL_CheckboxForm extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_Checkbox}></Field>
    );
  }
};

export class PL_Checkbox extends React.PureComponent{
  //Decorator for form-redux input onchange,
  //Now we have the option to set onchange callback, the callback function will get new value data.
  onChange = (data) =>{
    this.props.input.onChange(data);
    if(this.props.onChangeCallback){
      this.props.onChangeCallback(data.target.checked);
    }
  }
  render(){
    let {input = {}, meta, onChangeCallback, ...otherProps} = this.props;
    let onChange, value;

    //Handle scenario when redux-form not wrapped component.
    if(!this.props.input){
      onChange = this.props.onChange;
      value = this.props.value;
    }
    else{
      onChange = this.onChange;
      value = input.value;
    }
    return (
      <div className="pl-checkbox">
        <div className="checkbox-input">
          <input type="checkbox" {...input} onChange={onChange} checked={value} {...otherProps}/>
          <label htmlFor={otherProps.id}><i className="fa fa-check"></i></label>
          <label htmlFor={otherProps.id} className="checkbox-label" style={{display: this.props.label ? "inline" : "none"}}>{this.props.label}</label>
        </div>

      </div>
    );
  }
}

PL_Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

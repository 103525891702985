import Network from 'infrastructure/js/modules/network';
import { fetchAllResources, fetchOperations } from '../../../services/Scheduler/schedulerService.js';
import { fetchWorkOrders } from '../../../services/WorkOrders/workOrdersService.js';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SCAN_WORKORDER_PAGE_SUBMIT_IN_PROGRESS: 'SCAN_WORKORDER_PAGE_SUBMIT_IN_PROGRESS',
  SCAN_WORKORDER_PAGE_SUBMIT_FINISHED: 'SCAN_WORKORDER_PAGE_SUBMIT_FINISHED',
  SCAN_WORKORDER_PAGE_SUBMIT_FAILED: 'SCAN_WORKORDER_PAGE_SUBMIT_FAILED',
  SCAN_WORKORDER_PAGE_CLEAR: 'SCAN_WORKORDER_PAGE_CLEAR',
  SCAN_WORKORDER_PAGE_FETCH_RESOURCE_STATIONS_READY: 'SCAN_WORKORDER_PAGE_FETCH_RESOURCE_STATIONS_READY',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  submitInProgress: function () {
    return { type: actionTypes.SCAN_WORKORDER_PAGE_SUBMIT_IN_PROGRESS };
  },
  submitFinished: function (payload = {}) {
    return { type: actionTypes.SCAN_WORKORDER_PAGE_SUBMIT_FINISHED, payload };
  },
  submitFailed: function (payload) {
    return { type: actionTypes.SCAN_WORKORDER_PAGE_SUBMIT_FAILED, payload };
  },
  clear: function (payload) {
    return { type: actionTypes.SCAN_WORKORDER_PAGE_CLEAR, payload };
  },
  fetchResourceStationsReady: function (payload) {
    return { type: actionTypes.SCAN_WORKORDER_PAGE_FETCH_RESOURCE_STATIONS_READY, payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.submit = function (woId, stationId, callback) {
  return function (dispatch, getState) {
    api.submit(dispatch, getState)(woId, stationId, callback);
  };
};

jsxActions.clear = function () {
  return function (dispatch) {
    dispatch(actions.clear());
  };
};

jsxActions.init = function () {
  return function (dispatch, getState) {
    api.fetchResourceStations(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchResourceStations = function (dispatch) {
  return function () {
    dispatch(actions.submitInProgress());

    fetchAllResources()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Resource Stations Failed', response);
          return { success: false };
        }

        dispatch(actions.submitFinished(response.data));
        dispatch(actions.fetchResourceStationsReady(response));
      })
      .catch((error) => {
        console.error('Fetch Resource Stations Failed', error);
        return { success: false };
      });
  };
};

api.submit = function (dispatch) {
  return async function (woId, stationId, callback) {
    //initialize the error messages helper.
    const errorMessages = createLabelHelper('mat.mobile.scanWorkOrderPage.barcode.errors.');

    //Show loader spin animation.
    dispatch(actions.submitInProgress());

    try {
      // Fetch all the work orders from the server.
      const workOrdersResponse = await fetchWorkOrders();

      //Check if the response is valid and show error if not.
      if (!Network.isResponseValid(workOrdersResponse)) {
        console.error('Submit Failed', workOrdersResponse);
        dispatch(actions.submitFailed('System error'));
        return { success: false };
      }

      //Find the work order in the list
      const workOrder = workOrdersResponse?.dataList?.find(({ businessId }) => businessId === woId.toUpperCase());

      //If the work order was not found - stop loader and show "work order does not exist" message.
      if (!workOrder) {
        return dispatch(actions.submitFailed(errorMessages.get('workOrderNotFound', undefined, { workOrder: woId.toUpperCase() })));
      }

      // Create a query object to filter the operations by work order ID and status.
      const query = {
        filters: [
          { filterName: 'operationWorkOrder', shouldCreateFilterOptions: false, values: [woId.toUpperCase()] },
          { filterName: 'operationStatus', shouldCreateFilterOptions: true, values: ['COMPLETED', 'SCHEDULED', 'STARTED'] },
        ],
      };

      //Get all work order operations.
      const operationsResponse = await fetchOperations(query);

      //Check if the response is valid and show error if not.
      if (!Network.isResponseValid(operationsResponse)) {
        console.error('Submit Failed', operationsResponse);
        dispatch(actions.submitFailed('System error'));
        return { success: false };
      }

      //get the list of operations from the response.
      const { dataList } = operationsResponse;

      //Check if the the list is empty.
      //if it is - show "no scheduled tasks for this work order" error message and stop loader.
      if (!dataList.length) {
        return dispatch(actions.submitFailed(errorMessages.get('noUnscheduledTasks', undefined, { workOrder: woId.toUpperCase() })));
      }

      //Dispatch the submitFinished action with all the data.
      dispatch(actions.submitFinished({ tasks: dataList, selectedStation: stationId, selectedWorkOrder: woId }));

      //If a callback function was provided - invoke it.
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error('Submit Failed', error);
      dispatch(actions.submitFailed(error));
      return { success: false };
    }
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

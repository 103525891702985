import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from 'infrastructure/js/components/controls/Button/button';
import CircleSuccessIcon from 'infrastructure/js/components/CircleSuccessIcon/circleSuccessIcon';
import classNames from 'classnames'
require('./overlay.scss');


class Loading extends React.PureComponent {
  render() {
    return (
      <div className={classNames('overlay loading-overlay', this.props.className)}>
        <i className='fa fa-spinner fa-spin fa-fw' aria-hidden="true"/>
      </div>);
  }
}

class Label extends React.PureComponent {

  render() {
    return (
      <div className="overlay text-overlay">
        <span>{this.props.text}</span>
      </div>);
  }
}
Label.propTypes = {
  text: PropTypes.string,
};
Label.defaultProps = {
  text: ''
};


class Error extends React.PureComponent {

  onClickHandler = (e) => {
    if (this.props.onClickCallback) {
      this.props.onClickCallback();
    }
  };

  render() {
    return (
      <div className="overlay error-overlay">
        <div className="error-body">
          <i className='pl pl-error-icon' aria-hidden="true"/>
          <div className="error-right">
            <span className="error-message">{this.props.text}</span>
            <Button id='gridRefreshBtn' onClick={this.onClickHandler}>{this.props.buttonLabel}</Button>
          </div>
        </div>
      </div>);
  }
}
Error.propTypes = {
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
};

Error.defaultProps = {
  text: '',
  buttonLabel: '',
};

class Success extends React.PureComponent {

  render() {
    return (
      <div className="overlay success-overlay">
        <CircleSuccessIcon />
      </div>);
  }
}


export default {Loading, Label, Error, Success};


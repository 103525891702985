import { Map } from 'immutable';

import { actionTypes as CancelCutReportDialogActionTypes }  from '../../actions/LocationPage/CuttingView/cancelCutReportDialogActions';


let defaultState = {
  show: false,
  loading: false,
  currentCut: null
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    // Show dialog.
    case CancelCutReportDialogActionTypes.CUTKITSTATION_CANCELCUTREPORTDIALOG_SHOW:
      state = state.set('currentCut', action.payload);
      return state.set('show', true);

    // Hide dialog.
    case CancelCutReportDialogActionTypes.CUTKITSTATION_CANCELCUTREPORTDIALOG_HIDE:
      return state.set('show', false);

    // Submit to server started.
    case CancelCutReportDialogActionTypes.CUTKITSTATION_CANCELCUTREPORTDIALOG_REPORT_CUTS_IN_PROGRESS:
      state = state.set('loading', true);
      return state;


    // Submit to server finished.
    case CancelCutReportDialogActionTypes.CUTKITSTATION_CANCELCUTREPORTDIALOG_REPORT_CUTS_FINISHED:
      state = state.set('loading', false);
      state = state.set('show', false);
      return state;


    default:
      return state;
  }
}




import {gridsNames} from '../../enums/gridsNames';
import * as gridActionsHelper from '../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as pickListsService from '../../services/PickLists/pickListsService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.PICK_LIST_PAGE, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {

    let pickListId = getState().pickListPage.getIn(['data', 'pickList'])?.id;

    return pickListsService.getPickListAssets(pickListId, query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let rolls = rows.map((obj, index) => {
    if(obj.locationId){  //workaround for MoveAssetsDialog
      obj.location = {
        id: obj.locationId,
        name: obj.name,
      };
    }
    return obj;
  });

  return rolls;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

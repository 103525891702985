import { useState } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import classNames from 'classnames';

import './multiValueWithTextInput.scss';

//Get the MultiValue component from react-select components.
//This component is the actual "chip" that react select natively renders when it's on "Multi" mode.
const { MultiValue } = components;

/**
 * Calculates the width of an input based on the value of the input and the minimum and maximum widths.
 *
 * @param {string} value The value of the input.
 * @param {number} minWidth The minimum width of the input.
 * @param {number} maxWidth The maximum width of the input.
 * @returns {number} The calculated width of the input.
 */
export const getCalculatedInputWidth = (value, minWidth, maxWidth) => {
  // Calculate the new width of the input.
  const newWidth = value.length * 10;

  // If the new width is less than the minimum width, return the minimum width.
  if (minWidth && newWidth < minWidth) {
    return minWidth;
  }

  // If the new width is greater than the maximum width, return the maximum width.
  if (maxWidth && newWidth > maxWidth) {
    return maxWidth;
  }

  // Otherwise, return the new width.
  return newWidth;
};

/**
 * MultiValueWithTextInput component. A component that renders a MultiValue component and a DOM input field.
 *
 * @param {string} inputValue The initial value of the input element.
 * @param {string} inputPlaceholder The placeholder text for the input element.
 * @param {string} inputType The type of the input element.
 * @param {function} onInputChange A callback function that is called when the input value changes.
 * @param {object} otherInputProps Any additional props to pass into the Input element. for additional configuration. *This will OVERRIDE all predefined props (value,placeholder,type etc...)*
 * @param {object} props Any additional props to pass to the MultiValue component. (should contain react-select option props)
 *
 * @returns {ReactElement} A React element that represents the MultiValueWithTextInput component.
 */
const MultiValueWithTextInput = ({
  inputValue,
  inputPlaceholder,
  inputOnChangeModifier,
  inputType,
  onInputChange,
  otherInputProps,
  inputWidth,
  allowAutoResize,
  autoResizeMin,
  autoResizeMax,
  ...props
}) => {
  //Initialize the state for the input value.
  const [inputElementValue, setInputElementValue] = useState(inputValue);
  //Initialize the state for the input width.
  const [width, setWidth] = useState(inputWidth + 20);

  /**
   * Handles the change event of the input.
   *
   * @param {Event} e The event object.
   */
  const handleInputChange = (e) => {
    // Get the value of the input.
    const { value } = e.target;

    //If allowAutoResize is true recalculate and set the width of the input.
    if (allowAutoResize) {
      if (autoResizeMin <= autoResizeMax) {
        const newWidth = getCalculatedInputWidth(value, autoResizeMin, autoResizeMax);
        setWidth(newWidth);
      } else {
        console.error(`MultiValueWithTextInput: autoResizeMin (${autoResizeMin}) is bigger then autoResizeMax (${autoResizeMax})!`);
      }
    }

    // Call the inputOnChangeModifier function to modify the value of the input.
    const { modifiedValue, error } = inputOnChangeModifier(value);

    // If there is an error, do not continue.
    if (error) {
      return;
    }

    // Set the value of the input element.
    setInputElementValue(modifiedValue);

    // Call the onInputChange function to notify the parent component of the change.
    onInputChange(modifiedValue, props);
  };

  // Prevents the keypress,mouseClick,mouseDown events from bubbling up to the parent component.
  // This is to prevent backspace key from removing the entire component from the parent react-select.
  // This is also fixes the unwanted scroll bug.
  const eventPropagationStopper = (event) => {
    event.stopPropagation();
  };

  // Returns a boolean indicating whether the input value was changed.
  const isDirty = inputValue != inputElementValue;

  return (
    <div className="multi-value-with-text-input">
      <MultiValue {...props} />
      <input
        value={inputElementValue}
        onChange={handleInputChange}
        onKeyDown={eventPropagationStopper}
        onClick={eventPropagationStopper}
        onMouseDown={eventPropagationStopper}
        className={classNames('attached-input', { empty: !inputElementValue, dirty: isDirty })}
        placeholder={inputPlaceholder}
        type={inputType}
        style={{ width }}
        {...otherInputProps}
      />
    </div>
  );
};

MultiValueWithTextInput.propTypes = {
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputPlaceholder: PropTypes.string,
  inputType: PropTypes.string,
  onInputChange: PropTypes.func,
  inputOnChangeModifier: PropTypes.func,
  otherInputProps: PropTypes.object,
  inputWidth: PropTypes.number,
  allowAutoResize: PropTypes.bool,
  autoResizeMin: PropTypes.number,
  autoResizeMax: PropTypes.number,
};

MultiValueWithTextInput.defaultProps = {
  inputValue: '',
  inputPlaceholder: 'N/A',
  inputType: 'text',
  onInputChange: () => {},
  inputOnChangeModifier: (value) => ({
    modifiedValue: value,
    error: false,
  }),
  otherInputProps: {},
  inputWidth: 40,
  allowAutoResize: false,
  autoResizeMin: 40,
  autoResizeMax: 200,
};

export default MultiValueWithTextInput;

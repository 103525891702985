import Network from 'infrastructure/js/modules/network';
import {api as loginApi} from '../../actions/Login/loginActions';
import LoginService from 'infrastructure/js/services/LoginService.js';
import userService from "../../services/UserManagement/UserService";

export const actionTypes = {
  SECURITY_MESSAGE_DIALOG_SHOW: 'SECURITY_MESSAGE_DIALOG_SHOW',
  SECURITY_MESSAGE_DIALOG_HIDE: 'SECURITY_MESSAGE_DIALOG_HIDE',
};

const actions = {
  show: function (payload) {
    return {type: actionTypes.SECURITY_MESSAGE_DIALOG_SHOW, payload};
  },
  hide: function () {
    return {type: actionTypes.SECURITY_MESSAGE_DIALOG_HIDE};
  },
};

export let jsxActions = {};

jsxActions.submit = function (userData, messageDialogBuilder, isLoggedInUser) {
  return function (dispatch, getState) {
    api.submit(dispatch, getState)(userData, messageDialogBuilder, isLoggedInUser);
  }
};

jsxActions.show = function (userData) {
  return function (dispatch, getState) {
    dispatch(actions.show(userData));
  };
};
jsxActions.hide = function () {
  return function (dispatch, getState) {
    dispatch(actions.hide());
    //the refresh is needed because we already passed a half login process
    window.location.hash = '/Login';
    window.location.reload();
  }
};


export let api = {};

api.submit = function (dispatch, getState) {
  return function () {
    LoginService.acceptUserAgreement().then((acceptUserAgreementResponse) => {
      if (!Network.isResponseValid(acceptUserAgreementResponse) || !acceptUserAgreementResponse.data.data) {
        console.error('getting accept User Agreement Response failed');
        return;
      }

      userService.getCurrentUserProfile().then((response) => {

        if (!Network.isResponseValid(response) || !response.data) {
          console.error('getting current user profile failed');
          return;
        }

        dispatch(actions.hide());
        loginApi.executePostLoginActions(response.data)(dispatch, getState);
      });
    })
  };
};


api.show = function (dispatch, getState) {
  return function (userData) {
    dispatch(actions.show(userData));
  };
};

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
export function getValueByType(value, valueType){

  let labels = createLabelHelper('mat.activitydetails.');
  switch (valueType) {
    case "DATE_EPOCH":
      return DateTimeHelper.DateTimeFormat(value * 1000);
    case "ONLY_DATE_EPOCH":
      return DateTimeHelper.DateFormat(value * 1000);
    case "TIME_HOURS":
      return DateTimeHelper.ConvertMinutesToHoursMinutes(value);
    case "TEMPERATURE_CELSIUS":
      return UnitHelper.serverValueToUserValueWithLabel(unitTypes.TEMPERATURE, value, 0);
    case "LENGTH_CM":
      return UnitHelper.serverValueToUserValueWithLabel(unitTypes.LENGTH, value, 2);
    case "WEIGHT_GRAM":
      return  UnitHelper.serverValueToUserValueWithLabel(unitTypes.WEIGHT, value, 2);
    case "WIDTH_CM":
      return  UnitHelper.serverValueToUserValueWithLabel(unitTypes.WIDTH, value, 2);
    case "STRING":
    case "ENTITY_ID":
    case "ENTITY_BUSINESS_ID":
    case "INTEGER":
    case "WEIGHT_KG": //TODO: needs to be checked with new UOM system
      return value;
    case "LOCALIZED_LABEL":
      return labels.get(value);
    default:
      console.error(`value type is not supported: ${valueType}`);
      break;
  }
}

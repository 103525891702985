import { List } from 'immutable';
import Network from 'infrastructure/js/modules/network';
import * as rollsService from '../../../services/Rolls/rollsService.js';
import * as locationsService from '../../../services/Locations/locationsService.js';
import * as batchJobsService from '../../../services/BatchJobs/batchJobsService.js';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import { api as headerActionsApi }  from '../Header/headerActions';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CHANGELOCATION_PAGE_SUBMIT_IN_PROGRESS:     'CHANGELOCATION_PAGE_SUBMIT_IN_PROGRESS',
  CHANGELOCATION_PAGE_SUBMIT_FINISHED:        'CHANGELOCATION_PAGE_SUBMIT_FINISHED',
  CHANGELOCATION_PAGE_SUBMIT_FAILED:          'CHANGELOCATION_PAGE_SUBMIT_FAILED',
  CHANGELOCATION_PAGE_FETCH_LOCATIONS_READY:  'CHANGELOCATION_PAGE_FETCH_LOCATIONS_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {

  subminInProgress: function() {
    return {type: actionTypes.CHANGELOCATION_PAGE_SUBMIT_IN_PROGRESS};
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CHANGELOCATION_PAGE_SUBMIT_FINISHED, payload: payload};
  },
  submitFailed: function(payload) {
    return {type: actionTypes.CHANGELOCATION_PAGE_SUBMIT_FAILED, payload: payload};
  },
  fetchLocationsReady: function(payload) {
    return {type: actionTypes.CHANGELOCATION_PAGE_FETCH_LOCATIONS_READY, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function() {
  return function(dispatch, getState) {
    api.fetchPageData(dispatch, getState)();
  };
};

jsxActions.submit = function(data, locationName, callback) {
  return function(dispatch, getState) {
    api.submit(dispatch, getState)(data, locationName, callback);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};



api.fetchPageData = function(dispatch, getState) {
  return function() {

    locationsService.fetchActiveLocations()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error("Fetch Locations Failed");
          return {success: false};
        }
        dispatch(actions.fetchLocationsReady(response));
      });

  }
};
api.submit = function(dispatch, getState) {
  return function(data, locationName, callback) {

    dispatch(actions.subminInProgress());

    locationsService.moveAssets(data)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Change location failed');
          dispatch(actions.submitFailed());
          _showMessageDialog(dispatch, getState);
          return {success: false};
        }

        let jobExecutionId = response.data.jobExecutionId;

        batchJobsService.getJobAssetsDetailsStatus(jobExecutionId)
          .then((response2) => {
            if (!Network.isResponseValid(response2)) {
              console.error('Change location failed');
              dispatch(actions.submitFailed());

              _showMessageDialog(dispatch, getState);
              return {success: false};
            }

            let payload = {
              assetsDetails: List(response2.data.assetsDetails),
              locationName: locationName,
            };

            dispatch(actions.submitFinished(payload));
            headerActionsApi.clearAllScans(dispatch, getState)();


            if (callback) {
              callback();
            }
          });
      });
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _showMessageDialog(dispatch, getState) {

  let title = 'Operation failed';
  let closeFunc =  messageDialogApi.close(dispatch, getState);

  let desc = {
    title: 'Operation failed',
    className: 'mobile-message-dialog',
    buttons: [{ id:'close',text: 'Close', action: closeFunc, },] ,
    showTitleIcon: false
  };

  messageDialogApi.open(dispatch, getState)(desc);
}

//FOR DEBUG ONLY!!!!!!!
// response = createFailedResponse();
//
// function createFailedResponse() {
//   let res = {};
//
//   res.applicationResponseStatus = {
//     errorCode: "MAT_70001_A",
//     errors: {
//       mainError: {
//         errorKey: "MAT_70001_A",
//         id: "",
//         message: "Failed to create roll - duplicate name: 111.",
//       },
//       params: {businessId: "111"}
//     },
//     subErrors: [],
//     internalErrorMessage: "",
//     message: "Failed to create roll - duplicate name: 111.",
//     mid: "2018-12-20 12:48:48.377",
//     statusType: "ERROR"
//   };
//
//   res.errors = null;
//
//   return res;
// }





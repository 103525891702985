import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as toolsService from '../../../services/Tools/toolsService.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SCANTOOL_PAGE_SUBMIT_IN_PROGRESS: 'SCANTOOL_PAGE_SUBMIT_IN_PROGRESS',
  SCANTOOL_PAGE_SUBMIT_FINISHED: 'SCANTOOL_PAGE_SUBMIT_FINISHED',
  SCANTOOL_PAGE_SUBMIT_FAILED: 'SCANTOOL_PAGE_SUBMIT_FAILED',
  SCANTOOL_PAGE_CLEAR: 'SCANTOOL_PAGE_CLEAR',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  subminInProgress: function () {
    return { type: actionTypes.SCANTOOL_PAGE_SUBMIT_IN_PROGRESS };
  },
  submitFinished: function (payload) {
    return { type: actionTypes.SCANTOOL_PAGE_SUBMIT_FINISHED, payload: payload };
  },
  submitFailed: function (payload) {
    return { type: actionTypes.SCANTOOL_PAGE_SUBMIT_FAILED, payload: payload };
  },
  clear: function (payload) {
    return { type: actionTypes.SCANTOOL_PAGE_CLEAR, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.submit = function (assetType, assetBusinessId, callback) {
  return function (dispatch, getState) {
    api.submit(dispatch, getState)(assetType, assetBusinessId, callback);
  };
};

jsxActions.clear = function () {
  return function (dispatch, getState) {
    dispatch(actions.clear());
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.submit = function (dispatch, getState) {
  return function (assetType, toolBusinessId, callback) {
    dispatch(actions.subminInProgress());

    return toolsService.getToolByBusinessId(toolBusinessId).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch tool by businessId failed');
        let message = createLabelHelper('mat.mobile.scanToolPage.').get('result.barcodeNotFound');
        dispatch(actions.submitFailed(message));

        return { success: false };
      }

      let tool = response.data;

      let payload = {
        id: tool.id,
        businessId: tool.businessId,
        tags: tool.tags ? tool.tags : [],
        type: tool.toolType ? tool.toolType.businessId : '',
        status: tool.toolStatus,
        toolStatusDisplayKey: tool.toolStatusDisplayKey,
        cycles: tool.currentCyclesNumber,
        locationName: tool.locationName,
        alerts: tool.alerts,
      };
      dispatch(actions.submitFinished(payload));

      if (callback) {
        callback(response.data.id);
      }
    });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

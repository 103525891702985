import { Map } from 'immutable';
import defaultDialogReducer from '../defaultDialogReducer';
import {dialogsNames} from '../../enums/dialogsNames';
import { actionTypes as actionTypes }  from '../../actions/LocationPage/KittingView/reportKitDialogActions';


let defaultState = {
  show: false,
  loading: false,
  items: [],
  dialogData: null,
  submitInProgress: false,
  submitAndCreateInProgress: false
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {

    case actionTypes.REPORT_KIT_DIALOG_SUBMIT_KITS_IN_PROGRESS:
      return state.set('submitInProgress', true)
                  .set('loading', true);

    case actionTypes.REPORT_KIT_DIALOG_SUBMIT_KITS_FINISHED:
      return state.set('submitInProgress', false)
                  .set('loading', false);

    case actionTypes.REPORT_KIT_DIALOG_SUBMIT_KITS_AND_CREATE_IN_PROGRESS:
      return state.set('submitAndCreateInProgress', true)
                  .set('loading', true);

    case actionTypes.REPORT_KIT_DIALOG_SUBMIT_KITS_AND_CREATE_FINISHED:
      return state.set('submitAndCreateInProgress', false)
                  .set('loading', false);

    default:
      return defaultDialogReducer(state, action, dialogsNames.REPORT_KIT_DIALOG, defaultState);
  }
}




import React from 'react';
import PropTypes from 'prop-types';
import SelectAllCheckbox from 'infrastructure/js/components/Grid/Filters/MultiSelectFilter/SelectAllCheckbox/selectAllCheckbox';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import FilterFooter from 'infrastructure/js/components/Grid/Filters/Common/FilterFooter/filterFooter';
import FilterLayout from 'infrastructure/js/components/Grid/Filters/Common/FilterLayout/filterLayout';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

require('./multiSectionsFilter.scss');

export default class PL_MultiSectionsFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.filter.');

    this.filterName = props.filterName;

    let {sections} = props;
    this.state = {
      data: sections
    };

    this.currentData = this.deepCopy(this.state.data);

    //is it single section with single property
    this.isSingleSelection = (sections && sections.length === 1 && sections[0].properties && sections[0].properties.length === 1);
  }

  componentDidMount() {
    this.validateFilterParameters();
  }

  // called by agGrid
  afterGuiAttached(params) {
    if (this.currentData) {
      this.setState({data: this.deepCopy(this.currentData)});
    }

    this.hidePopup = params.hidePopup;
  }

  // called by agGrid
  doesFilterPass(params) {
    return true;
  }

// called by agGrid
  isFilterActive() {
    if (!this.currentData){
      return false;
    }

    let anySelected = false;
    this.currentData.map((section) => {
      section.properties.map((property) => {
        if (property.value) {
          anySelected = true;
        }
      });
    });
    return anySelected;
  }

  // called by agGrid
  onNewRowsLoaded() {
    this.handleFilterState();
  }

  handleFilterState = () => {
    let filterState = this.props.context?.componentParent?.props?.filterState;

    let isFilterStateActive = filterState?.some((item) => {
      return item.filterName === this.filterName && item.values;
    });
    if (!isFilterStateActive && this.isFilterActive()) {
      this.deactivateFilter();
    }
  }

  deactivateFilter = () => {
    let newData = this.createStateData(false);
    this.setState({data: newData});
    this.currentData = this.deepCopy(newData);
  }

  apply(){
    this.currentData = this.deepCopy(this.state.data);
    this.props.colDef.onFilterChanged(this.getModel());
    this.hidePopup();
  }

  cancel(){
    this.hidePopup();
  }

  getModel() {
    if (!this.currentData) {
      return [];
    }

    let checkedItems = [];
    this.state.data?.forEach((section) => {
      section.properties?.forEach((property) => {
        if (property.value) {
          checkedItems.push(property.name);
        }
      });
    });

    return [{ filterName: this.filterName, values: checkedItems}];
  }

  //not in use
  getApi() {
    return {
      getModel: this.getModel.bind(this)
    }
  }

  validateFilterParameters() {
    if (!this.props.filterName || !this.props.sections || this.props.sections.length === 0)
      throw "filter parameters are not valid"
  }

  deepCopy(data) {
    let result = data.map((section) => {
      return {
        header: section.header,
        properties: section.properties.map((property) => {
          return {name: property.name, value: property.value, label: property.label}
        })
      }
    });

    return result;
  }

  onSelectAllClick() {
    if (this.isAllCheckboxesSelected()) {
      this.setValueForAllCheckboxes(false);
    }else {
      this.setValueForAllCheckboxes(true);
    }
  }

  setValueForAllCheckboxes(newValue) {
    this.setState ({data: this.createStateData(newValue) });
  }

  createStateData(newValue) {
    return this.props.sections?.map((section) => {
      return {
        header: section.header,
        properties: section.properties?.map((property) => {
          return {name: property.name, label: property.label, value: newValue}
        })
      }
    })
  }

  isAllCheckboxesSelected() {
    let isAllCheckboxesSelected = true;
    this.state.data.map((section) => {
      section.properties.map((property) => {
        if (!property.value) {
          isAllCheckboxesSelected = false;
        }
      });
    });

    return isAllCheckboxesSelected;
  }

  onCheckboxChange(name, e) {
    let updatedData = this.state.data.map((section) => {
      return {
        header: section.header,
        properties: section.properties.map((property) => {
          if (property.name == name) {
            property.value = !property.value;
          }

          return property;
        })
      }
    });

    this.setState(
      {data: updatedData}
    )
  }

  getBody (){
    let getSectionBody = (properties) => {
      return properties.map((property)=> {
        return <Checkbox key={property.name} name={property.name} id={property.name} label={property.label} checked={property.value} onChange={this.onCheckboxChange.bind(this, property.name)}/>});
    }

    let getSectionHeader = (value) => {
      if (!value) {
        return null;
      }

      return <span><b>{value}</b></span>;
    }

    return this.state.data.map((section, index)=> {
      return <div key={`section${index}`}>
        {getSectionHeader(section.header)}
        {getSectionBody(section.properties)}
      </div>;
    });
  }

  render() {
    return (
      <FilterLayout className="multi-sections-filter"
                    columnWidth={this.props.column.actualWidth}
                    filterWidth={250}
                    filterAlignment={this.props.filterAlignment}
      >

        { !this.isSingleSelection &&
        <SelectAllCheckbox
          onClick={this.onSelectAllClick.bind(this)}
          isChecked={this.isAllCheckboxesSelected()}
          title={this.labels.get('selectall')}
        />}

        <div className="filter-body">
          {this.getBody()}
        </div>

        <FilterFooter
          okText={this.labels.get('submit') || 'FILTER'}
          cancelText={this.labels.get('cancel') || 'CANCEL'}
          onCancel={this.cancel.bind(this)}
          onOk={this.apply.bind(this)}
          showSelectedCount={false}
        />

      </FilterLayout>);
  }
}

PL_MultiSectionsFilter.propTypes = {
  filterName: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
}

import React from 'react';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import CheckMarkCell         from '../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';


class AssetTypesViewGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'name', filterName: 'name', getOptions: true},
    {fieldName: 'category', filterName: 'isCategory', getOptions: true},
  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.assettypes.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.assettype.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'name',
        width: 280,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'parent.name',
        title: this.labels.get('columns.parent.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          sortable: false,
        }
      },
      {
        fieldName: 'natureDefs',
        title: this.labels.get('columns.natures.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.naturesValueGetter(params.data.natureDefs);
          },
          sortable: false,
        }


      },
      {
        fieldName: 'category',
        title: this.labels.get('columns.isCategory.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: CheckMarkCell,
        }
      },
    ]
  };

  naturesValueGetter = (natures) => {
    return { dataList: natures.map((item)=> {return item.displayName ? item.displayName : item.name}),
      componentType: LabelWithTooltipCell};
  };

  getRowHeight(params) {
    let rowHeight =  64;
    if (params.data.natureDefs && params.data.natureDefs.length > 1) {
      rowHeight = params.data.natureDefs.length * 30 + 40;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_ASSET_TYPES}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'assetTypesViewGrid'
  }
)(AssetTypesViewGrid);

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { gridsNames } from '../../../enums/gridsNames';
import { jsxActions as headerActions } from '../../../actions/Header/headerActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as demoOrganizationsGridActions } from '../../../actions/Administration/OrganizationsPage/demoOrganizationsGridActions';
import { jsxActions as demoOrganizationsPageActions } from '../../../actions/Administration/OrganizationsPage/demoOrganizationsPageActions';
import { jsxActions as editDemoOrganizationDialogActions } from '../../../actions/Administration/OrganizationsPage/Dialogs/editDemoOrganizationDialogActions';
import { jsxActions as createDemoOrganizationDialogActions } from '../../../actions/Administration/OrganizationsPage/Dialogs/createDemoOrganizationDialogActions';
import { jsxActions as deleteDemoOrganizationDialogActions } from '../../../actions/Administration/OrganizationsPage/Dialogs/deleteDemoOrganizationDialogActions';

import DemoOrganizationsPage from '../../../components/Administration/OrganizationsPage/demoOrganizationsPage';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(demoOrganizationsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      demoOrganizationsGridActions: bindActionCreators(demoOrganizationsGridActions, dispatch),
      editDemoOrganizationDialogActions: bindActionCreators(editDemoOrganizationDialogActions, dispatch),
      createDemoOrganizationDialogActions: bindActionCreators(createDemoOrganizationDialogActions, dispatch),
      deleteDemoOrganizationDialogActions: bindActionCreators(deleteDemoOrganizationDialogActions, dispatch),
    },
  };
}

let DemoOrganizationsPageContainer = connect((state) => {
  const demoOrganizationsGrid = state.grid.get(gridsNames.ADMINISTRATION_DEMO_ORGANIZATIONS);

  return {
    sData: state.administration.get('organizationsPage'),
    sDemoOrganizationsGrid: demoOrganizationsGrid,
    sHasRows: demoOrganizationsGrid?.get('rows')?.size > 0,
    sSelectedRowsData: demoOrganizationsGrid?.get('selectedRowsData') || List(),
    sLoggedInUser: state.login.get('loggedInUser'),
    sCreateOrganizationDialogData: state.administration.getIn(['organizationsPage', 'createOrganizationDialog']),
    sCreateDemoOrganizationDialogData: state.administration.getIn(['organizationsPage', 'createDemoOrganizationDialog']),
    sDeleteDemoOrganizationDialogData: state.administration.getIn(['organizationsPage', 'deleteDemoOrganizationDialog']),
  };
}, mapDispatchToProps)(DemoOrganizationsPage);

export default DemoOrganizationsPageContainer;

import React, {Component} from 'react';

import PL_Wizard  from 'infrastructure/js/components/Wizard/wizard';
import UploadFileStep from '../ImportFiles/SubComponents/uploadFileStep.js';
import ImportFileStep from '../ImportFiles/SubComponents/importFileStep.js';
import SelectNestsStep from './SubComponents/SelectNestsStep';

require('./importNestsAnyRollsWizard.scss');

export default class ImportNestsAnyRollsWizard extends React.PureComponent {


  getSteps = ()=> {
    return {
      'step_1': <UploadFileStep />,
      'step_2': <SelectNestsStep />,
      'step_3': <ImportFileStep />
    };
  };

  render() {
    return (
      <PL_Wizard wizardName="IMPORT_NESTS_ANY_ROLLS_WIZARD" steps={this.getSteps()} {...this.props} form="wizard_form_IMPORT_NESTS_ANY_ROLLS"/>
    );
  }
}

import React from 'react';
import {reduxForm} from 'redux-form';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import MaterialHelper from '../../../../utils/materialHelper';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
require('./onHandInventoryGrid.scss');

class OnHandInventoryGrid extends React.PureComponent {

  filterConfig = [
     {fieldName: 'materialBusinessId', filterName: 'materialName', getOptions: false},
     {fieldName: 'units', filterName: 'units', getOptions: false},
     {fieldName: 'm_Quantity', filterName: 'quantity', getOptions: false},
     {filterName: 'location', getOptions: false},
     {filterName: 'assetAlert', getOptions: false},
  ];

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.onHandInvertory.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'materialBusinessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'materialName',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE],
          action: this.props.actions.fetchEntities
        },
        filterWidth: 400,
        width: 400,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.materialName, params.data.materialBusinessId);
          },
          sort: 'asc',
        }
      },
      {
        fieldName: 'units',
        title: this.labels.get('columns.units.title'),
        filterType: filterTypes.NONE,
        filterName: 'units',
        width: 150,
        columnOptions: {
          valueGetter: (params) => {
            return params.data?.units;
          },
        }
      },
      {
        fieldName: 'm_Quantity',
        title: this.labels.get('columns.quantity.title'),
        filterType: filterTypes.NONE,
        filterName: 'quantity',
        columnOptions: {
          valueGetter: (params) => {
            return params.data?.m_Quantity;
          },
        }
      },

    ]
  }

  getRowHeight() {
    return 100;
  }

  render() {
    return (
      <div className="on-hand-inventory-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ON_HAND_INVENTORY}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  checkboxSelection: false
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'OnHandInventoryGrid'
  }
)(OnHandInventoryGrid);







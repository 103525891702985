import { actionTypes as editWoAttributesActionTypes } from '../../../actions/Dialogs/EditWoAttributesDialog/editWoAttributesDialogActions.js';

import { Map, List } from 'immutable';
import {actionTypes as ActivateAssetsDialogActionTypes} from "../../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions";

let defaultState = {
  show: false,

  wos : [],

  projects: [],
  parts: [],
  kitTypes:[],
  customerOrders: [],

  preselectedAssets: List(),
  entitiesTypes: List(),
  boEntityFilter: '',
  cancelToken: '',
  loading: false,
  additionalFields:[]
};


export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_ASSETS_READY:
      // return state.set("wos", action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId, data: obj } }) );
      return state.set("wos", action.payload.dataList );
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ? List(action.payload.preselectedAssets) : List())
        .set('entitiesTypes', action.payload && action.payload.entitiesTypes ? List(action.payload.entitiesTypes) : List())
        .set('boEntityFilter', action.payload && action.payload.boEntityFilter ? action.payload.boEntityFilter : '')
        .set('show', true);
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_HIDE:
       return Map(defaultState);

    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_SAVE_CANCEL_TOKEN:
      return state.set("cancelToken", action.payload);

    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_PROJECTS_READY:
      return state.setIn(['projects'], action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId} }));
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_PARTS_READY:
      return state.setIn(['parts'], action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId} }));
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_KIT_TYPES_READY:
      return state.setIn(['kitTypes'], action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId , relatedProject : obj.project} }));
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_CUSTOMERORDERS_READY:
      return state.setIn(['customerOrders'], action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId} }));
    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_ADDITIONAL_FIELDS_READY:
      return state.set('additionalFields', action.payload.dataList);

    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_SET_VALIDATION_WARNINGS_DATA:
      return state.set('validationMessage', action.payload)
        .set('hasError', !!action.payload.errors)
        .set('showIgnoreValidationCheckbox', !action.payload.errors)
        .set('hasHighSeverityWarning', action.payload.reset ? false : action.payload.hasHighSeverityWarning)
        .set('hasMediumSeverityWarning', action.payload.reset ? false : action.payload.hasMediumSeverityWarning)
        .set('isIgnoreValidationWarnings', false);

    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('showIgnoreValidationCheckbox', false)
        .set('hasHighSeverityWarning', false)
        .set('hasMediumSeverityWarning', false)
        .set('hasError', false)
        .set('isIgnoreValidationWarnings', false);

    case editWoAttributesActionTypes.EDITWOATTRIBUTES_DIALOG_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('isIgnoreValidationWarnings', action.payload);

      default:
      return state;
  }


  return state;
}



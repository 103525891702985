import 'node_modules/font-awesome/scss/font-awesome.scss';

//Load bootstrap css
//import 'node_modules/5/dist/css/bootstrap.min.css';
import 'infrastructure/styles/bootstrap.min.css';
import 'node_modules/bootstrap/dist/css/bootstrap-theme.min.css';

import 'infrastructure/styles/fonts.scss';

import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import configureStore from './configureStore';
import { Router, Route, IndexRoute, hashHistory, Redirect, Switch } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import ReactIntlReduxProvider from './providers/ReactIntlReduxProvider';

//import { syncHistoryWithStore } from 'react-router-redux';

import App from './App';
import MobileApp from './MobileApp';

import LoginPage from './containers/LoginPage/loginPage.js';
import LatencyCalc from './containers/LatencyCalc/latencyCalc.js';
import ResetPasswordPage from './containers/ResetPasswordPage/resetPasswordPage';
import ChangePasswordPage from './containers/ChangePasswordPage/changePasswordPage';
import RegisterPage from './containers/RegisterPage/registerPage';
import AuthenticationPage from './containers/AuthenticationPage/authenticationPage';

import ChangePasswordDialog from './containers/Dialogs/changePasswordDialogContainer.js';

//import {UpdateLocalization} from 'infrastructure/js/actions/languageActions';
import { api as localizationActionsApi } from 'infrastructure/js/actions/languageActions';
import { api as versionsActionsApi } from 'infrastructure/js/actions/versionsActions';
import { jsxActions as systemActions } from './actions/System/systemActions';
import { jsxActions as loginActions } from './actions/Login/loginActions';
import { jsxActions as notificationActions } from './actions/Notifications/notificationsActions';

import TopIndicationBar from 'infrastructure/js/components/TopIndicationBar/topIndicationBar';
import NotificationTopIndicationBar from 'infrastructure/js/components/NotificationTopIndicationBar/notificationTopIndicationBar';

import AppLoader from 'infrastructure/js/components/AppLoader/appLoader';
import { injectIntl } from 'react-intl';
import { initLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Network from 'infrastructure/js/modules/network';
import MessageDialog from './containers/messageDialog.js';
import ActivityLogDialog from './containers/ActivityLogDialog/activityLogDialogContainer';
import UserManagementDialog from './containers/Dialogs/userManagementDialogContainer';
import AboutDialog from './containers/Dialogs/aboutDialogContainer';
import BatchJobProgressDialog from './containers/BatchJobProgressDialog/batchJobProgressDialogContainer';
import SecurityMessageDialog from './containers/Dialogs/securityMessageDialogContainer';
import * as AppHelper from 'infrastructure/js/utils/appHelper';
import { checkInactivityTime } from './utils/idleTimerHelper';
import ScheduleAction from './modules/scheduleAction';

import '../assets/svg-config.font.js';

const store = configureStore();

// Create an enhanced history that syncs navigation events with the store

//const history = syncHistoryWithStore(hashHistory, store);
const appElement = document.getElementById('main');

class IndexLocalizationWrapper extends Component {
  constructor(props) {
    super(props);
    initLabelHelper(this.props.intl);
  }

  componentDidMount() {
    ScheduleAction('Maintenance Scheduler', this.props.actions.systemActions.onMaintenanceCheck, 60, true);
    checkInactivityTime();
    this.props.actions.systemActions.setFeatureFlags();
    if (this.props.sLoggedInUser.id) {
      systemActions.initServerDatetime()(store.dispatch, store.getState);
      versionsActionsApi.initVersionsData(store.dispatch, store.getState)();

      if (AppHelper.isPreviewMode()) {
        this.props.actions.systemActions.setPreviewModeOn();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sLoggedInUser !== this.props.sLoggedInUser) {
      this.props.actions.systemActions.setFeatureFlags();
      //initLabelHelper(this.props.intl);

      let userStatus = nextProps?.sLoggedInUser?.status?.name;
      if (userStatus && userStatus !== 'PREACTIVE') {
        //User wit status 'user_status.preactive' is Unauthorized for requests.
        versionsActionsApi.initVersionsData(store.dispatch, store.getState)();
      }
    }

    if (nextProps.intl !== this.props.intl) {
      initLabelHelper(nextProps.intl);
    }
  }

  isLoggedIn = () => {
    let { sLoggedInUser } = this.props;
    return sLoggedInUser && !!sLoggedInUser.id && !!sLoggedInUser.loggedInOrg;
  };

  getRoutes = () => {
    let routesArr = [
      <Route
        key="loginRouteKey"
        path="/Login"
        render={(props) => <LoginPage {...props} loginPath="/Location/" datetimeLoader={this.props.datetimeLoader} />}
      />,
      <Route key="resetPasswordRouteKey" path="/ResetPassword" component={ResetPasswordPage} />,
      <Route key="changePasswordRouteKey" path="/ChangePassword" component={ChangePasswordPage} />,
      <Route key="registerRouteKey" path="/Register" component={RegisterPage} />,
      <Route key="latencyCalcRouteKey" path="/latencyCalc" component={LatencyCalc} />,
      <Route key="AuthRouteKey" path="/Authenticate" component={AuthenticationPage} />,
    ];

    if (this.isLoggedIn()) {
      routesArr.push(<Route key="appRouteKey" path="/" component={App} />);
    } else {
      routesArr.push(<Route key="loginRouteKey2" path="/" render={() => <Redirect to="/Login" />} />);
    }
    return routesArr;
  };

  getMobileRoutes = () => {
    let routesArr = [
      <Route
        key="loginRouteKey"
        path="/Login"
        render={(props) => <LoginPage {...props} loginPath="/Mobile/" datetimeLoader={this.props.datetimeLoader} />}
      />,
      <Route key="registerRouteKey" path="/Register" component={RegisterPage} />,
    ];

    if (this.isLoggedIn()) {
      routesArr.push(<Route key="appRouteKey" path="/" component={MobileApp} />);
    } else {
      routesArr.push(<Route key="loginRouteKey2" path="/" render={() => <Redirect to="/Login" />} />);
    }
    return routesArr;
  };

  render() {
    let { sTopIndicatorBarData, sNotificationsData, sLoading } = this.props;
    let loadingClassName = '';
    if (sLoading) {
      loadingClassName = 'loading';
    }

    if (AppHelper.isMobileApp()) {
      return (
        <div className={`index-localization-wrapper ${loadingClassName}`}>
          <MessageDialog />
          <TopIndicationBar actions={this.props.actions} data={sTopIndicatorBarData}></TopIndicationBar>
          <NotificationTopIndicationBar actions={this.props.actions} data={sNotificationsData} />
          <AppLoader isOn={sLoading}></AppLoader>

          <HashRouter>
            <Switch>{this.getMobileRoutes()}</Switch>
          </HashRouter>
        </div>
      );
    }

    return (
      <div className={`index-localization-wrapper ${loadingClassName}`}>
        <MessageDialog />
        <BatchJobProgressDialog />
        <ActivityLogDialog />
        <UserManagementDialog />
        <AboutDialog />
        <ChangePasswordDialog />
        <SecurityMessageDialog />
        <TopIndicationBar actions={this.props.actions} data={sTopIndicatorBarData}></TopIndicationBar>
        <NotificationTopIndicationBar actions={this.props.actions} data={sNotificationsData} />

        <AppLoader isOn={sLoading}></AppLoader>

        <HashRouter>
          <Switch>{this.getRoutes()}</Switch>
        </HashRouter>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      systemActions: bindActionCreators(systemActions, dispatch),
      loginActions: bindActionCreators(loginActions, dispatch),
      notificationActions: bindActionCreators(notificationActions, dispatch),
    },
  };
}

IndexLocalizationWrapper = connect((state) => {
  let criticals = state.appTopbar.get('notificationsData')?.get('criticals');

  return {
    sNotificationsData: criticals?.length ? criticals : null,
    sTopIndicatorBarData: state.system.get('indicatorBarData'),
    sLoading: state.system.get('loading'),
    sLoggedInUser: state.login.get('loggedInUser'),
  };
}, mapDispatchToProps)(IndexLocalizationWrapper);

let IntlInjectedContents = injectIntl(IndexLocalizationWrapper);

class Index extends Component {
  constructor() {
    super();
    Network.initDispatch(store.dispatch);
    // Load labels from local storage and update React-Intl.
    // Needed for page refresh, because labels are fetched from server only at login.

    if (AppHelper.isPreviewMode()) {
      localizationActionsApi.updateIntlForPreviewMode(store.dispatch, store.getState)();
    } else {
      localizationActionsApi.updateIntlFromLocalStorage(store.dispatch, store.getState)();
    }
  }

  render() {
    return (
      <Provider store={store}>
        <div className="providerContainer">
          <ReactIntlReduxProvider className="IntlProvider">
            <IntlInjectedContents datetimeLoader={this.datetimeLoader} />
          </ReactIntlReduxProvider>
        </div>
      </Provider>
    );
  }
}

ReactDOM.render(<Index></Index>, appElement);

import React, {Component} from 'react';
import PropTypes from 'prop-types';

require('./circularProgressBar.scss');

export default class CircularProgressBar extends Component {

  render() {
    //progress bar outer radius
    let {radius} = this.props;
    //SVG viewport
    let viewBox = `0 0 ${radius} ${radius}`;
    //progress bar inner radius
    let circleRadius = (this.props.radius - this.props.strokeWidth) / 2;
    // Arc length at 100% coverage is the circle circumference
    let dashArray = circleRadius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    let dashOffset = dashArray - dashArray * this.props.percentage / 100;

    return (
      <svg className="circle-progress-bar"
        width={this.props.radius}
        height={this.props.radius}
        viewBox={viewBox}>
        <circle
          className="circle-background"
          cx={this.props.radius / 2}
          cy={this.props.radius / 2}
          r={circleRadius}
          strokeWidth={`${this.props.strokeWidth}px`} />
        <circle
          className="circle-progress"
          cx={this.props.radius / 2}
          cy={this.props.radius / 2}
          r={circleRadius}
          strokeWidth={`${this.props.strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${this.props.radius / 2} ${this.props.radius / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }} />
        <text
          className="circle-text"
          x="50%"
          y="50%"
          dy=".3em"
          style={{
            fontSize: radius / 4
          }}

          textAnchor="middle">
          {`${this.props.percentage}%`}
        </text>
      </svg>
    );
  }
}

CircularProgressBar.defaultProps = {
  radius: 50,
  percentage: 28,
  strokeWidth: 10
};

CircularProgressBar.propTypes = {
  radius : PropTypes.number,
  percentage : PropTypes.number,
  strokeWidth : PropTypes.number,
};

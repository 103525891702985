import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
// import arrayMove from 'array-move';
import { Field } from 'redux-form';

require('./sortableList.scss');

export default class PL_SortableListForm extends React.PureComponent {
    render() {
        return <Field {...this.props} component={SortableList} />;
    }
}

//"array-move": "2.2.1",

export function SortableList({ items, input, className, itemRenderer, distance }) {
    const [listItems, setListItems] = useState(items ?? []);

    useEffect(() => {
        setListItems(items);
    }, [items]);

    useEffect(() => {
        if (input) {
            input.onChange(listItems);
        }
    }, [listItems, input]);

    const arrayMoveMutate = (array, from, to) => {
        const startIndex = to < 0 ? array.length + to : to;
        const item = array.splice(from, 1)[0];
        array.splice(startIndex, 0, item);
    };

    const arrayMove = (array, from, to) => {
        array = array.slice();
        arrayMoveMutate(array, from, to);
        return array;
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newItems = arrayMove(listItems, oldIndex, newIndex);
        setListItems(newItems);
    };

    let SortableItem = sortableElement(({ item }) => {
        return itemRenderer ? itemRenderer(item) : <div>{item}</div>;
    });

    let SortableContainer = sortableContainer(({ children }) => {
        return <div className={cn('pl-sortable-list', className)}>{children}</div>;
    });

    return (
        <SortableContainer
            onSortEnd={onSortEnd}
            helperClass='SortableHelper'
            lockAxis='y'
            lockToContainerEdges={true}
            distance={distance}
        >
            <div className='items-wrapper'>
                {listItems.map((item, index) => (
                    <SortableItem key={`item-${index}`} index={index} item={item} />
                ))}
            </div>
        </SortableContainer>
    );
}

SortableList.propTypes = {
    items: PropTypes.array,
    itemRenderer: PropTypes.func,
    className: PropTypes.string,
};

SortableList.defaultProps = {
    items: [],
};

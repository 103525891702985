import React, { Component } from 'react';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import ScheduleAction from '../../modules/scheduleAction.js';
import NotificationsItem from './NotificationItem/notificationItem';

import Button from 'infrastructure/js/components/controls/Button/button';

require('./notificationsPage.scss');

export default class NotificationsPage extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.reports.notificationspage.');
  }

  componentDidMount() {
    this.props.actions.init(true);
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  onMarkItemsAsReadButtonClick = () => {
    this.props.actions.onMarkItemsAsReadButtonClick();
  };

  render() {
    let items = this.props.sData.get("dataList");

    let itemProps = {
      ...this.props,
      labels: this.labels
    };

    return (
      <div className="notifications-page-container">

        <div className="side-filters-menu">
          &nbsp;
        </div>


        <div className="content-view">

          <div className="top-header">
            <label className="title">{this.labels.get('header.title')}</label>
            <div>
              <Button
                id='MarkItemsAsReadButton'
                className={'main-operation border-btn'}
                onClick={this.onMarkItemsAsReadButtonClick}
                icon={'pl pl-mark-complete'}>
                {this.labels.get('header.markallasread')}
              </Button>
            </div>
          </div>

          <div className="data-display">
            {items.map((item, index) => {
              return <NotificationsItem key={'notification-item' + index} item={item} {...itemProps} />;
            })}
          </div>

        </div>


      </div>
    )
  }
};







import { Map } from 'immutable';
import { actionTypes as WeightWizardActionType } from '../../actions/AssetPage/weightWizardActions';

let defaultState = {
  loading: false,
  rollId: null,
  errorMessage: '',
  isFetchWeightLoading: false,
  weight: null
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case WeightWizardActionType.WEIGHT_WIZARD_SAVE_PARAMETERS:
      return state.set("rollId", action.payload);

    case WeightWizardActionType.WEIGHT_WIZARD_HIDE:
      return Map(defaultState);

    case WeightWizardActionType.WEIGHT_WIZARD_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case WeightWizardActionType.WEIGHT_WIZARD_SUBMIT_FINISHED:
      return state.set("loading", false);

    case WeightWizardActionType.WEIGHT_WIZARD_UPDATE_ERROR_MESSAGE:
      return state.set("errorMessage", action.payload);

    case WeightWizardActionType.WEIGHT_WIZARD_SET_FETCH_WEIGHT_LOADING:
      return state.set("isFetchWeightLoading", action.payload);

    case WeightWizardActionType.WEIGHT_WIZARD_SET_WEIGHT:
      return state.set("weight", action.payload);

    default:
      return state;
  }
}













import React from 'react';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import CheckMarkCell from '../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';

require('./sensorsViewGrid.scss');

export default class SensorsViewGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'active', filterName: 'isActive', getOptions: true},
    {fieldName: 'sensorName', filterName: 'sensorNameSearch', getOptions: true},
    {fieldName: 'type', filterName: 'type', getOptions: true},
    {fieldName: 'uom', filterName: 'uom', getOptions: true},
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.settings.sensors.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'sensorName',
        title: this.labels.get('columns.sensor.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'sensorNameSearch',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'm_type',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'type',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'active',
        title: this.labels.get('columns.active.title'),
        filterType: filterTypes.NONE,
        filterName: 'isActive',
        width: 130,
        columnOptions: {
          sort: 'desc',
          cellComponent: CheckMarkCell,
        }
      },
      {
        fieldName: 'm_samplePeriod',
        title: this.labels.get('columns.samplePeriod.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'deviation',
        title: this.labels.get('columns.deviation.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.deviationValueGetter(params.data);
          },
        }
      },
    /*  {
        fieldName: 'deviceApp',
        title: this.labels.get('columns.deviceApp.title'),
        filterType: filterTypes.SEARCH,
        filterName: 'deviceApp',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },*/

    ]
  };

  deviationValueGetter = (data) => {
    return { dataList: [data.m_deviationUp, data.m_deviationDown]   ,  componentType: LabelWithTooltipCell};
  };

  getRowHeight = (params) => {
    return 80;
  };

  render() {
    return (
      <div className="sensors-grid ">
        <Grid gridName={gridsNames.ADMINISTRATION_SETTINGS_SENSORS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}



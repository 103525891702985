import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import DashboardDemoDataView from '../../../components/Administration/PsManagementPage/DashboardDemoDataView/dashboardDemoDataView.js';
import {jsxActions as dashboardsDemoDataViewActions} from '../../../actions/Administration/PsManagementPage/dashboardsDemoDataViewActions.js'
import { jsxActions as editDashboardDemoDataDialogActions} from '../../../actions/Administration/PsManagementPage/Dialogs/editDashboardDemoDataDialogActions';
import { jsxActions as psManagementPageActions} from '../../../actions/Administration/PsManagementPage/psManagementPageActions';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(dashboardsDemoDataViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      psManagementPageActions : bindActionCreators(psManagementPageActions, dispatch),
      editDashboardDemoDataDialogActions : bindActionCreators(editDashboardDemoDataDialogActions, dispatch),
    }

  }
}

let DashboardsDemoDataViewContainer = connect(
  (state) => {
  	return {
      sLoggedInUser: state.login.get('loggedInUser'),
      sData: state.administration.getIn(['psManagementPage', 'dashboardsDemoDataView']),
      sEditDashboardDemoDataDialogData: state.administration.getIn(['psManagementPage', 'editDashboardDemoDataDialog']),
    };
  },

  mapDispatchToProps
)(DashboardDemoDataView);


export default DashboardsDemoDataViewContainer;




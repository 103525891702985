import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import DropDown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';

require('./createAutomationScenarioDialog.scss');

class CreateAutomationScenarioDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.rfidsettings.dialog.createAutomationScenario.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    let dialogData = this.props.sData.get('dialogData');
    this.actionSequencesOptions = dialogData && dialogData.actionSequences ? dialogData.actionSequences : [];
    this.qualifiedEventsOptions = dialogData && dialogData.qualifiedEvents ? dialogData.qualifiedEvents : [];
    this.antennasOptions = dialogData && dialogData.antennas ? dialogData.antennas : [];
    this.readersOptions = dialogData && dialogData.readers ? dialogData.readers : [];


    this.state = {
      isQeSuccessFeedback: false,
      isQeFailureFeedback: false,
      isAsSuccessFeedback: false,
      isAsFailureFeedback: false,
    };
  }

  componentDidMount() {

    let initialValues = {
      active: true,
      antennasTypeSelect : {value: 1, label: this.labels.get('antennas')},
    };

    if (this.isEditMode) {
      initialValues.active = this.itemToEdit.active;
      initialValues.automationScenarioName = this.itemToEdit.name;
      initialValues.antennasSelect = this.getInitialAntennas(this.itemToEdit.antennas);

      initialValues.qualifiedEvent = this.itemToEdit.qualifiedEvent ? this.qualifiedEventsOptions.find((item) => item.value === this.itemToEdit.qualifiedEvent.id ) : null;
      initialValues.actionSequence = this.itemToEdit.actionSequence ? this.actionSequencesOptions.find((item) => item.value === this.itemToEdit.actionSequence.id ) : null;

      this.initGpoFeedbacks(initialValues, this.itemToEdit.gpoFeedbacks);
    }
    else {
       initialValues.isQeSuccessFeedback = true;
       initialValues.isQeFailureFeedback = true;
       initialValues.isAsSuccessFeedback = true;
       initialValues.isAsFailureFeedback = true;

       this.setState(
         {
           isQeSuccessFeedback: true,
           isQeFailureFeedback: true,
           isAsSuccessFeedback: true,
           isAsFailureFeedback: true,
         }
       )
    }

    this.props.initialize(initialValues);
  }

  getInitialAntennas = (items) => {
    if (items) {
      return items.map((item) => {return {value: item.id, label: item.name, data: item} });
    }
    return [];
  };

  initGpoFeedbacks = (initialValues, items) => {
    if (items) {
      let newState = {};
      items.forEach((item) => {
        if (item.feedbackType === 'QUALIFIED_EVENT_SUCCESS') {
          initialValues.isQeSuccessFeedback = !!(item.host && item.gpoId);
          initialValues.qeSuccessReader =  this.getReaderByHost(this.readersOptions, item.host);
          initialValues.qeSuccessGpoId = item.gpoId;
          newState.isQeSuccessFeedback = !!(item.host && item.gpoId);
        }
        else if (item.feedbackType === 'QUALIFIED_EVENT_FAILURE') {
          initialValues.isQeFailureFeedback = !!(item.host && item.gpoId);
          initialValues.qeFailureReader = this.getReaderByHost(this.readersOptions, item.host);
          initialValues.qeFailureGpoId = item.gpoId;
          newState.isQeFailureFeedback = !!(item.host && item.gpoId);
        }
        else if (item.feedbackType === 'ACTION_SEQUENCE_SUCCESS') {
          initialValues.isAsSuccessFeedback = !!(item.host && item.gpoId);
          initialValues.asSuccessReader = this.getReaderByHost(this.readersOptions, item.host);
          initialValues.asSuccessGpoId = item.gpoId;
          newState.isAsSuccessFeedback = !!(item.host && item.gpoId);
        }
        else if (item.feedbackType === 'ACTION_SEQUENCE_FAILURE') {
          initialValues.isAsFailureFeedback = !!(item.host && item.gpoId);
          initialValues.asFailureReader = this.getReaderByHost(this.readersOptions, item.host);
          initialValues.asFailureGpoId = item.gpoId;
          newState.isAsFailureFeedback = !!(item.host && item.gpoId);
        }
      });

      this.setState(newState);
    }
  };

  getReaderByHost = (readersOptions, host) => {
    let found = readersOptions.find((reader) => reader.data && reader.data.host === host );
    return found ? found : null;
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  onSubmit = (data) => {

    let antennasIds = data && data.antennasSelect ? data.antennasSelect.map((item) => {
      return item.value;
    }) : [];

    let submitData = {
      // deviceAppProfileId: this.props.deviceAppProfileId,
      isActive: data.active,
      name: data.automationScenarioName,
      antennasIds,
      qualifiedEventId: data.qualifiedEvent ? data.qualifiedEvent.value : null,
      actionSequenceId: data.actionSequence ? data.actionSequence.value : null,
      gpoFeedbacks: this.getGpoFeedbacks(data),
    }

    if (this.isEditMode) {
      submitData.id = this.itemToEdit.id;
    }
    else {
      submitData.deviceAppProfileId = this.props.deviceAppProfileId;
    }

    this.props.actions.submit(submitData, null, this.props.reloadParentComponent, {isEditMode: this.isEditMode});
  };

  getGpoFeedbacks = (data) => {

    let feedbacksData = [
      {
        isEnabled: data.isQeSuccessFeedback,
        feedbackType: 'QUALIFIED_EVENT_SUCCESS',
        reader: data.qeSuccessReader,
        gpoId: data.qeSuccessGpoId,
      },
      {
        isEnabled: data.isQeFailureFeedback,
        feedbackType: 'QUALIFIED_EVENT_FAILURE',
        reader: data.qeFailureReader,
        gpoId: data.qeFailureGpoId,
      },
      {
        isEnabled: data.isAsSuccessFeedback,
        feedbackType: 'ACTION_SEQUENCE_SUCCESS',
        reader: data.asSuccessReader,
        gpoId: data.asSuccessGpoId,
      },
      {
        isEnabled: data.isAsFailureFeedback,
        feedbackType: 'ACTION_SEQUENCE_FAILURE',
        reader: data.asFailureReader,
        gpoId: data.asFailureGpoId,
      },
    ];

    let gpoFeedbacks = [];

    feedbacksData.forEach((item) => {
      if (item.isEnabled) {
        gpoFeedbacks.push (
          {
            feedbackType: item.feedbackType,
            host: item.reader && item.reader.data ? item.reader.data.host : null,
            gpoId: item.gpoId,
          }
        )
      }
    })
    return gpoFeedbacks;
  }

  onHide =() => {
    this.props.actions.hide();
  };

  onCheckboxClick = (e) => {
    this.setState({[e.target.id]: e.target.checked});

    switch (e.target.id) {
      case 'isQeSuccessFeedback':
        return this.clearFeedbackLine('qeSuccess');
      case 'isQeFailureFeedback':
        return this.clearFeedbackLine('qeFailure');
      case 'isAsSuccessFeedback':
        return this.clearFeedbackLine('asSuccess');
      case 'isAsFailureFeedback':
        return this.clearFeedbackLine('asFailure');
    }

  }
  clearFeedbackLine = (prefix) => {
    this.props.change(prefix + 'Reader', null);
    this.props.change(prefix + 'GpoId', null);
  }

  getMaxGpoByReaderType(type){
    switch (type) {
      case 'IMPINJ':
      case 'ZEBRA':
        return 4;
      default:
        console.error('getMaxGpoByReaderType (): unknown reader type ' + type);
        return 0;
    }
  }

  validateGpoId = (reduxName) => (value, allValues)=>{
    if ( !allValues[reduxName] ) {
      return undefined;
    }

    let readerType = allValues[reduxName].data ? allValues[reduxName].data.readerType : null;

    let maxGpo = this.getMaxGpoByReaderType(readerType);

    return (value > maxGpo) ? this.labels.get('invalidGpoForReader') : undefined;
  };

  qeSuccessGpoIdValidate = this.validateGpoId('qeSuccessReader');
  qeFailureGpoIdValidate = this.validateGpoId('qeFailureReader');
  asSuccessGpoIdValidate = this.validateGpoId('asSuccessReader');
  asFailureGpoIdValidate = this.validateGpoId('asFailureReader');

  maxLength30 = Validation.maxLength(30);

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-automation-scenario-dialog"
        className="create-automation-scenario-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >


        <InputSection htmlFor="active" className="inline two-columns no-margin">
          <Checkbox name="active" id="active"  label={this.labels.get('active')} />
        </InputSection>

        <InputSection label={this.labels.get('name') + '*'} htmlFor="automationScenarioName" className="inline">
          <TextField id="automationScenarioName"
                     name="automationScenarioName"
                     validate={[Validation.required, this.maxLength30]}/>
        </InputSection>

        <InputSection label='' className="full">
          <DropDown
            key={'antennasTypeSelect'}
            id={'antennasTypeSelect'}
            name={'antennasTypeSelect'}
            className='antennas-type-select'
            isDisabled={true}
          />

          <PL_MultiSelectField name='antennasSelect' id='antennasSelect'
                               closeMenuOnSelect={false}
                               validate={Validation.dropdown.required}
                               className='antennas-select'
                               options={this.antennasOptions} />
        </InputSection>

        <div className="qualified-event-section">

          <InputSection label={this.labels.get('qualifiedEvent')+'*'} htmlFor="qualifiedEvent" className="inline">
            <Combobox id="qualifiedEvent"
                      name="qualifiedEvent"
                      options={this.qualifiedEventsOptions}
                      validate={Validation.required}
                      className=""/>
          </InputSection>

          <div className="feedback-params-section">
            <InputSection htmlFor="isQeSuccessFeedback" className="inline small no-margin">
              <Checkbox name="isQeSuccessFeedback" id="isQeSuccessFeedback"
                        label={this.labels.get('feedbackSuccess')}
                        onClick={this.onCheckboxClick}
              />
            </InputSection>

            <InputSection label={this.labels.get('readerName')+'*'} htmlFor="qeSuccessReader" className="inline small no-margin">
              <Combobox id="qeSuccessReader"
                        name="qeSuccessReader"
                        options={this.readersOptions}
                        validate={this.state.isQeSuccessFeedback ? Validation.required : undefined}
                        isDisabled={!this.state.isQeSuccessFeedback}
              />
            </InputSection>

            <InputSection label={this.labels.get('gpoId')+'*'} htmlFor="qeSuccessGpoId" className="inline small no-margin">
              <TextField id="qeSuccessGpoId"
                         name="qeSuccessGpoId"
                         normalize={Normalize.number(true, 1, 4)}
                         validate={this.state.isQeSuccessFeedback ? [Validation.number.required, this.qeSuccessGpoIdValidate ] : undefined}
                         disabled={!this.state.isQeSuccessFeedback}
              />
            </InputSection>
          </div>

          <div className="feedback-params-section">
            <InputSection htmlFor="isQeFailureFeedback" className="inline small no-margin">
              <Checkbox name="isQeFailureFeedback" id="isQeFailureFeedback"
                        label={this.labels.get('feedbackFailure')}
                        onClick={this.onCheckboxClick}
              />
            </InputSection>

            <InputSection label={this.labels.get('readerName')+'*'} htmlFor="qeFailureReader" className="inline small no-margin">
              <Combobox id="qeFailureReader"
                        name="qeFailureReader"
                        options={this.readersOptions}
                        validate={this.state.isQeFailureFeedback ? Validation.required : undefined}
                        isDisabled={!this.state.isQeFailureFeedback}
              />
            </InputSection>

            <InputSection label={this.labels.get('gpoId')+'*'} htmlFor="qeFailureGpoId" className="inline small no-margin">
              <TextField id="qeFailureGpoId"
                         name="qeFailureGpoId"
                         normalize={Normalize.number(true, 1, 4)}
                         validate={this.state.isQeFailureFeedback ? [Validation.number.required, this.qeFailureGpoIdValidate ] : undefined}
                         disabled={!this.state.isQeFailureFeedback}
              />
            </InputSection>
          </div>

        </div>

        <div className="action-sequence-section">

          <InputSection label={this.labels.get('actionSequence')+'*'} htmlFor="actionSequence" className="inline">
            <Combobox id="actionSequence"
                      name="actionSequence"
                      options={this.actionSequencesOptions}
                      validate={Validation.required}
                      className=""/>
          </InputSection>

          <div className="feedback-params-section">
            <InputSection htmlFor="isAsSuccessFeedback" className="inline small no-margin">
              <Checkbox name="isAsSuccessFeedback" id="isAsSuccessFeedback"
                        label={this.labels.get('feedbackSuccess')}
                        onClick={this.onCheckboxClick}
              />
            </InputSection>

            <InputSection label={this.labels.get('readerName')+'*'} htmlFor="asSuccessReader" className="inline small no-margin">
              <Combobox id="asSuccessReader"
                        name="asSuccessReader"
                        options={this.readersOptions}
                        validate={this.state.isAsSuccessFeedback ? Validation.required : undefined}
                        isDisabled={!this.state.isAsSuccessFeedback}
              />
            </InputSection>

            <InputSection label={this.labels.get('gpoId')+'*'} htmlFor="asSuccessGpoId" className="inline small no-margin">
              <TextField id="asSuccessGpoId"
                         name="asSuccessGpoId"
                         normalize={Normalize.number(true, 1, 4)}
                         validate={this.state.isAsSuccessFeedback ? [Validation.number.required, this.asSuccessGpoIdValidate ] : undefined}
                         disabled={!this.state.isAsSuccessFeedback}
              />
            </InputSection>
          </div>

          <div className="feedback-params-section">
            <InputSection htmlFor="isAsFailureFeedback" className="inline small no-margin">
              <Checkbox name="isAsFailureFeedback" id="isAsFailureFeedback"
                        label={this.labels.get('feedbackFailure')}
                        onClick={this.onCheckboxClick}
              />
            </InputSection>

            <InputSection label={this.labels.get('readerName')+'*'} htmlFor="asFailureReader" className="inline small no-margin">
              <Combobox id="asFailureReader"
                        name="asFailureReader"
                        options={this.readersOptions}
                        validate={this.state.isAsFailureFeedback ? Validation.required : undefined}
                        isDisabled={!this.state.isAsFailureFeedback}
              />
            </InputSection>

            <InputSection label={this.labels.get('gpoId')+'*'} htmlFor="asFailureGpoId" className="inline small no-margin">
              <TextField id="asFailureGpoId"
                         name="asFailureGpoId"
                         normalize={Normalize.number(true, 1, 4)}
                         validate={this.state.isAsFailureFeedback ? [Validation.number.required, this.asFailureGpoIdValidate ] : undefined}
                         disabled={!this.state.isAsFailureFeedback}
              />
            </InputSection>
          </div>

        </div>

      </Dialog>
    );
  }
}

CreateAutomationScenarioDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  deviceAppProfileId: PropTypes.number.isRequired,
};

export default reduxForm({
    form: 'createAutomationScenarioDialog',
  }
)(CreateAutomationScenarioDialog);




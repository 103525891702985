import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import { List } from 'immutable';
import { gridsNames} from '../../../../enums/gridsNames';
import { jsxActions as terminologyViewActions} from '../../../../actions/Administration/SettingsPage/terminologyViewActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import {jsxActions as terminologyViewGridActions} from '../../../../actions/Administration/SettingsPage/terminologyViewGridActions';
import {jsxActions as editTerminologyDialogActions} from '../../../../actions/Administration/SettingsPage/Dialogs/editTerminologyDialogActions';
import TerminologyView from '../../../../components/Administration/SettingsPage/Terminology/terminologyView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(terminologyViewActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch),
      terminologyViewGridActions: bindActionCreators(terminologyViewGridActions, dispatch),
      editTerminologyDialogActions : bindActionCreators(editTerminologyDialogActions, dispatch),
    }
  }
}

let TerminologyViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_SETTINGS_TERMINOLOGY);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sEditTerminologyDialogData: state.administration.getIn(['settingsPage', 'editTerminologyDialog']),
      sData: state.administration.getIn(['settingsPage', 'terminologyView']),
      sLoggedInUser: state.login.get('loggedInUser'),
      // isDirty: isDirty('cssSettingsImportForm')(state) || isDirty('cssSettingsExportForm')(state),
    };
  },

  mapDispatchToProps
)(TerminologyView);

export default TerminologyViewContainer;

import * as locationService from '../../../services/Locations/locationsService';
import Network from 'infrastructure/js/modules/network';
import { api as locationPageApi } from '../locationPageActions';
import { api as gridApi} from './activitiesGridActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ACTIVITIESVIEW_FETCHCHARTS_FINISHED:   'LOCATIONPAGE_ACTIVITIESVIEW_FETCHCHARTS_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchChartsFinished: function(payload) {
    return {type: actionTypes.ACTIVITIESVIEW_FETCHCHARTS_FINISHED, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.reload = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    api.init(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};
/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }

    return api.fetchCharts(dispatch, getState)();
  }
};

api.fetchCharts = function(dispatch, getState) {
  return function() {

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    return locationService.fetchActivityCharts(locationId)
      .then((response) => {

        if (!Network.isResponseValid(response)) {
          console.error("Fetch Chart Failed");
          return {success: false};
        }

        dispatch(actions.fetchChartsFinished({activitiesTodayCount: response.data ? response.data.data : 0, success: true}));
      });
  }
};



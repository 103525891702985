import React from 'react';
import PropTypes from 'prop-types';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import { Panel } from 'react-bootstrap';
import { NotificationsStatuses } from '../../../../mat/js/enums/notificationsStatuses';
import { Link } from 'react-router-dom';
import {getPathByType} from '../../../../mat/js/components/Common/Layout/Link/link';

require('./panel.scss');

export default class PL_Panel extends React.PureComponent{

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.wizards.notification.');
  }

  renderTitle = (titleClass , icon , title) => {
    return (
      <div className={titleClass}>{this.renderIcon(icon)} {title ? title : ''}</div>
    );
  }

  handleSecondaryTitleClickAction = (action) => {

    if(!action) return null;

    let Ids = this.props.config.data
      .filter((obj) => ( obj.props.item.status === NotificationsStatuses.UNREAD))
      .map(   (obj) => obj.props.item.id);

    let requestData = {
      ids : Ids,
      status: NotificationsStatuses.READ
    };

    action(requestData);
  }

  renderSecondaryTitle = (secondaryTitleClass , action, icon , title) => {
    return (
      <div className={secondaryTitleClass}>
             <label onClick={this.handleSecondaryTitleClickAction.bind(this, action)}>{this.renderIcon(icon)} {title}</label>
      </div>
    );
  }

  renderIcon(icon){
   if (!icon)
     return null;

    return <label className={`pl ${icon}`}/>;
  }

  isDisable = () => {

    if(!this.props.config.data) {
      return 'disable';
    }

    let arr =  this.props.config.data.map(obj => {
      return obj.props.item.status;
    });
    return arr.includes(NotificationsStatuses.UNREAD) ? '' : 'disable';
  }

  renderHeader = () => {
    if(!this.props.config.header)
      return null;
    return (
      <div className='header-wrapper'>
        {this.renderTitle('header-title', this.props.config.header.icon , this.props.config.header.title)}
        {this.renderSecondaryTitle(`header-secondary-title ${this.isDisable()}` , this.props.config.header.action , null, this.props.config.header.secondaryTitle)}
      </div>
    );
  }

  renderFooter = () => {
    if(!this.props.config.footer)
      return null;
    return (
      <Link to={getPathByType(this.props.config.footer.navigateTo , null)} >
        <div className='footer-wrapper' onClick={this.handleSecondaryTitleClickAction.bind(this, this.props.config.footer.action)}>
          {this.renderTitle('footer-title', this.props.config.footer.icon , this.props.config.footer.title)}
          {this.renderSecondaryTitle('footer-secondary-title' , null , this.props.config.footer.secondaryIcon, this.props.config.footer.secondaryTitle)}
        </div>
      </Link>
    );
  }

  renderContent = () => {
    if(this.props.config.data.length === 0){
      return (<div className='system-placeholder'>{this.labels.get('no.notifications.title')}</div>)
    }
    return (
      <div className='panel-content'>
        {this.props.config.data}
        {this.getLoadingOverlay()}
      </div>
    );
  };

 getLoadingOverlay() {
     if (!this.props.config.loading) {
       return null;
     }
     return <Overlay.Loading />;
  }

  renderPanelBody () {
    return (
      <div>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    );
  }

  handleMouseDown (event) {
    event.preventDefault();
    event.stopPropagation();
  }


  render() {
    return (
      <Panel className={this.props.config.panelClass} collapsible expanded={this.props.config.expanded} onMouseDown={this.handleMouseDown.bind(this)}>
        {this.renderPanelBody()}
      </Panel>

    );
  }
}

PL_Panel.propTypes = {
  config: PropTypes.object.isRequired
};

import { List, fromJS } from 'immutable';
import { actionTypes as gridActionTypes } from './Utils/gridActionsHelper';

let defaultState = {
};

let defaultNamedGridState = {
  gridName: null,
  filterConfig: [],
  columnsConfig: [],
  rows: null,
  filteredRowsCount: 0,
  filtersData: null,
  selectedRowsData: List(),
  filterState: List(),
  sortState: {},
  loading: false,
  error: false,
  queryResultInfo: null,
  abortController: new AbortController()
};

export default function(state = fromJS(defaultState), action) {
  let gridName = action.payload ? action.payload.gridName : null;
  if (!gridName) {
    return state;
  }

  // Prepare default named grid state for current action if needed.
  if (!state.get(gridName)) {
    if(action.payload.defaultState){
      defaultNamedGridState = {...defaultNamedGridState, ...action.payload.defaultState};
    }
    state = state.set(gridName, fromJS(defaultNamedGridState));
  }

  switch(action.type) {

    case gridActionTypes.GRID_FETCH_DATA_FINISHED:
      state = state.setIn([gridName, 'selectedRowsData'], List());
      state = state.setIn([gridName, 'filteredRowsCount'], action.payload.filteredRowsCount);
      state = state.setIn([gridName, 'filterState'], List(action.payload.filterModel));
      state = state.setIn([gridName, 'sortState'], action.payload.sortModel);
      state = state.setIn([gridName, 'rows'], List(action.payload.rows));
      state = state.setIn([gridName, 'filtersData'], List(action.payload.filtersData));
      state = state.setIn([gridName, 'queryResultInfo'], action.payload.queryResultInfo);
      state = state.setIn([gridName, 'loading'], false);
      state = state.setIn([gridName, 'abortController'], null);
      return state;

    case gridActionTypes.GRID_SELECTED_ROWS_CHANGED:
      return state.setIn([gridName, 'selectedRowsData'], List(action.payload.selectedRows));

    case gridActionTypes.GRID_FETCH_DATA_IN_PROGRESS:
      state = state.setIn([gridName, 'error'], false);
      state = state.setIn([gridName, 'abortController'], action.payload.abortController);
      return state.setIn([gridName, 'loading'], true);

    case gridActionTypes.GRID_FETCH_DATA_ERROR:
      state = state.setIn([gridName, 'loading'], false);
      state = state.setIn([gridName, 'abortController'], null);
      return state.setIn([gridName, 'error'], true);

    case gridActionTypes.GRID_INIT :
      const abortController = state.getIn([gridName, 'abortController'])
      state = state.set(gridName, fromJS(defaultNamedGridState));
      let defaultSort = _getDefaultSort(action.payload.columnsConfig);
      if (defaultSort) {
        state = state.setIn([gridName, 'sortState'], defaultSort);
      }
      if (action.payload.rows) {
        state = state.setIn([gridName, 'rows'], List(action.payload.rows));
      }

      return state
          .setIn([gridName, 'gridName'], action.payload.gridName)
          .setIn([gridName, 'filterConfig'], action.payload.filterConfig)
          .setIn([gridName, 'columnsConfig'], action.payload.columnsConfig)
          .setIn([gridName, 'abortController'], abortController);

    case gridActionTypes.GRID_CLEAR_FILTERS :
      return state
          .setIn([gridName, 'filterState'], action.payload.filterState);

    case gridActionTypes.GRID_UNMOUNT:
      return state.set(gridName, undefined);

    case gridActionTypes.GRID_UPDATE_ROW_DATA:
      state = state.setIn([gridName, 'rows'],action.payload.rows);
      return state;

    case gridActionTypes.GRID_SET_FILTER_STATE: {
      return state.setIn([gridName, 'filterState'], action.payload.filterState);
    }

    case gridActionTypes.GRID_SET_BUSY: {
      return state.setIn([gridName, 'loading'], action.payload.isBusy);
    }

    default:
      return state;
  }
}

let _getDefaultSort = function (columnsConfig) {
  let defaultSort = {};

  columnsConfig.map(columnConfig => {
    if (columnConfig.filterName && columnConfig.columnOptions && columnConfig.columnOptions.sort) {
      defaultSort = { filterName: columnConfig.filterName, direction: columnConfig.columnOptions.sort}
    }
  });

  return defaultSort;
}















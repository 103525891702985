import React from 'react';
import PropTypes from 'prop-types';
import GenerateButton from 'infrastructure/js/components/controls/GenerateButton/generateButton';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import AfpCreel from '../../SubComponents/AfpCreel/afpCreel';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./selectComponentAssetsStep.scss');

export default class SelectComponentAssetsStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');

    props.registerOnEnter(() => {
      this.props.setNextButtonEnabled(this.isNextButtonEnabled());
    });

    props.registerOnExit(() => {
      this.onExit();
    });

    this.afpRef = React.createRef();
  }

  componentDidMount() {
    this.generateId();
  }

  isNextButtonEnabled = () => {
    return true;
  }

  onExit = () => {
    this.afpRef?.current?.stopWaiting?.();
  }

  generateIdResponseHandler = (response) => {
    if (response?.data?.data) {
      this.props.change('layerId', response.data.data);
    }
  };

  generateId = () => this.props.actions.generateLayerId().then(this.generateIdResponseHandler);

  render() {
    const {labels} = this.props;
    return (
      <div className="select-component-assets-step">
        <InputSection label={labels.get('selectComponentAssetsStep.layerId')+'*'} htmlFor="layerId" className="inline left-side no-margin">
          <GenerateButton id="layerId" name="layerId" placeholder={labels.get('selectComponentAssetsStep.layerIdPlaceholder')}
                          validate={this.props.isCurrentStep ? Validation.required : undefined}
                          onClick={this.generateId}
                          buttonText={this.dialogLabels.get('generate')}
          />
        </InputSection>

        <InputSection label={labels.get('selectComponentAssetsStep.assignSpools')} className="">
          <AfpCreel labels={this.props.labels} actions={this.props.actions}
                    visible={this.props.isCurrentStep}
                    change={this.props.change}
                    ref={this.afpRef}
          />
        </InputSection>
      </div>
    );
  }
}

SelectComponentAssetsStep.propTypes = {
  labels: PropTypes.object.isRequired
}

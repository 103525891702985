import Network from 'infrastructure/js/modules/network';
import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import * as filtersParametersService from '../../../services/Rfid/filtersParametersService';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DEVICE_APPS_IN_PROCESS: 'ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DEVICE_APPS_IN_PROCESS',
  ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DEVICE_APPS_READY: 'ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DEVICE_APPS_READY',
  ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_DEVICE_APP_CHANGED: 'ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_DEVICE_APP_CHANGED',
  ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_UPDATE_IN_PROGRESS: 'ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_UPDATE_IN_PROGRESS',
  ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_UPDATE_FINISHED: 'ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_UPDATE_FINISHED',
  ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DATA_FINISHED: 'ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DATA_FINISHED',
  ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DATA_IN_PROGRESS: 'ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DATA_IN_PROGRESS'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  fetchDeviceAppsInProgress: function () {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DEVICE_APPS_IN_PROCESS};
  },
  fetchDeviceAppsFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DEVICE_APPS_READY, payload};
  },
  deviceAppChanged: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_DEVICE_APP_CHANGED, payload};
  },
  updateInProgress: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_UPDATE_IN_PROGRESS, payload: payload};
  },
  updateFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_UPDATE_FINISHED, payload: payload};
  },
  fetchDataFinished: function (payload) {
    return {
      type: actionTypes.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DATA_FINISHED,
      payload: payload
    };
  },
  fetchDataInProgress: function (payload) {
    return {
      type: actionTypes.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DATA_IN_PROGRESS,
      payload: payload
    };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    api.load(dispatch, getState)();
  }
};
jsxActions.onDeviceAppChanged = function (data) {
  return function (dispatch, getState) {
    api.deviceAppChanged(dispatch, getState)(data);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function (dispatch, getState) {
  return function () {

    dispatch(actions.fetchDeviceAppsInProgress());

    return deviceAppsService.fetchDeviceApps().then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return;
      }

      let deviceAppsOptions = response.dataList.map((obj) => {
        return {value: obj.id, label: obj.username}
      });
      dispatch(actions.fetchDeviceAppsFinished(deviceAppsOptions));
    });
  }
};

api.fetchData = function (dispatch, getState) {
  return function () {

    dispatch(actions.fetchDataInProgress());
    let selectedDeviceApp = getState().administration.getIn(['rfidSettingsPage', 'workflowsFiltersViewData', 'selectedDeviceApp']);
    if (!selectedDeviceApp || !selectedDeviceApp.label) {
      return;
    }

    let queryData = {
      deviceOrgBusinessId: getState().login.get('loggedInUser').loggedInOrg.businessId,
      deviceBusinessId: selectedDeviceApp.label
    }

    return deviceAppsService.getDeviceProfile(queryData).then((res) => {
      filtersParametersService.fetchWorkflowsFiltersData(res.data.profileId).then((response) => {

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          return;
        }
        dispatch(actions.fetchDataFinished(response));
      });
    });
  }
};

api.deviceAppChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.deviceAppChanged(data));
    api.fetchData(dispatch, getState)();
  }
};

api.update = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.updateInProgress());
    let id = data.id;
    delete data.id;
    filtersParametersService.updateWorkflowsFilters(data, id).then((response) => {
      dispatch(actions.updateFinished());

      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return;
      }

    return api.fetchData(dispatch, getState)();
    });
  };
};

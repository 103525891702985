import React from 'react';

import classNames from 'classnames';
import PL_ComboBox from 'infrastructure/js/components/controls/Combobox/combobox.js'

require('./multiSelectField.scss');

export default class PL_MultiSelectField extends React.PureComponent {

  dropdownIndicatorRenderer = (props) => {
    return null;
  };

  render() {
    return (
      <PL_ComboBox
                  isMulti={true}
                  {...this.props}
                  dropdownIndicatorRenderer={this.dropdownIndicatorRenderer}
                  className={classNames('pl-multi-select-field', this.props.className)}
      />
    )
  }
}
PL_MultiSelectField.defaultProps = {
};
PL_MultiSelectField.propTypes = {
};

import userManagementService from '../../../services/UserManagement/UserService';
import { api as userManagementDialogApi }         from '../../Dialogs/userManagementDialogActions';
import UserService from '../../../services/UserManagement/UserService';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import {createLabelHelper} from "../../../../../infrastructure/js/utils/labelHelper";
import {getEnumValue, enumTypes} from '../../../utils/enumHelper.js';
import {isSysAdmin} from "../../../utils/userHelper.js";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_USER_MANAGEMENT, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return  getState().system.get('featureFlags').ORGANIZATIONS ? userManagementService.getSystemUsersData(query): userManagementService.getUsersData(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let userStatuslabels = createLabelHelper('mat.usermanagement.');
  let loggedInUser = JSON.parse(localStorage['loggedInUser']);
  let items = rows.map((obj) => {
    obj.m_status = createLabelHelper('').get(obj.status.labelKey);
    obj.m_roles = obj.roles.map((role) => {
      return userStatuslabels.get(role.labelKey);
    });
    let requireSysAdmin = obj.roles.some(role => role.name === getEnumValue(enumTypes.USER_ROLE)('ROLE_SYSADMIN'));
    obj.m_hasEditPermissions = !requireSysAdmin || isSysAdmin(loggedInUser);
    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

jsxActions.showEditUser = function(user) {
  return function (dispatch, getState) {
    api.showEditUser(user, UserService.update)(dispatch, getState);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
api.showEditUser = function(user) {
  return function (dispatch, getState) {
    let updateAction = getState().system.get('featureFlags').ORGANIZATIONS ? UserService.updateSystemUser : UserService.update;
    userManagementDialogApi.show(user, updateAction)(dispatch, getState);
  }
};

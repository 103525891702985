import * as groupsService from '../../services/Groups/groupsService';
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ACTIVE_GROUPS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return groupsService.fetchActiveGroups(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds){
  let containers = rows.map((obj, index) => {
    if(obj.expirationDate) {
      obj.m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds);
    }
    obj.dueDateLeft = DateTimeHelper.GetDifferenceInDays(obj.workOrder && obj.workOrder.dueDate ? obj.workOrder.dueDate : 0, serverTimeSeconds);
    return obj;
  });

  return containers;
};


export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import Network from 'infrastructure/js/modules/network';
import {api as dashboardTabGridActions} from './dashboardTabGridActions';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ORGANIZATIONS_DASHBOARD_TAB_UPDATE_FILTER_DATA: 'ORGANIZATIONS_DASHBOARD_TAB_UPDATE_FILTER_DATA'

};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  updateFilterData:function (payload) {
    return {type: actionTypes.ORGANIZATIONS_DASHBOARD_TAB_UPDATE_FILTER_DATA, payload};
  },

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


// jsxActions.reload = function(data) {
//   return function(dispatch, getState) {
//     api.reload(dispatch, getState)(data);
//   }
// };

jsxActions.submit = function(data) {
  return function(dispatch, getState) {
    api.submit(dispatch, getState)(data);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


// api.reload = function(dispatch, getState) {
//   return function(localeId) {
//     dashboardTabGridActions.reload(dispatch, getState)();
//   }
// };

api.submit = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.updateFilterData(data));

    dashboardTabGridActions.reload(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

import React from 'react';
import PropTypes from 'prop-types';

import MultiEntitiesMultiSelect from '../../../../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect.js';
import Label from 'infrastructure/js/components/Label/label';

require('./assetsDemoldingSection.scss');


export default class AssetsDemoldingSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;

    this.hasPreSelectedWo = !!this.props.workOrder;

    // this.state = {
    //   dummyKey: 0,
    // };
  }

  // componentWillReceiveProps(nextProps) { //moved to render(): key
  //   if (nextProps.workOrder != this.props.workOrder) {
  //     this.setState({
  //       dummyKey: this.state.dummyKey === 0 ? 1 : 0,
  //     });
  //   }
  // }

  getReduxFieldName = (fieldName) => {
    return this.props.name + '.' + fieldName;
  };

  demoldingValueRenderer  = (value) => {
    if (!value || !value.data || !value.data.attachedAsset) {
      return null;
    }
    let text  = value.data.attachedAsset.businessId;
    return (
      <Label className='column custom-data' text={text}  />);
  };

  render = () => {
    let {name, fetchConfig, preselectedAssets, entitiesTypes, itemIndex, ...otherProps} = this.props;
    let focusProps = (itemIndex === 0 && this.hasPreSelectedWo) ? {autoFocus: true} : {};

    return(
      //{/*<div className="selection-rows">*/}
      <div className="demolding-section">
        <label>{this.labels.get('relatedassets')}</label>
        <MultiEntitiesMultiSelect
          id={this.getReduxFieldName('reportedAssets')}
          name={this.getReduxFieldName('reportedAssets')}
          entitiesTypes={entitiesTypes}
          // key={this.state.dummyKey}
          key={this.props.workOrder?.businessId || '' }
          preSelectedEntities={preselectedAssets}
          fetchConfig={fetchConfig}
          disableOnPreselect={false}
          customValueRenderer={this.demoldingValueRenderer}
          {...focusProps}
          { ...otherProps }/>
      </div>
    )

  };
}

AssetsDemoldingSection.defaultProps = {
  entitiesTypes: [],
};

AssetsDemoldingSection.propTypes = {
  workOrder : PropTypes.object,
  entitiesTypes: PropTypes.array,
  fetchConfig: PropTypes.object.isRequired,
};





import Network from 'infrastructure/js/modules/network';


export function fetchRollsCountByRemnant(query, isRemnant) {
  query.remnant = isRemnant;
  return Network.post('inventorydashboard/rollsCountByRemnant', query);
}
export function fetchRollsTotalLength(query) {
  return Network.post('inventorydashboard/rollsTotalLength', query);
}

export function fetchAverageMaterialLengthLength(query) {
  return Network.post('inventorydashboard/usage/averageMaterialLength', query);
}

export function fetchAverageConsumptionTime(query) {
  return Network.post('inventorydashboard/usage/averageConsumptionTime', query);
}

export function fetchAverageCarryingAmount(query) {
  return Network.post('inventorydashboard/usage/averageCarryingAmount', query);
}

export function fetchInventoryLocations() {
  return Network.get('inventorydashboard/locations');
}
export function fetchInventoryMaterials() {
  return Network.get('inventorydashboard/materials');
}

export function fetchInventoryTotalLengthPerMaterialChart(query) {
  return Network.post('inventorydashboard/totalLengthPerMaterial', query);
}
export function fetchInventoryRollsWithErrorsStatisticsChart(query) {
  return Network.post('inventorydashboard/rollsWithErrorsStatistics', query);
}
export function fetchInventoryArchivedRollsChart(query) {
  return Network.post('inventorydashboard/archiveAlertedRolls', query);
}

export function fetchInventoryMaterialUsageChart(query) {
  return Network.post('inventorydashboard/usage/materialUsage', query);
}
export function fetchInventoryConsumptionTimeChart(query) {
  return Network.post('inventorydashboard/usage/consumptionTime', query);
}

export function fetchToolsInLocationByStatusChart(query) {
  return Network.post('tooldashboard/toolsInLocationsByStatus', query);
}

export function fetchAverageCapacityUtilizationChart(query) {
  return Network.post('tooldashboard/capacityUtilizationAndDefects', query);
}

export function fetchToolsAverageCapacityUtilizationKpi(query) {
  return Network.post('tooldashboard/averageCapacityUtilization', query);
}
export function fetchToolsMttrKpi(query) {
  return Network.post('tooldashboard/mttr', query);
}

export function fetchHeaderData() {
  return Network.get('dashboard/filters');
}

export function fetchProductionChartData(data) {
  return Network.post('dashboard/overview', data);
}

export function fetchProgressChartData(data) {
  let categoryTypeId = data.categoryTypeId;
  delete data.categoryTypeId;
  return Network.post(`dashboard/kittype/${categoryTypeId}`, data);
  // return Network.post(`dashboard/kittype/700`, data);
}

export function fetchToolMaintenanceDemoData() {
  return Network.get('dashboard/demo/toolMaintenance');
}

export function fetchInventoryUsageDemoData() {
  return Network.get('dashboard/demo/inventoryUsage');
}

export function fetchInventoryDemoData() {
  return Network.get('dashboard/demo/inventory');
}

export function fetchProductionChartDemoData() {
  return Network.get('dashboard/demo/production');
}

export function fetchProgressChartDemoData() {
  return Network.get('dashboard/demo/woprogress');
}

export function fetchCorrelationsDemoData() {
  return Network.get('dashboard/demo/correlations');
}

export function fetchDashboardsDemoDataSettings() {
  return Network.get('dashboard/demo/settings');
}

export function editDashboardDemoDataSettings(data) {
  return Network.post('dashboard/demo/settings/edit', data);
}

export function uploadDashboardDemoData(file, data) {
  return Network.postFile('dashboard/demo/upload', file, data);
}

export function deleteDashboardDemoData(data) {
  return Network.post('dashboard/demo/delete/dashboard', data);
}

export function removeDashboardsDemoData() {
  return Network.delete('dashboard/demo/delete');
}

export function fetchCorrelationsData(data) {
  let categoryTypeId = data.categoryTypeId;
  delete data.categoryTypeId;
  return Network.post(`dashboard/defects/kittype/${categoryTypeId}`, data);
}

export function getCauseData(query) {
  return new Promise((resolve, reject) => {

    let response = _createDummyResponse();
    setTimeout(function() {
      resolve(response);
    }, 500);
  });
}

//////////////////////////////////////////////////

function _fetchDummyHeaderData() {
  return new Promise((resolve, reject) => {

    let headerData = _createDummyHeaderData();

    setTimeout(function() {
      resolve({data: {...headerData}});
      }, 500);
  });
}

function _fetchDummyProductionChartData(data) {

  return new Promise((resolve, reject) => {

    Network.get('kittypes').then((response) => {
      let dummyData = _createDummyCategoryChartData(response.dataList);
      // let dummyData = [];
      let categories = response.dataList;

      let chartData = dummyData.map((item, index) => {
        if (categories.length > index) {
          item.name = categories[index].businessId;
          item.id = categories[index].id;
        }
        return item;
      });

      setTimeout(function() {
        resolve({data: [...chartData]});
      }, 500);
    });

  });
}

function _createDummyHeaderData() {
  let timePeriodOptions = [
    {label: 'Today', value: 'TODAY'},
    {label: 'Yesterday', value: 'YESTERDAY'},
    {label: 'This month', value: 'THIS_MONTH'},
    {label: 'Last month', value: 'LAST_MONTH'},
    {label: 'Full Quarter', value: 'FULL_QUARTER'},
    {label: 'Half year', value: 'HALF_YEAR'},
    {label: 'Full year', value: 'FULL_YEAR'},
  ];

  let compareToOptions = [
    {label: 'Annual average', value: 'ANNUAL_AVERAGE'},
    {label: 'Same period, last year', value: 'SAME_PERIOD_LAST_YEAR'},
  ];



  let categoryOptions = [
    {label: 'Kit', value: 700},
    // {label: 'Parts', value: 2, isDisabled: true},
    {label: 'Part', value: 2},
  ];

  let causeOptions = [
    {label: 'Tools', value: 'TOOLS'},
    {label: 'Operations', value: 'OPERATIONS'},
    {label: 'Location', value: 'LOCATIONS'},
  ];

  let headerData = {timePeriodOptions, compareToOptions, categoryOptions, causeOptions};

  return headerData;
}

function _createDummyCategoryChartData() {

  let chartData = [
    {
      name: 'Part AAAAA BBBBB CCCCCC',
      id: 11,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 600,
      actual: 555,
      amt: null
    },
    {
      name: 'Part B',
      id: 22,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 400,
      actual: 300,
      amt: null
    },
    {
      name: 'Part C',
      id: 33,
      defectRatePercentage: '30%',
      defectRate: '(700/2100)',
      planned: 200,
      actual: null,
      amt: 389
    },
    {
      name: 'Part D',
      id: 44,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 100,
      actual: null,
      amt: 222
    },
    {
      name: 'Part E',
      id: 55,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 410,
      actual: 280,
      amt: null
    },
    {
      name: 'Part F',
      id: 66,
      defectRatePercentage: '1%',
      defectRate: '(1/100)',
      planned: 380,
      actual: null,
      amt: 470
    },
    {
      name: 'Kit Type A',
      id: 88,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 510,
      actual: 480,
      amt: null
    },
    {
      name: 'Vertical Stabiliser',
      id: 99,
      defectRatePercentage: '5%',
      defectRate: '(2/48)',
      planned: 180,
      actual: null,
      amt: 270
    },
    {
      name: 'Propeller',
      id: 100,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 310,
      actual: 280,
      amt: null
    },
    {
      name: 'Slats 123456789 123456789 123456789',
      id: 101,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 110,
      actual: 80,
      amt: null
    },
    {
      name: 'Turbine Engine',
      id: 102,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 210,
      actual: 180,
      amt: null
    },
    {
      name: 'Engine Cowling',
      id: 222,
      defectRatePercentage: '10%',
      defectRate: '(12/48)',
      planned: 400,
      actual: 300,
      amt: null
    },
    {
      name: 'Kit Type CCC 1234567 999999 3333333333',
      id: 333,
      defectRatePercentage: '30%',
      defectRate: '(700/2100)',
      planned: 200,
      actual: null,
      amt: 389
    },
    // {
    //   name: 'Kit Type DDD',
    //   id: 444,
    //   defectRatePercentage: '10%',
    //   defectRate: '(12/48)',
    //   planned: 100,
    //   actual: null,
    //   amt: 222
    // },
    // {
    //   name: 'Kit Type EEE',
    //   id: 555,
    //   defectRatePercentage: '10%',
    //   defectRate: '(12/48)',
    //   planned: 410,
    //   actual: 280,
    //   amt: null
    // },
  ];
  return chartData;
}

function _createDummyTypeChartData() {
  let chartData = [
    {
      name: 'Planned',
      id: 1,
      cutting: 2,
      kitting: 3,
      defrosting: 4,
      inspection: 1,
      curing: 2,
      demolding: 3,
      layup: 1
    },
    {
      name: 'WO-11111111',
      id: 11,
      cutting: 2,
      kitting: 4,
      defrosting: 1,
      inspection: 1,
      curing: 2,
      demolding: null,
      layup: 1
    },
    {
      name: 'WO-22222222222222',
      id: 22,
      cutting: 3,
      kitting: 4,
      defrosting: 2,
      inspection: null,
      curing: 3,
      demolding: 5,
      layup: null
    },
    {
      name: 'WO-3333333333333333',
      id: 33,
      cutting: null,
      kitting: 4,
      defrosting: null,
      inspection: null,
      curing: 3,
      demolding: 1,
      layup: 2
    },
    {
      name: 'WO-4444444444444444',
      id: 44,
      cutting: null,
      kitting: null,
      defrosting: 2,
      inspection: null,
      curing: 3,
      demolding: 5,
      layup: null
    },
    {
      name: 'WO-5555555555',
      id: 55,
      cutting: 3,
      kitting: 4,
      defrosting: null,
      inspection: null,
      curing: 3,
      demolding: 5,
      layup: null
    },
    {
      name: 'WO-6666666666666 777 8888 999999 123',
      id: 66,
      cutting: null,
      kitting: null,
      defrosting: 2,
      inspection: 1,
      curing: 3,
      demolding: 5,
      layup: null
    },
    {
      name: 'WO-77777777777777',
      id: 77,
      cutting: 3,
      kitting: 4,
      defrosting: 2,
      inspection: null,
      curing: null,
      demolding: 5,
      layup: null
    },
    {
      name: 'WO-888888888888',
      id: 88,
      cutting: 3,
      kitting: 4,
      defrosting: 2,
      inspection: null,
      curing: 3,
      demolding: null,
      layup: null
    },
    {
      name: 'WO-999999999999',
      id: 99,
      cutting: 1,
      kitting: 2,
      defrosting: 3,
      inspection: null,
      curing: 4,
      demolding: null,
      layup: 2
    },
    {
      name: 'WO-101010101010',
      id: 1010,
      cutting: 3,
      kitting: 4,
      defrosting: 2,
      inspection: null,
      curing: null,
      demolding: 2,
      layup: 1
    },
    ];

  return {data: [...chartData]};
}

function _createDummyResponse() {

  let res = {
    applicationResponseStatus: {
      statusType: 'OK'
    },

    dataList: [
      {
        causeId: 'Tool1',
        defectsNum: 123,
        defectsRate: '34%',
        total: '333'
      },
      {
        causeId: 'Tool2',
        defectsNum: 34009,
        defectsRate: '2%',
        total: '2342'
      },
      {
        causeId: 'Tool3',
        defectsNum: 230339,
        defectsRate: '1%',
        total: '456564'
      },
      {
        causeId: 'Tool4',
        defectsNum: 2,
        defectsRate: '12%',
        total: '88'
      }
    ],

    queryResultInfo: {
      first: true,
      last: true,
      number: 0,
      numberOfElements: 38,
      size: 100,
      totalElements: 38,
      totalPages: 1,
      totalUnfiltered: 0
    }
  };

  return res;
}

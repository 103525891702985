import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { BryntumScheduler } from '@bryntum/scheduler-react';
import { DateHelper, LocaleManager, LocaleHelper, Popup } from '@bryntum/scheduler';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import { getSystemColors } from 'infrastructure/js/utils/colorHelper';
import { getPatternStyle } from 'infrastructure/js/components/DynamicIcons/TaskStarted.js';
import { getPdfExportServersPath } from 'infrastructure/js/utils/appHelper';
import { createLabelHelper } from '../../utils/labelHelper';
import { navigationStates } from '../../../../mat/js/enums/navigationStates';
import Link from '../../../../mat/js/components/Common/Layout/Link/link';
import Header, { headerItemsLabels } from '../../../../mat/js/components/Common/Header/header';
import { getWeekStartDayIndex, getWeekHorizonStart } from '../../../../mat/js/components/Common/Helpers/SchedulerHelper';
import { enumTypes, getEnumValue } from '../../../../mat/js/utils/enumHelper';
import SchedulerKPI from './Components/SchedulerKPI/schedulerKPI';
import { PL_DialogWrapper } from '../Dialog/dialog';
import PrintPDFDialog from './Dialogs/PrintPDFDialog/printPDFDialog';
import { pdfInitConfig } from './Configs/PDFExport';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import ScheduleAction from '../../../../mat/js/modules/scheduleAction';

const priorityImg = require('svg/high-priority-task-icon.svg?url');

require('./scheduler.scss');

export const schedulerDragData = {
  DRAG_DATA_TASK_ID: 'datatransfertaskid',
  DRAG_DATA_TOOL_ID: 'datatransfertoolid',
};

const oneDayInMilliseconds = 86400000; //1000 * 60 * 60 * 24;
const SCHEDULER_ROW_HEIGHT_DEFAULT = 24; //25px in GANTT: 24px row content + 1px row border

export default class Scheduler extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.scheduler.gantt.');
    this.weekStartDayIndex = getWeekStartDayIndex();
    this.weekHorizonStart = getWeekHorizonStart();
    this.columns = this.getColumns();
    this.presets = this.getCustomPresets();
    this.listeners = this.getListeners();

    this.state = {
      schedulerInstanceReady: false,
      schedulerRerenderer: 0,
      currentKPIsTimeRange: '',
      currentHorizonName: this.presets[1].name,
      currentHorizonIndex: 1,
      currentHorizonTimeRange: '',
      showPrintPDFDialog: false,
      fullscreenMode: false,
    };
  }

  getCustomPresets = () => {
    let windowWidth = Math.max(window.innerWidth, 1280);
    let schWidth = windowWidth - 300;

    return [
      {
        id: 'pl_two_weeks',
        base: 'hourAndDay',
        name: this.labels.get('horizon.twoWeeks'),
        tickWidth: Math.floor(schWidth / 14),
        shiftIncrement: 2,
        shiftUnit: 'week',
        defaultSpan: 15,
        mainHeaderLevel: 0,
        headers: [
          {
            unit: 'W',
            increment: 2,
            renderer: function (start, end, headerConfig, index) {
              let startDate = DateHelper.format(start, DateTimeHelper.getDateFormat());
              let endDate = DateHelper.format(DateHelper.getEndOfPreviousDay(end), DateTimeHelper.getDateFormat());
              return `${startDate} - ${endDate}`;
            },
          },
          {
            unit: 'day',
            increment: 1,
            dateFormat: this.getDayFormat(),
          },
        ],
      },
      {
        id: 'pl_one_week',
        base: 'hourAndDay',
        name: this.labels.get('horizon.oneWeek'),
        tickWidth: Math.floor(schWidth / 7),
        shiftIncrement: 1,
        shiftUnit: 'week',
        defaultSpan: 15,
        mainHeaderLevel: 0,
        headers: [
          {
            unit: 'W',
            increment: 1,
            renderer: function (start, end, headerConfig, index) {
              let startDate = DateHelper.format(start, DateTimeHelper.getDateFormat());
              let endDate = DateHelper.format(DateHelper.getEndOfPreviousDay(end), DateTimeHelper.getDateFormat());
              return `${startDate} - ${endDate}`;
            },
          },
          {
            unit: 'day',
            increment: 1,
            dateFormat: 'ddd ' + this.getDayFormat(),
          },
        ],
      },
      {
        id: 'pl_three_days',
        base: 'hourAndDay',
        name: this.labels.get('horizon.threeDays'),
        tickWidth: Math.floor(schWidth / 9),
        shiftIncrement: 3,
        shiftUnit: 'day',
        defaultSpan: 105,
        mainHeaderLevel: 0,
        headers: [
          {
            unit: 'day',
            increment: 3,
            renderer: function (start, end, headerConfig, index) {
              let startDate = DateHelper.format(start, DateTimeHelper.getDateFormat());
              let endDate = DateHelper.format(DateHelper.getEndOfPreviousDay(end), DateTimeHelper.getDateFormat());
              return `${startDate} - ${endDate}`;
            },
          },
          {
            unit: 'hour',
            increment: 8,
            dateFormat: 'HH:mm ddd ' + this.getDayFormat(),
          },
        ],
      },
      {
        id: 'pl_one_day',
        base: 'hourAndDay',
        name: this.labels.get('horizon.oneDay'),
        tickWidth: Math.floor(schWidth / 12),
        shiftIncrement: 1,
        shiftUnit: 'day',
        defaultSpan: 105,
        mainHeaderLevel: 0,
        headers: [
          {
            unit: 'day',
            increment: 1,
            dateFormat: DateTimeHelper.getDateFormat(),
          },
          {
            unit: 'hour',
            increment: 2,
            dateFormat: 'HH:mm',
          },
        ],
      },
    ];
  };

  getDayFormat() {
    let format = DateTimeHelper.getDateFormat();
    switch (format) {
      case 'DD/MM/YYYY':
        return 'DD/MM';
      case 'M/D/YYYY':
      case 'M/D/YY':
        return 'M/D';

      case 'YY/MM/DD':
      case 'MM/DD/YYYY':
      case 'MM/DD/YY':
        return 'MM/DD';

      case 'DD-MMM-YYYY':
      case 'DD-MM-YYYY':
      case 'YYYY-MM-DD':
        return 'DD MMM';
    }
    console.error('Unknown Date format: ' + format);
    return 'DD MMM';
  }

  getColumns = () => [
    {
      headerRenderer: this.columnHeaderRenderer,
      renderer: this.columnRenderer,
      field: 'name',
      width: 165,
      editor: false,
      totalUtl: '',
    },
  ];

  getListeners = () => ({
    horizontalScroll: this.onHorizontalScroll,
    eventDblClick: this.showEventInfoPopup,

    beforePdfExport: ({ config }) => {
      const { rows, client } = config;
      if (rows.length) {
        const ids = rows.map(({ value }) => value);
        client.resourceStore.filter(({ id }) => ids.includes(id));
      }
    },
    pdfExport: ({ response, source: scheduler }) => {
      scheduler.resourceStore.clearFilters();
    },
  });

  componentDidMount() {
    let locale = DateTimeHelper.getUserOrgLocale();

    if (locale === 'en') {
      this.applyLocale('En', 'English');
    } else if (locale === 'de') {
      this.applyLocale('De', 'German');
    } else if (locale === 'zh-cn') {
      this.applyLocale('Zh', 'Chinese');
    }

    // detect enter/exit fullscreen mode
    document.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
  }

  updateSchedulerStore = async () => {
    if (this.exportStarted) {
      return null;
    }

    const scheduler = this.schedulerRef?.schedulerInstance;

    if (scheduler) {
      // rehydrate scheduler state after render to fix freeze on task drag.
      await scheduler?.assignmentStore?.project?.commitAsync?.();
      // update resource column header
      const newColumnsData = [...scheduler.columns.data];
      newColumnsData[0].totalUtl = this.props.data.totalUtl;
      scheduler.columns.data = newColumnsData;

      scheduler.refresh();
    }
  };

  componentDidUpdate() {
    this.updateSchedulerStore();
  }

  applyLocale = (localeName, localeDesc) => {
    let locale = {
      localeName,
      localeDesc,
      DateHelper: {
        locale: localeName,
        weekStartDay: this.weekStartDayIndex,
      },
      Dependencies: {
        from: this.labels.get('dependency.from'),
        to: this.labels.get('dependency.to'),
      },
    };

    LocaleHelper.publishLocale(localeName, { desc: localeDesc, locale });

    LocaleManager.applyLocale(localeName);
  };

  onAfterEventDrop = (event) => {
    this.schedulerRef.schedulerInstance.selectedEvents = []; //Remove the selection of the dragged event.
    this.props.onTaskMove(event);
  };
  onBeforeEventDelete = (event) => {
    this.props.onTaskDelete(event);
  };
  onSelectionChanged = (event) => {
    let selectedEvents = event.selection.map((selectedEvent) => {
      return selectedEvent.data;
    });
    this.props.onSelectionChanged(selectedEvents);
  };
  onEventClick = (event) => {
    this.props.onSelectionChanged([event.eventRecord.data]);
  };
  onBeforeEventDrag = (event) => {
    if(this.state.fullscreenMode) return false;

    this.props.actions.onBeforeTaskMove(event);
    if (this._shouldPreventMove(event)) {
      return false;
    }
    if (this.schedulerRef && this.schedulerRef.schedulerInstance) {
      this.schedulerRef.schedulerInstance.features.eventDrag.constrainDragToResource = event.eventRecord.isLockedForResource;
    }
  };

  _shouldPreventMove(event) {
    if (
      (event.eventRecord && event.eventRecord.locked) ||
      event.eventRecord.operationStatus === 'COMPLETED' ||
      event.eventRecord.operationStatus === 'STARTED'
    ) {
      return true;
    }
  }

  onDragEnter = (e) => {
    if (this.isDragDataValid(e)) {
      e.preventDefault();
    }
  };

  onDragOver = (e) => {
    if (this.isDragDataValid(e)) {
      e.preventDefault();
    }
  };

  onDrop = (e) => {
    if (!this.isDragDataValid(e)) {
      return;
    }

    let taskId = e.dataTransfer.getData(schedulerDragData.DRAG_DATA_TASK_ID);
    let toolId = e.dataTransfer.getData(schedulerDragData.DRAG_DATA_TOOL_ID) ?? null;

    let schedulerInstance = this.schedulerRef?.schedulerInstance;
    if (!schedulerInstance) {
      return;
    }

    let date = schedulerInstance.getDateFromDomEvent(e.nativeEvent, 'round');

    let resource = schedulerInstance.resolveResourceRecord(e.nativeEvent);
    if (!resource || !resource.data || !resource.data.id) {
      return;
    }

    e.preventDefault();

    let momentDate = date ? moment(date, DateTimeHelper.getDateFormat(), DateTimeHelper.getUserOrgLocale(), true).utcOffset(0, true) : null;

    let data = {
      startTime: DateTimeHelper.ConvertFromDate(momentDate),
      resourceId: resource.data.id,
      toolId: toolId ? Number(toolId) : null,
    };
    this.props.onTaskDrop(taskId, data);
  };

  isDragDataValid = (e) => {
    return e.dataTransfer.types.includes(schedulerDragData.DRAG_DATA_TASK_ID);
  };

  setSchedulerRef = (r) => {
    this.schedulerRef = r;
    this.setState({ schedulerInstanceReady: !!this.schedulerRef });

    if (this.schedulerRef) {
      if (this.props.setSchedulerEngineInstance) {
        this.props.setSchedulerEngineInstance(this.schedulerRef.schedulerInstance);
      }

      const { schedulerInstance } = this.schedulerRef;

      //check in system setting if the WeekHorizonStart setting is not 'today' and check if zoom level is 'week' or 'two weeks'.
      if (this.weekHorizonStart !== 'today' && [0, 1].includes(schedulerInstance.zoomLevel))
        //initialize the scheduler from first day of the current week.
        schedulerInstance.scrollToDate(DateTimeHelper.getDateByWeekDay(this.weekStartDayIndex, new Date()), {
          block: 'start',
          edgeOffset: 50, // px.
          animate: false,
        });
    }
  };

  HorizonSelect = function (props) {
    return (
      <div className="horizon-select">
        <select value={props.value} onChange={props.actions}>
          <option value="3">{props.labels.get('select.horizon.oneDay')}</option>
          <option value="2">{props.labels.get('select.horizon.threeDays')}</option>
          <option value="1">{props.labels.get('select.horizon.oneWeek')}</option>
          <option value="0">{props.labels.get('select.horizon.twoWeeks')}</option>
        </select>
      </div>
    );
  };

  handleUndoClick = () => {
    const {
      backtrackList: { undoItems },
    } = this.props;
    this.props.actions.undo(undoItems);
  };

  handleRedoClick = () => {
    const {
      backtrackList: { redoItems },
    } = this.props;
    this.props.actions.redo(redoItems);
  };

  getHeaderItems() {
    let { onScrollToNow } = this.props.actions;
    const backtrackList = this.props.backtrackList;
    const { printPdfServer } = getPdfExportServersPath();

    return {
      buttons: [
        {
          id: 'printButton',
          icon: 'pl pl-print',
          className: 'gantt-header-button',
          tooltip: headerItemsLabels.PRINT_GANTT,
          tooltipContainer: this,
          disabled: !printPdfServer || this.exportStarted,
          action: () => {
            this.togglePrintDialog(true);
          },
        },
        ...(!this.state.fullscreenMode ? [        
          {
            id: 'undoButton',
            tooltip: headerItemsLabels.UNDO,
            tooltipContainer: this,
            icon: 'fa fa-undo',
            className: 'gantt-header-button',
            disabled: !backtrackList.undoItems.length,
            action: this.handleUndoClick,
          },
          {
            id: 'redoButton',
            tooltip: headerItemsLabels.REDO,
            tooltipContainer: this,
            icon: 'fa fa-repeat',
            className: 'gantt-header-button',
            disabled: !backtrackList.redoItems.length,
            action: this.handleRedoClick,
          },
        ] : []),
        {
          id: 'todayButton',
          label: headerItemsLabels.TODAY,
          className: 'no-icon',
          disabled: !this.state.schedulerInstanceReady,
          action: onScrollToNow,
          actionData: this.schedulerRef?.schedulerInstance,
        },
        {
          id: 'horizonSelectButton',
          schedulerInstance: this.schedulerRef?.schedulerInstance,
          value: this.state.currentHorizonIndex,
          labels: this.labels,
          actions: this.onHorizonChange,
          buttonComponent: this.HorizonSelect,
        },
        {
          id: 'shiftPrevious',
          icon: 'pl pl-arrow-left',
          tooltip: headerItemsLabels.PREVIOUS,
          tooltipContainer: this,
          className: 'gantt-header-button',
          disabled: !this.state.schedulerInstanceReady,
          action: this.shiftPrevious,
          actionData: this.schedulerRef?.schedulerInstance,
        },
        {
          id: 'shiftNext',
          icon: 'pl pl-arrow-right',
          tooltip: headerItemsLabels.NEXT,
          tooltipContainer: this,
          className: 'gantt-header-button',
          disabled: !this.state.schedulerInstanceReady,
          action: this.shiftNext,
          actionData: this.schedulerRef?.schedulerInstance,
        },
        {
          id: 'zoomInButton',
          icon: 'pl pl-zoom-in',
          tooltip: headerItemsLabels.ZOOM_IN,
          tooltipContainer: this,
          className: 'gantt-header-button',
          disabled: !(
            this.state.schedulerInstanceReady && this.schedulerRef.schedulerInstance.zoomLevel < this.schedulerRef.schedulerInstance.maxZoomLevel
          ),
          action: this.zoomIn,
          actionData: this.schedulerRef?.schedulerInstance,
        },
        {
          id: 'zoomOutButton',
          icon: 'pl pl-zoom-out',
          tooltip: headerItemsLabels.ZOOM_OUT,
          tooltipContainer: this,
          className: 'gantt-header-button',
          disabled: !(
            this.state.schedulerInstanceReady && this.schedulerRef.schedulerInstance.zoomLevel > this.schedulerRef.schedulerInstance.minZoomLevel
          ),
          action: this.zoomOut,
          actionData: this.schedulerRef?.schedulerInstance,
        },
        {
          id: 'fullScreen',
          icon: 'pl pl-maximize',
          tooltip: this.state.fullscreenMode ? 'exitFullScreen' : 'showFullScreen',
          tooltipContainer: this,
          className: 'gantt-header-button',
          action: this.toggleFullScreen,
        },
      ],
    };
  }

  shiftPrevious = () => {
    if (this.schedulerRef?.schedulerInstance) {
      this.schedulerRef?.schedulerInstance.shiftPrevious();

      this.updateKPIsTimeRange();
    }
  };

  shiftNext = () => {
    if (this.schedulerRef?.schedulerInstance) {
      this.schedulerRef?.schedulerInstance.shiftNext();

      this.updateKPIsTimeRange();
    }
  };

  zoomIn = () => {
    let schedulerInstance = this.schedulerRef?.schedulerInstance;
    if (schedulerInstance && schedulerInstance.zoomLevel < schedulerInstance.maxZoomLevel) {
      let newZoomLevel = schedulerInstance.zoomLevel + 1;
      schedulerInstance.zoomLevel = newZoomLevel;

      this.setState({ currentHorizonIndex: newZoomLevel }, () => this.updateUI());
    }
  };

  zoomOut = () => {
    let schedulerInstance = this.schedulerRef?.schedulerInstance;

    if (schedulerInstance && schedulerInstance.minZoomLevel < schedulerInstance.zoomLevel) {
      let newZoomLevel = schedulerInstance.zoomLevel - 1;
      schedulerInstance.zoomLevel = newZoomLevel;

      this.setState({ currentHorizonIndex: newZoomLevel }, () => this.updateUI());
    }
  };

  resetScheduler = () => {
    const newData = { ...this.props.data };
    this.props.actions.resetSchedulerData(newData, this?.schedulerRef?.schedulerInstance);
  };

  //NOTE: Filtering for the tasks - currently not supported
  // filterEventsHandler = (event) => {
  //   if (this.schedulerRef) {
  //     let scheduler = this.schedulerRef.schedulerInstance;
  //     let value = event.target.value;
  //
  //     value = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  //
  //     // Replace all previous filters and set a new filter
  //     scheduler.eventStore.filter({
  //       filters : event => event.name.match(new RegExp(value, 'i')),
  //       replace : true
  //     });
  //
  //   }
  // }

  validateEventDrag = ({ startDate, eventRecords, newResource }) => {
    if (eventRecords?.[0].data?.isLockedForResource) {
      //currently no validation for non-operational task
      return {
        valid: true,
        message: '',
      };
    }
    let draggedOperationId = eventRecords?.[0].data?.operation?.id || '';
    let newResourceId = newResource?.data?.id || '';
    if (draggedOperationId && newResourceId) {
      let foundResource = this.props.data?.allResources?.find((r) => newResourceId === r.resourceId);
      if (foundResource?.operationsIds?.includes(draggedOperationId)) {
        //NOTE: currently there is no support for 'Start date in the past'
        // if (startDate?.getTime() < new Date().getTime()) {
        //   // console.log('----------------', startDate?.getTime(), new Date().getTime())
        //   return {
        //     valid: true,
        //     message: 'Start date in the past - ' + moment(startDate).format(DateTimeHelper.getDateTimeFormat())
        //   }
        // }
        return {
          valid: true,
          message: '',
        };
      }
    }
    let labelObj = {
      operationName: eventRecords?.[0].data?.operation?.businessId || '',
      taskName: eventRecords?.[0].data?.name || '',
      resourceName: newResource?.data?.businessId || '',
    };

    return {
      valid: false,
      message: this.labels.get('task.drag.validation', '', labelObj),
    };
  };

  eventRenderer = (data) => {
    let itemData = data?.eventRecord?.data;
    let renderData = data?.renderData;

    let fontSize = Math.floor(renderData?.height - 8);
    let style = `font-size:${fontSize}px`;
    let iconStyle = `height: ${fontSize}px;`;

    let eventIcon;
    if (itemData.valid) {
      eventIcon =
        itemData?.priority === 'High'
          ? `<img src="${priorityImg}" alt="Priority Icon" style="${iconStyle}"/>`
          : `<div class="b-sch-event-icon ${itemData.eventIcon}"></div>`;
    } else {
      eventIcon = `<div class="b-sch-event-icon ${itemData.eventIcon}"></div>`;
    }

    return `<div class="b-sch-event-custom" style="${style}">
         ${eventIcon}
          <div class="b-sch-event-body">${itemData.name}</div>
        </div>`;
  };

  columnRenderer = ({ value, record }) => {
    return (
      <div>
        <Link label={value} id={record.id} type={navigationStates.LOCATION_TASKS} />
        <span>{` (${record.utl})`}</span>
      </div>
    );
  };

  columnHeaderRenderer = (data) => {
    const totalUtl = data.column?.data?.totalUtl;
    let resourcesHeader = this.labels.get('column.stations');
    return `<div>
        <div class="column-header-resource">${resourcesHeader}</div>
        <div class="column-header-utl">
            <span class="column-header-utl-value">${totalUtl ?? ''}%</span>
            <span>${this.labels.get('column.totalUtl')}</span>
            </div>
      </div>`;
  };

  contextMenuItemRenderer = (icon, text) => {
    return `<div class='custom-menu-item'>${icon}<span>${text}</span></div>`;
  };

  showEventInfoPopup = ({ source: scheduler, eventRecord, eventElement }) => {
    const infoPopup =
      scheduler.infoPopup ||
      (scheduler.infoPopup = new Popup({
        cls: 'info-popup',
        closable: true,
        focusOnToFront: false,
      }));

    infoPopup.title = eventRecord.name;
    infoPopup.html = this.props.getTooltipTemplate({ eventRecord });
    infoPopup.showBy(eventElement);
  };

  getSchedulerConfig = () => {
    const { started, completed } = getSystemColors();
    const style = getPatternStyle(started).css;

    const TaskStartedIcon = `<div class='scheduler-menu-icon' style="${style}"></div>`;
    const TaskCompletedIcon = `<div class='scheduler-menu-icon' style="background-color:${completed}"></div>`;

    return {
      rowHeight: SCHEDULER_ROW_HEIGHT_DEFAULT,
      eventStyle: 'border',
      eventRenderer: this.eventRenderer,
      eventTooltipFeature: { disabled: true },
      eventEditFeature: { disabled: true },
      startDate: this.props.data.timeDefinition.startDate,
      barMargin: 0,
      timeResolution: 1,
      viewPreset: 'pl_one_week',
      presets: this.presets,
      zoomKeepsOriginalTimespan: true, //TODO: L probably to use it?
      stickyHeaders: false,
      // forceFit: true,
      // suppressFit: true,
      highlightSuccessors: true,
      highlightPredecessors: true,

      createEventOnDblClick: false,
      dependencyEditFeature: false,
      dependenciesFeature: { allowCreate: false },
      columns: this.columns,
      onBeforeEventDrag: this.onBeforeEventDrag,
      onAfterEventDrop: this.onAfterEventDrop,
      onBeforeEventDelete: this.onBeforeEventDelete,
      onEventSelectionChange: this.onSelectionChanged,
      onEventClick: this.onEventClick,
      listeners: this.listeners,
      resourceTimeRangesFeature: true,
      resourceTimeRanges: this.props.data.resourceTimeRanges,
      timeRangesFeature: {
        showHeaderElements: true,
        showCurrentTimeLine: true,
        currentDateFormat: DateTimeHelper.getTimeFormat(),
      },
      eventDragCreateFeature: false,
      eventDragFeature: {
        validatorFn: this.validateEventDrag,
      },
      pdfExportFeature: pdfInitConfig,
      eventResizeFeature: false,
      sortFeature: false,
      // grid menus
      headerMenuFeature: false,
      cellMenuFeature: false,
      // scheduler menus
      timeAxisHeaderMenuFeature: false,
      scheduleMenuFeature: false,
      eventMenuFeature: {
        processItems: ({ eventRecord, items }) => {
          if(this.state.fullscreenMode) return false;
          
          items.deleteEvent.text = eventRecord.data.labels.get('task.unschedule');
          items.deleteEvent.disabled = eventRecord.data.locked;

          items.lockUnlock.text = eventRecord.data.locked ? eventRecord.data.labels.get('task.unlock') : eventRecord.data.labels.get('task.lock');
          items.lockUnlock.icon = eventRecord.data.locked ? 'b-icon b-fa-unlock' : 'b-icon b-fa-lock';

          if (eventRecord.operationStatus === 'COMPLETED' || eventRecord.operationStatus === 'STARTED') {
            items.deleteEvent.disabled = true;
            items.lockUnlock.disabled = true;
            items.markAsStarted.disabled = true;
            items.startedAsPlanned.disabled = true;
          }

          if (eventRecord.operationStatus === 'COMPLETED') {
            items.markAsCompleted.disabled = true;
            items.completedAsPlanned.disabled = true;
          }
        },
        items: {
          lockUnlock: {
            weight: 100,
            onItem: ({ eventRecord }) => (eventRecord.data.locked ? this.props.onTaskUnlock(eventRecord) : this.props.onTaskLock(eventRecord)),
          },
          showInfo: {
            text: this.labels.get('task.showInfo'),
            icon: 'b-fa-circle-info',
            onItem: this.showEventInfoPopup,
          },
          markAsStarted: {
            cls: 'custom-context-menu-item',
            content: this.contextMenuItemRenderer(TaskStartedIcon, this.labels.get('task.markAsStarted')),
            onItem: ({ eventRecord }) =>
              this.props.actions.reportTaskProgress({
                taskData: eventRecord.data,
                status: getEnumValue(enumTypes.TASK_STATUS)('STARTED'),
                isReportAsPlanned: false,
              }),
          },
          startedAsPlanned: {
            cls: 'custom-context-menu-item',
            content: this.contextMenuItemRenderer(TaskStartedIcon, this.labels.get('task.startedAsPlanned')),
            onItem: ({ eventRecord }) =>
              this.props.actions.reportTaskProgress({
                taskData: eventRecord.data,
                status: getEnumValue(enumTypes.TASK_STATUS)('STARTED'),
                isReportAsPlanned: true,
              }),
          },
          markAsCompleted: {
            cls: 'custom-context-menu-item',
            content: this.contextMenuItemRenderer(TaskCompletedIcon, this.labels.get('task.markAsCompleted')),
            onItem: ({ eventRecord }) =>
              this.props.actions.reportTaskProgress({
                taskData: eventRecord.data,
                status: getEnumValue(enumTypes.TASK_STATUS)('COMPLETED'),
                isReportAsPlanned: false,
              }),
          },
          completedAsPlanned: {
            cls: 'custom-context-menu-item',
            content: this.contextMenuItemRenderer(TaskCompletedIcon, this.labels.get('task.completedAsPlanned')),
            onItem: ({ eventRecord }) =>
              this.props.actions.reportTaskProgress({
                taskData: eventRecord.data,
                status: getEnumValue(enumTypes.TASK_STATUS)('COMPLETED'),
                isReportAsPlanned: true,
              }),
          },
          unassignEvent: false,
          copyEvent: false,
          cutEvent: false,
        },
      },
    };
  };

  onHorizontalScroll = (event) => {
    this.updateKPIsTimeRange();
  };

  onHorizonChange = (event) => {
    if (this.schedulerRef?.schedulerInstance) {
      let newZoomLevel = +event.target.value;
      this.schedulerRef.schedulerInstance.zoomLevel = newZoomLevel;

      this.setState({ currentHorizonIndex: newZoomLevel }, () => this.updateUI());
    }
  };

  updateUI() {
    this.updateKPIsTimeRange();
    this.updateCurrentHorizonName();
    // this.updateCurrentHorizonTimeRange();  //TODO : L - implement it
  }

  updateCurrentHorizonName = () => {
    let zoomLevel = this.state.currentHorizonIndex;
    let preset = this.presets[zoomLevel];
    let presetName = preset?.name || '';
    setTimeout(() => {
      this.setState({ currentHorizonName: presetName });
    }, 0);
  };

  updateKPIsTimeRange = () => {
    if (this.schedulerRef?.schedulerInstance) {
      // let base = new Date(this.props.data.timeDefinition.startDate);
      // let value = DateHelper.floor(this.schedulerRef?.schedulerInstance?.viewportCenterDate, '3 days', base);
      // console.log('------base = ' + base + ' -------value = ' + value);
      // let value = DateHelper.floor(this.schedulerRef?.schedulerInstance?.viewportCenterDate, '1 day');

      let value = DateHelper.floor(this.schedulerRef?.schedulerInstance?.viewportCenterDate, oneDayInMilliseconds);

      let firstDateOfWeek = this.getFirstDateOfWeek(value, this.weekStartDayIndex);
      let formattedStart = DateHelper.format(firstDateOfWeek, DateTimeHelper.getDateFormat());

      let lastDateOfWeek = this.getLastDateOfWeek(value, this.weekStartDayIndex);
      let formattedEnd = DateHelper.format(lastDateOfWeek, DateTimeHelper.getDateFormat());

      let horizon = this.labels.get('kpi.horizon.oneWeek');
      let currentKPIsTimeRange = `${formattedStart} - ${formattedEnd} ${horizon}`;

      if (__DEV__) {
        let weekNumber = DateHelper.format(value, 'W');
        currentKPIsTimeRange += ' (' + weekNumber + ')';
      }

      if (this.state.currentKPIsTimeRange !== currentKPIsTimeRange) {
        setTimeout(() => {
          this.setState({ currentKPIsTimeRange: currentKPIsTimeRange });
        }, 0);
      }
    }
  };

  getWeekNumber() {
    if (this.schedulerRef?.schedulerInstance) {
      let date = DateHelper.floor(this.schedulerRef?.schedulerInstance?.viewportCenterDate, oneDayInMilliseconds);
      if (date && !isNaN(date.getTime())) {
        //if valid date
        let [year, weekNumber] = DateHelper.getWeekNumber(date, this.weekStartDayIndex);
        return weekNumber;
      }
      console.error('scheduler.getWeekNumber(): date = ', date);
    }

    return null;
  }

  getFirstDateOfWeek = (dateObject, firstDayOfWeekIndex) => {
    const dayOfWeek = dateObject.getDay();
    let firstDayOfWeek = new Date(dateObject);
    let diff = dayOfWeek >= firstDayOfWeekIndex ? dayOfWeek - firstDayOfWeekIndex : 6 - (firstDayOfWeekIndex - dayOfWeek - 1);

    firstDayOfWeek.setDate(dateObject.getDate() - diff);
    firstDayOfWeek.setHours(0, 0, 0, 0);

    return firstDayOfWeek;
  };

  getLastDateOfWeek = (dateObject, firstDayOfWeekIndex) => {
    const dayOfWeek = dateObject.getDay();
    let lastDayOfWeek = new Date(dateObject);
    let diff = dayOfWeek >= firstDayOfWeekIndex ? 6 - dayOfWeek + firstDayOfWeekIndex : firstDayOfWeekIndex - dayOfWeek - 1;

    lastDayOfWeek.setDate(dateObject.getDate() + diff);
    lastDayOfWeek.setHours(0, 0, 0, 0);

    return lastDayOfWeek;
  };

  getKPIsData() {
    let weekNumber = this.getWeekNumber();
    if (weekNumber !== null) {
      let { weeks } = this.props.data.metrics;
      let kpis = weeks?.[weekNumber];
      return kpis || null;
    }
    return null;
  }

  renderTitlesSection() {
    return (
      <div className="titles-section">
        <div className="gantt-title">{this.labels.get('header.title')}</div>
      </div>
    );
  }

  renderKPIsSection() {
    let items = this.getKPIsData();
    if (!items) {
      return null;
    }

    let KPIs = items.map((item, index) => {
      return <SchedulerKPI key={index} data={item} />;
    });

    return (
      <div className="scheduler-kpis-section">
        <div className="kpis-time-range">{this.state.currentKPIsTimeRange}</div>
        <div className="kpis-container">{KPIs}</div>
      </div>
    );
  }

  onPrintPDF = (data) => {
    const { schedulerInstance: scheduler } = this.schedulerRef;
    const { exportStations, exportFrom, exportTo } = data;

    this.exportStarted = true;
    const exportConfig = {};

    exportConfig.rows = exportStations;

    if (exportFrom && exportTo) {
      exportConfig.scheduleRange = 'daterange';
      exportConfig.rangeStart = exportFrom.startOf('day').toDate();
      exportConfig.rangeEnd = exportTo.endOf('day').toDate();
    }

    scheduler.features.pdfExport
      .export(exportConfig)
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => {
        this.exportStarted = false;
        this.schedulerRef.schedulerInstance.zoomLevel = this.originalSchedulerZoom;
        this.forceUpdate(); //refresh the UI to release the print button.
      });
  };

  togglePrintDialog = (show) => {
    const { schedulerInstance: scheduler = {} } = this.schedulerRef;
    //update only when opening the dialog.
    if (show) {
      this.originalSchedulerZoom = scheduler.zoomLevel;
    }
    this.setState({
      showPrintPDFDialog: show,
    });
  };

  updateSchedulerZoom = (value) => {
    const { schedulerInstance: scheduler } = this.schedulerRef;
    scheduler.zoomLevel = value ?? this.originalSchedulerZoom;
  };

  getCurrentViewTimeRange = () => {
    try {
      // Check if the schedulerRef is null
      if (!this.schedulerRef) {
        // Return an object with startDate and endDate set to null
        return { startDate: null, endDate: null };
      }

      // Extract the scheduler instance from the schedulerRef
      const { schedulerInstance: scheduler } = this.schedulerRef;

      // Get the startDate and endDate from the scheduler's visibleDateRange property
      const { startDate, endDate } = scheduler.visibleDateRange;

      // Return an object with startDate and endDate set to moment objects
      return { startDate: moment(startDate), endDate: moment(endDate) };
    } catch (ex) {
      // Log the error to the console
      console.error(ex);

      // Return an object with startDate and endDate set to null
      return { startDate: null, endDate: null };
    }
  };

  componentWillUnmount() {
    this.refreshTimer?.kill();

    // Remove fullscreen mode even listener
    document.removeEventListener('fullscreenchange', this.fullScreenChangeHandler);
  }

  // Handle cases where exit fullscreen mode is initiated by the browser's built in buttons / esc key
  fullScreenChangeHandler = () => {
    const isExitingFullscreen = !document.fullscreenElement;
    if (isExitingFullscreen && document.exitFullscreen) {
      this.setState({ fullscreenMode: false });
      this.refreshTimer?.kill();
    }
  };

  toggleFullScreen = () => (document.fullscreenElement ? this.exitFullscreen() : this.enterFullscreen());

  enterFullscreen = async () => {
    const ganttRefreshRate = PermissionManager.getOrgPreferences().refreshRateGanttTvMode;
    try {
      await this.schedulerWrapperRef?.requestFullscreen();
      this.setState({ fullscreenMode: true });
      if (ganttRefreshRate > 0) {
        this.refreshTimer = ScheduleAction('gantt refresh timer', this.reloadPage, ganttRefreshRate);
      }
    } catch (err) {
      console.log('Failed to request gantt fullscreen mode ', err);
    }
  };

  exitFullscreen = async () => {
    try {
      await document.exitFullscreen();
      this.setState({ fullscreenMode: false });
      this.refreshTimer?.kill();
    } catch (err) {
      console.log('Failed to request gantt exitFullscreen mode ', err);
    }
  };

  reloadPage = () => {
    this.props.refreshPage({}, true);
  };

  setSchedulerWrapperRef = (r) => {
    this.schedulerWrapperRef = r;
  };

  getFullscreenModeLoadingOverlay() {
    if (this.props.loading && this.state.fullscreenMode) {
      return <Overlay.Loading />;
    }

    return null;
  }

  render() {
    if (!this.props?.data) {
      return null;
    }

    const { events = [], resources = [], selectedDependencies = [] } = this.props.data;
    const { showPrintPDFDialog, tempResources } = this.state;

    let headerItems = this.getHeaderItems();

    return (
      <div ref={this.setSchedulerWrapperRef} className={cn('pl-scheduler', { 'full-screen-mode': this.state.fullscreenMode })}>
        <div className="pl-scheduler-header">
          {this.renderTitlesSection()}
          {this.renderKPIsSection()}
          <Header {...headerItems} />
        </div>

        <div className="pl-scheduler-container" onDragEnter={this.onDragEnter} onDragOver={this.onDragOver} onDrop={this.onDrop}>
          <BryntumScheduler
            key={this.state.schedulerRerenderer}
            ref={this.setSchedulerRef}
            events={events}
            resources={tempResources ?? resources}
            dependencies={selectedDependencies}
            {...this.getSchedulerConfig()}
            autoHeight={true}
          />
        </div>

        {this.getFullscreenModeLoadingOverlay()}

        <PL_DialogWrapper
          dialogComponent={PrintPDFDialog}
          show={showPrintPDFDialog}
          stations={resources}
          resolution={this.state.currentHorizonIndex}
          onPrintPDF={this.onPrintPDF}
          onDialogToggle={this.togglePrintDialog}
          updateSchedulerZoom={this.updateSchedulerZoom}
          getCurrentViewTimes={this.getCurrentViewTimeRange}
        />
      </div>
    );
  }
}

//NOTE: currently there is no support for recurring timeRanges and resourceTimeRanges
// class MyTimeRange extends RecurringTimeSpan(TimeSpan) {}
//
// class MyTimeRangeStore extends RecurringTimeSpansMixin(Store) {
//   static get defaultConfig() {
//     return {
//       // use our new MyTimeRange model
//       modelClass : MyTimeRange,
//       storeId    : 'timeRanges'
//     };
//   }
//
//   construct(config) {
//     super.construct(config, true);
//   }
// }
//
// const myTimeRangeStore = new MyTimeRangeStore()
//
//
//
// class MyResourceTimeRange extends RecurringTimeSpan(ResourceTimeRangeModel) {}
//
// class MyResourceTimeRangeStore extends RecurringTimeSpansMixin(ResourceTimeRangeStore) {
//   static get defaultConfig() {
//     return {
//       // use our new MyResourceTimeRange model
//       modelClass : MyResourceTimeRange,
//       storeId    : 'resourceTimeRanges'
//     };
//   }
// }
//
// const myResourceTimeRangeStore = new MyResourceTimeRangeStore();

//NOTE: currently there is no support for recurring timeRanges and resourceTimeRanges
// let timeRanges = this.props.data.timeRanges;
// timeRanges.forEach( (item) => {
//   item.recurrenceRule = 'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR';
// } );
//
// let resourceTimeRanges = this.props.data.resourceTimeRanges;
// resourceTimeRanges.forEach( (item) => {
//   item.recurrenceRule = 'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR';
// } );

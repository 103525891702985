import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {getEnumValue, enumTypes} from './enumHelper';


export function GetAlertTypeMessageTitle(alert, alertDetails) {

  let labelHelper = createLabelHelper('alerts.');
  let predictedMessage = 'ai.alertedview.warning';
  let alertMessage = '';
  switch (alert.alertType) {
    case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
    if (alert.withPrediction && alert.severityType == 'WARNING') {
      alertMessage = labelHelper.get('ai.expirationindays.warningPrediction', undefined, {time: DateTimeHelper.ConvertMinutesToHoursMinutes(alertDetails.expirationDaysLeft * 24 * 60 - alert.predicatedKitProductionTimeInMinutes)});

      if (alertDetails.expirationDate && alertDetails.expirationDate.warningStateWithoutPrediction) {
        alertMessage = alertDetails.expirationDaysLeft === 0 ?
          labelHelper.get('expirationtoday') :
          labelHelper.get('expirationindays', undefined, {message: DateTimeHelper.ConvertDaysToRelativeTime(alertDetails.expirationDaysLeft)}).concat(' ', alertMessage);
      }
      break;
    }
    alertMessage = alertDetails.expirationDaysLeft === 0 ? labelHelper.get('willExpireToday') : DateTimeHelper.ConvertDaysToRelativeTime(alertDetails.expirationDaysLeft);
    break;
    case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
      let exposureTimeLeftBond = alertDetails.exposureTimeLeftBond ? alertDetails.exposureTimeLeftBond.value : null;
      if(alert.withPrediction && alert.severityType == 'WARNING') {
        alertMessage = labelHelper.get('ai.etlBond.warningPrediction', undefined, {time: DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftBond - alert.predicatedKitProductionTimeInMinutes )});

        if(alertDetails.exposureTimeLeftBond && alertDetails.exposureTimeLeftBond.warningStateWithoutPrediction){
          alertMessage = exposureTimeLeftBond === 0 ?
            labelHelper.get('etlNoHoursLeft')  :
            DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftBond).concat('. ', alertMessage);
        }
        break;
      }

      alertMessage = exposureTimeLeftBond === 0 ? labelHelper.get('etlNoHoursLeft') : DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftBond);
    break;
    case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
      let exposureTimeLeftCure = alertDetails.exposureTimeLeftCure ? alertDetails.exposureTimeLeftCure.value : null;
      if(alert.withPrediction && alert.severityType == 'WARNING') {
        alertMessage = labelHelper.get('ai.etlCure.warningPrediction', undefined, {time: DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftCure - alert.predicatedKitProductionTimeInMinutes )});

        if(alertDetails.exposureTimeLeftCure && alertDetails.exposureTimeLeftCure.warningStateWithoutPrediction){
          alertMessage = exposureTimeLeftCure === 0 ?
            labelHelper.get('etlNoHoursLeft')  :
            DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftCure).concat('. ', alertMessage);
        }
        break;
      }

      alertMessage = exposureTimeLeftCure === 0 ?  labelHelper.get('etlNoHoursLeft') : DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftCure);
    break;
    case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
      if(alert.withPrediction && alert.severityType == 'WARNING') {
        alertMessage = _GetPredictedAlertMessage(alertDetails.dueDateMinutesLeft - alert.predicatedKitProductionTimeInMinutes , 'ai.alertedview.warning.critical', predictedMessage);
        break;
      }
      alertMessage = alertDetails.woDueDaysLeft === 0 ? labelHelper.get('willExpireToday') : DateTimeHelper.ConvertDaysToRelativeTime(alertDetails.woDueDaysLeft);
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME'):
      alertMessage = DateTimeHelper.ConvertMinutesToHoursMinutes(alertDetails.defrostingTimeLeft);
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'):
      alertMessage = labelHelper.get('usageTimeLeft', undefined, {message: alertDetails.toolUsageTime});
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'):
      alertMessage = alertDetails?.locationName || '';
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'):
       alertMessage = alertDetails?.pickListBusinessId || '';
      break;
    default:
      console.error('invalid alert.alertType: ' + alert.alertType);
      break;
  }
  return alertMessage;
}

export function GetAlertMessage(alert, alertDetails) {
  let alertMessage = '';
  let labelHelper = createLabelHelper('alerts.');

  switch (alert.alertType) {
    case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
      if(alert.withPrediction && alert.severityType == 'WARNING') {
        alertMessage = _GetPredictedAlertMessage(alertDetails.expirationDaysLeft * 24 * 60 - alert.predicatedKitProductionTimeInMinutes , 'ai.expirationindays.warning.critical','ai.expirationindays.warning');

        if(alertDetails.expirationDate && alertDetails.expirationDate.warningStateWithoutPrediction){
          alertMessage = alertMessage.concat(' ' , alertDetails.expirationDaysLeft === 0 ?
            labelHelper.get('expirationtoday') :
            labelHelper.get('expirationindays', undefined, {message: DateTimeHelper.ConvertDaysToRelativeTime(alertDetails.expirationDaysLeft)}));
        }
        break;
      }
      alertMessage = alertDetails.expirationDaysLeft === 0 ? labelHelper.get('expirationtoday') :
        labelHelper.get('expirationindays', undefined, {message: DateTimeHelper.ConvertDaysToRelativeTime(alertDetails.expirationDaysLeft)});
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
      let exposureTimeLeftCure = alertDetails.exposureTimeLeftCure ? alertDetails.exposureTimeLeftCure.value : null;
      if (alert.withPrediction && alert.severityType == 'WARNING') {
        alertMessage = _GetPredictedAlertMessage(exposureTimeLeftCure - alert.predicatedKitProductionTimeInMinutes , 'ai.etlinhours.warning.critical.cure','ai.etlinhours.warning.cure');
        if(alertDetails.exposureTimeLeftCure && alertDetails.exposureTimeLeftCure.warningStateWithoutPrediction){
          alertMessage = alertMessage.concat(' ' , exposureTimeLeftCure === 0 ?
            labelHelper.get('etlnohoursleft.cure') :
            labelHelper.get('etlinhours.cure', undefined, {message: DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftCure)}));
        }
        break;
      }

      alertMessage = exposureTimeLeftCure === 0 ? labelHelper.get('etlnohoursleft.cure') : labelHelper.get('etlinhours.cure', undefined, {message: DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftCure)});
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
      let exposureTimeLeftBond = alertDetails.exposureTimeLeftBond ? alertDetails.exposureTimeLeftBond.value : null;
      if (alert.withPrediction && alert.severityType == 'WARNING') {
        alertMessage = _GetPredictedAlertMessage(exposureTimeLeftBond - alert.predicatedKitProductionTimeInMinutes , 'ai.etlinhours.warning.critical.bond','ai.etlinhours.warning.bond');
        if(alertDetails.exposureTimeLeftBond && alertDetails.exposureTimeLeftBond.warningStateWithoutPrediction){
          alertMessage = alertMessage.concat(' ' , exposureTimeLeftBond === 0 ?
            labelHelper.get('etlnohoursleft.bond')  :
            labelHelper.get('etlinhours.bond', undefined, {message: DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftBond)}));
        }
        break;
      }
      alertMessage = exposureTimeLeftBond === 0 ? labelHelper.get('etlnohoursleft.bond') : labelHelper.get('etlinhours.bond', undefined, {message: DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftBond)});
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
      if(isNaN(alertDetails.woDueDaysLeft)){
        break;
      }

      if (alert.withPrediction && alert.severityType == 'WARNING') {
        alertMessage = _GetPredictedAlertMessage(alertDetails.dueDateMinutesLeft - alert.predicatedKitProductionTimeInMinutes , 'ai.woexpirationindays.warning.critical','ai.woexpirationindays.warning');
        break;
      }
      alertMessage = alertDetails.woDueDaysLeft === 0 ? labelHelper.get('woexpirationindaystoday') :
                      labelHelper.get('woexpirationindays', undefined, {message: DateTimeHelper.ConvertDaysToRelativeTime(alertDetails.woDueDaysLeft)});

      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME'):
      alertMessage = labelHelper.get('assetDefrostingTime', undefined, {message: DateTimeHelper.ConvertMinutesToHoursMinutes(alertDetails.defrostingTimeLeft)});
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT'):
      alertMessage = labelHelper.get('cyclesCount', undefined, {message:alertDetails.cyclesCount});
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'):
      alertMessage = labelHelper.get('usageTimeLeft', undefined, {message: alertDetails.toolUsageTime});
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'):
      alertMessage = labelHelper.get('misplacedAsset', undefined, {message: alertDetails.locationName});
      break;
    case getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'):
      alertMessage = labelHelper.get('reservedPickList', undefined, {message: alertDetails.pickListBusinessId});
      break;
    default:
      console.error('invalid alert.alertType: ' + alert.alertType);
      break;
  }
  return alertMessage;
}



export function getAlertTypeDisplayName(alertType) {
  let labels = createLabelHelper('mat.alertedrolls.alert.type.');

  switch (alertType) {
    case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
      return labels.get('expiration.date.displayName');

    case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
      return labels.get('ExposureTimeBond.displayName');

    case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
      return labels.get('ExposureTimeCure.displayName');

    case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
      return labels.get('dueDate.displayName');

    case getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME'):
      return labels.get('defrostingTime.displayName');

    case getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'):
      return labels.get('misplacedAsset.displayName');

    case getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'):
      return labels.get('reserved.displayName');
    default:
      return 'NONE';
  }
}

function _GetPredictedAlertMessage(remainingTime, critical_title, title){
  let alertMessage = '';
  let labelHelper = createLabelHelper('alerts.');

  if (remainingTime <= 0)
    alertMessage = labelHelper.get(critical_title, undefined, {message: DateTimeHelper.ConvertMinutesToHoursMinutes(-remainingTime)});
  else
    alertMessage = labelHelper.get(title, undefined, {message: DateTimeHelper.ConvertMinutesToHoursMinutes(remainingTime)});

  return alertMessage;
}



import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as shiftTypesViewActions} from '../../../actions/Administration/MatSettingsPage/shiftTypesViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as shiftTypesViewGridActions} from '../../../actions/Administration/MatSettingsPage/shiftTypesViewGridActions';
import { jsxActions as createShiftTypeDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createShiftTypeDialogActions';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import ShiftTypesView from '../../../components/Administration/MatSettingsPage/ShiftTypesView/shiftTypesView.js';
import { gridsNames} from '../../../enums/gridsNames';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(shiftTypesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      shiftTypesViewGridActions: bindActionCreators(shiftTypesViewGridActions, dispatch),
      createShiftTypeDialogActions : bindActionCreators(createShiftTypeDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }
  }
}

let ShiftTypesViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_SHIFT_TYPES) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_SHIFT_TYPES) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateShiftTypeDialogData: state.administration.getIn(['matSettingsPage', 'createShiftTypeDialog']),
      sShiftTypesSettings: state.administration.getIn(['matSettingsPage', 'shiftTypesSettings']),
    };
  },
  mapDispatchToProps
)(ShiftTypesView);


export default ShiftTypesViewContainer;




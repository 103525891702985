import {createContext, useState, useContext} from 'react';

const ElementSelectionContext = createContext();

export function ElementSelectionProvider({children}){
  const [selected, setSelected] = useState([]);

  const value = {selected, setSelected}

  return (
    <ElementSelectionContext.Provider value={value}>
      {children}
    </ElementSelectionContext.Provider>
  )
}

export function useElementSelectionContext() {
  const context = useContext(ElementSelectionContext)
  if (context === undefined) {
    throw new Error('useElementSelectionContext must be used within an ElementSelectionProvider')
  }
  return context
}

import React from 'react';
import PropTypes from 'prop-types';

import {reduxForm} from 'redux-form';
import {List, Map} from 'immutable';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import Label from 'infrastructure/js/components/Label/label';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import {isRoll} from '../../../../utils/assetHelper';
import ReportOperationItem from './ReportOperationItem/reportOperationItem';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
import MaterialHelper from '../../../../utils/materialHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from '../../../../../../infrastructure/js/utils/uomHelper';
import {isAFPStation} from '../../../../utils/locationHelper';
require('./workOrderDialog.scss');


class WorkOrderDialog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.workorder.reportoperationdialog.');

    this.operationInfo = this.props.sData.get('operationInfo');

    this.entitiesTypes = this.initEntitiesTypes();
  }


  initEntitiesTypes = () => {
    switch (this.operationInfo.operationType) {
      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITHOUT_ASSETS_CONNECTION'):
        return [getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'), getEnumValue(enumTypes.OBJECT_TYPE)('KIT'), getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')];
      case getEnumValue(enumTypes.OPERATION_TYPE)('CURING'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('UNCURING'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('MERGE'):
        return [getEnumValue(enumTypes.OBJECT_TYPE)('KIT')];
      case getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING'):
        return isAFPStation(this.props.sLocationDetails) ? [getEnumValue(enumTypes.OBJECT_TYPE)('PART')] : [getEnumValue(enumTypes.OBJECT_TYPE)('KIT')]
      default:
        return [getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'), getEnumValue(enumTypes.OBJECT_TYPE)('KIT')];
    }
  };

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
        text: this.dialogLabels.get("cancel"),
        action: this.props.actions.hide
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get("footer.reportoperation"),
          bsStyle: "primary",
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
                    this.props.sData.get('hasError') ||
                    (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
        }
      ]
    };
  }

  getOperationStatusOptions = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.operationStatusOptions ? dialogData.operationStatusOptions : [];
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  ////////////////////////////////////////////////////////////////
  //  Submit
  ////////////////////////////////////////////////////////////////

  onSubmit = (formData) => {
    this.reportOperation(formData);
  };

  reportOperation = (formData) => {
    let reportData = this.createReportData(formData);

    let operations = Object.keys(formData).filter((item) => {return (item && item.indexOf('reportOperation') >= 0)});

    operations.forEach((op) => {
      let operationData = this.convertToOperationData(formData[op]);
      reportData.reportOperations.push(operationData);
    });

    let action = this.getActionByOperationType();
    if (action) {
      action(reportData, this.getMessageDialogBuilder());
    }
  };

  createReportData = (data) => {
    return {
      reportOperations: [],
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };
  };

  getActionByOperationType = () => {
    switch (this.operationInfo.operationType) {
      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITHOUT_ASSETS_CONNECTION'):
        return this.props.actions.reportOperationAssetsAssociation;
      case getEnumValue(enumTypes.OPERATION_TYPE)('CURING'):
        return this.props.actions.reportOperationCuring;
      case getEnumValue(enumTypes.OPERATION_TYPE)('UNCURING'):
        return this.props.actions.reportOperationUncuring;
      case getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING'):
        return this.props.actions.reportOperationDemolding;
      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITH_ASSETS_CONNECTION'):
        return this.props.actions.reportOperationAssetsRelation;
      case getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS'):
        return this.props.actions.reportOperationAssetsToAssets;
      case getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP'):
        return this.props.actions.reportOperationLayup;
      case getEnumValue(enumTypes.OPERATION_TYPE)('MERGE'):
        return this.props.actions.reportOperationMerge;
      case getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS_WITH_ROLL_CONSUMPTION'):
        return this.props.actions.reportOperationAssetsToAssetsWithConsumption;
      default:
        console.error('Unknown operation type: ' + this.operationInfo.operationType);
        return null;
    }
  };

  convertToOperationData = (formData) => {
    let operationData = this.retrieveCommonOperationData(formData);
    operationData = this.addSpecificOperationData(formData, operationData);
    return operationData;
  };

  retrieveCommonOperationData = (formData) => {
    return {
      operationId : this.operationInfo.operationId,
      workOrder : (formData.woCombobox && formData.woCombobox.assetsValue) ? formData.woCombobox.assetsValue.value : null,
      woBusinessId : (formData.woCombobox && formData.woCombobox.assetsValue) ? formData.woCombobox.assetsValue.label : null,
      operationStatus : formData.operationStatus ? formData.operationStatus.value : null,
      isCompleted : formData.woComplete || false,
      comment : formData.comments,
    };
  };

  addSpecificOperationData = (formData, operationData) => {
    switch (this.operationInfo.operationType) {
      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITHOUT_ASSETS_CONNECTION'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('UNCURING'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING'):
        operationData.assets = this.getAssets(formData);
        break;
      case getEnumValue(enumTypes.OPERATION_TYPE)('CURING'):
        operationData.assets = this.getAssets(formData);
        operationData.curingTime = formData.curingTime;
        break;

      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITH_ASSETS_CONNECTION'):
        operationData.assetsSource = formData['add-asset-selection'] ? formData['add-asset-selection'].assetsValue : [];
        operationData.assetsTarget = formData['to-asset-selection'] ? formData['to-asset-selection'].assetsValue : [];
        break;
      case getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP'):
        operationData.assetsSource = formData['add-asset-selection'] ? formData['add-asset-selection'].assetsValue : [];
        operationData.assetsTarget = formData['to-asset-selection'] ? [formData['to-asset-selection'].assetsValue] : [];
        operationData.isConsumed = formData.markAsConsumed;
        operationData.usingByAssetId = formData['using-asset-selection'] ? formData['using-asset-selection'].assetsValue : null;
        break;
      case getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS_WITH_ROLL_CONSUMPTION'):
        operationData.assetsSource = formData['add-asset-selection'] ? [formData['add-asset-selection'].assetsValue] : [];
        operationData.assetsTarget = formData['to-asset-selection'] ? [formData['to-asset-selection'].assetsValue] : [];
        if(PermissionManager.isWeightSupported()){
          operationData.weightLeft = UnitHelper.userValueToServerValue(unitTypes.WEIGHT, formData['asset-weight'], 2);
        }
        else{
          let length = formData['asset-length'];
          operationData.length =UnitHelper.userValueToServerValue(unitTypes.LENGTH, length, 2);
        }
        break;
      case getEnumValue(enumTypes.OPERATION_TYPE)('MERGE'):
        operationData.assetsSource = formData['add-asset-selection'] ? formData['add-asset-selection'].assetsValue : [];
        operationData.assetsTarget = formData['to-asset-selection'] ? [formData['to-asset-selection'].assetsValue] : [];
        operationData.usingByAssetId = formData['using-asset-selection'] ? formData['using-asset-selection'].assetsValue : null;
        operationData.assetsForConsumption = formData['rollsList'] ? this.getAssetsForConsumption(formData['rollsList']) : null;
        break;
      default:
        console.error('Unknown operation type: ' + this.operationInfo.operationType);
    }
    return operationData;
  };

  getAssetsForConsumption = (assets) => {
    if (assets) {
      assets = assets.filter((asset) => {return (asset.assetId && asset.assetId.assetsValue)});
      assets  = assets.map((asset) => {
        let newAsset = {
          value: asset.assetId.assetsValue.value,
          label: asset.assetId.assetsValue.label,
          data: asset.assetId.assetsValue.data
        };

        if (PermissionManager.isWeightSupported()) {
          newAsset.weightLeft = asset.weight ?  UnitHelper.userValueToServerValue(unitTypes.WEIGHT, asset.weight) : 0;
        } else {
          newAsset.cutLength = asset.cutLength ? UnitHelper.userValueToServerValue(unitTypes.LENGTH, asset.cutLength, 2) : 0
        }
        return newAsset;
      });
      return ((assets && assets.length > 0) ? assets : null);
    }

    return null;
  };

  getAssets = (formData) => {
    let assets = [];
    let fieldName = 'reportedAssets';
    let allAssets = formData[fieldName];
    for (let assetIndex = 0; assetIndex < allAssets.length; assetIndex++) {
      let selectedAssets = allAssets[assetIndex].assetsValue;
      if (selectedAssets) {
        for (let assetIdIndex = 0; assetIdIndex < selectedAssets.length; assetIdIndex++) {
          assets.push(selectedAssets[assetIdIndex]);
        }
      }
    }

    return assets;
  };


  ////////////////////////////////////////////////////////////////
  //  Confirmation message
  ////////////////////////////////////////////////////////////////

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog, callback) => {
      if (data.succeededWoIds.length === 1 && data.failedWoIds.length === 0) {
        return this.buildDialogDescriptorForSingleItem(data.reportOperations[0], fnCloseDialog, callback);
      }

      return this.buildDialogDescriptorForMultipleItems(data, fnCloseDialog);
    };
  };

  buildDialogDescriptorForMultipleItems = (data, fnCloseDialog) => {

    if (data && data.succeededWoIds && data.failedWoIds) {

      let totalCount = data.succeededWoIds.length + data.failedWoIds.length;
      let className = 'oneBackground';
      let operationName = this.operationInfo.title;
      let title = '';

      if (data.succeededWoIds.length === totalCount) { //succeeded only
        title = this.labels.get('multiconfirm.header', undefined, {count: totalCount, operationName, status: 'succeeded'});
        return {title, type: 'success', className};
      }
      else if (data.failedWoIds.length === totalCount) { //failed only
        title = this.labels.get('multiconfirm.header', undefined, { count: totalCount, operationName, status: 'failed'});
        return {title, type: 'error', className};
      }
      else {  //succeeded & failed
        className = 'report-operation-multiple-confirmation-dialog';
        title = this.labels.get('multiconfirm.header', undefined, { count: totalCount, operationName, status: 'reported'});
        let buttons = [{id:'close', text: this.dialogLabels.get('close'), action: fnCloseDialog, bsStyle: 'primary'}];

        let children =[];

        children.push(<MessageDialog.StatusRow key="row1" type={'succeeded'} count={data.succeededWoIds.length} />);
        children.push(<MessageDialog.MessageArea key="row2" text={this.getBusinessIdsByIds(data.succeededWoIds, data.reportOperations).join(', ')}/>);

        children.push(<MessageDialog.StatusRow key="row3" type={'failed'} count={data.failedWoIds.length} />);
        children.push(<MessageDialog.MessageArea key="row4" text={this.getBusinessIdsByIds(data.failedWoIds, data.reportOperations).join(', ')}/>);

        return {title, children, className, buttons, showTitleIcon: false};
      }
    }
    return {};
  };

  getBusinessIdsByIds = (ids, reportOperations) => {
    return ids.map((woId) => { return this.getWorkOrderBusinessId(woId, reportOperations); });
  };

  getWorkOrderBusinessId = (woId, reportOperations) => {
    if (woId) {
      let foundWO = reportOperations.find((operation)=> { return operation.workOrder === woId});
      return foundWO ? foundWO.woBusinessId : null;
    }
    return null;
  };

  buildDialogDescriptorForSingleItem = (reportOperation, fnCloseDialog, callback) => {
    let title = this.labels.get("confirmation.header",undefined , {operation: this.operationInfo.title});
    let buttons = [{id:'close', text:this.labels.get('confirmation.footer.close'), action: fnCloseDialog, bsStyle: "primary"}];
    if (callback) {
      buttons.unshift({id:'printRfidTag', text: this.labels.get('footer.printKitRfidTag'), action: callback},)
    }
    let children = (<div  key="operation-confirmation">
      <div className="message-item">
        <div className="message-item-line">
          <MessageDialog.DataRow label={this.labels.get("confirmation.message.workorderid")}
                                 value={reportOperation.woBusinessId}/>
        </div>
        {this.getConfirmationDataForType(reportOperation)}
      </div>
    </div>);

    return {title, children, buttons, className: "report-operation-single-confirmation-dialog"};
  };

  isOperationWithAssetsConnection = () => {
    switch (this.operationInfo.operationType) {
      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITH_ASSETS_CONNECTION'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS_WITH_ROLL_CONSUMPTION'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('MERGE'):
        return true;
    }
    return false;
  };

  getConfirmationDataForType = (data) => {
    if (!this.isOperationWithAssetsConnection() && data.assets && data.assets.length > 0) {
      return (
        <div className="message-item-line">
          <MessageDialog.DataRow label={this.labels.get("confirmation.message.relatedassets")} value={this.getConfirmationAssetsRows(data.assets)}/>
        </div>
      );
    }

    if (this.isOperationWithAssetsConnection()) {
        let firstComponent =  (data.assetsSource && data.assetsSource.length > 0) ? data.assetsSource : null;
        let secondComponent =  (data.assetsForConsumption && data.assetsForConsumption.length > 0) ? data.assetsForConsumption : null;
        if (!firstComponent && secondComponent) {
          firstComponent = secondComponent;
          secondComponent = null;
        }
      return (
        <div>
          <div className="message-item-line">
            {firstComponent && <MessageDialog.DataRow label={this.labels.get("confirmation.message.component")}
                                                      value={this.getConfirmationAssetsRows(firstComponent)}/>}
            {secondComponent && <MessageDialog.DataRow label={''} value={this.getConfirmationAssetsRows(secondComponent)}/>}
          </div>
          <div className="message-item-line">
            <MessageDialog.DataRow label={this.labels.get("confirmation.message.resulting")} value={this.getConfirmationAssetsRows(data.assetsTarget)}/>
          </div>
        </div>
      );
    }

    return null;
  };

  getExtraData = (asset) => {
    if (asset.data) {
      if (isRoll(asset.data.objectType)) {
        return asset.data.material ? MaterialHelper.getMaterialAvailableLabel(asset.data.material.materialName, asset.data.material.businessId) : '';
      }
      else {
        return asset.data.kitType && asset.data.kitType.businessId ? asset.data.kitType.businessId : '';
      }
    }
    return '';
  };

  getConfirmationAssetsRows = (assets) => {
    if (assets) {
      return (<div>
        {assets.map((asset, index) => {
          let extraData = this.getExtraData(asset);
          let assetType = asset.data ? this.labels.get(`confirmation.message.entity.${asset.data.objectType.toLowerCase()}`) : '';
          return (
            <div className="related-asset-data" key={'related-asset-' + index}>
            <span className="message-item-value">
              <Label className="related-asset-type" text={assetType}/>
              <Label className="related-asset-id" text={asset.data.businessId}/>
              <Label className="related-asset-metadata" text={(extraData ? '(' + extraData + ')' : '')}/>
            </span>
            </div>
          )
        })}
      </div>);
    }

    return null;
  };


  /////////////////////////////////////////////////////////////////////////
  // Handlers
  /////////////////////////////////////////////////////////////////////////


  renderWorkOrderRows = () => {
    if ( !this.getOperationStatusOptions() || this.getOperationStatusOptions().length === 0) {
      return <Overlay.Loading />;
    }

    let selectedWorkorders = this.props.sData.get('selectedWorkorders');

    if (!selectedWorkorders || selectedWorkorders.size === 0) {
      selectedWorkorders = List([null]);
    }

    let reportOperationItems =  selectedWorkorders.map((selectedWo, rowIndex) => {
      return (
        <ReportOperationItem key={"woData" + rowIndex}
               name={'reportOperation' + rowIndex}
               labels={this.labels}
               workOrder={selectedWo}
               itemIndex={rowIndex}
               entitiesTypes={this.entitiesTypes}
               operationStatusOptions = {this.getOperationStatusOptions()}
               operationType = {this.operationInfo.operationType}
               preselectedTool={this.operationInfo.preselectedTool}
               {...this.props}
        />
       );}
      );

      return reportOperationItems;
  };

  render() {
    let { sData } = this.props;

    if (!sData.get('show')) {
      return null;
    }

    return (
      <Dialog
        id="work-order-dialog"
        className='work-order-dialog'
        bodyClassName="modal-body-overflow"
        titleText={this.operationInfo.title}
        show={sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.props.actions.hide}
        sData={sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        // onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <span className="instructions-title">{this.labels.get('instructions')}</span>
        <span className="instructions-text">{this.operationInfo.description}</span>

        {this.renderWorkOrderRows()}

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'workOrderDialog',
    onChange: (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(WorkOrderDialog);


WorkOrderDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

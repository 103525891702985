import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as settingsPageActions }  from '../../../actions/Administration/SettingsPage/settingsPageActions';
import SettingsPage from '../../../components/Administration/SettingsPage/settingsPage';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingsPageActions, dispatch)
  }
}

let SettingsPageContainer = connect(
  (state) => {
    return {
        sData: state.administration.get('settingsPage'),
        sLoggedInUser : state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps
)(SettingsPage);


export default SettingsPageContainer;




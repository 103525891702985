import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import CommonAlertsView from '../Components/CommonAlertsView/commonAlertsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';

require('./expirationDateView.scss');

export default  class ExpirationDateView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.expirationdate.view.');
  }

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_EXPIRATION_DATE);

    this.props.actions.load(getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'));
  }

  render() {
    return (
      <div className="exposure-time-view">
        <CommonAlertsView labels={this.labels}
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          alertType={getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE')}
        />
      </div>
    );
  }
}


import React from 'react';

import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import ScanHeader from '../Common/ScanHeader/scanHeader';
import ScanInput from '../Common/ScanInput/scanInput';

require('./scanToolPage.scss');

const tempImg = require('images/scan-illustration.png');

class ScanToolPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pageLabels = createLabelHelper('mat.mobile.scanToolPage.');
  }

  componentWillUnmount() {
    this.props.actions.clear();
  }

  onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit(e.target.value);
    }
  };

  onSubmit = (data) => {
    let rollId = data.barcodeInput;
    this.handleSubmit(rollId);
  };
  handleSubmit(rollId) {
    let barcodeSections = rollId ? rollId.split('|') : null;
    //Barcode Example: 4500050319|00010|5000085238|0001|1018003 |N/A| 0000009527|(blank)|
    //more info at SAAS-4761
    if (barcodeSections && barcodeSections.length === 9) {
      rollId = barcodeSections[6];
    }
    this.props.actions.submit('TOOL', rollId, this.onSubmitCallback);
  }

  onSubmitCallback = (assetId) => {
    let path = `/Mobile/tool/${assetId}`;
    this.props.history.push(path);
  };

  getHeaderItems = () => {
    let { actions } = this.props;

    let leftButton = {
      id: 'leftButton',
      icon: 'pl pl-arrow-left',
      action: actions.header.onGoToStart,
      actionData: this.props.history,
    };
    let middleButton = {
      id: 'middleButton',
      label: this.pageLabels.get('header.buttons.button.selectTool'),
    };
    let rightButton = null;

    return { leftButton, middleButton, rightButton };
  };

  getLoadingOverlay() {
    if (!this.props.sPageData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }

  render() {
    let errorText = this.props.sPageData.get('error');
    let loading = this.props.sPageData.get('loading');
    let headerItems = this.getHeaderItems();

    return (
      <div className="scan-tag-page">
        <ScanHeader {...headerItems} />

        <div className="content">
          <div className="scan-input-section">
            <ScanInput
              id="barcodeInput"
              name="barcodeInput"
              placeholder={this.pageLabels.get('barcode.placeholder')}
              //placeholder={'Tool ID'}
              onKeyPressHandler={this.onKeyPressHandler}
              onClickHandler={this.props.handleSubmit(this.onSubmit)}
              change={this.props.change}
              disabled={loading}
              error={errorText}
            />
          </div>

          <div className="section prompt-text">
            <label>{this.pageLabels.get('prompt.text')}</label>
            {/*<label>{'Enter the tool ID or point device to the tool\'s barcode and press \'Scan\''}</label>*/}
          </div>

          <div className="section barcode-image">
            <img src={tempImg} alt="Plataine Logo" className="pointer" />
          </div>
        </div>
        {this.getLoadingOverlay()}
      </div>
    );
  }
}

export default reduxForm({
  form: 'scanToolPage',
})(ScanToolPage);

ScanToolPage.propTypes = {};

ScanToolPage.defaultProps = {};

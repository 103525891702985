import React from 'react';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import ScanHeader from '../Common/ScanHeader/scanHeader';
import ScanBottom from '../Common/ScanBottom/scanBottom';
import ScanInput from '../Common/ScanInput/scanInput';

require('./scanAssetPage.scss');

const tempImg = require('images/scan-illustration.png');

class ScanAssetPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.mobile.scan.');
    this.pageLabels = createLabelHelper('mat.mobile.scanAssetPage.');
  }

  componentWillUnmount() {
    this.props.actions.clear();
  }

  onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit(e.target.value)
    }
  };

  onSubmit = (data) => {
    let assetId = data.barcodeInput;
    this.handleSubmit(assetId);
  };

  handleSubmit(assetId){
    let barcodeSections = assetId ? assetId.split('|') : null;
    //Barcode Example: 4500050319|00010|5000085238|0001|1018003 |N/A| 0000009527|(blank)|
    //more info at SAAS-4761
    if(barcodeSections && barcodeSections.length === 9){
      assetId = barcodeSections[6];
    }
    this.props.actions.submit(assetId, this.onSubmitCallback);  //TODO: L HANDHELD
  }

  onSubmitCallback = (assetId) => {
    let path = `/Mobile/asset/${assetId}`;
    this.props.history.push(path);
  };

  onBottomClick = () => {
    let path = '/ChangeLocation';
    this.props.history.push(path);
  };

  getHeaderItems() {
    let scannedAssets = this.props.sData.get('scannedAssets');
    let {actions} = this.props;

    let leftButton = {
      id: 'leftButton',
      icon: 'pl pl-arrow-left',
      action: actions.header.onGoToStart,
      actionData: this.props.history,
    };

    let middleButton = {
      id:'middleButton',
      label: this.labels.get('header.buttons.button.scanAssets')
    };

    let rightButton = null;

    if (scannedAssets && scannedAssets.size > 0) {
      leftButton.icon = (scannedAssets.size === 1 ? 'pl pl-arrow-left' : 'pl pl-delete-icon');
      leftButton.action = actions.header.onRemoveAllScans;
      leftButton.actionData = {data: this.props.history, withConfirmation: scannedAssets.size > 1};

      middleButton.label = this.labels.get('header.buttons.button.scanned', undefined, {assetsCount: scannedAssets.size});
      middleButton.action = actions.header.onShowAllScans;
      middleButton.actionData = this.props.history;
    }

    return {leftButton, middleButton, rightButton};
  };

  getBottomItems() {
    let scannedAssets = this.props.sData.get('scannedAssets');
    if (!scannedAssets || scannedAssets.size < 1) {
      return {button: null};
    }
    return {
      button: {
        action: this.onBottomClick,
        label: this.labels.get('bottom.button.changeLocation', undefined, {count: scannedAssets.size}),
      },
    };
  };

  getLoadingOverlay() {
    if (!this.props.sPageData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }

  render() {
    let errorText = this.props.sPageData.get('error');
    let loading = this.props.sPageData.get('loading');

    let headerItems = this.getHeaderItems();
    let bottomItems = this.getBottomItems();

    return (
      <div className="scan-asset-page">
        <ScanHeader {...headerItems}/>

        <div className="content">
          <div className="scan-input-section">
            <ScanInput
              id="barcodeInput"
              name="barcodeInput"
              placeholder={this.pageLabels.get('barcode.placeholder')}
              onKeyPressHandler={this.onKeyPressHandler }
              onClickHandler={this.props.handleSubmit(this.onSubmit)}
              change={this.props.change}
              disabled={loading}
              error={errorText}
            />
          </div>

          <div className="section prompt-text">
            <label>{this.pageLabels.get('prompt.text')}</label>
          </div>

          <div className="section barcode-image">
            <img src={tempImg} alt="Plataine Logo" className="pointer" />
          </div>
        </div>
        <ScanBottom {...bottomItems}/>
        {this.getLoadingOverlay()}
      </div>
    )
  }
}

export default reduxForm({
    form: 'scanAssetPage',
  }
)(ScanAssetPage);

ScanAssetPage.propTypes = {
};

ScanAssetPage.defaultProps = {
};


import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import EntitiesMultiSelect from '../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js';
import MultiEntitiesMultiSelect from '../MultiEntitiesMultiSelect/multiEntitiesMultiSelect.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Label from 'infrastructure/js/components/Label/label';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
import ToggleGroup from 'infrastructure/js/components/controls/ToggleButtonGroup/toggleButtonGroup';

require('./multiEntitiesMultiSearch.scss');

export default class MultiEntitiesMultiSearch extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.component.assets.multisearch.');

    this.state = { selectMode: 'byAssetId',
                   attachedAsset: null,
                 };

    this.hasListener = false;
    this.currentValue ='';

    this.tagsFetchConfig = {
      action: this.props.fetchConfig.action,
      filter: this.props.fetchConfig.filter,
      entityType: getEnumValue(enumTypes.OBJECT_TYPE)('TAG'),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectMode === 'byScan' && this.inputRef) {
      this.inputRef.focus();
    }
  }

  componentWillUnmount(){
    if (this.hasListener) {
      document.removeEventListener('keypress', this.handelKeyPress);
    }
  }

  getToggleChildren = () => {
    return ([{key: "byAssetId", value: "byAssetId", label: this.labels.get('mode.assetId')},
      {key: "byScan", value: "byScan", label: this.labels.get('mode.scan'), hasError: this.hasErrors()},
      {key: "byTagId", value: "byTagId", label: this.labels.get('mode.tagId')}])
  };

  onModeChangedHandler = (event, value) => {
    if (value === 'byScan' && !this.hasListener) {
      document.addEventListener('keypress', this.handelKeyPress, false);
      this.hasListener = true;
    }
    else if (value !== 'byScan' && this.hasListener) {
      document.removeEventListener('keypress', this.handelKeyPress);
      this.hasListener = false;
    }

    this.setState({selectMode: value});
  };

  handelKeyPress = (e) => {
    if (e.key === 'Enter' ) {
      if (this.props.onScanInputChange) {
        this.props.onScanInputChange(this.currentValue);
        if (this.inputRef) {
          this.inputRef.value = '';
        }
        this.currentValue ='';
      }
    }
    else {
      this.currentValue = this.currentValue + e.key;
    }
  };

  onInputBlur = (e) => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  onTagValueChange = (value) => {
    if (!value) {
      this.setState({attachedAsset: null});
      this.props.change('tags', null);
      return;
    }
    if (this.props.onTagValueChange) {
      this.props.onTagValueChange(value.label, this.onTagValueChangeCallback);
    }
  };

  onTagValueChangeCallback = (value) => {
    this.setState({attachedAsset: value});
  };

  onAddToAssetsClick = () => {
    if (this.state.attachedAsset && this.props.onAddAssetClick) {
      this.props.onAddAssetClick(this.state.attachedAsset);
      this.setState({attachedAsset: null});
      this.props.change('tags', null);
    }
  };

  hasErrors = () => {
    return (this.props.errors && this.props.errors.size > 0);
  };


  renderErrors() {
    if (this.state.selectMode === 'byScan' && this.hasErrors()) {
      let errorItems = this.props.errors.map((item, index) => {
          return (
            <div className="error-item" key={index}>
              <span className="pl pl-error-icon"/>
              <span>{item}</span>
            </div>
          )}
      );

      return (
        <div className="errors-container">
          {errorItems}
        </div>
      );
    }

    return null;
  }

  getComponentToRender = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;

    return <Label text={option.label} />
  };

  renderSectionByMode = () => {
    if ( this.state.selectMode === 'byScan') {
      return (
            <input type="text"
                   autoFocus
                   className="hiddenScanInput"
                   ref={r => this.inputRef = r}
                   onBlur={this.onInputBlur}
            />
      );
    }

    if (this.state.selectMode === 'byTagId') {
      let { attachedAsset} = this.state;
      return (
        <div className="by-tag-id-section">

          <EntitiesMultiSelect id="tags"
                               name="tags"
                               className="tags"
                               entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('TAG')]}
                               change={this.props.change}
                               fetchConfig={this.tagsFetchConfig}
                               isMulti={false}
                               singleValueRenderer={this.getComponentToRender}
                               optionRenderer={this.getComponentToRender}
                               onValueChangeCallback={this.onTagValueChange}
                               placeholder={this.labels.get('tags.placeholder')}
          />

          <div className="by-tag-id-asset">
            <Label text={attachedAsset ? attachedAsset.objectType + ' ' + attachedAsset.businessId : ''} />
          </div>
          {<Button id="addAsset"
                   disabled={!attachedAsset}
                   onClick={this.onAddToAssetsClick}>{this.labels.get('button.addToAssets')}</Button>}

        </div>
      );
    }
  };


  render() {
    let {name, entitiesTypes, validate, preSelectedEntities, fetchConfig,  ...otherProps} = this.props;
    return (
      <div className="multi-entities-multi-search" >
        <InputSection label={this.labels.get('label.selectAssetsBy')} className="no-margin" />

        <ToggleGroup id="modeToggle" type="radio" name="modeToggle" defaultValue={"byAssetId"}  onChange={this.onModeChangedHandler} children={this.getToggleChildren()} hasError={this.hasErrors()}/>

        {this.renderSectionByMode()}

        <MultiEntitiesMultiSelect name={name}
                                  entitiesTypes={entitiesTypes}
                                  validate={validate}
                                  preSelectedEntities={preSelectedEntities}
                                  fetchConfig={fetchConfig}
                                  { ...otherProps }/>

        {this.renderErrors()}
      </div>
    );
  }
}

MultiEntitiesMultiSearch.defaultProps = {
};
MultiEntitiesMultiSearch.propTypes = {
  onScanInputChange: PropTypes.func,
  onManualInputChange: PropTypes.func,
  onAddAssetClick: PropTypes.func,
  fetchConfig: PropTypes.object.isRequired,
};


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as matSettingsPageActions }  from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions.js';
import MatSettingsPage from '../../../components/Administration/MatSettingsPage/matSettingsPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(matSettingsPageActions, dispatch)
  }
}

let MatSettingsPageContainer = connect(
  (state) => {
  	return {
      sData: state.administration.get('matSettingsPage'),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(MatSettingsPage);


export default MatSettingsPageContainer;




import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, { headerItemsLabels } from '../../../Common/Header/header';
import CreateShiftTypeDialog from './Dialogs/CreateShiftTypeDialog/createShiftTypeDialog';
import ShiftTypesViewGrid from './shiftTypesViewGrid/shiftTypesViewGrid';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import { MatSettingsMenuItemsIds } from '../../../../enums/navigationMenuItemsIds';

import './shiftTypesView.scss';

export default class ShiftTypesView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.labels = createLabelHelper('mat.administration.matsettings.shiftTypes.view.');
    }

    componentDidMount() {
        this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(
            MatSettingsMenuItemsIds.NAV_ELEMENT_SHIFT_TYPES
        );
    }

    getHeaderItems() {
        let { actions } = this.props;
        let hasRows = this.props.sHasRows;
        let selectedRowsData = this.props.sSelectedRowsData;

        return {
            buttons: [
                {
                  id: 'clearFilters',
                  label: '',
                  icon: 'pl pl-filter-clear',
                  action: actions.clearFilters,
                  tooltip: headerItemsLabels.CLEAR_FILTERS,
                },
                {
                    id: 'create',
                    label: headerItemsLabels.CREATE,
                    icon: 'pl pl-icon-add',
                    action: actions.header.onCreateShiftTypeClick,
                },
                {
                    id: 'edit',
                    label: headerItemsLabels.EDIT,
                    icon: 'pl pl-edit-icon',
                    disabled: !(hasRows && selectedRowsData.size === 1),
                    action: actions.header.onEditShiftTypeClick,
                    actionData: selectedRowsData.get(0),
                },
            ],

            kebab: {
                disabled: !selectedRowsData || selectedRowsData.size === 0,
                menuItems: [
                    {
                        id: 'remove',
                        label: headerItemsLabels.DELETE,
                        action: actions.header.onRemoveShiftTypesClick,
                        disabled: hasRows && (!selectedRowsData || selectedRowsData.size === 0),
                        actionData: selectedRowsData,
                    },
                ],
            },
        };
    }

    reloadPage = () => {
        this.props.actions.init(true);
    };

    render() {
        let title = this.labels.get('title');
        let headerItems = this.getHeaderItems();

        return (
            <div className='administration-view'>
                <div className='title'>{title}</div>

                <Header {...headerItems} />

                <ShiftTypesViewGrid
                    actions={{
                        ...this.props.actions.shiftTypesViewGridActions,
                        ...this.props.actions.fetchEntitiesActions,
                    }}
                />

                <PL_DialogWrapper
                    dialogComponent={CreateShiftTypeDialog}
                    show={this.props.sCreateShiftTypeDialogData.get('show')}
                    actions={this.props.actions.createShiftTypeDialogActions}
                    sData={this.props.sCreateShiftTypeDialogData}
                    reloadParentComponent={this.reloadPage}
                />
            </div>
        );
    }
}

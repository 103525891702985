import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { gridsNames } from '../../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import { EntityPropertyTypes } from '../../../../../enums/entityPropertyTypes';
import { FetchEntitiesFilters } from '../../../../../enums/fetchEntitiesFilters';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./dashboardGrid.scss');

class DashboardGrid extends React.PureComponent {
  filterConfig = [
    { fieldName: 'orgName', filterName: 'orgBusinessId', getOptions: false },
    { fieldName: 'activitiesTotal.count', filterName: 'activitiesTotal', getOptions: false },
    { fieldName: 'activitiesBusinessDayAverage.count', filterName: 'activitiesBusinessDayAverage', getOptions: false },
    { fieldName: 'activitiesLastBusinessDay.count', filterName: 'activitiesLastBusinessDay', getOptions: false },
    { fieldName: 'assetsActive.count', filterName: 'assetsActive', getOptions: false },
    { fieldName: 'assetsTotal.count', filterName: 'assetsTotal', getOptions: false },
    { fieldName: 'rfidErrorsLastBusinessDay.count', filterName: 'rfidErrorsLastBusinessDay', getOptions: false },
    { fieldName: 'loginsBusinessDayAverage.count', filterName: 'loginsBusinessDayAverage', getOptions: false },
  ];

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.organizations.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'orgName',
        // title: this.labels.get('columns.name.title'),
        title: 'Org Name',
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'orgBusinessId',
        filterAlignment: filterAlignmentTypes.LEFT,
        fetchConfig: {
          entityType: EntityPropertyTypes.ORGS,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities,
        },
        columnOptions: {
          sortable: true,
          sort: 'asc',
          valueGetter: (params) => {
            return params.data.orgName;
          },
        },
      },
      {
        fieldName: 'activitiesTotal.count',
        // title: this.labels.get('columns.status.title'),
        title: 'Activities',
        filterType: filterTypes.NONE,
        filterName: 'activitiesTotal',
        columnOptions: {
          sortable: true,
          headerComponentParams: {
            headerTooltip: 'Total number of activities',
          },
          valueGetter: (params) => {
            return params.data.activitiesTotal?.count;
          },
        },
      },
      {
        fieldName: 'activitiesBusinessDayAverage.count',
        title: 'Daily avg.activities',
        filterType: filterTypes.NONE,
        filterName: 'activitiesBusinessDayAverage',
        columnOptions: {
          sortable: true,
          headerComponentParams: {
            headerTooltip: 'Daily average number of activities',
          },
          valueGetter: (params) => {
            return params.data.activitiesBusinessDayAverage?.count;
          },
        },
      },
      {
        fieldName: 'activitiesLastBusinessDay.count',
        title: 'LBD activities',
        filterType: filterTypes.NONE,
        filterName: 'activitiesLastBusinessDay',
        columnOptions: {
          sortable: true,
          headerComponentParams: {
            headerTooltip: 'Number of activities in last business day',
          },
          valueGetter: (params) => {
            return params.data.activitiesLastBusinessDay?.count;
          },
        },
      },
      {
        fieldName: 'assetsActive.count',
        title: 'Active Assets',
        filterType: filterTypes.NONE,
        filterName: 'assetsActive',
        columnOptions: {
          sortable: true,
          headerComponentParams: {
            headerTooltip: 'Number of active assets',
          },
          valueGetter: (params) => {
            return params.data.assetsActive?.count;
          },
        },
      },
      {
        fieldName: 'assetsTotal.count',
        title: 'Assets',
        filterType: filterTypes.NONE,
        filterName: 'assetsTotal',
        columnOptions: {
          sortable: true,
          headerComponentParams: {
            headerTooltip: 'Total number of assets',
          },
          valueGetter: (params) => {
            return params.data.assetsTotal?.count;
          },
        },
      },
      {
        fieldName: 'rfidErrorsLastBusinessDay.count',
        title: 'LBD RFID Errors',
        filterType: filterTypes.NONE,
        filterName: 'rfidErrorsLastBusinessDay',
        columnOptions: {
          sortable: true,
          headerComponentParams: {
            headerTooltip: 'Number of RFID errors in last business day',
          },
          valueGetter: (params) => {
            return params.data.rfidErrorsLastBusinessDay?.count;
          },
        },
      },
      {
        fieldName: 'loginsBusinessDayAverage.count',
        title: 'Avg. daily logins',
        filterType: filterTypes.NONE,
        filterName: 'loginsBusinessDayAverage',
        columnOptions: {
          sortable: true,
          headerComponentParams: {
            headerTooltip: 'Average daily logins',
          },
          valueGetter: (params) => {
            return params.data.loginsBusinessDayAverage?.count;
          },
        },
      },
    ];
  };

  render() {
    return (
      <div className="dashboard-grid">
        <Grid
          gridName={gridsNames.ADMINISTRATION_ORGS_DASHBOARD}
          columnsConfig={this.columnsConfig}
          actions={this.props.actions}
          filterConfig={this.filterConfig}
          gridProps={{
            checkboxSelection: false,
          }}
        />
      </div>
    );
  }
}

DashboardGrid.propTypes = {
  actions: PropTypes.object,
};

DashboardGrid.defaultProps = {
  actions: {},
};

export default reduxForm({
  form: 'DashboardGrid',
})(DashboardGrid);

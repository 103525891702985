import LoginService from 'infrastructure/js/services/LoginService.js';
import Network from 'infrastructure/js/modules/network.js';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CHANGEPASSWORD_SUBMIT_IN_PROCESS: "CHANGEPASSWORD_SUBMIT_IN_PROCESS",
  CHANGEPASSWORD_SUBMIT_FINISHED: "CHANGEPASSWORD_SUBMIT_FINISHED",
  CHANGEPASSWORD_SUBMIT_FAILED: "CHANGEPASSWORD_SUBMIT_FAILED",
  CHANGEPASSWORD_UPDATE_PASSWORD_CONFIG: 'CHANGEPASSWORD_UPDATE_PASSWORD_CONFIG'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actions = {
  changePasswordInProcess: function() {
    return {type: actionTypes.CHANGEPASSWORD_SUBMIT_IN_PROCESS };
  },
  changePasswordFinished: function(data) {
    return {type: actionTypes.CHANGEPASSWORD_SUBMIT_FINISHED, payload: data};
  },
  changePasswordFailed: function(data) {
    return {type: actionTypes.CHANGEPASSWORD_SUBMIT_FAILED, payload: data };
  },
  updatePasswordConfig: function (payload) {
    return {type: actionTypes.CHANGEPASSWORD_UPDATE_PASSWORD_CONFIG, payload: payload};
  },

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(query) {
  return function(dispatch, getState) {
    return api.fetchPasswordConfig(dispatch, getState)(query);
  }
};

jsxActions.changeExpiredPassword = function(values) {
  return function(dispatch, getState) {
    dispatch(actions.changePasswordInProcess());
    return LoginService.changeExpiredPassword(values).then((response) => {
      if(!Network.isResponseValid(response)){
        dispatch(actions.changePasswordFailed(response.applicationResponseStatus.message));
        return false;
      }

      dispatch(actions.changePasswordFinished(response.data.message));
      window.location.hash = "/Login";
      return true;
    });
  }
}

jsxActions.changePassword = function(values) {
  return function(dispatch, getState) {
    dispatch(actions.changePasswordInProcess());
    return LoginService.changePassword(values).then((response) => {
      if(!Network.isResponseValid(response)){
        dispatch(actions.changePasswordFailed(response.applicationResponseStatus.message));
        return false;
      }

      dispatch(actions.changePasswordFinished(response.data.message));
      window.location.hash = "/Login";
      return true;
    });
  }
}


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchPasswordConfig = function(dispatch, getState) {
  return function(query) {

    if(!query){
      //incase of not going via login process - such as refresh
      let orgId = getState().login.get('orgId') ? getState().login.get('orgId') : getState().login.get('loggedInUser').loggedInOrg?.id;
      if(!orgId){
        return;
      }
      query = {orgId: orgId}
    }
    return LoginService.fetchPasswordConfig(query).then((response) => {
      if(!Network.isResponseValid(response)){
        return;
      }
      let tooltip = response.data.tooltipPrefix + ' ';
      tooltip += response.data.tooltipItems.join(', ');
      dispatch(actions.updatePasswordConfig({passwordTooltip: tooltip, passwordConfig:response.data}));
    });
  }
};

import PropTypes from 'prop-types';

import './Item.scss';

const Item = ({ item, onDeleteClick, disabled }) => {
  const { value, label } = item;

  const handleDelete = (e) => {
    e.stopPropagation();
    onDeleteClick(value);
  };

  return (
    <div className="single-line-multi-select-item">
      <span title={label} className="item-name">
        {label}
      </span>
      {!disabled ? (
        <span className="item-delete" onClick={handleDelete}>
          x
        </span>
      ) : null}
    </div>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  onDeleteClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Item.defaultProps = {
  item: { label: 'no item' },
  onDeleteClick: () => {
    console.error('no delete function!');
  },
  disabled: false,
};

export default Item;

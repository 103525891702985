import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as partTypesViewActions} from '../../../actions/Administration/MatSettingsPage/partTypesViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as partTypesViewGridActions} from '../../../actions/Administration/MatSettingsPage/partTypesViewGridActions';
import { jsxActions as createPartTypeDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createPartTypeDialogActions.js';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import PartTypesView from '../../../components/Administration/MatSettingsPage/PartTypesView/partTypesView.js';
import { gridsNames} from '../../../enums/gridsNames';
function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(partTypesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      partTypesViewGridActions: bindActionCreators(partTypesViewGridActions, dispatch),
      createPartTypeDialogActions : bindActionCreators(createPartTypeDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }

  }
}

let PartTypesViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_PART_TYPES) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_PART_TYPES) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreatePartTypeDialogData: state.administration.getIn(['matSettingsPage', 'createPartTypeDialog']),
    };
  },
  mapDispatchToProps
)(PartTypesView);


export default PartTypesViewContainer;




import { Map } from 'immutable';
import { actionTypes as MqttSensorsSettingsViewActionsType } from '../../../actions/Administration/RfidSettingsPage/mqttSensorsSettingsViewActions';

let defaultState = {
  loading: false,
  selectedBroker : null,
  brokersOptions : []
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case MqttSensorsSettingsViewActionsType.ADMINISTRATION_RF_SETTINGS_SENSORS_FETCH_BROKERS_IN_PROCESS:
      return state.set('loading', true);

    case MqttSensorsSettingsViewActionsType.ADMINISTRATION_RF_SETTINGS_SENSORS_FETCH_BROKERS_READY:
      state = state.set('brokersOptions', action.payload);
      return state.set('loading',false);

    case MqttSensorsSettingsViewActionsType.ADMINISTRATION_RF_SETTINGS_SENSORS_BROKER_CHANGED:
      return state.set('selectedBroker', action.payload);

    case MqttSensorsSettingsViewActionsType.ADMINISTRATION_RF_SETTINGS_SENSORS_UNMOUNT:
      return Map(defaultState);

    default:
      return state;
  }
}








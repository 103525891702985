import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as shiftsSchedulerActions} from '../../../actions/Administration/MatSettingsPage/shiftsSchedulerActions';
import {jsxActions as shiftsTemplateViewActions} from '../../../actions/Administration/MatSettingsPage/shiftsTemplateViewActions.js';
import {jsxActions as shiftSettingsDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/shiftSettingsDialogActions';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import ShiftsTemplateView from '../../../components/Administration/MatSettingsPage/ShiftsTemplateView/shiftsTemplateView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(shiftsTemplateViewActions, dispatch),
      shiftsSchedulerActions : bindActionCreators(shiftsSchedulerActions, dispatch),
      shiftSettingsDialogActions: bindActionCreators(shiftSettingsDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }
  }
}

let ShiftsTemplateViewContainer = connect(
  (state) => {
    return {
      sShiftsSchedulerData: state.administration.getIn(['matSettingsPage', 'shiftsScheduler']),
      sShiftSettingsDialogData: state.administration.getIn(['matSettingsPage', 'shiftSettingsDialog']),
    };
  },
  mapDispatchToProps
)(ShiftsTemplateView);


export default ShiftsTemplateViewContainer;




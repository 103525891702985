import Network from 'infrastructure/js/modules/network';
import * as readersAndAntennasService from '../../../../services/Rfid/readersAndAntennasService';
import * as automatedWorkflowsService from '../../../../services/Rfid/automatedWorkflowsService';
import * as daHelper from '../../../Dialogs/dialogActionsHelper';
import {dialogsNames} from '../../../../enums/dialogsNames';



const config = {
  getSubmitMethod: (submitConfigData) => {
    return submitConfigData && submitConfigData.isEditMode ?
           automatedWorkflowsService.editAutomationScenario : automatedWorkflowsService.createAutomationScenario;
  }
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_AUTOMATION_SCENARIO_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_AUTOMATION_SCENARIO_DIALOG),

};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(data) {
      api.fetchDialogData(dispatch, getState)(data.deviceAppProfileId).then((response) => {
        dispatch(actions.show(data.itemToEdit));
      });
    }
  },

  fetchDialogData(dispatch, getState) {
    return function (deviceAppProfileId) {
      let promise1 = readersAndAntennasService.fetchAntennas(deviceAppProfileId);
      let promise2 = readersAndAntennasService.fetchReaders();
      let promise3 = automatedWorkflowsService.fetchQualifiedEvents();
      let promise4 = automatedWorkflowsService.fetchActionSequence();


      return Promise.all([promise1, promise2, promise3, promise4]).then((allResults) => {

        let isResultValid = allResults.every((result, index) => {
          return Network.isResponseValid(result);
        });
        if (!isResultValid) {
          console.error('Failed to get the Automation Scenario initial data', allResults);
          return {success: false};
        }

        let antennas = allResults[0].dataList;
        let readers = allResults[1].dataList;
        let qualifiedEvents = allResults[2].dataList;
        let actionSequences = allResults[3].dataList;

        let dialogData = {
          antennas: antennas.map((obj) => {
            return {value: obj.id, label: obj.name, data: obj}
          }),
          readers: readers.map((obj) => {
            return {value: obj.businessId, label: obj.name, data: obj}
          }),
          qualifiedEvents: qualifiedEvents.map((obj) => {
            return {value: obj.id, label: obj.name}
          }),
          actionSequences: actionSequences.map((obj) => {
            return {value: obj.id, label: obj.name}
          }),
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return {success: true};
      });
    }
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};


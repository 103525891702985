import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as orgsService from '../../../../services/Administration/organizationsService.js';
import { jsxActions as organizationsPageApi } from '../organizationsPageActions.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_ORGANIZATION_DIALOG_SHOW:               'CREATE_ORGANIZATION_DIALOG_SHOW',
  CREATE_ORGANIZATION_DIALOG_HIDE:               'CREATE_ORGANIZATION_DIALOG_HIDE',
  CREATE_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS: 'CREATE_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS',
  CREATE_ORGANIZATION_STATUS_SUBMIT_FINISHED:    'CREATE_ORGANIZATION_STATUS_SUBMIT_FINISHED',
  DUPLICATE_ORGANIZATION_DIALOG_SHOW: 'DUPLICATE_ORGANIZATION_DIALOG_SHOW'
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actions = {
  showDuplicate: function(payload) {
    return {type: actionTypes.DUPLICATE_ORGANIZATION_DIALOG_SHOW, payload: payload};
  },
  show: function(payload, isDuplicate) {
    return {type: actionTypes.CREATE_ORGANIZATION_DIALOG_SHOW, payload: payload, isDuplicate};
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_ORGANIZATION_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_ORGANIZATION_STATUS_SUBMIT_FINISHED, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data,  organizationId, isDuplicate, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, organizationId, isDuplicate, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data, isDuplicate) {
    if(isDuplicate && data){
      dispatch(actions.showDuplicate(data))
      return;
    }
    if (data) {
      dispatch(actions.show(data));  //for edit mode
    }
    else {
      dispatch(actions.show(null));   //for create mode
    }
  }
};


api.submit = function(dispatch, getState) {
  return function(data, organizationId, isDuplicate, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    let method;
    if(isDuplicate){
      method = orgsService.cloneOrganization;
    }
    else{
      method = organizationId ? orgsService.editOrganization : orgsService.createOrganization;
    }

    method(data, organizationId).then((response) => {
        dispatch(actions.submitFinished());
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Create/Edit organization failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }
        return {success: true};
      });
  };
};


import { Map } from 'immutable';

import {default as matSettingsPageReducer} from './MatSettingsPage/matSettingsPageReducer.js';
import {default as massOperationsPageReducer} from './MassOperationsPage/massOperationsPageReducer.js';
import {default as organizationsPageReducer} from './OrganizationsPage/organizationsPageReducer';
import {default as rfidSettingsPageReducer} from './RfidSettingsPage/rfidSettingsPageReducer';
import {default as billingPageReducer} from './Billing/billingPageReducer';
import {default as settingsPageReducer} from './SettingsPage/settingsPageReducer';
import {default as psManagementPageReducer} from './PsManagementPage/psManagementPageReducer';

let defaultState = {

};

export default function(state = Map(defaultState), action) {

  state = state.set('matSettingsPage', matSettingsPageReducer(state.get('matSettingsPage'), action));
  state = state.set('massOperationsPage', massOperationsPageReducer(state.get('massOperationsPage'), action));
  state = state.set('organizationsPage', organizationsPageReducer(state.get('organizationsPage'), action));
  state = state.set('rfidSettingsPage', rfidSettingsPageReducer(state.get('rfidSettingsPage'), action));
  state = state.set('billingPage', billingPageReducer(state.get('billingPage'), action));
  state = state.set('settingsPage', settingsPageReducer(state.get('settingsPage'), action));
  state = state.set('psManagementPage', psManagementPageReducer(state.get('psManagementPage'), action));

  return state;

}








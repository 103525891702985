const roleTypes = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_PS_USER: 'ROLE_PS_USER',
  ROLE_PM_USER: 'ROLE_PM_USER',
  ROLE_SYSADMIN:'ROLE_SYSADMIN',
  HOOK_USER:'HOOK_USER',
  ROLE_RFID_ADMIN:'ROLE_RFID_ADMIN',
  ROLE_LIMITED_USER:'ROLE_LIMITED_USER',
  ROLE_PRESENTER:'ROLE_PRESENTER',
  ROLE_USER: 'ROLE_USER'
};

export function isAdmin(user) {
  return hasRole(user, roleTypes.ROLE_ADMIN);
}

export function isPSUser(user) {
  return hasRole(user, roleTypes.ROLE_PS_USER);
}

export function isPMUser(user) {
  return hasRole(user, roleTypes.ROLE_PM_USER);
}

export function isSysAdmin(user) {
  return hasRole(user, roleTypes.ROLE_SYSADMIN);
}

export function isHookUser(user) {
  return hasRole(user, roleTypes.HOOK_USER);
}
export function isIntegrationUser(user) {
  return hasRole(user, 'ROLE_INTEGRATION_3RDPARTY');
}

export function isRfidAdminUser(user) {
  return hasRole(user, roleTypes.ROLE_RFID_ADMIN);
}
export function isLimitedUser(user) {
  return isOnlyRole(user, roleTypes.ROLE_LIMITED_USER);
}
export function isPresenterUser(user) {
  return hasRole(user, roleTypes.ROLE_PRESENTER);
}
export function isUser(user) {
  return hasRole(user, roleTypes.ROLE_USER);
}

function hasRole(user, roleName) {
  if(user && user.roles){
    let found =  user.roles.find((role) => {
      return role.name === roleName;
    });
    return !!found;
  }
  return false;
}
function isOnlyRole(user, roleName) {
  if(user && user.roles) {
    return user.roles.length === 1 && user.roles[0].name === roleName;
  }
  return false;
}


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import SmartSelectionGrid from './SmartSelectionGrid/smartSelectionGrid';
import SmartSelectionHeader from './SmartSelectionHeader/smartSelectionHeader';
import {gridsNames} from '../../../enums/gridsNames';
import {createLabelHelper} from '../../../../../infrastructure/js/utils/labelHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import AddToPickListDialog from '../../Dialogs/AddToPickListDialog/addToPickListDialog';

require('./smartSelectionPage.scss');

export default class SmartSelectionPage extends Component {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.smartSelect.page.');
  }

  componentDidMount() {
    if (PermissionManager.hasPredefinedViewSmartSelectionPermissions()) {
      this.props.actions.init();
    }
  }

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData} = this.props;

    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'addToPickList',
          icon: 'pl pl-picklist',
          label: headerItemsLabels.ADD_TO_PICK_LIST,
          counter: sSelectedRowsData.size,
          disabled: !sHasRows || !sSelectedRowsData || sSelectedRowsData.size === 0,
          action: actions.header.onAddToPickListClick,
          actionData: sSelectedRowsData
        },
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.header.onExportSmartSelectionClick,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_SMART_SELECTION, headerData: this.props.sHeaderData}
        },
      ],
    };

    return items;
  }

  getMessageDialogBuilder = () => {
    return (fnCloseDialog , callback ) => {
      let title = this.labels.get('operation.addToPickList.confirmation.title');
      let type = 'warning';
      let message = this.labels.get('operation.addToPickList.confirmation.message');
      let children =  [<span key={`confirmMessageDataRow`}>{message}</span>];
      let buttons = [
        {id:'cancel',text: this.labels.get('operation.addToPickList.cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'continue',text: this.labels.get('operation.addToPickList.continue'), action: callback  , bsStyle: 'primary'}
      ];
      let className = 'smart-selection-confirm-message';
      let messageDialogDescriptor = {title, buttons, children,  className, type};
      return messageDialogDescriptor;
    };
  };

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderBody = () => {
    if(!this.props.sHeaderData.get('isGridVisible')){
      return null;
    }
    let headerItems = this.getHeaderItems();
    return(<div className="smart-selection-grid-section">
      <Header  {...headerItems}/>

      <SmartSelectionGrid actions={{...this.props.actions.smartSelectionGridActions, ...this.props.actions.fetchEntitiesActions}}
                          requiredMaterialAmount={this.props.sHeaderData.get('requiredMaterial')}
                          selectedRows={this.props.sSelectedRowsData} />
    </div>)
  }

  render() {
    return (
      <div className="smart-selection-page">
        <SmartSelectionHeader actions={this.props.actions.smartSelectionHeaderActions} sData={this.props.sHeaderData} />
        {this.renderBody()}

        <PL_DialogWrapper dialogComponent={AddToPickListDialog}
                          show={this.props.sAddToPickListDialogData.get('show')}
                          actions={{...this.props.actions.addToPickListDialogActions, ...this.props.actions.fetchEntitiesActions}}
                          fetchEntitiesActions={this.props.actions.fetchEntitiesActions}
                          sData={this.props.sAddToPickListDialogData}
                          reloadParentComponent={this.reloadPage}
        />
      </div>
    )
  }
};

SmartSelectionPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sSmartSelectionGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
  sHeaderData: PropTypes.object,
  sAddToPickListDialogData: PropTypes.object,
};

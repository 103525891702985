import * as boHelper              from '../batchOperationDialogActionsHelper';
import * as locationsService from '../../../services/Locations/locationsService';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('ARCHIVE_ASSETS_DIALOG'),
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('ARCHIVE_ASSETS_DIALOG'),
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
const config = {
  submitMethod: locationsService.updateAssetsStatus,
  dialogReduxStorageName: 'archiveAssetsDialog'
};

export let api = {
  ...boHelper.getApiActions(actions, config),
};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api)
};



import React from 'react';
import {reduxForm} from 'redux-form';
import Form from '../../../../Common/Form/form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import {enumTypes, getEnumValue} from '../../../../../../utils/enumHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';

class SpoolTabForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.formLabels = createLabelHelper('mat.administration.settings.alerts.form.');

    this.state = {
      isEnableAlerts : false
    };
  }

  componentDidMount (){
    this.initFormValues();
    this.setStateStartValues();
  }

  componentWillUnmount(){
    this.props.actions.setStartParameters();
  }

  setStateStartValues = () => {
    this.setState({isEnableAlerts: !this.props.sData.enabled});
  };

  isEtlAlert = (alertType) => {
    return (alertType === getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND') ||  alertType === getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'));
  };

  initFormValues = () => {

    let data = this.props.sData;

    let initialValue = {
      spoolEnableAlertsCheckbox: data.enabled,
      spoolDefaultValuesWarning: this.isEtlAlert(this.props.alertType) ? data.warningThreshold / 60 : data.warningThreshold, // convert to hours
      spoolDefaultValuesError : this.isEtlAlert(this.props.alertType) ? data.errorThreshold / 60 : data.errorThreshold, // convert to hours
    };

    this.props.initialize(initialValue);
  }

  onCheckboxClick = () => {
    this.setState({isEnableAlerts: !this.state.isEnableAlerts});
  }

  onSubmit = (data) => {

    let newData = {
      id: this.props.sData.id,
      enabled: data.spoolEnableAlertsCheckbox,
      warningThreshold: this.isEtlAlert(this.props.alertType) ? data.spoolDefaultValuesWarning * 60 : data.spoolDefaultValuesWarning, //convert to minutes
      errorThreshold: this.isEtlAlert(this.props.alertType)? data.spoolDefaultValuesError * 60 : data.spoolDefaultValuesError, //convert to minutes

    };

    this.props.actions.update(newData, this.props.alertType, this.getMessageDialogBuilder()).then((res) => {
      if (res.success) {
        this.initFormValues(); //Initialize form values and reset pristine.
      }
    });

  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.formLabels.get('confirmation.success');
      let className = 'oneBackground';
      let messageDialogDescriptor = {title, className};
      return messageDialogDescriptor;
    };
  };

  getFormButtons() {
    return (
      [
        {
          text: this.formLabels.get('buttons.save'),
          className: 'common-alerts-form-button-save border-btn btn',
          action: this.props.handleSubmit(this.onSubmit),
          disabled:this.props.pristine
        }
      ]
    );
  }

  renderEnableAlertsSection() {
    let spoolLabel = this.props.labels.get('spool');
    return (
      <div className="common-alerts-form-enable-alerts-section" >
        <label htmlFor="spoolEnableAlertsCheckbox" >
          <Checkbox name="spoolEnableAlertsCheckbox" id="spoolEnableAlertsCheckbox"  onClick={this.onCheckboxClick} />
          <span>{this.props.labels.get('checkbox.title' ,'',{assetType: spoolLabel})}</span>
        </label>
      </div>
    );
  }

  renderSpoolDefaultValuesSection() {
    if (this.props.sData.alertType === getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME') ||
      this.props.sData.alertType === getEnumValue(enumTypes.ALERT_TYPE)('RESERVED')
    ) {
      return null;
    }
    return (
      <div className='common-alerts-form-default-values-container'>

        <div className="common-alerts-form-default-values-header">
          <label className='common-alerts-form-default-values-title'>{this.props.labels.get('defaultvalues.section.header')}</label>
        </div>

        <div className="common-alerts-form-default-values-section">
          <InputSection label={this.formLabels.get('defaultvalues.title.warning')} htmlFor="spoolDefaultValuesWarning" className="inline left-side">
            <TextField id="spoolDefaultValuesWarning" name="spoolDefaultValuesWarning" disabled={this.state.isEnableAlerts}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 9999)} />
            <span className='additional-info'>{this.props.labels.get('defaultvalues.additionalinfo.title')}</span>
          </InputSection>

          <InputSection label={this.formLabels.get('defaultvalues.title.error')} htmlFor="spoolDefaultValuesError" className="inline right-side">
            <TextField id="spoolDefaultValuesError" name="spoolDefaultValuesError" disabled={this.state.isEnableAlerts}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 9999)} />
            <span className='additional-info'>{this.props.labels.get('defaultvalues.additionalinfo.title')}</span>
          </InputSection>
        </div>

      </div>
    );
  }

  render() {
    return (
      <Form className="common-alerts-form" formButtons={this.getFormButtons()}>
        {this.renderEnableAlertsSection()}
        {this.renderSpoolDefaultValuesSection()}
      </Form>
    );
  }
}

export default reduxForm({
    form: 'spoolTabForm'
  }
)(SpoolTabForm);

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../../../MessageDialog/messageDialogActions.js';
import * as groupsService from '../../../../services/Groups/groupsService';
import * as daHelper from '../../../Dialogs/dialogActionsHelper';
import { dialogsNames } from '../../../../enums/dialogsNames';


const config = {
  getSubmitMethod: (submitConfigData) => {
    return submitConfigData && submitConfigData.isEditMode ?
      groupsService.editGroupType : groupsService.createGroupType;
  }
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_GROUP_TYPE_DIALOG)
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_GROUP_TYPE_DIALOG)
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  fetchDialogData(dispatch, getState) {
    return function() {
      return groupsService.getGroupTypes().then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Group Types failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return { success: false };
        }

        const uniqueFilteredResponse = _.uniqBy(response.dataList, 'description').filter(item => item.description);
        let dialogData = {
          groupTypes: uniqueFilteredResponse?.map((obj) => { return { value: obj.id, label: obj.description, data: obj }; }) || []
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return { success: true };
      });
    };
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api)
};


let currentSchedules = {};

function _startSchedule(name, actionFunction,interval){
  //console.log(`Start ${name} schedule, interval set to ${interval} seconds`);

  //If already exists schedule with this name, kill the old one.
  if(currentSchedules[name]){
    // console.log(`"${name}" schedule already exists, remove duplicate.`);
    _killSchedule(name, currentSchedules[name])();
  }

  return setInterval(actionFunction, (1000 * interval));
}

//The function return kill closure function.
function _killSchedule(name, intervalID){

  return function(){
    // console.log(`Remove ${name} schedule`);
    clearInterval(intervalID);
  }
}

export default function CreateScheduleAction(name, actionFunction, interval = 60, startImmediately = false){
  if(!name || !actionFunction || interval == 0){
    console.error("CreateScheduleAction: invalid params");
    return;
  }

  if(startImmediately){
    actionFunction();
  }

  currentSchedules[name] = _startSchedule(name, actionFunction, interval);

  //Export object
  return {
    kill: _killSchedule(name, currentSchedules[name])
  };
}







import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {List} from 'immutable';
import {jsxActions as headerActions} from '../../../actions/Header/headerActions';
import { jsxActions as fetchEntitiesActions }      from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as assetsViewActions }        from '../../../actions/LocationPage/AssetsView/assetsViewActions';
import { jsxActions as assetsPartsGridActions }        from '../../../actions/LocationPage/AssetsView/assetsPartsGridActions';
import { jsxActions as addReplaceSpoolsActions }        from '../../../actions/Dialogs/AddReplaceSpoolsDialog/addReplaceSpoolsActions';
import { jsxActions as editAssetsStatusActions }      from '../../../actions/Dialogs/EditAssetsStatusDialog/editAssetsStatusDialogActions.js';
import {gridsNames} from '../../../enums/gridsNames';
import PartsView from '../../../components/LocationPage/AssetsView/PartsView/partsView';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(assetsViewActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      headerActions:        bindActionCreators(headerActions, dispatch),
      assetsPartsGridActions:        bindActionCreators(assetsPartsGridActions, dispatch),
      addReplaceSpoolsActions:    bindActionCreators(addReplaceSpoolsActions, dispatch),
      editAssetsStatusActions:    bindActionCreators(editAssetsStatusActions, dispatch),
    }
  }
}

let PartsViewContainer = connect(
  (state) => {
    let sGrid = state.grid.get(gridsNames.LOCATION_ASSET_PARTS) ? state.grid.get(gridsNames.LOCATION_ASSET_PARTS) : null;
    return {
      sGrid:                  sGrid,
      sHasRows:               sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData:      sGrid ? sGrid.get('selectedRowsData') : List(),
      sData:                  state.locationPage.getIn(['assetsView', 'partsView']),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),
      sOperations:            state.locationPage.get('workOrderViewData').get('operations'),
      sLoggedInUser : state.login.get('loggedInUser'),
      sAddReplaceSpoolsDialogData:    state.dialogs.get('addReplaceSpoolsDialog'),
      sEditAssetsStatusDialogData:    state.dialogs.get('editAssetsStatusDialog'),
    };
  },
  mapDispatchToProps
)(PartsView);

export default PartsViewContainer;





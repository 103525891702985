import { Map, List } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';
import { actionTypes as actionTypes }  from '../../../actions/LocationPage/WorkOrderView/workOrderDialogActions';


let defaultState = {
  operationInfo: null,
  selectedWorkorders: List(),
  dialogData: null,
  fetchAssetsData: Map(),
  loading: false,
  show: false,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.WORK_ORDER_DIALOG_SHOW:
      return state.set('show', true)
                  .set('fetchAssetsData', Map())
                  .set('operationInfo', action.payload.operationInfo)
                  .set('selectedWorkorders', action.payload.selectedWorkorders);

    case actionTypes.WORK_ORDER_DIALOG_RESET_ASSETS_FOR_WO:
      return state.set('fetchAssetsData', Map());

    case actionTypes.WORK_ORDER_DIALOG_FETCH_ASSETS_FOR_WO_IN_PROGRESS:
      return state.set('loading', true);

    case actionTypes.WORK_ORDER_DIALOG_FETCH_ASSETS_FOR_WO_FINISHED:
      return state.set('loading', false)
                  .setIn(['fetchAssetsData', action.payload.woId], action.payload.assets);

    default:
      return defaultDialogReducer(state, action, dialogsNames.WORK_ORDER_DIALOG, defaultState);
  }
}








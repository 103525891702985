import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import Button from '../controls/Button/button.js'
import TextField from '../controls/TextField/textField.js';
//Style
require('./loginLayout.scss');

//Images
const logoImg = require("images/login-logo.png");
const bgImg = require("images/login-bg.png");
//<img src={bgImg} alt="background logo"  />
export class LoginLayout extends Component{

  renderButton = () => {
    if (!this.props.actionButtonText) {
      return null;
    }
    return <Button id='login' bsStyle="primary" type="submit" loading={this.props.loading} onlyLoading={true} tabIndex="10">{this.props.actionButtonText}</Button>
  }

  render() {
    return (
      <div className={this.props.formClass + " login-layout"}>

        <div className="login-page-img-bg" onKeyPress={this.props.secretKey} tabIndex="1000"></div>
        <div className="login-body">
          <div className="login-top-gradient"></div>
          <div className="login-body-inline">
            <div className="logo"><img src={logoImg} alt="login logo"/></div>

            <form className="form" onSubmit={this.props.actionButtonOnclick}>
              {this.props.children}

              {this.renderButton()}
            </form>



            {this.props.footerComponent}
            </div>
        </div>
      </div>
    );
  }
}

export class LoginInput extends Component {
  render() {
    let {label, ...otherProps} = this.props;
    return (
      <div className="login-row">
        <label className="textfield-label">{label}</label>
        <TextField {...otherProps} />
      </div>
    )
  }
}

export class LoginGoLoginPageFooter extends Component{
  render (){
    return <div className="footer">
      <Link to='/Login' className="pointer" >Go back to login page</Link>
    </div>;
  }
}




import {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Header, {headerItemsLabels} from '../../../../Common/Header/header';

require('./sectionSettingsForm.scss');

const maxLength30 = Validation.maxLength(30);

const SectionSettingsForm  = ({
   initialize,
   pristine,
   handleSubmit,
   actions,
   itemToEdit,
   onSave,
   onDelete,

   }) => {

  const labels = useMemo(() => createLabelHelper('mat.administration.matsettings.digitalTwin.section.form.'), []);

  useEffect(() => {
    initFormValues();
  }, [itemToEdit]);

  const initFormValues = () =>  {
    let initialValues =  isEditMode() ? {sectionName: itemToEdit?.name} : {};
    initialize(initialValues);
  }

  const isEditMode = () => {
    return itemToEdit?.id;
  }

  //the root section is not editable
  const isReadOnly = () => {
    return itemToEdit && itemToEdit.id && itemToEdit.parentId === null;
  }

  const onSubmit = (data) => {
    let newData = {
      name: data.sectionName,
      parentId: itemToEdit.parentId,
      prevSiblingId: null,
      nextSiblingId: null,
    };

    if (isEditMode()) {
      newData.id = itemToEdit.id;
      newData.parentId = null;  // do not send parentId on Edit (Server side request)
    }

    actions?.viewActions?.submit(newData, isEditMode(), false, true)
      .then((response) => {
        if (response && response.success) {
          onSave?.(response.data);
        }
      });
  };

  const onDeleteHandler = () => {
    actions?.viewActions?.delete(itemToEdit.id).then((response) => {
      if (response && response.success) {
        onDelete?.();
      }
    });
  }

  const getHeaderItems = () => {
    return {
      title: isReadOnly() ? ' ' : isEditMode() ? labels.get('title.edit') : labels.get('title.create'),
      buttons: [
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: pristine || isReadOnly(),
          action: handleSubmit(onSubmit),
        },
        ...(isEditMode() ? [
          {
            id: 'delete',
            label: headerItemsLabels.DELETE,
            className: 'no-icon',
            disabled: isReadOnly(),
            action: onDeleteHandler
          }]: []),
      ]
    }
  };

  let headerItems = getHeaderItems();

  return (
    <div className='section-settings-form'>
      <Header {...headerItems}/>

      <div className='section'>
        <InputSection label={labels.get('name')+'*'} htmlFor="sectionName" >
            <TextField id="sectionName" name="sectionName"
                       maxLength={30}
                       validate={[Validation.required, maxLength30]}
                       disabled={isEditMode() && itemToEdit && !itemToEdit.parentId}
            />
        </InputSection>
        <InputSection/>
      </div>
    </div>
  );
}

SectionSettingsForm.propTypes = {
  itemToEdit: PropTypes.object,
  actions : PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  pristine: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

SectionSettingsForm.defaultProps = {
  itemToEdit: null,
  handleSubmit: () => {
    console.error('handleSubmit is missing!');
  },
  initialize: () => {
    console.error('initialize is missing!');
  },
  pristine: true,
  onSave: () => {},
  onDelete: () => {},
}

export default reduxForm({
    form: 'sectionSettingsForm',
  }
)(SectionSettingsForm);





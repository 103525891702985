import * as toolsService from '../../services/Tools/toolsService';
import {gridsNames} from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {navigationStates} from '../../enums/navigationStates';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS_SCHEDULE, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return toolsService.fetchActiveToolsSchedule(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let labels = createLabelHelper('mat.activeToolsSchedule.grid.moreInfo.');

  let items = rows.map((obj) => {

    let {   toolType, plannedTool, operationStatus,
            plannedStartTime, plannedEndTime, plannedDuration, plannedResource,
            actualStartTime,  actualEndTime,  resource, validationMessages, toolCategory
    } = obj;
    let data = Object.assign({}, obj.task,{  toolType, plannedTool, operationStatus,
                                                          plannedStartTime, plannedEndTime, plannedDuration, plannedResource,
                                                          actualStartTime,  actualEndTime,  resource, validationMessages, toolCategory
                                              }) ;

    data.isRowMaster = true;
    data.extendedDetails = [
      {title: labels.get('plannedDuration'), value: data.plannedDuration ? DateTimeHelper.ConvertMinutesToHoursMinutes(data.plannedDuration) : ' '},
      {title: labels.get('startTime'), value: data.actualStartTime && data.actualStartTime.epochDateTime ? DateTimeHelper.DateTimeFormat(data.actualStartTime.epochDateTime * 1000) : ' '},
      {title: labels.get('endTime'),   value: data.actualEndTime && data.actualEndTime.epochDateTime ? DateTimeHelper.DateTimeFormat(data.actualEndTime.epochDateTime * 1000) : ' '},
      {title: labels.get('resource'), value: resource ? resource.name : '', isLink: true, id: resource ? resource.id : 0, type: navigationStates.STATION },

      {title: labels.get('duration'), value: data.duration ? DateTimeHelper.ConvertMinutesToHoursMinutes(data.duration) : ' '},
      {title: labels.get('kitType'), value: data.kitType ? data.kitType.name : ' '},
    ];
    return data;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

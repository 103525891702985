import { Map } from 'immutable';
import {dialogsNames} from '../../../enums/dialogsNames';
import defaultDialogReducer from '../../defaultDialogReducer';
import { actionTypes as actionTypes } from '../../../actions/Dialogs/CreateGroupDialog/createGroupDialogActions.js';


let defaultState = {
  show: false,
  loading: false,
  dialogData: null,
  preselectedWO: null,
  fetchAssetsData: Map(),
};

export default function(state = Map(defaultState), action) {

  switch(action.type) {

    case actionTypes.CREATE_GROUP_DIALOG_SHOW:
      return state.set('show', true)
                  .set('preselectedWO', action.payload ? action.payload.preselectedWO : null);

    case actionTypes.CREATE_GROUP_DIALOG_FETCH_ASSETS_FOR_WO_FINISHED:
      return state.set('loading', false)
                  .setIn(['fetchAssetsData', action.payload.woId], action.payload.assets);

    case actionTypes.CREATE_GROUP_DIALOG_RESET_ASSETS_FOR_WO:
      return state.set('fetchAssetsData', Map());

    default:
      return defaultDialogReducer(state, action, dialogsNames.CREATE_GROUP_DIALOG, defaultState);
  }
}



import React from 'react';
import PropTypes from 'prop-types';

import Normalize from "../../controls/controlsNormalizations";
import {PL_TextField} from 'infrastructure/js/components/controls/TextField/textField';
import classNames from "classnames";
//Style
require('./editableMultiCell.scss');

export default class EditableMultiCell extends React.PureComponent {

  constructor(props) {
    super(props);
    this.inputRefs = this.props.value.dataList.map(() => React.createRef());
    this.state = {values: this.props.value.dataList.map((item) => {
        return item.value
      })}
  }

  // grid api function
  afterGuiAttached(){
    const clickedCellIndex = this.props?.context?.clickedCellIndex
    if(clickedCellIndex >= 0 && this.inputRefs[clickedCellIndex]){
      this.inputRefs[clickedCellIndex].focus()
    }
  }

// grid api function
  getValue() {
    return this.state.values;
  }

  onBlur = () => {
    //grids api
    this.props.shouldStopEditingOnBlur && this.props.api.stopEditing();
  };

  onChange = (value, index) => {
    if(this.props.inputType === 'numeric'){
      value =  Normalize.number(this.props.isInt, 0, this.props.maxLength)(value, this.state.values[index])
    }

    let valuesCopy = JSON.parse(JSON.stringify(this.state.values));
    valuesCopy[index] = value;
    this.setState({values: valuesCopy})
  };

  render() {
    if (this.props.value.dataList) {
      let maxLengthString = this.props.inputType === 'string' ? this.props.maxLength : null;
      return (<div className="multi-grid-cell-editor">
        {this.props.value.dataList.map((item, index) => {
          return (<div className={classNames("editable-cell", this.props.isMulti ? "multi-row-cell" : '')} key={index}>

              <PL_TextField id={this.props.column.colId + this.props.rowIndex + 'index' + index}
                            name={this.props.column.colId + this.props.rowIndex + 'index' + index}
                            className="inline"
                            onChangeCallback={(value) => this.onChange(value, index)}
                            maxLength={maxLengthString}
                            onBlur={this.onBlur}
                            value={this.state.values[index]}
                            passive={true}
                            inputRef={ref => this.inputRefs[index] = ref}/>
            </div>
          )
        })}
      </div>);
    }
  }
}

EditableMultiCell.defaultProps = {
  inputType: 'string',
  isInt: false,
  shouldStopEditingOnBlur: true
};

EditableMultiCell.propTypes = {
  inputType: PropTypes.string,
  maxLength: PropTypes.number,
  isInt:PropTypes.bool
};

import React, { Component } from 'react';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import { reduxForm } from 'redux-form';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import EntityRangeListItem from './EntityRangeListItem';
import AddRemoveListExt from '../../../Common/Controls/AddRemoveListExt/addRemoveListExt';

import './SchedulerAnalyticModeSettingsDialog.scss';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';

class SchedulerAnalyticModeSettingsDialog extends Component {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.scheduler.analyticMode.settingsDialog.');

    this.state = {
      selectedTab: '1',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.sData.get('dialogData') || !this.props.pristine) {
      return;
    }

    let initialValues = {
      workOrders: this.props.sData.get('dialogData')?.preselectedEntities?.workOrders,
      stations: this.props.sData.get('dialogData')?.preselectedEntities?.stations,
      tools: this.props.sData.get('dialogData')?.preselectedEntities?.tools,
    };
    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          disabled: this.props.sData.get('loading'),
          text: this.labels.get('footer.update'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
        },
      ],
    };
  }

  onTabClick = (key) => {
    this.props.handleSubmit((formData) => {
      this.setState({ selectedTab: key });
    })();
  };

  renderTabHeaders = (entityTypeHeader) => {
    return (
      <div className={'tab-headers'}>
        <label className="column">{entityTypeHeader}</label>
        <label className="column">{this.labels.get('tabHeader.minInstances')}</label>
        <label className="column">{this.labels.get('tabHeader.maxInstances')}</label>
      </div>
    );
  };

  renderEntitiesList = (props) => {
    return (
      <AddRemoveListExt
        {...props}
        className="entities-list"
        itemRendererComponent={EntityRangeListItem}
        addButtonLabel={'add'}
        showRemoveAllButton={true}
        removeAllButtonLabel={'remove'}
        hideAddAtButton={true}
      />
    );
  };

  renderWorkordersTab = () => {
    const entitiesListData = {
      name: 'workOrders',
      itemRendererOptions: this.props.sData.get('dialogData')?.workOrders,
      preSelectedItems: this.props.sData.get('dialogData')?.preselectedEntities?.workOrders,
      preSelectedItemsIds:
        this.props.sData
          .get('dialogData')
          ?.preselectedEntities?.workOrders?.map((wo) => wo.objectId) || [],
    };

    return (
      <Tabs.Tab
        className="tab-wrapper"
        eventKey="1"
        title={this.labels.get('tabTitle.workOrders')}
        animation={false}
      >
        {this.renderTabHeaders(this.labels.get('tabHeader.workOrders'))}
        {this.renderEntitiesList(entitiesListData)}
      </Tabs.Tab>
    );
  };

  renderStationsTab = () => {
    const entitiesListData = {
      name: 'stations',
      itemRendererOptions: this.props.sData.get('dialogData')?.stations,
      preSelectedItems: this.props.sData.get('dialogData')?.preselectedEntities?.stations,
      preSelectedItemsIds:
        this.props.sData
          .get('dialogData')
          ?.preselectedEntities?.stations?.map((station) => station.objectId) || [],
    };

    return (
      <Tabs.Tab
        className="tab-wrapper"
        eventKey="2"
        title={this.labels.get('tabTitle.stations')}
        animation={false}
      >
        {this.renderTabHeaders(this.labels.get('tabHeader.stations'))}
        {this.renderEntitiesList(entitiesListData)}
      </Tabs.Tab>
    );
  };

  renderToolsTab = () => {
    const entitiesListData = {
      name: 'tools',
      itemRendererOptions: this.props.sData.get('dialogData')?.tools,
      preSelectedItems: this.props.sData.get('dialogData')?.preselectedEntities?.tools,
      preSelectedItemsIds:
        this.props.sData
          .get('dialogData')
          ?.preselectedEntities?.tools?.map((tool) => tool.objectId) || [],
    };

    return (
      <Tabs.Tab
        className="tab-wrapper"
        eventKey="3"
        title={this.labels.get('tabTitle.tools')}
        animation={false}
      >
        {this.renderTabHeaders(this.labels.get('tabHeader.tools'))}
        {this.renderEntitiesList(entitiesListData)}
      </Tabs.Tab>
    );
  };

  onEntered = () => {
    this.props.actions.fetchDialogData();
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onSubmit = (data) => {
    const { workOrders = [], stations = [], tools = [] } = data;

    // add objectType to each entity according to type of entity, then flatten the list.
    const flatEntitiesArray = [
      ...workOrders?.map((wo) => ({ ...wo, objectType: 'WO' })),
      ...stations?.map((station) => ({ ...station, objectType: 'STATION' })),
      ...tools?.map((tool) => ({ ...tool, objectType: 'TOOL' })),
    ];

    this.props.actions.submit({ schedulingPermutationSettingsList: flatEntitiesArray });
  };

  render() {
    const loading = this.props.sData.get('loading');
    const dialogData = this.props.sData.get('dialogData');
    return (
      <Dialog
        id="scheduler-analytic-mode-settings-dialog"
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        onEntered={this.onEntered}
        className="scheduler-analytic-mode-settings-dialog"
        sData={this.props.sData}
        titleText={this.labels.get('title')}
        // footerValidationCheckbox={this.getFooterValidationCheckBox()}
        // footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        {loading ? <Overlay.Loading /> : null}
        {dialogData ? (
          <Tabs
            activeKey={this.state.selectedTab}
            tabType="default"
            onSelect={this.onTabClick}
            id="content-tabs"
            mountOnEnter={false}
            unmountOnExit={false}
          >
            {this.renderWorkordersTab()}
            {this.renderStationsTab()}
            {this.renderToolsTab()}
          </Tabs>
        ) : null}
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'schedulerAnalyticModeSettings',
  onChange: (values, dispatch, props, previousValues) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
})(SchedulerAnalyticModeSettingsDialog);

import { Map, List, fromJS } from 'immutable';
import { actionTypes } from '../../../actions/Administration/MatSettingsPage/shiftsSchedulerActions';

let defaultState = {
  loadingData: false,
  loadingShifts: false,
  shiftTypes: List(),
  shiftsSchedulerData: List()
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.SHIFTS_SCHEDULER_UPDATE_DATA_IN_PROGRESS:
      return state.set('loadingData', true);

    case actionTypes.SHIFTS_SCHEDULER_UPDATE_DATA_FINISHED:
      return state.set('loadingData', false);

    case actionTypes.SHIFTS_SCHEDULER_FETCH_DATA_IN_PROGRESS:
      return state.set('loadingData',true);

    case actionTypes.SHIFTS_SCHEDULER_FETCH_DATA_FINISHED:
      return state.set('loadingData', false)
        .set('shiftsSchedulerData', action.payload);

    case actionTypes.SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_IN_PROGRESS:
      return state.set('loadingShifts', true);

    case actionTypes.SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_FINISHED:
      return state.set('shiftTypes', action.payload)
        .set('loadingShifts', false);

    case actionTypes.SHIFTS_SCHEDULER_UNMOUNT:
      return Map(defaultState)

    default:
      return state
  }

}








import Network from 'infrastructure/js/modules/network';
import { api as gridApi} from '../readersAndAntennasViewGridActions';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as readersAndAntennasService from '../../../../services/Rfid/readersAndAntennasService';
import * as deviceAppsService from '../../../../services/Rfid/deviceAppsService';
import * as locationsService from "../../../../services/Locations/locationsService";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_READER_DIALOG_SHOW:               'CREATE_READER_DIALOG_SHOW',
  CREATE_READER_DIALOG_HIDE:               'CREATE_READER_DIALOG_HIDE',
  CREATE_READER_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_READER_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_READER_DIALOG_SUBMIT_FINISHED:    'CREATE_READER_DIALOG_SUBMIT_FINISHED',
  CREATE_READER_DIALOG_FETCH_DATA_READY:   'CREATE_READER_DIALOG_FETCH_DATA_READY',
  SUBMIT_VALIDATION_FAILED: 'SUBMIT_VALIDATION_FAILED',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_READER_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_READER_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_READER_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_READER_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDialogDataReady: function(payload) {
    return {type: actionTypes.CREATE_READER_DIALOG_FETCH_DATA_READY, payload: payload };
  },
  submitValidationFailed  : function(payload) {
    return {type: actionTypes.SUBMIT_VALIDATION_FAILED, payload: payload };
  }
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(deviceApp ,data, isEditMode) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(deviceApp ,data, isEditMode);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    api.fetchDialogData(dispatch, getState)().then((response) => {
      dispatch(actions.show(data));
    });
  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function() {
    return locationsService.fetchActiveLocations().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Locations', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let dialogData = {
        locations: response.dataList.map((obj) => {return {value: obj.id, label: obj.name, data: obj}}),
      };

      dispatch(actions.fetchDialogDataReady(dialogData));
      return dialogData;
    });
  }
};

api.submit = function(dispatch, getState) {
  return function(deviceApp ,data, isEditMode) {

    dispatch(actions.submitInProgress());

    let quaryData = {
      deviceOrgBusinessId :getState().login.get('loggedInUser').loggedInOrg.businessId,
      deviceBusinessId: deviceApp.label
    };

    return deviceAppsService.getDeviceProfile(quaryData).then((res) => {

      let method = isEditMode ? readersAndAntennasService.editReader : readersAndAntennasService.createReader;

      method(!isEditMode ? res.data.profileId : data.id ,data).then((response) => {

        if (utility.IsResponseErrorAntennasDuplicateName(response)) {
          let {statusType , message} = response.applicationResponseStatus;

          let dialogMessageData = {
            severity : statusType.toLowerCase(),
            icon: 'pl pl-error-icon',
            message : message
          };

          dispatch(actions.submitValidationFailed(dialogMessageData));
          return;
        }

        dispatch(actions.submitFinished());
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Create/Edit Reader failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        gridApi.reload(dispatch, getState)();

      });

    });
  };
};

let utility = {};

utility.IsResponseErrorAntennasDuplicateName = function (response) {
  let {statusType , errorCode} = response.applicationResponseStatus;
  return statusType === 'ERROR' && errorCode === 'MAT_200100_B';
};


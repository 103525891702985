import { Map } from 'immutable';
import { actionTypes as preferenceViewActions } from '../../../actions/Administration/SettingsPage/preferenceViewActions';

let defaultState = {
  loading : false,
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

   /* case preferenceViewActions.ADMINISTRATION_SETTINGS_PREFERENCES_FETCH_PROCESS:
      return state.set('selectedContentTab', action.payload);

    case preferenceViewActions.ADMINISTRATION_SETTINGS_PREFERENCES_FETCH_READY:
      return state.set('loading', true);*/

    default:
      return state;
  }
}













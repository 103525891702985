import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

export function getTAskAlertCategoryLabel (category) {

  let categoryLabels = createLabelHelper('enum.scheduler.warning.type.');

  switch (category) {
    case 'DUE_DATE_CONFLICT' :
      return categoryLabels.get('DUE_DATE_CONFLICT');
    case 'DEPENDENCY_CONFLICT' :
      return categoryLabels.get('DEPENDENCY_CONFLICT');
    case 'STATION_AVAILABILITY_CONFLICT' :
      return categoryLabels.get('STATION_AVAILABILITY_CONFLICT');
    case 'TOOL_AVAILABILITY_CONFLICT' :
      return categoryLabels.get('TOOL_AVAILABILITY_CONFLICT');
    case 'PASSED_SCHEDULE_TIME' :
      return categoryLabels.get('PASSED_SCHEDULE_TIME');
    default:
      console.error('Unknown task alert category: ', category);
  }
  return '';
}

import { actionTypes as twoDMappingPageActionTypes } from '../../../actions/Reports/twoDMappingPageActions';
import {Map} from 'immutable';

let defaultState = {
  loading: false,
  items : [],
  alertsLoading: false,
  woLoading: false,
  alerts: [],
  wos: []
};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case twoDMappingPageActionTypes.TWODMAPPINGPAGE_FETCHDATA_STARTED:
      return state.set('loading', action.loadFirstTime);

    case twoDMappingPageActionTypes.TWODMAPPINGPAGE_FETCHDATA_FINISHED:
      return state.set('items', action.items).set('loading', false);

    case twoDMappingPageActionTypes.TWODMAPPINGPAGE_FETCH_WO_COUNT_STARTED:
      return state.set('woLoading', action.loadFirstTime);

    case twoDMappingPageActionTypes.TWODMAPPINGPAGE_FETCH_WO_COUNT_FINISHED:
      return state.set('wos', action.items).set('woLoading', false);

    case twoDMappingPageActionTypes.TWODMAPPINGPAGE_FETCH_ALERTS_STARTED:
      return state.set('alertsLoading', action.loadFirstTime);

    case twoDMappingPageActionTypes.TWODMAPPINGPAGE_FETCH_ALERTS_FINISHED:
      return state.set('alerts', action.items).set('alertsLoading', false);

    case twoDMappingPageActionTypes.TWODMAPPINGPAGE_UNMOUNT:
      return Map(defaultState);

    default:
      return state;
  }
}



import React from 'react';
import Label from 'infrastructure/js/components/Label/label.js';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./componentWithErrorCell.scss');

export default class ComponentWithErrorCell extends React.PureComponent {

  constructor(props){
    super(props);

    this.labels = createLabelHelper('alerts.titles.');
  }

  render() {
    let {value} = this.props;
    if (value) {
      if (value.errorMessage) {
        return <div className="component-with-error-cell">
          <AlertPopover
            popoverType={'error'}
            popoverComponent={<span>{value.errorMessage}</span>}
          />
          <Label text={value.errorMessage} className="error-message"/>
        </div>
      }

      return <value.componentType value={value.params}/>;
    }
    return null;
  }
}










import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import GenerateButton from 'infrastructure/js/components/controls/GenerateButton/generateButton';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import AdditionalField from '../../../../Common/Layout/AdditionalField/AdditionalField';
import isEmpty from 'lodash-es/isEmpty';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import ToolTypeHelper from '../../../../../utils/toolTypeHelper';
import SingleLineMultiSelect from 'infrastructure/js/components/controls/SingleLineMultiSelect/singleLineMultiSelect.js';

require('./createToolDialog.scss');

class CreateTool extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.createToolDialog.');
  }
  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.labels.get('footer.createTool'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
        },
      ],
    };
  }

  onSubmit = (data) => {
    data.additionalFieldsDefinitions = this.props.sData.get('additionalFields');
    //check if alert enabeld?
    data.maintenanceUsageTime = DateTimeHelper.ConvertDaysHoursMinutesToMinutes(
      data.maintenanceUsageTime_days,
      data.maintenanceUsageTime_hours,
      data.maintenanceUsageTime_minutes
    );
    this.props.actions.submit(data, this.getMessageDialogBuilder());
  };

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {
      let rows = [
        { label: this.labels.get('confirmation.message.toolId'), value: data.toolId },
        { label: this.labels.get('confirmation.message.toolType'), value: data.toolType.label },
      ];
      let children;
      if (data) {
        children = rows.map((item, index) => {
          return (
            <MessageDialog.DataRow
              key={`confirmMessageDataRow${index}`}
              label={item.label}
              value={item.value}
            />
          );
        });
      }

      let title = this.labels.get('confirmation.header');
      let buttons = [
        {
          id: 'close',
          text: this.dialogLabels.get('close'),
          action: fnCloseDialog,
          bsStyle: 'primary',
        },
      ];
      let messageDialogDescriptor = { title, children, buttons };
      return messageDialogDescriptor;
    };
  };

  onEntered = () => {
    this.initData();
  };
  onHide = () => {
    this.props.actions.hide();
  };

  initData = () => {
    this.props.actions.fetchDialogData();
  };

  generateToolId = () => {
    this.props.actions.generateToolId().then((response) => {
      this.props.change('toolId', response.data);
    });
  };

  renderAdditionalFields = () => {
    if (!this.props.sData.get('additionalFields')) {
      return null;
    }
    return this.props.sData.get('additionalFields').map((field, index) => {
      return <AdditionalField key={index} field={field} index={index} />;
    });
  };

  getToolTypesOptions = () => {
    const toolypes = this.props.sData.get('toolTypes');
    return toolypes.map((toolType) => ({
      ...toolType,
      label: ToolTypeHelper.getToolTypeFullLabel(
        toolType.data.description,
        toolType.data.businessId
      ),
    }));
  };

  onToolTypeChanged = ({ data }) => {
    const usageTimeObj = DateTimeHelper.ConvertMinutesToDaysHoursMinutesObject(
      data?.maxManufactureUsageTime ?? null
    );
    this.props.change('maintenanceUsageTime_days', usageTimeObj.days || '');
    this.props.change('maintenanceUsageTime_hours', usageTimeObj.hours || '');
    this.props.change('maintenanceUsageTime_minutes', usageTimeObj.minutes || '');
    this.props.change('cycleCount', data?.maxNumOfCycles ?? null);
  };

  maxLengthDropDown = Validation.dropdown.maxLength(30);
  render() {
    let bodyClassName = isEmpty(this.props.sData.get('additionalFields'))
      ? ''
      : 'modal-body-overflow';
    return (
      <Dialog
        id="create-tool-dialog"
        bodyClassName={bodyClassName}
        className="create-tool-dialog"
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get('header.title')}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        {/*<div className="create-tool-dialog">*/}
          <InputSection
            label={this.labels.get('toolId') + '*'}
            htmlFor="toolId"
            className="inline left-side"
          >
            <GenerateButton
              id="toolId"
              name="toolId"
              className="toolId"
              placeholder={this.labels.get('toolIdPlaceholder')}
              validate={Validation.required}
              maxLength={30}
              onClick={this.generateToolId}
              buttonText={this.labels.get('generatebuttontext')}
            />
          </InputSection>

          <InputSection
            label={this.labels.get('toolType') + '*'}
            htmlFor="toolType"
            className="inline right-side"
          >
            <Combobox
              id="toolType"
              name="toolType"
              options={this.getToolTypesOptions()}
              validate={[Validation.dropdown.required, this.maxLengthDropDown]}
              allowNewOption={true}
              onChangeCallback={this.onToolTypeChanged}
            />
          </InputSection>

          <InputSection label={this.labels.get('tag')} htmlFor="tags" className="inline left-side">
            <SingleLineMultiSelect
              id="tags"
              name="tags"
              limit={10}
              options={this.props.sData.get('tags')}
              value={[]}
              onChange={(value) => {
                this.props.change('tags', value);
              }}
            />
          </InputSection>

          <InputSection
            label={this.labels.get('cyclecount')}
            htmlFor="width"
            className="inline right-side"
          >
            <TextField
              id="cycleCount"
              name="cycleCount"
              className="inline right-side"
              normalize={Normalize.number(true, 0, 9999)}
            />
          </InputSection>
          <InputSection
            label={this.labels.get('maintenanceUsageTime')}
            htmlFor="maintenanceUsageTime"
            className="inline left-side"
          >
            <TimeField
              id="maintenanceUsageTime"
              name="maintenanceUsageTime"
              className="inline right-side"
              isDays={true}
              hoursCount={true}
            />
          </InputSection>
          <div>{this.renderAdditionalFields()}</div>
        {/*</div>*/}
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'createTool',
})(CreateTool);

import {fromJS, Map} from 'immutable';
import { actionTypes as createAssetTypeWizardActionsType } from '../../../actions/Administration/MatSettingsPage/Dialogs/createAssetTypeWizardActions.js';

let defaultState = {
  show: false,
  loading: false,
  initialData: null,
  stepOneData: null,
  stepTwoData: null,

  startParameters: {
    shouldRefreshPageOnCancel: false
  },
};

export default function(state = fromJS(defaultState), action) {

  switch (action.type) {

    case createAssetTypeWizardActionsType.CREATEASSETTYPE_WIZARD_FETCH_INITIAL_DATA_READY:
      return state.set('initialData', action.payload);

    // case createAssetTypeWizardActionsType.CREATEASSETTYPE_WIZARD_SAVE_START_PARAMETERS:
    //   return state.set('startParameters', action.payload);

    case createAssetTypeWizardActionsType.CREATEASSETTYPE_WIZARD_SAVE_STEP_ONE_DATA:
      return state.set('stepOneData', action.payload);

    case createAssetTypeWizardActionsType.CREATEASSETTYPE_WIZARD_FETCH_STEP_TWO_DATA_READY:
      return state.set('stepTwoData', action.payload);

    case createAssetTypeWizardActionsType.CREATEASSETTYPE_WIZARD_RESET:
      return fromJS(defaultState); // Reset state completely.

    case createAssetTypeWizardActionsType.CREATEASSETTYPE_WIZARD_SUBMIT_STARTED:
      return state.set('loading', true);

      case createAssetTypeWizardActionsType.CREATEASSETTYPE_WIZARD_SUBMIT_FINISHED:
      return state.set('loading', false);

    default:
      return state;
  }

}








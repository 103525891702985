import React from 'react';
import PropTypes, {node, oneOf, string} from 'prop-types';

import { FormWizardDialog } from 'infrastructure/js/components/Dialog/WizardDialog/wizardDialog.js';
import {createLabelHelper}  from 'infrastructure/js/utils/labelHelper';
import MessageDialog        from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';

import FileUploadStep from './Steps/FileUploadStep/fileUploadStep';
import SelectAttributesStep from './Steps/SelectAttributesStep/selectAttributesStep';
import AssetsValidateStep from '../Common/Steps/AssetsValidateStep/assetsValidateStep';
import UpdateAttributesPreviewStep from './Steps/UpdateAttributesPreviewStep/updateAttributesPreviewStep';
import {numberToLetters} from '../../Administration/SettingsPage/utils/csvSettingsHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';
import {ImportTypes} from '../../../enums/importTypes';

require('./importAttributesUpdateWizard.scss');

export default class ImportAttributesUpdateWizard extends React.PureComponent {

  constructor(props) {
    super(props);

    // this.labels = this.props.labels;
    this.labels = this.getLabelsByImportType();
    this.wizardLabels = createLabelHelper('mat.dialog.wizard.');
    this.assetType = this.getAssetTypeByImportType();
    this.operationStatusLabels = createLabelHelper(`mat.wizards.bulk.update.files.${this.assetType}.validation.grid.operationstatus.`);

    this.formComponent = FormWizardDialog('importAttributesUpdateWizard');
  }

  getLabelsByImportType = () => {
    switch(this.props.importType){
      case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_ROLL'):
        return createLabelHelper('mat.wizards.importAttributesUpdate.');
      case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_SPOOL'):
        return createLabelHelper('mat.wizards.importSpoolAttributesUpdate.');
      case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_PART_TYPE'):
      case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_KIT_TYPE'):
        return createLabelHelper('mat.wizards.importWOAttributesUpdate.');
      default:
        console.log('getLabelsByImportType(): unknown import type: ', this.props.importType);
        return null;
    }
  }

  getAssetTypeByImportType = () => {
    switch(this.props.importType){
      case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_ROLL'):
        return ImportTypes.IMPORT_ROLLS;
      case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_SPOOL'):
        return ImportTypes.IMPORT_SPOOLS;
      case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_PART_TYPE'):
      case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_KIT_TYPE'):
        return ImportTypes.IMPORT_WO
      default:
        return ''
    }
  }

  getSteps = () => {
    let {sData} = this.props;

    return [

      {
        name: this.labels.get('step1.name'),
        component: (props) => <FileUploadStep assetType={this.assetType} {...props} {...this.props} labels={this.labels}/>,
      },
      {
        name: this.labels.get('step3.name'),
        component: (props) => <SelectAttributesStep {...props} csvSettingsData={sData.get('csvSettingsData')} labels={this.labels} />,
      },
      {
        name: this.labels.get('step2.name'),
        component: (props) => <AssetsValidateStep {...props} assetType={this.assetType} validatedFileData={sData.get('validatedFileData')} labels={this.labels} operationStatusLabels={this.operationStatusLabels}/>,
      },
      {
        name: this.labels.get('step4.name'),
        component: (props) => <UpdateAttributesPreviewStep {...props} attributesPreviewData={sData.get('attributesPreviewData')} setRef={this.setRefCallback} labels={this.labels}/>,
      },
    ];
  };

  getCloseConfirmationDialog = () => {
    return (
      {
        titleText: this.wizardLabels.get('closeConfirmation.title'),
        contents:
          <div>
            <MessageDialog.DataRow key={'row1'} label={this.wizardLabels.get('closeConfirmation.line1')} value={''}/>
            <MessageDialog.DataRow key={'row2'} label={this.wizardLabels.get('closeConfirmation.line2')} value={''}/>
          </div> ,
      }
    );
  }

  setRefCallback = (step4Ref) => {
    this.step4Ref = step4Ref;
  }

  onHide = () => {
    this.props.actions.hide(this.props.importType);
  };

  onSubmit = (data, dispatch, props, nextTab, stepIndex) => {
    const { importType } = this.props;
    if (stepIndex === 0) {
      this.props.actions.uploadFile(nextTab, importType);
    }
    if (stepIndex === 1) {
      let newData = {
        importType,
        startingRowNum: 2,
        settings: this.convertToSettings(data.csvPropertiesList),
      };
      this.props.actions.saveBulkCsvSettings(newData, nextTab, importType);
    }
    else if (stepIndex === 2) {
      // let newData = this.getPreviewData(data);
      let validatedFileData = this.props.sData.get('validatedFileData');
      this.props.actions.getAttributesPreview(validatedFileData, nextTab, importType);
    }
    else if (stepIndex === 3) {
      if (this.step4Ref) {
        let newData = this.step4Ref.getSubmitData();
        newData.userFileName = data.selectedFileName;
        this.props.actions.submit(newData, importType, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
      }
    }
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get('confirmation.message.title', 'Confirmation', { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title, false);
    };
  };

  convertToSettings = (csvPropertiesList) => {
    let csvSettingsData = this.props.sData.get('csvSettingsData');
    let csvOptions = csvSettingsData.csvOptions;

    let res = csvPropertiesList.map((item, index) => {
      let matchOption = csvOptions.find((option) =>{return option.value === item.propertyName});
      return {
        optionId: item.propertyName,
        columnChar: numberToLetters(index+1),
        columnIndex: index+1,
        isAdditionalField: matchOption ? matchOption.data.isAdditionalField : false
      };
    }).filter((item) => {return !!item.optionId});

    return res;
  };


  // getPreviewData = (data) => {
  //   let validatedFileData = this.props.sData.get('validatedFileData');
  //   return (validatedFileData && validatedFileData.validAssetsBusinessIds) ? {validRollsBusinessIds: validatedFileData.validAssetsBusinessIds} : [];
  // }

  render() {
    let {sData} = this.props;

    return (
      <this.formComponent
        id="importAttributesUpdateWizard"
        tabType='wizard-horizontal'
        className="importAttributesUpdateWizard"
        labels={this.props.labels}
        show={sData.get('show')}
        titleText={this.labels.get('title')}
        loading={sData.get('loading')}
        steps={this.getSteps()}
        onClose={this.onHide}
        closeConfirmationDialog={this.getCloseConfirmationDialog()}
        defaultButtons={
          {
            onSubmit: this.onSubmit,
          }
        }
      />
    );
  }
}

ImportAttributesUpdateWizard.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  reloadParentComponent : PropTypes.func,
  // importType: PropTypes.string.isRequired,
  importType: oneOf([getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_ROLL'),
                     getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_SPOOL'),
                     getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_PART_TYPE'),
                     getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_KIT_TYPE'),
  ]).isRequired,
  // labels: PropTypes.object.isRequired
};

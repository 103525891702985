import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

require('./checkMarkCell.scss');

export default function CheckMarkCell(props) {

  return (<div className={classNames('check-mark-cell pl ',{'pl-check-icon-blue-hover' : props.value})}> </div>)
}

CheckMarkCell.propTypes = {
  value : PropTypes.bool
};





import React from 'react';
import PropTypes from 'prop-types';

import Label from 'infrastructure/js/components/Label/label.js';

import MaterialHelper from '../../../../../../../utils/materialHelper';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import {createLabelHelper} from "infrastructure/js/utils/labelHelper";
import PermissionManager from "infrastructure/js/utils/permissionManager";
require('./materialsCell.scss');

export default class MaterialsCell extends React.PureComponent {

  getSubstituteMaterials = (material) => {
    if (material && material.substituteMaterials && material.substituteMaterials.length > 0) {
      let sm = material.substituteMaterials.map((item) => {
        let fullMaterialLabel = item ? MaterialHelper.getMaterialFullLabel(item.materialName, item.businessId) : '';
        return (
          <div className="pl pl-substitute-material-mark substitute-material" key={item.id}>
            <Label text={fullMaterialLabel}/>
          </div>
        );
      });
      return sm;
    }
    return null;
  };

  renderLengthOrWeight = (material, lengthUnit, lengthLabel, weightUnit, weightLabel) => {
    if (material) {
      if(PermissionManager.isWeightSupported()){
        let weight = (material.weight || material.weight === 0) ? `${UnitHelper.serverValueToUserValue(unitTypes.WEIGHT,material.weight)} (${weightUnit})`: "";
        return <div className="info">{`${weightLabel} ${weight}`}</div>
      }
      else {
        let length = (material.length || material.length === 0) ? `${UnitHelper.serverValueToUserValue(unitTypes.LENGTH,material.length, 2)} (${lengthUnit})`: "";
        return <div className="info">{`${lengthLabel} ${length}`}</div>
      }

    }
    return null;
  };

  render() {
    let materials = this.props.value;
    if (!materials) {
      return null;
    }
    let lengthUnit = UnitHelper.getLabelForUnitType(unitTypes.LENGTH);
    let lengthLabel = createLabelHelper('mat.administration.matsettings.kittypes.grid.length').get('');
    let weightUnit = UnitHelper.getLabelForUnitType(unitTypes.WEIGHT);
    let weightLabel = createLabelHelper('mat.administration.matsettings.kittypes.grid.weight').get('');
    let materialRows = materials.map((item)=> {
      let fullMaterialLabel = MaterialHelper.getMaterialFullLabel(item.material.materialName, item.material.businessId);
      return (
      <div key={item.material.id} className={'materials-custom-cell'}>
        <Label className={'main-material'}
                           text={`${item.quantityExpectedPlies} x ${fullMaterialLabel}`}
                           tooltip={fullMaterialLabel} />

         {this.renderLengthOrWeight(item, lengthUnit, lengthLabel, weightUnit, weightLabel)}
         {this.getSubstituteMaterials(item)}
      </div>)
    });

    return (<div>
      {materialRows}
    </div>);
  }
}

MaterialsCell.propTypes = {
  value : PropTypes.array
};





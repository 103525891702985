import React from 'react';
import PropTypes from 'prop-types';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";

require('./lengthCell.scss');

export default class LengthCell extends React.PureComponent {

  getLength = (value) => {
    return UnitHelper.serverValueToUserValueWithLabel(unitTypes.LENGTH, value?.length || 0, 2);
  };

  render() {
    let {value} = this.props;

    return (<div className="length-cell">
      <span>{this.getLength(value)}</span>
    </div>);
  }
}

LengthCell.propTypes = {
  value: PropTypes.object,
};








import React from 'react';

import {} from 'prop-types';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { gridsNames } from '../../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

export default class ScanAssetsStep extends React.PureComponent {

  constructor(props) {
    super(props);
    this.columnLabels = createLabelHelper("");

    props.registerOnEnter(this.onEnter);
    this.inputRef = React.createRef();
    this.debounceTimeout = null;
    this.duplicateRowTimeout = null;
    this.state = {
      duplicateRow: null
    };
  }

  onEnter = () => {
    this.props.setNextButtonEnabled(this.props.sData.get("assets")?.length);

    this.inputRef.current?.focus();
  };

  componentDidUpdate(prevProps) {
    if (this.props.isCurrentStep) {
      let assetsAmount = this.props.sData.get("assets")?.length,
        prevAssetsAmount = prevProps.sData.get("assets")?.length;
      if (assetsAmount !== prevAssetsAmount) {
        this.props.setNextButtonEnabled(!!assetsAmount);
      }
    }
  }

  getConfig = (gridSettings) => {
    return [
      {
        fieldName: "index",
        title: this.props.labels.get('scanAssetsStep.columns.index'),
        filterType: filterTypes.NONE,
        width: 50,
        columnOptions: {
          sortable: false,
          valueGetter: (params) => {
            return params.node.rowIndex + 1;
          }
        }
      },
      ...gridSettings.map((setting) => {
        return {
          fieldName: setting.propertyName,
          title: setting.label,
          filterType: filterTypes.NONE,
          width: 200,
          columnOptions: {
            sortable: false,
            cellComponent: LabelWithTooltipCell,
            valueGetter: (params) => {
              return params.data?.[setting.propertyName]?.toString() || '';
            }
          }
        };
      })
    ];
  };

  restoreFocus = (event) => {
    if (this.props.isCurrentStep && document.activeElement !== event.target) {

      let id = event.relatedTarget?.id;

      if ( id === '' || (id && id !== 'globalMessageDialog' && !id.includes('_closeConfirmationDialog')) ) {
        this.inputRef.current?.focus();
      }
    }
  };

  onInputChanged = (event) => {
    if (null !== this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(this.debouncedInputChanged, 100, event.target);
  };

  debouncedInputChanged = (input) => {
    this.debounceTimeout = null;
    if (!input.value?.endsWith("\n")) {
      input.value = "";
      return;
    }
    //remove "\n" at the end of scanned data
    let scanData = input.value.substring(0, input.value.length - 1);

    let assets = this.props.sData.get("assets");
    let duplicateScanIndex = assets.findIndex((asset) => asset.scanData === scanData);
    if (-1 !== duplicateScanIndex) {
      this.setState({ duplicateRow: duplicateScanIndex });
      if (null !== this.duplicateRowTimeout) {
        clearTimeout(this.duplicateRowTimeout);
      }
      this.duplicateRowTimeout = setTimeout(this.finishSignalingDuplicateRow, 1400);
      input.value = "";
      return;
    }
    this.props.onBarcodeScanned(scanData);
    input.value = "";
  };

  finishSignalingDuplicateRow = () => {
    this.setState({ duplicateRow: null });
    this.duplicateRowTimeout = null;
  };

  render() {
    let gridSettings = this.props.sData.get("gridSettings");

    if (!gridSettings) {
      return null;
    }

    return <div className="height-100" >
      <div className="scan-assets-header">
        {this.props.labels.get('scanAssetsStep.header')}
      </div>
      <div className="scan-assets-grid">
        <Grid
          key={this.state.duplicateRow ?? -1}
          gridName={gridsNames.BARCODE_IMPORT_WIZARD_SCAN_ASSETS_GRID}
          columnsConfig={this.getConfig(gridSettings)}
          rows={this.props.sData.get('assets')}
          isStatic
          gridProps={
            {
              checkboxSelection: false,
              getRowClass: (params) => {
                if (params.node.rowIndex === this.state.duplicateRow) {
                  setTimeout(() => {params.api.ensureIndexVisible(this.state.duplicateRow)}, 0);
                  return "duplicate-row";
                }
                else {
                  return "";
                }
              }
            }
          }
        />
        <textarea ref={this.inputRef} autoFocus onBlur={this.restoreFocus} onChange={this.onInputChanged}
                  className="barcode-hidden-input"/>
      </div>
    </div>;
  }
}

import Network from 'infrastructure/js/modules/network';


export function fetchAutomationScenarios(id) {
  return Network.get(`deviceAppProfile/${id}/automatedScenarios`);
}

export function fetchQualifiedEvents() {
  return Network.get('automatedWorkflows/qualifiedEvents');
}

export function fetchActionSequence(id) {
  return Network.get('automatedWorkflows/actionSequences');
}

export function createAutomationScenario(data) {
  let deviceAppProfileId = data.deviceAppProfileId;
  delete data.deviceAppProfileId;
  return Network.post(`automatedWorkflows/deviceAppProfile/${deviceAppProfileId}/automatedScenario`, data);
}

export function editAutomationScenario(data) {
  let scenarioId = data.id;
  delete data.id;
  return Network.put(`automatedWorkflows/automatedScenarios/${scenarioId}`, data);
}

export function deleteAutomationScenarios(data) {
  return Network.delete('automatedWorkflows/automatedScenarios' , {scenariosIds: data});
}


import Network from 'infrastructure/js/modules/network';
import RoleService  from '../../services/Roles/roleService';
import * as LocationService from '../../services/Locations/locationsService';
import { api as messageDialogApi }  from '../MessageDialog/messageDialogActions';
import { api as loginApi }  from '../../actions/Login/loginActions';
import {jsxActions as userManagementPageActions} from '../../actions/Administration/UserManagementPage/userManagementPageActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';

export const actionTypes = {
  DIALOG_USERMANAGEMENT_SHOW : 'DIALOG_USERMANAGEMENT_SHOW',
  DIALOG_USERMANAGEMENT_HIDE : 'DIALOG_USERMANAGEMENT_HIDE',

  DIALOG_USERMANAGEMENT_IN_PROCESS : 'DIALOG_USERMANAGEMENT_IN_PROCESS',
  DIALOG_USERMANAGEMENT_FINISHED : 'DIALOG_USERMANAGEMENT_FINISHED',

  DIALOG_USERMANAGEMENT_FETCH_ROLES_IN_PROGRESS : 'DIALOG_USERMANAGEMENT_FETCH_ROLES_IN_PROGRESS',
  DIALOG_USERMANAGEMENT_FETCH_ROLES_FINISHED: 'DIALOG_USERMANAGEMENT_FETCH_ROLES_FINISHED',

  DIALOG_USERMANAGEMENT_FETCH_LOCATIONS_IN_PROGRESS : 'DIALOG_USERMANAGEMENT_FETCH_LOCATIONS_IN_PROGRESS',
  DIALOG_USERMANAGEMENT_FETCH_LOCATIONS_FINISHED: 'DIALOG_USERMANAGEMENT_FETCH_LOCATIONS_FINISHED',

  DIALOG_USERMANAGEMENT_SET_VALIDATION_WARNINGS_DATA: 'DIALOG_USERMANAGEMENT_SET_VALIDATION_WARNINGS_DATA',
  DIALOG_USERMANAGEMENT_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: 'DIALOG_USERMANAGEMENT_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
  DIALOG_USERMANAGEMENT_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: 'DIALOG_USERMANAGEMENT_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
};

const actions = {

  fetchRolesInProgress: function () {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_FETCH_ROLES_IN_PROGRESS};
  },
  fetchRolesFinished: function (payload) {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_FETCH_ROLES_FINISHED, payload};
  },
  fetchLocationsInProgress: function () {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_FETCH_LOCATIONS_IN_PROGRESS};
  },
  fetchLocationsFinished: function (payload) {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_FETCH_LOCATIONS_FINISHED, payload};
  },
  show: function (payload) {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_SHOW, payload};
  },
  hide: function () {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_HIDE};
  },
  updateInProcess: function () {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_IN_PROCESS};
  },
  updateFinished: function () {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_FINISHED};
  },

  setValidationWarningsData: function (payload) {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_SET_VALIDATION_WARNINGS_DATA, payload: payload};
  },
  hideIgnoreValidationWarningsCheckbox: function (payload) {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX, payload: payload};
  },
  toggleIgnoreValidationWarningsCheckbox(payload) {
    return {type: actionTypes.DIALOG_USERMANAGEMENT_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX, payload: payload};
  },
};

export let jsxActions = {};

jsxActions.fetchRoles = function () {
  return function(dispatch, getState) {
    api.fetchRoles()(dispatch, getState);
  }
};

jsxActions.onSubmit = function(userData , messageDialogBuilder, isLoggedInUser) {
  return function(dispatch, getState) {
    api.onSubmit(dispatch, getState)(userData, messageDialogBuilder, isLoggedInUser);
  }
};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    dispatch(actions.hide());
  }
};

jsxActions.hideIgnoreValidationWarningsCheckbox = function(value) {
  return function(dispatch, getState) {
    api.hideIgnoreValidationWarningsCheckbox(dispatch, getState)(value);
  };
};

jsxActions.toggleIgnoreValidationWarningsCheckbox = function(value) {
  return function(dispatch, getState) {
    api.toggleIgnoreValidationWarningsCheckbox(dispatch, getState)(value);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.fetchRoles = function () {
  return function(dispatch, getState) {
    dispatch(actions.fetchRolesInProgress());
    let roleServiceAction =  getState().system.get('featureFlags').ORGANIZATIONS ? RoleService.getSystemRoles() : RoleService.getRoles();

    return roleServiceAction.then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error("Get Roles Failed");
        return {success: false};
      }

      let roles = {
        dataList: response.dataList
      };
      dispatch(actions.fetchRolesFinished(roles));
    });
  }
};

api.fetchLocations = function () {
  return function(dispatch, getState) {
    dispatch(actions.fetchLocationsInProgress());
    return LocationService.fetchActiveLocations().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error("Get Locations Failed");
        return {success: false};
      }

      let locations = {
        dataList: response.dataList
      };
      dispatch(actions.fetchLocationsFinished(locations));
    });
  }
};

api.onSubmit = function(dispatch, getState) {
  return function(data, messageDialogBuilder, isLoggedInUser = false) {
    if(data.phone === '+'){
      data.phone = '';
    }
    let _data = {...data};
    dispatch(actions.updateInProcess());

    let serviceAction = getState().dialogs.getIn(['userManagementDialog' , 'serviceAction']);
    serviceAction(_data).then((response) => {

      dispatch(actions.updateFinished());

      let validations = dialogHelper.getResponseValidationDetails(response);
      if (validations){
        dispatch(actions.setValidationWarningsData(validations));
        return {success: false};
      }

      dispatch(actions.hide());

      if(!Network.isResponseValid(response)) {
        console.error('User Management Operation Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      //Note: workaround: update the local storage for the logged in user (for My Account dialog)
      // reload User Management Page for all others cases
      if (isLoggedInUser){
        if (response.data) {
          //localStorage['loggedInUser'] = JSON.stringify(response.data);
          loginApi.updateLoggedInUserData(response.data)(dispatch, getState);
        }
      }
      else {
        userManagementPageActions.reload()(dispatch, getState);
      }

      if (messageDialogBuilder) {
        let messageDialogDescriptor = messageDialogBuilder(
          response.data,
          messageDialogApi.close(dispatch, getState)
        );
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
      }
      return {success: true};
    });
  };
};

api.show = function(user, serviceAction) {
  return function(dispatch, getState) {

    let promiseRoles = PermissionManager.hasUserManagementTabPermissions() ? api.fetchRoles()(dispatch, getState) :  Promise.resolve();
    let promiseLocations = getState().system.get('featureFlags').ORGANIZATIONS ? {} : api.fetchLocations()(dispatch, getState);

    Promise.all([promiseRoles, promiseLocations])
      .then(() => {
        dispatch(actions.show({user, serviceAction}));
      });
  }
};

api.hideIgnoreValidationWarningsCheckbox = function(dispatch, getState) {
  return function(value) {
    dispatch(actions.hideIgnoreValidationWarningsCheckbox(value));
  }
};

api.toggleIgnoreValidationWarningsCheckbox = function(dispatch, getState) {
  return function(value) {
    dispatch(actions.toggleIgnoreValidationWarningsCheckbox(value));
  }
};


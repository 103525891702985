import React from 'react';
import {Route, Redirect} from 'react-router';

export function getRoutesFor(items, defaultRouteConfig=null) {
    let routes = defaultRouteConfig ? [getDefaultRoute(defaultRouteConfig)] : [];

    items?.forEach((item) => {
      if (item.items) {
        item.items.forEach((subItem) => routes.push(getRoute(subItem)))
      }
      else {
        routes.push(getRoute(item))
      }
    });

    if (defaultRouteConfig?.routeNotFoundComponent) {
      routes.push(<Route key="pageNotFoundRouteKey" component={defaultRouteConfig?.routeNotFoundComponent}/>);
    }
    return routes;
  }

function getDefaultRoute(config) {
  const {key, path, redirectPath} = config;
  return (
    <Route key={key}
           exact path={path}
           render={() => (<Redirect to={redirectPath} />)}
    />
  )
}

function getRoute(item) {
  const {id: key, link: path, component} = item;
  let routeProps = {key, path, component};
  return (
    <Route {...routeProps} />
  )
}
export function getSideNavigatorMenuItems(items) {
  if(!items || items?.length === 0) {
    return [];
  }
  return items?.map((item) => {
    if (item.items) {
      return {
        ...item,
        component: undefined,
        items: item.items.map((it) =>({
            ...it,
            component: undefined
          }))
      }
    }
    return {
      ...item,
      component: undefined
    }
  });
}

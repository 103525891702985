import React from 'react';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import ColorCell from '../../../../Common/CustomGridCells/ColorCell/colorCell';

class ShiftTypesViewGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'name', filterName: 'shiftName', getOptions: true},
  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.shiftTypes.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.name.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'shiftName',
        // width: 170,
      },
      {
        fieldName: 'm_startTime',
        title: this.labels.get('columns.startTime.title'),
        filterType: filterTypes.NONE,
        filterName: 'startTime',
        // width: 120,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'm_endTime',
        title: this.labels.get('columns.endTime.title'),
        filterType: filterTypes.NONE,
        filterName: 'endTime',
        // width: 100,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'color',
        title: this.labels.get('columns.color.title'),
        width: 110,
        columnOptions: {
          cellComponent: ColorCell,
          sortable: false,
        }
      },
    ]
  };

  render() {
    return (
        <div className='administration-view-grid'>
            <Grid
                gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_SHIFT_TYPES}
                columnsConfig={this.columnsConfig}
                actions={this.props.actions}
                filterConfig={this.filterConfig}
            />
        </div>
    );
  }
}

export default reduxForm({
    form: 'shiftTypesViewGrid'
  }
)(ShiftTypesViewGrid);

import { api as gridApi} from './operationsViewGridActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    api.load(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function(dispatch, getState) {
  return function(shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

import { actionTypes as printRfidTagsActionsTypes } from '../../../actions/Dialogs/PrintRfidTagsDialog/printRfidTagsDialogActions.js';
import { List, Map } from 'immutable';

let defaultState = {
  show: false,
  loading: false,
  printers: [],
  cancelToken: '',
  assets: [],
  boEntityFilter: '',
  isGeneric: false,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case printRfidTagsActionsTypes.PRINT_RFID_TAGS_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);
    case printRfidTagsActionsTypes.PRINT_RFID_TAGS_DIALOG_SUBMIT_FINISHED:
      return state.set('loading', false);
    case printRfidTagsActionsTypes.PRINT_RFID_TAGS_DIALOG_SHOW:
      return state
        .set('assets', action.payload.assets ? action.payload.assets : [])
        .set(
          'printers',
          action.payload.printers
            ? action.payload.printers.map((obj) => {
                return { value: obj.id, label: obj.name };
              })
            : []
        )
        .set('isGeneric', action.payload.isGeneric)
        .set('selectedAsset', action.payload.selectedAsset)
        .set('show', true);
    case printRfidTagsActionsTypes.PRINT_RFID_TAGS_DIALOG_HIDE:
      return Map(defaultState);
    case printRfidTagsActionsTypes.PRINT_RFID_TAGS_DIALOG_FETCH_ASSETS_READY:
      return state;
    case printRfidTagsActionsTypes.PRINT_RFID_TAGS_DIALOG_SAVE_CANCEL_TOKEN:
      return state.set('cancelToken', action.payload);
    default:
      return state;
  }

  return state;
}

import { useEffect } from 'react';

import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';

import FullScreenTabsSeparator from '../../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';
import OpenWorkOrderContainer from '../../../containers/OpenWorkOrdersPage/openWorkOrdersContainer';
import CompletedWorkOrderContainer from '../../../containers/CompletedWorkOrdersPage/completedWorkOrdersContainer';

import { navigationStates } from '../../../enums/navigationStates';

import './workOrdersPage.scss';

const labels = createLabelHelper('mat.workorders.page.');
const tabsLabels = createLabelHelper('mat.workorders.page.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  OPEN: 'open',
  COMPLETED: 'completed',
};

const WorkOrdersPage = () => {
  const updateNavBar = useNavBarUpdate();
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl('PredefinedViews/WorkOrders', Object.values(tabKeys), 'open');

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  useEffect(() => {
    updateNavBar(navigationStates.WORKORDERS, labels.get('title'));
  }, [updateNavBar, setTabKeyInURL]);

  return (
    <div className="work-orders-page">
      <FullScreenTabsSeparator />
      <Tabs id="work-orders-tab" tabType="page" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('open')} eventKey={tabKeys.OPEN}>
          <OpenWorkOrderContainer />
        </Tab>
        <Tab title={tabsLabels.get('completed')} eventKey={tabKeys.COMPLETED}>
          <CompletedWorkOrderContainer />
        </Tab>
      </Tabs>
    </div>
  );
};

export default WorkOrdersPage;

import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';

import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import AdditionalField from '../../Common/Layout/AdditionalField/AdditionalField';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import GenerateButton from 'infrastructure/js/components/controls/GenerateButton/generateButton';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import isEmpty from 'lodash-es/isEmpty';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { EntityPropertyTypes } from '../../../enums/entityPropertyTypes';
import { FetchEntitiesFilters } from '../../../enums/fetchEntitiesFilters';
import { enumTypes, getEnumValue } from '../../../utils/enumHelper';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import MultiEntitiesMultiSelect from '../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { List } from 'immutable';
import { DefaultWorkOrderTypes } from '../../../utils/workOrderHelper';
import * as additionalFieldsHelper from '../../Common/Helpers/AdditionalFieldsHelper';
import SingleLineMultiSelect from 'infrastructure/js/components/controls/SingleLineMultiSelect/singleLineMultiSelect.js';

require('./createGroupDialog.scss');

class CreateGroupDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.creategroupdialog.');
    this.loggedInUser = PermissionManager.getLoggedInUser();

    this.preselectedWO = this.props.sData.get('preselectedWO');

    this.state = {
      curWorkOrder: null,
      curProjectId: null,
      curPartTypeId: null,
      isAssetsAttachmentActive: false,
      dummyKey: 0,
    };

    this.woFetchConfig = {
      entityType: EntityPropertyTypes.WO_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE],
      action: this.props.actions.fetchEntities,
    };
    this.assetsFetchConfig = {
      filter: [
        FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.ON_LOCATION,
        FetchEntitiesFilters.NOT_CONTAINED,
        FetchEntitiesFilters.NOT_RELATED,
        FetchEntitiesFilters.NO_ATTACHED_ASSET,
      ],
      action: this.props.actions.fetchEntities,
    };

    this.entitiesTypes = [
      getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'),
      getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'),
      getEnumValue(enumTypes.OBJECT_TYPE)('KIT'),
      getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'),
      getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'),
    ];
  }

  componentDidMount() {
    if (this.preselectedWO) {
      let newValue = {
        value: this.preselectedWO.id,
        label: this.preselectedWO.businessId,
        data: this.preselectedWO,
      };
      setTimeout(() => {
        this.onWoComboboxChangeHandler(newValue, null);
      }, 0);
    }
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled:
            this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') &&
              !this.props.sData.get('isIgnoreValidationWarnings')),
        },
      ],
    };
  }

  getComponentAssetsIds = (formData) => {
    let assets = [];
    let fieldName = 'assets';
    let allAssets = formData[fieldName];
    for (let assetIndex = 0; assetIndex < allAssets.length; assetIndex++) {
      let selectedAssets = allAssets[assetIndex].assetsValue;
      if (selectedAssets) {
        for (let assetIdIndex = 0; assetIdIndex < selectedAssets.length; assetIdIndex++) {
          assets.push(selectedAssets[assetIdIndex].value);
        }
      }
    }

    return assets;
  };

  onSubmit = (data) => {
    let workOrder =
      data.workOrder && data.workOrder.assetsValue ? data.workOrder.assetsValue : null;

    let submitData = {
      businessId: data.assetId,
      tags: data.tags ? data.tags.map(({ value, label }) => ({ id: value, tagKey: label })) : [],
      workOrder: workOrder
        ? {
            id: workOrder.value === workOrder.label ? null : workOrder.value,
            businessId: workOrder.label,
          }
        : null,
      partType: data.part
        ? {
            id: data.part.value === data.part.label ? null : data.part.value,
            businessId: data.part.label,
          }
        : null,
      project: data.project
        ? {
            id: data.project.value === data.project.label ? null : data.project.value,
            businessId: data.project.label,
          }
        : null,
      groupType: data.groupType
        ? {
            id: data.groupType.value === data.groupType.label ? null : data.groupType.value,
            businessId: data.groupType.label,
          }
        : null,
      componentAssetIds: this.getComponentAssetsIds(data),
      maxStorageTemp: UnitHelper.userValueToServerValue(
        unitTypes.TEMPERATURE,
        data.maxStorageTemp,
        3
      ),
      assetStatus: 'ACTIVE',
      ignoreValidationWarnings:
        this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox
          ? data.footerValidationCheckbox
          : false,
    };

    if (!isEmpty(this.getAdditionalFields())) {
      submitData.additionalFieldsValues = additionalFieldsHelper.convertToAdditionalFields(
        data['additionalFields'],
        this.getAdditionalFields()
      );
    }

    this.props.actions.submit(
      submitData,
      this.getMessageDialogBuilder(),
      this.props.reloadParentComponent
    );
  };

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog, callback) => {
      let title = this.labels.get('confirmation.header');
      let buttons = [
        { id: 'printRfidTag', text: this.labels.get('footer.printRfidTag'), action: callback },
        {
          id: 'close',
          text: this.dialogLabels.get('close'),
          action: fnCloseDialog,
          bsStyle: 'primary',
        },
      ];
      let children = [];

      if (data) {
        let rows = [
          { label: this.labels.get('confirmation.message.groupId'), value: data.businessId },
        ];
        children = rows.map((item, index) => {
          return (
            <MessageDialog.DataRow
              key={`confirmMessageDataRow${index}`}
              label={item.label}
              value={item.value}
            />
          );
        });
      }
      return { title, children, buttons };
    };
  };

  onHide = () => {
    this.props.actions.hide();
  };

  generateAssetId = (kit, e) => {
    this.props.actions.generateAssetId().then((response) => {
      this.props.change('assetId', response.data);
    });
  };

  getGroupTypes = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData?.groupTypes || [];
  };

  getProjects() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.projects ? dialogData.projects : [];
  }

  getParts() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.parts ? dialogData.parts : [];
  }

  getTags() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.tags ? dialogData.tags : [];
  }

  getKitTypes() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.kitTypes ? dialogData.kitTypes : [];
  }

  getAdditionalFields() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.additionalFields ? dialogData.additionalFields : [];
  }

  renderAdditionalFields = () => {
    let dialogData = this.props.sData.get('dialogData');
    if (dialogData && dialogData.additionalFields) {
      return dialogData.additionalFields.map((field, index) => {
        return <AdditionalField key={index} field={field} index={index} />;
      });
    }
    return null;
  };

  getOptionById = (options, id) => {
    let found = options.find((op) => {
      return op && op.value === id;
    });
    return found ? found : null;
  };

  getProjectIdFromWO = (wo) => {
    return this.getPropIdByPropName(wo, 'project');
  };

  getPartTypeIdFromWO = (wo) => {
    return this.getPropIdByPropName(wo, 'partType');
  };

  getPropIdByPropName = (wo, propName) => {
    if (wo && wo.workOrderType === DefaultWorkOrderTypes.KITTYPE_ORIENTED && wo.kitType) {
      let kitType = this.getKitTypes().find((item) => {
        return wo.kitType.id === item.id;
      });
      return kitType && kitType[propName] ? kitType[propName].id : null;
    }
    return wo && wo[propName] ? wo[propName].id : null;
  };

  onWoComboboxChangeHandler = (newValue, oldValue) => {
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    let selectedWo = newValue ? newValue.data : null;

    let newState = {
      isAssetsAttachmentActive: !!newValue,
      curWorkOrder: selectedWo,
    };

    let curProjectId = this.getProjectIdFromWO(selectedWo);
    let projectOption = curProjectId ? this.getOptionById(this.getProjects(), curProjectId) : null;
    this.props.change('project', projectOption);
    newState.curProjectId = curProjectId;

    let curPartTypeId = this.getPartTypeIdFromWO(selectedWo);
    let partOption = curPartTypeId ? this.getOptionById(this.getParts(), curPartTypeId) : null;
    this.props.change('part', partOption);
    newState.curPartTypeId = curPartTypeId;

    newState.dummyKey = this.state.dummyKey === 0 ? 1 : 0;

    this.setState(newState);
    this.props.actions.resetAssetsForWo();
  };

  attachAssetsFromWoHandler = () => {
    if (this.state.curWorkOrder) {
      let fetchEntitiesFilters = [
        FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.NOT_CONTAINED,
        FetchEntitiesFilters.NOT_RELATED,
        FetchEntitiesFilters.NO_ATTACHED_ASSET,
      ];
      this.props.actions.fetchAssetsForWo(this.state.curWorkOrder.id, fetchEntitiesFilters);
      this.setState({ isAssetsAttachmentActive: false });
    }
  };

  renderAttachAssetsFromWoLink = () => {
    return (
      <a
        className="attach-assets-from-wo"
        onClick={this.attachAssetsFromWoHandler}
        style={{ visibility: this.state.isAssetsAttachmentActive ? 'visible' : 'hidden' }}
      >
        {this.labels.get('attachassetsfromwo')}
      </a>
    );
  };

  getWoRelatedAssetsData = () => {
    if (this.state.curWorkOrder) {
      let fetchAssetsData = this.props.sData.get('fetchAssetsData');
      let woId = this.state.curWorkOrder.id;
      let woRelatedAssets = fetchAssetsData.get(woId);
      if (woRelatedAssets && woRelatedAssets.size > 0) {
        return woRelatedAssets;
      }
    }
    return List();
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox')
      ? { label: this.dialogLabels.get('ignorewarnings'), onChange: this.onFooterValidationChange }
      : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  getPreselectedEntities = () => {
    return this.preselectedWO ? List([this.preselectedWO]) : List([]);
  };

  maxLengthDropDown = Validation.dropdown.maxLength(30);

  render() {
    let preselectedAssets = this.getWoRelatedAssetsData();
    let bodyClassName = isEmpty(this.props.sData.get('additionalFields'))
      ? ''
      : 'modal-body-overflow';

    return (
      <Dialog
        id="create-container-dialog"
        bodyClassName={bodyClassName}
        className="create-container-dialog"
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get('header.title')}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(
          this.props.sData,
          this.dialogLabels
        )}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <InputSection
          label={this.labels.get('groupId') + '*'}
          htmlFor="assetId"
          className="inline left-side no-margin"
        >
          <GenerateButton
            id="assetId"
            name="assetId"
            placeholder={this.labels.get('groupIdPlaceholder')}
            validate={Validation.required}
            onClick={this.generateAssetId.bind(this, this)}
            buttonText={this.labels.get('generatebuttontext')}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('groupType') + '*'}
          htmlFor="groupType"
          className="inline right-side no-margin"
        >
          <Combobox
            id="groupType"
            name="groupType"
            options={this.getGroupTypes()}
            validate={[Validation.dropdown.required, this.maxLengthDropDown]}
            // validate={Validation.dropdown.required}
            allowNewOption={true}
          />
        </InputSection>

        <div className="wo-section">
          <InputSection label={this.labels.get('workOrder')} htmlFor="workOrder" className="">
            <EntitiesMultiSelect
              id="workOrder"
              name="workOrder"
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('WO')]}
              fetchConfig={this.woFetchConfig}
              autoFocus={!this.hasPreSelectedWo}
              onValueChangeCallback={this.onWoComboboxChangeHandler}
              change={this.props.change}
              isMulti={false}
              showEntitiesTypes={false}
              preSelectedEntities={this.getPreselectedEntities()}
              allowNewOption={PermissionManager.hasCreateAssetsPermissions()}
              disableOnPreselect={!!this.preselectedWO}
            />
          </InputSection>

          <InputSection label={this.labels.get('project')} htmlFor="project" className=" ">
            <Combobox
              id="project"
              name="project"
              options={this.getProjects()}
              isDisabled={this.state.curProjectId}
              allowNewOption={PermissionManager.hasCreateAssetsPermissions()}
            />
          </InputSection>

          <InputSection label={this.labels.get('part')} htmlFor="part" className="">
            <Combobox
              id="part"
              name="part"
              options={this.getParts()}
              isDisabled={this.state.curPartTypeId}
              allowNewOption={PermissionManager.hasCreateAssetsPermissions()}
            />
          </InputSection>
        </div>

        {this.renderAttachAssetsFromWoLink()}

        <InputSection
          label={this.labels.get('componentAssets')}
          htmlFor="assets"
          className="full-width"
        >
          <MultiEntitiesMultiSelect
            id="assets"
            name="assets"
            // entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('KIT')]}
            entitiesTypes={this.entitiesTypes}
            preSelectedEntities={preselectedAssets}
            fetchConfig={this.assetsFetchConfig}
            // disableOnPreselect={false}
            // onValueChangeCallback={this.onValueChangeCallback}
            change={this.props.change}
            key={this.state.dummyKey}
          />
        </InputSection>

        <InputSection label={this.labels.get('tag')} htmlFor="tags" className="inline left-side">
          <SingleLineMultiSelect
            id="tags"
            name="tags"
            limit={10}
            options={this.getTags()}
            value={[]}
            onChange={(value) => {
              this.props.change('tags', value);
            }}
          ></SingleLineMultiSelect>
        </InputSection>

        <InputSection
          label={this.labels.get('maxstoragetemperature', undefined, {
            units: UnitHelper.getLabelForUnitType(unitTypes.TEMPERATURE),
          })}
          htmlFor="maxStorageTemp"
          className="inline right-side"
        >
          <TextField
            id="maxStorageTemp"
            name="maxStorageTemp"
            className="short-textfield"
            normalize={Normalize.number(
              true,
              UnitHelper.getMinValueForUnitType(unitTypes.TEMPERATURE, 0),
              UnitHelper.getMaxValueForUnitType(unitTypes.TEMPERATURE, 0)
            )}
          />
        </InputSection>

        <div>{this.renderAdditionalFields()}</div>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'createGroupDialog',
  onChange: (values, dispatch, props, previousValues) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
})(CreateGroupDialog);

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as assetEditorActions } from '../../actions/AssetPage/assetPageEditorActions';
import { jsxActions as editKitMaterialsDialogActions } from '../../actions/AssetPage/Dialogs/editKitMaterialsDialogActions';
import { jsxActions as weightWizardActions } from '../../actions/AssetPage/weightWizardActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import OverviewTab from '../../components/AssetPage/OverviewTab/overviewTab.js';
import {getDefaultWorkOrderType}  from '../../utils/workOrderHelper';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      assetEditorActions: bindActionCreators(assetEditorActions, dispatch),
      editKitMaterialsDialogActions: bindActionCreators(editKitMaterialsDialogActions, dispatch),
      weightWizardActions: bindActionCreators(weightWizardActions, dispatch),
    }
  }
}

let OverviewTabContainer = connect(
  (state) => {
    return {
      assetDetails: state.assetPage.getIn(['overviewTab', 'assetDetails']),
      resultingAssets: state.assetPage.getIn(['overviewTab', 'resultingAssets']),
      componentAssets: state.assetPage.getIn(['overviewTab', 'componentAssets']),
      equipmentAssets: state.assetPage.getIn(['overviewTab', 'equipmentAssets']),
      loading: state.assetPage.getIn(['overviewTab', 'loading']),

      sAssetEditorData:     state.assetPage.getIn(['data', 'assetEditorData']),
      sAssetEditorOperationsData:     state.assetPage.getIn(['data', 'assetEditorOperationsData']),
      sDefaultWorkOrderType: getDefaultWorkOrderType(PermissionManager.getOrgPreferences()),
      sLoggedInUser: state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(OverviewTab);

export default OverviewTabContainer;





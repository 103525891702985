import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as massOperationsPageActions }  from '../../../actions/Administration/MassOperationsPage/massOperationsPageActions.js';
import MassCreationView from '../../../components/Administration/MassOperationsPage/MassCreationView/massCreationView.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(massOperationsPageActions, dispatch)
  }
}

let MassCreationViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.get('massOperationsPage'),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(MassCreationView);


export default MassCreationViewContainer;




import * as kitsService from '../../../services/Kits/kitsService';

import { api as locationPageApi } from '../locationPageActions.js';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';

import Network from 'infrastructure/js/modules/network';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CUTKITSTATION_CANCELKITREPORTDIALOG_SHOW: "CUTKITSTATION_CANCELKITREPORTDIALOG_SHOW",
  CUTKITSTATION_CANCELKITREPORTDIALOG_HIDE: "CUTKITSTATION_CANCELKITREPORTDIALOG_HIDE",

  CUTKITSTATION_CANCELKITREPORTDIALOG_CANCEL_KIT_REPORT_IN_PROGRESS:  "CUTKITSTATION_CANCELKITREPORTDIALOG_CANCEL_KIT_REPORT_IN_PROGRESS",
  CUTKITSTATION_CANCELKITREPORTDIALOG_CANCEL_KIT_REPORT_FINISHED:     "CUTKITSTATION_CANCELKITREPORTDIALOG_CANCEL_KIT_REPORT_FINISHED"
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CUTKITSTATION_CANCELKITREPORTDIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CUTKITSTATION_CANCELKITREPORTDIALOG_HIDE, payload: payload };
  },
  cancelKitReportInProgress: function(payload) {
    return {type: actionTypes.CUTKITSTATION_CANCELKITREPORTDIALOG_CANCEL_KIT_REPORT_IN_PROGRESS, payload: payload };
  },
  cancelKitReportFinished: function(payload) {
    return {type: actionTypes.CUTKITSTATION_CANCELKITREPORTDIALOG_CANCEL_KIT_REPORT_FINISHED, payload: payload };
  }
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.onCancel = function() {
  return function(dispatch, getState) {
    api.closeDialog(dispatch, getState)();
  };
};

jsxActions.cancelKitReport = function(kitIds, messageDialogBuilder) {
  return function(dispatch, getState) {
    dispatch(actions.cancelKitReportInProgress());

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    kitsService.cancelKitReport(locationId, kitIds).then((response) => {
      dispatch(actions.cancelKitReportFinished());

      if (!Network.isResponseValid(response)) {
        console.error('Cancel Kit Report Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      // Refresh everything.
      locationPageApi.reload(dispatch, getState)().then(() => {
        // Prepare and open Confirmation Message Dialog.
        let messageDialogDescriptor = messageDialogBuilder();

        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor, { autoClose : 2000});
      });
    });
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

// Someone wants to close the dialog.
api.closeDialog = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

// Someone opens the dialog.
api.openDialog = function(dispatch, getState) {
  return function(kitIds) {
    dispatch(actions.show(kitIds)); // Open dialog first.
  }
};


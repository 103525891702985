import { Map } from 'immutable';
import { actionTypes as overviewTabActionTypes } from '../../actions/AssetPage/overviewTabActions';

let defaultState = {
      assetDetails: {},
      resultingAssets: [],
      componentAssets: [],
      equipmentAssets: [],
      loading: false,
};


export default function(state = Map(defaultState), action) {

  switch(action.type) {

    case overviewTabActionTypes.OVERVIEW_TAB_FETCHDATA_STARTED:
      return state.set('loading', true);

    case overviewTabActionTypes.OVERVIEW_TAB_FETCHDATA_FINISHED:
      state = state.set('assetDetails', action.payload.assetDetails)
                   .set('resultingAssets', action.payload.resultingAssets)
                   .set('componentAssets', action.payload.componentAssets)
                   .set('equipmentAssets', action.payload.equipmentAssets)
                   .set('loading', false);

      return state;

    default:
      return state;

  }

}

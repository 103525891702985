import React, {Component} from 'react';
//import { createLabelHelper } from "infrastructure/js/utils/labelHelper";


require('./appLoader.scss');


export default class AppLoader extends React.PureComponent{
  render() {
    //Handle loading mode
    let activeLoaderClassName = '';
    if(this.props.isOn){
      activeLoaderClassName = 'on';
    }

    return (
      <div className={`app-loader ${activeLoaderClassName}`}>
      </div>
    );
  }
}

import React, {Component} from 'react';
import {RegisterLayout}  from './RegisterLayout/registerLayout';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import PhoneField from 'infrastructure/js/components/controls/PhoneField/phoneField';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import ChangePassword from 'infrastructure/js/components/ChangePassword/changePassword';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {navigationStates} from '../../../../mat/js/enums/navigationStates';
import { getPathByType, getHomePagePathByType } from '../../../../mat/js/utils/navigationHelper';
import * as AppHelper from 'infrastructure/js/utils/appHelper';

import './registerPage.scss';

export default class RegisterPage extends Component{

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.register.');

    this.initializePageData();
  }

  // componentWillMount() { //moved to constructor
  initializePageData = () => {
    let user = this.props.sLoginData.get('loggedInUser');
    this.props.initialize(
      {
      username: user.username,
      email:user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone
    }
   );
  }

  register = (formData)=> {

    let queryData = {
      firstName: formData.firstName,
      lastName : formData.lastName,
      phone: formData.phone,
      email: formData.email,
      newPassword: formData.confirmPassword
    };

    this.props.actions.register(queryData)
      .then((response)=>{
        if(response){
          if (AppHelper.isMobileApp() ){
            this.props.history.push('/Mobile');
            return;
          }

          let path = '/Login';

          const { defaultEntityType, defaultEntityId } = response.userPreferences;
          if(defaultEntityType){
            const defaultHomePage = getPathByType(navigationStates.MANAGER_REPORT);
            const homePagePath = getHomePagePathByType(defaultEntityType, defaultEntityId) || defaultHomePage;
            path = homePagePath;
          }
          
          this.props.history.push(path);
        }
      });
  }


  render() {
    if ( AppHelper.isMobileApp()) {
      return (
        <RegisterLayout formClass="register-page" actionButtonText="Register" actionButtonOnclick={this.props.handleSubmit(this.register.bind(this))} loading={this.props.sData.get('loading')}>
          <div className='register-row'>
          <InputSection label={this.labels.get('username')} htmlFor="username" className=''>
            <TextField id="UsernameInput" name="username" disabled={true}/>
          </InputSection>
          </div>
          <div className='register-row'>
            <ChangePassword />
          </div>
        </RegisterLayout>
      );
    }

    return (
      <RegisterLayout formClass="register-page" actionButtonText="Register" actionButtonOnclick={this.props.handleSubmit(this.register.bind(this))} loading={this.props.sData.get('loading')}>

        <div className='register-row'>
          <InputSection label={this.labels.get('username')} htmlFor="username" className='inline left-side no-margin'>
            <TextField autoComplete="username" id="UsernameInput" name="username" disabled={true}/>
          </InputSection>
          <InputSection label={this.labels.get('email')} className="inline right-side no-margin">
            <TextField id="EmailInput" name="email" autoFocus validate={Validation.email}/>
          </InputSection>
        </div>

        <div className='register-row'>
          <InputSection label={this.labels.get('firstname')+ '*'} className='inline left-side'>
          <TextField id="FirstNameInput" name="firstName" maxLength={30} validate={Validation.required}/>
        </InputSection>

          <InputSection label={this.labels.get('lastname') + '*'} className='inline right-side' >
            <TextField id="LastNameInput" name="lastName" maxLength={30} validate={Validation.required}/>
          </InputSection>
        </div>

        <div className='register-row-single-row'>
          <InputSection label={this.labels.get('phone')}>
            <PhoneField id='PhoneInput' name='phone' />
          </InputSection>
        </div>

        <div className='register-row'>
          <ChangePassword />
        </div>

      </RegisterLayout>
    );
  }
}

import Network from 'infrastructure/js/modules/network';
import * as notificationsService from '../../services/Notifications/notificationsService';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {api as notificationPageApi} from '../NotificationsPage/notificationsPageActions';

export const actionTypes = {
  NOTIFICATIONS_TOGGLE: 'NOTIFICATIONS_TOGGLE',
  NOTIFICATIONS_HIDE: 'NOTIFICATIONS_HIDE',
  FETCH_NOTIFICATIONS_COUNT_READY: 'FETCH_NOTIFICATIONS_COUNT_READY',
  FETCH_NOTIFICATIONS_IN_PROGRESS: 'FETCH_NOTIFICATIONS_IN_PROGRESS',
  FETCH_NOTIFICATIONS_READY: 'FETCH_NOTIFICATIONS_READY',

  CHANGE_NOTIFICATION_READ_STATUS_IN_PROGRESS: 'CHANGE_NOTIFICATION_READ_STATUS_IN_PROGRESS',
  CHANGE_NOTIFICATION_READ_STATUS_READY: 'CHANGE_NOTIFICATION_READ_STATUS_READY',

  NOTIFICATIONS_MARK_ALL_AS_READ_IN_PROGRESS: 'NOTIFICATIONS_MARK_ALL_AS_READ_IN_PROGRESS',
  NOTIFICATIONS_MARK_ALL_AS_READ_READY: 'NOTIFICATIONS_MARK_ALL_AS_READ_READY'
};

const actions = {
  toggleNotificationsMenu: function () {
    return {type: actionTypes.NOTIFICATIONS_TOGGLE};
  },
  hideNotificationsMenu: function () {
    return {type: actionTypes.NOTIFICATIONS_HIDE};
  },
  notificationsCountReady: function (payload) {
    return {type: actionTypes.FETCH_NOTIFICATIONS_COUNT_READY, payload};
  },
  getNotificationsInProgress: function () {
    return {type: actionTypes.FETCH_NOTIFICATIONS_IN_PROGRESS};
  },
  notificationsReady: function (payload) {
    return {type: actionTypes.FETCH_NOTIFICATIONS_READY, payload};
  },
  changeNotificationReadStatusInProgress: function () {
    return {type: actionTypes.CHANGE_NOTIFICATION_READ_STATUS_IN_PROGRESS};
  },
  changeNotificationReadStatusReady: function (payload) {
    return {type: actionTypes.CHANGE_NOTIFICATION_READ_STATUS_READY , payload};
  },
  notificationsMarkAllAsReadInProgress: function () {
    return {type: actionTypes.NOTIFICATIONS_MARK_ALL_AS_READ_IN_PROGRESS};
  },
  notificationsMarkAllAsReadReady: function (payload) {
    return {type: actionTypes.NOTIFICATIONS_MARK_ALL_AS_READ_READY , payload};
  }

};

export let jsxActions = {};

jsxActions.toggleNotificationsMenu = function () {
  return function (dispatch, getState) {
    api.toggleNotificationsMenu(dispatch, getState)();
  }
};

jsxActions.hideNotificationsMenu = function () {
  return function (dispatch, getState) {
    let hideNotificationsMenu = () => {
      dispatch(actions.hideNotificationsMenu());
    };
    setTimeout(hideNotificationsMenu, 50);
  }
};

jsxActions.getNotificationsCount = function () {
  return function (dispatch, getState) {
    api.getNotificationsCount(dispatch, getState)();
  }
};

jsxActions.getNotifications = function () {
  return function (dispatch, getState) {
    api.getNotifications(dispatch, getState)();
  }
};

jsxActions.onTimerRefresh = function() {
  return function(dispatch, getState) {
    api.getNotificationsCount(dispatch, getState)();
  }
};

jsxActions.markAllAsRead = function(data) {
  return function(dispatch, getState) {
    api.markAllAsRead(dispatch, getState)(data);
  }
};

jsxActions.changeReadStatus = function(data) {
  return function(dispatch, getState) {
    api.changeReadStatus(dispatch, getState)(data);
  }
};


export let api = {};

api.markAllAsRead = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.notificationsMarkAllAsReadInProgress());
    return notificationsService.markAllAsRead(data)
      .then((response) => {
        if(!Network.isResponseValid(response)) {
          console.error('Notifications Mark All As Read failed');
          return {success: false};
        }
        let notificationsPromise = api.getNotifications(dispatch, getState)();
        let notificationsCountPromise = api.getNotificationsCount(dispatch, getState)();

        Promise.all([notificationsPromise, notificationsCountPromise])
          .then(() => {
            dispatch(actions.notificationsMarkAllAsReadReady());
          });
      });
  }
};

api.changeReadStatus = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.changeNotificationReadStatusInProgress());
    return notificationsService.changeReadStatus(data.id , {status: data.status})
      .then((response) => {
        if(!Network.isResponseValid(response)) {
          console.error('Notifications ChangeRead Status failed');
          return {success: false};
        }
        let notificationsPromise = api.getNotifications(dispatch, getState)();
        let notificationsCountPromise = api.getNotificationsCount(dispatch, getState)();

        Promise.all([notificationsPromise, notificationsCountPromise])
          .then(() => {
            dispatch(actions.changeNotificationReadStatusReady());

            if(shouldRefreshPage(getState)){
              notificationPageApi.loadData(dispatch, getState)();
            }
          });
      });
  }
};


api.getNotificationsCount = function(dispatch, getState) {
  return function() {
    return notificationsService.getNotificationsCount()
      .then((response) => {
        if(!Network.isResponseValid(response)) {
          console.error('Notifications Count failed');
          return {success: false};
        }
        dispatch(actions.notificationsCountReady(response.data));
      });
  }
};

api.toggleNotificationsMenu = function(dispatch, getState) {
  return function() {
    dispatch(actions.toggleNotificationsMenu());
    api.getNotificationsCount(dispatch, getState)();
    api.getNotifications(dispatch, getState)();
  }
};

api.getNotifications = function(dispatch, getState) {
  return function() {
    dispatch(actions.getNotificationsInProgress());

    let query = {
      filters: [
        {filterName: "notificationsFromDaysInterval#", values: [7]},
        {filterName: "createdDate"}
      ],
      sortByProperty: "createdDate",
      sortDirection: "desc",
      pageSize: 500
    };
    // TODO: Access page Form that wraps all the filters, and populate Query Filters based on Form values.
    return notificationsService.getNotifications(query)
      .then((response) => {
        if(!Network.isResponseValid(response)) {
          console.error('Get Notifications failed');
          return {success: false};
        }

        let serverTimeSeconds = getState().system.get('serverDatetime');

        let items  = response.dataList.map((obj) => {
          obj.m_Time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.createdDate, serverTimeSeconds);
          return obj;
        });
        dispatch(actions.notificationsReady(items));
      });
  }
};

//////////////////////////////////////////////////
function shouldRefreshPage(getState) {
  let dataList = getState().notificationsPage?.get('dataList');
  return dataList && dataList.length > 0;
}

import * as partsService from '../../services/Parts/partsService';
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ACTIVE_PARTS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return partsService.fetchActiveParts(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj, index) => {
    return obj;
  });

  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

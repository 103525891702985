import { Map } from 'immutable';

import {default as cutAndKitDialogReducer} from './cutAndKitDialogReducer';
import {default as reportCutDialogReducer} from './reportCutDialogReducer';
import {default as createSubRollsDialogReducer} from './createSubRollsDialogReducer';
import {default as cancelCutReportDialogReducer} from './cancelCutReportDialogReducer';
import {default as cancelKitReportDialogReducer} from './cancelKitReportDialogReducer';
import {default as reportKitDialogReducer} from './reportKitDialogReducer';
import {default as createKitWizardReducer} from './createKitWizardReducer';
import {default as kittingViewReducer} from './kittingViewReducer';

import { actionTypes as kittingViewActionTypes } from '../../actions/LocationPage/KittingView/kittingViewActions';
import { actionTypes as locationPageActionTypes } from '../../actions/LocationPage/locationPageActions';

let defaultState = {
  selectedTab : 'cutting',
  selectedCutToKit : '',
  showKitsInLocation: true,
};

export default function(state = Map(defaultState), action) {

  if (action.type === locationPageActionTypes.LOCATIONPAGE_LOCATION_CHANGED){
    state = Map(defaultState);
  }

  state = state.set("cutAndKitDialog", cutAndKitDialogReducer(state.get('cutAndKitDialog'), action));

  state = state.set("reportCutDialog", reportCutDialogReducer(state.get('reportCutDialog'), action));

  state = state.set("createSubRollsDialog", createSubRollsDialogReducer(state.get('createSubRollsDialog'), action));

  state = state.set("cancelCutReportDialog", cancelCutReportDialogReducer(state.get('cancelCutReportDialog'), action));

  state = state.set("cancelKitReportDialog", cancelKitReportDialogReducer(state.get('cancelKitReportDialog'), action));

  state = state.set("reportKitDialog", reportKitDialogReducer(state.get('reportKitDialog'), action));

  state = state.set("kittingViewData", kittingViewReducer(state.get('kittingViewData'), action));

  state = state.set("createKitWizard", createKitWizardReducer(state.get('createKitWizard'), action));

  switch(action.type) {
    case kittingViewActionTypes.CUTKITSTATION_SELECTED_CUT_TO_KIT_CHANGED:
      return state.set("selectedCutToKit", action.payload);
    case kittingViewActionTypes.CUTKITSTATION_SHOW_KITS_IN_LOCATION_CHANGED:
      return state.set('showKitsInLocation', action.payload);
    case locationPageActionTypes.LOCATIONPAGE_LOCATION_SET_LOCATION_ID:
      return state.setIn(["data", "locationDetails"], {id:action.payload});
    default:
      return state;
  }
}







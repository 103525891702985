import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import moment from 'moment';

require('./autoSchedulerDialog.scss');

const LOCAL_STORAGE_KEY = 'AutoSchedulerDialogDates';

//todo: move to external module and make library agnostic.
const getDateFragments = (date) => {
  const hours = date.hour();
  const minutes = date.minute();
  const day = date.day();
  return { hours, minutes, day };
};

const createLocalTimeObject = (localDateTimeString) => {
  return moment(localDateTimeString, 'YYYY-MM-DDTHH:mm:ss');
};

class AutoSchedulerDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.scheduler.operations.dialog.autoScheduler.');

    this.fromInitialDate = moment();
    this.toInitialDate = moment().add(7, 'days');
    this.minDate = moment();
    this.maxDate = moment().add(3, 'months');

    this.state = {
      curFromDate: null,
      curToDate: this.toInitialDate,
      curFromMinTime: this.calculateMinTime(this.fromInitialDate),
      curToMinTime: this.calculateMinTime(this.toInitialDate),
      curMaxTime: moment().endOf('day'),
    };
  }

  getStoredDates() {
    const savedString = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedString ? JSON.parse(savedString) : false;
  }

  storeDates({ from, to }) {
    const fromDate = from?.utc().format() ?? moment().utcOffset(0, true).utc().format();
    const toDate = to.utc().format();
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ from: fromDate, to: toDate }));
  }

  getCalculatedFromToDates({ from, to }) {
    //If From date has elapsed.
    const fromObj = from ? createLocalTimeObject(from) : moment();
    if (moment() > fromObj) {
      const newFrom = moment();
      const { hours, minutes, day } = getDateFragments(fromObj);
      newFrom.day(day).hour(hours).minute(minutes);
      //if the new From date is in the past.
      if (newFrom < moment()) {
        newFrom.add(7, 'days');
      }
      const newTo = moment(newFrom);
      newTo.add(7, 'days');
      return { from: newFrom, to: newTo };
    } else {
      return { from: moment.utc(from), to: moment.utc(to) };
    }
  }

  componentDidMount() {
    const { initialize } = this.props;
    const { toInitialDate, getStoredDates, getCalculatedFromToDates } = this;
    const storedDates = getStoredDates();
    if (storedDates) {
      const { from, to } = getCalculatedFromToDates(storedDates);
      if (from) {
        from.utcOffset(0, true);
      }
      to.utcOffset(0, true);
      initialize({
        fromHorizonDate: from,
        toHorizonDate: to,
      });
      this.setState({
        curFromDate: from,
        curToDate: to,
      });
    } else {
      initialize({
        fromHorizonDate: null,
        toHorizonDate: toInitialDate.utcOffset(0, true),
      });
    }

    /*  let initialValues = {
      fromHorizonDate: null,
      toHorizonDate: this.toInitialDate.utcOffset(0, true),
    };
    this.props.initialize(initialValues); */
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.labels.get('footer.autoSchedule'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled:
            this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') &&
              !this.props.sData.get('isIgnoreValidationWarnings')),
        },
      ],
    };
  }

  calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), 'day');
    if (isToday) {
      let nowAddOneMinute = moment(new Date()).add({ minutes: 1 });
      return nowAddOneMinute;
    }
    return moment(date).startOf('day');
  };

  onFromDateChange = (date) => {
    this.setState({
      curFromDate: this.handleDateChangeCalculation(date, 'fromHorizonDate'),
      curFromMinTime: this.calculateMinTime(date),
    });
  };
  onToDateChange = (date) => {
    this.setState({
      curToDate: this.handleDateChangeCalculation(date, 'toHorizonDate'),
      curToMinTime: this.calculateMinTime(date),
    });
  };

  handleDateChangeCalculation = (date, datePickerId) => {
    const now = moment().utcOffset(0, true);
    if (date && !date.isUtcOffset()) {
      date.utcOffset(0, true);
    }

    if (date?.isBefore(now)) {
      // manually change redux-form value because calculation takes place after datepicker onChange.
      this.props.change(datePickerId, moment().utcOffset(0, true).add(1, 'minutes'));
      return moment().add(1, 'minutes');
    }

    return date;
  };

  minDateTimeValidate = (value, allValues) => {
    let minDateTime = moment().utcOffset(0, true).add(1, 'minutes');
    return Validation.minDateTime(minDateTime)(value?.utcOffset(0, true), allValues);
  };

  maxDateTimeValidate = (value, allValues) => {
    let maxDateTime = moment().utcOffset(0, true).add(3, 'months').endOf('day');
    return Validation.maxDateTime(maxDateTime)(value, allValues);
  };

  toDateValidate = (value, allValues) => {
    if (!value || !value._isValid) {
      //do not care if value exists or has a proper format
      return undefined;
    }
    let fromDate =
      allValues && allValues.fromHorizonDate
        ? allValues.fromHorizonDate
        : moment().utcOffset(0, true);

    let isFromDateValueValid =
      fromDate && fromDate._isValid && fromDate.isBefore(this.maxDate, 'minute');

    if (isFromDateValueValid && value.isBefore(fromDate)) {
      return this.labels.get('validation.laterThanFromDate');
    }

    return undefined;
  };

  onSubmit = (data) => {
    if (data) {
      const { fromHorizonDate: from, toHorizonDate: to } = data;
      const { storeDates } = this;
      let newData = {
        tasksAssignmentsIds: this.props.sData.get('itemsToEdit'),
        fromTime: DateTimeHelper.ConvertFromDate(data.fromHorizonDate),
        toTime: DateTimeHelper.ConvertFromDate(data.toHorizonDate),
        ignoreValidationWarnings:
          this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox
            ? data.footerValidationCheckbox
            : false,
      };

      this.props.actions.submit(newData, this.props.sSchedulerEngineInstance);
      storeDates({ from, to });
    }
  };

  onHide = () => {
    this.props.actions.hide();
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox')
      ? { label: this.dialogLabels.get('ignorewarnings'), onChange: this.onFooterValidationChange }
      : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {
    return (
      <Dialog
        id="auto-scheduler-dialog"
        className="auto-scheduler-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        // validationArea  = {this.getValidationArea()}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(
          this.props.sData,
          this.dialogLabels
        )}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <span className="title">{this.labels.get('horizon.title')}</span>
        <InputSection
          label={this.labels.get('horizon.from') + '*'}
          htmlFor="fromHorizonDate"
          className="inline left-side"
        >
          <DatePicker
            id="fromHorizonDate"
            name="fromHorizonDate"
            showTimeSelect
            dateFormat={DateTimeHelper.getDateTimeFormat()}
            popperPlacement="auto"
            timeIntervals={10}
            isClearable={true}
            placeholderText={this.labels.get('horizon.from.placeholder')}
            onChangeCallback={this.onFromDateChange}
            selected={this.state.curFromDate}
            minDate={this.minDate}
            maxDate={this.maxDate}
            minTime={this.state.curFromMinTime}
            maxTime={this.state.curMaxTime}
            fixedHeight
            preventOpenOnFocus={true}
            validate={[Validation.date, this.minDateTimeValidate, this.maxDateTimeValidate]}
          />
        </InputSection>
        <InputSection
          label={this.labels.get('horizon.to') + '*'}
          htmlFor="toHorizonDate"
          className="inline right-side"
        >
          <DatePicker
            id="toHorizonDate"
            name="toHorizonDate"
            showTimeSelect
            dateFormat={DateTimeHelper.getDateTimeFormat()}
            popperPlacement="auto"
            initialDate={this.toInitialDate}
            timeIntervals={10}
            isClearable={true}
            onChangeCallback={this.onToDateChange}
            selected={this.state.curToDate}
            minDate={this.minDate}
            maxDate={this.maxDate}
            minTime={this.state.curToMinTime}
            maxTime={this.state.curMaxTime}
            fixedHeight
            preventOpenOnFocus={true}
            validate={[
              Validation.required,
              Validation.date,
              this.toDateValidate,
              this.minDateTimeValidate,
              this.maxDateTimeValidate,
            ]}
          />
        </InputSection>
      </Dialog>
    );
  }
}

export default AutoSchedulerDialog = reduxForm({
  form: 'autoSchedulerDialog',
  onChange: (values, dispatch, props, previousValues) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
})(AutoSchedulerDialog);

AutoSchedulerDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  sSchedulerEngineInstance: PropTypes.object.isRequired,
};

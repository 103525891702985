import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as headerActions }  from '../../../actions/Mobile/Header/headerActions.js';
import ScannedAssetsListPage from '../../../components/Mobile/ScannedAssetsListPage/scannedAssetsListPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      header: bindActionCreators(headerActions, dispatch),
    }
  }
}

let ScannedAssetsListPageContainer = connect(
  (state) => {
    return {
      sData: state.mobile.get('data'),
    };
  },
  mapDispatchToProps
)(ScannedAssetsListPage);

export default ScannedAssetsListPageContainer;





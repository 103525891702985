import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {RfidSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import ActionSequenceViewGrid from './ActionSequenceViewGrid/actionSequenceViewGrid';


require('./actionSequenceView.scss');

export default class ActionSequenceView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.mqtt.brokers.view.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_AUTOMATED_WORKFLOWS_ACTION_SEQUENCE);
  }

  render() {
    // let title = this.labels.get('title');
    let title = 'Automated workflows > Action sequences';

    return (
      <div className="administration-view action-sequences-view">
        <div className="title">{title}</div>

        <ActionSequenceViewGrid actions={this.props.actions.actionSequenceViewGridActions} />

      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Label from 'infrastructure/js/components/Label/label.js';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import cn from 'classnames';
import { getToolRequiredOptions } from '../../../../../utils/toolRequiredHelper';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';

require('./kitTypeOperationListItem.scss');

const validateDurationTimeMin = (value, allValues) => {
  const minValue = {
    _hours: 0,
    _minutes: 1
  }

  return Validation.timeField.min(minValue)(value, allValues)
};
export default class KitTypeOperationListItem extends React.PureComponent {
  constructor(props) {
    super(props);

    const selectedOperationData = props?.options?.find((operation) => props?.preSelectedItem?.operationId === operation?.data?.id)?.data;
    this.toolRequiredOptions = getToolRequiredOptions();
    this.state = {
      humanCapacityMaxEnabled: selectedOperationData?.manHours,
    };
  }

  onOperationChangeCallback = (value, oldValue) => {
    this.setState({ humanCapacityMaxEnabled: value?.data?.manHours });

    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
    if (this.props.onOperationChangeCallback) {
      this.props.onOperationChangeCallback(value, oldValue, this.props.index);
    }
  };

  onToolTypeChangeCallback = (value, oldValue) => {
    this.props.onToolRequiredChangeCallback(value, oldValue, this.props.index);
  };

  onCheckboxClick = () => {
    this.props.change(this.props.name + '.[minimumSplitBlock]', '');
    this.props.change(this.props.name + '.[maximumTotalDuration]', '');
  };

  validateMinimumSplitBlock = (value, allValues) => {
    let { index } = this.props;
    let duration = allValues.operations && allValues.operations[index] ? allValues.operations[index].duration : null;
    if (duration) {
      return Validation.timeField.max(duration)(value, allValues);
    }
    return undefined;
  };

  validateMaximumTotalDuration = (value, allValues) => {
    let { index } = this.props;
    let duration = allValues.operations && allValues.operations[index] ? allValues.operations[index].duration : null;
    if (duration) {
      return Validation.timeField.min(duration)(value, allValues);
    }
    return undefined;
  };

  getComponentToRender = (props) => {
    return props && props.data ? <Label text={props.data.label} /> : null;
  };

  validateMaxHumanCapacity = (value, allValues) => {
    let { index } = this.props;
    if (!value) {
      return undefined;
    }

    const minHumanCapacity = allValues?.operations[index]?.minHumanCapacity;
    if (value < minHumanCapacity) {
      const { labels } = this.props;
      return labels?.get?.('operations.humanCapacityMax.validation.maxIsLowerThanMin');
    }

    return undefined;
  };

  maxLength100 = Validation.maxLength(100);

  render() {
    const { name, index, allOperations, checkToolPreviousOptionDisabled } = this.props;

    return (
      <div
        className={cn('kit-type-operation-list-item', {
          'scheduler-enabled': PermissionManager.getOrgPreferences().schedulerEnabled,
        })}
      >
        <div className="column">
          <div id={name + '.[order]'}>{index + 1}</div>
        </div>

        <div className="column">
          <Combobox
            id={name + '.[operationName]'}
            name={name + '.[operationName]'}
            options={allOperations}
            parse={Parse.comboValueOnly()}
            format={Format.findOptionByValue(allOperations)}
            onChangeCallback={this.onOperationChangeCallback}
            className=""
            validate={Validation.required}
            singleValueRenderer={this.getComponentToRender}
            optionRenderer={this.getComponentToRender}
          />
        </div>

        <div className="column">
          <TimeField
            id={name + '.[duration]'}
            name={name + '.[duration]'}
            hoursCount={true}
            maxHoursLength={3}
            validate={[Validation.timeField.required, validateDurationTimeMin]}
          />
        </div>

        <div className="column flex-2">
          <Checkbox id={name + '.[durationPerPart]'} name={name + '.[durationPerPart]'} />
        </div>

        {PermissionManager.getOrgPreferences().schedulerEnabled && PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled && (
          <>
            <div className="column hc">
              <TextField
                id={name + '.[minHumanCapacity]'}
                name={name + '.[minHumanCapacity]'}
                className="short-textfield"
                normalize={Normalize.number(true, 1, 999)}
                validate={Validation.required}
              />
            </div>

            <div className="column hc">
              <TextField
                id={name + '.[maxHumanCapacity]'}
                name={name + '.[maxHumanCapacity]'}
                className="short-textfield"
                validate={this.state.humanCapacityMaxEnabled ? [this.validateMaxHumanCapacity, Validation.required] : []}
                normalize={Normalize.number(true, 1, 999)}
                disabled={!this.state.humanCapacityMaxEnabled}
              />
            </div>
          </>
        )}

        {PermissionManager.getOrgPreferences().schedulerEnabled && (
          <React.Fragment>
            <div className="column flex-2">
              <Checkbox
                id={name + '.[splitEnabled]'}
                name={name + '.[splitEnabled]'}
                disabled={!PermissionManager.getOrgPreferences().schedulerEnabled}
                onClick={this.onCheckboxClick}
              />
            </div>

            <div className="column">
              <TimeField
                id={name + '.[minimumSplitBlock]'}
                name={name + '.[minimumSplitBlock]'}
                hoursCount={true}
                maxHoursLength={3}
                disabled={!PermissionManager.getOrgPreferences().schedulerEnabled || !this.props.splitEnabled}
                validate={this.props.splitEnabled ? [Validation.timeField.required, this.validateMinimumSplitBlock] : undefined}
              />
            </div>

            <div className="column">
              <TimeField
                id={name + '.[maximumTotalDuration]'}
                name={name + '.[maximumTotalDuration]'}
                hoursCount={true}
                maxHoursLength={3}
                disabled={!PermissionManager.getOrgPreferences().schedulerEnabled || !this.props.splitEnabled}
                validate={this.props.splitEnabled ? [Validation.timeField.required, this.validateMaximumTotalDuration] : undefined}
              />
            </div>
          </React.Fragment>
        )}

        {PermissionManager.isToolsSchedulingEnabled() && (
          <div className="column">
            <Combobox
              id={name + '.[toolRequired]'}
              name={name + '.[toolRequired]'}
              options={this.toolRequiredOptions}
              isOptionDisabled={(option) => checkToolPreviousOptionDisabled(index, option)}
              parse={Parse.comboValueOnly()}
              format={Format.findOptionByValue(this.toolRequiredOptions)}
              singleValueRenderer={this.getComponentToRender}
              optionRenderer={this.getComponentToRender}
              onChangeCallback={this.onToolTypeChangeCallback}
            />
          </div>
        )}

        <div className="column">
          <TextField id={name + '.[recipe]'} name={name + '.[recipe]'} className="short-textfield" validate={this.maxLength100} />
        </div>
      </div>
    );
  }
}

KitTypeOperationListItem.defaultProps = {
  options: [],
  splitEnabled: false,
  toolTypes: [],
  allOperations: [],
  preSelectedItem: null,
  onToolRequiredChangeCallback: () => console.error('onToolRequiredChangeCallback is missing!'),
  checkToolPreviousOptionDisabled: () => {
    console.error('checkToolPreviousOptionDisabled is missing!');
    return false;
  },
  change: () => console.error('change is missing!'),
  onChangeCallback: null,
  onOperationChangeCallback: null,
};

KitTypeOperationListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  labels: PropTypes.object.isRequired,
  options: PropTypes.array,
  splitEnabled: PropTypes.bool,
  toolTypes: PropTypes.array,
  allOperations: PropTypes.array,
  onToolRequiredChangeCallback: PropTypes.func,
  checkToolPreviousOptionDisabled: PropTypes.func,
  preSelectedItem: PropTypes.object,
  change: PropTypes.func,
  onChangeCallback: PropTypes.func,
  onOperationChangeCallback: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';

import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import ToggleButton from 'infrastructure/js/components/controls/ToggleButton/toggleButton';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';

require('./pdfLabelPropertyListItem.scss');

export default class PdfLabelPropertyListItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fontSizeOptions = [
            { value: 9, label: '9' },
            { value: 10, label: '10' },
            { value: 11, label: '11' },
            { value: 12, label: '12' },
        ];
    }

    onChangeCallback = (value, oldValue) => {
        if (this.props.onChangeCallback) {
            this.props.onChangeCallback(value, oldValue, this.props.index);
        }

        this.props.change(this.props.name + '.[propertyType]', value.data.name)
    };

    getComponentToRender = (props) => {
        if (!props || !props.data) {
            return null;
        }
        let option = props.data;
        return (
            <span className={option.data && option.data.isMandatory ? 'mark-as-mandatory' : ''}>
                {option.label}
            </span>
        );
    };

    render() {
        let { options, name, index } = this.props;

        return (
            <div className='pdf-label-property-list-item'>
                <div className='column column-1'>
                    <Combobox
                        id={name + '.[propertyName]'}
                        name={name + '.[propertyName]'}
                        options={options}
                        parse={Parse.comboValueOnly()}
                        format={Format.findOptionByValue(options)}
                        onChangeCallback={this.onChangeCallback}
                        className='column column-1'
                        menuPlacement='auto'
                        validate={Validation.dropdown.required}
                        singleValueRenderer={this.getComponentToRender}
                        optionRenderer={this.getComponentToRender}
                    />
                </div>

                <div className='column column-2'>
                    <Combobox
                        id={name + '.[fontSize]'}
                        name={name + '.[fontSize]'}
                        options={this.fontSizeOptions}
                        parse={Parse.comboValueOnly()}
                        format={Format.findOptionByValue(this.fontSizeOptions)}
                        placeholder=''
                        menuPlacement='auto'
                        validate={Validation.dropdown.required}
                    />
                    <ToggleButton id={name + '.[fontBold]'} name={name + '.[fontBold]'}>
                        <i className='fa fa-bold'></i>
                    </ToggleButton>
                </div>
                <div className='column column-3'>
                    {this.props.isBusinessID && (
                        <ToggleButton id={name + '.[printBarcode]'} name={name + '.[printBarcode]'}>
                            <div className='barcode-icon'></div>
                        </ToggleButton>
                    )}
                </div>
            </div>
        );
    }
}

PdfLabelPropertyListItem.defaultProps = {
  options: [],
};

PdfLabelPropertyListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array,
};





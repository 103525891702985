import Network from 'infrastructure/js/modules/network';
import * as batchJobsService from '../../services/BatchJobs/batchJobsService';
import { api as messageDialogApi }      from '../MessageDialog/messageDialogActions';
import { api as batchJobProgressDialogApi }      from '../Dialogs/BatchJobProgressDialog/batchJobProgressDialogActions.js';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import  * as dialogHelper  from "infrastructure/js/components/Dialog/dialogHelper";
import * as BatchJobHelper  from '../../utils/batchJobHelper';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  BATCH_JOBS_ADD_JOB: 'BATCH_JOBS_ADD_JOB',
  BATCH_JOBS_REMOVE_JOB: 'BATCH_JOBS_REMOVE_JOB',
  BATCH_JOBS_UPDATE: 'BATCH_JOBS_UPDATE',
  BATCH_JOBS_BACKGROUND: 'BATCH_JOBS_BACKGROUND',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  addJob: function(payload) {
    return {type: actionTypes.BATCH_JOBS_ADD_JOB, payload: payload };
  },
  removeJob: function(payload) {
    return {type: actionTypes.BATCH_JOBS_REMOVE_JOB, payload: payload };
  },
  update: function(payload) {
    return {type: actionTypes.BATCH_JOBS_UPDATE, payload: payload };
  },
  background: function(payload) {
    return {type: actionTypes.BATCH_JOBS_BACKGROUND, payload: payload };
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};



/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.runJob = function(dispatch, getState) {
  return function(data, options={}) {
      if (data && data.jobInstanceId) {
        let jobData = _convertToBatchJobData(data);
        //add data for the time counter
        jobData.jobStartDate = Date.now();
        jobData.jobRunningDuration = _millisecondsToTimeFormat(0);

        dispatch(actions.addJob(jobData));

        let dialogConfig = {
          jobExecutionId: data.jobExecutionId,
          jobInstanceId: data.jobInstanceId,
        };

        batchJobProgressDialogApi.show(dispatch, getState)(dialogConfig);

        //start polling the batch job's status
        options.pollingCallback = api.pollingCallback(dispatch, getState);

        return batchJobsService.pollingResult(data.jobExecutionId, options)
          .then((response) => {
            if (response.data && _isJobFinished(response.data.jobStatus)) {
              let isInBackground = _isJobRunningInBackground(response.data.jobInstanceId, getState);
              setTimeout(()=> {
                batchJobProgressDialogApi.hide(dispatch, getState)();
                dispatch(actions.removeJob(response.data.jobInstanceId));
                if(!isInBackground) {
                  api.showJobResult(dispatch, getState)(response.data);
                }
              }, 1000 );

              return response;
            }
          })
          .catch(error => {
            console.error('batchJobActions.runJob(): pollingResult catch ', error);
            api.stopJob(dispatch, getState)(data.jobInstanceId);
            batchJobProgressDialogApi.hide(dispatch, getState)();

            if (!_isJobRunningInBackground(data.jobInstanceId, getState)) {
              messageDialogApi.showError(dispatch, getState)();
            }
            dispatch(actions.removeJob(data.jobInstanceId));

            throw error;
          })
      }
  }
};

api.stopJob = function(dispatch, getState) {
  return function (jobInstanceId) {
    batchJobsService.stop(jobInstanceId).then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('Stop job failed', jobInstanceId);
        return {success: false};
      }
    });
  };
};


api.updateJob = function(dispatch, getState) {
  return function(data) {
    if (data && data.jobInstanceId) {
      let job = getState().batchJobs.get('allRunningJobs')[data.jobInstanceId];
      if (job) {
        let jobData = _convertToBatchJobData(data);
        //update for the time counter
        jobData.jobStartDate = job.jobStartDate;
        jobData.jobRunningDuration = _millisecondsToTimeFormat(Date.now() - job.jobStartDate);

        dispatch(actions.update(jobData));
      }
    }
  }
};

api.runInBackground = function(dispatch, getState) {
  return function(jobInstanceId) {
    dispatch(actions.background(jobInstanceId));
  }
};

api.pollingCallback = function(dispatch, getState) {
  return function (response) {
    if (response && response.data && response.data.jobInstanceId) {
      api.updateJob(dispatch, getState)(response.data);
    }
  }
};

api.showJobResult = function(dispatch, getState) {
  return function(data) {

    let labels = createLabelHelper('mat.batchJob.');
    let title = BatchJobHelper.createJobTitle(data.jobName, data.totalJobItems, labels);
    let fnCloseDialog = messageDialogApi.close(dispatch, getState);
    let messageDialogDescriptor = dialogHelper.BuildDialogDescriptorForBO(data, fnCloseDialog, title, false);
    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
  }
};

////////////////////////////////////////////////////

function _convertToBatchJobData (data) {

  let {jobInstanceId,
    jobExecutionId,
    jobName,
    numberOfSucceededItems,
    numberOfCanceledItems,
    numberOfFailedItems,
    numberOfItemsWithWarnings,
    totalJobItems,
    startTime} = data;

    return {jobInstanceId,
      jobExecutionId,
      jobName,
      numberOfSucceededItems,
      numberOfCanceledItems,
      numberOfFailedItems,
      numberOfItemsWithWarnings,
      totalJobItems,
      startTime};
}


function _isJobFinished(status) {
  return status === 'COMPLETED' || status === 'STOPPED' || status === 'FAILED';
}

function _isJobRunningInBackground(jobInstanceId, getState) {
  let backgroundJobs = getState().batchJobs.get('backgroundJobs');

  return !!(backgroundJobs && backgroundJobs[jobInstanceId]);
}

function _millisecondsToTimeFormat(milliseconds) {
  let seconds = parseInt( (milliseconds / 1000) % 60);
  let minutes = parseInt( (milliseconds / (1000*60)) % 60);

  minutes = (minutes < 10) ? '0' + minutes : minutes;
  seconds = (seconds < 10) ? '0' + seconds : seconds;

  return minutes + ':' + seconds;
}

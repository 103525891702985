import { gridsNames } from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import * as rollService from '../../services/Rolls/rollsService';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_SMART_SELECTION, _fetchDataAction, _convertToRowData);

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = { ...gridActions.apiActions };

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    let headerData = getState().predefinedList.get('smartSelectionHeader');
    let selectWo = headerData.get('selectWo');
    let selectMaterial = headerData.get('selectMaterial');
    let selectLocation = headerData.get('selectLocation');
    let selectProject = headerData.get('selectProject');

    if (PermissionManager.getOrgPreferences().smartSelectionPickDatesRangeEnabled) {
      let selectWoPickDateFrom = headerData.get('selectWoPickDateFrom');
      let selectWoPickDateTo = headerData.get('selectWoPickDateTo');
      query.woPickDateFrom = selectWoPickDateFrom || null;
      query.woPickDateTo = selectWoPickDateTo || null;
    } else {
      let selectWoPickDate = headerData.get('selectWoPickDate');
      query.woPickDateFrom = selectWoPickDate || null;
    }

    let optionToValue = (option) => {
      return option.value;
    };

    query.workOrderIds = selectWo ? selectWo.map(optionToValue) : [];
    query.materialIds = selectMaterial?.map((material) => material?.value) || [];
    query.projectIds = selectProject ? selectProject.map(optionToValue) : [];
    let locationFilter = query.filters.find((f) => f.filterName === 'assetLocationName');
    locationFilter.values = _intersectFilterGroups(selectLocation, locationFilter.values);

    return rollService.getRollsBySmartSelection(query);
  };
}

function _convertToRowData(rows, serverTimeSeconds) {
  let materials = rows.map((obj, index) => {
    obj.isRowMaster = obj?.assets?.length > 0;
    obj.assets.forEach((roll) => {
      if (roll.expirationDate) {
        roll.m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(roll.expirationDate.value, serverTimeSeconds);
      }
    });

    return obj;
  });

  return materials;
}

export let actionTypes = gridActions.actionTypes,
  jsxActions = gridActions.jsxActions;

//private helpers
function _intersectFilterGroups(headerValues, filterValues) {
  if (headerValues?.length) {
    headerValues = headerValues.map((headerValue) => headerValue.label);
    if (filterValues?.length) {
      let intersect = filterValues.filter((filterValue) => headerValues.includes(filterValue));
      return 0 < intersect.length ? intersect : headerValues;
    } else {
      return headerValues;
    }
  } else {
    return filterValues || [];
  }
}

import favorite from './favorite-star.svg';
import schedulerBig from './scheduler-big.svg';
import schedulerSmall from './scheduler-small.svg';
import location from './header-icon-location.svg';
import tasks from './tasks.svg';
import report from './report.svg';
import operationsManager from './operations-manager.svg';
import assetsManager from './assets-manager.svg';
import user from './user-outline-white.svg';
import circledUser from './circled-user.svg';
import managerReport from './manager-report.svg';
import inventory from './inventory.svg';
import workOrder from './work-order.svg';
import shipment from './shipment.svg';
import materialSmartSelection from './material-smart-selection.svg';
import pickList from './pick-list.svg';
import onHandInventory from './on-hand-inventory.svg';
import rollSmall from './roll-small.svg';
import kitSmall from './kit-small.svg';
import toolSmall from './tool-small.svg';
import groupSmall from './group-small.svg';
import scrapSmall from './scrap-small.svg';
import productionDashboardSmall from './production-dashboard-sm.svg';
import progressDashboardSmall from './progress-dashboard-sm.svg';
import toolMaintenanceSmall from './tool-maintenance-sm.svg';
import inventoryDashboardSmall from './inventory-dashboard-sm.svg';
import tpoMasterDataSmall from './tpo-master-data-sm.svg';
import userManagementSmall from './user-management-sm.svg';
import rfidSettingsSmall from './rfid-settings-sm.svg';
import billingSettingsSmall from './billing-settings-sm.svg';
import psManagementSmall from './ps-management-sm.svg';
import locationsAndStationsBig from './locations-stations-big.svg';
import stationSmall from './station-sm.svg';
import locationSmall from './location-sm.svg';
import matSettings from 'infrastructure/assets/svg/header-icon-mat-settings.svg';
import settings from 'infrastructure/assets/svg/header-settings-icon.svg';
import notification from './notification-line.svg';
import error from './header-error.svg';
import circledQuestionMark from './circled-question-mark.svg';
import spool from './header-icon-spool.svg';
import part from './header-icon-part.svg';

export const icons = {
  favorite,
  schedulerBig,
  schedulerSmall,
  operationsManager,
  assetsManager,
  location,
  user,
  circledUser,
  report,
  managerReport,
  inventory,
  workOrder,
  tasks,
  shipment,
  materialSmartSelection,
  pickList,
  onHandInventory,
  rollSmall,
  kitSmall,
  toolSmall,
  groupSmall,
  scrapSmall,
  productionDashboardSmall,
  progressDashboardSmall,
  toolMaintenanceSmall,
  inventoryDashboardSmall,
  tpoMasterDataSmall,
  userManagementSmall,
  rfidSettingsSmall,
  billingSettingsSmall,
  psManagementSmall,
  locationsAndStationsBig,
  stationSmall,
  locationSmall,
  matSettings,
  settings,
  notification,
  error,
  circledQuestionMark,
  spool,
  part
};

export default icons;

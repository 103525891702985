import Network from "infrastructure/js/modules/network";
import PermissionManager from "infrastructure/js/utils/permissionManager";
import * as rollsService from "../../../services/Rolls/rollsService";
import * as daHelper from "../../Dialogs/dialogActionsHelper";
import { api as messageDialogApi } from "../../MessageDialog/messageDialogActions.js";
import { dialogsNames } from "../../../enums/dialogsNames";
import * as dialogHelper from "../../../../../infrastructure/js/components/Dialog/dialogHelper";
import { api as printPDFLabelsAPI } from '../../PrintLabelsActions/printLabelsActions';
import { enumTypes, getEnumValue } from "../../../utils/enumHelper";

const config = {
  getSubmitMethod: () => PermissionManager.isWeightSupported() ? rollsService.createSubRollsByWeight : rollsService.createSubRolls
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_SUB_ROLLS_DIALOG)
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_SUB_ROLLS_DIALOG)
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  generateSubRollId(dispatch, getState) {
    return function(parentRollId) {
      return rollsService.generateSubRollId(parentRollId).then((response) => {

        if (!Network.isResponseValid(response)) {
          console.error("Generate sub roll id failed", response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return "";
        }

        return response.data;
      });
    };
  },

  submit: (dispatch, getState) => (data, messageDialogBuilder, reloadParentComponent, printPDFLabels) => {

    dispatch(actions.setBusy(true));

    let method = PermissionManager.isWeightSupported() ? rollsService.createSubRollsByWeight : rollsService.createSubRolls;
    method(data).then((response) => {
      dispatch(actions.setBusy(false));

      let validations = dialogHelper.getResponseValidationDetails(response);
      if (validations) {
        dispatch(actions.setValidationWarningsData(validations));
        return response;
      }

      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error(config.getSubmitMethod().name + " failed ", response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return response;
      }

      if (printPDFLabels) {
        printPDFLabelsAPI.printLabels(dispatch, getState)(response.dataList.map(roll => roll.data.id), getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'));
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      if (messageDialogBuilder) {
        let messageDialogDescriptor = messageDialogBuilder(...this.getMessageDialogBuilderData(dispatch, getState)(response, data));
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
      }

    }).catch((error) => {
      console.error("dialogActionsHelper: submit failed, catch error: ", error);
      dispatch(actions.setBusy(false));
      dispatch(actions.hide());
    });
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  generateSubRollId(parentRollId) {
    return function(dispatch, getState) {
      return api.generateSubRollId(dispatch, getState)(parentRollId);
    };
  }

};

















import UserService from '../../services/UserManagement/UserService.js';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';

export const actionTypes = {
  DIALOG_CHANGEPASSWORD_SHOW: "DIALOG_CHANGEPASSWORD_SHOW",
  DIALOG_CHANGEPASSWORD_HIDE: "DIALOG_CHANGEPASSWORD_HIDE",

  DIALOG_CHANGEPASSWORD_IN_PROCESS: "DIALOG_CHANGEPASSWORD_IN_PROCESS",
  DIALOG_CHANGEPASSWORD_FINISHED: "DIALOG_CHANGEPASSWORD_FINISHED"
};

const actions = {
  show: function (payload) {
    return {type: actionTypes.DIALOG_CHANGEPASSWORD_SHOW, payload};
  },
  hide: function () {
    return {type: actionTypes.DIALOG_CHANGEPASSWORD_HIDE};
  },
  submitInProcess: function () {
    return {type: actionTypes.DIALOG_CHANGEPASSWORD_IN_PROCESS};
  },
  submitFinished: function () {
    return {type: actionTypes.DIALOG_CHANGEPASSWORD_FINISHED};
  }
};


export let jsxActions = {};
jsxActions.onSubmit = function (queryData,callbackAction,serviceAction,messageDialogBuilder) {
  return function (dispatch, getState) {
    dispatch(actions.submitInProcess());

    serviceAction(queryData, callbackAction, messageDialogBuilder).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Change Password Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      if (callbackAction){
        callbackAction();
      }

      let messageDialogDescriptor = messageDialogBuilder(
        messageDialogApi.close(dispatch, getState)
      );

      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

    });
  }
};

jsxActions.close = function () {
  return function (dispatch, getState) {
    dispatch(actions.hide());
  }
};

export let api = {};


api.show = function (user,callbackAction,serviceAction, dialogLabels) {
  return function (dispatch, getState) {
    dispatch(actions.show({user,callbackAction,serviceAction, dialogLabels}));
  }
};

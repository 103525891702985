export const dialogsNames = {
  ACTIVATE_WO_DIALOG: 'ACTIVATE_WO_DIALOG',
  CREATE_MQTT_BROKER_DIALOG: 'CREATE_MQTT_BROKER_DIALOG',
  CREATE_MQTT_SENSOR_DIALOG: 'CREATE_MQTT_SENSOR_DIALOG',
  CREATE_GROUP_DIALOG: 'CREATE_GROUP_DIALOG',
  ADD_TO_GROUP_DIALOG: 'ADD_TO_GROUP_DIALOG',
  CREATE_SUB_ROLLS_DIALOG: 'CREATE_SUB_ROLLS_DIALOG',
  REPORT_CUT_DIALOG: 'REPORT_CUT_DIALOG',
  CUT_AND_KIT_DIALOG: 'CUT_AND_KIT_DIALOG',
  REPORT_KIT_DIALOG: 'REPORT_KIT_DIALOG',
  WORK_ORDER_DIALOG: 'WORK_ORDER_DIALOG',
  AUTO_SCHEDULER_DIALOG: 'AUTO_SCHEDULER_DIALOG',
  SCHEDULE_TASK_DIALOG: 'SCHEDULE_TASK_DIALOG',
  ARCHIVE_WO_DIALOG: 'ARCHIVE_WO_DIALOG',
  CREATE_SHIFT_TYPE_DIALOG: 'CREATE_SHIFT_TYPE_DIALOG',
  SHIFT_SETTINGS_DIALOG: 'SHIFT_SETTINGS_DIALOG',
  CREATE_AUTOMATION_SCENARIO_DIALOG: 'CREATE_AUTOMATION_SCENARIO_DIALOG',
  CREATE_KIT_TYPE_DIALOG: 'CREATE_KIT_TYPE_DIALOG',
  IMPORT_KIT_TYPES_DIALOG: 'IMPORT_KIT_TYPES_DIALOG',
  CREATE_OPERATION_DIALOG: 'CREATE_OPERATION_DIALOG',
  CREATE_TOOL_CATEGORY_DIALOG: 'CREATE_TOOL_CATEGORY_DIALOG',
  ANALYTIC_MODE_SETTINGS_DIALOG: 'ANALYTIC_MODE_SETTINGS_DIALOG',
  CREATE_GROUP_TYPE_DIALOG: 'CREATE_GROUP_TYPE_DIALOG',
  EDITASSETATTRIBUTES_DIALOG: 'EDITASSETATTRIBUTES_DIALOG',
  ADD_TO_PICK_LIST_DIALOG: 'ADD_TO_PICK_LIST_DIALOG',
  ADD_REPLACE_SPOOLS_DIALOG: 'ADD_REPLACE_SPOOLS_DIALOG',
  IMPORT_TOOL_TYPES_DIALOG: 'IMPORT_TOOL_TYPES_DIALOG',
};

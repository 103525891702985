import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';




import RelatedAssetsTab from '../../components/AssetPage/RelatedAssetsTab/relatedAssetsTab.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {

    }
  }
}


let RelatedAssetsTabContainer = connect(
  (state) => {
  	return {

    };
  },
  mapDispatchToProps
)(RelatedAssetsTab);

export default RelatedAssetsTabContainer;





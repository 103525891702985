import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { gridsNames } from "../../enums/gridsNames";
import { jsxActions as viewActions } from "../../actions/PredefinedList/MaterialScrap/materialScrapViewActions.js";
import { jsxActions as headerActions } from "../../actions/Header/headerActions";
import { jsxActions as gridActions } from "../../actions/PredefinedList/MaterialScrap/materialScrapGridActions.js";
import { jsxActions as fetchEntitiesActions } from "../../actions/FetchEntities/fetchEntitiesActions.js";
import { jsxActions as exportActions } from "../../actions/Export/exportActions";

import MaterialScrapView from "../../components/PredefinedList/MaterialScrapView/materialScrapView";

export default connect(
  (state) => {
    let materialScrapGridState = state.grid.get(gridsNames.PREDEFINED_MATERIAL_SCRAP);
    return {
      sMaterialScrapGrid: materialScrapGridState,
      sHasRows: materialScrapGridState?.get("rows")?.size > 0 || false,
    };
  },
  (dispatch) => {
    return {
      actions: {
        ...bindActionCreators(viewActions, dispatch),
        header: bindActionCreators(headerActions, dispatch),
        fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
        gridActions: bindActionCreators(gridActions, dispatch),
        exportActions: bindActionCreators(exportActions, dispatch)
      }
    };
  }
)(MaterialScrapView);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
let DatePicker = require('react-datepicker').default;
let moment = require('moment');
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Popover from '../Popover/popover';

require('./datepickerOverlayEditor.scss');

export default class DatepickerOverlayEditor extends Component {

  constructor(props)
  {
    super(props);

    this.state = {editMode: false};
  }

  onEntered = () => {
    this.setState({editMode: true});
  };
  onExited = () => {
    this.setState({editMode: false});
  };

  onChange = (d) => {
    let date = d ? moment(d, DateTimeHelper.getDateFormat(), DateTimeHelper.getUserOrgLocale(), true).utcOffset(0, true) : null;
    this.handleSubmit(DateTimeHelper.ConvertFromDate(date));
  };

  onKeyDown = (e) => {
    if (e.keyCode === 46 || e.keyCode === 8) { //'Delete' or 'Backspace
      this.handleSubmit(null);
    }
  };

  handleSubmit = (data) => {
    if (this.props.handleSubmit) {
      let submitData = {};
      submitData.fieldName = this.props.name;
      submitData[this.props.name] = data;

      this.props.handleSubmit(submitData);
    }

    //close Popover
    document.body.click();
  };

  renderInput = (editableClassName)=>{
    return (<div className={`datepicker-label ${editableClassName}`}>
      <input type="text" value={DateTimeHelper.FormatDateObjectToDayMonth(this.props.value)} onKeyDown={this.onKeyDown} readOnly={true} />
      {this.props.alert}
    </div>)
  };

  getPopoverComponent = () => {

    let selectedDate = this.props.value ? DateTimeHelper.ConvertToDate(this.props.value).startOf('day') : null;
    return (
      <div>
        <div className="pl-datepicker-overlay">
          <DatePicker
            inline
            fixedHeight
            selected={selectedDate}
            onChange={this.onChange}
            locale={DateTimeHelper.getUserOrgLocale()}
            minDate={this.props.minDate ? this.props.minDate : null}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          > </DatePicker>
        </div>
      </div>
    )
  };

  render() {
    let editableClassName =   this.state.editMode ? 'editmode' : this.props.editable ? 'editable' : 'disabled';
    if(!this.props.editable){
      return this.renderInput(editableClassName)
    }
    return <Popover
      popoverId='datepicker-overlay'
      className='datepicker-overlay'
      trigger="click"
      placement = {this.props.placement}
      onEnter={this.onEntered}
      onExit={this.onExited}
      popoverTitle=''
      popoverComponent={this.getPopoverComponent()}
    >
      { this.renderInput(editableClassName)}

    </Popover>
  }
}

DatepickerOverlayEditor.defaultProps = {
  placement: 'bottom',
  editable: true
};
DatepickerOverlayEditor.propTypes = {
  placement: PropTypes.string,
  editable: PropTypes.bool,
};

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as pickListsPageActions} from '../../actions/PickListsPage/pickListsPageActions';
import {jsxActions as pickListsGridActions} from '../../actions/PickListsPage/pickListsGridActions';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

import {jsxActions as moveAssetsActions} from "../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions";
import {jsxActions as fetchEntitiesActions} from '../../actions/FetchEntities/fetchEntitiesActions';
import PickListsPage from "../../components/PredefinedList/PickListsPage/pickListsPage";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(pickListsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      exportActions: bindActionCreators(exportActions, dispatch),
      pickListsGridActions: bindActionCreators(pickListsGridActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
    }
  }
}

let PickListsPageContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.PREDEFINED_LIST_PICK_LISTS) ? state.grid.get(gridsNames.PREDEFINED_LIST_PICK_LISTS) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sMoveAssetsData:  state.dialogs.get('moveAssetsDialog'),
    };
  },
  mapDispatchToProps
)(PickListsPage);


export default PickListsPageContainer;




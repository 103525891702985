import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import {reduxForm} from 'redux-form';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import MaterialHelper from "../../../../../utils/materialHelper";
import Label from "../../../../../../../infrastructure/js/components/Label/label";
import Combobox from "../../../../../../../infrastructure/js/components/controls/Combobox/combobox";
import PropTypes from "prop-types";

require('./dashboardHeader.scss');

class InventoryDashboardHeader extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {};
    this.labels = createLabelHelper('mat.dashboard.inventory.inventoryManagement.filter.');

    this.state = {
      isFreezers: false,
      selectedLocations: [],
      selectedMaterials: []
    };
  }

  onSubmit = () => {
    this.props.loadAction(this.state);
  };

  onIsFreezersCheckboxClick = () => {
    this.props.change('selectLocations', []);
    this.onLocationChange([]);

    // overcome setState async update
    this.setState({isFreezers: !this.state.isFreezers}, () => {
      this.onSubmit();
    });
  };

  onLocationChange = (newData) =>{
    this.setState({selectedLocations: newData});
  };

  onMaterialChange = (newData) =>{
    this.setState({selectedMaterials: newData});
  };

  onLocationBlur = () => {
    this.onSubmit()
  };

  onMaterialBlur = () => {
    this.onSubmit()
  };

  getComponentToRender = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;
    let materialLabel = MaterialHelper.getMaterialFullLabel(option.data.materialName, option.data.businessId);

    return (<Label text={materialLabel} />);
  };

  render() {
    return (
      <div className="dashboard-header inventory-dashboard-header">

        {this.props.showMaterialFilter && <InputSection label={this.labels.get('material')} htmlFor="selectMaterials" className="inline">
          <PL_MultiSelectField
            id="selectMaterials"
            name="selectMaterials"
            options={this.props.headerData.materials}
            isSearchable={true}
            allowNewOption={false}
            optionRenderer={this.getComponentToRender}
            multiValueRenderer={this.getComponentToRender}
            closeMenuOnSelect={false}
            className="multi-select-field"
            onBlur={this.onMaterialBlur}
            onChangeCallback={this.onMaterialChange}
           >
          </PL_MultiSelectField>
        </InputSection>}

        {this.props.showLocationFilter && <InputSection label={this.labels.get('location')} htmlFor="selectLocations" className="inline">
          <PL_MultiSelectField
            id="selectLocations"
            name="selectLocations"
            options={this.props.headerData.locations}
            isSearchable={true}
            allowNewOption={false}
            closeMenuOnSelect={false}
            className="multi-select-field"
            isDisabled={this.state.isFreezers}
            onChangeCallback={this.onLocationChange}
            onBlur={this.onLocationBlur}>
          </PL_MultiSelectField>
        </InputSection>}

        {this.props.showFreezerFilter && <InputSection htmlFor="isFreezers" className="inline padding-top">
          <Checkbox className="" name="isFreezers" id="isFreezers" label={this.labels.get('freezersOnly')}
                    onClick={this.onIsFreezersCheckboxClick}/>
        </InputSection>}

      </div>
    );
  }
}

export default reduxForm({
  }
)(InventoryDashboardHeader);

InventoryDashboardHeader.defaultProps = {
  showMaterialFilter: false,
  showLocationFilter: false,
  showFreezerFilter: false,
};

InventoryDashboardHeader.propTypes = {
  showMaterialFilter: PropTypes.bool,
  showLocationFilter: PropTypes.bool,
  showFreezerFilter: PropTypes.bool,
};

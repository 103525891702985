import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';

import ActiveSpoolsContainer from '../../../containers/ActiveSpoolsPage/activeSpoolsContainer.js';
import AlertedSpoolsContainer from '../../../containers/AlertedSpoolsPage/alertedSpoolsContainer.js';
import ArchivedSpoolsContainer from '../../../containers/ArchivedSpoolsPage/archivedSpoolsContainer';

import './spoolsPage.scss';

const tabsLabels = createLabelHelper('mat.common.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  ACTIVE: 'active',
  ALERTED: 'alerted',
  ARCHIVED: 'archived',
};

const SpoolsPage = () => {
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl(
    'PredefinedViews/rawMaterials/spools',
    Object.values(tabKeys),
    'active',
    'materialStatus'
  );

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  return (
    <div className="spools-page">
      <Tabs id="spools-tabs" tabType="content" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('active')} eventKey={tabKeys.ACTIVE}>
          <ActiveSpoolsContainer />
        </Tab>
        <Tab title={tabsLabels.get('alerted')} eventKey={tabKeys.ALERTED}>
          <AlertedSpoolsContainer />
        </Tab>
        <Tab title={tabsLabels.get('archived')} eventKey={tabKeys.ARCHIVED}>
          <ArchivedSpoolsContainer />
        </Tab>
      </Tabs>
    </div>
  );
};

export default SpoolsPage;

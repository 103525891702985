import {Map} from 'immutable';

import { actionTypes } from '../../../actions/Dialogs/userManagementDialogActions.js';


let defaultState = {
  user : {},
  show : false,
  loading : false,
  serviceAction : undefined,
  sRoleData: [],
  sLocationsData: []
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.DIALOG_USERMANAGEMENT_FETCH_ROLES_FINISHED:
      return state.set('sRoleData', action.payload.dataList);

    case actionTypes.DIALOG_USERMANAGEMENT_FETCH_LOCATIONS_FINISHED:
      return state.set('sLocationsData', action.payload.dataList.map((item) => {return {value: item.id, label: item.name, data: item}}));

    case actionTypes.DIALOG_USERMANAGEMENT_HIDE:
      return Map(defaultState);

    case actionTypes.DIALOG_USERMANAGEMENT_SHOW:
      return state.set('show', true)
        .set('user', action.payload.user)
        .set('serviceAction', action.payload.serviceAction);

    case actionTypes.DIALOG_USERMANAGEMENT_IN_PROCESS:
      return state.set('loading', true);

    case actionTypes.DIALOG_USERMANAGEMENT_FINISHED:
      return state.set('loading', false);

    case actionTypes.DIALOG_USERMANAGEMENT_SET_VALIDATION_WARNINGS_DATA:
      return state.set('validationMessage', action.payload)
        .set('hasError', !!action.payload.errors)
        .set('showIgnoreValidationCheckbox', !action.payload.errors)
        .set('hasHighSeverityWarning', action.payload.reset ? false : action.payload.hasHighSeverityWarning)
        .set('hasMediumSeverityWarning', action.payload.reset ? false : action.payload.hasMediumSeverityWarning)
        .set('isIgnoreValidationWarnings', false);

    case actionTypes.DIALOG_USERMANAGEMENT_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('showIgnoreValidationCheckbox', false)
        .set('hasHighSeverityWarning', false)
        .set('hasMediumSeverityWarning', false)
        .set('hasError', false)
        .set('isIgnoreValidationWarnings', false);

    case actionTypes.DIALOG_USERMANAGEMENT_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('isIgnoreValidationWarnings', action.payload);

    default:
      return state;
  }
}






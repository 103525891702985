import React, { Component } from 'react';
import classNames from 'classnames';
import NotificationsPanel from '../NotificationsPanel/notificationsPanel';
import ScheduleAction from '../../../modules/scheduleAction';
import NotificationsIcon from '../../../../assets/svg/bell.svg';

import './notificationsCenter.scss';

export default class NotificationsCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animate: false,
      dummyKey: 0,
      rotateDummyKey: 0,
    };
  }

  componentDidMount() {
    this.notificationsTimerRefresh = ScheduleAction('Notifications Center Timer Refresh', this.props.actions.onTimerRefresh, 5);
    document.addEventListener('mousedown', this.handelClickOutside, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data && this.props.data && nextProps.data.get('count') > this.props.data.get('count')) {
      this.setState(this.handleNotificationAnimation);
    }

    if (
      nextProps.batchJobsData &&
      this.props.batchJobsData &&
      nextProps.batchJobsData.get('backgroundJobs') !== this.props.batchJobsData.get('backgroundJobs')
    ) {
      this.setState(this.handleBatchJobAnimation);
    }
  }

  componentWillUnmount() {
    this.notificationsTimerRefresh.kill();
    document.removeEventListener('mousedown', this.handelClickOutside, false);
  }

  handelClickOutside = (event) => {
    // this.node is the ref from 'notifications-center' DIV
    if (this.node.contains(event.target)) {
      // The click is inside the panel or on the 'notifications-center' wrapper - do nothing.
      return;
    }

    this.props.actions.hideNotificationsMenu();
  };

  toggleNotifications = () => {
    this.props.actions.toggleNotificationsMenu();
  };

  handleNotificationAnimation = (state, props) => {
    return {
      animate: true,
      //re-render the component to restart the animation
      dummyKey: state.dummyKey === 0 ? 1 : 0,
    };
  };
  handleBatchJobAnimation = (state, props) => {
    return {
      rotateDummyKey: state.dummyKey === 0 ? 1 : 0,
    };
  };

  getNotificationsLabel = (count) => {
    if (count === 0) {
      return;
    }

    return (
      <span key={this.state.dummyKey} className={classNames('notifications-label', { pulse: this.state.animate })}>
        {count > 99 ? '99+' : count}
      </span>
    );
  };

  hasBackgroundJobs = () => {
    let backgroundJobs = this.props.batchJobsData.get('backgroundJobs');
    return backgroundJobs && Object.keys(backgroundJobs) && Object.keys(backgroundJobs).length > 0;
    // return true;
  };

  renderRunningJobsIndicator = () => {
    if (this.hasBackgroundJobs()) {
      return (
        <span className="running-jobs">
          <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw " aria-hidden="true" />
        </span>
      );
    }
    return null;
  };

  renderNotifications = () => {
    return (
      <div className="notifications-center" ref={(node) => (this.node = node)}>
        <div className='notifications-icon-wrapper' onClick={this.toggleNotifications}>
          <NotificationsIcon className={classNames('top-bar-icon', {active: this.props.data.get('show')})} />
          {this.renderRunningJobsIndicator()}
          {this.getNotificationsLabel(this.props.data.get('count'))}
        </div>
        <div className="panel-container">
          <NotificationsPanel
            data={this.props.data.get('notifications')}
            batchJobsData={this.props.batchJobsData}
            open={this.props.data.get('show')}
            loading={this.props.data.get('loading')}
            actions={{ ...this.props.actions }}
          ></NotificationsPanel>
        </div>
      </div>
    );
  };

  render() {
    return this.renderNotifications();
  }
}

import React, {Component} from 'react';
import {LoginLayout, LoginGoLoginPageFooter} from 'infrastructure/js/components/LoginLayout/loginLayout.js';
import { GetValueFromQueryString } from 'infrastructure/js/utils/querystring.js';
import {createLabelHelper} from "../../utils/labelHelper";
import ChangePassword from "../ChangePassword/changePassword";

require('./changePasswordPage.scss');

export default class ChangePasswordPage extends Component{

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.changepassword.');
  }
  componentDidMount() {
    let query = {orgId:this.props.sLoginData.get('orgId'),registrationToken: GetValueFromQueryString('token')};
    //incase of refresh, we dont have the necessary info => redirect to login
    if(!query.orgId && !query.registrationToken){
      return window.location.hash = "/Login"
    }

    this.props.actions.init(query);
  }
  changePassword = (values)=> {
    if(this.props.sLoginData.get('isExpiredPassword')){
      this.props.actions.changeExpiredPassword({password:values.password});
    }
    else{
      this.props.actions.changePassword({password:values.password, registrationToken : GetValueFromQueryString('token')});
    }
  };

  getHasError = () => {
    if (this.props.sData.get('errorMessage')) {
      return ' has-error';
    }
    return '';
  };


  getHeaderMessage = () => {
    if (this.props.sLoginData.get('isExpiredPassword')) {
      return <div className="headerMessage">
        <div>
          Your credentials have been expired.
        </div>
        <div>
          Select a new password
        </div>
      </div>
    }
    return null;
  }

  render() {
    return (
      <LoginLayout formClass={'change-password-page' + this.getHasError()}  actionButtonText="Change Password" actionButtonOnclick={this.props.handleSubmit(this.changePassword)} footerComponent={<LoginGoLoginPageFooter/>} loading={this.props.sData.get('loading')}>
        {this.getHeaderMessage()}
        <ChangePassword/>

        <div className="change-password-error">{this.props.sData.get('errorMessage')}</div>
      </LoginLayout>
    );
  }
}

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as alertedKitsPageActions} from '../../actions/AlertedKitsPage/alertedKitsPageActions'
import {gridsNames} from '../../enums/gridsNames';

import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as shelfLifeUpdateActions } from '../../actions/Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialogActions';
import { jsxActions as alertedKitsGridActions } from '../../actions/AlertedKitsPage/alertedKitsGridActions';
import { jsxActions as archiveAssetsActions }   from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import { jsxActions as moveAssetsActions }      from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as editAssetAttributesActions }      from '../../actions/Dialogs/EditAssetAttributesDialog/editAssetAttributesDialogActions.js';
import { jsxActions as printPdfLabelsActions }  from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';

import AlertedKitsPage from '../../components/PredefinedList/AlertedKitsPage/alertedKitsPage';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(alertedKitsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      shelfLifeUpdateActions:   bindActionCreators(shelfLifeUpdateActions, dispatch),
      alertedKitsGridActions: bindActionCreators(alertedKitsGridActions, dispatch),
      archiveAssetsActions:     bindActionCreators(archiveAssetsActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      editAssetAttributesActions: bindActionCreators(editAssetAttributesActions, dispatch),
      printPdfLabelsActions:      bindActionCreators(printPdfLabelsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let AlertedKitsContainer = connect(
  (state) => {
    let alertedKitsGrid = state.grid.get(gridsNames.PREDEFINED_LIST_ALERTED_KITS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ALERTED_KITS) : null;
    return {
      sAlertedKitsGrid:  alertedKitsGrid,
      sHasRows: alertedKitsGrid && alertedKitsGrid.get('rows') ? alertedKitsGrid.get('rows').size > 0 : false,
      sSelectedRowsData: alertedKitsGrid ? alertedKitsGrid.get('selectedRowsData') : List(),
      sShelfLifeUpdateData: state.dialogs.get('shelfLifeUpdateDialog'),
      sArchiveAssetsData:   state.dialogs.get('archiveAssetsDialog'),
      sMoveAssetsData:      state.dialogs.get('moveAssetsDialog'),
      sEditAttributesData: state.dialogs.get('editAttributesDialog'),
      sEditAssetAttributesData: state.dialogs.get('editAssetAttributesDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(AlertedKitsPage);


export default AlertedKitsContainer;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as archivedPartsPageActions }    from '../../actions/ArchivedPartsPage/archivedPartsPageActions.js';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as activateAssetsDialogActions }    from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';

import { jsxActions as archivedPartsGridActions }    from '../../actions/ArchivedPartsPage/archivedPartsGridActions';
import { jsxActions as moveAssetsActions }        from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';

import ArchivedPartsPage from '../../components/PredefinedList/ArchivedPartsPage/archivedPartsPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(archivedPartsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      archivedPartsGridActions: bindActionCreators(archivedPartsGridActions, dispatch),
      activateAssetsDialogActions:  bindActionCreators(activateAssetsDialogActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
    }
  }
}

let ArchivedPartsPageContainer = connect(
  (state) => {
    let sGrid = state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_PARTS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_PARTS) : null;
    return {
      sGrid:  sGrid,
      sHasRows: sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData: sGrid ? sGrid.get('selectedRowsData') : List(),
      sActivateAssetsDialogData: state.dialogs.get('activateAssetsDialog'),
      sMoveAssetsData:     state.dialogs.get('moveAssetsDialog'),
    };
  },
  mapDispatchToProps
)(ArchivedPartsPage);


export default ArchivedPartsPageContainer;

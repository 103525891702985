import React from 'react';
import PropTypes from 'prop-types';

import AssetAlertPopover from '../../../../Layout/AssetAlertPopover/assetAlertPopover.js';
import Link from '../../../../Layout/Link/link';
import * as AssetHelper from '../../../../../../utils/assetHelper';

require('./relatedAssetCell.scss');

export default class RelatedAssetCell extends React.PureComponent {

  render() {
    let asset = this.props.data;

    if (!asset) {
      return null;
    }

    let type = AssetHelper.getNavigationStateByEntityType(asset.objectType)
    let active = "active"; // asset.active ? "active" : "inactive";

    return (<div className={'related-asset-cell asset ' + active}>
      <AssetAlertPopover value={asset}/>
      {AssetHelper.getAssetIconComponent(asset.objectType)}
      <Link label={asset.businessId} id={asset.id} type={type}/>
    </div>);
  }
}

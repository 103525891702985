import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Popover from 'infrastructure/js/components/popover/Popover/popover';
import classNames from 'classnames';

require('./customOverlay.scss');

export default class PL_CustomOverlay extends React.PureComponent{

  render (){
    return (<Popover
        className={classNames('custom-overlay', this.props.className)}
        trigger="click"
        placement="bottom"
        popoverId={this.props.id}
        popoverTitle={this.props.title || ""}
        popoverComponent={this.props.body}
      >
        {this.props.children}
      </Popover>
    )
  }
}
PL_CustomOverlay.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.object,
};

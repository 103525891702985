import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import {api as gridApi} from "../preferenceGridViewActions";
import {api as zplGridApi} from "../../RfidSettingsPage/zplGridViewActions";
import * as preferenceService from "../../../../services/Preferences/prefrencesService";
import {api as loginActions} from '../../../Login/loginActions';
import {api as navigatorActionsApi} from "../../../AppTopbar/navigatorActions";
import {api as sideNavigatorActionsApi} from "../../../SideNavigator/sideNavigatorActions";
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  EDIT_PREFERENCE_DIALOG_SHOW:               'EDIT_PREFERENCE_DIALOG_SHOW',
  EDIT_PREFERENCE_DIALOG_HIDE:               'EDIT_PREFERENCE_DIALOG_HIDE',
  EDIT_PREFERENCE_DIALOG_SUBMIT_IN_PROGRESS: 'EDIT_PREFERENCE_DIALOG_SUBMIT_IN_PROGRESS',
  EDIT_PREFERENCE_DIALOG_SUBMIT_FINISHED:    'EDIT_PREFERENCE_DIALOG_SUBMIT_FINISHED',
  EDIT_PREFERENCE_DIALOG_FETCH_DATA_READY:   'EDIT_PREFERENCE_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.EDIT_PREFERENCE_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.EDIT_PREFERENCE_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.EDIT_PREFERENCE_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.EDIT_PREFERENCE_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function(payload) {
    return {type: actionTypes.EDIT_PREFERENCE_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, isEditMode, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    dispatch(actions.show(data));
  }
};

api.submit = function(dispatch, getState) {
  return function(data, reloadParentComponent, isRfid) {
    dispatch(actions.submitInProgress());

    let method = isRfid ? preferenceService.updateZplPreferences : preferenceService.updatePreferences;

    data.orgId = getState().login.get('loggedInUser').loggedInOrg.id
    method(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Edit preference failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      isRfid ? zplGridApi.reload(dispatch, getState)() :gridApi.reload(dispatch, getState)();

      loginActions.updateLoggedInUserOrgPreferences()(dispatch, getState).then((response) => {
          navigatorActionsApi.fetchLocations(dispatch, getState)();
          navigatorActionsApi.fetchHomePage(dispatch, getState)();
          sideNavigatorActionsApi.init(dispatch, getState)();
      });
    });
  };
};


import React, { Component } from 'react';
import TwoDMappingItem from "./TwoDMappingItem/twoDMappingItem.js";
import ScheduleAction from '../../../modules/scheduleAction.js';
import Overlay from "infrastructure/js/components/Overlay/overlay";
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./twoDMappingPage.scss');

export default class TwoDMappingPage extends Component {

  componentDidMount() {
    this.props.actions.init(true);
    let refreshRate = PermissionManager.getOrgPreferences().refreshRate;
    if (refreshRate && refreshRate > 0) {
      this.timerRefresh = ScheduleAction("TwoDMapping Page Timer Refresh", this.props.actions.onTimerRefresh, refreshRate);
    }
    //currently - wo count & alerts
    let extraDataRefreshRate = PermissionManager.getOrgPreferences().refreshRateSecondary;
    if (extraDataRefreshRate && extraDataRefreshRate > 0) {
      this.extraDataTimerRefresh = ScheduleAction("TwoDMapping Page Extra Data Timer Refresh", this.props.actions.onExtraDataTimerRefresh, extraDataRefreshRate);
    }
  }

  componentWillUnmount() {
    if(this.timerRefresh) {
      this.timerRefresh.kill();
    }
    if(this.extraDataTimerRefresh) {
      this.extraDataTimerRefresh.kill();
    }
    this.props.actions.unmount();
  }

  getLoadingOverlay() {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }

  render() {
    let items = this.props.sData.get("items");
    if(!items){
      return null;
    }
    return (
      <div className="twoDMapping-page">
        {this.getLoadingOverlay()}
        {items.map((item, index) => {
          return <TwoDMappingItem key={'dashborad-item' + index}
                                  item={item}
                                  wos={this.props.sData.get("wos")}
                                  alerts={this.props.sData.get("alerts")}
                                  alertsLoading={this.props.sData.get("alertsLoading")}
                                  woLoading={this.props.sData.get("woLoading")}/>;
        })}
      </div>
    )
  }
};












import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import {navigationStates} from '../../enums/navigationStates';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';


export let jsxActions = {};

jsxActions.init = function() {
  return function(dispatch, getState) {
    api.init(dispatch, getState)();
  }
};


export let api = {};

api.init = function(dispatch, getState) {
  return function(isNewLoad) {
    navigatorApi.setLoading(isNewLoad)(dispatch, getState);

    let topNavigatorData = {
      name: createLabelHelper('mat.pagenotfoundpage.').get('header.pagenotfound'),
      type: navigationStates.PAGE_NOT_FOUND,
      id: 0,
      components: []
    };
    navigatorApi.setLoading(false)(dispatch, getState);
    navigatorApi.setData(dispatch, getState)(topNavigatorData);
  }
};

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import TimeField from '../../controls/TimeField/timeField';

const TimeEditorCell = forwardRef(({value: initialValue, rowIndex, change}, ref) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        change(`timeLeft_${rowIndex}_hours`, initialValue?.hours);
        change(`timeLeft_${rowIndex}_minutes`, initialValue?.minutes);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
        };
    });

    const onChangeHours = (hours) => {
        setValue({
            ...value,
            hours,
        });
    };

    const onChangeMinutes = (minutes) => {
        setValue({
            ...value,
            minutes,
        });
    };

    return (
        <div>
            <TimeField
                id={`timeLeft_${rowIndex}`}
                name={`timeLeft_${rowIndex}`}
                hoursCount={true}
                maxHoursLength={2}
                onChangeCallbackHours={onChangeHours}
                onChangeCallbackMinutes={onChangeMinutes}
            />
        </div>
    );
});

export default TimeEditorCell;

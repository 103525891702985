import PropTypes from 'prop-types';

import './humanCapacityChip.scss';

const defaultColor = '#646464';

export default function HumanCapacityChip({ color, humanCapacity, shiftHumanCapacity }) {
  return (
    <div className="human-capacity-chip" style={{ backgroundColor: color || defaultColor }}>
      <span>{`${humanCapacity}/${shiftHumanCapacity}`}</span>
      <span className="pl pl-user-outline"></span>
    </div>
  );
}

HumanCapacityChip.defaultProps = {
  color: defaultColor,
  humanCapacity: '',
  shiftHumanCapacity: '',
};

HumanCapacityChip.propTypes = {
  color: PropTypes.string,
  humanCapacity: PropTypes.number,
  shiftHumanCapacity: PropTypes.number,
}
import React from 'react';
import PropTypes from 'prop-types';

import EntitiesMultiSelect from '../../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelectWithChildren.js';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import {round} from '../../../../../../utils/assetHelper';
import {DefaultWorkOrderTypes} from '../../../../../../utils/workOrderHelper';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {generateKitId} from '../../../../../../services/Kits/kitsService';
import Button from 'infrastructure/js/components/controls/Button/button';
require('./assetsToAssetsWithConsumptionSection.scss');


export default class AssetsToAssetsWithConsumptionSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;

    this.hasPreSelectedWo = !!this.props.workOrder;

    this.state = {
      assetWeight:0,
      assetLengthLeft: 0,
      toKey: 0,
      addKey: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.workOrder != this.props.workOrder) {
      this.props.change(this.getReduxFieldName('add-asset-selection') + '.assetsValue', null);
      this.props.change(this.getReduxFieldName('to-asset-selection') + '.assetsValue', null);
      this.props.change(this.getReduxFieldName('asset-weight'), '');
      this.props.change(this.getReduxFieldName('asset-length'), '');
      this.setState({
        toKey: this.state.toKey === 0 ? 1 : 0,
        addKey: this.state.addKey === 0 ? 1 : 0,
      });
    }
  }

  getReduxFieldName = (fieldName) => {
    return this.props.name + '.' + fieldName;
  };

  onAddValueChangeCallback = (newValue, oldValue) => {
    //prevent clearing the 'length' field when there was no change
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    let assetLengthLeft = UnitHelper.serverValueToUserValue(unitTypes.LENGTH, newValue?.data?.lengthLeft, 2) || 0;
    let assetWeight = UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, newValue?.data?.weight, 2) || 0;

    this.setState({ assetWeight, assetLengthLeft });

    this.props.change(this.getReduxFieldName('asset-length'), '');
    this.props.change(this.getReduxFieldName('asset-weight'), '');
  };

  getNewOptionDataHandler = (inputValue, optionLabel) => {
    let {workOrder} = this.props;
    return {
      label: optionLabel,
      value: inputValue,
      __isNew__: true,
      data: {
        objectType: getEnumValue(enumTypes.OBJECT_TYPE)('KIT'),
        businessId: inputValue,
        kitType: {
          businessId: (workOrder && workOrder.kitType) ? workOrder.kitType.businessId : ''
        }
      }
    };
  };

  getLabelExtraComponent = (value) => {
    return (
      <span className="label-extra-component">{value}</span>
    )
  }

  renderLengthOrWeight = () => {
    let maxQuantity =  PermissionManager.isWeightSupported() ? this.state.assetWeight : this.state.assetLengthLeft;
    if(PermissionManager.isWeightSupported()){
      return (<InputSection
                            label={this.labels.get('weight')}
                            labelExtraComponent={this.getLabelExtraComponent(`(${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)})`) }
                            htmlFor={this.getReduxFieldName('asset-weight')} className="asset-length-section">
        <TextField id={this.getReduxFieldName('asset-weight')}
                   name={this.getReduxFieldName('asset-weight')}
                   normalize={Normalize.number(false, 0, maxQuantity)}
        />
      </InputSection>)
    }

    return (<InputSection
                          label={this.labels.get('length')}
                          labelExtraComponent={this.getLabelExtraComponent(`(${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`) }
                          htmlFor={this.getReduxFieldName('asset-length')} className="asset-length-section">
      <TextField id={this.getReduxFieldName('asset-length')}
                 name={this.getReduxFieldName('asset-length')}
                 normalize={Normalize.number(false, 0, maxQuantity)}
      />
    </InputSection>)
  }

  generateIdResponseHandler = (response) => {
    const newOption = {
      "value": response.data.data,
      "label": response.data.data,
      "data": {
        "id": response.data.data,
        objectType: getEnumValue(enumTypes.OBJECT_TYPE)('KIT')
      }
    }
    this.props.change(this.getReduxFieldName('to-asset-selection') + '.assetsValue', newOption);
  }

  generateId = () => generateKitId().then(this.generateIdResponseHandler);

  render = () => {
    let { workOrder, fetchConfig, itemIndex, ...otherProps} = this.props;
    let allowNewOption = (workOrder && workOrder.workOrderType === DefaultWorkOrderTypes.KITTYPE_ORIENTED && !!workOrder.kitType);
    let focusProps = (itemIndex === 0 && this.hasPreSelectedWo) ? {autoFocus: true} : {};

    return <div className="assets-to-asset-section-consumption">
      <div className="add-to-asset-container">

        <div className="add-to-asset-section add-section">
          <label className="add-to-asset-label">{this.labels.get("add")}</label>

          <EntitiesMultiSelect
            { ...otherProps }
            key={this.state.addKey}
            id={this.getReduxFieldName('add-asset-selection')}
            name={this.getReduxFieldName('add-asset-selection')}
            className="assets-to-asset-selection"
            entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')]}
            validate={Validation.required}
            onValueChangeCallback={this.onAddValueChangeCallback}
            fetchConfig={fetchConfig}
            isMulti={false}
            {...focusProps}
          />
          {this.renderLengthOrWeight()}

        </div>

        <label className="add-to-asset-arrow">
          <i className="pl pl-move-arrow-right"/>
        </label>

        <div className="add-to-asset-section to-section">
          <label className="add-to-asset-label">{this.labels.get("to")}</label>
            <EntitiesMultiSelect
            {...otherProps}
            key={this.state.toKey}
            id={this.getReduxFieldName('to-asset-selection')}
            name={this.getReduxFieldName('to-asset-selection')}
            className="assets-to-asset-selection"
            entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('KIT')]}
            validate={Validation.required}
            fetchConfig={fetchConfig}
            isMulti={false}
            allowNewOption={allowNewOption}
            getNewOptionData={this.getNewOptionDataHandler}
          >
            {
              allowNewOption && <div className='input-with-generate-button'>
                <Button id={'-button'} bsStyle="default" onClick={this.generateId} className={' generateButton border-btn btn btn-default'}>Generate</Button>
              </div>
            }
          </EntitiesMultiSelect>
        </div>

      </div>

    </div>
  };

}

AssetsToAssetsWithConsumptionSection.propTypes = {
  workOrder : PropTypes.object
};





import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

require('./toolMoreInfoCell.scss');

export default class ToolMoreInfoCell extends React.PureComponent {
  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.cells.moreInfo.');
  }

  getCycleText = (cyclesCount) => {
    if (cyclesCount === null) {
      return null;
    }
    return <span>{this.labels.get('cycles',undefined,{count:cyclesCount})}</span>;
  };
  getUtlText = (utl) => {
    if (utl === null) {
      return null;
    }
    return <span>{`${utl}`}</span>;
  };

  render() {
    let data = this.props.value;
    if (!data) {
      return '';
    }
    return (<div className="tool-more-info-cell">
      <label>{this.labels.get('cyclesLabel')} {this.getCycleText(this.props.value.cyclesCount)} </label>
      <label>{this.labels.get('utl')} {this.getUtlText(this.props.value.utl)}</label>
    </div>);
  }
}

ToolMoreInfoCell.propTypes = {
  value : PropTypes.object
};





import * as schedulerService from '../../../services/Scheduler/schedulerService';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions';
import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import { schedulingPreferencesKeys } from '../../../enums/schedulingPreferencesKeys';
import { api as loginActions } from '../../Login/loginActions';
import { setSystemColors } from '../../../../../infrastructure/js/utils/colorHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_IN_PROGRESS: 'ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_IN_PROGRESS',
  ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_FINISHED: 'ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_FINISHED',
  ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_FAILED: 'ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_FAILED',
  ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_FETCH_DATA_IN_PROGRESS:
    'ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_FETCH_DATA_IN_PROGRESS',
  ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_FETCH_DATA_FINISHED: 'ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_FETCH_DATA_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  submitInProgress: function (payload) {
    return { type: actionTypes.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function (payload) {
    return { type: actionTypes.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_FINISHED, payload: payload };
  },
  submitFailed: function (payload) {
    return { type: actionTypes.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_FAILED, payload: payload };
  },
  fetchDataInProgress: function (payload) {
    return { type: actionTypes.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_FETCH_DATA_IN_PROGRESS, payload: payload };
  },
  fetchDataFinished: function (payload) {
    return { type: actionTypes.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_FETCH_DATA_FINISHED, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    return api.fetchData(dispatch, getState)();
  };
};

jsxActions.submit = function (data, messageDialogBuilder) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, messageDialogBuilder);
  };
};

jsxActions.resetColor = function (type) {
  return function (dispatch, getState) {
    return api.resetColor(dispatch, getState)(type);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchData = function (dispatch, getState) {
  return function () {
    dispatch(actions.fetchDataInProgress());

    const fetchGoalsPromise = schedulerService.fetchSchedulingGoals();
    const fetchPreferencesPromise = schedulerService.fetchSchedulingPreferences({
      dataCategory: 'SCHEDULER',
    });
    const fetchRulesPromise = schedulerService.fetchSchedulingRuleByName({
      name: 'DueDateValidationRule',
    });

    return Promise.all([fetchGoalsPromise, fetchPreferencesPromise, fetchRulesPromise])
      .then((allResults) => {
        const invalidResponses = allResults.filter((response) => {
          return !Network.isResponseValid(response);
        });

        if (invalidResponses?.length > 0) {
          console.error('fetch scheduling preferences failed.');
          messageDialogApi.responseError(dispatch, getState)(invalidResponses[0]);
          dispatch(actions.fetchDataFinished());
          return;
        }

        const [Goals, Preferences, Rules] = allResults;

        const schedulingPreferences = Preferences?.data?.schedulingPreferences;

        const data = {
          goals: Goals?.dataList,
          reschedulingThreshold: schedulingPreferences[schedulingPreferencesKeys.RESCHEDULING_THRESHOLD],
          startOfDay: schedulingPreferences[schedulingPreferencesKeys.START_OF_DAY],
          startDayOfWeek: _convertStartDayOfWeek(schedulingPreferences[schedulingPreferencesKeys.START_DAY_OF_WEEK]),
          weekHorizonStart: schedulingPreferences[schedulingPreferencesKeys.WEEK_HORIZON_START],
          humanCapacityEnabled: _convertHumanCapacity(schedulingPreferences[schedulingPreferencesKeys.HUMAN_CAPACITY_ENABLED]),
          minimumToolCapacity: schedulingPreferences[schedulingPreferencesKeys.MINIMUM_TOOL_CAPACITY],
          maximumToolCapacity: schedulingPreferences[schedulingPreferencesKeys.MAXIMUM_TOOL_CAPACITY],
          enablePastDueDate: Rules?.data,
          colors: {
            started: schedulingPreferences[schedulingPreferencesKeys.STARTED_COLOR],
            completed: schedulingPreferences[schedulingPreferencesKeys.COMPLETED_COLOR],
            scheduled: schedulingPreferences[schedulingPreferencesKeys.SCHEDULED_COLOR],
            maintenance: schedulingPreferences[schedulingPreferencesKeys.MAINTENANCE_COLOR],
          },
        };

        dispatch(actions.fetchDataFinished(data));

        return data;
      })
      .catch(() => {
        dispatch(actions.fetchDataFinished());
        console.error('fetch scheduling preferences failed.');
      });
  };
};

api.submit = function (dispatch, getState) {
  return async function (data) {
    dispatch(actions.submitInProgress());

    try {
      const setPreferencesResponse = await schedulerService.setSchedulingPreferences(data?.preferences);
      const setGoalsResponse = await schedulerService.setSchedulingGoals(data?.goals);
      const setRuleResponse = await schedulerService.setSchedulingRule(data?.rule);

      const invalidResponses = [setPreferencesResponse, setGoalsResponse, setRuleResponse].some((response) => {
        return !Network.isResponseValid(response);
      });

      if (invalidResponses) {
        console.error('submit scheduling preferences failed.');
        messageDialogApi.open(dispatch, getState)(_getMessageDialogBuilder([setGoalsResponse, setPreferencesResponse, setRuleResponse]));
      } else {
        messageDialogApi.showSuccess(dispatch, getState)('');
        loginActions.updateLoggedInUserOrgPreferences()(dispatch, getState);
      }
    } catch (e) {
      console.error('submit scheduling preferences failed.');
    }

    dispatch(actions.submitFinished());
    return api.fetchData(dispatch, getState)();
  };
};

api.resetColor = function (dispatch, getState) {
  return async function (type) {
    dispatch(actions.submitInProgress());
    try {
      const response = await schedulerService.resetColor(type);
      if (Network.isResponseValid(response)) {
        setSystemColors({ [type]: response.data.propertyValue });
      } else {
        console.error('reset color failed', response);
      }
    } catch (ex) {
      console.error('reset color failed', ex);
    }

    dispatch(actions.submitFinished());
    return api.fetchData(dispatch, getState)();
  };
};

////////////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
function _getMessageDialogBuilder(responses) {
  let dialogLabels = createLabelHelper('mat.administration.settings.schedulingPreferences.view.alert.');

  const messages = [dialogLabels.get('error.goals'), dialogLabels.get('error.preferences'), dialogLabels.get('error.rules')];

  const children = responses.reduce((accumulatedChildren, currentResponse, i) => {
    if (!Network.isResponseValid(currentResponse)) {
      accumulatedChildren.push(<MessageDialog.MessageRow key={i} text={messages[i]} />);
    }
    return accumulatedChildren;
  }, []);

  return { title: dialogLabels.get('error'), type: 'error', className: 'oneBackground', children };
}

function _convertStartDayOfWeek(data) {
  if (data) {
    return {
      ...data,
      label: data?.value,
    };
  }
}

function _convertHumanCapacity(data) {
  if (data) {
    return {
      ...data,
      value: data?.value === 'true',
    };
  }
}

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as userManagementPageActions} from '../../../actions/Administration/UserManagementPage/userManagementPageActions';
import {jsxActions as userManagementGridActions} from '../../../actions/Administration/UserManagementPage/userManagementGridActions';
import {jsxActions as headerActions} from '../../../actions/Header/headerActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';

import {gridsNames} from '../../../enums/gridsNames';

import UserManagementPage from '../../../components/Administration/UserManagementPage/userManagementPage';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(userManagementPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      userManagementGridActions: bindActionCreators(userManagementGridActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
    }
  }
}

let UserManagementPageContainer = connect(
  (state) => {
    let userManagementGrid = state.grid.get(gridsNames.ADMINISTRATION_USER_MANAGEMENT) ? state.grid.get(gridsNames.ADMINISTRATION_USER_MANAGEMENT) : null;
    return {
      sUserManagementGrid: userManagementGrid,
      sHasRows: userManagementGrid && userManagementGrid.get('rows') ? userManagementGrid.get('rows').size > 0 : false,
      sSelectedRowsData: userManagementGrid ? userManagementGrid.get('selectedRowsData') : List()
    };
  },
  mapDispatchToProps
)(UserManagementPage);

export default UserManagementPageContainer;





import React from 'react';
import PropTypes from 'prop-types';


import EditInPlace from '../../controls/EditInPlace/editInPlace';
import {PL_TextField} from '../../controls/TextField/textField';

export default class TextFieldEditor extends React.PureComponent {


  render() {
    let {name, id, value, displayValue, handleSubmit, validate, editable} = this.props;
    displayValue = (displayValue === undefined) ? value : displayValue;
    return (
      <EditInPlace
        id={id}
        name={name}
        formName={`form_EditInPlace_${name}`}
        serverValue={value}
        serverDisplayValue={displayValue}
        sendData={handleSubmit}
        validate={validate}
        editable={editable}
      >
        <PL_TextField id={`${id}-input`} />
      </EditInPlace>
    );
  }
}
TextFieldEditor.defaultProps = {
  value: ''
};
TextFieldEditor.propTypes = {
  id : PropTypes.string.isRequired,
  name : PropTypes.string.isRequired,
  value : PropTypes.string,
};


import Network from 'infrastructure/js/modules/network';
import * as twoDMappingService from '../../services/Reports/twoDMappingService.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import { api as systemApi } from '../System/systemActions';
import {navigationStates} from '../../enums/navigationStates';
import * as locationsService from '../../services/Locations/locationsService';
import { api as navigationApi } from '../AppTopbar/navigatorActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  TWODMAPPINGPAGE_FETCHDATA_STARTED: "TWODMAPPINGPAGE_FETCHDATA_STARTED",
  TWODMAPPINGPAGE_FETCHDATA_FINISHED: "TWODMAPPINGPAGE_FETCHDATA_FINISHED",
  TWODMAPPINGPAGE_UNMOUNT: "TWODMAPPINGPAGE_UNMOUNT",
  TWODMAPPINGPAGE_FETCH_WO_COUNT_STARTED: "TWODMAPPINGPAGE_FETCH_WO_COUNT_STARTED",
  TWODMAPPINGPAGE_FETCH_WO_COUNT_FINISHED: "TWODMAPPINGPAGE_FETCH_WO_COUNT_FINISHED",
  TWODMAPPINGPAGE_FETCH_ALERTS_STARTED: "TWODMAPPINGPAGE_FETCH_ALERTS_STARTED",
  TWODMAPPINGPAGE_FETCH_ALERTS_FINISHED: "TWODMAPPINGPAGE_FETCH_ALERTS_FINISHED"

};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function(loadFirstTime) {
    return {type: actionTypes.TWODMAPPINGPAGE_FETCHDATA_STARTED, loadFirstTime};
  },
  fetchDataFinished: function(items) {
    return {type: actionTypes.TWODMAPPINGPAGE_FETCHDATA_FINISHED, items};
  },
  unmount: function() {
    return {type: actionTypes.TWODMAPPINGPAGE_UNMOUNT};
  },
  fetchWoCountStarted: function(loadFirstTime) {
    return {type: actionTypes.TWODMAPPINGPAGE_FETCH_WO_COUNT_STARTED, loadFirstTime};
  },
  fetchWoCountFinished: function(items) {
    return {type: actionTypes.TWODMAPPINGPAGE_FETCH_WO_COUNT_FINISHED, items};
  },
  fetchAlertsStarted: function(loadFirstTime) {
    return {type: actionTypes.TWODMAPPINGPAGE_FETCH_ALERTS_STARTED, loadFirstTime};
  },
  fetchAlertsFinished: function(items) {
    return {type: actionTypes.TWODMAPPINGPAGE_FETCH_ALERTS_FINISHED, items};
  },


};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(loadFirstTime = false) {
  return function(dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.TWOD_MAPPING);
    api.init(dispatch, getState)(loadFirstTime);
  }
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};

// Called by this component's ScheduleAction exclusively.
jsxActions.onTimerRefresh = function() {
  return function(dispatch, getState) {
    api.loadData(dispatch, getState)(false);
  }
};

// Called by this component's ScheduleAction exclusively.
jsxActions.onExtraDataTimerRefresh = function() {
  return function(dispatch, getState) {
    api.fetchExtraData(dispatch, getState)(false);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function (dispatch, getState) {
  return function (loadFirstTime) {
    api.loadData(dispatch, getState)(loadFirstTime);
  }
};

api.loadData = function(dispatch, getState) {
  return function(loadFirstTime) {
    navigationApi.setLoading(loadFirstTime)(dispatch, getState);

    api.fetchExtraData(dispatch, getState)(loadFirstTime);

    Promise.all([
      api.fetchLocationsCount(dispatch, getState)(),
      api.fetchData(dispatch, getState)(loadFirstTime)
    ]).then(()=>{
      navigationApi.setLoading(false)(dispatch, getState);
    });
  }
};

api.fetchExtraData = function (dispatch, getState) {
  return function (loadFirstTime) {
    api.fetchWoCount(dispatch, getState)(loadFirstTime);
    api.fetchAlerts(dispatch, getState)(loadFirstTime);
  }
};

api.fetchWoCount = function (dispatch, getState) {
  return function (loadFirstTime) {
    // loadFirstTime - to prevent this page scheduled task to display loading overlay.
    dispatch(actions.fetchWoCountStarted(loadFirstTime));
    return twoDMappingService.fetchWoCount()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error("Fetch TwoDMapping wo count Failed");
        }
        let items = response.dataList;
        dispatch(actions.fetchWoCountFinished(items));
      });
  }
};

api.fetchAlerts = function (dispatch, getState) {
  return function (loadFirstTime) {
    // loadFirstTime - to prevent this page scheduled task to display loading overlay.
    dispatch(actions.fetchAlertsStarted(loadFirstTime));
    return twoDMappingService.fetchAlerts()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error("Fetch TwoDMapping alerts Failed");
        }
        let items = response.dataList;
        dispatch(actions.fetchAlertsFinished(items));
      });
  }
};


api.fetchData = function(dispatch, getState) {
  return function(loadFirstTime) {

    // loadFirstTime - to prevent this page scheduled task to display loading overlay.
    dispatch(actions.fetchDataStarted(loadFirstTime));

    let method = _isHideInactiveLocations(getState) ? twoDMappingService.fetchActiveData : twoDMappingService.fetchAllData;
    return method()
      .then((response) => {

        if (!Network.isResponseValid(response)) {
          console.error("Fetch TwoDMapping Data Failed");
          return {success: false};
        }

        let labels = createLabelHelper('mat.reports.twodmapping.');

        let serverTimeSeconds = getState().system.get("serverDatetime");

        let items = response.data.locationItems.map((item, index) => {
          item.m_ActivityTime = DateTimeHelper.FormatDateObjectToRelativeTime(item.activityTime, serverTimeSeconds);
          item.locationName = item.active ? item.locationName : item.locationName + ' (' + labels.get('inactive') + ')';
          return item;
        });
        dispatch(actions.fetchDataFinished(items));
      });
  }
};

api.fetchLocationsCount = function(dispatch, getState) {
  return function() {
    return locationsService.fetchLocationCount()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error("Fetch Location count Failed");
          return;
        }

        let topNavigatorData = {
          name: createLabelHelper('mat.reports.twodmapping.').get('title'),
          type: navigationStates.TWOD_MAPPING,
          id: -1,
          components: [ {
            name: "mat.reports.twodmapping.counters.locations",
            count: response.data.data,
            alertCount: 0
          }]
        };

        navigatorApi.setData(dispatch, getState)(topNavigatorData);
      });
  }
};

function _isHideInactiveLocations (getState) {
  let loggedInUser = getState().login.get('loggedInUser');
  return loggedInUser ? PermissionManager.getOrgPreferences().hideInactiveLocations : false;
}

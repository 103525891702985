import React, {Component} from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import classNames from 'classnames';
import ScanHeader from '../Common/ScanHeader/scanHeader';
import ScanBottom from '../Common/ScanBottom/scanBottom';
import ScannedAssetListItem from '../ScannedAssetListItem/scannedAssetListItem';

require('./scannedAssetsListPage.scss');

export default class ScannedAssetsListPage extends React.PureComponent {

  constructor(props) {
    super(props);

    let scannedAssets = this.props.sData.get('scannedAssets');
    if (!scannedAssets || scannedAssets.size === 0) {
      this.props.history.push('/ScanAsset');
    }

    this.labels = createLabelHelper('mat.mobile.scan.');
  }

  onBottomClick = () => {
    let path = '/ChangeLocation';
    this.props.history.push(path);
  };

  getHeaderItems() {

    let scannedAssets = this.props.sData.get('scannedAssets');
    if (!scannedAssets) {
      return {};
    }

    return {
      leftButton: {
        id: 'goBackBtn',
        icon: 'pl pl-arrow-left',
        action: this.props.actions.header.onGoBack,
        actionData: this.props.history,
      },
      rightButton: {
        id: 'newScanBtn',
        icon: 'pl pl-icon-add',
        action: this.props.actions.header.onNewScan,
        actionData: this.props.history,
      },
      middleButton: {
        id: 'goBackMiddleBtn',
        label: this.labels.get('header.buttons.button.scanned', undefined, {assetsCount: scannedAssets.size}),
        action: this.props.actions.header.onGoBack,
        actionData: this.props.history,
      }
    };
  };

  getBottomItems() {
    let scannedAssets = this.props.sData.get('scannedAssets');
    if (!scannedAssets || scannedAssets.size < 1) {
      return {};
    }

    return {
      button: {
        id: 'changeLocationBtn',
        action: this.onBottomClick,
        label: this.labels.get('bottom.button.changeLocation', undefined, {count: scannedAssets.size}),
      },
    };
  };

  removeButtonClickHandler = (index) => {
    let payload = {data: this.props.history, index}
    this.props.actions.header.onRemoveScanAt(payload);
  };

  render() {

    let {sData} = this.props;
    if (!sData) {
      return null;
    }

    let assets = sData.get('scannedAssets');

    let assetsList = assets.map((asset, index) => {
      return (
        <div className="list-item" key={asset.id}>
          <span className="pl pl-x-input-close remove-btn" onClick={() => this.removeButtonClickHandler(index )}/>
          <ScannedAssetListItem asset={asset} />
        </div>
      );
    });

    let headerItems = this.getHeaderItems();
    let bottomItems = this.getBottomItems();

    return (
      <div className="scanned-assets-list-page">
        <ScanHeader {...headerItems}/>
        <div className="scanned-assets-list">
          {assetsList}
        </div>
        <ScanBottom {...bottomItems}/>

      </div>
    )
  }
}


ScannedAssetsListPage.propTypes = {
};

ScannedAssetsListPage.defaultProps = {
};


import { Map } from 'immutable';
import { actionTypes as CreateReaderDialogActionsType } from '../../../actions/Administration/RfidSettingsPage/Dialogs/createReaderDialogActions';

let defaultState = {
  show: false,
  loading: false,
  itemToEdit: null,
  dialogData: null,
  dialogMessageData : null
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreateReaderDialogActionsType.CREATE_READER_DIALOG_SHOW:
      return state.set('itemToEdit', action.payload)
        .set('dialogMessageData' , null)
        .set('show',true);

    case CreateReaderDialogActionsType.CREATE_READER_DIALOG_HIDE:
      return Map(defaultState);

    case CreateReaderDialogActionsType.CREATE_READER_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set('loading', true)
        .set('dialogMessageData' , null);

    case CreateReaderDialogActionsType.CREATE_READER_DIALOG_SUBMIT_FINISHED:
      return state.set('loading', false);

    case CreateReaderDialogActionsType.CREATE_READER_DIALOG_FETCH_DATA_READY:
      return state.set('dialogData', action.payload);

    case CreateReaderDialogActionsType.SUBMIT_VALIDATION_FAILED:
      return state.set('dialogMessageData' , action.payload)
        .set('loading', false);

    default:
      return state;
  }

}








import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as inventoryDashboardPageActions }    from '../../../actions/Reports/inventoryDashboardPageActions.js';
import InventoryDashboardPage from '../../../components/Reports/Dashboard/InventoryDashboardPage/inventoryDashboardPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(inventoryDashboardPageActions, dispatch),
    }
  }
}

let InventoryDashboardPageContainer = connect(

  (state) => {

  	return {
      sData: state.inventoryDashboardPage,
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps
)(InventoryDashboardPage);


export default InventoryDashboardPageContainer;




import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as workOrderPageEditorActions } from '../../actions/WorkOrderPage/workOrderPageEditorActions';

import OverviewTab from '../../components/WorkOrderPage/OverviewTab/overviewTab.js';
import {getDefaultWorkOrderType}  from '../../utils/workOrderHelper';
import PermissionManager from "infrastructure/js/utils/permissionManager";


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      workOrderPageEditorActions: bindActionCreators(workOrderPageEditorActions, dispatch)
    }
  }
}

let OverviewTabContainer = connect(
  (state) => {
    return {
      workOrderDetails: state.workOrderPage.getIn(['overviewTab', 'workOrderDetails']),
      sWorkOrderPageEditorData:     state.workOrderPage.getIn(['data', 'workOrderPageEditorData']),
      loading: state.workOrderPage.getIn(['overviewTab', 'loading']),
      sDefaultWorkOrderType: getDefaultWorkOrderType(PermissionManager.getOrgPreferences()),
      sLoggedInUser : state.login.get('loggedInUser'),

    };
  },
  mapDispatchToProps
)(OverviewTab);

export default OverviewTabContainer;





import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import hpi from 'infrastructure/assets/svg/high-priority-task-icon.svg?url';
import spi from 'infrastructure/assets/svg/split-task-icon.svg?url';
import Popover from 'infrastructure/js/components/popover/Popover/popover';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import './ColorPickerTile.scss';

const ColorPickerTile = ({ tileName, color, onChange, styleGenerator, originalSystemColor, input, onReset }) => {
  const [currentColor, setCurrentColor] = useState('#fff');
  const originalColor = useRef(color);
  const colorPickerElement = useRef();

  const { resetLabel, selectLabel } = useMemo(() => {
    const labelsObj = createLabelHelper('mat.colorPickerTile.');
    return { resetLabel: labelsObj.get('reset'), selectLabel: labelsObj.get('select') };
  }, []);

  useEffect(() => {
    const { value } = input;
    if (value) {
      updateColorPicker(value);
      return setCurrentColor(value);
    }

    if (color) {
      updateColorPicker(color);
      return setCurrentColor(color);
    }
  }, [color, input]);

  const handleColorPickerChange = ({ target: { value } }) => {
    setCurrentColor(value);
    input.onChange(value); //redux form connection
    onChange(value);
  };

  const handleResetClick = () => {
    if (onReset() === 'local') {
      const newValue = originalSystemColor ? originalSystemColor : originalColor.current;
      setCurrentColor(newValue);
      input.onChange(newValue); //redux form connection
      updateColorPicker(newValue);
    }
  };

  const updateColorPicker = (color) => {
    if (colorPickerElement?.current) colorPickerElement.current.value = color;
  };

  return (
    <div className="color-picker-tile-editor">
      <div className="color-picker-tile" style={styleGenerator(currentColor)}>
        {tileName}
        <div className="color-picker-tile-icons">
          <img src={spi} alt="split task icon" />
          <span className="fa fa-exclamation-triangle"></span>
          <img src={hpi} alt="high priority task icon" />
        </div>
      </div>
      <div className="color-picker-tile-actions">
        <Popover className="pl-popover" popoverTitle={selectLabel}>
          <label className="color-picker-tile-actions-edit fa fa-pencil-square-o pointer" htmlFor={`${tileName}-colorPicker`}></label>
        </Popover>
        <Popover className="pl-popover" popoverTitle={resetLabel}>
          <span className="fa fa-refresh pointer" onClick={handleResetClick}></span>
        </Popover>
        <input
          className="color-picker-tile-picker"
          ref={colorPickerElement}
          id={`${tileName}-colorPicker`}
          type="color"
          onChange={handleColorPickerChange}
        />
      </div>
    </div>
  );
};

ColorPickerTile.propTypes = {
  tileName: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  styleGenerator: PropTypes.func,
  originalSystemColor: PropTypes.string,
  input: PropTypes.object,
};

ColorPickerTile.defaultProps = {
  tileName: '',
  color: '#fff',
  onChange: () => false,
  styleGenerator: (color) => ({ background: color }),
  originalSystemColor: '',
  input: { onChange: () => false },
  onReset: () => 'local',
};

export default ColorPickerTile;

import React from 'react';
import PropTypes from 'prop-types';

import OrganizationsGrid from './OrganizationsGrid/organizationsGrid';
import Header, { headerItemsLabels } from '../../../Common/Header/header';

require('./organizationsTab.scss');

export default class OrganizationsTab extends React.PureComponent {
  getHeaderItems() {
    let { actions } = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      buttons: [
        {
          id: 'delete',
          label: headerItemsLabels.REQUEST_ORG_DELETION,
          icon: 'pl pl-delete-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onRequestOrganizationDeletionClick,
          actionData: selectedRowsData.get(0),
        },
        {
          id: 'duplicate',
          label: headerItemsLabels.DUPLICATE_ORG,
          icon: 'pl pl-duplicate',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onDuplicateOrganizationClick,
          actionData: selectedRowsData.get(0),
        },
        {
          id: 'edit',
          label: headerItemsLabels.EDIT_ORG,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditOrganizationClick,
          actionData: selectedRowsData.get(0),
        },
        {
          id: 'create',
          label: headerItemsLabels.CREATE_ORG,
          icon: 'pl pl-icon-add',
          disabled: !hasRows,
          action: actions.header.onCreateOrganizationClick,
        },
      ],
    };
  }

  render() {
    let headerItems = this.getHeaderItems();

    const actions = {
      ...this.props.actions.organizationsTabGridActions,
      ...this.props.actions.fetchEntitiesActions,
    };

    return (
      <div className="organizations-tab">
        <Header {...headerItems} />
        <OrganizationsGrid fetchAction actions={actions} />
      </div>
    );
  }
}

OrganizationsTab.propTypes = {
  actions: PropTypes.object.isRequired,
  sOrganizationsTabGrid: PropTypes.object,
  sSelectedRowsData: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
};

OrganizationsTab.defaultProps = {
  sOrganizationsTabGrid: {},
  sSelectedRowsData: {},
};

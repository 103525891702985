import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as projectsViewActions} from '../../../actions/Administration/MatSettingsPage/projectsViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as projectsViewGridActions} from '../../../actions/Administration/MatSettingsPage/projectsViewGridActions';
import { jsxActions as createProjectDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createProjectDialogActions.js';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import ProjectsView from '../../../components/Administration/MatSettingsPage/ProjectsView/projectsView.js';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(projectsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      projectsViewGridActions: bindActionCreators(projectsViewGridActions, dispatch),
      createProjectDialogActions : bindActionCreators(createProjectDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }

  }
}

let ProjectsViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_PROJECTS) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_PROJECTS) : null;
  	return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateProjectDialogData: state.administration.getIn(['matSettingsPage', 'createProjectDialog']),
    };
  },

  mapDispatchToProps
)(ProjectsView);


export default ProjectsViewContainer;




import Network from 'infrastructure/js/modules/network';

const POLL_INTERVAL = 1000;
const ONE_JOB_DURATION = 5000; //5s;
const OPERATION_DELTA_DURATION = 30000; //30s;


export function createHooks(data) {
  return Network.post('hooks/multi-create', data).then(response => {

    if(!Network.isResponseValid(response)) {
      console.error('createHooks() response: ', response);
      return response;
    }

    if (response.data && !_isPollingFinished(response.data.jobStatus)) {
      return pollingResult( response.data.jobExecutionId, response.data.totalJobItems);
    }

    return response;
  })
    .catch(error => {
      console.error('createHooks() catch ', error);
      throw error;
    })
}

function pollingResult(jobExecutionId, totalJobItems ) {
  let triesNumber = 0;
  let MAX_TRIES_NUM = (ONE_JOB_DURATION * totalJobItems + OPERATION_DELTA_DURATION) / POLL_INTERVAL;

  const handler = (resolve, reject) => {
    Network.post('hooks/multi-create-batch-counters', {batchJobExecutionId: jobExecutionId})
      .then(response => {
        if(!Network.isResponseValid(response)) {
          console.error('pollingResult(): response: ', response);
          return reject(response);
        }

        if (!_isPollingFinished(response.data.batchStatus) && triesNumber < MAX_TRIES_NUM) {
          _log(response.data.batchStatus, triesNumber, MAX_TRIES_NUM );

          triesNumber++;
          setTimeout(handler, POLL_INTERVAL, resolve, reject);
        }
        else if (_isPollingFinished(response.data.batchStatus)) {
          _log(response.data.batchStatus, triesNumber, MAX_TRIES_NUM );
          resolve(response);
        }
        else if (triesNumber >= MAX_TRIES_NUM) {
          console.error(`pollingResult(): the number of maximum permitted attempts (${MAX_TRIES_NUM}) was exceeded `);
          _log(response.data.batchStatus, triesNumber, MAX_TRIES_NUM );
          // reject(response);
          resolve(response);
        }

      })
      .catch(err => {
        console.error('pollingResult(): catch ', err);
        reject(err);
      })
  };

  return new Promise(handler);
}

function _isPollingFinished(status) {
  return status === 'COMPLETED';
}

function _log(status, counter, maxTriesNumber) {
  let DEBUG_MODE = false;

  // if (DEBUG_MODE) {
  //   console.log('----- status: ' + status + ' counter: ' + counter + ' MAX_TRIES_NUM: ' + maxTriesNumber);
  // }
}



import * as shiftsService from '../../../services/Shifts/shiftsService';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import moment from "moment/moment";
import DateTimeHelper from "../../../../../infrastructure/js/utils/dateTimeHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_MAT_SETTINGS_SHIFT_TYPES, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return shiftsService.fetchShiftTypesItems(query);
  }
};

function _convertToRowData(rows) {
  let items = rows.map((obj) => {
    let format = DateTimeHelper.getTimeFormat() ===  'HH:mm' ?  'HH:mm' : 'LT';
    let startTime = moment(obj.startTime,  'HH:mm').format(format);
    let endTime = moment(obj.endTime,  'HH:mm').format(format);
    obj.m_startTime = startTime;
    obj.m_endTime = endTime;

    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

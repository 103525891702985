import React, {Component} from 'react';

import PL_Wizard  from 'infrastructure/js/components/Wizard/wizard';
import CreateKitsDialog from './CreateKitsDialog/createKitsDialog';
import ConfirmCreateKitsDialog from './ConfirmCreateKitsDialog/confirmCreateKitsDialog';


require('./createKitWizard.scss');


export default class CreateKitWizard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.steps = {
      'step_1': <CreateKitsDialog />,
      'step_2': <ConfirmCreateKitsDialog />
    };
  }


  render() {
    return (
      <PL_Wizard wizardName="CREATE_KIT_WIAZRD" steps={this.steps} {...this.props} form="wizard_form_CREATE_KIT_WIAZRD"
                 preventStepsUnmountOnNavigation={true}
      />
    );
  }

}


import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import AddRemoveList from '../../../../../Common/Controls/AddRemoveList/addRemoveList';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import AntennaListItem from '../../../../../Common/Controls/AddRemoveList/AddRemoveListItem/AntennaListItem/antennaListItem';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import AlertPopover, {AlertPopoverType} from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
require('./createReaderDialog.scss');

class CreateReaderDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createreader.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.locationsOptions = this.props.sData.get('dialogData').locations;

    //add the first empty list item on opening
    let initialList = [];
    initialList.push(null);

    this.state = { antennas: initialList, errorMessage: ''};
  }

  componentDidMount() {
    let initialValues = {};

    if (this.isEditMode) {
      let selectedReader = this.getReaderTypes().find((reader)=>reader.value===this.itemToEdit.readerType);
      initialValues = {
        id: this.itemToEdit.id,
        readerName: this.itemToEdit.name,
        readerIP: this.itemToEdit.host,
        antennas : this.itemToEdit.antennas,
        readerType: {value: this.itemToEdit.readerType, label: selectedReader ? selectedReader.label : this.itemToEdit.readerType}
      };
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }


  onSubmit = (data) => {
    let newData = {
      name : data.readerName,
      host : data.readerIP,
      readerType: data.readerType.value,
      antennas : data.antennas ? data.antennas.map((item) => { return  {id : item.id ,name:item.name, locationId: item.locationId, port:item.port, tx:item.tx, rx:item.rx} }) : []
    };

    if(this.isEditMode){
      newData.id = data.id
    }

   this.props.actions.submit(this.props.deviceApp, newData, this.isEditMode);
  };

  onHide =() => {
    this.props.actions.hide();
  };

  listItemRenderer = (data) => {

    let alertConfig = !data.item.disableRemove ? null : {icon : 'pl pl-warning-icon warning' , text : data.item.tooltipMessage};

    return (
      <AntennaListItem
        id={data.name}
        name={data.name}
        index={data.index}
        item={data.item}
        labels={this.labels}
        options={this.locationsOptions}
        alert={alertConfig}
        {...this.props}
      />
    )
  };

  onRemoveCallback = (index) => {
    let antennas = [...this.state.antennas];

    if (index > -1) {
      antennas.splice(index, 1);
    }

    this.setState({antennas: antennas});
  };

  onAddCallback = () => {
    let antennas = [...this.state.antennas];

    antennas.push(null); //hold index in antennas for the new added item.

    this.setState({antennas: antennas});
  };

  getSelectedAntennasLocations = () => {

    let res =  this.itemToEdit.antennas.map((item) => {

        if(item.defaultDiscoUsages && item.nonDefaultDiscoUsages && item.nonDefaultDiscoUsages.length > 0){
          item.disableRemove = true;

          if(item.nonDefaultDiscoUsages && item.nonDefaultDiscoUsages.length === 1){
            item.tooltipMessage  = this.labels.get('alert.singleantennaconnected' , '' , {Antennas: item.nonDefaultDiscoUsages})
          }

          if(item.nonDefaultDiscoUsages && item.nonDefaultDiscoUsages.length > 1){

            let antennas = '';
            item.nonDefaultDiscoUsages.forEach(function (value) {
              let val = `\n[${value}]`;
              antennas += val
            });

            item.tooltipMessage  = this.labels.get('alert.multipleantennaconnected', '' , {Antennas: antennas})
          }
      }
      return item;

    });
    return res;

  }

  getIconPopOver = (type)=> {
    return (<AlertPopover popoverType={AlertPopoverType.HINT}
                          popoverComponent={this.getPopoverComponent(type)}
    />);
  };

  getPopoverComponent(type) {
    let message = type === 'rx' ? this.labels.get('antenna.header.rxTooltip') : this.labels.get('antenna.header.txTooltip') ;
    return <div>{message}</div>;
  }

  renderAntennasList = () => {
    return(
      <div className='antennas-wrapper'>
        <div className='antennas-header'>
          <label>{this.labels.get('antenna.header.name')}</label>
          <label>{this.labels.get('antenna.header.location')}</label>
          <label>{this.labels.get('antenna.header.port')}</label>
          <label>{this.labels.get('antenna.header.tx')}</label>
          {this.getIconPopOver('tx')}
          <label>{this.labels.get('antenna.header.rx')}</label>
          {this.getIconPopOver('rx')}
        </div>

        <AddRemoveList name='antennas'
                       itemRenderer={this.listItemRenderer}
                       preSelectedItems={this.isEditMode ?  this.getSelectedAntennasLocations() : []}
                       maxItemsToRender={8}
                       onRemoveCallback={this.onRemoveCallback}
                       defaultItem={{tx: 0, rx: 0}}
                       onAddCallback={this.onAddCallback}
                       addButtonLabel={this.labels.get('antenna.add')}
        />
      </div>
    )
  };

  getReaderTypes = ()=> {
  return [{value: 'IMPINJ', label: 'Impinj'}, {value: 'ZEBRA', label: 'Zebra'}];
  }



  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    return (
      <Dialog
        id="create-reader-dialog"
        className="create-reader-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerDialogMessage ={this.props.sData.get('dialogMessageData')}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <div className="reader-params-section">


        <InputSection label={this.labels.get('name')+'*'}
                      htmlFor="readerName"
                      className="inline small no-margin">
          <TextField  maxLength={30} id="readerName"
                     name="readerName"
                     className="short-textfield"
                     validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get('ip')+'*'}
                      htmlFor="readerIP"
                      className="inline small no-margin">
          <TextField id="readerIP"
                     name="readerIP"
                     className="short-textfield"
                     validate={[Validation.required , Validation.ipFormat]}
                     normalize={Normalize.numberAndDot()}
          />

        </InputSection>
        <InputSection label={this.labels.get('type')+'*'} htmlFor="readerType" className="inline small no-margin">
          <Combobox id="readerType" name="readerType" options={this.getReaderTypes()} validate={Validation.required} className="short-textfield" />
        </InputSection>

        </div>
        {this.renderAntennasList()}

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'createReaderDialog',
  }
)(CreateReaderDialog);

CreateReaderDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired
};



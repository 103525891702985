import React, { Component } from 'react';
import Overlay from 'infrastructure/js/components/Overlay/overlay.js';

export default class BryntumSchedulerLazy extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        lazyScheduler: null,
        loading: true,
        error: false,
    };

    componentDidMount() {
        import(
            /* webpackChunkName: "scheduler" */
            /* webpackMode: "lazy" */
            'infrastructure/js/components/Scheduler/scheduler'
        )
            .then(({ default: Scheduler }) => {
                this.setState({ lazyScheduler: Scheduler, loading: false });
            })
            .catch((e) => {
                console.error('error fetching scheduler component: ', e);
                this.setState({ loading: false, error: true });
            });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.lazyScheduler ? (
                    <this.state.lazyScheduler {...this.props} />
                ) : this.state.loading ? (
                    <Overlay.Loading />
                ) : (
                    <Overlay.Error />
                )}
            </React.Fragment>
        );
    }
}

import { api as workOrderDialogApi }  from './workOrderDialogActions.js';
import { api as createGroupDialogApi }  from '../../Dialogs/CreateGroupDialog/createGroupDialogActions.js';
import { api as createWorkOrderDialogApi }  from '../../Dialogs/CreateWorkOrderDialog/createWorkOrderDialogActions.js';
import { api as editWorkOrdersStatusDialogApi }  from '../../Dialogs/EditWorkOrdersStatusDialog/editWorkOrdersStatusDialogActions.js';
import { api as afpOperationWizardApi }  from '../../Wizards/AfpOperationWizard/afpOperationWizardActions.js';
import Network from 'infrastructure/js/modules/network';
import * as workOrdersService from '../../../services/WorkOrders/workOrdersService';
import * as locationsService from '../../../services/Locations/locationsService';
import { api as locationPageApi } from '../locationPageActions.js';
import { api as woGridApi} from './workOrderGridActions';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LOCATIONPAGE_WORKORDERVIEW_FETCHCHARTS_FINISHED:   'LOCATIONPAGE_WORKORDERVIEW_FETCHCHARTS_FINISHED',
  LOCATIONPAGE_WORKORDERVIEW_GETOPERATIONS_FINISHED:   'LOCATIONPAGE_WORKORDERVIEW_GETOPERATIONS_FINISHED',
  LOCATIONPAGE_WORKORDERVIEW_MARK_WO_COMPLETED_FINISHED: 'LOCATIONPAGE_WORKORDERVIEW_MARK_WO_COMPLETED_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchChartsFinished: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_WORKORDERVIEW_FETCHCHARTS_FINISHED, payload: payload };
  },
  getOperationsFinished: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_WORKORDERVIEW_GETOPERATIONS_FINISHED, payload: payload };
  },
  markWOasCompletedFinished: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_WORKORDERVIEW_MARK_WO_COMPLETED_FINISHED, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.onOpenDialogClick = function(operation, selectedWorkorders) {
  return function(dispatch, getState) {

    if (getEnumValue(enumTypes.OPERATION_TYPE)('AFP') === operation?.operationType?.type) {
      return afpOperationWizardApi.show(dispatch, getState)(operation);
    }
    workOrderDialogApi.show(dispatch, getState)(operation, selectedWorkorders);
  }
};

jsxActions.openCreateWorkOrderDialog = function() {
  return function(dispatch, getState) {
    createWorkOrderDialogApi.openDialog(dispatch, getState)();
  }
};

jsxActions.openCreateGroupDialog = function(payload) {
  return function(dispatch, getState) {
    createGroupDialogApi.show(dispatch, getState)(payload);
  }
};

jsxActions.markWOasComplete = function(data) {
  return function(dispatch, getState) {
    editWorkOrdersStatusDialogApi.show(dispatch, getState)(data);
  }
};
jsxActions.markWOasCanceled = function(data) {
  return function(dispatch, getState) {
    editWorkOrdersStatusDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    woGridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      woGridApi.reload(dispatch, getState)();
    }

    let fetchChartsPromise = api.fetchCharts(dispatch, getState)();
    let fetchOperationsPromise = api.getOperations(dispatch, getState)();

    return Promise.all([fetchChartsPromise, fetchOperationsPromise]);
  }
};

api.markWOasComplete = function(dispatch, getState) {
  return function(wo) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    workOrdersService.markWorkOrderAsComplete(locationId, wo)
      .then((response) => {

        if (!Network.isResponseValid(response)) {
          console.error("Mark WO as Complete Failed");
          return {success: false};
        }
        woGridApi.reload(dispatch, getState)();
        dispatch(actions.markWOasCompletedFinished({...response, success: true}));

        locationPageApi.reload(dispatch, getState)();
      });

  }
};


api.fetchCharts = function(dispatch, getState) {
  return function() {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    return workOrdersService.FetchCharts(locationId)
      .then((responses) => {
        let success = true;

        if(!Network.isResponseValid(responses[0]) || !Network.isResponseValid(responses[0])){
          console.error("WorkOrders fetch charts failed");
          success = false;
        }

        dispatch(actions.fetchChartsFinished({
          createdToday: responses[0].data.data,
          handledToday: responses[1].data.data,
          success
        }));
      });
  }
};

api.getOperations = function(dispatch, getState) {
  return function() {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    return locationsService.fetchLocationDetails(locationId).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error("Get Buttons Configuration Failed");
        return {success: false};
      }

      if (response === null) {
        return;
      }

      dispatch(actions.getOperationsFinished(response.data.operations));
    });
  }
};













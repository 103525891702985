import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';

import './Collapse.scss';

export default class PL_Collapse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            settingsCollapsed: false,
        };
    }

    toggleCollapsed = () => {
        this.setState({ settingsCollapsed: !this.state.settingsCollapsed });
        this.props?.onCollapseChange?.(this.state.settingsCollapsed)
    };

    render() {
        return (
            <div className='collapse-root'>
                <div className='collapse-header' onClick={this.toggleCollapsed}>
                    <span
                        className={`pl pl-arrow-right collapse-indicator ${
                            this.state.settingsCollapsed ? 'in' : ''
                        }`}
                    />
                    <span className='title' id={'collapse-trigger'}>
                        {this.props.title}
                    </span>
                </div>
                <Collapse in={this.state.settingsCollapsed}>
                    <div>{this.props.children}</div>
                </Collapse>
            </div>
        );
    }
}

PL_Collapse.propTypes = {
  title: PropTypes.string,
  onCollapseChange: PropTypes.func
}

import {createLabelHelper} from "../../../infrastructure/js/utils/labelHelper";
import PermissionManager from 'infrastructure/js/utils/permissionManager';

export const alertStatus = {
  INFO: 'info',
  NO_ALERT: 'noalert',
  WARNING: 'warning',
  ERROR: 'error',
};

export const scheduleAlertStatus = {
  NO_ALERT: 'noAlert',
  LOW_LEVEL_WARNING: 'lowWarning',
  HIGH_LEVEL_WARNING: 'highWarning',
};

export const alertType = {
  EXPIRATION: 'expiration',
  ETL_CURE: 'etlCure',
  ETL_BOND: 'etlBond',
  DUE_DATE: 'dueDate',
  RESERVED: 'reserved',
  MISPLACED: 'misplaced',
  DEFROSTING: 'defrosting',
};

export const scheduleAlertType = {
  DUE_DATE_CONFLICT: 'dueDateConflict',
  DEPENDENCY_CONFLICT: 'dependencyConflict',
  STATION_AVAILABILITY_CONFLICT: 'stationAvailabilityConflict',
  TOOL_AVAILABILITY_CONFLICT: 'toolAvailabilityConflict',
  PASSED_SCHEDULE: 'passedScheduleConflict',
};

export function getAlertColumnFilterSections(statuses, types) {
  let strings = createLabelHelper('mat.filter.alerts.').createSubset('');
  let result = [];

  if (statuses && statuses.length > 0) {
    let statusProperties = statuses.map((status) => { return {name: status, label: (strings[`alertstatus.${status}`]), value: false}});

    let status = {
      header: (strings['alertstatus']),
      properties: statusProperties
    };
    result.push(status);
  }

  if(!PermissionManager.isEtlBondEnabled() && types && types.length > 0 && types.includes(alertType.ETL_BOND)){
    let bond = types.indexOf(alertType.ETL_BOND);
    types.splice(bond, 1)
  }

    if (types && types.length > 0) {
    let typesProperties = types.map((type) => {return {name: type, label: (strings[`alerttype.${type}`]), value: false}});
    let type = {
      header: (strings['alerttype']),
      properties: typesProperties
    };
    result.push(type);
  }

  return result;
}

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as deviceAppsService from '../../../../services/Rfid/deviceAppsService';
import * as printersService from '../../../../services/Rfid/printersService.js';
import * as locationsService from '../../../../services/Locations/locationsService';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_PRINTER_DIALOG_SHOW:               'CREATE_PRINTER_DIALOG_SHOW',
  CREATE_PRINTER_DIALOG_HIDE:               'CREATE_PRINTER_DIALOG_HIDE',
  CREATE_PRINTER_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_PRINTER_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_PRINTER_DIALOG_SUBMIT_FINISHED:    'CREATE_PRINTER_DIALOG_SUBMIT_FINISHED',
  CREATE_PRINTER_DIALOG_FETCH_DATA_READY:   'CREATE_PRINTER_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_PRINTER_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_PRINTER_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_PRINTER_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_PRINTER_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDialogDataReady: function(payload) {
    return {type: actionTypes.CREATE_PRINTER_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};
//todo add fetch by device id

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, isEditMode, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    api.fetchDialogData(dispatch, getState)().then((response) => {
      dispatch(actions.show(data));
    });
  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function() {
    return locationsService.fetchActiveLocations().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Create Printer Dialog -  Fetch Locations Failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let dialogData = {
        locations: response.dataList.map((obj) => {return {value: obj.id, label: obj.name, data: obj}}),
      };

      dispatch(actions.fetchDialogDataReady(dialogData));
      return dialogData;
    });
  }
};

api.submit = function(dispatch, getState) {
  return function(data, isEditMode, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    let method = isEditMode ? printersService.editPrinter : printersService.createPrinter;

    let deviceProfileQuaryData = { //Use for getting deviceProfile data
      deviceOrgBusinessId :getState().login.get('loggedInUser').loggedInOrg.businessId,
      deviceBusinessId: data.deviceAppProfileId

    }

    return deviceAppsService.getDeviceProfile(deviceProfileQuaryData ).then((res) => {

      data.deviceAppProfileId = res.data.profileId;

      method(data).then((response) => {
        dispatch(actions.submitFinished());
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Create/Edit printer failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }
      });

    });


  };
};



import React from 'react';
import PropTypes from 'prop-types';

import Parse            from 'infrastructure/js/components/controls/controlsParse';
import EditInPlace from '../../controls/EditInPlace/editInPlace';
import {PL_ComboBox} from '../../controls/Combobox/combobox';
import Label from 'infrastructure/js/components/Label/label.js';

export default class ComboBoxEditor extends React.PureComponent {


  getComponentToRender = (props) => {
    return (props && props.data) ? (<Label text={props.data.label} />) : null;
  };

  render() {
    let {name, value, displayValue, validate, handleSubmit, parse, format, options, editable, allowNewOption } = this.props;
    value = (value === undefined) ? '' : value;
    displayValue = (displayValue === undefined) ? value : displayValue;
    return (
      <EditInPlace
        id={this.props.id}
        name={name}
        formName={`form_EditInPlace_${name}`}
        serverValue={value}
        serverDisplayValue={displayValue}
        sendData={handleSubmit}
        validate={validate}
        editable={editable}
        parse={parse}
        format={format}
        alert={this.props.alert}
      >
        <PL_ComboBox id={`${this.props.id}-input`}
                     options={options}
                     allowNewOption={allowNewOption}
                     openMenuOnFocus={true}
                     optionRenderer={this.getComponentToRender}
                     alert={this.props.alert}
        />

      </EditInPlace>
    );
  }
}

ComboBoxEditor.defaultProps = {
  allowNewOption: false,
  editable: true
};
ComboBoxEditor.propTypes = {
  name : PropTypes.string.isRequired,
  displayValue: PropTypes.string,
};


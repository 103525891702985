import { Map } from 'immutable';
import { actionTypes as CreateReasonTypeDialogActionsType } from '../../../actions/Administration/MatSettingsPage/Dialogs/createReasonTypeDialogActions.js';

let defaultState = {
  show: false,
  loading: false,
  itemToEdit: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreateReasonTypeDialogActionsType.CREATE_REASONTYPE_DIALOG_SHOW:
      state = state.set('itemToEdit', action.payload);
      return state.set('show',true);

    case CreateReasonTypeDialogActionsType.CREATE_REASONTYPE_DIALOG_HIDE:
      return Map(defaultState);

    case CreateReasonTypeDialogActionsType.CREATE_REASONTYPE_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case CreateReasonTypeDialogActionsType.CREATE_REASONTYPE_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);

    default:
      return state;
  }

}








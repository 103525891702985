import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

import classNames from 'classnames';

require('./notificationTopIndicationBar.scss');

export default class NotificationTopIndicationBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('');
    this.state = {
      isReadNotificationExists: false,
      isUnReadNotificationExists: false
    }
  }

  renderText = (critical) => {
    let text = this.labels.get(critical.labelKey, undefined, critical.paramsSet ? critical.paramsSet : undefined);
    return critical.status === 'READ' ?
      (<label>{text}</label>) : (<label>{text} <a className='agree-link'
                                                  onClick={() => this.onAgreeClicked(critical)}>{'acknowledge'}</a></label>)

  };

  onAgreeClicked(critical) {
    critical.status = 'READ';
    this.props.actions.notificationActions.changeReadStatus(critical);
  }

  renderUnReadCriticalContent = (critical) => {
    return (
      <div className="content" key={critical.id}>
        <i className={'fa b-fa-exclamation-triangle'}></i>
        <label htmlFor="">{this.renderText(critical)}</label>
      </div>
    )
  }

  renderUnReadCriticals = (criticals) => {
    if (!criticals || !criticals.length) {
      return null;
    }
    return (
      <div className="unread-notification-bar">
        {criticals.map(critical => {
          return this.renderUnReadCriticalContent(critical)
        })}
      </div>
    );
  }

  renderReadCriticals = (criticals) => {
    if (!criticals || !criticals.length) {
      return null;
    }
    return (
      <div className="read-notification-bar">
        {criticals.map(critical => {
          return this.renderUnReadCriticalContent(critical)
        })}
      </div>
    );
  }

  render() {

    let criticals = this.props.data ;
    if (!criticals || !criticals.length) {
      return null;
    }

    let unReadCriticals = criticals.filter(critical => critical.status !== 'READ');
    let readCriticals = criticals.filter(critical => critical.status === 'READ');

    return (
      <div
        className={classNames('notification-bar-wrapper', {'bar-only': (!unReadCriticals || !unReadCriticals.length)})}>
        {this.renderUnReadCriticals(unReadCriticals)}
        <div className="separator"/>
        {this.renderReadCriticals(readCriticals)}
      </div>
    )

  }
}

NotificationTopIndicationBar.propTypes = {
  data: PropTypes.object
};

import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DraggableShift from './draggableShift';
import { useElementSelectionContext } from 'infrastructure/js/contexts/elementSelectionContext';
import { useOnClickOutside } from 'infrastructure/js/hooks/useOnClickOutside';
import { convertTimeStringToDate } from './shiftsSchedulerHelpers';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';
import moment from 'moment';

import './assignedShift.scss';

export default function AssignedShift({ shiftData, resourceData, isTemplate }) {
  const labels = useMemo(() => createLabelHelper('mat.administration.matsettings.shiftsScheduler.assignment.'), []);
  const { color } = shiftData;
  const _isShiftStarted = isShiftStarted(shiftData);
  const { selected, setSelected } = useElementSelectionContext();
  const assignedShiftRef = useRef(null);
  const isSelected = selected.findIndex(({ element }) => element === assignedShiftRef.current);

  const deselect = () => {
    const newSelectedArr = [...selected];
    newSelectedArr.splice(isSelected, 1);
    setSelected(newSelectedArr);
  };

  const clickOutsideHandler = (e) => {
    const isCtrlKeyPressed = e.ctrlKey;
    const clickedOnASelectedAssignment = selected.some(({ element }) => element.contains(e.target));
    const clickedOnShiftSchedulerButton = e.target.classList?.contains('shift-scheduler-action');
    const clickedInsideEditDialog = document.getElementById('shift-settings-dialog')?.contains(e.target);

    if (!isCtrlKeyPressed && !clickedOnASelectedAssignment && !clickedOnShiftSchedulerButton && !clickedInsideEditDialog) {
      setSelected([]);
    }
  };

  useOnClickOutside(assignedShiftRef, clickOutsideHandler);

  const onShiftClick = (e) => {
    if (_isShiftStarted) {
      return;
    }

    const isCtrlKeyPressed = e.ctrlKey;
    const assignmentAlreadySelected = isSelected > -1;
    const currentAssignmentData = { element: e.currentTarget, data: { shiftData, resourceData } };

    if (assignmentAlreadySelected) {
      isCtrlKeyPressed ? deselect() : setSelected(selected.length > 1 ? [currentAssignmentData] : []);
    } else {
      isCtrlKeyPressed ? setSelected([...selected, currentAssignmentData]) : setSelected([currentAssignmentData]);
    }
  };

  const shiftWidth = useMemo(() => {
    return getShiftWidth(shiftData);
  }, [shiftData]);

  const shiftPosition = useMemo(() => {
    return getShiftPosition(shiftData);
  }, [shiftData]);

  const getTooltip = () => {
    const humanCapacityEnabled = PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled;

    return <div>
      <div>{labels.get('tooltip.shiftName')}: {shiftData.name}</div>
      <div>{shiftData.startTime.slice(0,-3)}-{shiftData.endTime.slice(0,-3)}</div>
      {humanCapacityEnabled && <div>{labels.get('tooltip.humanCapacity')}: {shiftData.humanCapacity}</div>}
    </div>
  }

  return (
    <DraggableShift shiftData={shiftData}>
      <Tooltip placement="top" value={getTooltip()}>
        <div
          ref={assignedShiftRef}
          className={cn('shift-type', {
            'shift-started': _isShiftStarted,
            'shift-changed': shiftData?.weeklyShiftOverrideId,
            'shift-selected': isSelected > -1,
            'shift-from-last-week': shiftData.isShiftFromLastWeek,
          })}
          style={{ backgroundColor: color, width: shiftWidth, left: shiftPosition }}
          onClick={onShiftClick}
        />
      </Tooltip>
    </DraggableShift>
  );
}

AssignedShift.propTypes = {
  shiftData: PropTypes.object,
  resourceData: PropTypes.object,
  isTemplate: PropTypes.bool
};

AssignedShift.defaultProps = {
  shiftData: {},
  resourceData: {},
  isTemplate: false
};

/////////////////////////////////////////////////////////////////////
// HELPERS

function isShiftStarted(shiftData) {
  if (shiftData?.date?.isValid()) {
    const startTimeHours = moment.duration(shiftData.startTime).hours();
    const startTimeMinutes = moment.duration(shiftData.startTime).minutes();

    const initShiftDate = shiftData.date.clone().startOf('day').hours(startTimeHours).minutes(startTimeMinutes);
    return initShiftDate.isBefore(moment());
  }

  return false;
}

function getDurationPercent(startDate, endDate) {
  const duration = endDate >= startDate ? endDate - startDate : 1000 * 60 * 60 * 24 - (startDate - endDate);
  return (duration / (1000 * 60 * 60 * 24)) * 100;
}

function getShiftWidth(shiftData) {
  if (!shiftData?.startTime || !shiftData?.endTime) {
    return '0%';
  }

  if (!shiftData.isShiftFromLastWeek && shiftData.startTime === shiftData.endTime) {
    return '100%';
  }

  const shiftStartTime = !shiftData.isShiftFromLastWeek ? shiftData.startTime : '00:00:00';
  const startDate = convertTimeStringToDate(shiftStartTime);
  const endDate = convertTimeStringToDate(shiftData.endTime);
  const durationInPercent = getDurationPercent(startDate, endDate);

  return `${Math.round(durationInPercent)}%`;
}

function getShiftPosition(shiftData) {
  const startOfDay = new Date().setHours(0, 0, 0, 0);
  const shiftStartTime = !shiftData.isShiftFromLastWeek ? shiftData.startTime : '00:00:00';
  const shiftStartDate = convertTimeStringToDate(shiftStartTime);
  const durationInPercent = getDurationPercent(startOfDay, shiftStartDate);

  return `${Math.round(durationInPercent)}%`;
}


//TO DO: Temporary solution, should be done in some general way
export function normalizeFloat( min, max, fixedTo=2) {
  return (value, previousValue, allValues, previousAllValues) => {

    if (value === '') {
      return value;
    }

    if (isNaN(value)) {
      return previousValue;
    }

    if ( (value.split('.')[1] || []).length > fixedTo) {
      return previousValue;
    }

    if ((max || max === 0) && (+value > max)) {
      return previousValue;
    }

    if ((min || min === 0) && (+value < min)) {
      return previousValue;
    }

    if ((value.slice(-1) === '.' || value.slice(-2) === '.0' || value === '0.0') ) {
      return value;
    }
    return +value;
  }
}

export function validateFloat(value, allValues) {
  return (!value || value.length === 0 || value === '0.' || value === '0.0') ? 'Required' : undefined;
}

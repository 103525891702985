import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from 'infrastructure/js/components/controls/Button/button';
import classNames from 'classnames';

require('./scanHeader.scss');

export default class ScanHeader extends React.PureComponent {

  onClick = (button) => {
    if (button.action) {
      button.action(button.actionData);
    }
  };


  createButton = (button, className2) => {
    if (!button) {
      return null;
    }
    return (<Button
      id={button.id}
      className={classNames(className2, 'scan-header-item', button.label ? '' : 'only-icon', button.className)}
      icon={button.icon}
      onClick={() => this.onClick(button)}
      //disabled={button.disabled || !button.action}
      disabled={button.disabled}
    >
      {button.label ? button.label : ''}
    </Button>);
  };



  render() {
    let leftButton = this.createButton(this.props.leftButton, 'btn-left');
    let rightButton = this.createButton(this.props.rightButton, 'btn-right');
    let middleButton = this.createButton(this.props.middleButton, 'btn-middle');
    return (

      <div className={classNames('scan-header', this.props.className)}>
        {leftButton}
        {middleButton}
        {rightButton}
      </div>
    );
  };
}
ScanHeader.defaultProps = {
  leftButton: {},
  rightButton: {},
  middleButton: {}
};

ScanHeader.propTypes = {
  leftButton: PropTypes.object,
  rightButton: PropTypes.object,
  middleButton: PropTypes.object
};



import Network from 'infrastructure/js/modules/network';
import * as shiftsService from '../../../services/Shifts/shiftsService';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions';
import { api as shiftSettingsDialogApi } from '../../../actions/Administration/MatSettingsPage/Dialogs/shiftSettingsDialogActions';
import { fetchAllResources } from '../../../services/Scheduler/schedulerService';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { filterTypes } from '../../../enums/shiftSchedulerEnums';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

export const actionTypes = {
  SHIFTS_SCHEDULER_FETCH_DATA_IN_PROGRESS: 'SHIFTS_SCHEDULER_FETCH_DATA_IN_PROGRESS',
  SHIFTS_SCHEDULER_FETCH_DATA_FINISHED: 'SHIFTS_SCHEDULER_FETCH_DATA_FINISHED',
  SHIFTS_SCHEDULER_UPDATE_DATA_IN_PROGRESS: 'SHIFTS_SCHEDULER_UPDATE_DATA_IN_PROGRESS',
  SHIFTS_SCHEDULER_UPDATE_DATA_FINISHED: 'SHIFTS_SCHEDULER_UPDATE_DATA_FINISHED',
  SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_IN_PROGRESS: 'SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_IN_PROGRESS',
  SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_FINISHED: 'SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_FINISHED',
  SHIFTS_SCHEDULER_UNMOUNT: 'SHIFTS_SCHEDULER_UNMOUNT',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataInProgress: function () {
    return { type: actionTypes.SHIFTS_SCHEDULER_FETCH_DATA_IN_PROGRESS };
  },
  fetchDataFinished: function (payload) {
    return { type: actionTypes.SHIFTS_SCHEDULER_FETCH_DATA_FINISHED, payload };
  },
  updateDataInProgress: function () {
    return { type: actionTypes.SHIFTS_SCHEDULER_UPDATE_DATA_IN_PROGRESS };
  },
  updateDataFinished: function (payload) {
    return { type: actionTypes.SHIFTS_SCHEDULER_UPDATE_DATA_FINISHED, payload };
  },
  fetchShiftTypesInProgress: function () {
    return { type: actionTypes.SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_IN_PROGRESS };
  },
  fetchShiftTypesFinished: function (payload) {
    return { type: actionTypes.SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_FINISHED, payload };
  },
  unmount: function () {
    return { type: actionTypes.SHIFTS_SCHEDULER_UNMOUNT };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchData = function () {
  return function (dispatch, getState) {
    return api.fetchData(dispatch, getState)();
  };
};

jsxActions.assignShift = function (shiftAssignData) {
  return function (dispatch, getState) {
    return api.assignShift(dispatch, getState)(shiftAssignData);
  };
};

jsxActions.removeShifts = function ({ filterData, assignments }) {
  return function (dispatch, getState) {
    const _assignments = assignments || getState().administration.getIn(['matSettingsPage', 'shiftsScheduler', 'shiftsSchedulerData']);
    const assignmentsToDelete = _getAssignmentsToDelete(_assignments, filterData);

    if (!assignmentsToDelete?.items?.length) {
      return;
    }

    if (assignmentsToDelete?.items?.length > 1) {
      return api.openRemoveShiftsConfirmationDialog(dispatch, getState)(assignmentsToDelete);
    }

    return api.removeShifts(dispatch, getState)(assignmentsToDelete);
  };
};

jsxActions.unmount = function () {
  return function (dispatch) {
    dispatch(actions.unmount());
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function (dispatch, getState) {
  return function () {
    api.fetchShiftsSchedulerData(dispatch, getState)();
  };
};

api.fetchShiftsSchedulerData = function (dispatch, getState) {
  return function () {
    dispatch(actions.fetchDataInProgress());

    const resourcesPromise = fetchAllResources();

    const weekStartDate = getState().administration.getIn(['matSettingsPage', 'weeklyShifts', 'weekStartDate']);
    const shiftsDataPromise = shiftsService.fetchWeeklyShifts({
      startDateOfWeek: DateTimeHelper.ConvertFromDate(weekStartDate),
    });

    return Promise.all([resourcesPromise, shiftsDataPromise])
      .then((allResults) => {
        const invalidResponse = allResults.find((response) => {
          return !Network.isResponseValid(response);
        });

        if (invalidResponse) {
          console.error('Failed to Fetch weekly shifts.', invalidResponse);
          messageDialogApi.responseError(dispatch, getState)(invalidResponse);
          dispatch(actions.fetchDataFinished());
          return { success: false };
        }

        const resourcesData = allResults[0]?.dataList;
        const shiftsData = allResults[1]?.data?.shifts;
        const shiftTypes = allResults[1]?.data?.shiftTypes;

        const rowsData = _convertToRowsData(resourcesData, shiftsData, weekStartDate);

        dispatch(actions.fetchDataFinished(rowsData));
        dispatch(actions.fetchShiftTypesFinished(shiftTypes));
      })
      .catch((err) => {
        console.error('Failed to Fetch weekly shifts.', err);
        messageDialogApi.responseError(dispatch, getState)();
        dispatch(actions.fetchDataFinished());
      });
  };
};

api.assignShift = function (dispatch, getState) {
  return function ({ shiftData, resourceData, date }) {
    dispatch(actions.updateDataInProgress());

    const workDate = date || getState().administration.getIn(['matSettingsPage', 'weeklyShifts', 'weekStartDate']);
    const workDateServerObj = DateTimeHelper.ConvertFromDate(workDate);

    const queryData = {
      shiftTypeId: shiftData.id,
      workDate: workDateServerObj,
      applyOnAllDays: !date,
      resourceId: resourceData?.id,
      humanCapacity: shiftData.humanCapacity,
    };

    const isMultiAssign = queryData.applyOnAllDays || !queryData.resourceId;

    return shiftsService
      .createWeeklyShift(queryData)
      .then((response) => {
        dispatch(actions.updateDataFinished());

        if (!Network.isResponseValid(response)) {
          console.error('Assign shift failed.', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return false;
        }

        const assignmentErrors = response?.data?.failedShiftRequests;
        const assignmentsSucceeded = response?.data?.succeededShifts;

        if (assignmentsSucceeded?.length > 0) {
          const shiftSettingsDialogData = {
            shiftData,
            resourceData,
            date: workDate,
            shifts: assignmentsSucceeded,
          };

          // Show shift settings dialog only if assigning to multiple resources/days
          if (isMultiAssign) {
            shiftSettingsDialogApi.show(dispatch, getState)(shiftSettingsDialogData);
          }
        }

        if (assignmentErrors?.length > 0) {
          messageDialogApi.open(dispatch, getState)(_assignMessageDialogBuilder(assignmentErrors));
        }

        api.fetchShiftsSchedulerData(dispatch, getState)();
      })
      .catch((err) => {
        console.error('Assign shift failed.', err);
        messageDialogApi.responseError(dispatch, getState)();
        dispatch(actions.updateDataFinished());
      });
  };
};

api.removeShifts = function (dispatch, getState) {
  return function (assignmentsToDelete) {
    dispatch(actions.updateDataInProgress());

    return shiftsService
      .deleteWeeklyShifts(assignmentsToDelete)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Delete shift failed.', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          dispatch(actions.updateDataFinished());
          return false;
        }

        const warnings = Network.hasValidationWarnings(response);
        if (warnings) {
          const messageDialogDescriptor = _warningMessageDialogDescriptor(warnings);
          if (messageDialogDescriptor) {
            messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          }
        }

        return api.fetchShiftsSchedulerData(dispatch, getState)();
      })
      .catch((err) => {
        console.error('Delete shift failed.', err);
        messageDialogApi.responseError(dispatch, getState)();
        dispatch(actions.updateDataFinished());
      });
  };
};

api.redeployTemplate = function (dispatch, getState) {
  return function () {
    dispatch(actions.updateDataInProgress());

    const weekStartDate = getState().administration.getIn(['matSettingsPage', 'weeklyShifts', 'weekStartDate']);

    const queryData = DateTimeHelper.ConvertFromDate(weekStartDate);

    return shiftsService
      .redeployWeeklyShiftsTemplate(queryData)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Redeploy template failed.', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          dispatch(actions.updateDataFinished());
          return false;
        }

        const warnings = Network.hasValidationWarnings(response);
        if (warnings) {
          const messageDialogDescriptor = _warningMessageDialogDescriptor(warnings);
          if (messageDialogDescriptor) {
            messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          }
        }

        console.log('redeployTemplate success');
        return api.fetchShiftsSchedulerData(dispatch, getState)();
      })
      .catch(() => {
        console.log('redeployTemplate error');
        dispatch(actions.updateDataFinished());
      });
  };
};

api.openRemoveShiftsConfirmationDialog = function (dispatch, getState) {
  return function (data) {
    const labels = createLabelHelper('mat.administration.matsettings.shiftsScheduler.remove.confirmation.');

    const dialogLabels = createLabelHelper('mat.dialog.');

    const children = [<MessageDialog.MessageRow key={0} text={labels.get('message')} />];

    let buttons = [
      {
        id: 'cancel',
        text: dialogLabels.get('cancel'),
        action: messageDialogApi.close(dispatch, getState),
        bsStyle: 'default',
      },
      {
        id: 'remove',
        text: labels.get('confirmRemove'),
        action: () => {
          messageDialogApi.close(dispatch, getState)();
          api.removeShifts(dispatch, getState)(data);
        },
        bsStyle: 'primary',
      },
    ];

    const confirmationConfig = {
      title: labels.get('title'),
      type: 'warning',
      className: '',
      children,
      buttons,
    };

    messageDialogApi.open(dispatch, getState)(confirmationConfig);
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _convertToRowsData(resources, assignedShiftsData, weekStartDate) {
  return (
    resources?.map((resource) => {
      const resourceWithAssignments = assignedShiftsData?.find((r) => resource.id === r.resourceId);

      if (resourceWithAssignments?.assignments) {
        resource.assignments = resourceWithAssignments.assignments.map((assignmentsPerDate) => {
          const assignmentsDate = DateTimeHelper.ConvertToDate(assignmentsPerDate.workDate);

          // Check if there are assignments that spill over from last week
          // and if so, move these assignments to the first day of the week.
          if (assignmentsDate.isBefore(weekStartDate, 'day')) {
            const newWorkDate = DateTimeHelper.ConvertFromDate(weekStartDate);
            const newShifts = assignmentsPerDate.shifts.map((shift) => ({ ...shift, isShiftFromLastWeek: true }));
            return {
              ...assignmentsPerDate,
              shifts: newShifts,
              day: weekStartDate.format('dddd'),
              workDate: newWorkDate,
            };
          }

          return {
            ...assignmentsPerDate,
            day: assignmentsDate.format('dddd'),
          };
        });
      }

      return resource;
    }) || []
  );
}

function _getAssignmentsToDelete(assignments, filterData) {
  switch (filterData.filterBy) {
    case filterTypes.ALL: {
      const flatAssignmentsData = _flattenShiftSchedulerData(assignments);
      return _convertAssignmentsToDeleteQueryData(flatAssignmentsData);
    }
    case filterTypes.SELECTED:
      return _convertAssignmentsToDeleteQueryData(assignments);
    case filterTypes.DAY:
    case filterTypes.RESOURCE:
    case filterTypes.DAY_AND_RESOURCE: {
      const filterFunc = _assignmentsFilterFunc(filterData);
      const flatAssignmentsData = _flattenShiftSchedulerData(assignments);
      const filteredAssignments = flatAssignmentsData.filter(filterFunc);
      return _convertAssignmentsToDeleteQueryData(filteredAssignments);
    }
  }
}

function _assignmentsFilterFunc(filterData) {
  switch (filterData.filterBy) {
    case filterTypes.DAY:
      return (assignment) => assignment?.shiftData?.day === filterData.day.toUpperCase() && !assignment.shiftData.isShiftFromLastWeek;
    case filterTypes.RESOURCE:
      return (assignment) => assignment?.resourceData?.id === filterData.resourceId && !assignment.shiftData.isShiftFromLastWeek;
    case filterTypes.DAY_AND_RESOURCE:
      return (assignment) =>
        assignment?.shiftData?.day === filterData.day.toUpperCase() &&
        assignment.resourceData.id === filterData.resourceId &&
        !assignment.shiftData.isShiftFromLastWeek;
  }
}

function _convertAssignmentsToDeleteQueryData(assignments) {
  const items = assignments.map((item) => ({
    weeklyShiftTemplateId: item.shiftData.templateId,
    weeklyShiftOverrideId: item.shiftData.weeklyShiftOverrideId,
    workDate: DateTimeHelper.ConvertFromDate(DateTimeHelper.ConvertToDate(item.shiftData.workDate)),
  }));

  return { items };
}

function _flattenShiftSchedulerData(assignmentsData) {
  const filteredResources = assignmentsData.filter((resource) => resource?.assignments);
  return filteredResources.flatMap((resource) => {
    const { assignments, ...resourceData } = resource;
    return assignments?.flatMap((assignment) => {
      const { shifts } = assignment;
      return shifts.map((shift) => {
        return {
          shiftData: { ...shift, date: DateTimeHelper.ConvertToDate(shift.workDate) },
          resourceData,
        };
      });
    });
  });
}

function _assignMessageDialogBuilder(errorsArray) {
  const dialogLabels = createLabelHelper('mat.administration.matsettings.weeklyShiftsTemplate.alert.failedAssignments.');

  const children = errorsArray.map((errorData, i) => {
    const label = dialogLabels.get('messageRow', '', errorData);
    return <MessageDialog.MessageRow key={i} text={label} />;
  });

  return { title: dialogLabels.get('title'), type: 'warning', className: '', children };
}

function _warningMessageDialogDescriptor(warnings) {
  let validations = dialogHelper.getValidationArea(warnings);

  if (validations && validations.length > 0) {
    let messages = [];
    validations.forEach((v) => {
      if (v && v.messages) {
        v.messages.forEach((m) => {
          messages.push(m);
        });
      }
    });

    if (messages.length > 0) {
      let title = messages[0] ? messages[0].message : 'Invalid Input';
      let type = 'warning';
      let className = 'oneBackground';

      return { title, type, className };
    }
  }
  return null;
}

import React from 'react';

import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import Label from 'infrastructure/js/components/Label/label.js';

require('./operationStatusCell.scss');

export default class OperationStatusCell extends React.PureComponent {

  getPopoverComponent = (value) => {
    let statuses = value.map((status , index) => <div key={'operationStatusCell' + index}>{status}</div>);
    return <div>{statuses}</div>;
  };

  render() {
    let {value} = this.props;

    if (value) {
      return (
        <div className={'operation-status-cell'}>
          <AlertPopover
            popoverType={value.severity.toLowerCase()}
            popoverComponent={this.getPopoverComponent(value.operationStatus)}
          />
          <Label text={value.operationStatus.join(' ')} className="operation-status-text"/>
        </div>);
    }
    return null;
  }
}







import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues} from 'redux-form';

import {jsxActions}  from '../../actions/LatencyCalc/latencyCalcActions.js';
import { withRouter } from 'react-router-dom'

import LatencyCalcPage from 'infrastructure/js/components/LatencyCalcPage/latencyCalcPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(jsxActions, dispatch),
    },
  }
}

let LatencyCalcContainer = reduxForm({
    form: 'latencyCalcPage'
  }
)(LatencyCalcPage);

LatencyCalcContainer = connect(
  (state)=>{
    return {
      sData: state.latencyCalc
    }
  },
  mapDispatchToProps
)(LatencyCalcContainer);

export default withRouter(LatencyCalcContainer);

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as alertedSpoolsPageActions} from '../../actions/AlertedSpoolsPage/alertedSpoolsPageActions';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import {gridsNames} from '../../enums/gridsNames';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as alertedSpoolsGridActions } from '../../actions/AlertedSpoolsPage/alertedSpoolsGridActions';
import { jsxActions as editAssetAttributesActions }  from '../../actions/Dialogs/EditAssetAttributesDialog/editAssetAttributesDialogActions';
import { jsxActions as moveAssetsActions }  from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as shelfLifeUpdateActions } from '../../actions/Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialogActions';
import { jsxActions as archiveAssetsActions }   from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import {jsxActions as printRfidTagsActions} from '../../actions/Dialogs/PrintRfidTagsDialog/printRfidTagsDialogActions';
import { jsxActions as printPdfLabelsActions }      from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import { jsxActions as importAttributesUpdateWizardActions} from '../../actions/Wizards/ImportAttributesUpdateWizard/importAttributesUpdateWizardActions.js';
import AlertedSpoolsPage from '../../components/PredefinedList/AlertedSpoolsPage/alertedSpoolsPage';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(alertedSpoolsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      alertedSpoolsGridActions: bindActionCreators(alertedSpoolsGridActions, dispatch),
      editAssetAttributesActions:      bindActionCreators(editAssetAttributesActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      shelfLifeUpdateActions:   bindActionCreators(shelfLifeUpdateActions, dispatch),
      archiveAssetsActions:     bindActionCreators(archiveAssetsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch),
      printRfidTagsActions: bindActionCreators(printRfidTagsActions, dispatch),
      printPdfLabelsActions: bindActionCreators(printPdfLabelsActions, dispatch),
      importAttributesUpdateWizardActions: bindActionCreators(importAttributesUpdateWizardActions, dispatch),
    }
  }
}

let AlertedSpoolsPageContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.PREDEFINED_LIST_ALERTED_SPOOLS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ALERTED_SPOOLS) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sEditAssetAttributesData: state.dialogs.get('editAssetAttributesDialog'),
      sMoveAssetsData:  state.dialogs.get('moveAssetsDialog'),
      sShelfLifeUpdateData: state.dialogs.get('shelfLifeUpdateDialog'),
      sArchiveAssetsData:   state.dialogs.get('archiveAssetsDialog'),
      sLoggedInUser : state.login.get('loggedInUser'),
      sPrintRfidTagsDialogData:    state.dialogs.get('printRfidTagsDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
      sImportAttributesUpdateWizardData: state.wizards.get('importAttributesUpdateWizard'),

    };
  },
  mapDispatchToProps
)(AlertedSpoolsPage);


export default AlertedSpoolsPageContainer;




import { Map } from 'immutable';
import { actionTypes as commonPdfLabelSettingsViewActions } from '../../../actions/Administration/SettingsPage/commonPdfLabelSettingsViewActions';

let defaultState = {
  loading : false,
  formData : null,
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case commonPdfLabelSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case commonPdfLabelSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_FINISHED:
      return state.set('loading', false);

    case commonPdfLabelSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_FAILED:
      return state.set('loading', false);

    case commonPdfLabelSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_FETCH_DATA_IN_PROGRESS:
      return state.set('loading', true);

    case commonPdfLabelSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_FETCH_DATA_FINISHED:
      // state = state.set('loading', false);
      state = state.set('formData', action?.payload?.formData);
      state = state.set('loading', false);
      return state;

    case commonPdfLabelSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_RESET_DATA:
      return Map(defaultState);

    default:
      return state;
  }
}













import { useEffect } from 'react';

import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';

import FullScreenTabsSeparator from '../../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';

import ActiveGroupsContainer from '../../../containers/ActiveGroupsPage/activeGroupsContainer';
import AlertedGroupsContainer from '../../../containers/AlertedGroupsPage/alertedGroupsContainer';
import ArchivedGroupsContainer from '../../../containers/ArchivedGroupsPage/archivedGroupsContainer';

import { navigationStates } from '../../../enums/navigationStates';

import './groupsPage.scss';

const labels = createLabelHelper('mat.groups.page.');
const tabsLabels = createLabelHelper('mat.common.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  ACTIVE: 'active',
  ALERTED: 'alerted',
  ARCHIVED: 'archived',
};

const GroupsPage = () => {
  const updateNavBar = useNavBarUpdate();
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl('PredefinedViews/groups', Object.values(tabKeys), 'active');

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  useEffect(() => {
    updateNavBar(navigationStates.GROUPS, labels.get('title'));
  }, [updateNavBar, setTabKeyInURL]);

  return (
    <div className="groups-page">
      <FullScreenTabsSeparator />
      <Tabs id="groups-tabs" tabType="page" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('active')} eventKey={tabKeys.ACTIVE}>
          <ActiveGroupsContainer />
        </Tab>
        <Tab title={tabsLabels.get('alerted')} eventKey={tabKeys.ALERTED}>
          <AlertedGroupsContainer />
        </Tab>
        <Tab title={tabsLabels.get('archived')} eventKey={tabKeys.ARCHIVED}>
          <ArchivedGroupsContainer />
        </Tab>
      </Tabs>
    </div>
  );
};

export default GroupsPage;

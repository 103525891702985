import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';

import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';

require('./deleteOrganizationDialog.scss');

class DeleteOrganizationDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.dialog.deleteorganization.');

    this.deletionData = this.props.sData.get('deletionData'); //organization data or deletion request data
    this.isAcceptDeletion = this.props.sData.get('isAcceptDeletion');
    this.isRejectDeletion = this.props.sData.get('isRejectDeletion');

    //run submit without showing the dialog
    if (this.isRejectDeletion) {
      const submitter = this.props.handleSubmit(this.onSubmit);
      submitter();
    }
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isAcceptDeletion ? this.labels.get('footer.accept.delete') : this.labels.get('footer.request.delete'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading')
        }
      ]
    };
  }

  onSubmit = (data) => {
    if (this.isRejectDeletion) {
      data.id = this.deletionData.id; //deletion request Id
    }
    else if (this.isAcceptDeletion) {
      data.id = this.deletionData.id; //deletion request Id
    }
    else {
      data.id = this.deletionData.id;   //org Id
    }
    this.props.actions.submit(data, this.isAcceptDeletion, this.isRejectDeletion, this.getMessageDialogBuilder());
  };

  getMessageDialogBuilder = () => {
    return () => {
      let stringKey = this.isAcceptDeletion ? 'confirmation.accept.header' :
                      ( this.isRejectDeletion ? 'confirmation.reject.header' : 'confirmation.request.header');

      return {title: this.labels.get(stringKey), className: 'oneBackground'};
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  renderCommentSection() {
    if (this.isAcceptDeletion) {
      return null;
    }
    return (
      <InputSection label={this.labels.get('comment')} htmlFor="comment" className="full-width">
        <TextAreaField id="comment-textField" name="comment" className="comment-textField" />
      </InputSection>
    );
  }


  render() {
    if (this.isRejectDeletion) {
      return null;
    }

    let titleText = this.isAcceptDeletion ? this.labels.get('accept.header.title') : this.labels.get('request.header.title');
    let bodyText = this.isAcceptDeletion ?
                    this.labels.get('accept.body.text', '', {orgName: this.deletionData.orgToDeleteBusinessId}) :
                    this.labels.get('request.body.text', '', {orgName: this.deletionData.businessId});

    return (
      <Dialog
        id="delete-organization-dialog"
        className="delete-organization-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}>

        <div className="delete-confirmation">{bodyText}</div>

        {this.renderCommentSection()}

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'deleteOrganizationDialog',
  }
)(DeleteOrganizationDialog);

DeleteOrganizationDialog.defaultProps = {
};
DeleteOrganizationDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import * as assetService from '../../services/Assets/assetService.js';
import * as ValueTypeConverter from "../../components/Common/Helpers/valueTypeConverter";
import {createLabelHelper} from "../../../../infrastructure/js/utils/labelHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ASSET_PAGE_ACTIVITY_LOG, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let assetData = getState().assetPage.getIn(['data', 'asset']);
    return assetService.getAssetActivityLog(assetData?.get('id'), query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let labels = createLabelHelper('mat.activitydetails.');
  let convertedRows = rows.map((row) => {
    row.m_Time = DateTimeHelper.FormatDateObjectToRelativeTime(row.time, serverTimeSeconds);
    if(row.extendedDetails){
      row.isRowMaster = row.extendedDetails.length > 0;
      row.extendedDetails.map((detail) => {
        detail.title = detail.keyIsReadyForDisplay ? detail.key : labels.get(detail.key);
        detail.value = ValueTypeConverter.getValueByType(detail.value, detail.valuetype);
      });
    }

    return row;
  });

  return convertedRows;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as analyticModeActions } from '../../../../actions/SchedulerPage/analyticModeActions';
import { jsxActions as analyticModeSettingsDialogActions } from '../../../../actions/SchedulerPage/analyticModeSettingsDialogActions';
import SchedulerAnalyticModePanel from './SchedulerAnalyticModePanel';

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            ...bindActionCreators(analyticModeActions, dispatch),
            analyticModeSettingsDialogActions: bindActionCreators(
                analyticModeSettingsDialogActions,
                dispatch
            ),
        },
    };
}

let SchedulerAnalyticModeContainer = connect((state) => {
    return {
        sData: state.predefinedList.get('schedulerAnalyticMode'),
        sAnalyticModeSettingsDialog: state.dialogs.get('analyticModeSettingsDialog'),
    };
}, mapDispatchToProps)(SchedulerAnalyticModePanel);

export default SchedulerAnalyticModeContainer;

import React from 'react';

import {reduxForm} from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';

require('./attachmentGrid.scss');

class AttachmentGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'attachmentId', filterName: 'attachmentId'},
    {fieldName: 'm_createdDate', filterName: 'createdDate', getOptions: false},
    {fieldName: 'm_size', filterName: 'fileSize', getOptions: false},
    {fieldName: 'isDeleted', filterName: 'isDeleted', getOptions: false},
    {fieldName: 'targetId', filterName: 'targetId', getOptions: false},
    {fieldName: 'targetType', filterName: 'targetType', getOptions: false},
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.assetpage.attachment.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let columnsConfig = [
      {
        fieldName: 'originalFileName',
        title: this.labels.get('columns.file.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'm_size',
        title: this.labels.get('columns.size.title'),
        filterName: 'fileSize',
      },
      {
        fieldName: 'status',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.isDeleted ? this.labels.get('columns.status.deleted') : this.labels.get('columns.status.ready');
          }
        }
      },
      {
        fieldName: 'category',
        title: this.labels.get('columns.category.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'createdByUser',
        title: this.labels.get('columns.user.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        },
        width: 220
      },
      {
        fieldName: 'm_createdDate',
        title: this.labels.get('columns.date.title'),
        filterType: filterTypes.NONE,
        filterName: 'createdDate',
      },
    ];
    return columnsConfig;
  };

  render() {
    return (
      <div className="attachments-grid">
        <Grid gridName={gridsNames.ASSET_PAGE_ATTACHMENT}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'attachmentGrid'
  }
)(AttachmentGrid);

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as smartSelectionPageActions }    from '../../actions/SmartSelection/smartSelectionPageActions';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';

import { jsxActions as smartSelectionGridActions }    from '../../actions/SmartSelection/smartSelectionGridActions';
import { jsxActions as smartSelectionHeaderActions }    from '../../actions/SmartSelection/smartSelectionHeaderActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as addToPickListDialogActions }      from '../../actions/Dialogs/AddToPickListDialog/addToPickListDialogActions';
import SmartSelectionPage from '../../components/PredefinedList/SmartSelectionPage/smartSelectionPage';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(smartSelectionPageActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      addToPickListDialogActions:    bindActionCreators(addToPickListDialogActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      smartSelectionGridActions: bindActionCreators(smartSelectionGridActions, dispatch),
      smartSelectionHeaderActions: bindActionCreators(smartSelectionHeaderActions, dispatch),
      exportActions: bindActionCreators(exportActions, dispatch)
    }
  }
}

let SmartSelectionPageContainer = connect(
  (state) => {
    let smartSelectionGrid = state.grid.get(gridsNames.PREDEFINED_LIST_SMART_SELECTION) ? state.grid.get(gridsNames.PREDEFINED_LIST_SMART_SELECTION) : null;
    return {
      sSmartSelectionGrid:  smartSelectionGrid,
      sHasRows: smartSelectionGrid && smartSelectionGrid.get('rows') ? smartSelectionGrid.get('rows').size > 0 : false,
      sSelectedRowsData: smartSelectionGrid ? smartSelectionGrid.get('selectedRowsData') : List(),
      sHeaderData: state.predefinedList.get('smartSelectionHeader'),
      sAddToPickListDialogData: state.dialogs.get('addToPickListDialog'),
    };
  },
  mapDispatchToProps
)(SmartSelectionPage);


export default SmartSelectionPageContainer;

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../../../MessageDialog/messageDialogActions.js';
import * as orgsService from '../../../../services/Administration/organizationsService.js';
import { jsxActions as demoOrganizationsPageApi } from '../demoOrganizationsPageActions.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_DEMO_ORGANIZATION_DIALOG_SHOW: 'CREATE_DEMO_ORGANIZATION_DIALOG_SHOW',
  CREATE_DEMO_ORGANIZATION_DIALOG_HIDE: 'CREATE_DEMO_ORGANIZATION_DIALOG_HIDE',
  CREATE_DEMO_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS: 'CREATE_DEMO_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS',
  CREATE_DEMO_ORGANIZATION_STATUS_SUBMIT_FINISHED: 'CREATE_DEMO_ORGANIZATION_STATUS_SUBMIT_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function (payload) {
    return { type: actionTypes.CREATE_DEMO_ORGANIZATION_DIALOG_SHOW, payload };
  },
  hide: function (payload) {
    return { type: actionTypes.CREATE_DEMO_ORGANIZATION_DIALOG_HIDE, payload };
  },
  submitInProgress: function (payload) {
    return { type: actionTypes.CREATE_DEMO_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS, payload };
  },
  submitFinished: function (payload) {
    return { type: actionTypes.CREATE_DEMO_ORGANIZATION_STATUS_SUBMIT_FINISHED, payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function () {
  return function (dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function (data, reloadParentComponent) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function (dispatch, getState) {
  return function () {
    dispatch(actions.hide());
  };
};

api.show = function (dispatch, getState) {
  return function () {
    dispatch(actions.show());
  };
};

api.submit = function (dispatch, getState) {
  return function (data, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    orgsService.createDemoOrganization(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Create demo organization failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      return { success: true };
      
    }).catch((error) => {
      dispatch(actions.submitFinished());
      console.error('Create demo organization failed', error);
      return { success: false };
    });
  };
};

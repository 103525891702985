import * as schedulerService from '../../services/Scheduler/schedulerService';
import { gridsNames } from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MaterialHelper from '../../utils/materialHelper';
import { navigationStates } from '../../enums/navigationStates';
import { enumTypes, getEnumValue, getLookupOptions } from '../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import ToolTypeHelper from '../../utils/toolTypeHelper';
import { api as schedulerPageActions } from '../../actions/SchedulerPage/schedulerPageActions';
import { getQuantityInfo } from '../../components/Common/Helpers/SchedulerHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query, newAbortController, runSideEffect) {
  return function (dispatch, getState) {
    return schedulerService.generateOperations().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Scheduler operations Failed');
        return [];
      }
      query.pageSize = PermissionManager.getOrgPreferences().schedulerTasksPageSize || 1000;
      if (runSideEffect) {
        schedulerPageActions.fetchData(dispatch, getState)(query, runSideEffect);
      }

      return schedulerService.fetchOperations(query);
    });
  };
}

function _convertToRowData(rows) {
  let labels = createLabelHelper('mat.scheduler.operations.grid.moreInfo.');
  let items = rows.map((obj, index) => {
    //Note: obj.id must be used. Inner obj.task.id is NOT in use.
    let {
      plannedStartTime,
      plannedEndTime,
      plannedDuration,
      plannedResource,
      plannedTool,
      actualStartTime,
      actualEndTime,
      actualDuration,
      actualResource,
      actualTool,
      id,
      locked,
      split,
      valid,
      operationStatus,
      validationMessages,
      startTime,
      endTime,
      splitAssignments,
      toolCapacity,
    } = obj;

    let data = Object.assign({}, obj.task, {
      plannedStartTime,
      plannedEndTime,
      plannedDuration,
      plannedResource,
      plannedTool,
      actualStartTime,
      actualEndTime,
      actualDuration,
      actualResource,
      actualTool,
      id,
      locked,
      split,
      valid,
      operationStatus,
      validationMessages,
      startTime,
      endTime,
      splitAssignments,
      toolCapacity,
    });

    // Note: this is a work around - Server does not return an objectType for the Task
    data.objectType = getEnumValue(enumTypes.OBJECT_TYPE)('TASK');
    let plannedResourceData = data.plannedResource ? data.plannedResource : null;
    let actualResourceData = data.actualResource ? data.actualResource : null;

    data.isRowMaster = true;

    //------------FOR DEBUG ONLY !----------------
    // data.plannedTool = {businessId: 'aaaa-bbbb-ccccc-ddddddd'};
    // data.quantity = 2;
    // data.toolCapacity = 30;
    //----------------------------

    data.extendedDetails = {
      rows: [
        //1st row -------
        {
          title: labels.get('plannedStartTime'),
          value:
            data.plannedStartTime && data.plannedStartTime.epochDateTime
              ? DateTimeHelper.DateTimeFormat(data.plannedStartTime.epochDateTime * 1000)
              : ' ',
        },
        {
          title: labels.get('plannedEndTime'),
          value:
            data.plannedEndTime && data.plannedEndTime.epochDateTime ? DateTimeHelper.DateTimeFormat(data.plannedEndTime.epochDateTime * 1000) : ' ',
        },
        {
          title: labels.get('plannedResource'),
          value: plannedResourceData ? plannedResourceData.name : '',
          isLink: true,
          id: plannedResourceData ? plannedResourceData.id : 0,
          type: navigationStates.STATION,
        },
        {
          title: labels.get('plannedToolId'),
          value: data.plannedTool ? data.plannedTool.businessId + ' ' + getQuantityInfo(data.quantity, data.toolCapacity) : '',
        },

        //2nd row -------
        {
          title: labels.get('startTime'),
          value:
            data.actualStartTime && data.actualStartTime.epochDateTime
              ? DateTimeHelper.DateTimeFormat(data.actualStartTime.epochDateTime * 1000)
              : ' ',
        },
        {
          title: labels.get('endTime'),
          value:
            data.actualEndTime && data.actualEndTime.epochDateTime ? DateTimeHelper.DateTimeFormat(data.actualEndTime.epochDateTime * 1000) : ' ',
        },
        {
          title: labels.get('resource'),
          value: actualResourceData ? actualResourceData.name : '',
          isLink: true,
          id: actualResourceData ? actualResourceData.id : 0,
          type: navigationStates.STATION,
        },
        { title: labels.get('toolId'), value: data.actualTool ? data.actualTool.businessId : '' },

        //3rd row -------
        {
          title: labels.get('plannedDuration'),
          value: data.plannedDuration ? DateTimeHelper.ConvertMinutesToHoursMinutes(data.plannedDuration) : ' ',
        },
        { title: labels.get('kitType'), value: data.kitType ? data.kitType.name : ' ' },
        { title: labels.get('project'), value: data.project ? data.project.name : ' ' },
        {
          title: labels.get('toolRequirement'),
          value: data.toolRequirement ?? '',
        },

        //4th row -------
        {
          title: labels.get('duration'),
          value: data.actualDuration ? DateTimeHelper.ConvertMinutesToHoursMinutes(data.actualDuration) : '',
        },
        {
          title: labels.get('material'),
          value: data.material ? MaterialHelper.getMaterialFullLabel(data.material.name, data.material.businessId) : ' ',
        },
        { title: labels.get('splitEnabled'), value: data.splittable ? 'True' : 'False' },
        { title: labels.get('recipe'), value: data.groupName ? data.groupName : ' ' },

        //5th row -------
        { title: labels.get('kitTypeDescription'), value: data.kitTypeDescription || ' ' },
      ],
      splitAssignments: data.splitAssignments,
    };

    data.m_priority = getLookupOptions(enumTypes.PRIORITY_TYPE).find((x) => x.value === data.priority);
    return data;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes,
  api = gridActions.apiActions,
  jsxActions = gridActions.jsxActions;

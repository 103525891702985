import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActivityLogDialog from '../../components/ActivityLogDialog/activityLogDialog';
import { jsxActions as activityLogDialogActions } from '../../actions/ActivityLogDialog/activityLogDialogActions';
import { jsxActions as activityLogGridActions } from '../../actions/ActivityLogDialog/activityLogDialogGridActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import {gridsNames} from '../../enums/gridsNames.js';

function mapDispatchToProps(dispatch) {
  return {
    actions:         bindActionCreators(activityLogDialogActions, dispatch),
    activityLogGridActions:     bindActionCreators(activityLogGridActions, dispatch),
    exportActions:      bindActionCreators(exportActions, dispatch)
  }
}

export default connect(
  (state)=>{
    return {
      show : state.activityLogDialog.get( 'show'),
      // title : state.activityLogDialog.get( 'title'),
      data : state.activityLogDialog.get( 'data'),
      gridData : state.grid.get(gridsNames.BO_ACTIVITY_LOG)
    }
  },
  mapDispatchToProps
)(ActivityLogDialog);


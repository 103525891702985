import React from 'react';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';
import DashboardGrid from './DashboardGrid/dashboardGrid';

require('./dashboardTab.scss');

class DashboardTab extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const actions = {
      ...this.props.actions.dashboardTabGridActions,
      ...this.props.actions.fetchEntitiesActions,
    };

    return (
      <div className="dashboard-tab">
        <DashboardGrid actions={actions} />
      </div>
    );
  }
}

export default reduxForm({
  form: 'orgsDashboard',
})(DashboardTab);

DashboardTab.propTypes = {
  actions: PropTypes.object.isRequired,
  sDashboardGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
};

DashboardTab.defaultProps = {
  sDashboardGrid: {},
};

import Network from 'infrastructure/js/modules/network';
import { api as locationPageApi } from '../LocationPage/locationPageActions.js';
import * as assetsService from '../../services/Assets/assetService';
import * as locationsService from '../../services/Locations/locationsService';
import { FetchEntitiesFilters } from '../../enums/fetchEntitiesFilters';
import { EntityPropertyTypes } from '../../enums/entityPropertyTypes';
import uniqBy from 'lodash-es/uniqBy';
import { enumTypes, getEnumValue } from '../../utils/enumHelper';
import MaterialHelper from '../../utils/materialHelper';
import { searchWorkOrders } from '../../services/WorkOrders/workOrdersService';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
//const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchEntities = function (fetchConfig, callback) {
  return function (dispatch, getState) {
    api.fetchEntities(dispatch, getState)(fetchConfig, callback);
  };
};
jsxActions.fetchEntitiesWithRelatedAssets = function (fetchConfig, callback) {
  return function (dispatch, getState) {
    console.log('fetchEntitiesWithRelatedAssets');
    api.fetchEntitiesWithRelatedAssets(dispatch, getState)(fetchConfig, callback);
  };
};
/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};
api.fetchEntities = function (dispatch, getState) {
  return function (fetchConfig, callback, promtToRefineFetch = false, prefetchHandler = null) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();

    let method = _getFetchMethod(fetchConfig);
    let data = _getFetchData(fetchConfig, locationId);
    prefetchHandler?.(data);

    method(data).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('fetchEntities() failed', response);
        if (callback) {
          callback({ options: [], callbackId: fetchConfig.callbackId, last: true });
        }
        return { success: false };
      }

      if (callback) {
        let res = response.dataList.map((obj) => {
          let label = _getLabelByEntityType(fetchConfig.entityType, obj);
          return { value: obj.id, label: label, data: obj };
        });

        if (fetchConfig.entityType === EntityPropertyTypes.MATERIAL_TYPE_NAME) {
          res = uniqBy(res, 'label');
        }

        if (promtToRefineFetch && response.queryResultInfo && !response.queryResultInfo.last) {
          //res.unshift({
          res.push({
            value: 'refine',
            label: fetchConfig.input ? fetchConfig.input : 'refine',
            data: {},
            isDisabled: true,
            promtToRefineFetch: true,
          });
        }

        let fetchedData = {
          options: res,
          callbackId: fetchConfig.callbackId,
          last: response.queryResultInfo && response.queryResultInfo.last,
        };

        callback(fetchedData);
      }
    });
  };
};
api.fetchEntitiesWithRelatedAssets = function (dispatch, getState) {
  return function (fetchConfig, callback, promtToRefineFetch = false) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let method = _getFetchMethodWithRelatedAsstets(fetchConfig);
    let data = _getFetchData(fetchConfig, locationId);

    method(data).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('fetchEntities() failed', response);
        if (callback) {
          callback({ options: [], callbackId: fetchConfig.callbackId, last: true });
        }
        return { success: false };
      }

      if (callback) {
        let res = response.dataList.map((obj) => {
          let label = _getLabelByEntityType(fetchConfig.entityType, obj);
          return { value: obj.id, label: label, data: obj };
        });

        if (promtToRefineFetch && response.queryResultInfo && !response.queryResultInfo.last) {
          //res.unshift({
          res.push({
            value: 'refine',
            label: fetchConfig.input ? fetchConfig.input : 'refine',
            data: {},
            isDisabled: true,
            promtToRefineFetch: true,
          });
        }

        let fetchedData = {
          options: res,
          callbackId: fetchConfig.callbackId,
          last: response.queryResultInfo && response.queryResultInfo.last,
        };

        callback(fetchedData);
      }
    });
  };
};

//////////////////////////////////////////////////////////////////////

const MAX_PAGE_SIZE = 100;

function _getFetchMethod(fetchConfig) {
  //TODO: L uses the old API 'fetchLocationsOrStations', should be replaced with a new one
  return fetchConfig.entityType === EntityPropertyTypes.LOCATION_NAME ||
    fetchConfig.entityType === EntityPropertyTypes.STATION_NAME
    ? locationsService.fetchLocationsOrStations
    : assetsService.fetchEntities;
}
function _getFetchMethodWithRelatedAsstets() {
  return searchWorkOrders;
}
function _getFetchData(fetchConfig, locationId) {
  //TODO: L data for the old API 'fetchLocationsOrStations', should be replaced with a new API for location/station
  if (
    fetchConfig.entityType === EntityPropertyTypes.LOCATION_NAME ||
    fetchConfig.entityType === EntityPropertyTypes.STATION_NAME
  ) {
    return {
      filters: [{ filterName: 'locationNameSearch', values: [fetchConfig.input] }],
      pageSize: MAX_PAGE_SIZE,
      isStation: fetchConfig.entityType === EntityPropertyTypes.STATION_NAME,
    };
  }

  // let data = _createFetchData(fetchConfig.entityType, fetchConfig.entityFilter, locationId);
  let data = _createFetchData(fetchConfig.entityFilter, locationId, fetchConfig.entityType);

  data.maxPageSize = fetchConfig.maxPageSize || MAX_PAGE_SIZE;
  if (fetchConfig.entityType === EntityPropertyTypes.MATERIAL_TYPE_NAME) {
    if (fetchConfig.input) {
      data.name = fetchConfig.input;
    }
  } else if (
    fetchConfig.entityType === EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID ||
    fetchConfig.entityType === EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID_PICK_LIST
  ) {
    if (fetchConfig.input) {
      data.name = fetchConfig.input;
      data.businessId = fetchConfig.input;
    }
  } else if (fetchConfig.entityType === EntityPropertyTypes.TAG_BUSINESS_ID) {
    data.tid = fetchConfig.input;
  } else if (fetchConfig.entityType === EntityPropertyTypes.SCHEDULER_TASK_NAME) {
    data.name = fetchConfig.input;
    //data.maxPageSize = 200;
  } else if (fetchConfig.entityType === EntityPropertyTypes.LOT_NAME) {
    data.name = fetchConfig.input;
  } else if (fetchConfig.entityType === EntityPropertyTypes.USER_NAME) {
    data.userName = fetchConfig.input;
  } else if (fetchConfig.entityType === EntityPropertyTypes.USER_FIRST_NAME) {
    data.userFirstName = fetchConfig.input;
  } else if (fetchConfig.entityType === EntityPropertyTypes.USER_LAST_NAME) {
    data.userLastName = fetchConfig.input;
  } else if (fetchConfig.entityType === EntityPropertyTypes.REPORTED_CUT) {
    // Do Nothing
  } else {
    data.businessId = fetchConfig.input;
  }
  data.entityType = fetchConfig.entityType;
  let searchBy = _getSearchByData(fetchConfig);
  if (searchBy) {
    data.searchBy = searchBy;
  }

  return data;
}

//////////////////////////////////////////////////////////////////////////////////////////

function _createFetchData(filters, locationId, entityType) {
  let data = {};

  if (filters) {
    filters.forEach((filter) => {
      _setDataByFilter(data, filter, locationId, entityType);
    });
  }
  return data;
}

function _setDataByFilter(data, filter, locationId, entityType) {
  switch (filter) {
    case FetchEntitiesFilters.ALL:
      return data;
    case FetchEntitiesFilters.ACTIVE:
      data.active = true;
      break;
    case FetchEntitiesFilters.ALERTED:
      data.alerted = true;
      break;
    case FetchEntitiesFilters.ARCHIVED:
      data.active = false;
      break;
    case FetchEntitiesFilters.COMPLETED:
      data.completed = true;
      break;
    case FetchEntitiesFilters.IN_PROGRESS:
      data.isInProgress = true;
      break;
    case FetchEntitiesFilters.NOT_IN_PROGRESS:
      if (entityType === EntityPropertyTypes.PART_BUSINESS_ID) {
        //TODO: L currently supported only for parts (workaround)
        data.isInProgress = false;
      }
      break;
    case FetchEntitiesFilters.IN_PASSPORT_STATUS:
      if (entityType === EntityPropertyTypes.ROLL_BUSINESS_ID) {
        //TODO: L currently supported only for rolls (workaround)
        data.isInPassportStatus = true;
      }
      break;
    case FetchEntitiesFilters.NOT_IN_PASSPORT_STATUS:
      if (entityType === EntityPropertyTypes.ROLL_BUSINESS_ID) {
        //TODO: L currently supported only for rolls (workaround)
        data.isInPassportStatus = false;
      }
      break;
    case FetchEntitiesFilters.ON_LOCATION:
      data.searchAtCurrentLocation = true;
      data.locationId = locationId;
      break;
    case FetchEntitiesFilters.EXCEPT_LOCATION:
      data.searchAtCurrentLocation = false;
      data.locationId = locationId;
      break;
    case FetchEntitiesFilters.HAS_ATTACHED_ASSET:
      data.hasAttachedAsset = true;
      break;
    case FetchEntitiesFilters.NO_ATTACHED_ASSET:
      if (
        entityType !== EntityPropertyTypes.GROUP_BUSINESS_ID &&
        entityType !== EntityPropertyTypes.SPOOL_BUSINESS_ID
      ) {
        //TODO: L not supported for groups and spools
        data.hasAttachedAsset = false;
      }
      break;
    case FetchEntitiesFilters.CURED:
      data.isCured = true;
      break;
    case FetchEntitiesFilters.NOT_CURED:
      data.isCured = false;
      break;

    case FetchEntitiesFilters.NOT_CONTAINED:
      if (entityType !== EntityPropertyTypes.PART_BUSINESS_ID) {
        //TODO: L currently supported only for kits (workaround)
        data.isContained = false;
      }
      break;
    case FetchEntitiesFilters.NOT_RELATED:
      if (entityType === EntityPropertyTypes.KIT_BUSINESS_ID) {
        //TODO: L currently supported only for kits (workaround)
        data.isRelatedToKit = false;
      }
      break;
    case FetchEntitiesFilters.NO_KIT_PART_TYPE:
      if (entityType === EntityPropertyTypes.WO_BUSINESS_ID) {
        //TODO: L currently supported only for kits (workaround)
        data.isEmptyKitTypeOrPartType = true;
      }
      break;
    case FetchEntitiesFilters.ALL_TASKS:
      data.tasksStatuses = null;
      data.locationId = null;
      break;
    case FetchEntitiesFilters.SCHEDULED_TASKS:
    case FetchEntitiesFilters.STARTED_TASKS:
    case FetchEntitiesFilters.COMPLETED_TASKS:
    case FetchEntitiesFilters.UNSCHEDULED_TASKS:
      data.tasksStatuses = _configTaskStatusByFilter(data, filter);
      break;
    case FetchEntitiesFilters.TASKS_ON_LOCATION:
      data.locationId = locationId;
      break;
    case FetchEntitiesFilters.USED_FOR_TASK:
      data.isUsedForTask = true;
      break;
    case FetchEntitiesFilters.ASSET_ACTIVE:
      data.isActiveAsset = true;
      break;
    case FetchEntitiesFilters.ASSET_ALERTED:
      data.isActiveAsset = true;
      data.isAlertedAsset = true;
      break;
    case FetchEntitiesFilters.ASSET_ARCHIVED:
      data.isActiveAsset = false;
      break;
    case FetchEntitiesFilters.ASSET_TYPE_ROLL:
      data.assetType = 'ROLL';
      break;
    case FetchEntitiesFilters.ASSET_TYPE_SPOOL:
      data.assetType = 'SPOOL';
      break;
    case FetchEntitiesFilters.ASSET_IN_PASSPORT_STATUS:
      data.isAssetInPassportStatus = true;
      break;
    case FetchEntitiesFilters.ASSET_NOT_IN_PASSPORT_STATUS:
      data.isAssetInPassportStatus = false;
      break;

    default:
      console.error('Unknown fetch entity filter: ' + filter);
  }

  return data;
}

function _configTaskStatusByFilter(data, filter) {
  if (!data.tasksStatuses) {
    data.tasksStatuses = [];
  }
  switch (filter) {
    case FetchEntitiesFilters.SCHEDULED_TASKS:
      return [...data.tasksStatuses, getEnumValue(enumTypes.TASK_STATUS)('SCHEDULED')];
    case FetchEntitiesFilters.STARTED_TASKS:
      return [...data.tasksStatuses, getEnumValue(enumTypes.TASK_STATUS)('STARTED')];
    case FetchEntitiesFilters.COMPLETED_TASKS:
      return [...data.tasksStatuses, getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')];
    case FetchEntitiesFilters.UNSCHEDULED_TASKS:
      return [...data.tasksStatuses, getEnumValue(enumTypes.TASK_STATUS)('UNASSIGNED')];
  }
  return null;
}

/* function _setTaskStatusByFilter(data, filter) {
  if (!data.tasksStatuses) {
    data.tasksStatuses = [];
  }
  switch (filter) {
    case FetchEntitiesFilters.SCHEDULED_TASKS:
      return [...data.tasksStatuses, getEnumValue(enumTypes.TASK_STATUS)('SCHEDULED')];
    case FetchEntitiesFilters.STARTED_TASKS:
      data.tasksStatuses.push([getEnumValue(enumTypes.TASK_STATUS)('STARTED')]);
      break;
    case FetchEntitiesFilters.COMPLETED_TASKS:
      data.tasksStatuses.push([getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')]);
      break;
    case FetchEntitiesFilters.UNSCHEDULED_TASKS:
      data.tasksStatuses.push([getEnumValue(enumTypes.TASK_STATUS)('UNASSIGNED')]);
      break;
  }
  return data.tasksStatuses;
} */

function _getSearchByData(fetchConfig) {
  if (fetchConfig && fetchConfig.searchBy) {
    switch (fetchConfig.entityType) {
      //add support for entity types as needed
      case EntityPropertyTypes.ROLL_BUSINESS_ID:
        return fetchConfig.searchBy.roll;
      case EntityPropertyTypes.KIT_BUSINESS_ID:
        return fetchConfig.searchBy.kit;
      case EntityPropertyTypes.WO_BUSINESS_ID:
        return fetchConfig.searchBy.wo;
      case EntityPropertyTypes.KIT_TYPE_BUSINESS_ID:
        return fetchConfig.searchBy.kitType;
      case EntityPropertyTypes.REPORTED_CUT:
        return fetchConfig.searchBy.cut;
    }
  }
  return null;
}

function _getLabelByEntityType(entityType, obj) {
  if (
    entityType === EntityPropertyTypes.STATION_NAME ||
    entityType === EntityPropertyTypes.LOCATION_NAME ||
    entityType === EntityPropertyTypes.LOT_NAME
  ) {
    return obj.name;
  } else if (entityType === EntityPropertyTypes.MATERIAL_TYPE_NAME) {
    return obj.materialName ? obj.materialName : '-none-';
  } else if (
    entityType === EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID ||
    entityType === EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID_PICK_LIST
  ) {
    return MaterialHelper.getMaterialFullLabel(obj.materialName, obj.businessId);
  } else if (entityType === EntityPropertyTypes.TAG_BUSINESS_ID) {
    return obj.tagKey;
  } else if (entityType === EntityPropertyTypes.SCHEDULER_TASK_NAME) {
    return obj.task ? obj.task.name : '';
  } else if (entityType === EntityPropertyTypes.USER_NAME) {
    return obj.username || '';
  } else if (entityType === EntityPropertyTypes.USER_FIRST_NAME) {
    return obj.firstName || '';
  } else if (entityType === EntityPropertyTypes.USER_LAST_NAME) {
    return obj.lastName || '';
  }
  // else if (entityType === EntityPropertyTypes.PICK_LIST_BUSINESS_ID) {
  //   return obj.pickListId || '';
  // }

  return obj.businessId;
}

// function _createFetchData(entityType, boEntityFilter, locationId) {
//   let data = {};
//
//   if (boEntityFilter === BO_EntityFilters.ALL_TASKS) {
//     data.tasksStatuses = null;
//     data.locationId = null;
//     return data;
//   }
//   else if (boEntityFilter === BO_EntityFilters.SCHEDULED_TASKS_ON_LOCATION) {
//     data.tasksStatuses = [getEnumValue(enumTypes.TASK_STATUS)('SCHEDULED')];
//     data.locationId = locationId;
//     return data;
//   }
//   else if(boEntityFilter === BO_EntityFilters.SCHEDULED_AND_EXECUTED_TASKS_ON_LOCATION){
//     data.tasksStatuses = [getEnumValue(enumTypes.TASK_STATUS)('SCHEDULED'), getEnumValue(enumTypes.TASK_STATUS)('STARTED'), getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')];
//     data.locationId = locationId;
//     return data;
//   }
//   else if(boEntityFilter === BO_EntityFilters.UNASSIGNED){
//     data.tasksStatuses = [getEnumValue(enumTypes.TASK_STATUS)('UNASSIGNED')];
//     return data;
//   }
//
//   if (boEntityFilter === BO_EntityFilters.ALERTED) {
//     data.alerted = true;
//     data.active = true;
//   }
//   if (boEntityFilter === BO_EntityFilters.COMPLETED) {
//     data.completed = true;
//   }
//   else if (boEntityFilter === BO_EntityFilters.ARCHIVED) {
//     data.active = false;
//   }
//   else if (boEntityFilter === BO_EntityFilters.ALL) {
//     //nothing to add
//   }
//   else if (boEntityFilter === BO_EntityFilters.HAS_ATTACHED_ASSET) {
//     data.hasAttachedAsset = true;
//     data.active = true;
//   }
//   else if (boEntityFilter === BO_EntityFilters.NO_ATTACHED_ASSET) {
//     data.hasAttachedAsset = false;
//     data.active = true;
//   }
//   else if (boEntityFilter === BO_EntityFilters.CURED) {
//     data.isCured = true;
//     data.active = true;
//   }
//   else if (boEntityFilter === BO_EntityFilters.NOT_CURED) {
//     data.isCured = false;
//     data.active = true;
//   }
//   else if (boEntityFilter === BO_EntityFilters.ON_LOCATION_NOT_CURED) {
//     data.isCured = false;
//     data.active = true;
//     data.searchAtCurrentLocation = true;
//     data.locationId = locationId;
//   }
//   else if (boEntityFilter === BO_EntityFilters.ALERTED_NOT_CURED) {
//     data.isCured = false;
//     data.active = true;
//     data.alerted = true;
//   }
//   else if (boEntityFilter === BO_EntityFilters.NOT_CONTAINED_NOT_RELATED_NO_ATTACHED_ASSET) {
//     data.active = true;
//     data.hasAttachedAsset = false;
//     data.isContained = false;
//     data.isRelatedToKit = false;
//   }
//
//   else {  //NOTE: BO_EntityFilters.ACTIVE is default for all other BO_EntityFilters
//     data.active = true;
//   }
//
//   if (boEntityFilter === BO_EntityFilters.ON_LOCATION) {
//     data.searchAtCurrentLocation = true;
//     data.locationId = locationId;
//   }
//   else if (boEntityFilter === BO_EntityFilters.EXCEPT_LOCATION) {
//     data.searchAtCurrentLocation = false;
//     data.locationId = locationId;
//   }
//
//   return data;
// }

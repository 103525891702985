import { api as fetchEntitiesApi } from '../FetchEntities/fetchEntitiesActions.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


jsxActions.fetchEntities = function (fetchConfig, callback) {
  return function (dispatch, getState) {
    fetchEntitiesApi.fetchEntities(dispatch, getState)(fetchConfig, callback);
  };
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};










import { api as activateAssetsDialogApi } from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions.js';
import { api as archiveAssetsDialogApi } from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions.js';
import { api as moveAssetsDialogApi } from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions.js';
import { api as bringAssetsDialogApi } from '../../actions/Dialogs/BringAssetsDialog/bringAssetsDialogActions.js';
import { api as shelfLifeUpdateDialogApi } from '../../actions/Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialogActions.js';
import { api as editAssetAttributesDialogApi } from '../Dialogs/EditAssetAttributesDialog/editAssetAttributesDialogActions.js';
import { api as importAttributesUpdateWizardActionsApi } from '../Wizards/ImportAttributesUpdateWizard/importAttributesUpdateWizardActions.js';
import { api as editWoAttributesDialogApi } from '../Dialogs/EditWoAttributesDialog/editWoAttributesDialogActions.js';
import { api as editTaskAttributesDialogApi } from '../Dialogs/EditTaskAttributesDialog/editTaskAttributesDialogActions.js';
import { api as editWorkOrdersStatusDialogApi } from '../Dialogs/EditWorkOrdersStatusDialog/editWorkOrdersStatusDialogActions.js';
import { api as editAssetsStatusDialogApi } from '../Dialogs/EditAssetsStatusDialog/editAssetsStatusDialogActions.js';
import { api as reportCutDialogApi } from '../../actions/LocationPage/CuttingView/reportCutDialogActions.js';
import { api as reportMaintenanceDialogApi } from '../Dialogs/ReportMaintenanceDialog/reportMaintenanceDialogActions';
import { api as createRollDialogApi } from '../../actions/LocationPage/AssetsView/createRollActions.js';
import { api as createSpoolDialogApi } from '../../actions/LocationPage/AssetsView/createSpoolActions.js';
import { api as addToGroupDialogApi } from '../../actions/LocationPage/AssetsView/addToGroupActions.js';
import { api as createSubRollsDialogApi } from '../../actions/LocationPage/CuttingView/createSubRollsDialogActions.js';
import { api as createAttachmentDialogApi } from '../../actions/Dialogs/createAttachmentDialog/createAttachmentDialogActions';
import { api as editAttachmentDialogApi } from '../../actions/Dialogs/editAttachmentDialog/editAttachmentDialogActions';
import { api as changePasswordDialogApi } from '../Dialogs/changePasswordDialogActions.js';
import { api as createOrganizationDialogApi } from '../../actions/Administration/OrganizationsPage/Dialogs/createOrganizationDialogActions.js';
import { api as deleteOrganizationDialogApi } from '../../actions/Administration/OrganizationsPage/Dialogs/deleteOrganizationDialogActions.js';
import { api as createDemoOrganizationDialogApi } from '../../actions/Administration/OrganizationsPage/Dialogs/createDemoOrganizationDialogActions.js';
import { api as deleteDemoOrganizationDialogApi } from '../../actions/Administration/OrganizationsPage/Dialogs/deleteDemoOrganizationDialogActions.js';
import { api as importFilesWizardActionsApi } from '../Wizards/ImportFiles/importFilesWizardActions';
import { api as importAssetsViaBarcodeWizardActionsApi } from '../Wizards/ImportAssetsViaBarcodeWizard/importAssetsViaBarcodeWizardActions';
import { api as importNestsWizardActions } from '../Wizards/ImportNests/importNestsWizardActions.js';
import { api as importNestsWithRollWizardActions } from '../Wizards/ImportNestsWithRoll/importNestsWithRollWizardActions.js';
import { api as importNestsMultiRollsWizardActions } from '../Wizards/ImportNestsMultiRolls/importNestsMultiRollsWizardActions.js';
import { api as importNestsAnyRollsWizardActions } from '../Wizards/ImportNestsAnyRolls/importNestsAnyRollsWizardActions';
import { api as userDialogApi } from '../Dialogs/userManagementDialogActions';
import { api as archiveWorkOrderDialogApi } from '../WorkOrderPage/Dialogs/archiveWorkOrderDialogActions.js';
import { api as activateWorkOrderDialogApi } from '../WorkOrderPage/Dialogs/activateWorkOrderDialogActions.js';
import { api as userManagementChangeStatusApi } from '../Administration/UserManagementPage/userManagementChangeStatusActions';
import { api as exportApi } from '../Export/exportActions';
import { api as pickListApi } from '../PickListPage/pickListPageActions';
import { api as printLabelsApi } from '../PrintLabelsActions/printLabelsActions';
import { UserAvailableActionsTypes } from '../../enums/userAvailableActionsTypes';
import * as assetService from '../../services/Assets/assetService.js';
import userService from '../../services/UserManagement/UserService';
import { ImportTypes, ImportAcceptFileTypes } from '../../enums/importTypes';
import { api as printRfidTagsApi } from '../PrintRfidActions/printRfidActions';
import { api as printPdfLabelsDialogApi } from '../Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions';
import { api as createTaskDialogApi } from '../SchedulerPage/createTaskDialogActions';
import { api as cutAndKitDialogApi } from '../LocationPage/CuttingView/CutAndKitDialog/CutAndKitDialogActions.js';
import { uniq, isEmpty } from 'lodash-es';
import { api as createWorkOrderDialogApi } from '../Dialogs/CreateWorkOrderDialog/createWorkOrderDialogActions';
import * as shipmentSevice from '../../services/Shipments/shipmentsService';
import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import { api as locationPageApi } from '../LocationPage/locationPageActions.js';
import { api as addToPickListDialogApi } from '../Dialogs/AddToPickListDialog/addToPickListDialogActions';
import { api as afpOperationWizardApi } from '../Wizards/AfpOperationWizard/afpOperationWizardActions';
import { api as addReplaceSpoolsDialogApi } from '../../actions/Dialogs/AddReplaceSpoolsDialog/addReplaceSpoolsActions';
import { api as createToolDialogApi } from '../LocationPage/AssetsView/createToolActions';
import { api as createGroupDialogApi } from '../Dialogs/CreateGroupDialog/createGroupDialogActions';
import { api as assetsViewApi } from '../../actions/LocationPage/AssetsView/assetsViewActions.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


jsxActions.importItems = function (config) {
  return function (dispatch, getState) {
    importFilesWizardActionsApi.openImportFilesWizard(dispatch, getState)(config);
  };
};

jsxActions.onCreateRollClick = function (data) {
  return function (dispatch, getState) {
    createRollDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onCreateSpoolClick = function (data) {
  return function (dispatch, getState) {
    createSpoolDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onCreateGroupClick = function () {
  return function (dispatch, getState) {
    createGroupDialogApi.show(dispatch, getState)();
  };
};

jsxActions.onUngroupClick = function (data) {
  return function (dispatch, getState) {
    if (data && data.size > 0) {
      let selectedGroup = data.get(0);
      assetsViewApi.openUngroupConfirmationDialog(dispatch, getState)(selectedGroup);
    }
  };
};

jsxActions.onCreateToolClick = function () {
  return function (dispatch, getState) {
    createToolDialogApi.show(dispatch, getState)();
  };
};

jsxActions.onCreateUserClick = function () {
  return function (dispatch, getState) {
    let serviceAction = getState().system.get('featureFlags').ORGANIZATIONS
      ? userService.createSystemUser
      : userService.create;
    userDialogApi.show({}, serviceAction)(dispatch, getState);
  };
};

jsxActions.onCreateDemoOrganizationClick = function () {
  return function (dispatch, getState) {
    createDemoOrganizationDialogApi.show(dispatch, getState)();
  };
};

jsxActions.onDeleteDemoOrganizationClick = function (data) {
  return function (dispatch, getState) {
    deleteDemoOrganizationDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onCreateOrganizationClick = function () {
  return function (dispatch, getState) {
    createOrganizationDialogApi.show(dispatch, getState)();
  };
};

jsxActions.onEditOrganizationClick = function (data) {
  return function (dispatch, getState) {
    createOrganizationDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onDuplicateOrganizationClick = function (data) {
  return function (dispatch, getState) {
    createOrganizationDialogApi.show(dispatch, getState)(data, true);
  };
};

jsxActions.onRequestOrganizationDeletionClick = function (data) {
  return function (dispatch, getState) {
    deleteOrganizationDialogApi.showRequestDeletion(dispatch, getState)(data);
  };
};

jsxActions.onAcceptOrganizationDeletionClick = function (data) {
  return function (dispatch, getState) {
    deleteOrganizationDialogApi.showAcceptDeletion(dispatch, getState)(data);
  };
};

jsxActions.onRejectOrganizationDeletionClick = function (data) {
  return function (dispatch, getState) {
    deleteOrganizationDialogApi.showRejectDeletion(dispatch, getState)(data);
  };
};

jsxActions.onUpdateShelfLifeClick = function (data) {
  return function (dispatch, getState) {
    shelfLifeUpdateDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onActivateClick = function (data) {
  return function (dispatch, getState) {
    activateAssetsDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onUploadFileClicked = function (data) {
  return function (dispatch, getState) {
    createAttachmentDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemDownloadAttachmentClick = function (data) {
  return function (dispatch, getState) {
    createAttachmentDialogApi.downloadFile(dispatch, getState)(data);
  };
};
jsxActions.onMenuItemEditAttachmentClick = function (data) {
  return function (dispatch, getState) {
    editAttachmentDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemDeleteAttachmentClick = function (data) {
  return function (dispatch, getState) {
    createAttachmentDialogApi.deleteFile(dispatch, getState)(data);
  };
};

jsxActions.onMarkCompleteClick = function (data) {
  return function (dispatch, getState) {
    editWorkOrdersStatusDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMarkCanceledClick = function (data) {
  return function (dispatch, getState) {
    editWorkOrdersStatusDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onEditAssetsStatusClick = function (data) {
  return function (dispatch, getState) {
    editAssetsStatusDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onReopenClick = function (data) {
  return function (dispatch, getState) {
    editWorkOrdersStatusDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemArchiveClick = function (data) {
  return function (dispatch, getState) {
    archiveAssetsDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onExportSmartSelectionClick = function (data) {
  return function (dispatch, getState) {
    exportApi.exportSmartSelection(dispatch, getState)(data);
  };
};
jsxActions.onExportOnHandInventoryClick = function (data) {
  return function (dispatch, getState) {
    exportApi.exportOnHandInventory(dispatch, getState)(data);
  };
};
jsxActions.onAddToPickListClick = function (data) {
  return function (dispatch, getState) {
    // smartSelectionApi.addToPickList(dispatch, getState)(data.rows, data.messageBuilder);
    addToPickListDialogApi.show(dispatch, getState)(data);
  };
};
jsxActions.onExportPickListClick = function (data) {
  return function (dispatch, getState) {
    exportApi.exportPickListItems(dispatch, getState)(data);
  };
};

jsxActions.onExportTasksListClick = function (data) {
  return function (dispatch, getState) {
    exportApi.exportGridRows(dispatch, getState)(data);
  };
};
jsxActions.onCreateTaskClick = function () {
  return function (dispatch, getState) {
    createTaskDialogApi.show(dispatch, getState)();
  };
};

jsxActions.onDeleteTasksClick = function (data) {
  return function (dispatch, getState) {
    createTaskDialogApi.removeNonOperationalTasks(dispatch, getState)(data);
  };
};

jsxActions.onRemoveFromPickListClick = function (data) {
  return function (dispatch, getState) {
    pickListApi.removeFromPickList(dispatch, getState)(data);
  };
};

jsxActions.onImportAssetsViaBarcodeClick = function (data) {
  return function (dispatch, getState) {
    importAssetsViaBarcodeWizardActionsApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemImportNestsWithRollClick = function (data) {
  return function (dispatch, getState) {
    let config = {
      importTypes: [ImportTypes.IMPORT_NESTS_WITH_ROLL],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: data.parentComponent,
    };

    importNestsWithRollWizardActions.openImportNestsWithRollWizard(dispatch, getState)(config);
  };
};

jsxActions.onMenuItemImportNestMultiRollsClick = function (data) {
  return function (dispatch, getState) {
    let config = {
      importTypes: [ImportTypes.IMPORT_NESTS_MULTI_ROLLS],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: data.parentComponent,
    };

    let assets = data.preselectedAssets ? data.preselectedAssets.toJS() : [];

    let materialsCodes = assets.map((asset) => {
      return asset.material.businessId;
    });
    let duplicates = uniq(
      materialsCodes.filter((item, index) => materialsCodes.indexOf(item) != index)
    );
    if (!isEmpty(duplicates)) {
      importNestsMultiRollsWizardActions.showDuplicateMaterialsMessage(
        dispatch,
        getState
      )(duplicates);
    } else {
      importNestsMultiRollsWizardActions.openImportNestsMultiRollsWizard(dispatch, getState)(
        config,
        assets
      );
    }
  };
};

jsxActions.onMenuItemImportNestsAnyRollsClick = function (data) {
  return function (dispatch, getState) {
    let config = {
      // importTypes: [ImportTypes.IMPORT_NESTS_ANY_ROLLS],
      importTypes: [ImportTypes.IMPORT_NESTS_MULTI_ROLLS], //todo: L INAR temp here
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: data.parentComponent,
    };

    let assets = data.preselectedAssets ? data.preselectedAssets.toJS() : [];

    importNestsAnyRollsWizardActions.openImportNestsAnyRollsWizard(dispatch, getState)(
      config,
      assets
    );
  };
};

jsxActions.onMenuItemImportNestClick = function (data) {
  return function (dispatch, getState) {
    let config = {
      importTypes: [ImportTypes.IMPORT_NESTS],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: data.parentComponent,
    };

    let asset =
      data.preselectedAssets && data.preselectedAssets.size > 0
        ? data.preselectedAssets.get(0)
        : null;

    importNestsWizardActions.openImportNestsWizard(dispatch, getState)(config, asset);
  };
};

jsxActions.onFiberPlacementOperationClick = function (data) {
  return function (dispatch, getState) {
    afpOperationWizardApi.show(dispatch, getState)(data);
  }
};

jsxActions.onAddReplaceSpoolsClick = function (data) {
  return function (dispatch, getState) {
    addReplaceSpoolsDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onMenuItemMoveToClick = function (data) {
  return function (dispatch, getState) {
    moveAssetsDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemBringHereClick = function (data) {
  return function (dispatch, getState) {
    bringAssetsDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemImportAttributesUpdateClick = function (data) {
  return function (dispatch, getState) {
    importAttributesUpdateWizardActionsApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemEditAssetAttributesClick = function (data) {
  return function (dispatch, getState) {
    editAssetAttributesDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemCreateWoClick = function (data) {
  return function (dispatch, getState) {
    createWorkOrderDialogApi.openDialog(dispatch, getState)();
  };
};

jsxActions.onMenuItemEditWoAttributesClick = function (data) {
  return function (dispatch, getState) {
    editWoAttributesDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemEditTaskAttributesClick = function (data) {
  return function (dispatch, getState) {
    editTaskAttributesDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.export = function (data) {
  return function (dispatch, getState) {
    exportApi.exportGridRows(dispatch, getState)(data);
  };
};

jsxActions.exportUsage = function (data) {
  return function (dispatch, getState) {
    exportApi.exportUsageGridRows(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemExportRollUsageClick = function (selectedAssets) {
  return function (dispatch, getState) {
    if (!selectedAssets || !selectedAssets.size || selectedAssets.size === 0) {
      return;
    }
    let selectedAssetsId = selectedAssets.first().id;
    exportApi.exportRollUsageById(dispatch, getState)(selectedAssetsId);
  };
};

jsxActions.onMenuItemExportSpoolUsageClick = function (selectedAssets) {
  return function (dispatch, getState) {
    if (!selectedAssets || !selectedAssets.size || selectedAssets.size === 0) {
      return;
    }
    let selectedAssetsId = selectedAssets.first().id;
    exportApi.exportSpoolUsageById(dispatch, getState)(selectedAssetsId);
  };
};

jsxActions.onMenuItemPrintLabelClick = function (data) {
  return function (dispatch, getState) {
    printPdfLabelsDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onAssetPrintPdfLabelClick = function (data) {
  return function (dispatch, getState) {
    if (!data || !data.length || data.length === 0) {
      return;
    }
    printLabelsApi.printMultiAssetsLabels(dispatch, getState)(data);
  };
};

jsxActions.onExportKitReportClick = function (selectedKits) {
  return function (dispatch, getState) {
    if (!selectedKits || !selectedKits.size || selectedKits.size === 0) {
      return;
    }

    let selectedKitsIds = selectedKits.map((asset) => {
      return asset.id;
    });

    exportApi.exportKitReport(dispatch, getState)(selectedKitsIds);
  };
};

jsxActions.onExportKitRollsReportClick = function (selectedKits) {
  return function (dispatch, getState) {
    if (!selectedKits || !selectedKits.size || selectedKits.size === 0) {
      return;
    }

    let selectedKitsIds = selectedKits.map((asset) => {
      return asset.id;
    });

    exportApi.exportKitRollsReport(dispatch, getState)(selectedKitsIds);
  };
};

jsxActions.onExportContainerReportClick = function (selectedAssets) {
  return function (dispatch, getState) {
    if (!selectedAssets || !selectedAssets.size || selectedAssets.size === 0) {
      return;
    }

    let selectedAssetsIds = selectedAssets.map((asset) => {
      return asset.id;
    });

    exportApi.exportGroupReport(dispatch, getState)(selectedAssetsIds);
  };
};

//todo - ran - remove type and builder after progress bar is done
//overview page
jsxActions.onPrintTagsClick = function (asset, messageBuilder) {
  return function (dispatch, getState) {
    return printRfidTagsApi.printRfidTagsWithPolling(dispatch, getState)(
      [asset.get('id')],
      asset.get('locationId'),
      asset.get('objectType'),
      asset.get('businessId'),
      messageBuilder
    );
  };
};

jsxActions.onPrintTagsWithDialogClick = function (asset) {
  return function (dispatch, getState) {
    return printRfidTagsApi.openPrintRfidTagsDialog(dispatch, getState)(
      asset.get('locationId'),
      asset
    );
  };
};

//predefine views
jsxActions.onMenuItemPrintRfidTagsClick = function (selectedAssets) {
  return function (dispatch, getState) {
    if (!selectedAssets || !selectedAssets.size || selectedAssets.size === 0) {
      return;
    }

    const selectedAsset = selectedAssets.get(0, null);

    const locationId =
      selectedAsset['locationId'] ?? locationPageApi.getLocationId(dispatch, getState)();
    return printRfidTagsApi.openPrintRfidTagsDialog(dispatch, getState)(locationId, selectedAsset);
  };
};

jsxActions.onMenuItemPrintEmptyRfidTagsClick = function () {
  return function (dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    return printRfidTagsApi.printEmptyRfidTags(dispatch, getState)(locationId);
  };
};

jsxActions.onMenuItemChangePasswordClick = function (data) {
  return function (dispatch, getState) {
    let changePasswordAction = getState().system.get('featureFlags').ORGANIZATIONS
      ? userService.changeSystemUserPassword
      : userService.changePassword;
    changePasswordDialogApi.show(
      data.selectedUser,
      data.callbackAction,
      changePasswordAction,
      data.dialogLabels
    )(dispatch, getState);
  };
};

jsxActions.onMenuItemChangeUsersStatusUnarchiveClick = function (data) {
  return function (dispatch, getState) {
    let queryData = {
      id: data.selectedUser.id,
      prevStatus: data.selectedUser.status,
      newStatus: UserAvailableActionsTypes.UNARCHIVE,
    };

    userManagementChangeStatusApi.changeUsersStatus(queryData)(dispatch, getState);
  };
};

jsxActions.onMenuItemChangeUsersStatusArchiveClick = function (data) {
  return function (dispatch, getState) {
    let queryData = {
      id: data.selectedUser.id,
      prevStatus: data.selectedUser.status,
      newStatus: UserAvailableActionsTypes.ARCHIVE,
    };

    userManagementChangeStatusApi.changeUsersStatus(queryData)(dispatch, getState);
  };
};

jsxActions.onMenuItemChangeUsersStatusUnlockClick = function (data) {
  return function (dispatch, getState) {
    let queryData = {
      id: data.selectedUser.id,
      prevStatus: data.selectedUser.status,
      newStatus: UserAvailableActionsTypes.UNLOCK,
    };

    userManagementChangeStatusApi.changeUsersStatus(queryData)(dispatch, getState);
  };
};

jsxActions.onReportMaintenanceClick = function (data) {
  return function (dispatch, getState) {
    reportMaintenanceDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onReportCutClick = function (data) {
  return function (dispatch, getState) {
    reportCutDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onCutAndKitClick = function (data) {
  return function (dispatch, getState) {
    cutAndKitDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onCreateSubRollsClick = function (data) {
  return function (dispatch, getState) {
    createSubRollsDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.onMenuItemArchiveWOClick = function () {
  return function (dispatch, getState) {
    archiveWorkOrderDialogApi.show(dispatch, getState)();
  };
};
jsxActions.onActivateWOClick = function () {
  return function (dispatch, getState) {
    activateWorkOrderDialogApi.show(dispatch, getState)();
  };
};

jsxActions.onAddToGroupDialogClick = function (data) {
  return function (dispatch, getState) {
    addToGroupDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.confirmShipmentStatus = function (data) {
  return function (dispatch, getState) {
    api.confirmShipment([...data.actionData][0].id, data.parentComponent)(dispatch, getState);
  };
};

jsxActions.rejectShipment = function (data) {
  return function (dispatch, getState) {
    api.rejectShipment([...data.actionData][0].id, data.parentComponent)(dispatch, getState);
  };
};

jsxActions.downloadShipmentDocument = function (data) {
  return function (dispatch, getState) {
    api.downloadShipmentDocument(data)(dispatch, getState);
  };
};

jsxActions.markAsReceived = function (data) {
  const assetIds = [...data.actionData].map((d) => d.id);
  const config = {
    assetIds: assetIds,
    comment: '',
  };
  return function (dispatch, getState) {
    api.markAsReceived(config, data.parentComponent)(dispatch, getState);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};
api.markAsReceived = function (config, callback) {
  return function (dispatch, getState) {
    assetService.markAsReceived(config).then((result) => {
      if (!Network.isResponseValid(result)) {
        console.error(' mark As Received failed');
        messageDialogApi.responseError(dispatch, getState)(result);
        return false;
      } else {
        callback?.();
      }
    });
  };
};

api.confirmShipment = function (data, callback) {
  return function (dispatch, getState) {
    shipmentSevice.confirmShipment(data).then((result) => {
      if (!Network.isResponseValid(result)) {
        console.error('confirm shipment  failed');
        messageDialogApi.responseError(dispatch, getState)(result);
        return false;
      } else {
        callback?.();
      }
    });
  };
};

api.rejectShipment = function (data, callback) {
  return function (dispatch, getState) {
    shipmentSevice.rejectShipment(data).then((result) => {
      if (!Network.isResponseValid(result)) {
        console.error('reject shipment failed');
        messageDialogApi.responseError(dispatch, getState)(result);
        return false;
      } else {
        callback?.();
      }
    });
  };
};

api.downloadShipmentDocument = function (data) {
  return function (dispatch, getState) {
    shipmentSevice.downloadShipment([...data][0].id).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  };
};

function _handleBlobResponse(response, dispatch, getState, fileType = 'csv') {
  if (!response.ok) {
    console.error('Export CSV failed', response);
    messageDialogApi.responseError(dispatch, getState)();
    return [];
  }
  Network.loadBlobResponse(response, fileType).then((loadResponse) => {
    if (!Network.isResponseValid(loadResponse)) {
      console.error('Export CSV failed', loadResponse);
      messageDialogApi.responseError(dispatch, getState)(loadResponse);
    }
    return [];
  });
}
/*
api.printLabels = function (dispatch, getState) {
  return function (selectedAssetsIds, assetType) {

    assetService.PrintAssetLabel(assetType, selectedAssetsIds)
      .then((response) => {
        response.blob().then((blobResponse) => {
          if (blobResponse.size === 0) {
            console.error('Print Asset Label failed');
            return {success: false};
          }

          var myURL = window.URL || window.webkitURL;
          var file = new Blob([blobResponse], {type: 'application/pdf'});
          var fileURL = myURL.createObjectURL(file);
          window.open(fileURL, '_blank');
        });
      });

  };
};
*/

import Network from 'infrastructure/js/modules/network';
import * as dashboardService from '../../../services/Reports/dashboardService';
import {api as exportApi}      from '../../Export/exportActions.js';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  DASHBOARDS_DEMO_DATA_VIEW_FETCH_IN_PROCESS: 'DASHBOARDS_DEMO_DATA_VIEW_FETCH_IN_PROCESS',
  DASHBOARDS_DEMO_DATA_VIEW_FETCH_FINISHED: 'DASHBOARDS_DEMO_DATA_VIEW_FETCH_FINISHED',
  DASHBOARDS_DEMO_DATA_VIEW_FETCH_READY: 'DASHBOARDS_DEMO_DATA_VIEW_FETCH_READY',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {

  fetchDashboardsDemoDataInProgress: function (payload) {
    return {type: actionTypes.DASHBOARDS_DEMO_DATA_VIEW_FETCH_IN_PROCESS, payload};
  },
  fetchDashboardsDemoDataFinished: function (payload) {
    return {type: actionTypes.DASHBOARDS_DEMO_DATA_VIEW_FETCH_FINISHED, payload};
  },
  updateDashboardsDemoData: function (payload) {
    return {type: actionTypes.DASHBOARDS_DEMO_DATA_VIEW_FETCH_READY, payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function() {
  return function(dispatch, getState) {
    api.fetchDashboardsDemoDataSettings(dispatch, getState)();
  }
};

jsxActions.removeDashboardsDemoData = function(getMessageDialogBuilder) {
  return function(dispatch, getState) {
    api.removeDashboardsDemoData(dispatch, getState)(getMessageDialogBuilder);
  }
};

jsxActions.downloadDashboardTemplate = function(dashboardType) {
  return function(dispatch, getState) {
    api.downloadDashboardTemplate(dispatch, getState)(dashboardType);
  }
};

jsxActions.deleteDashboardDemoData = function(dashboardType) {
  return function(dispatch, getState) {
    api.deleteDashboardDemoData(dispatch, getState)(dashboardType);
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchDashboardsDemoDataSettings = function(dispatch, getState) {
  return function() {
    dispatch(actions.fetchDashboardsDemoDataInProgress());

    return dashboardService.fetchDashboardsDemoDataSettings().then((response) => {
      if (!Network.isResponseValid(response)) {
        dispatch(actions.fetchDashboardsDemoDataFinished());
        console.error('Failed to fetch Dashboards Demo Data settings', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let data = (response.data && response.data.settings) ? response.data.settings : [];

      dispatch(actions.updateDashboardsDemoData(data));

    });
  }
};

api.removeDashboardsDemoData = function(dispatch, getState) {
  return function (getMessageDialogBuilder) {

    let messageDialogDescriptor = getMessageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.doRemoveDashboardsDemoData(dispatch, getState)()
    );
    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
  }
};


api.doRemoveDashboardsDemoData = function(dispatch, getState) {
  return function() {
    messageDialogApi.close(dispatch, getState)();

    dashboardService.removeDashboardsDemoData().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to remove all Dashboards Demo Data', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      api.fetchDashboardsDemoDataSettings(dispatch, getState)().then((response2) => {
        let labelKey = 'mat.administration.psmanagement.dashboardsDemoData.view.removeAllData.confirmation.success';
        messageDialogApi.showSuccess(dispatch, getState)(labelKey);
      });
    });
  }
};

api.downloadDashboardTemplate = function(dispatch, getState) {
  return function(dashboardType) {
    exportApi.exportDashboardTemplate(dispatch, getState)({dashboardType});
  }
};

api.deleteDashboardDemoData = function(dispatch, getState) {
  return function(dashboardType) {
    dashboardService.deleteDashboardDemoData({dashboardType}).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to remove Dashboard Demo Data', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      api.fetchDashboardsDemoDataSettings(dispatch, getState)().then((response2) => {
        let labelKey = 'mat.administration.psmanagement.dashboardsDemoData.view.removeDashboardData.confirmation.success';
        messageDialogApi.showSuccess(dispatch, getState)(labelKey);
      });
    });


  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

import * as dashboardService from '../../services/Reports/dashboardService.js';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {api as navigatorApi} from '../AppTopbar/navigatorActions.js';
import {api as systemApi} from '../System/systemActions';
import {navigationStates} from '../../enums/navigationStates';
import Network from "../../../../infrastructure/js/modules/network";
import MaterialHelper from "../../utils/materialHelper";
import {api as messageDialogApi} from "../MessageDialog/messageDialogActions";
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import * as DashboardHelper from "../../components/Reports/utils/dashboardHelper.js";

export const MATERIAL_USE_CHART_MAX_MATERIAL_TYPES = 10;

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  INVENTORY_DASHBOARD_PAGE_FETCH_DATA_STARTED: 'INVENTORY_DASHBOARD_PAGE_FETCH_DATA_STARTED',
  INVENTORY_DASHBOARD_PAGE_FETCH_DATA_FINISHED: 'INVENTORY_DASHBOARD_PAGE_FETCH_DATA_FINISHED',
  INVENTORY_DASHBOARD_PAGE_FETCH_HEADER_DATA_FINISHED: 'INVENTORY_DASHBOARD_PAGE_FETCH_HEADER_DATA_FINISHED',
  INVENTORY_DASHBOARD_PAGE_FETCH_USAGE_CHART_DATA_FINISHED: 'INVENTORY_DASHBOARD_PAGE_FETCH_USAGE_CHART_DATA_FINISHED',
  INVENTORY_DASHBOARD_PAGE_FETCH_INVENTORY_CHART_DATA_FINISHED: 'INVENTORY_DASHBOARD_PAGE_FETCH_INVENTORY_CHART_DATA_FINISHED',
  INVENTORY_DASHBOARD_PAGE_RESET_DATA: 'INVENTORY_DASHBOARD_PAGE_RESET_DATA',
  INVENTORY_DASHBOARD_PAGE_FETCH_INVENTORY_KPI_DATA_FINISHED: 'INVENTORY_DASHBOARD_PAGE_FETCH_INVENTORY_KPI_DATA_FINISHED',
  INVENTORY_DASHBOARD_PAGE_FETCH_USAGE_KPI_DATA_FINISHED: 'INVENTORY_DASHBOARD_PAGE_FETCH_USAGE_KPI_DATA_FINISHED',
  INVENTORY_DASHBOARD_PAGE_UPDATE_INVENTORY_FILTERS: 'INVENTORY_DASHBOARD_PAGE_UPDATE_INVENTORY_FILTERS',
  INVENTORY_DASHBOARD_PAGE_UPDATE_USAGE_FILTERS: 'INVENTORY_DASHBOARD_PAGE_UPDATE_USAGE_FILTERS'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function () {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_FETCH_DATA_STARTED};
  },
  fetchDataFinished: function () {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_FETCH_DATA_FINISHED};
  },
  fetchHeaderDataFinished: function (payload) {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_FETCH_HEADER_DATA_FINISHED, payload};
  },
  fetchInventoryKpisDataFinished: function (payload) {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_FETCH_INVENTORY_KPI_DATA_FINISHED, payload};
  },
  fetchUsageKpisDataFinished: function (payload) {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_FETCH_USAGE_KPI_DATA_FINISHED, payload};
  },
  fetchInventoryChartsDataFinished: function (payload) {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_FETCH_INVENTORY_CHART_DATA_FINISHED, payload};
  },
  fetchUsageChartsDataFinished: function (payload) {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_FETCH_USAGE_CHART_DATA_FINISHED, payload};
  },
  resetState: function (payload) {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_RESET_DATA, payload: payload};
  },
  updateInventoryFilters: function (payload) {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_UPDATE_INVENTORY_FILTERS, payload: payload};
  },
  updateUsageFilters: function (payload) {
    return {type: actionTypes.INVENTORY_DASHBOARD_PAGE_UPDATE_USAGE_FILTERS, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.INVENTORY_DASHBOARD);

    navigatorApi.setLoading(true)(dispatch, getState);

    api.init(dispatch, getState)().then(() => {
      navigatorApi.setLoading(false)(dispatch, getState);
    });
  }
};

jsxActions.fetchInventoryData = function (data) {
  return function (dispatch, getState) {
    let locationIds = data.selectedLocations ? data.selectedLocations.map((location) => location.data.id) : [];
    let materialIds = data.selectedMaterials ? data.selectedMaterials.map((material) => material.data.businessId) : [];

    let filters = {
      searchBy: [
        {
          objectName: "locationId",
          values: locationIds
        },
        {
          objectName: "isFreezer",
          values: [data.isFreezers]
        },
        {
          objectName: "material",
          values: materialIds
        }
      ]
    };
    api.fetchInventoryData(dispatch, getState)(filters);
  }
};

jsxActions.onUsageHeaderFilterChange = function (data) {
  return function (dispatch, getState) {
    let filtersData = {...getState().inventoryDashboardPage.get('usageFilters')};

    //Note: ids must be strings ["100", "101", "102"]
    let materialIds = data.selectedMaterials ? data.selectedMaterials.map((material) => material?.data?.businessId) : [];
    filtersData.searchBy = [
      {
        objectName: "material",
        values: materialIds
      }
    ]

    api.fetchUsageData(dispatch, getState)(filtersData);
  }
};

jsxActions.onMaterialUsageFilterChange = function (data) {
  return function (dispatch, getState) {
    let filtersData = {...getState().inventoryDashboardPage.get('usageFilters')};
    filtersData.materialUsagePeriod = data;

    api.fetchUsageData(dispatch, getState)(filtersData);
  }
};

jsxActions.onUsageTimeFilterChange = function (data) {
  return function (dispatch, getState) {
    let filtersData = {...getState().inventoryDashboardPage.get('usageFilters')};
    filtersData.consumptionTimePeriod = data;

    api.fetchUsageData(dispatch, getState)(filtersData);
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};

// Called by this component's ScheduleAction exclusively.
jsxActions.onTimerRefresh = function () {
  return function (dispatch, getState) {

    let inventoryFilters = getState().inventoryDashboardPage.get('inventoryFilters');
    let fetchInventoryPromise = api.fetchInventoryData(dispatch, getState)(inventoryFilters);

    let usageFilters = getState().inventoryDashboardPage.get('usageFilters');
    let fetchUsagePromise = api.fetchUsageData(dispatch, getState)(usageFilters);

    return Promise.all([fetchInventoryPromise, fetchUsagePromise]);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.init = function (dispatch, getState) {
  return function () {
    let label = createLabelHelper('mat.header.navigator.').get('section.reports.inventoryDashboard');

    let topNavigatorData = {
      name: label,
      type: navigationStates.INVENTORY_DASHBOARD,
      id: -1,
      components: []
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);


    let fetchFiltersPromise = api.fetchFilters(dispatch, getState)();

    let usageFilters = getState().inventoryDashboardPage.get('usageFilters');
    let inventoryFilters = getState().inventoryDashboardPage.get('inventoryFilters');
    let fetchInventoryPromise = api.fetchInventoryData(dispatch, getState)(inventoryFilters);
    let fetchUsagePromise = api.fetchUsageData(dispatch, getState)(usageFilters);

    return Promise.all([fetchFiltersPromise, fetchInventoryPromise, fetchUsagePromise]);
  };
};

api.fetchInventoryKpis = function (dispatch, getState) {
  return function (kpisQuery) {

    let isThresholdActive = PermissionManager.getOrgPreferences().remnantsThreshold;

    let promises = [];

    let fetchRollsTotalLengthPromise = dashboardService.fetchRollsTotalLength(kpisQuery);
    promises.push(fetchRollsTotalLengthPromise);

    let fetchRollsCountPromise = dashboardService.fetchRollsCountByRemnant(kpisQuery, false);
    promises.push(fetchRollsCountPromise);

    if (isThresholdActive) {
      let fetchRollsCountByRemnantPromise = dashboardService.fetchRollsCountByRemnant(kpisQuery, true);
      promises.push(fetchRollsCountByRemnantPromise);
    }

    return Promise.all(promises).then((allResults) => {
      if (!Network.isResponseValid(allResults[0]) ||
        !Network.isResponseValid(allResults[1])) {
        console.error('inventory dashboard fetch kpis failed');
        dispatch(actions.fetchDataFinished());
        messageDialogApi.responseError(dispatch, getState)(null);
        return {success: false};
      }

      let totalLength = allResults[0].data.data;
      let rollsCount = allResults[1].data.data;
      let rollsRemnantCount = allResults[2] ? allResults[2].data.data : null;

      let kpiData = {rollsRemnantCount, totalLength, rollsCount};
      dispatch(actions.fetchInventoryKpisDataFinished(kpiData));
    })
  }
};

api.fetchUsageKpis = function (dispatch, getState) {
  return function (kpisQuery) {

    let fetchAverageMaterialLengthPromise = dashboardService.fetchAverageMaterialLengthLength(kpisQuery);
    let fetchAverageConsumptionTimePromise = dashboardService.fetchAverageConsumptionTime(kpisQuery);
    let fetchAverageCarryingAmountPromise = dashboardService.fetchAverageCarryingAmount(kpisQuery);

    return Promise.all([fetchAverageMaterialLengthPromise, fetchAverageConsumptionTimePromise, fetchAverageCarryingAmountPromise]).then((allResults) => {
      if (!Network.isResponseValid(allResults[0]) || !Network.isResponseValid(allResults[1]) || !Network.isResponseValid(allResults[2])) {
        console.error('inventory dashboard fetch kpis failed');
        dispatch(actions.fetchDataFinished());
        messageDialogApi.responseError(dispatch, getState)(null);
        return {success: false};
      }

      let averageMaterialLength = allResults[0].data.data;
      let averageConsumptionTime = allResults[1].data.data;
      let AverageCarryingAmount = allResults[2].data.data;

      dispatch(actions.fetchUsageKpisDataFinished({averageMaterialLength, averageConsumptionTime, AverageCarryingAmount}));
    })
  }
};

api.fetchFilters = function (dispatch, getState) {
  return function () {

    let fetchLocationsPromise = dashboardService.fetchInventoryLocations();
    let fetchMaterialsPromise = dashboardService.fetchInventoryMaterials();
    return Promise.all([fetchLocationsPromise, fetchMaterialsPromise]).then((allResults) => {
      if (!Network.isResponseValid(allResults[0]) ||
        !Network.isResponseValid(allResults[1])) {
        console.error('inventory dashboard fetch filters failed');
        dispatch(actions.fetchDataFinished());
        messageDialogApi.responseError(dispatch, getState)(null);
        return {success: false};
      }

      let locations = allResults[0].dataList
        .map((obj) => {
          return {value: obj.id, label: obj.name, data: obj}
        })
        .sort((locationA, locationB) => {
          return locationA.label.toLocaleLowerCase() < locationB.label.toLocaleLowerCase() ? -1 : 1
        });

      let materials = allResults[1].dataList
        .map((obj) => {
          let materialLabel = MaterialHelper.getMaterialFullLabel(obj.materialName, obj.businessId);
          return {value: obj.id, label: materialLabel, data: obj}
        })
        .sort((materialA, materialB) => {
          return materialA.label.toLocaleLowerCase() < materialB.label.toLocaleLowerCase() ? -1 : 1
        });

      let filters = {locations, materials};
      dispatch(actions.fetchHeaderDataFinished(filters));
    });
  }
};

api.fetchInventoryData = function (dispatch, getState) {
  return function (filters) {
    let loggedInUser = getState().login.get('loggedInUser');
    let isDemoMode = DashboardHelper.isDemoMode(loggedInUser);


    function fetchDataAction() {
      let fetchChartsPromise = api.fetchInventoryChartsData(dispatch, getState)(filters);
      let fetchKpisPromise = api.fetchInventoryKpis(dispatch, getState)(filters);

      dispatch(actions.updateInventoryFilters(filters));
      return Promise.all([fetchChartsPromise, fetchKpisPromise]);
    }

    if (isDemoMode) {
      return _handleInventoryChartDemoData(dispatch, getState)().then((isHandled) => {
        if(isHandled) {
          return Promise.resolve();
        }

        return fetchDataAction();
      });
    };

    return fetchDataAction();
  }
};

api.fetchUsageData = function (dispatch, getState) {
  return function (filters) {
    let loggedInUser = getState().login.get('loggedInUser');
    let isDemoMode = DashboardHelper.isDemoMode(loggedInUser);


    function fetchDataAction() {
      let fetchChartsPromise = api.fetchUsageChartsData(dispatch, getState)(filters);
      let fetchKpisPromise = api.fetchUsageKpis(dispatch, getState)({ searchBy: filters.searchBy });

      dispatch(actions.updateUsageFilters(filters));
      return Promise.all([fetchChartsPromise, fetchKpisPromise]);
    }

    if (isDemoMode) {
      return _handleUsageChartDemoData(dispatch, getState)().then((isHandled) => {
        if(isHandled) {
          return Promise.resolve();
        }

        return fetchDataAction();
      });
    };

    return fetchDataAction();
  }
};

api.fetchInventoryChartsData = function (dispatch, getState) {
  return function (query) {
    dispatch(actions.fetchDataStarted());

    delete query.remnant;
    let totalLengthPerMaterialPromise = dashboardService.fetchInventoryTotalLengthPerMaterialChart(query);
    let fetchRollsWithErrorsStatisticsPromise = dashboardService.fetchInventoryRollsWithErrorsStatisticsChart(query);
    let fetchInventoryArchivedRollsPromise = dashboardService.fetchInventoryArchivedRollsChart(query);
    return Promise.all([totalLengthPerMaterialPromise, fetchRollsWithErrorsStatisticsPromise, fetchInventoryArchivedRollsPromise]).then((allResults) => {
      if (!Network.isResponseValid(allResults[0]) ||
        !Network.isResponseValid(allResults[1]) ||
        !Network.isResponseValid(allResults[2])) {
        console.error('inventory dashboard fetch charts failed');
        dispatch(actions.fetchDataFinished());
        messageDialogApi.responseError(dispatch, getState)(null);
        return {success: false};
      }
      let totalLengthPerMaterial = allResults[0].data;
      let rollsWithErrorsStatistics = allResults[1].data;
      let archivedRolls = allResults[2].data.archivedAlertedRolls;

      dispatch(actions.fetchInventoryChartsDataFinished({totalLengthPerMaterial, rollsWithErrorsStatistics, archivedRolls}));
    });
  }
};

api.fetchUsageChartsData = function (dispatch, getState) {
  return function (filters) {
    dispatch(actions.fetchDataStarted());

    let materialUsagePromise = dashboardService.fetchInventoryMaterialUsageChart({searchBy: filters.searchBy, timePeriod: filters.materialUsagePeriod});
    let consumptionTimePromise = dashboardService.fetchInventoryConsumptionTimeChart({searchBy: filters.searchBy, timePeriod: filters.consumptionTimePeriod});

    return Promise.all([materialUsagePromise, consumptionTimePromise]).then((allResults) => {
      if (!Network.isResponseValid(allResults[0]) ||
        !Network.isResponseValid(allResults[1])) {
        console.error('inventory dashboard fetch usage charts failed');
        dispatch(actions.fetchDataFinished());
        messageDialogApi.responseError(dispatch, getState)(null);
        return {success: false};
      }

      let materialUsage = allResults[0].data;
      let consumptionTime = allResults[1].data;
      dispatch(actions.fetchUsageChartsDataFinished({materialUsage, consumptionTime}));
    });
  }
};

/////////////////////////////////////////////////////////////////////
// HELPERS
let _handleUsageChartDemoData = function(dispatch, getState) {
  return function() {
    dispatch(actions.fetchDataStarted());
    return dashboardService.fetchDashboardsDemoDataSettings().then((response) => {
      if (!Network.isResponseValid(response)) {
        dispatch(actions.fetchDataFinished());
        console.error('Fetch Production dashboard chart data failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return false;
      }
      let settings = (response.data && response.data.settings) ? response.data.settings : [];
      if (!DashboardHelper.isDashboardDemoDataSet(settings, DashboardHelper.dashboardTypes.INVENTORY_USAGE)) {
        return false;
      }

      return dashboardService.fetchInventoryUsageDemoData().then((response) => {
        dispatch(actions.fetchDataFinished());
        if (!Network.isResponseValid(response) || !response.data) {
          console.error('Fetch Tool Maintenance dashboard - Demo grid data failed');
          messageDialogApi.responseError(dispatch, getState)(response);
          return false;
        }

        let averageMaterialLength = response.data.averageMaterialLength;
        let averageConsumptionTime = response.data.averageConsumptionTime;
        let AverageCarryingAmount = response.data.averageCarryingAmount;
        dispatch(actions.fetchUsageKpisDataFinished({averageMaterialLength, averageConsumptionTime, AverageCarryingAmount}));

        let materialUsage = response.data.materialUsageResponseModel;
        let consumptionTime = response.data.timeToConsumptionResultResponseModel;
        dispatch(actions.fetchUsageChartsDataFinished({materialUsage, consumptionTime}));

        return true;
      })
    })
  }
};

let _handleInventoryChartDemoData = function(dispatch, getState) {
  return function() {
    dispatch(actions.fetchDataStarted());
    return dashboardService.fetchDashboardsDemoDataSettings().then((response) => {
      if (!Network.isResponseValid(response)) {
        dispatch(actions.fetchDataFinished());
        console.error('Fetch Production dashboard chart data failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return false;
      }
      let settings = (response.data && response.data.settings) ? response.data.settings : [];
      if (!DashboardHelper.isDashboardDemoDataSet(settings, DashboardHelper.dashboardTypes.INVENTORY)) {
        return false;
      }

      return dashboardService.fetchInventoryDemoData().then((response) => {
        dispatch(actions.fetchDataFinished());
        if (!Network.isResponseValid(response) || !response.data) {
          console.error('Fetch Tool Maintenance dashboard - Demo grid data failed');
          messageDialogApi.responseError(dispatch, getState)(response);
          return false;
        }

        let totalLength = response.data.rollsTotalLength;
        let rollsCount = response.data.numberOfRolls;
        let rollsRemnantCount = response.data.numberOfRemnansRolls;

        let kpiData = {rollsRemnantCount, totalLength, rollsCount};
        dispatch(actions.fetchInventoryKpisDataFinished(kpiData));

        let totalLengthPerMaterial = response.data.totalMaterialsLength;
        let rollsWithErrorsStatistics = response.data.rollWithErrorsStatistics;
        let archivedRolls = response.data.archivedAlertedRollsTotal.archivedAlertedRolls;
        dispatch(actions.fetchInventoryChartsDataFinished({totalLengthPerMaterial, rollsWithErrorsStatistics, archivedRolls}));

        return true;
      })
    })
  }
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import { jsxActions as commonAdditionalFieldsViewActions} from '../../../../actions/Administration/SettingsPage/commonAdditionalFieldsViewActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import WoAdditionalFieldsView from '../../../../components/Administration/SettingsPage/AdditionalFields/WoAdditionalFieldsView/woAdditionalFieldsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonAdditionalFieldsViewActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch)
    }
  }
}

let WoAdditionalFieldsViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonAdditionalFieldsView']),
      isDirty: isDirty('commonAdditionalFieldsForm')(state),
      sLoggedInUser : state.login.get('loggedInUser')
    };
  },

  mapDispatchToProps
)(WoAdditionalFieldsView);

export default WoAdditionalFieldsViewContainer;




import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

/**
 * @constant TOOL_REQUIRED_OPTIONS_TYPES
 * @description An enumeration of the possible values for the `toolsRequired` property of an operation.
 * @enum {string}
 * @values
 * NO - The operation does not require any tools.
 * YES - The operation requires tools.
 * PREVIOUS - The operation uses the tools that were used in the previous operation.
 */
export const TOOL_REQUIRED_OPTIONS_TYPES = {
  NO: 'No',
  YES: 'Yes',
  PREVIOUS: 'Previous',
};

/**
 * @function getToolRequiredOptions
 * @description Returns an array of react-select options for the toolRequired dropdown.
 * @returns {Array} An array of options.
 */
export const getToolRequiredOptions = () => {
  // Get the labels for the options.
  const labels = createLabelHelper('mat.administration.matsettings.dialog.createkittype.operations.toolRequired.options.');
  // Return the array of options.
  return [
    { value: TOOL_REQUIRED_OPTIONS_TYPES.NO, label: labels.get('no') },
    { value: TOOL_REQUIRED_OPTIONS_TYPES.YES, label: labels.get('yes') },
    { value: TOOL_REQUIRED_OPTIONS_TYPES.PREVIOUS, label: labels.get('previous') },
  ];
};

/**
 * @function checkToolRequiredPreviousOptions
 * @description Checks array of operationItems. sets the 'toolRequired' prop to NO if the current value is 'PREVIOUS' and previous item
 * value is 'NO'.
 * @param {Array} items The array of items to check.
 * @returns {Array} The array with the `toolsRequired` properties updated.
 */
export const checkToolRequiredPreviousOptions = (items = []) => {
  // Create a copy of the items array.
  const computedItems = [...items];

  // Iterate over the computed items array.
  for (const item of computedItems) {
    // Get the index of the current item in the array.
    const index = computedItems.indexOf(item);

    // If the current item's toolRequired property is "Previous",
    if (item.toolRequired === TOOL_REQUIRED_OPTIONS_TYPES.PREVIOUS) {
      // If the current item is the first item in the array,
      if (index === 0) {
        // Set the current item's toolRequired property to "No".
        item.toolRequired = TOOL_REQUIRED_OPTIONS_TYPES.NO;
      } else {
        // Get the previous item in the array.
        const prevItem = computedItems[index - 1];

        // If the previous item's toolRequired property is "No",
        if (prevItem.toolRequired === TOOL_REQUIRED_OPTIONS_TYPES.NO) {
          // Set the current item's toolRequired property to "No".
          item.toolRequired = TOOL_REQUIRED_OPTIONS_TYPES.NO;
        }
      }
    }
  }

  // Return the computed items array.
  return computedItems;
};

/**
 * @function parseNewAddedItem
 * @description Initialize the new item that has been added to the list of operations with the server equivalent value of 'NO' in the toolRequired prop.
 * @param {object} item The item that has been added.
 * @returns {object} The item with the `toolRequired` property set to `NO`.
 */
export const parseNewAddedItem = (item) => {
  item.toolRequired = TOOL_REQUIRED_OPTIONS_TYPES.NO;
  return item;
};

/**
 * @function checkToolPreviousOptionDisabled
 * @description Checks if the "Previous" option in the toolsRequired dropdown should be disabled.
 * @param {number} index The index of the current option.
 * @param {object} option The option that is being checked.
 * @param {Array} items The list of all the operations.
 * @returns {boolean} Whether or not the "Previous" option should be disabled.
 */
export const checkToolPreviousOptionDisabled = (index, option, items) => {
  const { value } = option;

  //Disable "Previous" in the first line always...
  if (index === 0 && value === TOOL_REQUIRED_OPTIONS_TYPES.PREVIOUS) {
    return true;
  }
  //Enable "Yes" and "No" options always...
  if (value !== TOOL_REQUIRED_OPTIONS_TYPES.PREVIOUS) {
    return false;
  }

  //disable the "Previous" if previous line is "No".
  const { toolRequired } = items[index - 1];
  return toolRequired === TOOL_REQUIRED_OPTIONS_TYPES.NO;
};

export const isToolRequired = (item) => {
  return (item?.toolRequirement === TOOL_REQUIRED_OPTIONS_TYPES.YES ||
         item?.toolRequirement === TOOL_REQUIRED_OPTIONS_TYPES.PREVIOUS);
};

import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import {List} from 'immutable';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import EntitiesMultiSelect from '../EntitiesMultiSelect/entitiesMultiSelect.js'
import Button from 'infrastructure/js/components/controls/Button/button';
require('./multiEntitiesMultiSelect.scss');

export default class MultiEntitiesMultiSelect extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.component.assets.multiselect.');

    this.entitiesTypes = props.entitiesTypes;

    this.preSelectedEntitiesMap = new Map(); //holds all preselected entities by their types (key: 'ROLL', value: [])

    this.currentValues = [];

    this.typesInUse = [];

    this.state = { dummyKey: 0};  //dummy key to force the component remount
  }

  componentDidMount() {
    this.initData(this.props.preSelectedEntities);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.preSelectedEntities !== this.props.preSelectedEntities) {
      this.initData(nextProps.preSelectedEntities);
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  initData = (preSelectedEntities) => {
    let hasPreSelectedEntities = preSelectedEntities?.size > 0;

    if (!hasPreSelectedEntities) {
      this.clearData();
      this.typesInUse = [];
      this.currentValues = [];

      this.typesInUse[0] = this.entitiesTypes[0];
      this.props.change( `${this.props.name}[0]`, {} );
      return;
    }

    let preSelectedEntitiesTypes = this.getPreSelectedEntitiesTypes(preSelectedEntities);

    //remove empty fields
    if (this.fields.length > 0) {
      let len = this.fields.length - 1;
      for(let i = len ; i >= 0 ; i--) {
        if (this.currentValues && (!this.currentValues[i] || !this.currentValues[i].length)) {
          this.removeButtonClickHandler(i, this.fields);
        }
      }
    }

    for(let i = 0 ; i < preSelectedEntitiesTypes.length ; i++) {

      //check if the preSelectedEntitiesTypes[i] already in use; if not - add it
      let type = preSelectedEntitiesTypes[i];
      let typeIndex = this.typesInUse.indexOf(type);
      if (typeIndex === -1) {
        if (this.fields) {
          this.fields.push()
        }
        typeIndex = this.typesInUse.length;
        this.typesInUse[typeIndex] = type;
      }

      //filter preSelectedEntities by current type
      let preSelectedEntitiesByType = preSelectedEntities.filter((e) => {return e.objectType === type});

      //merge preSelectedEntities with currentValues for the  current type
      if (this.currentValues && this.currentValues[typeIndex] && this.currentValues[typeIndex].length > 0) {
        let ids = preSelectedEntitiesByType.map( item => item.id );

        let newList = preSelectedEntitiesByType;
        this.currentValues[typeIndex].forEach((item) => {
          if (!ids.includes(item.id)) {
            newList = newList.push(item);
          }
        });
        preSelectedEntitiesByType = newList;
      }

      this.preSelectedEntitiesMap.set(type, preSelectedEntitiesByType);
      this.props.change( `${this.props.name}[${typeIndex}]`, preSelectedEntitiesByType );
    }
  };

  getPreSelectedEntitiesTypes(entities) {
    let types =  entities?.map((item) => item.objectType);
    return [...new Set(types)];
  }

  getPreSelectedEntities(index) {
    let type = this.typesInUse[index];
    return this.preSelectedEntitiesMap.get(type);
  }

  clearData =() => {
    this.fields?.removeAll?.();
  };

  getAvailableTypes(index) {
    let availableTypes = [];

    let currentValue = this.entitiesTypes.find(type => type === this.typesInUse[index]);
    //add the currently used type into the list of the available types
    if (currentValue) {
      availableTypes.push(currentValue);
    }

    availableTypes.push(...this.entitiesTypes.filter((item)=>{
      return this.typesInUse.indexOf(item) < 0;
    }));

    return availableTypes;
  }

  onTypeChangeCallback = (index, val, oldValue) => {

    this.props.onTypeChangeCallback?.(val?.value);
    this.typesInUse[index] = val;
  };

  onValueChangeCallback = (index, value) => {
    this.props.onValueChangeCallback?.(value);
    this.currentValues[index] = value?.map(item => {return item.data});
  };

  removeButton = (index, fields) => {
      if (fields.length > 1) {
        return <span className="pl pl-x-input-close remove-row" onClick={() => this.removeButtonClickHandler(index , fields) }/>
    }
  };

  removeButtonClickHandler = (index, fields) => {
    fields.remove(index);

    let type = this.typesInUse[index];
    this.preSelectedEntitiesMap.set(type, new List());

    this.typesInUse.splice(index, 1);
    this.setState({dummyKey: this.state.dummyKey === 0 ? 1 : 0});

  };

  addButton = (fields) => {
    if (fields.length < this.entitiesTypes.length) {
      return (
        <Button id="add-select-asset-type-row" className="add-row" onClick={() => {this.addButtonClickHandler(fields)}}>
          <span className="pl pl-icon-add"/> {this.labels.get('addassettype')}
        </Button>
      );
    }
  };

  addButtonClickHandler = (fields) => {
    fields.push();

    let index = fields.length;
    let availableTypes = this.getAvailableTypes(index);
    if (availableTypes && availableTypes.length > 0) {
      this.typesInUse[index] = availableTypes[0];
    }
  };

  renderRows = ({ fields, meta: { error } }) => {
    this.fields = fields;

    let { name, fetchConfig, entitiesTypes, dropdownMenuClassName, validate, preSelectedEntities, ...otherProps} = this.props;
    let menuClassName = dropdownMenuClassName ? dropdownMenuClassName : '';

    return (
      <div className="entities-multi-select-ex">
        {fields.map((fieldName, index) =>
          <div className="selection-row" key={index}>
            <EntitiesMultiSelect
              id={fieldName}
              name={fieldName}
              key={index === 0 ? fieldName + index : fieldName + index + '-' + this.state.dummyKey }
              entitiesTypes={this.getAvailableTypes(index)}
              preSelectedEntities={this.getPreSelectedEntities(index)}
              validate={validate}
              { ...otherProps }
              onTypeChangeCallback={this.onTypeChangeCallback.bind(this, index)}
              onValueChangeCallback={this.onValueChangeCallback.bind(this, index)}
              dropdownMenuAttachment='right'
              dropdownMenuClassName={index > 0 ? 'nth-multi-select-field ' + menuClassName : 'first-multi-select-field ' + menuClassName}
              fetchConfig={fetchConfig}
            />
            {this.removeButton(index, fields)}
          </div>
        )}

        {this.addButton(fields)}
      </div>
    )
  };

  render() {
    return (
      <FieldArray name={this.props.name} component={this.renderRows}/>
    );
  }
}

MultiEntitiesMultiSelect.defaultProps = {
  disableOnPreselect : false,
  dropdownMenuClassName : '',
};
MultiEntitiesMultiSelect.propTypes = {
  entitiesTypes: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disableOnPreselect : PropTypes.bool,
  dropdownMenuClassName: PropTypes.string,
  onTypeChangeCallback: PropTypes.func,
  onValueChangeCallback: PropTypes.func,
};


import './colorCell.scss';

const ColorCell = ({ value }) => {
    return (
        <div className='color-cell'>
            <div className='color-box' style={{ backgroundColor: value }}></div>
        </div>
    );
};

export default ColorCell;

import { Map , List } from 'immutable';
import { actionTypes as ActionsType } from '../../../actions/Administration/RfidSettingsPage/workflowFiltersViewActions.js';

let defaultState = {
  loading: false,
  selectedDeviceApp : null,
  deviceAppsOptions : []
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case ActionsType.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DEVICE_APPS_IN_PROCESS:
      return state.set('loading', true);
    case ActionsType.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DEVICE_APPS_READY:
      state = state.set('deviceAppsOptions', action.payload);
      return state.set('loading',false);
    case ActionsType.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_DEVICE_APP_CHANGED:
      return state.set('selectedDeviceApp', action.payload);
    case ActionsType.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_UPDATE_IN_PROGRESS:
      return state.set('loading', true);
    case ActionsType.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_UPDATE_FINISHED:
      return state.set('loading', false);
    case ActionsType.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DATA_IN_PROGRESS:
      return state.set('loading', true);
    case ActionsType.ADMINISTRATION_RF_SETTINGS_WORKFLOWS_FILTERS_FETCH_DATA_FINISHED:
      state = state.set('loading', false);
      return state.set('filtersData', action.payload.data);
    default:
      return state;
  }

}








import Network from 'infrastructure/js/modules/network';

export function fetchAllMqttBrokers() {
  return Network.get('mqttBrokers');
}

export function fetchMqttBrokers(id) {
  return Network.get(`deviceAppProfile/${id}/mqttBrokers`);
}

export function createMqttBroker(deviceAppId , data) {
  return Network.post(`deviceAppProfile/${deviceAppId}/mqttBroker`, data);
}

export function editMqttBroker(brokerId, data) {
  return Network.put(`mqttBroker/${brokerId}`, data);
}

export function deleteMqttBrokers(data) {
  return Network.delete('mqttBroker' , {brokerIds: data});
}

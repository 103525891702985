import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as deviceAppsViewActions} from '../../../actions/Administration/RfidSettingsPage/deviceAppsViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as deviceAppsViewGridActions} from '../../../actions/Administration/RfidSettingsPage/deviceAppsViewGridActions';
import { jsxActions as createDeviceAppDialogActions} from '../../../actions/Administration/RfidSettingsPage/Dialogs/createDeviceAppDialogActions';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';

import { gridsNames} from '../../../enums/gridsNames';
import DeviceAppsView from '../../../components/Administration/RfidSettingsPage/DeviceAppsView/deviceAppsView.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(deviceAppsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      deviceAppsViewGridActions: bindActionCreators(deviceAppsViewGridActions, dispatch),
      createDeviceAppDialogActions : bindActionCreators(createDeviceAppDialogActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let DeviceAppsViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_DEVICE_APPS);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateDeviceAppDialogData: state.administration.getIn(['rfidSettingsPage', 'createDeviceAppDialog']),
    };
  },

  mapDispatchToProps
)(DeviceAppsView);

export default DeviceAppsViewContainer;



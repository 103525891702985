import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BatchJobProgressDialog from '../../components/Dialogs/BatchJobProgressDialog/batchJobProgressDialog';
import { jsxActions as batchJobProgressDialogActions } from '../../actions/Dialogs/BatchJobProgressDialog/batchJobProgressDialogActions';



function mapDispatchToProps(dispatch) {
  return {
    // actions:         bindActionCreators(activityLogDialogActions, dispatch),
    actions: bindActionCreators(batchJobProgressDialogActions, dispatch)
  }
}

export default connect(
  (state)=>{
    let data = state.dialogs.get('batchJobProgressDialog');
    let jobInstanceId = data.get( 'jobInstanceId');
    let boData = state.batchJobs;
    return {
      show : data.get( 'show'),
      jobInstanceId : jobInstanceId,
      // ...data
      // title : state.activityLogDialog.get( 'title'),
      sData : data,
      boData: boData,
      // ...state.dialogs.get('batchJobProgressDialog')
    }
  },
  mapDispatchToProps
)(BatchJobProgressDialog);


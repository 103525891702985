import Network from 'infrastructure/js/modules/network';

export function uploadFile (file, assetType) {
  return Network.postFile('upload/update/file', file);
}

export function validateFile(data, assetType) {
  return Network.post(`validate/update/${normalizeAssetType(assetType)}`, data);
}

export function getBulkCsvSettings(data) {
  return Network.post('csvSettings/bulk/update/settings', data);
}

export function importFile (data, assetType) {
  let options = {pollingRequest: 'batchJobs/JOB_EXECUTION_ID/status-with-printing'};
  return Network.polling.post(`batch/update/${normalizeAssetType(assetType)}`, data, options);
}

export function getUpdateAttributesPreview (data) {
  return Network.post('preview/update/assets', data);
}

export function updateAttributes (data, assetType) {
  return Network.polling.post(`batch/update/imported/${assetType}`, data);
}

export function deleteBulkCsvSettings (data) {
  return Network.delete('csvSettings/bulk/update/settings/delete', data);
}

/////////////////////////////////////////////////////////
function normalizeAssetType (assetType) {
  return assetType;
}

import {actionTypes as importNestsWizardActions }from '../../../actions/Wizards/ImportNests/importNestsWizardActions.js';
import {actionTypes as gridActions} from 'infrastructure/js/components/Grid/Utils/gridActionsHelper.js';
import {gridsNames} from '../../../enums/gridsNames.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { List, Map} from 'immutable';

let defaultState = {
  errors: null,
  nestsErrors: null,
  loading: false,
  validatedFileData:null,
  fileToken:null,
  selectedFile: null,
  importFileData:null,
  selectedAssetType: null,
  parentComponent: null,
  currentDate: DateTimeHelper.DateFormat(Date.now()),
  importTypes: [],
  shouldIncludeLocation: false,
  showPrintLabels: false,
  acceptFileTypes:[],

  rollId: null,
  materialBusinessId: null,
  nestsItems: List()
};

export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case gridActions.GRID_SELECTED_ROWS_CHANGED:
      if (action.payload.gridName !== gridsNames.IMPORT_NESTS_SELECT_NESTS){
        return state;
      }
      return state.set('nestsErrors', null);

    case importNestsWizardActions.IMPORT_NESTS_SAVE_START_PARAMETERS:
      return state.set('importTypes', action.payload.importTypes)
                  .set('acceptFileTypes', action.payload.acceptFileTypes)
                  .set('shouldIncludeLocation', action.payload.shouldIncludeLocation ? action.payload.shouldIncludeLocation : false)
                  .set('showPrintLabels', action.payload.showPrintLabels ? action.payload.showPrintLabels : false)
                  .set('parentComponent', action.payload.parentComponent);

    case importNestsWizardActions.IMPORT_NESTS_CLEAR_PARAMETERS:
      return Map(defaultState);

    case importNestsWizardActions.IMPORT_NESTS_ON_ROWS_CHANGED:
      return state.set('nestsErrors', null);

    case importNestsWizardActions.IMPORT_NESTS_HAS_ERRORS:
      return state.set('errors', action.payload);

    case importNestsWizardActions.IMPORT_NESTS_HAS_SELECTED_NESTS_ERRORS:
      return state.set('nestsErrors', action.payload);

    case importNestsWizardActions.IMPORT_NESTS_UPLOAD_FILE_IN_PROGRESS:
      return state.set('loading', true);

    case importNestsWizardActions.IMPORT_NESTS_UPLOAD_FILE_FINISHED:
      return state.set('rollId', action.payload.rollId)
                  .set('materialBusinessId', action.payload.materialBusinessId)
                  .set('errors', null)
                  .set('nestsErrors', null)
                  .set('nestsItems', List(action.payload.cutAndKitItemsGroupedByNest))
                  .set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_UPLOAD_FILE_FAILED:
      return state.set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_VALIDATE_FILE_IN_PROGRESS:
      return state.set('loading', true);

    case importNestsWizardActions.IMPORT_NESTS_VALIDATE_FILE_FINISHED:
      return state.set('validatedFileData', action.payload.data)
                  .set('fileToken', action.payload.token)
                  .set('validationJobId', action.payload.validationJobId)
                  .set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_VALIDATE_FILE_FAILED:
      return state.set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_IMPORT_FILE_IN_PROGRESS:
      return state.set('loading', true);

    case importNestsWizardActions.IMPORT_NESTS_IMPORT_FILE_FINISHED:
      return state.set('importFileData', action.payload)
                  .set('selectedFile', null)
                  .set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_IMPORT_FILE_FAILED:
      return state.set('errors', action.payload)
                  .set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_SELECTED_ASSET_TYPE_CHANGED:
      return state.set('selectedAssetType', action.payload);

    case importNestsWizardActions.IMPORT_NESTS_SELECTED_FILE_CHANGED:
      return state.set('selectedFile', action.payload);

    default:
      return state;
  }

  return state;
}




















import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as BatchJobHelper  from '../../../utils/batchJobHelper';
import CircularProgressBar from 'infrastructure/js/components/CircularProgressBar/circularProgressBar';

require('./batchJobItem.scss');

export default class BatchJobItem extends React.PureComponent {

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.batchJob.');
  }

  render = () => {
    if (!this.props.item) {
      return null;
    }

    let { jobName, totalJobItems, jobRunningDuration,
          numberOfSucceededItems, numberOfFailedItems, numberOfItemsWithWarnings,
        } = this.props.item;

    let title = BatchJobHelper.createJobTitle(jobName, totalJobItems, this.labels);
    let processedJobItems = numberOfSucceededItems + numberOfFailedItems + numberOfItemsWithWarnings;
    let subTitle = BatchJobHelper.createJobSubTitle(processedJobItems, totalJobItems, this.labels);
    let percentage = totalJobItems ? Math.floor(processedJobItems * 100 / totalJobItems) : 0;
    let jobRunningDurationLabel = jobRunningDuration + ' ' + this.labels.get('duration.units');

    return (
      <div className='popup-bo-item'>
        <div className="operation-progress-indicator">
          <div className="indicator-progress">
            <CircularProgressBar  radius={70} strokeWidth={7} percentage={percentage}/>
          </div>
          <div className="indicator-title">
            <div>{title}</div>
            <div>{subTitle}</div>
          </div>
          <div className="indicator-time">
            <div>{jobRunningDurationLabel}</div>
          </div>
        </div>
      </div>
    );
  }
}

BatchJobItem.propTypes = {
  item:  PropTypes.object,
};

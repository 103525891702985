import { Map} from 'immutable';
import { actionTypes as dashboardTabActionTypes }  from '../../../actions/Administration/OrganizationsPage/dashboardTabActions';

let defaultState = {
  productionOnlyMode: true,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case dashboardTabActionTypes.ORGANIZATIONS_DASHBOARD_TAB_UPDATE_FILTER_DATA:
      return  state.set('productionOnlyMode', action.payload.productionOnlyMode);

    default:
      return state;
  }
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as rfidSettingsPageActions }  from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions.js';
import RfidSettingsPage from '../../../components/Administration/RfidSettingsPage/rfidSettingsPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(rfidSettingsPageActions, dispatch)
  }
}

let RfidSettingsPageContainer = connect(
  (state) => {
    return {
        sData: state.administration.get('rfidSettingsPage'),
    };
  },
  mapDispatchToProps
)(RfidSettingsPage);


export default RfidSettingsPageContainer;




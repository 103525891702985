import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';

export const getEditorDataConverter = (type) => {
  return (data) => {
    let name = data.fieldName;
    let convertedData = data[data.fieldName];

    const dataConverters = {
      bag: (obj) => (!obj ? null : obj),
      locationId: ({ value }) => value,
      subLocation: (obj) => obj || null,
      description: (obj) => obj || '',
      tag: (obj) => (Array.isArray(obj) || obj === null ? null : obj.value),
      maxStorageTemp: (obj) =>
        obj === null || obj === undefined
          ? null
          : UnitHelper.userValueToServerValue(unitTypes.TEMPERATURE, obj, 3),
      lot: (obj) =>
        obj === null || Array.isArray(obj)
          ? null
          : obj.value === obj.label
          ? { name: obj.label }
          : { id: obj.value },
      materialId: (obj) => obj,
      length: (obj) => UnitHelper.userValueToServerValue(unitTypes.LENGTH, obj, 2),
      weight: (obj) => UnitHelper.userValueToServerValue(unitTypes.WEIGHT, obj, 2),
      width: (obj) => UnitHelper.userValueToServerValue(unitTypes.WIDTH, obj, 2),
      manufacturer: (obj) =>
        obj === null || Array.isArray(obj)
          ? null
          : obj.value === obj.label
          ? { name: obj.label }
          : { name: obj.label, id: obj.value },
      dateManufacturer: (obj) => obj,
      projectId: (obj) => (obj === null || Array.isArray(obj) ? null : obj),
      inspectionStatus: (obj) => (Array.isArray(obj) || obj === null ? null : obj.value),
      tags: (obj) => obj,
      workOrders: (obj) =>
        obj === null
          ? null
          : obj.value === obj.label
          ? { [obj.label]: null }
          : { [obj.label]: obj.value },
      currentCyclesNumber: (obj) => obj,
      toolTypeBusinessId: (obj) => (obj === null ? null : obj.data.businessId),
      maintenanceUsageTime: (obj) => obj || null,
      destinationId: (obj) => (obj === null || Array.isArray(obj) ? null : obj.value),
      dueDate: (obj) => obj,
      quantity: (obj) => obj,
      pickDate: (obj) => obj,
      partType: (obj) => (obj === null ? null : { id: obj.value }),
      kitType: (obj) => (obj === null ? null : { id: obj.value }),
      customerOrder: (obj) =>
        obj === null || Array.isArray(obj)
          ? null
          : obj.value === obj.label
          ? { businessId: obj.label, id: null }
          : { id: obj.value },
      priority: (obj) => (obj ? obj.value : null),
    };

    if (dataConverters[name]) {
      return dataConverters[name](convertedData);
    } else {
      console.error(type + ':EditorHelper: Unknown field name: ' + name);
      return null;
    }
  };
};

import { Map } from 'immutable';
import { actionTypes as dashboardsDemoDataViewActionsTypes } from '../../../actions/Administration/PsManagementPage/dashboardsDemoDataViewActions';

let defaultState = {
  dashboardsData: [],
  loading: false,
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case dashboardsDemoDataViewActionsTypes.DASHBOARDS_DEMO_DATA_VIEW_FETCH_IN_PROCESS:
      return state.set('loading', true);
    case dashboardsDemoDataViewActionsTypes.DASHBOARDS_DEMO_DATA_VIEW_FETCH_FINISHED:
      return state.set('loading', false);
    case dashboardsDemoDataViewActionsTypes.DASHBOARDS_DEMO_DATA_VIEW_FETCH_READY:
      state = state.set('dashboardsData', action.payload);
      return state.set('loading',false);

    default:
      return state;
  }


  return state;
}













import { Map} from 'immutable';
import { actionTypes as DeleteOrganizationDialogActionTypes }  from '../../../../actions/Administration/OrganizationsPage/Dialogs/deleteOrganizationDialogActions';

let defaultState = {
  show: false,
  loading: false,
  deletionData: null,
  isAcceptDeletion: false,
  isRejectDeletion: false,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case DeleteOrganizationDialogActionTypes.DELETE_ORGANIZATION_DIALOG_SHOW:
      state = state.set('deletionData',     action.payload.deletionData);
      state = state.set('isAcceptDeletion', action.payload.isAcceptDeletion);
      state = state.set('isRejectDeletion', action.payload.isRejectDeletion);
      return state.set('show', true);

    case DeleteOrganizationDialogActionTypes.DELETE_ORGANIZATION_DIALOG_HIDE:
      return Map(defaultState);

    case DeleteOrganizationDialogActionTypes.DELETE_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case DeleteOrganizationDialogActionTypes.DELETE_ORGANIZATION_STATUS_SUBMIT_FINISHED:
      return state.set('loading', false);

    default:
      return state;
  }
}








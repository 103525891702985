import React from 'react';
import PropTypes from 'prop-types';

import {Field} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import PL_FileChooser from '../FileChooser/fileChooser';
import DropDown from 'infrastructure/js/components/controls/Dropdown/dropdown';

require('./assetsFileChooser.scss');

export default class PL_FileChooserField extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_AssetsFileChooser}></Field>
    );
  }
};

export class PL_AssetsFileChooser extends React.PureComponent{

  componentDidMount(){
    let {options} = this.props;

    if(options.length === 1){
      this.props.change('importAssetsTypesDropdown' ,options[0]);
    }
  }

  render() {
    let { options, placeHolder , accept , loading , buttonText , onSelectedFileChanged , onDropDownChange ,selectedFile} = this.props;
    return (
      <div className="assets-file-chooser">

        <DropDown
          key={'importAssetsTypesDropdown'}
          id={'importAssetsTypesDropdown'}
          name={'importAssetsTypesDropdown'}
          className='import-type-selection'
          validate={Validation.dropdown.required}
          options={options}
          onChangeCallback={onDropDownChange}
          isDisabled={options.length === 1 || loading}
        />

        <PL_FileChooser
          placeHolder={placeHolder}
          buttonText={buttonText}
          accept={accept}
          disabled={loading}
          selectedFile={selectedFile}
          onSelectedFileChanged={onSelectedFileChanged} />

      </div>
    );
  }
}

PL_AssetsFileChooser.propTypes = {
  accept: PropTypes.array,
  loading: PropTypes.bool,
  placeHolder:PropTypes.string,
  buttonText:PropTypes.string,
  onSelectedFileChanged: PropTypes.func
};

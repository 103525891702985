import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as scanWorkOrderPageActions } from '../../../actions/Mobile/ScanWorkOrderPage/scanWorkOrderPageActions.js';
import { jsxActions as headerActions } from '../../../actions/Mobile/Header/headerActions.js';
import ScanWorkOrderPage from '../../../components/Mobile/ScanWorkOrderPage/scanWorkOrderPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(scanWorkOrderPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
    },
  };
}

let ScanWorkOrderPageContainer = connect((state) => {
  return {
    sData: state.mobile.get('data'),
    sPageData: state.mobile.get('scanWorkOrderPage'),
  };
}, mapDispatchToProps)(ScanWorkOrderPage);

export default ScanWorkOrderPageContainer;

import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import ComboBox from 'infrastructure/js/components/controls/Combobox/combobox';
import SingleLineMultiSelect from 'infrastructure/js/components/controls/SingleLineMultiSelect/singleLineMultiSelect.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import './printPDFDialog.scss';

const initialLabels = {
  dialogLabels: createLabelHelper('mat.dialog.'),
  printDialogLabels: createLabelHelper('mat.scheduler.gantt.dialog.pdf.'),
  ganttLabels: createLabelHelper('mat.scheduler.gantt.'),
};

const PrintPDFDialog = ({
  show,
  stations,
  handleSubmit,
  initialize,
  change,
  onPrintPDF,
  onDialogToggle,
  resolution,
  updateSchedulerZoom,
  getCurrentViewTimes,
}) => {
  const [labels, setLabels] = useState(initialLabels);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const isSubmittingRef = useRef(false);

  //Update the labels each time the component is mounted.
  useEffect(() => {
    setLabels({
      dialogLabels: createLabelHelper('mat.dialog.'),
      printDialogLabels: createLabelHelper('mat.scheduler.gantt.dialog.pdf.'),
      ganttLabels: createLabelHelper('mat.scheduler.gantt.'),
    });
  }, []);

  const { dialogLabels, printDialogLabels, ganttLabels } = labels;

  const resolutions = useMemo(
    () => [
      { label: ganttLabels.get('select.horizon.oneDay'), value: 'pl_one_day', id: 3 },
      { label: ganttLabels.get('select.horizon.threeDays'), value: 'pl_three_days', id: 2 },
      { label: ganttLabels.get('select.horizon.oneWeek'), value: 'pl_one_week', id: 1 },
      // this options was temporarily removed because it causes a crash in the export process.
      //{ label: ganttLabels.get('select.horizon.twoWeeks'), value: 'pl_two_weeks', id: 0 },
    ],
    [ganttLabels]
  );

  useEffect(() => {
    const { startDate, endDate } = getCurrentViewTimes();

    setStartDate(startDate);
    setEndDate(endDate);

    let selectedRes = resolutions.find(({ id }) => id === resolution);

    if (!selectedRes) {
      selectedRes = resolutions[2];
      updateSchedulerZoom(selectedRes.value);
    }

    initialize({
      exportFrom: startDate,
      exportTo: endDate,
      exportResolution: selectedRes,
      exportStations: [],
    });
  }, [initialize, resolution, resolutions, getCurrentViewTimes, updateSchedulerZoom]);

  const computedStations = useMemo(() => stations.map(({ name, id }) => ({ label: name, value: id })), [stations]);

  const doSubmit = (data) => {
    isSubmittingRef.current = true;
    onPrintPDF(data);
    onDialogToggle(false);
  };

  const hideDialog = () => {
    const { current: isSubmitting } = isSubmittingRef;
    if (!isSubmitting) {
      updateSchedulerZoom(); //restore the original zoom level only if submit was canceled.
    }
    onDialogToggle(false);
  };

  const dialogButtons = {
    left: [
      {
        id: 'cancel',
        text: dialogLabels.get('cancel'),
        action: hideDialog,
      },
    ],
    right: [
      {
        id: 'print',
        text: printDialogLabels.get('print'),
        bsStyle: 'primary',
        action: handleSubmit(doSubmit),
        disabled: !startDate || !endDate,
      },
    ],
  };

  //Resolution must be changed on the fly directly on the instance because of race condition.
  //In some cases, the zoom change is still being applied while the export engine start working on converting the view to HTML.
  //This causes the export process to break.
  const handleResolutionChange = ({ value }) => {
    updateSchedulerZoom(value);
  };

  const handleDateChange = (id, date) => {
    if (id === 'exportFrom') {
      setStartDate(date);
    }

    if (id === 'exportTo') {
      setEndDate(date);
    }

    change(id, date);
  };

  return (
    <Dialog
      id="print-pdf-dialog"
      className="print-pdf-dialog"
      titleText={printDialogLabels.get('title')}
      show={show}
      onHide={hideDialog}
      footerButtons={dialogButtons}
    >
      <InputSection label={ganttLabels.get('dependency.from') + '*'} htmlFor="exportFrom" className="inline left-side">
        <DatePicker
          id="exportFrom"
          name="exportFrom"
          maxDate={endDate}
          popperPlacement="auto"
          onChange={(value) => handleDateChange('exportFrom', value)}
          isClearable={true}
        />
      </InputSection>
      <InputSection label={ganttLabels.get('dependency.to') + '*'} htmlFor="exportTo" className="inline right-side">
        <DatePicker
          id="exportTo"
          name="exportTo"
          minDate={startDate}
          popperPlacement="auto"
          onChange={(value) => handleDateChange('exportTo', value)}
          isClearable={true}
        ></DatePicker>
      </InputSection>
      <InputSection label={printDialogLabels.get('resolution')} htmlFor="exportResolution" className="inline left-side">
        <ComboBox id="exportResolution" name="exportResolution" options={resolutions} onChange={handleResolutionChange}></ComboBox>
      </InputSection>
      <InputSection label={printDialogLabels.get('stations')} htmlFor="exportStations" className="inline right-side">
        <SingleLineMultiSelect
          id="exportStations"
          name="exportStations"
          options={computedStations}
          placeholder={printDialogLabels.get('all.stations')}
          onChange={(value) => change('exportStations', value)}
        ></SingleLineMultiSelect>
      </InputSection>
    </Dialog>
  );
};

PrintPDFDialog.propTypes = {
  stations: PropTypes.array,
  show: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  change: PropTypes.func,
  onPrintPDF: PropTypes.func,
  onDialogToggle: PropTypes.func,
  resolution: PropTypes.number,
  updateSchedulerZoom: PropTypes.func,
  getCurrentViewTimes: PropTypes.func,
};

PrintPDFDialog.defaultProps = {
  stations: [],
  show: false,
  updateSchedulerZoom: () => {
    console.error('updateSchedulerZoom is missing!');
  },
  handleSubmit: () => {
    console.error('handleSubmit is missing!');
  },
  initialize: () => {
    console.error('initialize is missing!');
  },
  change: () => {
    console.error('change is missing!');
  },
  onPrintPDF: () => {
    console.error('onPrintPDF is missing!');
  },
  onDialogToggle: () => {
    console.error('onDialogToggle is missing!');
  },
  resolution: 0,
  getCurrentViewTimes: () => ({ startDate: null, endDate: null }),
};

export default reduxForm({
  form: 'exportSchedulerToPDF',
})(PrintPDFDialog);

import React from 'react';

import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import {enumTypes, getEnumValue, getLookupOptions} from "../../../utils/enumHelper";
import Header, {headerItemsLabels} from "../../Common/Header/header";
import PickListGrid from "../PickListGrid/pickListGrid";
import PermissionManager from "infrastructure/js/utils/permissionManager";
import {PL_DialogWrapper} from "infrastructure/js/components/Dialog/dialog";
import MoveAssetsDialog from "../../Dialogs/MoveAssetsDialog/moveAssetsDialog";
import {FetchEntitiesFilters} from "../../../enums/fetchEntitiesFilters";
import {gridsNames} from "../../../enums/gridsNames";
let moment = require('moment');
require('./pickListAssets.scss');

export default class PickListAssets extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.pickList.page.assets.');
  }

  getMoveAssetsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NOT_CONTAINED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'), getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData, pickList} = this.props;

    let items = {
      title: this.labels.get('title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'moveTo',
          label: headerItemsLabels.MOVE_TO,
          icon: 'pl pl-move-assets',
          disabled: !sHasRows || !sSelectedRowsData || sSelectedRowsData.size === 0,
          action: actions.header.onMenuItemMoveToClick,
          actionData: this.getMoveAssetsDialogConfig()
        },
        {
          id:'remove',
          icon: 'pl pl-remove-from-picklist',
          label: headerItemsLabels.REMOVE_FROM_PICK_LIST,
          counter: sSelectedRowsData.size,
          disabled: !sHasRows || !sSelectedRowsData || sSelectedRowsData.size === 0,
          action: actions.header.onRemoveFromPickListClick,
          actionData: {rows: sSelectedRowsData, pickListId: pickList?.id}
        },
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.header.onExportPickListClick,
          actionData: {gridName: gridsNames.PICK_LIST_PAGE, pickListId: pickList?.id}
        }
      ],
    };
    return items;

  }

  reloadPage = () => {
    this.props.actions?.reload?.(this.props.pickList?.id, true);
  };


  renderMoveAssetsDialog = () => {
    if (PermissionManager.hasLocationPickListTabPermissions()) {
      return null;
    }
    return (
      <PL_DialogWrapper dialogComponent={MoveAssetsDialog}
                        show={this.props.sMoveAssetsData.get('show')}
                        actions={this.props.actions.moveAssetsActions}
                        sData={this.props.sMoveAssetsData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };


  render() {

    let headerItems = this.getHeaderItems();

    return (
      <div className="pick-list-assets">

        <Header  {...headerItems}/>

        <PickListGrid actions={{...this.props.actions.pickListGridActions, ...this.props.actions.fetchEntitiesActions}} />

        { this.renderMoveAssetsDialog() }
      </div>
    );
  }
}

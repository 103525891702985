import PropTypes from 'prop-types'
import './progressCell.scss';

function ProgressCell({ value: progress = 0 }) {
    const getProgressColor = () => (progress < 100 ? '#2ec6ff' : '#00ce6b');
    const getLabelColor = () => (progress < 100 ? '#333' : '#fff');

    return (
        <div className='progress-cell'>
            <div className='pl-progress-bar'>
                <div className='pl-progress-label' style={{ color: getLabelColor() }}>
                    {progress ?? 0}%
                </div>
                <div
                    className='pl-progress'
                    style={{ width: `${progress ?? 0}%`, backgroundColor: getProgressColor() }}
                />
            </div>
        </div>
    );
}

ProgressCell.propTypes = {
  value: PropTypes.number
}

export default ProgressCell;

import Network from 'infrastructure/js/modules/network';

export function editShiftType(shift) {
  return Network.put(`shiftTypes/${shift.id}`,shift);
}

export function createShiftType(data) {
  return Network.post('shiftTypes', data);
}

export function removeShiftTypes(ids) {
  return Network.delete('shiftTypes', {shiftIds: ids.ids});
}

export function fetchShiftTypes() {
  return Network.get('shiftTypes');
}

export function fetchShiftTypesItems(query = {}) {
  return Network.post('shiftTypes/items', query);
}

export function fetchWeeklyShifts(query){
  return Network.post('weeklyShifts/items', query)
}

export function createWeeklyShift(query){
  return Network.post('weeklyShifts', query)
}

export function updateWeeklyShift(query){
  return Network.put('weeklyShifts', query)
}

export function deleteWeeklyShifts(query){
  return Network.delete('weeklyShifts', query)
}

export function redeployWeeklyShiftsTemplate(query){
  return Network.post('weeklyShifts/redeploy', query)
}

export function fetchWeeklyShiftsTemplate(){
  return Network.get('weeklyShiftTemplates')
}

export function createTemplateShift(query){
  return Network.post('weeklyShiftTemplates', query)
}

export function deleteTemplateShifts(query){
  return Network.delete('weeklyShiftTemplates', query)
}

export function updateTemplateShiftHumanCapacity(query){
  return Network.post('weeklyShiftTemplates-hc', query)
}

import React, {Component} from 'react';
import { DropdownButton } from 'react-bootstrap';

import PropTypes from "prop-types";
import PL_Dropdown from "../Dropdown/dropdown";

//Style
require('./dropdownButton.scss');

export default class PL_DropdownButton extends React.PureComponent{
   getLoading = (loading) =>{
     if(!loading) {
       return '';
     } else {
       return (
         <div className="pre-dropdown-button"><i className='fa fa-spinner fa-spin fa-fw' aria-hidden="true"></i></div>
       );
     }
  }
  render() {
    let { className="", loading, ...props} = this.props;
    className += " only-icon fa fa-ellipsis-h";
    return (
      <DropdownButton title={this.getLoading(loading)}  className={className} {...props}
                      pullRight={true} noCaret={true}
                      rootCloseEvent="mousedown"   //TODO: L check it - DropdownButton does not open without this line in react 17.0.2
      >
        {this.props.children}
      </DropdownButton>
    );
  }
}
PL_DropdownButton.propTypes = {
  id:PropTypes.string.isRequired,
};




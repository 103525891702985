import Network                      from 'infrastructure/js/modules/network';
import * as boHelper                from '../batchOperationDialogActionsHelper';
import { api as locationPageApi }   from '../../LocationPage/locationPageActions.js';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import { FormAdapter }              from 'infrastructure/js/utils/formUtils';
import * as locationsService from '../../../services/Locations/locationsService';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import {List} from 'immutable';



let myState = function(getState) {
  return getState().dialogs.get('moveAssetsDialog');
};

let myFormAdapter  = function(dispatch, getState) {
  return new FormAdapter(dispatch, getState, 'moveAssetsDialog');
};



/////////////////////////////////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('MOVEASSETS_DIALOG'),
  MOVEASSETS_DIALOG_FETCH_DESTINATION_READY: 'MOVEASSETS_DIALOG_FETCH_DESTINATION_READY'
};

/////////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('MOVEASSETS_DIALOG'),
  fetchLocationsReady: function(payload) {
    return {type: actionTypes.MOVEASSETS_DIALOG_FETCH_DESTINATION_READY, payload: payload };
  },
};

/////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

const config = {
  submitMethod: locationsService.moveAssets,
  dialogReduxStorageName: 'moveAssetsDialog',
  submitWithProgressMethod: locationsService.startMoveAssets
};

export let api = {
  ...boHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(data) {
      return api.validateMovement(dispatch, getState)(data).then((response) => {
        if (response) {
          dispatch(actions.show(response));
        }
      });
    }
  },

  validateMovement(dispatch, getState) {
    return function (data) {
      if (data && data.preselectedAssets && data.preselectedAssets.size > 0) {
        let assetIds = data.preselectedAssets.toJS().map((item) => {return item.id});

        return locationsService.validateMovement({assetIds}).then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error('Failed to validate assets movement', response);
            messageDialogApi.responseError(dispatch, getState)(response);
            return null;
          }

          let validations = dialogHelper.getResponseValidationDetails(response);
          if (validations) {
            dispatch(actions.setValidationWarningsData(validations));
          }
          let legalAssets = (response.data && response.data.legalAssets) ? response.data.legalAssets : []
          let legalAssetsIds = legalAssets.map((item) => {return item.id});
          let preselectedAssets = data.preselectedAssets.filter((item) => {
            return legalAssetsIds.includes(item.id)})

          data.preselectedAssets = List(preselectedAssets);
          return data;
        });
      }
      return Promise.resolve(data);
    }
  },

  fetchLocations: function (dispatch, getState) {
    return function(options) {

      locationsService.fetchActiveLocations()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Fetch destinations failed');
            return {success: false};
          }

          let myDestinations = response.dataList;

          // Filter destinations if applicable.
          let locationIdToHide = determineLocationIdToHideFromDestinations(dispatch, getState)(options);
          if (locationIdToHide !== null) {
            myDestinations = response.dataList.filter((item) =>
              item.id !== locationIdToHide
            );
          }

          dispatch(actions.fetchLocationsReady(myDestinations));
        });

    };
  }
};



/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),

  fetchLocations: function (options) {
    return function (dispatch, getState) {
      api.fetchLocations(dispatch, getState)(options);
    };
  },

  closeMessageDialog: function () {
    return function (dispatch, getState) {
      messageDialogApi.close(dispatch, getState)();
    };
  }
};




/////////////////////////////////////////////////////////////////////
// HELPERS


//////////////////////////////////////////////////////
// Creates an object with convenience methods
// in which the assets are organized in arrays,
// each under distinct location id:
//
// {
//    assetsByLocation: {
//      "17": [a1, a2, a4],
//      "123": [a3]
//    },
//    ...helperFunctions
// }
//
let groupAssetsByLocationId = function(assets) {

  // Result object.
  let result = {
    assetsByLocation: {}, // data
    isMultipleLocations: function() {
      return (Object.getOwnPropertyNames(this.assetsByLocation).length > 1);
    },
    isSingleLocation: function() {
      return (Object.getOwnPropertyNames(this.assetsByLocation).length === 1);
    },
    isEmpty: function() {
      return (Object.getOwnPropertyNames(this.assetsByLocation).length === 0);
    },
    getSingleLocationId: function() {
      if (this.isSingleLocation()) {
        return +(Object.getOwnPropertyNames(this.assetsByLocation)[0]); // Get single property name, parse to number.
      }
      return null;
    }
  };


  // Group assets by location Id.
  let assetsByLocation = {};
  for (let asset of assets) {

    // Check if we can't access assets location for some reason.
    if (!asset || !asset.location || !asset.location.id) {
      console.error("Can't extract locationId from asset.");
      return null;
    }

    assetsByLocation[asset.location.id] = assetsByLocation[asset.location.id] || [];
    assetsByLocation[asset.location.id].push(asset);

  }

  // Set data to result object.
  result.assetsByLocation = assetsByLocation;
  return result;
};



let determineLocationIdToHideFromDestinations = function (dispatch, getState) {
  return function (options) {

    let { isLocationBound } = options;


    // On Locations page. ===> Get Location ID from Location Page API.
    if (isLocationBound) {
      return locationPageApi.getLocationId(dispatch, getState)();
    }


    //////////////////////////////////////////////////////////////////////////////
    // Determine which location Id to filter out based on preselected assets.

    // Get preselected assets from store.
    let preselectedAssets =  myState(getState).get('preselectedAssets').toJS();

    // Analyze preselected assets.
    let assetsGroupedByLocationId = groupAssetsByLocationId(preselectedAssets);

    // Only one location is preselected ===> use its Id.
    if (assetsGroupedByLocationId.getSingleLocationId()) {
      return assetsGroupedByLocationId.getSingleLocationId();
    }


    // No filtering required.
    return null;

  };
};










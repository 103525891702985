import { actionTypes as createRollActionsTypes } from '../../../actions/LocationPage/AssetsView/createSpoolActions.js';
import MaterialHelper from '../../../utils/materialHelper.js';

import { Map } from 'immutable';

let defaultState = {
  show: false,
  loading: false,
  materials: [],
  lots: [],
  manufacturers: [],
  tags: [],
  projects: [],
  inspectionStatuses: [],
  additionalFields:[],
  weight: null,
  weightError: null
};


export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case createRollActionsTypes.CREATE_SPOOL_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);
    case createRollActionsTypes.CREATE_SPOOL_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);
    case createRollActionsTypes.CREATE_SPOOL_DIALOG_SHOW_DIALOG:
      return state.set("show", true);
    case createRollActionsTypes.CREATE_SPOOL_DIALOG_HIDE_DIALOG:
      return state.set("show", false);
    case createRollActionsTypes.CREATE_SPOOL_DIALOG_FETCH_DATA_READY:
      state = state.setIn(["materials"], action.payload.materials.dataList.map((obj)=> { return MaterialHelper.convertToMaterialItem(obj) }));
      state = state.setIn(["lots"], action.payload.lots.dataList.map((obj)=> { return { value: obj.id, label: obj.name } }));
      state = state.setIn(["manufacturers"], action.payload.manufacturers.dataList.map((obj)=> { return { value: obj.id, label: obj.name} }));
      state = state.setIn(["tags"], action.payload.tags.dataList.map((obj)=> { return { value: obj.id, label: obj.tagKey} }));
      state = state.setIn(["projects"], action.payload.projects.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId} }));
      state = state.setIn(["inspectionStatuses"], action.payload.InspectionStatuses);
      state = state.setIn(["additionalFields"], action.payload.additionalFields.dataList);
      return state;
    case createRollActionsTypes.CREATE_SPOOL_DIALOG_FETCH_WEIGHT_READY:
      state = state.set("loading", false);
      state = state.set("weight", action.payload.weight);
      state = state.set('weightError', action.payload.error);

    default:
      return state;
  }


  return state;
}



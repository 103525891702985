import React from 'react';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import {DefaultWorkOrderTypes} from '../../../../utils/workOrderHelper';
import AdditionalField from '../../../Common/Layout/AdditionalField/AdditionalField';
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper"
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {connect} from "react-redux";
import {enumTypes, getLookupOptions} from "../../../../utils/enumHelper";

class WoAttributes extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.editwoattributes.');
  }

  componentDidMount() {
    this.props.actions.fetchProjects();
    this.props.actions.fetchParts();
    this.props.actions.fetchKitTypes();
    this.props.actions.fetchCustomerOrders();
    this.props.actions.fetchAdditionalFields();
  }

  isProjectComboboxDisabled = (type) => {
    return type === DefaultWorkOrderTypes.KITTYPE_ORIENTED ? true : false;
  }

  handleKitTypeOnChange = (selectedOption) => {
    let relatedProjectData = {
      label: selectedOption.relatedProject.businessId,
      value: selectedOption.relatedProject.id
    };
    this.props.change('project', relatedProjectData);
  }

  renderDefaultWorkOrderTypes = (type) => {
    if(type === DefaultWorkOrderTypes.PARTTYPE_ORIENTED) {
      return (
        <InputSection label={this.labels.get('part')} htmlFor="part" className="inline left-side">
          <Combobox id='part' name="part" options={this.props.sData.get('parts')} />
        </InputSection>
      );
    } else if (type === DefaultWorkOrderTypes.KITTYPE_ORIENTED) {
      return (
        <InputSection label={this.labels.get('kittype')} htmlFor="kitType" className="inline left-side">
          <Combobox id="kitType" name="kitType" options={this.props.sData.get('kitTypes')} onChangeCallback={this.handleKitTypeOnChange} />
        </InputSection>
      );
    } else {
      console.error('error in renderDefaultWorkOrderTypes: invalid type: ' + type);
      return null;
    }
  };

  renderAdditionalFields = ()=>{
    if(!this.props.sData.get('additionalFields')){
      return null;
    }
    return this.props.sData.get('additionalFields').map((field, index)=> {
      return (<AdditionalField key={index} field={field} index={index}/>)
    });
  };

  minDateValidate = Validation.minDate(DateTimeHelper.ConvertToDate({epochDateTime: this.props.orgTime}));

  renderPickDate = ()=>{
    if(!PermissionManager.getOrgPreferences().woPickDateSupported){
      return null;
    }
    let orgTime = DateTimeHelper.ConvertToDate({epochDateTime: this.props.orgTime});
    return <InputSection label={this.labels.get("woPickDate")} htmlFor="pickDate" className="inline left-side">
            <DatePicker id="pickDate" name="pickDate" validate={this.minDateValidate} minDate={orgTime} />
    </InputSection>
  };

  render() {
    if(!this.props.orgTime){
      return null;
    }
    return (
      <div>

        {this.renderDefaultWorkOrderTypes(this.props.defaultWorkOrderType)}

        <InputSection label={this.labels.get("project")} htmlFor="project" className="inline right-side">
          <Combobox id="project" name="project" options={this.props.sData.get("projects")} isDisabled={this.isProjectComboboxDisabled(this.props.defaultWorkOrderType)}/>
        </InputSection>

        <InputSection label={this.labels.get("quantity")} htmlFor="quantity" className="inline left-side">
          <TextField id="quantity" name="quantity" className="short-textfield" normalize={Normalize.number(true, 0, null)}/>
        </InputSection>

       <InputSection label={this.labels.get("customerorder")} htmlFor="customerOrder" className="inline right-side">
         <Combobox id="customerOrder" name="customerOrder" options={this.props.sData.get("customerOrders")} allowNewOption={true}/>
        </InputSection>

        <InputSection label={this.labels.get("duedate")} htmlFor="dueDate" className="inline left-side">
          <DatePicker id="dueDate" name="dueDate" validate={Validation.date} />
        </InputSection>

        <InputSection label={this.labels.get('priority')} htmlFor="priority" className="inline right-side">
          <Combobox id="priority-combobox" name="priority" options={getLookupOptions(enumTypes.PRIORITY_TYPE)}/>
        </InputSection>

        {this.renderPickDate()}

        <div>
          {this.renderAdditionalFields()}
        </div>
      </div>

    );
  }
}

export default connect( state => {
  return { orgTime : state.system.get("serverDatetime")}
})(WoAttributes);




import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Label from 'infrastructure/js/components/Label/label';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./moreInfoCell.scss');

export default class MoreInfoCell extends React.PureComponent {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.cells.moreInfo.');
  }

  render() {
    let data = this.props.value;
    if (!data) {
      return '';
    }

    // For Weight-based orgs.
    if (PermissionManager.isWeightSupported()) {
      return (<div className="active-rolls-more-info-cell">
        <label>{this.labels.get('weight')} <span>{data.weight}</span></label>
        <label>{this.labels.get('lot')} <Label text={data.lot}/></label>
        <label>{this.labels.get('manufacturer')}  <Label text={data.manufacturer}/></label>
      </div>);
    }

    // For regular (Length-based) orgs.
    return (<div className="active-rolls-more-info-cell">
      <label>{this.labels.get('length')} <span>{data.lengthLeft}</span></label>
      <label>{this.labels.get('lot')} <Label text={data.lot}/></label>
      <label>{this.labels.get('manufacturer')}  <Label text={data.manufacturer}/></label>
    </div>);
  }
}

MoreInfoCell.propTypes = {
  value : PropTypes.object
};





import * as nestsService from '../../../services/Nests/nestsService';
import * as rollsService from '../../../services/Rolls/rollsService';
import { api as kittingViewApi }   from '../KittingView/kittingViewActions.js';
import { api as locationPageApi } from '../locationPageActions.js';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import  { api as cancelCutReportDialogApi } from './cancelCutReportDialogActions.js';
import Network from 'infrastructure/js/modules/network';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import * as daHelper from '../../Dialogs/dialogActionsHelper';
import {dialogsNames} from '../../../enums/dialogsNames';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.REPORT_CUT_DIALOG),
  REPORT_CUT_DIALOG_ROLL_CHANGED: 'REPORT_CUT_DIALOG_ROLL_CHANGED'
};

const actions = {
  ...daHelper.getActions(dialogsNames.REPORT_CUT_DIALOG),
  rollChanged: function (payload){
    return {type: actionTypes.REPORT_CUT_DIALOG_ROLL_CHANGED, payload}
  }
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  fetchDialogData(dispatch, getState) {
    return function () {
      let data = {
        businessId: '',
        maxPageSize: 1000000,
        active: true,
      };

      let promiseFetchNests = nestsService.fetchNests(data);

      return Promise.all([promiseFetchNests]).then((allResults) => {
        if (!Network.isResponseValid(allResults[0])) {
          console.error('Fetch Report cut data failed');
          messageDialogApi.responseError(dispatch, getState)(null);
          return {success: false};
        }

        let nests = allResults[0].dataList.map((item, index) => {
          return item;
        });

        let nestsOptions = nests.map((item, index) => {
          return {label: item.businessId, value: item.id};
        });

        let dialogData = {nestsOptions};
        dispatch(actions.fetchDialogDataReady(dialogData));

        return {success: true};
      });
    }
  },

  submit (dispatch, getState) {
    return function(data, messageDialogBuilder) {

      // Inject locationId to all items in the request.
      // (The current server API needs it in this format).
      let locationId = locationPageApi.getLocationId(dispatch, getState)();

      data.cutsToReport.forEach((cutToReport) => {
        cutToReport.locationId = locationId;
      });

      dispatch(actions.setBusy(true));

      let locationDetails = locationPageApi.getLocationDetails(dispatch, getState)();

      let newData = {
        operationId: _getOperationIdByType(getEnumValue(enumTypes.OPERATION_TYPE)('CUT'), locationDetails?.operations ),
        reportedCuts: data.cutsToReport,
        ignoreValidationWarnings: data.ignoreValidationWarnings
      };

      let method =  PermissionManager.isWeightSupported() ? rollsService.reportCutsByWeight : rollsService.reportCuts;
      method(locationId, newData).then((response) => {

        if (!Network.isResponseValid(response)) {
          console.error('Report Cut Failed');
          dispatch(actions.hide());
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }


        let validations = dialogHelper.getResponseValidationDetails(response);
        if (validations){
          dispatch(actions.setValidationWarningsData(validations));
          dispatch(actions.setBusy(false));
          return {success: false};
        }

        // Refresh everything.
        locationPageApi.reload(dispatch, getState)().then((promiseObjects) => {

          function showConfirmationDialog() {
            dispatch(actions.hide());
            // Prepare and open Confirmation Message Dialog.
            //Note: workaround for the failed cuts - to fill the missing rollBusinessId
            response.dataList.forEach((item) => {
              if (item && item.data && item.data.rollId && !item.data.rollBusinessId) {
                item.data.rollBusinessId = _getBusinessIdById(item.data.rollId, data.rollsIdsData)
              }
              return item;
            });
            if (messageDialogBuilder) {
              let messageDialogDescriptor = messageDialogBuilder(
                response.dataList,
                messageDialogApi.close(dispatch, getState),
                api.kitThis(dispatch, getState)
              );

              messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
            }
          }

          if (promiseObjects.kittingView) {
            promiseObjects.kittingView.then(() => {
              showConfirmationDialog();
            });
            return;
          }
          showConfirmationDialog();
        });
      });
    };
  },

  kitThis(dispatch, getState) {
    return (cutId) => {
      messageDialogApi.close(dispatch, getState)();

      // Open Kits tab.
      locationPageApi.activateTab(dispatch, getState)('kitting');

      // Set Current Cut To Kit Dropdown value in the Kits tab.
      kittingViewApi.setCurrentCutToKitDropdownByCutId(dispatch, getState)(cutId);
    }
  },

  onRollChanged(dispatch, getState){
    return (rollData) => {
      dispatch(actions.rollChanged(rollData))
    }
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  onRollChanged(rollData) {
    return function (dispatch, getState) {
      api.onRollChanged(dispatch, getState)(rollData)
    }
  },

  onCancelCutReportClick(cut) {
    return function(dispatch, getState) {
      cancelCutReportDialogApi.openDialog(dispatch, getState)(cut);
    }
  },
}


////////////////////////////////////////////////////////////////

function _getBusinessIdById (rollId, rollsIdsData) {
  if (rollsIdsData) {
    let found = rollsIdsData.find((item) => {
      return (item.rollId === rollId);
    });
    return found ? found.rollBusinessId : rollId;
  }
  return rollId;
}

function _getOperationIdByType(operationType, operations ) {
  let found = operations?.find?.((op) => {
    return op.operationType?.name === operationType;
  })
  return found?.id || null;
}

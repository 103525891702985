import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import { jsxActions as messageDialogActions } from '../actions/MessageDialog/messageDialogActions';


function mapDispatchToProps(dispatch) {
  return { 
    actions: bindActionCreators(messageDialogActions, dispatch)
  }
}

export default connect(
  (state)=>{
    return {
      ...state.messageDialog
    }
  },
  mapDispatchToProps
)(MessageDialog);


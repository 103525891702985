import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {RfidSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import QualifiedEventsViewGrid from './QualifiedEventsViewGrid/qualifiedEventsViewGrid';


require('./qualifiedEventsView.scss');

export default class QualifiedEventsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.qualifiedEvents.view.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_AUTOMATED_WORKFLOWS_QUALIFIED_EVENTS);
  }

  render() {
    // let title = this.labels.get('title');
    let title = 'Automated workflows > Qualified events';

    return (
      <div className="administration-view qualified-events-view">
        <div className="title">{title}</div>

        <QualifiedEventsViewGrid actions={this.props.actions.qualifiedEventsViewGridActions} />

      </div>
    );
  }
}

import React from 'react';

import AttachmentGrid from './AttachmentGrid/attachmentGrid';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import {reduxForm} from "redux-form";
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
require('./attachmentTab.scss');

const TAB_ID = 'attachment';

class AttachmentTab extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state={ hideDeleted: true };
  }

  componentDidMount() {
    let initialValue = {
      hideDeleted: this.state.hideDeleted,
    };
    this.props.change ('hideDeleted', this.state.hideDeleted);
    this.props.initialize(initialValue);
  }

  onMaxValueForUtlCheckboxClick = (event)=>{
    this.setState({hideDeleted: (event.target.checked)});
    this.props.change ('hideDeleted', event.target.checked);
    this.props.actions.assetPageActions.updateAttachmentDeletedItems(event.target.checked)
  }

  render() {
    if (this.props.match.params.tabId !== TAB_ID ) {
      return null;
    }

    return (
      <div className="attachment-view">
        <InputSection htmlFor= "hideDeleted" className="deleted-checkbox">
          <Checkbox name= "hideDeleted" id= "hideDeleted" label={'Hide deleted files'} onClick={this.onMaxValueForUtlCheckboxClick}/>
        </InputSection>
        <AttachmentGrid  actions={this.props.actions.attachmentGridActions}/>
      </div>
    );
  }
}
export default reduxForm({
    form: 'attachmentTab',
  }
)(AttachmentTab);

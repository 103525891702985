import { Map } from 'immutable';
import { actionTypes } from '../../../actions/Administration/SettingsPage/AccessControl/ipWhitelistViewActions';

let defaultState = {
  loading : false,
  ips: [],
  enabled: false
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.IP_WHITELIST_VIEW_LOAD_STARTED: {
      return state.set('loading', true);
    }
    case actionTypes.IP_WHITELIST_VIEW_LOAD_FINISHED: {
      return state.set('loading', false);
    }
    default:
      return state;
  }
}













import * as dashboardService from '../../services/Reports/dashboardService.js';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {api as navigatorApi} from '../AppTopbar/navigatorActions.js';
import {api as systemApi} from '../System/systemActions';
import {navigationStates} from '../../enums/navigationStates';
import Network from "../../../../infrastructure/js/modules/network";
import {api as messageDialogApi} from "../MessageDialog/messageDialogActions";
import * as DashboardHelper from "../../components/Reports/utils/dashboardHelper";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  TOOL_MAINTENANCE_DASHBOARD_PAGE_FETCH_DATA_STARTED: 'TOOL_MAINTENANCE_DASHBOARD_PAGE_FETCH_DATA_STARTED',
  TOOL_MAINTENANCE_PAGE_FETCH_DATA_FINISHED: 'TOOL_MAINTENANCE_PAGE_FETCH_DATA_FINISHED',
  TOOL_MAINTENANCE_PAGE_FETCH_CHART_DATA_FINISHED: 'TOOL_MAINTENANCE_PAGE_FETCH_CHART_DATA_FINISHED',
  TOOL_MAINTENANCE_PAGE_RESET_DATA: 'TOOL_MAINTENANCE_PAGE_RESET_DATA',
  TOOL_MAINTENANCE_PAGE_FETCH_KPI_DATA_FINISHED: 'TOOL_MAINTENANCE_PAGE_FETCH_KPI_DATA_FINISHED',
  TOOL_MAINTENANCE_PAGE_UPDATE_FILTERS: 'TOOL_MAINTENANCE_PAGE_UPDATE_FILTERS'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function () {
    return {type: actionTypes.TOOL_MAINTENANCE_DASHBOARD_PAGE_FETCH_DATA_STARTED};
  },
  fetchDataFinished: function () {
    return {type: actionTypes.TOOL_MAINTENANCE_PAGE_FETCH_DATA_FINISHED};
  },
  fetchKpisDataFinished: function (payload) {
    return {type: actionTypes.TOOL_MAINTENANCE_PAGE_FETCH_KPI_DATA_FINISHED, payload};
  },

  fetchChartDataFinished: function (payload) {
    return {type: actionTypes.TOOL_MAINTENANCE_PAGE_FETCH_CHART_DATA_FINISHED, payload};
  },
  resetState: function (payload) {
    return {type: actionTypes.TOOL_MAINTENANCE_PAGE_RESET_DATA, payload: payload};
  },
  updateFilters: function (payload) {
    return {type: actionTypes.TOOL_MAINTENANCE_PAGE_UPDATE_FILTERS, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.TOOL_MAINTENANCE_DASHBOARD);

    navigatorApi.setLoading(true)(dispatch, getState);

    api.init(dispatch, getState)().then(() => {
      navigatorApi.setLoading(false)(dispatch, getState);
    });
  }
};

jsxActions.fetchData = function (data) {
  return function (dispatch, getState) {
    api.fetchData(dispatch, getState)(data);
  }
};



jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.init = function (dispatch, getState) {
  return function () {
    return api.fetchData(dispatch, getState)('LAST_WEEK').then(() => {

      let label = createLabelHelper('mat.header.navigator.').get('section.reports.toolsMaintenanceDashboard');

      let topNavigatorData = {
        name: label,
        type: navigationStates.TOOL_MAINTENANCE_DASHBOARD,
        id: -1,
        components: []
      };

      navigatorApi.setData(dispatch, getState)(topNavigatorData);
    });
  };
};

api.fetchKpis = function (dispatch, getState) {
  return function (kpisQuery) {
    let averageCapacityPromise = dashboardService.fetchToolsAverageCapacityUtilizationKpi(kpisQuery);
    let mttrPromise = dashboardService.fetchToolsMttrKpi(kpisQuery);

    return Promise.all([averageCapacityPromise, mttrPromise]).then((allResults) => {
      if (!Network.isResponseValid(allResults[0]) || !Network.isResponseValid(allResults[0])){
        console.error('inventory dashboard fetch kpis failed');
        dispatch(actions.fetchDataFinished());
        messageDialogApi.responseError(dispatch, getState)(null);
        return {success: false};
      }
      let averageCapacity = allResults[0].data.data;
      let mttr = allResults[1].data.data;

      let kpiData = {averageCapacity, mttr};
      dispatch(actions.fetchKpisDataFinished(kpiData));
    })
  }
};


api.fetchData = function (dispatch, getState) {
  return function (timeFrame) {
    let loggedInUser = getState().login.get('loggedInUser');
    let isDemoMode = DashboardHelper.isDemoMode(loggedInUser);

    let filters = {timePeriod: timeFrame };

    function fetchDataAction() {
      let fetchChartsPromise = api.fetchChartsData(dispatch, getState)(filters);
      let fetchKpisPromise = api.fetchKpis(dispatch, getState)(filters);

      return Promise.all([fetchChartsPromise, fetchKpisPromise]);
    }

    if (isDemoMode) {
      return api.handleChartDemoData(dispatch, getState)().then((isHandled) => {
        if(isHandled) {
          return Promise.resolve();
        }

        return fetchDataAction();
      });
    };

    return fetchDataAction();
  }
};

api.handleChartDemoData = function(dispatch, getState) {
  return function() {
    dispatch(actions.fetchDataStarted());
    return dashboardService.fetchDashboardsDemoDataSettings().then((response) => {
      if (!Network.isResponseValid(response)) {
        dispatch(actions.fetchDataFinished());
        console.error('Fetch Production dashboard chart data failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return false;
      }
      let settings = (response.data && response.data.settings) ? response.data.settings : [];
      if (!DashboardHelper.isDashboardDemoDataSet(settings, DashboardHelper.dashboardTypes.TOOL_MAINTENANCE)) {
        return false;
      }

      return dashboardService.fetchToolMaintenanceDemoData().then((response) => {
        dispatch(actions.fetchDataFinished());
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Tool Maintenance dashboard - Demo grid data failed');
          messageDialogApi.responseError(dispatch, getState)(response);
          return false;
        }

        let averageCapacity = response.data.averageCapacityUtilization;
        let mttr = response.data.mttr;
        let kpiData = {averageCapacity, mttr};
        dispatch(actions.fetchKpisDataFinished(kpiData));

        let toolsInLocation = response.data.toolsInLocationByStatus;
        let toolsCapacity = response.data.toolCapacityUtilizationAndDefects;
        let chartsData = { toolsInLocation, toolsCapacity};
        dispatch(actions.fetchChartDataFinished({chartsData}));

        return true;
      })
    })
  }
};

api.fetchChartsData = function (dispatch, getState) {
  return function (query) {
    dispatch(actions.fetchDataStarted());
    let toolsInLocationPromise = dashboardService.fetchToolsInLocationByStatusChart(query);
    let averageCapacityUtilization = dashboardService.fetchAverageCapacityUtilizationChart(query);

    return Promise.all([toolsInLocationPromise, averageCapacityUtilization]).then((allResults) => {
      if (!Network.isResponseValid(allResults[0]) || !Network.isResponseValid(allResults[1])) {
        console.error('tools dashboard fetch charts failed');
        messageDialogApi.responseError(dispatch, getState)(null);
        dispatch(actions.fetchDataFinished());
        return {success: false};
      }
      let toolsInLocation = allResults[0].data ? allResults[0].data.toolsInLocationByStatusModel : [];
      let toolsCapacity = allResults[1].data &&  allResults[1].data.toolCapacityUtilizationAndDefectsResponseModel ? allResults[1].data.toolCapacityUtilizationAndDefectsResponseModel: [];

      let chartsData = { toolsInLocation, toolsCapacity};
      dispatch(actions.fetchChartDataFinished({chartsData}));
    });
  }
};

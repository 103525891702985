import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Button from 'infrastructure/js/components/controls/Button/button';
import classNames from 'classnames';

require('./scanInput.scss');

export default class ScanInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.showSubmitButton = !!this.props.onClickHandler;

    this.state = {
      currentInput: '',
      dummyKey: 0,  //dummy key to force the component remount and set the focus on mounting
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue) {
      this.setState({currentInput: nextProps.initialValue});
    }
  }

  onKeyPressHandler = (e) => {
    if (this.props.onKeyPressHandler) {
      this.props.onKeyPressHandler(e);
    }
  };
  onClickHandler = (e) => {
    if (this.props.onClickHandler) {
      this.props.onClickHandler(e);
    }

    this.setState({
      dummyKey: this.state.dummyKey === 0 ? 1 : 0
    });
  };

  onChangeHandler = (value) => {
    this.setState({currentInput:  value})
  };

  onInputClearHandler = () => {
    this.props.change(this.props.name, '');

    this.setState({
      currentInput: '',
      dummyKey: this.state.dummyKey === 0 ? 1 : 0
    });
  };

  render() {

    return (
      <div className="scan-input-container">
        <div className="barcode-input">
          <TextField
            id={this.props.id}
            name={this.props.name}
            key={this.state.dummyKey}
            placeholder={this.props.placeholder}
            onKeyPress={this.onKeyPressHandler }
            onChangeCallback={this.onChangeHandler }
            autoFocus
          />
          <Button id={this.props.id + '-clear'}
                  className="only-icon"
                  icon="pl pl-x-input-close"
                  disabled={!this.state.currentInput}
                  onClick={this.onInputClearHandler}
          />
          { this.showSubmitButton &&
          <Button id={this.props.id + '-submit'}
                  className="only-icon submit"
                  icon="pl pl-arrow-right"
                  disabled={this.props.disabled}
                  onClick={this.onClickHandler}
          />}
        </div>
        <label className="barcode-input-error">{this.props.error}</label>
      </div>
    )
  }

}
ScanInput.defaultProps = {
  disabled: false,
  error: '',
  initialValue: '',
};

ScanInput.propTypes = {
  onKeyPressHandler: PropTypes.func,
  onClickHandler: PropTypes.func,
  change: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  initialValue: PropTypes.string,
};


import React from 'react';
import PropTypes from 'prop-types';

import {Prompt} from 'react-router-dom';
import {Tab} from 'react-bootstrap';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import TabTitle from 'infrastructure/js/components/Tabs/tabTitle';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Header from '../../../../../Common/Header/header';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import CommonCsvSettingsForm from '../CommonCsvSettingsForm/commonCsvSettingsForm';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
require('./commonCsvSettingsView.scss');

 export default class CommonCsvSettingsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.csvSettings.view.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');

    this.initialSelectedTab = this.props.importEnabled ? 'importTab' : this.props.exportEnabled ? 'exportTab' : null;
  }

  componentDidMount () {
    this.onTabClick(this.initialSelectedTab);
  }

  componentWillUnmount() {
     this.props.actions.unmount();
  }

  getHeaderItems = () => {
    return {
      title: this.getHeaderTitle(),
    };
  };

  getHeaderTitle = () => {
    let {entityType} = this.props;
    switch (entityType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        return this.labels.get('title.roll');
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        return this.labels.get('title.spool');
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        return this.labels.get('title.kit');
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        return this.labels.get('title.group');
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP_TYPE'):
        return this.labels.get('title.groupType');
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        return this.labels.get('title.tool');
      case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
        return this.labels.get('title.workOrder');
      case getEnumValue(enumTypes.OBJECT_TYPE)('TASK'):
        return this.labels.get('title.task');
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'):
        return this.labels.get('title.kitType');
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL_TYPE'):
        return this.labels.get('title.toolType');
      case getEnumValue(enumTypes.OBJECT_TYPE)('MATERIAL_TYPE'):
        return this.labels.get('title.materialType');
      case 'PART_TYPE':
        return this.labels.get('title.partType');
      case 'PROJECT':
        return this.labels.get('title.project');
      // case getEnumValue(enumTypes.OBJECT_TYPE)('OPERATION'):  //TODO: L OPERATION
      case 'OPERATION':  //TODO: L OPERATION
        return this.labels.get('title.operation');
      case 'ROLL_USAGE':  //TODO: L ROLL_USAGE
        return this.labels.get('title.rollUsage');
      case getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT'):  //TODO: L ROLL_USAGE
        return this.labels.get('title.shipment');
    }
    console.error('getHeaderTitle(): unknown entity type ' + entityType);
    return '';
  };

  onTabClick = (key) => {
    this.props.actions.onTabClick(key);
  };

  getTabTitles = () => {
    let importTab = <TabTitle title={this.labels.get('tabs.import')} />;
    let exportTab = this.props.entityType !== getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT') && <TabTitle title={this.labels.get('tabs.export')} />;

    return {importTab, exportTab};
  };

  renderLoadingOverlay = () => {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

   renderPrompt = () => {
     return (
       <Prompt
         when={this.props.isDirty}
         message={(location) =>
           this.navigationLabels.get('datanotsaved.text')
         }
       />
     );
   };

   renderImportTab = (tabTitles) => {
     let {sData, entityType, actions, importEnabled} = this.props;

     if ( importEnabled && sData && sData.get('importData')) {

       return (
         <Tab eventKey={'importTab'} animation={false} title={tabTitles.importTab}>
           <CommonCsvSettingsForm
             formType="IMPORT"
             form={'cssSettingsImportForm'}
             formData={sData.get('importData')}
             entityType={entityType}
             labels={this.labels}
             actions={actions}
           />
         </Tab>
       );
     }
     return null;
   };

   renderExportTab = (tabTitles) => {
     let {sData, entityType, actions, exportEnabled} = this.props;

     if ( exportEnabled && sData && sData.get('exportData')) {

       return (
         <Tab eventKey={'exportTab'} animation={false} title={tabTitles.exportTab}>
           <CommonCsvSettingsForm
             formType="EXPORT"
             form={'cssSettingsExportForm'}
             formData={sData.get('exportData')}
             entityType={entityType}
             labels={this.labels}
             actions={actions}
           />
         </Tab>
       );
     }
     return null;
   };

  renderTabs = () => {
    let selectedTab = this.props.sData ? this.props.sData.get('selectedContentTab') : null;
    if (!selectedTab) {
      return null;
    }
    let tabTitles = this.getTabTitles();

    return (
      <div className="common-csv-settings-view-tabs-section">
        <Tabs activeKey={selectedTab} tabType="content" onSelect={this.onTabClick} id="content-tabs">

          {this.renderImportTab(tabTitles)}
          { this.props.entityType !== getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT') && this.renderExportTab(tabTitles)}

        </Tabs>
      </div>
    );
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className='common-csv-settings-view'>
        <Header {...headerItems}/>
        {this.renderTabs()}

        {this.renderLoadingOverlay()}
        {this.renderPrompt()}
      </div>
    );
  }
}

CommonCsvSettingsView.defaultProps = {
  importEnabled: true,
  exportEnabled: true,

};

CommonCsvSettingsView.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  entityType : PropTypes.string.isRequired,
  importEnabled: PropTypes.bool,
  exportEnabled: PropTypes.bool,
};





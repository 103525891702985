import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as billingsPageActions }  from '../../../actions/Administration/BillingPage/billingPageActions';
import BillingPage from '../../../components/Administration/Billing/billingPage';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(billingsPageActions, dispatch)
  }
}

let BillingPageContainer = connect(
  (state) => {
    return {
        sData: state.administration.get('billingPage'),
        sLoggedInUser : state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps
)(BillingPage);


export default BillingPageContainer;




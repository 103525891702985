import Network from 'infrastructure/js/modules/network';

export function update(data, filterId) {
  return Network.put(`timedFloodFilter/${filterId}`, data);
}

export function fetchData(profileId) {
  return Network.get(`deviceAppProfile/${profileId}/timedFloodFilter`);
}

export function updateWorkflowsFilters(data, filterId) {
  return Network.put(`automatedWorkflows/filters/${filterId}`, data);
}

export function fetchWorkflowsFiltersData(profileId) {
  return Network.get(`deviceAppProfile/${profileId}/automatedWorkflows/filters`);
}

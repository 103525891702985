import React from 'react';

import classNames from 'classnames';
import Label from 'infrastructure/js/components/Label/label.js';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';

require('./labelCell.scss');

export default class LabelCell extends React.PureComponent {

  constructor(props){
    super(props);
  }

  getErrorIcon = () => {
    if (!this.props.value.errorMessage) {
      return null;
    }

    return (<AlertPopover
      popoverType={'error'}
      popoverComponent={<span>{this.props.value.errorMessage}</span>}
    />);
  };

  renderAdditionalField = ()=>{
    if(!this.props.data.additionalField){
      return null;
    }
    return (<Label text={this.props.data.additionalField} className="error-message"/>)

  }


  render() {
    if (this.props.value.errorMessage && this.props.value.showOnlyError) {
      return <div className="label-cell-container">
        {this.getErrorIcon()}
        <Label text={this.props.value.errorMessage} className="error-message"/>
      </div>;
    }

    return (<div className={classNames('label-cell-container label-cell', this.props.value.className)}>
      {this.getErrorIcon()}
      <Label text={(this.props.value && this.props.value.value) ? this.props.value.value.toString() : ''} className="error-message"/>
      {this.renderAdditionalField()}
    </div>);
  }
}










import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActivityLogTab from '../../components/WorkOrderPage/ActivityLogTab/activityLogTab.js';
import {gridsNames} from '../../enums/gridsNames';
import { jsxActions as activityLogGridActions } from '../../actions/WorkOrderPage/activityLogTabGridActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      activityLogGridActions:     bindActionCreators(activityLogGridActions, dispatch),
    }
  }
}

let ActivityLogTabContainer = connect(
  (state) => {
    let activityLogTabGrid = state.grid.get(gridsNames.WO_PAGE_ACTIVITY_LOG) ? state.grid.get(gridsNames.WO_PAGE_ACTIVITY_LOG) : null;
    return {
      ssActivityLogTabGrid: activityLogTabGrid
    };
  },
  mapDispatchToProps
)(ActivityLogTab);

export default ActivityLogTabContainer;





import * as schedulerService from '../../services/Scheduler/schedulerService';
import * as schedulerHelper from '../../components/Common/Helpers/SchedulerHelper';
import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import { api as schedulerPageApi } from './schedulerPageActions';
import { actions as schedulerPageActions } from './schedulerPageActions';
import * as tasksService from '../../services/Tasks/tasksService';
import * as boHelper from '../Dialogs/batchOperationActionsHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('SCHEDULER_GANTT'),
  SCHEDULER_SELECTED_TASKS_CHANGED: 'SCHEDULER_SELECTED_TASKS_CHANGED',
  SCHEDULER_GANTT_ZOOM_LEVEL: 'SCHEDULER_GANTT_ZOOM_LEVEL',
  SCHEDULER_GANTT_SCROLL_POSITION: 'SCHEDULER_GANTT_SCROLL_POSITION',
  SCHEDULER_RESET_DATA: 'SCHEDULER_RESET_DATA',
  SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM: 'SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM',
  SCHEDULER_STORE_MOVING_START_DATE: 'SCHEDULER_STORE_MOVING_START_DATE',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('SCHEDULER_GANTT'),
  selectedTasksChanged: function (payload) {
    return { type: actionTypes.SCHEDULER_SELECTED_TASKS_CHANGED, payload: payload };
  },
  zoomLevel: function (payload) {
    return { type: actionTypes.SCHEDULER_GANTT_ZOOM_LEVEL, payload: payload };
  },
  scrollPosition: function (payload) {
    return { type: actionTypes.SCHEDULER_GANTT_SCROLL_POSITION, payload: payload };
  },
  resetSchedulerData: function (payload) {
    return { type: actionTypes.SCHEDULER_RESET_DATA, payload: payload };
  },
  undoLastAction: () => {
    return { type: actionTypes.SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM, payload: { type: 'undo' } };
  },
  redoLastAction: () => {
    return { type: actionTypes.SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM, payload: { type: 'redo' } };
  },
  storeMovedEventOriginDate: (movedItemDate) => {
    return { type: actionTypes.SCHEDULER_STORE_MOVING_START_DATE, payload: movedItemDate };
  },
};
/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),
};

jsxActions.onTaskMove = function (task) {
  return function (dispatch, getState) {
    return api.move(dispatch, getState)(task);
  };
};

jsxActions.onTaskDelete = function (task) {
  return function (dispatch, getState) {
    return api.delete(dispatch, getState)(task);
  };
};

jsxActions.onSelectionChanged = function (selectedTasks) {
  return function (dispatch, getState) {
    return api.updateSelectedTasks(dispatch, getState)(selectedTasks);
  };
};

jsxActions.onTaskDrop = function (taskId, data) {
  return function (dispatch, getState) {
    return api.onTaskDrop(dispatch, getState)(taskId, data);
  };
};

jsxActions.onTaskLock = function (data) {
  return function (dispatch, getState) {
    return api.onTaskLock(dispatch, getState)(data);
  };
};

jsxActions.onTaskUnlock = function (data) {
  return function (dispatch, getState) {
    return api.onTaskUnlock(dispatch, getState)(data);
  };
};

jsxActions.onScrollToNow = function (schedulerInstance) {
  return function (dispatch, getState) {
    return api.scrollToNow(dispatch, getState)(schedulerInstance);
  };
};

jsxActions.onBeforeTaskMove = function (task) {
  return function (dispatch, getState) {
    return api.onBeforeTaskMove(dispatch, getState)(task);
  };
};

jsxActions.resetSchedulerData = function (data, schedulerInstance) {
  return function (dispatch, getState) {
    return api.resetSchedulerData(dispatch, getState)(data, schedulerInstance);
  };
};

jsxActions.reportTaskProgress = function (data) {
  return function (dispatch, getState) {
    return api.reportTaskProgress(dispatch, getState)(data);
  };
};

jsxActions.undo = function (items) {
  return function (dispatch, getState) {
    api.undo(dispatch, getState)(items);
  };
};

jsxActions.redo = function (items) {
  return function (dispatch, getState) {
    api.redo(dispatch, getState)(items);
  };
};

// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

const config = {
  submitMethod: tasksService.bulkUpdateTasksProgress,
};

export let api = {
  ...boHelper.getApiActions(actions, config),
};

api.delete = function (dispatch, getState) {
  return function (task) {
    schedulerPageApi.unAssign(dispatch, getState)([task.eventRecords[0].data]);
  };
};

api.onTaskLock = function (dispatch, getState) {
  return function (task) {
    schedulerPageApi.lock(dispatch, getState)([task.data]);
  };
};

api.onTaskUnlock = function (dispatch, getState) {
  return function (task) {
    schedulerPageApi.unlock(dispatch, getState)([task.data]);
  };
};

api.move = function (dispatch, getState) {
  return function (task, backtrackTime = null) {
    dispatch(schedulerPageActions.setLoading(true));

    const data = task.eventRecords[0].data;
    const { forceStartDate } = task;

    let convertedTask = schedulerHelper.convertToServerData([data], forceStartDate);

    let query = {
      resourceId: data.resourceId,
      toolId: data.plannedTool?.id || null,
      startTime: {
        epochDateTime: convertedTask.events[0].startDate.epochDateTime,
        systemTimeZone: convertedTask.events[0].startDate.systemTimeZone,
      },
      originalTimestamp: backtrackTime,
    };

    return schedulerService.moveTask(data.id, query).then((response) => {
      dispatch(schedulerPageActions.setLoading(false));

      if (!Network.isResponseValid(response)) {
        console.error('Fetch Scheduler data Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        schedulerPageApi.refreshPageData(dispatch, getState)();
        return response;
      }

      let warnings = response?.applicationResponseStatus?.warnings?.subWarnings;
      if (warnings && warnings.length) {
        let desc = _getMessageDialogBuilder('mat.scheduler.operations.view.warning.title', warnings, null, null, null, 'error');
        messageDialogApi.open(dispatch, getState)(desc);
      }

      if (!backtrackTime) {
        const { lastMovedItemDate } = getState().predefinedList.get('scheduler').get('backtrackList');
        schedulerPageApi.addItemToBacktrackList(dispatch)('move', [{ ...task, originalDate: lastMovedItemDate }]);
        dispatch(actions.storeMovedEventOriginDate(null));
      }
      
      schedulerPageApi.refreshPageData(dispatch, getState)();
      return response;
    });
  };
};

api.updateSelectedTasks = function (dispatch) {
  return function (selectedTasks) {
    dispatch(actions.selectedTasksChanged(selectedTasks));
  };
};

api.scrollToNow = function (dispatch) {
  return function (schedulerInstance) {
    let scrollOptions = {
      block: 'start',
      //block: 'center',
      edgeOffset: 50, // px.
      animate: true,
    };

    // Update Redux. Does nothing beyond that currently.
    dispatch(actions.scrollPosition(scrollOptions));

    // Command scheduler engine.
    schedulerInstance.scrollToNow(scrollOptions);
  };
};

api.resetSchedulerData = function (dispatch) {
  return function (data, schedulerInstance) {
    if (schedulerInstance) {
      schedulerInstance.features.timeRanges.showCurrentTimeLine = true;
      schedulerInstance.features.timeRanges.store.forEach((r) => (r.cls = 'shift-range'));
      schedulerInstance.rowHeight = 30;
    }
    dispatch(actions.resetSchedulerData(data));
  };
};

api.onTaskDrop = function (dispatch, getState) {
  return function (taskId, data) {
    api
      .assign(dispatch, getState)(taskId, data)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Assign task failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return;
        }

        schedulerPageApi.refreshPageData(dispatch, getState)();
      });
  };
};

api.assign = function (dispatch, getState) {
  return function (taskId, data, backtrackTime = null, handleValidationWarnings = true) {
    const query = { ...data, originalTimestamp: backtrackTime };
    return schedulerService.assignTask(taskId, query).then((response) => {
      if (!Network.isResponseValid(response)) {
        return response;
      }

      if (handleValidationWarnings) {
        let warnings = _hasValidationWarnings(response.applicationResponseStatus.warnings);
        if (warnings) {
          let message = _getLocalizedValidations(warnings);
          let desc = _getAssignMessageDialogBuilder(message);
          messageDialogApi.open(dispatch, getState)(desc);
        }
      }
      if (!backtrackTime) {
        const { mainTaskId, id } = response.data;
        schedulerPageApi.addItemToBacktrackList(dispatch)('assign', [id, mainTaskId, data]);
      }
      return response;
    });
  };
};

api.onBeforeTaskMove = function (dispatch, getState) {
  return function (task) {
    let labels = createLabelHelper('mat.scheduler.gantt.');
    let title = '';

    dispatch(actions.storeMovedEventOriginDate(task.eventRecord._startDate));

    if (!task) {
      return;
    }
    let operationStatus = task.eventRecord.operationStatus;
    if (operationStatus === 'STARTED') {
      title = labels.get('task.started.notification', undefined, {
        taskName: task.eventRecord.name,
      });
    }
    if (operationStatus === 'COMPLETED') {
      title = labels.get('task.completed.notification', undefined, {
        taskName: task.eventRecord.name,
      });
    }
    if (task.eventRecord.locked) {
      title = labels.get('task.locked.notification', undefined, {
        taskName: task.eventRecord.name,
      });
    }

    if (!title) {
      return;
    }
    let desc = { title, className: 'oneBackground scheduler-page-message-dialog', type: 'error' };
    messageDialogApi.open(dispatch, getState)(desc);
  };
};

api.reportTaskProgress = function (dispatch, getState) {
  return function ({ taskData, status, isReportAsPlanned, isRollbackToScheduled = false }, backtrackTime = null) {
    dispatch(schedulerPageActions.setLoading(true));

    let requestPromise = null;
    if (isRollbackToScheduled) {
      requestPromise = tasksService.rollbackTaskProgress(taskData.id, { originalTimestamp: backtrackTime });
    } else {
      const performedBy = getState().login.get('loggedInUser')?.id;
      const query = {
        tasksProgressList: [
          {
            taskAssignmentId: taskData.id,
            toolId: taskData.plannedTool?.id,
          },
        ],
        locationId: taskData.plannedResource.id,
        status,
        isReportAsPlanned,
        performedBy,
      };

      requestPromise = api.submit(dispatch, getState)(query, _getBatchOperationMessageDialogBuilder());
    }

    return requestPromise
      .then((response) => {
        dispatch(schedulerPageActions.setLoading(false));

        if (response?.responseData?.data?.jobStatus === 'FAILED') {
          return response;
        }

        if (!backtrackTime) {
          const data = {
            taskData,
            status,
            isReportAsPlanned,
          };

          schedulerPageApi.addItemToBacktrackList(dispatch)('reportTaskProgress', [data]);
        }
        schedulerPageApi.refreshPageData(dispatch, getState)();

        return response;
      })
      .catch((err) => {
        dispatch(schedulerPageActions.setLoading(false));
        console.error('Report task progress failed.', err);
        messageDialogApi.responseError(dispatch, getState)();
      });
  };
};

api.undo = function (dispatch, getState) {
  return function (items) {
    api.undoRedoAction(dispatch, getState)(items[items.length - 1], 'undo');
    dispatch(actions.undoLastAction());
    schedulerPageApi.refreshPageData(dispatch, getState)();
  };
};

api.redo = function (dispatch, getState) {
  return function (items) {
    api.undoRedoAction(dispatch, getState)(items[items.length - 1], 'redo');
    dispatch(actions.redoLastAction());
    schedulerPageApi.refreshPageData(dispatch, getState)();
  };
};

api.undoRedoAction = (dispatch, getState) => {
  return function (actionItem, actionDirection) {
    const { time } = actionItem;
    const { action, params } = actionItem[actionDirection];
    const { lock, unlock, unAssign, refreshPageData, clearBacktrackList } = schedulerPageApi;
    const { assign, move, reportTaskProgress } = api;

    const reversibleActions = {
      lock,
      unlock,
      unAssign,
      assign,
      move,
      reportTaskProgress,
    };
    reversibleActions[action](dispatch, getState)(...params, time).then((response = false) => {
      if (!response) {
        return console.error(`response is missing! please check ${action} function and make sure it return the response`);
      }

      const { data } = response;
      if (!data) {
        return console.error('data is missing!');
      }

      if (data.resetClientHistory) {
        clearBacktrackList(dispatch);
      }
      refreshPageData(dispatch, getState)();
    });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
function _getMessageDialogBuilder(titleKey, warnings, fnCloseDialog, submitFunc, submitKey, type) {
  let title = createLabelHelper('').get(titleKey);
  let children = warnings.map((warning, index) => {
    let message = createLabelHelper('').get(warning.id, undefined, warning.params || undefined);
    return <MessageDialog.MessageArea key={'confirmMessageDataRow' + index} text={message} />;
  });

  let buttons = [];
  if (fnCloseDialog) {
    buttons.push({
      id: 'cancel',
      text: createLabelHelper('mat.dialog.').get('cancel'),
      action: fnCloseDialog,
      bsStyle: 'default',
    });
  }
  if (submitFunc) {
    buttons.push({
      id: 'submit',
      text: createLabelHelper('').get(submitKey),
      action: submitFunc,
      bsStyle: 'primary',
    });
  }

  let className = 'scheduler-page-message-dialog';
  return { title, buttons, children, className, type };
}

function _hasValidationWarnings(warnings) {
  if (warnings) {
    return warnings.mainWarning ? [warnings.mainWarning] : warnings.subWarnings && warnings.subWarnings.length > 0 ? warnings.subWarnings : null;
  }
  return null;
}

function _getLocalizedValidations(validations) {
  if (validations && validations.length > 0) {
    let validationsLabels = createLabelHelper('');

    let res = validations.map((m) => {
      //Note: params should be 'undefined' (or {} but not null) when there is no params (for react-intl)
      return validationsLabels.get(m.id, undefined, m.params || undefined);
    });

    return res.join(' ');
  }
  return null;
}

function _getAssignMessageDialogBuilder(message) {
  let title = createLabelHelper('').get('mat.scheduler.operations.view.warning.title');
  let children = [<MessageDialog.MessageArea key="confirmMessageDataRow" text={message} />];
  let className = 'scheduler-page-message-dialog';
  return { title, children, className, type: 'error' };
}

const _getBatchOperationMessageDialogBuilder = () => {
  return (response, fnCloseDialog) => {
    const title = createLabelHelper('').get('mat.scheduler.operations.view.updateStatus.warning.title', '', { count: response.totalJobItems });
    return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
  };
};

import React from 'react';
import {reduxForm} from 'redux-form';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {gridsNames} from '../../../../enums/gridsNames';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import MoreInfoCell from '../../../Common/CustomGridCells/MoreInfoCell/moreInfoCell.js';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {navigationStates} from '../../../../enums/navigationStates';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./archivedGroupsGrid.scss');

class ArchivedGroupsGrid extends React.PureComponent {

  filterConfig = [
      {fieldName: 'availability', filterName: 'availabilityStatus', getOptions: true},
      {fieldName: 'businessId', filterName: 'groupBusinessId', getOptions: false},
      {fieldName: 'groupType.businessId', filterName: 'groupType', getOptions: true},
      {fieldName: 'moreInfo'},
      {fieldName: 'alertStatus', filterName: 'assetAlert'},
      {fieldName: '', filterName: 'groupWorkOrder'},
      {fieldName: '', filterName: 'groupPart'},
      {fieldName: '', filterName: 'groupProject'},
      {fieldName: 'lastStatusChangeDate.epochDateTime', filterName: 'lastStatusChangeDate'}
    ];

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.grid.');
    this.filterLabels = createLabelHelper('mat.grid.columns.moreInfo.filter.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.DUE_DATE, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          sort: 'desc',
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              dueDateLeft: params.data.dueDateLeft,
              locationName: params.data.locationName,
            };
          }
        },
      },
      {
        fieldName: 'availability',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'availabilityStatus',
        width: 130,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return this.getAvailabilityLabel(params.data.availability)
          }
        }
      },
      {
        fieldName: 'lastStatusChangeDate.epochDateTime',
        title: this.labels.get('columns.archivingDate.title'),
        filterType: filterTypes.NONE,
        valueFormatter: (params) => {
          return (params.data && params.data.m_archivingDate ? params.data.m_archivingDate : '');
        },
        width: 200,
        columnOptions:{
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon()),
          valueGetter: (params) => {
            return params.data.lastStatusChangeDate?.epochDateTime;
          },
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.groupId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'groupBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.GROUP_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ARCHIVED],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
                id: params.data.id,
                type: navigationStates.GROUP,
                label:  params.data.businessId
              };
          }
        }
      },
      {
        fieldName: 'groupType.businessId',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'groupType',
        width: 200,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return (params.data.groupType?.businessId || '');
          },
        }
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.moreInfo.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        filterAlignment: filterAlignmentTypes.RIGHT,
        propertyTypes: this.getMoreInfoProperties(),
        columnOptions: {
          cellComponent: MoreInfoCell,
          sortable: false,
          valueGetter: (params) => {
            return {
              part: params.data.partType ? params.data.partType.businessId : null,
              project: params.data.project ? params.data.project.businessId : null,
              workOrder: params.data.workOrder ? params.data.workOrder.businessId : null,
              workOrderId: params.data.workOrder ? params.data.workOrder.id : null
            };
          }
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change,
      }
    ]
  }

  getAvailabilityLabel(availability) {
    if (availability === 'AVAILABLE') {
      return this.labels.get('columns.status.filter.available');
    }
    else if (availability === 'OCCUPIED') {
      return this.labels.get('columns.status.filter.occupied');
    }
    return '';
  }

  getMoreInfoProperties() {
    return [
      {value: 'groupPart', label: this.filterLabels.get('partType') || 'Part Type'},
      {value: 'groupProject', label: this.filterLabels.get('project') || 'Project'},
      {value: 'groupWorkOrder', label: this.filterLabels.get('workOrder') || 'Work Order'},
    ];
  }

  getRowHeight() {
    return 128;
  }

  render() {
    return (
      <div className="archive-groups-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ARCHIVE_GROUPS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}

              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'archivedGroupsGrid'
  }
)(ArchivedGroupsGrid);

import {api as networkApiActions} from "../actions/networkActions";
import {api as systemkApiActions} from "../../../mat/js/actions/System/systemActions";
import * as systemService from 'infrastructure/js/services/systemService';

import FileSaver from 'file-saver';
import * as appHelper from '../utils/appHelper'
let SERVER_PATH;
let dispatch;

SERVER_PATH = appHelper.getServerPath();

const headers = new Headers({
  'Content-Type': 'application/json'
});

const defaultOptions = {
  //credentials : "same-origin",
  credentials : "include",
  redirect : "follow"
};


var _catch = (err, funcPath) => {
  // if application is under building process then return.
  if(dispatch(systemkApiActions.getBuildStatus()) && err.message === 'Failed to fetch'){
    return;
  }
  //Only when server health check return with failed to fetch move the app status to offline mode.
  if(err.message === 'Failed to fetch' && funcPath.includes('healthcheck')){
   dispatch(networkApiActions.OfflineModeOn(_serverHealthCheck));
   return {};
  }
  if(err.message === 'Failed to fetch'){
    _serverHealthCheck();
  }

  if(err.name === 'AbortError'){
    console.error('Aborted by UI, request path: ' + funcPath)
    return err
  }

  return {};
};

let _serverHealthCheck = () => {
   systemService.getServerHealthCheck((response)=>{
     return response;
   })
};

function _redirectToLogin(errorMessage = ""){
  if (window.location.hash === "#/Login") {
    return;
  }

  console.error('Redirect to Login after error - ' + errorMessage);
  dispatch(systemkApiActions.clearAllData());
  window.location.hash = '/Login';
}

function _response(res, ignore404NotFound=false){

  dispatch(networkApiActions.OfflineModeOff());
  if (!res.ok && res.type === "opaqueredirect") {
    _redirectToLogin("opaqueredirect")
    throw Error(res);
  }

  if(!res.ok && res.status >= 500){
    return {status : res.status};
  }

  if(!res.ok &&  res.status === 404){
    if (!ignore404NotFound) {
      location.hash = '/PageNotFound';
      return {status : res.status};
    }
  }

  if(!res.ok &&  (res.status == 440 || res.status == 401)){
    postFunc('users/checkLogin').then((response) => {
      if (!isResponseValid(response) || !response.data || !response.data.data) {
        _redirectToLogin("user not logged in");
      }
    });
  }

  if(!res.ok && res.status == 403){
    return res.json().then((response)=>{
      return response;
    });
  }

  return res.json().then((response)=>{
    if(response.applicationResponseStatus && response.applicationResponseStatus.errorCode === "SEC_10401"){
      _redirectToLogin("errorCode SEC_10401");
    }
    return response;

  });
};

//this method calls an url without the application prefix
function getExternalFunc(apiFunc, callback) {

  try {
    let oReq = new XMLHttpRequest();
    let getResponse = function () {
      if (this.readyState == 4 && this.status == 200) {
        callback(JSON.parse(this.responseText));
      }
    };
    oReq.onerror= function(e) {
      _catch({message:'Failed to fetch'},apiFunc);
    };
    oReq.onreadystatechange = getResponse;
    oReq.open("GET", apiFunc, true);
    oReq.send();
  }
  catch (err) {
    return callback(err);
  }
}

function getFunc(apiFunc, data, options = {}){
  let extendedOptions = {...defaultOptions, ...options};
  let {ignore404NotFound, blobResponse,  ...otherOptions} = extendedOptions;

  if(!apiFunc){console.error("getFunc - missing apiFunc"); return;}
  return fetch(SERVER_PATH + apiFunc + getQueryString(data), {
    method: 'get',
    headers,
    ...otherOptions
  }).then((response) => {

    if(blobResponse){
      return response;
    }
    return _response(response, ignore404NotFound);

  }).catch(function(err) {
    _catch(err, apiFunc);
  });
}

function postFunc(apiFunc, data = {}, options = {}){
  let extendedOptions = {...defaultOptions, ...options};
  let {blobResponse, ignore404NotFound, ...otherOptions} = extendedOptions;


  if(!apiFunc){console.error("postFunc - missing apiFunc"); return;}
  return fetch(SERVER_PATH + apiFunc, {
    method: 'post',
    headers,
    body : JSON.stringify(data),
    ...otherOptions
  }).then((response) => {

    if(blobResponse){
      return response;
    }

    return _response(response, ignore404NotFound);

  }).catch(function(err) {
    return _catch(err, apiFunc);
  });
}

function putFunc(apiFunc, data = {}, options = {}){
  let extendedOptions = {...defaultOptions, ...options};
  if(!apiFunc){console.error("putFunc - missing apiFunc"); return;}
  return fetch(SERVER_PATH + apiFunc, {
    method: 'put',
    headers,
    body : JSON.stringify(data),
    ...extendedOptions
  }).then(response => {
    return _response(response);
  }).catch(function(err) {
    _catch(err, apiFunc);
  });
}


function deleteFunc(apiFunc, data={}, options = {}){
  let extendedOptions = {...defaultOptions, ...options}
  if(!apiFunc){console.error("putFunc - missing apiFunc"); return;}
  return fetch(SERVER_PATH + apiFunc, {
    method: 'DELETE',
    headers,
    body : JSON.stringify(data),
    ...extendedOptions
  }).then(response => {
    return _response(response);
  }).catch(function(err) {
    _catch(err, apiFunc);
  });
}

//Utils
function getQueryString(query, onlyQuery) {
  if(!query){return '';}

  var queryString = "";

  if(!onlyQuery){queryString += "?";}

  queryString += Object.keys(query)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(query[key]))
    .join("&")
    .replace(/%20/g, "+");

  return queryString;
}


function getDto(res){
  return res.data;
}

//Check if response have multi statuses items.
function _isDataListItemsResponse(response){return (response.dataList ? true : false);}

//Check if multi items response is success.
function _checkDataListItemsResponse(response) {
  let itemsWithError = response.dataList.filter((item) => {
    return item.applicationResponseStatus && (item.applicationResponseStatus.statusType !== "OK");
  });

  if(itemsWithError.length > 0) {return false;}
  return true;
}

function hasValidationWarnings(response) {
  if (response === null || response === undefined) {
    return null;
  }
  let status = response.applicationResponseStatus;
  if (status && (status.statusType === 'WARNING' || status.statusType === 'VALIDATION_ERROR')) {

    let errors = _getValidationErrors(status.errors);
    let warnings = _getValidationWarnings(status.warnings);
    if (!errors && !warnings) {
      return null;
    }
    return {errors, warnings};
  }
}

function _getValidationErrors(errors) {
  if (errors) {
    return (errors.mainError) ? [errors.mainError] : (errors.subErrors && errors.subErrors.length > 0) ? errors.subErrors : null;
  }
  return null;
}
function _getValidationWarnings(warnings) {
  if (warnings) {
    return (warnings.mainWarning) ? [warnings.mainWarning] : (warnings.subWarnings && warnings.subWarnings.length > 0) ? warnings.subWarnings : null;
  }
  return null;
}

//Check if the response from the server is valid.
function isResponseValid(response){
  try {
    if (response === null || response === undefined) {
      return false;
    }

    let {statusType} = response.applicationResponseStatus;
    if(response.errors && response.errors.length){
      return false ;
    }

    if (statusType !== "OK" && statusType !== "WARNING" && statusType !== "VALIDATION_ERROR") {
      return false;
    }

    if(response?.name === 'AbortError'){
      return false;
    }
    return true;
  }
  catch(e){
    return false;
  }
}

function isAuthenticationFailed(response, consoleErrorPrefix){
  if(response && response.applicationResponseStatus &&  response.applicationResponseStatus.errorCode === "SEC_10401"){
    localStorage.removeItem("loggedInUser");
    console.error(consoleErrorPrefix, " Authentication issue");
    return true;
  }
  return false;
}

//The function gets blobResponse and open the file in the browser for download.
function loadBlobResponse(response, fileType){
  //response.body object is one-use only.
  //To allow multiple use (calling response.json() after calling the response.blob()),
  //the response should be cloned before.
  let clonedResponse = response.clone();

  return response.blob().then((blob)=> {
    let contentDispositionHeader = response.headers.get("Content-Disposition");
    if (!contentDispositionHeader) {
      console.error("could not receive contentDispositionHeader for loading csv response");
      return _response(clonedResponse);
    }

    let fileNameUtfEncoding = contentDispositionHeader.substring(contentDispositionHeader.search("filename=")+9,contentDispositionHeader.search(`${'.'}${fileType}` ) + fileType.length + 1);
    let fileName = decodeURIComponent(fileNameUtfEncoding);

    FileSaver.saveAs(blob, fileName);

    return {applicationResponseStatus: {statusType: 'OK'}};
  });
}


function postFileFunc(apiFunc, file = {}, data = {}, options = {}, ) {
  let extendedOptions = {...defaultOptions, ...options};
  let formData = new FormData();
  formData.append('file', file);

  if (data) {
    Object.keys(data).forEach(keyName => {
      formData.append(keyName, data[keyName]);
    });
  }

 return fetch(SERVER_PATH + apiFunc, {
    method: 'post',
    body : formData,
   ...extendedOptions
  }).then((response) => {
    return _response(response);
  }).catch(function(err) {
    _catch(err, apiFunc);
  });
}

//init the store dispatch on load, use when error occurs.
function initDispatch(_dispatch){
  dispatch = _dispatch;

}


//////////////////////////////////////////////////////////////

const POLL_INTERVAL = 1000;
const MAX_TRIES_NUM = 3600;

function pollingPostFunc(apiFunc, data = {}, options = {}) {
  return pollingFunc (postFunc, apiFunc, data, options);
}

function pollingPutFunc(apiFunc, data = {}, options = {}) {
  return pollingFunc (putFunc, apiFunc, data, options);
}

function pollingFunc(method, apiFunc, data = {}, options = {}) {
  return method(apiFunc, data, options).then(response => {
    if(!isResponseValid(response)) {
      console.error(`pollingFunc - ${method.name} response: `, response);
      return response;
    }

    if (options.callback) {
      options.callback(response);
    }

    // if (response.data && !_isJobFinished(response.data.jobStatus)) {
    if (response.data && response.data.jobStatus !== undefined && !_isJobFinished(response.data.jobStatus)) {
      return pollingResult( response.data.jobExecutionId , options);
    }

    return response;
  })
    .catch(error => {
      console.error(`pollingFunc - ${method.name} catch `, error);
      throw error;
    })
}

function pollingResult(jobExecutionId , options) {
  let triesNumber = 0;
  let request = `batchJobs/${jobExecutionId}/status`;

  if(options && options.pollingRequest) {
    request = options.pollingRequest.replace('JOB_EXECUTION_ID', jobExecutionId);
  }

  const handler = (resolve, reject) => {
    getFunc(request , {})
      .then(response => {
        if(!isResponseValid(response)) {
          console.error('pollingResult(): response: ', response);
          return reject(response);
        }

        if (!_isJobFinished(response.data.jobStatus) && triesNumber < MAX_TRIES_NUM) {
          _log(response.data.jobStatus, response.data.jobInstanceId, triesNumber );

          if(options && options.pollingCallback) {
            options.pollingCallback(response);
          }
          triesNumber++;
          setTimeout(handler, POLL_INTERVAL, resolve, reject);
        }
        else if (_isJobFinished(response.data.jobStatus)) {
          _log(response.data.jobStatus, response.data.jobInstanceId, triesNumber );
          if(options && options.pollingCallback) {
            options.pollingCallback(response);
          }
          resolve(response);
        }
        else if (triesNumber >= MAX_TRIES_NUM) {
          console.error(`pollingResult(): the number of maximum permitted attempts (${MAX_TRIES_NUM}) was exceeded `);
          _log(response.data.jobStatus, response.data.jobInstanceId, triesNumber );
          reject(response);
        }

      })
      .catch(err => {
        console.error('pollingResult(): catch ', err);
        reject(err);
      })
  };

  return new Promise(handler);
}

function _isJobFinished(status) {
  return status === 'COMPLETED' || status === 'STOPPED' || status === 'FAILED' || status === null;
}

function _log(status, jobInstanceId, counter) {
  let DEBUG_MODE = false;

  // if (DEBUG_MODE) {
  //   console.log('----- status: ' + status + ' jobInstanceId: ' + jobInstanceId + ' counter: ' + counter);
  // }
}


export default {
  getQueryString,
  getDto,
  get : getFunc,
  getExternalFunc : getExternalFunc,
  post : postFunc,
  postFile:postFileFunc,
  put: putFunc,
  delete: deleteFunc,
  Headers: headers,
  SERVER_PATH,
  hasValidationWarnings,
  isResponseValid,
  isAuthenticationFailed,
  initDispatch,
  loadBlobResponse: loadBlobResponse,
  polling: {
    post : pollingPostFunc,
    put : pollingPutFunc,
    pollingResult: pollingResult,
  }
};


import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import CommonAlertsView from '../Components/CommonAlertsView/commonAlertsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';

require('./reserved.scss');

export default  class ReservedView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.reserved.view.');
  }

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_RESERVED);

    this.props.actions.load(getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'));
  }

  render() {
    return (
      <div className="reserved-view">
        <CommonAlertsView labels={this.labels}
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          alertType={getEnumValue(enumTypes.ALERT_TYPE)('RESERVED')}
        />
      </div>
    );
  }
}


import React from 'react';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import ScanHeader from '../Common/ScanHeader/scanHeader';
import ScanBottom from '../Common/ScanBottom/scanBottom';
import ScanDetails from '../Common/ScanDetails/scanDetails';
import * as AssetDetailsHelper from '../../../components/AssetPage/OverviewTab/AssetDetails/utils/assetDetailsHelper';
import { getEnumValue, enumTypes } from '../../../utils/enumHelper';
import ScanMultiItems from '../Common/ScanMultiItems/scanMultiItems';
import TagItem from './components/TagItem';

require('./scannedToolPage.scss');

class ScannedToolPage extends React.PureComponent {
  constructor(props) {
    super(props);

    let scannedTool = this.props.sData.get('scannedTool');
    if (!scannedTool) {
      this.props.history.push('/ScanTool');
    }

    this.labels = createLabelHelper('mat.mobile.scannedToolPage.');
    this.toolStatusLabels = createLabelHelper('enum.tool.status.');

    this.state = {
      errorTags: [],
    };
  }

  componentDidMount() {
    let scannedTool = this.props.sData.get('scannedTool');
    this.tags = scannedTool['tags'];
    if (this.tags) {
      this.initFormValues(this.tags);
    }
  }

  initFormValues = (value) => {
    let initialValues = {
      tags: value,
    };
    this.props.initialize(initialValues);

    this.initialTagKey = value;
  };

  componentWillUnmount() {
    this.props.actions.clear();
  }

  onSubmit = (data) => {
    let scannedTool = this.props.sData.get('scannedTool');
    if (scannedTool) {
      let toolId = scannedTool.id;
      let tags = data.tags;

      this.props.actions.submit(
        toolId,
        tags,
        this.onSubmitCallback,
        this.onErrorCallback,
        this.tagsTrackedNature
      );
    }
  };

  onSubmitCallback = () => {
    let path = '/ScanTool';
    this.props.history.push(path);
  };

  onErrorCallback = (errorTags = []) => {
    const tagKeys = errorTags.map(({ tag: { tagKey } }) => tagKey);
    this.setState({ errorTags: tagKeys });
  };

  getAlertIcon = (asset, alertType) => {
    return AssetDetailsHelper.getIcon(asset, alertType);
  };

  getHeaderItems = () => {
    return {
      leftButton: {
        id: 'leftButton',
        icon: 'pl pl-arrow-left',
        action: this.props.actions.header.onGoBack,
        actionData: this.props.history,
      },
      rightButton: null,
      middleButton: {
        id: 'middleButton',
        label: this.labels.get('header.buttons.button.scannedTool'),
      },
    };
  };

  getBottomItems = () => {
    let loading = this.props.sPageData.get('loading');
    let error = this.props.sPageData.get('error');
    return {
      button: {
        id: 'saveChangesButton',
        action: this.props.handleSubmit(this.onSubmit),
        label: this.labels.get('bottom.button.saveChanges'),
        loading: loading,
        disabled: this.props.pristine || !!error,
      },
    };
  };

  getDetailsItems = () => {
    let tool = this.props.sData.get('scannedTool');
    if (tool) {
      return [
        {
          label: this.labels.get('details.toolId'),
          body: <label>{tool.businessId}</label>,
        },
        {
          label: this.labels.get('details.tag'),
          body: this.renderTagEditor(this.tags),
        },
        {
          label: this.labels.get('details.type'),
          body: <label>{tool.type}</label>,
        },
        {
          label: this.labels.get('details.progressStatus'),
          body: (
            <label>
              {tool.toolStatusDisplayKey
                ? createLabelHelper('').get(tool.toolStatusDisplayKey)
                : tool.status}
            </label>
          ),
        },
        {
          label: this.labels.get('details.nextMaintenance'),
          body: (
            <label>
              {tool.cycles}{' '}
              {this.getAlertIcon(tool, getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT'))}
            </label>
          ),
        },
        {
          label: this.labels.get('details.location'),
          body: <label>{tool.locationName}</label>,
        },
      ];
    }
    return [];
  };

  renderTagEditor = (tags = []) => {
    //let errorText = this.props.sPageData.get('error');
    const { change } = this.props;
    const { errorTags } = this.state;

    this.tagsTrackedNature = tags.length > 0 ? tags[0].trackedNature : null;

    const getInvalidItems = (items) => {
      const computedItems = items.map((item) => {
        const { tagKey } = item;
        return { ...item, valid: !errorTags.includes(tagKey) };
      });

      const invalidItems = computedItems.filter(({ valid }) => !valid);
      return { computedItems, invalidItems };
    };

    const handleChange = (value) => {
      change('tags', value);
    };

    return (
      <div className="tag-section">
        <ScanMultiItems
          id="tags"
          name="tags"
          value={tags}
          placeholder={'Type or Scan new tag...'}
          item={TagItem}
          onChange={handleChange}
          getInvalidItems={getInvalidItems}
        />
      </div>
    );
  };

  getLoadingOverlay() {
    if (!this.props.sPageData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }

  getSuccessOverlay() {
    if (!this.props.sPageData.get('success')) {
      return null;
    }
    return <Overlay.Success />;
  }

  render() {
    let headerItems = this.getHeaderItems();
    let bottomItems = this.getBottomItems();
    let detailsItems = this.getDetailsItems();

    return (
      <div className="scanned-tool-page">
        <ScanHeader {...headerItems} />
        <ScanDetails details={detailsItems} />
        <ScanBottom {...bottomItems} />
        {this.getLoadingOverlay()}
        {this.getSuccessOverlay()}
      </div>
    );
  }
}

export default reduxForm({
  form: 'scannedToolPage',
  onChange: (values, dispatch, props) => {
    if (props && props.sPageData && props.sPageData.get('error')) {
      props.actions.clearError();
    }
  },
})(ScannedToolPage);

ScannedToolPage.propTypes = {
  change: PropTypes.func,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  sData: PropTypes.object,
  actions: PropTypes.object,
  history: PropTypes.object,
  sPageData: PropTypes.object,
  pristine: PropTypes.bool,
};

ScannedToolPage.defaultProps = {
  change: () => false,
  initialize: () => false,
  handleSubmit: () => false,
  sData: {},
  actions: {},
  history: {},
  sPageData: {},
  pristine: false,
};

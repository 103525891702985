import React, {Component} from 'react';

require('./fullScreenTabsSeparator.scss');

export default class FullScreenTabsSeparator extends Component{

  render() {
    return (
        <div className="full-screen-tabs-separator"><div><div></div></div></div>
    );
  }
}

import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "../../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";
import * as logsService from "../../../services/Logs/logsService";
import DateTimeHelper from "../../../../../infrastructure/js/utils/dateTimeHelper";
import {enumTypes, getEnumValue, getLookupOptions} from "../../../utils/enumHelper";
import UnitsHelper from 'infrastructure/js/utils/uomHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_SETTINGS_SENSORS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return logsService.fetchSensorsLogs(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {

  let items = rows.map((obj) => {
    obj.m_deviationUp = `+${obj.deviationUp} ${UnitsHelper.getUomForTelemetry(obj.uom)}`;
    obj.m_deviationDown = `- ${obj.deviationDown} ${UnitsHelper.getUomForTelemetry(obj.uom)}`;
    obj.m_samplePeriod = DateTimeHelper.ConvertSecondsToMinutesSeconds(obj.samplingPeriodSec);
    obj.m_type = getLookupOptions(enumTypes.TELEMETRY_SENSOR_TYPE).find((x)=> x.value === obj.type).label;
    return obj;
  });
  return items;
};


export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import Network from 'infrastructure/js/modules/network';

export function bulkUpdateTasksProgress(data) {
  return Network.polling.post('planner/tasks/progress', data);
}

export function updateSingleTaskProgress(taskAssignmentId, data) {
    return Network.post(`planner/tasks/progress/${taskAssignmentId}`, data);
}

export function rollbackTaskProgress(taskAssignmentId, data) {
    return Network.post(`planner/tasks/progress/rollback/${taskAssignmentId}`, data);
}

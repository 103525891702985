import Network from 'infrastructure/js/modules/network';
import {FetchEntitiesFilters} from "../../enums/fetchEntitiesFilters";

export function fetchCompletedWorkOrders(query = {}) {
  // TODO -remove after Server side fix
  return Network.post('workorders/completed/items', query);
}

export function fetchActiveWorkOrders(query = {}) {
  // TODO -remove after Server side fix
  return Network.post('workorders/items?active=true', query);
}

export function fetchWorkOrders() {
  return Network.get('workorders');
}

export function searchWorkOrders(data) {
  delete data.entityType;
  return Network.post('workorders/search/items?isRelatedAssetsIncluded=true', data);
}

export function getWorkOrdersForSmartSelection(query) {
  return Network.post('pickup/workorders', query);
}

export function fetchWorkOrdersByStationId(stationID, defaultWorkOrderType, query = {}) {
  // TODO -remove after Server side fix
  if (query.sortByProperty == "woHasAssets") {
    query.isPostProcessingSort = true;
  }
  return Network.post(`stations/${stationID}/workorders/items?active=true`, query);
}

export function fetchWorkOrdersTasksByStationId(stationID, query = {}) {
  return Network.post(`stations/${stationID}/planner-operations/items`, query);
}

export function markWorkOrderAsComplete(locationId, wo){
  return Network.put(`locations/${locationId}/workorders/${wo.id}/set-complete-status`);
}

export function reportOperationLayup(data){
  return Network.post('workorders/report-layup',data);
}

export function reportOperationMerge(data){
  return Network.post('workorders/report-merge',data);
}
export function reportOperationMergeWithWeight(data){
  return Network.post('workorders/report-merge-by-weight',data);
}

export function reportOperationAssetsToAssets(data){
  return Network.post('workorders/report-operation-assets-to-assets',data);
}
export function reportOperationAssetsToAssetsWithConsumption(data){
  return Network.post('workorders/report-operation-assets-to-assets-consumption',data);
}
export function reportOperationAssetsToAssetsWithConsumptionWithWeight(data){
  return Network.post('workorders/report-operation-assets-to-assets-consumption-by-weight',data);
}

export function ReportOperationCuring(data){
  return Network.post('workorders/report-operation-curing',data);
}

export function ReportOperationUncuring(data){
  return Network.post('workorders/report-un-curing',data);
}

export function ReportOperationDemolding(data){
  return Network.post('workorders/report-operation-demolding', data);
}

export function reportOperationAssetsRelation(data){
  return Network.post('workorders/report-operation-assets-relation',data);
}

export function reportOperationAssetsAssociation(data){
  return Network.post('workorders/report-operation-assets-association',data);
}

export function fetchRelatedAssetWorkOrderById(woId){
  return Network.get(`workorders/${woId}/related-assets`);
}

function _getUrlQueryParamsByFilters(filters) {
  if (filters) {
    let queryParams = [];
    filters.forEach((filter) => {
      switch (filter) {
        case FetchEntitiesFilters.HAS_ATTACHED_ASSET:
          return queryParams.push('isAttached=true');
        case FetchEntitiesFilters.NO_ATTACHED_ASSET:
          return queryParams.push('isAttached=false');
        case FetchEntitiesFilters.CURED:
          return queryParams.push('isCured=true');
        case FetchEntitiesFilters.NOT_CURED:
          return queryParams.push('isCured=false');
        case FetchEntitiesFilters.NOT_CONTAINED:
          return queryParams.push('isContained=false');
        case FetchEntitiesFilters.NOT_RELATED:
          return queryParams.push('isRelatedToKit=false');
      }
    })
    return queryParams.length > 0 ? '?' + queryParams.join('&') : ''
  }
  return '';
}

export function fetchWoRelatedAssets(woId, fetchEntitiesFilters) {
  let baseUrl = `workorders/${woId}/related-assets`;
  let queryParams = _getUrlQueryParamsByFilters(fetchEntitiesFilters);

  return Network.get(baseUrl + queryParams);
}

export function FetchCharts(station){
  let woCreatedPromise = Network.get(`stations/${station}/workorderscountcreated`);
  let woHandledPromise = Network.get(`stations/${station}/workorderscounthandled`);

  return Promise.all([woCreatedPromise, woHandledPromise]);
}

export function generateWorkOrderId() {
  return Network.get('workorders/generateId');
}

export function createWorkOrder(workOrderData) {
  return Network.post(`workorders`, workOrderData);
}

export function updateWorkOrder(data) {
  return Network.post('workorders/update', data);
}

export function fetchWorkOrderById(workOrderId){
  return Network.get(`workorders/${workOrderId}`);
}

export function updateWorkOrderStatus(data){
  let workOrderId = data.workOrderId;
  delete data.workOrderId;
  return Network.put(`workorders/${workOrderId}/change-status`, data);
}
export function getworkOrderActivityLog(workOrderId, query = {}){
  return Network.post(`workorders/${workOrderId}/activities`, query);
}
export function editWoAttributes(data, callback) {
  return Network.polling.post('workorders/multiUpdate' , data.updateData, {callback});
}

export function updateWorkOrdersStatus(data, callback) {
  //data.workOrdersIds = data.workOrdersIds.concat(_createDummyIds(200));
  return Network.polling.put('workorders/updateWorkOrdersStatus' , data, {callback});
}
export function fetchWorkOrderPickDates(){
  return Network.get('workorders/pickup-dates');
}
export function fetchWorkOrdersMaterials(ids) {
  return Network.post('workorders/materials', {workOrderIds:ids});
}
export function fetchWorkOrdersMaterialsByWeight(ids) {
  return Network.post('workorders/materials-by-weight', {workOrderIds:ids});
}
export function fetchOperationStatus(){
  return Network.get('operation/statuses');
}





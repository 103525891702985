import {api as gridApi} from './automationScenariosViewGridActions';
import Network from 'infrastructure/js/modules/network';
import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import * as automatedWorkflowsService from '../../../services/Rfid/automatedWorkflowsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  RFID_SETTINGS_AUTOMATION_SCENARIOS_FETCH_DEVICE_APPS_IN_PROCESS: 'RFID_SETTINGS_AUTOMATION_SCENARIOS_FETCH_DEVICE_APPS_IN_PROCESS',
  RFID_SETTINGS_AUTOMATION_SCENARIOS_FETCH_DEVICE_APPS_READY: 'RFID_SETTINGS_AUTOMATION_SCENARIOS_FETCH_DEVICE_APPS_READY',
  RFID_SETTINGS_AUTOMATION_SCENARIOS_DEVICE_APP_CHANGED: 'RFID_SETTINGS_AUTOMATION_SCENARIOS_DEVICE_APP_CHANGED',
  RFID_SETTINGS_AUTOMATION_SCENARIOS_UNMOUNT: 'RFID_SETTINGS_AUTOMATION_SCENARIOS_UNMOUNT',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  fetchDeviceAppsInProgress: function () {
    return {type: actionTypes.RFID_SETTINGS_AUTOMATION_SCENARIOS_FETCH_DEVICE_APPS_IN_PROCESS};
  },
  fetchDeviceAppsFinished: function (payload) {
    return {type: actionTypes.RFID_SETTINGS_AUTOMATION_SCENARIOS_FETCH_DEVICE_APPS_READY, payload};
  },
  deviceAppChanged: function (payload) {
    return {type: actionTypes.RFID_SETTINGS_AUTOMATION_SCENARIOS_DEVICE_APP_CHANGED, payload};
  },
  unmount: function(items) {
    return {type: actionTypes.RFID_SETTINGS_AUTOMATION_SCENARIOS_UNMOUNT};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid) {
  return function (dispatch, getState) {
    api.load(dispatch, getState)(shouldRefreshGrid);
  }
};
jsxActions.onDeviceAppChanged = function (data) {
  return function (dispatch, getState) {
    api.deviceAppChanged(dispatch, getState)(data);
  }
};

jsxActions.onRemoveAutomationScenariosClick = function(messageDialogBuilder , selectedItemsIds) {
  return function(dispatch, getState) {
    api.openRemoveItemsConfirmationDialog(dispatch, getState)(messageDialogBuilder , selectedItemsIds);
  }
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function (dispatch, getState) {
  return function (shouldRefreshGrid) {

    dispatch(actions.fetchDeviceAppsInProgress());

    return deviceAppsService.fetchDeviceApps().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('FETCH_DEVICE_APPS failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let deviceAppsOptions = response.dataList.map((obj) => {
        // return {value: obj.id, label: obj.username}
        return {value: obj.id, label: obj.username, data: obj}
      });

      if (shouldRefreshGrid) {
        gridApi.reload(dispatch, getState)();
      }

      dispatch(actions.fetchDeviceAppsFinished(deviceAppsOptions));
    });
  }
};

api.deviceAppChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.deviceAppChanged(data));
    gridApi.reload(dispatch, getState)();
  }
};

api.openRemoveItemsConfirmationDialog = function(dispatch, getState) {
  return function(messageDialogBuilder , selectedItemsIds) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.removeAutomationScenarios(dispatch, getState)(selectedItemsIds)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

  }
};

api.removeAutomationScenarios = function(dispatch, getState) {
  return function(selectedItemsIds) {

    messageDialogApi.close(dispatch, getState)();

    automatedWorkflowsService.deleteAutomationScenarios(selectedItemsIds).then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('Failed to remove Automation Scenarios', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success : false};
      }

      api.load(dispatch, getState)(true);
    });
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '../dialog';
import Button from 'infrastructure/js/components/controls/Button/button';
import {concatClassNames} from '../../../utils/componentUtils.js';

//Style
require('./messageDialog.scss');
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import PL_Grid from "../../Grid/grid";
import {List} from "immutable";


export default class PL_MessageDialog extends React.PureComponent {
  constructor (props){
    super(props);

  }
  //Get the dialog header icon image by prop type.
  getHeaderIcon(){
    let icon;

    switch (this.props.type) {
      case 'confirmation':
      case 'success':
      case 'reportedCuts':
        icon = 'pl-check-circle-icon-blue';
        break;
      case 'alert':
      case 'error':
        icon = 'pl-error-icon';
        break;
      case 'warning':
        icon = 'pl-warning-icon';
        break;
      case 'canceled':
        icon = 'pl-operation-canceled';
        break;
      default:
        icon = 'pl-check-circle-icon-blue';
        break;
    }

    return icon;
  }

  //Get the dialog header jsx data.
  getTitle(){
    let {title} = this.props;

    if (this.props.showTitleIcon) {
      return (<div>
        <span className={`header-icon pl ${this.getHeaderIcon()}`}/>
        <label className="title">{title}</label>
      </div>);
    }
    return (<div>
      <label className="title">{title}</label>
    </div>);
  }

  //Get buttons array data.
  getButtons(){
    let {buttons} = this.props;
    if (buttons && buttons.length > 0) {
      return buttons.map((buttonObj)=> {
        return (<Button
          id={buttonObj.id}
          key={`id_${buttonObj.text}`}
          bsStyle={buttonObj.bsStyle}
          onClick={buttonObj.action}
          loading={buttonObj.loading}
          style={buttonObj.style}>{buttonObj.text}
        </Button>);
      });
    }

    return "";
  }
  render() {
    let { id, children, className, show } = this.props;
    let { onHide, onEntered, onExited } = this.props.actions;
    let dialogProps = { id, show, onHide, onEntered, onExited };

    return (
      <Dialog {...dialogProps} className={concatClassNames("message-dialog", className)} titleComponent={this.getTitle()} moreInfoTextHeader="" >
          <div>{children}</div>
          <div className="buttons-container">
            {this.getButtons()}
          </div>
      </Dialog>
    )
  }
}

PL_MessageDialog.defaultProps = {
  showTitleIcon : true,
};

PL_MessageDialog.propTypes = {
  id : PropTypes.string,
  type : PropTypes.string.isRequired,
  children : PropTypes.any.isRequired,
  show : PropTypes.bool.isRequired,
  titleText : PropTypes.string,
  showTitleIcon : PropTypes.bool,
  buttons : PropTypes.array,
  //onHide : PropTypes.func.isRequired,
  onEntered : PropTypes.func,
  onExited : PropTypes.func
};

class DataRow extends React.PureComponent {
  render = ()=> {
    let {label, value, id} = this.props;
    return (
      <div id={id ? id : ''} className="line">
          <span className="label">{label}</span>
          <span className="value">{value}</span>
      </div>);
  }
}
DataRow.propTypes = {
  label: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}
PL_MessageDialog.DataRow = DataRow;



class StatusRow extends React.PureComponent {
  constructor(props){
    super(props);
    this.labels = createLabelHelper('mat.dialog.messagedialog.statusrow');
  }
  getIconClass(){
    let iconClass = 'pl pl-check-circle-icon-blue';

    if (this.props.type === 'warning') {
      iconClass = "pl pl-warning-icon";
    }
    if (this.props.type === 'failed') {
      iconClass = "pl pl-error-icon";
    }
    if (this.props.type === 'canceled') {
      iconClass = "pl pl-operation-canceled";
    }
    return iconClass;
  }

  render = ()=> {
    let {type, count} = this.props;
    return (
      <div className={`multistatus-line ${type}`}>
        <span className={`line-icon ${this.getIconClass()}`}>&nbsp;</span>
        <span className="line-count">{count}</span>
        <span className="line-explanation">{this.labels.get(`.label.${type}`)}</span>
      </div>);
  }
}

PL_MessageDialog.StatusRow = StatusRow;


class StatusImportFileRow extends React.PureComponent {
  constructor(props){
    super(props);
    this.labels = createLabelHelper('mat.dialog.import.file.statusrow.');
    this.typeLabels = createLabelHelper('mat.wizards.import.types.');
  }
  getIconClass(){
    let iconClass = 'pl pl-check-circle-icon-blue';

    if (this.props.status === 'warning') {
      iconClass = 'pl pl-warning-icon';
    }
    if (this.props.status === 'failed') {
      iconClass = 'pl pl-canceled-icon';
    }

    return iconClass;
  }

  render = ()=> {
    let {type, status, count , assetType, actionType} = this.props;
    let assetLabel = this.typeLabels.get(assetType, undefined, {count});
    return (
      <div className={`multistatus-line ${status}`}>
        <span className={`line-icon ${this.getIconClass()}`}></span>
        <span className="line-count">{count}</span>
        <span className="line-explanation">{ this.labels.get(`label.${actionType}.${type}`, undefined, {count:count, actionType:actionType, asset:assetLabel})}</span>
      </div>);
  }
}
StatusImportFileRow.propTypes = {
  actionType: PropTypes.string
};

StatusImportFileRow.defaultProps = {
  actionType: "create"
};

PL_MessageDialog.StatusImportFileRow = StatusImportFileRow;


class MessageRow extends React.PureComponent {

  render = ()=> {
    let {icon, text} = this.props;
    let iconClass = (icon === 'succeeded') ? 'pl pl-check-circle-icon-blue' :
                    (icon === 'failed') ? 'pl pl-error-icon' : '';
    return (
      <div className="message-row">
        {icon  && <span className={iconClass}/>}
        {text && <span>{text}</span>}
      </div>);
  }
}

MessageRow.defaultProps = {
  icon: null,
};
MessageRow.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.any,
};
PL_MessageDialog.MessageRow = MessageRow;


class MessageArea extends React.PureComponent {
  render = () => {
    let {text} = this.props;

    return (text && <div className="message-area">{text}</div>);
  }
}
MessageArea.defaultProps = {
  text: null,
};

MessageArea.propTypes = {
  text: PropTypes.any,
};
PL_MessageDialog.MessageArea = MessageArea;

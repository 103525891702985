import { api as createAssetTypeWizardApi } from '../../actions/Administration/MatSettingsPage/Dialogs/createAssetTypeWizardActions.js';
import { api as createGroupTypeDialogAPI } from '../../actions/Administration/MatSettingsPage/Dialogs/createGroupTypeDialogActions.js';
import { api as createMaterialTypeDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/createMaterialTypeDialogActions.js';
import { api as createToolCategoryDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/createToolCategoryDialogActions.js';
import { api as createToolTypeDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/createToolTypeDialogActions.js';
import { api as importToolTypesDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/importToolTypesDialogActions.js';
import { api as createKitTypeDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/createKitTypeDialogActions.js';
import { api as importKitTypesDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/importKitTypesDialogActions.js';
import { api as createPartTypeDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/createPartTypeDialogActions.js';
import { api as createShiftTypeDialogApi } from '../Administration/MatSettingsPage/Dialogs/createShiftTypeDialogActions.js';
import { api as createProjectDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/createProjectDialogActions.js';
import { api as createReasonTypeDialogApi } from '../../actions/Administration/MatSettingsPage/Dialogs/createReasonTypeDialogActions.js';
import { api as matSettingsPageApi } from '../../actions/Administration/MatSettingsPage/matSettingsPageActions.js';
import { api as createOperationDialogApi } from '../Administration/MatSettingsPage/Dialogs/createOperationDialogActions';
import { api as createDeviceAppDialogApi } from '../../actions/Administration/RfidSettingsPage/Dialogs/createDeviceAppDialogActions.js';
import { api as createPrinterDialogApi } from '../../actions/Administration/RfidSettingsPage/Dialogs/createPrinterDialogActions.js';
import { api as createAlertDeviceDialogApi } from '../../actions/Administration/RfidSettingsPage/Dialogs/createAlertDeviceDialogActions.js';
import { api as createReaderDialogActionsApi } from '../Administration/RfidSettingsPage/Dialogs/createReaderDialogActions';
import { api as createMqttBrokerDialogActionsApi } from '../Administration/RfidSettingsPage/Dialogs/createMqttBrokerDialogActions';
import { api as createMqttSensorDialogActionsApi } from '../Administration/RfidSettingsPage/Dialogs/createMqttSensorDialogActions';
import { api as createAutomationScenarioDialogActionsApi } from '../Administration/RfidSettingsPage/Dialogs/createAutomationScenarioDialogActions';

import { api as importFilesWizardActionsApi } from '../Wizards/ImportFiles/importFilesWizardActions';
import { api as communicationParametersApi } from '../Administration/RfidSettingsPage/communicationParametersViewActions.js';
import { api as filtersParametersApi } from '../Administration/RfidSettingsPage/filtersParametersViewActions.js';
import { api as workflowFiltersApi } from '../Administration/RfidSettingsPage/workflowFiltersViewActions.js';
import { api as createVisibilityDeviceDialogApi } from '../../actions/Administration/RfidSettingsPage/Dialogs/createVisibleDeviceDialogActions.js';
import { api as editPreferenceDialogApi } from '../../actions/Administration/SettingsPage/Dialogs/editPreferenceDialogActions.js';
import { api as createSensorDialogApi } from '../../actions/Administration/SettingsPage/Dialogs/createSensorDialogActions.js';
import { api as sensorLogsApi } from '../Administration/SettingsPage/sensorsViewActions';
import {api as exportApi} from '../Export/exportActions.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.importItems = function (config) {
  return function (dispatch, getState) {
    importFilesWizardActionsApi.openImportFilesWizard(dispatch, getState)(config);
  }
};

jsxActions.exportItems = function (config) {
  return function (dispatch, getState) {
    exportApi.exportGridRows(dispatch, getState)(config);
  }
};

//---asset types---
jsxActions.onCreateAssetTypeClick = function (data) {
  return function (dispatch, getState) {
    createAssetTypeWizardApi.openWizard(dispatch, getState)(data);
  }
};

jsxActions.onEditAssetTypeClick = function (data) {
  return function (dispatch, getState) {
    createAssetTypeWizardApi.openWizard(dispatch, getState)(data);
  }
};

jsxActions.onRemoveAssetTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeAssetType(dispatch, getState)(data);
  }
};

//---group types---
jsxActions.onCreateGroupTypeClick = function () {
  return function (dispatch, getState) {
    createGroupTypeDialogAPI.show(dispatch, getState)();
  }
};

jsxActions.onEditGroupTypeClick = function (data) {
  return function (dispatch, getState) {
    createGroupTypeDialogAPI.show(dispatch, getState)(data);
  }
};

jsxActions.onActivateGroupTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.activateGroupTypes(dispatch, getState)(data);
  }
};

jsxActions.onDeactivateGroupTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.deactivateGroupTypes(dispatch, getState)(data);
  }
};

jsxActions.onRemoveGroupTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeGroupTypes(dispatch, getState)(data);
  }
};

//---material types---
jsxActions.onCreateMaterialTypeClick = function () {
  return function (dispatch, getState) {
    createMaterialTypeDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditMaterialTypeClick = function (data) {
  return function (dispatch, getState) {
    createMaterialTypeDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onActivateMaterialTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.activateMaterialTypes(dispatch, getState)(data);
  }
};

jsxActions.onDeactivateMaterialTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.deactivateMaterialTypes(dispatch, getState)(data);
  }
};

jsxActions.onRemoveMaterialTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeMaterialTypes(dispatch, getState)(data);
  }
};

//---tool categories---
jsxActions.onCreateToolCategoryClick = function () {
  return function (dispatch, getState) {
    createToolCategoryDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditToolCategoryClick = function (data) {
  return function (dispatch, getState) {
    createToolCategoryDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onActivateToolCategoriesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.activateToolCategories(dispatch, getState)(data);
  }
};

jsxActions.onDeactivateToolCategoriesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.deactivateToolCategories(dispatch, getState)(data);
  }
};

jsxActions.onRemoveToolCategoriesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeToolCategories(dispatch, getState)(data);
  }
};

//---tool types---
jsxActions.onCreateToolTypeClick = function () {
  return function (dispatch, getState) {
    createToolTypeDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditToolTypeClick = function (data) {
  return function (dispatch, getState) {
    createToolTypeDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onActivateToolTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.activateToolTypes(dispatch, getState)(data);
  }
};

jsxActions.onDeactivateToolTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.deactivateToolTypes(dispatch, getState)(data);
  }
};

jsxActions.onRemoveToolTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeToolTypes(dispatch, getState)(data);
  }
};

jsxActions.onMenuItemImportToolTypesJsonClick = function (data) {
  return function (dispatch, getState) {
    importToolTypesDialogApi.show(dispatch, getState)();
  }
};

//---kit types---
jsxActions.onCreateKitTypeClick = function () {
  return function (dispatch, getState) {
    createKitTypeDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditKitTypeClick = function (data) {
  return function (dispatch, getState) {
    createKitTypeDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onActivateKitTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.activateKitTypes(dispatch, getState)(data);
  }
};

jsxActions.onDeactivateKitTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.deactivateKitTypes(dispatch, getState)(data);
  }
};

jsxActions.onRemoveKitTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeKitTypes(dispatch, getState)(data);
  }
};

jsxActions.onMenuItemImportKitTypesJsonClick = function (data) {
  return function (dispatch, getState) {
    importKitTypesDialogApi.show(dispatch, getState)();
  }
};

//---operations---
jsxActions.onCreateOperationClick = function () {
  return function (dispatch, getState) {
    createOperationDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditOperationClick = function (data) {
  return function (dispatch, getState) {
    createOperationDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onActivateOperationsClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.activateOperations(dispatch, getState)(data);
  }
};

jsxActions.onDeactivateOperationsClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.deactivateOperations(dispatch, getState)(data);
  }
};

jsxActions.onRemoveOperationsClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeOperation(dispatch, getState)(data);
  }
};

jsxActions.exportOperationSequence = function () {
  return function (dispatch, getState) {
    exportApi.exportOperationSequence(dispatch, getState)();
  }
};

//---part types---
jsxActions.onCreatePartTypeClick = function () {
  return function (dispatch, getState) {
    createPartTypeDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditPartTypeClick = function (data) {
  return function (dispatch, getState) {
    createPartTypeDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onActivatePartTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.activatePartTypes(dispatch, getState)(data);
  }
};

jsxActions.onDeactivatePartTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.deactivatePartTypes(dispatch, getState)(data);
  }
};

jsxActions.onRemovePartTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removePartTypes(dispatch, getState)(data);
  }
};

//---shifts---
jsxActions.onCreateShiftTypeClick = function () {
  return function (dispatch, getState) {
    createShiftTypeDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditShiftTypeClick = function (data) {
  return function (dispatch, getState) {
    createShiftTypeDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onRemoveShiftTypesClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeShiftTypes(dispatch, getState)(data);
  }
};

//---projects---
jsxActions.onCreateProjectClick = function () {
  return function (dispatch, getState) {
    createProjectDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditProjectClick = function (data) {
  return function (dispatch, getState) {
    createProjectDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onActivateProjectsClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.activateProjects(dispatch, getState)(data);
  }
};

jsxActions.onDeactivateProjectsClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.deactivateProjects(dispatch, getState)(data);
  }
};

jsxActions.onRemoveProjectsClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeProjects(dispatch, getState)(data);
  }
};

//---reasons---
jsxActions.onCreateReasonTypeClick = function () {
  return function (dispatch, getState) {
    createReasonTypeDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditReasonTypeClick = function (data) {
  return function (dispatch, getState) {
    createReasonTypeDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onChangeActiveReasonTypesStatusClick = function (data) {
  return function (dispatch, getState) {

    let newData = data.map((item) => {
      item.active = !item.active;
      return item;
    });

    matSettingsPageApi.changeActiveReasonTypesStatus(dispatch, getState)({'reasonTypes' : newData});
  }
};

jsxActions.onRemoveReasonClick = function (data) {
  return function (dispatch, getState) {
    matSettingsPageApi.removeReason(dispatch, getState)(data);
  }
};

//---device apps---
jsxActions.onCreateDeviceAppClick = function () {
  return function (dispatch, getState) {
    createDeviceAppDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditDeviceAppClick = function (data) {
  return function (dispatch, getState) {
    createDeviceAppDialogApi.show(dispatch, getState)(data);
  }
};

//---printer---
jsxActions.onEditPrinterClick = function (data) {
  return function (dispatch, getState) {
    createPrinterDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onCreatePrinterClick = function () {
  return function (dispatch, getState) {
    createPrinterDialogApi.show(dispatch, getState)();
  }
};

//---alert devices---
jsxActions.onCreateAlertDeviceClick = function (data) {
  return function (dispatch, getState) {
    createAlertDeviceDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onEditAlertDeviceClick = function (data) {
  return function (dispatch, getState) {
    createAlertDeviceDialogApi.show(dispatch, getState)(data);
  }
};

//---mqtt brokers---
jsxActions.onCreateMqttBrokerClick = function (data) {
  return function (dispatch, getState) {
    createMqttBrokerDialogActionsApi.show(dispatch, getState)(data);
  }
};

jsxActions.onEditMqttBrokerClick = function (data) {
  return function (dispatch, getState) {
    createMqttBrokerDialogActionsApi.show(dispatch, getState)(data);
  }
};

//---mqtt sensors---
jsxActions.onCreateMqttSensorClick = function (data) {
  return function (dispatch, getState) {
    createMqttSensorDialogActionsApi.show(dispatch, getState)(data);
  }
};

jsxActions.onEditMqttSensorClick = function (data) {
  return function (dispatch, getState) {
    createMqttSensorDialogActionsApi.show(dispatch, getState)(data);
  }
};

//---autoomation scenarios---
jsxActions.onCreateAutomationScenarioClick = function (data) {
  return function (dispatch, getState) {
    createAutomationScenarioDialogActionsApi.show(dispatch, getState)(data);
  }
};

jsxActions.onEditAutomationScenarioClick = function (data) {
  return function (dispatch, getState) {
    createAutomationScenarioDialogActionsApi.show(dispatch, getState)(data);
  }
};

//---readers---
jsxActions.onCreateReaderClick = function () {
  return function (dispatch, getState) {
    createReaderDialogActionsApi.show(dispatch, getState)();
  }
};

jsxActions.onEditReaderClick = function (data) {
  return function (dispatch, getState) {
    createReaderDialogActionsApi.show(dispatch, getState)(data);
  }
};

//---mqtt parameters---
jsxActions.onSaveCommunicationParametersClick = function (data) {
  return function (dispatch, getState) {
    communicationParametersApi.update(dispatch, getState)(data);
  }
};

jsxActions.onSaveFiltersParametersClick = function (data) {
  return function (dispatch, getState) {
    filtersParametersApi.update(dispatch, getState)(data);
  }
};

jsxActions.onSaveWorkflowsFiltersClick = function (data) {
  return function (dispatch, getState) {
    workflowFiltersApi.update(dispatch, getState)(data);
  }
};

//---visibility devices---
jsxActions.onEditVisibilityDeviceClick = function (data) {
  return function (dispatch, getState) {
    createVisibilityDeviceDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onCreateVisibilityDeviceClick = function () {
  return function (dispatch, getState) {
    createVisibilityDeviceDialogApi.show(dispatch, getState)();
  }
};

//---sensors---
jsxActions.onCreateSensorClick = function () {
  return function (dispatch, getState) {
    createSensorDialogApi.show(dispatch, getState)();
  }
};

jsxActions.onEditSensorClick = function (data) {
  return function (dispatch, getState) {
    createSensorDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onRemoveSensorLogClick = function (data) {
  return function (dispatch, getState) {
    sensorLogsApi.delete(dispatch, getState)(data);
  }
};

jsxActions.onActivateSensorLogClick = function (data) {
  return function (dispatch, getState) {
    sensorLogsApi.activate(dispatch, getState)(data);
  }
};

jsxActions.onDeactivateSensorLogClick = function (data) {
  return function (dispatch, getState) {
    sensorLogsApi.deactivate(dispatch, getState)(data);
  }
};

jsxActions.onEditPreferenceClick = function (data) {
  return function (dispatch, getState) {
    editPreferenceDialogApi.show(dispatch, getState)(data);
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
// export let api = {};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as importWizardActions } from '../../../actions/Wizards/ImportNestsAnyRolls/importNestsAnyRollsWizardActions.js';
import importNestsAnyRollsWizard from '../../../components/Wizards/ImportNestsAnyRollsWizard/importNestsAnyRollsWizard';
import {gridsNames} from '../../../enums/gridsNames.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(importWizardActions, dispatch)
    }
  }
}


let ImportNestsAnyRollsWizardContainer = connect(

  (state) => {
    return {
      importTypes: state.importNestsAnyRolls.get('importTypes'),
      acceptFileTypes:state.importNestsAnyRolls.get('acceptFileTypes'),
      loading:state.importNestsAnyRolls.get('loading'),
      sErrors: state.importNestsAnyRolls.get('errors'),
      sNestsErrors: state.importNestsAnyRolls.get('nestsErrors'),
      sValidatedFileData: state.importNestsAnyRolls.get('validatedFileData'),
      sFileToken:state.importNestsAnyRolls.get('fileToken'),
      sSelectedAseetType:state.importNestsAnyRolls.get('selectedAssetType'),
      sSelectedFile: state.importNestsAnyRolls.get('selectedFile'),
      currentDate:state.importNestsAnyRolls.get('currentDate'),
      parentComponent:state.importNestsAnyRolls.get('parentComponent'),
      rollIds:state.importNestsAnyRolls.get('rollIds'),
      materialBusinessId:state.importNestsAnyRolls.get('materialBusinessId'),
      nestsItems: state.importNestsAnyRolls.get('nestsItems'),
      selectedNests: state.grid.get(gridsNames.IMPORT_NESTS_ANY_ROLLS_SELECT_NESTS) ? state.grid.get(gridsNames.IMPORT_NESTS_ANY_ROLLS_SELECT_NESTS).get('selectedRowsData') : List(),
      plan:state.importNestsAnyRolls.get('plan'),
    };
  },
  mapDispatchToProps
)(importNestsAnyRollsWizard);

export default ImportNestsAnyRollsWizardContainer;



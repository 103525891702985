import React, {Component} from 'react';

import PL_Wizard  from 'infrastructure/js/components/Wizard/wizard';
import CreateAssetTypeStepOneDialog from './CreateAssetTypeStepOneDialog/createAssetTypeStepOneDialog';
import CreateAssetTypeStepTwoDialog from './CreateAssetTypeStepTwoDialog/createAssetTypeStepTwoDialog';


require('./createAssetTypeWizard.scss');


export default class CreateAssetTypeWizard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.steps = {
      'step_1': <CreateAssetTypeStepOneDialog />,
      'step_2': <CreateAssetTypeStepTwoDialog />
    };
  }


  render() {
    return (
      <PL_Wizard wizardName="CREATE_ASSET_TYPE_WIAZRD" steps={this.steps} {...this.props} form="wizard_form_CREATE_ASSET_TYPE_WIAZRD"
                 preventStepsUnmountOnNavigation={true}
      />
    );
  }

}


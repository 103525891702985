import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js';
import * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
require('./editAssetsStatusDialog.scss');

class EditAssetsStatusDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.dialog.editAssetsStatus.');
    this.entitiesTypes = props.sData.get('entitiesTypes').toJS();

    this.fetchConfig = {
      action: props.actions.fetchEntities,
      filter: props.sData.get('boEntityFilter')
    };
  }

  getDialogButtons() {
    let cancelTokenEnabled = !!this.props.sData.get('cancelToken') ;
    return {
      left: [{
        id:'cancel',
        text:   this.props.sData.get('loading') ? this.dialogLabels.get('stop') : this.dialogLabels.get('cancel'),
        action: this.props.sData.get('loading') ? this.onStop : this.onHide,
        enabledOnLoading: cancelTokenEnabled
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.markAsComplete'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
          this.props.sData.get('hasError') ||
          (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
        }
      ]
    };
  }

  onSubmit = (data) => {
    let row = data['assets-multi-select'];
    let assetsIds =  row?.assetsValue ? row.assetsValue.map((asset) => {return asset.value}) : [];

    let updateData = {
      partsIds: assetsIds,
      status: 'COMPLETED',
      comment: data.comment || '',
    }

    updateData.ignoreValidationWarnings = (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
      data.footerValidationCheckbox : false;

    this.props.actions.submit(updateData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get('confirmation.message.title', '', { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  render() {

    return (
      <Dialog
        id="edit-assets-status-dialog"
        className="edit-assets-status-dialog"
        titleText={this.labels.get('header.title') }
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <InputSection label={this.labels.get('assets')} className="no-margin">
          <EntitiesMultiSelect
            id="assets-multi-select"
            name="assets-multi-select"
            entitiesTypes={this.entitiesTypes}
            validate={Validation.required}
            preSelectedEntities={ this.props.sData.get('preselectedAssets')}
            fetchConfig={this.fetchConfig}
            autoFocus={true}
            { ...this.props }
            // onValueChangeCallback = {this.onPreselectedAssetsChange}
          />
        </InputSection>

        <InputSection label={this.labels.get('comment')} htmlFor="comment">
          <TextAreaField id="comment-textField" name="comment" className="comment-textField" />
        </InputSection>

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'editAssetsStatusDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(EditAssetsStatusDialog);


EditAssetsStatusDialog.defaultProps = {
  preselectedAssets: [],
};
EditAssetsStatusDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

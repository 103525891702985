import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as messageDialogActions }  from '../../../actions/MessageDialog/messageDialogActions.js';
import { jsxActions as headerActions }  from '../../../actions/Mobile/Header/headerActions.js';

import ScannedAssetPage from '../../../components/Mobile/ScannedAssetPage/scannedAssetPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      messageDialogActions: bindActionCreators(messageDialogActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
    }
  }
}

let ScannedAssetPageContainer = connect(
  (state) => {
    return {
      sData: state.mobile.get('data'),
      sPageData: state.mobile.get('scannedAssetPage'),

    };
  },
  mapDispatchToProps
)(ScannedAssetPage);

export default ScannedAssetPageContainer;





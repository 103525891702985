import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';

import { concatClassNames } from '../../utils/componentUtils.js';

//Style
require('./tabs.scss');

// #  https://react-bootstrap.github.io/components.html#tabs
export function getClassName(tabType) {
  switch (tabType) {
    case 'hidden':
      return 'pl-hidden-tabs';
    case 'topbar':
    case 'page':
      return 'pl-topbar-tabs';
    case 'content':
      return 'pl-content-tabs';
    case 'wizard-sidebar':
      return 'pl-wizard-sidebar-tabs';
    case 'wizard-horizontal':
      return 'pl-wizard-horizontal-tabs';
    case 'modal':
    case 'default':
    default:
      return 'pl-tabs';
  }
}

export default class PL_Tabs extends React.PureComponent {
  render() {
    let { className, tabType, mountOnEnter, unmountOnExit, mountOnlyActiveTab, ...props } = this.props;

    if (mountOnlyActiveTab) {
      mountOnEnter = true;
      unmountOnExit = true;
    }

    return (
      <Tabs
        {...props}
        animation={false}
        className={concatClassNames(getClassName(tabType), className)}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
      >
        {this.props.children}
      </Tabs>
    );
  }
}

PL_Tabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
  tabType: PropTypes.oneOf(['topbar', 'page', 'content', 'modal', 'default', 'wizard-sidebar', 'wizard-horizontal', 'hidden', undefined, null]),
  mountOnEnter: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  activeKey: PropTypes.any,
  id: PropTypes.any.isRequired,
  onSelect: PropTypes.func, //(key: any, event: SyntheticEvent) => void; called on tab select
  mountOnlyActiveTab: PropTypes.bool,
};

PL_Tabs.defaultProps = {
  className: '',
  children: [],
  tabType: null,
  mountOnEnter: false,
  unmountOnExit: false,
  activeKey: undefined,
  onSelect: () => {},
  mountOnlyActiveTab: false,
};

PL_Tabs.Tab = Tab;

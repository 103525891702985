import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as twoDMappingPageActions }    from '../../../actions/Reports/twoDMappingPageActions.js';

import TwoDMappingPage from '../../../components/Reports/TwoDMappingPage/twoDMappingPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(twoDMappingPageActions, dispatch)
  }
}

let TwoDMappingPageContainer = connect(
  (state) => {
  	return {
      sData: state.twoDMappingPage,
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps
)(TwoDMappingPage);


export default TwoDMappingPageContainer;




import React from "react";

import PropTypes from "prop-types";
import Header, { headerItemsLabels } from "../../Common/Header/header";
import MaterialScrapGrid from "./MaterialScrapGrid/materialScrapGrid";
import { gridsNames } from "../../../enums/gridsNames";
import './materialScrapView.scss';

export default class MaterialScrapView extends React.PureComponent {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
    this.props.actions.init(false);
  }

  getHeaderItems() {
    let { actions, sHasRows } = this.props;

    return {
      buttons: [
        {
          id: "export",
          icon: "pl pl-export-icon",
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.export,
          actionData: { gridName: gridsNames.PREDEFINED_MATERIAL_SCRAP }
        }
      ]
    };
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="material-scrap-page">
        <Header {...headerItems}/>
        <MaterialScrapGrid
          actions={{ ...this.props.actions.gridActions, ...this.props.actions.fetchEntitiesActions }}/>
      </div>
    );
  }
};

MaterialScrapView.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object
};







import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';

require('./createProjectDialog.scss');

class CreateProjectDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createproject.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;
  }

  componentDidMount() {
    let initialValues = {active: true};

    if (this.isEditMode) {
      initialValues = {
        active: this.itemToEdit.active,
        projectName: this.itemToEdit.businessId,
      };
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  onSubmit = (data) => {

    let newData = {
      businessId: data.projectName,
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
      newData.orgId = this.itemToEdit.orgId;
    }
    else{
      newData.active = data.active;
    }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide =() => {
    this.props.actions.hide();
  };

  maxLength30 = Validation.maxLength(30);

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-project-type-dialog"
        className="create-project-type-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection htmlFor="active" className="inline two-columns no-margin">
          <Checkbox name="active" id="active"  label={this.labels.get('active')} disabled={this.isEditMode}/>
        </InputSection>

        <InputSection label={this.labels.get('name')+'*'} htmlFor="projectName" className="inline left-side">
          <TextField id="projectName" name="projectName" className="short-textfield"
                     validate={[Validation.required, this.maxLength30]}
          />
        </InputSection>

      </Dialog>
    );
  }
}

CreateProjectDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'createProjectDialog',
  }
)(CreateProjectDialog);




import DraggableShift from './draggableShift';
import moment from 'moment';
import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

import './shiftsPanel.scss'

export default function ShiftsPanel({ shiftTypes }) {
  const labels = useMemo(
    () =>
      createLabelHelper(
        'mat.administration.matsettings.shiftsPanel.'
      ),
    []
  );

  return (
    <div className="shift-types">
      <DraggableShift shiftData={{ name: 'eraser', color: '#fff', id: 'eraser' }}>
        <div className="eraser">
          <span className="pl pl-draggable"></span>
          <span className="shift-name">{labels.get('clear')}</span>
        </div>
      </DraggableShift>

      {shiftTypes?.map((shiftData) => {
        const { id, name, startTime, endTime, color } = shiftData;
        return (
          <DraggableShift key={id} shiftData={shiftData}>
            <div
              className="shift"
              style={{
                backgroundColor: color,
              }}
            >
              <span className="pl pl-draggable"></span>
              <span className="shift-name">{name}</span>
              <span>{moment(startTime, 'HH:mm').format('HH:mm')}</span>
              <span>-</span>
              <span>{moment(endTime, 'HH:mm').format('HH:mm')}</span>
            </div>
          </DraggableShift>
        );
      })}

      {!shiftTypes?.length && <div className="no-shifts-defined">{labels.get('noShifts')}</div>}
    </div>
  );
}

ShiftsPanel.propTypes = {
  shiftTypes: PropTypes.array,
};

//TODO: color contrast util to determine shift name text color (black or white?)
// https://stackoverflow.com/a/11868159

import React from 'react';
import PropTypes from 'prop-types';

import PL_TextField from 'infrastructure/js/components/controls/TextField/textField';

require('./fileChooser.scss');

export default class PL_FileChooser extends React.PureComponent {

  constructor(props) {
    super(props);

    let fileName = this.props.selectedFile ? this.props.selectedFile.name : null;

    this.state = {
      fileName: fileName
    }
  }

  onSelectedFileChanged = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({fileName: event.target.files[0].name});
      if (this.props.onSelectedFileChanged) {
        this.props.onSelectedFileChanged(event.target.files[0]);
      }
    }
  };

  getAcceptTypes = (accept) => {
      return accept ? accept.join() : [];
  };

  clearInputValue = (e) => e.target.value = null

  render() {
    let {placeHolder , buttonText , accept , disabled} = this.props;

    return (
      <div className="file-chooser-wrapper">

        <input type="file" id="fileChooser"
               disabled={disabled}
               multiple={false}
               name='fileChooser'
               className="input-file"
               accept={this.getAcceptTypes(accept)}
               onClick={this.clearInputValue}
               onChange={this.onSelectedFileChanged}
        />

        <div className='file-chooser-selection'>
          <PL_TextField
            id="fileChooser"
            name="fileName"
            placeholder={this.state.fileName ? this.state.fileName: placeHolder}
            disabled={true}
          />
          <label className='custom-file-chooser' htmlFor="fileChooser">{buttonText || ''}</label>
        </div>
      </div>
    );
  }

}

PL_FileChooser.propTypes = {
  accept: PropTypes.array,
  disabled: PropTypes.bool,
  buttonText:PropTypes.string,
  placeHolder:PropTypes.string,
  onSelectedFileChanged: PropTypes.func
};

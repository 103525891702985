import React from 'react';
import {reduxForm} from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import { navigationStates } from '../../../../enums/navigationStates';
import ActivityTypeCell from '../../../Common/CustomGridCells/ActivityTypeCell/activityTypeCell';
import ActivityDetailsCell from '../../../Common/CustomGridCells/ActivityDetailsCell/activityDetailsCell';
import MasterDetailRow from 'infrastructure/js/components/Grid/MasterDetailRow/MasterDetailRow'
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./activityLogGrid.scss');

class ActivityLogGrid extends React.PureComponent{

  filterConfig = [
     {fieldName: 'time.epochDateTime', filterName: 'fromDate'},
     {fieldName: 'activityType', filterName: 'activityType', getOptions: true},
     {fieldName: 'location', filterName: 'locationName', getOptions: true},
     {fieldName: 'username', filterName: 'reportedBy', getOptions: true}
    ]

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let columnsConfig = [
      {
        fieldName: 'time.epochDateTime',
        title: this.labels.get('columns.time.title'),
        filterType: filterTypes.NONE,
        filterName: 'fromDate',
        valueFormatter: (params) => {
          return (params.data && params.data.m_Time ? params.data.m_Time : '');
        },
        width: 165,
        columnOptions: {
          cellRenderer: 'agGroupCellRenderer',
          sort: 'desc',
          valueGetter: (params) => {
            return params.data?.time?.epochDateTime;
          },
        },
      },
      {
        fieldName: 'activityType',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'activityType',
        width: 220,
        columnOptions: {
          cellComponent: ActivityTypeCell,
          valueGetter: (params) => {
            return { activityType: params.data.activityType, activityTypeDisplayKey: params.data.activityTypeDisplayKey };
          }
        }
      },
      {
        fieldName: 'details',
        title: this.labels.get('columns.details.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ActivityDetailsCell,
          valueGetter: (params) => {
            return this.activityDetailsValueGetter(params);
          },
          sortable: false,
        }
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'locationName',
        width: 220,
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.location ? params.data.location.id : null,
              type: navigationStates.LOCATION,
              label: params.data.location ? params.data.location.name : null
            };
          }
        }
      },
      {
        fieldName: 'username',
        title: this.labels.get('columns.reportedBy.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'reportedBy',
        filterAlignment: filterAlignmentTypes.RIGHT,
        width: 220,
        columnOptions: {
          valueGetter: (params) => {
            return params.data?.username;
          },
        }
      }
    ];
    return columnsConfig;
  }


  activityDetailsValueGetter(params) {
    // AT the moment we are supporting only 1, according to product it may change
    if(!params.data.primaryDetails || !params.data.primaryDetails[0]){
      return { key: 'NOT_FOUND', value: '' };
    }
    let primaryActivity = params.data.primaryDetails[0];
    return { key: primaryActivity.key, value: primaryActivity.value, type: primaryActivity.valuetype, isLocalizedKey: primaryActivity.keyIsReadyForDisplay };
  }

  getRowHeight = (params) => {
    let rowHeight = 64;
    //check if row is a master detail row (the return height is for the detail row)
    //then check if need more then a single row
    if (params.node && params.node.detail && params.data && params.data.extendedDetails) {
      let rowCount = Math.ceil(params.data.extendedDetails.length / 4);
      return rowCount > 0 ? rowHeight * rowCount : rowHeight;
    }

    return rowHeight;
  };

  render() {
    return (
      <div className="activity-log-grid">
        <Grid gridName={gridsNames.ASSET_PAGE_ACTIVITY_LOG}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  checkboxSelection: false,
                  masterDetailRowComponent: MasterDetailRow,
                  isMasterDetail:true
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'activityLogGrid'
  }
)(ActivityLogGrid);

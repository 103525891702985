import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import PL_FilePicker from 'infrastructure/js/components/controls/FilePicker/filePicker';
import {ImportAcceptFileTypes} from '../../../../../../enums/importTypes';

require('./editDashboardDemoDataDialog.scss');

class EditDashboardDemoDataDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.psmanagement.dialog.editDashboardDemoData.');
    this.allLabels= createLabelHelper('');
    this.dialogLabels = createLabelHelper('mat.dialog.');

    this.itemToEdit = this.props.sData.get('itemToEdit');

    this.state = {
      useDefault: (this.itemToEdit && this.itemToEdit.isDefault),
      useCustom: (this.itemToEdit && this.itemToEdit.isCustom),
      initialFileName: this.getInitialFileName(),
      selectedFile: null,

    }
  }

  getInitialFileName = () => {
    return (this.itemToEdit && this.itemToEdit.isCustom) ? this.itemToEdit.fileName : '';
  };

  componentDidMount() {
    let initialValues = {
      useDefault: this.state.useDefault,
      useCustom: this.state.useCustom,
    };

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    let {useDefault, useCustom} = this.state;
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.dialogLabels.get('save'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.props.pristine || this.props.sData.get('loading') || (!useDefault && !useCustom))
        }
      ]
    };
  }


  onSubmit = (data) => {

    if (data) {
      if (data.useCustom && data.selectedFileName) {
        let file = this.state.selectedFile;
        let newData = {
          dashboardType: this.itemToEdit.dashboardType,
        };

        this.props.actions.submitUpload(file, newData, this.props.reloadParentComponent);
      }
      else if (data.useDefault) {
        let newData = {dashboardType: this.itemToEdit.dashboardType, isDefault: data.useDefault};
        this.props.actions.submitEdit( newData, this.props.reloadParentComponent);
      }
    }
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onUseDefaultCheckboxClick = (e) => {

    let newState = {useDefault: e.target.checked};

    if (this.state.useCustom && e.target.checked) {
      newState.useCustom = false;
      this.props.change('useCustom', false);
    }

    this.setState(newState);
  };

  onUseCustomCheckboxClick = (e) => {
    let newState = {useCustom: e.target.checked};

    if (this.state.useDefault && e.target.checked) {
      newState.useDefault = false;
      this.props.change('useDefault', false);
    }

    this.setState(newState);
  };

  onSelectedFileChanged = (file) => {
    this.setState({selectedFile: file ? file : null});
  };


  render() {
    let titleText = this.labels.get('header.title') + ' - ' + this.allLabels.get(this.itemToEdit.dashboardTypeDisplayKey);

    let acceptFileTypes = [ImportAcceptFileTypes.JSON];

    return (
      <Dialog
        id="edit-dashboard-demo-data-dialog"
        className="edit-dashboard-demo-data-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection htmlFor="useDefault" className="full-width no-margin">
          <Checkbox name="useDefault" id="useDefault"
                    label={this.labels.get('useDefault')}
                    onClick={this.onUseDefaultCheckboxClick}/>
        </InputSection>

        <InputSection htmlFor="useCustom" className="full-width">
          <Checkbox name="useCustom" id="useCustom"
                    label={this.labels.get('useCustom')}
                    onClick={this.onUseCustomCheckboxClick}/>

          <div className="use-custom-content">
              <PL_FilePicker
                name="selectedFileName"
                placeHolder={this.state.initialFileName ? this.state.initialFileName : this.labels.get('useCustom.placeHolder')}
                buttonText={this.labels.get('useCustom.button.browse')}
                accept={acceptFileTypes}
                disabled={!this.state.useCustom}
                onSelectedFileChanged={this.onSelectedFileChanged}
              />
          </div>
        </InputSection>

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'editDashboardDemoDataDialog',
  }
)(EditDashboardDemoDataDialog);

EditDashboardDemoDataDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};




import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm} from 'redux-form';

import ResetPasswordPage from 'infrastructure/js/components/ResetPasswordPage/resetPasswordPage.js';
import {jsxActions} from '../../actions/ResetPassword/resetPasswordActions.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(jsxActions, dispatch)
  }
}
let ResetPasswordPageContainer = reduxForm({
    form: 'resetPasswordPage'
  }
)(ResetPasswordPage);

ResetPasswordPageContainer = connect(
  (state)=>{
    return {
      sData: state.resetPassword
    }
  },
  mapDispatchToProps
)(ResetPasswordPageContainer);

export default ResetPasswordPageContainer;

import { Map } from 'immutable';
import { actionTypes as scannedToolPageActionTypes } from '../../../actions/Mobile/ScannedToolPage/scannedToolPageActions.js';

let defaultState = {
  loading: false,
  error : null,
  success : false,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case scannedToolPageActionTypes.SCANNEDTOOL_PAGE_SUBMIT_IN_PROGRESS:
      state = state.set('loading', true)
        .set('success', false)
        .set('error', null);
      return state;
    case scannedToolPageActionTypes.SCANNEDTOOL_PAGE_SUBMIT_FINISHED:
      state = state.set('loading', false)
        .set('success', true)
        .set('error', null);
      return state;
    case scannedToolPageActionTypes.SCANNEDTOOL_PAGE_SUBMIT_FAILED:
      state = state.set('loading', false)
        .set('success', false)
        .set('error', action.payload);
      return state;
    case scannedToolPageActionTypes.SCANNEDTOOL_PAGE_CLEAR:
      return Map(defaultState);

    default:
      return state;
  }
}













import Network from 'infrastructure/js/modules/network';
import * as assetsService from '../../../services/Assets/assetService';
import * as rollsService from '../../../services/Rolls/rollsService';
import * as spoolsService from '../../../services/Spools/spoolsService';
import * as kitsService from '../../../services/Kits/kitsService';
import * as boHelper              from '../batchOperationDialogActionsHelper';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('SHELFLIFEUPDATE_DIALOG'),
  SHELFLIFEUPDATE_DIALOG_FETCH_REASONS_READY:   'SHELFLIFEUPDATE_DIALOG_FETCH_REASONS_READY',
};
////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('SHELFLIFEUPDATE_DIALOG'),
  fetchReasonsReady: function(payload) {
    return {type: actionTypes.SHELFLIFEUPDATE_DIALOG_FETCH_REASONS_READY, payload: payload };
  },
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
const config = {
  submitMethod: (data, callback) => {
      let method = getMethodByAssetType(data.assetType);
      delete data.assetType;
      return method ? method(data, callback) :  Promise.resolve({success: false}) ;
    },
  dialogReduxStorageName: 'shelfLifeUpdateDialog'
};

function getMethodByAssetType(assetType) {
  switch (assetType) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      return rollsService.updateShelfLife;
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
      return kitsService.updateShelfLife;
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
      return spoolsService.updateShelfLife;
    default:
      console.log('getMethodByAssetType(): Unknown assetType', assetType);
      return null;
  }
}

export let api = {
  ...boHelper.getApiActions(actions, config),
  fetchReasons: function(dispatch, getState) {
    return function() {
      assetsService.fetchReasons()
        .then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error("Fetch Reasons Failed");
            return {success: false};
          }
          dispatch(actions.fetchReasonsReady(response.dataList));
        });
    };
  },
};



/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),

  fetchReasons: function () {
    return function (dispatch, getState) {
      api.fetchReasons(dispatch, getState)();
    };
  },
};








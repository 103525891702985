import React from 'react';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../../enums/gridsNames';
import LabelWithTooltipCell from '../../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';

class ShelfLifeUpdateViewGrid extends React.PureComponent{

  filterConfig = [];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.reasons.shelflife.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'type',
        title: this.labels.get('columns.reason.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            // return {text: params.data.type, description: ''};
            return params.data.type;
          }
        }
      }
    ]
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_SHELF_LIFE_UPDATE}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'shelfLifeUpdateViewGrid'
  }
)(ShelfLifeUpdateViewGrid);

import { Map } from 'immutable';
import { actionTypes as CreateDemoOrganizationDialogActionTypes } from '../../../../actions/Administration/OrganizationsPage/Dialogs/createDemoOrganizationDialogActions';

let defaultState = {
  show: false,
  loading: false,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case CreateDemoOrganizationDialogActionTypes.CREATE_DEMO_ORGANIZATION_DIALOG_SHOW:
      return state.set('show', true);

    case CreateDemoOrganizationDialogActionTypes.CREATE_DEMO_ORGANIZATION_DIALOG_HIDE:
      return Map(defaultState);

    case CreateDemoOrganizationDialogActionTypes.CREATE_DEMO_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case CreateDemoOrganizationDialogActionTypes.CREATE_DEMO_ORGANIZATION_STATUS_SUBMIT_FINISHED:
      return state.set('loading', false);

    default:
      return state;
  }
}

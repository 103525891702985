import Network from 'infrastructure/js/modules/network';
import * as stationsService from '../../../services/Stations/stationsService';
import { api as cancelCutReportDialogApi } from './cancelCutReportDialogActions.js';
import { api as locationPageApi } from '../locationPageActions.js';
import { api as cuttingGrigApi } from './cuttingGridActions.js';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LOCATIONPAGE_CUTTINGVIEW_FETCHCHARTS_FINISHED: 'LOCATIONPAGE_CUTTINGVIEW_FETCHCHARTS_FINISHED'

};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchChartsFinished: function(payload) {
    return { type: actionTypes.LOCATIONPAGE_CUTTINGVIEW_FETCHCHARTS_FINISHED, payload: payload };
  }

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.onCancelCutReportClick = function(cut) {
  return function(dispatch, getState) {
    cancelCutReportDialogApi.openDialog(dispatch, getState)(cut);
  };
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    cuttingGrigApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      cuttingGrigApi.reload(dispatch, getState)();
    }
    return api.fetchCharts(dispatch, getState)();
  };
};


api.fetchCharts = function(dispatch, getState) {
  return function() {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();

    var chartsPromise = stationsService.FetchSummaryCounts(locationId);
    var unKittedPromise = stationsService.getUnkittedReportedCuts(locationId);

    return Promise.all([chartsPromise, unKittedPromise]).then((response) => {
      if (!Network.isResponseValid(response[0]) || !Network.isResponseValid(response[1])) {
        console.error("Fetch Chart Failed");
        return { success: false };
      }

      let serverTimeSeconds = getState().system.get("serverDatetime");
      let unkittedCuts = response[1].dataList.map((cut, index) => {
        cut.m_CreatedDate = DateTimeHelper.FormatDateObjectToRelativeTime(cut.createdDate, serverTimeSeconds);
        cut.quantity = PermissionManager.isWeightSupported() ?
          UnitHelper.serverValueToUserValueWithLabel(unitTypes.WEIGHT, cut.weightLeft, 2) :
          UnitHelper.serverValueToUserValueWithLabel(unitTypes.LENGTH, cut.lengthCut, 2);
        return cut;
      });
      dispatch(actions.fetchChartsFinished({
        charts: { ...response[0].data.data },
        unkittedCuts: unkittedCuts,
        success: true
      }));
    });
  };
};



import { useRef, useCallback, Children, useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AccordionContext from 'infrastructure/js/contexts/accordionContext';

import './accordion.scss';

export default function Accordion({ children: childrenProp, expanded: expandedProp, disabled, onChange }) {
  // check if accordion is controlled externally. it should never change for the lifetime of the component.
  const { current: isControlled } = useRef(expandedProp !== undefined);

  const [_expanded, setExpanded] = useState(expandedProp);
  const expanded = isControlled ? expandedProp : _expanded;

  const [detailHeight, setDetailHeight] = useState(0);
  const detailRef = useRef(null);
  const detailContentRef = useRef(null);

  // Detect detail height before painting in order to set expanded height dynamically.
  useLayoutEffect(() => {
    detailContentRef.current.style.position = 'absolute';
    const { height } = detailContentRef.current.getBoundingClientRect();
    detailContentRef.current.style.position = '';
    setDetailHeight(height);
  }, [childrenProp]);

  useEffect(() => {
    detailRef.current.style.height = expanded ? `${detailHeight}px` : '0px';
  }, [expanded, detailHeight]);

  const handleChange = useCallback(
    (event) => {
      if (expandedProp === undefined) {
        setExpanded(!expanded);
      }

      if (onChange) {
        onChange(event, !expanded);
      }
    },
    [expanded, onChange, setExpanded, expandedProp]
  );

  const [summary, ...children] = Children.toArray(childrenProp);

  const contextValue = { expanded, disabled, toggle: handleChange };

  return (
    <div className="pl-accordion">
      <AccordionContext.Provider value={contextValue}>{summary}</AccordionContext.Provider>
      <div ref={detailRef} className={cn('pl-accordion-detail', { expanded })}>
        <div ref={detailContentRef}>{children}</div>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Accordion.defaultProps = {
  children: null,
  expanded: false,
  disabled: false,
  onChange: null,
};
import React from 'react';
import PropTypes from 'prop-types';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Validation from "infrastructure/js/components/controls/controlsValidations";

require('./editAttachmentDialog.scss');

class EditAttachmentDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.assetpage.editAttachmentDialog.');
    this.entitiesTypes = [this.props.asset.get('objectType')];
    this.categoryOptions = [
      {label: 'Geometry', value: 'GEOMETRY'},
      {label: 'Instruction', value: 'INSTRUCTION'},
      {label: 'Image', value: 'IMAGE'},
      {label: 'Diagram', value: 'DIAGRAM'},
      {label: 'Report', value: 'REPORT'},
      {label: 'Other', value: 'OTHER'},
    ]
  }

  getDialogButtons() {
    return {
      left: [{
        id: 'cancel',
        text: (this.props.sData.get('loading')) ? this.dialogLabels.get('stop') : this.dialogLabels.get('cancel'),
        action: (this.props.sData.get('loading')) ? this.onStop : this.onHide,
      }],
      right: [
        {
          id: 'submit',
          text: this.labels.get('footer.update'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading')
        }
      ]
    };
  }

  componentDidMount() {
    let selectedRows = this.props.preselectedData.get('selectedRowsData');
    let item = selectedRows.get(0);
    if (!item) {
      return;
    }
    let initialValues = {
      category: this.categoryOptions.find((option) => item.category === option.value),
      file: item.originalFileName
    };
    this.props.initialize(initialValues);
  }

  onSubmit = (data) => {
    if (!this.props.preselectedData) {
      return;
    }
    let selectedRows = this.props.preselectedData.get('selectedRowsData');
    this.props.actions.submit(selectedRows.get(0).id, data.category.value, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };


  render() {
    let titleText = this.labels.get('header.title');

    return (
      <Dialog
        id="archive-assets-dialog"
        className="archive-assets-dialog"
        titleText={titleText}
        show={this.props.sData.get("show")}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('file')} htmlFor="file" className="inline left-side no-margin">
          <TextField id="file" name="file" className="short-textfield" disabled={true}/>
        </InputSection>

        <InputSection label={this.labels.get('category')} htmlFor="category" className="inline right-side no-margin">
          <Combobox id="category" name="category" options={this.categoryOptions} validate={Validation.required}/>
        </InputSection>
      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'editAttachmentDialog',
  }
)(EditAttachmentDialog);

EditAttachmentDialog.defaultProps = {};

EditAttachmentDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  asset: PropTypes.object,
  sData: PropTypes.object.isRequired,
  preselectedData: PropTypes.object

};

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as archivedKitsPageActions} from '../../actions/ArchivedKitsPage/archivedKitsPageActions.js'
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as archivedKitsGridActions } from '../../actions/ArchivedKitsPage/archivedKitsGridActions';
import { jsxActions as activateAssetsDialogActions }    from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';
import { jsxActions as moveAssetsActions }        from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as printPdfLabelsActions }    from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

import ArchivedKitsPage from '../../components/PredefinedList/ArchivedKitsPage/archivedKitsPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(archivedKitsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      archivedKitsGridActions: bindActionCreators(archivedKitsGridActions, dispatch),
      activateAssetsDialogActions:  bindActionCreators(activateAssetsDialogActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      printPdfLabelsActions:      bindActionCreators(printPdfLabelsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let ArchivedKitsContainer =  connect (
  (state) => {
    let archivedKitsGrid = state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_KITS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_KITS) : null;
    return {
      sArchivedKitsGrid:  archivedKitsGrid,
      sHasRows: archivedKitsGrid && archivedKitsGrid.get('rows') ? archivedKitsGrid.get('rows').size > 0 : false,
      sSelectedRowsData: archivedKitsGrid ? archivedKitsGrid.get('selectedRowsData') : List(),
      sActivateAssetsDialogData: state.dialogs.get('activateAssetsDialog'),
      sMoveAssetsData:     state.dialogs.get('moveAssetsDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
    };
  },
  mapDispatchToProps
)(ArchivedKitsPage);


export default ArchivedKitsContainer;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import PL_FilePicker from 'infrastructure/js/components/controls/FilePicker/filePicker';
import {ImportAcceptFileTypes, ImportTypes} from '../../../../../enums/importTypes';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

require('./fileUploadStep.scss');

export default class FileUploadStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');

    this.state = {selectedFile: null};

    this.props.registerOnEnter(() => {
      this.props.setNextButtonEnabled(this.isNextButtonEnabled());
    });

    this.props.registerOnExit(() => {
    })
  }

  componentDidUpdate(prevProps) {
    let errors = this.getErrors();
    let prevErrors = prevProps.sData?.get('errors');

    if (errors !== prevErrors) {
      this.props.setNextButtonEnabled(this.isNextButtonEnabled());
    }
  }

  isNextButtonEnabled = () => {
    return (!!this.state.selectedFile && !this.getErrors() );
  }

  onSelectedFileChanged = (file) => {
    let selectedFile = file ? file : null;
    this.setState({selectedFile}, () => {
      this.props.setNextButtonEnabled(true);
    });

    this.props.actions.onSelectedFileChanged(selectedFile);
  };

  renderTopSection = () => {
    return (
      <div className="top-section" >
        <div className="top-section-line">{this.props.labels.get('fileUploadStep.topSection.comment1')} </div>
        <div className="top-section-line">{this.props.labels.get('fileUploadStep.topSection.comment2')}</div>
        <div className="top-section-line">
          <div className="column-name">{this.props.labels.get('fileUploadStep.topSection.column2.name')}</div>
          <div className="attr-name">{DateTimeHelper.getDateFormat()}</div>
        </div>
      </div>
    );
  }

  renderBottomSection = () => {
    if(this.props.assetType === ImportTypes.IMPORT_WO){
      return null
    }
    return (
      <div className="bottom-section" >
        {this.props.labels.get('fileUploadStep.bottomSection.comment1')}
      </div>
    );
  }

  renderErrorSection = () => {
    let error = this.getErrors();
    if (error) {
      return (
        <div className="error-section">
          <span className={cn('pl pl-warning-icon', error.statusType )}/>
          <label className='error-msg'>{error.message ? error.message : ''}</label>
        </div>
      );
    }
    return null;
  }

  getErrors = () => {
    return this.props.sData ? this.props.sData.get('errors') : null;
  }

  render() {
    let acceptFileTypes = [ImportAcceptFileTypes.CSV];

    return (
      <div className="file-upload-step" >

        {this.renderTopSection()}

        <div className="file-picker-section">
            <PL_FilePicker
              name="selectedFileName"
              placeHolder={this.props.labels.get('fileUploadStep.placeholder')}
              buttonText={this.state.selectedFile ? this.dialogLabels.get('changeFile') : this.dialogLabels.get('browse')}
              accept={acceptFileTypes}
              onSelectedFileChanged={this.onSelectedFileChanged}
              validate={this.props.isCurrentStep ? Validation.required : undefined}
            />
        </div>

        {this.renderBottomSection()}

        {this.renderErrorSection()}

      </div>
    );
  }
}

FileUploadStep.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  isCurrentStep : PropTypes.bool,
  labels: PropTypes.object.isRequired
};


import React from 'react';
import CommonCsvSettingsView from '../Components/CommonCsvSettingsView/commonCsvSettingsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';
require('./spoolCsvSettingsView.scss');

export default  class SpoolCsvSettingsView extends React.PureComponent {

  componentDidMount() {
   this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_CSV_SPOOL);

   this.props.actions.load(getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'));
  }

  render() {
    return (
        <CommonCsvSettingsView
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          entityType={getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')}
        />
    );
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm, formValueSelector } from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js';
import * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import * as AssetHelper from '../../../utils/assetHelper';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import {getAssetRelatedAssets} from '../../../services/Assets/assetService';
require('./editWorkOrdersStatusDialog.scss');

class EditWorkOrdersStatusDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.dialog.editworkordersstatus.');
    this.assetsLabels = createLabelHelper('mat.workorderpage.archiveworkorderdialog.');
    this.entitiesTypes = props.sData.get('entitiesTypes').toJS();
    this.status = props.sData.get('status');
    this.state = { options: [] , dummyKey: new Date()};

    this.setUILabelsByStatus();
    this.fetchConfig = {
      action: props.actions.fetchEntitiesWithRelatedAssets,
      filter: props.sData.get('boEntityFilter')
    };
  }

  componentDidMount(){
    const preselectedAssets =  this.props.sData.get('preselectedAssets')
    const affectedAssets = this.getRelatedAssets(preselectedAssets);
    this.props.change( 'affectedAssets', affectedAssets );
    this.setState({options: affectedAssets});
  }

  removeRelatedAssets (workOrders,oldAssets) {
    workOrders.forEach((wo) =>{
      const relatedAssets =  (wo && wo.data) ? wo.data.relatedAssets : wo.relatedAssets
      relatedAssets.forEach((a) => {
        oldAssets.splice(oldAssets.findIndex(item => item.value === a.id), 1)
      })
     })
    return oldAssets;
  }

  getNewValue (val1, val2) {
    if(!val2){
      return val1
    }
    return val1.filter(e => val2 && !val2.map((o) => o.value).includes(e.value))
  }

  onPreselectedAssetsChange = (value, oldValue) => {
    const oldAssets = this.state.options;
    const isAddAction = value.length > oldValue.length ? true : false;
    const workOrders =  isAddAction ? this.getNewValue(value, oldValue) : this.getNewValue(oldValue, value);
    const newAffectedAssets =  isAddAction ?
       [...oldAssets, ...this.getRelatedAssets(workOrders)] :
       [...this.removeRelatedAssets(workOrders,oldAssets)];

    this.setState({options: newAffectedAssets,dummyKey: new Date()}, ()  =>  this.props.change( 'affectedAssets', newAffectedAssets));

   }


  getRelatedAssets(workOrders) {
    let assets = [];
    workOrders.forEach((wo) => {
        const relatedAssets =  (wo && wo.data) ? wo.data.relatedAssets : wo.relatedAssets
        relatedAssets.forEach((asset) => {
              assets.push({
                value: asset.id,
                label: asset.businessId,
                title: asset.businessId,
                data: asset,
          });
         });
    })
    return assets;
  }

  setUILabelsByStatus() {
    switch (this.status) {
      case getEnumValue(enumTypes.WORK_ORDER_STATUS)('ACTIVE'):
        this.submitButtonLabel = this.labels.get('footer.reopen');
        this.dialogTitle = this.labels.get('header.reopen.title');
        this.inputLabel = this.labels.get('wotoreopen');
        this.noteText = null;
        return;
      case getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED'):
        this.submitButtonLabel = this.labels.get('footer.markascomplete');
        this.dialogTitle = this.labels.get('header.complete.title');
        this.inputLabel = this.labels.get('wotocomplete');
        this.noteText = <div className="edit-note">{this.labels.get('note.complete.text')}</div>
        return;
      case getEnumValue(enumTypes.WORK_ORDER_STATUS)('CANCELED'):
        this.submitButtonLabel = this.labels.get('footer.markascanceled');
        this.dialogTitle = this.labels.get('header.canceled.title');
        this.inputLabel = this.labels.get('wotocancel');
        this.noteText = this.labels.get('wotoreopen');
        this.noteText = <div className="edit-note">{this.labels.get('note.canceled.text')}</div>
        return;
    }
    console.error('setUILabelsByStatus(): unknown status ', this.status)
  }

  getDialogButtons() {
    let cancelTokenEnabled = !!this.props.sData.get('cancelToken') ;
    return {
      left: [{
        id:'cancel',
        text:   this.props.sData.get('loading') ? this.dialogLabels.get('stop') : this.dialogLabels.get('cancel'),
        action: this.props.sData.get('loading') ? this.onStop : this.onHide,
        enabledOnLoading: cancelTokenEnabled
      }],
      right: [
        {
          id:'submit',
          text: this.submitButtonLabel,
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
          this.props.sData.get('hasError') ||
          (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
        }
      ]
    };
  }

  onSubmit = (data) => {
    let workOrders = data['workorders-multi-select'] ? data['workorders-multi-select'].assetsValue : [];
    let workOrdersData = [];
    let  affectedAssets = data['affectedAssets'] ? data['affectedAssets'] : [];
    workOrders.forEach((wo) => {
      let item = {workOrdersId: wo.value };

      if (this.status !== getEnumValue(enumTypes.WORK_ORDER_STATUS)('ACTIVE')) {
        if (wo && wo.data && wo.data.relatedAssets) {
          let assetsIds = [];
          wo.data.relatedAssets.forEach((asset) => {
            //do not include ROlls
            if (!AssetHelper.isRoll(asset.objectType)) {
              affectedAssets.some(a => a.value === asset.id) && assetsIds.push(asset.id);
            }
          });
          item.affectedAssetIds = assetsIds;
        }
      }
      workOrdersData.push(item);
    });

    let newData = {
      workOrdersData : workOrdersData,
      status: this.status,
      comment: data.comment ? data.comment : '',
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };

     this.props.actions.submit(newData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getConfirmationMessageByStatus(response) {
    if (this.status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('ACTIVE')) {
      return this.labels.get('confirmation.message.reopen.title', '', { count: response.totalJobItems});
    }
    else if (this.status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED')) {
      return this.labels.get('confirmation.message.complete.title', '', { count: response.totalJobItems});
    }
    else if (this.status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('CANCELED')) {
      return this.labels.get('confirmation.message.canceled.title', '', { count: response.totalJobItems});
    }
    console.error('getConfirmationMessageByStatus(): unknown status ', this.status);
    return ''
  }

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.getConfirmationMessageByStatus(response);
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  renderNote = () => {
    return this.noteText;
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  getMarkedAssetsLabel = () => {
    if (this.status) {
      switch (this.status) {
        case getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED')  :
          return this.assetsLabels.get('mark.assets.completed');
        case getEnumValue(enumTypes.WORK_ORDER_STATUS)('DISQUALIFIED'):
          return this.assetsLabels.get('mark.assets.disqualified');
        case getEnumValue(enumTypes.WORK_ORDER_STATUS)('CANCELED'):
          return this.assetsLabels.get('mark.assets.canceled');
        default:
          console.error('ArchiveWorkOrderDialog: Unknown status : ' + this.status);
          return '';
      }
    }
    return '';
  };

  renderMarkedAssets() {
    let label = this.getMarkedAssetsLabel();

    let placeholder = this.state.options.length > 0 ? this.assetsLabels.get('mark.assets.placeholder.select') :
      this.assetsLabels.get('mark.assets.placeholder.noassets');
    let isDisabled = (this.status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED')) || (this.state.options.length === 0);
    let clearable = (this.status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED')) ? false : true;

    return (
      <InputSection label={label} htmlFor="affectedAssets" className="right-side full-width marked-assets-section">
        <PL_MultiSelectField  id="affectedAssets" name="affectedAssets"
          //clearable={false}
                              isSearchable={false}
                              options={this.state.options}
                              placeholder={placeholder}
                              isMultiValueClearable={clearable}
                              isDisabled={isDisabled}
                              key={this.state.dummyKey}

        >
        </PL_MultiSelectField>
      </InputSection>
    );

  }
  render() {


    return (
      <Dialog
        id="edit-workorders-status-dialog"
        className="edit-workorders-status-dialog"
        titleText={this.dialogTitle }
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >


        <InputSection label={this.inputLabel} className="no-margin">
          <EntitiesMultiSelect
            id="workorders-multi-select"
            name="workorders-multi-select"
            entitiesTypes={this.entitiesTypes}
            validate={Validation.required}
            preSelectedEntities={ this.props.sData.get('preselectedAssets')}
            fetchConfig={this.fetchConfig}
            autoFocus={true}
            { ...this.props }
            onValueChangeCallback = {this.onPreselectedAssetsChange}
          />

        </InputSection>
        {this.renderMarkedAssets()}
        <InputSection label={this.labels.get('comment')} htmlFor="comment">
          <TextAreaField id="comment-textField" name="comment" className="comment-textField" />
        </InputSection>

        {this.renderNote()}

      </Dialog>
    );
  }
}

let EditWorkOrdersStatusForm = reduxForm({
    form: 'editWorkOrdersStatusDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(EditWorkOrdersStatusDialog);

const selector = formValueSelector('editWorkOrdersStatusDialog') // <-- same as form name
EditWorkOrdersStatusForm = connect(state => {
  const { preselectedAssets, affectedAssets } = selector(state, 'preselectedAssets', 'affectedAssets')
  return {
    preselectedAssets: preselectedAssets,
    affectedAssets: affectedAssets
  }
})(EditWorkOrdersStatusForm)



EditWorkOrdersStatusDialog.defaultProps = {
  status: '',
  preselectedAssets: [],
  affectedAssets: []
};
EditWorkOrdersStatusDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  status: PropTypes.string.isRequired
};
export default EditWorkOrdersStatusForm

import Network from 'infrastructure/js/modules/network';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions.js';

const actionTypes = {
  SHOW: '_SHOW',
  HIDE: '_HIDE',
  FETCH_DIALOG_DATA_READY: '_FETCH_DIALOG_DATA_READY',
  SET_BUSY: '_SET_BUSY',

  SET_VALIDATION_WARNINGS_DATA: '_SET_VALIDATION_WARNINGS_DATA',
  HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: '_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
  TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: '_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
};

export function getActionTypes(prefix) {
  let keys = Object.keys(actionTypes);
  let res = {};
  keys.forEach((key) => {
    res[prefix + actionTypes[key]] = prefix + actionTypes[key];
  });
  return res;
}

export function getActions(prefix) {
  return {
    show(payload) {
      return { type: prefix + actionTypes.SHOW, payload: payload };
    },
    hide() {
      return { type: prefix + actionTypes.HIDE };
    },

    fetchDialogDataReady(payload) {
      return { type: prefix + actionTypes.FETCH_DIALOG_DATA_READY, payload: payload };
    },
    setBusy(payload) {
      return { type: prefix + actionTypes.SET_BUSY, payload: payload };
    },

    setValidationWarningsData(payload) {
      return { type: prefix + actionTypes.SET_VALIDATION_WARNINGS_DATA, payload: payload };
    },
    hideIgnoreValidationWarningsCheckbox() {
      return { type: prefix + actionTypes.HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX };
    },
    toggleIgnoreValidationWarningsCheckbox(payload) {
      return { type: prefix + actionTypes.TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX, payload: payload };
    },
  };
}

export function getJsxActions(api) {
  return {
    show(data) {
      return function (dispatch, getState) {
        api.show(dispatch, getState)(data);
      };
    },

    hide() {
      return function (dispatch, getState) {
        api.hide(dispatch, getState)();
      };
    },

    submit(data, messageDialogBuilder, reloadParentComponent, submitConfigData = null) {
      return function (dispatch, getState) {
        api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent, submitConfigData);
      };
    },

    fetchDialogData(fetchConfig, callback) {
      return function (dispatch, getState) {
        api.fetchDialogData(dispatch, getState)(fetchConfig, callback, true);
      };
    },

    hideIgnoreValidationWarningsCheckbox() {
      return function (dispatch, getState) {
        api.hideIgnoreValidationWarningsCheckbox(dispatch, getState)();
      };
    },

    toggleIgnoreValidationWarningsCheckbox(value) {
      return function (dispatch, getState) {
        api.toggleIgnoreValidationWarningsCheckbox(dispatch, getState)(value);
      };
    },
  };
}

export function getApiActions(actions, config) {
  if (config.getSubmitMethod === undefined || typeof config.getSubmitMethod !== 'function') {
    console.error('dialogActionsHelper: config.getSubmitMethod error: ', config.getSubmitMethod);
    return {};
  }

  return {
    show(dispatch, getState) {
      return function (data = null) {
        if (this.fetchDialogData) {
          return this.fetchDialogData(dispatch, getState)().then(() => {
            dispatch(actions.show(data));
          });
        }
        dispatch(actions.show(data));
      }.bind(this);
    },

    hide(dispatch) {
      return function () {
        dispatch(actions.hide());
      };
    },

    submit(dispatch, getState) {
      return function (data, messageDialogBuilder, reloadParentComponent, submitConfigData = null) {
        dispatch(actions.setBusy(true));

        config
          .getSubmitMethod(submitConfigData)(data)
          .then((response) => {
            dispatch(actions.setBusy(false));

            let validations = dialogHelper.getResponseValidationDetails(response);
            if (validations) {
              dispatch(actions.setValidationWarningsData(validations));
              return response; //TODO: L test it!
            }

            dispatch(actions.hide());

            if (!Network.isResponseValid(response)) {
              console.error(config.getSubmitMethod(submitConfigData).name + ' failed ', response);
              messageDialogApi.responseError(dispatch, getState)(response);
              return response; //TODO: L test it!
            }

            if (reloadParentComponent) {
              reloadParentComponent();
            }

            if (messageDialogBuilder) {
              let messageDialogDescriptor = messageDialogBuilder(...this.getMessageDialogBuilderData(dispatch, getState)(response, data));
              messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
            }
          })
          .catch((error) => {
            console.error('dialogActionsHelper: submit failed, catch error: ', error);
            dispatch(actions.setBusy(false));
            dispatch(actions.hide());
          });
      }.bind(this);
    },

    getMessageDialogBuilderData(dispatch, getState) {
      return function (response) {
        return [response.data, messageDialogApi.close(dispatch, getState)];
      };
    },

    hideIgnoreValidationWarningsCheckbox(dispatch) {
      return function () {
        dispatch(actions.hideIgnoreValidationWarningsCheckbox());
      };
    },

    toggleIgnoreValidationWarningsCheckbox(dispatch) {
      return function (value) {
        dispatch(actions.toggleIgnoreValidationWarningsCheckbox(value));
      };
    },
  };
}

import Network                     from 'infrastructure/js/modules/network';
import { List }                    from 'immutable';
import { api as locationPageApi }  from '../locationPageActions.js';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions.js';
import { api as fetchEntitiesApi } from '../../FetchEntities/fetchEntitiesActions.js';
import DateTimeHelper              from 'infrastructure/js/utils/dateTimeHelper';
import PermissionManager           from 'infrastructure/js/utils/permissionManager';
import {createLabelHelper}         from 'infrastructure/js/utils/labelHelper';
import * as workOrdersService      from '../../../services/WorkOrders/workOrdersService';
import * as assetsService          from '../../../services/Assets/assetService';
import * as dialogHelper           from 'infrastructure/js/components/Dialog/dialogHelper';
import * as daHelper               from '../../Dialogs/dialogActionsHelper';
import {dialogsNames}              from '../../../enums/dialogsNames';
import {api as printRfidTagsApi}   from '../../PrintRfidActions/printRfidActions';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.WORK_ORDER_DIALOG),

  WORK_ORDER_DIALOG_FETCH_ASSETS_FOR_WO_IN_PROGRESS: 'WORK_ORDER_DIALOG_FETCH_ASSETS_FOR_WO_IN_PROGRESS',
  WORK_ORDER_DIALOG_FETCH_ASSETS_FOR_WO_FINISHED: 'WORK_ORDER_DIALOG_FETCH_ASSETS_FOR_WO_FINISHED',
  WORK_ORDER_DIALOG_RESET_ASSETS_FOR_WO: 'WORK_ORDER_DIALOG_RESET_ASSETS_FOR_WO',
  WORK_ORDER_DIALOG_GET_ASSET_ACTIVITY_LOG: 'WORK_ORDER_DIALOG_GET_ASSET_ACTIVITY_LOG'
};

const actions = {
  ...daHelper.getActions(dialogsNames.WORK_ORDER_DIALOG),

  fetchAssetsForWoInProgress(payload) {
    return {type: actionTypes.WORK_ORDER_DIALOG_FETCH_ASSETS_FOR_WO_IN_PROGRESS, payload: payload};
  },
  fetchAssetsForWoFinished(payload) {
    return {type: actionTypes.WORK_ORDER_DIALOG_FETCH_ASSETS_FOR_WO_FINISHED, payload: payload };
  },
  resetAssetsForWo(payload) {
    return {type: actionTypes.WORK_ORDER_DIALOG_RESET_ASSETS_FOR_WO, payload: payload };
  },

};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(operation, selectedWorkorders) {

      api.fetchDialogData(dispatch, getState)();

      const operationInfo = {
        operationId: operation.id,
        operationType: operation.operationType.name,
        title: operation.displayName,
        description: operation.instructions,
        preselectedTool: operation?.preselectedTool
      };

      dispatch(actions.show({operationInfo, selectedWorkorders}));
    }
  },

  fetchDialogData(dispatch, getState) {
    return function() {

      let data = {
        businessId: '',
        maxPageSize: 1000000,
        active: true,
      };

      let promise2 = workOrdersService.fetchOperationStatus();

      return Promise.all([ promise2]).then((allResults) => {

        let operationStatuses = allResults[0].data.items;

        let labels = createLabelHelper('');

        let dialogData = {
          operationStatusOptions: operationStatuses.map((item) => {return { value: item.name, label: labels.get(item.labelKey)}}),
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return dialogData;
      });
    }
  },

  fetchAssetsForWo(dispatch, getState) {
    return function(woId, fetchEntitiesFilters) {
      dispatch(actions.fetchAssetsForWoInProgress());

      workOrdersService.fetchWoRelatedAssets(woId, fetchEntitiesFilters)
        .then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error('Fetch assets for WorkOrder Failed');
            return {success: false};
          }

          dispatch(actions.fetchAssetsForWoFinished({woId : woId, assets: List(response.dataList)}));
        });
    };
  },

  resetAssetsForWo(dispatch, getState) {
    return function() {
      dispatch(actions.resetAssetsForWo());
    };
  },

  reportOperation(dispatch, getState) {
    return function(serviceMethod, convertDataMethod, data, messageDialogBuilder) {

      let locationId = getState().appTopbar.getIn(['topNavigatorData', 'currentLocation', 'id']);

      let reportOperations = [];
      for (let i = 0; i < data.reportOperations.length; i++) {
        let op = convertDataMethod(data.reportOperations[i], locationId);
        reportOperations.push(op);
      }

      // dispatch(actions.reportOperationInProgress());
      dispatch(actions.setBusy(true));

      let newData = {
        reportOperations,
        ignoreValidationWarnings: data.ignoreValidationWarnings,
      };

      serviceMethod(newData).then((response) => {
        _handleResponse(dispatch, getState)(response, data, messageDialogBuilder);
      });
    };
  },

  getAssetActivityLog(dispatch, getState) {
    //callback: function(response : error ? errorObject : LogEvent[], error : Boolean) { }
    return function (assetType, assetId, callback) {
      if (!callback)
        throw 'Cannot call the getAssetActivityLog action without a callback.'
      dispatch(actions.setBusy(true));
      assetsService.getAssetActivityLog(assetType, assetId)
        .catch((err) => callback(err, true))
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Invalid getAssetActivityLog Response', assetType, assetId);
            throw 'Failed to get asset activity log.';
          }
          callback(response.dataList, false);
        });
      dispatch(actions.setBusy(false));
    }
  },

  printRfidTag(dispatch, getState) {
    return function (assetIds) {
      messageDialogApi.close(dispatch, getState)();

      if (assetIds && assetIds.length > 0) {
        let locationId = locationPageApi.getLocationId(dispatch, getState)();
        printRfidTagsApi.printRfidTags(dispatch, getState)(assetIds, locationId, true);
      }
    }
  },
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  fetchEntities(fetchConfig, callback) {
    return function (dispatch, getState) {
      fetchEntitiesApi.fetchEntities(dispatch, getState)(fetchConfig, callback, true);
    };
  },
  fetchEntitiesWithRelatedAssets(fetchConfig, callback) {
    return function (dispatch, getState) {
      fetchEntitiesApi.fetchEntitiesWithRelatedAssets(dispatch, getState)(fetchConfig, callback, true);
    };
  },

  fetchAssetsForWo( woId, fetchEntitiesFilters) {
    return function(dispatch, getState) {
      api.fetchAssetsForWo(dispatch, getState)(woId, fetchEntitiesFilters);
    };
  },

  resetAssetsForWo() {
    return function(dispatch, getState) {
      api.resetAssetsForWo(dispatch, getState)();
    };
  },

  reportOperationAssetsAssociation(data, messageDialogBuilder) {
    return function(dispatch, getState) {
      api.reportOperation(dispatch, getState)(workOrdersService.reportOperationAssetsAssociation,
        _convertToOperationData,
        data, messageDialogBuilder);
    };
  },

  reportOperationAssetsRelation(data, messageDialogBuilder) {
    return function(dispatch, getState) {
      api.reportOperation(dispatch, getState)(workOrdersService.reportOperationAssetsRelation,
        _convertToAssetsRelationData,
        data, messageDialogBuilder);
    };
  },

  reportOperationCuring(data, messageDialogBuilder) {
    return function(dispatch, getState) {
      api.reportOperation(dispatch, getState)(workOrdersService.ReportOperationCuring,
        _convertToCuringData,
        data, messageDialogBuilder);
    };
  },

  reportOperationUncuring(data, messageDialogBuilder) {
    return function(dispatch, getState) {
      api.reportOperation(dispatch, getState)(workOrdersService.ReportOperationUncuring,
        _convertToOperationData,
        data, messageDialogBuilder);
    };
  },

  reportOperationDemolding(data, messageDialogBuilder) {
    return function(dispatch, getState) {
      api.reportOperation(dispatch, getState)(workOrdersService.ReportOperationDemolding,
        _convertToOperationData,
        data, messageDialogBuilder);
    };
  },

  reportOperationLayup(data, messageDialogBuilder) {
    return function(dispatch, getState) {
      api.reportOperation(dispatch, getState)(workOrdersService.reportOperationLayup,
        _convertToAssets2AssetsData,
        data, messageDialogBuilder);
    };
  },

  reportOperationMerge(data, messageDialogBuilder) {
    return function(dispatch, getState) {
      let method = PermissionManager.isWeightSupported() ? workOrdersService.reportOperationMergeWithWeight : workOrdersService.reportOperationMerge;
      api.reportOperation(dispatch, getState)(method,
        _convertToMergeData,
        data, messageDialogBuilder);
    };
  },

  reportOperationAssetsToAssets(data, messageDialogBuilder) {
    return function(dispatch, getState) {
      api.reportOperation(dispatch, getState)(workOrdersService.reportOperationAssetsToAssets,
        _convertToAssets2AssetsData,
        data, messageDialogBuilder);
    };
  },

  reportOperationAssetsToAssetsWithConsumption(data, messageDialogBuilder) {
    return function (dispatch, getState) {
      let method = PermissionManager.isWeightSupported() ? workOrdersService.reportOperationAssetsToAssetsWithConsumptionWithWeight : workOrdersService.reportOperationAssetsToAssetsWithConsumption;
      api.reportOperation(dispatch, getState)(method,
        _convertToAssets2AssetsWithConsumptionData,
        data, messageDialogBuilder);
    };
  },

  getAssetActivityLog(assetType, assetId, callback) {
    return function(dispatch, getState) {
      api.getAssetActivityLog(dispatch, getState)(assetType, assetId, callback);
    }
  }
};


/////////////////////////////////////////////////////////////////////
// HELPERS
let _convertToCommonData = function (data, locationId) {
  return {
    locationId: locationId,
    operationId: data.operationId,
    workOrderId: data.workOrder,
    isCompleted: data.isCompleted,
    operationStatus: data.operationStatus,
    comment: data.comment,
  };
};

let _convertToOperationData = function (data, locationId) {
  let op = _convertToCommonData(data, locationId);
  op.assetIds = data.assets.map((asset) => {return asset.value});
  return op;
};
let _convertToCuringData = function (data, locationId) {
  let op = _convertToOperationData(data, locationId);
  op.curingTime = data.curingTime ? DateTimeHelper.ConvertFromDate(data.curingTime) : null;

  return op;
};

let _convertToAssetsRelationData = function (data, locationId) {
  let op = _convertToCommonData(data, locationId);
  op.assetIdsSource = data.assetsSource.map((asset) => {return asset.value});
  op.assetIdsTarget = data.assetsTarget.map((asset) => {return asset.value});

  return op;
};
let _convertToAssets2AssetsData = function (data, locationId) {
  let op = _convertToCommonData(data, locationId);
  op.assetIdsSource = data.assetsSource.map((asset) => {return asset.value});
  op.assetIdTarget = data.assetsTarget[0] ? data.assetsTarget[0].value : null;
  op.isConsumed = data.isConsumed;
  op.usingByAssetId = data.usingByAssetId ? data.usingByAssetId.value : null;

  return op;
};
let _convertToAssets2AssetsWithConsumptionData = function (data, locationId) {
  let op = _convertToCommonData(data, locationId);
  op.assetIdSource = data.assetsSource[0] ? data.assetsSource[0].value : null;
  op.assetIdTarget = (data.assetsTarget[0] && (data.assetsTarget[0].value !== data.assetsTarget[0].label)) ? data.assetsTarget[0].value : null;
  op.assetBusinessIdTarget = (data.assetsTarget[0] && (data.assetsTarget[0].value === data.assetsTarget[0].label)) ? data.assetsTarget[0].label : null;
  PermissionManager.isWeightSupported() ? op.weightLeft = data.weightLeft: op.length = data.length;

  return op;
};

let _convertToMergeData = function (data, locationId) {
  let op = _convertToCommonData(data, locationId);
  op.assetIdsSource = data.assetsSource ? data.assetsSource.map((asset) => {return asset.value}) : null;
  op.assetIdTarget = (data.assetsTarget[0] && (data.assetsTarget[0].value !== data.assetsTarget[0].label)) ? data.assetsTarget[0].value : null;
  op.assetBusinessIdTarget = (data.assetsTarget[0] && (data.assetsTarget[0].value === data.assetsTarget[0].label)) ? data.assetsTarget[0].label : null;
  op.assetsForConsumption = data.assetsForConsumption ? data.assetsForConsumption.map((asset) => {
    if(PermissionManager.isWeightSupported()){
      return {assetId: asset.value, weightLeft: asset.weightLeft}
    }
    return {assetId: asset.value, cutLength: asset.cutLength}}) : null;
  op.usingByAssetId = data.usingByAssetId ? data.usingByAssetId.value : null;
  op.isConsumed = true;

  return op;
};

let _handleResponse = function (dispatch, getState) {
  return function (response, data, messageDialogBuilder) {
    dispatch(actions.setBusy(false));

    let validations = dialogHelper.getResponseValidationDetails(response);
    if (validations) {
      dispatch(actions.setValidationWarningsData(validations));
      return {success: false};
    }

    dispatch(actions.hide());

    if (!Network.isResponseValid(response)) {
      console.error('Report operations failed', response);
      messageDialogApi.responseError(dispatch, getState)(response);
      return {success: false};
    }

    let succeededWoIds = [];
    let failedWoIds = [];
    let assetIds = [];

    if (response.dataList) {
      response.dataList.forEach((item) => {
        if (item.data) {
          if (Network.isResponseValid(item)) {
            succeededWoIds.push(item.data.workOrderId);
          } else {
            failedWoIds.push(item.data.workOrderId)
          }
          if (item.data.assetIdCreatedOnTheFly) {
            assetIds.push(item.data.assetIdCreatedOnTheFly)
          }
        }
      });
    }


    if (messageDialogBuilder) {
      let builderData = {succeededWoIds, failedWoIds, reportOperations: data.reportOperations};

      let messageDialogDescriptor = messageDialogBuilder(
        builderData,
        messageDialogApi.close(dispatch, getState),
        assetIds.length > 0 ? (() => api.printRfidTag(dispatch, getState)(assetIds)) : null
      );

      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    }

    locationPageApi.reload(dispatch, getState)();
  };
};


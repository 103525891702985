import { useDispatch, useStore } from 'react-redux';
import { api } from '../../../mat/js/actions/AppTopbar/navigatorActions';
import { useCallback } from 'react';

/**
 * @function useNavBarUpdate
 * @description Custom Hook that returns a function that allow you to update the text in the Navigation Bar and save information to nav history.
 * @returns {Function} A function that updates the nav bar. the function accept these params:
 * @param {string} type The type of the location to update.
 * @param {string} name The name of the location to update.
 * @param {string|number} id (optional) The id of the location to update.
 */
const useNavBarUpdate = () => {
  const dispatch = useDispatch();
  const { getState } = useStore();

  return useCallback(
    (type, name, id = -1) => {
      api.setData(dispatch, getState)({ id, type, name });
    },
    [dispatch, getState]
  );
};

export default useNavBarUpdate;

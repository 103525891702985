import Network from 'infrastructure/js/modules/network';

const getRoles = function(){
  return Network.get('users/roles');
};
const getSystemRoles = function(){
  return Network.get('system/users/roles');
};



export default {getRoles, getSystemRoles};

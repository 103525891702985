import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import CheckMarkCell from '../../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import {gridsNames} from '../../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';

require('./toolCategoriesViewGrid.scss');

class ToolCategoriesViewGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: true},
    {fieldName: 'active', filterName: 'active', getOptions: false},
  ];


  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.toolCategory.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'businessId',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.businessId;
          }
        }
      },
      {
        fieldName: 'active',
        title: this.labels.get('columns.active.title'),
        filterType: filterTypes.NONE,
        filterName: 'active',
        width: 130,
        columnOptions: {
          sort: 'desc',
          cellComponent: CheckMarkCell,
          valueGetter: (params) => {
            return params.data?.active;
          },
        }
      },
      {
        fieldName: 'operations',
        title: this.labels.get('columns.operations.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return this.operationsValueGetter(params.data.operations);
          },
        }
      }
    ]
  };

  operationsValueGetter = (operations) => {
    let dataList = (operations ? operations.sort((a, b) => {return (a.position < b.position) ? -1 : 1;}) : [] );

    return { dataList: dataList.map((op)=> {return op.displayName}),
      componentType: LabelWithTooltipCell};
  };

  getRowHeight(params) {
    let rowHeight =  64;
    if (params.data.operations && params.data.operations.length > 1) {
      rowHeight = params.data.operations.length* 30 + 40;
    }
    return rowHeight;
  }


  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_CATEGORIES}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'toolCategoriesViewGrid'
  }
)(ToolCategoriesViewGrid);

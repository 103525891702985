import * as mqttSensorsService from '../../../services/Rfid/mqttSensorsService';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_RFID_SETTINGS_MQTT_SENSORS, _fetchDataAction);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let selectedBroker =  getState().administration.getIn(['rfidSettingsPage', 'mqttSensorsSettingsViewData' , 'selectedBroker']);

    if (!selectedBroker || !selectedBroker.value) {
      return Promise.resolve([]);
    }

    return mqttSensorsService.fetchMqttSensors(selectedBroker.value);
  }
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

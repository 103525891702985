import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import {filterAlignmentTypes} from '../../../../../enums/filterAlignmentTypes';

require('./filterLayout.scss');

export default class PL_FilterLayout extends React.PureComponent {

  render() {
    let {className, columnWidth, filterWidth, filterAlignment} = this.props;

    // Calculated values for filter position
    let rightLayoutValue = Math.min( (columnWidth - filterWidth)/2 - (30 + 13), -30);
    let rightFilterValue = Math.max( (filterWidth - columnWidth)/2 + (3 + 13), 3);
    // let rightLayoutValue = Math.min( (columnWidth - filterWidth)/2 - (30 + 13 + 12), -30);
    // let rightFilterValue = Math.max( (filterWidth - columnWidth)/2 + (3 + 13 ), 3);

    if (filterAlignment === filterAlignmentTypes.RIGHT) {
      rightLayoutValue = -30;
      rightFilterValue = 3;
    }
    else if (filterAlignment === filterAlignmentTypes.LEFT && (columnWidth < filterWidth) ) {
      rightLayoutValue = Math.min( (columnWidth - filterWidth) - 30, -30);
      rightFilterValue = Math.max((filterWidth - columnWidth) + 3 , 3);
    }

    return (
      <div className={cn('filterLayout', className)} style={{right: (rightLayoutValue), width: filterWidth}}>
        <div className="border-triangle" style={{right: (rightFilterValue + 10) }}/>
        <i className="pl pl-filter" style={{right: (rightFilterValue) }}/>
        {this.props.children}
      </div>);
  }
}

PL_FilterLayout.defaultProps = {
  filterAlignment: '',
}

PL_FilterLayout.propTypes = {
  columnWidth: PropTypes.number.isRequired,
  filterWidth: PropTypes.number.isRequired,
  filterAlignment: PropTypes.string,
};




import { Map } from 'immutable';
import { actionTypes as MatSettingsPageActionsType } from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { default as createAssetTypeWizardReducer } from './createAssetTypeWizardReducer';
import { default as createMaterialTypeDialogReducer } from './createMaterialTypeDialogReducer';
import { default as createGroupTypeDialogReducer } from './createGroupTypeDialogReducer';
import { default as createKitTypeDialogReducer } from './createKitTypeDialogReducer';
import { default as importKitTypesDialogReducer } from './importKitTypesDialogReducer';
import { default as importToolTypesDialogReducer } from './importToolTypesDialogReducer';
import { default as createPartTypeDialogReducer } from './createPartTypeDialogReducer';
import { default as createToolCategoryDialogReducer } from './createToolCategoryDialogReducer';
import { default as createToolTypeDialogReducer } from './createToolTypeDialogReducer';
import { default as createProjectDialogReducer } from './createProjectDialogReducer';
import { default as createReasonTypeDialogReducer } from './createReasonTypeDialogReducer';
import { default as createOperationDialogReducer } from './createOperationDialogReducer';
import { default as digitalTwinViewReducer } from './digitalTwinViewReducer';
import { default as createShiftTypeDialogReducer } from './createShiftTypeDialogReducer';
import { default as shiftSettingsDialogReducer } from './shiftSettingsDialogReducer';
import { default as shiftTypesSettingsReducer } from './shiftTypesSettingsReducer';
import { default as shiftsSchedulerReducer } from './shiftsSchedulerReducer';
import { default as weeklyShiftsViewReducer } from './weeklyShiftsViewReducer';
import {MatSettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import PermissionManager from 'infrastructure/js/utils/permissionManager';


let defaultState = {
  selectedMenuItemId : (__DEV__ && PermissionManager.hasAssetTypesTabPermissions()) ?
    MatSettingsMenuItemsIds.NAV_ELEMENT_ASSET_TYPES : MatSettingsMenuItemsIds.NAV_ELEMENT_PROJECTS
};

export default function(state = Map(defaultState), action) {
  state = state.set('createAssetTypeWizard', createAssetTypeWizardReducer(state.get('createAssetTypeWizard'), action));
  state = state.set('createMaterialTypeDialog', createMaterialTypeDialogReducer(state.get('createMaterialTypeDialog'), action));
  state = state.set('createGroupTypeDialog', createGroupTypeDialogReducer(state.get('createGroupTypeDialog'), action));
  state = state.set('createKitTypeDialog', createKitTypeDialogReducer(state.get('createKitTypeDialog'), action));
  state = state.set('importKitTypesDialog', importKitTypesDialogReducer(state.get('importKitTypesDialog'), action));
  state = state.set('importToolTypesDialog', importToolTypesDialogReducer(state.get('importToolTypesDialog'), action));
  state = state.set('createPartTypeDialog', createPartTypeDialogReducer(state.get('createPartTypeDialog'), action));
  state = state.set('createToolCategoryDialog', createToolCategoryDialogReducer(state.get('createToolCategoryDialog'), action));
  state = state.set('createToolTypeDialog', createToolTypeDialogReducer(state.get('createToolTypeDialog'), action));
  state = state.set('createProjectDialog', createProjectDialogReducer(state.get('createProjectDialog'), action));
  state = state.set('createReasonTypeDialog', createReasonTypeDialogReducer(state.get('createReasonTypeDialog'), action));
  state = state.set('createOperationDialog', createOperationDialogReducer(state.get('createOperationDialog'), action));
  state = state.set('digitalTwinView', digitalTwinViewReducer(state.get('digitalTwinView'), action));
  state = state.set('createShiftTypeDialog', createShiftTypeDialogReducer(state.get('createShiftTypeDialog'), action));
  state = state.set('shiftTypesSettings', shiftTypesSettingsReducer(state.get('shiftTypesSettings'), action));
  state = state.set('shiftsScheduler', shiftsSchedulerReducer(state.get('shiftsScheduler'), action));
  state = state.set('shiftSettingsDialog', shiftSettingsDialogReducer(state.get('shiftSettingsDialog'), action));
  state = state.set('weeklyShifts', weeklyShiftsViewReducer(state.get('weeklyShifts'), action));
  switch (action.type) {

    case MatSettingsPageActionsType.ADMINISTRATION_MAT_SETTINGS_SELECTED_MENU_ITEM_CHANGED:
      return state.set('selectedMenuItemId', action.payload);

    default:
      return state;
  }
}




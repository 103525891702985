import React from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import PreferencesViewGrid from './PreferencesViewGrid/preferencesViewGrid';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {RfidSettingsMenuItemsIds, SettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import EditPreferenceDialog from '../Preferences/Dialogs/editPreferenceDialog.js';

require('./preferencesPage.scss');

export default  class PreferencesView extends React.PureComponent {


  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.settings.preferences.view.');
  }

  componentDidMount() {
    if(this.props.isRfid){
      this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_ZPL_SETTINGS);
      return
    }
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_SYSTEM_PREFERENCES);
  }
  getHeaderItems() {

    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      title: this.props.isRfid ? this.labels.get('rfidTitle') : this.labels.get('title'),
      buttons: [
        ...(!this.props.isRfid ? [{
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        }] : []),
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditPreferenceClick,
          actionData: selectedRowsData.get(0)
        }
      ],
    };
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="preferences-page">
        <Header {...headerItems} />
        <PreferencesViewGrid actions={this.props.actions.preferencesViewGridActions} isRfid={this.props.isRfid}/>
        <PL_DialogWrapper dialogComponent={EditPreferenceDialog}
                          show={this.props.sEditPreferenceDialogData.get('show')}
                          actions={this.props.actions.editPreferenceDialogActions}
                          sData={this.props.sEditPreferenceDialogData}
                          reloadParentComponent={this.reloadPage}
                          isRfid={this.props.isRfid}
        />
      </div>
    );
  }
}

PreferencesView.propTypes = {
  actions: PropTypes.object.isRequired,
  sPreferencesGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired
};


import React from 'react';

import PropTypes from 'prop-types';
import Label from 'infrastructure/js/components/Label/label.js';


export default class LabelWithTooltipCell extends React.PureComponent {

  render() {
    let value = (this.props.value || this.props.value === 0) ? this.props.value.toString() : '';

    return ( <Label text={value} /> );

  }
}

LabelWithTooltipCell.defaultProps = {
  value : '',
};

LabelWithTooltipCell.propTypes = {
  value : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

import PropTypes from 'prop-types';
import DroppableWrapper from '../droppableWrapper';
import { filterTypes } from '../../../../../enums/shiftSchedulerEnums';

const GridColumnHeaderCell = ({ children, actions, headerData, isTemplate, checkIsDroppable, setHighlighted, highlighted }) => {
  const onDragEnter = (e) => {
    setHighlighted({ direction: 'column', headerId: headerData.day, target: e.target });
  };

  const onDragLeave = (e) => {
    if (highlighted && e.target !== highlighted.target) {
      return;
    }
    setHighlighted(null);
  };

  const dropAction = (assignmentData) => {
    if (assignmentData?.shiftData?.id === 'eraser') {
      const filterData = {
        filterBy: filterTypes.DAY,
        day: headerData.day,
      };
      actions.removeShifts({ filterData });
      return;
    }

    actions.assignShift(assignmentData);
  };

  return (
    <DroppableWrapper
      className="grid-item grid-column-header-cell"
      action={dropAction}
      assignmentData={headerData}
      isHeader={true}
      isTemplate={isTemplate}
      checkIsDroppable={checkIsDroppable}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      setHighlighted={setHighlighted}
    >
      {children}
    </DroppableWrapper>
  );
};

GridColumnHeaderCell.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.object,
  headerData: PropTypes.object,
  isTemplate: PropTypes.bool,
  checkIsDroppable: PropTypes.func,
  setHighlighted: PropTypes.func,
  highlighted: PropTypes.object,
};

GridColumnHeaderCell.defaultProps = {
  children: '',
  actions: {},
  headerData: {},
  isTemplate: false,
  checkIsDroppable: () => true,
  setHighlighted: () => {},
  highlighted: null,
};

export default GridColumnHeaderCell;

import { actionTypes as locationPageActionTypes } from '../../actions/LocationPage/locationPageActions';
import { actionTypes as cuttingViewActionTypes }  from '../../actions/LocationPage/CuttingView/cuttingViewActions';
import { actionTypes as kittingViewActionTypes }  from '../../actions/LocationPage/KittingView/kittingViewActions';
import { actionTypes as activitiesViewActionsTypes }  from '../../actions/LocationPage/ActivitiesView/activitiesViewActions';
import { actionTypes as workOrderViewActionsTypes }  from '../../actions/LocationPage/WorkOrderView/workOrderViewActions';
import { actionTypes as tasksViewActionsTypes }  from '../../actions/LocationPage/TasksView/tasksViewActions';



import { Map } from 'immutable';

let defaultState = {
  assets: Map({
    errors:     Map({text: "mat.locationpage.view.assets.charts.errors", count: undefined }),
    warnings:   Map({text: "mat.locationpage.view.assets.charts.warnings", count: undefined })
  }),
  activity: Map({
    activities: Map({text: "mat.locationpage.view.activities.charts.activitiestoday", count: undefined })
  }),

  cutting: Map({
    reportedCuts:   Map({text:"mat.locationpage.view.cutting.charts.reportedcuts", count: undefined, unkittedCuts: []})
  }),

  kitting: Map({
    kitsInStation:  Map({text:"mat.locationpage.view.kitting.charts.kitsinstation", count: undefined}),
    cutsKitted:     Map({text:"mat.locationpage.view.kitting.charts.cutskitted", count: undefined, totalCount: undefined})
  }),

  workOrder: Map({
    createdToday: Map({text:"mat.locationpage.workorder.charts.createdtoday", count: undefined}),
    handledToday: Map({text:"mat.locationpage.workorder.charts.handledtoday", count: undefined})
  }),

  tasks: Map({
    completedTasks: Map({text:"mat.locationpage.workorder.charts.completedTasks", count: undefined, totalCount: undefined}),
  })

};

export default function(state = Map(defaultState), action) {

  switch(action.type) {

    case locationPageActionTypes.LOCATIONPAGE_LOCATION_ALERTS_COUNT_FETCH_READY:
      state = state.setIn(["assets", "errors", "count"],    action.payload.errors);
      state = state.setIn(["assets", "warnings", "count"],  action.payload.warnings);
      return state;

    case cuttingViewActionTypes.LOCATIONPAGE_CUTTINGVIEW_FETCHCHARTS_FINISHED:
      state = state.setIn(["cutting", "reportedCuts", "count"], action.payload.charts.reportedCutsCount);
      state = state.setIn(["cutting", "reportedCuts", "unkittedCuts"], action.payload.unkittedCuts);
      return state;


    case kittingViewActionTypes.LOCATIONPAGE_KITTINGVIEW_FETCHCHARTS_FINISHED:
      state = state.setIn(["kitting", "kitsInStation", "count"],    action.payload.kitsStationCount);
      state = state.setIn(["kitting", "cutsKitted", "count"],       action.payload.finishedKittedReportedCutsCount);
      state = state.setIn(["kitting", "cutsKitted", "totalCount"],  action.payload.reportedCutsCount);
      return state;

    case activitiesViewActionsTypes.ACTIVITIESVIEW_FETCHCHARTS_FINISHED:
      state = state.setIn(["activity", "activities", "count"], action.payload.activitiesTodayCount);
      return state;

    case workOrderViewActionsTypes.LOCATIONPAGE_WORKORDERVIEW_FETCHCHARTS_FINISHED:
      state = state.setIn(["workOrder", "createdToday", "count"], action.payload.createdToday);
      state = state.setIn(["workOrder", "handledToday", "count"], action.payload.handledToday);
      return state;

    case tasksViewActionsTypes.LOCATIONPAGE_TASKSVIEW_FETCHCHARTS_FINISHED:
      state = state.setIn(["tasks", "completedTasks", "count"], action.payload.completed);
      state = state.setIn(["tasks", "completedTasks", "totalCount"], action.payload.total);
      return state;

    default:
      return state;
  }


  return state;
}



import Network from 'infrastructure/js/modules/network';
import * as dashboardService from '../../services/Reports/dashboardService.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import { api as systemApi } from '../System/systemActions';
import * as DashboardHelper from '../../components/Reports/utils/dashboardHelper';
import {navigationStates} from '../../enums/navigationStates';
import {api as messageDialogApi} from "../MessageDialog/messageDialogActions";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  DASHBOARDPAGE_FETCHDATA_STARTED: 'DASHBOARDPAGE_FETCHDATA_STARTED',
  DASHBOARDPAGE_FETCHDATA_FINISHED: 'DASHBOARDPAGE_FETCHDATA_FINISHED',
  DASHBOARDPAGE_FETCH_HEADER_DATA_FINISHED: 'DASHBOARDPAGE_FETCH_HEADER_DATA_FINISHED',
  DASHBOARDPAGE_FETCH_CHART_DATA_FINISHED: 'DASHBOARDPAGE_FETCH_CHART_DATA_FINISHED',
  DASHBOARDPAGE_RESET_DATA:  'DASHBOARDPAGE_RESET_DATA',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function() {
    return {type: actionTypes.DASHBOARDPAGE_FETCHDATA_STARTED};
  },
  fetchDataFinished: function() {
    return {type: actionTypes.DASHBOARDPAGE_FETCHDATA_FINISHED};
  },
  fetchHeaderDataFinished: function(payload) {
    return {type: actionTypes.DASHBOARDPAGE_FETCH_HEADER_DATA_FINISHED, payload};
  },
  fetchChartDataFinished: function(payload) {
    return {type: actionTypes.DASHBOARDPAGE_FETCH_CHART_DATA_FINISHED, payload};
  },
  resetState: function (payload) {
    return {type: actionTypes.DASHBOARDPAGE_RESET_DATA, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function() {
  return function(dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.PRODUCTION_DASHBOARD);

    navigatorApi.setLoading(true)(dispatch, getState);

    api.init(dispatch, getState)().then(() => {
      navigatorApi.setLoading(false)(dispatch, getState);
    });
  }
};

jsxActions.fetchData = function(data) {
  return function(dispatch, getState) {
    api.fetchData(dispatch, getState)(data);
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.init = function(dispatch, getState) {
  return function (loadFirstTime) {
    return api.fetchHeaderData(dispatch, getState)().then(() => {
      let label = createLabelHelper('mat.header.navigator.').get('section.reports.productionDashboard');

      let topNavigatorData = {
        name: label,
        type: navigationStates.PRODUCTION_DASHBOARD,
        id: -1,
        components: []
      };

      navigatorApi.setData(dispatch, getState)(topNavigatorData);
    });
  };
};

api.fetchHeaderData = function(dispatch, getState) {
  return function(data) {
    return dashboardService.fetchHeaderData().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Dashboard Header Data Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let headerData = _createHeaderData(response.data);
      dispatch(actions.fetchHeaderDataFinished(headerData));
    })
  }
};

api.fetchData = function(dispatch, getState) {
  return function (data) {
    let loggedInUser = getState().login.get('loggedInUser');
    let isDemoMode = DashboardHelper.isDemoMode(loggedInUser);

   if (isDemoMode) {
     return api.handleChartDemoData(dispatch, getState)(data);
   }
   return api.fetchChartData(dispatch, getState)(dashboardService.fetchProductionChartData, data) ;
  }
};

api.fetchChartData = function(dispatch, getState) {
  return function(method, data) {
    dispatch(actions.fetchDataStarted());

    return method(data).then((response) => {
      dispatch(actions.fetchDataFinished());
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Dashboard Chart Data Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      let kpiData = _createKpiData(response.data, data);
      let chartData = _createChartData(response.data);
      dispatch(actions.fetchChartDataFinished({chartData, kpiData}));
      return {success: true};
    })
  }
};

api.handleChartDemoData = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.fetchDataStarted());

    return dashboardService.fetchDashboardsDemoDataSettings().then((response) => {

      if (!Network.isResponseValid(response)) {
        dispatch(actions.fetchDataFinished());
        console.error('Fetch Production dashboard chart data failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      let settings = (response.data && response.data.settings) ? response.data.settings : [];

      let isDemoDataSet = DashboardHelper.isDashboardDemoDataSet(settings, DashboardHelper.dashboardTypes.PRODUCTION);

      let method = isDemoDataSet ? dashboardService.fetchProductionChartDemoData : dashboardService.fetchProductionChartData;

      return api.fetchChartData(dispatch, getState)(method, data);
    })
  }
};

//////////////////////////////////////////////////////////////////

function _createHeaderData(data) {
  if (!data) {
    return null;
  }
  let labels = createLabelHelper('');
  let timePeriodOptions = DashboardHelper.convertDataToOptions(data.timePeriods, labels);
  let compareToOptions = DashboardHelper.convertDataToOptions(data.comparisonPeriods, labels);
  let categoryOptions = DashboardHelper.convertDataToOptions(data.categories, labels);
  let causeOptions = DashboardHelper.convertDataToOptions(data.defectCauses, labels);

  return {timePeriodOptions, compareToOptions, categoryOptions, causeOptions};
}

function _createKpiData(data, filterData) {
  if (!data ) {
    return null;
  }
  // let {kitTypeOverviewList, ...kpiData} = data;

  let {defectsNumber, defectsNumberLastYear,
    defectsRate, defectsRateLastYear,
    producedCounter, producedCounterLastYear} = data;

  let kpiData = {defectsNumber, defectsNumberLastYear,
                 defectsRate, defectsRateLastYear,
                 producedCounter, producedCounterLastYear };

  kpiData.timePeriod = filterData.timePeriod;
  kpiData.comparisonPeriod = filterData.comparisonPeriod;

  //TEMP HERE (FOR DEBUG ONLY) -------
  // kpiData.defectsNumber = 90;
  // kpiData.defectsNumberLastYear = 100;
  // kpiData.defectsRate = 25.234;
  // kpiData.defectsRateLastYear = 21.567;
  // kpiData.producedCounter = 12345;
  // kpiData.producedCounterLastYear = 12345;
  //-----------------------------------

  return kpiData;


}

function _createChartData(data) {
  if (!data || !data.kitTypeOverviewList) {
    return [];
  }

   // data.kitTypeOverviewList = _createDummyTempList(); //TEMP HERE (FOR DEBUG ONLY)

  let chartData = data.kitTypeOverviewList.map((item) => {
    return {
      name: item.kitTypeName,
      id: item.kitTypeId,
      defectRatePercentage: DashboardHelper.toPercentage(item.defectsRate),
      defectRate: `${item.defectsNumber}/${item.completedCounter}`,
      planned:  _minutesToDays(item.plannedDuration),
      actual:   _minutesToDays(item.actualDuration <= item.plannedDuration ? item.actualDuration : null),
      exceeded: _minutesToDays(item.actualDuration > item.plannedDuration ? item.actualDuration - item.plannedDuration : null),
      within:   _minutesToDays(item.actualDuration > item.plannedDuration ? item.plannedDuration : null),
    }
  });

  return chartData;
}

function _minutesToDays (value) {
  if (value === null || value === undefined) {
    return null;
  }
  let res =  Math.round((value * 100.0) / (24 * 60)) / 100;
  return res;
}


function _createDummyTempList() {
  let res = [
    {
      kitTypeId: 700,
      kitTypeName: "ATA 51 Doors",
      defectsRate: 20.9834566,
      defectsNumber: 2,
      producedCounter: 30,
      plannedDuration: 15.00333 * 24 * 60,
      actualDuration: 3.7 * 24 * 60,
    },
    {
      kitTypeId: 701,
      kitTypeName: "ATA 53 Fuselage",
      defectsRate: 30.000,
      defectsNumber: 555,
      producedCounter: 345345,
      plannedDuration: 0.123 * 24 * 60,
      actualDuration: 0.345 * 24 * 60
    },
    {
      kitTypeId: 702,
      kitTypeName: "ATA 55 Stabilizers",
      defectsRate: 18.0098,
      defectsNumber: 45,
      producedCounter: 3424,
      plannedDuration: 1.23456 * 24 * 60,
      actualDuration: 1.6789 * 24 * 60
    },
    {
      kitTypeId: 703,
      kitTypeName: "ATA 57 Wings",
      defectsRate: 5.456,
      defectsNumber: 3232323,
      producedCounter: 333234234,
      plannedDuration: 2.098 * 24 * 60,
      actualDuration: 2.009 * 24 * 60
    },
    {
      kitTypeId: 555,
      kitTypeName: "KitTypeTest-11111111",
      defectsRate: 29.246,
      defectsNumber: 2,
      producedCounter: 30,
      plannedDuration: 12.00511 * 24 * 60,
      actualDuration: 3.025 * 24 * 60
    },
    {
      kitTypeId: 666,
      kitTypeName: "KitTypeTest-222",
      defectsRate: 30,
      defectsNumber: 555,
      producedCounter: 345345,
      plannedDuration: 6 * 24 * 60,
      actualDuration: 9 * 24 * 60
    },
    {
      kitTypeId: 777,
      kitTypeName: "KitTypeTest-3",
      defectsRate: 10,
      defectsNumber: 45,
      producedCounter: 3424,
      plannedDuration: 7 * 24 * 60,
      actualDuration: 12 * 24 * 60
    },
    {
      kitTypeId: 888,
      kitTypeName: "KitTypeTest-444444444",
      defectsRate: 5,
      defectsNumber: 3232323,
      producedCounter: 333234234,
      plannedDuration: 2 * 24 * 60,
      actualDuration: 5 * 24 * 60
    },
    {
      kitTypeId: 999,
      kitTypeName: "KitTypeTest-11111111",
      defectsRate: 20,
      defectsNumber: 2,
      producedCounter: 30,
      plannedDuration: 5 * 24 * 60,
      actualDuration: 3 * 24 * 60
    },
    {
      kitTypeId: 10101010,
      kitTypeName: "KitTypeTest-222",
      defectsRate: 30,
      defectsNumber: 555,
      producedCounter: 345345,
      plannedDuration: 6 * 24 * 60,
      actualDuration: 9 * 24 * 60
    },
    {
      kitTypeId: 1111111,
      kitTypeName: "KitTypeTest-3",
      defectsRate: 10,
      defectsNumber: 45,
      producedCounter: 3424,
      plannedDuration: 15 * 24 * 60,
      actualDuration: 12 * 24 * 60
    },
    {
      kitTypeId: 222222222,
      kitTypeName: "KitTypeTest-444444444",
      defectsRate: 5,
      defectsNumber: 3232323,
      producedCounter: 33323423,
      plannedDuration: 2 * 24 * 60,
      actualDuration: 5 * 24 * 60
    },
    {
      kitTypeId: 13,
      kitTypeName: "KitTypeTest-11111111",
      defectsRate: 20,
      defectsNumber: 2,
      producedCounter: 30,
      plannedDuration: 5 * 24 * 60,
      actualDuration: 3 * 24 * 60
    },
    {
      kitTypeId: 14,
      kitTypeName: "KitTypeTest-222",
      defectsRate: 30,
      defectsNumber: 555,
      producedCounter: 345345,
      plannedDuration: 16 * 24 * 60,
      actualDuration: 9 * 24 * 60
    },
    {
      kitTypeId: 15,
      kitTypeName: "KitTypeTest-3",
      defectsRate: 10,
      defectsNumber: 45,
      producedCounter: 3424,
      plannedDuration: 9 * 24 * 60,
      actualDuration: 12 * 24 * 60
    },
    {
      kitTypeId: 16,
      kitTypeName: "KitTypeTest-444444444",
      defectsRate: 5,
      defectsNumber: 3232323,
      producedCounter: 333234234,
      plannedDuration: 2 * 24 * 60,
      actualDuration: 5 * 24 * 60
    },
    {
      kitTypeId: 17,
      kitTypeName: "KitTypeTest-11111111",
      defectsRate: 20,
      defectsNumber: 2,
      producedCounter: 30,
      plannedDuration: 5 * 24 * 60,
      actualDuration: 3 * 24 * 60
    },
    {
      kitTypeId: 18,
      kitTypeName: "KitTypeTest-222",
      defectsRate: 30,
      defectsNumber: 555,
      producedCounter: 345345,
      plannedDuration: 6 * 24 * 60,
      actualDuration: 9 * 24 * 60
    },
    {
      kitTypeId: 19,
      kitTypeName: "KitTypeTest-3",
      defectsRate: 10,
      defectsNumber: 45,
      producedCounter: 3424,
      plannedDuration: 5 * 24 * 60,
      actualDuration: 12 * 24 * 60
    },
    {
      kitTypeId: 20,
      kitTypeName: "KitTypeTest-444444444",
      defectsRate: 5,
      defectsNumber: 3232323,
      producedCounter: 333234234,
      plannedDuration: 2 * 24 * 60,
      actualDuration: 5 * 24 * 60
    },
    {
      kitTypeId: 21,
      kitTypeName: "KitTypeTest-11111111",
      defectsRate: 20,
      defectsNumber: 2,
      producedCounter: 30,
      plannedDuration: 11 * 24 * 60,
      actualDuration: 3 * 24 * 60
    },
    {
      kitTypeId: 22,
      kitTypeName: "KitTypeTest-222",
      defectsRate: 30,
      defectsNumber: 555,
      producedCounter: 345345,
      plannedDuration: 6 * 24 * 60,
      actualDuration: 9 * 24 * 60
    },
    {
      kitTypeId: 23,
      kitTypeName: "KitTypeTest-3",
      defectsRate: 10,
      defectsNumber: 45,
      producedCounter: 3424,
      plannedDuration: 12 * 24 * 60,
      actualDuration: 15 * 24 * 60
    },
    {
      kitTypeId: 24,
      kitTypeName: "KitTypeTest-444444444",
      defectsRate: 5,
      defectsNumber: 3232323,
      producedCounter: 333234234,
      plannedDuration: 2 * 24 * 60,
      actualDuration: 5 * 24 * 60
    },
  ];

  return res;
}

import * as workOrdersService from '../../../services/WorkOrders/workOrdersService';
import * as daHelper          from '../../Dialogs/dialogActionsHelper';
import {dialogsNames}         from '../../../enums/dialogsNames';

const config = {
  getSubmitMethod: () => workOrdersService.updateWorkOrderStatus,
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.ACTIVATE_WO_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.ACTIVATE_WO_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};

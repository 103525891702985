import Network from 'infrastructure/js/modules/network';


export function fetchPickLists(query = {}) {
  return Network.post('picklists/items', query);
}

export function generatePickListId() {
  return Network.get('picklists/generateId');
}

export function createPickList(data) {
  return Network.post('picklists', data);
}

export function updatePickList(pickListId, data) {
  return Network.put(`picklists/${pickListId}`, data);
}

export function addAssetsToPickList(data) {
  let pickListId = data.pickListId;
  delete data.pickListId;
  delete data.businessId;
  return Network.post(`picklists/${pickListId}/assets-assignment`, data);
}

export function removeAssetsFromPickList(pickListId, ids = []){
  return Network.post(`picklists/${pickListId}/deallocate`, {materialIds: ids});
}

export function deletePickList(pickListId) {
  return Network.delete(`picklists/${pickListId}`);
}

export function getPickListDetails(pickListId) {
  return Network.get(`picklists/${pickListId}`);
}
export function getPickListAssets(pickListId, query) {
  return Network.post(`picklists/${pickListId}/materials/items`, query);
}


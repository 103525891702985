import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import * as printersService from '../../../services/Rfid/printersService';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "../../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_RFID_SETTINGS_PRINTERS, _fetchDataAction);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let deviceBusinessId =  getState().administration.getIn(['rfidSettingsPage', 'printersViewData' , 'selectedDeviceApp']).label;

    if (!deviceBusinessId) {
      return Promise.resolve([]);
    }

    let quaryData = {
      deviceOrgBusinessId :getState().login.get('loggedInUser').loggedInOrg.businessId,
      deviceBusinessId: deviceBusinessId

    }

    return deviceAppsService.getDeviceProfile(quaryData).then((res) => {
      return printersService.fetchPrinters(res.data.profileId);
    });
  }
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormControl} from 'react-bootstrap';

import {Field} from 'redux-form';
import {getPropsWithReduxForm} from '../controlsUtils';
import ValidationComponent from 'infrastructure/js/components/controls/ValidationComponent/validationComponent.js'

require('./textAreaField.scss');

export default class PL_TextAreaFieldForm extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_TextAreaField}></Field>
    );
  }
};

export  class PL_TextAreaField extends React.PureComponent{
  getClassName (){
    if(this.props.className){
      return ' ' + this.props.className;
    }
    return '';
  }
  onChange = (data) =>{
    this.props.input.onChange(data);
    if(this.props.onChangeCallback){
      this.props.onChangeCallback(data.target.value);
    }
  }
  errorClass(){
    let {touched, error} = this.props.meta;
    return (touched && error ? " invalid" : "");
  }
  render() {
    return (
      <span className={"PL-textAreaField" + this.errorClass() + this.getClassName()}>
        <FormControl componentClass="textarea" {...getPropsWithReduxForm(this.props)} onChange={this.onChange} />
        <ValidationComponent {...this.props.meta} />
      </span>
    );
  }
}

PL_TextAreaField.propTypes = {
  id: PropTypes.string.isRequired,
  className : PropTypes.string
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as schedulerPageActions } from '../../actions/SchedulerPage/schedulerPageActions';
import { jsxActions as headerActions } from '../../actions/Header/headerActions';
import { jsxActions as fetchEntitiesActions } from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as schedulerOperationsGridActions } from '../../actions/SchedulerPage/schedulerOperationsGridActions';
import { jsxActions as schedulerGanttActions } from '../../actions/SchedulerPage/schedulerGanttActions';
import { jsxActions as scheduleTaskDialogActions } from '../../actions/SchedulerPage/scheduleTaskDialogActions';
import { jsxActions as editTaskAttributesActions } from '../../actions/Dialogs/EditTaskAttributesDialog/editTaskAttributesDialogActions';
import { jsxActions as autoSchedulerDialogActions } from '../../actions/SchedulerPage/autoSchedulerDialogActions';
import { jsxActions as createTaskDialogActions } from '../../actions/SchedulerPage/createTaskDialogActions';

import SchedulerPage from '../../components/PredefinedList/SchedulerPage/schedulerPage';
import { List } from 'immutable';
import { gridsNames } from '../../enums/gridsNames';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(schedulerPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      schedulerOperationsGridActions: bindActionCreators(schedulerOperationsGridActions, dispatch),
      schedulerGanttActions: bindActionCreators(schedulerGanttActions, dispatch),
      scheduleTaskDialogActions: bindActionCreators(scheduleTaskDialogActions, dispatch),
      autoSchedulerDialogActions: bindActionCreators(autoSchedulerDialogActions, dispatch),
      createTaskDialogActions: bindActionCreators(createTaskDialogActions, dispatch),
      editTaskAttributesActions: bindActionCreators(editTaskAttributesActions, dispatch),
    },
  };
}

let SchedulerPageContainer = connect((state) => {
  let schedulerOperationsGrid = state.grid.get(gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS)
    ? state.grid.get(gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS)
    : null;
  return {
    sSchedulerOperationsGrid: schedulerOperationsGrid,
    sHasRows: schedulerOperationsGrid && schedulerOperationsGrid.get('rows') ? schedulerOperationsGrid.get('rows').size > 0 : false,
    sSelectedRowsData: schedulerOperationsGrid ? schedulerOperationsGrid.get('selectedRowsData') : List(),
    sData: state.predefinedList.get('scheduler'),
    sScheduleTaskDialogData: state.predefinedList.getIn(['scheduler', 'scheduleTaskDialog']),
    sAutoSchedulerDialogData: state.predefinedList.getIn(['scheduler', 'autoSchedulerDialog']),
    sCreateTaskDialogData: state.predefinedList.getIn(['scheduler', 'createTaskDialog']),
    sEditTaskAttributesData: state.dialogs.get('editTaskAttributesDialog'),
  };
}, mapDispatchToProps)(SchedulerPage);

export default SchedulerPageContainer;

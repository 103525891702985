import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as orgsService from '../../../../services/Administration/organizationsService.js';
import { jsxActions as organizationsPageApi } from '../organizationsPageActions.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  DELETE_ORGANIZATION_DIALOG_SHOW:               'DELETE_ORGANIZATION_DIALOG_SHOW',
  DELETE_ORGANIZATION_DIALOG_HIDE:               'DELETE_ORGANIZATION_DIALOG_HIDE',
  DELETE_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS: 'DELETE_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS',
  DELETE_ORGANIZATION_STATUS_SUBMIT_FINISHED:    'DELETE_ORGANIZATION_STATUS_SUBMIT_FINISHED',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.DELETE_ORGANIZATION_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.DELETE_ORGANIZATION_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.DELETE_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.DELETE_ORGANIZATION_STATUS_SUBMIT_FINISHED, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, isAcceptDeletion, isRejectDeletion, messageDialogBuilder) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isAcceptDeletion, isRejectDeletion, messageDialogBuilder);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.showAcceptDeletion = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.show({ deletionData: data,
                            isAcceptDeletion: true,
                            isRejectDeletion: false,
                          }));
  }
};

api.showRejectDeletion = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.show({ deletionData: data,
                            isAcceptDeletion: false,
                            isRejectDeletion: true,
                          }));
  }
};

api.showRequestDeletion = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.show({ deletionData: data,
                            isAcceptDeletion: false,
                            isRejectDeletion: false,
                          }));
  }
};

api.submit = function(dispatch, getState) {
  return function(data, isAcceptDeletion, isRejectDeletion, messageDialogBuilder) {
    dispatch(actions.submitInProgress());

    let method = isAcceptDeletion ? orgsService.acceptOrganizationDeletion :
                 isRejectDeletion ? orgsService.rejectOrganizationDeletion :
                                    orgsService.requestOrganizationDeletion;
    method(data)
      .then((response) => {
        dispatch(actions.submitFinished());
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error(`${method} failed`);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        organizationsPageApi.init(true)(dispatch, getState);

        let messageDialogDescriptor = messageDialogBuilder(
          messageDialogApi.close(dispatch, getState),
        );

        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        return {success: true};
      });
  };
};


import { actionTypes as inventoryDashboardPageActionsTypes } from '../../../actions/Reports/inventoryDashboardPageActions';
import {Map} from 'immutable';

let defaultState = {
  loading: false,
  headerData: null,
  inventoryKpiData: null,
  usageKpiData: null,
  inventoryChartsData: {},
  usageChartsData: {},
  materials: [],
  locations: [],
  inventoryFilters: {
    searchBy: [
      {
        objectName: "locationId",
        values: []
      },
      {
        objectName: "isFreezer",
        values: []
      },
      {
        objectName: "material",
        values: []
      }
    ]
  },
  usageFilters: {
    searchBy: [
      {
        objectName: "material",
        values: []
      }
    ],
    materialUsagePeriod: "LAST_WEEK",
    consumptionTimePeriod: "IN_WEEKS",
  },
  /*materialUsageFilter: null,
  timeToConsumptionFilter: null,*/
};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_FETCH_DATA_STARTED:
      return state.set('loading', true);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_FETCH_DATA_FINISHED:
      return state.set('loading', false);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_FETCH_HEADER_DATA_FINISHED:
      return state.set('headerData', action.payload);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_FETCH_INVENTORY_CHART_DATA_FINISHED:
      return state.set('loading', false)
        .set('inventoryChartsData', action.payload);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_FETCH_USAGE_CHART_DATA_FINISHED:
      return state.set('loading', false)
        .set('usageChartsData', action.payload);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_FETCH_INVENTORY_KPI_DATA_FINISHED:
      return state.set('inventoryKpiData', action.payload);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_FETCH_USAGE_KPI_DATA_FINISHED:
      return state.set('usageKpiData', action.payload);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_UPDATE_INVENTORY_FILTERS:
      return state.set('inventoryFilters', action.payload);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_UPDATE_USAGE_FILTERS:
      return state.set('usageFilters', action.payload);

    case inventoryDashboardPageActionsTypes.INVENTORY_DASHBOARD_PAGE_RESET_DATA:
      return Map(defaultState);

    default:
      return state;
  }
}



import React from 'react';

import PropTypes from "prop-types";
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import SensorsViewGrid from "./SensorsViewGrid/sensorsViewGrid";
import {createLabelHelper} from "infrastructure/js/utils/labelHelper";
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import CreateSensorDialog from "./Dialogs/createSensorDialog.js";
import {SettingsMenuItemsIds} from "../../../../enums/navigationMenuItemsIds";
import {isAllItemsActive, isAllItemsDeactive} from "../../MatSettingsPage/utils/matSettingsHelper";

require('./sensorsView.scss');

export default  class SensorsView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.settings.sensors.view.');
  }
  componentDidMount(){
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_SENSORS);
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      title: this.labels.get('title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'add',
          label: headerItemsLabels.ADD,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreateSensorClick,
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditSensorClick,
          actionData: selectedRowsData.get(0)
        }
      ],
      kebab: {
        disabled: !hasRows,
        menuItems: [
          {
            id:'activate',
            label: headerItemsLabels.ACTIVATE,
            action: actions.header.onActivateSensorLogClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsDeactive(selectedRowsData)),
            actionData:  selectedRowsData,
          },
          {
            id:'deactivate',
            label: headerItemsLabels.DEACTIVATE,
            action: actions.header.onDeactivateSensorLogClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsActive(selectedRowsData)),
            actionData:  selectedRowsData,
          },
          {
            id:'remove',
            label: headerItemsLabels.REMOVE,
            action: actions.header.onRemoveSensorLogClick,
            disabled: (hasRows && (!selectedRowsData || selectedRowsData.size === 0)),
            actionData: selectedRowsData
          }

        ]
      }
    };
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="preferences-page">
        <Header {...headerItems} />
        <SensorsViewGrid actions={this.props.actions.sensorsViewGridActions}/>
        <PL_DialogWrapper dialogComponent={CreateSensorDialog}
                          show={this.props.sCreateSensorDialogData.get('show')}
                          actions={this.props.actions.createSensorDialogActions}
                          sData={this.props.sCreateSensorDialogData}
                          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}

SensorsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sSensorsGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired
};


import React from 'react';
import PropTypes from 'prop-types';

import Switch from 'react-switch';
import { Field } from 'redux-form';

require('./switch.scss');

export default class PL_SwitchForm extends React.PureComponent {
  render() {
    return <Field {...this.props} component={PL_Switch} />;
  }
}

export class PL_Switch extends React.PureComponent {
  handleChange = (data) => {
    this.props.input.onChange(data);
  };

  render() {
    let inputProps = {};
    if (this.props.checkedLabel && this.props.uncheckedLabel) {
      inputProps.uncheckedIcon = <div className="uncheckedIcon">{this.props.uncheckedLabel}</div>;
      inputProps.checkedIcon = <div className="checkedIcon">{this.props.checkedLabel}</div>;
    }

    let value = this.props.input.value === '' ? this.props.checked : this.props.input.value;

    return (
      <div id={this.props.id} className="pl-switch">
        <Switch
          onChange={this.handleChange}
          checked={value ?? false}
          disabled={this.props.disabled}
          onColor={this.props.onColor}
          width={this.props.width}
          {...inputProps}
        />
      </div>
    );
  }
}

PL_Switch.defaultProps = {
  width: 56,
  disabled: false,
  onColor: '#4da5b5',
  checked: false,
};

PL_Switch.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  width: PropTypes.number,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
};

import { useRef } from 'react';
import { useEffect, useMemo, useState } from 'react';
import network from '../../modules/network';

const getComputedValue = (value, allowEmptyStringValue) => {
  if (!value) {
    if (value === '') {
      return !allowEmptyStringValue ? 'getNoOptions' : value;
    } else {
      return 'getNoOptions';
    }
  }
  return value;
};

const checkInvalidOptions = (options) => {
  if (!Array.isArray(options)) {
    console.error('withAsyncProps: you must pass an array options.');
    return true;
  }

  const noSourceOptions = options.filter(({ config }) => !config?.type);
  if (noSourceOptions.length) {
    console.error(
      'withAsyncProps: type is missing! please provide type "redux" or "api" in config',
      noSourceOptions
    );
    return true;
  }

  return false;
};

const fetchFromNetwork = async (config, propName, value) => {
  const { fetchMethod, dataTranslator } = config;
  let data = false;
  if (fetchMethod) {
    data = await fetchMethod(value);
  }
  if (config.connection) {
    const { url, params, method, valueKey, allowEmptyStringValue } = config.connection;
    const computedValue = getComputedValue(value, allowEmptyStringValue);
    if (computedValue === 'getNoOptions') {
      return [];
    }
    const newParams = { ...params, [valueKey]: computedValue };

    if (network[method]) {
      data = await network[method](url, newParams);
    } else {
      console.warn(`unknown method ${method} in network`);
      data = await network.get(url, newParams);
    }

    if (data) {
      return { [propName]: dataTranslator ? dataTranslator(data) : data };
    } else {
      console.error('No fetchMethod or connection information were provided.', config, propName);
      return null;
    }
  }
};

const updateCache = (cache, key, value) => {
  cache.current = { ...cache.current, [key]: value };
};

const getFromCache = (cache, key) => cache.current[key];

/**
 * High order component for allowing pulling of props from server.
 * @constructor
 */
const withAsyncProps = (Component, asyncOptions) => {
  if (checkInvalidOptions(asyncOptions)) {
    return null;
  }

  if (!Component) {
    console.error('Missing component!');
    return 'div';
  }

  const apiCalls = asyncOptions.filter(({ config }) => config.onLoad);

  const WithAsyncProps = (props) => {
    const [loading, setLoading] = useState(false);
    const [asyncProps, setAsyncProps] = useState({});
    const [error, setError] = useState(null);
    const [asyncLoaders, setAsyncLoaders] = useState({});

    const cache = useRef({});

    useEffect(() => {
      setLoading(true);
      const promises = [];
      apiCalls.forEach(({ config, propName }) => {
        const { onLoad, initialValue } = config;
        if (onLoad) {
          onLoad();
        }
        promises.push(fetchFromNetwork(config, propName));
        if (initialValue) {
          setAsyncProps((oldProps) => ({ ...oldProps, [propName]: initialValue }));
        }
      });
      Promise.all(promises)
        .then((answers) => {
          const answersProps = answers.reduce((obj, item) => {
            return item ? { ...obj, ...item } : obj;
          }, {});
          setAsyncProps((oldProps) => ({ ...oldProps, ...answersProps }));
        })
        .catch((ex) => setError(ex))
        .finally(() => {
          setLoading(false);
        });
      return () => {
        cache.current = {};
      };
    }, []);

    const generateAsyncOnChangeMethods = (options) => {
      return options.reduce((obj, { config, propName }) => {
        const { initialValue } = config;
        obj[propName] = async (value) => {
          const cachedValue = getFromCache(cache, value);
          if (cachedValue) {
            setAsyncProps((oldData) => ({ ...oldData, ...cachedValue }));
            return;
          }
          setAsyncLoaders((loaders) => ({ ...loaders, [propName]: true }));
          const serverData = await fetchFromNetwork(config, propName, value);
          const propData = serverData ? serverData : { [propName]: initialValue };
          setAsyncProps((oldData) => ({ ...oldData, ...propData }));
          setAsyncLoaders((loaders) => ({ ...loaders, [propName]: false }));
          if (value) {
            updateCache(cache, value, serverData);
          }
        };
        return obj;
      }, {});
    };

    const computedProps = { ...props, ...asyncProps };
    const asyncMethods = useMemo(() => generateAsyncOnChangeMethods(asyncOptions), []);
    return (
      <Component
        {...computedProps}
        asyncLoading={loading}
        asyncError={error}
        asyncOnChangeMethods={asyncMethods}
        asyncLoaders={asyncLoaders}
      />
    );
  };
  return WithAsyncProps;
};

export default withAsyncProps;

import Network from 'infrastructure/js/modules/network';

export function fetchPreferences(query) {
  return Network.post('settings/org', query);
}
export function updatePreferences(query) {
  return Network.put('settings/setting', query);
}
export function fetchZplPreferences(query) {
  return Network.post('settings/org/data-category/zpl', query);
}
export function updateZplPreferences(query) {
  return Network.put('settings/setting/data-category/zpl', query);
}

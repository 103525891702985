import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';

require('./createVisibleDeviceDialog.scss');

class CreateVisibleDeviceDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.rfidsettings.dialog.createVisibleDevice.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;
    this.isCloudMonitor = {label: this.labels.get('cloudMonitor'), value: 'isCloudMonitor'};
    this.isRfidMonitor = {label: this.labels.get('rfidMonitor'), value: 'isRfidMonitor'};
    this.isTemperatureMonitor = {label: this.labels.get('temperature'), value: 'isTemperatureMonitor'};
    this.isHumidityMonitor = {label: this.labels.get('humidity'), value: 'isHumidityMonitor'};
    this.purposeOptions = [ this.isCloudMonitor, this.isRfidMonitor, this.isTemperatureMonitor, this.isHumidityMonitor ];

    this.state = {
      isLocationEnabled : true,
    };
  }

  componentDidMount() {
    let initialValues = {
      isAutomatic: true,
      controlPort: 80,
      purpose:[]
    };

    if (this.isEditMode) {
      let location = this.itemToEdit.locations ? this.itemToEdit.locations : [];
      initialValues = {
        name: this.itemToEdit.name,
        host: this.itemToEdit.host,
        isAutomatic: this.itemToEdit.automatic,
        location: this.createLocationsOption(location),
        controlPort: this.itemToEdit.controlPort,
        purpose: this.getSelectedPurposes()
      };
    }

    this.setState({
      isLocationEnabled: initialValues.isAutomatic,
    });

    this.props.initialize(initialValues);
  }

  createLocationsOption = (location) => {
    return location ? location.map((l)=> {return {label: l.name, value: l.id, data: l}}) : [];
  };

  getSelectedPurposes(){
    let options = [];
    if(!this.itemToEdit){
      return options;
    }

    if(this.itemToEdit.isHumidityMonitor){
      options.push(this.isHumidityMonitor)
    }
    if(this.itemToEdit.isTemperatureMonitor){
      options.push(this.isTemperatureMonitor)
    }
    if(this.itemToEdit.isRfidMonitor){
      options.push(this.isRfidMonitor)
    }
    if(this.itemToEdit.isCloudMonitor){
      options.push(this.isCloudMonitor)
    }

    return options;
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancelBtn',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submitBtn',
          text: this.isEditMode ? this.labels.get('footer.edit') : this.labels.get('footer.create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  onAutomaticCheckboxClick = () => {
    this.setState({isLocationEnabled: !this.state.isLocationEnabled});
  };

  onSubmit = (data) => {

    let newData = {
      deviceAppProfileId: this.props.deviceApp.label,
      name: data.name,
      host: data.host,
      isAutomatic: data.isAutomatic,
      isHumidityMonitor : this.isPurposeExist(data.purpose, 'isHumidityMonitor'),
      isTemperatureMonitor: this.isPurposeExist(data.purpose, 'isTemperatureMonitor'),
      isCloudMonitor: this.isPurposeExist(data.purpose, 'isCloudMonitor'),
      isRfidMonitor: this.isPurposeExist(data.purpose, 'isRfidMonitor'),
      locationIds : data.location ? data.location.map((location)=> {return location.data.id}) : [],
      controlPort: data.controlPort,
    };

    if(this.isEditMode) {
      newData.id =  this.itemToEdit.id;
    }
    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  isPurposeExist(purpose, value){
    return purpose ? purpose.some(p => p.value === value) : false
  }
  onHide =() => {
    this.props.actions.hide();
  };

  validateAppControlPort = (value)=>{
    if (!value || value === '') {
      return undefined;
    }

    let min = 10000;
    let max = 65535;
    if ((min <= value && value <= max) || value == 80) {
      return undefined;
    }
    return this.labels.get('portError');
  };

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    return (
      <Dialog
        id="create-visible-device-dialog"
        className="create-visible-device-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('devicePurpose')} htmlFor='locations' className="no-margin full-width">
          <PL_MultiSelectField name='purpose' id='purpose' options={this.purposeOptions}/>
        </InputSection>

        <InputSection label={this.labels.get('deviceName')+'*'}
                      htmlFor="name"
                      className="inline small">
          <TextField id="name"
                     name="name"
                     className="short-textfield"
                     maxLength={30}
                     validate={[Validation.required]}/>
        </InputSection>

        <InputSection label={this.labels.get('host')+'*'}
                      htmlFor="host"
                      className="inline small">
          <TextField id="host"
                     name="host"
                     className="short-textfield"
                     validate={[Validation.required , Validation.ipFormat]}
                     normalize={Normalize.numberAndDot()}/>
        </InputSection>

        <InputSection label={this.labels.get('controlPort')+'*'}
                      htmlFor="controlPort"
                      className="inline small">
          <TextField id="controlPort"
                     name="controlPort"
                     className="short-textfield"
                     maxLength={5}
                     normalize={Normalize.number()}
                     validate={[Validation.required, this.validateAppControlPort]}/>
        </InputSection>

        <InputSection label={this.labels.get('location')}
                      htmlFor="location"
                      className="inline left-side">
          <PL_MultiSelectField id="location"  name="location" options={this.props.sData.get('dialogData').locations}/>
        </InputSection>

        <InputSection htmlFor= "isAutomatic"
                      className="inline right-side padding-top">
          <Checkbox name= "isAutomatic"
                    id= "isAutomatic"
                    label={this.labels.get('mode')}
                    onClick={this.onAutomaticCheckboxClick}/>
        </InputSection>

      </Dialog>
    );
  }
}

CreateVisibleDeviceDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'createVisibleDevice',
  }
)(CreateVisibleDeviceDialog);

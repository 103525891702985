import React from 'react';
import PropTypes from 'prop-types';


//Style
require('./multiRowsCell.scss');

export default class MultiRowsCell extends React.PureComponent {

  render() {
    const dataList = this.props?.value?.dataList

    if(!dataList){
      return null
    }

    return (<div className="multi-rows-cell">
      {dataList.map((data, index)=> {
        return (
            <this.props.value.componentType
              {...this.props}
                data={data}
                value={data}
                key={`multi-row-${index}`}
                index={index}
            />
        );
      })}
    </div>);
  }
}

MultiRowsCell.propTypes = {
  value: PropTypes.object
};





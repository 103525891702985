import React, {Component} from 'react';

import PL_Wizard  from 'infrastructure/js/components/Wizard/wizard';
import UploadFileStep from './SubComponents/uploadFileStep';
import ImportFileStep from './SubComponents/importFileStep';

require('./importFilesWizard.scss');


export default class ImportFilesWizard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.steps = {
      'step_1':   <UploadFileStep />,
      'step_2':   <ImportFileStep />
    };


  }


  render() {
    return (
      <PL_Wizard wizardName="IMPORT_FILES_WIZARD" steps={this.steps} {...this.props} form="wizard_form_IMPORT_FILES"/>
    );

  }

}











import Network from 'infrastructure/js/modules/network';

export function fetchStations(query) {
  return Network.post('stations/items', query);
}

export function getAvailableStations() {
  return Network.get('stations');
}

export function getAllStations() {
  return Network.get('stations');
}

export function createStation(data) {
  return Network.post('stations', data);
}

export function updateStations(data) {
  return Network.put('stations/update' , data);
}

export function FetchSummaryCounts(station){
  return Network.get(`stations/${station}/summarycounts`);
}
export function getUnkittedReportedCuts(station){
  return Network.get(`stations/${station}/not-kitted-reported-cuts`);
}

import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Link from '../../../../../Common/Layout/Link/link';
import Label from 'infrastructure/js/components/Label/label';
import isEmpty from 'lodash-es/isEmpty';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import * as AssetHelper from '../../../../../../utils/assetHelper';
require('./moreInfoCell.scss');

export default class MoreInfoCell extends React.PureComponent {
  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.cells.moreInfo.');
  }

  getPopoverComponent = (data) => {
    let warningLabel = createLabelHelper('mat.smartSelect.warning');
    let messages = [];
    data.warnings.map((warning, index) => {
      if(!warning){
        return;
      }
      messages.push(<span className="asset-alert-message" key={'alertMessage' + index}>{warningLabel.get('','',{woBusinessId:warning.params.woBusinessId, leftTime:warning.params.leftTime})}</span>);
      if (index !== (data.warnings.length - 1)) {
        messages.push(<br key={'alertBr' + index}/>);
      }
    });

    return <div>{messages}</div>;
  };

  getIconPopOver = (data) => {
    if (isEmpty(data.warnings)) {
      return null;
    }

  return (<AlertPopover
      popoverType={'warning'}
      popoverComponent={this.getPopoverComponent(data)}
    />)
  };

  render() {
    let data = this.props.value;
    if (!data) {
      return '';
    }

    let allLabels = createLabelHelper('');
    return (<div className="smart-selection-more-info-custom-cell">
      <label>{this.labels.get('assetId')} <Link label={data.assetBusinessId} id={data.assetId} type={AssetHelper.getNavigationStateByEntityType(data.objectType)}/> {this.getIconPopOver(data)}</label>
      <label>{this.labels.get('lot')} <Label text={data.lot ? data.lot: '' }/></label>
      <label>{this.labels.get('manufacturer')} <Label text={data.manufacturer}/></label>
      <label>{this.labels.get('inspectionStatus')} <Label text={data.inspectionStatus ? allLabels.get(data.inspectionStatus) : ''}/></label>
      <label>{this.labels.get('project')} <Label text={data.project ? data.project.businessId : ''}/></label>
    </div>);
  }
}

MoreInfoCell.propTypes = {
  value : PropTypes.object
};





import * as workOrdersService from '../../services/WorkOrders/workOrdersService';
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";
import WoGridHelper from "../../../../infrastructure/js/utils/woGridHelpers";

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_OPEN_WORK_ORDER, _fetchDataAction, WoGridHelper._convertServerItemsToGridRowsData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return workOrdersService.fetchActiveWorkOrders(query);
  }
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

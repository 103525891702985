import { Map } from 'immutable';
import { actionTypes as actionTypes } from '../../../actions/SchedulerPage/analyticModeSettingsDialogActions';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';

let defaultState = {
  show: false,
  loading: undefined,
  dialogData: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    default:
      return defaultDialogReducer(state, action, dialogsNames.ANALYTIC_MODE_SETTINGS_DIALOG, defaultState);

  }
}

import Network from 'infrastructure/js/modules/network';

export function getAllNotifications() {
  return Network.get('notifications');
}

export function getNotifications(query = {}) {
  return Network.post('notification/items', query);
}

export function getNotificationsCount() {
  return Network.get('notifications/userCounter?status=UNREAD');
}

export function markAllAsRead() {
  return Network.post('notification/markAllNotificationsAsRead');
}

export function changeReadStatus(id,data) {
  return Network.post(`notification/changeReadStatus/ ${id}`, data);
}
export function getCategories(){
  return Network.get('enum/Category');
};





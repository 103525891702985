import { api as gridApi} from './readersAndAntennasViewGridActions';
import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import * as readersAndAntennasService from '../../../services/Rfid/readersAndAntennasService';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import Network from 'infrastructure/js/modules/network';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_FETCH_DEVICE_APPS_IN_PROCESS :'ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_FETCH_DEVICE_APPS_IN_PROCESS',
  ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_FETCH_DEVICE_APPS_READY: 'ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_FETCH_DEVICE_APPS_READY',
  ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_DEVICE_APP_CHANGED: 'ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_DEVICE_APP_CHANGED'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  fetchDeviceAppsInProgress: function() {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_FETCH_DEVICE_APPS_IN_PROCESS};
  },
  fetchDeviceAppsFinished: function(payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_FETCH_DEVICE_APPS_READY , payload};
  },
  deviceAppChanged: function(payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_READERS_AND_ANTENNAS_DEVICE_APP_CHANGED, payload};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    return api.load(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.onDeviceAppChanged = function(data) {
  return function(dispatch, getState) {
    api.deviceAppChanged(dispatch, getState)(data);
  }
};

jsxActions.onRemoveReaderClick = function(messageDialogBuilder , selectedItem) {
  return function(dispatch, getState) {
    api.openRemoveReaderConfirmationDialog(dispatch, getState)(messageDialogBuilder, selectedItem);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function(dispatch, getState) {
  return function(shouldRefreshGrid) {

    dispatch(actions.fetchDeviceAppsInProgress());

    return deviceAppsService.fetchDeviceApps().then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('FETCH_DEVICE_APPS failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let deviceAppsOptions = response.dataList.map((obj) => {return {value: obj.id, label: obj.username}});

      if (shouldRefreshGrid) {
        gridApi.reload(dispatch, getState)();
      }

      dispatch(actions.fetchDeviceAppsFinished(deviceAppsOptions));
    });
  }
};


api.deviceAppChanged = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.deviceAppChanged(data));
    gridApi.reload(dispatch, getState)();
  }
};


api.openRemoveReaderConfirmationDialog = function(dispatch, getState) {
  return function(messageDialogBuilder , selectedItem) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.removeReader(dispatch, getState)(selectedItem)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

  }
};

api.removeReader = function(dispatch, getState) {
  return function(selectedItem) {

    messageDialogApi.close(dispatch, getState)();

    readersAndAntennasService.deleteReaders(selectedItem).then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('Remove Reader Failed');

        messageDialogApi.responseError(dispatch, getState)(response);
        return {success : false};
      }
    });
    gridApi.reload(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import AdminTab from '../../../components/Administration/OrganizationsPage/AdminTab/adminTab.js';
import {gridsNames} from '../../../enums/gridsNames';
import {jsxActions as headerActions} from '../../../actions/Header/headerActions.js';
import {jsxActions as adminTabGridActions } from '../../../actions/Administration/OrganizationsPage/adminTabGridActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      header: bindActionCreators(headerActions, dispatch),
      adminTabGridActions: bindActionCreators(adminTabGridActions, dispatch)
    }
  }
}

let AdminTabContainer = connect(
  (state) => {
    let adminTabGrid = state.grid.get(gridsNames.ADMINISTRATION_ADMIN_TAB) ? state.grid.get(gridsNames.ADMINISTRATION_ADMIN_TAB) : null;
    return {
      sAdminTabGrid: adminTabGrid,
      sHasRows: adminTabGrid && adminTabGrid.get('rows') ? adminTabGrid.get('rows').size > 0 : false,
      sSelectedRowsData: adminTabGrid ? adminTabGrid.get('selectedRowsData') : List()
    };
  },
  mapDispatchToProps
)(AdminTab);

export default AdminTabContainer;





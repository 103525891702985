import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import { api as navigatorApi } from '../../AppTopbar/navigatorActions.js';
import {navigationStates} from '../../../enums/navigationStates';
import {api as messageDialogApi} from "../../MessageDialog/messageDialogActions";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_PS_MANAGEMENT_SELECTED_MENU_ITEM_CHANGED : 'ADMINISTRATION_PS_MANAGEMENT_SELECTED_MENU_ITEM_CHANGED'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  setSelectedMenuItemId: function(payload) {
    return {type: actionTypes.ADMINISTRATION_PS_MANAGEMENT_SELECTED_MENU_ITEM_CHANGED , payload};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(loadFirstTime) {
  return function(dispatch, getState) {
    api.init(dispatch, getState)(loadFirstTime);
  }
};

jsxActions.onSelectedMenuItemChanged  = function (selectedItemId) {
  return function(dispatch, getState) {
    dispatch(actions.setSelectedMenuItemId(selectedItemId));
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(loadFirstTime) {
    navigatorApi.setLoading(loadFirstTime)(dispatch, getState);

    let topNavigatorData = {
      name: createLabelHelper('mat.header.navigator.administration.').get('psmanagement'),
      type: navigationStates.PS_MANAGEMENT,
      id: 0,
      components: []
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);
    navigatorApi.setLoading(false)(dispatch, getState);
  }
};



/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS








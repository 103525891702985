import {ImportTypes} from '../enums/importTypes';

const getQueryDataForImport = function getQueryDataForImport (userFileName, token , printLabels , locationId , entityType, currentCutId, shouldIncludeLocation) {

  let dataQuery = {
    fileName: token,
    userFileName: userFileName
  };

  if(entityType === ImportTypes.IMPORT_ROLLS) {
    dataQuery.printLabels = printLabels;
    dataQuery.locationId = shouldIncludeLocation ? null : locationId;
  }

  if(entityType === ImportTypes.IMPORT_SPOOLS) {
    dataQuery.printLabels = printLabels;
    dataQuery.locationId = shouldIncludeLocation ? null : locationId;
  }

  if(entityType === ImportTypes.IMPORT_WO) {
    dataQuery.locationId = locationId;
  }

  if(entityType === ImportTypes.IMPORT_KITS) {
    dataQuery.locationId = shouldIncludeLocation ? null : locationId;
    dataQuery.printLabels = printLabels;
  }

  if(entityType === ImportTypes.IMPORT_GROUPS) {
    dataQuery.locationId = shouldIncludeLocation ? null : locationId;
  }

  if(entityType === ImportTypes.IMPORT_SHIPMENTS) {
    dataQuery.locationId = locationId;
    dataQuery.printLabels = printLabels;
  }


  if(entityType === ImportTypes.IMPORT_TOOLS) {
    dataQuery.locationId = shouldIncludeLocation ? null : locationId;
  }

  //TODO: Integrate with server with import nests api for sending query params.
  if(entityType === ImportTypes.IMPORT_NESTS) {
  }

  return dataQuery;
};

const getQueryDataForValidate = function getQueryDataForValidate (assetType , token , locationId , currentCutId, shouldIncludeLocation = false) {

  let dataQuery = {
    fileName : token
  };
  switch(assetType) {
    case ImportTypes.IMPORT_ROLLS:
    case ImportTypes.IMPORT_SPOOLS:
    case ImportTypes.IMPORT_TOOLS:
      dataQuery.shouldIncludeLocation = shouldIncludeLocation;
      break;
    case ImportTypes.IMPORT_TAGS:
    case ImportTypes.IMPORT_KIT_TYPES:
    case ImportTypes.IMPORT_OPERATION_SEQUENCE:
      break;
    case ImportTypes.IMPORT_WO:
      dataQuery.locationId = locationId;
      break;
    case ImportTypes.IMPORT_KITS:
    case ImportTypes.IMPORT_GROUPS:
      dataQuery.shouldIncludeLocation = shouldIncludeLocation;
      break;
    case ImportTypes.IMPORT_NESTS: //TODO: Integrate with server with validate nests api for sending query params.
    case ImportTypes.IMPORT_NESTS_WITH_ROLL: //TODO: Integrate with server with validate nests api for sending query params.
      break;
    default:
  }

  return dataQuery;
};

export default {getQueryDataForValidate, getQueryDataForImport};

import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from "../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell";
import {navigationStates} from "../../../../../enums/navigationStates";
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import DevicePurposeCell from "../DevicePurposeCell/devicePurposeCell";
export default class VisibilityDevicesViewGrid extends React.PureComponent{

  filterConfig = [];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.visibilityDevice.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.deviceName.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'host',
        title: this.labels.get('columns.host.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.locations.map(location =>  ({ id: location.id, type: navigationStates.LOCATION, label: location.name})),
              componentType: EntityIdCell
            };
          }
        }
      },
      {
        fieldName: 'purpose',
        title: this.labels.get('columns.purpose.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: DevicePurposeCell,
          valueGetter: (params) => {
            return {
              isAppMonitor: params.data.isAppMonitor,
              isCloudMonitor: params.data.isCloudMonitor,
              isHumidityMonitor: params.data.isHumidityMonitor,
              isRfidMonitor: params.data.isRfidMonitor,
              isTemperatureMonitor: params.data.isTemperatureMonitor,
              appMonitorPort:params.data.appMonitorPort,
              appMonitorIntervalMsec:params.data.appMonitorIntervalMsec,
            };
          }
        }
      },

    ]
  };

  getRowHeight(params) {
    let purposeCell = 64;
    if(params.data.isAppMonitor){
      purposeCell += 50;
    }
    if(params.data.isCloudMonitor){
      purposeCell += 30;
    }
    if(params.data.isHumidityMonitor){
      purposeCell += 30;
    }
    if(params.data.isRfidMonitor){
      purposeCell += 30;
    }
    if(params.data.isTemperatureMonitor){
      purposeCell += 30;
    }
    let locationCell = 64;
    if (params.data.locations && params.data.locations.length > 1) {
      locationCell = params.data.locations.length * 30 + 40;
    }
    return Math.max(purposeCell, locationCell);
  }

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_RFID_SETTINGS_VISIBILITY_DEVICES}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}

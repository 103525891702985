import * as boHelper              from '../batchOperationDialogActionsHelper';
import * as workOrdersService from '../../../services/WorkOrders/workOrdersService';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('EDIT_WORKORDERS_STATUS_DIALOG'),
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('EDIT_WORKORDERS_STATUS_DIALOG'),
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
const config = {
  submitMethod: workOrdersService.updateWorkOrdersStatus,
  dialogReduxStorageName: 'editWorkOrdersStatusDialog'
};

export let api = {
  ...boHelper.getApiActions(actions, config),
};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api)
};



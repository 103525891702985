import PropTypes from 'prop-types';
import Accordion from 'infrastructure/js/components/Accordion/accordion';
import SideNavAccordionSummary from './sideNavAccordionSummary';
import SideNavAccordionDetails from './sideNavAccordionDetail';

export default function MenuItem({ itemData, expanded, onExpandChange, actions, isFavoritesFull }) {
  const { items } = itemData;
  return (
    <Accordion expanded={expanded} onChange={onExpandChange}>
      <SideNavAccordionSummary {...itemData} />
      {items && <SideNavAccordionDetails items={items} actions={actions} isFavoritesFull={isFavoritesFull} />}
    </Accordion>
  );
}

MenuItem.propTypes = {
  itemData: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  actions: PropTypes.object,
  isFavoritesFull: PropTypes.bool,
};

MenuItem.defaultProps = {
  expanded: false,
  onExpandChange: () => {},
  actions: {},
  isFavoritesFull: undefined,
};

import { actionTypes as tasksViewActionTypes } from '../../../actions/LocationPage/TasksView/tasksViewActions';
import { Map } from 'immutable';

let defaultState = {
  viewDate: null
};


export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case tasksViewActionTypes.LOCATIONPAGE_TASKSVIEW_CHANGE_VIEW_DATE:
      return state.set("viewDate", action.payload);

    default:
      return state;
  }
}



import { Map } from 'immutable';
import { actionTypes as actionTypes } from '../../../actions/SchedulerPage/analyticModeActions';

let defaultState = {
    schedulingGoals: [],
    loading: false,
};

export default function (state = Map(defaultState), action) {
    switch (action.type) {
        case actionTypes.SCHEDULER_ANALYTIC_MODE_FETCH_DATA_FINISHED:
            return state.set('schedulingGoals', action.payload);
        case actionTypes.SCHEDULER_ANALYTIC_MODE_LOADING:
            return state.set('loading', action.payload);
        default:
            return state;
    }
}

import React from 'react';

import Label from 'infrastructure/js/components/Label/label'
import moment from 'moment';
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";

const hoursMinutesFormat = 'HH:mm';
export default class TimeFrameCell extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let data = this.props.value;
    if (!data || !data.timeFrom || !data.timeTo) {
      return null;
    }

    let format = DateTimeHelper.getTimeFormat() === hoursMinutesFormat ? hoursMinutesFormat : 'LT';
    let timeFrom = moment(data.timeFrom, hoursMinutesFormat).format(format);
    let timeTo = moment(data.timeTo, hoursMinutesFormat).format(format);

    let daysOptions = DateTimeHelper.getDaysOfWeekOptions();
    let dayFrom = daysOptions.find(day => data.dayFrom === day.value);
    let dayTo = daysOptions.find(day => data.dayTo === day.value);
    let fullText = `${dayFrom ? dayFrom.label : null} ${timeFrom} - ${dayTo ? dayTo.label : null} ${timeTo}`;

    return (<div>
      <Label text={fullText} />
    </div>);
  }
}

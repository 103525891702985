import {Map} from 'immutable';
import { actionTypes } from '../../actions/LatencyCalc/latencyCalcActions.js';

let defaultState = Map({
  loading : false,
  result: null,
  hasError: null
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.LATENCY_CALC_IN_PROCESS:
      return state.set('loading', true)
        .set('result', null)
        .set('hasError', false);

    case actionTypes.LATENCY_CALC_FAILED:
      return state.set('loading', false)
        .set('result', null)
        .set('hasError', true);

    case actionTypes.LATENCY_CALC_CALC_FINISHED:
      return state.set('loading', false)
        .set('hasError', false)
        .set('result', action.payload);

    default:
      return state;
  }
}

import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

import CheckboxCell from 'infrastructure/js/components/Grid/CheckboxCell/checkboxCell';
import CheckboxHeader from 'infrastructure/js/components/Grid/CheckboxHeader/checkboxHeader';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import { navigationStates } from '../../../../enums/navigationStates';
import DefrostingLabelCell from '../../../Common/CustomGridCells/DefrostingLabelCell/defrostingLabelCell';
import LengthCell from '../../../Common/CustomGridCells/LengthCell/lengthCell';
import ValueWithStateCell from '../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell';
import MoreInfoCell from './CustomCells/MoreInfoCell/moreInfoCell';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import WeightCell from '../../../Common/CustomGridCells/WeightCell/weightCell';

import './smartSelectionDetailGrid.scss';

export default class SmartSelectionDetailGrid extends Component {
    constructor(props) {
        super(props);
        this.areAllRowsSelected = false;
        this.labels = createLabelHelper('mat.grid.');
        this.allLabels = createLabelHelper('');
        this.isWeightSupported = PermissionManager.isWeightSupported();

        this.state = {
            colDefs: [
                {
                    field: '',
                    headerName: '',
                    width: 60,
                    suppressSizeToFit: true,
                    resizable: false,
                    sortable: false,
                    filter: false,
                    checkboxSelection: true,
                    cellRenderer: CheckboxCell,
                    headerComponent: CheckboxHeader,
                    headerComponentParams: {
                        gridName: 'smartSelectionDetailGrid',
                        getValue: () => {
                            return this.areAllRowsSelected;
                        },
                        onChecked: () => this.api.selectAll(),
                        onUnchecked: () => this.api.deselectAll(),
                    },
                    cellClass: 'checkbox-style-wrapper',
                },
                {
                    field: 'alertStatus',
                    headerName: this.labels.get('columns.alert.title'),
                    width: 120,
                    resizable: true,
                    suppressSizeToFit: true,
                    cellRenderer: AssetAlertPopoverCell,
                    valueGetter: (params) => {
                        return {
                            description: '',
                            alerts: params.data.alerts,
                            alertStatus: params.data.alertStatus,
                            exposureTimeLeftBond: params.data.exposureTimeLeftBond,
                            exposureTimeLeftCure: params.data.exposureTimeLeftCure,
                            expirationDaysLeft: params.data.m_ExpirationDaysLeft,
                            expirationDate: params.data.expirationDate,
                        };
                    },
                },
                {
                    field: 'location',
                    resizable: true,
                    headerName: this.labels.get('columns.location.title'),
                    cellRenderer: EntityIdCell,
                    valueGetter: (params) => {
                        return {
                            id: params.data.locationId,
                            type: navigationStates.LOCATION,
                            label: params.data.locationName,
                        };
                    },
                },
                {
                    field: 'defrostingStatus',
                    headerName: this.labels.get('columns.defrosting.title'),
                    width: 170,
                    resizable: true,
                    suppressSizeToFit: true,
                    cellRenderer: DefrostingLabelCell,
                    valueGetter: (params) => {
                        return {
                            status: params.data.defrostingStatus,
                            statusLabel: params.data.defrostingStatusDisplayKey
                                ? this.allLabels.get(params.data.defrostingStatusDisplayKey)
                                : '',
                            defrostingTimeLeft: params.data.defrostingTimeLeft,
                        };
                    },
                },
                {
                    field: this.isWeightSupported ? 'weight' : 'lengthLeft',
                    headerName: this.labels.get('columns.quantity.title'),
                    width: 150,
                    resizable: true,
                    suppressSizeToFit: true,
                    cellRenderer: this.isWeightSupported ? WeightCell : LengthCell,
                    valueGetter: (params) => {
                        return this.isWeightSupported ?
                          { weight: params.data.weight } : {length: params.data.lengthLeft}
                    },
                },
                {
                    field: 'expirationDate',
                    headerName: this.labels.get('columns.expiration.title'),
                    isTime: false,
                    resizable: true,
                    cellRenderer: ValueWithStateCell,
                    valueGetter: (params) => {
                        if (params.data.expirationDate && params.data.expirationDate.value) {
                            return {
                                value: DateTimeHelper.FormatDateObjectToDayMonth(
                                    params.data.expirationDate.value
                                ),
                                state: params.data.expirationDate.state,
                            };
                        }

                        return { value: '', state: '' };
                    },
                    headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
                },
                {
                    field: 'exposureTimeLeft',
                    resizable: true,
                    headerName: this.labels.get('columns.etl.title'),
                    cellRenderer: ValueWithStateCell,
                    valueGetter: (params) => {
                        if (
                            params.data.exposureTimeLeftCure &&
                            params.data.exposureTimeLeftCure.value !== null
                        ) {
                            let minutes = params.data.exposureTimeLeftCure.value;
                            return {
                                value: DateTimeHelper.ConvertMinutesToHoursMinutes(minutes),
                                state: params.data.exposureTimeLeftCure.state,
                            };
                        }

                        return { value: '', state: '' };
                    },
                },
                {
                    field: 'moreInfo',
                    headerName: this.labels.get('columns.moreInfo.title'),
                    width: 310,
                    resizable: true,
                    suppressSizeToFit: true,
                    cellRenderer: MoreInfoCell,
                    valueGetter: (params) => {
                        return {
                            warnings: params.data.smartSelectionWarnings,
                            assetId: params.data.id,
                            assetBusinessId: params.data.businessId,
                            objectType: params.data.objectType,
                            lot: params.data.lot,
                            manufacturer: params.data.manufacturerModel
                                ? params.data.manufacturerModel.name
                                : '',
                            inspectionStatus: params.data.inspectionStatusDisplayKey
                                ? params.data.inspectionStatusDisplayKey
                                : '',
                            project: params.data.project,
                        };
                    },
                },
            ],

            defaultColDef: {
                minWidth: 60,
            },
            rowId: props.node.id,
            masterGridApi: props.api,
        };
    }

    onGridReady = (params) => {
        this.api = params.api;
        const gridInfo = {
            id: this.state.rowId,
            api: params.api,
            columnApi: params.columnApi,
        };

        const preselectedRows = this.props
            .getSelectedRows()
            .filter((row) => row.parentId === this.state.rowId);

        params.api.forEachNode((node) => {
            if (preselectedRows.some((row) => row.id === node.data.id)) {
                node.setSelected(true);
            }
            node.setData({ ...node.data, parentId: this.state.rowId });
        });

        this.state.masterGridApi.addDetailGridInfo(this.state.rowId, gridInfo);
    };

    componentWillUnmount = () => {
        // the detail grid is automatically destroyed as it is a React component
        this.state.masterGridApi.removeDetailGridInfo(this.state.rowId);
    };

    onRowDataChanged(event) {
        this.areAllRowsSelected = false;
        if (this.api) {
            this.api.refreshHeader();
            this.props?.onRowDataChanged?.(this.api);
        }
    }

    updateCheckboxHeader = (selectedRows) => {
        if (selectedRows.length === 0) {
            this.areAllRowsSelected = false;
        } else {
            // update 'areAllRowsSelected' for Selection column header re-render
            this.areAllRowsSelected = true;
            this.api.forEachNode((node) => {
                if (!node.isSelected() && !node.data.isRowDisabled) {
                    this.areAllRowsSelected = false;
                }
            });
        }

        this.api.refreshHeader();
    };

    onSelectionChanged = (event) => {
        const allGridsSelectedRows = this.props.getSelectedRows().filter((row) => {
            return row.parentId !== this.state.rowId;
        });

        this.props.onSelectedRowsChanged(this.props.gridName, [
            ...allGridsSelectedRows,
            ...this.api.getSelectedRows(),
        ]);

        let selectedRows = this.api.getSelectedRows();
        this.updateCheckboxHeader(selectedRows);

        if (this.isWeightSupported) {
          const allocatedSum = selectedRows.reduce((acc, curr) => acc + Number(curr?.weight), 0);
          this.props.node.setDataValue('weight', allocatedSum);
        }
        else {
          const allocatedSum = selectedRows.reduce((acc, curr) => acc + Number(curr?.lengthLeft), 0);
          this.props.node.setDataValue('lengthLeft', allocatedSum);
        }
    };

    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };

    render() {
        const { data } = this.props;

        return (
            <AgGridReact
                id='detailGrid'
                className='pl-detail-grid'
                rowHeight={190}
                headerHeight={46}
                columnDefs={this.state.colDefs}
                defaultColDef={this.state.defaultColDef}
                onRowDataChanged={this.onRowDataChanged.bind(this)}
                onSelectionChanged={this.onSelectionChanged}
                rowSelection='multiple'
                suppressRowClickSelection={true}
                suppressMovableColumns={true}
                // rowData={data.rolls}
                rowData={data.assets}
                onGridReady={this.onGridReady}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
            />
        );
    }
}

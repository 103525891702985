import Network from 'infrastructure/js/modules/network';

const printTags = function (queryData) {
  return Network.post(`locations/${queryData.assetId}/printAssetTag`, queryData);
};

const printRfidTags = function (queryData) {
  return Network.polling.post(`locations/${queryData.locationId}/printAssetsTags`, queryData, {
    pollingRequest: 'batchJobs/JOB_EXECUTION_ID/status-with-printing',
  });
};

const printRfidTagsNew = function (queryData) {
  return Network.post(`tags/printRFIDTags`, queryData);
};
//todo - ran - remove after progress bar is done
const printRfidTagsNewPolling = function (queryData) {
  return Network.polling.post(`tags/printRFIDTags`, queryData, {
    pollingRequest: 'batchJobs/JOB_EXECUTION_ID/status-with-printing',
  });
};

const printGenericRfidTags = function (queryData) {
  delete queryData.isGeneric;
  return Network.post(`tags/printGenericRFIDTags`, queryData);
};

const pollingResult = function (queryData) {
  const handler = (resolve, reject) => {
    return Network.get(
      `locations/${queryData.printerLocationId}/Assets/${queryData.assetId}/getAssetPrintStatus`
    ).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('pollingResult(): response: ', response);
        return reject(response);
      }

      if (!_isPollingFinished(response.data.printStatus)) {
        setTimeout(handler, 1000, resolve, reject);
      } else if (_isPollingFinished(response.data.printStatus)) {
        resolve(response);
      }
    });
  };

  return new Promise(handler);
};

function _isPollingFinished(status) {
  return status !== 'WAITING_FOR_PRINT';
}

export default {
  printTags,
  pollingResult,
  printRfidTags,
  printRfidTagsNew,
  printGenericRfidTags,
  printRfidTagsNewPolling,
};

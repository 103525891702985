import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as printersViewActions} from '../../../actions/Administration/RfidSettingsPage/printersViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as printersViewGridActions} from '../../../actions/Administration/RfidSettingsPage/printersViewGridActions';
import { jsxActions as createPrinterDialogActions} from '../../../actions/Administration/RfidSettingsPage/Dialogs/createPrinterDialogActions';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import PrintersView from '../../../components/Administration/RfidSettingsPage/PrintersView/printersView.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(printersViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      printersViewGridActions: bindActionCreators(printersViewGridActions, dispatch),
      createPrinterDialogActions : bindActionCreators(createPrinterDialogActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let PrintersViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_PRINTERS);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreatePrinterDialogData: state.administration.getIn(['rfidSettingsPage', 'createPrinterDialog']),
      sData: state.administration.getIn(['rfidSettingsPage', 'printersViewData']),
    };
  },

  mapDispatchToProps
)(PrintersView);

export default PrintersViewContainer;



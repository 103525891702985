import React from 'react';
import PropTypes from 'prop-types';

import EllipsisWithTooltip from 'infrastructure/js/components/EllipsisWithTooltip/ellipsisWithTooltip.js';

require('./label.scss');


export default class Label extends React.PureComponent {
  render() {
    let {text, tooltip, className, tooltipPlacement} = this.props;
    if (!text && text !== 0) {
      return null;
    }
    return (
      <EllipsisWithTooltip className={className} tooltip={tooltip} tooltipPlacement={tooltipPlacement}>
        {text}
      </EllipsisWithTooltip>
    );
  }
}
Label.propTypes = {
  text: PropTypes.any,
  tooltip: PropTypes.string,
  className: PropTypes.string,
};

Label.defaultProps = {
  text: ''
};

export function makeLabel(text) {
  return <Label text={text} />
}




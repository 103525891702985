import React from 'react';

import PropTypes from 'prop-types';

require('./textCell.scss');

export default class TextCell extends React.PureComponent {

  render() {
    let value = (this.props.value || this.props.value === 0) ? this.props.value.toString() : '';

    return ( <pre className="text-cell">{value}</pre> );
  }
}

TextCell.defaultProps = {
  value : '',
};

TextCell.propTypes = {
  value : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// this helper responsible for converting unit measurements. doc - https://www.npmjs.com/package/convert-units
import { IsJsonString } from "infrastructure/js/utils/JSON.js";
import convert from "convert-units";
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import { enumTypes, getEnumValue } from "../../../mat/js/utils/enumHelper";

let loggedInUser = localStorage["loggedInUser"];
if (!(loggedInUser = IsJsonString(loggedInUser))) {
  loggedInUser = undefined;
}
let unitMeasurementInstance = function() {
  let obj = {};

  /* Old system

  obj.init = function(systemUnits) {
    _unitMeasurementType = systemUnits || "Metric System";
    this.labels = createLabelHelper("measurements.");
  };


  obj.getUnitMeasurementType = function() {
    return _unitMeasurementType;
  };

  obj.convertMeterOrFeetToCentimeterOrInch = function(data) {
    if (isNaN(data)) {
      return 0;
    }
    return _unitMeasurementType === UNIT_TYPES.METRIC ? data * 100 : data * 12;
  };
  obj.convertSquareCentimeterToSquareMeterOrSquareFeet = function(cm, precision = null) {
    if (isNaN(cm)) {
      return 0;
    }
    let value = _unitMeasurementType === UNIT_TYPES.METRIC ? _convertCentimeterToSquareMeter(cm) : _convertCentimeterToSquareFeet(cm);
    return (precision !== null) ? round(value, precision) : value;
  };

  obj.convertSquareCentimeterToSquareYard = function(cm, precision = null) {
    if (isNaN(cm)) {
      return 0;
    }
    let value = _convertCentimeterToSquareYard(cm);
    return (precision !== null) ? round(value, precision) : value;
  };

  obj.convertCelsiusToCelsiusOrFahrenheit = function(c, precision = null) {
    if (isNaN(c)) {
      return 0;
    }
    let value = _unitMeasurementType === UNIT_TYPES.METRIC ? c : _convertCelsiusToFahrenheit(c);
    return (precision !== null) ? round(value, precision) : value;
  };
  obj.convertCelsiusOrFahrenheitToCelsius = function(value) {
    if (isNaN(value)) {
      return "";
    }
    return _unitMeasurementType === UNIT_TYPES.METRIC ? value : _convertFahrenheitToCelsius(value);
  };

  obj.convertCentimeterToCentimeterOrInch = function(cm, precision = null) {
    if (isNaN(cm)) {
      return 0;
    }
    let value = _unitMeasurementType === UNIT_TYPES.METRIC ? cm : _convertCentimeterToInch(cm);
    return (precision !== null) ? round(value, precision) : value;
  };

  obj.convertCentimeterOrInchToCentimeter = function(value, precision = null) {
    if (isNaN(value)) {
      return 0;
    }
    let converted = _unitMeasurementType === UNIT_TYPES.METRIC ? value : _convertInchToCentimeter(value);
    return (precision !== null) ? round(converted, precision) : converted;
  };

  obj.convertCentimeterToMeterOrFeet = function(cm, precision = null) {
    if (isNaN(cm)) {
      return 0;
    }
    let value = _unitMeasurementType === UNIT_TYPES.METRIC ? _convertCentimeterToMeter(cm) : _convertCentimeterToFeet(cm);
    return (precision !== null) ? round(value, precision) : value;
  };
  obj.convertCentimeterToYards = function(cm, precision = null) {
    if (isNaN(cm)) {
      return 0;
    }
    let value = _convertCentimeterToYard(cm);
    return (precision !== null) ? round(value, precision) : value;
  };
  obj.convertYardToCentimeter = function(yd, precision = null) {
    if (isNaN(yd)) {
      return 0;
    }
    let value = _convertYardToCentimeter(yd);
    return (precision !== null) ? round(value, precision) : value;
  };

  obj.convertMeterOrFeetToCentimeter = function(value, precision = null) {
    if (isNaN(value)) {
      return 0;
    }
    let converted = _unitMeasurementType === UNIT_TYPES.METRIC ? _convertMeterToCentimeter(value) : _convertFeetToCentimeter(value);
    return (precision !== null) ? round(converted, precision) : converted;
  };
  obj.convertMeterToMeterOrFeet = function(m) {
    return _unitMeasurementType === UNIT_TYPES.METRIC ? m : convert(m).from("m").to("ft");
  };
  obj.getCentimeterOrFeetLabel = function() {
    return _unitMeasurementType === UNIT_TYPES.METRIC ? this.labels.get("centimeter") : this.labels.get("feet");
  };
  obj.getMeterOrFeetLabel = function() {
    return _unitMeasurementType === UNIT_TYPES.METRIC ? this.labels.get("meter") : this.labels.get("feet");
  };
  obj.getYardLabel = function() {
    return this.labels.get("yard");
  };
  obj.getCelsiusOrFahrenheitLabel = function() {
    let degreeSymbol = String.fromCharCode(176);
    return _unitMeasurementType === UNIT_TYPES.METRIC ? `${degreeSymbol}C` : `${degreeSymbol}F`;
  };
  obj.getSquareMeterOrSquareFeetLabel = function(isShortLabel = false) {
    return _unitMeasurementType === UNIT_TYPES.METRIC ?
      (!isShortLabel ? this.labels.get("squaremeter") : this.labels.get("shortsquaremeter")) :
      (!isShortLabel ? this.labels.get("squarefeet") : this.labels.get("shortsquarefeet"));
  };

  obj.getSquareYardLabel = function(isShortLabel = false) {
    return isShortLabel ? this.labels.get("shortSquareYard") : this.labels.get("squareYard");
  };
  obj.getCentimeterOrInchLabel = function() {
    return _unitMeasurementType === UNIT_TYPES.METRIC ? this.labels.get("centimeter") : this.labels.get("inch");
  };

  obj.getWeightLabel = function() {
    return this.labels.get("gram");
  };

  let _convertCentimeterToSquareYard = function(cm) {
    return convert(cm).from("cm2").to("yd2");
  };

  let _convertCentimeterToSquareFeet = function(cm) {
    return convert(cm).from("cm2").to("ft2");
  };
  let _convertCentimeterToSquareMeter = function(cm) {
    return convert(cm).from("cm2").to("m2");
  };

  let _convertYardToCentimeter = function(yd) {
    return convert(yd).from("yd").to("cm");
  };
  let _convertCentimeterToYard = function(cm) {
    return convert(cm).from("cm").to("yd");
  };

  let _convertCentimeterToMeter = function(cm) {
    return convert(cm).from("cm").to("m");
  };

  let _convertFeetToCentimeter = function(m) {
    return convert(m).from("ft").to("cm");
  };
  let _convertMeterToCentimeter = function(m) {
    return convert(m).from("m").to("cm");
  };
  let _convertCentimeterToFeet = function(cm) {
    return convert(cm).from("cm").to("ft");
  };
  let _convertCentimeterToInch = function(cm) {
    if (isNaN(cm)) {
      return 0;
    }
    return convert(cm).from("cm").to("in");
  };
  let _convertInchToCentimeter = function(inch) {
    if (isNaN(inch)) {
      return 0;
    }
    return _unitMeasurementType === UNIT_TYPES.METRIC ? inch : convert(inch).from("in").to("cm");
  };
  let _convertCelsiusToFahrenheit = function(c) {
    if (isNaN(c)) {
      return 0;
    }
    return convert(c).from("C").to("F");
  };
  let _convertFahrenheitToCelsius = function(f) {
    if (isNaN(f)) {
      return 0;
    }
    return _unitMeasurementType === UNIT_TYPES.METRIC ? f : convert(f).from("F").to("C");
  };

*/

  let _orgPrefs = {
    TEMPERATURE: "C",
    LENGTH: "cm",
    WIDTH: "cm",
    AREA: "cm2",
    WEIGHT: "g"
  };

  obj.getUomForTelemetry = function(uom) {
    let degreeSymbol = String.fromCharCode(176);
    switch (uom) {
      case getEnumValue(enumTypes.TELEMETRY_UOM)("CELSIUS"):
        return `${degreeSymbol}C`;
      case getEnumValue(enumTypes.TELEMETRY_UOM)("FAHRENHEIT"):
        return `${degreeSymbol}F`;
      case getEnumValue(enumTypes.TELEMETRY_UOM)("KELVIN"):
        return "K";
      case getEnumValue(enumTypes.TELEMETRY_UOM)("G_M3"):
        return "g/m³";
      case getEnumValue(enumTypes.TELEMETRY_UOM)("RELATIVE_PERCENTAGE"):
        return "%RH";
      default:
        console.log("no matching uom found");
        return "xxx";
    }
  };

  obj.setSystemUnits = function setSystemUnits(orgPreferences) {
    obj.setUnits(
      obj.getUnitFromEnumValue(orgPreferences.systemTemperatureUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemLengthUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemWidthUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemAreaUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemWeightUnits)
    );
  };

  obj.setUnits = function setUnits(temperature, length, width, area, weight) {
    _orgPrefs = {
      LENGTH: length,
      WIDTH: width,
      AREA: area,
      WEIGHT: weight,
      TEMPERATURE: temperature
    };
  };

  obj.serverValueToUserValue = function serverValueToUserValue(unitType, serverValue, precision = 10) {
    if (isNaN(serverValue) || null === serverValue || undefined === serverValue || "" === serverValue) {
      return null;
    }
    let sourceUOM = obj.getServerUOMFromUnitType(unitType),
      targetUOM = obj.getUserUOMFromUnitType(unitType);
    return round(
      convert(serverValue).from(sourceUOM).to(targetUOM),
      precision);
  };

  obj.serverValueToUserValueWithLabel = function serverValueToUserValueWithLabel(unitType, serverValue, precision = 10, shortLabel = true) {
    return `${obj.serverValueToUserValue(unitType, serverValue, precision)} ${obj.getLabelForUnitType(unitType, shortLabel)}`;
  };

  obj.userValueToServerValue = function userValueToServerValue(unitType, userValue, precision = 10) {
    if (isNaN(userValue) || null === userValue || undefined === userValue || "" === userValue) {
      return null;
    }
    let sourceUOM = obj.getUserUOMFromUnitType(unitType),
      targetUOM = obj.getServerUOMFromUnitType(unitType);
    return round(
      convert(userValue).from(sourceUOM).to(targetUOM),
      precision);
  };

  obj.round = round;
  obj.floor = floor;

/*
* protocol for adding new UOM Type:
* add it as an enum type in enumHelper.js
* add it as a dropdown in createOrganizationDialog.js
* add it in the _orgPrefs object above
* add it in the setSystemUnits function above
* add cases for it in getServerUOMFromUnitType
* add cases for it in getUserUOMFromUnitType
* add case for it in getMinValueForUnitType and getMaxValueForUnitType
* add possible values according to the protocol below

* protocol for adding new UOM:
*   add case in getLabelForUnitType
*   add case in getUnitFromEnumValue
 */

  obj.getServerUOMFromUnitType = function getServerUOMFromUnitType(unitType) {
    switch (unitType) {
      case "T":
      case "TEMPERATURE":
      case unitTypes.TEMPERATURE:
        return "C";
      case "L":
      case "LENGTH":
      case unitTypes.LENGTH:
        return "cm";
      case "WI":
      case "WIDTH":
      case unitTypes.WIDTH:
        return "cm";
      case "A":
      case "AREA":
      case unitTypes.AREA:
        return "cm2";
      case "WE":
      case "WEIGHT":
      case unitTypes.WEIGHT:
        return "g";
    }
  };

  obj.getUserUOMFromUnitType = function getUserUOMFromUnitType(unitType) {
    switch (unitType) {
      case "T":
      case "TEMPERATURE":
      case unitTypes.TEMPERATURE:
        return _orgPrefs.TEMPERATURE;
      case "L":
      case "LENGTH":
      case unitTypes.LENGTH:
        return _orgPrefs.LENGTH;
      case "WI":
      case "WIDTH":
      case unitTypes.WIDTH:
        return _orgPrefs.WIDTH;
      case "A":
      case "AREA":
      case unitTypes.AREA:
        return _orgPrefs.AREA;
      case "WE":
      case "WEIGHT":
      case unitTypes.WEIGHT:
        return _orgPrefs.WEIGHT;
    }
  };

  obj.getLabelForUnitType = function getLabelForUnitType(unitType, short = true) {
    let label = createLabelHelper("measurements.").get;
    let degSymbol = String.fromCharCode(176);
    switch (obj.getUserUOMFromUnitType(unitType)) {
      //temperature
      case "C":
        return short ? degSymbol + label("celsiusShort") : label("celsius");
      case "F":
        return short ? degSymbol + label("fahrenheitShort") : label("fahrenheit");
      //case "K":
      //  return short ? degSymbol + label("kelvinShort") : label("kelvin");
      //length
      case "mm":
        return short ? label("millimeterShort") : label("millimeter");
      case "cm":
        return short ? label("centimeterShort") : label("centimeter");
      case "m":
        return short ? label("meterShort") : label("meter");
      case "in":
        return short ? label("inchShort") : label("inch");
      case "ft":
        return short ? label("feetShort") : label("feet");
      case "yd":
        return short ? label("yardShort") : label("yard");
      //area
      case "mm2":
        return short ? label("squareMillimeterShort") : label("squareMillimeter");
      case "cm2":
        return short ? label("squareCentimeterShort") : label("squareCentimeter");
      case "m2":
        return short ? label("squareMeterShort") : label("squareMeter");
      case "in2":
        return short ? label("squareInchShort") : label("squareInch");
      case "ft2":
        return short ? label("squareFeetShort") : label("squareFeet");
      case "yd2":
        return short ? label("squareYardShort") : label("squareYard");
      //weight
      case "g":
        return short ? label("gramShort") : label("gram");
      case "kg":
        return short ? label("kilogramShort") : label("kilogram");
      case "oz":
        return short ? label("ounceShort") : label("ounce");
      case "lb":
        return short ? label("poundShort") : label("pound");
    }
  };

  obj.getUnitFromEnumValue = function getUnitFromEnumValue(enumValue) {
    switch (enumValue) {
      case "CELSIUS":
        return "C";
      case "FAHRENHEIT":
        return "F";
      case "METER":
        return "m";
      case "CENTIMETER":
        return "cm";
      case "MILLIMETER":
        return "mm";
      case "INCH":
        return "in";
      case "FEET":
        return "ft";
      case "YARD":
        return "yd";
      case "SQUARE_METER":
        return "m2";
      case "SQUARE_CENTIMETER":
        return "cm2";
      case "SQUARE_MILLIMETER":
        return "mm2";
      case "SQUARE_INCH":
        return "in2";
      case "SQUARE_FEET":
        return "ft2";
      case "SQUARE_YARD":
        return "yd2";
      case "GRAM":
        return "g";
      case "KILOGRAM":
        return "kg";
      case "POUND":
        return "lb";
    }
  };

  obj.getMinValueForUnitType = function getMinValueForUnitType(unitType) {
    let min = 0, precision = 2;
    switch (unitType) {
      case "T":
      case "TEMPERATURE":
      case unitTypes.TEMPERATURE:
        min = -100;
    }
    let sourceUOM = obj.getServerUOMFromUnitType(unitType),
      targetUOM = obj.getUserUOMFromUnitType(unitType);
    return floor(
      convert(min).from(sourceUOM).to(targetUOM),
      precision
    );
  };

  obj.getMaxValueForUnitType = function getMaxValueForUnitType(unitType) {
    let max = 0, precision = 2;
    switch (unitType) {
      case "T":
      case "TEMPERATURE":
      case unitTypes.TEMPERATURE:
      {
        max = 999;
        break;
      }
      case "L":
      case "LENGTH":
      case unitTypes.LENGTH:
        max = 999999;
        if (_orgPrefs.LENGTH === "mm")
          precision = 0;
        break;
      case "WI":
      case "WIDTH":
      case unitTypes.WIDTH:
        max = 999;
        if (_orgPrefs.WIDTH === "mm")
          precision = 0;
        break;
      case "WE":
      case "WEIGHT":
      case unitTypes.WEIGHT:
        max = 999999.99;
        if (_orgPrefs.WEIGHT === "kg")
          precision = 5;
        break;
    }
    let sourceUOM = obj.getServerUOMFromUnitType(unitType),
      targetUOM = obj.getUserUOMFromUnitType(unitType);
    // return floor(   //round the max values, according to SAAS-16548 - Overhaul of displaying validation messages in import assets
    return round(
      convert(max).from(sourceUOM).to(targetUOM),
      precision
    );
  };

  if (loggedInUser) {
    obj.setSystemUnits(loggedInUser.loggedInOrg.orgPreferences);
  }

  return obj;
}();

function round(value, precision = 2) {
  const multiplier = Math.pow(10, precision || 0);
  return (Math.round(value * multiplier) / multiplier);
}

function floor(value, precision = 2) {
  const multiplier = Math.pow(10, precision || 0);
  return (Math.floor(value * multiplier) / multiplier);
}

if (__DEV__) {
  window.uomHelper = unitMeasurementInstance;
}

export default unitMeasurementInstance;

export const unitTypes = {
  LENGTH: "LENGTH",
  WIDTH: "WIDTH",
  AREA: "AREA",
  WEIGHT: "WEIGHT",
  TEMPERATURE: "TEMPERATURE"
};

import React from 'react';
import PropTypes from 'prop-types';

require('./alertIconCell.scss');

export default function AlertIconCell (props) {

    let alert = props.data;
    let icon = alert?.severityType === 'ERROR' ? 'pl-error-icon error' :
               alert?.severityType === 'WARNING' ?'pl-warning-icon warning' :
               alert?.severityType === 'INFO' ?'pl-warning-icon info' : '';

    return (<div className='alert-icon-cell'>
       <span className={`alert-icon pl ${icon}`}/>
    </div>);
}

AlertIconCell.propTypes = {
  data: PropTypes.object,
};

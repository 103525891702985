import { api as messageDialogApi } from '../../../actions/MessageDialog/messageDialogActions.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SCAN_HEADER_REMOVE_ALL_SCANS: 'SCAN_HEADER_REMOVE_ALL_SCANS',
  SCAN_HEADER_REMOVE_SCAN_AT: 'SCAN_HEADER_REMOVE_SCAN_AT',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  removeAllScans: function () {
    return { type: actionTypes.SCAN_HEADER_REMOVE_ALL_SCANS };
  },
  removeScanAt: function (payload) {
    return { type: actionTypes.SCAN_HEADER_REMOVE_SCAN_AT, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.onGoBack = function (data) {
  return function (dispatch, getState) {
    data.goBack();
  };
};

jsxActions.onGoToStart = function (data) {
  return function (dispatch, getState) {
    let path = '/Mobile';
    data.push(path);
  };
};

jsxActions.onNewScan = function (data) {
  return function (dispatch, getState) {
    let path = '/ScanAsset';
    data.push(path);
  };
};

jsxActions.onShowAllScans = function (data) {
  return function (dispatch, getState) {
    let path = '/ScannedAssetsList';
    data.push(path);
  };
};

jsxActions.onRemoveAllScans = function (payload) {
  return function (dispatch, getState) {
    api.onRemoveAllScans(dispatch, getState)(payload);
  };
};

jsxActions.onRemoveScanAt = function (payload) {
  return function (dispatch, getState) {
    api.onRemoveScanAt(dispatch, getState)(payload);
  };
};

jsxActions.clearAllScans = function () {
  return function (dispatch, getState) {
    api.clearAllScans(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.onRemoveAllScans = function (dispatch, getState) {
  return function (payload) {
    if (payload.withConfirmation) {
      let title = createLabelHelper('').get('mat.mobile.confirmation.remove.all.scans');
      let cancelFunc = messageDialogApi.close(dispatch, getState);
      let removeFunc = api.doRemove(dispatch, getState, payload.data);

      let desc = _getMessageDialogBuilder(title, cancelFunc, removeFunc);
      messageDialogApi.open(dispatch, getState)(desc);
    } else {
      dispatch(actions.removeAllScans());
      let path = '/ScanAsset';
      payload.data.push(path);
    }
  };
};

api.doRemove = function (dispatch, getState, data) {
  return function () {
    dispatch(actions.removeAllScans());
    messageDialogApi.close(dispatch, getState)();
    let path = '/ScanAsset';
    data.push(path);
  };
};

api.onRemoveScanAt = function (dispatch, getState) {
  return function (payload) {
    let scannedAssets = getState().mobile.getIn(['data', 'scannedAssets']);
    if (!scannedAssets || payload.index > scannedAssets.size) {
      return;
    }

    let title = createLabelHelper('').get('mat.mobile.confirmation.remove.scan');

    let cancelFunc = messageDialogApi.close(dispatch, getState);
    let removeFunc = api.doRemoveAt(dispatch, getState, payload);

    let desc = _getMessageDialogBuilder(title, cancelFunc, removeFunc);
    messageDialogApi.open(dispatch, getState)(desc);
  };
};

api.doRemoveAt = function (dispatch, getState, payload) {
  return function () {
    dispatch(actions.removeScanAt(payload.index));
    messageDialogApi.close(dispatch, getState)();

    let scannedAssets = getState().mobile.getIn(['data', 'scannedAssets']);
    if (scannedAssets && scannedAssets.size === 0) {
      let path = '/ScanAsset';
      payload.data.push(path);
    }
  };
};

api.clearAllScans = function (dispatch, getState) {
  return function () {
    dispatch(actions.removeAllScans());
  };
};

////////////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
function _getMessageDialogBuilder(title, cancelFunc, removeFunc) {
  let dialogLabels = createLabelHelper('mat.dialog.');
  let buttons = [
    {
      id: 'cancel',
      text: dialogLabels.get('cancel'),
      action: cancelFunc,
    },
    {
      id: 'remove',
      text: dialogLabels.get('remove'),
      action: removeFunc,
    },
  ];

  let className = 'mobile-message-dialog';

  return { title, className, buttons, showTitleIcon: false };
}

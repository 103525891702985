import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as communicationParametersViewActions} from '../../../actions/Administration/RfidSettingsPage/communicationParametersViewActions.js';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import CommunicationParametersView from '../../../components/Administration/RfidSettingsPage/CommunicationParametersView/communicationParametersView.js';
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(communicationParametersViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let communicationParametersViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['rfidSettingsPage', 'communicationParametersViewData']),
    };
  },

  mapDispatchToProps
)(CommunicationParametersView);

export default communicationParametersViewContainer;

import PropTypes from 'prop-types';
import Button from 'infrastructure/js/components/controls/Button/button';

import './weekNavigation.scss';

export default function WeekNavigation({ weekStartDate, updateWeekStartDate, init, labels }) {
  const getWeekDatesLabel = () => {
    if (weekStartDate) {
      const startOfWeekDate = weekStartDate.format('MMM DD').toUpperCase();
      const endOfWeekDate = weekStartDate.clone().add(6, 'd').format('MMM DD, YYYY').toUpperCase();
      return `${startOfWeekDate} - ${endOfWeekDate}`;
    }

    return '';
  };

  const getNextWeek = () => {
    updateWeekStartDate(weekStartDate.add(7, 'd'));
  };

  const getPreviousWeek = () => {
    updateWeekStartDate(weekStartDate.subtract(7, 'd'));
  };

  const getToday = () => {
    init(true);
  };

  return (
    <div className="week-nav-container">
      <span className="week-dates">{getWeekDatesLabel()}</span>
      <div className='week-nav-buttons'>
        <Button id="today" name="today" className={'border-btn only-icon'} onClick={getToday}>
          {labels.get('header.dateNav.today')}
        </Button>
        <Button id="prevWeek" name="prevWeek" className={'border-btn only-icon'} icon={'pl pl-arrow-left'} onClick={getPreviousWeek} />
        <Button id="nextWeek" name="nextWeek" className={'border-btn only-icon'} icon={'pl pl-arrow-right'} onClick={getNextWeek} />
      </div>
    </div>
  );
}

WeekNavigation.propTypes = {
  weekStartDate: PropTypes.object,
  sData: PropTypes.object,
  updateWeekStartDate: PropTypes.func,
  init: PropTypes.func,
  labels: PropTypes.object,
};

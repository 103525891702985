import React from 'react';

import CommonCsvSettingsView from '../Components/CommonCsvSettingsView/commonCsvSettingsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';
require('./partTypeCsvSettingsView.scss');

export default  class PartTypeCsvSettingsView extends React.PureComponent {

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_CSV_PART_TYPE);
    this.props.actions.load('PART_TYPE');
  };

  render() {
    return (
        <CommonCsvSettingsView
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          // entityType={ getEnumValue(enumTypes.OBJECT_TYPE)('PART_TYPE')}
                          entityType={ 'PART_TYPE'}
        />
    );
  }
}


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as activitiesViewActions }  from '../../actions/LocationPage/ActivitiesView/activitiesViewActions';
import { jsxActions as activitiesGridActions }  from '../../actions/LocationPage/ActivitiesView/activitiesGridActions';
import { jsxActions as headerActions }   from '../../actions/Header/headerActions';
import { jsxActions as exportActions }   from '../../actions/Export/exportActions.js';
import { jsxActions as locationPageActions }      from '../../actions/LocationPage/locationPageActions';

import ActivitiesView from '../../components/LocationPage/ActivitiesView/activitiesView.js';
import {gridsNames} from '../../enums/gridsNames';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(activitiesViewActions, dispatch),
      headerActions: bindActionCreators(headerActions, dispatch),
      exportActions: bindActionCreators(exportActions, dispatch),
      activitiesGridActions:   bindActionCreators(activitiesGridActions, dispatch),
      locationPageActions:       bindActionCreators(locationPageActions, dispatch)
    }
  }
}

let ActivitiesViewContainer = connect(

  (state) => {
    let sGrid = state.grid.get(gridsNames.LOCATION_ACTIVITIES) ? state.grid.get(gridsNames.LOCATION_ACTIVITIES) : null;
  	return {
      sActivitiesGridData:  sGrid,
      sHasRows: sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
    };
  },
  mapDispatchToProps
)(ActivitiesView);

export default ActivitiesViewContainer;



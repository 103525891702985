import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../MessageDialog/messageDialogActions.js';
import assetService from '../../services/Assets/assetService.js';
import {api as wizardApi} from "../../../../infrastructure/js/components/Wizard/wizardActions";
import {api as assetPageApi} from "./assetPageActions";
import {api as systemApi} from "../System/systemActions";
import * as deviceAppsService from '../../services/Rfid/deviceAppsService';
import {createLabelHelper} from "../../../../infrastructure/js/utils/labelHelper";


let weightWizardApi = wizardApi.createWizardSpecificAdapter('WEIGHT_WIZARD');
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  WEIGHT_WIZARD_HIDE:               'WEIGHT_WIZARD_HIDE',
  WEIGHT_WIZARD_SUBMIT_IN_PROGRESS: 'WEIGHT_WIZARD_SUBMIT_IN_PROGRESS',
  WEIGHT_WIZARD_SUBMIT_FINISHED:    'WEIGHT_WIZARD_SUBMIT_FINISHED',
  WEIGHT_WIZARD_WEIGHT_IN_PROGRESS: 'WEIGHT_WIZARD_WEIGHT_IN_PROGRESS',
  WEIGHT_WIZARD_WEIGHT_FINISHED:    'WEIGHT_WIZARD_WEIGHT_FINISHED',
  WEIGHT_WIZARD_SAVE_PARAMETERS:   'WEIGHT_WIZARD_SAVE_PARAMETERS',
  WEIGHT_WIZARD_UPDATE_ERROR_MESSAGE : 'WEIGHT_WIZARD_UPDATE_ERROR_MESSAGE',
  WEIGHT_WIZARD_SET_FETCH_WEIGHT_LOADING: 'WEIGHT_WIZARD_SET_FETCH_WEIGHT_LOADING',
  WEIGHT_WIZARD_SET_WEIGHT: 'WEIGHT_WIZARD_SET_WEIGHT'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {

  hide: function(payload) {
    return {type: actionTypes.WEIGHT_WIZARD_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.WEIGHT_WIZARD_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.WEIGHT_WIZARD_SUBMIT_FINISHED, payload: payload };
  },
  saveStartParameters: function(payload) {
    return {type: actionTypes.WEIGHT_WIZARD_SAVE_PARAMETERS, payload: payload };
  },
  updateErrorMessage: function(payload) {
    return {type: actionTypes.WEIGHT_WIZARD_UPDATE_ERROR_MESSAGE, payload: payload };
  },
  setFetchWeightLoading: function(payload) {
    return {type: actionTypes.WEIGHT_WIZARD_SET_FETCH_WEIGHT_LOADING, payload: payload };
  },
  setWeight: function(payload) {
    return {type: actionTypes.WEIGHT_WIZARD_SET_WEIGHT, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.openWeightWizard = function (rollId) {
  return function (dispatch, getState) {
    api.openWeightWizard(dispatch, getState)(rollId);
  }
};

jsxActions.onWizardClose = function () {
  return function (dispatch, getState) {
    dispatch(actions.hide());
  }
};


jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.weight = function() {
  return function(dispatch, getState) {
    return api.weight(dispatch, getState)();
  };
};

jsxActions.submit = function(data, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, reloadParentComponent);
  };
};

jsxActions.goOn = function () {
  return function (dispatch, getState) {
    api.goOn(dispatch, getState)();
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.goOn = function (dispatch, getState) {
  return function () {
    dispatch(actions.setFetchWeightLoading(true));

    deviceAppsService.getWeight().then((response) => {

      dispatch(actions.setFetchWeightLoading(false));
      if (!Network.isResponseValid(response)) {
        let mainError = response && response.applicationResponseStatus ?
          (response.applicationResponseStatus.errors.mainError || []) : [];
        let errorParams = mainError.params || [];
        let labels = createLabelHelper('');
        let message = labels.get(mainError.errorKey, '', errorParams);

        dispatch(actions.updateErrorMessage(message ? message : 'xxx'));
        weightWizardApi.gotoStep(dispatch, getState)('step_3');
        return;
      }

      systemApi.setLoading(false)(dispatch, getState);
      switch (response.data.jobStatus) {
        case 'COMPLETED':
          dispatch(actions.setWeight(response.data.payloadData));
          return weightWizardApi.gotoStep(dispatch, getState)('step_2');
          break;
        case 'FAILED':
          let labels = createLabelHelper('');
          dispatch(actions.updateErrorMessage(labels.get(response.data.genericErrorCode)));
          weightWizardApi.gotoStep(dispatch, getState)('step_3');
          break;
        default:
          console.error('error in weight wizard');
      }
    });
  }
};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.openWeightWizard = function(dispatch, getState) {
  return function(rollId) {
    dispatch(actions.saveStartParameters(rollId));
    weightWizardApi.start(dispatch, getState)();
  }
};

api.submit = function(dispatch, getState) {
  return function(weight, rollId) {
    dispatch(actions.submitInProgress());
    let data = {weight: weight};
    assetPageApi.updateRoll(rollId, data)
  };
};


import { useEffect, useMemo } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { getEnumValue, enumTypes } from '../../../../../utils/enumHelper';
import { SettingsMenuItemsIds } from '../../../../../enums/navigationMenuItemsIds';
import CommonAlertsView from '../Components/CommonAlertsView/commonAlertsView';

import './misplacedMaterialView.scss';

export default function MisplacedMaterialView({ actions, sData, isDirty }) {

  const labels = useMemo(
    () => createLabelHelper('mat.administration.settings.misplacedMaterial.view.'),
    []
  );

  useEffect(() => {

    actions.settingsPageActions.onSelectedMenuItemChanged(
      SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_MISPLACED_MATERIAL
    );

    actions.load(getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'));
  }, []);

  return (
    <div className="misplaced-material-view">
      <CommonAlertsView
        labels={labels}
        actions={actions}
        sData={sData}
        isDirty={isDirty}
        alertType={getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL')}
      />
    </div>
  );
}

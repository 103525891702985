import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import ColorPicker from 'infrastructure/js/components/controls/ColorPicker/colorPicker';
import DateTimeHelper from '../../../../../../../../infrastructure/js/utils/dateTimeHelper';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import moment from 'moment/moment';
import * as dialogHelper from '../../../../../../../../infrastructure/js/components/Dialog/dialogHelper';

require('./createShiftTypeDialog.scss');

class CreateShiftTypeDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createShiftType.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;
  }

  componentDidMount() {
    let initialValues = {
      active: true,
      color: '#EE00FF',
    };

    if (this.isEditMode) {
      initialValues = {
        shiftName: this.itemToEdit.name,
        startTime: moment(this.itemToEdit.startTime, 'HH:mm'),
        endTime: moment(this.itemToEdit.endTime, 'HH:mm'),
        color: this.itemToEdit.color,
      };
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine) || this.props.sData.get('loading') || this.props.sData.get('hasError'),
        },
      ],
    };
  }

  onSubmit = (data) => {
    let fromTimeHours = data.startTime ? moment(data.startTime).get('hours') : 0;
    let fromTimeMinutes = data.startTime ? moment(data.startTime).get('minutes') : 0;
    let toTimeHours = data.endTime ? moment(data.endTime).get('hours') : 0;
    let toTimeMinutes = data.endTime ? moment(data.endTime).get('minutes') : 0;

    let newData = {
      name: data.shiftName,
      startTime: [fromTimeHours, fromTimeMinutes],
      endTime: [toTimeHours, toTimeMinutes],
      color: data.color || '',
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  maxLength30 = Validation.maxLength(30);

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    let startTime = this.itemToEdit ? moment(this.itemToEdit.startTime, 'HH:mm') : null;
    let endTime = this.itemToEdit ? moment(this.itemToEdit.endTime, 'HH:mm') : null;
    return (
      <Dialog
        id="create-shift-type-dialog"
        className="create-shift-type-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <InputSection label={this.labels.get('name') + '*'} htmlFor="shiftName" className="inline left-side">
          <TextField id="shiftName" name="shiftName" className="short-textfield" validate={[Validation.required, this.maxLength30]} />
        </InputSection>

        <InputSection label={this.labels.get('color') + '*'} htmlFor="shiftTypeColor" className="no-margin inline right-side">
          <ColorPicker name="color" text="Shift name" validate={[Validation.required]} />
        </InputSection>

        <InputSection label={this.labels.get('startTime') + '*'} htmlFor="startTime" className="inline left-side">
          <DatePicker
            className="hour-picker"
            id="startTime"
            name="startTime"
            initialDate={startTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeFormat={DateTimeHelper.getTimeFormat()}
            dateFormat={DateTimeHelper.getTimeFormat()}
            timeCaption=""
            placeholderText={this.labels.get('time')}
            validate={[Validation.required, Validation.time]}
          />
        </InputSection>

        <InputSection label={this.labels.get('endTime') + '*'} htmlFor="endTime" className="inline right-side">
          <DatePicker
            className="hour-picker"
            id="endTime"
            name="endTime"
            initialDate={endTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeFormat={DateTimeHelper.getTimeFormat()}
            dateFormat={DateTimeHelper.getTimeFormat()}
            timeCaption=""
            placeholderText={this.labels.get('time')}
            validate={[Validation.required, Validation.time]}
          />
        </InputSection>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'createShiftTypeDialog',
  onChange: (values, dispatch, props, previousValues) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
})(CreateShiftTypeDialog);

CreateShiftTypeDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

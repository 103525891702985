import React from 'react';
import {reduxForm} from 'redux-form';

import Form from '../../../../Common/Form/form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';

require('./taskTabForm.scss');

class TaskTabForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.formLabels = createLabelHelper('mat.administration.settings.alerts.form.');

    this.state = {
      isEnableAlerts : false
    };
  }

  componentDidMount (){
    this.initFormValues();
    this.setStateStartValues();
  }

  componentWillUnmount(){
    this.props.actions.setStartParameters();
  }

  setStateStartValues = () => {
    this.setState({isEnableAlerts: !this.props.sData.enabled});
  };

  initFormValues = () => {

    let data = this.props.sData;

    let initialValue = {
      enableAlertsCheckbox: data.enabled,
      defaultValuesWarning: data.warningThreshold,
      defaultValuesError : data.errorThreshold,
    };

    this.props.initialize(initialValue);
  }

  onCheckboxClick = () => {
    this.setState({isEnableAlerts: !this.state.isEnableAlerts});
  }

  onSubmit = (data) => {
    let newData = {
      id: this.props.sData.id,
      enabled: data.enableAlertsCheckbox,
      warningThreshold: data.defaultValuesWarning,
      errorThreshold: data.defaultValuesError,
    };

    this.props.actions.update(newData, this.props.alertType, this.getMessageDialogBuilder()).then((res) => {
      if (res.success) {
        this.initFormValues(); //Initialize form values and reset pristine.
      }
    });

  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.formLabels.get('confirmation.success');
      let className = 'oneBackground';
      let messageDialogDescriptor = {title, className};
      return messageDialogDescriptor;
    };
  };

  getFormButtons() {
    return (
      [
        {
          text: this.formLabels.get('buttons.save'),
          className: 'common-alerts-form-button-save border-btn btn',
          action: this.props.handleSubmit(this.onSubmit),
          disabled:this.props.pristine
        }
      ]
    );
  }

  renderEnableAlertsSection() {
    let taskLabel = this.props.labels.get('task');
    return (
      <div className="common-alerts-form-enable-alerts-section" >
        <label htmlFor="enableAlertsCheckbox" >
          <Checkbox name="enableAlertsCheckbox" id="enableAlertsCheckbox" value={false} onClick={this.onCheckboxClick} />
          <span>{this.props.labels.get('checkbox.title' ,'',{assetType: taskLabel})}</span>
        </label>
      </div>
    );
  }


  renderDefaultValuesSection() {
    return (
      <div className='common-alerts-form-default-values-container'>

        <div className="common-alerts-form-default-values-header">
          <label className='common-alerts-form-default-values-title'>{this.props.labels.get('defaultvalues.section.header')}</label>
        </div>

        <div className="common-alerts-form-enable-alerts-section" >
          <label htmlFor="enableAlertsCheckbox" >
            <Checkbox name="enableAlertsCheckbox" id="enableAlertsCheckbox" value={false} onClick={this.onCheckboxClick} />
            <span>{this.props.labels.get('checkbox.title')}</span>
          </label>
        </div>

        <div className="common-alerts-form-default-values-section">
          <InputSection label={this.formLabels.get('defaultvalues.title.warning.low')} htmlFor="defaultValuesWarning" className="inline left-side">
            <TextField id="defaultValuesWarning" name="defaultValuesWarning" disabled={this.state.isEnableAlerts}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 9999)} />
            <span className='additional-info'>{this.props.labels.get('defaultvalues.additionalinfo.title')}</span>
          </InputSection>

          <InputSection label={this.formLabels.get('defaultvalues.title.warning.high')} htmlFor="defaultValuesError" className="inline right-side">
            <TextField id="defaultValuesError" name="defaultValuesError" disabled={this.state.isEnableAlerts}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 9999)} />
            <span className='additional-info'>{this.props.labels.get('defaultvalues.additionalinfo.title')}</span>
          </InputSection>
        </div>

      </div>
    );
  }

  render() {
    return (
      <Form className="common-alerts-form task-tab-form" formButtons={this.getFormButtons()}>
        {/*{this.renderEnableAlertsSection()}*/}
        {this.renderDefaultValuesSection()}
      </Form>
    );
  }
}

export default reduxForm({
    form: 'taskTabForm'
  }
)(TaskTabForm);

import React, { Component } from 'react';
import classNames from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import Pagination from 'infrastructure/js/components/controls/Pagination/pagination';
import PL_Tooltip from 'infrastructure/js/components/tooltip/tooltip';
import {navigationStates} from '../../../../../enums/navigationStates';
import { Link } from 'react-router-dom';
import Label from 'infrastructure/js/components/Label/label';

require('./productionChart.scss');


export default class ProductionChart extends Component {

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.dashboard.production.');
  }

  // onChartClickHandler = (e) => {
  // };

  getChartData = () => {
    let {chartData} = this.props;
    return chartData ? chartData : [];
  };

  renderTooltip = (props) => {
    const { active, payload } = props;

    if (active && payload && payload.length > 0) {
      let title = <div className="tooltip-title" >{payload[0].payload.name}</div>;
      let content = payload.map((item, index) => {
        return (
          <div key={`${item.name}-${index}`} className="tooltip-item">
            <span><i className="fa fa-square" style={{ color: item.fill }}></i></span>
            <span>{item.value}</span>
            <span>{'d'}</span>
            <span>{item.name}</span>
          </div>
        )
      });

      return (
        <div className="custom-tooltip">
          {title}
          {content}
        </div>
      );
    }
    return null;
  };

  renderLegend = (value, entry) => {
    let { dataKey } = entry;
    let cn = (dataKey === 'planned') ? 'pl pl-vertical-line' : '';
    return <span className={`custom-legend ${cn}`}>{value}</span>;
  };

  getPathTo = (item) => {
    return (
      {
        pathname: "/ProgressDashboard",
        state: {
          itemId: item.id,
          itemName: item.label,
          timePeriod: this.props.filterData ? this.props.filterData.timePeriod : null,
          comparisonPeriod: this.props.filterData ? this.props.filterData.comparisonPeriod : null,
        }
      }
    )
  };

  renderEntityCell = (item) => {
    if (item.id) {
      let val = {
        id: item.id,
        label: item.name,
        type: navigationStates.PROGRESS_DASHBOARD
      };
      return (
        <div className="entity-id-cell">
          <PL_Tooltip
            placement="top"
            value={item.name}
            delayShow={500}
          >
            <Link to={this.getPathTo(val)} className="pointer">{item.name}</Link>
          </PL_Tooltip>
        </div>
      );
    }

    return (
      <Label text={item.name} className="entity-id-cell"/>
    );

  };

  renderTable = () => {
    let chartData = this.getChartData();

    let items = chartData.map((item, index )=> {
      return  (<div className='item-row' key={`${item.id}-${index}`}>

        {this.renderEntityCell(item)}

        <div className='cell'>
          <span>{item.defectRatePercentage}</span>
          <span>{item.defectRate}</span>
        </div>
      </div>)
    });
    return (
        <div className="table-section">
          { items }
        </div>
      )
  };

  renderCustomDot = (props) => {
    const { cx, cy, index } = props;

    return (
      <g key={index}>
        <rect x={cx} y={cy-10}  width={3} height={20} fill="#367A87"/>
      </g>
    );
  };



  renderChart = () => {
    let chartData = this.getChartData();
    let height = chartData && chartData.length > 0 ? chartData.length * 30 + 50 : 0;

    return (
      <div className={classNames('chart-section', {'legend-bottom': (window.innerHeight - height < 380)})}>
      <ComposedChart layout="vertical"
                     width={800} height={height}
                     data={chartData}
                     margin={{top: 0, right: 20, bottom: 0, left: 0}}
                     // onClick={this.onChartClickHandler}
      >
        <CartesianGrid stroke='#cecece' horizontal={false}/>
        <XAxis type="number" orientation="top" axisLine={false} tickLine={false} tickCount={10}/>
        <YAxis dataKey="name2" type="category" axisLine={false} tickLine={false} />
        <Tooltip content={this.renderTooltip}/>
        <Legend  formatter={this.renderLegend} wrapperStyle={{ bottom: 20 }} iconType="circle"/>
        <Bar dataKey='actual' name={this.labels.get('chart.bar.actualTime.title')} barSize={10} fill='#49c5db' stackId="stack"  />
        <Bar dataKey='within' name={this.labels.get('chart.bar.withinPlannedTime.title')} barSize={10} fill='#ccc' stackId="stack" />
        <Bar dataKey='exceeded' name={this.labels.get('chart.bar.exceededPlannedTime.title')} barSize={10} fill='#d9385c' stackId="stack" />
        <Line dataKey='planned' name={this.labels.get('chart.bar.plannedProductionTime.title')} stroke="none" fill="#367A87"  dot={this.renderCustomDot}/>
      </ComposedChart>
      </div>
    );
  };

  renderHeader = () => {
    return (
      <div className="chart-header">
        <div className="column">{this.labels.get('chart.columns.kittype.title')}</div>
        <div className="column">{this.labels.get('chart.columns.defectsRate.title')}</div>
        <div className="column">{this.labels.get('chart.columns.productionDuration.title')}</div>
      </div>
    )
  };
  // renderPagination = () => {
  //   let paginationData = {
  //     first: true,
  //     last: true,
  //     number: 0,
  //     numberOfElements: 7,
  //     size: 100,
  //     totalElements: 7,
  //     totalPages: 1,
  //     totalUnfiltered: 0,
  //   };
  //
  //   return (
  //     <Pagination  paginationData={paginationData}/>
  //   )
  // };


  render() {
    return (
      <div className="production-chart">

        {this.renderHeader()}

        <div className="chart-content">
          {this.renderTable()}
          {this.renderChart()}
        </div>
        {/*{false && this.renderPagination()}*/}

      </div>
    )
  }
};




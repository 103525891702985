import { actionTypes as toolMaintenanceDashboardPageActionsTypes } from '../../../actions/Reports/toolMaintenanceDashboardPageActions';
import {Map} from 'immutable';

let defaultState = {
  loading: false,
  kpiData: null,
  chartData: null,
  filters: null
};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case toolMaintenanceDashboardPageActionsTypes.TOOL_MAINTENANCE_DASHBOARD_PAGE_FETCH_DATA_STARTED:
      return state.set('loading', true);

    case toolMaintenanceDashboardPageActionsTypes.TOOL_MAINTENANCE_PAGE_FETCH_DATA_FINISHED:
      return state.set('loading', false);

    case toolMaintenanceDashboardPageActionsTypes.TOOL_MAINTENANCE_PAGE_FETCH_CHART_DATA_FINISHED:
      state = state.set('loading', false)
      return state.set('chartData', action.payload.chartsData);

    case toolMaintenanceDashboardPageActionsTypes.TOOL_MAINTENANCE_PAGE_FETCH_KPI_DATA_FINISHED:
      return state.set('kpiData', action.payload);

    case toolMaintenanceDashboardPageActionsTypes.TOOL_MAINTENANCE_PAGE_UPDATE_FILTERS:
      return state.set('filters', action.payload);

    case toolMaintenanceDashboardPageActionsTypes.TOOL_MAINTENANCE_PAGE_RESET_DATA:
      return Map(defaultState);

    default:
      return state;
  }
}



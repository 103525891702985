import React from 'react';
import ReactDOM from 'react-dom';
import SelectAllCheckbox from './SelectAllCheckbox/selectAllCheckbox';
import FilterTable from './FilterTable/filterTable';
import FilterFooter from '../Common/FilterFooter/filterFooter';
import FilterTableRow from './FilterTableRow/filterTableRow';
import FilterLayout from '../Common/FilterLayout/filterLayout';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MultiSelectFieldAsync from 'infrastructure/js/components/controls/MultiSelectFieldAsync/multiSelectFieldAsync';
import Input from '../../../../components/controls/Input/input';

require('./multiSelectFilter.scss');

const MULTI_SELECT_FILTER_WIDTH = 334;

export default class PL_MultiSelectFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.filter.');
    this.controlsLabels = createLabelHelper('mat.controls.');

    // these value must be initialized in the filter parameters
    this.filterName = "";
    this.mainPropertyName = "";
    this.additionalDataPropertyName = "";

    this.currentStateRows = [];

    this.state = {
      text: '',
      rows : [],
      last: true,
      dummyKey: 0,
    };

    this.initialFetch = true;

    this.initialState = {...this.state};

    this.resultsFetched = false;
    this.handleResultsOnFetchComplete = false;
  }

  componentDidMount() {
    if(this.state.rows.length > 0){
      return;
    }

    this.validateFilterParameters();

    this.filterName = this.props.filterName;
    this.mainPropertyName = this.props.mainPropertyName;

    if (this.props.additionalDataPropertyName !== undefined) {
      this.additionalDataPropertyName = this.props.additionalDataPropertyName;
    }

    if (!this.props.asyncMode) {
      let rows = this.props.values.map((value, index) => {
        return {
          id: value[0].value + index,
          isChecked: false,
          isVisible: true,
          value: value[0].value,
          valueDisplay: value[0].valueDisplay,
          additionalData: value[1] || "",
          additionalDataDisplay: value[1] ? value[1].valueDisplay : ''
        };
      });

      this.setState({rows: rows});
      this.initialState = {text: this.state.text, rows: rows, last: this.state.last};
    }
  }

  // called by agGrid
  afterGuiAttached(params) {

    this.setState({...this.initialState}, () => {
      this.clearFilterInput();
      this.fetchOnOpening();
    });

    this.hidePopup = params.hidePopup;

    this.setInputFocus();
  }

  // called by agGrid
  doesFilterPass(params) {
    return true;
  }

  // called by agGrid
  isFilterActive() {
    let anySelected = false;
    for(var i=0; i < this.currentStateRows.length; i++) {
      if (this.currentStateRows[i].isChecked) {
        anySelected = true;
      }
    }

    return anySelected;
  }

  // called by agGrid
  // when modifying rows data in the grid, update list of filter options accordingly.
  onNewRowsLoaded(){
    this.handleFilterState();

    setTimeout(() => {

      if (!this.props.asyncMode) {
        const currentSelectedValues = this.state.rows.filter(row => row.isChecked).map(row => row.value);
        const currentSelectedOptions = this.state.rows.filter(row => row.isChecked);

        this.props.api.getFilterInstance(this.props.colDef.filterParams.mainPropertyName, agGridFilterInstance => {
          let rows = agGridFilterInstance?.props?.column?.colDef?.filterParams?.values?.map(
            (value, index) => {
              return {
                id: value[0].value + index,
                isChecked: currentSelectedValues.includes(value[0].value),
                isVisible: true,
                value: value[0].value,
                valueDisplay: value[0].valueDisplay,
                additionalData: value[1] || '',
                additionalDataDisplay: value[1] ? value[1].valueDisplay : '',
              };
            }
          );

          // Note: workaround - if there are some selected filter's options (currentSelectedOptions) that wasn't received
          // from the latest Server response - add them to the filter's options (rows).
          const rowsValues = rows.map(row => row.value);
          const options = currentSelectedOptions.filter(o => !rowsValues.includes(o.value));
          rows = [...options, ...rows];

          this.setState({rows});
          this.currentStateRows = rows;
          this.initialState = {...this.state, rows}
          this.props.filterChangedCallback();   // forces grid to call isFilterActive()
        });
      }
    }, 0);
  }

  handleFilterState = () => {
    let filterState = this.props.context?.componentParent?.props?.filterState;

    let isFilterStateActive = filterState?.some((item) => {
      return item.filterName === this.filterName && item.values;
    });
    if (!isFilterStateActive && this.isFilterActive()) {
      this.deactivateFilter();
    }
  }

  deactivateFilter = () => {
    let newState = { text: '',  rows: this.getAssignedNewRows(false) }
    this.setState(newState)
    this.currentStateRows = newState.rows;
    this.initialState = newState;
  }

  apply() {
    this.currentStateRows = this.state.rows;
    this.initialState = {...this.state};

    this.props.colDef.onFilterChanged(this.getModel());
    this.hidePopup();
  }

  cancel(){
    this.hidePopup();
  }

  getModel() {
    if (!this.currentStateRows || this.currentStateRows.length === 0) {
      return [{filterName: this.filterName, values: []}];
    }

    var checkedRows = this.currentStateRows.filter(r => r.isChecked);
    var selectedValues = checkedRows.map(row => {
      return this.props.asyncMode ? row.valueDisplay : row.value;
    });

    return [{filterName: this.filterName, values: selectedValues}];
  }

  //not in use
  getApi() {
    return {
      getModel: this.getModel.bind(this)
    }
  }

  //Note:  //always clear the Not Async Filter input on opening
  clearFilterInput = () => {
    if (!this.props.asyncMode) {
      this.initialState.text = '';
      this.onInputChangeCallback();
    }
  };

  //Note:  //fetch data for the Async Filter on opening
  fetchOnOpening = () => {
    if (this.props.asyncMode && !this.initialFetch) {
      if (this.searchBoxRef) {
        this.searchBoxRef.doFetch(this.state.text)
      }
    }
  }

  setInputFocus = () => {
    let searchBoxInput = this.findSearchBoxDOMInput();
    if (searchBoxInput) {
      searchBoxInput.focus();
    }
  };

  findSearchBoxDOMInput = () => {
    let component = ReactDOM.findDOMNode(this.searchBoxRef);
    if (component) {
      let foundChildInputs = component.getElementsByTagName('input');
      if (foundChildInputs.length > 0) {
        return foundChildInputs[0];
      }
    }
    return null;
  };

  validateFilterParameters() {
    if (!this.props.filterName || !this.props.mainPropertyName)
      throw 'filter parameters are not valid';
  }

  onAsyncInputChangeCallback = (e) => {
    this.setState({
      text: e.target.value,
    });

    this.resultsFetched = false;
  };

  onInputChangeCallback = (e) => {
    let value = e ? e.target.value : '';

    let isVisible = (item)=> {
      return (item.valueDisplay !== null && item.valueDisplay.toLowerCase().includes(value.toLowerCase())) ||
        (item.additionalDataDisplay !== null && item.additionalDataDisplay.toLowerCase().includes(value.toLowerCase()));
    };

    let updatedRows = this.state.rows.map((item)=> {
      return Object.assign({}, item, {isVisible : isVisible(item)});
    });

    this.setState({
      text: value,
      rows: updatedRows
    });
  };

  onRowClick(id, e) {
    let isChecked = (item)=> {
      if (item.id !== id) {
        return item.isChecked;
      }
      return !item.isChecked
    };

    let updatedRows = this.state.rows.map((item)=> {
      return Object.assign({}, item, {isChecked : isChecked(item)});
    });

    this.setState(
      {rows: updatedRows}
    )
  }

  onSelectAllClick() {
    if (this.isAllFilteredRowsSelected()) {
      this.clearAll();
    }else {
      this.selectAll();
    }
  }

  getAssignedNewRows(checkValue){
    return this.state.rows.map((item)=> {
      return Object.assign({}, item, {isChecked : item.isVisible ? checkValue : item.isChecked});
    });
  }

  selectAll() {
    this.setState(
      {rows: this.getAssignedNewRows(true)}
    )
  }

  clearAll() {
    this.setState(
      {rows: this.getAssignedNewRows(false)}
    )
  }

  isAllFilteredRowsSelected() {
    let filteredRows = this.state.rows.filter((row)=> {return row.isVisible});
    return filteredRows !== null && filteredRows.length > 0 && filteredRows.every((row)=> {return row.isChecked});
  }

  getRows (){
    var filteredRows = this.state.rows.filter((row)=> {return row.isVisible});
    if (filteredRows === undefined)
      return null;
    return filteredRows.map((row)=> {
      return <FilterTableRow
        key={row.id}
        rowData={row}
        onRowClick={this.onRowClick.bind(this, row.id)}
      />;
    });
  }

  onFetchStartCallback = () => {
    this.setState({last: true});
  };

  onKeyPressCallback = (e) => {
    if (e.key === 'Enter' && this.state.text) {

      if (!this.resultsFetched) {
        this.handleResultsOnFetchComplete = true;
        return;
      }
      this.handleResults(this.state.rows, this.state.last);
    }
  };

  onFetchCompleteCallback = (value) => {

    this.resultsFetched = true;

    if (this.handleResultsOnFetchComplete) {
      this.handleResultsOnFetchComplete = false;
      if (value && value.options) {
        return this.handleResults(value.options, value.last);
      }
    }
    this.handleFetch(value.options, value.last);
  };

  handleResults = (results, last) => {
    if (results) {

      let exactMatchFound = results.find((item) => {
        return (item.label.toUpperCase() === this.state.text.toUpperCase());
      });

      if (!exactMatchFound) {
        return this.handleFetch(results, last);
      }

      let exactMatchItem =
          {
            id: 0,
            isChecked: true,
            isVisible: true,
            value: exactMatchFound.value,
            valueDisplay: exactMatchFound.label,
            label: exactMatchFound.label,
            additionalData: '',
            additionalDataDisplay: ''
          };

      let checkedRows = this.state.rows
        .filter((row) => {return (row.isChecked && row.value !== exactMatchItem.value)})
        .map((r, ind) => { r.id = ind + 1;  return r;});

      let checkedIds = checkedRows.map((row) => {return row.value});

      let shift = checkedRows.length + 1;

      let rows = results.filter((row) => {
        return (!checkedIds.includes(row.value) && row.value !== exactMatchItem.value);
      });
      rows = rows.map((value, index) => {
        return {
          id: (shift + index),
          isChecked: false,
          isVisible: true,
          value: value.value,
          valueDisplay: value.label,
          label: value.label,
          additionalData: '',
          additionalDataDisplay: ''
        };
      });

      let allRows = [...[exactMatchItem], ...checkedRows, ...rows];
      this.setState({rows : allRows, last: last});

      this.clearInput();
    }
  };

  clearInput = () => {
    this.setState({
      text: '',
      dummyKey: (this.state.dummyKey === 0 ? 1 : 0),
    });

    setTimeout(() => { this.setInputFocus()} , 0);
  };

  handleFetch = (results, last) => {

    //get all checked rows
    let checkedRows = this.state.rows
      .filter((row) => {return row.isChecked})
      .map((r, ind) => { r.id = ind;  return r;});
    let checkedIds = checkedRows.map((row) => {return row.value});

    let shift = checkedRows.length;

    if (results) {
      //filter out already checked items from 'results'
      let rows = results.filter((row) => {return !checkedIds.includes(row.value)});
      rows = rows.map((value, index) => {
        return {
          id: (shift + index),
          isChecked: false,
          isVisible: true,
          value: value.value,
          valueDisplay: value.label,
          label: value.label,
          additionalData: '',
          additionalDataDisplay: ''
        };
      });

      let allRows = [...checkedRows, ...rows];
      this.setState({rows : allRows, last: last});

      if (this.initialFetch) {
        this.initialFetch = false;
        this.initialState = {text: '', rows : rows, last: last};
      }
    }
  };

  renderSearchBox = () => {
    if (this.props.asyncMode) {
      return (
        <MultiSelectFieldAsync
          {...this.props}
          key={this.state.dummyKey}
          id="search-box-async"
          name="search-box-async"
          className="search-box"
          value={this.state.text}
          placeholder={this.labels.get('search')}
          onInputChangeCallback={this.onAsyncInputChangeCallback}
          onKeyPressCallback={this.onKeyPressCallback}
          hasCustomMenuRenderer={true}
          useSimpleInput={true}
          onFetchStartCallback={this.onFetchStartCallback}
          onFetchCompleteCallback={this.onFetchCompleteCallback}
          fetchConfig={this.props.fetchConfig}
          ref={(r) => { this.searchBoxRef = r; }}
        />
      )
   }
      return (
        <Input
          onChange={this.onInputChangeCallback}
          value={this.state.text}
          classIcon="search"
          type='text'
          placeholder={this.props.placeholder || 'Search...'}
          ref={(r) => { this.searchBoxRef = r; }}
        />

      )
  };

  renderLast = () => {
    if (this.state.last) {
      return null;
    }
    let msg = this.controlsLabels.get('combobox.refineSearch');
    return (
      <div className="result result-bottom">{msg}</div>
    );
  };

  render() {
    return (
      <FilterLayout className="multi-select-filter"
                    columnWidth={this.props.column.actualWidth}
                    filterWidth={this.props.filterWidth || MULTI_SELECT_FILTER_WIDTH}
                    filterAlignment={this.props.filterAlignment}
      >

        {this.renderSearchBox()}

        <SelectAllCheckbox
          onClick={this.onSelectAllClick.bind(this)}
          isChecked={this.isAllFilteredRowsSelected()}
          title={this.labels.get('selectall')}
        />

        <FilterTable
          rows={this.getRows()}
        />
        <br/>

        {this.renderLast()}

        <FilterFooter
          okText={this.labels.get('submit') || 'FILTER'}
          cancelText={this.labels.get('cancel') || 'CANCEL'}
          statusText={this.labels.get('status') || 'Selected'}
          onCancel={this.cancel.bind(this)}
          onOk={this.apply.bind(this)}
          showSelectedCount={true}
          selectedCount={this.state.rows.filter(r => r.isChecked).length}
        />

      </FilterLayout>);
  }
}



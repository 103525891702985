import { Map, List} from 'immutable';
import { actionTypes as CreateAttachmentDialogActions }  from '../../../actions/Dialogs/createAttachmentDialog/createAttachmentDialogActions';

let defaultState = {
  show: false,
  assets : Map(),
  preselectedAssets: List(),
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreateAttachmentDialogActions.CREATE_ATTACHMENT_SHOW:
      return state.set('show', true);

    case CreateAttachmentDialogActions.CREATE_ATTACHMENT_HIDE:
      return Map(defaultState);

    case CreateAttachmentDialogActions.CREATE_ATTACHMENT_IN_PROCESS:
      return state.set("loading", true);

    case CreateAttachmentDialogActions.CREATE_ATTACHMENT_FINISHED:
      return state.set("loading", false);
    default:
      return state;
  }

}








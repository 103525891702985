import Network                      from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import { api as fetchEntitiesApi } from '../../FetchEntities/fetchEntitiesActions.js';
import * as assetService from '../../../services/Assets/assetService';
import * as tagsService from '../../../services/Tags/tagsService';
import {api as printLabelsApi} from '../../../actions/PrintLabelsActions/printLabelsActions';
import { FormAdapter }              from 'infrastructure/js/utils/formUtils';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {isRoll, isSpool, isKit} from '../../../utils/assetHelper';

let myState = function(getState) {
  return getState().dialogs.get('printPdfLabelsDialog');
};

let myFormAdapter  = function(dispatch, getState) {
  return new FormAdapter(dispatch, getState, 'printPdfLabelsDialog');
};



/////////////////////////////////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  PRINT_PDF_LABELS_DIALOG_SHOW: 'PRINT_PDF_LABELS_DIALOG_SHOW',
  PRINT_PDF_LABELS_DIALOG_HIDE: 'PRINT_PDF_LABELS_DIALOG_HIDE',
  PRINT_PDF_LABELS_DIALOG_SUBMIT_IN_PROGRESS: 'PRINT_PDF_LABELS_DIALOG_SUBMIT_IN_PROGRESS',
  PRINT_PDF_LABELS_DIALOG_SUBMIT_FINISHED: 'PRINT_PDF_LABELS_DIALOG_SUBMIT_FINISHED',
  PRINT_PDF_LABELS_DIALOG_UPDATE_ASSETS: 'PRINT_PDF_LABELS_DIALOG_UPDATE_ASSETS',
  PRINT_PDF_LABELS_DIALOG_UPDATE_ERRORS: 'PRINT_PDF_LABELS_DIALOG_UPDATE_ERRORS',
};

/////////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.PRINT_PDF_LABELS_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.PRINT_PDF_LABELS_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.PRINT_PDF_LABELS_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.PRINT_PDF_LABELS_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  updateAssets: function(payload) {
    return {type: actionTypes.PRINT_PDF_LABELS_DIALOG_UPDATE_ASSETS, payload: payload };
  },
  updateErrors: function(payload) {
    return {type: actionTypes.PRINT_PDF_LABELS_DIALOG_UPDATE_ERRORS, payload: payload };
  },
};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
};

jsxActions.show = function(data) {
  return function(dispatch, getState) {
    api.show(dispatch, getState)(data);
  };
};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.fetchEntities = function (fetchConfig, callback) {
  return function (dispatch, getState) {
    fetchEntitiesApi.fetchEntities(dispatch, getState)(fetchConfig, callback, true);
  };
};

jsxActions.submit = function(data) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data);
  };
};

jsxActions.addAssetByTagId = function(tagId, allAssets) {
  return function(dispatch, getState) {
    return api.addAssetByTagId(dispatch, getState)(tagId, allAssets);
  };
};

jsxActions.findAssetByTagId = function(tagId, callback) {
  return function(dispatch, getState) {
    return api.findAssetByTagId(dispatch, getState)(tagId, callback);
  };
};

jsxActions.addAsset = function(asset, allAssets) {
  return function(dispatch, getState) {
    return api.addAsset(dispatch, getState)(asset, allAssets);
  };
};


/////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.show(data));
  }
};

api.submit = function(dispatch, getState) {
  return function(data) {

    dispatch(actions.submitInProgress());

    return printLabelsApi.printMultiAssetsLabels(dispatch, getState)(data.assetsIds).then((response) => {
      dispatch(actions.hide());
    });
  };
};


api.findAssetByTagId = function(dispatch, getState) {
  return function(tagId, callback) {
    assetService.getAssetByTagId({tid: tagId}).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('findAssetByTagId() failed for ' + tagId, response);
        return;
      }

      if (callback) {
        let asset = response.data;
        asset = ( asset && isSupportedAssetType(asset)) ? asset : null;
        callback(asset);
      }
    });
  }
};

api.addAssetByTagId = function(dispatch, getState) {
  return function(tagId, allAssets) {

    let labels = createLabelHelper('mat.dialog.printPdfLabelsDialog.');

    if (!isTagIdValid(tagId)) {
      dispatch(actions.updateErrors(labels.get('error.tagNotValid', '', {tagId})));
      return;
    }

    tagsService.getTagByKey(tagId).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('getTagByKey() failed, Tag Id: ' + tagId, response);
        dispatch(actions.updateErrors(labels.get('error.tagNotFound', '', {tagId})));
        return;
      }

      assetService.getAssetByTagId({tid: tagId}).then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('getAssetByTagId() failed, Tag Id: ' + tagId, response);
          dispatch(actions.updateErrors(labels.get('error.default')));
          return;
        }
        let asset = response.data;
        if ( asset && isSupportedAssetType(asset)) {
          api.addAsset(dispatch, getState)(asset, allAssets);
        }
        else {
          dispatch(actions.updateErrors(labels.get('error.noAttachedAsset', '', {tagId})));
        }
      });
    });
  }
};

api.addAsset = function(dispatch, getState) {
  return function(asset, allAssets) {
    if (asset) {
      let found = allAssets.find((item) => {
        return item.id === asset.id
      });
      if (!found) {
        let newData = [...allAssets, asset];
        dispatch(actions.updateAssets(null));  //workaround - to clear the previous value in the component
        setTimeout(() => {dispatch(actions.updateAssets(newData))}, 0);
      }
    }
  }
};


/////////////////////////////////////////////////////////////////////
// HELPERS

function isTagIdValid(tagId) {
  return (tagId.match(/^[a-f0-9]{16}$/i) !== null);
}

//currently only Roll, Spool and Kit types are supported
function isSupportedAssetType(asset) {
  return (asset && (isRoll(asset.objectType) || isSpool(asset.objectType) || isKit(asset.objectType) ));
}







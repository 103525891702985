import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as headerSettingsActions} from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import { gridsNames} from '../../../../enums/gridsNames';
import SensorsView from '../../../../components/Administration/SettingsPage/Sensors/sensorsView';
import {jsxActions as sensorsViewActions} from '../../../../actions/Administration/SettingsPage/sensorsViewActions';
import {jsxActions as sensorsViewGridActions} from '../../../../actions/Administration/SettingsPage/sensorsGridViewActions';
import {jsxActions as createSensorDialogActions} from '../../../../actions/Administration/SettingsPage/Dialogs/createSensorDialogActions';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(sensorsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      sensorsViewGridActions: bindActionCreators(sensorsViewGridActions, dispatch),
      createSensorDialogActions : bindActionCreators(createSensorDialogActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch),
    }
  }
}

let SensorsViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_SETTINGS_SENSORS);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateSensorDialogData: state.administration.getIn(['settingsPage', 'createSensorDialog']),
      sData: state.administration.getIn(['settingsPage', 'sensorsView']),
    };
  },

  mapDispatchToProps
)(SensorsView);

export default SensorsViewContainer;

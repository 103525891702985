import React from 'react';

import { Prompt } from 'react-router-dom';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {headerItemsLabels} from '../../../Common/Header/header';
import {reduxForm} from 'redux-form';
import RfidHeader from '../Common/rfidHeader';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import {RfidSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';

require('./communicationParametersView.scss');

class CommunicationParametersView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.rfidsettings.communicationparameters.view.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_COMMUNICATIONS_PARAMETERS);
    this.props.actions.init();
    this.initFormValues();
  }

  // componentWillMount() { moved to componentDidMount()
  //   this.props.actions.init();
  // }
  componentDidUpdate(prevProps){
    let data = this.props.sData.get('communicationData');
    // allows to update values returning from async process and prevent init data while typing
    if(data !== prevProps.sData.get('communicationData')){
      this.initFormValues();
    }
  }

  initFormValues = () => {
    let data = this.props.sData.get('communicationData');
    if(!data){
      return;
    }
    let initialValue = {
      dispatchEventsBatchEveryMsec: data.dispatcEventsBatchEveryMsec,
      cloudAutoFetchEveryMsec: data.cloudAutoFetchEveryMsec,
      dispatchLogsBatchEveryMsec: data.dispatchLogsBatchEveryMsec,
      cloudCommandsFetchEveryMsec:data.cloudCommandsFetchEveryMsec,
      deviceAppDropDown: this.props.sData.get('selectedDeviceApp'),
      id: data.id
    };

    this.props.initialize(initialValue);
  }
  getHeaderItems() {
    return {
      dropdown: {
        name : 'deviceAppDropDown',
        id : 'deviceAppDropDown',
        options: this.props.sData.get('deviceAppsOptions'),
        disabled: this.props.sData.get('deviceAppsOptions').length <= 1,
        changeCallback: this.props.actions.onDeviceAppChanged,
      },
      buttons: [
        {
          id:'save',
          className: 'no-icon',
          label: headerItemsLabels.SAVE,
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.props.pristine || !this.props.sData.get('selectedDeviceApp'))
        }
      ],
    };
  }

  renderPrompt = () => {
    return (
      <Prompt
        when={!this.props.pristine}
        message={location =>
          this.navigationLabels.get('datanotsaved.text')
        }
      />
    );
  }

  onSubmit = (data) => {
    let query = {
      cloudAutoFetchEveryMsec: data.cloudAutoFetchEveryMsec,
      dispatcEventsBatchEveryMsec: data.dispatchEventsBatchEveryMsec,
      dispatchLogsBatchEveryMsec: data.dispatchLogsBatchEveryMsec,
      cloudCommandsFetchEveryMsec: data.cloudCommandsFetchEveryMsec,
      id: data.id
    };
    this.props.actions.header.onSaveCommunicationParametersClick(query)
  }

  getLoadingOverlay() {
    if (!this.props.sData.get('loading')) {
      return null;
    }

    return <div className="rifd-view-loading-overlay">
      <i className='fa fa-spinner fa-spin fa-fw ' aria-hidden="true"></i>
    </div>;
  }

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    let isDisabled = !this.props.sData.get('selectedDeviceApp');
    return (
      <div className="administration-view communication-parameters-view">
        {this.renderPrompt()}
        <div className="title">{title}</div>
        {this.getLoadingOverlay()}
        <RfidHeader {...headerItems} change = {this.props.change} selectedDeviceApp={this.props.sData.get('selectedDeviceApp')}/>
        <div>
          <InputSection label={this.labels.get('dispatchevents')+'*'} htmlFor="dispatchEventsBatchEveryMsec" className="inline">
            <TextField id="dispatchEventsBatchEveryMsec" name="dispatchEventsBatchEveryMsec" disabled={isDisabled}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)} className="short-textfield" />
          </InputSection>
          <InputSection label={this.labels.get('cloudautofetch')+'*'} htmlFor="cloudAutoFetchEveryMsec" className="inline">
            <TextField id="cloudAutoFetchEveryMsec" name="cloudAutoFetchEveryMsec" disabled={isDisabled}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}  className="short-textfield"/>
          </InputSection>
          <InputSection label={this.labels.get('dispatchlogs')+'*'} htmlFor="dispatchLogsBatchEveryMsec" className="inline">
            <TextField id="dispatchLogsBatchEveryMsec" name="dispatchLogsBatchEveryMsec" disabled={isDisabled}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}  className="short-textfield"/>
          </InputSection>
          <InputSection label={this.labels.get('cloudcommand')+'*'} htmlFor="cloudCommandsFetchEveryMsec" className="inline">
            <TextField id="cloudCommandsFetchEveryMsec" name="cloudCommandsFetchEveryMsec" disabled={isDisabled}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}  className="short-textfield"/>
          </InputSection>
        </div>
      </div>
    );
  }
}

export default reduxForm({
    form: 'CommunicationParametersForm'
  }
)(CommunicationParametersView);

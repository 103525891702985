import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {api as navigationApi, api as navigatorApi} from '../AppTopbar/navigatorActions';
import { api as gridApi} from './pickListGridActions';
import {navigationStates} from '../../enums/navigationStates';
import { api as systemApi } from '../System/systemActions.js';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';
import Network from 'infrastructure/js/modules/network';
import * as pickListsService from '../../services/PickLists/pickListsService';
import {api as pickListPageEditorAPI} from './pickListPageEditorActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  PICKLIST_PAGE_FETCH_DATA_FINISHED: 'PICKLIST_PAGE_FETCH_DATA_FINISHED',
  PICKLIST_PAGE_SET_BUSY: 'PICKLIST_PAGE_SET_BUSY',
  PICKLIST_PAGE_UNMOUNT: 'PICKLIST_PAGE_UNMOUNT',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataFinished: function(payload) {
    return {type: actionTypes.PICKLIST_PAGE_FETCH_DATA_FINISHED, payload: payload};
  },
  unmount: function() {
    return {type: actionTypes.PICKLIST_PAGE_UNMOUNT};
  },
  setBusy(payload) {
    return { type: actionTypes.PICKLIST_PAGE_SET_BUSY, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (pickListId, shouldRefreshGrid) {
  return function (dispatch, getState) {

    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.PICK_LIST);

    navigationApi.setLoading(true)(dispatch, getState);

    pickListPageEditorAPI.fetchAllDataForEditor(dispatch, getState)();
    api.fetchData(dispatch, getState)( pickListId, shouldRefreshGrid).then((response) => {

      let pickListLabel = createLabelHelper('mat.header.navigator.predefinedlists.').get('pickList');

      let topNavigatorData = {
        name: pickListLabel,
        subname: response?.data?.businessId || '',
        status: '',
        type: navigationStates.PICK_LIST,
        id: pickListId,
        components: []
      };
      navigatorApi.setData(dispatch, getState)(topNavigatorData);
      navigationApi.setLoading(false)(dispatch, getState);
    });
  }
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};


jsxActions.reload = function(pickListId, shouldRefreshGrid) {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)(pickListId, shouldRefreshGrid);
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.reload = function(dispatch, getState) {
  return function(pickListId, shouldRefreshGrid) {
    return api.fetchData(dispatch, getState)(pickListId, shouldRefreshGrid);
  }
};

api.fetchData = function(dispatch, getState) {
  return function(pickListId, shouldRefreshGrid=false) {

    dispatch(actions.setBusy(true));

    return pickListsService.getPickListDetails(pickListId).then((response) => {

      dispatch(actions.setBusy(false));

      if (!Network.isResponseValid(response)) {
        console.error('Failed to get the Pick List details', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return null;
      }

      let pickListDetails = response?.data || null;
      dispatch(actions.fetchDataFinished(pickListDetails));

      if (shouldRefreshGrid) {
        gridApi.reload(dispatch, getState)();
      }

      return response;
    });
  }
};

api.removeFromPickList = function (dispatch, getState) {
  return function (data) {

    let assetsIds = data?.rows.map((asset) => { return asset.id })?.toJS() || [];

    pickListsService.removeAssetsFromPickList(data?.pickListId, assetsIds).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Removing assets from pick list failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      api.reload(dispatch, getState)(data?.pickListId, true);
    });
  }
};




import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import * as mqttBrokersService from '../../../services/Rfid/mqttBrokersService';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_RFID_SETTINGS_MQTT_BROKERS, _fetchDataAction);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let selectedDeviceApp =  getState().administration.getIn(['rfidSettingsPage', 'mqttBrokersSettingsViewData', 'selectedDeviceApp']);

    if (!selectedDeviceApp || !selectedDeviceApp.label) {
      return Promise.resolve([]);
    }

    let queryData = {
      deviceOrgBusinessId :getState().login.get('loggedInUser').loggedInOrg.businessId,
      deviceBusinessId: selectedDeviceApp.label
    }

    return deviceAppsService.getDeviceProfile(queryData).then((res) => {
      return mqttBrokersService.fetchMqttBrokers(res.data.profileId);
    });

  }
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import React from 'react';

require('./circleSuccessIcon.scss');

export default class CircleSuccessIcon extends React.Component {
  render() {
    return (
      <div className="success-icon in">
        <div className="border"/>

        <div className="border-highlight"/>

        <div className="checkmark-box">
          <div className="bar-1"/>
          <div className="bar-2"/>
        </div>
      </div>
    )
  }
}



import { Map } from 'immutable';
import { actionTypes as CreateTaskDialogActionsType } from '../../../actions/SchedulerPage/createTaskDialogActions';

let defaultState = {
  show: false,
  loading: false,
  dialogData: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreateTaskDialogActionsType.CREATE_TASK_DIALOG_SHOW:
      return state.set('show',true);

    case CreateTaskDialogActionsType.CREATE_TASK_DIALOG_HIDE:
      return Map(defaultState);

    case CreateTaskDialogActionsType.CREATE_TASK_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case CreateTaskDialogActionsType.CREATE_TASK_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);

    case CreateTaskDialogActionsType.CREATE_TASK_DIALOG_FETCH_DATA_READY:
      return state.set("dialogData", action.payload);

    default:
      return state;
  }

}








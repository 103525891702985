import React from 'react';

import DetailsColumn from '../../../../Common/DetailsComponents/detailsColumn.js';
import Link from '../../../../Common/Layout/Link/link';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';
import { navigationStates } from '../../../../../enums/navigationStates.js';
import {isAssetActivated} from '../../../../../utils/assetHelper';
import * as AssetDetailsHelper from '../utils/assetDetailsHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import TextFieldEditor from 'infrastructure/js/components/editors/TextFieldEditor/textFieldEditor';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { getEditorDataConverter } from 'infrastructure/js/utils/editorHelper.js';
import TagsAsyncEditor from '../../../Common/TagsAsyncEditor';


require('./partDetails.scss');

export default class PartDetails extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.assetpage.overview.details.');
    this.partStatuslabels = createLabelHelper('enum.part.status.');
    this.dateFormatTooltip = PermissionManager.isDateFormatEnabled() ? createLabelHelper('period.date.format.tooltip').get('', undefined, {format:DateTimeHelper.getDateFormat()}) : '';
  }

  convertEditorDataToServerData = getEditorDataConverter('part');

  handleAssetEditInPlaceSubmit = (editorSubmitData) => {
    if (!editorSubmitData ) {
      return;
    }

    let serverDataValue = this.convertEditorDataToServerData(editorSubmitData);
    if (serverDataValue === undefined) {
      return;
    }
    let newData = {};
    newData[editorSubmitData.fieldName] = serverDataValue;

    //If location changed, erase subLocation, send empty value.
    if(editorSubmitData.fieldName === 'locationId' && this.props.asset.location.id !== serverDataValue){
      newData['subLocation'] = null;
    }

    this.props.actions.assetEditorActions.updatePart(this.props.asset.id, newData);
  };

  renderLocationLink = (asset) => {
    return (
      <Link label={asset.locationName} id={asset.locationId} type={navigationStates.LOCATION}
                    className="link-details"/>
    );
  };

  renderSublocationEditor = (asset) => {
    return (
      <TextFieldEditor
        id='subLocation'
        name='subLocation'
        value={asset.subLocation}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
        validate={Validation.maxLength(50)}
      />
    );
  };

  renderTagEditor = (asset) => {
    //Convert server object value to singleLineMultiSelect object.

    return (
      <TagsAsyncEditor
        id="tags"
        name="tags"
        limit={10}
        asset={asset}
        onSubmit={this.handleAssetEditInPlaceSubmit}
        disabled={!isAssetActivated(asset.assetStatus)}
        parse={(data, name) => ({
          fieldName: name,
          tags: data.map(({ value }) => ({
            id: value,
          })),
        })}
      />
    );
  };

  renderAssetWorkOrder = (asset) => {
    if (asset.workOrder) {
      let project = (asset.workOrder.project) ? ` (${asset.workOrder.project.businessId})` : '';
      return (
        <Link label={`${asset.workOrder.businessId}${project}`}
                      id={asset.workOrder.id}
                      type={navigationStates.WORKORDER}
                      className="link-details"
        />
      )
    }
    return <span/>;
  };

  renderWorkOrderDueDate = (asset) => {
    if (asset.workOrder && asset.workOrder.dueDate) {
      return (
        <div>
          <span>{DateTimeHelper.FormatDateObjectToDayMonth(asset.workOrder.dueDate)}</span>
          {AssetDetailsHelper.getAlertPopover(asset, getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'))}
        </div>
      );
    }
    return <span/>;
  };
  getAssetDetails (asset, labels) {

    let index0 = [
      {
        label: this.labels.get('location'),
        body: this.renderLocationLink(asset)
      },
      {
        label: this.labels.get('sublocation'),
        body: this.renderSublocationEditor(asset)
      },
      {
        label: this.labels.get('tag'),
        body: this.renderTagEditor(asset)
      },
    ];

    let index1 = [
      {
        label: this.labels.get('wo'),
        body: this.renderAssetWorkOrder(asset),
      },
      {
        label: this.labels.get('woduedate'),
        body: this.renderWorkOrderDueDate(asset),
        tooltip: this.dateFormatTooltip,
      },
      {
        label: this.labels.get('status'),
        body: <span id="part-status-input">{asset.partStatus ? this.partStatuslabels.get(asset.partStatus) : ''}</span>,
      },
    ];

    let index2 = [
      {
        label: this.labels.get('createdat'),
        body: <span id="create-date-input">{DateTimeHelper.FormatDateObjectToDayMonth(asset.createdDate)}</span>,
        tooltip: this.dateFormatTooltip,
      },
      {
        label: this.labels.get('createdby'),
        body: <span id="create-by-input">{asset.createdByUser}</span>
      },
      {
        label: '',
        body: null,
      },
    ];

    let result = [index0, index1, index2];

    return result;
  }

  render() {
    let {asset} = this.props;
    if (!asset || !asset.id) {
      return null;
    }

    let columnsData = this.getAssetDetails(asset, this.labels);

    let columns = columnsData.map((column, index) => {
      return <DetailsColumn key={'part' + index} rows={column}/>
    });

    return (
      <div className="part-details asset-details-section ">
        {columns}
      </div>
    );
  }
}


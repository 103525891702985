export function concatClassNames(...classes){
  let classString = '';
  classes.map((className)=>{
    if(className !== undefined){classString += className + " ";}
  });
  classString.trim();
  return classString;
}


// Generate object describing control "names" and "id"s that will be used as data paths in Redux-Form.
export function generateFormNames(chosenProps, formArrayName, controlIdPrefix, index) {
  let result = {};

  for (let propName in chosenProps) {
    let controlName = (formArrayName + '[' + index + '].' + propName);
    result[propName] = {
      name: controlName,
      id:controlIdPrefix + controlName
    };
  }

  return result;
};

// Convert value in one range to value in a different range.
export function mapRange(value, low1, high1, low2, high2) {
  let result = low2 + (high2 - low2) * (value - low1) / (high1 - low1);
  return Math.ceil(result);
}

export function convertOptionsToServerData(arrOptions) {
  /***************************************************************/
  // Convert:
  // [{label: "AAA", value: 123}, {label: "BBB", value: 456}]
  // to {AAA: 123, BBB: 456}
  /***************************************************************/

  if (!arrOptions) { return {}; }

  // Convert first to Array of options, if dealing with a single option.
  if (!arrOptions.forEach) { arrOptions = [arrOptions]; }


  let result = {};
  arrOptions.forEach((item, index) => {

    // Detect manually added options and set their values to null.
    // if (item.className && item.className === "Select-create-option-placeholder") {
    if (item.label === item.value ) {
      result[item.label] = null;
    }
    // Regular options.
    else {
      result[item.label] = item.value
    }
  });

  return result;
}

export function toNumberOrNull(s) {
  return (isNaN(s)) ? (null) : +(s);
}

export function capitalizeFirstLetter(s) {
  if ( !s || (typeof s !== 'string') ) return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}








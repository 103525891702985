import React from 'react';
import PropTypes from 'prop-types';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import Validation from "infrastructure/js/components/controls/controlsValidations";
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import AlertPopover, {AlertPopoverType} from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import Normalize from "infrastructure/js/components/controls/controlsNormalizations";
import {enumTypes, getEnumValue, getLookupOptions} from "../../../../../utils/enumHelper";
import UnitsHelper from 'infrastructure/js/utils/uomHelper';
require('./createSensorDialog.scss');

class CreateSensorDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.settings.sensors.dialog.createSensor.');
    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;
    this.state = {currentUomOptions: []}
  }

  componentDidMount() {
//deviceAppProfileName
    this.sensorsOptions = this.props.sData.get('dialogData') ? this.props.sData.get('dialogData').sensors : [];
    this.uomAllOptions = getLookupOptions(enumTypes.TELEMETRY_UOM);
    this.uomAllOptions.forEach((x)=> x.label = UnitsHelper.getUomForTelemetry(x.value));
    this.uomHumidityOptions = getLookupOptions(enumTypes.TELEMETRY_UOM_HUMIDITY);
    this.uomHumidityOptions.forEach((x)=> x.label = UnitsHelper.getUomForTelemetry(x.value));
    this.uomTemperatureOptions = getLookupOptions(enumTypes.TELEMETRY_UOM_TEMPERATURE);
    this.uomTemperatureOptions.forEach((x)=> x.label = UnitsHelper.getUomForTelemetry(x.value));
    this.sensorTypeOptions = getLookupOptions(enumTypes.TELEMETRY_SENSOR_TYPE);

    let initialValues = {
      active: true,
      positiveDeviation: 0.5,
      negativeDeviation: 0.5,
    };
    if (this.isEditMode) {
      let type = this.sensorTypeOptions.find((x)=>{ return x.value === this.itemToEdit.type});
      this.onTypeChangeCallback(type);
      initialValues = {
        active: this.itemToEdit.active,
        positiveDeviation: this.itemToEdit.deviationUp,
        negativeDeviation: this.itemToEdit.deviationDown,
        name: this.sensorsOptions && this.sensorsOptions.length ? this.sensorsOptions.find((x)=> {return x.data.sensorName === this.itemToEdit.sensorName}) : null,
        type: type,
        uom: this.uomAllOptions.find((x)=>{return x.value === this.itemToEdit.uom}),
      };
      if(this.itemToEdit.samplingPeriodSec){
        initialValues.period_seconds = this.itemToEdit.samplingPeriodSec % 60 | 0;
        initialValues.period_minutes = this.itemToEdit.samplingPeriodSec / 60 | 0;
      }
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: 'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('add'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  onSubmit = (data) => {

    let minutesToSec = data.period_minutes ? data.period_minutes * 60 : 0;
    minutesToSec += data.period_seconds ? data.period_seconds : 0;
     let newData = {
      sensorName : data.name.data.sensorName,
      type : data.type.value,
      uom : data.uom.value,
      active : true,
      samplingPeriodSec : minutesToSec,
      deviationUp : data.positiveDeviation,
      deviationDown : data.negativeDeviation
    };
     if(this.isEditMode){
       newData.settingId = this.itemToEdit.id;
     }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  getLabelIcon=(text)=>{
    return (<AlertPopover
      popoverType={AlertPopoverType.HINT}
      popoverComponent={<div>{text}</div>}
    >
    </AlertPopover>)
  };

  validateDuration = (value, allValues) => {
    return Validation.minutesSecondsTimeRequired('period')(value, allValues);
  };

  onTypeChangeCallback = (newValue) => {
    this.props.change('uom', null);
    if(!newValue || !newValue.value){
      this.setState({currentUomOptions: []})
    }
    if(newValue.value === 'TEMPERATURE'){
      this.setState({currentUomOptions: this.uomTemperatureOptions});
      this.props.change('uom', this.uomTemperatureOptions.find((x)=> x.value === 'CELSIUS'));
    }
    else{
      this.setState({currentUomOptions: this.uomHumidityOptions});
      this.props.change('uom', this.uomHumidityOptions.find((x)=> x.value === 'RELATIVE_PERCENTAGE'));
    }
  }

  render() {

    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    return (
      <Dialog
        id="create-sensor-dialog"
        className="create-sensor-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection htmlFor="active" className="inline two-columns no-margin">
          <Checkbox name="active" id="active" label={this.labels.get('active')}/>
        </InputSection>

        <InputSection label={this.labels.get('name')+'*'} htmlFor="name" className="inline left-side">
          <Combobox id="name" name="name" options={this.sensorsOptions} validate={Validation.required} />
        </InputSection>

        <InputSection label={this.labels.get('type')+'*'} htmlFor="type" className="inline right-side">
          <Combobox id="type" name="type" options={this.sensorTypeOptions} validate={Validation.required} onChangeCallback={this.onTypeChangeCallback} />
        </InputSection>

        <InputSection labelExtraComponent={this.getLabelIcon(this.labels.get('positiveDeviationTooltip'))} label={this.labels.get('deviation') + ' ( + ) ' + '*'} htmlFor="positiveDeviation" className="inline left-side">
          <TextField id="positiveDeviation" name="positiveDeviation" className="short-textfield" normalize={Normalize.normalizeFloat(0, 99.999, 3)} placeholder={this.labels.get('deviationPlaceHolder')} validate={Validation.required} />
        </InputSection>

        <InputSection labelExtraComponent={this.getLabelIcon(this.labels.get('negativeDeviationTooltip'))} label={this.labels.get('deviation') + ' ( - ) ' + '*'} htmlFor="negativeDeviation" className="inline right-side">
          <TextField id="negativeDeviation" name="negativeDeviation" className="short-textfield" normalize={Normalize.normalizeFloat(0, 99.999, 3)} placeholder={this.labels.get('deviationPlaceHolder')} validate={Validation.required} />
        </InputSection>

        <InputSection label={this.labels.get('uom')+'*'} htmlFor="uom" className="inline left-side">
          <Combobox id="uom" name="uom" options={this.state.currentUomOptions} validate={Validation.required} placeholder={this.labels.get('uomPlaceHolder')} />
        </InputSection>

        <InputSection label={this.labels.get('period')+'*'} htmlFor="period" className="inline right-side" labelExtraComponent={this.getLabelIcon(this.labels.get('periodTooltip'))}>
          <TimeField id="period" name="period" className="short-textfield" validate={this.validateDuration} isSeconds={true} isHours={false} hoursCount={true} maxHoursLength={2}/>
        </InputSection>

      </Dialog>
    );
  }
}

CreateSensorDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'CreateSensorDialog',
  }
)(CreateSensorDialog);

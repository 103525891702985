import { api as locationPageApi } from '../locationPageActions.js';
import * as spoolsService from '../../../services/Spools/spoolsService';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_ASSET_SPOOLS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let locationType = locationPageApi.getLocationType(dispatch, getState)();

    if (locationType === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_AREA') ) {
      return spoolsService.getShippedSpoolsByStationId(locationId, query);
    }
    else if (locationType === getEnumValue(enumTypes.LOCATION_TYPE)('RECEIVING_AREA') ) {
      return spoolsService.getReceivingSpoolsByStationId(locationId, query);
    }

    else if (locationType === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA') ) {
      return spoolsService.getSpoolsDataByStationId(locationId, query, true);
    }
    return spoolsService.getSpoolsDataByStationId(locationId, query);
  }
}

function _convertToRowData(rows, serverTimeSeconds){
  let items = rows.map((obj, index) => {
    if(obj.expirationDate){
      obj.m_ExpirationDaysLeft =  DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds);
    }
    return obj;
  });

  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

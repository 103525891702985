import { actionTypes as progressDashboardPageActionsTypes } from '../../../actions/Reports/progressDashboardPageActions';
import {Map} from 'immutable';

let defaultState = {
  loading: false,
  headerData: null,
  kpiData: null,
  chartData: null,
  TOOLS: null,
  LOCATIONS: null,
  OPERATIONS: null,
};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case progressDashboardPageActionsTypes.PROGRESS_DASHBOARDPAGE_FETCHDATA_STARTED:
      return state.set('loading', true);

    case progressDashboardPageActionsTypes.PROGRESS_DASHBOARDPAGE_FETCHDATA_FINISHED:
      return state.set('loading', false);

    case progressDashboardPageActionsTypes.PROGRESS_DASHBOARDPAGE_FETCH_HEADER_DATA_FINISHED:
      return state.set('headerData', action.payload);

    case progressDashboardPageActionsTypes.PROGRESS_DASHBOARDPAGE_FETCH_CHART_DATA_FINISHED:
      return state.set('chartData', action.payload.chartData)
        .set('wosCount', action.payload.wosCount)
        .set('chartMetadata', action.payload.chartMetadata);

    case progressDashboardPageActionsTypes.PROGRESS_DASHBOARDPAGE_FETCH_GRID_DATA_FINISHED:
      return state.set('TOOLS', action.payload.gridData.TOOLS)
                  .set('LOCATIONS', action.payload.gridData.LOCATIONS)
                  .set('OPERATIONS', action.payload.gridData.OPERATIONS)
                  .set('kpiData', action.payload.kpiData);

    case progressDashboardPageActionsTypes.PROGRESS_DASHBOARDPAGE_RESET_DATA:
      return Map(defaultState);

    default:
      return state;
  }
}



import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as massOperationsPageActions }  from '../../../actions/Administration/MassOperationsPage/massOperationsPageActions.js';
import MassOperationsPage from '../../../components/Administration/MassOperationsPage/massOperationsPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(massOperationsPageActions, dispatch)
  }
}

let MassOperationsPageContainer = connect(
  (state) => {
  	return {
      sData: state.administration.get('massOperationsPage'),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(MassOperationsPage);


export default MassOperationsPageContainer;




import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../../Common/Header/header';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import {MatSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {isAllItemsActive, isAllItemsDeactive} from '../../utils/matSettingsHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import CreateToolCategoryDialog from './Dialogs/CreateToolCategoryDialog/createToolCategoryDialog';
import ToolCategoriesViewGrid from './ToolCategoriesViewGrid/toolCategoriesViewGrid';

export default class ToolCategoriesView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.toolCategories.view.');
  }

  componentDidMount() {
    this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_TOOL_CATEGORIES);
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    let items =  {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        ...(PermissionManager.hasCreateOrEditToolCategoriesPermissions() ? [
          {
            id:'create',
            label: headerItemsLabels.CREATE,
            icon: 'pl pl-icon-add',
            action: actions.header.onCreateToolCategoryClick,
          },
          {
            id:'edit',
            label: headerItemsLabels.EDIT,
            icon: 'pl pl-edit-icon',
            disabled: !(hasRows && selectedRowsData.size === 1),
            action: actions.header.onEditToolCategoryClick,
            actionData: selectedRowsData.get(0)
          },
        ] : []),
      ],

      kebab: {
        menuItems: [
          {
            id:'activate',
            label: headerItemsLabels.ACTIVATE,
            action: actions.header.onActivateToolCategoriesClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsDeactive(selectedRowsData)),
            actionData:  selectedRowsData,
          },
          {
            id:'deactvate',
            label: headerItemsLabels.DEACTIVATE,
            action: actions.header.onDeactivateToolCategoriesClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsActive(selectedRowsData)),
            actionData:  selectedRowsData,
          },
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.header.onRemoveToolCategoriesClick,
            disabled: (!selectedRowsData || selectedRowsData.size === 0),
            actionData:  selectedRowsData,
          },
        ]
      }
    };

    return items;
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems}/>

        <ToolCategoriesViewGrid actions={{...this.props.actions.toolCategoriesViewGridActions, ...this.props.actions.fetchEntitiesActions}} />

        <PL_DialogWrapper dialogComponent={CreateToolCategoryDialog}
                          show={this.props.sCreateToolCategoryDialogData.get('show')}
                          actions={this.props.actions.createToolCategoryDialogActions}
                          sData={this.props.sCreateToolCategoryDialogData}
                          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}




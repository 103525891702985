import { Map } from 'immutable';

import {default as smartSelectionHeaderReducer} from './SmartSelectionPage/smartSelectionHeaderReducer';
import {default as onHandInventoryHeaderReducer} from './OnHandInventory/onHandInventoryHeaderReducer.js';
import {default as schedulerReducer} from './SchedulerPage/schedulerReducer';
import analyticModeReducer from './SchedulerPage/analyticModeReducer'

let defaultState = {

};

export default function(state = Map(defaultState), action) {

  state = state.set('smartSelectionHeader', smartSelectionHeaderReducer(state.get('smartSelectionHeader'), action));
  state = state.set('onHandInventoryHeader', onHandInventoryHeaderReducer(state.get('onHandInventoryHeader'), action));
  state = state.set('scheduler', schedulerReducer(state.get('scheduler'), action));
  state = state.set('schedulerAnalyticMode', analyticModeReducer(state.get('schedulerAnalyticMode'), action));

  return state;

}

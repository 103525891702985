import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import AssetTypesViewGrid from './AssetTypesViewGrid/assetTypesViewGrid';
import {MatSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import CreateAssetTypeWizard from './CreateAssetTypeWizard/createAssetTypeWizard';

export default class AssetTypesView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.assettypes.view.');
  }

  componentDidMount() {
    this.props.actions.init(false);
    this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_ASSET_TYPES);
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreateAssetTypeClick,
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditAssetTypeClick,
          actionData: selectedRowsData.get(0)
        }
      ],
      kebab: {
        disabled: !selectedRowsData || selectedRowsData.size === 0 ,
        menuItems: [
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.header.onRemoveAssetTypesClick,
            disabled: !(hasRows && selectedRowsData.size === 1),
            actionData:  selectedRowsData,
          },
        ]
      }
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems}/>

        <AssetTypesViewGrid actions={{...this.props.actions.assetTypesViewGridActions, ...this.props.actions.fetchEntitiesActions}} />

        <CreateAssetTypeWizard
          actions={this.props.actions.createAssetTypeWizardActions}
          sData={this.props.sCreateAssetTypeWizard}
          // sLoggedInUser={this.props.sLoggedInUser}
        />


      </div>
    );
  }
}

AssetTypesView.propTypes = {
  sGrid: PropTypes.object,
};


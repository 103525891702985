import { actionTypes as workOrderActionTypes } from '../../../actions/LocationPage/WorkOrderView/workOrderViewActions';
import { Map } from 'immutable';

let defaultState = {
  operations: [],
};


export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case workOrderActionTypes.LOCATIONPAGE_WORKORDERVIEW_GETOPERATIONS_FINISHED:
      return state.set("operations", action.payload);

    default:
      return state;
  }


  return state;
}



import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as automatedWorkflowsService from '../../../services/Rfid/automatedWorkflowsService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_RFID_SETTINGS_QUALIFIED_EVENTS, _fetchDataAction);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return automatedWorkflowsService.fetchQualifiedEvents();
  }
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as logsService from "../../../../services/Logs/logsService";
import { api as gridApi} from '../sensorsGridViewActions';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_SENSOR_DIALOG_SHOW: 'CREATE_SENSOR_DIALOG_SHOW',
  CREATE_SENSOR_DIALOG_HIDE: 'CREATE_SENSOR_DIALOG_HIDE',
  CREATE_SENSOR_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_SENSOR_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_SENSOR_DIALOG_SUBMIT_FINISHED: 'CREATE_SENSOR_DIALOG_SUBMIT_FINISHED',
  CREATE_SENSOR_DIALOG_FETCH_DATA_READY: 'CREATE_SENSOR_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_SENSOR_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_SENSOR_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_SENSOR_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_SENSOR_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDialogDataReady: function(payload) {
    return {type: actionTypes.CREATE_SENSOR_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, isEditMode, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    api.fetchDialogData(dispatch, getState)().then(()=>{
      dispatch(actions.show(data));
    });

  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function() {
    return logsService.getSensorsOptions().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch sensors logs failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return;
      }
      let dialogData = {
        sensors: response.dataList.map((obj) => {return {value: obj.sensorId, label: `${obj.sensorName} (${obj.deviceAppProfileName})`, data: obj}}),
      };

      dispatch(actions.fetchDialogDataReady(dialogData));
    });
  }
};


api.submit = function(dispatch, getState) {
  return function(data, isEditMode) {
    dispatch(actions.submitInProgress());

    let method = isEditMode ? logsService.editSensorLog : logsService.createSensorLog;

    method(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Edit sensors failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      let label = isEditMode ? 'mat.administration.settings.sensors.dialog.createSensor.confirm.edit' : 'mat.administration.settings.sensors.dialog.createSensor.confirm.add';
      messageDialogApi.showSuccess(dispatch, getState)(label);
      gridApi.reload(dispatch, getState)();
    });
  };
};


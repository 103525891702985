import { actionTypes as productionDashboardPageActionsTypes } from '../../../actions/Reports/productionDashboardPageActions';
import {Map} from 'immutable';

let defaultState = {
  loading: false,
  headerData: null,
  kpiData: null,
  chartData: null,


};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case productionDashboardPageActionsTypes.DASHBOARDPAGE_FETCHDATA_STARTED:
      return state.set('loading', true);

    case productionDashboardPageActionsTypes.DASHBOARDPAGE_FETCHDATA_FINISHED:
      return state.set('loading', false);

    case productionDashboardPageActionsTypes.DASHBOARDPAGE_FETCH_HEADER_DATA_FINISHED:
      return state.set('headerData', action.payload);

    case productionDashboardPageActionsTypes.DASHBOARDPAGE_FETCH_CHART_DATA_FINISHED:
      return state.set('chartData', action.payload.chartData)
                  .set('kpiData', action.payload.kpiData);

    case productionDashboardPageActionsTypes.DASHBOARDPAGE_RESET_DATA:
      return Map(defaultState);

    default:
      return state;
  }
}



import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import TimeFrameCell from "../../../../Common/CustomGridCells/TimeFrameCell/timeFrameCell";
import LabelWithTooltipCell from "../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell";

export default class DeviceAppsViewGrid extends React.PureComponent{

  filterConfig = [  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.deviceapps.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'username',
        title: this.labels.get('columns.devicename.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          sortable: false,
        }
      },
      {
        fieldName: 'verificationToken',
        title: this.labels.get('columns.token.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.verificationToken ? params.data.verificationToken : "";
          }
        }
      },
      {
        fieldName: 'updateWindow',
        title: this.labels.get('columns.insatllUpdates.title'),
        filterType: filterTypes.NONE,
        width: 320,
        columnOptions: {
          sortable: false,
          cellComponent: TimeFrameCell,
          valueGetter: (params) => {
            return {
              dayFrom : params.data.updateWindowSlot ? params.data.updateWindowSlot.from.dayName : null,
              dayTo:  params.data.updateWindowSlot ? params.data.updateWindowSlot.to.dayName : null,
              timeFrom: params.data.updateWindowSlot ? params.data.updateWindowSlot.from.dayTime : null,
              timeTo: params.data.updateWindowSlot ? params.data.updateWindowSlot.to.dayTime: null,
            }
          }
        }
      }
    ]
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_RFID_SETTINGS_DEVICE_APPS}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}


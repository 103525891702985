import { actionTypes } from '../../actions/Register/registerActions';
import {Map} from 'immutable';

let defaultState = Map({
  loading : false
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.REGISTER_SUBMIT_IN_PROCESS:
      return state.set('loading', true);

    case actionTypes.REGISTER_SUBMIT_FINISHED:
      return state.set('loading', false);

    case actionTypes.REGISTER_SUBMIT_FAILED:
      return state.set('loading', false);

    default:
      return state;
  }
}

import React, {Component} from 'react';

import PL_Wizard  from 'infrastructure/js/components/Wizard/wizard';
import UploadFileStep from '../ImportFiles/SubComponents/uploadFileStep.js';
import ImportFileStep from '../ImportFiles/SubComponents/importFileStep.js';
import SelectNestsStep from './SubComponents/SelectNestsStep';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Label from 'infrastructure/js/components/Label/label.js';
import MaterialHelper from '../../../utils/materialHelper';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
require('./importNestsWizard.scss');

export default class ImportNestsWizard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.wizards.import.files.reportedCutsAndKits.');
  }

  getMaterialBodyTopSection = () => {
    if (!this.props.selectedRoll) {
      return null;
    }
    return (
      <div className="body-top-section title">
        {this.getMaterialContent()}
      </div>
    );
  };

  getMaterialContent(){
    let item = this.props.selectedRoll;
    let rollTitle = this.labels.get("title.roll", undefined, {rollId: item.businessId});
    return(
      <div>
        <label className="title material-title">{this.labels.get('title.material')}: {MaterialHelper.getMaterialFullLabel(item.material.materialName, item.material.businessId)}</label>
        <Label text={rollTitle} tooltip={item.businessId}/>
      </div>
    );
  }

  getImportFileBodyTopSection = () => {
    if(!this.props.sValidatedFileData || !this.props.selectedRoll){
      return null
    }
    let length = UnitHelper.serverValueToUserValue(unitTypes.LENGTH,this.props.sValidatedFileData.lengthSum, 2);
    let rollTitle = this.labels.get("title.lengthcut", undefined, {length});
    return (
      <div className="body-top-section title">
        {this.getMaterialContent()}
        <label className="title">{rollTitle}</label>
      </div>
    );
  };

  getSteps = ()=> {
    return {
      'step_1':   <UploadFileStep bodyTopSection={this.getMaterialBodyTopSection()}/>,
      'step_2':   <SelectNestsStep bodyTopSection={this.getMaterialBodyTopSection()}/>,
      'step_3':   <ImportFileStep bodyTopSection={this.getImportFileBodyTopSection()}/>
    };
  };

  render() {
    return (
      <PL_Wizard wizardName="IMPORT_NESTS_WIZARD" steps={this.getSteps()} {...this.props} form="wizard_form_IMPORT_NESTS"/>
    );
  }
}











import * as toolsService from '../../../services/Tools/toolsService';
import * as boHelper      from '../batchOperationDialogActionsHelper';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('REPORTMAINTENANCE_DIALOG')
};
////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('REPORTMAINTENANCE_DIALOG')
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
const config = {
  submitMethod: toolsService.reportMaintenance,
  dialogReduxStorageName: 'reportMaintenanceDialog'
};

export let api = {
  ...boHelper.getApiActions(actions, config)
};



/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api)
};








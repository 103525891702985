import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as weightWizardActions } from '../../../actions/AssetPage/weightWizardActions';
import { jsxActions as assetEditorActions } from '../../../actions/AssetPage/assetPageEditorActions';
import weightWizard from '../../../components/AssetPage/WeightWizard/weightWizard';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(weightWizardActions, dispatch),
      assetEditorActions: bindActionCreators(assetEditorActions, dispatch),
    }
  }
}

let WeightWizardContainer = connect(

  (state) => {
    return {
      loading: state.assetPage.getIn(['weightWizard', 'loading']),
      errorMessage: state.assetPage.getIn(['weightWizard', 'errorMessage']),
      parentComponent: state.assetPage.getIn(['weightWizard', 'parentComponent']),
      rollId: state.assetPage.getIn(['weightWizard', 'rollId']),
      isFetchWeightLoading: state.assetPage.getIn(['weightWizard', 'isFetchWeightLoading']),
      weight: state.assetPage.getIn(['weightWizard', 'weight']),

    };
  },
  mapDispatchToProps
)(weightWizard);

export default WeightWizardContainer;

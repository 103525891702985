import { fromJS } from 'immutable';
import { List , Map } from 'immutable';
import { actionTypes as pickListPageActionTypes }    from '../../actions/PickListPage/pickListPageActions.js';
import { actionTypes as pickListPageEditorActions }  from '../../actions/PickListPage/pickListPageEditorActions.js';

let defaultState = {
  data: {
    pickList: Map({}),
    pickListEditorData: {
      optionsPickListDestinations: [],
    },
  },
  loading: false,
};


export default function(state = fromJS(defaultState), action) {

  switch(action.type) {

    case pickListPageActionTypes.PICKLIST_PAGE_FETCH_DATA_FINISHED:
      state = state.setIn(['data', 'pickList'], action.payload);
      return state;

    case pickListPageActionTypes.PICKLIST_PAGE_SET_BUSY:
      return state.set('loading', action.payload);

    case pickListPageActionTypes.ASSETPAGE_CLEAR_DATA:
      state = state.setIn(['data', 'pickList', 'id'], null)
                   .setIn(['data', 'pickList', 'status'], null)
      return state;

    case pickListPageActionTypes.PICKLIST_PAGE_UNMOUNT:
      return fromJS(defaultState);

    case pickListPageEditorActions.PICKLIST_PAGE_EDITOR_FETCH_DESTINATIONS_READY:
      return state.setIn(['data', 'pickListEditorData', 'optionsPickListDestinations'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.name} })
      );

    default:
      return state;
  }
}










import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router';
import classNames from 'classnames';
import NavigatorMenu from '../../../SideNavigator/navigatorMenu';

require('./page.scss');

export default class Page extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let { sideNavigatorMenuItems, className, routes, selectedMenuItemId, actions } = this.props;

    return (
      <div className={classNames('administration-page-container', className)}>
        <NavigatorMenu
          menuItems={sideNavigatorMenuItems}
          expandedItem={selectedMenuItemId}
          actions={{ setExpandedItem: actions.onSelectedMenuItemChanged }}
        />

        <div className="administration-page">
          <Switch>{routes}</Switch>
        </div>
      </div>
    );
  }
}

Page.defaultProps = {
  sideNavigatorMenuItems: [],
  selectedMenuItemId: undefined,
  className: '',
};

Page.propTypes = {
  routes: PropTypes.array.isRequired,
  sideNavigatorMenuItems: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  selectedMenuItemId: PropTypes.string,
  className: PropTypes.string,
};

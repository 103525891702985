import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Label from 'infrastructure/js/components/Label/label.js';
import Overlay from 'infrastructure/js/components/Overlay/overlay';

require('./updateAttributesPreviewStep.scss');

export default class UpdateAttributesPreviewStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.allLabels = createLabelHelper('');

    this.state = {
      assetsData: [],
      selectedAssetIndex: -1,
      selectedAssetAttributes: null,
    };

    this.props.registerOnEnter(() => {
      this.props.setNextButtonEnabled(this.isNextButtonEnabled(this.state.assetsData));
    });

    this.props.registerOnExit(() => {
    })
  }

  componentDidMount() {
    if (this.props.setRef) {
      this.props.setRef(this);
    }
  }

  componentDidUpdate(prevProps) {
    let {attributesPreviewData} = this.props;

    if (attributesPreviewData && !prevProps.attributesPreviewData) {
      this.setState(
        {
          assetsData: this.convertToAssetsData(attributesPreviewData),
          selectedAssetIndex: attributesPreviewData && attributesPreviewData.length > 0 ? 0 : -1,
          selectedAssetAttributes: attributesPreviewData && attributesPreviewData.length > 0 ? attributesPreviewData[0].attributes : null,
        });
    }

    else if (this.isAttributesChanged(attributesPreviewData, prevProps.attributesPreviewData) ) {
        this.setState(
          {
            assetsData: this.updateAssetsData(),
            selectedAssetAttributes: this.getAssetAttributesByIndex(this.state.selectedAssetIndex),
          });
      }
  }

  isNextButtonEnabled = (attributesPreviewData) => {
    if (attributesPreviewData && attributesPreviewData.length > 0) {
      let attrs = attributesPreviewData[0].attributes;
      return (attrs && attrs.length > 0);
    }
    return false;
  }

  getSubmitData = () => {
    let data = this.state.assetsData;

    return data ? {selectedAssetsBusinessIds: data.map( (item) => item.assetBusinessId)} : {};
  }

  convertToAssetsData = (attributesPreviewData) => {
    if (attributesPreviewData ) {
      let assetsData = [...attributesPreviewData];
      assetsData.forEach((item, index) => {
        item.visited = (index === 0);
      })

      return assetsData;
    }
    return [];
  }

  updateAssetsData = () => {
    let assetsData = [...this.state.assetsData];
    assetsData.forEach((item, index) => {
      item.attributes = this.getAssetAttributesById(item.assetBusinessId);
    })

    return assetsData;
  }

  isAttributesChanged = (newData, prevData) => {
    if (newData && newData.length > 0 && prevData && prevData.length > 0) {
      let newAttrs = newData[0].attributes;
      let prevAttrs = prevData[0].attributes;

      if (newAttrs && prevAttrs ) {
        if (newAttrs.length !== prevAttrs.length) {
          return true;
        }
        let newAttrsIds = newAttrs.map((item) => this.getItemId(item));
        let prevAttrsIds = prevAttrs.map((item) => this.getItemId(item));

        if (!newAttrsIds.every((item) => prevAttrsIds.includes(item))) {
          return true;
        }
      }
    }
    return false;
  }

  getItemId = (item) => {
    if (item && item.attribute) {
      return (item.attribute.isAdditionalField && item.attribute.additionalField) ? item.attribute.additionalField.id : item.attribute.id;
    }
    return -1;
  }

  getAssetAttributesByIndex = (index) => {
    if (index >= 0) {
      let asset = this.state.assetsData[index];
      return asset ? this.getAssetAttributesById(asset.assetBusinessId) : [];
    }
    return [];
  }

  getAssetAttributesById = (assetBusinessId) => {
    if (assetBusinessId) {
      let asset = this.props.attributesPreviewData.find((item) => assetBusinessId === item.assetBusinessId);
      return asset ? asset.attributes : [];
    }
    return [];
  }

  onItemClick = (item, index) => {
    let items = [...this.state.assetsData];
    if (items[index]) {
      items[index].visited = true;
    }
    this.setState(
      {
        assetsData: items,
        selectedAssetIndex: index,
        selectedAssetAttributes: item && item.assetBusinessId ? this.getAssetAttributesById(item.assetBusinessId) : null,
      })
  }

  onItemRemove = (index, e) => {
    e.stopPropagation();
    e.preventDefault();

    let newItems = [...this.state.assetsData];
    newItems.splice(index, 1);
    let newState = {assetsData: newItems};

    let {selectedAssetIndex} = this.state;
    if (index === selectedAssetIndex) {
      let isLastItem = (selectedAssetIndex === this.state.assetsData.length - 1);
      newState.selectedAssetIndex = isLastItem ? selectedAssetIndex - 1 : selectedAssetIndex;
      newState.selectedAssetAttributes = this.getAssetAttributesByIndex(isLastItem ? selectedAssetIndex - 1 : selectedAssetIndex + 1) ;
      if (newState.selectedAssetIndex >= 0 && newState.assetsData[newState.selectedAssetIndex]) {
        newState.assetsData[newState.selectedAssetIndex].visited = true;
      }
    }
    else if (index < selectedAssetIndex) {
      newState.selectedAssetIndex = selectedAssetIndex - 1;
    }
    this.setState(newState);

    if (newState && newState.assetsData && newState.assetsData.length === 0) {
      this.props.setNextButtonEnabled(false);
    }

  }

  renderAttributes = (attributes) => {
    if (attributes) {
      let res = attributes.map((item, index) => {
        let {attribute} = item;
        let attrLabel = attribute.isAdditionalField ? attribute.additionalField.displayName : attribute.property ? this.allLabels.get(attribute.property.labelKey) : '';
        return (
          <div className="attribute-line" key={'attr-' + index}>
            <Label text={attrLabel}/>
            <Label text={(item.value || item.value === 0) ? item.value.toString() : ' '}/>
          </div>
        );
      })
      return (
          <div className="attributes-preview-list">
            {res}
        </div>);
    }
  }

  renderOverlay = () => {
    let {assetsData, selectedAssetAttributes} = this.state;
    let text = '';

    if (!selectedAssetAttributes || selectedAssetAttributes.length === 0) {
      text = this.props.labels.get('previewStep.noAttributesToUpdate');
    }
    if (!assetsData || assetsData.length === 0 ) {
      text = this.props.labels.get('previewStep.noAssetsToUpdate');
    }

    return text ? <Overlay.Label text={text}/> : null;
  }

  renderAssetsList = () =>  {
    let assets = this.state.assetsData.map((item, index) => {
      let isSelectedItem = this.state.selectedAssetIndex === index;

      return (
        <div key={'asset-' + item.assetBusinessId + '-' + index}
             className={cn('asset-list-item', {'selected': isSelectedItem}, {'visited': item.visited })}
             onClick={(e) => this.onItemClick(item, index)}
        >
          <span className={isSelectedItem || item.visited ? 'fa fa-circle' : 'fa fa-circle-o'} aria-hidden="true"/>
          {(!!item.assetBusinessId) ? <Label text={item.assetBusinessId} className={'item-data'}/> : <div className={'item-data'}/>}
          <span className="pl pl-x-input-close remove-btn" onClick={(e) => this.onItemRemove(index, e)}/>
        </div>
      )});

    return (
      <div className="assets-list-container">
        <div className="assets-list">
          {assets}
        </div>
      </div>
    )
  }

  renderAssetAttributes = () => {
    let {assetsData, selectedAssetAttributes} = this.state;
    let hasItemsToUpdate = (selectedAssetAttributes && selectedAssetAttributes.length > 0 && assetsData && assetsData.length > 0);

    return (
      <div className="attributes-preview-container">
        {hasItemsToUpdate ? this.renderAttributes(selectedAssetAttributes) : this.renderOverlay()}
      </div>);
  }

  render() {

    let {attributesPreviewData} = this.props;
    if (!attributesPreviewData) {
      return null;
    }

    return (
      <div className="update-attributes-preview-step">
        {this.renderAssetsList()}
        {this.renderAssetAttributes()}
      </div>
    );
  }
}

UpdateAttributesPreviewStep.propTypes = {
  attributesPreviewData : PropTypes.array,
  labels: PropTypes.object.isRequired
};



import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AttachmentTab from '../../components/AssetPage/AttachmentTab/attachmentTab.js';
import {gridsNames} from '../../enums/gridsNames';
import { jsxActions as attachmentGridActions } from '../../actions/AssetPage/attachmentTabGridActions';
import { jsxActions as assetPageActions }  from '../../actions/AssetPage/assetPageActions';
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      attachmentGridActions: bindActionCreators(attachmentGridActions, dispatch),
      assetPageActions: bindActionCreators(assetPageActions, dispatch)
    }
  }
}

let AttachmentTabContainer = connect(
  (state) => {
    let attachmentTabGrid = state.grid.get(gridsNames.ASSET_PAGE_ATTACHMENT) ? state.grid.get(gridsNames.ASSET_PAGE_ATTACHMENT) : null;
    return {
      sAttachmentTabGrid:  attachmentTabGrid
    };
  },
  mapDispatchToProps
)(AttachmentTab);

export default AttachmentTabContainer;





import Network from '../../../../infrastructure/js/modules/network';

export function fetchTags() {
  return Network.get('tags/getAvailableTags');
}

export function getTagByKey(tagKey) {
  return Network.get(`tags/key/${tagKey}`, {}, { ignore404NotFound: true });
}

export function createTag(data) {
  return Network.post('tags', data);
}

export function tagSearch(searchValue) {
  Network.get('/tags/search/items', { name: searchValue });
}

import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {isToolRequired} from '../../../../../../utils/toolRequiredHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

require('./scheduleTaskDialog.scss');

class ScheduleTaskDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.scheduler.operations.dialog.assignTask.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isNoneOperationalTask = this.itemToEdit && this.itemToEdit.taskType === 'NON_OPERATIONAL';
  }

  componentDidMount() {
    if(this.isNoneOperationalTask){
      let initialValues = {
        stationName: {value: this.itemToEdit.resource.id, label: this.itemToEdit.resource.name},
      };
      this.props.initialize(initialValues);
    }
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.assign'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.props.pristine ||
                    this.props.sData.get('loading') ||
                    this.props.sData.get('hasError') ||
                    (this.props.sData.get('showIgnoreValidationCheckbox') &&
                      !this.props.sData.get('isIgnoreValidationWarnings') )
          )
        }
      ]
    };
  }

  getStations() {
    if(this.itemToEdit && this.itemToEdit.taskType === 'NON_OPERATIONAL'){
      return [{value: this.itemToEdit.resource.id, label: this.itemToEdit.resource.name, data: this.itemToEdit.resource}];
    }
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.stations ? dialogData.stations : [];
  }

  getTools() {
    if(this.itemToEdit && this.itemToEdit.taskType === 'NON_OPERATIONAL'){
      return [];  //TODO: L WHAT should be displayed???
    }
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.tools ? dialogData.tools : [];
  }

  onSubmit = (data) => {
    if (data) {
      let newData = {
        resourceId: data.stationName.value,
        startTime: DateTimeHelper.ConvertFromDate(data.startDateTime),
        toolId: data.toolId?.value || null,
        ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
          data.footerValidationCheckbox : false,
      };
      this.props.actions.submit(this.itemToEdit.id, newData, this.props.sSchedulerEngineInstance);
    }
  };

  onHide =() => {
    this.props.actions.hide();
  };

  renderToolSection = () => {
    if (!this.isNoneOperationalTask && isToolRequired(this.itemToEdit)) {
      return (
        <InputSection label={this.labels.get('tool')} htmlFor="toolId" className="inline left-side">
          <Combobox id="toolId" name="toolId" options={this.getTools()}/>
        </InputSection>
      );
    }
    return null;
  }

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  render() {
    return (
      <Dialog
        id="assign-scheduler-task-dialog"
        className="assign-scheduler-task-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('station') + '*'} htmlFor="stationName" className="inline left-side">
          <Combobox id="stationName" name="stationName" options={this.getStations()}
                    allowNewOption={true}
                    validate={Validation.required}
                    isDisabled={this.isNoneOperationalTask}
          />
        </InputSection>

        <InputSection label={this.labels.get('startTime') + '*'} htmlFor="startDate" className="inline right-side">
          <DatePicker id="startDateTime"
                      name="startDateTime"
                      showTimeSelect
                      dateFormat={DateTimeHelper.getDateTimeFormat()}
                      popperPlacement="auto"
                      timeIntervals={10}
                      isClearable={true}
                      fixedHeight
                      preventOpenOnFocus={true}
                      validate={[Validation.required, Validation.date]}
          />
        </InputSection>

        {this.renderToolSection()}

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'scheduleTaskDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(ScheduleTaskDialog);

ScheduleTaskDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  sSchedulerEngineInstance : PropTypes.object.isRequired,
};



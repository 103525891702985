import React from 'react';
import PropTypes from 'prop-types';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';

require('./securityMessageDialog.scss');

class SecurityMessageDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAgree: false
    };
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.security.dialog.');
  }

  componentDidMount() {
    this.initializeValues();
  }

  initializeValues = () => {
    this.setState({
      isAgree: false
    });
    let initialValues = {isAgree: false};
    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('decline', 'DECLINE'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: 'submit',
          text: this.dialogLabels.get('continue', 'CONTINUE'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: !this.state.isAgree
        }
      ]
    };
  }

  onSubmit = () => {
    this.props.actions.submit();
  };

  onExit = () => {
    this.initializeValues();
  };

  onIsAgreeCheckboxClick = () => {
    this.setState({isAgree: !this.state.isAgree});
  };

  renderLink() {
    let link = this.props?.loggedInUserData?.loggedInOrg?.orgPreferences?.userAgreementMessageLink;
    if (!link) {
      return null;
    }

    return (<a href={link}
               className="security-message-link pointer"
               target="_blank">
      {link}
    </a>)
  }

  render() {
    return (
      <Dialog
        id="security-message-dialog"
        className="security-message-dialog"
        titleText={this.props?.loggedInUserData?.loggedInOrg?.orgPreferences?.userAgreementMessageHeader}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onExit={this.onExit}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}>

        <MessageDialog.MessageArea
          key={'confirmMessageDataRow'}
          text={this.props?.loggedInUserData?.loggedInOrg?.orgPreferences?.userAgreementMessageContent}
        />

        {this.renderLink()}

        <Checkbox className="agree-checkbox" name="isAgree" id="isAgree" label={this.labels.get('acknowledge', 'I agree to the terms and conditions')}
                  onClick={this.onIsAgreeCheckboxClick}/>
      </Dialog>
    );
  }
}

SecurityMessageDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'securityMessageDialog',
  }
)(SecurityMessageDialog);




import React from 'react';

import WoAlertPopover from '../../Layout/WoAlertPopover/woAlertPopover';

require('./woAlertPopoverCell.scss');

export default class WoAlertPopoverCell extends React.PureComponent {

  render() {
    let {value} = this.props;
    return (<div className="wo-alert-popover-cell">
      <WoAlertPopover value={value} />
    </div>);
  }
}










import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
export function isWorkOrderArchived(workOrderStatus) {
  if (workOrderStatus) {
    let status = workOrderStatus.toUpperCase();
    return (
      status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED') ||
      status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('DISQUALIFIED') ||
      status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('CANCELED')
    );
  }
  return false;
}

export function isWorkOrderActivated(workOrderStatus) {
  if(!PermissionManager.hasEditAssetDetailsPermissions()){
    return false;
  }
  if (workOrderStatus) {
    let status = workOrderStatus.toUpperCase();
    return (
      status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('ACTIVE') ||
      status === getEnumValue(enumTypes.WORK_ORDER_STATUS)('IN_PROGRESS')
    );
  }
  return false;
}

import { Map, List } from 'immutable';
import defaultDialogReducer from '../defaultDialogReducer';
import { dialogsNames } from '../../enums/dialogsNames';
import { actionTypes as actionTypes } from '../../actions/LocationPage/CuttingView/CutAndKitDialog/CutAndKitDialogActions';
import { actionTypes as kitToWorkOrdersActionTypes } from '../../actions/LocationPage/CuttingView/CutAndKitDialog/kitToWorkOrdersActions.js';
import {enumTypes, getEnumValue} from '../../utils/enumHelper';

let defaultState = {
  show: false,
  loading: false,
  preselectedAssets: List(),
  rolls: [],
  rollsInLocation: [],
  kits: [],
  kitTypes: [],
  dialogData: null,
  addedWOs: List(),
  kitFieldGeneratingID: null,
  showSubmitWizardConfirmation: false,
  numOfSucceededWorkOrders: null,
  numOfFailedWorkOrders: null,
  failedWorkOrdersBusinessIds: null,
  createdKitsIds: null
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.CUT_AND_KIT_DIALOG_SHOW: {
      return state.set( 'preselectedAssets',  _convertDataToDialogItem(action?.payload) || List())
        .set('origin', action?.payload?.origin)
        .set('addedWOs', List())
        .set('show', true);
    }

    case actionTypes.CUT_AND_KIT_DIALOG_SUBMIT_WIZARD_CONFIRMATION_SHOW: {
      return state.set( 'showSubmitWizardConfirmation', action?.payload?.show)
        .set('numOfSucceededWorkOrders', action?.payload?.numOfSucceededWorkOrders)
        .set('numOfFailedWorkOrders', action?.payload?.numOfFailedWorkOrders)
        .set('failedWorkOrdersBusinessIds', action?.payload?.failedWorkOrdersBusinessIds)
        .set('createdKitsIds', action?.payload?.createdKitsIds)
    }
    case actionTypes.CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_STARTED: {
      return state.set('loading', true);
    }
    case actionTypes.CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_FINISHED: {
      return state.set('loading', false)
        .set('rolls', action.payload.rolls)
        .set('rollsInLocation', action.payload.rollsInLocation)
        .set('allWOs', action.payload.allWOs)
        .set('kits', action.payload.kits)
        .set('kitTypes', action.payload.kitTypes);
    }
    case actionTypes.CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_FAILED: {
      return Map(defaultState);
    }

    case kitToWorkOrdersActionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_ADD_WO: {
      return state.update('addedWOs', addedWOs => {
        return addedWOs.push(null);
      });
    }
    case kitToWorkOrdersActionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_SELECT_WO: {
      return state.update('addedWOs', (addedWOs) => {
        return addedWOs.set(action.payload.index, action.payload.workOrder)
      });
    }

    case kitToWorkOrdersActionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_REMOVE_WO: {
      return state.update('addedWOs', (addedWOs) => {
        return addedWOs.remove(action.payload);
      });
    }

    case kitToWorkOrdersActionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_STARTED: {
      return state.set('kitFieldGeneratingID', action.payload);
    }
    case kitToWorkOrdersActionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_FINISHED: {
      return state.set('kitFieldGeneratingID', null);
    }
    case kitToWorkOrdersActionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_FAILED: {
      return state.set('kitFieldGeneratingID', null);
    }


    default:
      return defaultDialogReducer(
        state,
        action,
        dialogsNames.CUT_AND_KIT_DIALOG,
        defaultState
      );
  }
}

////////////////////////////////////////////////////
function _convertDataToDialogItem(data) {
  if(data?.preselectedAssets?.size > 0){

    if(data?.preselectedAssetsType === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')){
      return data.preselectedAssets.map(item => ({
        roll: {
          assetsValue: {
            value: item?.id,
            label: item?.businessId,
            data: item
          }
        }
      }));
    }

    if(data?.preselectedAssetsType === getEnumValue(enumTypes.OBJECT_TYPE)('WO')){
      return List([{
        affectedWOs: {
          assetsValue: data.preselectedAssets.map(item => ({
            value: item?.id,
            label: item?.businessId,
            data: item
          })).toJS()
        }
      }])
    }

  }
}

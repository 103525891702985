import Network from 'infrastructure/js/modules/network';
import * as assetsService         from '../../../services/Assets/assetService';
import * as lotsService from '../../../services/Lots/lotsService';
import * as projectsService from '../../../services/Projects/projectsService';
import * as materialsService from '../../../services/Materials/materialsService';
import * as manufacturersService from '../../../services/Manufacturers/manufacturersService';
import * as toolsService from '../../../services/Tools/toolsService';
import * as rollsService from '../../../services/Rolls/rollsService';
import * as groupsService from '../../../services/Groups/groupsService';
import * as boHelper              from '../batchOperationDialogActionsHelper';
import * as locationsService from '../../../services/Locations/locationsService';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import * as additionalFieldsService from '../../../services/Administration/additionalFieldsService';
import {dialogsNames} from '../../../enums/dialogsNames';

const config = {
  submitMethod: assetsService.editAssetAttributes,
  dialogReduxStorageName: 'editAssetAttributesDialog'
};

/////////////////////////////////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes(dialogsNames.EDITASSETATTRIBUTES_DIALOG),
  EDITASSETATTRIBUTES_DIALOG_FETCH_MATERIALS_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_MATERIALS_READY',
  EDITASSETATTRIBUTES_DIALOG_FETCH_MANUFACTURERS_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_MANUFACTURERS_READY',
  EDITASSETATTRIBUTES_DIALOG_FETCH_LOCATIONS_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_LOCATIONS_READY',
  EDITASSETATTRIBUTES_DIALOG_FETCH_LOTS_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_LOTS_READY',
  EDITASSETATTRIBUTES_DIALOG_FETCH_PROJECTS_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_PROJECTS_READY',
  EDITASSETATTRIBUTES_DIALOG_FETCH_INSPECTION_STATUSES_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_INSPECTION_STATUSES_READY',
  EDITASSETATTRIBUTES_DIALOG_FETCH_TOOL_TYPES_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_TOOL_TYPES_READY',
  EDITASSETATTRIBUTES_DIALOG_FETCH_GROUP_TYPES_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_GROUP_TYPES_READY',
  EDITASSETATTRIBUTES_DIALOG_FETCH_ADDITIONAL_FIELDS_READY: 'EDITASSETATTRIBUTES_DIALOG_FETCH_ADDITIONAL_FIELDS_READY'
};

/////////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('EDITASSETATTRIBUTES_DIALOG'),
  fetchMaterialsReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_MATERIALS_READY, payload: payload };
  },
  fetchManufacturersReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_MANUFACTURERS_READY, payload: payload };
  },
  fetchLocationsReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_LOCATIONS_READY, payload: payload };
  },
  fetchLotsReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_LOTS_READY, payload: payload };
  },
  fetchProjectsReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_PROJECTS_READY, payload: payload };
  },
  fetchInspectionStatusesReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_INSPECTION_STATUSES_READY, payload: payload };
  },
  fetchToolTypesReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_TOOL_TYPES_READY, payload: payload };
  },
  fetchGroupTypesReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_GROUP_TYPES_READY, payload: payload };
  },
  fetchAdditionalFieldsReady: function(payload) {
    return {type: actionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_ADDITIONAL_FIELDS_READY, payload: payload };
  },
};

/////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {
  ...boHelper.getApiActions(actions, config),

  fetchMaterials: function (dispatch, getState) {
    return function () {
      materialsService.fetchActiveMaterials()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Materials Failed");
            return {success: false};
          }
          dispatch(actions.fetchMaterialsReady(response));
        });
    };
  },
  fetchManufacturers: function (dispatch, getState) {
    return function () {
      manufacturersService.fetchManufacturers()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Manufacturers Failed");
            return {success: false};
          }
          dispatch(actions.fetchManufacturersReady(response));
        });
    };
  },
  fetchLocations: function (dispatch, getState) {
    return function () {
      locationsService.fetchActiveLocations()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Locations Failed");
            return {success: false};
          }
          dispatch(actions.fetchLocationsReady(response));
        });
    };
  },
  fetchLots: function (dispatch, getState) {
    return function () {
      lotsService.fetchLots()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Lots Failed");
            return {success: false};
          }
          dispatch(actions.fetchLotsReady(response));
        });
    };
  },
  fetchProjects: function (dispatch, getState) {
    return function () {
      projectsService.fetchActiveProjects()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Projects Failed");
            return {success: false};
          }
          dispatch(actions.fetchProjectsReady(response));
        });
    };
  },
  fetchInspectionTypes: function (dispatch, getState) {
    return function () {
      rollsService.fetchInspectionStatuses()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Inspection Statuses Failed");
            return {success: false};
          }

          let payload = [];
          if (response.data && response.data.items) {
            let labels = createLabelHelper('');
            payload = response.data.items.map((obj) => {
              return {value: obj.name, label: labels.get(obj.labelKey)}
            })
          }
          dispatch(actions.fetchInspectionStatusesReady(payload));
        });
    };
  },
  fetchToolTypes: function (dispatch, getState) {
    return function () {
      toolsService.fetchToolTypes()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Fetch Tool types failed');
            return {success: false};
          }
          dispatch(actions.fetchToolTypesReady(response));
        });
    };
  },
  fetchGroupTypes: function (dispatch, getState) {
    return function () {
      groupsService.getActiveGroupTypes()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Fetch group types failed', response);
            return {success: false};
          }
          dispatch(actions.fetchGroupTypesReady(response));
        });
    };
  },
  fetchAdditionalFields: function(dispatch, getState) {
    return function(entityType) {
      additionalFieldsService.fetchAdditionalFields({entityType: entityType})
        .then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error("Fetch additional Fields Failed");
            return {success: false};
          }
          dispatch(actions.fetchAdditionalFieldsReady(response));
      });
  };
},


};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),

  fetchMaterials: function () {
    return function (dispatch, getState) {
      api.fetchMaterials(dispatch, getState)();
    };
  },
  fetchManufacturers: function () {
    return function (dispatch, getState) {
      api.fetchManufacturers(dispatch, getState)();
    };
  },
  fetchLocations: function () {
    return function (dispatch, getState) {
      api.fetchLocations(dispatch, getState)();
    };
  },
  fetchLots: function () {
    return function (dispatch, getState) {
      api.fetchLots(dispatch, getState)();
    };
  },
  fetchProjects: function () {
    return function (dispatch, getState) {
      api.fetchProjects(dispatch, getState)();
    };
  },
  fetchInspectionTypes: function () {
    return function (dispatch, getState) {
      api.fetchInspectionTypes(dispatch, getState)();
    };
  },
  fetchToolTypes: function () {
    return function (dispatch, getState) {
      api.fetchToolTypes(dispatch, getState)();
    };
  },
  fetchGroupTypes: function () {
    return function (dispatch, getState) {
      api.fetchGroupTypes(dispatch, getState)();
    };
  },
  fetchAdditionalFields: function (entityType) {
    return function (dispatch, getState) {
      api.fetchAdditionalFields(dispatch, getState)(entityType);
    };
  },
}


import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';
import cn from 'classnames';

require('./ellipsisWithTooltip.scss');

export default class EllipsisWithTooltip extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      hasOverflow: false,
      textContent: null,
      prevChildren: null,
    };
  }


  elementHasOverflow = (el) => {
    if (el) {
      let hasChild = (el.childElementCount === 1);

      //workaround: when rounded scrollWidth === clientWidth, get their fractional values
      if (hasChild && el.scrollWidth === el.clientWidth) {
        let firstChild = el.firstChild;
        if (firstChild) {
          let childRect = firstChild.getBoundingClientRect();
          let elRect = el.getBoundingClientRect();
          return childRect.width > elRect.width;
        }
      }
      return el.scrollWidth > el.clientWidth;
    }
    return false;
  };


  updateOverflow = (e) => {
    const el = e.target;

    let elHasOverflow = this.elementHasOverflow(el);

    this.setState({ hasOverflow: elHasOverflow });

    if (elHasOverflow) {
      if (el.textContent !== this.state.textContent) {
        this.setState({textContent: el.textContent});
      }
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.children !== this.props.children) {
  //     this.setState({
  //       hasOverflow: false,
  //       textContent: null,
  //     })
  //   }
  // }


  static  getDerivedStateFromProps(props, state) {
    if (props.children !== state.prevChildren) {
      return {
        hasOverflow: false,
        textContent: null,
        prevChildren: props.children,
      };
    }
    return null;
  }

  render() {
    const { className, tooltip, tooltipPlacement } = this.props;
    return (

      this.state.hasOverflow ?
        (<Tooltip
            placement={tooltipPlacement ? tooltipPlacement : "top"}
            value={tooltip || this.state.textContent}
            delayShow={500}>
            <div className={cn('ellipsis-with-tooltip', className)} onMouseEnter={this.updateOverflow}>
              {this.props.children}
            </div>
          </Tooltip>
        ) :
        (<div className={cn('ellipsis-with-tooltip', className)} onMouseEnter={this.updateOverflow}>
            {this.props.children}
          </div>
        ));
  }
}

EllipsisWithTooltip.defaultProps = {
};

EllipsisWithTooltip.propTypes = {
  tooltip: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};



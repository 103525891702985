import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import * as materialsService from '../../../services/Materials/materialsService.js';
import * as kitsService from '../../../services/Kits/kitsService';
import assetService from '../../../services/Assets/assetService.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  EDIT_KIT_MATERIALS_DIALOG_SHOW:               'EDIT_KIT_MATERIALS_DIALOG_SHOW',
  EDIT_KIT_MATERIALS_DIALOG_HIDE:               'EDIT_KIT_MATERIALS_DIALOG_HIDE',
  EDIT_KIT_MATERIALS_DIALOG_SUBMIT_IN_PROGRESS: 'EDIT_KIT_MATERIALS_DIALOG_SUBMIT_IN_PROGRESS',
  EDIT_KIT_MATERIALS_DIALOG_SUBMIT_FINISHED:    'EDIT_KIT_MATERIALS_DIALOG_SUBMIT_FINISHED',
  EDIT_KIT_MATERIALS_DIALOG_FETCH_DATA_READY:   'EDIT_KIT_MATERIALS_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.EDIT_KIT_MATERIALS_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.EDIT_KIT_MATERIALS_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.EDIT_KIT_MATERIALS_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.EDIT_KIT_MATERIALS_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function(payload) {
    return {type: actionTypes.EDIT_KIT_MATERIALS_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.show = function(data) {
  return function(dispatch, getState) {
    api.show(dispatch, getState)(data);
  };
};
jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data) {
    api.fetchDialogData(dispatch, getState)();

    dispatch(actions.show(data));
  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function() {
    let promise = materialsService.fetchMaterials();


    return Promise.all([promise]).then((allResults) => {

      let materials = allResults[0].dataList;

      let dialogData = {
        materials: materials.map((obj) => {return {value: obj.id, label: obj.materialName, data: obj}}),
      };

      dispatch(actions.fetchDilaogDataReady(dialogData));
      return dialogData;
    });
  }
};

api.submit = function(dispatch, getState) {
  return function(data, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    kitsService.updateKittedPlies(data).then((response) => {
        dispatch(actions.submitFinished());
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Update kitted materials failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }
        return {success: true};
      });
  };
};


import PropTypes from 'prop-types';
import Item from '../Item';

import './AllItemsList.scss';

const getUniqueValueDefault = (item) => {
  if (typeof item === 'object') {
    return item.value;
  }
  return item;
};

const AllItemsList = ({ items, getUniqueValue, removeItem, ...props }) => {
  return (
    <div className="all-items-list">
      {items?.length > 0 ? (
        <div className="items">
          {items.map((item) => (
            <Item key={getUniqueValue(item)} item={item} onDeleteClick={removeItem} {...props} />
          ))}
        </div>
      ) : (
        <div>{'no items'}</div>
      )}
    </div>
  );
};

AllItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  getUniqueValue: PropTypes.func,
  removeItem: PropTypes.func,
};

AllItemsList.defaultProps = {
  items: [],
  getUniqueValue: getUniqueValueDefault,
  removeItem: () => console.error('missing removeItem function!'),
};

export default AllItemsList;

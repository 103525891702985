import { Map, List} from 'immutable';
import { actionTypes as ArchiveAssetsDialogActionTypes }  from '../../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import {actionTypes as ActivateAssetsDialogActionTypes} from "../../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions";
// import {mapToSearchableMultiSelectAssetData} from '../../../utils/assetHelper';

let defaultState = {
  show: false,
  assets : Map(),
  preselectedAssets: List(),
  entitiesTypes: List(),
  boEntityFilter: '',
  cancelToken: '',
  loading: false,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ? List(action.payload.preselectedAssets) : List())
        .set('entitiesTypes', action.payload && action.payload.entitiesTypes ? List(action.payload.entitiesTypes) : List())
        .set('boEntityFilter', action.payload && action.payload.boEntityFilter ? action.payload.boEntityFilter : '')
        .set('show', true);

    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_HIDE:
      return Map(defaultState);

    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_SUBMIT_FINISHED:
      return state.set('loading', false);

    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_FETCH_ASSETS_READY:
      // return state.setIn(['assets', action.payload.assetType], action.payload.dataList.map(mapToSearchableMultiSelectAssetData) );
      return state.setIn(['assets', action.payload.assetType.toLowerCase()], action.payload.dataList );

    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_SAVE_CANCEL_TOKEN:
      return state.set('cancelToken', action.payload);

    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_SET_VALIDATION_WARNINGS_DATA:
      return state.set('validationMessage', action.payload)
        .set('hasError', !!action.payload.errors)
        .set('showIgnoreValidationCheckbox', !action.payload.errors)
        .set('hasHighSeverityWarning', action.payload.reset ? false : action.payload.hasHighSeverityWarning)
        .set('hasMediumSeverityWarning', action.payload.reset ? false : action.payload.hasMediumSeverityWarning)
        .set('isIgnoreValidationWarnings', false);

    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('showIgnoreValidationCheckbox', false)
        .set('hasHighSeverityWarning', false)
        .set('hasMediumSeverityWarning', false)
        .set('hasError', false)
        .set('isIgnoreValidationWarnings', false);

    case ArchiveAssetsDialogActionTypes.ARCHIVE_ASSETS_DIALOG_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('isIgnoreValidationWarnings', action.payload);

    default:
      return state;
  }

}








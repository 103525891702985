import Network from 'infrastructure/js/modules/network';
import { api as gridApi} from './terminologyViewGridActions';
import { api as editTerminologyDialogApi } from './Dialogs/editTerminologyDialogActions.js';
import * as terminologyService from '../../../services/Administration/terminologyService';
import {api as terminologyViewGridActions} from './terminologyViewGridActions';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  TERMINOLOGY_SETTINGS_VIEW_UPDATE_IN_PROCESS: 'TERMINOLOGY_SETTINGS_VIEW_UPDATE_IN_PROCESS',
  TERMINOLOGY_SETTINGS_VIEW_UPDATE_FINISHED: 'TERMINOLOGY_SETTINGS_VIEW_UPDATE_FINISHED',
  TERMINOLOGY_SETTINGS_VIEW_FETCH_LOCALES_FINISHED: 'TERMINOLOGY_SETTINGS_VIEW_FETCH_LOCALES_FINISHED',
  TERMINOLOGY_SETTINGS_VIEW_FETCH_MACROS_FINISHED: 'TERMINOLOGY_SETTINGS_VIEW_FETCH_MACROS_FINISHED',
  TERMINOLOGY_SETTINGS_VIEW_UPDATE_FILTER_DATA: 'TERMINOLOGY_SETTINGS_VIEW_UPDATE_FILTER_DATA',
  TERMINOLOGY_SETTINGS_VIEW_UPDATE_UNPUBLISHED_DATA_STATUS: 'TERMINOLOGY_SETTINGS_VIEW_UPDATE_UNPUBLISHED_DATA_STATUS',
  TERMINOLOGY_SETTINGS_VIEW_RESET_DATA: 'TERMINOLOGY_SETTINGS_VIEW_RESET_DATA',

};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  updateInProgress: function () {
    return {type: actionTypes.TERMINOLOGY_SETTINGS_VIEW_UPDATE_IN_PROCESS};
  },
  updateFinished: function () {
    return {type: actionTypes.TERMINOLOGY_SETTINGS_VIEW_UPDATE_FINISHED};
  },
  fetchLocalesFinished: function (payload) {
    return {type: actionTypes.TERMINOLOGY_SETTINGS_VIEW_FETCH_LOCALES_FINISHED, payload: payload};
  },
  fetchMacrosFinished: function (payload) {
    return {type: actionTypes.TERMINOLOGY_SETTINGS_VIEW_FETCH_MACROS_FINISHED, payload: payload};
  },
  updateFilterData:function (payload) {
    return {type: actionTypes.TERMINOLOGY_SETTINGS_VIEW_UPDATE_FILTER_DATA, payload};
  },
  updateUnpublishedStatus:function (payload) {
    return {type: actionTypes.TERMINOLOGY_SETTINGS_VIEW_UPDATE_UNPUBLISHED_DATA_STATUS, payload};
  },
  resetState: function (payload) {
    return {type: actionTypes.TERMINOLOGY_SETTINGS_VIEW_RESET_DATA, payload: payload};
  },

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchLocales = function() {
  return function(dispatch, getState) {
   return api.fetchLocales(dispatch, getState)();
  }
};

jsxActions.fetchMacroSetsNames = function(localeId) {
  return function(dispatch, getState) {
    api.fetchMacroSetsNames(dispatch, getState)(localeId);
  }
};

jsxActions.init = function(data) {
  return function(dispatch, getState) {
    api.init(dispatch, getState)(data);
  }
};

jsxActions.reload = function(data) {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)(data);
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

jsxActions.submit = function(data) {
  return function(dispatch, getState) {
    api.submit(dispatch, getState)(data);
  }
};


jsxActions.edit = function (data) {
  return function (dispatch, getState) {
    editTerminologyDialogApi.show(dispatch, getState)(data);
  }
};


jsxActions.resetToDefault = function (data, messageDialogBuilder) {
  return function (dispatch, getState) {
    api.resetToDefault(dispatch, getState)(data, messageDialogBuilder);
  }
};

jsxActions.preview = function (data) {
  return function (dispatch, getState) {
    api.preview(dispatch, getState)(data);
  }
};

jsxActions.applyChanges = function (data, messageDialogBuilder) {
  return function (dispatch, getState) {
    api.applyChanges(dispatch, getState)(data, messageDialogBuilder );
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};




/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchLocales = function(dispatch, getState) {
  return function () {
   return terminologyService.fetchLocales().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to fetch locales', response);
        return {success: false};
      }
      if (response.dataList) {
       let payload = response.dataList.map((item) => {
         return {value: item.id, label: item.language, data: item}
       });
       dispatch(actions.fetchLocalesFinished(payload));

     }
    })
  }
};

api.fetchMacroSetsNames = function(dispatch, getState) {
  return function(localeId) {
    terminologyService.fetchMacroSetsNames({localeId}).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to fetch terminology macro set names', response);
        return {success: false};
      }

      if (response.dataList) {

        let payload = response.dataList.map((item) => {
          return {
            label: item.displayMacroSetName,
            value: item.macroSetNameType
          }
        });

        dispatch(actions.fetchMacrosFinished(payload));
      }
    })
  }
};

api.init = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.updateFilterData(data));
    api.fetchMacroSetsNames(dispatch, getState)(data.localeId);
    api.doUnpublishedLabelsExist(dispatch, getState)(data.localeId);
  }
};

api.reload = function(dispatch, getState) {
  return function(localeId) {
    api.doUnpublishedLabelsExist(dispatch, getState)(localeId);
    terminologyViewGridActions.reload(dispatch, getState)();
  }
};

api.submit = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.updateFilterData(data));
    api.reload(dispatch, getState)(data.localeId);
  }
};


api.doUnpublishedLabelsExist = function(dispatch, getState) {
  return function(localeId) {
    terminologyService.doUnpublishedLabelsExist({localeId}).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to check unpublished terminology labels', response);
        return {success: false};
      }

      if (response.data) {
        let payload = response.data.doUnPublishedLabelsExist;

        dispatch(actions.updateUnpublishedStatus(payload));
      }
    })
  }
};

api.resetToDefault = function(dispatch, getState) {
  return function(data, messageDialogBuilder) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.resetToDefaultAndPublish(dispatch, getState)(data)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
  }
};

api.resetToDefaultAndPublish = function(dispatch, getState) {
  return function (data) {

    terminologyService.resetToDefaultAndPublish(data).then((response) => {
      messageDialogApi.close(dispatch, getState)(); //close previous confirmation message

      if (!Network.isResponseValid(response)) {
        console.error('Failed to reset to default values', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      api.reload(dispatch, getState)(data.localeId);
    });
  }
};


api.preview = function(dispatch, getState) {
  return function(data) {
    let url = window.location.origin + window.location.pathname + '#/' + Network.getQueryString(data);
    let win = window.open(url, '_blank');
    // let win = window.open(url, 'TPO_PREVIEW_MODE');
    win.focus();
  }
};



api.applyChanges = function(dispatch, getState) {
  return function(data, messageDialogBuilder) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.publishTerminologyLabels(dispatch, getState)(data)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
  }
};

api.publishTerminologyLabels = function(dispatch, getState) {
  return function (data) {

    terminologyService.publishTerminologyLabels(data).then((response) => {
      messageDialogApi.close(dispatch, getState)(); //close previous confirmation message

      if (!Network.isResponseValid(response)) {
        console.error('Failed to apply labels', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      api.reload(dispatch, getState)(data.localeId);
    });
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

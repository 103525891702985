import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';


//Style
require('./input.scss');

export default class Input extends React.PureComponent {

  render() {
    return (
      <div  className="input">
        {/*<input onKeyUp={this.props.onChange} type="text" id="filterText" placeholder="Search..."/>*/}
        <FormControl autoFocus={this.props.autoFocus} value={this.props.value}
                     type={this.props.type || "text"} placeholder={this.props.placeholder || ""}
                     onChange={this.props.onChange}
                     onKeyPress={this.props.onKeyPress}
        />
        <i className={"fa fa-" + this.props.classIcon}></i>
      </div>);
  }
}


Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  classIcon: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};



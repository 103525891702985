import moment from 'moment';
import { api as shiftsSchedulerApi } from './weeklyShiftsSchedulerActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { api as shiftSettingsDialogApi } from './Dialogs/shiftSettingsDialogActions';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

export const actionTypes = {
  WEEKLY_SHIFTS_WEEK_START_DATE_CHANGED: 'WEEKLY_SHIFTS_WEEK_START_DATE_CHANGED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  updateWeekStartDate: function (payload) {
    return { type: actionTypes.WEEKLY_SHIFTS_WEEK_START_DATE_CHANGED, payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (resetDate) {
  return function (dispatch, getState) {
    if (resetDate) {
      const weekStartDate = _initializeWeekStartDate();
      dispatch(actions.updateWeekStartDate(weekStartDate));
    }
    return shiftsSchedulerApi.init(dispatch, getState)();
  };
};

jsxActions.redeployTemplate = function (data) {
  return function (dispatch, getState) {
    api.openRedeployTemplateConfirmationDialog(dispatch, getState)(data);
  };
};

jsxActions.updateWeekStartDate = function (newWeekStartDate) {
  return function (dispatch, getState) {
    api.updateWeekStartDate(dispatch, getState)(newWeekStartDate.clone());
    return shiftsSchedulerApi.fetchShiftsSchedulerData(dispatch, getState)();
  };
};

jsxActions.showShiftSettingsDialog = function (data) {
  return function (dispatch, getState) {
    const isHumanCapacityEnabled =
      PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled;

    if (data.isTemplate && !isHumanCapacityEnabled) {
      return;
    }

    return shiftSettingsDialogApi.show(dispatch, getState)(data);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.updateWeekStartDate = function (dispatch, getState) {
  return function (newWeekStartDate) {
    dispatch(actions.updateWeekStartDate(newWeekStartDate));
  };
};

api.openRedeployTemplateConfirmationDialog = function (dispatch, getState) {
  return function (data) {
    const labels = createLabelHelper(
      'mat.administration.matsettings.weeklyShifts.redeployTemplate.confirmation.'
    );

    const dialogLabels = createLabelHelper('mat.dialog.');

    const children = [<MessageDialog.MessageRow key={0} text={labels.get('message')} />];

    let buttons = [
      {
        id: 'cancel',
        text: dialogLabels.get('cancel'),
        action: messageDialogApi.close(dispatch, getState),
        bsStyle: 'default',
      },
      {
        id: 'redeploy',
        text: labels.get('redeploy'),
        action: () => {
          messageDialogApi.close(dispatch, getState)();
          shiftsSchedulerApi.redeployTemplate(dispatch, getState)();
        },
        bsStyle: 'primary',
      },
    ];

    const confirmationConfig = {
      title: labels.get('title'),
      type: 'warning',
      className: '',
      children,
      buttons,
    };

    messageDialogApi.open(dispatch, getState)(confirmationConfig);
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _initializeWeekStartDate() {
  const firstDayOfTheWeek = PermissionManager.getOrgPreferences().startDayOfWeek;
  const _moment = moment();

  // moment is mutable so to prevent changes throughout the app it should be cloned.
  const startOfWeekNum = _moment.clone().isoWeekday(firstDayOfTheWeek).isoWeekday();
  const today = _moment.clone().isoWeekday();

  if (today < startOfWeekNum) {
    return _moment.clone().subtract(1, 'weeks').isoWeekday(startOfWeekNum);
  } else {
    return _moment.clone().isoWeekday(startOfWeekNum);
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

class CreateDemoOrganizationDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.dialog.createDemoOrganization.');
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.isEditMode && !this.isDuplicateMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading'),
        },
      ],
    };
  }

  onSubmit = ({orgName, demoDate}) => {
    const submitData = {
      orgBusinessId: orgName,
      zeroTime: demoDate ? DateTimeHelper.ConvertFromDate(demoDate) : null,
    }

    this.props.actions.submit(submitData, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  maxLength20 = Validation.maxLength(20);

  render() {
    return (
      <Dialog
        id="create-organization-dialog"
        className="create-organization-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
      >
        <InputSection label={this.labels.get('orgname') + '*'} htmlFor="orgName" className="inline left-side no-margin">
          <TextField
            id="orgName"
            name="orgName"
            className="short-textfield"
            validate={[Validation.required, this.maxLength20, Validation.orgNameFormat]}
          />
        </InputSection>

        <InputSection label={this.labels.get('demoDate')} htmlFor="demoDate" className="inline right-side no-margin">
            <DatePicker
              className="hour-picker"
              id="demoDate"
              name="demoDate"
              showTimeSelect
              timeIntervals={1}
              dateFormat={DateTimeHelper.getDateTimeFormat()}
              isClearable={true}
            />
          </InputSection>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'createDemoOrganizationDialog',
})(CreateDemoOrganizationDialog);

CreateDemoOrganizationDialog.defaultProps = {};
CreateDemoOrganizationDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

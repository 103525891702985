import React from 'react';

import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {RfidSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {headerItemsLabels} from '../../../../Common/Header/header';
import AutomationScenariosViewGrid from './AutomationScenariosViewGrid/automationScenariosViewGrid';
import RfidHeader from '../../Common/rfidHeader';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import CreateAutomationScenarioDialog from '../Dialogs/CreateAutomationScenarioDialog/createAutomationScenarioDialog';

require('./automationScenariosView.scss');

class AutomationScenariosView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.automationScenarios.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_AUTOMATED_WORKFLOWS_AUTOMATION_SCENARIOS);
    this.props.actions.init(false);
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  getSelectedDeviceAppProfileId = () => {
    let deviceApp = this.props.sData.get('selectedDeviceApp');

    if (deviceApp && deviceApp.data && deviceApp.data.profilesIDsList && (deviceApp.data.profilesIDsList.length > 0) ) {
      return deviceApp.data.profilesIDsList[0];
    }
    return null;
  };

  handleRemoveAutomationScenarios = () => {
    let selectedItemsIds = this.props.sSelectedRowsData.map((item) => {
      return item.id;
    });

    this.props.actions.onRemoveAutomationScenariosClick(this.getMessageDialogBuilder(), selectedItemsIds);
  }

  getMessageDialogBuilder = () => {
    return (fnCloseDialog, callback) => {
      let title = this.labels.get('remove.confirmation.title');
      let message = this.labels.get('remove.confirmation.message');
      let children =  [<MessageDialog.DataRow key={'confirmMessageDataRow'} label={message} value={''}/>];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'remove',text: this.dialogLabels.get('remove'), action: callback , bsStyle: 'primary'}
      ];
      let messageDialogDescriptor = {title, buttons, children,  className: '', type: 'warning'};
      return messageDialogDescriptor;
    };
  };

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    let deviceAppProfileId = this.getSelectedDeviceAppProfileId();

    return {
      dropdown: {
        name : 'deviceAppDropDown',
        id : 'deviceAppDropDown',
        options: this.props.sData.get('deviceAppsOptions'),
        disabled: this.props.sData.get('deviceAppsOptions').length <= 1,
        changeCallback: this.props.actions.onDeviceAppChanged,
      },
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          disabled: !deviceAppProfileId,
          // disabled: this.props.sData.get('deviceAppsOptions').length === 0,
          action: actions.header.onCreateAutomationScenarioClick,
          actionData: {deviceAppProfileId: deviceAppProfileId}
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditAutomationScenarioClick,
          actionData: {deviceAppProfileId: deviceAppProfileId,
            itemToEdit: selectedRowsData.get(0)}
        },
        {
          id:'remove',
          label: headerItemsLabels.REMOVE,
          icon: 'pl pl-delete-icon',
          disabled: !(hasRows && selectedRowsData.size > 0),
          action: this.handleRemoveAutomationScenarios
        },
      ],
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  renderGrid = () => {
    if(!this.props.sData.get('selectedDeviceApp')) {
      return null;
    }
    return <AutomationScenariosViewGrid actions={this.props.actions.automationScenariosViewGridActions} />

  }


  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();

    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <RfidHeader {...headerItems} change = {this.props.change} selectedDeviceApp={this.props.sData.get('selectedDeviceApp')}/>

        {this.renderGrid()}

        <PL_DialogWrapper dialogComponent={CreateAutomationScenarioDialog}
                          show={this.props.sCreateAutomationScenarioDialogData.get('show')}
                          actions={this.props.actions.createAutomationScenarioDialogActions}
                          sData={this.props.sCreateAutomationScenarioDialogData}
                          deviceAppProfileId={this.getSelectedDeviceAppProfileId()}
                          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}

export default reduxForm({
    form: 'automationScenariosForm'
  }
)(AutomationScenariosView);

AutomationScenariosView.propTypes = {
  sGrid: PropTypes.object,
};

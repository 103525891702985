import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header, { headerItemsLabels } from '../../../Common/Header/header';
import { gridsNames } from '../../../../enums/gridsNames';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';
import { ImportTypes, ImportAcceptFileTypes } from '../../../../enums/importTypes';
import ToolsGrid from './ToolsGrid/toolsGrid';
import { getEnumValue, enumTypes } from '../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import CreateToolDialog from '../Dialogs/CreateToolDialog/createToolDialog';
import ReportMaintenanceDialog from '../../../Dialogs/ReportMaintenanceDialog/reportMaintenanceDialog';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {getLocationAssetTypes} from '../../../../utils/locationHelper';

require('./toolsView.scss');

export default class ToolsView extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.assets.');
  }

  getDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  getMoveAssetsDialogConfig = () => {
    let {sSelectedRowsData, sLocationDetails} = this.props;
    return {
      boEntityFilter: [
        FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.NOT_CONTAINED,
        FetchEntitiesFilters.ON_LOCATION,
      ],
      entitiesTypes: sSelectedRowsData?.size > 0 ? [getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')] :
        getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')),
      preselectedAssets: sSelectedRowsData,
    };
  };

  getBringAssetsDialogConfig = () => {
    let {sLocationDetails} = this.props;
    return {
      boEntityFilter: [
        FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.EXCEPT_LOCATION,
        FetchEntitiesFilters.NOT_CONTAINED,
      ],
      entitiesTypes: getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')),
    };
  };

  getImportConfig = () => {
    return {
      importTypes: [ImportTypes.IMPORT_TOOLS],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: this.onRefreshAll,
      shouldIncludeLocation: false, //import is done from a location , the import file locationId column is optional.
      showPrintLabels: false,
    };
  };

  getImportTagsConfig = () => {
    return {
      importTypes: [ImportTypes.IMPORT_TAGS],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: this.onRefreshAll,
    };
  };

  getAddToGroupDialogConfig = () => {
    let {sSelectedRowsData, sLocationDetails} = this.props;
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      preselectedAssets: this.props.sSelectedRowsData,
      entitiesTypes: sSelectedRowsData?.size > 0 ? [getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')] :
        getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')),
    };
  };

  onRefreshAll = () => {
    this.props.actions?.reloadParentComponent();
  };

  getHeaderItems() {
    let { actions, sHasRows, sSelectedRowsData, sLocationDetails, loading } = this.props;

    let dialogConfig = this.getDialogConfig();
    let importConfig = this.getImportConfig();
    let isLocationActive = sLocationDetails?.isActive;

    let items = {
      title: this.labels.get('title.tools'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          actionData: {gridName: gridsNames.LOCATION_ASSET_TOOLS}
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.onRefreshAll,
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: loading || !isLocationActive,
        },
        {
          id: 'moveTo',
          label: headerItemsLabels.MOVE_TO,
          icon: 'pl pl-move-assets',
          counter: sSelectedRowsData.size,
          action: actions.headerActions.onMenuItemMoveToClick,
          actionData: this.getMoveAssetsDialogConfig(sSelectedRowsData),
          disabled: loading || !isLocationActive,
        },
        {
          id: 'bringHere',
          label: headerItemsLabels.BRING_HERE,
          icon: 'pl pl-bring-assets',
          action: actions.headerActions.onMenuItemBringHereClick,
          actionData: this.getBringAssetsDialogConfig(),
          disabled: loading || !isLocationActive,
        },
      ],

      kebab: {
        disabled: loading || !isLocationActive,
        menuItems: [
          {
            id: 'reportMaintenance',
            label: headerItemsLabels.REPORT_MAINTENANCE,
            disabled: !sHasRows,
            action: actions.headerActions.onReportMaintenanceClick,
            actionData: dialogConfig,
          },

          ...(PermissionManager.hasCreateAssetsPermissions()
            ? [
                {
                  id: 'createTool',
                  label: headerItemsLabels.CREATE_TOOL,
                  action: actions.headerActions.onCreateToolClick,
                  // actionData: this.getDialogConfig
                },
              ]
            : []),

          ...(PermissionManager.hasArchiveAssetsPermissions()
            ? [
                {
                  id: 'archive',
                  label: headerItemsLabels.ARCHIVE,
                  action: actions.headerActions.onMenuItemArchiveClick,
                  actionData: dialogConfig,
                  disabled: !sHasRows,
                },
              ]
            : []),

          {
            id: 'export',
            label: headerItemsLabels.EXPORT_TOOLS_LIST,
            action: actions.headerActions.export,
            actionData: { gridName: gridsNames.LOCATION_ASSET_TOOLS },
            disabled: !sHasRows,
          },

          ...(PermissionManager.hasGroupAssetPermissions() &&
          PermissionManager.getOrgPreferences().groupsEnabled
            ? [
                {
                  id: 'addToGroup',
                  label: headerItemsLabels.ADD_TO_GROUP,
                  disabled: !sHasRows,
                  action: actions.headerActions.onAddToGroupDialogClick,
                  actionData: this.getAddToGroupDialogConfig(),
                },
              ]
            : []),

          ...(PermissionManager.hasEditAttributesPermissions()
            ? [
                {
                  id: 'editAttributes',
                  label: headerItemsLabels.EDIT_ATTRIBUTES,
                  disabled: !sHasRows,
                  action: actions.headerActions.onMenuItemEditAssetAttributesClick,
                  actionData: dialogConfig,
                },
              ]
            : []),

          ...(PermissionManager.hasImportAssetsPermissions()
            ? [
                {
                  id: 'importTools',
                  label: headerItemsLabels.IMPORT_TOOLS,
                  action: actions.headerActions.importItems,
                  actionData: importConfig,
                },
                {
                  id: 'importTags',
                  label: headerItemsLabels.IMPORT_TAGS,
                  action: actions.headerActions.importItems,
                  actionData: this.getImportTagsConfig(),
                },
              ]
            : []),

        ],
      },
    };

    return items;
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="tools-view">
        <Header {...headerItems} />

        <ToolsGrid
          actions={{...this.props.actions.assetsToolsGridActions, ...this.props.actions.fetchEntitiesActions}}
          locationDetails={this.props.sLocationDetails}
          cellAction={this.props.actions.getAssetRelatedAssets}
        />

        <PL_DialogWrapper dialogComponent={ReportMaintenanceDialog}
                          show={this.props.sReportMaintenanceDialogData.get('show')}
                          actions={this.props.actions.reportMaintenanceActions}
                          sData={this.props.sReportMaintenanceDialogData}
                          reloadParentComponent={this.onRefreshAll} />

        <PL_DialogWrapper dialogComponent={CreateToolDialog}
                          show={this.props.sDataCreateTool.get('show')}
                          actions={this.props.actions.createToolActions}
                          sData={this.props.sDataCreateTool}
        />
      </div>
    );
  }
}

ToolsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
  sLocationDetails: PropTypes.object.isRequired,
};

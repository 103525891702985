import React from 'react';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import CuttingGrid      from './CuttingGrid/cuttingGrid.js';
import CancelCutReportDialog from './CancelCutReportDialog/cancelCutReportDialog.js';
import CreateSubRollsDialog from './CreateSubRollsDialog/createSubRollsDialog.js';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import ImportNestsWizardContainer from '../../../containers/Wizards/ImportNests/ImportNestsWizardContainer.js';
import ImportNestsWithRollWizardContainer from '../../../containers/Wizards/ImportNestsWithRoll/ImportNestsWithRollWizardContainer.js';
import ImportNestsMultiRollsWizardContainer from '../../../containers/Wizards/ImportNestsMultiRolls/ImportNestsMultiRollsWizardContainer.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {enumTypes, getEnumValue } from '../../../utils/enumHelper';
import ImportNestsAnyRollsWizardContainer from '../../../containers/Wizards/ImportNestsAnyRolls/ImportNestsAnyRollsWizardContainer';

require('./cuttingView.scss');

export default class CuttingView extends React.PureComponent {
  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.locationpage.view.cutting.');
    this.isCutAndKitOperationAvailable = false;  //currently not supported
  }
  getDialogConfig = () => {
    return {
      preselectedAssets: this.props.sSelectedRowsData,
      parentComponent: this.props.actions.locationPageActions.reload
    };
  };

  onRefreshAll = () => {
    this.props.actions?.locationPageActions?.reload?.();
  };

  hasOperationOfType = ( operationType, operations) => {
    if (operations) {
      let found =  operations.find((op) => {
        return op.operationType.name === operationType;
      });
      return !!found;
    }
    return false;
  };

  getHeaderItems() {

    let {actions, sHasRows, sSelectedRowsData} = this.props;
    let dialogConfig = this.getDialogConfig();

    let loading = this.props.sCuttingGridData ? this.props.sCuttingGridData.get('loading') : true;
    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.cuttingViewActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          disabled: loading,
        },
        {
          id: 'refreshAll',
          label: '',
          icon: 'pl pl-refresh',
          action: this.onRefreshAll,
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: loading,
        },
        {
          id: 'createSubRolls',
          label: headerItemsLabels.CREATE_SUB_ROLLS,
          icon: 'pl pl-roll-icon-blue',
          disabled: (loading || !sHasRows || !sSelectedRowsData || sSelectedRowsData.size !== 1),
          action: actions.headerActions.onCreateSubRollsClick,
          actionData: dialogConfig,
          loading: loading
        },
        ...(this.isCutAndKitOperationAvailable && this.hasOperationOfType(getEnumValue(enumTypes.OPERATION_TYPE)('CUT_&_KIT'), this.props.sLocationOperations) ? [{
            id: 'cutAndKit',
            label: headerItemsLabels.CUT_AND_KIT,
            icon: 'pl pl-cut-icon',
            counter: sSelectedRowsData.size,
            disabled:
              loading ||
              !sHasRows ||
              !sSelectedRowsData ||
              sSelectedRowsData.size === 0,
            action: actions.headerActions.onCutAndKitClick,
            actionData: {
              ...dialogConfig,
              preselectedAssetsType: getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'),
              origin: 'cuttingTab'
            },
            loading: loading,
        }] : [{
          id: 'reportCut',
          label: headerItemsLabels.REPORT_CUT,
          icon: 'pl pl-cut-icon',
          counter: sSelectedRowsData.size,
          disabled: (loading || !sHasRows || !sSelectedRowsData || sSelectedRowsData.size === 0),
          action: actions.headerActions.onReportCutClick,
          actionData: dialogConfig,
          loading: loading
        }])
      ],
      kebab: {
        disabled: loading,
        menuItems: [
          ...(this.isCutAndKitOperationAvailable && this.hasOperationOfType(getEnumValue(enumTypes.OPERATION_TYPE)('CUT_&_KIT'), this.props.sLocationOperations) ? [{
            id: 'reportCut',
            label: headerItemsLabels.REPORT_CUT,
            icon: 'pl pl-cut-icon',
            counter: sSelectedRowsData.size,
            disabled: (loading || !sHasRows || !sSelectedRowsData || sSelectedRowsData.size === 0),
            action: actions.headerActions.onReportCutClick,
            actionData: dialogConfig,
            loading: loading
          }] : [])
        ]
      },
      title: this.labels.get('header.title'),
    };

    let isStandardFlowEnabled = PermissionManager.getOrgPreferences().standardFlowIntegrationWithFoEnabled;
    let isActualFlowEnabled = PermissionManager.getOrgPreferences().actualFlowIntegrationWithFoEnabled;
    let isStandardFlowMultiRollsEnabled = PermissionManager.getOrgPreferences().standardFlowMultiRollsIntegrationWithFoEnabled;
    let isStandardFlowAnyRollsEnabled = PermissionManager.getOrgPreferences().standardFlowMultiRollsRobustCuttingMechanismEnabled;
    let isWeightSupported = PermissionManager.isWeightSupported();

    if(isStandardFlowEnabled || isActualFlowEnabled || isStandardFlowMultiRollsEnabled || isStandardFlowAnyRollsEnabled){
      let actualMenuItem = isActualFlowEnabled ? {
        id: 'importNestsWithRoll',
        label: headerItemsLabels.IMPORT_NESTS_WITH_ROLL,
        action: actions.headerActions.onMenuItemImportNestsWithRollClick,
        actionData: dialogConfig,
        disabled: isWeightSupported
      } : null;

      let standardMenuItem = isStandardFlowEnabled ? {
        id: 'importNests',
        disabled: (sSelectedRowsData.size !== 1) || isWeightSupported,
        label: headerItemsLabels.IMPORT_NESTS,
        action: actions.headerActions.onMenuItemImportNestClick,
        actionData: dialogConfig
      } : null

      let standardMultiRollsMenuItem = isStandardFlowMultiRollsEnabled ? {
        id: 'importNestsMultiRolls',
        disabled: (!sSelectedRowsData.size) || isWeightSupported,
        label: headerItemsLabels.IMPORT_NESTS_MULTI_ROLLS,
        action: actions.headerActions.onMenuItemImportNestMultiRollsClick,
        actionData: dialogConfig
      } : null;

      let standardAnyRollsMenuItem = isStandardFlowAnyRollsEnabled ? {
        id: 'importNestsAnyRolls',
        disabled: (!sSelectedRowsData.size) || isWeightSupported,
        label: headerItemsLabels.IMPORT_NESTS_ANY_ROLLS,
        action: actions.headerActions.onMenuItemImportNestsAnyRollsClick,
        actionData: dialogConfig
      } : null;

      items.kebab.menuItems = [...items.kebab.menuItems, standardMenuItem, standardMultiRollsMenuItem, standardAnyRollsMenuItem, actualMenuItem]
    }

    return items;
  }

  reloadLocationPage = () => {
    if (this.props.actions.locationPageActions && this.props.actions.locationPageActions.reload) {
      this.props.actions.locationPageActions.reload();
    }
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className="location-cutting-view">
        <Header  {...headerItems}/>

        <CuttingGrid actions={{...this.props.actions.cuttingGridActions, ...this.props.actions.fetchEntitiesActions}}/>

        <PL_DialogWrapper dialogComponent={CancelCutReportDialog}
                          show={this.props.sCancelCutReportDialog.get('show')}
                          sData={this.props.sCancelCutReportDialog}
                          actions={this.props.actions.cancelCutReportDialog}/>

        <PL_DialogWrapper dialogComponent={CreateSubRollsDialog}
                          show={this.props.sCreateSubRollsData.get('show')}
                          actions={this.props.actions.createSubRollsDialog}
                          fetchEntitiesActions={this.props.actions.fetchEntitiesActions}
                          sData={this.props.sCreateSubRollsData}
                          reloadParentComponent={this.reloadLocationPage}/>

        <ImportNestsWizardContainer selectedRoll={(this.props.sSelectedRowsData && this.props.sSelectedRowsData.size === 1) ? this.props.sSelectedRowsData.get(0) : null}/>
        <ImportNestsWithRollWizardContainer />
        <ImportNestsMultiRollsWizardContainer />
        <ImportNestsAnyRollsWizardContainer />

      </div>
    );
  }
}



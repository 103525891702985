import * as csvSettingsService from '../../../services/Administration/csvSettingsService';
import { api as messageDialogApi, api as messageDialogAPI } from '../../MessageDialog/messageDialogActions.js';
import Network from '../../../../../infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { _normalize } from './commonCsvSettingsViewActions';
import { enumTypes, getEnumValue } from '../../../utils/enumHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

export const actionTypes = {
  COMMON_BARCODE_SETTINGS_PAGE_SET_BUSY: 'COMMON_BARCODE_SETTINGS_PAGE_SET_BUSY',
  COMMON_BARCODE_SETTINGS_PAGE_FETCH_DATA_READY: 'COMMON_BARCODE_SETTINGS_PAGE_FETCH_DATA_READY',
  COMMON_BARCODE_SETTINGS_PAGE_RESET_DATA: 'COMMON_BARCODE_SETTINGS_PAGE_RESET_DATA',
};

const actions = {

  setBusy(payload) {
    return { type: actionTypes.COMMON_BARCODE_SETTINGS_PAGE_SET_BUSY, payload: payload };
  },

  fetchDataReady(payload) {
    return { type: actionTypes.COMMON_BARCODE_SETTINGS_PAGE_FETCH_DATA_READY, payload: payload };
  },

  resetState: function (payload) {
    return {type: actionTypes.COMMON_BARCODE_SETTINGS_PAGE_RESET_DATA, payload: payload};
  },

}

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.load = function (entityType) {
  return function (dispatch, getState) {
    return api.load(dispatch, getState)(entityType);
  }
};

jsxActions.submit = function (data, entityType, formType, messageDialogBuilder) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, entityType, formType, messageDialogBuilder);
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function (dispatch, getState) {
  return function (entityType) {
    dispatch(actions.setBusy(true));

    let type = { importType: _getImportType(entityType) };
    let promise1 = csvSettingsService.fetchImportPropertiesOptions(type);
    let promise2 = csvSettingsService.fetchImportSettings(type);

    return Promise.all([promise1, promise2]).then((allResults) => {

      dispatch(actions.setBusy(false));

      let invalidResponse = allResults.find((result) => {
        return !Network.isResponseValid(result);
      });
      if (invalidResponse) {
        messageDialogAPI.responseError(dispatch, getState)(invalidResponse);
        console.error('Failed to fetch barcode Settings', invalidResponse);
        return {success: false};
      }

      let labels = createLabelHelper('');
      let barcodeOptions = allResults[0].dataList.map((item) => { return { value: item.id, label: item.isAdditionalField ? item.name : labels.get(item.labelKey), data: item } });
      let barcodeData = {
        settings: _normalize(allResults[1].data.settings),
        mergedPropertiesIndexes: allResults[1].data.mergedPropertiesIndexes,
        delimiter: allResults[1].data.delimiter,
      };
      dispatch(actions.fetchDataReady({ csvOptions: barcodeOptions, csvData: barcodeData }));
      return { success: true };
    });
  }
}

api.submit = function (dispatch, getState) {
  return function (data, entityType, formType, messageDialogBuilder) {

    dispatch(actions.setBusy(true));

    data.importType = _getImportType(entityType);
    data.startingRowNum = 1;

    return csvSettingsService.setImportSettings(data).then((response) => {

      dispatch(actions.setBusy(false));

      if (!Network.isResponseValid(response)) {
        messageDialogAPI.responseError(dispatch, getState)(response);
        return { success: false };
      }

      if (messageDialogBuilder) {
        let messageDialogDescriptor = messageDialogBuilder(
          response.data
        );

        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
      }

      return api.load(dispatch, getState)(entityType);
    });
  }
}

///////////////////////////////////////////////////////////////////////////////////

function _getImportType(entityType) {
  switch (entityType) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      return 'ROLL_BARCODE_SCAN';
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
      return 'SPOOL_BARCODE_SCAN';
    default:
      console.error('_getImportType(): unknown entity type ' + entityType);
  }
}


import React from 'react';

import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {reduxForm} from 'redux-form';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {gridsNames} from '../../../../enums/gridsNames';
import MasterDetailRow from 'infrastructure/js/components/Grid/MasterDetailRow/MasterDetailRow'
import GridHelper from "infrastructure/js/components/Grid/Utils/gridHelper";
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

require('./shipmentsGrid.scss');

class ShipmentsGrid extends React.PureComponent {


    filterConfig = [
      {fieldName: "shipmentBusinessId", filterName: "shipmentBusinessId", getOptions: false},
      {fieldName: "status", filterName: "shipmentStatus", getOptions: true},
      {fieldName: "createdDate.epochDateTime", filterName: "shipmentCreatedDate", getOptions: false},
      {fieldName: "updatedOn.epochDateTime", filterName: "shipmentUpdatedOn", getOptions: false},
      {fieldName: "createdByUser", filterName: "shipmentCreatedByUser", getOptions: true},
    ];

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.locationpage.view.shipments.columns.');
    this.columnsConfig = this.createColumnsConfig();

  }
  getFormattedDate = (data) => {
    return ((data) ?  DateTimeHelper.DateFormat(data * 1000) : '')
  }
  createColumnsConfig = () => {

    return [
      {
        fieldName: 'shipmentBusinessId',
        title: this.labels.get('shipmentId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'shipmentBusinessId',
        fetchConfig: {
          entityType:  EntityPropertyTypes.SHIPMENT_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntitiesActions.fetchEntities
        },
        width: 180,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.businessId
          }
        }
      },
      {
        fieldName: 'status',
        title: this.labels.get('status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'shipmentStatus',
        fetchConfig: {
          entityType: EntityPropertyTypes.SHIPMENT_STATUS,
          filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntitiesActions.fetchEntities
        },
        width: 160,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.status;
          },
        }
      },
      {
        fieldName: 'createdDate.epochDateTime',
        title: this.labels.get('createdDate.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'shipmentCreatedDate',
        isTime: true,
        valueFormatter: (params) => {
          return this.getFormattedDate(params.data?.createdDate?.epochDateTime)
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return ((params.data && params.data.createdDate) ?  DateTimeHelper.DateTimeFormat(params.data.createdDate.epochDateTime * 1000) : '');
          }
        }
      },
      {
        fieldName: 'updatedOn.epochDateTime',
        title: this.labels.get('updatedDate.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'shipmentUpdatedOn',
        isTime: true,
        valueFormatter: (params) => {
          return this.getFormattedDate(params.data?.updatedOn?.epochDateTime)
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return ((params.data && params.data.updatedOn) ?  DateTimeHelper.DateTimeFormat(params.data.updatedOn.epochDateTime * 1000) : '');
          }
        }
      },
      {
        fieldName: 'createdByUser',
        title: this.labels.get('createdBy.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'shipmentCreatedByUser',
        fetchConfig: {
          entityType: EntityPropertyTypes.SHIPMENT_STATUS,
          filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntitiesActions.fetchEntities
        },
        width: 180,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.createdByUser
          }
        }
      },
      {
        fieldName: 'shipmentDocLink',
        title: this.labels.get('docLink.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          sortable: false,
          valueGetter: (params) => {
            return params.data.fileName;

          }
        }
      },


    ]
  }

  getRowHeight = (params) => {

    let rowHeight = 64;
    //check if row is a master detail row (the return height is for the detail row)
    //then check if need more then a single row
    if (params.node && params.node.detail && params.data && params.data.extendedDetails) {
      let rowCount = Math.ceil(params.data.extendedDetails.length / 4);
      return rowCount > 0 ? rowHeight * rowCount : rowHeight;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="shipments-grid">
        <Grid gridName={gridsNames.LOCATION_SHIPMENTS}
              columnsConfig={this.columnsConfig}
               actions={this.props.actions.shipmentsGridActions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  checkboxSelection: true,
                  masterDetailRowComponent: MasterDetailRow,
                  isMasterDetail:false
                }
              }
          >
        </Grid>
      </div>
    );
  }
}


export default reduxForm({
    form: 'ShipmentsGrid'
  }
)(ShipmentsGrid);







import React from 'react';

import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Normalize from "../../../../../../infrastructure/js/components/controls/controlsNormalizations";
import {AddRemoveListExt} from "../../Controls/AddRemoveListExt/addRemoveListExt";
import PropTypes from "prop-types";
import DateTimeHelper from "../../../../../../infrastructure/js/utils/dateTimeHelper";

export default class AdditionalField extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let field = this.props.field;
    if (!field) {
      return null;
    }
    let index = this.props.index;
    let fieldClass = index % 2 == 0 ? 'inline left-side' : 'inline right-side';
    fieldClass = this.props.className ? this.props.className : fieldClass;
    switch (field.type.type) {
      case 'TEXT':
        return this.createTextField(index, field, fieldClass);
      case 'NUMERIC':
        return this.createNumericField(index, field, fieldClass);
      case 'DATE_PICKER':
        return this.createDateField(index, field, fieldClass);
      case 'LARGE_TEXT':
        return this.createTextAreaField(index, field, fieldClass);
      default:
        console.log(`Additional fields - unsupported type: ${field.type.type}`);
        return null;
    }
  }

  createTextAreaField(index, field, fieldClass) {
    return (
      <InputSection key={index} label={field.displayName} htmlFor={`${this.props.additionalFieldPrefix}.${field.name}`}
                    className={fieldClass}>
        <TextAreaField id={`${this.props.additionalFieldPrefix}.${field.name}`} name={`${this.props.additionalFieldPrefix}.${field.name}`} maxLength={250}/>
      </InputSection>
    )
  }

  createDateField(index, field, fieldClass) {
    let initialDate = null;
    let match = this.props.fieldsInitialValues ? this.props.fieldsInitialValues.find((x)=> x.field.id === field.id) : null;
    if(match){
      initialDate = DateTimeHelper.ConvertToDate(match.value);
    }
    return (
      <InputSection key={index} label={field.displayName} htmlFor={`${this.props.additionalFieldPrefix}.${field.name}`}
                    className={fieldClass}>
        <DatePicker
          id={`${this.props.additionalFieldPrefix}.${field.name}`}
          name={`${this.props.additionalFieldPrefix}.${field.name}`}
          initialDate={initialDate}
        />
      </InputSection>
    )
  }

  createNumericField(index, field, fieldClass) {
    return (
      <InputSection key={index} label={field.displayName} htmlFor={`${this.props.additionalFieldPrefix}.${field.name}`}
                    className={fieldClass}>
        <TextField id={`${this.props.additionalFieldPrefix}.${field.name}`} name={`${this.props.additionalFieldPrefix}.${field.name}`}
                   normalize={Normalize.number(false, 0, 99999.99)}/>
      </InputSection>
    )
  }

  createTextField(index, field, fieldClass) {
    return (
      <InputSection key={index} label={field.displayName} htmlFor={`${this.props.additionalFieldPrefix}.${field.name}`}
                    className={fieldClass}>
        <TextField id={`${this.props.additionalFieldPrefix}.${field.name}`} name={`${this.props.additionalFieldPrefix}.${field.name}`} maxLength={40}/>
      </InputSection>
    )
  }
}

AdditionalField.defaultProps = {
  additionalFieldPrefix: 'additionalFields',
};

AdditionalField.propTypes = {
  index : PropTypes.number,
  field : PropTypes.object,
  additionalFieldPrefix: PropTypes.string,
  className: PropTypes.string,

};

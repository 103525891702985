import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { navigationStates } from '../../enums/navigationStates';
import { getPathByType } from '../../utils/navigationHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { AppMainMenuItemsIds } from '../../enums/navigationMenuItemsIds';
import { getNavigationStateByHomePageType } from '../../utils/navigationHelper';
import icons from '../../../assets/svg';

export const settingsLinkPath = getPathByType(navigationStates.SETTINGS);

export function getNavigationElements(sData, topNavigatorData, featureFlags) {
  const labels = createLabelHelper('mat.sideNavigator.');

  if (featureFlags.ORGANIZATIONS) {
    return [
      {
        id: AppMainMenuItemsIds.NAV_ELEMENT_ORGS_MANAGEMENT,
        label: labels.get('administration.orgsmanagement'),
        icon: _getIconByType(navigationStates.ORG_MANAGEMENT),
        items: [
          {
            entityType: navigationStates.ORG_MANAGEMENT,
            icon: _getIconByType(navigationStates.ORG_MANAGEMENT),
            link: getPathByType(navigationStates.ORG_MANAGEMENT),
            label: labels.get('administration.orgsmanagement'),
          },
          {
            entityType: navigationStates.DEMO_ORG_MANAGEMENT,
            icon: _getIconByType(navigationStates.DEMO_ORG_MANAGEMENT),
            link: getPathByType(navigationStates.DEMO_ORG_MANAGEMENT),
            label: labels.get('administration.demoOrgsManagement'),
          },
          {
            entityType: navigationStates.USER_MANAGEMENT,
            icon: _getIconByType(navigationStates.USER_MANAGEMENT),
            link: getPathByType(navigationStates.USER_MANAGEMENT),
            label: labels.get('administration.usermanagement'),
          },
        ],
      },
    ];
  }

  const favorites = _getFavorites(sData.get('favorites'));
  const locations = _getLocationsAndStations(sData.get('locationsAndStations'));

  let navigatorItems = [
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES,
      label: labels.get('section.favorites'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES),
      items: favorites,
    },
    ...(PermissionManager.getOrgPreferences().schedulerEnabled && PermissionManager.hasPredefinedViewSchedulerPermissions()
      ? [
          {
            id: AppMainMenuItemsIds.NAV_ELEMENT_SCHEDULER,
            label: labels.get('section.scheduler'),
            icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_SCHEDULER),
            items: [
              {
                entityId: -1,
                entityType: navigationStates.SCHEDULER,
                icon: _getIconByType(navigationStates.SCHEDULER),
                link: getPathByType(navigationStates.SCHEDULER),
                label: labels.get('schedulerTasksGantt'),
              },
            ],
          },
        ]
      : []),
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_OPERATIONS_MANAGER,
      label: labels.get('section.operations'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_OPERATIONS_MANAGER),
      items: [
        ...(PermissionManager.getOrgPreferences().smartSelectionEnabled && PermissionManager.hasPredefinedViewSmartSelectionPermissions()
          ? [
              {
                entityId: -1,
                entityType: navigationStates.SMART_SELECTION,
                icon: _getIconByType(navigationStates.SMART_SELECTION),
                link: getPathByType(navigationStates.SMART_SELECTION),
                label: labels.get('materialSmartSelection'),
              },
            ]
          : []),
        ...(PermissionManager.getOrgPreferences().smartSelectionEnabled && PermissionManager.hasPredefinedViewSmartSelectionPermissions()
          ? [
              {
                entityId: -1,
                entityType: navigationStates.PICK_LISTS,
                icon: _getIconByType(navigationStates.PICK_LISTS),
                link: getPathByType(navigationStates.PICK_LISTS),
                label: labels.get('pickLists'),
              },
            ]
          : []),
        {
          entityId: -1,
          entityType: navigationStates.ON_HAND_INVENTORY,
          icon: _getIconByType(navigationStates.ON_HAND_INVENTORY),
          link: getPathByType(navigationStates.ON_HAND_INVENTORY),
          label: labels.get('onHandInventory'),
        },
        {
          entityId: -1,
          entityType: navigationStates.WORK_ORDERS,
          icon: _getIconByType(navigationStates.WORK_ORDERS),
          link: getPathByType(navigationStates.WORK_ORDERS),
          label: labels.get('workorders'),
        },
      ],
    },
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_ASSETS_MANAGER,
      label: labels.get('section.assets'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_ASSETS_MANAGER),
      items: [
        {
          entityId: -1,
          entityType: navigationStates.RAW_MATERIALS,
          icon: _getIconByType(navigationStates.RAW_MATERIALS),
          link: getPathByType(navigationStates.RAW_MATERIALS),
          label: labels.get('rawMaterials'),
        },
        {
          entityId: -1,
          entityType: navigationStates.PARTS,
          icon: _getIconByType(navigationStates.PARTS),
          link: getPathByType(navigationStates.PARTS),
          label: labels.get('parts'),
        },
        {
          entityId: -1,
          entityType: navigationStates.KITS,
          icon: _getIconByType(navigationStates.KITS),
          link: getPathByType(navigationStates.KITS),
          label: labels.get('kits'),
        },
        {
          entityId: -1,
          entityType: navigationStates.TOOLS,
          icon: _getIconByType(navigationStates.TOOLS),
          link: getPathByType(navigationStates.TOOLS),
          label: labels.get('tools'),
        },
        ...(PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled
          ? [
              {
                entityId: -1,
                entityType: navigationStates.GROUPS,
                icon: _getIconByType(navigationStates.GROUPS),
                link: getPathByType(navigationStates.GROUPS),
                label: labels.get('groups'),
              },
            ]
          : []),
        ...(PermissionManager.hasScrapViewPermissions()
          ? [
              {
                entityId: -1,
                entityType: navigationStates.MATERIAL_SCRAP,
                icon: _getIconByType(navigationStates.MATERIAL_SCRAP),
                link: getPathByType(navigationStates.MATERIAL_SCRAP),
                label: labels.get('scrap'),
              },
            ]
          : []),
      ],
    },
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_LOCATIONS_AND_STATIONS,
      label: labels.get('section.locationsStations'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_LOCATIONS_AND_STATIONS),
      items: locations,
    },
    //only for ADMIN, PS_USER, SYS_ADMIN, RFID_ADMIN_USER
    ...(PermissionManager.hasAdministrationTabPermissions()
      ? [
          {
            id: AppMainMenuItemsIds.NAV_ELEMENT_ADMINISTRATION,
            label: labels.get('section.administration'),
            icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_ADMINISTRATION),
            items: [
              {
                entityId: -1,
                entityType: navigationStates.MAT_SETTINGS,
                icon: _getIconByType(navigationStates.MAT_SETTINGS),
                link: getPathByType(navigationStates.MAT_SETTINGS),
                label: labels.get('masterData'),
              },
              {
                entityId: -1,
                entityType: navigationStates.USER_MANAGEMENT,
                icon: _getIconByType(navigationStates.USER_MANAGEMENT),
                link: getPathByType(navigationStates.USER_MANAGEMENT),
                label: labels.get('userManagement'),
              },
              ...(PermissionManager.hasRfidTabPermissions()
                ? [
                    {
                      entityId: -1,
                      entityType: navigationStates.RFID_SETTINGS,
                      icon: _getIconByType(navigationStates.RFID_SETTINGS),
                      link: getPathByType(navigationStates.RFID_SETTINGS),
                      label: labels.get('rfidSettings'),
                    },
                  ]
                : []),
              ...(PermissionManager.hasBillingTabPermissions()
                ? [
                    {
                      entityId: -1,
                      entityType: navigationStates.BILLING,
                      icon: _getIconByType(navigationStates.BILLING),
                      link: getPathByType(navigationStates.BILLING),
                      label: labels.get('billing'),
                    },
                  ]
                : []),
              ...(PermissionManager.hasPSManagementTabPermissions()
                ? [
                    {
                      entityId: -1,
                      entityType: navigationStates.PS_MANAGEMENT,
                      icon: _getIconByType(navigationStates.PS_MANAGEMENT),
                      link: getPathByType(navigationStates.PS_MANAGEMENT),
                      label: labels.get('psManagement'),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_REPORTS,
      label: labels.get('section.reports'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_REPORTS),
      items: [
        {
          entityId: -1,
          entityType: navigationStates.MANAGER_REPORT,
          icon: _getIconByType(navigationStates.MANAGER_REPORT),
          link: getPathByType(navigationStates.MANAGER_REPORT),
          label: labels.get('managerView'),
        },
        ...(PermissionManager.hasDashboardsTabPermissions()
          ? [
              {
                entityId: -1,
                entityType: navigationStates.PRODUCTION_DASHBOARD,
                icon: _getIconByType(navigationStates.PRODUCTION_DASHBOARD),
                link: getPathByType(navigationStates.PRODUCTION_DASHBOARD),
                label: labels.get('productionDashboard'),
              },
              {
                entityId: -1,
                entityType: navigationStates.PROGRESS_DASHBOARD,
                icon: _getIconByType(navigationStates.PROGRESS_DASHBOARD),
                link: getPathByType(navigationStates.PROGRESS_DASHBOARD),
                label: labels.get('progressDashboard'),
              },
              {
                entityId: -1,
                entityType: navigationStates.TOOL_MAINTENANCE_DASHBOARD,
                icon: _getIconByType(navigationStates.TOOL_MAINTENANCE_DASHBOARD),
                link: getPathByType(navigationStates.TOOL_MAINTENANCE_DASHBOARD),
                label: labels.get('toolsMaintenanceDashboard'),
              },
            ]
          : []),
        ...(PermissionManager.hasInventoryDashboardsTabPermissions()
          ? [
              {
                entityId: -1,
                entityType: navigationStates.INVENTORY_DASHBOARD,
                icon: _getIconByType(navigationStates.INVENTORY_DASHBOARD),
                link: getPathByType(navigationStates.INVENTORY_DASHBOARD),
                label: labels.get('inventoryDashboard'),
              },
            ]
          : []),
      ],
    },
  ];

  _markHomeAndFavorites(navigatorItems, favorites, topNavigatorData?.get('homePage'));

  return navigatorItems;
}

function _markHomeAndFavorites(navigatorItems, favorites, homePageData) {
  if (favorites) {
    navigatorItems.forEach((section) => {
      section.items.forEach((item) => {
        item.isFavorite = favorites.some((favorite) => {
          return favorite.entityType === item.entityType && favorite.entityId === item.entityId;
        });

        const homePageType = homePageData.get('type');
        const homePageId = homePageData.get('id');
        const _homePageType = getNavigationStateByHomePageType(homePageType);
        item.isHomePage = item.entityType === _homePageType && homePageId === item.entityId;
      });
    });
  }
}

function _getFavorites(favorites) {
  return favorites.map((favorite) => {
    const icon = _getIconByType(favorite.entityType);
    return {
      ...favorite,
      icon,
      link: getPathByType(favorite.entityType, favorite.entityId),
      isFavoriteMenu: true,
    };
  });
}

function _getLocationsAndStations(locations) {
  return locations.map((location) => {
    const icon = _getIconByType(location.entityType);
    return {
      ...location,
      icon,
      link: getPathByType(navigationStates.LOCATION, location.entityId),
    };
  });
}

function _getIconByType(type) {
  switch (type?.toUpperCase()) {
    case navigationStates.KITS:
      return icons.kitSmall;
    case navigationStates.GROUPS:
      return icons.groupSmall;
    case navigationStates.TOOLS:
      return icons.toolSmall;
    case navigationStates.STATION:
      return icons.stationSmall;
    case navigationStates.LOCATION:
      return icons.locationSmall;
    case navigationStates.NOTIFICATIONS:
      return icons.notifications;
    case navigationStates.PRODUCTION_DASHBOARD:
      return icons.productionDashboardSmall;
    case navigationStates.PROGRESS_DASHBOARD:
      return icons.progressDashboardSmall;
    case navigationStates.INVENTORY_DASHBOARD:
      return icons.inventoryDashboardSmall;
    case navigationStates.TOOL_MAINTENANCE_DASHBOARD:
      return icons.toolMaintenanceSmall;
    case navigationStates.MATERIAL_SCRAP:
      return icons.scrapSmall;
    case navigationStates.SMART_SELECTION:
      return icons.materialSmartSelection;
    case navigationStates.PICK_LISTS:
    case navigationStates.PICK_LIST:
      return icons.pickList;
    case navigationStates.SCHEDULER:
      return icons.schedulerSmall;
    case navigationStates.ON_HAND_INVENTORY:
      return icons.onHandInventory;
    case navigationStates.RAW_MATERIALS:
      return icons.rollSmall;
    case navigationStates.MANAGER_REPORT:
      return icons.managerReport;
    case navigationStates.ORG_MANAGEMENT:
    case navigationStates.DEMO_ORG_MANAGEMENT:
      return icons.matSettings;
    case navigationStates.WORK_ORDERS:
      return icons.workOrder;
    case navigationStates.PARTS:
      return icons.part;
    case navigationStates.MAT_SETTINGS:
      return icons.tpoMasterDataSmall;
    case navigationStates.USER_MANAGEMENT:
      return icons.userManagementSmall;
    case navigationStates.RFID_SETTINGS:
      return icons.rfidSettingsSmall;
    case navigationStates.BILLING:
      return icons.billingSettingsSmall;
    case navigationStates.PS_MANAGEMENT:
      return icons.psManagementSmall;
    case AppMainMenuItemsIds.NAV_ELEMENT_ADMINISTRATION:
      return icons.user;
    case AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES:
      return icons.favorite;
    case AppMainMenuItemsIds.NAV_ELEMENT_SCHEDULER:
      return icons.schedulerBig;
    case AppMainMenuItemsIds.NAV_ELEMENT_OPERATIONS_MANAGER:
      return icons.operationsManager;
    case AppMainMenuItemsIds.NAV_ELEMENT_ASSETS_MANAGER:
      return icons.assetsManager;
    case AppMainMenuItemsIds.NAV_ELEMENT_LOCATIONS_AND_STATIONS:
      return icons.locationsAndStationsBig;
    case AppMainMenuItemsIds.NAV_ELEMENT_REPORTS:
      return icons.report;
    default:
      return '';
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import * as AlertHelper from '../../../../utils/alertHelper.js';

require('./assetAlertPopover.scss');

export default class AssetAlertPopover extends React.PureComponent {

  sortBySeverity = (a, b) => {
    const severities = ['ERROR', 'WARNING', 'INFO'];
    if (a?.severityType && b?.severityType) {
      a = severities.indexOf(a.severityType);
      b = severities.indexOf(b.severityType);
      return a - b;
    }
    return 0;
  };

  getSeverity = (alerts) => {
    if (alerts?.length > 0) {
      alerts.sort(this.sortBySeverity);
      return alerts?.[0]?.severityType?.toLowerCase?.();
    }
    return '';
  };

  getPopoverComponent = (alerts) => {
    let messages = [];

    alerts.sort(this.sortBySeverity);

    alerts.map((alert, index) => {
      let alertDetails = {
        expirationDaysLeft: this.props.value.expirationDaysLeft,
        expirationDate: this.props.value.expirationDate,
        exposureTimeLeftBond: this.props.value.exposureTimeLeftBond ? this.props.value.exposureTimeLeftBond : null,
        exposureTimeLeftCure: this.props.value.exposureTimeLeftCure ? this.props.value.exposureTimeLeftCure : null,
        woDueDaysLeft: this.props.value.dueDateLeft,
        defrostingTimeLeft: this.props.value.defrostingTimeLeft,
        cyclesCount: this.props.value.cyclesCount,
        toolUsageTime: this.props.value.toolUsageTime,
        dueDateMinutesLeft: this.props.value.dueDateMinutesLeft,
        locationName: this.props.value.locationName,
        pickListBusinessId: this.props.value.pickListBusinessId
      };
      messages.push(<div key={'alertMessage' + index}>{AlertHelper.GetAlertMessage(alert, alertDetails)}</div>);

    });

    return <div className="alert-popover-component">{messages}</div>;
  };


  render() {
    let {value, type} = this.props;
    let alerts = value.alerts;
    if (type && alerts) {
      alerts = alerts.filter((alert) => {return alert.alertType === type});
    }

    if (alerts && alerts.length > 0) {
      let severity = this.getSeverity(alerts);

      return (<AlertPopover
        popoverId={'assetAlertPopover'}
        holdOnHover={true}
        popoverType={severity}
        popoverComponent={this.getPopoverComponent(alerts)}
      />)
    }
    return (
      <span className={'pl alert-icon '}/>
    );
  }
}

AssetAlertPopover.defaultProps = {
  value: {},
};

AssetAlertPopover.propTypes = {
  value: PropTypes.object,
};

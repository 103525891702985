import Network from 'infrastructure/js/modules/network';

export function fetchLocales() {
  return Network.get('locales');
}

export function fetchMacroSetsNames(data) {
  return Network.post('label/macroSetsNames', data);
}

export function fetchTerminologyLabels(data) {
  return Network.post('label/getTerminologyLabels', data);
}

export function doUnpublishedLabelsExist(data) {
  return Network.post('label/doUnPublishedLabelsExist', data);
}

export function editTerminologyLabels(data) {
  return Network.post('label/editLabels', data);
}

export function resetToDefaultAndPublish(data) {
  return Network.post('label/resetToDefaultAndPublish', data);
}

export function publishTerminologyLabels(data) {
  return Network.post('label/publishLabels', data);
}


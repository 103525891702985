import React, {Component} from 'react';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import ChangePassword from 'infrastructure/js/components/ChangePassword/changePassword';

require('./changePasswordDialog.scss');

export default class ChangePasswordDialog extends Component {

  constructor(props) {
    super(props);

    this.labels = null;
  }

  init() {
    if (this.props.sData.get('user').id) {
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    this.labels = this.props.sData.get('dialogLabels');
  }

  handleSubmit = () => {
    let {password, oldPassword} = this.props.formValues;
    let userId = this.props.sData.get('user').id;
    let serviceAction = this.props.sData.get('serviceAction');
    let callbackAction = this.props.sData.get('callbackAction');

    let queryData = {password};

    if (this.props.currentUser.id === userId) {
      queryData.oldPassword = oldPassword;
    } else {
      queryData.userId = userId;
    }

    this.props.actions.onSubmit(queryData ,callbackAction ,serviceAction ,this.getMessageDialogBuilder(this.labels));
  }

  getMessageDialogBuilder = (labels) => {
    return () => {
      let title = labels.get('confirmation.success');
      let className = 'oneBackground';
      let messageDialogDescriptor = {title, className};
      return messageDialogDescriptor;
    };
  };

  createFooterButtons() {
    return {
      left: [{
        id:'cancel',
        text: this.labels ? this.labels.get('button.cancel'): '' ,
        action: this.props.actions.close
      }],
      right: [
        {
          id:'submit',
          text: this.labels ? this.labels.get('button.primary.password') : '',
          bsStyle: "primary",
          action: this.props.handleSubmit(this.handleSubmit),
          loading: this.props.sData.get('loading')
        }
      ]
    };
  }

  onExit = () => {
    this.props.initialize({password: '', confirmPassword: '', oldPassword: ''});
  }

  render() {
    this.init();

    return (
      <Dialog id="ChangePasswordDialog"
              className='change-password-dialog'
              show={this.props.sData.get('show')}
              onHide={this.props.actions.close}
              titleText={`${this.props.sData.get('user').username} ${this.labels ? this.labels.get('title') : ''}`}
              onExited={this.onExit}
              footerButtons={this.createFooterButtons()}
              onEnterKeyPress={this.props.handleSubmit(this.handleSubmit)}
      >

        <ChangePassword loggedinUser={this.props.currentUser} user={this.props.sData.get('user')}/>


      </Dialog>
    );
  }
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as weeklyShiftsSchedulerActions} from '../../../actions/Administration/MatSettingsPage/weeklyShiftsSchedulerActions';
import {jsxActions as WeeklyShiftsViewActions} from '../../../actions/Administration/MatSettingsPage/weeklyShiftsViewActions';
import {jsxActions as shiftSettingsDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/shiftSettingsDialogActions';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import WeeklyShiftsView from '../../../components/Administration/MatSettingsPage/WeeklyShiftsView/weeklyShiftsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(WeeklyShiftsViewActions, dispatch),
      shiftsSchedulerActions : bindActionCreators(weeklyShiftsSchedulerActions, dispatch),
      shiftSettingsDialogActions: bindActionCreators(shiftSettingsDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }
  }
}

let WeeklyShiftsViewContainer = connect(
  (state) => {
    return {
      sShiftsSchedulerData: state.administration.getIn(['matSettingsPage', 'shiftsScheduler']),
      sShiftSettingsDialogData: state.administration.getIn(['matSettingsPage', 'shiftSettingsDialog']),
      sWeeklyShiftsViewData: state.administration.getIn(['matSettingsPage', 'weeklyShifts']),
    };
  },
  mapDispatchToProps
)(WeeklyShiftsView);


export default WeeklyShiftsViewContainer;




import React from 'react';

import PropTypes from 'prop-types';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import * as AlertHelper from '../../../../utils/alertHelper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./woAlertPopover.scss');

export default class WoAlertPopover extends React.PureComponent {
  constructor(props){
    super(props);

  }

  getSeverity = (mostSevereAlert) => {

    if (mostSevereAlert && mostSevereAlert.alert) {
      return mostSevereAlert.alert.severityType === 'ERROR' ? 'error' : 'warning';
    }
    return '';
  };

  getPopoverComponent = (data) => {
    let messages = [];

    messages.push(<div key={'alertMessage'}>{this._getRelatedAssetsQuantityMessage(data)}</div>);

    data.alerts.sort(function (a, b) {return (a.severityType === 'ERROR') ? -1 : 1;});


    data.alerts.map((mostSevereAlert, index) => {
      if(!mostSevereAlert || !mostSevereAlert.alert){
        return;
      }
      let message = `${AlertHelper.GetAlertMessage(mostSevereAlert.alert, mostSevereAlert.alertDetails)} (${mostSevereAlert.assetBusinessId}).`;
      messages.push(<div key={'alertMessage' + index}>{message}</div>);
    });
    return <div className="alert-popover-component">{messages}</div>;
  };


  _getRelatedAssetsQuantityMessage=(value)=>{
    if(value.errorKitCount === 1 && !value.warningKitCount){
      return createLabelHelper('alerts.woRelatedAssetsSingleErrorAlert').get('');
    }
    if(!value.errorKitCount && value.warningKitCount === 1){
      return createLabelHelper('alerts.woRelatedAssetsSingleWarningAlert').get('');
    }
    return createLabelHelper('alerts.woRelatedAssetsAlert').get('',undefined, {errorKitCount: value.errorKitCount, warningKitCount: value.warningKitCount})
  };

  render() {
    let {value} = this.props;

    let mostSevereAlert = value.mostSevereAlert;

    if (mostSevereAlert && mostSevereAlert.alert) {
      let severity = this.getSeverity(mostSevereAlert);

      return (<AlertPopover
        popoverId={'woAlertPopover'}
        holdOnHover={true}
        popoverType={severity}
        popoverComponent={this.getPopoverComponent(value)}
      />)
    }

    return (
    <span className={'pl alert-icon '}></span>
    );
  }
}

WoAlertPopover.defaultProps = {
  value: {},
};

WoAlertPopover.propTypes = {
  value: PropTypes.object,
};

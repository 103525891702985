import React, { Component } from 'react';
import classNames from 'classnames';

require('./schedulerKPI.scss');

export default class SchedulerKPI extends Component {

  constructor (props) {
    super(props);
  }

  render() {
    let {leftData, leftDataClassName, rightData, rightDataClassName} = this.props.data;
    return (
        <div className={classNames('scheduler-kpi', this.props.className, this.props.data ? this.props.data.className : '')}>

            <div className="left-side">
              <span className={leftDataClassName}>{leftData}</span>
            </div>
            <div className="right-side">
              <label className={rightDataClassName}>{rightData}</label>
            </div>
        </div>
    )
  }
};



import React from 'react';

import ImportFilesCommonGrid from '../../../ImportFiles/ImportFilesGrids/ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

export default class AssetsValidateGrid extends React.PureComponent {

  constructor(props) {
    super(props);

    this.entityTypeLabels = createLabelHelper(`mat.wizards.bulk.update.files.${this.props.assetType}.`);
  }

  render() {
    return  <ImportFilesCommonGrid  gridName={gridsNames.IMPORT_ATTRIBUTES_UPDATE}
                                    rows={this.props.rows}
                                    assetType={this.props.assetType}
                                    columnsWidthConfig={{type:100}}
                                    operationStatusLabel={this.props.operationStatusLabels}
                                    entityTypeLabels={this.entityTypeLabels}
    />
  }
}

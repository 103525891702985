import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';

import { jsxActions as onHandInventoryPageActions }    from '../../actions/OnHandInventory/onHandInventoryPageActions.js';
import { jsxActions as onHandInventoryGridActions }    from '../../actions/OnHandInventory/onHandInventoryGridActions';
import { jsxActions as onHandInventoryHeaderActions }    from '../../actions/OnHandInventory/onHandInventoryHeaderActions';

import OnHandInventoryPage from '../../components/PredefinedList/OnHandInventory/onHandInventoryPage.js';
import {jsxActions as fetchEntitiesActions} from '../../actions/FetchEntities/fetchEntitiesActions';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(onHandInventoryPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      onHandInventoryGridActions: bindActionCreators(onHandInventoryGridActions, dispatch),
      onHandInventoryHeaderActions:bindActionCreators(onHandInventoryHeaderActions, dispatch),
    }
  }
}

let OnHandInventoryPageContainer = connect(
  (state) => {
    let onHandInventoryGrid = state.grid.get(gridsNames.PREDEFINED_LIST_ON_HAND_INVENTORY) ? state.grid.get(gridsNames.PREDEFINED_LIST_ON_HAND_INVENTORY) : null;
    return {
      sOnHandInventoryGrid:  onHandInventoryGrid,
      sHasRows: onHandInventoryGrid && onHandInventoryGrid.get('rows') ? onHandInventoryGrid.get('rows').size > 0 : false,
      sHeaderData: state.predefinedList.get('onHandInventoryHeader'),
    };
  },
  mapDispatchToProps
)(OnHandInventoryPage);


export default OnHandInventoryPageContainer;

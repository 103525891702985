import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';

import { PL_TextField } from '../TextField/textField.js'
import './passwordField.scss';

export default class PL_PasswordFieldForm extends React.PureComponent {
  render() {
    return <Field component={PL_PasswordField} {...this.props} />;
  }
}

export class PL_PasswordField extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      reveal: false
    };
  }

  componentDidMount() {
    if (this.props.setRef)
      this.props.setRef(this);
  }

  componentWillUnmount() {
    if (this.props.setRef)
      this.props.setRef(undefined);
  }

  showPassword = () => {
    this.setState({ reveal: true });
  }

  hidePassword = () => {
    this.setState({ reveal: false });
  }

  togglePassword = () => {
    this.setState({ reveal: !this.state.reveal });
  }


  render() {
    let { className, id, enableReveal, hold, containerClassName, setRef, ...cleanProps } = this.props;
    let shown = this.state.reveal;
    className = classnames(className, 'password-field');
    return (
      <div className={ classnames(containerClassName, 'password-field-container')}>
        <PL_TextField id={this.props.id} type={this.state.reveal ? 'input' : 'password'} {...cleanProps} className={className} />
        { this.props.enableReveal ?
            <span
              className={`fa fa-eye${ !hold && shown ? '-slash' : '' } password-reveal-icon${cleanProps.meta && cleanProps.meta.error && cleanProps.meta.touched ? ' with-error' : ''}`}
              onMouseDown={hold ? this.showPassword : this.togglePassword}
              onMouseUp={hold ? this.hidePassword : undefined}
              onMouseLeave={hold ? this.hidePassword : undefined}
              id={this.props.id + '_revealIcon'}
            />
          : null
        }
      </div>
    );
  }
}

PL_PasswordField.propTypes = {
  enableReveal: PropTypes.bool,
  id: PropTypes.any.isRequired,
  hold: PropTypes.bool, //is revelation type hold (true) or toggle (false)
  containerClassName: PropTypes.string, // the className for the container div
  setRef: PropTypes.func, //(ref: Form(PL_PasswordField)) => void;
}



import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../MessageDialog/messageDialogActions.js';
import * as schedulerService from '../../services/Scheduler/schedulerService';
import {api as schedulerPageActions} from './schedulerPageActions' ;
import React from 'react';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_TASK_DIALOG_SHOW:               'CREATE_TASK_DIALOG_SHOW',
  CREATE_TASK_DIALOG_HIDE:               'CREATE_TASK_DIALOG_HIDE',
  CREATE_TASK_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_TASK_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_TASK_DIALOG_SUBMIT_FINISHED:    'CREATE_TASK_DIALOG_SUBMIT_FINISHED',
  CREATE_TASK_DIALOG_FETCH_DATA_READY:   'CREATE_TASK_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_TASK_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_TASK_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_TASK_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_TASK_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function(payload) {
    return {type: actionTypes.CREATE_TASK_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(taskId, data, schedulerEngineInstance) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(taskId, data, schedulerEngineInstance);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

/*
api.openRemoveTaskConfirmationDialog = function(dispatch, getState) {
  return function(messageDialogBuilder , selectedItems) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.removeDevices(dispatch, getState)(selectedItems)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

  }
};
*/
api.removeNonOperationalTasks = function(dispatch, getState) {
  return function(selectedItems) {

    let selectedItemsIds = selectedItems?.toJS()?.map(item => item.id) || [];

    if (selectedItemsIds?.length < 1) {
      console.error('removeNonOperationalTasks(): no selected items');
      return;
    }

    schedulerService.deleteNoneOperationalTasks({taskAssignmentIds: selectedItemsIds}).then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('delete None Operational tasks failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success : false};
      }

      messageDialogApi.showSuccess(dispatch, getState)(response?.data?.labelKey, response?.data?.params);

      schedulerPageActions.reloadTasks(dispatch, getState)();
    });
  }
};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function() {
    api.fetchDialogData(dispatch, getState)();
    dispatch(actions.show());
  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function() {
    return schedulerService.fetchAllResources().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch resources failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return;
      }
      let dialogData = {
        resources: response.dataList.map((obj) => {return {value: obj.id, label: obj.name, data: obj}}),
      };

      dispatch(actions.fetchDilaogDataReady(dialogData));
    });
  }
};

api.submit = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.submitInProgress());

    return schedulerService.createNoneOperationalTask(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Create Task failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return;
      }

      schedulerPageActions.reloadTasks(dispatch, getState)();
    });
  };
};

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as activeGroupsPageActions} from '../../actions/ActiveGroupsPage/activeGroupsPageActions';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import {gridsNames} from '../../enums/gridsNames';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions';
import { jsxActions as activeGroupsGridActions } from '../../actions/ActiveGroupsPage/activeGroupsGridActions';
import { jsxActions as editAssetAttributesActions }  from '../../actions/Dialogs/EditAssetAttributesDialog/editAssetAttributesDialogActions';
import { jsxActions as moveAssetsActions }  from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as shelfLifeUpdateActions } from '../../actions/Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialogActions';
import { jsxActions as archiveAssetsActions }   from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import {jsxActions as printRfidTagsActions} from '../../actions/Dialogs/PrintRfidTagsDialog/printRfidTagsDialogActions';
import { jsxActions as printPdfLabelsActions }      from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import ActiveGroupsPage from '../../components/PredefinedList/ActiveGroupsPage/activeGroupsPage';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(activeGroupsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      activeGroupsGridActions: bindActionCreators(activeGroupsGridActions, dispatch),
      editAssetAttributesActions:      bindActionCreators(editAssetAttributesActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      shelfLifeUpdateActions:   bindActionCreators(shelfLifeUpdateActions, dispatch),
      archiveAssetsActions:     bindActionCreators(archiveAssetsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch),
      printRfidTagsActions: bindActionCreators(printRfidTagsActions, dispatch),
      printPdfLabelsActions:      bindActionCreators(printPdfLabelsActions, dispatch),
    }
  }
}

let ActiveGroupsContainer = connect(
  (state) => {
    let activeGroupsGrid = state.grid.get(gridsNames.PREDEFINED_LIST_ACTIVE_GROUPS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ACTIVE_GROUPS) : null;
    return {
      sHasRows: activeGroupsGrid && activeGroupsGrid.get('rows') ? activeGroupsGrid.get('rows').size > 0 : false,
      sSelectedRowsData: activeGroupsGrid ? activeGroupsGrid.get('selectedRowsData') : List(),
      sEditAssetAttributesData: state.dialogs.get('editAssetAttributesDialog'),
      sMoveAssetsData:  state.dialogs.get('moveAssetsDialog'),
      sShelfLifeUpdateData: state.dialogs.get('shelfLifeUpdateDialog'),
      sArchiveAssetsData:   state.dialogs.get('archiveAssetsDialog'),
      sLoggedInUser : state.login.get('loggedInUser'),
      sPrintRfidTagsDialogData:    state.dialogs.get('printRfidTagsDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
    };
  },
  mapDispatchToProps
)(ActiveGroupsPage);


export default ActiveGroupsContainer;




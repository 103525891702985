import { Map } from 'immutable';
import { actionTypes } from '../../actions/Login/loginActions.js';
import { actionTypes as systemActionTypes } from '../../actions/System/systemActions.js';
import { IsJsonString } from 'infrastructure/js/utils/JSON.js';
import * as AppHelper from 'infrastructure/js/utils/appHelper';

let loggedInUser = localStorage['loggedInUser'];

// while on mobile app, refresh action redirect to login page
if (!(loggedInUser = IsJsonString(loggedInUser)) || (!__DEV__ && AppHelper.isMobileApp())) {
  loggedInUser = {};
  let excludeUrls = ['/Login', 'changepassword', 'latencyCalc'];
  let isExcluded = excludeUrls.some((url) => {
    return location.hash.toLowerCase().includes(url.toLowerCase());
  });

  if (!isExcluded) {
    window.location.hash = '/Login';
    localStorage.removeItem('loggedInUser');
  }
}

let defaultState = Map({
  loading: false,
  loggedInUser: loggedInUser,
  hasError: false,
  locationsData: [],
  errorMessage: '',
});

export default function (state = defaultState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_LOGIN_IN_PROCESS:
      return state.set('loading', true).set('hasError', false);

    case actionTypes.LOGIN_LOGIN_FAILED:
      return state.set('loading', false).set('hasError', true).set('errorMessage', action.payload);

    case actionTypes.LOGIN_LOGIN_FINISHED:
      localStorage['loggedInUser'] = JSON.stringify(action.payload.user);
      return state.set('loading', false).set('hasError', false).set('loggedInUser', action.payload.user);
    case actionTypes.LOGIN_PRE_LOGIN_FINISHED:
      return state.set('loading', false).set('hasError', false);

    case actionTypes.LOGIN_FETCH_LOCATIONS_FINISHED:
      return state.set(
        'locationsData',
        action.payload.dataList
          ? action.payload.dataList.map((item) => {
              return { value: item.id, label: item.name, data: item };
            })
          : []
      );

    case actionTypes.LOGIN_UPDATE_LOGGEDIN_USER_DATA:
      let _loggedInUser = JSON.parse(localStorage['loggedInUser']);
      _loggedInUser.email = action.payload.user.email;
      _loggedInUser.firstName = action.payload.user.firstName;
      _loggedInUser.lastName = action.payload.user.lastName;
      _loggedInUser.phone = action.payload.user.phone;
      _loggedInUser.userPreferences.defaultEntityId = action.payload.user.userPreferences
        ? action.payload.user.userPreferences.defaultEntityId
        : null;
      _loggedInUser.userPreferences.defaultEntityType = action.payload.user.userPreferences
        ? action.payload.user.userPreferences.defaultEntityType
        : null;

      localStorage['loggedInUser'] = JSON.stringify(_loggedInUser);

      return state.set('loggedInUser', _loggedInUser);

    case actionTypes.LOGIN_UPDATE_LOGGEDIN_USER_ORG_PREFERENCES:
      let currentUser = JSON.parse(localStorage['loggedInUser']);
      currentUser.loggedInOrg.orgPreferences = action.payload.preferences;
      localStorage['loggedInUser'] = JSON.stringify(currentUser);

      return state.set('loggedInUser', currentUser);

    case actionTypes.LOGIN_UPDATE_USER_EXPIRED:
      return state.set('isExpiredPassword', true).set('orgId', action.payload);

    case actionTypes.LOGIN_UPDATE_PASSWORD_CONFIG:
      return state.set('passwordTooltip', action.payload.passwordTooltip).set('passwordConfig', action.payload.passwordConfig);

    case systemActionTypes.SYSTEM_CLEAR_ALL:
      localStorage.removeItem('loggedInUser');
      return Map({
        loading: false,
        loggedInUser: {},
        hasError: false,
        locationsData: [],
        errorMessage: '',
      });

    default:
      return state;
  }
}

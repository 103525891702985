import React, {Component} from 'react';
import {ComposedChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Legend} from 'recharts';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./toolsCapacityChart.scss');

export default class ToolsCapacityChart extends Component {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.dashboard.toolsMaintenance.toolsCapacityChart.');
  }

  getChartData = () => {

    return this.props.chartData.map((tool) => {
      let name = tool.toolTypeBusinessId;
      if (name.length > 15) {
        name = name.slice(0, 15) + '...';
      }

      return {
        shortName: name,
        usageLabel: this.labels.get('usage'),
        defectLabel: this.labels.get('defects'),
        name: tool.toolTypeBusinessId,
        occupied: tool.occupitedCount,
        defected: tool.defectsCount,
      }
    });
  };

  customTooltip(e) {
    if (e.active && e.payload != null && e.payload[0] != null) {
      return (<div className="custom-tooltip">
        <p>{`${e.payload[0].payload.name}`}</p>
        <p>{`${e.payload[0].payload.usageLabel} ${e.payload[0].payload.occupied}`}</p>
        <p>{`${e.payload[0].payload.defectLabel} ${e.payload[0].payload.defected}`}</p>
      </div>);
    }
    else {
      return "";
    }
  }

  renderChart = () => {
    if (!this.props.chartData || !this.props.chartData.length) {
      return <div className={'empty-chart-section'}><span className={'no-results'}>{this.labels.get('noResults')}</span>
      </div>;
    }
    let chartData = this.getChartData();

    return (
      <div className={'chart-section'}>
        <ComposedChart width={600} height={400} data={chartData} key={'111'}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="shortName" angle={-45} textAnchor="end" interval={0} height={150}/>
          <YAxis label={{value: this.labels.get('timesInOccupiedStatus'), angle: -90, position: 'insideLeft', style:{ textAnchor: 'middle' }}}
                 yAxisId="occupied" orientation="left"/>
          <YAxis label={{value: this.labels.get('defects'), angle: -90, position: 'insideRight', style:{ textAnchor: 'middle' }}} yAxisId="defected"
                 orientation="right"/>
          <Tooltip content={this.customTooltip}/>
          <Legend/>
          <Bar yAxisId="occupied" dataKey={'occupied'} name={this.labels.get('usage')} fill="#5DADE2" key={'1'}/>
          <Line yAxisId="defected" type='monotone' name={this.labels.get('defects')} dataKey='defected' stroke='#ff7300'/>
        </ComposedChart>
      </div>
    );
  };

  render() {
    if (!this.props.chartData) {
      return null;
    }

    let title = this.labels.get('title');
    if (this.props.chartData.isReachedMaxTotal) {
      title = `${title} - ${this.labels.get('maxReachedTitle')}`;
    }

    return (
      <div className="tool-capacity-chart">
        <div className='chart-title'>{title}</div>
        {this.renderChart()}
      </div>
    )
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import EntitiesMultiSelect from '../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import EntitiesMultiSelectWithChildren from '../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelectWithChildren.js';
import {enumTypes, getEnumValue} from '../../../../../utils/enumHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Button from 'infrastructure/js/components/controls/Button/button';

require('./selectResultingAssetStep.scss');

export default class SelectResultingAssetStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.woFetchConfig = {
      entityType: EntityPropertyTypes.WO_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NO_KIT_PART_TYPE],
      action: this.props.actions.fetchEntities,
    };
    this.fetchConfig = {
      entityType: EntityPropertyTypes.PART_BUSINESS_ID,
      action: this.props.actions.fetchEntities,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IN_PROGRESS]
    };
    this.usingFetchConfig = {
      entityType: EntityPropertyTypes.TOOL_BUSINESS_ID,
      action: this.props.actions.fetchEntities,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NO_ATTACHED_ASSET]
    };

    this.state = {
      isNewAsset: true,
      usingAssetId: null,
    }

    this.preselectedAssets = props.sData.get('preselectedAssets');
    this.itemToEdit = this.preselectedAssets?.size > 0 ? this.preselectedAssets.get(0) : null;
    this.isEditMode = !!this.itemToEdit;


    this.props.registerOnEnter(() => {
      this.props.setNextButtonEnabled(this.isNextButtonEnabled());
    });

    this.props.registerOnExit(() => {
    })
  }

  componentDidMount() {
    if (this.isEditMode) {
      this.updateUI(
        { label: this.itemToEdit.businessId,
                value: this.itemToEdit.id,
                data: this.itemToEdit
              });
      }
  }

  isNextButtonEnabled = () => {
    return true;
  }

  onValueChangeCallback = (newValue, oldValue) => {

    //prevent updating when there was no change
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    if (newValue === null || newValue.label === newValue.value) {
      this.updateUI(null);
      return;
    }
    if (newValue.label !== newValue.value) {  //existing pick list
      this.updateUI(newValue);
    }
  }

  getAssetDataHandler = (data) => {
    if (this.callbackId !== data?.callbackId) {
      return;
    }
    let tool = data?.equipmentAsset
    if (tool) {
      let toolData = {
        value: tool.id,
        label: tool.businessId,
        data: {...tool}
      }
      this.props.change('usingAsset.assetsValue', toolData);
    }
    this.setState({layers: data?.layers, isNewAsset: false})
  }

  updateUI = (value) => {
    if (!value) {
      this.props.change('workOrder.assetsValue', null);
      this.props.change('usingAsset.assetsValue', null);
      this.setState({isNewAsset: true, layers: null});
      return;
    }
    if (value?.data?.workOrder) {
      let workOrder = {
        value: value.data.workOrder.id,
        label: value.data.workOrder.businessId,
        data: { ...value.data.workOrder}
      }
      this.props.change('workOrder.assetsValue', workOrder);
    }

    this.callbackId = new Date().getTime();
    this.props.actions.getAssetData(value.value, this.callbackId).then(this.getAssetDataHandler);

  }

  generateIdResponseHandler = (response) => {
    if (response?.data) {
      const newOption = {
        "value": response.data.data,
        "label": response.data.data,
        "data": {
          "id": response.data.data,
          objectType: getEnumValue(enumTypes.OBJECT_TYPE)('PART')
        }
      };
      this.props.change('resultingAsset.assetsValue', newOption);
      this.updateUI(null);
    }
  };

  generateId = () => this.props.actions.generatePartId().then(this.generateIdResponseHandler);

  renderLayers() {
    if (this.state.isNewAsset) {
      return null;
    }

    let layers = this.state.layers?.map((item) => {
      return (
        <div key={item.id}>{item?.businessId}</div>
      )
    })

    return (
      <InputSection label={this.props.labels.get('selectResultingAssetStep.layers')} className='layers-section' >
        <div className='layers-list'>
          {layers}
        </div>
      </InputSection>
    )
  }

  getNewOptionDataHandler = (inputValue, optionLabel) => {
    return {
      label: optionLabel,
      value: inputValue,
      __isNew__: true,
      data: {
        objectType: getEnumValue(enumTypes.OBJECT_TYPE)("PART"),
        businessId: inputValue,
      }
    };
  };

  maxLengthDropDown = Validation.dropdown.maxLength(50);

  render() {

    const {labels} = this.props;
    return (
      <div className="select-resulting-asset-step">

          <InputSection label={labels.get('selectResultingAssetStep.part') + '*'} htmlFor="resultingAsset" className="inline left-side">
            <EntitiesMultiSelectWithChildren
              id="resultingAsset"
              name="resultingAsset"
              validate={[Validation.dropdown.required, this.maxLengthDropDown]}
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('PART')]}
              fetchConfig={this.fetchConfig}
              isMulti={false}
              allowNewOption={true}
              showEntitiesTypes={false}
              change={this.props.change}
              getNewOptionData={this.getNewOptionDataHandler}
              onValueChangeCallback={this.onValueChangeCallback}
              preSelectedEntities={this.preselectedAssets}
            >
              <div className='input-with-generate-button'>
                <Button id={'-button'} bsStyle="default"
                        onClick={this.generateId}
                        className={' generateButton border-btn btn btn-default'}>
                  {this.dialogLabels.get('generate')}
                </Button>
              </div>
            </EntitiesMultiSelectWithChildren>
          </InputSection>

          <InputSection label={labels.get('selectResultingAssetStep.using')} htmlFor="usingAsset" className="inline right-side">

            <EntitiesMultiSelect id='usingAsset'
                                 name='usingAsset'
                                 entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')]}
                                 change={this.props.change}
                                 fetchConfig={this.usingFetchConfig}
                                 isMulti={false}
                                 isDisabled={!this.state.isNewAsset && this.state.layers?.length > 0}
            />
          </InputSection>

          <InputSection label={labels.get('selectResultingAssetStep.workOrder')} htmlFor="workOrder" className="inline left-side">
            <EntitiesMultiSelect
              id="workOrder"
              name="workOrder"
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('WO')]}
              fetchConfig={this.woFetchConfig}
              change={this.props.change}
              isMulti={false}
              showEntitiesTypes={false}
              isDisabled={!this.state.isNewAsset}
            />
          </InputSection>

          {this.renderLayers()}

        </div>
    );
  }
}

SelectResultingAssetStep.propTypes = {
  actions : PropTypes.object.isRequired,
  isCurrentStep : PropTypes.bool,
  labels: PropTypes.object.isRequired
};


import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as activeToolsPageActions} from '../../actions/ActiveToolsPage/activeToolsPageActions';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import {gridsNames} from '../../enums/gridsNames';
import { jsxActions as activeToolsGridActions } from '../../actions/ActiveToolsPage/activeToolsGridActions';
import { jsxActions as activeToolsScheduleGridActions } from '../../actions/ActiveToolsPage/activeToolsScheduleGridActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as archiveAssetsActions }   from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import { jsxActions as reportMaintenanceDialogActions }      from '../../actions/Dialogs/ReportMaintenanceDialog/reportMaintenanceDialogActions';
import { jsxActions as moveAssetsActions }      from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as editAssetAttributesDialogActions }      from '../../actions/Dialogs/EditAssetAttributesDialog/editAssetAttributesDialogActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import ActiveToolsPage from '../../components/PredefinedList/ActiveToolsPage/activeToolsPage';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(activeToolsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      archiveAssetsActions:     bindActionCreators(archiveAssetsActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      editAssetAttributesActions:      bindActionCreators(editAssetAttributesDialogActions, dispatch),
      activeToolsGridActions: bindActionCreators(activeToolsGridActions, dispatch),
      activeToolsScheduleGridActions: bindActionCreators(activeToolsScheduleGridActions, dispatch),
      reportMaintenanceDialogActions: bindActionCreators(reportMaintenanceDialogActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let ActiveToolsPageContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS) : null;
    let scheduleGrid = state.grid.get(gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS_SCHEDULE) ? state.grid.get(gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS_SCHEDULE) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sArchiveAssetsData:   state.dialogs.get('archiveAssetsDialog'),
      sMoveAssetsData:      state.dialogs.get('moveAssetsDialog'),
      sEditAssetAttributesData:      state.dialogs.get('editAssetAttributesDialog'),
      sReportMaintenanceDialogActionsData:      state.dialogs.get('reportMaintenanceDialog'),
      sScheduleGrid:  scheduleGrid,
    };
  },
  mapDispatchToProps
)(ActiveToolsPage);


export default ActiveToolsPageContainer;




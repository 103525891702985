import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as scanAssetPageActions } from '../../../actions/Mobile/ScanAssetPage/scanAssetPageActions';
import { jsxActions as headerActions }  from '../../../actions/Mobile/Header/headerActions.js';
import ScanAssetPage from '../../../components/Mobile/ScanAssetPage/scanAssetPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(scanAssetPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
    }
  }
}

let ScanAssetPageContainer = connect(
  (state) => {
    return {
      sData: state.mobile.get('data'),
      sPageData: state.mobile.get('scanAssetPage'),
    };
  },
  mapDispatchToProps
)(ScanAssetPage);

export default ScanAssetPageContainer;





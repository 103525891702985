import React from 'react';
import * as NotificationService from '../services/Notifications/notificationsService';

let notificationCategoriesHelperInstance = function () {
  let obj = {};

  let _categories;
  obj.init = function (){
    NotificationService.getCategories().then((response)=>{
      _categories = response.data;
    });
  };

  if (!_categories && localStorage['loggedInUser']) {
    obj.init();
  }

  obj.isBaseCategoryNotificationByType = function(category, type) {
    if(category === type){
      return true
    }
    let match = _categories.items.find((item) => { return item.name === category });
    if(!match){
      return false;
    }
    return match.parents.some((parent) => { return parent === type });
  };

  return obj;
}();

export default notificationCategoriesHelperInstance;

import {api as wizardApi} from 'infrastructure/js/components/Wizard/wizardActions';
import importNestsService from '../../../services/ImportNests/importNestsService.js';
import { api as messageDialogApi }      from '../../MessageDialog/messageDialogActions';
import { api as locationPageApi } from '../../LocationPage/locationPageActions';
import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as ImportResponseHelper from '../Common/ImportResponseHelper';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import { api as batchJobsApi }      from '../../BatchJobs/batchJobsActions.js';
import {api as exportApi} from "../../Export/exportActions";
import importFilesService from "../../../services/ImportFiles/importFilesService";
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import MaterialHelper from "../../../utils/materialHelper";

let importNestsWithRollWizardApi = wizardApi.createWizardSpecificAdapter('IMPORT_NESTS_WITH_ROLL');

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  IMPORT_NESTS_WITH_ROLL_SAVE_START_PARAMETERS: 'IMPORT_NESTS_WITH_ROLL_SAVE_START_PARAMETERS',
  IMPORT_NESTS_WITH_ROLL_CLEAR_PARAMETERS :'IMPORT_NESTS_WITH_ROLL_CLEAR_PARAMETERS',

  IMPORT_NESTS_WITH_ROLL_HAS_ERRORS: 'IMPORT_NESTS_WITH_ROLL_HAS_ERRORS',

  IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_IN_PROGRESS: 'IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_IN_PROGRESS',
  IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FINISHED: 'IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FINISHED',
  IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FAILED: 'IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FAILED',

  IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_IN_PROGRESS: 'IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_IN_PROGRESS',
  IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_FINISHED: 'IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_FINISHED',
  IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_FAILED: 'IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_FAILED',
  IMPORT_NESTS_WITH_ROLL_HAS_SELECTED_NESTS_ERRORS: 'IMPORT_NESTS_WITH_ROLL_HAS_SELECTED_NESTS_ERRORS',

  IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_IN_PROGRESS: 'IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_IN_PROGRESS',
  IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_FINISHED: 'IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_FINISHED',
  IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_FAILED: 'IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_FAILED',

  IMPORT_NESTS_WITH_ROLL_SELECTED_FILE_CHANGED: 'IMPORT_NESTS_WITH_ROLL_SELECTED_FILE_CHANGED',
  IMPORT_NESTS_WITH_ROLL_SELECTED_ASSET_TYPE_CHANGED: 'IMPORT_NESTS_WITH_ROLL_SELECTED_ASSET_TYPE_CHANGED',

  IMPORT_NESTS_WITH_ROLL_ON_ROWS_CHANGED: 'IMPORT_NESTS_WITH_ROLL_ON_ROWS_CHANGED'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  saveStartParameters: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_SAVE_START_PARAMETERS, payload: payload};
  },
  clearParameters: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_CLEAR_PARAMETERS, payload: payload};
  },
  showErrors: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_HAS_ERRORS, payload: payload};
  },
  showSelectedNestsErrors: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_HAS_SELECTED_NESTS_ERRORS, payload: payload};
  },
  uploadFileInProgress: function () {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_IN_PROGRESS};
  },
  uploadFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FINISHED, payload: payload};
  },
  uploadFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FAILED, payload: payload};
  },
  validateFileInProgress: function () {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_IN_PROGRESS};
  },
  validateFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_FINISHED, payload: payload};
  },
  validateFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FAILED, payload: payload};
  },
  importFileInProgress: function () {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_IN_PROGRESS};
  },
  importFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_FINISHED, payload: payload};
  },
  importFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FAILED, payload: payload};
  },
  onSelectedAssetTypeChanged: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_SELECTED_ASSET_TYPE_CHANGED, payload: payload};
  },
  selectedFileChanged: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_SELECTED_FILE_CHANGED, payload: payload};
  },
  onRowsChanged: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_WITH_ROLL_ON_ROWS_CHANGED, payload: payload};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.openWizard = function (config) {
  return function (dispatch, getState) {
    api.openImportNestsWithRollWizard(dispatch, getState)(config, asset);
  }
};

jsxActions.onWizardClose = function () {
  return function (dispatch, getState) {
    dispatch(actions.clearParameters());
  }
};

jsxActions.exportValidationReport = function () {
  return function (dispatch, getState) {
    api.exportValidationReport(dispatch, getState)();
  }
};

jsxActions.uploadFile = function (file) {
  return function (dispatch, getState) {
    api.uploadFile(dispatch, getState)(file);
  }
};


jsxActions.goOn = function (file, nestItems) {
  return function (dispatch, getState) {
    api.goOn(dispatch, getState)(file, nestItems);
  }
};

jsxActions.validateFile = function (file, rollId, materialBusinessId, nestItems) {
  return function (dispatch, getState) {
    api.validateFile(dispatch, getState)(file, rollId, materialBusinessId, nestItems);
  }
};

jsxActions.onRowsChanged = function (nestItems) {
  return function (dispatch, getState) {
    api.onRowsChanged(dispatch, getState)(nestItems);
  }
};

jsxActions.importFile = function (data, messageDialogBuilder, reloadParentComponent) {
  return function (dispatch, getState) {
    api.importFile(dispatch, getState)( data, messageDialogBuilder, reloadParentComponent);
  }
};

jsxActions.importFileWithProgress = function (data, messageDialogBuilder, reloadParentComponent) {
  return function (dispatch, getState) {
    api.importFileWithProgress(dispatch, getState)( data, messageDialogBuilder, reloadParentComponent);
  }
};

jsxActions.hasErrors = function (data) {
  return function (dispatch, getState) {
    api.showErrors(dispatch, getState)(data);
  }
};

jsxActions.onSelectedAssetTypeChanged = function (data) {
  return function (dispatch, getState) {
    api.selectedAssetTypeChanged(dispatch, getState)(data);
  }
};

jsxActions.onSelectedFileChanged = function (file) {
  return function (dispatch, getState) {
    dispatch(actions.selectedFileChanged(file));
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.exportValidationReport = function (dispatch, getState) {
  return function () {
    let validationJobId = getState().importNestsWithRoll.get('validationJobId');
    return exportApi.exportValidationReportOfImportNestsWithRoll(dispatch, getState)(validationJobId);
  }
};

api.selectedAssetTypeChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.onSelectedAssetTypeChanged(data));
  }
};

api.onRowsChanged = function (dispatch, getState) {
  return function (nestItems) {
    dispatch(actions.onRowsChanged(nestItems));
  }
};

api.openImportNestsWithRollWizard = function (dispatch, getState) {
  return function (config) {

    importFilesService.checkCutAndKitExistence().then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return;
      }

      let error = response?.applicationResponseStatus?.errors?.subErrors[0];
      if(error){
        let messageDialogDescriptor = dialogHelper.getMessageDialogDescriptorByErrorCode(error.id);
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        return;
      }
      dispatch(actions.saveStartParameters(config));
      importNestsWithRollWizardApi.start(dispatch, getState)();
    });
  }
};

api.showErrors = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.showErrors(data));
  }
};

api.uploadFile = function (dispatch, getState) {
  return function (file) {
    dispatch(actions.uploadFileInProgress());

    let promiseUploadFile = importFilesService.uploadFile(file)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.uploadFileFailed(response));
          dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Upload File Failed' +  ' ' + response);
          return null;
        }

        let locationId =  locationPageApi.getLocationId(dispatch, getState)();
        importNestsService.importNestsWithRollFetchItems(response.data.data, locationId)
          .then((res) => {
            if (!Network.isResponseValid(res)) {
              dispatch(actions.uploadFileFailed(res));
              dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(res)));
              console.error('Fetch Items Failed' +  ' ' + res);
              return null;
            }
            res.data.importCutPlanWithRollGroupedByNestModel.map((obj) => {
              obj.lengthCut = UnitHelper.serverValueToUserValue(unitTypes.LENGTH,obj.lengthCut, 2);
              obj.expectedLengthCut = UnitHelper.serverValueToUserValue(unitTypes.LENGTH,obj.expectedLengthCut, 2);
              obj.isRowDisabled = obj.hasNestError;
              obj.quantityKittedPlies = [];
              obj.materialUsedAmount = [];
              obj.materialScrapAmount = [];
              obj.scrap = Math.max(0, obj.lengthCut - obj.expectedLengthCut) ;

              if (obj.nestWithRollFromFile && obj.nestWithRollFromFile.length > 0) {
                obj.nestWithRollFromFile.map((nest)=>{
                  //TODO: ask QA: is this a bug? all nests take their value from file[0]
                  nest.rollLength = UnitHelper.serverValueToUserValue(unitTypes.LENGTH,obj.nestWithRollFromFile[0].rollLength, 2);
                  nest.rollWidth = UnitHelper.serverValueToUserValue(unitTypes.WIDTH, obj.nestWithRollFromFile[0].rollWidth, 2);
                  nest.nestWidth =  UnitHelper.serverValueToUserValue(unitTypes.WIDTH, obj.nestWithRollFromFile[0].nestWidth, 2);
                  nest.nestLengthCut =  UnitHelper.serverValueToUserValue(unitTypes.LENGTH, obj.nestWithRollFromFile[0].nestLengthCut, 2);
                  nest.materialUsedAmount = MaterialHelper.relativeMaterialUsageCalculation(obj.lengthCut, nest.relativeLength, obj.nestWithRollFromFile.length);
                  nest.materialScrapAmount = MaterialHelper.relativeMaterialUsageCalculation(obj.scrap, nest.relativeLength, obj.nestWithRollFromFile.length);
                  obj.quantityKittedPlies.push(nest.quantityKittedPlies)
                  obj.materialUsedAmount.push(nest.materialUsedAmount)
                  obj.materialScrapAmount.push(nest.materialScrapAmount)
                });

                obj.materialBusinessId = obj.nestWithRollFromFile[0].materialBusinessId;
                obj.rollBusinessId = obj.nestWithRollFromFile[0].rollBusinessId;
                obj.rollLength = obj.nestWithRollFromFile[0].rollLength;
                obj.rollWidth = obj.nestWithRollFromFile[0].rollWidth;
                obj.nestWidth = obj.nestWithRollFromFile[0].nestWidth;
              }
            });

            dispatch(actions.uploadFileFinished(res.data));
            importNestsWithRollWizardApi.gotoStep(dispatch, getState)('step_2');
            return res.data;
          });

      });
    return promiseUploadFile;
  }
};

api.goOn = function (dispatch, getState) {
  return function () {
    importNestsWithRollWizardApi.gotoStep(dispatch, getState)('step_3');
  }
};

api.validateFile = function (dispatch, getState) {
  return function (token, nestItems) {
    dispatch(actions.validateFileInProgress());

    let updatedNests = [];
    nestItems.map((nest) => {

      let updatedNest = {
        ...nest,
        lengthCut:  UnitHelper.userValueToServerValue(unitTypes.LENGTH, nest.lengthCut, 2),
        expectedLengthCut:  UnitHelper.userValueToServerValue(unitTypes.LENGTH, nest.expectedLengthCut, 2),
        scrap:  UnitHelper.userValueToServerValue(unitTypes.LENGTH, nest.scrap, 2),
      };
      updatedNest.nestWithRollFromFile = nest.nestWithRollFromFile.map((nestWithRoll) => ({
        ...nestWithRoll,
        nestLengthCut: UnitHelper.userValueToServerValue(unitTypes.LENGTH, nest.lengthCut, 2),
        rollLength: UnitHelper.userValueToServerValue(unitTypes.LENGTH, nest.rollLength, 2),
        rollWidth: UnitHelper.userValueToServerValue(unitTypes.WIDTH, nest.rollWidth, 2),
        nestWidth: UnitHelper.userValueToServerValue(unitTypes.WIDTH,nest.nestWidth, 2),
        materialUsedAmount: UnitHelper.userValueToServerValue(unitTypes.LENGTH, nestWithRoll.materialUsedAmount, 2),
        materialScrapAmount: UnitHelper.userValueToServerValue(unitTypes.LENGTH, nestWithRoll.materialScrapAmount, 2),
        scrap: UnitHelper.userValueToServerValue(unitTypes.LENGTH, nest.scrap, 2),
      }));

      delete updatedNest.isRowDisabled;
      delete updatedNest.rollBusinessId;
      delete updatedNest.materialBusinessId;
      delete updatedNest.rollLength;
      delete updatedNest.rollWidth;
      delete updatedNest.nestWidth;
      delete updatedNest.quantityKittedPlies;
      delete updatedNest.materialUsedAmount;
      delete updatedNest.materialScrapAmount;

      updatedNests.push(updatedNest);
    });

    let queryData = {
      locationId: locationPageApi.getLocationId(dispatch, getState)(),
      importCutPlanWithRollGroupedByNestModel: updatedNests,
    };

    importNestsService.importNestsWithRollValidateFile(queryData)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.validateFileFailed());
          dispatch(actions.showSelectedNestsErrors(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Validate File Failed', response);
          return null;
        }

        if (response.data.generalError || response.data.jobStatus !== 'COMPLETED') {
          dispatch(actions.validateFileFailed());
          _handleValidationError(dispatch, getState)(response);
          return null;
        }

        let validationJobId = response.data.jobExecutionId;
        importNestsService.importNestsWithRollGetValidationInfo( {validationJobId} )
          .then((response2) => {
            if (!Network.isResponseValid(response2)) {
              dispatch(actions.validateFileFailed());
              dispatch(actions.showSelectedNestsErrors(ImportResponseHelper.NormalizeResponseError(response2)));
              console.error('Get Validation info failed', response2);
              return null;
            }

            dispatch(actions.validateFileFinished({data: response2.data, token, validationJobId}));
            importNestsWithRollWizardApi.gotoStep(dispatch, getState)('step_4');
          });
      });
  }
};

api.importFile = function (dispatch, getState) {
  return function (data, messageDialogBuilder, reloadParentComponent) {
    dispatch(actions.importFileInProgress());
    importNestsService.importNestsWithRollImportFile({jobId: getState().importNestsWithRoll.get('validationJobId')})
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.importFileFailed(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Import File Failed' + ' '  + response);
          return null;
        }
        dispatch(actions.importFileFinished(response.data));

        importNestsWithRollWizardApi.close(dispatch, getState)();

        if (reloadParentComponent) {
          reloadParentComponent(dispatch, getState);
        }

        if (messageDialogBuilder) {
          // the actionType is used for getting the relevant message (bug 3626)
          response.data.actionType = "createOrUpdate";
          let messageDialogDescriptor = messageDialogBuilder(
            response.data
          );
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }
      });
  }
};

api.importFileWithProgress = function (dispatch, getState) {
  return function (data, messageDialogBuilder, reloadParentComponent) {
    dispatch(actions.importFileInProgress());

    let selectedNests = [];
    data.nestItems.map((nest) => {
      if (nest.selected) {
        selectedNests.push({...nest, lengthCut: UnitHelper.serverValueToUserValue(unitTypes.LENGTH,nest.lengthCut, 2)});
      }
    });

    let queryData = {
      rollId: data.rollId,
      userFileName: data.userFileName,
      materialBusinessId: data.materialBusinessId,
      locationId: locationPageApi.getLocationId(dispatch, getState)(),
      cutAndKitItemsGroupedByNest: selectedNests,
    };

    importNestsService.startImportFile(queryData)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.importFileFailed(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Import File Failed' + ' '  + response);
          return null;
        }
        dispatch(actions.importFileFinished(response.data));

        importNestsWithRollWizardApi.close(dispatch, getState)();

        let pollingRequest = 'batchJobs/JOB_EXECUTION_ID/status-with-printing';

        batchJobsApi.runJob(dispatch, getState)(response.data, {pollingRequest} )
          .then((jobResponse) => {
            let runningInBackground = (jobResponse && jobResponse.data && jobResponse.data.runningInBackground);
            if (reloadParentComponent) {
              reloadParentComponent(dispatch, getState);
            }

            if (!runningInBackground && messageDialogBuilder) {
              // the actionType is used for getting the relevant message (bug 3626)
              response.data.actionType = "createOrUpdate";
              let messageDialogDescriptor = messageDialogBuilder(
                response.data
              );
              messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
            }
          })
          .catch (error => {
            console.error('importFile.runJob failed, catch error: ', error);
          });

      });
  }
};

function _handleValidationError (dispatch, getState) {
  return function (response) {
    if (response.data.generalError) {
      let generalErrorLabels = createLabelHelper('mat.wizards.import.files.importCutPlanWithRoll.validation.grid.operationstatus.');
      let responseData = response.data;

      switch (responseData.generalError.labelType) {
        case 'LENGTH_CM':
          dispatch(actions.showSelectedNestsErrors({
            message: generalErrorLabels.get(
              responseData.generalError.labelKey,
              undefined,
              {
                nestsLength: UnitHelper.serverValueToUserValue(unitTypes.LENGTH, responseData.generalError.params.nestsLength, 2),
                nestsWidth: UnitHelper.serverValueToUserValue(unitTypes.WIDTH, responseData.generalError.params.nestsWidth, 2),
                rollWidth: UnitHelper.serverValueToUserValue(unitTypes.WIDTH,responseData.generalError.params.rollWidth, 2),
                rollLength: UnitHelper.serverValueToUserValue(unitTypes.LENGTH,responseData.generalError.params.rollLength, 2)
              })
          }));
          return;
        case 'STRING':
          dispatch(actions.showSelectedNestsErrors({
            message: generalErrorLabels.get(responseData.generalError.labelKey, undefined, responseData.generalError.params)
          }));
          return;
        default:
          console.error('ImportNestsValidateFile unknown generalError labelType: ' + responseData.generalError.labelType);
      }
    }

    if (response.data.jobStatus !== 'COMPLETED') {
      console.error('Validate File Failed', response);
      messageDialogApi.showError(dispatch, getState)();
    }
  }
}


import { Map } from 'immutable';
import { actionTypes as commonAdditionalFieldsViewActions } from '../../../actions/Administration/SettingsPage/commonAdditionalFieldsViewActions';

let defaultState = {
  loading : false,
  fields: null,
  fieldTypes: null
};


export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case commonAdditionalFieldsViewActions.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_VIEW_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case commonAdditionalFieldsViewActions.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_SUBMIT_FINISHED:
      return state.set('loading', false);

    case commonAdditionalFieldsViewActions.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_SUBMIT_FAILED:
      return state.set('loading', false);

    case commonAdditionalFieldsViewActions.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_FETCH_DATA_IN_PROGRESS:
      return state.set('loading', true);

    case commonAdditionalFieldsViewActions.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_FETCH_DATA_FINISHED:

      state = state.set('loading', false);
      state = state.set('fieldTypes', action.payload.fieldTypes);
      state = state.set('fields', action.payload.fields);
      return state;

    case commonAdditionalFieldsViewActions.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_RESET_DATA:
      return Map(defaultState);

    default:
      return state;
  }
}













import Network from 'infrastructure/js/modules/network';
import * as pickListsService from '../../../services/PickLists/pickListsService';
import * as daHelper                from '../dialogActionsHelper';
import {dialogsNames}             from '../../../enums/dialogsNames';
import * as locationsService from '../../../services/Locations/locationsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import PermissionManager from "infrastructure/js/utils/permissionManager";
import {api as locationPageApi} from "../../LocationPage/locationPageActions";

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.ADD_TO_PICK_LIST_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.ADD_TO_PICK_LIST_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(data = null) {
      api.fetchDialogData(dispatch, getState)().then((response) => {
        dispatch(actions.show(data));
      });
    }
  },

  fetchDialogData(dispatch, getState) {
    return function () {

      // let promise1 = pickListsService.fetchPickLists();
      let promise2 = locationsService.fetchActiveLocations();

      return Promise.all([/*promise1,*/ promise2]).then((allResults) => {
      const invalidResponse = allResults.find(response => {
        return !Network.isResponseValid(response);
      });
      if(invalidResponse){
        console.error('Failed to get the Pick List dialog data', invalidResponse);
        messageDialogApi.responseError(dispatch, getState)(invalidResponse);
        dispatch(actions.fetchDialogDataReady());
        return {success: false};
      }


      // return pickListsService.fetchPickLists().then((response) => {

        // if (!Network.isResponseValid(response)) {
        //   console.error('Fetch pick lists failed');
        //   return {success: false};
        // }
        // let pickLists = allResults[0].dataList;
        let destinations = allResults[0].dataList
        // let pickLists = response?.dataList ? response.dataList.map((obj) => {
        //   return {value: obj.id, label: obj.businessId}
        // }) : [];

        let dialogData = {
          // pickLists: pickLists?.map((obj) => {return {value: obj.id, label: obj.businessId} }) || [],
          destinations: destinations?.map((obj)=> { return { value: obj.id, label: obj.name} }) || []
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return dialogData;
      });
    }
  },

  generateAssetId: function(dispatch, getState) {
    return function() {
      return pickListsService.generatePickListId()
        .then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error('Generate pick list Id failed', response);
            return {success: false};
          }
          return response.data;
        });
    };
  },

  submit: function(dispatch, getState) {
    return function(data, isEditMode, messageDialogBuilder, reloadParentComponent) {

      dispatch(actions.setBusy(true));

      let pickListBusinessId = data?.businessId;

      if (PermissionManager.getOrgPreferences().smartSelectionPickDatesRangeEnabled) {
        data.woPickDateFrom = getState().predefinedList.getIn(['smartSelectionHeader', 'selectWoPickDateFrom']) || null;
        data.woPickDateTo = getState().predefinedList.getIn(['smartSelectionHeader', 'selectWoPickDateTo']) || null;
      }
      else {
        data.woPickDateFrom = getState().predefinedList.getIn(['smartSelectionHeader', 'selectWoPickDate']) || null;
      }

      let method = isEditMode ? pickListsService.addAssetsToPickList : pickListsService.createPickList;
      method(data).then((response) => {
        dispatch(actions.setBusy(false));

        let validations = dialogHelper.getResponseValidationDetails(response);
        if (validations) {
          dispatch(actions.setValidationWarningsData(validations));
          return {success: false};
        }

        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Create/Edit pick list failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }
        let label = isEditMode ? 'mat.dialog.addToPickListDialog.confirm.edit' : 'mat.dialog.addToPickListDialog.confirm.create';
        messageDialogApi.showSuccess(dispatch, getState)(label, {pickList: pickListBusinessId});

        if (PermissionManager.hasLocationSmartSelectionTabPermissions()) {
          return locationPageApi.reload(dispatch, getState)();
        }

        return {success: true};
      });
    };
  },

  // getMessageDialogBuilderData(dispatch, getState) {
  //   return function (response) {
  //     let locationId = locationPageApi.getLocationId(dispatch, getState)();
  //     return [
  //       response.data,
  //       messageDialogApi.close(dispatch, getState),
  //       () => api.printRfidTag(dispatch, getState)(response, locationId)
  //
  //     ];
  //   }
  // }

};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...daHelper.getJsxActions(api),

  submit: function (data, isEditMode, messageDialogBuilder, reloadParentComponent) {
    return function (dispatch, getState) {
      return api.submit(dispatch, getState)(data, isEditMode, messageDialogBuilder, reloadParentComponent);
    };
  },

  generateAssetId: function () {
    return function (dispatch, getState) {
      return api.generateAssetId(dispatch, getState)();
    };
  }
}






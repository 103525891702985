import React, { Component } from 'react';
import classNames from 'classnames';
import {ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import {navigationStates} from '../../../../../enums/navigationStates';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper'
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./progressChart.scss');


export default class ProgressChart extends Component {

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.dashboard.woProgress.');

    this.state = {rowPlannedIndicatorWidth: 1230} //by default 1230px
  }

  // onChartClickHandler = (e) => {
  //   // console.log(e);
  // };

  getChartData = () => {
    let {chartData} = this.props;
    return chartData ? chartData : [];
  };

  getChartMetadata = () => {
    let {chartMetadata} = this.props;
    return chartMetadata ? chartMetadata : [];
  };

  renderTimeFormat = () => {
    if(!PermissionManager.isDateFormatEnabled()){
      return null;
    }
    return (<div>
      {DateTimeHelper.getDateFormat()}
    </div>)
  };

  renderTooltip = (props) => {
    const { active, payload } = props;

    if (active && payload && payload.length > 0) {
      let title = <div className="tooltip-title" >{payload[0].payload.woName}</div>;
      let dueDate = payload[0].payload.dueDate ? payload[0].payload.dueDate : '';
      let bottom = <div className="tooltip-bottom">
        <div>
          {`${this.labels.get('chart.tooltip.woDueDate')} ${dueDate}`}
        </div>
        {this.renderTimeFormat()}
      </div>;
      let content = payload.map((item, index) => {
        return (
            <div key={item.name + index}  className="tooltip-item">
              <span><i className="fa fa-square" style={{ color: item.fill }}></i></span>
              <span>{item.value}</span>
              <span>{'h'}</span>
              <span>{item.name}</span>
              </div>
        )
      });
      return (
        <div className="custom-tooltip">
          {title}
          {content}
          {bottom}
        </div>
      );
    }
    return null;
  };

  renderTable = () => {
    let chartData = this.getChartData();

    let items = chartData.map((item, index) => {
      let woValue = {
        id: item.woId,
        label: item.woName,
        type: navigationStates.WORKORDER
      };
      let kitValue = {
        id: item.kitId,
        label: item.kitName,
        type: navigationStates.KIT
      };
      return  <div className='item-row' key={`${item.woId}-${item.kitId}-${index}`}>
        {!item.woId && <div className='entity-id-cell'>{item.woName}</div>}
        {!!item.woId && <EntityIdCell  className='entity-id-cell' value={woValue}/>}
        <EntityIdCell  className='entity-id-cell' value={kitValue}/>
      </div>
    });
    return (
        <div className="table-section">
          { items }
          <div className="row-planned-indicator" style={{width: this.state.rowPlannedIndicatorWidth}}>
            {this.labels.get('chart.planned')}
            </div>
        </div>
      )
  };

  renderChart = () => {
    let chartData = this.getChartData();
    let chartMetadata = this.getChartMetadata();
    let height = chartData && chartData.length > 0 ? chartData.length * 30 + 50 : 0;

    let BAR_SIZE = 7;
    let BAR_RADIUS = 7;


    let bars = [];
    chartMetadata.forEach((value, key, map) => {
      bars.push(<Bar key={key} dataKey={key} name={value.opName} legendType={value.opLegend} barSize={BAR_SIZE} fill={value.opColor} stackId="stack" radius={[BAR_RADIUS,BAR_RADIUS,BAR_RADIUS,BAR_RADIUS]}/>)
    });

    return (
      <div className={classNames('chart-section', {'legend-bottom': (window.innerHeight - height < 380)})}>
        <ComposedChart layout="vertical"
                       width={800} height={height}
                       data={chartData}
                       margin={{top: 0, right: 20, bottom: 0, left: 0}}
                       // onClick={this.onChartClickHandler}
      >
        <CartesianGrid stroke='#cecece' horizontal={false}/>
        <XAxis type="number" orientation="top" axisLine={false} tickLine={false} tickCount={10} />
        <YAxis dataKey="name3" type="category" axisLine={false} tickLine={false}/>
        <Tooltip content={this.renderTooltip}/>
        <Legend  wrapperStyle={{ bottom: 20 }}/>
        {bars}
      </ComposedChart>
      </div>
    );
  };

  // renderPagination = () => {
  //   let paginationData = {
  //     first: true,
  //     last: true,
  //     number: 0,
  //     numberOfElements: 7,
  //     size: 100,
  //     totalElements: 7,
  //     totalPages: 1,
  //     totalUnfiltered: 0,
  //   };
  //
  //   return (
  //     <Pagination  paginationData={paginationData}/>
  //   )
  // };


  setChartContentRef = (ref) => {
    this.chartContentRef = ref;
    if (this.chartContentRef) {
      this.setState({rowPlannedIndicatorWidth: this.chartContentRef.clientWidth});
    }
  };

  render() {
    return (
      <div className="progress-chart">

        <div className="chart-header">
          <div className="chart-header-column">{this.labels.get('chart.columns.workOrder.title')}</div>
          <div className="chart-header-column">{this.labels.get('chart.columns.kit.title')}</div>
          <div className="chart-header-column">{this.labels.get('chart.columns.operationDuration.title')}</div>
        </div>

        <div className="chart-content" ref={this.setChartContentRef}>
          {this.renderTable()}
          {this.renderChart()}
        </div>

        {/*{this.renderPagination()}*/}

      </div>
    )
  }
};






import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {jsxActions} from '../../actions/AuthenticationPageActions/AuthenticationPageActions.js';
import AuthenticationPage from 'infrastructure/js/components/AuthenticationPage/authenticationPage'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(jsxActions, dispatch)
  }
}

let AuthenticationPageContainer = reduxForm({
    form: 'authenticationPage'
  }
)(AuthenticationPage);

AuthenticationPageContainer = connect(
  state => {
    return {
      sData: state.authenticationPageReducer,
    };
  },
  mapDispatchToProps

)(AuthenticationPageContainer);

export default AuthenticationPageContainer;

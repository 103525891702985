import React, { Component } from 'react';
import Button from 'infrastructure/js/components/controls/Button/button';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import { reduxForm } from 'redux-form';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import SchedulerAnalyticModeSettingsDialog from '../SchedulerAnalyticModeDialog/SchedulerAnalyticModeSettingsDialog';
import classNames from 'classnames';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import './SchedulerAnalyticModePanel.scss';

export class SchedulerAnalyticModePanel extends Component {
    constructor(props) {
        super(props);

        this.labels = createLabelHelper('mat.scheduler.analyticMode.');

        this.state = {
            settingsCollapsed: false,
            showSettingsDialog: false,
        };
    }

    componentDidMount() {
        this.props.actions.init();

        const previouslySelectedGoals = JSON.parse(localStorage.getItem('analyticModeData'))?.goals;
        if (previouslySelectedGoals) {
            let initialValues = {
                goals: previouslySelectedGoals,
            };
            this.props.initialize(initialValues);
        }
    }

    toggleCollapsed = () => {
        this.setState({ settingsCollapsed: !this.state.settingsCollapsed });
    };

    toggleSettingsDialog = (show) => {
        this.setState({ showSettingsDialog: show });
    };

    onSubmit = (data) => {
      const goals = data?.goals?.map(({value}) => value);
      this.props.actions.runSimulation(goals)
    }

    render() {
      const isLoading = this.props.sData.get('loading')
        return (
            <div className='panel-root'>
                <InputSection
                    label={this.labels.get('panel.goals')}
                    htmlFor='goals'
                    className='goals-section'
                >
                    <PL_MultiSelectField
                        id='goals'
                        name='goals'
                        options={this.props.sData.get('schedulingGoals')}
                        allowNewOption={false}
                        closeMenuOnSelect={false}
                        className='multi-select-field'
                    />
                </InputSection>
                <Button
                    id={'schedulerAnalyticModeSettings'}
                    className={classNames('main-operation border-btn no-icon')}
                    bsStyle='default'
                    onClick={this.props.handleSubmit(this.onSubmit)}
                    disabled={isLoading}
                    loading={isLoading}
                >
                  {this.labels.get('panel.runSimulation')}
                </Button>
                <InputSection
                    label={this.labels.get('panel.settings')}
                    htmlFor='settings'
                    className='settings-section'
                >
                    <Button
                        id={'schedulerAnalyticModeSettings'}
                        className={classNames('main-operation border-btn', 'only-icon')}
                        icon='pl pl-header-settings-icon'
                        // loading={button.loading}
                        onClick={() => this.props.actions.analyticModeSettingsDialogActions.show()}
                        disabled={false}
                    />
                </InputSection>
                <InputSection
                    label={this.labels.get('panel.insights')}
                    className='insights-section'
                >
                    <span
                        tabIndex='1'
                        key={this.state.rotateDummyKey}
                        className={classNames('pl pl-notifications pointer')}
                        // onClick={this.toggleNotifications}
                    >
                        <span
                            className={classNames('notifications-label', {
                                pulse: true,
                            })}
                        >
                            1
                        </span>
                    </span>
                </InputSection>

                <PL_DialogWrapper
                    dialogComponent={SchedulerAnalyticModeSettingsDialog}
                    show={this.props.sAnalyticModeSettingsDialog.get('show')}
                    actions={this.props.actions.analyticModeSettingsDialogActions}
                    sData={this.props.sAnalyticModeSettingsDialog}
                />
            </div>
        );
    }
}

export default reduxForm({
    form: 'schedulerAnalyticModePanel',
})(SchedulerAnalyticModePanel);

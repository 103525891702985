import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as terminologyService from '../../../services/Administration/terminologyService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_SETTINGS_TERMINOLOGY, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {

    let terminologyView = getState().administration.getIn(['settingsPage', 'terminologyView']);
    if (terminologyView) {
      let currentLocaleId = terminologyView.get('localeId');
      if (currentLocaleId) {
        let allMacrosMode = terminologyView.get('allMacrosMode');
        let currentMacro = terminologyView.get('currentMacro');

        query.localeId = currentLocaleId;
        query.isMacrosOnly = allMacrosMode;
        query.macroSetNameType = currentMacro;

        return terminologyService.fetchTerminologyLabels(query);
      }
    }
    return Promise.resolve({});
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

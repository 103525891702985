
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import { api as wizardApi }  from 'infrastructure/js/components/Wizard/wizardActions';
import Network from 'infrastructure/js/modules/network';
import * as assetTypesService  from '../../../../services/AssetTypes/assetTypesService';
import {api as assetTypesViewApi} from '../assetTypesViewActions.js';

let createAssetTypeWizard_WIZARD_Api  = wizardApi.createWizardSpecificAdapter('CREATE_ASSET_TYPE_WIAZRD');


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {

  CREATEASSETTYPE_WIZARD_FETCH_INITIAL_DATA_READY:       'CREATEASSETTYPE_WIZARD_FETCH_INITIAL_DATA_READY',
  // CREATEASSETTYPE_WIZARD_SAVE_START_PARAMETERS:          'CREATEASSETTYPE_WIZARD_SAVE_START_PARAMETERS',
  CREATEASSETTYPE_WIZARD_SAVE_STEP_ONE_DATA:             'CREATEASSETTYPE_WIZARD_SAVE_STEP_ONE_DATA',
  CREATEASSETTYPE_WIZARD_FETCH_STEP_TWO_DATA_READY:      'CREATEASSETTYPE_WIZARD_FETCH_STEP_TWO_DATA_READY',

  CREATEASSETTYPE_WIZARD_SUBMIT_STARTED:                 'CREATEASSETTYPE_WIZARD_SUBMIT_STARTED',
  CREATEASSETTYPE_WIZARD_SUBMIT_FINISHED:                'CREATEASSETTYPE_WIZARD_SUBMIT_FINISHED',
  CREATEASSETTYPE_WIZARD_RESET:                          'CREATEASSETTYPE_WIZARD_RESET',

  CREATEASSETTYPE_WIZARD_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: 'CREATEASSETTYPE_WIZARD_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
  CREATEASSETTYPE_WIZARD_SET_VALIDATION_WARNINGS_DATA:    'CREATEASSETTYPE_WIZARD_SET_VALIDATION_WARNINGS_DATA'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {

  fetchInitialDataReady: function(payload) {
    return {type: actionTypes.CREATEASSETTYPE_WIZARD_FETCH_INITIAL_DATA_READY, payload: payload };
  },

  // saveStartParameters: function(payload) {
  //   return {type: actionTypes.CREATEASSETTYPE_WIZARD_SAVE_START_PARAMETERS, payload: payload };
  // },

  saveStepOneData: function(payload) {
    return {type: actionTypes.CREATEASSETTYPE_WIZARD_SAVE_STEP_ONE_DATA, payload: payload };
  },
  fetchStepTwoDataReady: function(payload) {
    return {type: actionTypes.CREATEASSETTYPE_WIZARD_FETCH_STEP_TWO_DATA_READY, payload: payload };
  },

  submitStarted: function(payload) {
    return {type: actionTypes.CREATEASSETTYPE_WIZARD_SUBMIT_STARTED, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATEASSETTYPE_WIZARD_SUBMIT_FINISHED, payload: payload };
  },

  reset: function(payload) {
    return {type: actionTypes.CREATEASSETTYPE_WIZARD_RESET, payload: payload };
  },

  showIgnoreValidationCheckbox: function (payload) {
    return {type: actionTypes.CREATEASSETTYPE_WIZARD_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX, payload: payload};
  },
  setValidationMessage: function (payload) {
    return {type: actionTypes.CREATEASSETTYPE_WIZARD_SET_VALIDATION_WARNINGS_DATA, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};



jsxActions.onWizardSubmit = function(formData) {
  return function(dispatch, getState) {
    return api.onWizardSubmit (dispatch, getState)(formData);
  };
};

jsxActions.showIgnoreValidationCheckbox = function(value) {
  return function(dispatch, getState) {
    api.showIgnoreValidationCheckbox(dispatch, getState)(value);
  };
};

jsxActions.saveStepOneData = function(formData, callback) {
  return function(dispatch, getState) {
    return api.saveStepOneData (dispatch, getState)(formData, callback);
  };
};

jsxActions.onCancel = function (messageDialogBuilder) {
  return function (dispatch, getState) {
    api.closeWizard(dispatch, getState)();
  };
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.openWizard = function(dispatch, getState) {
  return function(data) {

    api.fetchInitialWizardData(dispatch, getState)(data)
      .then((response) => {

       if (response.success) {

         //Open wizard
         createAssetTypeWizard_WIZARD_Api.start(dispatch, getState)();
       }
    })
  };
};

api.closeWizard = function(dispatch, getState) {
  return function() {
    createAssetTypeWizard_WIZARD_Api.close(dispatch, getState)();

    // Destroy wizard sData.
    dispatch(actions.reset());
  }
};

api.showIgnoreValidationCheckbox = function(dispatch, getState) {
  return function(value) {
    dispatch(actions.showIgnoreValidationCheckbox(value));
  }
};

api.saveStepOneData = function (dispatch, getState) {
  return function (formData, callback) {

      dispatch(actions.saveStepOneData(formData));

      let data = {
        assetTypeId: formData.assetTypeId ? formData.assetTypeId : null,
        parentId: formData.parent ? formData.parent : null,
        natureDefIds: formData.natures ? formData.natures.map((item) => item.nature) : []
      };

      api.fetchStepTwoData(dispatch, getState)(data)
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Fetch naturesData failed');
            api.closeWizard(dispatch, getState)();
            messageDialogApi.responseError(dispatch, getState)(response);
            return {response};
          }

          if (callback) {
            callback();
          }
        });
  };
};

api.fetchInitialWizardData = function (dispatch, getState) {
  return function (itemToEdit) {

    let promise1 = assetTypesService.fetchAllParents();
    let promise2 = assetTypesService.fetchAllNatures();
    let promise3 = assetTypesService.fetchAllIcons();

    let promises = [promise1, promise2, promise3];
    if (itemToEdit) {
      let promise4 = assetTypesService.getAssetType(itemToEdit.id);  //????
      promises.push(promise4);
    }

    return Promise.all(promises).then((allResults) => {

      let isResultValid = allResults.every((result, index) => {
        return Network.isResponseValid(result);
      });
      if (!isResultValid) {
        console.error('Get Asset Types initial data failed.');
        return {success: false};
      }

      let parents = allResults[0].dataList || [];
      let natures = allResults[1].dataList || [];
      let icons = allResults[2].dataList || [];
      let itemToEditData = allResults[3] && allResults[3].data ? allResults[3].data : null;

      let initialData = {
        parents:  parents.map( (obj) => { return { value: obj.id, label: obj.name, data: obj } }),
        natures:  natures.map( (obj) => { return { value: obj.id, label: obj.displayName, data: obj } }),
        icons:    icons,
        itemToEdit: itemToEditData,
      };

      dispatch(actions.fetchInitialDataReady(initialData));
      return {success: true};
    });
  };
};

api.fetchStepTwoData = function (dispatch, getState) {
  return function (data) {
    return assetTypesService.fetchPropsAndActionsByNaturesIds(data)
      .then((response) => {

        if (!Network.isResponseValid(response)) {
          console.error('Fetch naturesData failed');
          return response;
        }

        // let validations = dialogHelper.getResponseValidationDetails(response);
        // if (validations){
        //   dispatch(actions.setValidationMessage(validations));
        //   return {success: false};
        // }

        let stepTtoData = response.data;

        dispatch(actions.fetchStepTwoDataReady(stepTtoData));
        return response;
      });
  }
};


api.onWizardSubmit = function (dispatch, getState) {
  return function (formData) {

    let stepOneData =  getState().administration.getIn(['matSettingsPage', 'createAssetTypeWizard' , 'stepOneData']);

    let submitData = {};

    submitData.id = formData.assetTypeId || null;
    submitData.parentId = stepOneData.parent ? stepOneData.parent : null;
    submitData.name = stepOneData.nameSingular;
    submitData.pluralName = stepOneData.namePlural;
    submitData.category = stepOneData.category || false;
    submitData.icon = stepOneData.icon ? stepOneData.icon : '';

    if (formData.properties) {
      let properties = Object.keys(formData.properties).map((key, index) => {
        let item = formData.properties[key];
        return {
          propertyDefId: +key.substr(2),
          orderId: index,
          visible: item.isVisible || false,
          editable: item.isEditable || false,
          mandatory: item.isMandatory || false,
          showInGrid: item.isShowInGrid || false,
          defaultValue: item.defaultValue,
          maxValue: item.maxValue ? +item.maxValue : 0,
          minValue: item.minValue ? +item.minValue : 0,
          displayedChars: item.displayedChars ? +item.displayedChars : 0,
          displayedDigits: item.displayedDigits ? +item.displayedDigits : 0,
          displayedDecimalDigits: item.displayedDecimalDigits ? +item.displayedDecimalDigits : 2,
        }
      });
      submitData.properties = properties;
    }

    if (formData.actions) {
      let actions = Object.keys(formData.actions).map((key, index) => {
        let item = formData.actions[key];
        return {
          actionDefId: +key.substr(2),
          orderId: index,
          visible: item.isVisible || false,
          primary: item.isPrimary || false,
        }
      });
      submitData.actions = actions;
    }

    dispatch(actions.submitStarted());

    assetTypesService.createAssetType(submitData)
      .then((response) => {

        dispatch(actions.submitFinished());
        api.closeWizard(dispatch, getState)();

        if (!Network.isResponseValid(response)) {
          console.error('Create Asset Type failed');
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        assetTypesViewApi.load(dispatch, getState)(true);

        return {success: true};
      });

  };
};

////////////////////////////////////////////////////////////////////////////////////





import React from 'react';

import {reduxForm} from 'redux-form';
import {Prompt} from 'react-router-dom';
import classNames from 'classnames';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import TerminologyViewGrid from './TerminologyViewGrid/terminologyViewGrid';
import {SettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import EditTerminologyDialog from './Dialogs/editTerminologyDialog.js';
import PermissionManger from 'infrastructure/js/utils/permissionManager';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./terminologyView.scss');


class TerminologyView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.terminology.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');

    this.allLabelsMode = PermissionManger.hasAllLabelsModeInTerminologyPermissions();

    this.submitter = this.props.handleSubmit(this.onSubmit);

    this.state = {
      isMacrosOnlySelected: !this.allLabelsMode,
      currentLocaleId: -1,
    };
  }

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_TERMINOLOGY);

    this.props.actions.fetchLocales().then(() => {
      this.init();
    })
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  init() {
    let initialLocale = this.getInitialLocale();
    let initialValues = {
      locale: initialLocale,
      macrosOnly: this.state.isMacrosOnlySelected,
    };
    this.props.initialize(initialValues);

    if (initialLocale) {
      let initialLocaleId = initialLocale.value;
      let newData = {
        localeId: initialLocaleId,
        allMacrosMode: this.state.isMacrosOnlySelected,
        currentMacro: null,
      };

      this.props.actions.init(newData);

      this.setState({currentLocaleId: initialLocaleId});
    }
  }

  getInitialLocale = () => {
    let systemLocale = PermissionManager.getOrgPreferences().systemLocale;

    let locales = this.getLocalesOptions();
    let found = locales.find((item) => {
      return (item.data && item.data.localeCode === systemLocale)
    });
    return found ? found : (locales.length > 0) ? locales[0] : null;
  };


  getHeaderItems = () => {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      title: this.labels.get('title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.edit,
          actionData: selectedRowsData.get(0)
        },
        {
          id:'reset',
          label: headerItemsLabels.RESET_TO_DEFAULT,
          className: 'no-icon',
          disabled: !(hasRows && selectedRowsData.size > 0),
          action: this.resetToDefaultHandler,
          actionData: selectedRowsData
        },
        {
          id:'preview',
          label: headerItemsLabels.PREVIEW,
          className: 'no-icon',
          action: actions.preview,
          actionData: {
            previewMode:true,
            localeId: this.state.currentLocaleId
          },
        },
        {
          id:'apply',
          label: headerItemsLabels.APPLY,
          className: 'no-icon',
          disabled: !(hasRows && selectedRowsData.size > 0 && this.hasUnpublishedData()),
          action: this.applyChangesHandler,
          actionData: selectedRowsData
        },
      ],
      // kebab: {
      //   // disabled: !selectedRowsData || selectedRowsData.size === 0,
      //   menuItems: [
      //     {
      //       id:'import',
      //       label: headerItemsLabels.IMPORT,
      //       // action: actions.header.onActivateProjectsClick,
      //       //disabled: true,//(hasRows && (!selectedRowsData || selectedRowsData.size === 0 || isAllItemsActive(selectedRowsData))),
      //       //actionData:  selectedRowsData,
      //     },
      //     {
      //       id:'export',
      //       label: headerItemsLabels.EXPORT,
      //       //action: actions.header.onDeactivateProjectsClick,
      //       //disabled: true,//(hasRows && (!selectedRowsData || selectedRowsData.size === 0 || isAllItemsDeactive(selectedRowsData))),
      //       //actionData:  selectedRowsData,
      //     },
      //
      //   ]
      // }
    };
  };

  convertToActionData = (data) => {
    let newData = {
      localeId: this.state.currentLocaleId,
    };

    if (data && data.size > 0) {
      let labelsKeys = [];
      data.forEach((item) => {
        labelsKeys.push( item.labelKey);
      });
      newData.labelsKeys = labelsKeys;
    }
    return newData;
  };

  resetToDefaultHandler = (data) => {
    let actionData = this.convertToActionData(data);
    this.props.actions.resetToDefault(actionData, this.getMessageDialogBuilderForReset());
  };

  getMessageDialogBuilderForReset = (msg) => {
    return (fnCloseDialog, callback) => {
      let title = this.labels.get('resetToDefault.confirmation.title');
      let type = 'warning';
      let message = this.labels.get('resetToDefault.confirmation.message');

      let children =  [<div key={'confirmMessageDataRow'} className="terminology-confirm-message">{message}</div>];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'reset',text: this.labels.get('resetToDefault.confirmation.footer.reset'), action: callback , bsStyle: 'primary'}
      ];
      let className = '';
      let messageDialogDescriptor = {title, children, buttons, className, type};
      return messageDialogDescriptor;
    };
  };

  applyChangesHandler = (data) => {
    let actionData = this.convertToActionData(data);
    this.props.actions.applyChanges(actionData, this.getMessageDialogBuilderForApply());
  };

  getMessageDialogBuilderForApply = (msg) => {
    return (fnCloseDialog, callback) => {
      let title = this.labels.get('apply.confirmation.title');
      let type = 'warning';
      let message = this.labels.get('apply.confirmation.message');

      let children =  [<div key={'confirmMessageDataRow'} className="terminology-confirm-message">{message}</div>];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'reset',text: this.labels.get('apply.confirmation.footer.apply'), action: callback , bsStyle: 'primary'}
      ];
      let className = '';
      let messageDialogDescriptor = {title, children, buttons, className, type};
      return messageDialogDescriptor;
    };
  };


  getLocalesOptions = () => {
    let {sData} = this.props;
    let localesOptions = sData ? sData.get('localesOptions') : null;
    return localesOptions ? localesOptions : [];
  };

  getMacrosOptions = () => {
    let {sData} = this.props;
    let macrosOptions = sData ? sData.get('macrosOptions') : null;
    return macrosOptions ? macrosOptions : [];
  };

  onLocaleChangeHandler = (newValue, oldValue) => {
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    //prevent clearing
    if ( !newValue && oldValue && oldValue.value) {
      this.props.change('locale', oldValue);
      return;
    }
    //reload macros set names
    this.props.change('macros', null);
    this.props.actions.fetchMacroSetsNames(newValue.value);
    this.setState({currentLocaleId: newValue.value});
    setTimeout(() => {this.submitter()}, 0);
  };

  onCheckboxClick = () => {
    this.setState({isMacrosOnlySelected: !this.state.isMacrosOnlySelected});
    setTimeout(() => {this.submitter()}, 0);
  };

  onMacrosChangeHandler = (newValue, oldValue) => {
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    setTimeout(() => {this.submitter()}, 0);
  };

  onSubmit = (data) => {

    let localeId = data.locale ? data.locale.value : null;

    let newData = {
      localeId: localeId,
      allMacrosMode: this.state.isMacrosOnlySelected,
      currentMacro: data.macrosOnly && data.macros ? data.macros.value : null,
    };

    this.props.actions.submit(newData);
  };

  reloadPage = () => {
    this.props.actions.reload(this.state.currentLocaleId);
  };

  // renderLoadingOverlay = () => {
  //   // if (!this.props.sData.get('loading')) {
  //     return null;
  //   // }
  //   return <div className="loading-overlay">
  //     <i className='fa fa-spinner fa-spin fa-fw ' aria-hidden="true"/>
  //   </div>;
  // };

  hasUnpublishedData = () => {
    let {sData} = this.props;
    return sData ? sData.get('hasUnpublishedData') : false;
  };


  renderPrompt = () => {
    return (
      <Prompt
        when={this.hasUnpublishedData()}
        message={() =>
          this.labels.get('dataNotSaved.text')
        }
      />
    );
  };

  renderFilterSection = () => {

    // let {sData} = this.props;
    // let loading = sData ? sData.get('loading') : false;
    return (
      <InputSection htmlFor="mergeColumns" className="filter-section">
        <Combobox id="locale" name="locale"
                  // isDisabled={loading}
                  options={this.getLocalesOptions()}
                  onChangeCallback={this.onLocaleChangeHandler}
        />

        <Checkbox name="macrosOnly" id="macrosOnly"
                  label={this.labels.get('filter.macrosOnly')}
                  disabled={!this.allLabelsMode}
                  // disabled={!this.allLabelsMode || loading}
                  onClick={this.onCheckboxClick}
        />
        {this.state.isMacrosOnlySelected && <Combobox id="macros" name="macros"
                  // isDisabled={loading}
                  options={this.getMacrosOptions()}
                  placeholder={this.labels.get('filter.macro.placeholder')}
                  onChangeCallback={this.onMacrosChangeHandler}
        />}
    </InputSection>
    )
  };

  renderTerminologyGrid = () => {
    if (this.state.currentLocaleId < 0 ) {
      return null;
    }
    return <TerminologyViewGrid actions={this.props.actions.terminologyViewGridActions} />;
  };


  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className='pm-terminology-settings-view'>
        <Header {...headerItems}/>

        {this.renderFilterSection()}

        <div className={classNames('note-section', {'on': this.hasUnpublishedData()})}>{this.labels.get('note')}</div>

        {this.renderTerminologyGrid()}

        <PL_DialogWrapper dialogComponent={EditTerminologyDialog}
                          show={this.props.sEditTerminologyDialogData.get('show')}
                          actions={this.props.actions.editTerminologyDialogActions}
                          sData={this.props.sEditTerminologyDialogData}
                          localeId={this.state.currentLocaleId}
                          reloadParentComponent={this.reloadPage}
        />


        {/*{this.renderLoadingOverlay()}*/}
        {this.renderPrompt()}
      </div>
    );
  }
}

export default reduxForm({
    form: 'terminologyView'
  }
)(TerminologyView);


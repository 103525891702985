import React from 'react';
import PropTypes from 'prop-types';


//Style
require('./filterTable.scss');

export default class PL_FilterTable extends React.PureComponent {

  render() {
    return (
      <div className="filterTable">
        <table>
          <tbody >
            {this.props.rows}
          </tbody>
        </table>
      </div>);
  }
}

PL_FilterTable.propTypes = {
  rows: PropTypes.any,
};



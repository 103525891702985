import React from 'react';
import PropTypes from 'prop-types';


require('./userRolesCell.scss');

export default class UserRolesCell extends React.PureComponent {
  render() {
    let role = this.props.data;
    if (!role) {
      return null;
    }

    return (
      <div className='user-roles-cell'>
           <span>{role}</span>
      </div>
    );
  }
}

UserRolesCell.propTypes = {
  // value : PropTypes.array
};





import React from 'react';

import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';

require('./validationComponent.scss');

const errorIcon = require("infrastructure/assets/images/error.png");

export default class PL_ValidationComponent extends React.PureComponent{

  render() {
    let {touched, error} =  this.props;
    if (touched && error) {
      return (<span className="invalid-icon">
        <AlertPopover
          // trigger="click"
          popoverType={'error'}
          popoverComponent={<div> {error} </div>}
        >
          <img src={errorIcon} />
        </AlertPopover>
      </span>);
    }

    return null;
  }
};

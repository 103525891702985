import { Map } from 'immutable';
import { actionTypes as commonAlertsViewActions } from '../../../actions/Administration/SettingsPage/commonAlertsViewActions';

let defaultState = {
  selectedContentTab: null,
  loading : false,
  rollData : null,
  kitData : null,
  containerData : null
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_TAB_SELECTED:
      return state.set('selectedContentTab', action.payload);


    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_UPDATE_IN_PROGRESS:
      return state.set('loading', true);

    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_UPDATE_FINISHED:
      return state.set('loading', false);

    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_UPDATE_FAILED:
      return state.set('loading', false);

    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_FETCH_DATA_IN_PROGRESS:
      return state.set('loading', true);

    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_FETCH_DATA_FINISHED:
      state = state.set('loading', false);
      state = state.set('rollData', action?.payload?.rollData);
      state = state.set('spoolData', action?.payload?.spoolData);
      state = state.set('kitData', action?.payload?.kitData);
      state = state.set('containerData', action?.payload?.containerData);
      state = state.set('toolData', action?.payload?.toolData);
      state = state.set('taskData', action?.payload?.taskData);
      state = state.set('misplacedMaterialData', action?.payload?.misplacedMaterialData);
      return state;

    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_SET_START_PARAMETERS:
      return Map(defaultState);

    default:
      return state;
  }

  return state;
}













import React from 'react';
import CommonBarcodeSettingsView from '../Components/CommonBarcodeSettingsView/commonBarcodeSettingsView';
import { SettingsMenuItemsIds } from '../../../../../enums/navigationMenuItemsIds';
import { enumTypes, getEnumValue } from '../../../../../utils/enumHelper';

export default class RollBarcodeSettingsView extends React.PureComponent {

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_BARCODE_ROLL);

    this.props.actions.load(getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'));
  }

  render() {
    return (
      <CommonBarcodeSettingsView
        actions={this.props.actions}
        sData={this.props.sData}
        isDirty={this.props.isDirty}
        entityType={getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')}
      />
    );
  }
}

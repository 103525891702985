import React from 'react';

import AssetAlertPopover from '../../Layout/AssetAlertPopover/assetAlertPopover.js';

require('./assetAlertPopoverCell.scss');

export default class AssetAlertPopoverCell extends React.PureComponent {

  getDescription = (value) => {
    if (!value || !value.description) {
      return null;
    }

    return <span className="description">{value.description}</span>;
  };

  render() {
    let {value} = this.props;

    return (<div className="asset-alert-popover-cell">
      {this.getDescription(value)}
      <AssetAlertPopover value={value} />
    </div>);
  }
}










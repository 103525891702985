import Network from 'infrastructure/js/modules/network';

export function fetchOperations(query={}) {
  return Network.post('operation/get-operations' , query);
}

export function fetchAllOperations() {
  return Network.get('operation');
}

export function fetchActiveOperations() {
  return Network.get('operation?isActive=true');
}

export function getOperationTypes() {
  return Network.get('operation/types');
}

export function createOperation(data) {
  return Network.post('operation', data);
}

export function editOperation (data) {
  return Network.post('operation/update', {operations: [data]});
}

export function activateOperations(data) {
  return Network.post('operation/activate', data);
}

export function deactivateOperations(data) {
  return Network.polling.post('operation/deactivate', data);
}

export function removeOperation (ids) {
  return Network.delete('operations/', {operationIds: ids.ids });
}

export function editTasksDuration (data, callback) {
  return Network.polling.put('planner/tasks/duration',  { tasksAssignmentsIds: data.taskIds, duration: data.duration }, {callback});
}

export function editTasksAttributes (data, callback) {
  return Network.polling.put('planner/tasks/attributes',  data, {callback});
}



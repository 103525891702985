import * as systemService from 'infrastructure/js/services/systemService.js';
import LoginService from 'infrastructure/js/services/LoginService.js';
import {api as systemActionsApi} from '../actions/System/systemActions.js';
import network from "infrastructure/js/modules/network.js";
import moment from 'moment';

//todo: check if need to add sync each x time from server.
export let ServerDatetime = {momentDatetime : moment()};

let handleDateTimeActionsInstance = function(){
  let obj = {};
  let _secDatetime = 0;
  let _secInterval = 10;
  let _intervalInstance = 0;

  obj.init = function (dispatch, getState) {

    LoginService.checkLogin().then((response) => {
      if (!network.isResponseValid(response) || !response.data || !response.data.data) {
        return;
      }
      systemService.getServerDatetimeSync().then((response) => handleSuccessResponse(response, dispatch, getState));
    });
  };

  function handleSuccessResponse(response,dispatch, getState ){
    if(!network.isResponseValid(response) || network.isAuthenticationFailed(response, "_getDateTimeFromServer: ")) {
      setTimeout(() => obj.init(dispatch, getState), 3000);
      return;
    }

    runAction(response.data.data, dispatch, getState);
    if(_intervalInstance === 0){
      _intervalInstance = setInterval((data)=>runAction(data, dispatch, getState), _secInterval * 1000);
    }
  }

  let runAction = (dateTime = _secDatetime + _secInterval, dispatch, getState)=>{
    _secDatetime = dateTime;

    if(!isNaN(dateTime)){
      ServerDatetime.momentDatetime = moment(dateTime * 1000);
    }
    systemActionsApi.setServerDatetime(dateTime)(dispatch, getState);
  };

  return obj;

}();
export default handleDateTimeActionsInstance;

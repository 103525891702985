import { Map } from 'immutable';
import { actionTypes as EditKitMaterialsDialogType } from '../../actions/AssetPage/Dialogs/editKitMaterialsDialogActions';


let defaultState = {
  loading: false,
  show: false,
  itemToEdit: null,
  dialogData: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case EditKitMaterialsDialogType.EDIT_KIT_MATERIALS_DIALOG_SHOW:
      state = state.set('itemToEdit', action.payload);
      return state.set('show',true);

    case EditKitMaterialsDialogType.EDIT_KIT_MATERIALS_DIALOG_HIDE:
      return Map(defaultState);

    case EditKitMaterialsDialogType.EDIT_KIT_MATERIALS_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case EditKitMaterialsDialogType.EDIT_KIT_MATERIALS_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);

    case EditKitMaterialsDialogType.EDIT_KIT_MATERIALS_DIALOG_FETCH_DATA_READY:
      return state.set("dialogData", action.payload);
    default:
      return state;
  }
}













import React from 'react';

import MaterialHelper from '../../../../../utils/materialHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip';
import Button from 'infrastructure/js/components/controls/Button/button';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import {isAssetActivated} from '../../../../../utils/assetHelper';

require('./kitMaterialsDetails.scss');

export default class KitMaterialsDetails extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.assetpage.overview.details.');

    // this.state = {inEditMode: false};
  }

  showUpdateKitMaterialsDialog = () => {
    let {asset} = this.props;
    let data = {kitId: asset.id, kittedMaterials: asset.kittedMaterials};
    this.props.actions.show(data);
  };

  renderSubstituteMaterialMark = (item) => {
    if (item && item.isSubstituteMaterial) {
      return <span className="pl pl-substitute-material-mark"></span>
    }
    return null;
  };

  generateRequiredMaterialTooltip = (item) => {
    let material = item.isSubstituteMaterial ? item.substituteMaterial : item.mainMaterial;
    let displayValue = material ? MaterialHelper.getMaterialFullLabel(material.materialName, material.businessId) : '';
    return (
      <div className='tooltip-container' title={displayValue}>{displayValue}</div>
    )};


  // onMouseEnterHandler = () => {
  //   this.setState({inEditMode: true});
  // };

  // onMouseLeaveHandler = (ev) => {
  //   this.setState({inEditMode: false});
  // };

  renderUpdateButton = (asset) => {
    if (isAssetActivated(asset?.assetStatus) ) {
      return (
        <Button id="edit-kit-materials-btn"
                className="edit-kit-materials-btn with-icon"
                icon="pl pl-shelf-life"
                onClick={this.showUpdateKitMaterialsDialog}
                disabled={!PermissionManager.hasEditAssetDetailsPermissions()}
        >
          <span>
            {this.labels.get('updatekitmaterials')}
            <i className="pl pl-move-arrow-right"/>
          </span>
        </Button>
      );
    }
    return null;
  }

  render() {
    let {asset} = this.props;
    if (!asset || !asset.id) {
      return null;
    }
    let materials = [];
    if(asset.kittedMaterials) {
      materials = asset.kittedMaterials.map(item => {
        return (
            <div className='asset-materials-row' key={item.id}>
                <div className='cell'>
                    {this.renderSubstituteMaterialMark(item)}
                    {this.generateRequiredMaterialTooltip(item)}
                </div>
                <div className='cell'>{item.quantityExpectedPlies}</div>
                <div className='cell'>{item.quantityKittedPlies}</div>
                <div className='cell'>{item.materialUsedAmount || 0}</div> {/*this is post unit conversion*/}
            </div>
        );
      });
    }
    return (
      <div className='kit-materials-details'
        //    onMouseEnter={this.onMouseEnterHandler}
        //    onMouseLeave={this.onMouseLeaveHandler}
      >
        <hr/>
        <div className='asset-materials-row header'>
          <div className='cell'>{this.labels.get('requiredmaterials')}</div>
          <div className='cell'>{this.labels.get('expected')}</div>
          <div className='cell'>{this.labels.get('kitted')}</div>
          <div className='cell'>{this.labels.get('amount', 'xxx', {unit: UnitHelper.getLabelForUnitType(PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH) })}</div>
        </div>
        <div className="asset-materials-table-content">
          { materials }
        </div>

        {this.renderUpdateButton(asset)}

      </div>
    );
  };
}


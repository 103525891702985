import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {reduxForm} from 'redux-form';
import ActivityTypeCell from '../../../Common/CustomGridCells/ActivityTypeCell/activityTypeCell.js';
import ActivityDetailsCell from '../../../Common/CustomGridCells/ActivityDetailsCell/activityDetailsCell.js';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {gridsNames} from '../../../../enums/gridsNames';
import MasterDetailRow from 'infrastructure/js/components/Grid/MasterDetailRow/MasterDetailRow'
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import PermissionManager from '../../../../../../infrastructure/js/utils/permissionManager';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
import {getNavigationStateByEntityType} from '../../../../utils/assetHelper';
require('./activitiesGrid.scss');

class ActivitiesGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'activityType', filterName: 'activityType', getOptions: true},
    {fieldName: 'entityBusinessId'},
    {fieldName: 'username', filterName: 'reportedBy', getOptions: true},
    {fieldName: 'time.epochDateTime', filterName: 'fromDate'},
    {fieldName: '', filterName: 'rollBusinessId'},
    {fieldName: '', filterName: 'spoolBusinessId'},
    {fieldName: '', filterName: 'kitBusinessIdSearch'},
    {fieldName: '', filterName: 'woBusinessId'},
    {fieldName: '', filterName: 'toolBusinessId'},
    {fieldName: '', filterName: 'partBusinessId'},
    {fieldName: '', filterName: 'groupBusinessId'}
  ]

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.grid.');
    this.entityLabels = createLabelHelper('mat.entity.type.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'time.epochDateTime',
        title: this.labels.get('columns.time.title'),
        filterType: filterTypes.NONE,
        width: 165,
        filterName: 'fromDate',
        valueFormatter: (params) => {
          return (params.data && params.data.m_Time ? params.data.m_Time : '');
        },
        columnOptions: {
          cellRenderer: 'agGroupCellRenderer',
          sort: 'desc',
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon()),
          valueGetter: (params) => {
            return params.data.time?.epochDateTime;
          }
        }
      },
      {
        fieldName: 'activityType',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'activityType',
        width: 220,
        columnOptions: {
          cellComponent: ActivityTypeCell,
          valueGetter: (params) => {
            return { activityType: params.data.activityType, activityTypeDisplayKey: params.data.activityTypeDisplayKey };
          }
        }
      },
      {
        fieldName: 'details',
        title: this.labels.get('columns.details.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: ActivityDetailsCell,
          valueGetter: (params) => {
            // AT the moment we are supporting only 1, according to product it may change
            if(!params.data.primaryDetails || !params.data.primaryDetails[0]){
              return;
            }
            let primaryActivity = params.data.primaryDetails[0];
            return { key: primaryActivity.key, value: primaryActivity.value, type: primaryActivity.valuetype, isLocalizedKey: primaryActivity.keyIsReadyForDisplay};
          }
        }
      },
      {
        fieldName: 'entityBusinessId',
        title: this.labels.get('columns.entity.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        propertyTypes: this.getMoreEntityFilterProperties(),
        width: 220,
        columnOptions: {
          cellComponent: EntityIdCell,
          sortable: false,
          valueGetter: this.entityValueGetter
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change,
      },
      {
        fieldName: 'username',
        title: this.labels.get('columns.reportedBy.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'reportedBy',
        filterAlignment: filterAlignmentTypes.RIGHT,
        width: 220,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.username;
          }
        }
      },

    ]
  }

  getMoreEntityFilterProperties() {
    let res = [
      {value: 'rollBusinessId',   label: (this.entityLabels.get('roll') || 'Roll')},
      {value: 'spoolBusinessId',   label: (this.entityLabels.get('spool') || 'Spool')},
      {value: 'kitBusinessIdSearch',    label: (this.entityLabels.get('kit') || 'Kit')},
      {value: 'toolBusinessId',    label: (this.entityLabels.get('tool') || 'Tool')},
      {value: 'woBusinessId',     label: (this.entityLabels.get('workorder') || 'Work Order')},
      {value: 'partBusinessId',     label: (this.entityLabels.get('part') || 'Part')},
    ];
    if (PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled) {
      res.push({value: 'groupBusinessId', label: (this.entityLabels.get('group') || 'Group')})
    }
    return res;
  }

  entityValueGetter = (params) => {
    let navState = getNavigationStateByEntityType(params.data.entityType);
    return { id: params.data.entityId, label: params.data.entityBusinessId, type: navState};
  };

  getRowHeight = (params) => {

    let rowHeight = 64;
    //check if row is a master detail row (the return height is for the detail row)
    //then check if need more then a single row
    if (params.node && params.node.detail && params.data && params.data.extendedDetails) {
      let rowCount = Math.ceil(params.data.extendedDetails.length / 4);
      return rowCount > 0 ? rowHeight * rowCount : rowHeight;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="activities-grid">
        <Grid gridName={gridsNames.LOCATION_ACTIVITIES}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  checkboxSelection: false,
                  masterDetailRowComponent: MasterDetailRow,
                  isMasterDetail:true
                }
              }
          >
        </Grid>
      </div>
    );
  }
}


export default reduxForm({
    form: 'ActivitiesGrid'
  }
)(ActivitiesGrid);







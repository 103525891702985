import React from 'react';

import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import PropTypes from "prop-types";
import Validation from "../controlsValidations";
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import classNames from "classnames";
import {createLabelHelper} from "../../../utils/labelHelper";
require('./weekdayAndTime.scss');

export class PL_WeekdayAndTime extends React.PureComponent{
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('timeFrame.');
  }
  getClassName (){
    return this.props.className ? ' ' + this.props.className : '';
  }

  render() {
    return (
     <div className={classNames("week-day-and-time", this.getClassName())}>
       <InputSection label={this.props.label} className="day-of-week">
         <Combobox id={`dayOfWeek${this.props.id}`} name={`dayOfWeek${this.props.id}`} options={DateTimeHelper.getDaysOfWeekOptions()} validate={Validation.required}  />
       </InputSection>
       <InputSection className="hour-picker">
         <DatePicker
           className="hour-picker"
           id={`time${this.props.id}`}
           name={`time${this.props.id}`}
           initialDate={this.props.initialTime}
           showTimeSelect
           showTimeSelectOnly
           timeIntervals={this.props.timeInterval}
           timeFormat={DateTimeHelper.getTimeFormat()}
           dateFormat={DateTimeHelper.getTimeFormat()}
           timeCaption=""
           placeholderText={this.labels.get('time')}
           validate={[Validation.required,Validation.time]}/>
       </InputSection>
     </div>
    );
  }
}

PL_WeekdayAndTime.defaultProps = {
  timeInterval:15
};

PL_WeekdayAndTime.propTypes = {
  id:PropTypes.string.isRequired,
  label:PropTypes.string,
  timeInterval:PropTypes.number,
  initialTime:PropTypes.object,
};

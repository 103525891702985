import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useOnClickOutside } from 'infrastructure/js/hooks/useOnClickOutside';
import LogoSection from './LogoSection/logoSection';
import TopSearch from './TopSearch/topSearch';
import UserMenu from './UserMenu/userMenu';
import HelpMenu from './HelpMenu/helpMenu';
import NotificationsCenterContainer from '../Notifications/NotificationsCenter/notificationsCenterContainer';
import NavigatorComponent from './TopNavigator/NavigatorComponent/navigatorComponent';
import { getLocationIcon } from './appTopBarHelper';
import UserMenuIcon from '../../../assets/svg/circled-user.svg';
import HelpMenuIcon from '../../../assets/svg/circled-question-mark.svg';

import './appTopbar.scss';

export default function AppTopbar({ topNavigatorData, featureFlags, actions, sServerVersion, sLoggedInUser }) {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showHelpMenu, setShowHelpMenu] = useState(false);
  const userMenuRef = useRef(null);
  const helpMenuRef = useRef(null);

  useEffect(() => {
    actions.topNavigator.fetchHomePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnClickOutside(userMenuRef, () => setShowUserMenu(false));
  useOnClickOutside(helpMenuRef, () => setShowHelpMenu(false));

  const renderSearchComponent = () => {
    if (!featureFlags.ALL) {
      return null;
    }
    return <TopSearch actions={actions.topSearch} />;
  };

  const renderNotifications = () => {
    if (!featureFlags.ALL) {
      return null;
    }
    return <NotificationsCenterContainer />;
  };

  const renderLocationIcon = () => {
    const locationType = topNavigatorData.getIn(['currentLocation', 'type']) || '';
    const LocationIcon = getLocationIcon(locationType);

    if (!LocationIcon) {
      return null;
    }

    return <LocationIcon className="location-icon" />;
  };

  const renderLocationStats = () => {
    const currentLocationComponents = topNavigatorData.getIn(['currentLocation', 'components']);
    if (currentLocationComponents) {
      return currentLocationComponents.map((component, index) => {
        return <NavigatorComponent key={index} {...component} />;
      });
    }
    return null;
  };

  const locationName = topNavigatorData.getIn(['currentLocation', 'name']);
  const locationSubName = topNavigatorData.getIn(['currentLocation', 'subname']);

  return (
    <div className="app-topbar">
      <div className="left-section">
        <LogoSection sHomePageData={topNavigatorData.get('homePage')} />
        <div className="location-section">
          {renderLocationIcon()}
          <div className="location-title">
            <h1>{locationName}</h1>
            <h2>{locationSubName}</h2>
          </div>
          {renderLocationStats()}
        </div>
      </div>

      <div className="right-section inline-block">
        {renderSearchComponent()}

        <div className="help-menu-container" ref={helpMenuRef}>
          <HelpMenuIcon className={classNames('top-bar-icon', { active: showHelpMenu })} onClick={() => setShowHelpMenu(true)} />
          <HelpMenu open={showHelpMenu} actions={{ ...actions.helpMenu, ...actions.system }} sServerVersion={sServerVersion} />
        </div>

        {renderNotifications()}

        <div className="user-menu-container" ref={userMenuRef}>
          <UserMenuIcon className={classNames('top-bar-icon', { active: showUserMenu })} onClick={() => setShowUserMenu(true)} />
          <UserMenu
            featureFlags={featureFlags}
            open={showUserMenu}
            actions={{ ...actions.userMenu, ...actions.system }}
            sServerVersion={sServerVersion}
            sData={sLoggedInUser}
          />
        </div>
      </div>
    </div>
  );
}

AppTopbar.defaultProps = {
  topNavigatorData: {},
  featureFlags: {},
  actions: {},
  sServerVersion: '',
};

AppTopbar.propTypes = {
  topNavigatorData: PropTypes.object,
  featureFlags: PropTypes.object,
  actions: PropTypes.object,
  sServerVersion: PropTypes.string,
  sLoggedInUser: PropTypes.object.isRequired,
};

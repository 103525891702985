import Network from 'infrastructure/js/modules/network';

export function fetchPermutationSettings() {
    return Network.get('planner/permutation-settings');
}

export function updatePermutationSettings(data) {
    return Network.post('planner/permutation-settings', data);
}

export function fetchGoals() {
  return Network.get('planner/goals');
}

export function runSimulation(data) {
  return Network.post('planner/run-simulations', data);
}

export function updatePlannerSettings(data) {
  return Network.put('planner/horizon-view-preferences', data);
}

export function getPlannerSettings(data) {
  return Network.post('planner/horizon-view-preferences', data);
}


import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import {getShipmentsByDataQuery} from '../../../services/Shipments/shipmentsService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_SHIPMENTS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
      return getShipmentsByDataQuery(query);

  }
}

function _convertToRowData(rows){
  return rows;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

//Style
require('./infoChart.scss');

export default class InfoChart extends React.PureComponent {
  render() {
    let {text, count, totalCount} = this.props.data.toObject();
    let {className} = this.props;
    return (
      <section className={classNames('right-top-component-container', 'inline-block', className)}>
        <div className="count inline-block">{count}</div>
        { totalCount !== undefined ? <div className="separator inline-block"></div> : " "}
        <div className="inner-text-count inline-block">
          { totalCount !== undefined ? <span>{totalCount} </span> : null}

          <FormattedMessage id={text} defaultMessage="x_infochart" />

        </div>
      </section>
    );
  }
}



import React from 'react';

import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {getTAskAlertCategoryLabel} from '../../../../utils/taskAlertHelper';

require('./taskAlertPopoverCell.scss');

export default class TaskAlertPopoverCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('');
  }

  getPopoverComponent = (alerts) => {
    let alertsHigh = alerts.filter((a) => {return a.severity === 'HIGH'});
    let alertsLow = alerts.filter((a) => {return a.severity !== 'HIGH'});

    let alertsHighDetails = alertsHigh?.length ? this.getAlertsBySeverity(alertsHigh, 'HIGH') : '';
    let alertsLowDetails = alertsLow?.length ? this.getAlertsBySeverity(alertsLow, 'LOW') : '';

    return (<div className="alert-popover-component">
              {alertsHighDetails}
              {alertsLowDetails}
            </div>);
  };

  getAlertsBySeverity = (alerts, severity) => {
    let messages = alerts.map((alert, index) => {
      return (<div key={'alertMessage' + index}>
        <span className={'category'}>{getTAskAlertCategoryLabel(alert.category) + ':'}</span>
        <span>{this.labels.get(alert.id, undefined, alert.params ? alert.params : undefined)}</span>
      </div>);
    });

    if (messages && messages.length > 0) {
      return (<div className="alert-popover-component">
        <div
          className={severity === 'HIGH' ? 'alert-icon pl pl-error-icon error' : 'alert-icon pl pl-warning-icon warning'}/>
        {messages}
      </div>);
    }
  };

  getIcon = (alerts) => {
    let foundHigh = alerts.find((alert) => {
      return alert.severity === 'HIGH';
    });

    let foundLow = alerts.find((alert) => {
      return alert.severity !== 'HIGH';
    });

    return (<React.Fragment>
      {!!foundHigh && <span className={'alert-icon pl pl-error-icon error'}/>}
      {!!foundLow && <span className={'alert-icon pl pl-warning-icon warning'}/>}
    </React.Fragment>);
  };

  render() {
    let alerts = this.props.value?.dataList;
    if (!alerts || !alerts.length) {
      return null;
    }
    return <div className="task-alert-popover-cell">
      <AlertPopover
        popoverId={'schedulerTaskAlertPopover'}
        holdOnHover={true}
        trigger="click"
        popoverType={'untitled'}
        popoverComponent={this.getPopoverComponent(alerts)}
      >
        {this.getIcon(alerts)}
      </AlertPopover>
    </div>
  }
}

import React from 'react';
import moment from 'moment';

import { getEditorDataConverter } from 'infrastructure/js/utils/editorHelper.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DatepickerOverlayEditor from 'infrastructure/js/components/popover/DatepickerOverlayEditor/datepickerOverlayEditor';
import ComboBoxEditor from 'infrastructure/js/components/editors/ComboBoxEditor/comboBoxEditor';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Overlay from 'infrastructure/js/components/Overlay/overlay';

import DetailsColumn from '../../Common/DetailsComponents/detailsColumn.js';

require('./pickListDetails.scss');

export default class PickListDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.pickList.page.details.');
  }
  componentDidMount() {
    this.props.actions.fetchAllDataForEditor();
  }

  isPickListCompleted = () => {
    return this.props.pickList?.status === 'COMPLETED';
  };

  convertEditorDataToServerData = getEditorDataConverter('pickList');

  /*  convertEditorDataToServerData = (data) => {
    let name = data.fieldName;
    let obj = data[data.fieldName];
    let value = {};

    switch (name) {
      case 'destinationId':
        if (obj === null || Array.isArray(obj)) {
          value = null;
        } else {
          value = obj.value;
        }
        break;
      case 'dueDate':
        value = obj;
        break;
      default:
        console.error('PickListDetails: Unknown field name: ' + name);
        return undefined;
    }
    return value;
  }; */

  handleEditSubmit = (editorSubmitData) => {
    if (!editorSubmitData) {
      return;
    }
    let serverData = this.convertEditorDataToServerData(editorSubmitData);
    if (serverData === undefined) {
      return;
    }
    let newData = {};
    newData[editorSubmitData.fieldName] = serverData;

    this.props.actions.updatePickList(this.props.pickList.id, newData);
  };

  renderDueDateEditor = (pickList) => {
    return (
      <DatepickerOverlayEditor
        id="dueDate"
        name="dueDate"
        value={pickList.dueDate}
        placement="bottom"
        handleSubmit={this.handleEditSubmit}
        editable={!this.isPickListCompleted()}
        minDate={moment()}
      />
    );
  };

  getDestinations = () => {
    let { pickListEditorData } = this.props;
    return pickListEditorData?.optionsPickListDestinations || [];
  };

  renderDestinationEditor = (pickList) => {
    let destination = {
      id: pickList.destination?.id || null,
      name: pickList.destination?.name || null,
    };
    return (
      <ComboBoxEditor
        id="destinationId"
        name="destinationId"
        value={Parse.findOptionById(destination.id, this.getDestinations())}
        displayValue={destination.name}
        handleSubmit={this.handleEditSubmit}
        options={this.getDestinations()}
        editable={!this.isPickListCompleted()}
      />
    );
  };

  getPickListDetails(pickList, labels) {
    let index0 = [
      {
        label: labels.get('createdAt'),
        body: <span id="create-date-input">{DateTimeHelper.FormatDateObjectToDayMonth(pickList.createdDate)}</span>,
      },
      {
        label: labels.get('createdBy'),
        body: <span id="create-by-input">{pickList.createdByUser}</span>,
      },
    ];

    let index1 = [
      {
        label: labels.get('status'),
        body: <span id="status">{pickList.status}</span>,
      },
      {
        label: labels.get('destination'),
        body: this.renderDestinationEditor(pickList),
      },
    ];

    let index2 = [
      {
        label: labels.get('duedate'),
        body: this.renderDueDateEditor(pickList),
      },
      {
        label: '',
        body: null,
      },
    ];
    return [index0, index1, index2];
  }

  getLoadingOverlay() {
    return this.props.loading ? <Overlay.Loading /> : null;
  }

  render() {
    let { pickList } = this.props;
    if (!pickList || !pickList.id) {
      return null;
    }

    let columnsData = this.getPickListDetails(pickList, this.labels);

    let columns = columnsData.map((column, index) => {
      return <DetailsColumn key={'pl-' + index} rows={column} />;
    });

    return (
      <div className="picklist-details">
        <div className="picklist-details-section">{columns}</div>

        {this.getLoadingOverlay()}
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import NotificationsItem from '../NotificationsItem/notificationsItem';
import BatchJobItem from '../BatchJobItem/batchJobItem';
import PL_Panel from 'infrastructure/js/components/Panel/panel';
import {navigationStates} from '../../../enums/navigationStates';


require('./notificationsPanel.scss');

export default class NotificationsPanel extends React.PureComponent{

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.header.notifications.panel.');
  }

  getNotificationsMenuElements(){
    return this.props.data;
  }

  getBatchJobsMenuElements(){

    if (this.props.batchJobsData) {
      let all = this.props.batchJobsData.get('allRunningJobs');
      let backgroundJobs = this.props.batchJobsData.get('backgroundJobs');
      let allJobs = Object.values(all);
      let elements = allJobs.filter((item) => {
        return (backgroundJobs && backgroundJobs[item.jobInstanceId])
      });
      return elements;
    }
    return [];
  }

  renderNotificationsElements = () => {
    let elements = new Array();

    this.getBatchJobsMenuElements().map((item, index)=>{
      elements.push( <BatchJobItem item={item} actions={this.props.actions}  key={'bo-item-'+index}/> )
    });

    this.getNotificationsMenuElements().map((item, index)=>{
      elements.push( <NotificationsItem item={item} actions={this.props.actions}  key={'notifications-item-'+index}/> )
    });

    return elements;
  }

  getConfig = () => {
    let config = {
      panelClass:'notifications-wrapper',
      expanded: this.props.open,
      loading: this.props.loading,
      data: this.renderNotificationsElements(),
      header : {
        icon: null,
        title : this.labels.get('notifications'),
        secondaryTitle : this.labels.get('markasread'),
        secondaryIcon: null,
        action: this.props.actions.markAllAsRead
      },
      footer : {
        icon: 'pl-notification-line',
        title : this.labels.get('viewallnotifications').toUpperCase(),
        secondaryTitle : null,
        secondaryIcon: 'pl-arrow-right',
        navigateTo: navigationStates.NOTIFICATIONS,
        action: this.props.actions.hideNotificationsMenu
      }
    };

    return config;

  }

  render() {
    return (
      <PL_Panel config={this.getConfig()} />
    );
  }
}

NotificationsPanel.propTypes = {
  expanded: PropTypes.bool,
  loading: PropTypes.bool
};

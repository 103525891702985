import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as tasksViewActions } from '../../actions/LocationPage/TasksView/tasksViewActions';
import { jsxActions as tasksGridActions }       from '../../actions/LocationPage/TasksView/tasksGridActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import { jsxActions as locationPageActions }          from '../../actions/LocationPage/locationPageActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as headerActions }   from '../../actions/Header/headerActions';
import {gridsNames} from '../../enums/gridsNames';
import TasksView from '../../components/LocationPage/TasksView/tasksView';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(tasksViewActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      tasksGridActions:  bindActionCreators(tasksGridActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch),
      locationPageActions:       bindActionCreators(locationPageActions, dispatch),
      headerActions: bindActionCreators(headerActions, dispatch),
    }
  }
}

let TasksViewContainer = connect(
  (state) => {
    let tasksGrid = state.grid.get(gridsNames.LOCATION_TASKS) ? state.grid.get(gridsNames.LOCATION_TASKS) : null;
    return {
      sHasRows: tasksGrid && tasksGrid.get('rows') ? tasksGrid.get('rows').size > 0 : false,
      sSelectedRowsData: tasksGrid ? tasksGrid.get('selectedRowsData') : List(),
      sTasksGridData: tasksGrid,
      sLoggedInUser: state.login.get('loggedInUser'),
      sLocationDetails: state.locationPage.get('data').get('locationDetails'),
    };
  },
  mapDispatchToProps
)(TasksView);

export default TasksViewContainer;



import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import useRedux from 'infrastructure/js/hooks/useRedux';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';
import Button from 'infrastructure/js/components/controls/Button/button';

import { jsxActions as headerActions } from '../../../actions/Mobile/Header/headerActions.js';
import { jsxActions as schedulerGanttActions } from '../../../actions/SchedulerPage/schedulerGanttActions.js';
import { jsxActions as scanWorkOrderPageActions } from '../../../actions/Mobile/ScanWorkOrderPage/scanWorkOrderPageActions.js';
import ScanHeader from '../Common/ScanHeader/scanHeader.js';
import ScanDetails from '../Common/ScanDetails/scanDetails.js';
import {
  getButtonStyles,
  getButtonsDataByStatus,
  getEventFormattedComponents,
  getModalHeaderByStatus,
  getPageTitle,
} from './utils/taskPageHelper.js';
import MobileModal from '../Common/MobileModal/mobileModal.js';

import './taskPage.scss';

const getState = (state) => {
  return {
    tasks: state.mobile.get('scanWorkOrderPage').get('woTasks') ?? [],
    loading: state.mobile.get('scanWorkOrderPage').get('loading'),
    selectedStation: state.mobile.get('scanWorkOrderPage').get('selectedStation'),
    selectedWorkOrder: state.mobile.get('scanWorkOrderPage').get('selectedWorkOrder'),
  };
};

const allActions = {
  header: headerActions,
  gantt: schedulerGanttActions,
  scanWorkOrderPageActions,
};

const buttonsLabels = createLabelHelper('mat.mobile.taskPage.modal.buttons.button.');

const TaskPage = ({ history }) => {
  const { state, actions } = useRedux(allActions, getState);
  const { tasks, loading } = state;
  const { taskId } = useParams();
  const [activeTask, setActiveTask] = useState(null);
  const [showStartModal, setShowStartModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  useEffect(() => {
    if (!taskId || !tasks.length) {
      actions.header.onGoBack(history);
    } else {
      const task = tasks.find(({ task }) => `${task.id}` === taskId);
      if (task) {
        setActiveTask(task);
      } else {
        alert('unknown task Id!');
        actions.header.onGoBack(history);
      }
    }
  }, [taskId, actions, tasks, history, loading]);

  const taskInfo = useMemo(() => {
    const data = getEventFormattedComponents(activeTask);
    return data
      .filter(({ fieldValue }) => !!fieldValue)
      .map(({ fieldName, fieldValue }) => {
        return { label: fieldName, body: <strong>{fieldValue}</strong> };
      });
  }, [activeTask]);

  const headerItems = {
    leftButton: {
      id: 'leftButton',
      icon: 'pl pl-arrow-left',
      action: actions.header.onGoBack,
      actionData: history,
    },
    middleButton: {
      id: 'middleButton',
      label: getPageTitle(activeTask),
    },
    rightButton: null,
  };

  const handleShowCompleteModal = useCallback((show = true) => {
    setShowCompleteModal(show);
  }, []);
  const handleShowStartModal = useCallback((show = true) => {
    setShowStartModal(show);
  }, []);

  const handleReportTaskProgress = (newStatus, isReportAsPlanned) => {
    const { selectedWorkOrder, selectedStation } = state;
    actions.gantt.reportTaskProgress({ taskData: activeTask, status: newStatus, isReportAsPlanned }).then(() => {
      actions.scanWorkOrderPageActions.submit(selectedWorkOrder, selectedStation);
    });
    handleShowStartModal(false);
    handleShowCompleteModal(false);
  };

  const handleStartNowClick = () => {
    handleReportTaskProgress('STARTED', false);
  };
  const handleStartAsPlannedClick = () => {
    handleReportTaskProgress('STARTED', true);
  };

  const handleCompleteNowClick = () => {
    handleReportTaskProgress('COMPLETED', false);
  };
  const handleCompleteAsPlannedClick = () => {
    handleReportTaskProgress('COMPLETED', true);
  };

  const bottomButtons = useMemo(() => {
    if (!activeTask) {
      return [];
    }
    const taskStatus = activeTask.operationStatus;
    const buttonData = getButtonsDataByStatus(taskStatus, { complete: handleShowCompleteModal, start: handleShowStartModal });

    return buttonData.map(({ id, action, label, style }) => {
      return (
        <Button key={id} id={id} className="task-page-button" bsStyle="primary" style={style} onClick={() => action()}>
          {label ?? ''}
        </Button>
      );
    });
  }, [activeTask, handleShowCompleteModal, handleShowStartModal]);

  const { scheduled } = getButtonStyles();

  if (!activeTask) {
    return null;
  }

  return (
    <div className="task-page">
      <ScanHeader {...headerItems} />
      <div className="task-page-task-info">
        <ScanDetails details={taskInfo} />
      </div>
      <div className="task-page-buttons">{bottomButtons}</div>

      <MobileModal
        show={showStartModal}
        header={getModalHeaderByStatus('start')}
        footer={
          <Button
            id="cancel-start"
            className="task-page-modal-button"
            style={scheduled}
            onClick={() => {
              handleShowStartModal(false);
            }}
          >
            {buttonsLabels.get('cancel')}
          </Button>
        }
      >
        <Button id="start-now" className="border-btn main-menu-item" onClick={handleStartNowClick}>
          {buttonsLabels.get('startNow')}
        </Button>
        <Button id="start-as-planned" className="border-btn main-menu-item" onClick={handleStartAsPlannedClick}>
          {buttonsLabels.get('startAsPlanned')}
        </Button>
      </MobileModal>
      <MobileModal
        show={showCompleteModal}
        header={getModalHeaderByStatus('complete')}
        footer={
          <Button
            id="cancel-complete"
            className="task-page-modal-button"
            style={scheduled}
            onClick={() => {
              handleShowCompleteModal(false);
            }}
          >
            {buttonsLabels.get('cancel')}
          </Button>
        }
      >
        <Button id="complete-now" className="border-btn main-menu-item" onClick={handleCompleteNowClick}>
          {buttonsLabels.get('completeNow')}
        </Button>
        <Button id="complete-as-planned" className="border-btn main-menu-item" onClick={handleCompleteAsPlannedClick}>
          {buttonsLabels.get('completeAsPlanned')}
        </Button>
      </MobileModal>
    </div>
  );
};

TaskPage.propTypes = {
  history: PropTypes.object,
};

TaskPage.defaultProps = { history: null };

export default TaskPage;

import React from 'react';

import ImportFilesCommonGrid from '../ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';

export default class ImportGroupsGrid extends React.PureComponent {

  render() {
    return  <ImportFilesCommonGrid  rows={this.props.rows} assetType={ImportTypes.IMPORT_GROUPS}
                                    gridName={gridsNames.IMPORT_GROUPS}
                                    showWorkOrderColumn={true}
                                    showProjectColumn={true}

            />;
  }
}

import React, {Component} from 'react';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";

import PropTypes from 'prop-types';
import classNames from 'classnames';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import Button from 'infrastructure/js/components/controls/Button/button';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown.js'
import {reduxForm} from 'redux-form';
import Label from 'infrastructure/js/components/Label/label.js'
import MaterialHelper from '../../../../utils/materialHelper';
import Checkbox from "infrastructure/js/components/controls/Checkbox/checkbox";

require('./header.scss');


const addKitIconImg = require("ico/add-kit-icon.png");
const reportKittingIconImg = require("ico/report-kitting-icon.png");

class KittingHeader extends React.PureComponent{

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.kitting.grid.header.');
    this.cutLabels = createLabelHelper('mat.locationpage.view.assets.grid.header.reportedcut.');
  }

  componentDidMount() {
    this.initFormValues();
  }

  initFormValues = () => {
    let initialValues = {
      showKitsInLocation: true,
    };
    this.props.initialize(initialValues);
  };

  getHeaderItems() {
    let {kittingGridData} = this.props;

    let selectedKitsCount = kittingGridData ? kittingGridData.get("selectedRowsData").size : 0;

    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: this.props.actions.clearFilters,
          disabled: (this.props.loading),
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          // loading: this.props.loading,
          action: this.reloadPage,
          disabled: (this.props.loading),
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        {
          id:'headerCreateKitBtn',
          label: headerItemsLabels.CREATE_KITS,
          icon: 'pl pl-create-kit',
          className: 'fixed-width',
          loading: this.props.loading,
          disabled: (this.props.loading || !this.props.selectedCutToKit || !this.props.selectedCutToKit.material || !this.props.isLocationActive),
          action: this.props.actions.onCreateKitsClick,
        },
        {
          id:'headerReportKitBtn',
          label: headerItemsLabels.REPORT_KITTING,
          icon: 'pl pl-kit1-icon',
          className: 'fixed-width',
          loading: this.props.loading,
          disabled: (this.props.loading || !this.props.selectedCutToKit || !this.props.selectedCutToKit.material || !selectedKitsCount || !this.props.isLocationActive),
          counter: (this.props.selectedCutToKit && this.props.selectedCutToKit.material ? selectedKitsCount : 0),
          action: this.onReportKittingClick,
        }
      ],
    };
    return items;
  }

  reloadPage = () => {
    if (this.props.actions.reloadParentComponent) {
      this.props.actions.reloadParentComponent();
    }
  };

  getReportedCutOptions() {
    let reportedCuts = this.props.kittingViewData.get("reportedCuts");
    reportedCuts =  reportedCuts.length ? reportedCuts : [];

    if (reportedCuts[0] && reportedCuts[0].value !== "showAll") {
      reportedCuts.unshift({label: this.labels.get("selectreportedcut.showall"), value: "showAll", data: {}});
    }

    return reportedCuts;
  }

  onReportKittingClick = () => {
    let {kittingGridData} = this.props;
    if (!kittingGridData || !kittingGridData.get('selectedRowsData') || !this.props.selectedCutToKit) {
      return;
    }

    let selectedRowsData = kittingGridData.get('selectedRowsData').toJS();
    let kitIds = selectedRowsData.map((kit) => {
      return kit.id;
    });

    let payload = {kitIds : kitIds, cutId: this.props.selectedCutToKit.id};

    this.props.actions.onReportKittingClick(payload);

  };

  getComponentToRender = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let item = props.data;

    if (item.value === 'showAll') {
      return <div className="cut-data-row">
        <span className={classNames('show-all-option', {'disabled': !this.props.isLocationActive})}>{item.label}</span>
      </div>;
    }
    else {
      let materialLabel = MaterialHelper.getMaterialFullLabel(item.data.material.materialName, item.data.material.businessId);
      return <div className="cut-data-row">
        <Label className="material" text={materialLabel} />
        <span className="roll-business-id">{item.data.rollBusinessId} </span>
        <span className="cut-id">{this.cutLabels.get("cutid")} {item.data.id} </span>
        <span className="time">{item.data.m_CreatedDate}</span>
      </div>;
    }
  };

  onDropdownChangeCallback = (newValue, oldValue) => {
    //prevent clearing
    if ( !newValue && oldValue && oldValue.value) {
      this.props.change('reportsCutDropdown', oldValue);
      return;
    }

    this.props.actions.onSelectedCutToKitChange(newValue);
  };

  onShowKitsClick = (event) => {
    this.props.actions.onShowKitsInLocationChange(event.target.checked);
  }

  render() {
    let {kittingGridData, kittingViewData} = this.props;
    if (!kittingGridData || !kittingGridData.get("selectedRowsData") || !kittingViewData || !kittingViewData.get("reportedCuts")) {
      return null;
    }

    // let selectedKitsCount = kittingGridData.get("selectedRowsData").size;

    let headerItems = this.getHeaderItems();

    return (
      <div className="kitting-header">

        <Header  {...headerItems}/>

        <div className="select-reported-cut">

          <span className="title inline-block">{this.labels.get('selectreportedcut')}</span>

          <Dropdown
                    name="reportsCutDropdown"
                    id="reportsCutDropdown"
                    placeholder={this.labels.get('notSelected')}
                    options={this.getReportedCutOptions()}
                    onChangeCallback={this.onDropdownChangeCallback}
                    className="cuts-dropdown"
                    singleValueRenderer={this.getComponentToRender}
                    optionRenderer={this.getComponentToRender}
                    isDisabled={!this.props.isLocationActive}
          >
          </Dropdown>

          <div className="title-second-line">
            <div className="title">{this.labels.get('selectkits')}</div>
            <Checkbox name= "showKitsInLocation" id= "showKitsInLocation" label={'Show kits in this location'} onClick={this.onShowKitsClick}/>
          </div>

        </div>

      </div>
    );
  }
}

KittingHeader.defaultProps = {
  isLocationActive: true,
};
KittingHeader.propTypes = {
  isLocationActive: PropTypes.bool,
};

//Connect form redux to ComponentDemoPage component.
//All relevant redux-form action/data will added inside props.
export default reduxForm({
    form: 'cutKitStation_Kitting_HeaderForm',
  }
)(KittingHeader);



import { actionTypes } from '../../actions/ChangePassword/changePasswordActions.js';
import {Map} from 'immutable';

let defaultState = Map({
  loading : false,
  errorMessage: ''
});

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.CHANGEPASSWORD_SUBMIT_IN_PROCESS:
      return state.set('loading', true);

    case actionTypes.CHANGEPASSWORD_SUBMIT_FINISHED:
      state = state.set('errorMessage', '');
      state = state.set('loading', false);
      return state;

    case actionTypes.CHANGEPASSWORD_SUBMIT_FAILED:
      state = state.set('errorMessage', action.payload);
      state = state.set('loading', false);
      return state;

    case actionTypes.CHANGEPASSWORD_UPDATE_PASSWORD_CONFIG:
      return state.set('passwordTooltip', action.payload.passwordTooltip)
                  .set('passwordConfig', action.payload.passwordConfig);

    default:
      return state;
  }
}

import React from 'react';
import classNames from 'classnames';

require('./editableViewCell.scss');

export default class EditableViewCell extends React.PureComponent {
    constructor(props) {
        super(props);

        this.cellRef = React.createRef();
    }

    //NOTE: The code below stopped working in the ag-grid version 30.0.2 and was
    //       temporarily commented out until the next change
    // componentDidMount() {
    //     // if this component is part of a multi input cell, register a mousedown event
    //     // in order to pass the clicked element index to the editing cell before current component unmounts.
    //     if (this.cellRef.current) {
    //         setTimeout(() => {
    //             this.cellRef.current.onmousedown = this.handleMouseDown;
    //         });
    //     }
    // }
    //
    // componentWillUnmount() {
    //     if (this.cellRef.current) {
    //         this.cellRef.current?.removeEventListener('onmousedown', this.handleMouseDown);
    //     }
    // }
    //
    // // save current item's index to ag-grid context and trigger cell edit event.
    // handleMouseDown = (e) => {
    //     e.preventDefault();
    //
    //     this.props.agGridReact.gridOptions.context = {
    //         clickedCellIndex: this.props.index,
    //     };
    //     this.props.api.startEditingCell({
    //         rowIndex: this.props.rowIndex,
    //         colKey: this.props.column.getId(),
    //     });
    // };

    render() {
        if (!this.props.value.enabled) {
            return (
                <div className='input-cell-disabled'>
                    <span>{this.props.value.value}</span>
                </div>
            );
        }
        return (
            <div
                ref={this.cellRef}
                className={classNames(
                    'input-cell',
                    this.props.value.isMulti ? 'multi-row-cell' : ''
                )}
            >
                <span>{this.props.value.value}</span>
            </div>
        );
    }
}

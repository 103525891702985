export function isAllItemsActive(data) {
  return isAllInState(data, true);
}

export function isAllItemsDeactive(data) {
  return isAllInState(data, false);
}

/////////////////////////////////////////////////
function isAllInState (data, isActiveState) {
  let res = [];
  data.map((item) => {
    if (item.active !== isActiveState) {
      res.push(item.id);
    }
  });
  return res.length === 0;
}


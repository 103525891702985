import Network                     from 'infrastructure/js/modules/network';
import { List }                    from 'immutable';
import * as groupsService          from '../../../services/Groups/groupsService';
import * as daHelper               from '../../Dialogs/dialogActionsHelper';
import * as dialogHelper           from 'infrastructure/js/components/Dialog/dialogHelper';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions.js';
import { api as locationPageApi }  from '../locationPageActions.js';
import { api as printRfidTagsApi } from '../../PrintRfidActions/printRfidActions';
import {dialogsNames}              from '../../../enums/dialogsNames';

const config = {
  getSubmitMethod: () => groupsService.addToGroup,
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.ADD_TO_GROUP_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.ADD_TO_GROUP_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(data) {
      return api.validateAssignment(dispatch, getState)(data).then((response) => {
        if (response) {
          dispatch(actions.show(response));
        }
      });
    }
  },

  validateAssignment(dispatch, getState) {
    return function (data) {
      if (data && data.preselectedAssets && data.preselectedAssets.size > 0) {
        let assetIds = data.preselectedAssets.toJS().map((item) => {return item.id});

        return groupsService.validateAssignment({assetIds}).then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error('Failed to validate assets assignment', response);
            messageDialogApi.responseError(dispatch, getState)(response);
            return null;
          }

          let validations = dialogHelper.getResponseValidationDetails(response);
          if (validations) {
            dispatch(actions.setValidationWarningsData(validations));
          }
          let legalAssets = (response.data && response.data.legalAssets) ? response.data.legalAssets : []
          let legalAssetsIds = legalAssets.map((item) => {return item.id});
          let preselectedAssets = data.preselectedAssets.filter((item) => {
            return legalAssetsIds.includes(item.id)})

          data.preselectedAssets = List(preselectedAssets);
          return data;
        });
      }
      return Promise.resolve(data);
    }
  },

  printRfidTag(dispatch, getState) {
    return function (response, locationId) {
      messageDialogApi.close(dispatch, getState)();

      if (response && response.data && response.data.id) {
        printRfidTagsApi.printRfidTags(dispatch, getState)([response.data.id], locationId, true);
      }

    }
  },

  getMessageDialogBuilderData(dispatch, getState) {
    return function (response, data) {
      let locationId = locationPageApi.getLocationId(dispatch, getState)();
      let isGroupCreation = (data && data.group && !data.group.id);
      return [
        response.data,
        messageDialogApi.close(dispatch, getState),
        isGroupCreation ? (() => api.printRfidTag(dispatch, getState)(response, locationId)) : null
      ];
    }
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  submit(data, messageDialogBuilder, reloadParentComponent) {
    return function (dispatch, getState) {
      data.locationId = locationPageApi.getLocationId(dispatch, getState)();
      return api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
    };
  },
};





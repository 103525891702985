import React from 'react';

import ImportFilesCommonGrid from '../ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';

export default class ImportOperationSequenceGrid extends React.PureComponent {

  render() {
    return  <ImportFilesCommonGrid  rows={this.props.rows}
                                    assetType={ImportTypes.IMPORT_OPERATION_SEQUENCE}
                                    gridName={gridsNames.IMPORT_OPERATION_SEQUENCE}
                                    showBusinessIdColumn={false}
                                    showOperationNameColumn={true}
                                    showOperationDurationColumn={true}
    />;
  }
}

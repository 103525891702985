import { Map, List} from 'immutable';
import { actionTypes as actionTypes }  from '../../../actions/Dialogs/EditAssetsStatusDialog/editAssetsStatusDialogActions';

let defaultState = {
  show: false,
  assets : [],
  preselectedAssets: List(),
  entitiesTypes: List(),
  boEntityFilter: '',
  cancelToken: '',
  loading: false,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case actionTypes.EDIT_ASSETS_STATUS_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ? List(action.payload.preselectedAssets) : List())
        .set('entitiesTypes', action.payload && action.payload.entitiesTypes ? List(action.payload.entitiesTypes) : List())
        .set('boEntityFilter', action.payload && action.payload.boEntityFilter ? action.payload.boEntityFilter : '')
        .set('show', true);

    case actionTypes.EDIT_ASSETS_STATUS_DIALOG_HIDE:
      return Map(defaultState);

    case actionTypes.EDIT_ASSETS_STATUS_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case actionTypes.EDIT_ASSETS_STATUS_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);

    // case actionTypes.EDIT_ASSETS_STATUS_DIALOG_FETCH_ASSETS_READY:
    //   return state.setIn(['assets', 'workorders'], action.payload.dataList);
                          //action.payload.dataList.map((obj)=> {return { value: obj.id, label: obj.businessId, data: obj } }) );

    case actionTypes.EDIT_ASSETS_STATUS_DIALOG_SAVE_CANCEL_TOKEN:
      return state.set("cancelToken", action.payload);

    case actionTypes.EDIT_ASSETS_STATUS_DIALOG_SET_VALIDATION_WARNINGS_DATA:
      return state.set('validationMessage', action.payload)
        .set('hasError', !!action.payload.errors)
        .set('showIgnoreValidationCheckbox', !action.payload.errors)
        .set('hasHighSeverityWarning', action.payload.reset ? false : action.payload.hasHighSeverityWarning)
        .set('hasMediumSeverityWarning', action.payload.reset ? false : action.payload.hasMediumSeverityWarning)
        .set('isIgnoreValidationWarnings', false);

    case actionTypes.EDIT_ASSETS_STATUS_DIALOG_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('showIgnoreValidationCheckbox', false)
        .set('hasHighSeverityWarning', false)
        .set('hasMediumSeverityWarning', false)
        .set('hasError', false)
        .set('isIgnoreValidationWarnings', false);

    case actionTypes.EDIT_ASSETS_STATUS_DIALOG_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('isIgnoreValidationWarnings', action.payload);

    default:
      return state;
  }

}








import Network from 'infrastructure/js/modules/network';

export function fetchPartTypes(query={}) {
  return Network.post('parttype/items', query);
}

export function createPartType(data) {
  return Network.post('parttypes', data);
}

export function editPartType (data) {
  return Network.post('parttypes/update', {partTypes: [data]});
}

export function activatePartTypes(data) {
  return Network.post('parttypes/activate', data);
}

export function deactivatePartTypes(data) {
  return Network.post('parttypes/deactivate', data);
}
export function fetchAllParts() {
  return Network.get('parttypes');
}

export function removePartTypes(ids) {
  return Network.delete('parttypes/', {partTypeIds: ids.ids});
}
export function fetchPartsByProject(id) {
  return Network.get('parttypes/byProject', {projectId: id});
}

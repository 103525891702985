import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as notificationsPageActions }    from '../../actions/NotificationsPage/notificationsPageActions.js';

import NotificationsPage from '../../components/NotificationsPage/notificationsPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(notificationsPageActions, dispatch)
  }
}

let NotificationsPageContainer = connect(
  (state) => {
  	return {
      sData: state.notificationsPage,
    };
  },
  mapDispatchToProps
)(NotificationsPage);


export default NotificationsPageContainer;




import Network from 'infrastructure/js/modules/network';
import PermissionManager from '../../../../../infrastructure/js/utils/permissionManager';
import { api as createKitWizardApi }  from './createKitWizardActions.js';
import { api as kittingViewApi } from './kittingViewActions.js';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import { api as cancelKitReportDialogApi }  from './cancelKitReportDialogActions.js';
import { api as locationPageApi } from '../locationPageActions.js';
import * as kitsService from '../../../services/Kits/kitsService';
import * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import * as daHelper from '../../Dialogs/dialogActionsHelper';
import {dialogsNames} from '../../../enums/dialogsNames';


const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.REPORT_KIT_DIALOG),

  REPORT_KIT_DIALOG_SUBMIT_KITS_IN_PROGRESS:              'REPORT_KIT_DIALOG_SUBMIT_KITS_IN_PROGRESS',
  REPORT_KIT_DIALOG_SUBMIT_KITS_FINISHED:                 'REPORT_KIT_DIALOG_SUBMIT_KITS_FINISHED',

  REPORT_KIT_DIALOG_SUBMIT_KITS_AND_CREATE_IN_PROGRESS:   'REPORT_KIT_DIALOG_SUBMIT_KITS_AND_CREATE_IN_PROGRESS',
  REPORT_KIT_DIALOG_SUBMIT_KITS_AND_CREATE_FINISHED:      'REPORT_KIT_DIALOG_SUBMIT_KITS_AND_CREATE_FINISHED',

};

const actions = {
  ...daHelper.getActions(dialogsNames.REPORT_KIT_DIALOG),

  submitKitsInProgress(payload) {
    return {type: actionTypes.REPORT_KIT_DIALOG_SUBMIT_KITS_IN_PROGRESS, payload: payload };
  },
  submitKitsFinished(payload) {
    return {type: actionTypes.REPORT_KIT_DIALOG_SUBMIT_KITS_FINISHED, payload: payload };
  },

  submitKitsAndCreateInProgress(payload) {
    return {type: actionTypes.REPORT_KIT_DIALOG_SUBMIT_KITS_AND_CREATE_IN_PROGRESS, payload: payload };
  },
  submitKitsAndCreateFinished(payload) {
    return {type: actionTypes.REPORT_KIT_DIALOG_SUBMIT_KITS_AND_CREATE_FINISHED, payload: payload };
  },
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(data) {
      api.fetchDialogData(dispatch, getState)(data).then((response) => {
        dispatch(actions.show());
      });
    }
  },

  fetchDialogData(dispatch, getState) {
    return function(data) {

      let locationId = locationPageApi.getLocationId(dispatch, getState)();
      let cutId = data.cutId;
      delete data.cutId;
      return kitsService.fetchKitsRequiredmaterials(data, locationId, cutId)
        .then((response) => {

          let warnings = _hasValidationWarnings(response, getState);
          if (warnings) {
            //Note:  convert this specific API warnings object to common 'Validation Warnings' structure
            let payload = {warnings, errors: null, canIgnoreValidation: true};
            dispatch(actions.setValidationWarningsData(payload));
          }

          let dialogData = {
            kits: response.dataList,
          };

          dispatch(actions.fetchDialogDataReady(dialogData));
          return dialogData;
        });
    }
  },

};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  onReportKittings(kittingsData, messageDialogBuilder) {
    return function(dispatch, getState) {

      dispatch(actions.submitKitsInProgress());

      kitsService.reportKits(kittingsData)
        .then((response) => {
          dispatch(actions.submitKitsFinished(response));

          let validations = dialogHelper.getResponseValidationDetails(response);
          if (validations) {
            dispatch(actions.setValidationWarningsData(validations));
            return {success: false};
          }

          dispatch(actions.hide());

          if (!Network.isResponseValid(response)) {
            console.error('Report Kitting failed');
            messageDialogApi.responseError(dispatch, getState)(response);
            return {success: false};
          }

          kittingViewApi.setCurrentCutToKitDropdownToShowAll(dispatch, getState)();

          // Refresh everything.
          locationPageApi.reload(dispatch, getState)();

          // Prepare and open Confirmation Message Dialog.
          let messageDialogDescriptor = messageDialogBuilder(

            {updatedKits: kittingsData.kittedPlies, success: Network.isResponseValid(response)},
            messageDialogApi.close(dispatch, getState)
          );

          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

        });

    };
  },

  onReportKittingsAndCreate(kittingsData) {
    return function(dispatch, getState) {

      dispatch(actions.submitKitsAndCreateInProgress());

      kitsService.reportKits(kittingsData)
        .then((response) => {
          dispatch(actions.submitKitsAndCreateFinished(response));

          let validations = dialogHelper.getResponseValidationDetails(response);
          if (validations) {
            dispatch(actions.setValidationWarningsData(validations));
            return {success: false};
          }

          dispatch(actions.hide());

          let createKitWizardOptions = {
            shouldRefreshPageOnCancel: true,

            existingKitsKittedPlies: kittingsData.kittedPlies,
          };

          createKitWizardApi.openWizard(dispatch, getState)(createKitWizardOptions);
        });
    };
  },


  onCancelKitReportClick(kitIds) {
    return function(dispatch, getState) {
      cancelKitReportDialogApi.openDialog(dispatch, getState)(kitIds);
    }
  },
};


//////////////////////////////////
function _hasValidationWarnings (response, getState) {


  let operationPredictionEnabled = PermissionManager.getOrgPreferences().operationPredictionEnabled ?
    PermissionManager.getOrgPreferences().operationPredictionEnabled : false;

  if (operationPredictionEnabled && response.dataList) {

    let messages = [];

    response.dataList.forEach((item) => {
      let operationValidation = item.operationValidation;
      if (operationValidation && !operationValidation.valid) {
        messages.push(
          {
            errorKey: operationValidation.message ? operationValidation.message.labelKey : '',
            params: operationValidation.message ? operationValidation.message.params : null
          });
      }
    });

    return (messages.length > 0 ) ? messages : null;
  }
  return null;
}




import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as mqttBrokersSettingsViewActions} from '../../../actions/Administration/RfidSettingsPage/mqttBrokersSettingsViewActions'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as mqttBrokersSettingsViewGridActions} from '../../../actions/Administration/RfidSettingsPage/mqttBrokersSettingsViewGridActions';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { jsxActions as createMqttBrokerDialogActions} from '../../../actions/Administration/RfidSettingsPage/Dialogs/createMqttBrokerDialogActions';
import { gridsNames} from '../../../enums/gridsNames';
import MqttBrokersSettingsView from '../../../components/Administration/RfidSettingsPage/MqttSettingsView/MqttBrokersSettingsView/mqttBrokersSettingsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(mqttBrokersSettingsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      mqttBrokersSettingsViewGridActions: bindActionCreators(mqttBrokersSettingsViewGridActions, dispatch),
      createMqttBrokerDialogActions : bindActionCreators(createMqttBrokerDialogActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let MqttBrokersSettingsViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_MQTT_BROKERS);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sData: state.administration.getIn(['rfidSettingsPage', 'mqttBrokersSettingsViewData']),
      sCreateMqttBrokerDialogData: state.administration.getIn(['rfidSettingsPage', 'createMqttBrokerDialog']),
    };
  },

  mapDispatchToProps
)(MqttBrokersSettingsView);

export default MqttBrokersSettingsViewContainer;



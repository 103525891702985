import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import { gridsNames } from '../../../../enums/gridsNames';
import { EntityPropertyTypes } from '../../../../enums/entityPropertyTypes';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';

import './demoOrganizationsGrid.scss';

const labels = createLabelHelper('mat.organizations.grid.');

const filterConfig = [{ fieldName: 'name', filterName: 'orgBusinessId', getOptions: true }];

export function DemoOrganizationsGrid({ actions }) {
  const columnsConfig = [
    {
      fieldName: 'name',
      title: labels.get('columns.name.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'orgBusinessId',
      columnOptions: {
        sort: 'asc',
      },
    },
    {
      fieldName: 'status',
      title: labels.get('columns.status.title'),
      filterType: filterTypes.NONE,
      width: 130,
      columnOptions: {
        sortable: false,
        valueGetter: (params) => {
          return params.data.m_status;
        },
      },
    },
    {
      fieldName: 'locale',
      title: labels.get('columns.locale.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        sortable: false,
        valueGetter: (params) => {
          return params.data.m_locale;
        },
      },
    },
    {
      fieldName: 'orgPreferences.systemDateFormat',
      title: labels.get('columns.dateformat.title'),
      filterType: filterTypes.NONE,
      width: 150,
      columnOptions: {
        sortable: false,
      },
    },
    {
      fieldName: 'orgPreferences.systemTimeFormat',
      title: labels.get('columns.timeformat.title'),
      filterType: filterTypes.NONE,
      width: 150,
      columnOptions: {
        sortable: false,
        valueGetter: (params) => {
          return params.data.m_timeFormatLabel;
        },
      },
    },
    {
      fieldName: 'orgPreferences.systemTimeZone',
      title: labels.get('columns.timezone.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        sortable: false,
      },
    },
  ];

  const gridActions = {
    ...actions.demoOrganizationsGridActions,
    ...actions.fetchEntitiesActions,
  };

  return (
    <div className="demo-organizations-grid">
      <Grid gridName={gridsNames.ADMINISTRATION_DEMO_ORGANIZATIONS} columnsConfig={columnsConfig} actions={gridActions} filterConfig={filterConfig} />
    </div>
  );
}

DemoOrganizationsGrid.propTypes = {
  actions: PropTypes.object,
};

DemoOrganizationsGrid.defaultProps = {
  actions: {},
};

export default reduxForm({
  form: 'demoOrganizationsGrid',
})(DemoOrganizationsGrid);

import Network from 'infrastructure/js/modules/network';


export function fetchImportPropertiesOptions(data) {
  return Network.post('csvSettings/import/properties/options', data);
}

export function fetchExportPropertiesOptions(data) {
  return Network.post('csvSettings/export/properties/options', data);
}

export function fetchImportSettings(data) {
  return Network.post('csvSettings/import/settings', data);
}

export function fetchExportSettings(data) {
  return Network.post('csvSettings/export/settings', data);
}

export function setImportSettings(data) {
  return Network.post('csvSettings/import/settings/save', data);
}

export function setExportSettings(data) {
  return Network.post('csvSettings/export/settings/save', data);
}

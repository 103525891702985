import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../../../MessageDialog/messageDialogActions.js';
import * as orgsService from '../../../../services/Administration/organizationsService.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  DELETE_DEMO_ORGANIZATION_DIALOG_SHOW: 'DELETE_DEMO_ORGANIZATION_DIALOG_SHOW',
  DELETE_DEMO_ORGANIZATION_DIALOG_HIDE: 'DELETE_DEMO_ORGANIZATION_DIALOG_HIDE',
  DELETE_DEMO_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS: 'DELETE_DEMO_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS',
  DELETE_DEMO_ORGANIZATION_STATUS_SUBMIT_FINISHED: 'DELETE_DEMO_ORGANIZATION_STATUS_SUBMIT_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function (payload) {
    return { type: actionTypes.DELETE_DEMO_ORGANIZATION_DIALOG_SHOW, payload: payload };
  },
  hide: function (payload) {
    return { type: actionTypes.DELETE_DEMO_ORGANIZATION_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function (payload) {
    return { type: actionTypes.DELETE_DEMO_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function (payload) {
    return { type: actionTypes.DELETE_DEMO_ORGANIZATION_STATUS_SUBMIT_FINISHED, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function () {
  return function (dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function (data, reloadParentComponent) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.show = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.show({deletionData: data}));
  };
};

api.hide = function (dispatch, getState) {
  return function () {
    dispatch(actions.hide());
  };
};

api.submit = function (dispatch, getState) {
  return function (data, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    orgsService.deleteDemoOrganization(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error(`Delete demo org failed`);
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      if(reloadParentComponent){
        reloadParentComponent()
      }

      return { success: true };
    });
  };
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { gridsNames} from '../../../enums/gridsNames';
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { jsxActions as mqttSensorsSettingsViewActions} from '../../../actions/Administration/RfidSettingsPage/mqttSensorsSettingsViewActions';
import { jsxActions as mqttSensorsSettingsViewGridActions} from '../../../actions/Administration/RfidSettingsPage/mqttSensorsSettingsViewGridActions';
import { jsxActions as createMqttSensorDialogActions} from '../../../actions/Administration/RfidSettingsPage/Dialogs/createMqttSensorDialogActions';
import MqttSensorsSettingsView from '../../../components/Administration/RfidSettingsPage/MqttSettingsView/MqttSensorsSettingsView/mqttSensorsSettingsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(mqttSensorsSettingsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      mqttSensorsSettingsViewGridActions: bindActionCreators(mqttSensorsSettingsViewGridActions, dispatch),
      createMqttSensorDialogActions : bindActionCreators(createMqttSensorDialogActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let MqttSensorsSettingsViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_MQTT_SENSORS);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sData: state.administration.getIn(['rfidSettingsPage', 'mqttSensorsSettingsViewData']),
      sCreateMqttSensorDialogData: state.administration.getIn(['rfidSettingsPage', 'createMqttSensorDialog']),
    };
  },

  mapDispatchToProps
)(MqttSensorsSettingsView);

export default MqttSensorsSettingsViewContainer;



import { api as batchJobsApi }      from '../../BatchJobs/batchJobsActions.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  BATCH_JOB_PROGRESS_DIALOG_SHOW: 'BATCH_JOB_PROGRESS_DIALOG_SHOW',
  BATCH_JOB_PROGRESS_DIALOG_HIDE: 'BATCH_JOB_PROGRESS_DIALOG_HIDE',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.BATCH_JOB_PROGRESS_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.BATCH_JOB_PROGRESS_DIALOG_HIDE, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  }
};

jsxActions.stop = function(jobInstanceId) {
  return function(dispatch, getState) {
    batchJobsApi.stopJob(dispatch, getState)(jobInstanceId);
  }
};

jsxActions.runInBackground = function(jobInstanceId) {
  return function(dispatch, getState) {
    batchJobsApi.runInBackground(dispatch, getState)(jobInstanceId);
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

// Someone wants to close the dialog.
api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(payload) {
    dispatch(actions.show(payload));
  }
};








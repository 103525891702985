import { useEffect } from 'react';

import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';

import FullScreenTabsSeparator from '../../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';

import ActivePartsContainer from '../../../containers/ActivePartsPage/activePartsContainer';
import ArchivedPartsContainer from '../../../containers/ArchivedPartsPage/archivedPartsContainer';

import { navigationStates } from '../../../enums/navigationStates';

import './partsPage.scss';

const labels = createLabelHelper('mat.parts.page.');
const tabsLabels = createLabelHelper('mat.common.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

const PartsPage = () => {
  const updateNavBar = useNavBarUpdate();
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl('PredefinedViews/parts', Object.values(tabKeys), 'active');

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  useEffect(() => {
    updateNavBar(navigationStates.PARTS, labels.get('title'));
  }, [updateNavBar, setTabKeyInURL]);

  return (
    <div className="parts-page">
      <FullScreenTabsSeparator />
      <Tabs id="parts-tabs" tabType="page" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('active')} eventKey={tabKeys.ACTIVE}>
          <ActivePartsContainer />
        </Tab>
        <Tab title={tabsLabels.get('archived')} eventKey={tabKeys.ARCHIVED}>
          <ArchivedPartsContainer />
        </Tab>
      </Tabs>
    </div>
  );
};

export default PartsPage;

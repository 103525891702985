import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }      from '../../MessageDialog/messageDialogActions.js';

const actionTypes = {
  SUBMIT_IN_PROGRESS: '_SUBMIT_IN_PROGRESS',
  SUBMIT_FINISHED: '_SUBMIT_FINISHED',
  SELECTED_MENU_ITEM_CHANGED : 'SELECTED_MENU_ITEM_CHANGED'
};

export function getActionTypes(preffix) {
  let keys = Object.keys(actionTypes);
  let res = {};
  keys.forEach(key => {
    res[preffix + actionTypes[key]] = preffix + actionTypes[key];
  });
  return res;
}

export function getActions(preffix) {
  return {
    submitInProgress: function (payload) {
      return {type: preffix + actionTypes.SUBMIT_IN_PROGRESS, payload: payload};
    },
    submitFinished: function (payload) {
      return {type: preffix + actionTypes.SUBMIT_FINISHED, payload: payload};
    },
    setSelectedMenuItemId: function(payload) {
      return {type: actionTypes.SELECTED_MENU_ITEM_CHANGED , payload};
    }
  }
}

export function getJsxActions(api) {
  return {
    submit: function (data, messageDialogBuilder, reloadParentComponent) {
      return function (dispatch, getState) {
        api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
      };
    },
    onSelectedMenuItemChanged: function (selectedItemId) {
    return function(dispatch, getState) {
      api.setSelectedMenuItemId(selectedItemId)(dispatch, getState);
    }
  }
  }
}


export function getApiActions(actions, config) {
  return {

    submit: function(dispatch, getState) {
      return (function(data, messageDialogBuilder, reloadParentComponent) {

        dispatch(actions.submitInProgress());

        config.submitMethod(data)
          .then((response) => {
            dispatch(actions.submitFinished(response));

            if(!Network.isResponseValid(response)) {
              console.error(config.submitMethod.name + ' failed ', response);
              messageDialogApi.responseError(dispatch, getState)(response);
              return {success: false};
            }

            if (reloadParentComponent) {
              reloadParentComponent();
            }

            if (messageDialogBuilder) {
              let messageDialogDescriptor = messageDialogBuilder(
                response.data
              );

              messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
            }
            return {success: true};
          })
          .catch (error => {
            console.error(config.submitMethod.name + ' failed, catch error: ', error);
            dispatch(actions.submitFinished());
           })
      }).bind(this);
    },
    setSelectedMenuItemId: function (selectedItemId) {
      return function(dispatch, getState) {
        dispatch(actions.setSelectedMenuItemId(selectedItemId));
      }
    }
  }
}



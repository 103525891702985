import { useEffect } from 'react';

import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';

import FullScreenTabsSeparator from '../../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';

import ArchivedKitsContainer from '../../../containers/ArchivedKitsPage/archivedKitsContainer.js';
import AlertedKitsContainer from '../../../containers/AlertedKitsPage/alertedKitsContainer.js';
import ActiveKitsContainer from '../../../containers/ActiveKitsPage/activeKitsContainer';

import { navigationStates } from '../../../enums/navigationStates';

import './kitsPage.scss';

const labels = createLabelHelper('mat.kits.page.');
const tabsLabels = createLabelHelper('mat.common.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  ACTIVE: 'active',
  ALERTED: 'alerted',
  ARCHIVED: 'archived',
};

const KitsPage = () => {
  const updateNavBar = useNavBarUpdate();
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl('PredefinedViews/kits', Object.values(tabKeys), 'active');

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  useEffect(() => {
    updateNavBar(navigationStates.KITS, labels.get('title'));
  }, [updateNavBar, setTabKeyInURL]);

  return (
    <div className="kits-page">
      <FullScreenTabsSeparator />
      <Tabs id="kits-tabs" tabType="page" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('active')} eventKey={tabKeys.ACTIVE}>
          <ActiveKitsContainer />
        </Tab>
        <Tab title={tabsLabels.get('alerted')} eventKey={tabKeys.ALERTED}>
          <AlertedKitsContainer />
        </Tab>
        <Tab title={tabsLabels.get('archived')} eventKey={tabKeys.ARCHIVED}>
          <ArchivedKitsContainer />
        </Tab>
      </Tabs>
    </div>
  );
};

export default KitsPage;

import React, {Component} from 'react';
import classNames from 'classnames';
import {PieChart, Pie, Cell, Legend} from 'recharts';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./archivedRollsChart.scss');

const predefinedColors = {
  'CANCELED': '#ccd1d1',
  'Expired/Over exposed': '#e74c3c',
  'DISQUALIFIED': '#a93226',
  'CONSUMED': '#85c1e9',
  'SHIPPED': '#f7c070',
}

const colorPallete = {
  blue: '#36a2eb',
  cyan: '#00bcd4',
  teal: '#009688',
  green: '#4caf50',
  orange: '#ff9f40',
  purple: '#9c27b0',
  yellow: '#ffeb3b',
  pink: '#e91e63'
}

export default class ArchivedRollsChart extends Component {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.dashboard.inventory.inventoryManagement.archivedAlertedRoolsChart.');

    const RADIAN = Math.PI / 180;
    this.renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };
  }

  getChartData = () => {
    const colorsArr = Object.keys(colorPallete).map(key => colorPallete[key])
    let {chartData} = this.props;
    if(!chartData){
      return [];
    }
    chartData = chartData.filter((status)=> status.percentage);
    if(!chartData.length){
      return [{name:createLabelHelper('').get('mat.activitydetails.activity_details_value_asset_status_active'), value:100}]
    }
    return chartData.map((status, i)=>{
      return {
        name: `${createLabelHelper('').get(status.assetStatusKey)} - ${status.units} ${this.labels.get('units')}`,
        value: status.percentage,
        fill: predefinedColors[status.assetStatus] || colorsArr[i % colorsArr.length]
      }
    })
  };

  renderChart = () => {
    let chartData = this.getChartData();
    return (
      <div className={classNames('chart-section')}>
        <PieChart width={400} height={400}>
          <Pie
            data={chartData}
            cx={200}
            cy={170}
            labelLine={false}
            label={this.renderCustomizedLabel}
            dataKey="value"
            isAnimationActive={false}
            outerRadius={120}
            fill="#8884d8"
          />
          <Legend />
        </PieChart>
      </div>
    );
  };

  render() {
    if(!this.props.chartData){
      return null
    }
    return (
      <div className="rolls-chart">
        <div className='chart-title'>Archived / Alerted Rolls (units)</div>
        {this.renderChart()}
      </div>
    )
  }
};






import Network from 'infrastructure/js/modules/network';

export function createDevice(data){
  let deviceAppProfileId = data.deviceAppProfileId;
  delete data.deviceAppProfileId;

  return Network.post(`deviceAppProfile/${deviceAppProfileId}/patliteTower`, data);
}

export function editDevice(data) {
  let id = data.id;
  delete  data.id;
  delete data.deviceAppProfileId;
  return Network.put(`patliteTower/${id}`, data);
}

export function fetchDevices(id) {
  return Network.get(`deviceAppProfile/${id}/patliteTowers`);
}

export function deleteDevices(data) {
  let deviceAppProfileId = data.deviceAppProfileId;
  delete data.deviceAppProfileId;

  return Network.delete(`deviceAppProfile/${deviceAppProfileId}/patliteTowers`, {towerIds: data.selectedDevicesIds});
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {List} from 'immutable';
import {jsxActions as headerActions} from '../../../actions/Header/headerActions';
import { jsxActions as fetchEntitiesActions }      from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as assetsViewActions }        from '../../../actions/LocationPage/AssetsView/assetsViewActions';
import { jsxActions as assetsRollsGridActions }        from '../../../actions/LocationPage/AssetsView/assetsRollsGridActions';
import {jsxActions as createRollActions} from '../../../actions/LocationPage/AssetsView/createRollActions';
import {gridsNames} from '../../../enums/gridsNames';
import RollsView from '../../../components/LocationPage/AssetsView/RollsView/rollsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(assetsViewActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      headerActions:        bindActionCreators(headerActions, dispatch),
      assetsRollsGridActions:        bindActionCreators(assetsRollsGridActions, dispatch),
      createRollActions:        bindActionCreators(createRollActions, dispatch),
    }
  }
}

let RollsViewContainer = connect(
  (state) => {
    let sGrid = state.grid.get(gridsNames.LOCATION_ASSET_ROLLS) ? state.grid.get(gridsNames.LOCATION_ASSET_ROLLS) : null;
    return {
      sGrid:                  sGrid,
      sHasRows:               sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData:      sGrid ? sGrid.get('selectedRowsData') : List(),
      sData:                  state.locationPage.getIn(['assetsView', 'rollsView']),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),
      sLoggedInUser :         state.login.get('loggedInUser'),
      sDataCreateRoll:        state.locationPage.get('createRollData'),
    };
  },
  mapDispatchToProps
)(RollsView);

export default RollsViewContainer;





import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import MisplacedMaterialView from '../../../../components/Administration/SettingsPage/Alerts/MisplacedMaterialView/misplacedMaterialView';
import { jsxActions as headerSettingsActions} from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions.js';
import {jsxActions as commonAlertsViewActions} from '../../../../actions/Administration/SettingsPage/commonAlertsViewActions';
import {jsxActions as fetchEntitiesActions} from '../../../../actions/FetchEntities/fetchEntitiesActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonAlertsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
    }
  }
}

const MisplacedMaterialViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonAlertsView']),
      isDirty: false
    };
  },
  mapDispatchToProps
)(MisplacedMaterialView);

export default MisplacedMaterialViewContainer;

import React from 'react';
import cn from 'classnames';
import {schedulerDragData} from 'infrastructure/js/components/Scheduler/scheduler';

require('./draggableCell.scss');

export default class DraggableCell extends React.PureComponent {

  constructor (props){
    super(props);
    this.dragImage = null;
  }

  onDragStart = (e, value) => {
    let { dataTransferTaskId, dataTransferDisplayValue, dataTransferToolId} = value;

   let dragImage = this.createDragImage(dataTransferDisplayValue);
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 0, 30);

    e.dataTransfer.setData(schedulerDragData.DRAG_DATA_TASK_ID, dataTransferTaskId || dataTransferTaskId === 0 ? dataTransferTaskId.toString() : '');
    e.dataTransfer.setData(schedulerDragData.DRAG_DATA_TOOL_ID, dataTransferToolId || dataTransferToolId === 0 ? dataTransferToolId.toString() : '');

    this.dragImage = dragImage;
  };

  onDragEnd = (e) => {
    if (this.dragImage) {
      document.body.removeChild(this.dragImage);
      this.dragImage = null;
    }
  };

  createDragImage = (text) => {
    let node = document.createElement('div');
    node.id = 'cell-drag-image-ghost';
    node.innerText = text;
    node.style.backgroundColor = "grey";
    node.style.padding = "20px";
    node.style.color = "white";
    node.style.borderRadius = "5px";
    node.style.position = "absolute";
    node.style.top = "-65px";
    node.style.left = "0px";

    return node;
  };

  render() {
    let {value} = this.props;
    if (!value) {
      return null;
    }

    return (
      <div className={cn('draggable-cell')}
           draggable={value.isDraggable}
           onDragStart = {(e) => this.onDragStart(e, value)}
           onDragEnd = {(e) => this.onDragEnd(e)}
           style={{cursor: value.isDraggable ? 'grab' : 'default'}}
      >
        {value.isDraggable ? <span className="pl pl-draggable"></span> : null}
      </div>);
  }
}







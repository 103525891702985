import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as shelfLifeUpdateViewActions} from '../../../../actions/Administration/MatSettingsPage/shelfLifeUpdateViewActions';
import { jsxActions as headerSettingsActions} from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as shelfLifeUpdateViewGridActions} from '../../../../actions/Administration/MatSettingsPage/shelfLifeUpdateViewGridActions';
import { jsxActions as createReasonTypeDialogActions} from '../../../../actions/Administration/MatSettingsPage/Dialogs/createReasonTypeDialogActions.js';
import { jsxActions as matSettingsPageActions} from '../../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames} from '../../../../enums/gridsNames';

import ShelfLifeUpdateView from '../../../../components/Administration/MatSettingsPage/Reasons/ShelfLifeUpdateView/shelfLifeUpdateView';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(shelfLifeUpdateViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      shelfLifeUpdateViewGridActions: bindActionCreators(shelfLifeUpdateViewGridActions, dispatch),
      createReasonTypeDialogActions : bindActionCreators(createReasonTypeDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }

  }
}

let ShelfLifeUpdateViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_SHELF_LIFE_UPDATE) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_SHELF_LIFE_UPDATE) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateReasonTypeDialogData: state.administration.getIn(['matSettingsPage', 'createReasonTypeDialog']),
    };
  },

  mapDispatchToProps
)(ShelfLifeUpdateView);


export default ShelfLifeUpdateViewContainer;




var moment = require('moment');
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';


let validation = {};

const REQUIRED_LABEL = 'This field is required'

const ERROR_MESSAGES = {
  REQUIRED_TEXT: 'required',
  INVALID_DATE: 'invaliddate',
  INVALID_DATE_MIN_DATE: 'invalidmindate',
  INVALID_DATE_MAX_DATE: 'invalidmaxdate',
  INVALID_MIN_LENGTH_PASSWORD_TEXT: 'invalidminlengthpassword',
  INVALID_DIGIT_PASSWORD_TEXT: 'invaliddigitpassword',
  INVALID_LOWER_CASE_PASSWORD_TEXT: 'invalidlowercasepassword',
  INVALID_UPPER_CASE_PASSWORD_TEXT: 'invaliduppercasepassword',
  INVALID_EMAIL_TEXT: 'invalidemail',
  INVALID_PHONE_TEXT: 'invalidphone',
  INVALID_CONFIRM_PASSWORD: 'invalidonfirmpassword',
  INVALID_ORG_NAME_FORMAT: 'invalidorgnameformat',
  INVALID_IP_FORMAT: 'invalidipformat',
  INVALID_IP_RANGE_FORMAT: 'invalidIPRangeFormat',
  TEXT_MAX_LENGTH: 'textmaxlength',
  MUST_BE_UNIQUE: 'mustbeunique',
  INVALID_TIME:'invalidTime',
  INVALID_RANGE:'invalidRange',
  INVALID_RANGE_WITH_DEFAULT: 'invalidRangeWithDefault',
  INVALID_END_TIME:'invalidEndTime',
  INVALID_MAX_TIME:'invalidMaxTime',
  INVALID_MIN_TIME:'invalidMinTime',
  NUMBER_MAX_INCLUSIVE: 'number.max.inclusive',
  NUMBER_MAX_EXCLUSIVE: 'number.max.exclusive',
  NUMBER_MIN_INCLUSIVE: 'number.min.inclusive',
  NUMBER_MIN_EXCLUSIVE: 'number.min.exclusive',
  UNIQUE_VALUE_GENERIC: 'uniqueGeneric'
};



///////////////////////////////////////////////////////////////
// Helper function.
// Check if value exists and has text if it's a String.
// Numeric type values supported.
//
let isEmpty = (value) => {

  // Support numeric types 0.
  if (value === null || value === undefined) {
    return true;
  }

  // Cast to String and check length after trim.
  return ((value + '').trim().length === 0);

};

validation.password = (config) => (value, allValues) => {
  if (!value || value.length === 0) {
    return getErrorMessage(ERROR_MESSAGES.REQUIRED_TEXT, undefined, REQUIRED_LABEL);
  }
  if(!config){
    return;
  }
  let errors = config.regexItems.map((validation) => {
    if (validation.regex == 'LOGIC_VARIETY') {
      //server function validation...
      if (varietyValidator(value, validation.referenceValue)) {
        return getPasswordErrorMessage(validation.errorKey, validation.referenceValue);
      }
      else{
        return null;
      }
    }
    let pattern = new RegExp(validation.regex);
    if (!pattern.test(value)) {
      return getPasswordErrorMessage(validation.errorKey, validation.referenceValue);
    }
  });
  return errors.find((error) => !!error) ? errors.find((error) => !!error) : undefined;
};


validation.confirmPassword = (value, allValues) => {
  if (!value || value.length === 0) {
    return getErrorMessage(ERROR_MESSAGES.REQUIRED_TEXT, undefined, REQUIRED_LABEL);
  }
  //match
  if (value !== allValues.password) {
    return getErrorMessage(ERROR_MESSAGES.INVALID_CONFIRM_PASSWORD);
  }

  return undefined;
};


validation.required = (value, allValues) => {
  if (isEmpty(value)) {
    return getErrorMessage(ERROR_MESSAGES.REQUIRED_TEXT, undefined, REQUIRED_LABEL);
  }

  return undefined;
};
validation.timeField = {};
validation.timeField.required = (value, allValues) => {
  let hours = value ? value._hours : null;
  let minutes = value ? value._minutes : null;
  if (isEmpty(hours) && isEmpty(minutes)) {
    return getErrorMessage(ERROR_MESSAGES.REQUIRED_TEXT, undefined, REQUIRED_LABEL);
  }
  return undefined;
};

validation.timeField.max = (maxValue) => (value, allValues) => {
  let hours = value ? value._hours : null;
  let minutes = value ? value._minutes : null;
  if (isEmpty(hours) && isEmpty(minutes)) {
    return undefined;
  }
  let max_hours = maxValue ? maxValue._hours : null;
  let max_minutes = maxValue ? maxValue._minutes : null;
  if (isEmpty(max_hours) && isEmpty(max_minutes)) {
    return undefined;
  }

  let time = DateTimeHelper.ConvertHoursMinutesToMinutes(hours, minutes);
  let maxTime = DateTimeHelper.ConvertHoursMinutesToMinutes(max_hours, max_minutes);
  if (time > maxTime) {
    return getErrorMessage(ERROR_MESSAGES.INVALID_MAX_TIME, {maxTime: DateTimeHelper.ConvertMinutesToHoursMinutes(maxTime)});
  }
  return undefined;
};

validation.timeField.min = (minValue) => (value, allValues) => {
  let hours = value ? value._hours : null;
  let minutes = value ? value._minutes : null;
  if (isEmpty(hours) && isEmpty(minutes)) {
    return undefined;
  }
  let min_hours = minValue ? minValue._hours : null;
  let min_minutes = minValue ? minValue._minutes : null;
  if (isEmpty(min_hours) && isEmpty(min_minutes)) {
    return undefined;
  }

  let time = DateTimeHelper.ConvertHoursMinutesToMinutes(hours, minutes);
  let minTime = DateTimeHelper.ConvertHoursMinutesToMinutes(min_hours, min_minutes);
  if (time < minTime) {
    return getErrorMessage(ERROR_MESSAGES.INVALID_MIN_TIME, {minTime: DateTimeHelper.ConvertMinutesToHoursMinutes(minTime)});
  }
  return undefined;
};





validation.timeRequired  = (fieldName) => (value, allValues) =>{
  let hours = allValues[`${fieldName}_hours`];
  let minutes = allValues[`${fieldName}_minutes`];
  if (isEmpty(hours) && isEmpty(minutes)) {
    return getErrorMessage(ERROR_MESSAGES.REQUIRED_TEXT, undefined, REQUIRED_LABEL);
  }
  return undefined;
};

validation.minutesSecondsTimeRequired  = (fieldName) => (value, allValues) =>{
  let minutes = allValues[`${fieldName}_minutes`];
  let seconds = allValues[`${fieldName}_seconds`];
  if (isEmpty(seconds) && isEmpty(minutes)) {
    return getErrorMessage(ERROR_MESSAGES.REQUIRED_TEXT, undefined, REQUIRED_LABEL);
  }
  return undefined;
};


validation.number = {};
validation.number.required = (value, allValues) => {
  if (!value && value !== 0) {
    return getErrorMessage(ERROR_MESSAGES.REQUIRED_TEXT, undefined, REQUIRED_LABEL);
  }

  return undefined;
};

validation.number.max = (maxValue, inclusive = true) => (value) => {
  if (value === null || value === undefined)
    return undefined;
  if (inclusive ? maxValue < value : maxValue <= value) {
    return getErrorMessage(inclusive ? ERROR_MESSAGES.NUMBER_MAX_INCLUSIVE : ERROR_MESSAGES.NUMBER_MAX_EXCLUSIVE, { maxValue });
  }
  return undefined;
}

validation.number.min = (minValue, inclusive = true) => (value) => {
  if (value === null || value === undefined || value === '')
    return undefined;
  if (inclusive ? minValue > value : minValue >= value) {
    return getErrorMessage(inclusive ? ERROR_MESSAGES.NUMBER_MIN_INCLUSIVE : ERROR_MESSAGES.NUMBER_MIN_EXCLUSIVE, { minValue });
  }
  return undefined;
}

validation.date = (value, allValues) => {
  return validateDateOrTime(value, allValues, ERROR_MESSAGES.INVALID_DATE);
};

function validateDateOrTime(value, allValues, message){
  if (value) {
    let isValidDate = moment(value, DateTimeHelper.getDateFormat(), true).isValid();
    if (!isValidDate) {
      return getErrorMessage(message);
    }
  }
  return undefined;
}

validation.time = (value, allValues) => {
 return validateDateOrTime(value, allValues, ERROR_MESSAGES.INVALID_TIME);
};

validation.endTimeCompare = (endTime, allValues) => {
  if(!endTime){
    return undefined;
  }

  if(endTime < allValues.startTime){
    return getErrorMessage(ERROR_MESSAGES.INVALID_END_TIME);
  }
  return undefined;
};

validation.minDate = (minDate) => (value, allValues) => {
    if (value) {
      let isValidDate = moment(value, DateTimeHelper.getDateFormat(), true).isValid();
      if (!isValidDate) {
        return getErrorMessage(ERROR_MESSAGES.INVALID_DATE);
      }
      if (minDate && minDate.isValid()) {
        if (moment(value, DateTimeHelper.getDateFormat(), true).isBefore(minDate, 'day')) {
          return getErrorMessage(ERROR_MESSAGES.INVALID_DATE_MIN_DATE, {date: DateTimeHelper.FormatDateToDayMonth(minDate)});
        }
      }
    }
    return undefined;
};

validation.maxDate = (maxDate) => (value, allValues) => {
  if (value) {
    let isValidDate = moment(value, DateTimeHelper.getDateFormat(), true).isValid();
    if (!isValidDate) {
      return getErrorMessage(ERROR_MESSAGES.INVALID_DATE);
    }
    if (maxDate && maxDate.isValid()) {
      if (moment(value, DateTimeHelper.getDateFormat(), true).isAfter(maxDate, 'day')) {
        return getErrorMessage(ERROR_MESSAGES.INVALID_DATE_MAX_DATE, {date: DateTimeHelper.FormatDateToDayMonth(maxDate)});
      }
    }
  }
  return undefined;
};

validation.minDateTime = (minDateTime) => (value, allValues) => {
  if (value) {
    let isValidDate = moment(value, DateTimeHelper.getDateFormat(), true).isValid();
    if (!isValidDate) {
      return getErrorMessage(ERROR_MESSAGES.INVALID_DATE);
    }
    if (minDateTime && minDateTime.isValid()) {
      if (moment(value, DateTimeHelper.getDateFormat(), true).isBefore(minDateTime, 'minute')) {
        return getErrorMessage(ERROR_MESSAGES.INVALID_DATE_MIN_DATE, {date: DateTimeHelper.DateTimeFormat(minDateTime)});
        // return getErrorMessage(ERROR_MESSAGES.INVALID_DATE_MIN_DATE, {date: moment(minDateTime, DateTimeHelper.getDateTimeFormat(), true)});
      }
    }
  }
  return undefined;
};

validation.maxDateTime = (maxDateTime) => (value, allValues) => {
  if (value) {
    let isValidDate = moment(value, DateTimeHelper.getDateTimeFormat(), true).isValid();
    if (!isValidDate) {
      return getErrorMessage(ERROR_MESSAGES.INVALID_DATE);
    }
    if (maxDateTime && maxDateTime.isValid()) {
      if (moment(value, DateTimeHelper.getDateFormat(), true).isAfter(maxDateTime, 'minute')) {
        return getErrorMessage(ERROR_MESSAGES.INVALID_DATE_MAX_DATE, {date: DateTimeHelper.DateTimeFormat(maxDateTime)});
        // return getErrorMessage(ERROR_MESSAGES.INVALID_DATE_MAX_DATE, {date:  moment(maxDateTime, DateTimeHelper.getDateTimeFormat(), true)});
      }
    }
  }
  return undefined;
};



validation.emailNotRequired = (value, allValues) => {
  if (!value || value === '') {
    return undefined;
  }

  let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!pattern.test(value)) {
    return getErrorMessage(ERROR_MESSAGES.INVALID_EMAIL_TEXT);
  }
  return undefined;
};

validation.email = (value, allValues) => {
  if (!value || value === '') {
    return undefined;
  }

  let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!pattern.test(value)) {
    return getErrorMessage(ERROR_MESSAGES.INVALID_EMAIL_TEXT);
  }
  return undefined;
};

validation.phone = (value, allValues) => {
  if (!value || value === '') {
    return undefined;
  }

  let pattern = /^\+?[0-9]{2,3}-?[0-9]{6,12}$/;
  if (!pattern.test(value)) {
    return getErrorMessage(ERROR_MESSAGES.INVALID_PHONE_TEXT);
  }
  return undefined;
};
validation.range = (min, max) => (value, allValues) => {

  if (!value || value === '' || isNaN(min) || isNaN(max)) {
    return undefined;
  }

  if (min <= value && value <= max) {
    return undefined;
  }
  return getErrorMessage(ERROR_MESSAGES.INVALID_RANGE , {min,max});
};
validation.rangeWithDefaultNumber = (defaultNum, min, max) => (value, allValues) => {

  if (!value || value === '' || isNaN(min) || isNaN(max)) {
    return undefined;
  }

  if(value == defaultNum){
    return undefined;
  }

  if (min <= value && value <= max) {
    return undefined;
  }
  return getErrorMessage(ERROR_MESSAGES.INVALID_RANGE_WITH_DEFAULT , {defaultNum, min,max});
};


validation.phoneNotRequired = (value, allValues) => {
  if (!value || value === '') {
    return undefined;
  }

  let pattern = /^\+?[0-9]{2,3}-?[0-9]{6,12}$/;
  if (!pattern.test(value)) {
    return getErrorMessage(ERROR_MESSAGES.INVALID_PHONE_TEXT);
  }
  return undefined;
};

validation.orgNameFormat = (value, allValues) => {
  if (value) {
    // let pattern = /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*(?:\-[a-zA-Z0-9]+)*$/g;
    //let pattern = /^[a-zA-Z0-9]+(?:(\.|\-)[a-zA-Z0-9]+)*/g;
    let pattern = /^[a-zA-Z0-9]+(([.-][a-zA-Z0-9]+)+)?$/g;

    if (!pattern.test(value)) {
      return getErrorMessage(ERROR_MESSAGES.INVALID_ORG_NAME_FORMAT);
    }
  }
  return undefined;
};

validation.maxLength = (maxVal) => (value, allValues) => {
  if (value && value.length > maxVal) {
    // `Must be ${maxVal} characters or less`;
    return getErrorMessage(ERROR_MESSAGES.TEXT_MAX_LENGTH, {maxLength: maxVal});
  }
  return undefined;
};

validation.ipFormat = (value, allValues) => {
  if (value) {
    let pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (!pattern.test(value)) {
      return getErrorMessage(ERROR_MESSAGES.INVALID_IP_FORMAT);
    }
  }
  return undefined;
};

validation.ipOrRangeFormat = (value) => {
  if (value) {
    let pattern = /(^(\s*)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\s*)$)|(^(\s*)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\s*\-\s*)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\s*)$)/;
    if (!pattern.test(value))
      return getErrorMessage(ERROR_MESSAGES.INVALID_IP_RANGE_FORMAT);
  }
  return undefined;
}

validation.unique = (selector, comparer = null, valueToLabel = null) => (value, allValues) => {
  if (!value)
    return undefined;
  let items = selector(allValues), count = 0;
  if (items.count < 2)
    return undefined; //no duplicates
  for (let i in items) {
    if (comparer ? comparer(items[i], value) : items[i] === value)
      count++;
  }
  if (1 < count) {
    let label = valueToLabel ? valueToLabel(value) : value.label || value; //prefer converter, then (if exists) value.label, then value
    return getErrorMessage(ERROR_MESSAGES.UNIQUE_VALUE_GENERIC, { value: label });
  }
  return undefined;
}


///////////////////////////
//Drop down components validations functions.
validation.dropdown = {};

validation.dropdown.required = (value, allValues) => {
  if (!value || value.length === 0 || (typeof value === 'object' && value.hasOwnProperty('value') && value.value === undefined )) {
    return getErrorMessage(ERROR_MESSAGES.REQUIRED_TEXT);
  }
  return undefined;
};

validation.dropdown.maxLength = (maxVal) => (value, allValues) => {
  if (value && typeof value === 'object' && value.hasOwnProperty('value') && value.hasOwnProperty('label') && value.value === value.label) {
    if (value.value.length > maxVal) {
      return getErrorMessage(ERROR_MESSAGES.TEXT_MAX_LENGTH, {maxLength: maxVal});
    }
  }
  return undefined;
};




/////////////////////////////////////////////////////
// SPECIALIZED VALIDATORS.

validation.specialized = {};

validation.specialized.createKitWizard = {};
validation.specialized.createKitWizard.uniqueNewBusinessId = (value, allValues) => {

  // Precaution - we don't validate value existence here.
  if (isEmpty(value)) {
    return undefined;
  }

  // Find all tabs in the Form with this businessId.
  let formNames = Object.keys(allValues);
  let allKitsWithThisBusinessId = formNames.filter(formName => {
    let kit = allValues[formName];
    return (kit && kit.kitId && kit.kitId === value);
  });
  //
  // // Check that only one such tab exists.
  if (allKitsWithThisBusinessId.length > 1) {
    return getErrorMessage(ERROR_MESSAGES.MUST_BE_UNIQUE)
  }

  return undefined;
};

validation.specialized.createKitWizard.uniqueNewKitType = (value, allValues) => {

  // Precaution - we don't validate value existence here.
  if (isEmpty(value)) {
    return undefined;
  }

  // Find all tabs in the Form with this businessId.
  let formNames = Object.keys(allValues);
  let allKitsWithThisKitType = formNames.filter(formName => {
    let kit = allValues[formName];
    return (kit && kit.kitType && (kit.kitType.value === kit.kitType.label) && (kit.kitType.label === value.label));
  });
  //
  // // Check that only one such tab exists.
  if (allKitsWithThisKitType.length > 1) {
    return getErrorMessage(ERROR_MESSAGES.MUST_BE_UNIQUE)
  }

  return undefined;
};

export default validation;


///////////////////////////
function varietyValidator(input, requiredVariety) {
  var counts = {};
  Array.from(input).forEach(function (x) {
    counts[x] = (counts[x] || 0) + 1;
  });
  let isError;
  Object.values(counts).forEach(function (value, index) {
    if (value > requiredVariety) {
      isError = true
    }
  });
  return !!isError
}
function getErrorMessage(label, values , defaultValue = undefined) {
  let labels = createLabelHelper('mat.validation.messages.');
  return (labels.get(label, defaultValue, values) || label);
}
function getPasswordErrorMessage(label, values , defaultValue = undefined) {
  let labels = createLabelHelper('');
  return (labels.get(label, defaultValue, {n:values}) || label);
}

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as materialsService from '../../../../services/Materials/materialsService.js';
import uniqBy from "lodash-es/uniqBy";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_MATERIALTYPE_DIALOG_SHOW:               'CREATE_MATERIALTYPE_DIALOG_SHOW',
  CREATE_MATERIALTYPE_DIALOG_HIDE:               'CREATE_MATERIALTYPE_DIALOG_HIDE',
  CREATE_MATERIALTYPE_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_MATERIALTYPE_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_MATERIALTYPE_DIALOG_SUBMIT_FINISHED:    'CREATE_MATERIALTYPE_DIALOG_SUBMIT_FINISHED',
  CREATE_MATERIALTYPE_DIALOG_FETCH_DATA_READY:   'CREATE_MATERIALTYPE_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_MATERIALTYPE_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_MATERIALTYPE_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_MATERIALTYPE_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_MATERIALTYPE_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function(payload) {
    return {type: actionTypes.CREATE_MATERIALTYPE_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, isEditMode, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    api.fetchDialogData(dispatch, getState)();

    dispatch(actions.show(data));
  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function() {
    return materialsService.fetchAllMaterials().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch materials failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let materials = response.dataList.filter((obj) => {return !!obj.materialName});
      materials = uniqBy(materials, 'materialName');

      let dialogData = {
        materials: materials.map((obj) => {return {value: obj.id, label: obj.materialName, data: obj}}),
      };

      dispatch(actions.fetchDilaogDataReady(dialogData));
    });
  }
};

api.submit = function(dispatch, getState) {
  return function(data, isEditMode, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    let method = isEditMode ? materialsService.editMaterial : materialsService.createMaterial;

    method(data).then((response) => {
        dispatch(actions.submitFinished());
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Create/Edit material failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }

        return {success: true};
      });
  };
};


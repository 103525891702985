import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as navigatorApi } from '../AppTopbar/navigatorActions';
import { api as gridApi} from './pickListsGridActions';
import {navigationStates} from '../../enums/navigationStates';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';
import Network from 'infrastructure/js/modules/network';
import * as rollService from '../../services/Rolls/rollsService';
import * as pickListsService from '../../services/PickLists/pickListsService';
import {api as locationPageApi} from '../LocationPage/locationPageActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    api.init(dispatch, getState)();
  }
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

jsxActions.deletePickList = function(data) {
  return function(dispatch, getState) {
    api.deletePickListConfirmationDialog(dispatch, getState)(data);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function() {
    const id = -1;
    const type = navigationStates.PICK_LISTS;
    const name = createLabelHelper('mat.header.navigator.predefinedlists.').get('pickLists');

    navigatorApi.setData(dispatch, getState)({id, type, name});
  }
}

api.removeFromPickList = function (dispatch, getState) {
  return function (rows) {

    let rollIds = rows.map((roll) => { return roll.id });
    rollService.removeFromPickList(rollIds).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('pick list - remove rolls from pick list failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
    });

    if (PermissionManager.hasLocationPickListTabPermissions()) {
      return locationPageApi.reload(dispatch, getState)();
    }
    gridApi.reload(dispatch, getState)();
  }
};

api.reload = function(dispatch, getState) {
  return function() {
    return gridApi.reload(dispatch, getState)();
  }
};

api.deletePickListConfirmationDialog = function(dispatch, getState) {
  return function( data) {
    let labels = createLabelHelper('mat.pickLists.page.deletePickList.confirmation.');
    let dialogLabels = createLabelHelper('mat.dialog.');

    let confirmationConfig = {
      title: labels.get('title'),
      message: labels.get('message'),
      submitButtonLabel: dialogLabels.get('remove'),
      cancelHandler: messageDialogApi.close(dispatch, getState),
      submitHandler: () => api.deletePickList(dispatch, getState)(data)
    }
    messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
  }
};

api.deletePickList = function(dispatch, getState) {
  return function(data) {

    messageDialogApi.close(dispatch, getState)();

    pickListsService.deletePickList(data?.id).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('pick list - delete pick list failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      gridApi.reload(dispatch, getState)();
    });
  }
};

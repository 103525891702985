import React from 'react';
import PropTypes from 'prop-types';

import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';

require('./statusCell.scss');

const kitEmptyImg = require("infrastructure/assets/images/kit-empty.png");
const kitHalfImg = require("infrastructure/assets/images/kit-half.png");
const kitFullImg = require("infrastructure/assets/images/kit-full.png");

export default class StatusCell extends React.PureComponent {

  getIcon = () => {
    let icon = kitEmptyImg;
    switch (this.props.value.capacityState) {
      case getEnumValue(enumTypes.CAPACITY_STATE)('EMPTY'):
        icon = kitEmptyImg;
        break;
      case getEnumValue(enumTypes.CAPACITY_STATE)('HALF'):
        icon = kitHalfImg;
        break;
      case getEnumValue(enumTypes.CAPACITY_STATE)('FULL'):
        icon = kitFullImg;
        break;
      default:
        console.error(`StatusCell - invalid capacity state: ${this.props.value.capacityState}`);
        break;
    }

    return icon;
  };

  render() {
    if (!this.props.value) {
      return '';
    }

    return (
      <div className="kitting-status-custom-cell">
        <label>{this.props.value.description}</label>
        <br/>
        <img src={this.getIcon()}/>
      </div>)
  }
}

StatusCell.propTypes = {
  value : PropTypes.object
};





import {enumTypes, getEnumValue} from './enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

export function isShippingOrReceivingLocation(location) {
  return ( /*location &&*/
          (location.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_AREA') ||
           location.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('RECEIVING_AREA'))
         );
}

export function isTransitArea(location) {
  return ( /*location &&*/
    location.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA'))
}

export function isAFPStation(location) {
  return ( getEnumValue(enumTypes.LOCATION_TYPE)('AFP') === location?.locationType );
}

export function getLocationAssetTypes(location, firstAssetType) {
  let types = [];

  if (!isTransitArea(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'));
  }
  if (!isShippingOrReceivingLocation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'));
  }
  if (!isTransitArea(location) && !isAFPStation(location) && !isShippingOrReceivingLocation(location) ) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('KIT'));
  }
  if (isAFPStation(location) ) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('PART'));
  }
  if (PermissionManager.hasGroupAssetPermissions() &&
      PermissionManager.getOrgPreferences().groupsEnabled &&
      !isShippingOrReceivingLocation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'));
  }
  if (!isTransitArea(location) && !isShippingOrReceivingLocation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'));
  }

  if (firstAssetType) {
    let index = types.indexOf(firstAssetType);
    if (index < 0) {
      console.error('getLocationAssetTypes(): invalid firstAssetType: ' + firstAssetType);
      return types;
    }

    let firstType = types.splice(index, 1);
    return [...firstType, ...types];
  }
  return types;
}

export function getLocationAssetTypesForPrintPdfLabels(location, firstAssetType) {
  let types = [];

  if (!isTransitArea(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'));
  }
  if (!isShippingOrReceivingLocation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'));
  }
  if (!isTransitArea(location) && !isAFPStation(location) && !isShippingOrReceivingLocation(location) ) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('KIT'));
  }
  if (PermissionManager.hasGroupAssetPermissions() &&
    PermissionManager.getOrgPreferences().groupsEnabled &&
    !isShippingOrReceivingLocation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'));
  }

  if (firstAssetType) {
    let index = types.indexOf(firstAssetType);
    if (index < 0) {
      console.error('getLocationAssetTypesForPrintPdfLabels(): invalid firstAssetType: ' + firstAssetType);
      return types;
    }

    let firstType = types.splice(index, 1);
    return [...firstType, ...types];
  }
  return types;
}

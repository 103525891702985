import Network from 'infrastructure/js/modules/network';
import { api as locationPageApi } from '../locationPageActions.js';
import { api as tasksGridApi } from './tasksGridActions';
import * as tasksService from '../../../services/Tasks/tasksService';
import { fetchTasksCounters } from '../../../services/Scheduler/schedulerService';
import { api as messageDialogApi } from '../../../actions/MessageDialog/messageDialogActions';
import * as boHelper                from '../../Dialogs/batchOperationActionsHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('LOCATIONPAGE_TASKSVIEW'),
    LOCATIONPAGE_TASKSVIEW_FETCHCHARTS_FINISHED: 'LOCATIONPAGE_TASKSVIEW_FETCHCHARTS_FINISHED',
    LOCATIONPAGE_TASKSVIEW_GETOPERATIONS_FINISHED: 'LOCATIONPAGE_TASKSVIEW_GETOPERATIONS_FINISHED',
    LOCATIONPAGE_TASKSVIEW_CHANGE_VIEW_DATE: 'LOCATIONPAGE_TASKSVIEW_CHANGE_VIEW_DATE',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('LOCATIONPAGE_TASKSVIEW'),
    fetchChartsFinished: function (payload) {
        return { type: actionTypes.LOCATIONPAGE_TASKSVIEW_FETCHCHARTS_FINISHED, payload: payload };
    },
    getOperationsFinished: function (payload) {
        return {
            type: actionTypes.LOCATIONPAGE_TASKSVIEW_GETOPERATIONS_FINISHED,
            payload: payload,
        };
    },
    changeViewDate: function (payload) {
        return {
            type: actionTypes.LOCATIONPAGE_TASKSVIEW_CHANGE_VIEW_DATE,
            payload: payload,
        };
    },
};

const config = {
  submitMethod: tasksService.bulkUpdateTasksProgress,
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {
  ...boHelper.getApiActions(actions, config),

  init(dispatch, getState) {
    return function (shouldRefreshGrid) {
      if (shouldRefreshGrid) {
        tasksGridApi.reload(dispatch, getState)();
      }

      return api.fetchCharts(dispatch, getState)();
    };
  },

  fetchCharts(dispatch, getState) {
    return function () {
      const locationId = locationPageApi.getLocationId(dispatch, getState)();
      const viewDate = getState().locationPage.getIn(['tasksViewData', 'viewDate']);
      const payload = {
        viewDate,
      };
      return fetchTasksCounters({locationId, payload}).then((response) => {
        let success = true;

        if (!Network.isResponseValid(response)) {
          console.error('WorkOrders fetch charts failed');
          success = false;
          return;
        }

        dispatch(
          actions.fetchChartsFinished({
            completed: response?.data?.completedTasksCount,
            total: response?.data?.totalTasksCount,
            success,
          })
        );
      });
    };
  },
}

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

  export let jsxActions = {
    ...boHelper.getJsxActions(api),

    changeViewDate(viewDate) {
      return function (dispatch, getState) {
        dispatch(actions.changeViewDate(viewDate));
        api.fetchCharts(dispatch, getState)();
        tasksGridApi.reload(dispatch, getState)();
      };
    },

    bulkProgressReport({selectedRows, status, isReportAsPlanned}, messageDialogBuilder) {
      return function (dispatch, getState) {
        tasksGridApi.setBusy(true)(dispatch, getState);
        const locationId = getState().appTopbar.getIn([
          'topNavigatorData',
          'currentLocation',
          'id',
        ]);
        const performedBy = getState().login.get('loggedInUser')?.id;
        const query = {
          tasksProgressList: _convertRowsToTasksList(selectedRows),
          locationId,
          status,
          isReportAsPlanned,
          performedBy,
        };

        api.submit(dispatch, getState)(query, messageDialogBuilder).then((response) => {

            api.fetchCharts(dispatch, getState)();
            tasksGridApi.reload(dispatch, getState)();

          })
          .catch((err) => {
            console.error('Bulk update tasks progress failed.', err);
            messageDialogApi.responseError(dispatch, getState)();
          });
      };
    },

    clearFilters () {
      return function (dispatch, getState) {
        tasksGridApi.clearFilters(dispatch, getState)();
      }
    },
  }
/////////////////////////////////////////////////////////////////

function _convertRowsToTasksList(rows) {
    return (
        rows.toArray()?.map((row) => ({
            taskAssignmentId: row?.id,
            toolId: row?.selectedToolId,
            // estimatedLeftDuration: 120,
        })) || []
    );
}

import React from 'react';
import {reduxForm} from 'redux-form';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell';
import MoreInfoCell from './CustomCells/MoreInfoCell/moreInfoCell';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import MaterialHelper from '../../../../utils/materialHelper';
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import {navigationStates} from '../../../../enums/navigationStates';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
require('./archivedRollsGrid.scss');

class ArchiveRollsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'rollBusinessId', getOptions: false},
    {fieldName: 'material.businessId', filterName: 'rollMaterial', getOptions: false},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'assetStatus', filterName: 'assetStatus', getOptions: true},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetEtl', getOptions: false},
    {fieldName: 'lastStatusChangeDate.epochDateTime', filterName: 'lastStatusChangeDate'}
  ];

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterSections: getAlertColumnFilterSections([alertStatus.INFO, alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.RESERVED, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        filterName: 'assetAlert',
        columnOptions: {
          sort: 'desc',
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              defrostingTimeLeft: params.data.defrostingTimeLeft,
              pickListBusinessId: params.data.pickListBusinessId,
            };
          }
        },
      },
      {
        fieldName: 'assetStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetStatus',
        valueFormatter: (params) => {
          return (params.data.assetStatusLabel || '');
        },
        columnOptions:{
          valueGetter: (params) => {
              return (params.data.assetStatus || '');
         },
        }
      },
      {
        fieldName: 'lastStatusChangeDate.epochDateTime',
        title: this.labels.get('columns.archivingDate.title'),
        filterType: filterTypes.NONE,
        valueFormatter: (params) => {
          return (params.data && params.data.m_archivingDate ? params.data.m_archivingDate : '');
        },
        columnOptions:{
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon()),
          valueGetter: (params) => {
            return params.data.lastStatusChangeDate?.epochDateTime;
          }
        }
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'rollMaterial',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ASSET_ARCHIVED, FetchEntitiesFilters.ASSET_TYPE_ROLL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.rollId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'rollBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.ROLL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ARCHIVED],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.ROLL
            };
          }
        }
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.moreInfo.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MoreInfoCell,
          sortable: false,
          valueGetter: (params) => {
            return {
              lot: params.data.lot,
              lengthLeft: params.data.m_lengthLeft,
              weight: params.data.m_weight,
            };
          }
        }
      }
    ]
  }

  getRowHeight() {
     return 100;
  }

  render() {
    return (
      <div className="archive-rolls-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ARCHIVE_ROLLS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}

              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'ArchiveRollsGrid'
  }
)(ArchiveRollsGrid);







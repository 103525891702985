import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';

import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import { capitalizeFirstLetter } from 'infrastructure/js/utils/componentUtils';
import MultiEntitiesMultiSelect from '../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect.js';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';

require('./activateAssetsDialog.scss');

class ActivateAssetsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.assetpage.activateassetdialog.');
    this.entityLabels = createLabelHelper('mat.entity.type.');
    // when the asset is injected from outside through the props,
    // the dialog runs in NOT batch operation mode
    this.isBatchOperation = !this.props.asset;
    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.props.sData.get('boEntityFilter')
    };
  }

  getDialogButtons() {
    let cancelTokenEnabled = !!this.props.sData.get('cancelToken') ;
    return {
      left: [{
        id:'cancel',
        text:   (this.isBatchOperation && this.props.sData.get('loading')) ? this.dialogLabels.get('stop') : this.dialogLabels.get('cancel'),
        action: (this.isBatchOperation && this.props.sData.get('loading')) ? this.onStop : this.onHide,
        enabledOnLoading: this.isBatchOperation && cancelTokenEnabled
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.activate'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
          this.props.sData.get('hasError') ||
          (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))

        }
      ]
    };
  }

  onSubmit = (data) => {

    let assetsIds = [];

    if (this.isBatchOperation) {
      let allRows = data['entities-multi-select-ex'];
      allRows.map((row) => {
        if (row && row.assetsValue) {
          row.assetsValue.map((asset) => {
            assetsIds.push(asset.value);
          });
        }
      });
    }
    else {
      assetsIds.push(this.props.asset.get('id'));
    }

    let newData = {
      assetsIds : assetsIds,
      status: getEnumValue(enumTypes.ASSET_STATUS)('ACTIVE'),
      comment: data.comment ? data.comment : '',
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };

    let messageDialogBuilder = this.isBatchOperation ? this.getMessageDialogBuilder() : null;
    this.props.actions.submit(newData, messageDialogBuilder, this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get("confirmation.message.title", "", { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  renderAssetsMultiSelect = () => {
    if (!this.isBatchOperation) {
      return null;
    }

    let preselectedAssets = this.props.sData.get('preselectedAssets');
    return (
      <InputSection label={ this.labels.get('selectassets.text')+'*' } >
        <MultiEntitiesMultiSelect name="entities-multi-select-ex"
                               entitiesTypes={this.entitiesTypes}
                               validate={Validation.required}
                               preSelectedEntities={preselectedAssets}
                               fetchConfig={this.fetchConfig}
                               autoFocus={true}
                               { ...this.props }/>
      </InputSection>
    );
  };

  renderConfirmation = () => {
    if (this.isBatchOperation) {
      return null;
    }
    return <div className="activate-assets-confirmation">{this.labels.get('confirmation.text', '', {assetType: this.props.asset.get('objectType')})}</div>
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  render() {
    let titleText = this.labels.get('header.title.multiple');
    if (!this.isBatchOperation) {
      let objectType = this.props.asset.get('objectType');
      titleText = this.labels.get('header.title', '', {assetType: capitalizeFirstLetter(objectType)});
    }

    return (
      <Dialog
        id="activate-assets-dialog"
        className="activate-assets-dialog"
        titleText={titleText}
        show={this.props.sData.get("show")}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        {this.renderAssetsMultiSelect()}

        {this.renderConfirmation()}

        <InputSection label={this.labels.get('comment')} htmlFor="comment" className="full-width">
          <TextAreaField id="comment-textField" name="comment" className="comment-textField" />
        </InputSection>

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'activateAssetsDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(ActivateAssetsDialog);

ActivateAssetsDialog.defaultProps = {
};
ActivateAssetsDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  asset : PropTypes.object,
  sData : PropTypes.object.isRequired,
};

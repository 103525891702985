import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from "../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell";
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./preferencesViewGrid.scss');
export default class PreferencesViewGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: "name", filterName: "displayName", getOptions: false},
    {fieldName: "defaultValue", filterName: "defaultValue", getOptions: false},
    {fieldName: "value", filterName: "orgValue", getOptions: false},
    {fieldName: "description", filterName: "description", getOptions: false}
  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.preferences.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.props.isRfid ? this.labels.get('columns.zpl.title') : this.labels.get('columns.preference.title'),
        filterType: filterTypes.SEARCH,
        filterName: 'displayName',
        width: 300,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'defaultValue',
        title: this.labels.get('columns.defaultValue.title'),
        filterType: filterTypes.SEARCH,
        filterName: 'defaultValue',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'value',
        title:  this.props.isRfid ? this.labels.get('columns.actualOrgValue.title') : this.labels.get('columns.actualOrgValue.title'),
        filterType: filterTypes.SEARCH,
        filterName: 'orgValue',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'description',
        title: this.labels.get('columns.description.title'),
        filterType: filterTypes.SEARCH,
        filterName: 'description',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
    ]
  };

  getRowHeight(params) {
    return 64;
  }

  render() {
    return (
      <div className="preferences-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_SETTINGS_PREFERENCES}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}


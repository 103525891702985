import Network from 'infrastructure/js/modules/network';
import * as systemService from 'infrastructure/js/services/systemService';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LATENCY_CALC_IN_PROCESS: 'LATENCY_CALC_IN_PROCESS',
  LATENCY_CALC_FAILED: 'LATENCY_CALC_FAILED',
  LATENCY_CALC_CALC_FINISHED: 'LATENCY_CALC_CALC_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  latencyCalculationInProcess: function () {
    return {type: actionTypes.LATENCY_CALC_IN_PROCESS};
  },
  latencyCalculationFailed: function () {
    return {type: actionTypes.LATENCY_CALC_FAILED};
  },
  latencyCalculationFinished: function (value) {
    return {type: actionTypes.LATENCY_CALC_CALC_FINISHED, payload: {value}};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.calcLatency = function () {
  return function (dispatch, getState) {
    api.calcLatency()(dispatch, getState);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.calcLatency = function () {
  return function (dispatch, getState) {
    dispatch(actions.latencyCalculationInProcess());
    try {
      var startActionTime = new Date();
      var responseTimeValues = [];

      _calcAverageLatency(dispatch, responseTimeValues, startActionTime);
    }
    catch (err) {
      dispatch(actions.latencyCalculationFailed());
      console.error('error getting server response time');
      return;
    }
  }
};

function _calcAverageLatency(dispatch, responseTimeValues, startActionTime) {
  let startTime = new Date();
  // stop loop after 5 seconds
  if (startTime - startActionTime > 5000) {
    let average;
    if (responseTimeValues.length === 1) {
      average = responseTimeValues[0];
    } else {
      average = (responseTimeValues.reduce((a, b) => a + b)) / responseTimeValues.length;
    }

    dispatch(actions.latencyCalculationFinished(Math.round(average)));
    return;
  }

  /*systemService.getSpeedTest().then(() => {
    var endTime = new Date();
    var duration = endTime - startTime;

    responseTimeValues.push(duration);
    _calcAverageLatency(dispatch, responseTimeValues, startActionTime)
  });
*/
  systemService.getSpeedTest(() => {
    var endTime = new Date();
    var duration = endTime - startTime;

    responseTimeValues.push(duration);
    _calcAverageLatency(dispatch, responseTimeValues, startActionTime)
  });
}





import * as daHelper from '../Dialogs/dialogActionsHelper';
import { dialogsNames } from '../../enums/dialogsNames';
import { fetchActiveWorkOrders } from '../../services/WorkOrders/workOrdersService';
import { getAllStations } from '../../services/Stations/stationsService';
import { fetchActiveTools } from '../../services/Tools/toolsService';
import * as plannerService from '../../services/Scheduler/plannerService';

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';

const config = {
    getSubmitMethod: () => plannerService.updatePermutationSettings, //set null when override the dialogActionsHelper's submit() method
};

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
    ...daHelper.getActionTypes(dialogsNames.ANALYTIC_MODE_SETTINGS_DIALOG),
};

const actions = {
    ...daHelper.getActions(dialogsNames.ANALYTIC_MODE_SETTINGS_DIALOG),
};

export let api = {
    ...daHelper.getApiActions(actions, config),

    show(dispatch, getState) {
        return function (data = null) {
            dispatch(actions.show(data));
            api.fetchDialogData(dispatch, getState)();
        };
    },

    fetchDialogData(dispatch, getState) {
        return function () {
            dispatch(actions.setBusy(true));

            const promises = [
                fetchActiveWorkOrders(),
                getAllStations(),
                fetchActiveTools(),
                plannerService.fetchPermutationSettings(),
            ];

            return Promise.all(promises)
                .then((allResults) => {
                    const invalidResponse = allResults.find((response) => {
                        return !Network.isResponseValid(response);
                    });

                    if (invalidResponse) {
                        console.error(
                            'Failed to get analytic mode settings dialog initial data',
                            invalidResponse
                        );
                        messageDialogApi.responseError(dispatch, getState)(invalidResponse);
                        dispatch(actions.fetchDialogDataReady());
                        return { success: false };
                    }

                    const dialogData = {
                        preselectedEntities: {
                            stations:
                                allResults[3].data?.schedulingPermutationSettingsListPerObjectType
                                    ?.STATION,
                            workOrders:
                                allResults[3].data?.schedulingPermutationSettingsListPerObjectType
                                    ?.WO,
                            tools: allResults[3].data
                                ?.schedulingPermutationSettingsListPerObjectType?.TOOL,
                        },
                        // preselectedEntities: {
                        //   stations: mockResponse.data.schedulingPermutationSettingsListPerObjectType.STATION,
                        //   workOrders: mockResponse.data.schedulingPermutationSettingsListPerObjectType.WO
                        // },
                        workOrders: allResults[0].dataList?.map((obj) => {
                            return { value: obj.id, label: obj.businessId, data: obj };
                        }),
                        stations: allResults[1].dataList?.map((obj) => {
                            return { value: obj.id, label: obj.name, data: obj };
                        }),
                        tools: allResults[2].dataList?.map((obj) => {
                            return { value: obj.id, label: obj.businessId, data: obj };
                        }),
                    };

                    dispatch(actions.fetchDialogDataReady(dialogData));
                    dispatch(actions.setBusy(false));
                })
                .catch((err) => {
                    console.error(
                        'Failed to get analytic mode settings dialog initial data: catch ',
                        err
                    );
                    messageDialogApi.responseError(dispatch, getState)();
                    return { success: false };
                });
        };
    },
};

export let jsxActions = {
    ...daHelper.getJsxActions(api),

    submit(data, messageDialogBuilder, reloadParentComponent) {
        return function (dispatch, getState) {
            return api.submit(dispatch, getState)(
                data,
                messageDialogBuilder,
                reloadParentComponent
            );
        };
    },
};

const mockResponse = {
    data: {
        schedulingPermutationSettingsListPerObjectType: {
            STATION: [
                {
                    id: 1009450,
                    objectId: 1000950,
                    objectType: 'STATION',
                    orgId: 1,
                    minRange: 2,
                    maxRange: 20,
                },
            ],
            WO: [
                {
                    id: 1009400,
                    objectId: 1002650,
                    objectType: 'WO',
                    orgId: 1,
                    minRange: 7,
                    maxRange: 70,
                },
                {
                    id: 1009100,
                    objectId: 1002700,
                    objectType: 'WO',
                    orgId: 1,
                    minRange: 4,
                    maxRange: 40,
                },
            ],
        },
    },
    applicationResponseStatus: {
        statusType: 'OK',
        message: '',
        errorCode: '',
        mid: null,
        internalErrorMessage: null,
        stackTrace: null,
        warnings: {
            mainWarning: null,
            subWarnings: [],
        },
        errors: {
            mainError: null,
            subErrors: [],
        },
    },
};

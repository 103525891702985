import * as rollsService from '../../../services/Rolls/rollsService';

import { api as locationPageApi } from '../locationPageActions.js';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import { api as kittingViewApi }  from '../KittingView/kittingViewActions.js';

import { change as reduxFormChange }   from 'redux-form';

import Network from 'infrastructure/js/modules/network';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CUTKITSTATION_CANCELCUTREPORTDIALOG_SHOW: "CUTKITSTATION_CANCELCUTREPORTDIALOG_SHOW",
  CUTKITSTATION_CANCELCUTREPORTDIALOG_HIDE: "CUTKITSTATION_CANCELCUTREPORTDIALOG_HIDE",

  CUTKITSTATION_CANCELCUTREPORTDIALOG_FETCH_INITIAL_DATA_READY: "CUTKITSTATION_CANCELCUTREPORTDIALOG_FETCH_INITIAL_DATA_READY",

  CUTKITSTATION_CANCELCUTREPORTDIALOG_REPORT_CUTS_IN_PROGRESS:  "CUTKITSTATION_CANCELCUTREPORTDIALOG_REPORT_CUTS_IN_PROGRESS",
  CUTKITSTATION_CANCELCUTREPORTDIALOG_REPORT_CUTS_FINISHED:     "CUTKITSTATION_CANCELCUTREPORTDIALOG_REPORT_CUTS_FINISHED"

};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CUTKITSTATION_CANCELCUTREPORTDIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CUTKITSTATION_CANCELCUTREPORTDIALOG_HIDE, payload: payload };
  },

  cancelCutReportInProgress: function(payload) {
    return {type: actionTypes.CUTKITSTATION_CANCELCUTREPORTDIALOG_REPORT_CUTS_IN_PROGRESS, payload: payload };
  },
  cancelCutReportFinished: function(payload) {
    return {type: actionTypes.CUTKITSTATION_CANCELCUTREPORTDIALOG_REPORT_CUTS_FINISHED, payload: payload };
  }

};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.onCancel = function() {
  return function(dispatch, getState) {
    api.closeDialog(dispatch, getState)();
  };
};

jsxActions.cancelCutReport = function(cutReport, shouldAddLength, messageDialogBuilder) {
  return function(dispatch, getState) {

    dispatch(actions.cancelCutReportInProgress());

    rollsService.cancelCutReport(cutReport.locationId, cutReport.id, shouldAddLength).then((response) => {
      dispatch(actions.cancelCutReportFinished());

      if (!Network.isResponseValid(response)) {
        console.error('Cancel Cut Report Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      kittingViewApi.setCurrentCutToKitDropdownToShowAll(dispatch, getState)();
      // Refresh everything.
      locationPageApi.reload(dispatch, getState)().then(() => {
        // Prepare and open Confirmation Message Dialog.
        let messageDialogDescriptor = messageDialogBuilder();

        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor, { autoClose : 2000});
      });
    });
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

// Someone wants to close the dialog.
api.closeDialog = function(dispatch, getState) {
  return function() {

    dispatch(actions.hide());

  }
};

// Someone opens the dialog.
api.openDialog = function(dispatch, getState) {
  return function(cut) {
    dispatch(actions.show(cut)); // Open dialog first.
  }
};



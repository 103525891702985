import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import moment from 'moment';

import './shiftSettingsDialog.scss';

export function ShiftSettingsDialog({
  sData,
  actions,
  reloadParentComponent,
  handleSubmit,
  pristine,
  initialize,
}) {
  const itemToEdit = sData.get('itemToEdit');
  const { disabled: isDisabled } = itemToEdit;

  const isHumanCapacityEnabled =
    PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled;

  const labels = useMemo(
    () => createLabelHelper('mat.administration.matsettings.dialog.shiftSettingsDialog.'),
    []
  );

  const dialogLabels = useMemo(() => createLabelHelper('mat.dialog.'), []);

  useEffect(() => {
    const initialData = {
      startTime: moment(itemToEdit?.shiftData?.startTime, 'HH:mm'),
      endTime: moment(itemToEdit?.shiftData?.endTime, 'HH:mm'),
    }
    
    const humanCapacity = itemToEdit?.shiftData?.humanCapacity;
    if (humanCapacity >= 0) {
      initialData.availableHumanCapacity = humanCapacity
    }

    initialize(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDialogButtons = () => {
    return {
      left: [
        {
          id: 'cancel',
          text: 'cancel',
          action: actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: 'Submit',
          bsStyle: 'primary',
          loading: sData.get('loading'),
          action: handleSubmit(onSubmit),
          disabled:
            pristine ||
            sData.get('loading') ||
            sData.get('hasError') ||
            (sData.get('showIgnoreValidationCheckbox') && !sData.get('isIgnoreValidationWarnings')),
        },
      ],
    };
  };

  const getFooterValidationCheckBox = () => {
    return sData.get('showIgnoreValidationCheckbox')
      ? {
          label: dialogLabels.get('ignorewarnings'),
          onChange: actions.toggleIgnoreValidationWarningsCheckbox,
        }
      : null;
  };

  const onSubmit = (data) => {
    const ignoreValidationWarnings =
      sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox
        ? data.footerValidationCheckbox
        : false;

    if (itemToEdit.isTemplate) {
      // if the dialog opens after successful multi assignment of shifts, provide their template ids.
      // otherwise provide template id from the shift data.
      const templateIds = itemToEdit?.shiftAssignmentIds ?? [itemToEdit.shiftData.templateId];

      const dataToSubmit = {
        ids: templateIds,
        humanCapacity: data?.availableHumanCapacity,
        ignoreValidationWarnings,
      };
      actions.submit({dataToSubmit, reloadParentComponent, isTemplate: true});
      return;
    }

    if(itemToEdit.isMultiSelectedShifts){
      const dataToSubmit = {
        shifts: itemToEdit?.shifts,
        humanCapacity: data?.availableHumanCapacity,
        ignoreValidationWarnings,
      };

      actions.submit({dataToSubmit, reloadParentComponent, isMultiSelectedShifts: true});
      return;
    }

    const { weeklyShiftOverrideId, templateId, workDate } = itemToEdit.shiftData;
    const _workDate = new Date(0);
    _workDate.setUTCSeconds(workDate?.epochDateTime)

    const dataToSubmit = {
      shifts: itemToEdit?.shifts,
      weeklyShiftOverrideId,
      weeklyShiftTemplateId: templateId,
      workDate: DateTimeHelper.ConvertFromDate(_workDate),
      startTime: data.startTime.format('HH:mm:ss'),
      endTime: data.endTime.format('HH:mm:ss'),
      humanCapacity: data?.availableHumanCapacity,
      ignoreValidationWarnings,
    };

    actions.submit({dataToSubmit, reloadParentComponent});
  };

  const stationName = itemToEdit?.resourceData?.displayName,
    day = itemToEdit?.day,
    shiftName = itemToEdit?.shiftData?.name;

  const headerText = `${stationName ? stationName + ' \\' : ''} ${
    day ? day + ' \\' : ''
  } ${shiftName ? shiftName : ''}`;

  const startTime = itemToEdit?.shiftData?.startTime
    ? moment(itemToEdit?.shiftData.startTime, 'HH:mm')
    : null;

  const endTime = itemToEdit?.shiftData?.endTime
    ? moment(itemToEdit?.shiftData.endTime, 'HH:mm')
    : null;

  return (
    <Dialog
      id="shift-settings-dialog"
      className="shift-settings-dialog"
      titleText={labels.get('title')}
      show={sData.get('show')}
      onHide={actions.hide}
      sData={sData}
      onEnterKeyPress={handleSubmit(onSubmit)}
      footerButtons={getDialogButtons()}
      footerValidationCheckbox={getFooterValidationCheckBox()}
    >
       <h4 className="body-header">{headerText}</h4>

      {!itemToEdit.isTemplate && !itemToEdit.isMultiSelectedShifts && (
        <>
          <InputSection label={labels.get('startTime')} htmlFor="startTime" className="inline left-side">
            <DatePicker
              className="hour-picker"
              id="startTime"
              name="startTime"
              initialDate={startTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeFormat={DateTimeHelper.getTimeFormat()}
              dateFormat={DateTimeHelper.getTimeFormat()}
              timeCaption=""
              placeholderText={labels.get('time')}
              validate={[Validation.required, Validation.time]}
              disabled={isDisabled}
            />
          </InputSection>

          <InputSection label={labels.get('endTime')} htmlFor="endTime" className="inline right-side">
            <DatePicker
              className="hour-picker"
              id="endTime"
              name="endTime"
              initialDate={endTime}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeFormat={DateTimeHelper.getTimeFormat()}
              dateFormat={DateTimeHelper.getTimeFormat()}
              timeCaption=""
              placeholderText={labels.get('time')}
              validate={[Validation.required, Validation.time]}
              disabled={isDisabled}
            />
          </InputSection>
        </>
      )}

      {isHumanCapacityEnabled && (
        <InputSection label={'Available Human Capacity'} htmlFor="availableHumanCapacity" className="left-side">
          <TextField
            id="availableHumanCapacity"
            name="availableHumanCapacity"
            className="short-textfield"
            normalize={Normalize.number(true, 1, 999)}
            validate={[Validation.required]}
            disabled={isDisabled}
          />
        </InputSection>
      )}
    </Dialog>
  );
}

export default reduxForm({
  form: 'shiftSettingsDialog',
})(ShiftSettingsDialog);

ShiftSettingsDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

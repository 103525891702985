import React from 'react';

import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper'
import {createLabelHelper} from "infrastructure/js/utils/labelHelper";

require('./defrostingLabelCell.scss');

export default class DefrostingLabelCell extends React.PureComponent {

  render() {
    if(!this.props.value || !this.props.value.statusLabel){
      return null
    }
    return (<div className="defrosting-label-cell">
      <label>{this.props.value.statusLabel}</label>
      {this.renderDefrostingTime()}
    </div>);
  }

  renderDefrostingTime(){
    if(!this.props.value || !this.props.value.defrostingTimeLeft){
      return null
    }
    let time =  DateTimeHelper.ConvertMinutesToHoursMinutes(this.props.value.defrostingTimeLeft);
    let timeLabel =  createLabelHelper('mat.grid.defrostingTime').get('',undefined, {time});
    return (<span>{timeLabel}</span>)
  }
}

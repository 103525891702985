import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import OnHandInventoryGrid from './OnHandInventoryGrid/onHandInventoryGrid';
import OnHandInventoryHeader from './OnHandInventoryHeader/onHandInventoryHeader';
import {gridsNames} from '../../../enums/gridsNames';

require('./onHandInventoryPage.scss');

export default class OnHandInventoryPage extends Component {

  componentDidMount() {
    this.props.actions.init();
  }

  getHeaderItems() {
    let {actions, sHasRows} = this.props;

    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.header.onExportOnHandInventoryClick,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_ON_HAND_INVENTORY, headerData: this.props.sHeaderData}
        },
      ],
    };

    return items;
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  render() {
    return (<div className="on-hand-inventory-page">
        <OnHandInventoryHeader actions={this.props.actions.onHandInventoryHeaderActions} sData={this.props.sHeaderData}/>
        <Header  {...this.getHeaderItems()}/>
        <OnHandInventoryGrid actions={{...this.props.actions.onHandInventoryGridActions, ...this.props.actions.fetchEntitiesActions}} />
      </div>
    )
  }
};

OnHandInventoryPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sOnHandInventoryGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
};



import React, {Component} from 'react';

import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import  * as dialogHelper  from "infrastructure/js/components/Dialog/dialogHelper";
import {createLabelHelper} from "infrastructure/js/utils/labelHelper";
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./confirmCreateKitsDialog.scss');


export class ConfirmCreateKitsDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels       = createLabelHelper('mat.locationpage.view.kitting.createkitwizard.');
    this.confirmationLabels = createLabelHelper('mat.locationpage.view.kitting.kittingOperation.confirmation.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {
      let printRfidTagsHandler = PermissionManager.getOrgPreferences().kittingPrintRfidTagsEnabled ? this.onPrintRfidTagsClick : null;
      return dialogHelper.BuildDialogDescriptorForKitOperations(data, this.confirmationLabels, fnCloseDialog, this.onCancelKitReportClick, printRfidTagsHandler);
    };
  };

  getCurrentCut = () => {
    const cutSelectionAllowed = this.props.sData.get('startParameters')?.allowCutSelection;
    if(cutSelectionAllowed){
      return this.props.sData.get('selectedCut');
    }

    return this.props.currentCut
  }

  onCancelKitReportClick = (reportedKits, fnCloseDialog) => {
    this.props.actions.onCancelKitReportClick(reportedKits);
    fnCloseDialog();
  };

  onPrintRfidTagsClick = (reportedKits, fnCloseDialog) => {
    this.props.actions.onPrintRfidTagsClick(reportedKits);
    fnCloseDialog();
  };

  onCancel = () => {
    this.props.actions.onCancel(this.getMessageDialogBuilder());
  };

  onConfirm = () => {
    this.props.handleSubmit((formData) => {
      this.props.actions.onWizardSubmit(formData, this.getCurrentCut(), this.getMessageDialogBuilder());
    })();
  };

  renderKitsSummarySections() {
    let newKitsForSubmit = this.props.sData.get('newKitsForSubmit');

    if (!newKitsForSubmit) {
      return (<div></div>);
    }

    // Sort kits by type
    let kitsByType = {};
    newKitsForSubmit.forEach((kit) => {
      let kitTypeString = kit.kitType.value;
      kitsByType[kitTypeString] = kitsByType[kitTypeString] || [];
      kitsByType[kitTypeString].push(kit);
    });


    let htmlKitTypeSection = [];
    for (let type in kitsByType) {
      if (kitsByType.hasOwnProperty(type)) {
        let kitGroup = kitsByType[type];
        htmlKitTypeSection.push(this.renderKitTypeSection(kitGroup, type));
      }
    }

    return <div>{htmlKitTypeSection}</div>;
  }

  renderKitTypeSection(kitGroup, type) {
    return (
      <div key={"kit-type-" + type} className="kit-type-section">
        {this.renderKitTypeSectionTitle(kitGroup, type)}
        {this.renderKitTypeSectionRows(kitGroup)}
      </div>
    );
  }

  renderKitTypeSectionTitle(kitGroup, type) {
    let typeLabel = kitGroup[0].kitType.label;

    return (
      <div key={"kit-type-title-" + type} className="inner-title-row">
        <div className="inner-title type">
          <span className="kits-type">{this.labels.get('page2.headings.type')} </span>
          <span className="kits-type-id">{typeLabel}</span>
          <span className="kits-type-count">({kitGroup.length})</span></div>
        <div className="inner-title plies">{this.labels.get('page2.headings.kittedplies')}</div>
      </div>
    );

  }

  getKitDefaultMaterialKittedPlies = (kit) => {
    if (kit && kit.materials) {
      let found = kit.materials.find((item) => {
        return item.isDefaultMaterial;
      });

      return found ? found.quantityKittedPlies : null;
    }
    return null;
  };

  renderKitTypeSectionRows(kitGroup) {
    return kitGroup.map((kit, index) => {
      let quantityKittedPlies = this.getKitDefaultMaterialKittedPlies(kit);
      return (

        <div className="inner-row" key={"kit-id-" + kit.kitId}>
          <div className="kit-id">{kit.kitId}</div>
          <div className="kit-kitted-plies">{quantityKittedPlies}</div>
        </div>
      );
    });
  }

  getTitleComponent() {
    const currentCut = this.getCurrentCut();
    const titleText = currentCut ? currentCut.material.businessId + ", Roll: " + currentCut.rollBusinessId : '';

    return (
      <div className="confirm-new-kit-wizard-title">
        <span>{this.labels.get('page2.header.title')}</span>
        <span className="more-info">{titleText}</span>
      </div>
    )
  }

  getFooterCenterText() {
    return (
      <div>
        <Checkbox name="printKitLabel" id="printKitLabel" />
        {this.labels.get('page2.footer.printkitlabels')}
      </div>
    );
  }

  getDialogButtons_step2() {
    return {

      left: [{
        id:'back',
        text: this.dialogLabels.get('back'),
        action: this.props.wizardActions.previous
      }],

      right: [
        {
          id:'submit',
          text: this.dialogLabels.get('create'),
          bsStyle: "primary",
          loading: this.props.sData.get('loading'),
          action: this.onConfirm
        }
      ]
    };
  }

  render() {

    let {currentCut} = this.props;

    let animation = this.props.isFirstLaunch;

    let loadingClass = (this.props.sData.get('loading') ? 'loading' : '');
    return (

      <Dialog
        id="confirm-new-kit-wizard"
        className={`confirm-new-kit-wizard ${loadingClass}`}
        animation={animation}
        show={true}
        onCancel={this.onCancel}
        onHide={this.onCancel}
        sData={this.props.sData}
        titleComponent={this.getTitleComponent()}
        footerCenterText={this.getFooterCenterText()}
        footerButtons={this.getDialogButtons_step2()}
        style={this.props.style}
      >

        {this.renderKitsSummarySections()}

      </Dialog>
    );

  }
}

export default reduxForm({
    forceUnregisterOnUnmount: true, // When this page isn't rendered, don't validate its fields.
    destroyOnUnmount: false         // When this page isn't rendered, don't destroy the form.
  }
)(ConfirmCreateKitsDialog);




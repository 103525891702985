import { Map } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';
import { actionTypes as actionTypes } from '../../../actions/SchedulerPage/autoSchedulerDialogActions';


let defaultState = {
  show: false,
  loading: false,
  itemsToEdit: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.AUTO_SCHEDULER_DIALOG_SHOW:
      return state.set('itemsToEdit', action.payload)
            .set('show', true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.AUTO_SCHEDULER_DIALOG, defaultState);
  }
}








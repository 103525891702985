import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import {jsxActions as organizationsPageActions} from '../../../actions/Administration/OrganizationsPage/organizationsPageActions';
import {jsxActions as createOrganizationDialogActions} from '../../../actions/Administration/OrganizationsPage/Dialogs/createOrganizationDialogActions';
import {jsxActions as deleteOrganizationDialogActions} from '../../../actions/Administration/OrganizationsPage/Dialogs/deleteOrganizationDialogActions';

import OrganizationsPage from '../../../components/Administration/OrganizationsPage/organizationsPage';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(organizationsPageActions, dispatch),
      createOrganizationDialogActions : bindActionCreators(createOrganizationDialogActions, dispatch),
      deleteOrganizationDialogActions : bindActionCreators(deleteOrganizationDialogActions, dispatch),
    }
  }
}

let OrganizationsPageContainer = connect(
  (state) => {
    return {
      sData:          state.administration.get('organizationsPage'),
      selectedTab:    state.administration.getIn(['organizationsPage', 'data', 'selectedTab']),
      sLoggedInUser : state.login.get('loggedInUser'),
      sCreateOrganizationDialogData: state.administration.getIn(['organizationsPage', 'createOrganizationDialog']),
      sDeleteOrganizationDialogData: state.administration.getIn(['organizationsPage', 'deleteOrganizationDialog']),
    };
  },
  mapDispatchToProps
)(OrganizationsPage);

export default OrganizationsPageContainer;




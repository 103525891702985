import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import AdditionalField from '../../../Common/Layout/AdditionalField/AdditionalField';

export default class GroupAttributes extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.editassetattributes.');
  }

  componentDidMount() {
    this.props.actions.fetchLocations();
    this.props.actions.fetchGroupTypes();
    this.props.actions.fetchAdditionalFields('GROUP');
  }

  renderAdditionalFields = ()=>{
    if(!this.props.sData.get('additionalFields')){
      return null;
    }
    return this.props.sData.get('additionalFields').map((field, index)=> {
      return (<AdditionalField key={index} field={field} index={index}/>)
    });
  };

  getGroupTypes = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData?.groupTypes || [];
  }

  render() {
    return (
      <div>

        <InputSection label={this.labels.get('groupType')} htmlFor="groupType" className="inline left-side">
          <Combobox id="groupType" name="groupType" options={this.props.sData.get('groupTypes') || []}/>
        </InputSection>

        <InputSection label={this.labels.get("maxstoragetemp", undefined, {units:UnitHelper.getLabelForUnitType(unitTypes.TEMPERATURE)})}  htmlFor="maxStorageTemp" className="inline right-side">
          <TextField id="maxStorageTemp" name="maxStorageTemp" className="short-textfield" normalize={Normalize.number(true,UnitHelper.getMinValueForUnitType(unitTypes.TEMPERATURE, 0), UnitHelper.getMaxValueForUnitType(unitTypes.TEMPERATURE, 0))}/>
        </InputSection>

        <InputSection label={this.labels.get("location")} htmlFor="location" className="inline left-side">
          <Combobox id="location" name="location" options={this.props.sData.get('locations') || []} />
        </InputSection>

        <InputSection label={this.labels.get("sublocation")} htmlFor="subLocation" className="inline right-side">
          <TextField id="subLocation" name="subLocation" />
        </InputSection>


        <div>
          {this.renderAdditionalFields()}
        </div>
      </div>

    );
  }
}




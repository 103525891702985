import Network from 'infrastructure/js/modules/network';

export function getAllFavorites() {
  return Network.get('navigation/getAllFavoriteNavigations');
}

export function saveFavorite(body) {
  return Network.post('navigation/saveFavoriteLink', body);
}

export function deleteFavorite(body) {
  return Network.delete('navigation/deleteFavorite', body);
}

export function setHomePage(body) {
  return Network.put('homePage', body);
}

export function getHomePage() {
  return Network.get('homePage');
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, routerShape } from 'react-router-dom';
import { LoginInput, LoginLayout } from '../LoginLayout/loginLayout';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import * as AppHelper from 'infrastructure/js/utils/appHelper';

require('./loginPage.scss');

export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { forceOrgFieldDisplay: false, errorMessage: '' };

    let initialData = __DEV__
      ? { email: 'pmuser', password: 'aaa123', orgID: this.getOrgIDinStorage(), rememberMe: false }
      : { orgID: this.getOrgIDinStorage() };
    props.initialize(initialData);
  }

  getOrgIDinStorage = () => {
    return localStorage.getItem('orgID');
  };

  _login = (formData) => {
    this.props.actions.login(formData);
  };

  navigateToResetPassword = () => {
    this.props.history.push({
      pathname: '/ResetPassword',
      state: {
        org: this.props.formValues.orgID,
        user: this.props.formValues.email,
      },
    });
  };

  getFooter() {
    return (
      <div className="footer">
        <a target="_self" className="pointer leftFooter" onClick={this.navigateToResetPassword}>
          Reset Password
        </a>
        <a target="_blank" className="pointer rightFooter " href="http://jira.plataine.com:8080/servicedesk/customer/portal/1">
          Support
        </a>
      </div>
    );
  }

  getHasError = () => {
    if (this.props.sData.get('hasError')) {
      return ' has-error';
    }
    return '';
  };

  getOrgField = () => {
    if (!this.getOrgIDinStorage() || this.state.forceOrgFieldDisplay || AppHelper.isMobileApp()) {
      return (
        <LoginInput
          label="organization"
          name="orgID"
          id="OrgIDInput"
          placeholder="Organization"
          validate={Validation.required}
          tabIndex="1"
          autoFocus={!this.setAutoFocus()}
        />
      );
    } else return null;
  };

  setAutoFocus = () => {
    let autoFocus = 'autoFocus';

    if (this.getOrgIDinStorage()) {
      return autoFocus;
    }

    return null;
  };
  secretKeyPressHandler = (e) => {
    if (e.key === '`') {
      this.setState({ forceOrgFieldDisplay: true });
    }
  };
  render() {
    return (
      <LoginLayout
        secretKey={this.secretKeyPressHandler}
        formClass={'login-page' + this.getHasError()}
        actionButtonText="Login"
        actionButtonOnclick={this.props.handleSubmit(this._login)}
        footerComponent={this.getFooter()}
        loading={this.props.sData.get('loading')}
      >
        {this.getOrgField()}

        <LoginInput
          label="user name"
          name="email"
          id="UserNameInput"
          placeholder="Username"
          validate={Validation.required}
          tabIndex="2"
          autoFocus={this.setAutoFocus()}
        />

        <LoginInput
          label="password"
          name="password"
          id="PasswordInput"
          placeholder="Password"
          type="password"
          validate={Validation.required}
          tabIndex="3"
        />

        {/* <div className="login-row checkbox-row">
          <label className="checkboxField" htmlFor="rememberMe" ><Checkbox name="rememberMe" id="rememberMe" tabIndex="4"/><span>Remember Me</span></label>
        </div>*/}

        <div className="login-error">{this.props.sData.get('errorMessage')}</div>
      </LoginLayout>
    );
  }
}

LoginPage.propTypes = {
  initialize: PropTypes.func.isRequired,
  loginPath: PropTypes.string,
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

/*LoginPage.contextTypes = {
  router: routerShape,
};*/

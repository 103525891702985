import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MatSettingsMenuItemsIds } from '../../../../enums/navigationMenuItemsIds';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import ShiftsScheduler from '../../Common/ShiftsScheduler/shiftsScheduler';
import ShiftsPanel from '../../Common/ShiftsScheduler/shiftsPanel';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import ShiftSettingsDialog from '../../Common/ShiftsScheduler/Dialogs/shiftSettingsDialog';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import { getSortedWeekdays } from '../../Common/ShiftsScheduler/shiftsSchedulerHelpers';
import ShiftsSchedulerCell from '../../Common/ShiftsScheduler/shiftsSchedulerCell';
import { ElementSelectionProvider, useElementSelectionContext } from 'infrastructure/js/contexts/elementSelectionContext';
import Button from 'infrastructure/js/components/controls/Button/button';
import { filterTypes } from '../../../../enums/shiftSchedulerEnums';

import './shiftsTemplateView.scss';

export default function ShiftsTemplateView({ actions, sShiftsSchedulerData, sShiftSettingsDialogData }) {
  const labels = useMemo(() => createLabelHelper('mat.administration.matsettings.weeklyShiftsTemplate.view.'), []);

  useEffect(() => {
    actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_SHIFTS_TEMPLATE);

    actions.init();

    return () => actions.shiftsSchedulerActions.unmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadPage = () => {
    actions.init(true);
  };

  const columnsConfig = useMemo(() => {
    return getSortedWeekdays().map((weekDay) => ({
      id: weekDay.value,
      title: weekDay.label,
      valueGetter: (rowData) => {
        const assignments = rowData?.assignments?.find((assignment) => assignment.day === weekDay.value);

        return {
          assignments,
          resourceData: rowData,
          day: weekDay.value,
        };
      },
      cellComponent: ShiftsSchedulerCell,
      headerData: {
        day: weekDay.value,
      },
    }));
  }, []);

  return (
    <div className="administration-view weekly-shifts-template-view">
      <div className="title">{labels.get('title')}</div>
      <ElementSelectionProvider>
        <ShiftsTemplateHeader actions={actions} labels={labels} assignmentsData={sShiftsSchedulerData.get('shiftsSchedulerData')} />
        <div className="shift-scheduler-wrapper">
          <ShiftsScheduler
            isTemplate={true}
            data={sShiftsSchedulerData.get('shiftsSchedulerData')}
            columnsConfig={columnsConfig}
            actions={actions.shiftsSchedulerActions}
          />
        </div>
      </ElementSelectionProvider>

      <ShiftsPanel shiftTypes={sShiftsSchedulerData.get('shiftTypes')} />

      {(sShiftsSchedulerData.get('loadingShifts') || sShiftsSchedulerData.get('loadingData')) && <Overlay.Loading />}

      <PL_DialogWrapper
        dialogComponent={ShiftSettingsDialog}
        show={sShiftSettingsDialogData.get('show')}
        actions={actions.shiftSettingsDialogActions}
        sData={sShiftSettingsDialogData}
        reloadParentComponent={reloadPage}
      />
    </div>
  );
}

ShiftsTemplateView.propTypes = {
  actions: PropTypes.object,
  sShiftsSchedulerData: PropTypes.object,
  sShiftSettingsDialogData: PropTypes.object,
};

ShiftsTemplateView.defaultProps = {
  actions: {},
  sShiftsSchedulerData: {},
  sShiftSettingsDialogData: {},
};

function ShiftsTemplateHeader({ actions, labels, assignmentsData }) {
  const { selected, setSelected } = useElementSelectionContext();

  const handleEditSelected = () => {
    if (!selected.length) {
      return;
    }

    const isTemplate = true;

    if (selected.length > 1) {
      const shiftAssignmentIds = selected.map(({ data }) => data.shiftData.templateId);
      actions.showShiftSettingsDialog({ isTemplate, shiftAssignmentIds });
      return;
    }

    const assignmentData = selected[0].data;
    actions.showShiftSettingsDialog({ isTemplate, ...assignmentData });
  };

  const handleRemoveSelected = () => {
    const filterData = {
      filterBy: filterTypes.SELECTED,
    };

    actions.shiftsSchedulerActions.removeShifts({ filterData, assignments: selected.map((item) => item.data) })
  };

  const handleRemoveAll = () => {
    const filterData = {
      filterBy: filterTypes.ALL,
    };

    actions.shiftsSchedulerActions.removeShifts({ filterData, assignments: assignmentsData });
  };

  return (
    <div className="header">
      <div className="flex-grow-2">
        <Button className={'border-btn right-side shift-scheduler-action'} onClick={handleRemoveAll}>
          {labels.get('header.removeAll')}
        </Button>
        <Button
          id="removeSelected"
          name="removeSelected"
          className={'border-btn right-side shift-scheduler-action'}
          onClick={handleRemoveSelected}
          disabled={!selected.length}
        >
          {labels.get('header.removeSelected')} {selected.length > 0 && `(${selected.length})`}
        </Button>
      </div>
      <Button
        id="editAssignments"
        name="editAssignments"
        className={'border-btn right-side shift-scheduler-action'}
        onClick={handleEditSelected}
        disabled={!selected.length}
      >
        {labels.get('header.edit')}
      </Button>
    </div>
  );
}

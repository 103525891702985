
export const navigationStates = {
  LOCATION: 'NS_LOCATION',
  STATION: 'NS_STATION',

  ASSET_PAGE: 'NS_ASSET_PAGE', // Abstract Page name both for NS_ROLL or NS_KIT ...etc. navigation states.
  ROLL: 'NS_ROLL',
  SPOOL: 'NS_SPOOL',
  ROLLS: 'NS_ROLLS',
  SPOOLS: 'NS_SPOOLS',
  KIT: 'NS_KIT',
  PART: 'NS_PART',
  PARTS: 'NS_PARTS',
  TOOL: 'NS_TOOL',
  GROUP: 'NS_GROUP',
  GROUPS: 'NS_GROUPS',
  WORKORDER: 'NS_WORKORDER',
  WORKORDERS: 'NS_WORKORDERS',
  ACTIVE_SPOOLS: 'NS_ACTIVE_SPOOLS',
  ALERTED_SPOOLS: 'NS_ALERTED_SPOOLS',
  ARCHIVED_SPOOLS: 'NS_ARCHIVED_SPOOLS',
  ARCHIVED_ROLLS: 'NS_ARCHIVED_ROLLS',
  ARCHIVED_KITS: 'NS_ARCHIVED_KITS',
  ARCHIVED_GROUPS: 'NS_ARCHIVED_GROUPS',
  ALERTED_ROLLS: 'NS_ALERTED_ROLLS',
  ACTIVE_ROLLS: 'NS_ACTIVE_ROLLS',
  ACTIVE_KITS: 'NS_ACTIVE_KITS',
  ACTIVE_GROUPS: 'NS_ACTIVE_GROUPS',
  ALERTED_KITS: 'NS_ALERTED_KITS',
  ALERTED_GROUPS: 'NS_ALERTED_GROUPS',
  ACTIVE_PARTS: 'NS_ACTIVE_PARTS',
  ALERTED_PARTS: 'NS_ALERTED_PARTS',
  ARCHIVED_PARTS: 'NS_ARCHIVED_PARTS',
  OPEN_WORKORDERS: 'NS_OPEN_WORKORDERS',
  COMPLETED_WORKORDERS: 'NS_COMPLETED_WORKORDERS',
  ACTIVE_TOOLS: 'NS_ACTIVE_TOOLS',
  ARCHIVED_TOOLS: 'NS_ARCHIVED_TOOLS',
  TOOLS: 'NS_TOOLS',
  MATERIAL_SCRAP_VIEW: 'MATERIAL_SCRAP_VIEW',
  MATERIAL_SCRAP: 'NS_MATERIAL_SCRAP',
  TWOD_MAPPING: 'NS_TWOD_MAPPING',
  PRODUCTION_DASHBOARD: 'NS_PRODUCTION_DASHBOARD',
  PROGRESS_DASHBOARD: 'NS_PROGRESS_DASHBOARD',
  INVENTORY_DASHBOARD: 'NS_INVENTORY_DASHBOARD',
  TOOL_MAINTENANCE_DASHBOARD: 'NS_TOOL_MAINTENANCE_DASHBOARD',
  NOTIFICATIONS: 'NS_NOTIFICATIONS',
  LOADING: 'NS_LOADING',
  MAT_SETTINGS: 'NS_MAT_SETTINGS',
  USER_MANAGEMENT: 'NS_USER_MANAGEMENT',
  MASS_OPERATIONS: 'NS_MASS_OPERATIONS',
  ORG_MANAGEMENT: 'NS_ORG_MANAGEMENT',
  DEMO_ORG_MANAGEMENT: 'NS_DEMO_ORG_MANAGEMENT',
  PAGE_NOT_FOUND: 'NS_PAGE_NOT_FOUND',
  RFID_SETTINGS: 'NS_RFID_SETTINGS',
  BILLING: 'NS_BILLING',
  SETTINGS: 'NS_SETTINGS',
  PS_MANAGEMENT: 'NS_PS_MANAGEMENT',
  SMART_SELECTION: 'NS_SMART_SELECTION',
  ON_HAND_INVENTORY: 'NS_ON_HAND_INVENTORY',
  PICK_LIST: 'NS_PICK_LIST',
  PICK_LISTS: 'NS_PICK_LISTS',
  SCHEDULER: 'NS_SCHEDULER',
  LOCATION_TASKS: 'NS_LOCATION_TASKS',
  SETTINGS_CSV_WORKORDERS: 'SETTINGS_CSV_WORKORDERS',
  RAW_MATERIALS: 'NS_RAW_MATERIALS',
  KITS: 'NS_KITS',
  SCRAP: 'NS_SCRAP',
  MANAGER_REPORT: 'NS_MANAGER_REPORT',
  MASTER_DATA: 'NS_MASTER_DATA',
  WORK_ORDERS: 'NS_WORK_ORDERS',
};


import React from 'react';



require('./relatedAssetsTab.scss');


export default class RelatedAssetsTab extends React.PureComponent {

  render() {


    return (
      <div className="related-assets-view">
        RELATED ASSETS
      </div>
    );

  }
}




import {api as gridApi} from './mqttSensorsSettingsViewGridActions';
import Network from 'infrastructure/js/modules/network';
import * as mqttBrokersService from '../../../services/Rfid/mqttBrokersService';
import * as mqttSensorsService from '../../../services/Rfid/mqttSensorsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_RF_SETTINGS_SENSORS_FETCH_BROKERS_IN_PROCESS: 'ADMINISTRATION_RF_SETTINGS_SENSORS_FETCH_BROKERS_IN_PROCESS',
  ADMINISTRATION_RF_SETTINGS_SENSORS_FETCH_BROKERS_READY: 'ADMINISTRATION_RF_SETTINGS_SENSORS_FETCH_BROKERS_READY',
  ADMINISTRATION_RF_SETTINGS_SENSORS_BROKER_CHANGED: 'ADMINISTRATION_RF_SETTINGS_SENSORS_BROKER_CHANGED',
  ADMINISTRATION_RF_SETTINGS_SENSORS_UNMOUNT: 'ADMINISTRATION_RF_SETTINGS_SENSORS_UNMOUNT'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  fetchBrokersInProgress: function () {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_SENSORS_FETCH_BROKERS_IN_PROCESS};
  },
  fetchAllBrokersFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_SENSORS_FETCH_BROKERS_READY, payload};
  },
  brokerChanged: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_SENSORS_BROKER_CHANGED, payload};
  },
  unmount: function(items) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_SENSORS_UNMOUNT};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid) {
  return function (dispatch, getState) {
    api.load(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.reload = function (shouldRefreshGrid) {
  return function (dispatch, getState) {
    gridApi.reload(dispatch, getState)();
  }
};

jsxActions.onBrokerChanged = function (data) {
  return function (dispatch, getState) {
    api.brokerChanged(dispatch, getState)(data);
  }
};

jsxActions.onRemoveSensorsClick = function(messageDialogBuilder , selectedSensorsIds) {
  return function(dispatch, getState) {
    api.openRemoveSensorsConfirmationDialog(dispatch, getState)(messageDialogBuilder , selectedSensorsIds);
  }
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};



/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function (dispatch, getState) {
  return function (shouldRefreshGrid) {

    dispatch(actions.fetchBrokersInProgress());

    return mqttBrokersService.fetchAllMqttBrokers().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to fetch all mqtt brokers', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let allBrokers = response.dataList.map((obj) => {
        return {value: obj.id, label: obj.name, data: obj}
      });

      if (shouldRefreshGrid) {
        gridApi.reload(dispatch, getState)();
      }

      dispatch(actions.fetchAllBrokersFinished(allBrokers));
    });
  }
};

api.brokerChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.brokerChanged(data));
    gridApi.reload(dispatch, getState)();
  }
};

api.openRemoveSensorsConfirmationDialog = function(dispatch, getState) {
  return function(messageDialogBuilder , selectedSensorsIds) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.removeSensors(dispatch, getState)(selectedSensorsIds)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

  }
};

api.removeSensors = function(dispatch, getState) {
  return function(selectedSensorsIds) {

    messageDialogApi.close(dispatch, getState)();

    mqttSensorsService.deleteMqttSensor(selectedSensorsIds).then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('Failed to remove Mqtt sensors', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success : false};
      }

      gridApi.reload(dispatch, getState)();
    });
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

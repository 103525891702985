import { connect } from 'react-redux';
import React, {Component} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AppTopbar from './containers/AppTopbar/appTopbarContainer';
import ErrorBoundary from './components/ErrorBoundary/errorBoundary';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
//Routes Components
import LocationPageContainer  from './containers/LocationPage/locationPageContainer';
import TwoDMappingPageContainer     from './containers/Reports/TwoDMappingPage/twoDMappingPageContainer.js';
import ProductionDashboardPageContainer     from './containers/Reports/DashboardPage/productionDashboardPageContainer.js';
import ProgressDashboardPageContainer     from './containers/Reports/DashboardPage/progressDashboardPageContainer.js';
import InventoryDashboardPageContainer     from './containers/Reports/DashboardPage/inventoryDashboardPageContainer.js';
import ToolMaintenanceDashboardPageContainer from './containers/Reports/DashboardPage/toolMaintenanceDashboardPageContainer.js';
import NotificationsPageContainer   from './containers/NotificationsPage/notificationsPageContainer.js';
import AssetPageContainer  from './containers/AssetPage/assetPageContainer';
import WorkOrderPageContainer from './containers/WorkOrderPage/workOrderPageContainer';
import UserManagementContainer from './containers/Administration/UserManagementPage/userManagementContainer';
import MatSettingsContainer  from './containers/Administration/MatSettingsPage/matSettingsPageContainer';
import RfidSettingsContainer  from './containers/Administration/RfidSettingsPage/rfidSettingsPageContainer';
import MassOperationsPageContainer from './containers/Administration/MassOperationsPage/massOperationsPageContainer';
import OrganizationsPageContainer  from './containers/Administration/OrganizationsPage/organizationsPageContainer.js';
import DemoOrganizationsPageContainer  from './containers/Administration/OrganizationsPage/demoOrganizationsPageContainer';
import ImportFilesWizardContainer     from './containers/Wizards/ImportFiles/ImportFilesWizardContainer';
import PageNotFoundPageContainer from './containers/PageNotFoundPage/pageNotFoundPageContainer.js';
import BillingContainer  from './containers/Administration/BillingPage/billingPageContainer';
import SettingsPageContainer  from './containers/Administration/SettingsPage/settingsPageContainer';
import SmartSelectionContainer  from './containers/SmartSelectionPage/smartSelectionContainer';
import onHandInventoryContainer  from './containers/OnHandInventory/onHandInventoryContainer.js';
import PickListPageContainer  from './containers/PickListPage/pickListPageContainer';
import SchedulerPageContainer  from './containers/SchedulerPage/schedulerPageContainer';
import PsManagementContainer  from './containers/Administration/PsManagementPage/psManagementPageContainer';
import MaterialScrapContainer from './containers/PredefinedList/materialScrapContainer.js';
import PickListsContainer from './containers/PickListsPage/pickListsContainer';
import SideNavigator from './containers/SideNavigator/sideNavigatorContainer';

import WorkOrdersPage from './components/PredefinedList/WorkOrdersPage/workOrdersPage';
import PartsPage from './components/PredefinedList/PartsPage/partsPage';
import GroupsPage from './components/PredefinedList/GroupsPage/groupsPage';
import ToolsPage from './components/PredefinedList/ToolsPage/toolsPage';
import KitsPage from './components/PredefinedList/KitsPage/kitsPage';
import RawMaterialsPage from './components/PredefinedList/RawMaterialsPage/rawMaterialsPage';

require('styles/app.scss');

class App extends Component {
  getRoutes () {
    let routesArr;
    //todo: Need to Rewrite featureFlags logic.
    let {featureFlags} = this.props;
    if (featureFlags.ALL) {
      routesArr = [
        <Route key="managerReportRouteKey" exact path='/' component={TwoDMappingPageContainer}/>,
        <Route key="managerReportRouteKey2" path='/ManagerReport' component={TwoDMappingPageContainer}/>,

        <Route key="productionDashboardRouteKey" path='/ProductionDashboard' component={ProductionDashboardPageContainer} />,
        <Route key="progressDashboardRouteKey" path='/ProgressDashboard' component={ProgressDashboardPageContainer} />,
        <Route key="inventoryDashboardRouteKey" path='/InventoryDashboard' component={InventoryDashboardPageContainer} />,
        <Route key="toolMaintenanceDashboardRouteKey" path='/ToolMaintenanceDashboard' component={ToolMaintenanceDashboardPageContainer} />,
        <Route key="notificationsPageRouteKey" path='/Notifications' component={NotificationsPageContainer} />,
        <Route key="locationPageRouteKey" path='/Location/:locationId/:tabId?/:subTabId?' component={LocationPageContainer} />,

        
        <Route key="assetRouteKey" path='/Asset/:assetType/:assetId/:tabId?' component={AssetPageContainer}/>,
        <Route key="workorderPageRouteKey" path='/WorkOrder/:workorderId/:tabId?' component={WorkOrderPageContainer}/>,

        <Route key="onHandInventoryRouteKey" path='/PredefinedViews/onHandInventory' component={onHandInventoryContainer}/>,
        <Route key="PageNotFoundPageRouteKey" path='/PageNotFound' component={PageNotFoundPageContainer}/>,
        //PredefinedViews 
        <Route key="PartsRouteKey" path="/PredefinedViews/parts" component={PartsPage} />,
        <Route key="WorkOrdersRouteKey" path="/PredefinedViews/workOrders" component={WorkOrdersPage} />,
        <Route key="KitsRouteKey" path="/PredefinedViews/kits" component={KitsPage} />,
        <Route key="ToolsRouteKey" path="/PredefinedViews/tools/:tabName?/:tabId?" component={ToolsPage} />,
        <Route key="archivedSpoolsRouteKey" path="/PredefinedViews/rawMaterials/:materialName?/:materialStatus?" component={RawMaterialsPage} />,
      ];

      if (PermissionManager.getOrgPreferences().smartSelectionEnabled) {
        if (PermissionManager.hasPredefinedViewSmartSelectionPermissions()) {
          routesArr.push(<Route key="smartSelectionPageRouteKey" path='/PredefinedViews/smartSelection'
                                component={SmartSelectionContainer}/>);
        }

        if (PermissionManager.hasPredefinedViewPickListPermissions()) {
          routesArr.push(<Route key="pickListsPageRouteKey" path='/PredefinedViews/pickLists'
                                component={PickListsContainer}/>);

        }
        if (PermissionManager.hasPredefinedViewPickListPermissions() || PermissionManager.hasLocationPickListTabPermissions()) {
          routesArr.push(<Route key="pickListPageRouteKey" path='/PickList/:pickListId?' component={PickListPageContainer}/>);
        }
      }

      if (PermissionManager.getOrgPreferences().schedulerEnabled) {
        routesArr.push( <Route key="schedulerRouteKey" path='/PredefinedViews/scheduler' component={SchedulerPageContainer}/>);
      }

      if (PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled) {
        routesArr.push(<Route key="archivedGroupsRouteKey" path="/PredefinedViews/groups" component={GroupsPage} />);
      }

      if (PermissionManager.hasScrapViewPermissions()) {
        routesArr.push(<Route key="materialScrapRouteKey" path='/PredefinedViews/materialScrap' component={MaterialScrapContainer}/>);
      }

      if (PermissionManager.hasMatSettingsTabPermissions()) {
        routesArr.push(<Route key="matSettingsRouteKey" path='/Administration/matSettings' component={MatSettingsContainer}/>);
      }
      if (PermissionManager.hasUserManagementTabPermissions()) {
        routesArr.push(<Route key="userManagementPageRouteKey" path='/Administration/userManagementPage' component={UserManagementContainer}/>);
      }
      if (PermissionManager.hasRfidTabPermissions()) {
        routesArr.push(<Route key="rfidSettingsRouteKey" path='/Administration/rfidSettings' component={RfidSettingsContainer}/>);
      }
      if (PermissionManager.hasBillingTabPermissions()) {
        routesArr.push(<Route key="billingRouteKey" path='/Administration/billing' component={BillingContainer}/>);
      }
      if (PermissionManager.hasAdministrationTabPermissions()) {
        routesArr.push(<Route key="settingRouteKey" path='/Administration/settings' component={SettingsPageContainer}/>);
      }
      if (PermissionManager.hasPSManagementTabPermissions()) {
        routesArr.push(<Route key="psManagementKey" path='/Administration/psManagement' component={PsManagementContainer}/>);
      }


    }
    //todo: Need to Rewrite featureFlags logic.
    if (featureFlags.MASS_OPERATION) {
      routesArr = [<Route key="massOperationsRouteKey" path='/Administration/massOperations' component={MassOperationsPageContainer}/>,
        <Route key="massOperationsRouteKey2" path='/' component={MassOperationsPageContainer}/>];
    }

    if (featureFlags.ORGANIZATIONS) {
      routesArr = [
        <Route key="organizationsPageRouteKey" path='/Administration/organizationsPage' component={OrganizationsPageContainer}/>,
        <Route key="demoOrganizationsPageRouteKey" path='/Administration/demoOrganizations' component={DemoOrganizationsPageContainer}/>,
      ];
      if (PermissionManager.hasOrgPlataineUserManagementTabPermissions()) {
        routesArr.push( <Route key="userManagementPageRouteKey" path='/Administration/userManagementPage' component={UserManagementContainer}/>);
      }

      routesArr.push(<Route key="organizationsPageRouteKey2" path="/" render={() => (<Redirect to="/Administration/organizationsPage" />)} />);
    }

    routesArr.push(<Route key="PageNotFoundPageRouteKey2" component={PageNotFoundPageContainer}/>);

    return routesArr;
  }
  render() {
    return (
      <ErrorBoundary>
        <div className="app-container">
          <header>
            <AppTopbar />
          </header>
          <ImportFilesWizardContainer />
          <div className="app-container-main">
            <aside>
              <SideNavigator />
            </aside>
            <main className="app-container-content">
              <Switch>{this.getRoutes()}</Switch>
            </main>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}


export default App = connect(
  (state) => {
    return {
      featureFlags : state.system.get('featureFlags'),
      sLoggedInUser : state.login.get("loggedInUser"),
    };
  },
  undefined

)(App);







import Network from 'infrastructure/js/modules/network';
import * as workOrderService from '../../services/WorkOrders/workOrdersService';
import * as locationService from '../../services/Locations/locationsService';
import { getMSSInitialLocations } from "../../services/Rolls/rollsService";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import {api as smartSelectionGridActions} from "./smartSelectionGridActions";
import MaterialHelper from '../../utils/materialHelper';
import {getWorkOrdersForSmartSelection} from '../../services/WorkOrders/workOrdersService';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_DATA_STARTED: "PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_DATA_STARTED",
  PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_DATA_FINISHED: "PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_DATA_FINISHED",
  PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_WO_STARTED: "PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_WO_STARTED",
  PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_WO_FINISHED: "PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_WO_FINISHED",
  PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_MATERIALS_STARTED: "PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_MATERIALS_STARTED",
  PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_MATERIALS_FINISHED: "PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_MATERIALS_FINISHED",
  PREDEFINED_LIST_SMART_SELECTION_HEADER_UPDATE_HEADER_DATA: "PREDEFINED_LIST_SMART_SELECTION_HEADER_UPDATE_HEADER_DATA",
  PREDEFINED_LIST_SMART_SELECTION_HEADER_HIDE_GRID: "PREDEFINED_LIST_SMART_SELECTION_HEADER_HIDE_GRID",
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function () {
    return {type: actionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_DATA_STARTED};
  },
  fetchDataFinished: function (payload) {
    return {type: actionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_DATA_FINISHED, payload};
  },
  fetchWorkOrdersStarted: function () {
    return {type: actionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_WO_STARTED};
  },
  fetchWorkOrdersFinished: function (payload) {
    return {type: actionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_WO_FINISHED, payload};
  },
  fetchMaterialByWorkOrdersStarted: function () {
    return {type: actionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_MATERIALS_STARTED};
  },
  fetchMaterialByWorkOrdersFinished: function (payload) {
    return {type: actionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_MATERIALS_FINISHED, payload};
  },
  updateHeaderData: function (payload) {
    return {type: actionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_UPDATE_HEADER_DATA, payload};
  },
  hideGrid: function () {
    return {type: actionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_HIDE_GRID};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid) {
  return function (dispatch, getState) {
    return api.init(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.fetchWos = function (startDate, endDate) {
  return function (dispatch, getState) {
    return api.fetchWos(dispatch, getState)(startDate, endDate);
  }
};
jsxActions.fetchWosMaterials = function (ids) {
  return function (dispatch, getState) {
    return api.fetchWosMaterials(dispatch, getState)(ids)
  }
};
jsxActions.fetchGridData = function (data) {
  return function (dispatch, getState) {
    smartSelectionGridActions.changeFilter(dispatch, getState)({ filterName: 'rollMaterial', values: [] });
    return dispatch(actions.updateHeaderData(data));
  }
};
jsxActions.hideGrid = function () {
  return function (dispatch, getState) {
    return dispatch(actions.hideGrid());
  }
};
/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};
api.fetchWosMaterials = function (dispatch, getState) {
  return function (woIds) {
    dispatch(actions.fetchMaterialByWorkOrdersStarted());

    workOrderService.fetchWorkOrdersMaterials(woIds)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('smart selection: Fetch materials Failed');
          dispatch(actions.fetchMaterialByWorkOrdersFinished({data: []}));
          return {success: false};
        }
        let materials = response.data.materialsData.map((material) => {
          return {
            value: material.displayMaterial.id,
            label: MaterialHelper.getMaterialFullLabel(material.displayMaterial.materialName, material.displayMaterial.businessId),
            data: material
          }
        });

        dispatch(actions.fetchMaterialByWorkOrdersFinished({materials: materials}));
      });
  };
};

api.fetchWos = function (dispatch, getState) {
  return function (startDate, endDate) {
    dispatch(actions.fetchWorkOrdersStarted());
    // TODO - change to normal query
    let dateValues = [];
    if (startDate || endDate) {
      dateValues.push(startDate ? DateTimeHelper.ConvertFromDate(startDate).epochDateTime : 'none');
      dateValues.push(endDate ? DateTimeHelper.ConvertFromDate(endDate).epochDateTime : 'none');
    }
    let query =
      {
        filters: [
          {
            filterName: 'woPickDate',
            values: dateValues,
            shouldCreateFilterOptions: false
          },
        ],
        isPostProcessingSort: false,
        sortByProperty: 'woBusinessId',
        sortDirection: 'ASC',
        pageSize: 10000
      };
    workOrderService.getWorkOrdersForSmartSelection(query)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('smart selection: Fetch WorkOrder Failed');
          dispatch(actions.fetchWorkOrdersFinished({data: []}));
          return {success: false};
        }
        let wos = response.dataList.map((wo) => {
          return {value: wo.id, label: wo.businessId, data: wo}
        });
        dispatch(actions.fetchWorkOrdersFinished({wos: wos}));
      });
  };
};

api.init = function (dispatch, getState) {
  return function (shouldRefreshGrid) {
    dispatch(actions.fetchDataStarted());

    let datesPromise = workOrderService.fetchWorkOrderPickDates();
    let materialsPromise = workOrderService.fetchWorkOrdersMaterials([]);
    let locationQuery =
      {
        filters: [
          {filterName: 'displayName', values: [], shouldCreateFilterOptions: true},
          {filterName: 'locationType', values: [], shouldCreateFilterOptions: true},
          {filterName: 'isUsedForSmartSelection', values: [true], shouldCreateFilterOptions: true}
        ],
        sortByProperty: 'displayName',
        sortDirection: 'ASC',
        pageSize: 1000
      };
    let locationsPromise = locationService.fetchLocations(locationQuery);
    let initialLocations = getMSSInitialLocations(); //rollsService call
    return Promise.all([datesPromise, materialsPromise, locationsPromise, initialLocations]).then((allResults) => {

      let pickDates = allResults[0].data ? allResults[0].data.pickupDates : [];
      let materials = allResults[1].data;
      let locations = allResults[2].dataList;
      let initialLocations = [];
      allResults[3].dataList.forEach((initialLocationID) => {
        let location = locations.find(loc => loc.id === initialLocationID);
        if (location?.active) //filter inactive locations
          initialLocations.push({ value: initialLocationID, label: location.displayName });
      });

      let data = {
        pickDateOptions: pickDates.map((date) => {
          return DateTimeHelper.ConvertToDate(date)
        }),
        materialOptions: materials ? materials.materialsData.map((material) => {
          return {
            value: material.displayMaterial.id,
            label: MaterialHelper.getMaterialFullLabel(material.displayMaterial.materialName, material.displayMaterial.businessId),
            data: material
          }
        }) : [],
        locationOptions: locations.map((location) => {
          return {value: location.id, label: location.displayName}
        }),
        initialLocations
      };
      dispatch(actions.fetchDataFinished(data));
      return data;
    });
  }
}

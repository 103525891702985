import React from 'react';
import PropTypes from 'prop-types';
import CommonCsvSettingsForm from '../../../CsvSettings/Components/CommonCsvSettingsForm/commonCsvSettingsForm';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import { Prompt } from 'react-router-dom';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header from '../../../../../Common/Header/header';
import {enumTypes, getEnumValue} from '../../../../../../utils/enumHelper';

import './commonBarcodeSettingsView.scss';

export default class CommonBarcodeSettingsView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.barcodeSettings.view.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  renderLoadingOverlay = () => {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

  renderPrompt = () => {
    return (
      <Prompt
        when={this.props.isDirty}
        message={(location) =>
          this.navigationLabels.get('datanotsaved.text')
        }
      />
    );
  };

  renderContents = () => {
    let {sData, entityType, actions} = this.props;
    if ( sData && sData.get('formData')) {
      return (
        <CommonCsvSettingsForm
          formType="BARCODE"
          form={'barcodeSettingsForm'}
          formData={sData.get('formData')}
          entityType={entityType}
          labels={this.labels}
          actions={actions}
        />
      );
    }
    return null;
  }

  getHeaderItems = () => {
    return {
      title: this.getHeaderTitle(),
    };
  };

  getHeaderTitle = () => {
    let {entityType} = this.props;
    switch (entityType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        return this.labels.get('title.roll');
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        return this.labels.get('title.spool');
    }
    console.error('getHeaderTitle(): unknown entity type ' + entityType);
    return '';
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className="barcode-settings-view">
        <Header {...headerItems}/>
        {this.renderContents()}
        {this.renderLoadingOverlay()}
        {this.renderPrompt()}
      </div>
    );
  }
}

CommonBarcodeSettingsView.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  entityType : PropTypes.string.isRequired,
}

import { Map } from 'immutable';
import { actionTypes as terminologyViewActions } from '../../../actions/Administration/SettingsPage/terminologyViewActions';

let defaultState = {
  loading : false,
  hasUnpublishedData: false,
  macrosOptions: [],
  localesOptions: null
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case terminologyViewActions.TERMINOLOGY_SETTINGS_VIEW_UPDATE_IN_PROCESS:
      return state.set('loading', true);
    case terminologyViewActions.TERMINOLOGY_SETTINGS_VIEW_UPDATE_FINISHED:
      return state.set('loading', false);

    case terminologyViewActions.TERMINOLOGY_SETTINGS_VIEW_FETCH_LOCALES_FINISHED:
      return state.set('localesOptions', action.payload);

    case terminologyViewActions.TERMINOLOGY_SETTINGS_VIEW_FETCH_MACROS_FINISHED:
      return state.set('macrosOptions', action.payload);

    case terminologyViewActions.TERMINOLOGY_SETTINGS_VIEW_UPDATE_FILTER_DATA:
      state = state.set('localeId', action.payload.localeId);
      state = state.set('allMacrosMode', action.payload.allMacrosMode);
      state = state.set('currentMacro', action.payload.currentMacro);
      return state;

    case terminologyViewActions.TERMINOLOGY_SETTINGS_VIEW_UPDATE_UNPUBLISHED_DATA_STATUS:
      return state.set('hasUnpublishedData', action.payload);


    case terminologyViewActions.TERMINOLOGY_SETTINGS_VIEW_RESET_DATA:
      return Map(defaultState);

    default:
      return state;
  }
}













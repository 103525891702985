import { api as locationPageApi }    from '../locationPageActions';
import {api as gridApi} from '../ShipmentsView/shipmentsGridActions';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.reload = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    api.init(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.getLocationDetails = function() {
  return function(dispatch, getState) {
    return locationPageApi.getLocationDetails(dispatch, getState)();
  };
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }
  }
};

import Network from 'infrastructure/js/modules/network';
import assetPrintTagsService from '../../services/Assets/assetPrintTagsService';
import * as lotsService from '../../services/Lots/lotsService';
import * as materialsService from '../../services/Materials/materialsService';
import * as manufacturersService from '../../services/Manufacturers/manufacturersService';
import * as tagsService from '../../services/Tags/tagsService';
import * as projectsService from '../../services/Projects/projectsService';
import * as toolsService from '../../services/Tools/toolsService';
import * as rollsService from '../../services/Rolls/rollsService';
import * as spoolsService from '../../services/Spools/spoolsService';
import * as kitsService from '../../services/Kits/kitsService';
import * as partsService from '../../services/Parts/partsService';
import * as groupsService from '../../services/Groups/groupsService';
import * as workOrdersService from '../../services/WorkOrders/workOrdersService';
import * as locationsService from '../../services/Locations/locationsService';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import { api as assetPageApi } from './assetPageActions.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as additionalFieldsService from '../../services/Administration/additionalFieldsService';
import { getEnumValue, enumTypes } from '../../utils/enumHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ASSETPAGE_ASSETEDITOR_FETCH_MATERIALS_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_MATERIALS_READY',
  ASSETPAGE_ASSETEDITOR_FETCH_LOTS_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_LOTS_READY',
  ASSETPAGE_ASSETEDITOR_FETCH_MANUFACTURERS_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_MANUFACTURERS_READY',
  ASSETPAGE_ASSETEDITOR_FETCH_PROJECTS_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_PROJECTS_READY',
  ASSETPAGE_ASSETEDITOR_FETCH_INSPECTION_STATUSES_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_INSPECTION_STATUSES_READY',

  ASSETPAGE_ASSETEDITOR_FETCH_TAGS_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_TAGS_READY',
  ASSETPAGE_ASSETEDITOR_FETCH_LOCATIONS_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_LOCATIONS_READY',
  ASSETPAGE_ASSETEDITOR_FETCH_TOOL_TYPES_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_TOOL_TYPES_READY',
  ASSETPAGE_ASSETEDITOR_FETCH_WORKORDERS_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_WORKORDERS_READY',
  ASSETPAGE_ASSETEDITOR_FETCH_ADDITIONAL_FIELDS_READY: 'ASSETPAGE_ASSETEDITOR_FETCH_ADDITIONAL_FIELDS_READY',

  ASSETPAGE_ASSETEDITOR_PRINT_TAGS_FAILED: 'ASSETPAGE_ASSETEDITOR_PRINT_TAGS_FAILED',
  ASSETPAGE_ASSETEDITOR_PRINT_TAGS_IN_PROGRESS: 'ASSETPAGE_ASSETEDITOR_PRINT_TAGS_IN_PROGRESS',
  ASSETPAGE_ASSETEDITOR_PRINT_TAGS_FINISHED: 'ASSETPAGE_ASSETEDITOR_PRINT_TAGS_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchMaterialsReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_MATERIALS_READY, payload: payload };
  },
  fetchLotsReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_LOTS_READY, payload: payload };
  },
  fetchManufacturersReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_MANUFACTURERS_READY, payload: payload };
  },
  fetchProjectsReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_PROJECTS_READY, payload: payload };
  },
  fetchInspectionStatusesReady: function (payload) {
    return {
      type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_INSPECTION_STATUSES_READY,
      payload: payload,
    };
  },

  fetchTagsReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_TAGS_READY, payload };
  },
  fetchLocationsReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_LOCATIONS_READY, payload };
  },
  fetchToolTypesReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_TOOL_TYPES_READY, payload };
  },
  fetchWorkOrdersReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_WORKORDERS_READY, payload };
  },
  fetchAdditionalFieldsReady: function (payload) {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_FETCH_ADDITIONAL_FIELDS_READY, payload };
  },

  printTagsFailed: function () {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_PRINT_TAGS_FAILED };
  },
  printTagsInProgress: function () {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_PRINT_TAGS_IN_PROGRESS };
  },
  printTagsFinished: function () {
    return { type: actionTypes.ASSETPAGE_ASSETEDITOR_PRINT_TAGS_FINISHED };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchAllDataForEditor = function (assetType) {
  return function (dispatch, getState) {
    api.fetchAllDataForEditor(dispatch, getState)(assetType);
  };
};

jsxActions.updateRoll = function (rollId, rollUpdateData) {
  return function (dispatch, getState) {
    // dispatch(actions.rollUpdateInProgress()); // NOT IN USE.
    rollsService.updateRoll(rollId, rollUpdateData).then((response) => {
      if (!Network.isResponseValid(response)) {
        //for generic display
        response.applicationResponseStatus = null;
        messageDialogApi.responseError(dispatch, getState)(response);
        console.error('Update Roll Failed');
      }
      // let updatedRoll = response.data; // NOT IN USE.
      // dispatch(actions.rollUpdateFinished(updatedRoll));  // NOT IN USE.

      // Reload all editor support data.
      api.fetchAllDataForEditor(dispatch, getState)(getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'));

      // Command Asset page to reload everything.
      assetPageApi.reload(dispatch, getState)(
        getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'),
        rollId,
        true
      );
    });
  };
};

jsxActions.updateSpool = function (spoolId, spoolUpdateData) {
  return function (dispatch, getState) {
    // dispatch(actions.rollUpdateInProgress()); // NOT IN USE.
    spoolsService.updateSpool(spoolId, spoolUpdateData).then((response) => {
      if (!Network.isResponseValid(response)) {
        //for generic display
        response.applicationResponseStatus = null;
        messageDialogApi.responseError(dispatch, getState)(response);
        console.error('Update Spool Failed');
      }
      // let updatedRoll = response.data; // NOT IN USE.
      // dispatch(actions.rollUpdateFinished(updatedRoll));  // NOT IN USE.

      // Reload all editor support data.
      api.fetchAllDataForEditor(dispatch, getState)(getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'));

      // Command Asset page to reload everything.
      assetPageApi.reload(dispatch, getState)(
        getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'),
        spoolId,
        true
      );
    });
  };
};

jsxActions.updateKit = function (kitId, kitUpdateData) {
  return function (dispatch, getState) {
    // dispatch(actions.kitUpdateInProgress()); // NOT IN USE.
    kitsService.updateKit(kitId, kitUpdateData).then((response) => {
      // let updatedKit = response.data; // NOT IN USE.
      // dispatch(actions.kitUpdateFinished(updatedKit));  // NOT IN USE.

      // Reload all editor support data.
      api.fetchAllDataForEditor(dispatch, getState)(getEnumValue(enumTypes.OBJECT_TYPE)('KIT'));

      // Command Asset page to reload everything.
      assetPageApi.reload(dispatch, getState)(
        getEnumValue(enumTypes.OBJECT_TYPE)('KIT'),
        kitId,
        true
      );
    });
  };
};

jsxActions.updatePart = function (assetId, updateData) {
  return function (dispatch, getState) {
    // dispatch(actions.kitUpdateInProgress()); // NOT IN USE.
    partsService.updatePart(assetId, updateData).then((response) => {
      // Reload all editor support data.
      api.fetchAllDataForEditor(dispatch, getState)(getEnumValue(enumTypes.OBJECT_TYPE)('PART'));

      // Command Asset page to reload everything.
      assetPageApi.reload(dispatch, getState)(
        getEnumValue(enumTypes.OBJECT_TYPE)('PART'),
        assetId,
        true
      );
    });
  };
};

jsxActions.updateGroup = function (containerId, containerUpdateData) {
  return function (dispatch, getState) {
    // dispatch(actions.kitUpdateInProgress()); // NOT IN USE.
    groupsService.updateGroup(containerId, containerUpdateData).then((response) => {
      // let updatedKit = response.data; // NOT IN USE.
      // dispatch(actions.kitUpdateFinished(updatedKit));  // NOT IN USE.

      // Reload all editor support data.
      api.fetchAllDataForEditor(dispatch, getState)(getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'));

      // Command Asset page to reload everything.
      assetPageApi.reload(dispatch, getState)(
        getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'),
        containerId,
        true
      );
    });
  };
};

jsxActions.updateTool = function (toolId, toolUpdateData) {
  return function (dispatch, getState) {
    toolsService.updateTool(toolId, toolUpdateData).then((response) => {

      // Reload all editor support data.
      api.fetchAllDataForEditor(dispatch, getState)(getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'));

      // Command Asset page to reload everything.
      assetPageApi.reload(dispatch, getState)(
        getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'),
        toolId,
        true
      );
    });
  };
};

jsxActions.getAdditionalFieldDefinitions = function getAdditionalFieldDefinitions(assetType) {
  return function (dispatch, getState) {
    return additionalFieldsService
      .fetchAdditionalFields({ entityType: getEnumValue(enumTypes.OBJECT_TYPE)(assetType) })
      .then((result) => {
        if (!Network.isResponseValid(result)) {
          messageDialogApi.responseError(dispatch, getState)(null);
          console.error('Get additional fields failed');
          return { success: false };
        }

        let fields = result.dataList,
          name = '';
        switch (assetType) {
          case 'ROLL':
            name = 'rollAdditionalFields';
            break;
          case 'SPOOL':
            name = 'spoolAdditionalFields';
            break;
          case 'KIT':
            name = 'kitAdditionalFields';
            break;
          case 'TOOL':
            name = 'toolAdditionalFields';
            break;
          case 'KIT_TYPE':
            name = 'kitTypeAdditionalFields';
            break;
          case 'GROUP':
            name = 'containerAdditionalFields';
            break;
        }
        dispatch(actions.fetchAdditionalFieldsReady({ name, fields }));
      });
  };
};

jsxActions.printTags = function (asset) {
  return function (dispatch, getState) {
    api.printTags(dispatch, getState)(asset);
  };
};

jsxActions.printTagsFailed = function (response) {
  return function (dispatch, getState) {
    api.printTagsFailed(dispatch, getState)(response);
  };
};

jsxActions.printTagsFinished = function (response) {
  return function (dispatch, getState) {
    api.printTagsFinished(dispatch, getState)(response);
  };
};

jsxActions.onRemoveAssetButtonClick = function (
  confirmationDialogBuilder,
  succeededMessageDialogBuilder,
  data
) {
  return function (dispatch, getState) {
    api.openRemoveAssetConfirmationDialog(dispatch, getState)(
      confirmationDialogBuilder,
      succeededMessageDialogBuilder,
      data
    );
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.openRemoveAssetConfirmationDialog = function (dispatch, getState) {
  return function (confirmationDialogBuilder, succeededMessageDialogBuilder, data) {
    let messageDialogDescriptor = confirmationDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.removeAsset(dispatch, getState)(data, succeededMessageDialogBuilder)
    );
    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
  };
};

api.removeAsset = function (dispatch, getState) {
  return function (data, removeAssetSucceededMessageDialogBuilder) {
    messageDialogApi.close(dispatch, getState)();

    let method =
      data.parentAssetObjectType === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')
        ? groupsService.removeAssetFromGroup
        : kitsService.removeRollFromKit;

    method(data).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Remove related asset failed');
        return { success: false };
      }
      assetPageApi.reload(dispatch, getState)(data.parentAssetObjectType, data.parentAssetId, true);
      let messageDialogDescriptor = removeAssetSucceededMessageDialogBuilder(
        messageDialogApi.close(dispatch, getState)
      );
      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    });
  };
};

api.printTags = function (dispatch, getState) {
  return function (asset, messageDialogBuilder) {
    let queryData = {
      assetId: asset.get('id'),
      printerLocationId: asset.get('locationId'),
    };

    dispatch(actions.printTagsInProgress());

    return assetPrintTagsService.printTags(queryData).then((response) => {
      if (!Network.isResponseValid(response)) {
        dispatch(actions.printTagsFailed());
        console.error('Print Tags Failed');

        let messageDialogDescriptor = messageDialogBuilder(
          utility.NormalizeResponseError(response)
        );
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        return { success: false };
      }
      dispatch(actions.printTagsFinished());
      messageDialogApi.open(
        dispatch,
        getState
      )({
        title: createLabelHelper('').get(
          'mat.locationpage.view.assets.printRfidTagsDialog.confirmation.message.title'
        ),
        statusType: 'success',
        errorCode: '0',
      });
    });
  };
};

api.handelPrintTagsPrinterPollingRequest = function (dispatch, getState) {
  return function (queryData, messageDialogBuilder) {
    return assetPrintTagsService.pollingResult(queryData).then((response) => {
      api.printTagsPrinterPollingRequestFinished(dispatch, getState)(
        response,
        messageDialogBuilder
      );
    });
  };
};

api.printTagsPrinterPollingRequestFinished = function (dispatch, getState) {
  return function (res, messageDialogBuilder) {
    switch (res.data.printStatus) {
      case 'PRINT_SUCCESS':
        dispatch(actions.printTagsFinished());
        assetPageApi.reload(dispatch, getState)(res.data.assetType, res.data.assetId, true);
        break;
      case 'PRINT_FAILED':
        dispatch(actions.printTagsFailed());
        let messageDialogDescriptor = messageDialogBuilder(
          utility.NormalizePrinterPollingResponseError(res)
        );
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        break;
      default:
        console.error('error in printTagsPrinterPollingRequestFinished');
    }
  };
};

api.fetchAllDataForEditor = function (dispatch, getState) {
  return function (assetType) {
    api.fetchMaterials(dispatch, getState)();
    api.fetchLots(dispatch, getState)();
    api.fetchManufacturers(dispatch, getState)();
    api.fetchProjects(dispatch, getState)();
    api.fetchInspectionStatuses(dispatch, getState)();

    api.fetchTags(dispatch, getState)();
    api.fetchLocations(dispatch, getState)();
    api.fetchToolTypes(dispatch, getState)();
    api.fetchWorkOrders(dispatch, getState)();
    api.fetchAdditionalFields(dispatch, getState)(assetType);
  };
};

api.fetchAdditionalFields = function (dispatch, getState) {
  return function (assetType) {
    if (assetType === getEnumValue(enumTypes.OBJECT_TYPE)('KIT')) {
      let promise1 = additionalFieldsService.fetchAdditionalFields({
        entityType: getEnumValue(enumTypes.OBJECT_TYPE)('KIT'),
      });
      let promise2 = additionalFieldsService.fetchAdditionalFields({
        entityType: getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'),
      });

      return Promise.all([promise1, promise2]).then((allResults) => {
        if (!Network.isResponseValid(allResults[0]) || !Network.isResponseValid(allResults[1])) {
          messageDialogApi.responseError(dispatch, getState)(null);
          console.error('Get additional fields failed');
          return { success: false };
        }

        let kitAdditionalFields = allResults[0] ? allResults[0].dataList : [];
        let kitTypeAdditionalFields = allResults[1] ? allResults[1].dataList : [];

        let additionalFields = {
          kitAdditionalFields: kitAdditionalFields,
          kitTypeAdditionalFields: kitTypeAdditionalFields,
        };
        dispatch(actions.fetchAdditionalFieldsReady(additionalFields));
      });
    }

    return additionalFieldsService
      .fetchAdditionalFields({ entityType: getEnumValue(enumTypes.OBJECT_TYPE)(assetType) })
      .then((result) => {
        if (!Network.isResponseValid(result)) {
          messageDialogApi.responseError(dispatch, getState)(null);
          console.error('Get additional fields failed');
          return { success: false };
        }

        let fields = result.dataList,
          name = '';
        switch (assetType) {
          case 'ROLL':
            name = 'rollAdditionalFields';
            break;
          case 'SPOOL':
            name = 'spoolAdditionalFields';
            break;
          case 'TOOL':
            name = 'toolAdditionalFields';
            break;
          case 'GROUP':
            name = 'containerAdditionalFields';
            break;
        }

        dispatch(actions.fetchAdditionalFieldsReady({ [name]: fields }));
      });
  };
};

api.fetchMaterials = function (dispatch, getState) {
  return function () {
    materialsService.fetchActiveMaterials().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Materials Failed');
        return { success: false };
      }
      dispatch(actions.fetchMaterialsReady(response));
    });
  };
};

api.fetchLots = function (dispatch, getState) {
  return function () {
    lotsService.fetchLots().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Lots Failed');
        return { success: false };
      }
      dispatch(actions.fetchLotsReady(response));
    });
  };
};

api.fetchManufacturers = function (dispatch, getState) {
  return function () {
    manufacturersService.fetchManufacturers().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Manufacturers Failed');
        return { success: false };
      }
      dispatch(actions.fetchManufacturersReady(response));
    });
  };
};

api.fetchTags = function (dispatch, getState) {
  return function () {
    tagsService.fetchTags().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Tags Failed');
        return { success: false };
      }
      dispatch(actions.fetchTagsReady(response));
    });
  };
};

api.fetchProjects = function (dispatch, getState) {
  return function () {
    projectsService.fetchActiveProjects().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Projects Failed');
        return { success: false };
      }
      dispatch(actions.fetchProjectsReady(response));
    });
  };
};

api.fetchInspectionStatuses = function (dispatch, getState) {
  return function () {
    rollsService.fetchInspectionStatuses().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch nspection Statuses Failed');
        return { success: false };
      }

      let payload = [];
      if (response.data && response.data.items) {
        let labels = createLabelHelper('');
        payload = response.data.items.map((obj) => {
          return { value: obj.name, label: labels.get(obj.labelKey) };
        });
      }
      dispatch(actions.fetchInspectionStatusesReady(payload));
    });
  };
};

api.fetchLocations = function (dispatch, getState) {
  return function () {
    locationsService.fetchActiveLocations().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Locations Failed');
        return { success: false };
      }
      dispatch(actions.fetchLocationsReady(response));
    });
  };
};

api.fetchToolTypes = function (dispatch, getState) {
  return function () {
    toolsService.fetchToolTypes().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Tool Types Failed');
        return { success: false };
      }
      dispatch(actions.fetchToolTypesReady(response));
    });
  };
};

api.fetchWorkOrders = function (dispatch, getState) {
  return function () {
    workOrdersService.fetchWorkOrders().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Work Orders Failed');
        return { success: false };
      }
      dispatch(actions.fetchWorkOrdersReady(response));
    });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

let utility = {};

utility.NormalizeResponseError = function (response) {
  let message;
  let statusType;
  let errorCode;

  if (
    !response ||
    !response.applicationResponseStatus ||
    !response.applicationResponseStatus.message
  ) {
    message = 'Print Tags Failed';
  } else {
    message = response.applicationResponseStatus.message;
    statusType = response.applicationResponseStatus.statusType.toLowerCase();
    errorCode = response.applicationResponseStatus.errorCode;
  }

  return {
    message,
    statusType: statusType,
    errorCode: errorCode,
  };
};

utility.NormalizePrinterPollingResponseError = function (response) {
  return {
    message:
      'Failed To Print Tags On Asset ' +
      response.data.assetType +
      ' ' +
      response.data.assetBusinessId,
    statusType: 'error',
    errorCode: '0',
  };
};

import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import TextCell from '../../../../../Common/CustomGridCells/TextCell/textCell';

require('./actionSequenceViewGrid.scss');

export default class ActionSequenceViewGrid extends React.PureComponent{

  filterConfig = [];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.mqtt.brokers.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        // title: this.labels.get('columns.brokerName.title'),
        title: 'Name',
        filterType: filterTypes.NONE,
        width: 250,
        columnOptions: {
          sortable: false,
          minWidth: 140,
        }
      },
      {
        fieldName: 'description',
        // title: this.labels.get('columns.port.title'),
        title: 'Description',
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: TextCell,
        }
      },
    ]
  };

  getRowHeight(params) {
    let rowHeight = 64;
    if (params.data.description) {
      let lines = params.data.description.split('\n');
      if (lines) {
        rowHeight = lines.length * 25 + 40;
      }
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="action-sequences-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_RFID_SETTINGS_ACTION_SEQUENCE}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  checkboxSelection: false,
                  getRowHeight: this.getRowHeight
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

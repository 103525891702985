import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';

import { jsxActions as scanWorkOrderPageActions } from '../../../actions/Mobile/ScanWorkOrderPage/scanWorkOrderPageActions.js';
import { jsxActions as headerActions } from '../../../actions/Mobile/Header/headerActions.js';
import ScanHeader from '../Common/ScanHeader/scanHeader.js';

import ScanBottom from '../Common/ScanBottom/scanBottom.js';
import TaskButton from '../Common/TaskButton/taskButton.js';

import './scannedWorkOrderTaskPage.scss';

const pageLabels = createLabelHelper('mat.mobile.scannedWorkOrderTasksPage.');

const getState = (state) => {
  const pageState = state.mobile.get('scanWorkOrderPage');
  return {
    tasks: pageState.get('woTasks') ?? [],
    selectedStation: pageState.get('selectedStation') ?? '',
    stations: pageState.get('stations') ?? [],
    selectedWorkOrder: pageState.get('selectedWorkOrder') ?? '',
  };
};

const allActions = {
  header: headerActions,
  scanWorkOrder: scanWorkOrderPageActions,
};

/**
 * Returns a React element representing a list of tasks, based on the given tasks, station name, and open task page function.
 *
 * @param {array} tasks An array of tasks.
 * @param {string} stationName The name of the station.
 * @param {function} openTaskPage A function to open the task page.
 * @returns {ReactElement} A React element representing the task list.
 */
const getTaskList = (tasks, stationName, openTaskPage) => {
  // If the tasks array is empty, return a no tasks message.
  if (!tasks.length) {
    const message = pageLabels.get('content.noTaskForStation', undefined, { station: stationName });
    return <div className="scanned-work-order-no-tasks">{message}</div>;
  }

  // Return a list of task buttons.
  return tasks.map((task) => <TaskButton task={task} onClick={openTaskPage} key={task.task.id} />);
};

const ScannedWorkOrderTasksPage = ({ history }) => {
  const { state, actions } = useRedux(allActions, getState);

  const [showAll, setShowAll] = useState(false);

  const { tasks = [], selectedStation, stations, selectedWorkOrder } = state;

  useEffect(() => {
    if (!tasks.length) {
      history.push('/scanWorkOrder');
    }
  }, [tasks, history]);

  const stationName = useMemo(() => {
    const station = stations.find((station) => selectedStation === station.value);
    if (station) {
      return station.label;
    }
    return '';
  }, [stations, selectedStation]);

  const filteredTasks = useMemo(() => {
    if (showAll) {
      return tasks;
    }

    if (!selectedStation) {
      return tasks;
    }

    return tasks.filter(({ plannedResource }) => {
      const { id } = plannedResource ?? {};
      return selectedStation === id;
    });
  }, [selectedStation, showAll, tasks]);

  const toggleShowAll = () => {
    setShowAll((state) => !state);
  };

  const headerItems = {
    leftButton: {
      id: 'leftButton',
      icon: 'pl pl-arrow-left',
      action: actions.header.onGoBack,
      actionData: history,
    },
    middleButton: {
      id: 'middleButton',
      label: selectedWorkOrder.toUpperCase(),
    },
    rightButton: null,
  };

  const footerItems = {
    button: {
      id: 'changeLocationButton',
      action: toggleShowAll,
      label: `${
        showAll
          ? pageLabels.get('footer.buttons.button.center.stationTasks', undefined, { station: stationName })
          : pageLabels.get('footer.buttons.button.center.allTasks')
      }`,
    },
  };

  const openTaskPage = ({ task }) => {
    history.push(`/Mobile/task/${task.id}/`);
  };

  return (
    <div className="scanned-work-order-tasks-page">
      <ScanHeader {...headerItems} />
      <div className="scanned-work-order-tasks">{getTaskList(filteredTasks, stationName, openTaskPage)}</div>
      {stationName ? <ScanBottom {...footerItems} /> : null}
    </div>
  );
};

ScannedWorkOrderTasksPage.propTypes = {
  history: PropTypes.object,
};

ScannedWorkOrderTasksPage.defaultProps = {
  history: {},
};

export default ScannedWorkOrderTasksPage;

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';
require('./inputSection.scss');

export default class InputSection extends React.PureComponent {

  getLabel = () => {

    if(this.props.label && this.props.label.length > 35 && !this.props.forceLabelOverflow){
      let text = this.props.label.slice(0, 35) + '...';
      return (
        (<Tooltip
            placement={"top"}
            value={this.props.label}
            delayShow={500}>
            <label htmlFor={this.props.htmlFor}>
              {text}
              {this.props.labelExtraComponent}
            </label>
          </Tooltip>
        )
      )
    }


    return (
      <label htmlFor={this.props.htmlFor}>
        {this.props.label}
        {this.props.labelExtraComponent}
      </label>
    )
  };
  getHeaderCheckbox = ()=>{
    if(!this.props.onCheckboxChangeCallback && !this.props.onCheckboxActualChangeCallback){
      return null;
    }
    return(
      <div className="header-checkbox-wrapper">
        <Checkbox name={'headerCheckbox_' + this.props.htmlFor} id={'headerCheckbox_' + this.props.htmlFor} onClick={this.props.onCheckboxChangeCallback} onChange={this.props.onCheckboxActualChangeCallback} disabled={this.props.isCheckboxDisabled}/>
        <label htmlFor={'headerCheckbox_' + this.props.htmlFor}>{this.props.checkBoxLabel ? this.props.checkBoxLabel : 'clear'}</label>
      </div>)
  };

  render() {
    return (
      <div className={cn('input-section', this.props.onCheckboxChangeCallback ? 'header-checkbox' : '',this.props.className)}>
        {this.getLabel()}
        {this.getHeaderCheckbox()}
        {this.props.children}
      </div>
    );

  }
}


InputSection.defaultProps = {
  labelExtraComponent: null,
};

InputSection.propTypes = {
  labelExtraComponent: PropTypes.any,
  onCheckboxChangeCallback: PropTypes.func,
  //every implementation on the site is using onCheckboxChangeCallback which is actually called on click instead of on change, and thus gives the wrong value
  //until each other implementation is fixed, you may use onCheckboxActualChangeCallback which is called on change.
  //TODO: Fix implementation bug in every usage where onCheckboxChangeCallback is used
  onCheckboxActualChangeCallback: PropTypes.func,
  isCheckboxDisabled: PropTypes.bool,
  checkBoxLabel:PropTypes.string,
  forceLabelOverflow: PropTypes.bool,
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  className: PropTypes.string
};

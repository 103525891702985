import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { gridsNames} from '../../../enums/gridsNames';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { jsxActions as actionSequenceViewGridActions} from '../../../actions/Administration/RfidSettingsPage/actionSequenceViewGridActions';
import ActionSequenceView from '../../../components/Administration/RfidSettingsPage/AutomatedWorkflowsView/ActionSequenceView/actionSequenceView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      actionSequenceViewGridActions: bindActionCreators(actionSequenceViewGridActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let ActionSequenceViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_ACTION_SEQUENCE);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sData: state.administration.getIn(['rfidSettingsPage', 'actionSequenceViewData']),
    };
  },

  mapDispatchToProps
)(ActionSequenceView);

export default ActionSequenceViewContainer;



import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

require('./valueWithStateCell.scss');

export default class ValueWithStateCell extends React.PureComponent {
  render() {
    let {value} = this.props;

    if (value) {
      let className = classNames('value-with-state-cell', {'has-error': (value.state === 'ERROR')}, {'has-warning': (value.state === 'WARNING')});
      return (
        <div className={className}>
          <span>{value.value}</span>
          <label >{value.label}</label>
        </div>);
    }
    return null;
  }
}

ValueWithStateCell.propTypes = {
  value: PropTypes.object,
};

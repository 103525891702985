import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { routerReducer } from 'react-router-redux';
import {reducer as formReducer} from 'redux-form';
import {intlReducer} from '../../../mat/js/reducers/ReactIntl/ReactIntlReducer';

import wizardReducer from '../components/Wizard/wizardReducer'
import gridReducer from '../components/Grid/gridReducer.js'
import {func} from 'prop-types';


export default function(reducers, actionsTypes) {
  let createStoreWithMiddleware;

  //add more middleware as further arguments to the method
  const middleware = applyMiddleware(
    thunkMiddleware
  );

  // Configure the dev tools when in DEV mode
  if (__DEV__) {

    //old store creator, returns (reducer, initialState) => store;
    /*const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    createStoreWithMiddleware = composeEnhancers(
      applyMiddleware(thunkMiddleware)
    )(createStore);
    */

    // Use Redux "DevTools Extension". - Browser extension with graphs and other cool stuff.
    // extension options object
    const options = {
      trace: true
    };
    const composeEnhancers = composeWithDevTools(options);
    const middlewareCreator = composeEnhancers(middleware);
    createStoreWithMiddleware = function(rootReducer, initialState) {
      return createStore(rootReducer, initialState, middlewareCreator);
    };
  } else {
    // old store creator, returns (reducer, initialState) => store;
    // createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);
    createStoreWithMiddleware = function(rootReducer, initialState) {
      return createStore(rootReducer, initialState, middleware)
    };
  }

  //const rootReducer = combineReducers({...reducers, routing: routerReducer, form : formReducer, intl : intlReducer});
  const appReducer = combineReducers({
    ...reducers,
    routing: routerReducer,
    form: formReducer,
    intl: intlReducer,
    wizard: wizardReducer,
    grid: gridReducer,
  });


  const rootReducer = (state, action) => {

    if (__DEV__) {
      window.zzGetState = function() {
        return state;
      };

      window.zzGetFormState = function() {
        return state.form;
      };

      window.zzGetWizards = function() {
        return state.wizard.toJS();
      };
    }

    if (action.type === actionsTypes.SYSTEM_CLEAR_ALL) {
      localStorage.removeItem('enumsData');
      state = undefined;
    }

    return appReducer(state, action);
  };

  return function(initialState) {
    return createStoreWithMiddleware(rootReducer, initialState);
  }

}

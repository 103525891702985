import React, { Component } from 'react';
import {BarChart, CartesianGrid, XAxis,YAxis, Tooltip, Bar, Legend} from 'recharts';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

require('./toolsStatusChart.scss');

export default class ToolsStatusChart extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.dashboard.toolsMaintenance.distributionStatusChart.');
  }

  getChartData = () => {

    return this.props.chartData.map((location, index) => {
      let name = location.locationName;
      if(name.length > 15){
        name = name.slice(0, 15) + '...';
      }

      return {
        shortName: name,
        availableLabel: this.labels.get('available'),
        occupiedLabel: this.labels.get('occupied'),
        waitingForMaintenanceLabel: this.labels.get('waitingForMaintenance'),
        inMaintenanceLabel: this.labels.get('inMaintenance'),
        name: location.locationName,
        available: location.availableCount,
        waiteForMaintenance: location.waitingForMaintenanceCount,
        inMaintenance: location.inMaintenanceCount,
        occupied: location.occupitedCount,
      }
    });
  };

  customTooltip(e) {
    if (e.active && e.payload != null && e.payload[0] != null) {
      return (<div className="custom-tooltip">
        <p>{`${e.payload[0].payload.name}`}</p>
        <p>{`${e.payload[0].payload.availableLabel} ${e.payload[0].payload.available}`}</p>
        <p>{`${e.payload[0].payload.waitingForMaintenanceLabel} ${e.payload[0].payload.waiteForMaintenance}`}</p>
        <p>{`${e.payload[0].payload.inMaintenanceLabel} ${e.payload[0].payload.inMaintenance}`}</p>
        <p>{`${e.payload[0].payload.occupiedLabel} ${e.payload[0].payload.occupied}`}</p>
      </div>);
    }
    else {
      return "";
    }
  }

  renderChart = () => {
    if(!this.props.chartData || !this.props.chartData.length){
      return  <div className={'empty-chart-section'}><span className={'no-results'}>{this.labels.get('noResults')}</span></div>;
    }
    let chartData = this.getChartData();

    return (
      <div className={'chart-section'}>
        <BarChart width={600} height={400} data={chartData} key={'111'} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="shortName" angle={-45}  textAnchor="end" interval={0} height={150}/>
          <YAxis   label={{value: this.labels.get('toolCount'), offset: 10,angle: -90, position: 'insideLeft', style:{ textAnchor: 'middle' }}}/>
          <Tooltip content={this.customTooltip}/>
          <Legend />
          <Bar dataKey={'available'} name={this.labels.get('available')} fill="#5DADE2"  stackId="a"/>
          <Bar dataKey={'occupied'} name={this.labels.get('occupied')} fill="#FF9300"  stackId="a"/>
          <Bar dataKey={'waiteForMaintenance'} name={this.labels.get('waitingForMaintenance')} fill="#CCD1D1"  stackId="a"/>
          <Bar dataKey={'inMaintenance'} name={this.labels.get('inMaintenance')} fill="#F0CA0E"  stackId="a"/>
        </BarChart>
      </div>
    );
  };
  render() {
    if(!this.props.chartData){
      return null;
    }
    let title = this.labels.get('title');
    if(this.props.chartData.isReachedMaxTotal){
      title = `${title} - ${this.labels.get('maxReachedTitle')}`;
    }
    return (
        <div className="tools-status-chart">
          <div className='chart-title'>{title}</div>
          {this.renderChart()}
        </div>
    )
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import EditDashboardDemoDataDialog from './Dialogs/EditDashboardDemoDataDialog/editDashboardDemoDataDialog';
import {PsManagementMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import Button from 'infrastructure/js/components/controls/Button/button';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';

require('./dashboardsDemoDataView.scss');

export default class DashboardsDemoDataView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.psmanagement.dashboardsDemoData.view.');
    this.allLabels= createLabelHelper('');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  componentDidMount() {
    this.props.actions.init();
    this.props.actions.psManagementPageActions.onSelectedMenuItemChanged(PsManagementMenuItemsIds.NAV_ELEMENT_DASHBOARDS_DEMO_DATA);
  }

  getHeaderItems() {
    let {actions} = this.props;

    return {
      title: this.labels.get('title'),
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.REMOVE_DATA_FROM_DASHBOARDS,
          icon: 'pl pl-delete-icon',
          action: this.removeDashboardsDemoData,
          disabled: !this.isDemoDataFound(),
        },
      ],
    };
  }

  isDemoDataFound = () => {
    let dashboardsData = this.props.sData.get('dashboardsData');

    if (dashboardsData) {
      let demoDataFound = dashboardsData.find( (item, index) => {
        return (item.isDefault || item.isCustom );
      });
      return !!demoDataFound;
    }
    return false;
  };

  removeDashboardsDemoData = () => {
    this.props.actions.removeDashboardsDemoData(this.getMessageDialogBuilder());
  };

  getMessageDialogBuilder = () => {
    return (fnCloseDialog, callback) => {
      let title = this.dialogLabels.get('confirmation');
      let type = 'warning';
      let message = this.labels.get('removeAllData.confirmation.message');
      let children =  [<MessageDialog.DataRow key="confirmMessageDataRow" label={message} value={''}/>];
      let buttons = [
        {id:'cancel', text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'remove', text: this.dialogLabels.get('remove'), action: callback , bsStyle: 'primary'}
      ];
      let className = '';
      let messageDialogDescriptor = {title, buttons, children,  className, type};
      return messageDialogDescriptor;
    };
  };


  showEditDashboardDemoDataDialog = (item) => {
    this.props.actions.editDashboardDemoDataDialogActions.show(item);
  };

  downloadDashboardTemplate = (item) => {
    if (item) {
      this.props.actions.downloadDashboardTemplate(item.dashboardType);
    }
  };

  deleteDashboardDemoData = (item) => {
    if (item) {
      this.props.actions.deleteDashboardDemoData(item.dashboardType);
    }
  };

  reloadPage = () => {
    this.props.actions.init();
  };

  getLoadingOverlay() {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }

  renderDashboardsData = () => {
    let dashboardsData = this.props.sData.get('dashboardsData');

    if (dashboardsData) {
      let dashboards =  dashboardsData.map( (item, index) => {
        let dashboardData = item.isDefault ? this.labels.get('default') : item.isCustom ? this.labels.get('custom') : '';
        return (
          <div className="dashboards-list-row" key={item.id}>
            <div className="cell">{this.allLabels.get(item.dashboardTypeDisplayKey)}</div>
            <div className="cell">{dashboardData}</div>
            <div className="cell">
            <Button id={'editBtn' + index}
                    icon="pl pl-edit-icon"
                    className="border-btn"
                    onClick={() => this.showEditDashboardDemoDataDialog(item)}
                    >
              {this.labels.get('button.edit')}
            </Button>
            <Button id={'downloadBtn' + index}
                    icon="pl pl-export-icon"
                    className="border-btn"
                    onClick={() => this.downloadDashboardTemplate(item)}
            >
              {this.labels.get('button.downloadTemplate')}
            </Button>
            <Button id={'deleteBtn' + index}
                    icon="pl pl-delete-icon"
                    className="border-btn"
                    onClick={() => this.deleteDashboardDemoData(item)}
                    disabled={!item.isDefault && !item.isCustom}
            >
              {this.labels.get('button.deleteData')}
            </Button>
            </div>
          </div>
        )
      });

      return (
        <div className="dashboards-list">
          <div className="dashboards-list-header ">
            <label className="column">{this.labels.get('column.dashboard')}</label>
            <label className="column">{this.labels.get('column.demoData')}</label>
          </div>
          {dashboards}
        </div>
      )

    }

    return null;
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className="dashboards-demo-data-view">

        <Header {...headerItems}/>

        {this.renderDashboardsData()}

        {this.getLoadingOverlay()}

        <PL_DialogWrapper dialogComponent={EditDashboardDemoDataDialog}
                          show={this.props.sEditDashboardDemoDataDialogData.get('show')}
                          actions={this.props.actions.editDashboardDemoDataDialogActions}
                          sData={this.props.sEditDashboardDemoDataDialogData}
                          reloadParentComponent={this.reloadPage}
                          sLoggedInUser={this.props.sLoggedInUser}
        />

      </div>
    );
  }
}

DashboardsDemoDataView.propTypes = {
  sGrid: PropTypes.object,
};


import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ACTIVITYLOG_DIALOG_SHOW: "ACTIVITYLOG_DIALOG_SHOW",
  ACTIVITYLOG_DIALOG_HIDE: "ACTIVITYLOG_DIALOG_HIDE",
  ACTIVITYLOG_DIALOG_SET_CONFIG_DATA: "ACTIVITYLOG_DIALOG_SET_CONFIG_DATA"
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.ACTIVITYLOG_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.ACTIVITYLOG_DIALOG_HIDE, payload: payload };
  },
  setConfigData: function(payload) {
    return {type: actionTypes.ACTIVITYLOG_DIALOG_SET_CONFIG_DATA, payload: payload };
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  }
};

jsxActions.show = function() {
  return function(dispatch, getState) {
    api.show(dispatch, getState)();
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

// Someone wants to close the dialog.
api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

// Someone opens the dialog.
api.show = function(dispatch, getState) {
  return function() {
    dispatch(actions.show());
  }
};
api.setConfigData = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.setConfigData(data));
  }
};

// api.responseError = (dispatch, getState) =>{
//   return (response) => {
//     let labels = createLabelHelper('');
//     let message;
//     let className = "oneBackground";
//     let title;
//     let mainError = response.applicationResponseStatus.errors.mainError || [];
//     let errorParams = mainError.params  || [];
//     //Check if response valid for format message.
//     if(!response || !response.applicationResponseStatus  || !response.applicationResponseStatus.message){message = 'Operation Failed';}
//     else{message = response.applicationResponseStatus.errorCode;}
//
//     title = labels.get(message, 'Operation Failed', errorParams);
//
//     let messageDialogDescriptor = {title, className, type:'error'};
//     dispatch(actions.show(messageDialogDescriptor));
//   };
// };





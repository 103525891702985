import { Map } from 'immutable';
import { actionTypes as messageDialogActionTypes } from '../../actions/MessageDialog/messageDialogActions';

//check if we already load the current location, handle refresh scenario.
let defaultState = {
  id:"globalMessageDialog",
  title : "",
  children : "",
  show :false,
  type : "",
  buttons : []
};


export default function(state = defaultState, action) {
  switch (action.type) {

    case messageDialogActionTypes.MESSAGEDIALOG_SHOW:
      action.payload = action.payload || {};
      return {...defaultState, ...action.payload, show: true};

    case messageDialogActionTypes.MESSAGEDIALOG_HIDE:
      return {...state, show: false};

    default:
      return state;
  }
}


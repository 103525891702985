import { fromJS } from 'immutable';

import { default as overviewTabReducer } from './overviewTabReducer';
import {default as archiveWorkOrderDialogReducer } from './Dialogs/archiveWorkOrderDialogReducer';
import {default as activateWorkOrderDialogReducer } from './Dialogs/activateWorkOrderDialogReducer';

import { actionTypes as workOrderPageActionTypes }    from '../../actions/WorkOrderPage/workOrderPageActions.js';
import { actionTypes as workOrderPageEditorActions }  from '../../actions/WorkOrderPage/workOrderPageEditorActions.js';

let defaultState = {
  data: {
    workOrder: {
      id: null,
      workOrderStatus: null,
    },

    workOrderPageEditorData: {
      // optionsWorkOrderProjects: [],
      // optionsWorkOrderParts: [],
      // optionsWorkOrderOrders: []
    }

  }
};


export default function(state = fromJS(defaultState), action) {

  state = state.set('overviewTab',            overviewTabReducer(state.get('overviewTab'), action));
  state = state.set('archiveWorkOrderDialog',     archiveWorkOrderDialogReducer(state.get('archiveWorkOrderDialog'), action));
  state = state.set('activateWorkOrderDialog',    activateWorkOrderDialogReducer(state.get('activateWorkOrderDialog'), action));


  switch(action.type) {

    case workOrderPageActionTypes.WORKORDERPAGE_FETCHDATA_FINISHED:
      state = state.setIn(['data', 'workOrder', 'id'], action.payload.id);
      state = state.setIn(['data', 'workOrder', 'workOrderStatus'], action.payload.workOrderStatus);

      return state;

    case workOrderPageActionTypes.WORKORDERPAGE_CLEAR_DATA:
      return fromJS(defaultState);

    case workOrderPageActionTypes.WORKORDERPAGE_UNMOUNT:
      return fromJS(defaultState);

    case workOrderPageEditorActions.WORKORDERPAGE_EDITOR_FETCH_PROJECTS_READY:
      state = state.setIn(['data', 'workOrderPageEditorData', 'optionsWorkOrderProjects'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId } })
      );
      return state;

    case workOrderPageEditorActions.WORKORDERPAGE_EDITOR_FETCH_PARTS_READY:
      state = state.setIn(['data', 'workOrderPageEditorData', 'optionsWorkOrderParts'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId } })
      );
      return state;

    case workOrderPageEditorActions.WORKORDERPAGE_EDITOR_FETCH_KIT_TYPES_READY:
      state = state.setIn(['data', 'workOrderPageEditorData', 'optionsWorkOrderKitTypes'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId } })
      );
      return state;

    case workOrderPageEditorActions.WORKORDERPAGE_EDITOR_FETCH_ORDERS_READY:
      state = state.setIn(['data', 'workOrderPageEditorData', 'optionsWorkOrderOrders'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId} })
      );
      return state;
    case workOrderPageEditorActions.WORKORDERPAGE_EDITOR_FETCH_ADDITIONAL_FIELDS_READY:
      state = state.setIn(['data', 'workOrderPageEditorData', 'additionalFields'],action.payload.dataList);
      return state;

    default:
      return state;

  }

}










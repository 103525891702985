import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import * as assetService from '../../services/Assets/assetService.js';
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ASSET_PAGE_ATTACHMENT, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {

    let hideDeleted = getState().assetPage.get('hideDeleted');
    let assetData = getState().assetPage.getIn(['data', 'asset']);

    let workOrderId = getState().workOrderPage.getIn(['data', 'workOrder' , 'id']);
    if(workOrderId){
      return assetService.getAssetAttachment('WO', workOrderId, hideDeleted, query );
    }
    return assetService.getAssetAttachment(assetData.get('objectType'), assetData.get('id'), hideDeleted, query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let convertedRows = rows.map((row) => {
    row.m_createdDate = DateTimeHelper.FormatDateObjectToDayMonth(row.createdDate);
    row.m_size = Math.ceil(row.size / Math.pow(1024,1)) + ' KB';
    return row;
  });

  return convertedRows;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

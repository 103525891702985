import React, {Component} from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import { Link } from 'react-router-dom';
import * as topSearchHelper from '../topSearchHelper';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
require('./topSearchResult.scss');


export default class TopSearchResult extends React.PureComponent {
  constructor(props) {
    super(props);
     this.labels = createLabelHelper('mat.header.search.');
     this.controlsLabels = createLabelHelper('mat.controls.');
  }

  boldSearchedKey(source, key) {
    return source.replace(new RegExp('(^|)(' + key + ')(|$)','ig'), '$1<b>$2</b>$3');
  }

  renderResult(result) {
    let resultCustomLabel = this.boldSearchedKey(result.label, this.props.currentInput);
    switch (this.props.currentType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('STATION') :
      case getEnumValue(enumTypes.OBJECT_TYPE)('LOCATION'):
      case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        return resultCustomLabel;
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        return `${resultCustomLabel} - ${result.data.material ? result.data.material.businessId :''}`;
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        return `${resultCustomLabel} - ${result.data.kitType ? result.data.kitType.businessId :''}`;
      case getEnumValue(enumTypes.OBJECT_TYPE)('PART'):
        return `${resultCustomLabel} - ${result.data.partType ? result.data.partType.businessId :''}`;
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        return `${resultCustomLabel} - ${result.data.toolType ? result.data.toolType.businessId :''}`;
    }
  }
  renderResults() {
    if (this.props.results.length == 0) {
      return <div tabIndex="0" className="result no-results ">
        <span>{this.labels.get('result.notfound')} &#39;<b>{this.props.currentInput}</b>&#39; </span></div>
    }
    return this.props.results.map((result, index)=>{
        return (
          <Link to={topSearchHelper.getLinkByType(this.props.currentType, result.value)}
            key={'nav-' + index}
            onClick={this.props.onSearchResultClick}
          >
            <div key={'search-result-' + index} className="result" dangerouslySetInnerHTML={{__html: this.renderResult(result)}}></div>
          </Link>)
    });
  }

  renderTitle() {
    if (this.props.results.length > 0 ) {
      let labelKey = topSearchHelper.getLabelKeyByType(this.props.currentType);
      let title = this.labels.get(labelKey, '', {count: this.props.results.length});
      return (
        <div tabIndex="0" className="result result-header ">{title}</div>);
    }
    return null;
  }

  renderLast = () => {
    if (this.props.isLast) {
      return null;
    }
    let msg = this.controlsLabels.get('combobox.refineSearch');
    return (
      <div className="result result-bottom">{msg}</div>
    );
  };

  render() {
    return  <div tabIndex="0" className="results-container">
      <div className="results">
        {this.renderTitle()}
        {this.renderResults()}
      </div>
      {this.renderLast()}
    </div>

  }
}

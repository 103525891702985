import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import ValidationComponent from '../ValidationComponent/validationComponent';
import { useEffect, useState } from 'react';

import './colorPicker.scss';

const PL_ColorPickerWithText = (props) => {
  return <Field {...props} component={ColorPickerWithText} />;
};

export const ColorPickerWithText = ({ name, onChange, meta, input, text }) => {
  const [color, setColor] = useState('#000');

  useEffect(() => {
    if (input) {
      setColor(input.value || '#000');
    }
  }, [input]);

  const computedName = input.name ?? name;

  const handleInputChange = ({ target: { value } }) => {
    if (input.onChange) {
      input.onChange(value);
    } else {
      onChange(value);
    }

    setColor(value);
  };

  return (
    <div className="color-picker-with-text">
      <div className="color-holder-wrapper">
        <label className="color-holder" style={{ backgroundColor: color }} htmlFor={computedName}>
          <span>{text}</span>
        </label>
      </div>
      {meta ? <ValidationComponent {...meta} /> : null}
      <input type="color" className="color-input" id={computedName} name={computedName} value={color} onChange={handleInputChange} />
    </div>
  );
};

ColorPickerWithText.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  meta: PropTypes.object,
  input: PropTypes.object,
};

ColorPickerWithText.defaultProps = {
  name: 'no_name',
  text: '',
  onChange: () => console.error('no onChange function!'),
  meta: {},
  input: {},
};

export default PL_ColorPickerWithText;

import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import EditPasswordField from 'infrastructure/js/components/controls/EditPasswordField/editPasswordField.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

require('./createMqttBrokerDialog.scss');

const qosOptions = [
  {value: 0, label: '0'},
  {value: 1, label: '1'},
  {value: 2, label: '2'},
]

class CreateMqttBrokerDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.rfidsettings.dialog.createMqttBroker.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.state = {
      selectedTab: 'connectionTab',
      originalPasswordValue: null,
      isEditingPassword: false
    };

    this.maxLength10 = Validation.maxLength(10);
  }

  componentDidMount() {
    let initialValues = {
      port: this.isEditMode ? this.itemToEdit.port : 1883,
      useSsl: this.isEditMode ? this.itemToEdit.useSsl : false,
      keepAliveTimeSeconds: this.isEditMode ? this.itemToEdit.keepAliveTimeSeconds : 60,
      useCleanSession: this.isEditMode ? this.itemToEdit.useCleanSession : true,

      bmPayload: this.isEditMode ? this.itemToEdit.bmPayload : 'ONLINE',
      cmPayload: this.isEditMode ? this.itemToEdit.cmPayload : 'OFFLINE',
      wmPayload: this.isEditMode ? this.itemToEdit.wmPayload : 'DISCONNECTED',
      bmRetain:  this.isEditMode ? this.itemToEdit.bmRetain : true,
      cmRetain:  this.isEditMode ? this.itemToEdit.cmRetain : true,
      wmRetain:  this.isEditMode ? this.itemToEdit.wmRetain : true,
      bmQoS:     this.isEditMode && this.itemToEdit.bmQoS ? this.itemToEdit.bmQoS : 0,
      cmQoS:     this.isEditMode && this.itemToEdit.cmQoS ? this.itemToEdit.cmQoS : 0,
      wmQoS:     this.isEditMode && this.itemToEdit.wmQoS ? this.itemToEdit.wmQoS : 0,
    };

    if (this.isEditMode) {
      initialValues.name = this.itemToEdit.name;
      initialValues.host = this.itemToEdit.host;
      initialValues.username = this.itemToEdit.username;
      initialValues.password = this.itemToEdit.password;
      initialValues.bmTopic = this.itemToEdit.bmTopic;
      initialValues.cmTopic = this.itemToEdit.cmTopic;
      initialValues.wmTopic = this.itemToEdit.wmTopic;
    }

    this.props.initialize(initialValues);
    this.setState({ originalPasswordValue: initialValues.password });
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode &&
            this.props.pristine ||
            this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings')) ||
            this.state.isEditingPassword
          )
        }
      ]
    };
  }

  onSubmit = (data) => {
    let newData = {
      deviceAppProfileId: this.props.deviceApp.label,
      name: data.name,
      host:  data.host,
      port:  data.port,
      useSsl: data.useSsl ? data.useSsl : false,
      keepAliveTimeSeconds: data.keepAliveTimeSeconds,
      useCleanSession: data.useCleanSession,
      clientId: this.isEditMode ? data.clientId : this.props.deviceApp.label,
      username: data.username ? data.username : null,
      password: data.password ? data.password : null,

      bmTopic: data.bmTopic ? data.bmTopic : null,
      cmTopic: data.cmTopic ? data.cmTopic : null,
      wmTopic: data.wmTopic ? data.wmTopic : null,

      bmPayload: data.bmPayload ? data.bmPayload : null,
      cmPayload: data.cmPayload ? data.cmPayload : null,
      wmPayload: data.wmPayload ? data.wmPayload : null,

      bmRetain: data.bmRetain ? data.bmRetain : false,
      cmRetain: data.cmRetain ? data.cmRetain : false,
      wmRetain: data.wmRetain ? data.wmRetain : false,

      bmQoS: data.bmQoS ? data.bmQoS : 0,
      cmQoS: data.cmQoS ? data.cmQoS : 0,
      wmQoS: data.wmQoS ? data.wmQoS : 0,

      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,

    };

    if(this.isEditMode) {
      newData.id =  this.itemToEdit.id;
    }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onTabClick = (key) => {
    this.props.handleSubmit((formData) => {
      this.setState({selectedTab: key});
    })();
  };

  onQoSChangeCallback = (reduxName, newValue, oldValue ) => {
    //prevent clearing
    if ( !newValue && oldValue && oldValue.value) {
      this.props.change(reduxName, oldValue.value);
    }
  };

  parseComboValueOnly = () => {
    return (value, name) => {
      return ((value && value.value) ? value.value : 0);
    }
  };

  onPasswordFieldChange = (value) => {
    this.setState({ originalPasswordValue: value });
  }

  onPasswordFieldChangeMode = (isEditMode) => {
    this.setState({ isEditingPassword: isEditMode })
  }

  renderConnectionTab = () => {
    return (
      <Tabs.Tab className="connection-tab" eventKey="connectionTab" title={this.labels.get('tabs.connection')} animation={false}>

        <InputSection label={this.labels.get('brokerName') + '*'} htmlFor="name" className="inline left-side">
            <TextField id="name"
                       name="name"
                       maxLength={30}
                       validate={[Validation.required]}
                       placeholder={this.labels.get('brokerName.placeholder')}
            />
          </InputSection>

          <InputSection label={this.labels.get('brokerIp') + '*'} htmlFor="host" className="inline right-side">
            <TextField id="host"
                       name="host"
                       validate={[Validation.required , Validation.ipFormat]}
                       normalize={Normalize.numberAndDot()}
                       placeholder={this.labels.get('brokerIp.placeholder')}
            />
          </InputSection>

          <InputSection label={this.labels.get('port') + '*'} htmlFor="port" className="inline left-side">
            <TextField id="port"
                       name="port"
                       validate={[Validation.required]}
                       normalize={Normalize.number()}
                       maxLength={5}
                       placeholder={this.labels.get('port.placeholder')}
            />
          </InputSection>

          <InputSection label={this.labels.get('keepAliveTimeSeconds') + '*'} htmlFor="keepAliveTimeSeconds" className="inline right-side">
              <TextField id="keepAliveTimeSeconds"
                         name="keepAliveTimeSeconds"
                         validate={[Validation.required]}
                         normalize={Normalize.number()}
                         maxLength={4}
                         placeholder={this.labels.get('keepAliveTimeSeconds.placeholder')}
              />
          </InputSection>

          <InputSection  htmlFor="useSsl" className="inline left-side">
            <Checkbox name="useSsl" id="useSsl"  label={this.labels.get('useSsl')}/>
          </InputSection>

          <InputSection  htmlFor="useCleanSession" className="inline right-side">
            <Checkbox name="useCleanSession" id="useCleanSession" label={this.labels.get('useCleanSession')}/>
          </InputSection>

      </Tabs.Tab>
    )
  };

  renderSecurityTab = () => {
    return (
      <Tabs.Tab className="security-tab" eventKey="securityTab" title={this.labels.get('tabs.security')} animation={false}>

        <InputSection label={this.labels.get('userName')} htmlFor="username" className=''>
          <TextField id="username" name="username" maxLength={50} validate={this.maxLength10} placeholder={this.labels.get('userName.placeholder')}/>
        </InputSection>

        <InputSection label={this.labels.get('password')} htmlFor="password" className="">
          <EditPasswordField
            id="password"
            name="password"
            maxLength={50}
            autoComplete="new-password"
            placeholder={this.labels.get('password.placeholder')}
            enableReveal={true}
            change={this.props.change}
            touch={this.props.touch}
            onChange={this.onPasswordFieldChange}
            originalValue={this.state.originalPasswordValue}
            onChangeMode={this.onPasswordFieldChangeMode}
          />
        </InputSection>

      </Tabs.Tab>    )
  };

  renderMessagesTab = () => {
    return (
      <Tabs.Tab className="messages-tab" eventKey="messagesTab" title={this.labels.get('tabs.messages')} animation={false}>

        <div className="message-title">{this.labels.get('birthMessage')}</div>
        <div className="message-section">
          <InputSection label={this.labels.get('topic')} htmlFor="bmTopic" className="inline left-side">
            <TextAreaField id="bmTopic" name="bmTopic" maxLength='300' placeholder={this.labels.get('topic.placeholder')}/>
          </InputSection>
          <InputSection  htmlFor="bmRetain" className="inline right-side">
            <Checkbox name="bmRetain" id="bmRetain" label={this.labels.get('retain')}/>
          </InputSection>
        </div>

        <div className="message-section">
          <InputSection label={this.labels.get('payload')} htmlFor="bmPayload" className="inline left-side">
            <TextAreaField id="bmPayload" name="bmPayload" maxLength='300' placeholder={this.labels.get('payload.placeholder')}/>
          </InputSection>
          <InputSection  htmlFor="bmQoS" label={this.labels.get('qos')}   className="inline right-side">
            <Combobox name="bmQoS" id="bmQoS" options={qosOptions}
                      parse={this.parseComboValueOnly()}
                      format={Format.findOptionByValue(qosOptions)}
                      onChangeCallback={(newValue, oldValue) => this.onQoSChangeCallback('bmQoS', newValue, oldValue)}
            />
          </InputSection>
        </div>

        <div className="message-title">{this.labels.get('closeMessage')}</div>
        <div className="message-section">
          <InputSection label={this.labels.get('topic')} htmlFor="cmTopic" className="inline left-side">
            <TextAreaField id="cmTopic" name="cmTopic" maxLength='300' placeholder={this.labels.get('topic.placeholder')}/>
          </InputSection>
          <InputSection  htmlFor="cmRetain" className="inline right-side">
            <Checkbox name="cmRetain" id="cmRetain" label={this.labels.get('retain')}/>
          </InputSection>
        </div>

        <div className="message-section">
          <InputSection label={this.labels.get('payload')} htmlFor="cmPayload" className="inline left-side">
            <TextAreaField id="cmPayload" name="cmPayload" maxLength='300' placeholder={this.labels.get('payload.placeholder')}/>
          </InputSection>
          <InputSection  htmlFor="cmQoS" label={this.labels.get('qos')}   className="inline right-side">
            <Combobox name="cmQoS" id="cmQoS" options={qosOptions}
                      parse={this.parseComboValueOnly()}
                      format={Format.findOptionByValue(qosOptions)}
                      onChangeCallback={(newValue, oldValue) => this.onQoSChangeCallback('cmQoS', newValue, oldValue)}
            />
          </InputSection>
        </div>

        <div className="message-title">{this.labels.get('willMessage')}</div>
        <div className="message-section">
          <InputSection label={this.labels.get('topic')} htmlFor="wmTopic" className="inline left-side">
            <TextAreaField id="wmTopic" name="wmTopic" maxLength='300' placeholder={this.labels.get('topic.placeholder')}/>
          </InputSection>
          <InputSection  htmlFor="wmRetain" className="inline right-side">
            <Checkbox name="wmRetain" id="wmRetain" label={this.labels.get('retain')}/>
          </InputSection>
        </div>

        <div className="message-section">
          <InputSection label={this.labels.get('payload')} htmlFor="wmPayload" className="inline left-side">
            <TextAreaField id="wmPayload" name="wmPayload" maxLength='300' placeholder={this.labels.get('payload.placeholder')}/>
          </InputSection>
          <InputSection  htmlFor="wmQoS" label={this.labels.get('qos')}   className="inline right-side">
            <Combobox name="wmQoS" id="wmQoS" options={qosOptions}
                      parse={this.parseComboValueOnly()}
                      format={Format.findOptionByValue(qosOptions)}
                      onChangeCallback={(newValue, oldValue) => this.onQoSChangeCallback('wmQoS', newValue, oldValue)}
            />
          </InputSection>
        </div>

      </Tabs.Tab>
    )
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    let selectedTab = this.state.selectedTab;

    return (
      <Dialog
        id="create-mqtt-broker-dialog"
        className="create-mqtt-broker-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <Tabs activeKey={selectedTab} tabType="default" onSelect={this.onTabClick} id="content-tabs">
          {this.renderConnectionTab()}
          {this.renderSecurityTab()}
          {this.renderMessagesTab()}
        </Tabs>


      </Dialog>
    );
  }
}

CreateMqttBrokerDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'createMqttBrokerDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(CreateMqttBrokerDialog);




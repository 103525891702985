import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize  from 'infrastructure/js/components/controls/controlsNormalizations.js';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import Label from 'infrastructure/js/components/Label/label.js'
import MaterialHelper from '../../../../../../utils/materialHelper';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./createKitTypeMaterialListItem.scss');

export default class CreateKitTypeMaterialListItem extends React.PureComponent {

  constructor(props) {
    super(props);

  }

  onChangeCallback = (value, oldValue) => {

    if (value && oldValue && value.value === oldValue.value) {
      return;
    }

    this.props.change( this.props.name + '.[substituteMaterials]', null);

    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
  };


  onSubstituteChangeCallback = (value, oldValue) => {
    if (value && value.length > 3) {
      this.props.change( this.props.name + '.[substituteMaterials]', oldValue);
    }
  };

  getComponentToRender = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;
    let materialLabel = MaterialHelper.getMaterialFullLabel(option.data.materialName, option.data.businessId);

    return (<Label text={materialLabel} />);
  };


  renderWeightOrLength=(name)=>{
    return PermissionManager.isWeightSupported() ?
      (<TextField id={ name + '.[weight]'}
               name={ name + '.[weight]'}
               className="short-textfield"
               normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.WEIGHT))}/>) :

      (<TextField id={ name + '.[length]'}
                 name={ name + '.[length]'}
                 className="short-textfield"
                 normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.LENGTH))}/>)
  };

  render() {
    let {options, name, substituteOptions} = this.props;

    return (<div className="material-list-item">
      <Combobox id={ name + '.[material]'}
                name={ name + '.[material]'}
                singleValueRenderer={this.getComponentToRender}
                optionRenderer={this.getComponentToRender}
                validate={Validation.dropdown.required}
                options={options}
                parse={Parse.comboValueOnly()}
                format={Format.findOptionByValue(options)}
                onChangeCallback={this.onChangeCallback}
                className=""
      />
      <PL_MultiSelectField id={ name + '.[substituteMaterials]'}
                           name={ name + '.[substituteMaterials]'}
                           className="substitute-materials"
                           options={substituteOptions}
                           optionRenderer={this.getComponentToRender}
                           closeMenuOnSelect={false}
                           onChangeCallback={this.onSubstituteChangeCallback}

      />
      <TextField id={ name + '.[quantity]'}
                 name={ name + '.[quantity]'}
                 className="short-textfield"
                 normalize={Normalize.number(true, 0, 99999)}
                 validate={Validation.required}
      />

      {this.renderWeightOrLength(name)}





      </div>);
  }
}

CreateKitTypeMaterialListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};





import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header, { headerItemsLabels } from '../../../Common/Header/header';
import { gridsNames } from '../../../../enums/gridsNames';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';
import { ImportTypes, ImportAcceptFileTypes } from '../../../../enums/importTypes';
import KitsGrid from './KitsGrid/kitsGrid';
import { getEnumValue, enumTypes } from '../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {isAllAssetsUncured} from '../../../../utils/assetsHelper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {getLocationAssetTypes, getLocationAssetTypesForPrintPdfLabels} from '../../../../utils/locationHelper';

require('./kitsView.scss');

export default class KitsView extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.assets.');
  }

  getDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('KIT')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  getUpdateShelfLifeConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.ON_LOCATION,
        FetchEntitiesFilters.NOT_CURED
      ],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('KIT')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  getMoveAssetsDialogConfig = () => {
    let {sSelectedRowsData, sLocationDetails} = this.props;
    return {
      boEntityFilter: [
        FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.NOT_CONTAINED,
        FetchEntitiesFilters.ON_LOCATION,
      ],
      entitiesTypes: sSelectedRowsData?.size > 0 ? [getEnumValue(enumTypes.OBJECT_TYPE)('KIT')] :
        getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('KIT')),
      preselectedAssets: sSelectedRowsData,
    };
  };

  getBringAssetsDialogConfig = () => {
    let {sLocationDetails} = this.props;
    return {
      boEntityFilter: [
        FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.EXCEPT_LOCATION,
        FetchEntitiesFilters.NOT_CONTAINED,
      ],
      entitiesTypes: getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('KIT')),
    };
  };

  getImportConfig = () => {
    return {
      importTypes: [ImportTypes.IMPORT_KITS],
      acceptFileTypes: [ImportAcceptFileTypes.CSV, ImportAcceptFileTypes.JSON],
      parentComponent: this.onRefreshAll,
      shouldIncludeLocation: false, //import is done from a location , the import file locationId column is optional.
      showPrintLabels: false,
    };
  };

  getPrintPdfLabelsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ALL],
      preselectedAssets: this.props.sSelectedRowsData,
      entitiesTypes: getLocationAssetTypesForPrintPdfLabels(this.props.sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('KIT')),
    };
  };

  getAddToGroupDialogConfig = () => {
    let {sSelectedRowsData, sLocationDetails} = this.props;
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      preselectedAssets: this.props.sSelectedRowsData,
      entitiesTypes: sSelectedRowsData?.size > 0 ? [getEnumValue(enumTypes.OBJECT_TYPE)('KIT')] :
        getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('KIT')),
    };
  };

  getEditLengthDialogConfig = () => {
    let payload = this.getDialogConfig();
    payload.editLengthOnly = true;
    return payload;
  };

  onRefreshAll = () => {
    this.props.actions?.reloadParentComponent();
  };

  getHeaderItems() {
    let { actions, sHasRows, sSelectedRowsData, sLocationDetails, loading } = this.props;

    let dialogConfig = this.getDialogConfig();
    let importConfig = this.getImportConfig();
    let isLocationActive = sLocationDetails?.isActive;

    let items = {
      title: this.labels.get('title.kits'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          actionData: {gridName: gridsNames.LOCATION_ASSET_KITS}
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.onRefreshAll,
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: loading || !isLocationActive,
        },
        {
          id: 'moveTo',
          label: headerItemsLabels.MOVE_TO,
          icon: 'pl pl-move-assets',
          counter: sSelectedRowsData.size,
          action: actions.headerActions.onMenuItemMoveToClick,
          actionData: this.getMoveAssetsDialogConfig(),
          disabled: loading || !isLocationActive,
        },
        {
          id: 'bringHere',
          label: headerItemsLabels.BRING_HERE,
          icon: 'pl pl-bring-assets',
          action: actions.headerActions.onMenuItemBringHereClick,
          actionData: this.getBringAssetsDialogConfig(),
          disabled: loading || !isLocationActive,
        },
      ],

      kebab: {
        disabled: loading || !isLocationActive,
        menuItems: [
          ...(PermissionManager.hasUpdateShelfLifePermissions()
            ? [
                {
                  id: 'updateShelfLife',
                  label: headerItemsLabels.UPDATE_SELF_LIFE,
                  disabled: !sHasRows || !isAllAssetsUncured(sSelectedRowsData),
                  action: actions.headerActions.onUpdateShelfLifeClick,
                  actionData: this.getUpdateShelfLifeConfig(),
                },
              ]
            : []),

          ...(PermissionManager.hasArchiveAssetsPermissions()
            ? [
                {
                  id: 'archive',
                  label: headerItemsLabels.ARCHIVE,
                  action: actions.headerActions.onMenuItemArchiveClick,
                  actionData: dialogConfig,
                  disabled: !sHasRows,
                },
              ]
            : []),

          {
            id: 'export',
            label: headerItemsLabels.EXPORT_KITS_LIST,
            action: actions.headerActions.export,
            actionData: { gridName: gridsNames.LOCATION_ASSET_KITS },
            disabled: !sHasRows,
          },
          {
            id: 'exportReport',
            label: headerItemsLabels.EXPORT_KIT_REPORT,
            action: actions.headerActions.onExportKitReportClick,
            actionData: sSelectedRowsData,
            disabled: !sHasRows || !sSelectedRowsData || sSelectedRowsData.size === 0,
          },

          ...(PermissionManager.hasGroupAssetPermissions() &&
          PermissionManager.getOrgPreferences().groupsEnabled
            ? [
                {
                  id: 'addToGroup',
                  label: headerItemsLabels.ADD_TO_GROUP,
                  disabled: !sHasRows,
                  action: actions.headerActions.onAddToGroupDialogClick,
                  actionData: this.getAddToGroupDialogConfig(),
                },
              ]
            : []),

          ...(PermissionManager.hasEditAttributesPermissions()
            ? [
                {
                  id: 'editAttributes',
                  label: headerItemsLabels.EDIT_ATTRIBUTES,
                  disabled: !sHasRows,
                  action: actions.headerActions.onMenuItemEditAssetAttributesClick,
                  actionData: dialogConfig,
                },
              ]
            : []),

          ...(PermissionManager.hasImportAssetsPermissions()
            ? [
                {
                  id: 'importKits',
                  label: headerItemsLabels.IMPORT_KITS,
                  action: actions.headerActions.importItems,
                  actionData: importConfig,
                  disabled: PermissionManager.isWeightSupported()
                },
              ]
            : []),
          {
            id: 'printRfidTags',
            label: headerItemsLabels.PRINT_RFID_TAGS,
            action: actions.headerActions.onMenuItemPrintRfidTagsClick,
            actionData: sSelectedRowsData,
            disabled: !sHasRows || !sSelectedRowsData || sSelectedRowsData.size !== 1,
          },

          ...(PermissionManager.getOrgPreferences().printZplGenericAssetEnabled
            ? [
                {
                  id: 'printEmptyTags',
                  label: headerItemsLabels.PRINT_EMPTY_TAGS,
                  action: actions.headerActions.onMenuItemPrintEmptyRfidTagsClick,
                },
              ]
            : []),

          {
            id: 'printPdfLabels',
            label: headerItemsLabels.PRINT_LABEL,
            action: actions.headerActions.onMenuItemPrintLabelClick,
            actionData: this.getPrintPdfLabelsDialogConfig(),
          },
        ],
      },
    };

    return items;
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="kits-view">
        <Header {...headerItems} />

        <KitsGrid actions={{...this.props.actions.assetsKitsGridActions, ...this.props.actions.fetchEntitiesActions}}
          locationDetails={this.props.sLocationDetails}
          cellAction={this.props.actions.getAssetRelatedAssets}
        />
      </div>
    );
  }
}

KitsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
  sLocationDetails: PropTypes.object.isRequired,
};

import React from 'react';
import cn from 'classnames';
import Label from 'infrastructure/js/components/Label/label.js';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';

require('./multiRowCell.scss');

export default class MultiRowCell extends React.PureComponent {

  renderErrorIcon = () => {
    if (this.props.value?.errorMessage) {
      return (<AlertPopover
        popoverType={'error'}
        popoverComponent={<span>{this.props.value?.errorMessage}</span>}
      />);
    }
    return null;
  };

  renderErrorMessage = () => {
    if (this.props.value?.errorMessage && this.props.value?.showOnlyError) {
      return (
        <React.Fragment>
          <Label text={this.props.value.errorMessage} className="error-message"/>
          <span className="empty-message"/>
        </React.Fragment>
      );
    }
    return null;
  }

  renderRow = () => {
    if (!(this.props.value?.errorMessage && this.props.value?.showOnlyError)) {
      return (
        <React.Fragment>
          <Label text={this.props.value?.value?.toString() || ''} className="main-message"/>
          {this.renderAdditionalField()}
        </React.Fragment>
      )
    }
    return null;
  }

  renderAdditionalField = () => {
    if (this.props.data?.additionalField) {
      return (<Label text={this.props.data?.additionalField} className="secondary-message"/>)
    }
    return ( <span className="empty-message"/>)
  }

  render() {
    return (
      <div className={cn('multi-row-cell-container', this.props.value?.className)}>
        {this.renderErrorIcon()}
        {this.renderErrorMessage()}
        {this.renderRow()}
      </div>
    );
  }
}


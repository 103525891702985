import React from 'react';
import PropTypes from 'prop-types';

import {List} from 'immutable';
import EntitiesMultiSelect from '../../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import {FetchEntitiesFilters} from '../../../../../../enums/fetchEntitiesFilters';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./assetsToAssetsSection.scss');


export default class AssetsToAssetsSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;

    this.hasPreSelectedWo = !!this.props.workOrder;

    this.usingFetchConfig = {
      action: this.props.actions.fetchEntities,
      // filter: BO_EntityFilters.NO_ATTACHED_ASSET,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NO_ATTACHED_ASSET],
    };

    this.entitiesTypes = this.props.entitiesTypes;

    this.state = {
      addCurrentType: this.entitiesTypes[0],
      toCurrentType: this.entitiesTypes[0],
      addKey: 0,
      toKey: 0,
      usingKey: 0,
      usingAssetId: null,
      addCurrentPreselectedAssets: List(),
    };
  }

  getReduxFieldName = (fieldName) => {
    return this.props.name + '.' + fieldName;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.workOrder !== this.props.workOrder) {
      this.props.change(this.getReduxFieldName('add-asset-selection') + '.assetsValue', null);
      this.props.change(this.getReduxFieldName('to-asset-selection') + '.assetsValue', null);
      this.props.change(this.getReduxFieldName('using-asset-selection') + '.assetsValue', null);
      this.setState({
        toKey: this.state.toKey === 0 ? 1 : 0,
        addKey: this.state.addKey === 0 ? 1 : 0,
        usingKey: this.state.usingKey === 0 ? 1 : 0,
      });
    }
    if (nextProps.preselectedAssets !== this.props.preselectedAssets) {
      let filteredPreselectedAssets = nextProps.preselectedAssets.filter((asset) => {
        return asset.objectType === this.state.addCurrentType;
      });
      this.setState({addCurrentPreselectedAssets: filteredPreselectedAssets});
    }
  }

  onAddTypeChangeCallback = (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }

    let newState = {};

    if (newValue === getEnumValue(enumTypes.OBJECT_TYPE)('KIT') && this.state.toCurrentType !== getEnumValue(enumTypes.OBJECT_TYPE)('KIT')) {
      this.props.change(this.getReduxFieldName('to-asset-selection'), null);
      this.props.change(this.getReduxFieldName('using-asset-selection') + '.assetsValue', null);
      this.setState({
        addCurrentType: newValue,
        toCurrentType: getEnumValue(enumTypes.OBJECT_TYPE)('KIT'),
        toKey: this.state.toKey === 0 ? 1 : 0,
        usingAssetId: null,
        usingKey: this.state.usingKey === 0 ? 1 : 0,
      });
      return;
    }

    this.setState({addCurrentType: newValue});
  };

  onToTypeChangeCallback = (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }

    let newState = {};

    if (newValue === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL') && this.state.addCurrentType !== getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')) {
      this.props.change(this.getReduxFieldName('add-asset-selection'), {});
      newState.addCurrentType = getEnumValue(enumTypes.OBJECT_TYPE)('ROLL');
      newState.addKey = (this.state.addKey === 0 ? 1 : 0);
    }
    if (this.state.usingAssetId !== null) {
      newState.usingAssetId = null;
      newState.usingKey = this.state.usingKey === 0 ? 1 : 0;
      this.props.change(this.getReduxFieldName('using-asset-selection') + '.assetsValue', null);
    }

    newState.toCurrentType = newValue;
    this.setState(newState);
  };

  onToValueChangeCallback = (value) => {
    let attachedAssetId =
      (value && value.data && value.data.attachedAsset && value.data.attachedAsset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')) ?
       value.data.attachedAsset.id : null;

    if (this.state.usingAssetId !== attachedAssetId) {

      let newUsingAsset = null;
      if (attachedAssetId) {
        newUsingAsset = {
                  value: attachedAssetId,
                  label: value.data.attachedAsset.businessId,
                  data: {
                    businessId: value.data.attachedAsset.businessId,
                    toolType: {businessId: value.data.attachedAsset.toolType.businessId}
                  }
                }
      }
      this.props.change(this.getReduxFieldName('using-asset-selection') + '.assetsValue', newUsingAsset);

      this.setState({
        usingAssetId: attachedAssetId,
        usingKey: this.state.usingKey === 0 ? 1 : 0
      });
    }
  };

  getPreselectedTool = () => {
    if(this.props.preselectedTool){
      return List([{
        ...this.props.preselectedTool,
        objectType: getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')
      }]);
    }

    return List([]);
  }

  render = () => {
    let {name, entitiesTypes, disableMarkAsConsumed, itemIndex, ...otherProps} = this.props;
    let focusProps = (itemIndex === 0 && this.hasPreSelectedWo) ? {autoFocus: true} : {};

    return <div className="assets-to-asset-section">
      <div className="add-to-asset-container">
        <div className="add-to-asset-section">
          <label className="add-to-asset-label">{this.labels.get("add")}</label>

          <EntitiesMultiSelect
            key={this.state.addKey}
            id={this.getReduxFieldName('add-asset-selection')}
            name={this.getReduxFieldName('add-asset-selection')}
            className="add-to-asset-selection"
            entitiesTypes={this.entitiesTypes}
            selectedEntitiesType={this.state.addCurrentType}
            preSelectedEntities={this.state.addCurrentPreselectedAssets}
            validate={Validation.required}
            onTypeChangeCallback={this.onAddTypeChangeCallback}
            dropdownMenuClassName='multi-select-field-workorder-dialog'
            {...focusProps}
            {...otherProps}
          />

          <InputSection htmlFor={this.getReduxFieldName('markAsConsumed')} className="mark-as-consumed-section no-margin">
            <Checkbox
                      name={this.getReduxFieldName('markAsConsumed')}
                      id={this.getReduxFieldName('markAsConsumed')}
                      disabled={disableMarkAsConsumed}
                      label={this.labels.get('markasconsumed')} />
          </InputSection>
        </div>

        <label className="add-to-asset-arrow">
          <i className="pl pl-move-arrow-right"/>
        </label>

        <div className="add-to-asset-section">
          <label className="add-to-asset-label">{this.labels.get("to")}</label>
          <EntitiesMultiSelect
            key={this.state.toKey}
            id={this.getReduxFieldName('to-asset-selection')}
            name={this.getReduxFieldName('to-asset-selection')}
            className="assets-to-asset-selection"
            entitiesTypes={this.entitiesTypes}
            selectedEntitiesType={this.state.toCurrentType}
            validate={Validation.required}
            onTypeChangeCallback={this.onToTypeChangeCallback}
            onValueChangeCallback={this.onToValueChangeCallback}
            dropdownMenuAttachment='right'
            dropdownMenuClassName='multi-select-field-workorder-dialog'
            /*allowNewOption={this.props.operationType == 'LAYUP' && PermissionManager.isPartTypeOriented()}*/
            {...otherProps}
            isMulti={false}
          />

          <InputSection label={this.labels.get('using')} className="using-asset-section" >

            <EntitiesMultiSelect id={this.getReduxFieldName('using-asset-selection')}
                                 name={this.getReduxFieldName('using-asset-selection')}
                                 key={this.state.usingKey}
                                 className="using-asset-selection"
                                 entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')]}
                                 disableTypeChange={true}
                                 {...otherProps}
                                 fetchConfig={this.usingFetchConfig}
                                 isMulti={false}
                                 isDisabled={!!this.state.usingAssetId}
                                 preSelectedEntities={this.getPreselectedTool()}
            />
          </InputSection>
        </div>

      </div>

    </div>
  };

}

AssetsToAssetsSection.defaultProps = {
  entitiesTypes: ['ROLL'],
  disableMarkAsConsumed: false,
};

AssetsToAssetsSection.propTypes = {
  value : PropTypes.object,
  entitiesTypes : PropTypes.array,
  disableMarkAsConsumed : PropTypes.bool,
  preselectedTool: PropTypes.object
};





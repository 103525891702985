import { connect } from 'react-redux';
import { Component } from 'react';
import ScanAssetPageContainer from './containers/Mobile/ScanAssetPage/scanAssetPageContainer.js';
import ScanToolPageContainer from './containers/Mobile/ScanToolPage/scanToolPageContainer.js';
import ScanWorkOrderPageContainer from './containers/Mobile/ScanWorkOrderPage/scanWorkOrderPageContainer.js';
import ScannedAssetPageContainer from './containers/Mobile/ScannedAssetPage/scannedAssetPageContainer.js';
import ScannedToolPageContainer from './containers/Mobile/ScannedToolPage/scannedToolPageContainer.js';
import ChangeLocationPageContainer from './containers/Mobile/ChangeLocationPage/changeLocationPageContainer.js';
import ChangeLocationResultPageContainer from './containers/Mobile/ChangeLocationResultPage/changeLocationResultPageContainer.js';
import ScannedAssetsListPageContainer from './containers/Mobile/ScannedAssetsListPage/scannedAssetsListPageContainer.js';
import PageNotFoundPageContainer from './containers/PageNotFoundPage/pageNotFoundPageContainer.js';

import TaskPage from './components/Mobile/TaskPage/taskPage.js';
import ScannedWorkOrderTasksPage from './components/Mobile/ScannedWorkOrderTasksPage/scannedWorkOrderTasksPage.js';
import MainPage from './components/Mobile/MainPage/mainPage.js';
import { Route, Switch } from 'react-router-dom';

require('styles/app.scss');

class MobileApp extends Component {
  getRoutes() {
    let routesArr;
    //todo: Need to Rewrite featureFlags logic.
    let { featureFlags } = this.props;
    if (featureFlags.ALL) {
      routesArr = [
        <Route key="mainPageKey" exact path="/" component={MainPage} />,
        <Route key="mainPageKey2" exact path="/Mobile" component={MainPage} />,
        <Route key="scanAssetPageRouteKey" path="/ScanAsset" component={ScanAssetPageContainer} />,
        <Route key="scanAssetPageRouteKey" path="/ScanTool" component={ScanToolPageContainer} />,
        <Route key="scanWorkOrderPageRouteKey" path="/scanWorkOrder" component={ScanWorkOrderPageContainer} />,
        <Route key="scannedWorkOrderPageRouteKey" path="/ScannedWorkorderTasks" component={ScannedWorkOrderTasksPage} />,
        <Route key="scannedAssetPageRouteKey" path="/Mobile/asset/:assetId/" component={ScannedAssetPageContainer} />,
        <Route key="scannedAssetPageRouteKey" path="/Mobile/tool/:assetId/" component={ScannedToolPageContainer} />,
        <Route key="TaskDetailsPageRouteKey" path="/Mobile/task/:taskId/" component={TaskPage} />,
        <Route key="changeLocationPageRouteKey" path="/ChangeLocation" component={ChangeLocationPageContainer} />,
        <Route key="changeLocationResultPageRouteKey" path="/ChangeLocationResult" component={ChangeLocationResultPageContainer} />,
        <Route key="scannedAssetsListPageRouteKey" path="/ScannedAssetsList" component={ScannedAssetsListPageContainer} />,
        <Route key="PageNotFoundPageRouteKey" path="/PageNotFound" component={PageNotFoundPageContainer} />,
      ];
    }

    routesArr.push(<Route key="PageNotFoundPageRouteKey2" component={PageNotFoundPageContainer} />);

    return routesArr;
  }
  render() {
    return (
      <div className="app-container">
        <div className="app-container-content">
          <Switch>{this.getRoutes()}</Switch>
        </div>
      </div>
    );
  }
}

export default MobileApp = connect((state) => {
  return {
    featureFlags: state.system.get('featureFlags'),
    sLoggedInUser: state.login.get('loggedInUser'),
  };
}, undefined)(MobileApp);

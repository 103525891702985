import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as groupTypesViewActions} from '../../../actions/Administration/MatSettingsPage/groupTypesViewActions.js'
import { jsxActions as groupTypesViewGridActions} from '../../../actions/Administration/MatSettingsPage/groupTypesViewGridActions';
import { jsxActions as createGroupTypeDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createGroupTypeDialogActions';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import GroupTypesView from '../../../components/Administration/MatSettingsPage/GroupTypesView/groupTypesView.js';


function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(groupTypesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      groupTypesViewGridActions: bindActionCreators(groupTypesViewGridActions, dispatch),
      createGroupTypeDialogActions : bindActionCreators(createGroupTypeDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }

  }
}

let GroupTypesViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_GROUP_TYPES) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_GROUP_TYPES) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateGroupTypeDialogData: state.administration.getIn(['matSettingsPage', 'createGroupTypeDialog']),
    };
  },
  mapDispatchToProps
)(GroupTypesView);

export default GroupTypesViewContainer;


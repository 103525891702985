import { Map } from 'immutable';
import { actionTypes as ActionsType } from '../../../actions/Wizards/ImportAttributesUpdateWizard/importAttributesUpdateWizardActions';


let defaultState = {
  loading: false,
  show: false,
  importType: '',
  csvSettingsData: null,
  selectedFile: null,
  validatedFileData: null,
  attributesPreviewData: null,
  errors: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_SHOW:
      return state.set('importType', action.payload.importType)
                  .set('show', true)

    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_HIDE:
      return Map(defaultState);

    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_SET_BUSY:
      return state.set('loading', action.payload);

    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_UPLOAD_FILE_FINISHED:
      return state.set('fileToken', action.payload.token);

    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_GET_SCV_SETTINGS_FINISHED:
      return state.set('csvSettingsData', action.payload);

    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_SELECTED_FILE_CHANGED:
      return state.set('selectedFile', action.payload)
                  .set('csvSettingsData', null)
                  .set('attributesConfigData', null)
                  .set('attributesPreviewData', null)
                  .set('errors', null);


    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_VALIDATE_FILE_FINISHED:
     return state.set('validatedFileData', action.payload.data);

    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_HAS_ERRORS:
      return state.set('errors', action.payload);

    case ActionsType.IMPORT_ATTRIBUTES_UPDATE_WIZARD_GET_ATTRIBUTES_PREVIEW_FINISHED:
      return state.set('attributesPreviewData', action.payload);

    default:
      return state;
  }
}













import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import {jsxActions as commonAlertsViewActions} from '../../../../actions/Administration/SettingsPage/commonAlertsViewActions';
import { jsxActions as headerSettingsActions} from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions.js';
import ReservedView from '../../../../components/Administration/SettingsPage/Alerts/ReservedView/reservedView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonAlertsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch)
    }
  }
}

let ReservedViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonAlertsView']),
      isDirty: isDirty('RollTabForm')(state) || isDirty('spoolTabForm')(state)
    };
  },

  mapDispatchToProps
)(ReservedView);

export default ReservedViewContainer;




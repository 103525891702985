import React from 'react';

import ImportFilesCommonGrid from '../ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';

export default class ImportShipmentDocumentGrid extends React.PureComponent {

  render() {
    return  <ImportFilesCommonGrid  rows={this.props.rows} assetType={ImportTypes.IMPORT_SHIPMENTS} gridName={gridsNames.IMPORT_SHIPMENT_DOCUMENT}/>;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Label from 'infrastructure/js/components/Label/label.js';

import './afpPin.scss';

const AfpPin = (props) => {

  const onClickHandler = (e) => {
    props.onClickCallback?.(props.pinNumber)
  }
  const onClearHandler = (e) => {
    e.stopPropagation();
    props.onClearCallback?.(props.pinNumber)
  }

  return (
    // <div className={cn('afp-pin', {selected: props.selected}, props.className || '')} key={i} onClick={onClickHandler}>
    <div className={cn('afp-pin', {selected: props.selected}, props.className || '')}
         onClick={onClickHandler}>
      <div className='afp-pin-number'>{'#' + props.pinNumber}</div>
      <Label className='afp-pin-asset' text={props.value} />
      <span className={cn('pl pl-x-input-close', {'has-asset' : !!props.value})}
            onClick={onClearHandler}/>
    </div>
  )
};

AfpPin.defaultProps = {
  className: '',
  selected: false,
  pinNumber: 0,
  value: '',
};

AfpPin.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  pinNumber: PropTypes.number,
  value: PropTypes.string,
  onClickCallback: PropTypes.func,
  onClearCallback: PropTypes.func,
};

export default AfpPin;


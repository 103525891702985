import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import GenerateButton from 'infrastructure/js/components/controls/GenerateButton/generateButton';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import { DefaultWorkOrderTypes } from '../../../../../utils/workOrderHelper';
import Label from 'infrastructure/js/components/Label/label.js';
import NewKitMaterialListItem from '../../../../Common/Controls/AddRemoveList/AddRemoveListItem/NewKitMaterialListItem/newKitMaterialListItem';
import NewKitTypeMaterialListItem from '../../../../Common/Controls/AddRemoveListExt/AddRemoveListItem/NewKitTypeMaterialListItem/newKitTypeMaterialListItem';
import MaterialHelper from '../../../../../utils/materialHelper';
import AddRemoveListExt from '../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import AdditionalField from '../../../../Common/Layout/AdditionalField/AdditionalField';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import SingleLineMultiSelect from 'infrastructure/js/components/controls/SingleLineMultiSelect/singleLineMultiSelect.js';

require('./createKitForm.scss');

export default class CreateKitForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.kitting.createkitwizard.');

    this.kitTypeCreationOnTheFlyEnabled =
      !!PermissionManager.getOrgPreferences().kitTypeCreationOnTheFlyEnabled;

    this.initialData = this.props.sData.get('initialData');
    this.currentCutMaterial = this.props.currentCut.material;

    this.state = {
      key: 0,
      curWoId: null,
      curKitTypeId: null,
      isKitTypeDisabled: false,
      isWorkOrderDisabled: false,
      isNewKitType: false,
      curProjectId: null,
      curPartTypeId: null,
      curKitTypesOptions: this.initialData?.kitTypes || [],
      curKitTypeMaterials: [],
      substitutableOptions: [],
    };
  }

  componentDidMount() {
    const preselectedWorkOrder = this.props.preselctedWorkOrder;
    if(preselectedWorkOrder){
      this.props.change(this.getKitPropReduxName('workOrder'), preselectedWorkOrder);
      this.setState({isWorkOrderDisabled: true});
    }

    const selectedCut = this.props.sData.get('selectedCut');
    if(selectedCut){
      this.initialData = this.props.sData.get('initialData');
      this.currentCutMaterial = selectedCut?.material;
      this.onWorkOrderOnChangeHandler(preselectedWorkOrder);
    }
  }

  componentDidUpdate(prevProps) {
    const prevSelectedCut = prevProps.sData.get('selectedCut');
    const currentSelectedCut = this.props.sData.get('selectedCut');
    const isCutSelectAllowed = this.props.sData.get('startParameters')?.allowCutSelection;

    if(isCutSelectAllowed && prevSelectedCut?.id !== currentSelectedCut?.id){
      this.initialData = this.props.sData.get('initialData');
      this.currentCutMaterial = currentSelectedCut?.material;
      this.onWorkOrderOnChangeHandler(this.props.preselctedWorkOrder)
    }
  }

  getKitPropReduxName(propName) {
    let { name } = this.props;
    return name + '.' + propName;
  }

  getNewKitTypeAvailableMaterials = (allMaterials, cutId) => {
    if (allMaterials && cutId) {
      let res = allMaterials.filter((m) => {
        return m.data.id !== cutId;
      });
      return res;
    }
    return [];
  };

  getOptionById = (options, id) => {
    let found = options.find((op) => {
      return op && op.value === id;
    });
    return found ? found : null;
  };

  onWorkOrderOnChangeHandler = (newValue, oldValue) => {
    if (!newValue && !oldValue) {
      return;
    }
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    let selectedWo = newValue && newValue.data ? newValue.data : null;

    let curWoId = selectedWo ? selectedWo.id : null;

    if (
      selectedWo &&
      selectedWo.workOrderType === DefaultWorkOrderTypes.KITTYPE_ORIENTED &&
      selectedWo.kitType
    ) {
      let kitTypeOption = this.getOptionById(this.initialData.kitTypes, selectedWo.kitType.id);
      this.props.change(this.getKitPropReduxName('kitType'), kitTypeOption);

      this.setState({ curWoId, isKitTypeDisabled: true }, () => {
        this.handleKitTypeOnChange(kitTypeOption, null);
      });
      return;
    }

    let curProjectId = selectedWo && selectedWo.project ? selectedWo.project.id : null;
    let projectOption = curProjectId
      ? this.getOptionById(this.initialData.projects, curProjectId)
      : null;
    this.props.change(this.getKitPropReduxName('projectId'), projectOption);

    let curPartTypeId = selectedWo && selectedWo.partType ? selectedWo.partType.id : null;
    let partOption = curPartTypeId
      ? this.getOptionById(this.initialData.parts, curPartTypeId)
      : null;
    this.props.change(this.getKitPropReduxName('partId'), partOption);

    let curKitTypeId = null;
    this.props.change(this.getKitPropReduxName('kitType'), null);

    let curKitTypesOptions = this.initialData.kitTypes.filter((kt) => {
      // Filter out the KitTypes options that don't match either WO's project or WO's partType
      return !(
        (curProjectId && curProjectId !== kt.data.project.id) ||
        (curPartTypeId && curPartTypeId !== kt.data.partType.id)
      );
    });

    this.setState({
      curWoId,
      isKitTypeDisabled: false,
      curKitTypeId,
      isNewKitType: false,
      curProjectId,
      curPartTypeId,
      curKitTypesOptions,
    });
  };

  handleKitTypeOnChange = (newValue, oldValue) => {
    if (!newValue && !oldValue) {
      return;
    }
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    let isNewKitType = newValue && newValue.value === newValue.label;
    if (isNewKitType) {
      this.handleNewKitType(newValue);
      return;
    }

    let selectedKT = newValue && newValue.data ? newValue.data : null;

    if (!selectedKT) {
      //unselect KitType
      let woOption = this.state.curWoId
        ? this.getOptionById(this.initialData.workorders, this.state.curWoId)
        : null;
      this.onWorkOrderOnChangeHandler(woOption, null);
      return;
    }

    let curKitTypeId = selectedKT ? selectedKT.id : null;
    let kitTypeMaterials = selectedKT ? selectedKT.kitTypeMaterials : [];
    let curKitTypeMaterials = this.convertKitTypeMaterials(kitTypeMaterials);
    this.props.change(this.getKitPropReduxName('materials'), curKitTypeMaterials);

    let curProjectId = selectedKT && selectedKT.project ? selectedKT.project.id : null;
    let projectOption = selectedKT
      ? this.getOptionById(this.initialData.projects, curProjectId)
      : null;
    this.props.change(this.getKitPropReduxName('projectId'), projectOption);

    let curPartTypeId = selectedKT && selectedKT.partType ? selectedKT.partType.id : null;
    let partOption = curPartTypeId
      ? this.getOptionById(this.initialData.parts, curPartTypeId)
      : null;
    this.props.change(this.getKitPropReduxName('partId'), partOption);

    let substitutableOptions = this.getSubstitutableMaterialsOptions(kitTypeMaterials);

    this.setState({
      curKitTypeId,
      curProjectId,
      curPartTypeId,
      substitutableOptions,
      curKitTypeMaterials,
      isNewKitType: false,
    });
  };

  createDefaultMaterial = () => {
    let material = this.currentCutMaterial;
    let materialId = material.id ? material.id : '';
    let materialLabel =
      material.materialName || material.businessId ? this.getMaterialLabel(material) : '';

    let requiredMaterial = {
      value: materialId,
      label: materialLabel,
      data: {
        mainMaterialId: materialId,
        mainMaterialLabel: materialLabel,
        substituteMaterialId: null,
      },
    };

    return [
      {
        requiredMaterial: requiredMaterial,
        quantityExpectedPlies: null,
        quantityKittedPlies: null,
        isDefaultMaterial: true,
        amount: this.props.remainingAmount
      },
    ];
  };

  handleNewKitType = (newValue) => {
    let selectedWo = this.state.curWoId
      ? this.getOptionById(this.initialData.workorders, this.state.curWoId)
      : null;

    let curKitTypeId = newValue.value;

    // let curKitTypeMaterials = this.convertKitTypeMaterials([this.currentCutMaterial]);
    let curKitTypeMaterials = this.createDefaultMaterial();
    this.props.change(this.getKitPropReduxName('materials'), curKitTypeMaterials);

    let curProjectId =
      selectedWo && selectedWo.data && selectedWo.data.project ? selectedWo.data.project.id : null;
    let projectOption = curProjectId
      ? this.getOptionById(this.initialData.projects, curProjectId)
      : null;
    this.props.change(this.getKitPropReduxName('projectId'), projectOption);

    let curPartTypeId =
      selectedWo && selectedWo.data && selectedWo.data.partType
        ? selectedWo.data.partType.id
        : null;
    let partOption = curPartTypeId
      ? this.getOptionById(this.initialData.parts, curPartTypeId)
      : null;
    this.props.change(this.getKitPropReduxName('partId'), partOption);

    this.setState({
      curKitTypeId,
      curProjectId,
      curPartTypeId,
      isNewKitType: true,
      curKitTypeMaterials,
    });
  };

  isDefaultMaterial = (material) => {
    return material && material.id === this.currentCutMaterial.id;
  };

  convertKitTypeMaterials = (materials) => {
    if (materials) {
      let res = materials.map((item) => {
        let material = item.material;
        let materialId = material.id ? material.id : '';
        let materialLabel =
          material.materialName || material.businessId ? this.getMaterialLabel(material) : '';

        let requiredMaterial = {
          value: materialId,
          label: materialLabel,
          data: {
            mainMaterialId: materialId,
            mainMaterialLabel: materialLabel,
            substituteMaterialId: null,
          },
        };

        let amount = null;
        if (this.isDefaultMaterial(material)) {
          amount = this.props.remainingAmount;
        }

        return {
          requiredMaterial: requiredMaterial,
          quantityExpectedPlies: item.quantityExpectedPlies,
          quantityKittedPlies: this.isDefaultMaterial(material) ? item.quantityExpectedPlies : null,
          amount,
          isDefaultMaterial: this.isDefaultMaterial(material),
        };
      });
      return res;
    }
    return [];
  };

  getSubstitutableMaterialsOptions = (materials) => {
    if (materials) {
      let substitutableMaterialsOptions = [];

      materials.forEach((item) => {
        if (item.substituteMaterials && item.substituteMaterials.length > 0) {
          let substituteMaterialFound = item.substituteMaterials.find((m) => {
            return m.id === this.currentCutMaterial.id;
          });
          if (substituteMaterialFound) {
            substitutableMaterialsOptions.push({
              value: item.material.id,
              label: this.getMaterialLabel(item.material),
              data: item.material,
            });
          }
        }
      });
      return substitutableMaterialsOptions;
    }
    return [];
  };

  getMaterialLabel = (material) => {
    if (material) {
      return MaterialHelper.getMaterialFullLabel(material.materialName, material.businessId);
    }

    return '';
  };

  generateKitId = () => {
    this.props.actions.generateKitId().then((response) => {
      this.props.change(this.getKitPropReduxName('kitId'), response.data);
    });
  };

  onTagsChangeHandler = (newValue) => {
    this.props.change(this.getKitPropReduxName('tags'), newValue);
  };

  handleSubstitutableMaterialOnChange = (selectedOption) => {
    let currentCutMaterial = this.currentCutMaterial;

    let kitTypeMaterials = this.state.curKitTypeMaterials;

    // -- update kitTypeMaterials --
    let kitTypeMaterialsNew = kitTypeMaterials.map((x) => {
      if (!selectedOption) {
        x.isDefaultMaterial =
          x.requiredMaterial.data.mainMaterialId === currentCutMaterial.id ? true : false;
        x.requiredMaterial.data.substituteMaterialId = null;
        x.requiredMaterial.value = x.requiredMaterial.data.mainMaterialId;
        x.requiredMaterial.label = x.requiredMaterial.data.mainMaterialLabel;
      } else {
        x.isDefaultMaterial =
          x.requiredMaterial.data.mainMaterialId === selectedOption.value ? true : false;
        x.requiredMaterial.data.substituteMaterialId = x.isDefaultMaterial
          ? currentCutMaterial.id
          : null;
        x.requiredMaterial.value = x.isDefaultMaterial
          ? currentCutMaterial.id
          : x.requiredMaterial.data.mainMaterialId;
        x.requiredMaterial.label = x.isDefaultMaterial
          ? this.getMaterialLabel(currentCutMaterial)
          : x.requiredMaterial.data.mainMaterialLabel;
      }
      x.quantityKittedPlies = x.isDefaultMaterial ? x.quantityExpectedPlies : null;

      return x;
    });

    this.props.change(this.getKitPropReduxName('materials'), kitTypeMaterialsNew);
    this.setState({
      curKitTypeMaterials: kitTypeMaterialsNew,
      key: this.state.key === 0 ? 1 : 0,
    });
  };

  //Workaround - force the new rendering after change
  substitutableMaterialOnChangeCallback = () => {
    this.setState({ key: this.state.key === 0 ? 1 : 0 });
  };

  getComponentToRender(props) {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;

    return (
      <span
        className={
          option.data && option.data.isRelatedToSelectedCut ? 'mark-as-related-option' : ''
        }
      >
        {option.label}
      </span>
    );
  }

  renderRequiredMaterials = () => {
    let { name, currentCut, ...others } = this.props;
    let ListItem = this.state.isNewKitType ? NewKitTypeMaterialListItem : NewKitMaterialListItem;

    const cutLength = PermissionManager.isWeightSupported()
      ? UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, currentCut.weightLeft, 2)
      : UnitHelper.serverValueToUserValue(unitTypes.LENGTH, currentCut.lengthCut, 2);

    let requiredMaterials = this.state.curKitTypeMaterials.map((item, index) => {
      return (
        <ListItem
          className={classNames({ 'default-material': this.state.isNewKitType })}
          key={index}
          id={this.getKitPropReduxName('materials[' + index + ']')}
          name={this.getKitPropReduxName('materials[' + index + ']')}
          index={index}
          item={item}
          options={[]}
          validate={Validation.required}
          cutLength={cutLength}
          {...others}
        />
      );
    });

    let units = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;

    return (
      <div key={this.state.key} className="required-materials" id="kit-materials-create-kit-wizard">
        <div className="list-materials-title">
          <label className="column">{this.labels.get('page1.requiredmaterials') + '*'}</label>
          <label className="column">{this.labels.get('page1.expectedplies') + '*'}</label>
          <label className="column">{this.labels.get('page1.kittedplies') + '*'}</label>
          <div className="column">
            <label className="">{this.labels.get('page1.amount')}</label>
            <label className="units">{`(${UnitHelper.getLabelForUnitType(units)})`}</label>
          </div>
          {PermissionManager.hasScrapViewPermissions() && (
            <div className="column">
              <label className="">{this.labels.get('page1.scrap')}</label>
              <label className="units">{`(${UnitHelper.getLabelForUnitType(units)})`}</label>
            </div>
          )}
        </div>

        {requiredMaterials}
      </div>
    );
  };

  //render substitutable materials section (when relevant)
  renderSubstitutableMaterials() {
    let substitutableMaterialsOptions = this.state.substitutableOptions;
    if (!substitutableMaterialsOptions || substitutableMaterialsOptions.length < 1) {
      return null;
    }

    let currentCutMaterial = this.currentCutMaterial;
    let currentCutMaterialLabel = this.getMaterialLabel(currentCutMaterial);

    let kitTypeMaterials = this.state.curKitTypeMaterials;

    let mainMaterialFound = kitTypeMaterials.find((m) => {
      return (
        m.requiredMaterial &&
        m.requiredMaterial.data &&
        m.requiredMaterial.data.mainMaterialId === currentCutMaterial.id
      );
    });

    return (
      <div className="substitutable-materials">
        <div className="substitutable-materials-label">
          <span className="pl pl-substitute-material-mark"></span>
          <Label className="" text={currentCutMaterialLabel} />
          <span className="substitute-material-details">{'substitute material for:'}</span>
        </div>
        <Combobox
          id={this.getKitPropReduxName('substituteMaterial')}
          name={this.getKitPropReduxName('substituteMaterial')}
          options={substitutableMaterialsOptions}
          onChangeCallback={this.handleSubstitutableMaterialOnChange}
          validate={!mainMaterialFound ? Validation.dropdown.required : undefined}
          menuPlacement="top"
        />
      </div>
    );
  }

  renderNewKitTypeMaterials = () => {
    if (!this.state.isNewKitType) {
      return null;
    }

    let newKitTypeAvailableMaterials = this.getNewKitTypeAvailableMaterials(
      this.initialData.materials,
      this.currentCutMaterial.id
    );

    return (
      <AddRemoveListExt
        {...this.props}
        id={this.getKitPropReduxName('newKitTypeMaterials')}
        name={this.getKitPropReduxName('newKitTypeMaterials')}
        itemRendererComponent={NewKitTypeMaterialListItem}
        itemRendererOptions={newKitTypeAvailableMaterials}
        addButtonLabel={this.labels.get('page1.addnewmaterial')}
        hideAddAtButton={true}
      />
    );
  };

  renderMaterialsSection = () => {
    if (!this.state.curKitTypeId) {
      return null;
    }

    return (
      <div className="materials-section" id="kit-materials-create-kit-wizard">
        {this.renderRequiredMaterials()}

        {this.state.isNewKitType && this.renderNewKitTypeMaterials()}

        {!this.state.isNewKitType && this.renderSubstitutableMaterials()}
      </div>
    );
  };
  renderAdditionalFields = () => {
    if (
      !this.props.sData.get('initialData') ||
      !this.props.sData.get('initialData').additionalFields
    ) {
      return null;
    }

    return this.props.sData.get('initialData').additionalFields.map((field, index) => {
      return (
        <AdditionalField
          key={index}
          field={field}
          index={index}
          additionalFieldPrefix={this.props.name}
        />
      );
    });
  };

  maxLengthDropDown = Validation.dropdown.maxLength(30);

  render() {
    let initialData = this.props.sData.get('initialData');
    let allWorkOrders = initialData ? initialData.workorders : [];
    let allProjects = initialData ? initialData.projects : [];
    let allParts = initialData ? initialData.parts : [];
    let kitTypesOptions = this.state.curKitTypesOptions;

    return (
      <div>
        <section className="create-kit-form">
          {/* LEFT COLUMN */}
          <div className="vertical-input-column">
            <InputSection
              label={this.labels.get('page1.workorder')}
              htmlFor={this.getKitPropReduxName('workOrder')}
              className="full-width no-margin"
            >
              <Combobox
                id={this.getKitPropReduxName('workOrder')}
                name={this.getKitPropReduxName('workOrder')}
                allowNewOption={PermissionManager.hasCreateAssetsPermissions()}
                options={allWorkOrders}
                optionRenderer={this.getComponentToRender}
                onChangeCallback={this.onWorkOrderOnChangeHandler}
                isDisabled={this.state.isWorkOrderDisabled}
              />
            </InputSection>

            <InputSection
              label={this.labels.get('page1.type') + '*'}
              htmlFor={this.getKitPropReduxName('kitType')}
              className="full-width"
            >
              <Combobox
                id={this.getKitPropReduxName('kitType')}
                name={this.getKitPropReduxName('kitType')}
                allowNewOption={this.kitTypeCreationOnTheFlyEnabled}
                options={kitTypesOptions}
                isDisabled={this.state.isKitTypeDisabled}
                onChangeCallback={this.handleKitTypeOnChange}
                validate={[
                  Validation.dropdown.required,
                  this.maxLengthDropDown,
                  Validation.specialized.createKitWizard.uniqueNewKitType,
                ]}
              />
            </InputSection>

            <InputSection
              label={this.labels.get('page1.kitid') + '*'}
              htmlFor={this.getKitPropReduxName('kitId')}
              className="full-width"
            >
              <GenerateButton
                id={this.getKitPropReduxName('kitId')}
                name={this.getKitPropReduxName('kitId')}
                className="kitId"
                placeholder={this.labels.get('page1.kitid.inputhint')}
                buttonText={this.labels.get('page1.kitid.generate')}
                validate={[
                  Validation.required,
                  Validation.specialized.createKitWizard.uniqueNewBusinessId,
                ]}
                onClick={this.generateKitId}
              />
            </InputSection>

            <InputSection
              label={this.labels.get('page1.tag')}
              htmlFor={this.getKitPropReduxName('tags')}
              className="full-width"
            >
              <SingleLineMultiSelect
                id={this.getKitPropReduxName('tags')}
                name={this.getKitPropReduxName('tags')}
                limit={10}
                options={this.props.allTagsOptions}
                value={[]}
                onChange={this.onTagsChangeHandler}
              ></SingleLineMultiSelect>
            </InputSection>
          </div>

          {/* RIGHT COLUMN */}
          <div className="vertical-input-column">
            <InputSection label="" className="spacer no-margin full-width"></InputSection>

            <InputSection
              label={this.labels.get('page1.project') + '*'}
              htmlFor={this.getKitPropReduxName('projectId')}
              className="full-width"
            >
              <Combobox
                id={this.getKitPropReduxName('projectId')}
                name={this.getKitPropReduxName('projectId')}
                options={allProjects}
                validate={Validation.dropdown.required}
                isDisabled={this.state.curProjectId}
              />
            </InputSection>

            <InputSection
              label={this.labels.get('page1.part') + '*'}
              htmlFor={this.getKitPropReduxName('partId')}
              className="full-width"
            >
              <Combobox
                id={this.getKitPropReduxName('partId')}
                name={this.getKitPropReduxName('partId')}
                options={allParts}
                validate={Validation.dropdown.required}
                isDisabled={this.state.curPartTypeId}
              />
            </InputSection>
          </div>
          <div>{this.renderAdditionalFields()}</div>

          {this.renderMaterialsSection()}
        </section>
      </div>
    );
  }
}

CreateKitForm.propTypes = {
  currentCut: PropTypes.object.isRequired,
};

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { reduxForm } from 'redux-form';

import {jsxActions} from '../../actions/ChangePassword/changePasswordActions.js';

import ChangePasswordPage from 'infrastructure/js/components/ChangePasswordPage/changePasswordPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(jsxActions, dispatch)
  }
}
let ChangePasswordPageContainer = reduxForm({
    form: 'changePasswordPage',
  }
)(ChangePasswordPage);

ChangePasswordPageContainer = connect(
  (state)=>{
    return {
      sData: state.changePassword,
      sLoginData : state.login
    }
  },
  mapDispatchToProps
)(ChangePasswordPageContainer);

export default ChangePasswordPageContainer;

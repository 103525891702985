import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

require('./commonList.scss');

export default class CommonList extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      selectedIndex: -1,
    }
  }

  componentDidUpdate(prevProps){

    if(this.props.items !== prevProps.items){
     this.setState({selectedIndex: -1});
    }
  }

  onItemClickHandler = (item, index) => {
    this.setState({selectedIndex: index});

    if (this.props.onItemClickCallback) {
      this.props.onItemClickCallback(item, index);
    }
  };

  render() {
    let {items, itemRenderer, className} = this.props;
    let itemsList = items.map((item, index) => {
      return(
        <div className={cn('common-list-item', className, {'selected' : index === this.state.selectedIndex})}
             key={`common-list-item-${index}`}
             onClick={() => this.onItemClickHandler(item, index)}
        >
          {itemRenderer ? itemRenderer(item, index) : <div>{index}</div>}
        </div>
      ) }
    );

    return (
      <div className="common-list">
        {itemsList}
      </div>

    );
  }
}
CommonList.defaultProps = {
  items: [],
};


CommonList.propTypes = {
  items: PropTypes.array,
  itemRenderer: PropTypes.func
};


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as progressDashboardPageActions }    from '../../../actions/Reports/progressDashboardPageActions.js';
import ProgressDashboardPage from '../../../components/Reports/Dashboard/ProgressDashboardPage/progressDashboardPage.js';
import {gridsNames} from '../../../enums/gridsNames';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(progressDashboardPageActions, dispatch),
    }
  }
}

let ProgressDashboardPageContainer = connect(


  (state) => {
    // let sGrid = state.grid.get(gridsNames.DASHBOARD_CORRELATIONS) ? state.grid.get(gridsNames.DASHBOARD_CORRELATIONS) : null;

  	return {
      // sGridData:  sGrid,
      // sHasRows: sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sData: state.progressDashboardPage,
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps
)(ProgressDashboardPage);


export default ProgressDashboardPageContainer;




import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import CommonCsvSettingsView from '../Components/CommonCsvSettingsView/commonCsvSettingsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';
require('./toolTypeCsvSettingsView.scss');

export default  class ToolTypeCsvSettingsView extends React.PureComponent {

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_CSV_TOOL_TYPE);

    this.props.actions.load(getEnumValue(enumTypes.OBJECT_TYPE)('TOOL_TYPE'));
  }

  render() {
    return (
        <CommonCsvSettingsView
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          entityType={ getEnumValue(enumTypes.OBJECT_TYPE)('TOOL_TYPE')}
        />
    );
  }
}


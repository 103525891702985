import React from 'react';

import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import StatusCell     from './CustomCells/StatusCell/statusCell.js';
import MaterialCell   from './CustomCells/MaterialCell/materialCell.js';
import MoreInfoCell   from './CustomCells/MoreInfoCell/moreInfoCell.js';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../enums/gridsNames';
import {reduxForm} from 'redux-form';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./kittingGrid.scss');

class KittingGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'status', filterName: 'kitStatus', getOptions: true},
    {fieldName: 'kitType', filterName: 'kitType', getOptions: true},
    {fieldName: '', filterName: 'kitBusinessIdSearch'},
    {fieldName: '', filterName: 'kitProject'},
    {fieldName: '', filterName: 'kitWorkOrder'},
  ]

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.filterLabels = createLabelHelper('mat.grid.columns.moreInfo.filter.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'status',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitStatus',
        width: 175,
        columnOptions: {
          cellComponent: StatusCell,
          sortable: false,
        }
      },
      {
        fieldName: 'kitType',
        title: this.labels.get('columns.kitType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'materials',
        title: this.labels.get('columns.materials.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MaterialCell,
          sortable: false
        }
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.moreInfo.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        filterAlignment: filterAlignmentTypes.RIGHT,
        propertyTypes: this.getMoreInfoProperties(),
        columnOptions: {
          cellComponent: MoreInfoCell,
          sortable: false
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change,
      }
    ]
  }

  getRowHeight(params) {
    let rowHeight = 160;
    // increase row height for rows with many materials types

    if (params.data.materials.length > 3) {
      rowHeight = params.data.materials.length * 30 + 40;
    }

    return rowHeight;
  }

  getMoreInfoProperties() {
    return [
      {value: 'kitBusinessIdSearch', label: this.filterLabels.get('kitId') || 'Kit Id'},
      {value: 'kitProject', label: this.filterLabels.get('project') || 'Project'},
      {value: 'kitWorkOrder', label: this.filterLabels.get('workOrder') || 'Work Order'},
    ];
  }

  getOverlayNoRowsTemplate = () => {
    return '';
  }

  render() {
    return (
      <div className="kitting-grid">
        <Grid gridName={gridsNames.LOCATION_KITTING}
              columnsConfig={this.columnsConfig}
              filterConfig={this.filterConfig}
              actions={this.props.actions}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  overlayNoRowsTemplate: this.getOverlayNoRowsTemplate
                }
              }
        >
        </Grid>

      </div>
    );
  }
}


export default reduxForm({
    form: 'KittingGrid'
  }
)(KittingGrid);

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as workOrderPageActions }           from '../../actions/WorkOrderPage/workOrderPageActions';
import {jsxActions as headerActions}                  from '../../actions/Header/headerActions';
import { jsxActions as activityLogTabGridActions }       from '../../actions/WorkOrderPage/activityLogTabGridActions.js';
import { jsxActions as archiveWorkOrderDialogActions }  from '../../actions/WorkOrderPage/Dialogs/archiveWorkOrderDialogActions';
import { jsxActions as activateWorkOrderDialogActions } from '../../actions/WorkOrderPage/Dialogs/activateWorkOrderDialogActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import { jsxActions as createAttachmentDialogActions }      from '../../actions/Dialogs/createAttachmentDialog/createAttachmentDialogActions';
import { jsxActions as editAttachmentDialogActions }      from '../../actions/Dialogs/editAttachmentDialog/editAttachmentDialogActions';

import WorkOrderPage from '../../components/WorkOrderPage/workOrderPage.js';
import {gridsNames} from "../../enums/gridsNames";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      header:                          bindActionCreators(headerActions, dispatch),
      workOrderPageActions:            bindActionCreators(workOrderPageActions, dispatch),
      activityLogTabGridActions:       bindActionCreators(activityLogTabGridActions, dispatch),
      archiveWorkOrderDialogActions :  bindActionCreators(archiveWorkOrderDialogActions, dispatch),
      activateWorkOrderDialogActions : bindActionCreators(activateWorkOrderDialogActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch),
      createAttachmentDialogActions: bindActionCreators(createAttachmentDialogActions, dispatch),
      editAttachmentDialogActions: bindActionCreators(editAttachmentDialogActions, dispatch),
    }
  }
}


let WorkOrderPageContainer = connect(
  (state) => {
  	return {
      selectedTab:                  state.workOrderPage.getIn(['data', 'selectedTab']),
      workOrder:                    state.workOrderPage.getIn(['data', 'workOrder']),
      sArchiveWorkOrderDialogData:  state.workOrderPage.get('archiveWorkOrderDialog'),
      sActivateWorkOrderDialogData: state.workOrderPage.get('activateWorkOrderDialog'),
      //for ArchiveWorkOrderDialog
      relatedAssets:                state.workOrderPage.getIn(['overviewTab', 'relatedAssets']),
      attachmentTabGrid: state.grid.get(gridsNames.ASSET_PAGE_ATTACHMENT) ? state.grid.get(gridsNames.ASSET_PAGE_ATTACHMENT) : null,
      sCreateAttachmentDialogData:   state.dialogs.get('createAttachmentDialog'),
      sEditAttachmentDialogData:   state.dialogs.get('editAttachmentDialog'),
    };
  },
  mapDispatchToProps
)(WorkOrderPage);

export default WorkOrderPageContainer;




import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Grid from './grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as staticGridActions } from './staticGridActions.js';

class GridWrapper extends React.PureComponent {
  gridActions = {};

  constructor(props) {
    super(props);
    this.gridActions = this.props.isStatic ? this.props.staticActions : this.props.actions;
    this.gridActions.init(
      this.props.gridName,
      this.props.columnsConfig,
      this.props.filterConfig,
      this.props.rows
    );
    this.gridContainer = this.buildGridContainer(this.props.gridName);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isStatic && prevProps.rows !== this.props.rows) {
      this.gridActions.init(
        this.props.gridName,
        this.props.columnsConfig,
        this.props.filterConfig,
        this.props.rows
      );
    }
  }
  buildGridContainer = (gridName) => {
    return connect((state) => {
      return {
        ...state.grid.get(gridName).toObject(),
      };
    })(Grid);
  };

  render() {
    let gridProps = this.props.gridProps || {};
    return (
      <this.gridContainer gridName={this.props.gridName} {...gridProps} {...this.gridActions} />
    );
  }
}

GridWrapper.propTypes = {
  gridName: PropTypes.string.isRequired,
  columnsConfig: PropTypes.array,
  filterConfig: PropTypes.array,
  rows: PropTypes.array,
  isStatic: PropTypes.bool,
};

function mapDispatchToProps(dispatch) {
  return {
    staticActions: {
      ...bindActionCreators(staticGridActions, dispatch),
    },
  };
}

let GridWrapperContainer = connect((state) => {
  return {};
}, mapDispatchToProps)(GridWrapper);

export default GridWrapperContainer;

import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';

import Dialog from 'infrastructure/js/components/Dialog/dialog';

class DeleteDemoOrganizationDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.dialog.deleteDemoOrganization.');

    this.deletionData = this.props.sData.get('deletionData'); //organization data
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.labels.get('footer.delete'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading'),
        },
      ],
    };
  }

  onSubmit = () => {
    const demoOrgId = this.deletionData.id; //org Id
    this.props.actions.submit(demoOrgId, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  render() {
    let titleText = this.labels.get('header.title');
    let bodyText = this.labels.get('body.text', '', { orgName: this.deletionData.businessId });

    return (
      <Dialog
        id="delete-organization-dialog"
        className="delete-organization-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
      >
        <div className="delete-confirmation">{bodyText}</div>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'deleteDemoOrganizationDialog',
})(DeleteDemoOrganizationDialog);

DeleteDemoOrganizationDialog.defaultProps = {};
DeleteDemoOrganizationDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

import Network from 'infrastructure/js/modules/network';

export function fetchSensorsLogs(query) {
  return Network.post('iotLoggingSettings/items', query);
}
export function createSensorLog(query) {
  return Network.post('iotLoggingSettings/create', query);
}
export function editSensorLog(query) {
  let settingId = query.settingId;
  delete query.settingId;
  return Network.put(`iotLoggingSettings/${settingId}`, query);
}
export function deleteSensorsLog(targetIds) {
  return Network.delete(`iotLoggingSettings/delete`, {targetIds});
}
export function getSensorsOptions() {
  return Network.post(`telemetry/searchSensorsForLogging`);
}
export function activateSensorsLog(targetIds) {
  return Network.post(`iotLoggingSettings/activate`, {targetIds});
}
export function deactivateSensorsLog(targetIds) {
  return Network.post(`iotLoggingSettings/deactivate`, {targetIds});
}



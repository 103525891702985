import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as archivedRollsPageActions }    from '../../actions/ArchivedRollsPage/archivedRollsPageActions.js';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as activateAssetsDialogActions }    from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';

import { jsxActions as archivedRollsGridActions }    from '../../actions/ArchivedRollsPage/archivedRollsGridActions';
import { jsxActions as moveAssetsActions }        from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as printPdfLabelsActions }      from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

import ArchivedRollsPage from '../../components/PredefinedList/ArchivedRollsPage/archivedRollsPage';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(archivedRollsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      archivedRollsGridActions: bindActionCreators(archivedRollsGridActions, dispatch),
      activateAssetsDialogActions:  bindActionCreators(activateAssetsDialogActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      printPdfLabelsActions:      bindActionCreators(printPdfLabelsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let ArchivedRollsPageContainer = connect(
  (state) => {
    let archivedRollsGrid = state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_ROLLS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_ROLLS) : null;
  	return {
      sArchivedRollsGrid:  archivedRollsGrid,
      sHasRows: archivedRollsGrid && archivedRollsGrid.get('rows') ? archivedRollsGrid.get('rows').size > 0 : false,
      sSelectedRowsData: archivedRollsGrid ? archivedRollsGrid.get('selectedRowsData') : List(),
      sActivateAssetsDialogData: state.dialogs.get('activateAssetsDialog'),
      sMoveAssetsData:     state.dialogs.get('moveAssetsDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
    };
  },
  mapDispatchToProps
)(ArchivedRollsPage);


export default ArchivedRollsPageContainer;

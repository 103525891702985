import * as groupsService from '../../../services/Groups/groupsService';
import { gridsNames } from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_MAT_SETTINGS_GROUP_TYPES, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return () => {
    return groupsService.fetchGroupTypes(query);
  };
}

function _convertToRowData(rows) {
  return rows;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import Network from 'infrastructure/js/modules/network';

export function fetchMqttSensors(brokerId) {
  return Network.get(`mqttBroker/${brokerId}/sensors`);
}

export function createMqttSensor(data) {
  let deviceAppId = data.deviceAppProfileId;
  delete data.deviceAppProfileId;
  return Network.post(`deviceAppProfile/${deviceAppId}/mqttSensor`, data);
}

export function editMqttSensor(data) {
  let sensorId = data.sensorId;
  delete data.sensorId;
  return Network.put(`mqttSensor/${sensorId}`, data);
}

export function deleteMqttSensor(data) {
  return Network.delete('mqttSensor' , {sensorIds: data});
}

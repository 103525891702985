import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import {jsxActions} from '../../actions/Register/registerActions.js';

import RegisterPage from 'infrastructure/js/components/RegisterPage/registerPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(jsxActions, dispatch)
  }
}

let RegisterPageContainer = reduxForm({
    form: 'registerPage'
  }
)(RegisterPage);

RegisterPageContainer = connect(
  state => {
    return {
      sData: state.register,
      sLoginData : state.login
    };
  },
  mapDispatchToProps

)(RegisterPageContainer);

export default RegisterPageContainer;

import PropTypes from 'prop-types';

const TaskScheduled = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
      <g data-name="Group 3535">
        <path data-name="Rectangle 1510" style={{ fill: color }} d="M0 0h42v42H0z" />
        <path
          data-name="Path 636"
          d="M25.576 505.689v13.618a2.11 2.11 0 0 1-.6 1.481 2.047 2.047 0 0 1-1.459.614H9.064a2.047 2.047 0 0 1-1.459-.614 2.11 2.11 0 0 1-.6-1.481v-13.618a2.111 2.111 0 0 1 .6-1.481 2.048 2.048 0 0 1 1.459-.614H10.1V501.5h2.06v2.095h8.256V501.5h2.064v2.095h1.032a2.048 2.048 0 0 1 1.459.614 2.111 2.111 0 0 1 .605 1.48zm-2.064 2.095H9.064v11.523h14.448z"
          transform="translate(4.713 -491)"
          style={{ fill: '#fff' }}
        />
        <g data-name="Ellipse 315" transform="translate(25 25)" style={{ stroke: color, fill: '#fff' }}>
          <circle cx="4" cy="4" r="4" style={{ stroke: 'none' }} />
          <circle cx="4" cy="4" r="3.5" style={{ fill: 'none' }} />
        </g>
      </g>
    </svg>
  );
};

TaskScheduled.propTypes = {
  color: PropTypes.string,
};

TaskScheduled.defaultProps = {
  color: '#646464',
};

export default TaskScheduled;

import Network from '../modules/network';
import {api as systemApi} from '../../../mat/js/actions/System/systemActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.initVersionsData =  function (dispatch, getState) {
  return function() {
    let serverVersionPromise = systemApi.setServerVersion()(dispatch, getState);
    let clientVersionPromise = systemApi.setClientVersion()(dispatch, getState);

    Promise.all([serverVersionPromise, clientVersionPromise])
      .then((allResults) => {
        api.setNetworkHeaderVersions(dispatch, getState)(allResults);
      });
  }
}

api.setNetworkHeaderVersions =  function (dispatch, getState) {
  return function(allResults) {
    //TODO: SAAS-4207 - add client and sever version numbers to every server request headers.
    //TODO: Server must 'know' These header types (or provide known types).
    //Network.Headers.append('X-Version-Server' , date[0]);
    //Network.Headers.append('X-Version-Client' , date[1]);

    // EXEMPLE - if unmarking this line, you will see on the network the new Content-Type ***DO NOT USE THIS LINE OTHERWISE THEN TESTING
    //Network.Headers.append( 'Content-Type' , 'application/x-www-form-urlencoded; charset=UTF-8');
  }
}





import { Map } from 'immutable';
import { actionTypes as PsManagementPageActionsType } from '../../../actions/Administration/PsManagementPage/psManagementPageActions';
import {MatSettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import { default as dashboardsDemoDataViewReducer } from './dashboardsDemoDataViewReducer';
import { default as editDashboardDemoDataDialogReducer } from './editDashboardDemoDataDialogReducer';


let defaultState = {
  selectedMenuItemId : MatSettingsMenuItemsIds.NAV_ELEMENT_MATERIAL_TYPES
};

export default function(state = Map(defaultState), action) {

  state = state.set('dashboardsDemoDataView', dashboardsDemoDataViewReducer(state.get('dashboardsDemoDataView'), action));
  state = state.set('editDashboardDemoDataDialog', editDashboardDemoDataDialogReducer(state.get('editDashboardDemoDataDialog'), action));

  switch (action.type) {

    case PsManagementPageActionsType.ADMINISTRATION_PS_MANAGEMENT_SELECTED_MENU_ITEM_CHANGED:
      return state.set('selectedMenuItemId', action.payload);

    default:
      return state;
  }
}




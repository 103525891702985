import React from 'react';

import classNames from "classnames";
import Label from '../../Label/label';
import Link from "../../../../../mat/js/components/Common/Layout/Link/link";
export default class MasterDetailRow extends React.PureComponent {

  render (){
    if(!this.props.data || !this.props.data.extendedDetails){
      return null;
    }

    var rows = [];
    for (let index = 0; index < this.props.data.extendedDetails.length; index += 4) {
      let extendedDetailsChunk = this.props.data.extendedDetails.slice(index, index + 4);

      var details = extendedDetailsChunk.map((detail, index) => {
        return <div key={index} className={classNames('more-info-row-cell')}>
          <p className="title">{detail.title}:</p>
          {detail.isLink ? <Link label={detail.value.toString()} id={detail.id} type={detail.type}/> : <Label text={detail.value.toString()} />}
        </div>
      });

      rows.push(<div key={index}>
        {details}
      </div>)
    }

    return <div className="more-info-row">
      {rows}
    </div>
  }
}

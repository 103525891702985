import {actionTypes as importNestsWizardActions }from '../../../actions/Wizards/ImportNestsWithRoll/importNestsWithRollWizardActions.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {gridsNames}  from '../../../enums/gridsNames';
import {actionTypes as gridActions} from 'infrastructure/js/components/Grid/Utils/gridActionsHelper.js';
import { Map, List } from 'immutable';

let defaultState = {
  errors: null,
  nestsErrors: null,
  loading: false,
  validatedFileData: null,
  fileToken: null,
  selectedFile: null,
  importFileData: null,
  selectedAssetType: null,
  parentComponent: null,
  currentDate: DateTimeHelper.DateFormat(Date.now()),
  importTypes: [],
  shouldIncludeLocation: false,
  showPrintLabels: false,
  acceptFileTypes:[],
  rollId: null,
  materialBusinessId: null,
  nestsItems: List()
};

export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case gridActions.GRID_SELECTED_ROWS_CHANGED:
      if (action.payload.gridName !== gridsNames.IMPORT_NESTS_WITH_ROLL_SELECT_NESTS &&
          action.payload.gridName !== gridsNames.IMPORT_NESTS_WITH_ROLL_SELECT_NESTS_STEP_TWO ){
        return state;
      }
      return state.set('nestsErrors', null);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_SAVE_START_PARAMETERS:
      return state.set('importTypes', action.payload.importTypes)
                  .set('acceptFileTypes', action.payload.acceptFileTypes)
                  .set('parentComponent', action.payload.parentComponent);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_CLEAR_PARAMETERS:
      return Map(defaultState);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_ON_ROWS_CHANGED:
      return state.set('nestsErrors', null)
                  .set('nestsItems', action.payload);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_HAS_ERRORS:
      return state.set('errors', action.payload);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_HAS_SELECTED_NESTS_ERRORS:
      return state.set('nestsErrors', action.payload);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_IN_PROGRESS:
      return state.set('loading', true);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FINISHED:
      return state.set('errors', null)
                  .set('nestsErrors', null)
                  .set('nestsItems', action.payload.importCutPlanWithRollGroupedByNestModel)
                  .set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_UPLOAD_FILE_FAILED:
      return state.set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_IN_PROGRESS:
      return state.set('loading', true);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_FINISHED:
      return state.set('validatedFileData', action.payload.data)
                  .set('fileToken', action.payload.token)
                  .set('validationJobId', action.payload.validationJobId)
                  .set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_VALIDATE_FILE_FAILED:
      return state.set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_IN_PROGRESS:
      return state.set('loading', true);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_FINISHED:
      return state.set('importFileData', action.payload)
                  .set('selectedFile', null)
                  .set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_IMPORT_FILE_FAILED:
      return state.set('errors', action.payload)
                  .set('loading', false);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_SELECTED_ASSET_TYPE_CHANGED:
      return state.set('selectedAssetType', action.payload);

    case importNestsWizardActions.IMPORT_NESTS_WITH_ROLL_SELECTED_FILE_CHANGED:
      return state.set('selectedFile', action.payload);

    default:
      return state;
  }

  return state;
}

import React from 'react';

import ImportFilesCommonGrid from '../ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';

export default class ImportNestsGrid extends React.PureComponent {

  render() {
    return  <ImportFilesCommonGrid  rows={this.props.rows}
                                    assetType={ImportTypes.IMPORT_NESTS}
                                    gridName={gridsNames.IMPORT_NESTS}
                                    showWorkOrderColumn={true}
                                    showNumberOfPliesColumn={true}
    />;
  }
}

import React, {Component} from 'react';

import {LoginLayout, LoginInput, LoginGoLoginPageFooter} from '../LoginLayout/loginLayout.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';

//Style
require('./resetPasswordPage.scss');

export default class ResetPasswordPage extends Component{

  componentWillUnmount() {
    this.props.actions.clearData();
  }

  componentDidMount() {
    this.props.initialize({orgBusinessId: this.props?.location?.state?.org, username: this.props?.location?.state?.user})
  }

  getHasError = () => {
    if (this.props.sData.get('errorMessage')) {
      return ' has-error';
    }
    return '';
  };
  isSuccess = () => {
    if (this.props.sData.get('isSuccess') && this.props.sData.get('errorMessage')) {
      return ' success';
    }
    return '';
  };

  resetPassword = (formData)=>{
    this.props.actions.resetPassword(formData);
  };

  render() {
    return (
      <LoginLayout formClass={'reset-password-page' + this.getHasError()} actionButtonText="Reset Password" actionButtonOnclick={this.props.handleSubmit(this.resetPassword)} footerComponent={<LoginGoLoginPageFooter/>} loading={this.props.sData.get('loading')}>
        <LoginInput label="Organization" name="orgBusinessId" id="OrgInput"  placeholder="Organization" validate={Validation.required} />
        <LoginInput label="USER NAME" name="username" id="userNameInput"  placeholder="User" validate={Validation.required} />
        <div className={'reset-password-error' + this.isSuccess()}>{this.props.sData.get('errorMessage')}</div>
      </LoginLayout>
    );
  }
}






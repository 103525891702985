import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EntitiesMultiSelect from './entitiesMultiSelect';
require('./entitiesMultiSelectWithChildren.scss');

export default  function EntitiesMultiSelectWithChildren({children, ...rest}){
  return (
    <div className='entities-multi-select-with-children'>
      <EntitiesMultiSelect {...rest} />
      {children}
    </div>
  )
}

import React from 'react';
import PropTypes from 'prop-types';

import {List} from 'immutable';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import DateTimeHelper from "../../../../../infrastructure/js/utils/dateTimeHelper";
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
require('./reportMaintenanceDialog.scss');

class ReportMaintenanceDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.dialog.reportmaintenance.');
    this.entitiesTypes = this.props.asset ? [this.props.asset.get('objectType')] : this.props.sData.get('entitiesTypes').toJS();
    this.preselectedAssets = this.props.asset ? List([this.props.asset.toJS()]) : this.props.sData.get('preselectedAssets');


    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.props.sData.get('boEntityFilter')
    };

    this.state = {
      isCyclesFieldDisabled: false,
      isUtlFieldDisabled: false
    }

    //todo - get from api
    this.operationStatusTypes = [
      {value: 'COMPLETE', label: this.labels.get('operationstatus.complete')},
      {value: 'IN_PROGRESS', label: this.labels.get('operationstatus.inprogress')},
    ];

    this.setOperationStatusTypesOptionRedux(this.operationStatusTypes[0]);
    this.setNextMaintenanceCyclesOptionRedux();
  }

  setOperationStatusTypesOptionRedux  = (options) => {
    this.props.change('operation-status', options);
  };

  setNextMaintenanceCyclesOptionRedux  = () => {
    if(this.props.asset && this.props.asset.get('currentCyclesNumber')){
      this.props.change('next_maintenance_cycles', this.props.asset.get('currentCyclesNumber'));
    }
  };

  getDialogButtons() {
    let cancelTokenEnabled = !!this.props.sData.get('cancelToken') ;
    return {
      left: [{
        id:'cancel',
        text:   this.props.sData.get('loading') ? this.dialogLabels.get('stop') : this.dialogLabels.get('cancel'),
        action: this.props.sData.get('loading') ? this.onStop : this.onHide,
        enabledOnLoading: cancelTokenEnabled
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.reportmaintenance'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
          this.props.sData.get('hasError') ||
          (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))

        }
      ]
    };
  }


  onSubmit = (data) => {

    let row = data['tools-multi-select'];
    let toolIds = (row && row.assetsValue) ? row.assetsValue.map((item) => {return item.value}) : [];

    let operationStatus = data['operation-status'].value;
    let nextMaintenanceNumOfCycles = data['next_maintenance_cycles'];
    let comment = data['comment'];
    let maintenanceUsageTime = DateTimeHelper.ConvertDaysHoursMinutesToMinutes(data.maintenanceUsageTime_days, data.maintenanceUsageTime_hours, data.maintenanceUsageTime_minutes);

    let newData = {
      toolIds : toolIds,
      operationStatus: operationStatus,
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,


    };

    if(typeof nextMaintenanceNumOfCycles !== 'undefined') {
      newData.nextMaintenanceNumOfCycles = nextMaintenanceNumOfCycles;
    }

    if(comment) {
      newData.comment = comment;
    }

    if(data.headerCheckbox_maintenanceUsageTime){
      newData.maintenanceUsageTime = null;
    }
    if(data.headerCheckbox_next_maintenance_cycles){
      newData.nextMaintenanceNumOfCycles = null;
    }

    if(maintenanceUsageTime){
      newData.maintenanceUsageTime = maintenanceUsageTime;
    }
    if(data.isMaxValueForUtl){
      newData.useMaxValueForUtl = true;
    }
    if(data.isMaxValueForCycles){
      newData.useMaxValueForCycles = true;
    }

    this.props.actions.submit(newData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title =  this.labels.get('confirmation.message.complete.title', '', { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  onCyclesCheckboxChangeCallback = (event)=>{
    this.setState({isCyclesFieldDisabled: (event.target.checked)});
    this.props.change('isMaxValueForCycles', false);
    this.props.change('next_maintenance_cycles', null);
  };

  onUtlCheckboxChangeCallback = (event)=>{
    this.setState({isUtlFieldDisabled: (event.target.checked)});
    this.props.change('isMaxValueForUtl', false);
    this.props.change('maintenanceUsageTime_days', '');
    this.props.change('maintenanceUsageTime_hours', '');
    this.props.change('maintenanceUsageTime_minutes', '');
  };

  onMaxValueForUtlCheckboxClick = (event)=>{
    this.setState({isUtlFieldDisabled: (event.target.checked)});
    this.props.change('headerCheckbox_maintenanceUsageTime', false);
  };
  onMaxValueForCyclesCheckboxClick = (event)=>{
    this.setState({isCyclesFieldDisabled: (event.target.checked)});
    this.props.change('headerCheckbox_next_maintenance_cycles', false);
  };

  render() {

    return (
      <Dialog
        id="report-maintenance-dialog"
        className="report-maintenance-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >


        <InputSection label={''} className="no-margin">
          <EntitiesMultiSelect
            id="tools-multi-select"
            name="tools-multi-select"
            entitiesTypes={this.entitiesTypes}
            validate={Validation.required}
            preSelectedEntities={this.preselectedAssets}
            fetchConfig={this.fetchConfig}
            autoFocus={true}
            { ...this.props }
          />

        </InputSection>

        <InputSection label={this.labels.get('operationstatus')} htmlFor="operation-status" className='inline left-side' >
          <Dropdown
            id= 'operation-status-dropdown'
            name='operation-status'
            options={this.operationStatusTypes}
            validate={Validation.required}>
          </Dropdown>
        </InputSection>

        <InputSection label="" className="spacer no-margin inline right-side"></InputSection>

        <InputSection label={this.labels.get('nextmaintenancecycles')} htmlFor="next_maintenance_cycles" className="inline left-side" onCheckboxChangeCallback={this.onCyclesCheckboxChangeCallback}>
          <TextField id="next_maintenance_cycles" name="next_maintenance_cycles"  normalize={Normalize.number(true, 0, 9999)} disabled={this.state.isCyclesFieldDisabled} />
        </InputSection>

        <InputSection htmlFor= "isMaxValueForCycles" className="inline right-side padding-top">
          <Checkbox name= "isMaxValueForCycles" id= "isMaxValueForCycles" label={this.labels.get('maxValueForCycles')} onClick={this.onMaxValueForCyclesCheckboxClick}/>
        </InputSection>


        <InputSection label={this.labels.get("utlNextMaintenance")} htmlFor="maintenanceUsageTime" className="inline left-side" onCheckboxChangeCallback={this.onUtlCheckboxChangeCallback}>
          <TimeField id="maintenanceUsageTime" name="maintenanceUsageTime" className="inline right-side" isDays={true} hoursCount={true} disabled={this.state.isUtlFieldDisabled}/>
        </InputSection>

        <InputSection htmlFor= "isMaxValueForUtl" className="inline right-side padding-top">
          <Checkbox name= "isMaxValueForUtl" id= "isMaxValueForUt" label={this.labels.get('maxValueForUtl')} onClick={this.onMaxValueForUtlCheckboxClick}/>
        </InputSection>

        <InputSection label={this.labels.get('comment')} htmlFor="comment">
          <TextAreaField id="comment-textField" name="comment" className="comment-textField" />
        </InputSection>

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'reportMaintenanceDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(ReportMaintenanceDialog);

ReportMaintenanceDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

import LoginService from 'infrastructure/js/services/LoginService';
import Network from 'infrastructure/js/modules/network';
import { api as loginActionsApi } from '../Login/loginActions';
import { api as messageDialogApi }  from '../MessageDialog/messageDialogActions';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';


// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  REGISTER_SUBMIT_IN_PROCESS: 'REGISTER_SUBMIT_IN_PROCESS',
  REGISTER_SUBMIT_FINISHED: 'REGISTER_SUBMIT_FINISHED',
  REGISTER_SUBMIT_FAILED: 'REGISTER_SUBMIT_FAILED'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  registerInProcess: function() {
    return {type: actionTypes.REGISTER_SUBMIT_IN_PROCESS };
  },
  registerFinished: function(payload) {
    return {type: actionTypes.REGISTER_SUBMIT_FINISHED ,payload: payload };
  },
  registerFailed: function(payload) {
    return {type: actionTypes.REGISTER_SUBMIT_FAILED ,payload: payload };
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.register = function(queryData) {
  return function(dispatch, getState) {
    dispatch(actions.registerInProcess());
    
    return LoginService.noEmailRegistration(queryData).then((response) => {

      let warnings = Network.hasValidationWarnings(response);
      if (warnings) {
        let messageDialogDescriptor = _createMessageDialogDescriptor(warnings);
        if (messageDialogDescriptor) {
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }
        dispatch(actions.registerFailed());
        return false;
      }

      if(!Network.isResponseValid(response)){
        console.error("Register User Failed");
        messageDialogApi.responseError(dispatch, getState)(response);
        dispatch(actions.registerFailed());
        return false;
      }
      loginActionsApi.updateLoggedInUserData(response.data)(dispatch, getState);
      dispatch(actions.registerFinished(response.data));
      return response.data;
    });
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

/////////////////////////////////////////////////////////////////////////
function _createMessageDialogDescriptor(warnings) {

  let validations = dialogHelper.getValidationArea(warnings);

  if(validations && validations.length > 0) {
    let messages = [];
    validations.forEach((v) => {
      if (v && v.messages) {
        v.messages.forEach((m) => {
          messages.push(m);
        })
      }
    });

    if (messages.length > 0) {
      let title = messages[0] ? messages[0].message : 'Invalid Input';
      let type = 'error';
      let className = 'oneBackground';

      return {title, type, className};
    }
  }
  return null;
}




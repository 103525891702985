import PropTypes from 'prop-types';

const TaskCompleted = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
      <g data-name="Group 3700">
        <g data-name="Group 3536">
          <g data-name="Completed box">
            <g data-name="Group 172">
              <path data-name="Rectangle 1510" style={{ fill: color }} d="M0 0h42v42H0z" />
            </g>
            <g data-name="Group 163">
              <path
                data-name="Path 649"
                d="m476.331 514.784-4.4-4.35 1.68-1.662 2.725 2.693 6.285-6.214 1.68 1.662z"
                transform="translate(-457.944 -488.401)"
                style={{ fill: '#fff' }}
              />
              <path
                data-name="Path 650"
                d="M480.573 522.134a9.368 9.368 0 1 0-9.369-9.368 9.368 9.368 0 0 0 9.369 9.368zm0 1.9a11.265 11.265 0 1 0-11.266-11.265 11.265 11.265 0 0 0 11.266 11.261z"
                transform="translate(-460.178 -491.768)"
                style={{ fill: '#fff', fillRule: 'evenodd' }}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

TaskCompleted.propTypes = {
  color: PropTypes.string,
};

TaskCompleted.defaultProps = {
  color: 'green',
};

export default TaskCompleted;

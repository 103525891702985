import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import PropTypes from 'prop-types';
import Label from 'infrastructure/js/components/Label/label';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./moreInfoCell.scss');

export default class MoreInfoCell extends React.PureComponent {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.cells.moreInfo.');
  }
  renderLengthOrWeight =(data)=>{
    if(PermissionManager.isWeightSupported()){
      return(<label>{this.labels.get('weight')} <span>{data.weight}</span></label>)
    }
    return(<label>{this.labels.get('length')} <span>{data.lengthLeft}</span></label>)
  };

  render() {
    let data = this.props.value;
    if (!data) {
      return '';
    }

    return (<div className="archived-rolls-more-info-custom-cell">
      {this.renderLengthOrWeight(data)}
      <label>{this.labels.get('lot')} <Label text={data.lot}/></label>
    </div>);
  }
}

MoreInfoCell.propTypes = {
  value : PropTypes.object
};





import { getPdfExportServersPath } from 'infrastructure/js/utils/appHelper';
import getPDFHeader from './PDFHeader';

const { printPdfServer, translateURLsToAbsolute, clientURL } = getPdfExportServersPath();

export const pdfInitConfig = {
  exportServer: printPdfServer,
  translateURLsToAbsolute,
  clientURL,
  exporterType: 'multipage',
  fileName: 'Plataine-scheduler',
  fileFormat: 'pdf',
  orientation: 'landscape',
  scheduleRange: 'currentview',
  repeatHeader: true,
  sendAsBinary: true,
  headerTpl: getPDFHeader,
};

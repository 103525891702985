import React, { Component } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import InventoryDashboardHeader from '../Components/DashboardHeader/inventoryDashboardHeader';
import ArchivedRollsChart from '../Components/ArchivedRollsChart/archivedRollsChart';
import CommonKPI from '../Components/CommonKPI/commonKPI';
import {Tab} from 'react-bootstrap';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import TabTitle from 'infrastructure/js/components/Tabs/tabTitle';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import MaterialLengthChart from "../Components/MaterialLengthChart/materialLengthChart";
import AlertedRollsChart from "../Components/AlertedRollsChart/alertedRollsChart";
import UsageTimeChart from "../Components/UsageTimeChart/usageTimeChart";
import MaterialUsageChart from "../Components/MaterialUsageChart/materialUsageChart.js";
import ScheduleAction from "../../../../modules/scheduleAction";

require('./inventoryDashboardPage.scss');

export default class InventoryDashboardPage extends Component {

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.dashboard.inventory.');

    this.state = {
      selectedTab: 'inventoryManagementTab',
    };
  }

  componentDidMount() {
    this.props.actions.init(true);
    let refreshRateInHours = PermissionManager.getOrgPreferences().inventoryDashboardRefreshRate;
    if (refreshRateInHours && refreshRateInHours > 0) {
      this.timerRefresh = ScheduleAction("Inventory Dashboard Page Timer Refresh", this.props.actions.onTimerRefresh, refreshRateInHours *3600);
    }
  }

  componentWillUnmount() {
    if(this.timerRefresh) {
      this.timerRefresh.kill();
    }
    this.props.actions.unmount();
  }

  getUsageKPIsData = () => {
    let {sData, sLoggedInUser} = this.props;
    let data = sData.get('usageKpiData');

    if (!data) {
      return [];
    }

    return [
      {
        title: this.labels.get('usageManagement.kpi.averageMaterialLength'),
        leftData: data.averageMaterialLength === 0 ? "N/A" : UnitHelper.serverValueToUserValueWithLabel(unitTypes.LENGTH,data.averageMaterialLength, 2),
        className: 'cupsole blue'
      },
      {
        title: this.labels.get('usageManagement.kpi.averageConsumptionTime'),
        tooltip: this.labels.get('usageManagement.kpi.averageConsumptionTimeTooltip'),
        leftData: data.averageConsumptionTime === 0 ? "N/A" : `${data.averageConsumptionTime} ${this.labels.get('usageManagement.kpi.days')}`,
        className: 'cupsole red'
      },
      {
        title: this.labels.get('usageManagement.kpi.averageCarryingAmount'),
        tooltip: this.labels.get('usageManagement.kpi.averageCarryingAmountTooltip'),
        leftData: data.AverageCarryingAmount === 0 ? "N/A" : UnitHelper.serverValueToUserValueWithLabel(unitTypes.LENGTH,data.AverageCarryingAmount, 2),
        className: 'cupsole green'
      },
    ];
  };

  getInventoryKPIsData = () => {
    let {sData, sLoggedInUser} = this.props;
    let data = sData.get('inventoryKpiData');
    let isThresholdActive = PermissionManager.getOrgPreferences().remnantsThreshold;

    if (!data) {
      return [];
    }

    return [
      {
          title: this.labels.get('inventoryManagement.kpi.numberOfRolls'),
          leftData: `${data.rollsCount} ${this.labels.get('inventoryManagement.kpi.units')}`,
          className: 'cupsole blue'
      },
      {
        title: this.labels.get('inventoryManagement.kpi.inventoryLength'),
        leftData: UnitHelper.serverValueToUserValueWithLabel(unitTypes.LENGTH, data.totalLength, 2),
        className: 'cupsole red'
      },
      {
        title: this.labels.get('inventoryManagement.kpi.numberOfRemnants'),
        leftData: isThresholdActive ? `${data.rollsRemnantCount} ${this.labels.get('inventoryManagement.kpi.units')}` : this.labels.get('inventoryManagement.kpi.notDefined') ,
        className: 'cupsole green'
      },
    ];
  };

  getKPIsData = (selectedTab) => {
    switch (selectedTab) {
      case 'usageManagementTab':
        return this.getUsageKPIsData();
      case 'inventoryManagementTab':
        return this.getInventoryKPIsData();
      default:
        console.log('No KPI defined for ' + selectedTab);
        return null
    }
  }

  renderKPIs = (selectedTab) => {
    let items = this.getKPIsData(selectedTab);

    let KPIs = items.map((item, index) => {
      return <CommonKPI key={index} data={item} className={'gray-bg'} />
    });

    return (
      <div className="kpis-container">
        {KPIs}
      </div>
    )
  };

  renderLoadingOverlay = (loading) => {
    if (!loading) {
      return null;
    }
    return <Overlay.Loading />;
  };

  getTabTitles = () => {
    let inventoryManagementTab = <TabTitle title={this.labels.get('inventoryManagement.tab.title')}/>;
    let usageManagementTab = <TabTitle title={this.labels.get('usageManagement.tab.title')}/>;
    return {inventoryManagementTab, usageManagementTab};
  };

  onTabClick = (key) => {
    this.setState({selectedTab:key});
    if (this.state.selectedTab !== key) {
      setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 0);
    }
  };

  render() {
    let {sData} = this.props;
    let headerData = sData.get('headerData');
    let inventoryChartsData = sData.get('inventoryChartsData');
    let usageChartsData = sData.get('usageChartsData');
    let loading = sData.get('loading');

    let tabTitles = this.getTabTitles();
    let selectedTab = this.state.selectedTab;

    return (
      <div className="inventory-dashboard-page">

        <Tabs activeKey={selectedTab} tabType="content" onSelect={this.onTabClick} id="content-tabs">

          <Tab eventKey={'inventoryManagementTab'} animation={false} title={tabTitles.inventoryManagementTab}>
            <div>
              {!!headerData && <InventoryDashboardHeader form="inventoryManagementTabHeader"
                                                         showMaterialFilter={true}
                                                         showLocationFilter={true}
                                                         showFreezerFilter={true}
                                                         headerData={headerData}
                                                         loadAction={(data) => this.props.actions.fetchInventoryData(data)}
              />}

              {this.renderKPIs('inventoryManagementTab')}

              <div className={'charts-container'}>
                <ArchivedRollsChart chartData={inventoryChartsData.archivedRolls} loading={loading}/>
                <MaterialLengthChart chartData={inventoryChartsData.totalLengthPerMaterial} loading={loading}/>
                <AlertedRollsChart chartData={inventoryChartsData.rollsWithErrorsStatistics} loading={loading}/>
              </div>
            </div>
          </Tab>

          <Tab eventKey={'usageManagementTab'} animation={false} title={tabTitles.usageManagementTab}>
            <div>
              {!!headerData && <InventoryDashboardHeader form="usageManagementTabHeader"
                                                         showMaterialFilter={true}
                                                         headerData={headerData}
                                                         loadAction={(data) => this.props.actions.onUsageHeaderFilterChange(data)}
              />}

              {this.renderKPIs('usageManagementTab')}

              <div className={'charts-container'}>
                <MaterialUsageChart chartData={usageChartsData.materialUsage} loading={loading} onChangeAction={(data) => this.props.actions.onMaterialUsageFilterChange(data)}/>
                <UsageTimeChart chartData={usageChartsData.consumptionTime} loading={loading} onChangeAction={(data) => this.props.actions.onUsageTimeFilterChange(data)}/>
              </div>
            </div>
          </Tab>

          <div>
          </div>

        </Tabs>
        {this.renderLoadingOverlay(loading)}

      </div>
    )
  }
};

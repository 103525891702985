import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import RelatedAssetsCell from '../../../../Common/CustomGridCells/RelatedAssetsCell/relatedAssetsCell.js';
import ValueWithStateCell from '../../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell.js';
import LengthCell from '../../../../Common/CustomGridCells/LengthCell/lengthCell.js';
import WeightCell from '../../../../Common/CustomGridCells/WeightCell/weightCell.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {navigationStates} from '../../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import MaterialHelper from '../../../../../utils/materialHelper';
import {getAssetEtl} from '../../../../../utils/assetHelper';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import DefrostingLabelCell from '../../../../Common/CustomGridCells/DefrostingLabelCell/defrostingLabelCell';
import {alertStatus,alertType,getAlertColumnFilterSections} from '../../../../../utils/alertFilterSectionHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import {enumTypes, getEnumLabel, getEnumValue} from '../../../../../utils/enumHelper';
import {isShippingOrReceivingLocation} from '../../../../../utils/locationHelper';

require('./rollsGrid.scss');

class RollsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'rollBusinessId', getOptions: false},
    {fieldName: 'material.businessId', filterName: 'rollMaterial', getOptions: false},
    {fieldName: 'lot', filterName: 'rollLot', getOptions: false},
    {fieldName: 'lengthLeft', filterName: 'rollLength'},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: 'defrostingStatus', filterName: 'defrostingStatus', getOptions: true},
    {fieldName: 'weight', filterName: 'rollWeight'},
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.allLabels = createLabelHelper('');
    this.filterDefrostinglabels = createLabelHelper('mat.filter.defrosting.');
    this.etlLabels = createLabelHelper('enum.etl.type.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let lengthOrWeightColumn = this.getLengthOrWeight();
    let customColumn = this.getColumnByLocationType();
    let isShippingOrReceivingLoc = isShippingOrReceivingLocation(this.props.locationDetails);

    let columnsConfig = [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.INFO, alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.RESERVED, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              defrostingTimeLeft: params.data.defrostingTimeLeft,
              locationName: params.data.locationName,
              pickListBusinessId: params.data.pickListBusinessId,
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.rollId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'rollBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.ROLL_BUSINESS_ID,
          filter: this.getRollIdFilterByLocationType(),
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: isShippingOrReceivingLoc ? LabelWithTooltipCell : EntityIdCell,
          valueGetter: (params) => {
            return isShippingOrReceivingLoc ? params.data.businessId :
              {
                id: params.data.id,
                label: params.data.businessId,
                type: navigationStates.ROLL
              };
          }
        }
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'rollMaterial',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [...[FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_ROLL, FetchEntitiesFilters.ON_LOCATION],
                    ...(isShippingOrReceivingLoc ? [FetchEntitiesFilters.ASSET_IN_PASSPORT_STATUS] : [])],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {...customColumn},
      {
        fieldName: 'lot',
        title: this.labels.get('columns.lot.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'rollLot',
        fetchConfig: {
          entityType: EntityPropertyTypes.LOT_NAME,
          filter: [...[FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_ROLL, FetchEntitiesFilters.ON_LOCATION],
            ...(isShippingOrReceivingLoc ? [FetchEntitiesFilters.ASSET_IN_PASSPORT_STATUS] : [])],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.lot || ''
          }
        }
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {...lengthOrWeightColumn},

    ];
    return columnsConfig;
  }

  getRollIdFilterByLocationType = () => {
    let {locationDetails} = this.props;

    if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('RECEIVING_AREA')) {
      return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IN_PASSPORT_STATUS]
    }

    if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_AREA')) {
      return [FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IN_PASSPORT_STATUS]
    }

    return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION]
  }

    getColumnByLocationType = () => {

      let {locationDetails} = this.props;

      if (locationDetails) {
        if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('DEFROSTING_AREA') ||
            locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('CUT_KIT') ||
            locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('CUT'))
        {
          return ({
            fieldName: 'defrostingStatus',
            title: this.labels.get('columns.defrosting.title'),
            filterType: filterTypes.MULTI_SECTIONS,
            filterSections: this.getDefrostingColumnFilterSections(),
            filterName: 'defrostingStatus',
            width: 170,
            columnOptions: {
              cellComponent: DefrostingLabelCell,
              valueGetter: (params) => {
                return {
                  status: params.data.defrostingStatus,
                  statusLabel: params.data.defrostingStatusDisplayKey ? this.allLabels.get(params.data.defrostingStatusDisplayKey) : '',
                  defrostingTimeLeft: params.data.defrostingTimeLeft
                }
              }
            }
          });
        }
        else if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('RECEIVING_AREA')) {
          return {
            fieldName: 'assetStatus',
            title: this.labels.get('columns.status.title'),
            filterType: filterTypes.NONE,
            width: 100,
            columnOptions: {
              sortable: false,
              valueGetter: (params) => {
                return params.data.assetStatus ? getEnumLabel(enumTypes.ASSET_STATUS)(params.data.assetStatus)  : '';
              },
            }
          }
        }
      }

      return ( {
            fieldName: 'relatedAssetData',
            title: '',
            filterType: filterTypes.NONE,
            width: 70,
            columnOptions: {
              sortable: false,
              resizable: false,
              cellComponent: RelatedAssetsCell,
              valueGetter: (params) => {
                return {
                  count: params.data.relatedAssetsCounter,
                  cellAction: this.props.cellAction,
                  assetId: params.data.id,
                  componentAssetsTitle: this.labels.get('columns.componentAssets.cell.title'),
                  resultingAssetsTitle: this.labels.get('columns.resultingAssets.cell.title')
                };
              },
              headerComponentParams: {
                headerIcon: "resulting-assets",
                headerTooltip: this.labels.get('columns.resultingAssets.cell.title'),
              }
            }
          } );
    }

  getLengthOrWeight = () => {

    if(PermissionManager.isWeightSupported()){
      return {
        fieldName: 'weight',
        title: this.labels.get('columns.weight.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          cellComponent: WeightCell,
          valueGetter: (params) => {
            return {
              weight: params.data.weight
            };
          },
        }
      }
    }

    return {
      fieldName: 'lengthLeft',
      title: this.labels.get('columns.length.title'),
      filterType: filterTypes.NONE,
      width: 110,
      columnOptions: {
        cellComponent: LengthCell,
        valueGetter: (params) => {
          return {
            length: params.data.lengthLeft
          };
        },
      }
    }
  };


  getDefrostingColumnFilterSections() {
    return [
      {
        properties: [
          {name: 'BEING_DEFROSTED', label: this.filterDefrostinglabels.get('beingDefrosted'), value: false},
          {name: 'DEFROSTED', label:  this.filterDefrostinglabels.get('defrosted'), value: false}
        ]
      },
    ];
  }

  render() {
    return (
      <div className="rolls-grid">
        <Grid gridName={gridsNames.LOCATION_ASSET_ROLLS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'AssetsView_RollsGrid'
  }
)(RollsGrid);



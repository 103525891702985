import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';


export function GetActivityTypeDetails(activityType, activityTypeDisplayKey='') {
  if (!activityType) {
    return {icon: null, description: ''};
  }
  const labels = createLabelHelper('mat.locationpage.view.activities.grid.columns.type.filter.');

  let description = activityTypeDisplayKey ? labels.get(activityTypeDisplayKey) : 'X_NOT_FOUND';
  let icon = _getActivityTypeIcon(activityType);

  return {icon, description};
}

function _getActivityTypeIcon(activityType) {
  switch(activityType) {
    case 'Location Change':
      return 'pl pl-move-arrow-right';
    case 'Kit Creation':
      return  'pl pl-kit2-icon';
    case 'Issue':
      return 'pl pl-error-icon';
    case 'Shelf Life Update':
      return 'pl pl-shelf-life';

    case 'Status Update':
    case 'Details Update':
    case 'Failed Details Update':
      return 'pl pl-update-icon';

    case 'Roll Creation':
    case 'Sub Rolls Creation':
      return 'pl pl-roll-icon-blue';

    case 'Spool Creation':
      return 'pl pl-header-icon-spool';

    case 'Group Creation':
      return 'pl pl-header-icon-container';

    case 'Kit Reported Complete':
    case 'WO Reported Complete':
      return 'pl pl-check-circle-icon-blue';

    case 'Resolved Issue':
      return 'pl pl-issue-resolved';
    case 'Operation Reported':
    case 'Component Asset Added':
    case 'Resulting Asset Added':
    case 'Asset Attached':
    case 'Asset Detached':
      return 'pl pl-note-icon-blue';
    case 'Operation Canceled':
      return 'pl pl-operation-canceled';
    case 'Asset Added':
    case 'WO Added':
      return 'pl pl-added';
    case 'Asset Removed':
      return 'pl pl-removed';


    case 'WO Creation':
      return 'pl pl-header-icon-work_order';
    case 'Tool Creation':
      return 'pl pl-menu-active-header-tool';
    case 'Maintenance Reporting':
      return 'pl pl-report-maintenance';
    default:
      return 'pl ';
  }
}

export function ActivityJobNameToTitle(activityJobName, numberOfItems, date) {
  let result = '';

  const labels = createLabelHelper('mat.activitylogdialog.header.');

  switch(activityJobName) {
    case 'moveAssetsJob':
      return labels.get('moveAssetsJob.title', '', {count: numberOfItems, date});

    case 'updateAssetsStatusJob_active':
    case 'updateAssetsStatusJob_completed':
    case 'updateAssetsStatusJob_in_progress':
      return labels.get('updateAssetsStatusJob.activate.title', '', {count: numberOfItems, date});

    case 'updateAssetsStatusJob_consumed':
    case 'updateAssetsStatusJob_disqualified':
    case 'updateAssetsStatusJob_shipped':
    case 'updateAssetsStatusJob_canceled':
    case 'updateAssetsStatusJob_retired':
      return labels.get('updateAssetsStatusJob.archive.title', '', {count: numberOfItems, date});

    case 'updateWorkOrdersStatusJob_completed':
      return labels.get('updateWorkOrdersStatusJob.completed.title', '', {count: numberOfItems, date});

    case 'updateWorkOrdersStatusJob_disqualified':
    case 'updateWorkOrdersStatusJob_canceled':
      return labels.get('updateWorkOrdersStatusJob.archive.title', '', {count: numberOfItems, date});

    case 'updateWorkOrdersStatusJob_active':
    case 'updateWorkOrdersStatusJob_in_progress':
      return labels.get('updateWorkOrdersStatusJob.activate.title', '', {count: numberOfItems, date});

    case 'updateTasksProgressJob':
      return labels.get('updateTasksProgressJob.title', '', {count: numberOfItems, date});
    case 'updateRollsJob':
    case 'updateKitsJob':
    case 'updateToolsJob':
      return labels.get('updateAssetJob.title', '', {count: numberOfItems, date});
    case 'updateWorkOrdersJob':
      return labels.get('updateWorkOrdersJob.title', '', {count: numberOfItems, date});
    case 'updateShelfLifeJob':
      return labels.get('updateShelfLifeJob.title', '', {count: numberOfItems, date});

    case 'createHooksJob':
      return labels.get('createHooksJob.title', '', {count: numberOfItems, date});

  }

  return result;
}

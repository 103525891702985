import Network from 'infrastructure/js/modules/network';

export function createPrinter(data){
  return Network.post('zebraPrinter', data);
}

export function editPrinter(data) {
  let id = data.id;
  delete data.id;

  return Network.put(`zebraPrinter/${id}`, data);
}
export function fetchPrinters(id) {
  return Network.get(`deviceAppProfile/${id}/zebraPrinters`);
}

export function deletePrinters(data) {
  return Network.delete('zebraPrinters' , {zebraPrinterIds: data});
}

export function fetchPrintersByLocation(locationId) {
  return Network.get(`zebraPrinter/fromLocation/${locationId}`);
}
export function fetchPrintersByAssests(data) {
  return Network.put(`zebraPrinter/fromAssetsLocations`, data);
}

import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import CreateProjectDialog from './Dialogs/CreateProjectDialog/createProjectDialog';
import ProjectsViewGrid from './ProjectsViewGrid/projectsViewGrid';
import {MatSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import {isAllItemsActive, isAllItemsDeactive} from '../utils/matSettingsHelper';
import {ImportAcceptFileTypes, ImportTypes} from '../../../../enums/importTypes';
import {gridsNames} from '../../../../enums/gridsNames';

export default class ProjectsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.projects.view.');
  }

  componentDidMount() {
    this.props.actions.init(false);
    this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_PROJECTS);
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreateProjectClick,
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditProjectClick,
          actionData: selectedRowsData.get(0)
        }
      ],

      kebab: {
        // disabled: !selectedRowsData || selectedRowsData.size === 0,
        menuItems: [
          {
            id:'activate',
            label: headerItemsLabels.ACTIVATE,
            action: actions.header.onActivateProjectsClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsDeactive(selectedRowsData)),
            actionData:  selectedRowsData,
          },
          {
            id:'deactvate',
            label: headerItemsLabels.DEACTIVATE,
            action: actions.header.onDeactivateProjectsClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsActive(selectedRowsData)),
            actionData:  selectedRowsData,
          },
          {
            id: 'importProjects',
            label: headerItemsLabels.IMPORT_PROJECTS,
            action: actions.header.importItems,
            disabled: false,
            actionData: {
              importTypes: [ImportTypes.IMPORT_PROJECTS],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: actions.reload
            }
          },
          {
            id: 'exportProjects',
            label: headerItemsLabels.EXPORT_PROJECTS,
            action: actions.header.exportItems,
            disabled: !hasRows,
            actionData: { gridName: gridsNames.ADMINISTRATION_MAT_SETTINGS_PROJECTS }
          },
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.header.onRemoveProjectsClick,
            disabled: (!selectedRowsData || selectedRowsData.size === 0),
            actionData:  selectedRowsData,
          },
        ]
      }
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems}/>

        <ProjectsViewGrid actions={this.props.actions.projectsViewGridActions} />

        <PL_DialogWrapper dialogComponent={CreateProjectDialog}
                          show={this.props.sCreateProjectDialogData.get('show')}
                          actions={this.props.actions.createProjectDialogActions}
                          sData={this.props.sCreateProjectDialogData}
                          reloadParentComponent={this.reloadPage}
        />

      </div>
    );
  }
}

ProjectsView.propTypes = {
  sGrid: PropTypes.object,
};


import Network from 'infrastructure/js/modules/network';

export function getShipmentsByDataQuery(query) {
  return Network.post(`shipments/items`, query);
}

export function confirmShipment(id) {
    return Network.post(`shipments/${id}/confirmshipment`)
}


export function rejectShipment(id) {
  return Network.put(`shipments/${id}/rejection`)
}

export function downloadShipment(id) {
  return Network.get(`shipments/${id}/document`,{}, {blobResponse:true})

}

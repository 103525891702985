export const ImportTypes = {
  IMPORT_ROLLS:'rolls',
  IMPORT_SPOOLS:'spools',
  IMPORT_SHIPMENTS: 'shipment',
  IMPORT_WO:'wos',
  IMPORT_KITS:'kits',
  IMPORT_GROUPS:'groups',
  IMPORT_TOOLS:'tools',
  IMPORT_TAGS:'tags',
  IMPORT_KIT_TYPES:'kittypes',
  IMPORT_TOOL_TYPES:'tooltypes',
  IMPORT_PART_TYPES:'parttypes',
  IMPORT_PROJECTS:'projects',
  IMPORT_MATERIAL_TYPES:'materialtypes',
  IMPORT_GROUP_TYPES:'grouptypes',
  IMPORT_OPERATION_SEQUENCE:'operationSequence',
  IMPORT_NESTS_MULTI_ROLLS:'reportedCutsAndKitsMultiRolls',
  IMPORT_NESTS_ANY_ROLLS:'reportedCutsAndKitsAnyRolls',
  IMPORT_NESTS:'reportedCutsAndKits',
  IMPORT_NESTS_WITH_ROLL: 'importCutPlanWithRoll'
};

export const ImportAcceptFileTypes = {
  CSV:'.csv',
  JSON:'.json'
};

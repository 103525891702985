import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {navigationStates} from '../../../../enums/navigationStates';
import Link from '../../Layout/Link/link';
import Label from 'infrastructure/js/components/Label/label';

require('./moreInfoCell.scss');

export default class MoreInfoCell extends React.PureComponent {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.cells.moreInfo.');
  }

  render() {
    let data = this.props.value;
    if (!data) {
      return '';
    }

    return (<div className="kits-more-info-cell">
      <label>{this.labels.get('workOrder')}
        <Link label={data.workOrder} id={data.workOrderId} type={navigationStates.WORKORDER}/>
      </label>
      <label>{this.labels.get('partType')}
        <Label text={data.part} />
      </label>
      <label>{this.labels.get('project')}
        <Label text={data.project} />
      </label>
    </div>);
  }
}

MoreInfoCell.propTypes = {
  value : PropTypes.object
};





import React, {Component} from 'react';

import PL_Wizard  from 'infrastructure/js/components/Wizard/wizard';
import UploadFileStep from '../ImportFiles/SubComponents/uploadFileStep.js';
import ImportFileStep from '../ImportFiles/SubComponents/importFileStep.js';
import SelectNestsFirstStep from './SubComponents/SelectNestsFirstStep';
import SelectNestsSecondStep from './SubComponents/SelectNestsSecondStep';
require('./importNestsWithRollWizard.scss');

export default class ImportNestsWithRollWizard extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  getSteps = ()=> {
    return {
      'step_1':   <UploadFileStep/>,
      'step_2':   <SelectNestsFirstStep/>,
      'step_3':   <SelectNestsSecondStep/>,
      'step_4':   <ImportFileStep/>
    };
  };

  render() {
    return (
      <PL_Wizard wizardName="IMPORT_NESTS_WITH_ROLL" steps={this.getSteps()} {...this.props} form="wizard_form_IMPORT_NESTS_WITH_ROLL"/>
    );
  }
}











import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Link, routerShape} from "react-router-dom";
import {LoginInput, LoginLayout} from '../LoginLayout/loginLayout';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Button from 'infrastructure/js/components/controls/Button/button'

require('./latencyCalcPage.scss');

export default class LatencyCalcPage extends Component{

  calc = () => {
    this.props.actions.calcLatency();
  };

  renderResult = (value1) => {
    if (!value1) {
      return null;
    }

    return <label className="result">
     {"Average Response Time: " + value1.value + "ms"}
    </label>
  };

  renderErrorMessage = (value) => {
    if (!value) {
      return null;
    }

    return <label className="error">
      {"An error has occurred"}
    </label>
  };

  renderWaitMessage = () => {
    if (!this.props.sData.get("loading")) {
      return null;
    }

    return <label className="wait">
      {"please wait..."}
    </label>
  };

  render() {
    return (
      <div className="latency-calc">
        <LoginLayout>

          <h1>Latency Calculator</h1>

          <label className="description">
            Click the button below to estimate the latency from your browser to Plataine services.
          </label>

          {this.renderWaitMessage()}

          {this.renderErrorMessage(this.props.sData.get("hasError"))}

          <Button id='login' className="start" bsStyle="primary" type="submit" loading={this.props.sData.get("loading")} disabled={this.props.sData.get("loading")} onClick={this.props.handleSubmit(this.calc)}>Start</Button>

          {this.renderResult(this.props.sData.get("result"))}

        </LoginLayout>
      </div>
    );
  }
}

LatencyCalcPage.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};



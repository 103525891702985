import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import moment from 'moment';
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import {PL_WeekdayAndTime as WeekdayAndTime} from "infrastructure/js/components/controls/WeekdayAndTime/weekdayAndTime";
require('./createDeviceAppDialog.scss');

class CreateDeviceAppDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.rfidsettings.dialog.createdevice.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;
  }

  componentDidMount() {

    if (!this.isEditMode) {
      return;
    }
    let daysOptions = DateTimeHelper.getDaysOfWeekOptions();
    let initialValues = {
      deviceAppName: this.itemToEdit.username,
      id: this.itemToEdit.id,
      dayOfWeekWeekdayAndHourFrom: this.itemToEdit.updateWindowSlot ? daysOptions.find(day => this.itemToEdit.updateWindowSlot.from.dayName === day.value) : null,
      dayOfWeekWeekdayAndHourTo :this.itemToEdit.updateWindowSlot ? daysOptions.find(day => this.itemToEdit.updateWindowSlot.to.dayName === day.value) : null,
      //todo - this is needed for the form to recognize there is a value in the field -
      timeWeekdayAndHourFrom: moment(this.itemToEdit.updateWindowSlot.from.dayTime, 'HH:mm'),
      timeWeekdayAndHourTo: moment(this.itemToEdit.updateWindowSlot.to.dayTime, 'HH:mm')
    };
    this.props.initialize(initialValues);
  }
  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  onSubmit = (data) => {
    this.props.actions.submit(data, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide =() => {
    this.props.actions.hide();
  };

  maxLength30 = Validation.maxLength(30);
  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    return (
      <Dialog
        id="create-device-app-dialog"
        className="create-device-app-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('name')+'*'}
                      htmlFor="deviceAppName"
                      className="inline left-side">
          <TextField id="deviceAppName"
                     name="deviceAppName"
                     className="short-textfield"
                     validate={[Validation.required, this.maxLength30]}/>
        </InputSection>

        <div>
          <WeekdayAndTime initialTime={this.itemToEdit && this.itemToEdit.updateWindowSlot ? moment(this.itemToEdit.updateWindowSlot.from.dayTime, 'HH:mm') : null } label={this.labels.get('installUpdatesFrom') + '*'} id="WeekdayAndHourFrom" />
          <WeekdayAndTime initialTime={this.itemToEdit && this.itemToEdit.updateWindowSlot ? moment(this.itemToEdit.updateWindowSlot.to.dayTime, 'HH:mm'): null} label={this.labels.get('installUpdatesTo') + '*'} id="WeekdayAndHourTo" />
        </div>

      </Dialog>
    );
  }
}

CreateDeviceAppDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'createDeviceAppDialog',
  }
)(CreateDeviceAppDialog);




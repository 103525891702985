import React from "react";
import { shape, number, arrayOf, string, bool } from "prop-types";

import { createLabelHelper } from "../../../../../../infrastructure/js/utils/labelHelper";
import Link from "../../../Common/Layout/Link/link";
import Label from "infrastructure/js/components/Label/label";
import PropTypes from "prop-types";
export default class RelatedEntitiesCell extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper("mat.materialScrap.grid.columns.relatedEntities.");
  }

  renderParent = () => {
    const { value } = this.props;
    let result = "", addComma = false;
    if (0 !== value.entities.rolls) {
      result += this.labels.get("rolls", "xxx", { amount: value.entities.rolls });
      addComma = true;
    }
    if (0 !== value.entities.kits) {
      if (addComma) {
        result += ", ";
      }
      result += this.labels.get("kits", "xxx", { amount: value.entities.kits });
      addComma = true;
    }
    if (0 !== value.entities.workOrders) {
      if (addComma) {
        result += ", ";
      }
      result += this.labels.get("workOrders", "xxx", { amount: value.entities.workOrders });
    }

    return <span key="baseCell" className="material-scrap-grid-item"><Label text={result} /> </span>;
  }

  render() {
    let { value } = this.props;
    if (!value) {
      return null;
    }

    if(value.isParent){
      return this.renderParent()
    } else {
      return <Link type={`NS_${value?.assetType}`} id={value?.assetId} label={value?.businessId}/>
    }
  }
}

RelatedEntitiesCell.propTypes = {
  value: PropTypes.shape({
    isParent: PropTypes.bool,
    assetId: PropTypes.number,
    assetType: PropTypes.string,
    businessId: PropTypes.string,
    entities: PropTypes.shape({
      kits: PropTypes.number,
      workOrders: PropTypes.number,
      rolls: PropTypes.number
    }),
  })
};





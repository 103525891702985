import { actionTypes as actionTypes } from '../../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import { List, Map} from 'immutable';

let defaultState = {
  show: false,
  loading: false,
  entitiesTypes: List(),
  preselectedAssets: List(),
  boEntityFilter: '',
  errors: List(),
};

export default function(state = Map(defaultState), action) {
  switch(action.type) {
    case actionTypes.PRINT_PDF_LABELS_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case actionTypes.PRINT_PDF_LABELS_DIALOG_SUBMIT_FINISHED:
      return state.set('loading', false);

    case actionTypes.PRINT_PDF_LABELS_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ? List(action.payload.preselectedAssets) : List())
        .set('entitiesTypes', action.payload && action.payload.entitiesTypes ? List(action.payload.entitiesTypes) : '')
        .set('boEntityFilter', action.payload && action.payload.boEntityFilter ? action.payload.boEntityFilter : '')
        .set('show', true);

    case actionTypes.PRINT_PDF_LABELS_DIALOG_HIDE:
      return Map(defaultState);

    case actionTypes.PRINT_PDF_LABELS_DIALOG_UPDATE_ASSETS:
      let newData =  (action.payload === null) ? null : List(action.payload)
      return state.set('preselectedAssets', newData);
      // return state.set('preselectedAssets', List(action.payload));

    case actionTypes.PRINT_PDF_LABELS_DIALOG_UPDATE_ERRORS:
      return state.set('errors', List([action.payload, ...state.get('errors')]));

    default:
      return state;
  }
}

import { actionTypes } from '../../../actions/Wizards/ImportAssetsViaBarcodeWizard/importAssetsViaBarcodeWizardActions.js';
import { Map } from 'immutable';
const defaultState = {
  gridSettings: null,
  delimiter: null,
  assets: [],
  show: false,
  loading: false,
  errors: [], // [{ roll: { }, errors: ["", ""] }, ...]
  validAssets: [],
  validatedFileData: null,
  importType: ''
}

export default function importAssetsViaBarcodeWizardReducer(state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_SHOW: {
      return state.set('importType', action.payload.importType)
        .set('show', true)
        .set('loading', false)
      // return state.set("show", true).set("loading", false);
    }
    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_HIDE: {
      return Map(defaultState);
    }
    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_SET_GRID_SETTINGS: {
      return state.set("loading", false).set('gridSettings', action.payload.gridSettings).set('delimiter', action.payload.delimiter);
    }
    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_SET_LOADING: {
      return state.set("loading", action.payload);
    }
    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_ASSET_SCANNED: {
      return state.set('loading', false).update('assets', (assets) => { return [...assets, action.payload]; });
    }

    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_SUBMIT_SCAN_ASSETS_FINISHED: {
      return state.set('validatedFileData', action.payload.validatedFileData)
                  .set('validAssets', action.payload.validAssets);
    }

    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_SUBMIT_SCAN_ASSETS_FAILED: {
      return state.set("errors", []).set('validAssets', []).set("loading", false);
    }
    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_REMOVE_ALL_ERRORED_ASSETS: {
      let errorCIDs = state.get("errors").map(error => error.cid);
      return state
        .set("errors", [])
        .update("assets", assets => assets.filter(asset => !errorCIDs.includes(asset.cid)));
    }
    case actionTypes.IMPORT_ASSETS_VIA_BARCODE_REMOVE_ASSET: {
      let validAssets = state.get('validAssets'), cid = validAssets[action.payload].cid;
      validAssets.splice(action.payload, 1);
      return state
        .update('assets', assets => assets.filter((asset) => { return asset.cid !== cid; }))
        .set('validAssets', [...validAssets]);
    }
  }

  return state;
}

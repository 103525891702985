import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cn from 'classnames';

import './buttonSelect.scss';

const styles = {
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        // hide input without removing it from the DOM because 'hide menu on click outside' relies on the input's focus state
        visibility: 'hidden',
        overflow: 'hidden',
        width: 0,
        height: 0,
        padding: 0,
    }),
};

function ButtonSelect({ options, icon, className, onButtonClick, onSelect, disabled, buttonData }) {
    const selectMenuElement = useRef(null);

    const handleChange = (data) => {
        onSelect(data);
    };

    const handleButtonClick = () => {
        onButtonClick?.(buttonData);
    };

    const formatOptionLabel = ({ label }) => <div className='pl-select-option-label'>{label}</div>;

    const { label } = buttonData;

    return (
        <div className={cn('pl-select-button-wrapper', className)}>
            {icon && <div className='pl-select-button-icon'>{icon}</div>}

            <div className={cn('pl-select-button', { disabled })}>
                <button onClick={handleButtonClick} disabled={disabled}>
                    {label}
                </button>
                <Select
                    className='pl-select'
                    classNamePrefix='pl-select'
                    options={options}
                    formatOptionLabel={formatOptionLabel}
                    onChange={handleChange}
                    styles={styles}
                    menuPortalTarget={selectMenuElement.current}
                    isDisabled={disabled}
                />
            </div>
            <div className='pl-select-menu-portal' ref={selectMenuElement} />
        </div>
    );
}

ButtonSelect.propTypes = {
    options: PropTypes.array,
    icon: PropTypes.object,
    className: PropTypes.string,
    onButtonClick: PropTypes.func,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    buttonData: PropTypes.object,
};

export default ButtonSelect;

import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

export function getSortedWeekdays() {
  const weekDays = DateTimeHelper.getDaysOfWeekOptions();
  const firstDayOfTheWeek = PermissionManager.getOrgPreferences().startDayOfWeek;
  const indexOfNewFirstDayOfWeek = weekDays.findIndex(
    (day) => day.value.toLowerCase() === firstDayOfTheWeek.toLowerCase()
  );

  const daysUpToNewFirstDay = weekDays.slice(0, indexOfNewFirstDayOfWeek);
  const daysAfterNewFirstDay = weekDays.slice(indexOfNewFirstDayOfWeek);

  return daysAfterNewFirstDay.concat(daysUpToNewFirstDay);
}

export function getWeekDaysAndDates(weekStartDate) {
  if (weekStartDate) {
    const weekDays = Array.from(Array(7));
    return weekDays.map((_, i) => {
      const newDayDate = weekStartDate.clone().add(i, 'd');
      return {
        date: newDayDate,
        day: newDayDate.format('dddd'),
      };
    });
  }

  return [];
}

export function convertTimeStringToDate(timeStr) {
  const date = new Date();
  try {
    const [hour, minute] = timeStr.split(':');
    return date.setHours(Number(hour), Number(minute), 0, 0);
  } catch(e) {
    console.error('failed to convert time string to date:', e)
    return date
  }
}
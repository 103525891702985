import {createLabelHelper} from "infrastructure/js/utils/labelHelper";
import Network from "../../../../infrastructure/js/modules/network";
import EnumService from "../../services/Enums/EnumService";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.getEnums = function() {
    return EnumService.getEnums().then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('fetch enums failed')
        return;
      }

      let labelHelper = createLabelHelper('');
      let result = {};

      //build lookups for combobox usage etc
      result.lookups = {};
      response.data.enums.map(e => {
        result.lookups[e.type] = e.items.map(l => {
          return {
            value: l.name,
            label: labelHelper.get(l.labelKey),
            parents: (l.parents && l.parents.length > 0) ? l.parents : e.type}
        });
      });

      //build enums
      result.enums = {};
      response.data.enums.map(e => {
        result.enums[e.type] = {};
        e.items.map(i => {
          result.enums[e.type][i.name] = i.name;
        });
      });
      localStorage['enumsData'] = JSON.stringify(result);
    }).catch(err => {
      console.error('fetch enums failed: ', err)
    });
};

import {gridsNames} from '../../enums/gridsNames';
import * as gridActionsHelper from '../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as pickListsService from '../../services/PickLists/pickListsService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_PICK_LISTS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return pickListsService.fetchPickLists(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  return rows;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import React, {Component} from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import CustomOverlay from 'infrastructure/js/components/popover/CustomOverlay/customOverlay.js';
import Button from 'infrastructure/js/components/controls/Button/button';
import MaterialHelper from '../../../../utils/materialHelper';
require('./reportedCutsOverlay.scss');

export default class ReportedCutsOverlay extends Component {

  constructor(props)
  {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.');
  }

  onCancelClick = (cut) => {
    this.props.cancelCutReportAction(cut);
  };

  getCuts = (cuts)=> {
    return cuts.map((cut, index) => {
      return <div key={`unkittedCut${index}`} className='unkitted-cut'>
        <span className='unkitted-cut-time'>{cut.m_CreatedDate}</span>
        <div className='material-businessid-name'>
          <div className='material-name-length'>{cut.material ? MaterialHelper.getMaterialAvailableLabel(cut.material.materialName, cut.material.businessId) : ''}{' - '}{cut.quantity}</div>
          <div className='material-businessid'>{cut.rollBusinessId}</div>
        </div>
        <Button id='reportCutCancelBtn' style={{display: (cut.cancelAllowed ? '': 'none')}} className='cancel-button btn-default'  onClick={() => {this.onCancelClick(cut)}}>{this.labels.get('view.cutting.overlay.cancel')}</Button>
        <hr className='hr-secondary mrgn-left-twenty'/>
      </div>
    })
  };
  getBody = (item) =>
  {
    return (

        <div className='unkitted-cuts category'>
          <label className='mrgn-left-twenty bold'>{this.labels.get('view.cutting.overlay.cutspendingkitting')}</label>
          <hr className='hr-main mrgn-left-twenty'/>
          <div className='unkitted-cuts-list'>
            {this.getCuts(item.get('unkittedCuts'))}
          </div>
        </div >
    )
  };

  render()
  {
    let item = this.props.data;
    return <CustomOverlay id='reported-cuts-custom-overlay'
                          className='reported-cuts-custom-overlay'
                          title=''
                          body={this.getBody(item)}
                          key={'reportedCuts'}>
      {this.props.children}
    </CustomOverlay>
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {AddRemoveListExt} from '../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import CsvPropertyListItem from '../../../../Administration/SettingsPage/CsvSettings/Components/CsvPropertyListItem/csvPropertyListItem';

require('./selectAttributesStep.scss');

export default class SelectAttributesStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {updateKey: 0};

    this.props.registerOnEnter(() => {
      this.props.setNextButtonEnabled(this.isNextButtonEnabled());
    });

    this.props.registerOnExit(() => {
    })
  }

  componentDidUpdate(prevProps) {

    if (this.props.csvSettingsData && !prevProps.csvSettingsData) {

      let initialValues = this.getInitialValues(this.props.csvSettingsData);
      this.props.change('csvPropertiesList',  initialValues);
    }
    if (this.props.isCurrentStep && this.props.isCurrentStep !== prevProps.isCurrentStep) {
      this.setState({updateKey: this.state.updateKey === 0 ? 1 : 0});
    }
  }

  getInitialValues = (data) => {
  if (data && data.csvData && data.csvData.settings) {

    return data.csvData.settings.map((item) => {
      //NOTE: No initial values currently in use
      // return {propertyName: item.isAdditionalField ? item.additionalField.id : item.property ? item.property.id : null}
      return {propertyName: null}
    });
  }
  return null;
};

  isNextButtonEnabled = () => {
    return true;
  }

  render() {

    let {csvSettingsData} = this.props;
    if (csvSettingsData) {

      let allOptions = csvSettingsData.csvOptions;
      // let preSelectedItemsIds = [];
      let settings = csvSettingsData.csvData.settings;

      // if (settings) {   //NOTE: No preselected id currently in use
      //
      //   settings.forEach((item) => {
      //     let id = item.isAdditionalField ? item.additionalField.id : item.property ? item.property.id : null;
      //     if (id) {
      //       preSelectedItemsIds.push(id);
      //     }
      //   });
      // }

      return (
        <div className="select-attributes-step">

          <div className="csv-properties-list-header">
            <label className="column">{this.props.labels.get('chooseAttributesStep.list.header.column')}</label>
            <label className="column">{this.props.labels.get('chooseAttributesStep.list.header.title')}</label>
            <label className="column">{this.props.labels.get('chooseAttributesStep.list.header.attribute')}</label>
          </div>

          <AddRemoveListExt
            {...this.props}
            updateKey={this.state.updateKey }
            name={'csvPropertiesList'}
            itemRendererComponent={CsvPropertyListItem}
            itemRendererOptions={allOptions}
            itemRendererData={{showCsvColumnHeader: true}}
            preSelectedItems={settings ? settings : []}
            // preSelectedItemsIds={preSelectedItemsIds}
            maxItemsToOverflow={10000}
            isViewOnly={true}
          />

          <div className="note-section-line">
            {this.props.labels.get('chooseAttributesStep.note')}
          </div>
        </div>
      );
    }
    return null;
  }
}

SelectAttributesStep.propTypes = {
  labels: PropTypes.object.isRequired
}

import Network from 'infrastructure/js/modules/network';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import * as ActivityTypeHelper from '../../utils/activityTypeHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { api as batchJobsApi } from '../BatchJobs/batchJobsActions.js';

import { api as messageDialogApi } from '../MessageDialog/messageDialogActions.js';
import { api as activityLogDialogApi } from '../ActivityLogDialog/activityLogDialogActions.js';

const actionTypes = {
  SUBMIT_IN_PROGRESS: '_SUBMIT_IN_PROGRESS',
  SUBMIT_FINISHED: '_SUBMIT_FINISHED',
};

export function getActionTypes(preffix) {
  let keys = Object.keys(actionTypes);
  let res = {};
  keys.forEach((key) => {
    res[preffix + actionTypes[key]] = preffix + actionTypes[key];
  });
  return res;
}

export function getActions(preffix) {
  return {
    submitInProgress: function (payload) {
      return { type: preffix + actionTypes.SUBMIT_IN_PROGRESS, payload: payload };
    },
    submitFinished: function (payload) {
      return { type: preffix + actionTypes.SUBMIT_FINISHED, payload: payload };
    },
  };
}

export function getJsxActions(api) {
  return {
    submit: function (data, messageDialogBuilder, reloadParentComponent) {
      return function (dispatch, getState) {
        api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
      };
    },

    submitWithProgress: function (data, messageDialogBuilder, reloadParentComponent) {
      return function (dispatch, getState) {
        api.submitWithProgress(dispatch, getState)(
          data,
          messageDialogBuilder,
          reloadParentComponent
        );
      };
    },

    stop: function () {
      return function (dispatch, getState) {
        api.stop(dispatch, getState)();
      };
    },
  };
}

export function getApiActions(actions, config) {
  return {
    submitWithProgress: function (dispatch, getState) {
      return function (data, messageDialogBuilder, reloadParentComponent) {
        dispatch(actions.submitInProgress());

        config
          .submitWithProgressMethod(data)
          .then((response) => {
            dispatch(actions.submitFinished(response));

            let validations = dialogHelper.getResponseValidationDetails(response);
            if (validations) {
              dispatch(actions.setValidationWarningsData(validations));
              return { success: false };
            }

            dispatch(actions.hide());

            if (!Network.isResponseValid(response)) {
              console.error(config.submitWithProgressMethod.name + ' failed ', response);
              messageDialogApi.responseError(dispatch, getState)(response);
              return { success: false };
            }

            //TODO: L check for all jobs
            let pollingRequest = 'batchJobs/JOB_EXECUTION_ID/status-with-printing';

            batchJobsApi
              .runJob(dispatch, getState)(response.data, { pollingRequest })
              .then((jobResponse) => {
                //TODO: L currently call with messageDialogBuilder = null
                return _handleSubmitResponse(dispatch, getState)(
                  jobResponse,
                  null,
                  reloadParentComponent
                );
              })
              .catch((error) => {
                console.error('runJob failed, catch error: ', error);
                return { success: false };
              });
          })
          .catch((error) => {
            console.error(config.submitWithProgressMethod.name + ' failed, catch error: ', error);
            dispatch(actions.submitFinished());
            dispatch(actions.hide());
          });
      }.bind(this);
    },

    submit: function (dispatch, getState) {
      return function (data, messageDialogBuilder, reloadParentComponent) {
        dispatch(actions.submitInProgress());

        return config
          ?.submitMethod?.(data)
          .then((response) => {
            dispatch(actions.submitFinished(response));

            if (!Network.isResponseValid(response)) {
              console.error(config.submitMethod.name + ' failed ', response);
              messageDialogApi.responseError(dispatch, getState)(response);
              return { success: false };
            }

            return _handleSubmitResponse(dispatch, getState)(
              response,
              messageDialogBuilder,
              reloadParentComponent
            );
          })
          .catch((error) => {
            console.error(config.submitMethod.name + ' failed, catch error: ', error);
            dispatch(actions.submitFinished());
            return error;
          });
      }.bind(this);
    },
  };
}

/////////////////////////////////////////////////////////////////////////////////////

function _getTitleFromResponse(response) {
  if (!response || !response.data) {
    return '';
  }
  //canceled items do not appear in the Activity log
  let itemsNum = response.data.totalJobItems - response.data.numberOfCanceledItems;
  let date = DateTimeHelper.FormatDateObjectToDayMonth(response.data.createTime);

  return ActivityTypeHelper.ActivityJobNameToTitle(response.data.jobName, itemsNum, date);
}

function _showActivityLog(dispatch, getState) {
  return function () {
    activityLogDialogApi.show(dispatch, getState)();
    messageDialogApi.close(dispatch, getState)();
  };
}

function _handleSubmitResponse(dispatch, getState) {
  return function (response, messageDialogBuilder, reloadParentComponent) {
    if (reloadParentComponent) {
      reloadParentComponent();
    }

    if (messageDialogBuilder) {
      let messageDialogDescriptor = messageDialogBuilder(
        response.data,
        _showActivityLog(dispatch, getState)
      );

      activityLogDialogApi.setConfigData(
        dispatch,
        getState
      )({
        activityLogId: response.data.jobExecutionId,
        activityLogTitle: _getTitleFromResponse(response),
      });

      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    }

    return { success: true, responseData: response };
  };
}

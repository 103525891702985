import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import classNames from 'classnames';
import Label from 'infrastructure/js/components/Label/label.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, {unitTypes} from 'infrastructure/js/utils/uomHelper';

require('./newKitMaterialListItem.scss');

export default class NewKitMaterialListItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.amountUnitMaxValue = PermissionManager.isWeightSupported()
      ? UnitHelper.getMaxValueForUnitType(unitTypes.WEIGHT)
      : UnitHelper.getMaxValueForUnitType(unitTypes.LENGTH)
  }


  onChangeCallback = (value,) => {
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value);
    }
  };

  renderSubstituteMaterialMark = (item) => {
    return (item && item.data && item.data.substituteMaterialId) ?
        <span className="pl pl-substitute-material-mark"></span> : null;
  };


  getComponentToRender = (value) => {
    if (!value || !value.data) {
      return null;
    }

    return (
      <div className="material-value">
        {this.renderSubstituteMaterialMark(value.data)}
        <Label className="Select-value-label" text={value.data.label}  />
      </div>
    );
  };

  render() {
    let {options, name, item, cutLength} = this.props;

    return (<div className={classNames('new-kit-material-list-item', this.props.className)} >
      <Combobox id={ name + '.[requiredMaterial]'}
                name={ name + '.[requiredMaterial]'}
                singleValueRenderer={this.getComponentToRender}
                isDisabled={true}

      />
      <TextField id={ name + '.[quantityExpectedPlies]'}
                 name={ name + '.[quantityExpectedPlies]'}
                 className="short-textfield" normalize={Normalize.number(true, 1, 999)}
                 disabled={true}
      />

      <div className="big-input-separator"></div>

      <TextField id={ name + '.[quantityKittedPlies]'}
                 name={ name + '.[quantityKittedPlies]'}
                 className="short-textfield"
                 normalize={Normalize.number(true, 0, 999)}
                 validate={item && item.isDefaultMaterial ? this.props.validate : undefined }
                 disabled={item && !item.isDefaultMaterial}
                 onChangeCallback={this.onChangeCallback}

      />

      <div className="big-input-separator"></div>

      <TextField id={ name + '.[amount]'}
                 name={ name + '.[amount]'}
                 normalize={Normalize.number(false, 0, cutLength || this.amountUnitMaxValue)}
                 disabled={item && !item.isDefaultMaterial}
                 onChangeCallback={this.onChangeCallback}
      />

      {PermissionManager.hasScrapViewPermissions() &&
        <React.Fragment>
          <div className="big-input-separator"></div>

          <TextField id={ name + '.[scrap]'}
                     name={ name + '.[scrap]'}
                     normalize={Normalize.number(false, 0, this.amountUnitMaxValue)}
                     disabled={item && !item.isDefaultMaterial}
                     onChangeCallback={this.onChangeCallback}
          />
        </React.Fragment>
      }
      </div>);
  }
}

NewKitMaterialListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};





import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';

export default class MqttSensorsSettingsViewGrid extends React.PureComponent{

  filterConfig = [  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.mqtt.sensors.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.sensorName.title'),
        filterType: filterTypes.NONE,
        width: 250,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'valueTopic',
        title: this.labels.get('columns.valueTopic.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'statusTopic',
        title: this.labels.get('columns.statusTopic.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'readCommandTopic',
        title: this.labels.get('columns.commandTopic.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
    ]
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_RFID_SETTINGS_MQTT_SENSORS}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}


import React from 'react';

import { connect } from 'react-redux';
import {reduxForm, formValueSelector  } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import MultiEntitiesMultiSearch from '../../Common/Controls/MultiEntitiesMultiSearch/multiEntitiesMultiSearch.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations';

require('./printPdfLabelsDialog.scss');

class PrintPdfLabelsDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.dialog.printPdfLabelsDialog.');

    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.props.sData.get('boEntityFilter')
    };
  }

  getDialogButtons() {
      return {
        left: [{
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.onHide,
        }],
        right: [
          {
            id:'submit',
            text: this.labels.get('footer.print'),
            bsStyle: 'primary',
            loading: this.props.sData.get('loading'),
            action:  this.props.handleSubmit(this.onSubmit),
            disabled: this.props.sData.get('loading'),
          }
        ]
      };
  }

  onSubmit = (data) => {
    let allRows = data['multi-search'];
    let assetsIds = [];
    allRows.map((row) => {
      if (row && row.assetsValue) {
        row.assetsValue.map((asset) => {
          assetsIds.push(asset.value);
        });
      }
    });
    let newData = {assetsIds};
    this.props.actions.submit(newData);
  };

  getAllSelectedAssets = () => {
    let allRows = this.props.fieldsValues;
    let allAssets = [];
    allRows.map((row) => {
      if (row && row.assetsValue) {
        row.assetsValue.map((asset) => {
          allAssets.push(asset.data);
        });
      }
    });
    return allAssets;
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onScanInputChange = (value) => {
    if (value) {
      let selectedAssets = this.getAllSelectedAssets();
      this.props.actions.addAssetByTagId(value, selectedAssets );
    }
  };

  onTagValueChange = (value, callback) => {
    if (value) {
      this.props.actions.findAssetByTagId(value, callback );
    }
  };

  onAddAssetClick = (asset) => {
    if (asset) {
      let selectedAssets = this.getAllSelectedAssets();
      this.props.actions.addAsset(asset, selectedAssets );
    }
  };


  render() {
    let preselectedAssets = this.props.sData.get('preselectedAssets');
    let errors = this.props.sData.get('errors');

    let { ...otherProps } = this.props;
    return (
      <Dialog
        id="print-pdf-labels-dialog"
        className="print-pdf-labels-dialog"
        show={this.props.sData.get('show') || false}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get('header.title')}

        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >


          <MultiEntitiesMultiSearch name="multi-search"
                                 entitiesTypes={this.entitiesTypes}
                                 validate={Validation.required}
                                 preSelectedEntities={preselectedAssets}
                                 fetchConfig={this.fetchConfig}
                                 {...otherProps }
                                 onScanInputChange={this.onScanInputChange}
                                 onTagValueChange={this.onTagValueChange}
                                 onAddAssetClick={this.onAddAssetClick}
                                 errors={errors}
          />
      </Dialog>
    );
  }
}

PrintPdfLabelsDialog = reduxForm({
    form: 'printPdfLabelsDialog',
  }
)(PrintPdfLabelsDialog);

const selector = formValueSelector('printPdfLabelsDialog');

export default PrintPdfLabelsDialog = connect( state => {
  return {  fieldsValues: selector(state, 'multi-search') }
})( PrintPdfLabelsDialog );


import { Map } from 'immutable';
import { actionTypes as scanWorkOrderPageActionTypes } from '../../../actions/Mobile/ScanWorkOrderPage/scanWorkOrderPageActions.js';

let defaultState = {
  stations: [],
  loading: false,
  error: null,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case scanWorkOrderPageActionTypes.SCAN_WORKORDER_PAGE_SUBMIT_IN_PROGRESS:
      state = state.set('loading', true).set('error', null);
      return state;
    case scanWorkOrderPageActionTypes.SCAN_WORKORDER_PAGE_SUBMIT_FINISHED:
      state = state
        .set('loading', false)
        .set('error', null)
        .set('woTasks', action.payload.tasks)
        .set('selectedStation', action.payload.selectedStation)
        .set('selectedWorkOrder', action.payload.selectedWorkOrder);
      return state;
    case scanWorkOrderPageActionTypes.SCAN_WORKORDER_PAGE_SUBMIT_FAILED:
      state = state.set('loading', false).set('error', action.payload).set('woTasks', []);
      return state;
    case scanWorkOrderPageActionTypes.SCAN_WORKORDER_PAGE_CLEAR:
      return Map(defaultState);
    case scanWorkOrderPageActionTypes.SCAN_WORKORDER_PAGE_FETCH_RESOURCE_STATIONS_READY:
      return state.set(
        'stations',
        action.payload.dataList.map((obj) => {
          return { value: obj.id, label: obj.name };
        })
      );

    default:
      return state;
  }
}

import { api as locationPageApi } from '../locationPageActions.js';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as partsService from '../../../services/Parts/partsService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_ASSET_PARTS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    return partsService.fetchPartsByStationId(locationId, query);
  }
}

function _convertToRowData(rows, serverTimeSeconds){
  let items = rows.map((obj) => {
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;









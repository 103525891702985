import Network from 'infrastructure/js/modules/network';
import {api as navigatorApi} from '../AppTopbar/navigatorActions';
import {navigationStates} from '../../enums/navigationStates';
import * as rollService from '../../services/Rolls/rollsService';
import {api as gridApi} from './smartSelectionGridActions';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import {api as locationPageApi} from '../LocationPage/locationPageActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {groupBy} from 'lodash';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    api.init(dispatch, getState)();
  }
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function() {
    const id = -1;
    const type = navigationStates.SMART_SELECTION;
    const name = createLabelHelper('mat.header.navigator.predefinedlists.').get('smartSelection');

    navigatorApi.setData(dispatch, getState)({id, type, name});
  }
}

api.addToPickList = function (dispatch, getState) {
  return function (rows, requiredMaterialConfirmationMessageBuilder) {

    let rollIds = rows.map((roll) => {return roll.id});
    let isWeight = PermissionManager.isWeightSupported();
    let requiredMaterial = getState().predefinedList.get('smartSelectionHeader').get('requiredMaterial');

    if(requiredMaterial){

        const rollsGroupedByMaterial = groupBy(rows.toArray(), roll => roll?.material?.businessId)
        const selectedAmountByMaterial = Object.entries(rollsGroupedByMaterial)
          .map(entry => entry[1]?.reduce((total, roll) => total + (isWeight ? roll.weight : roll.lengthLeft), 0))

        const someMaterialsHaveLessThanRequiredAmount = selectedAmountByMaterial.some(amount => {
          const convertedAmount = UnitHelper.serverValueToUserValue(isWeight ? unitTypes.WEIGHT : unitTypes.LENGTH, amount,2)
          return convertedAmount < requiredMaterial
        })

      if(someMaterialsHaveLessThanRequiredAmount){
        let messageDialogDescriptor = requiredMaterialConfirmationMessageBuilder(
          messageDialogApi.close(dispatch, getState),
          () => _addRollToPickList(dispatch, getState)(rollIds)
        );
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
      }
      else{
        _addRollToPickList(dispatch, getState)(rollIds);
      }

      return;
    }

    _addRollToPickList(dispatch, getState)(rollIds);

  }
};

api.reload = function(dispatch, getState) {
  return function() {
    return gridApi.reload(dispatch, getState)();
  }
};

//////////////////////////////////////////////////////////////
function _addRollToPickList (dispatch, getState) {
  return function (rollIds) {

    messageDialogApi.close(dispatch, getState)();

    let pickDateFrom, pickDateTo;
    let headerData = getState().predefinedList.get('smartSelectionHeader');

    if (PermissionManager.getOrgPreferences().smartSelectionPickDatesRangeEnabled) {
      let selectWoPickDateFrom = headerData.get('selectWoPickDateFrom');
      let selectWoPickDateTo = headerData.get('selectWoPickDateTo');
      pickDateFrom = selectWoPickDateFrom ? DateTimeHelper.ConvertFromDate(selectWoPickDateFrom) : null;
      pickDateTo = selectWoPickDateTo ? DateTimeHelper.ConvertFromDate(selectWoPickDateTo) : null;
    }
    else {
      let selectWoPickDate = headerData.get('selectWoPickDate');
      pickDateFrom = pickDateTo = selectWoPickDate ? DateTimeHelper.ConvertFromDate(selectWoPickDate) : null;
    }

    rollService.addToPickList(rollIds, pickDateFrom, pickDateTo).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('smart selection - add rolls to pick list failed');

        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
    });

    if (PermissionManager.hasLocationSmartSelectionTabPermissions()) {
      return locationPageApi.reload(dispatch, getState)();
    }
    gridApi.reload(dispatch, getState)();
  }
}

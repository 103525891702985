import * as materialsService from '../../../services/Materials/materialsService';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import DateTimeHelper from "../../../../../infrastructure/js/utils/dateTimeHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_MAT_SETTINGS_MATERIAL_TYPES, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return materialsService.fetchMaterials(query);
  }
};

function _convertToRowData(rows) {
  let items = rows.map((obj) => {
    let m_defrostingTime = obj.defrostingTime !== null ? DateTimeHelper.ConvertMinutesToHoursMinutes(obj.defrostingTime) : '';
    obj.m_defrostingTime= m_defrostingTime;
    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

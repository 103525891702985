import * as whitelistService from '../../../../services/Administration/ipWhitelistService';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from '../../../MessageDialog/messageDialogActions';

export const actionTypes = {
  IP_WHITELIST_VIEW_LOAD_STARTED: "IP_WHITELIST_VIEW_LOAD_STARTED",
  IP_WHITELIST_VIEW_LOAD_FINISHED: "IP_WHITELIST_VIEW_LOAD_FINISHED",
  IP_WHITELIST_VIEW_SAVE: "IP_WHITELIST_VIEW_SAVE",
};

const actions = {
  loadStarted() {
    return { type: actionTypes.IP_WHITELIST_VIEW_LOAD_STARTED };
  },
  loadFinished() {
    return { type: actionTypes.IP_WHITELIST_VIEW_LOAD_FINISHED }
  }
}

export const api = {
  init(dispatch, getState) {
    return () => {
      dispatch(actions.loadStarted());
      return whitelistService.getWhitelistSettings().then((response) => {

        dispatch(actions.loadFinished());
        if (!Network.isResponseValid(response)) {
          console.error('Failed to fetch Allowed list data', response);
          messageDialogApi.responseError(dispatch, getState)(response);
        }
        let data = response.data;
        return {
          enabled: data?.enabled,
          ips: data?.ipRanges?.map((range) => { return range.ipRange; }) || []
        }
      });
    }
  },

  submit(dispatch, getState) {
    return (data) => {
      dispatch(actions.loadStarted());
      return whitelistService.submitWhitelistSettings({
        enabled: data.enabled,
        ipRanges: data.ips?.map((range)  => { return { ipRange: range }; }) || []
      }).then((response) => {
        let valid = Network.isResponseValid(response);
        if (!valid) {
          console.error('Failed to update Allowed list Settings', response);
          messageDialogApi.responseError(dispatch, getState)(response);
        }
        dispatch(actions.loadFinished());
        return { success: valid };
      });
    }
  }
}

export const jsxActions = {
  init() {
    return (dispatch, getState) => api.init(dispatch, getState)()
  },
  submit(data) {
    return (dispatch, getState) => api.submit(dispatch, getState)(data);
  }
}

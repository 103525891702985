import Network from 'infrastructure/js/modules/network';
import * as dashboardService from '../../services/Reports/dashboardService.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import { api as systemApi } from '../System/systemActions';
import {navigationStates} from '../../enums/navigationStates';
import {round} from '../../utils/assetHelper';
import * as DashboardHelper from '../../components/Reports/utils/dashboardHelper';
import { List } from 'immutable';
import {api as messageDialogApi} from "../MessageDialog/messageDialogActions";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
 PROGRESS_DASHBOARDPAGE_FETCHDATA_STARTED: 'PROGRESS_DASHBOARDPAGE_FETCHDATA_STARTED',
 PROGRESS_DASHBOARDPAGE_FETCHDATA_FINISHED: 'PROGRESS_DASHBOARDPAGE_FETCHDATA_FINISHED',
 PROGRESS_DASHBOARDPAGE_FETCH_HEADER_DATA_FINISHED: 'PROGRESS_DASHBOARDPAGE_FETCH_HEADER_DATA_FINISHED',
 PROGRESS_DASHBOARDPAGE_FETCH_CHART_DATA_FINISHED: 'PROGRESS_DASHBOARDPAGE_FETCH_CHART_DATA_FINISHED',
 PROGRESS_DASHBOARDPAGE_FETCH_GRID_DATA_FINISHED: 'PROGRESS_DASHBOARDPAGE_FETCH_GRID_DATA_FINISHED',
 PROGRESS_DASHBOARDPAGE_RESET_DATA:  'PROGRESS_DASHBOARDPAGE_RESET_DATA',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function() {
    return {type: actionTypes.PROGRESS_DASHBOARDPAGE_FETCHDATA_STARTED};
  },
  fetchDataFinished: function() {
    return {type: actionTypes.PROGRESS_DASHBOARDPAGE_FETCHDATA_FINISHED};
  },
  fetchHeaderDataFinished: function(payload) {
    return {type: actionTypes.PROGRESS_DASHBOARDPAGE_FETCH_HEADER_DATA_FINISHED, payload};
  },
  fetchChartDataFinished: function(payload) {
    return {type: actionTypes.PROGRESS_DASHBOARDPAGE_FETCH_CHART_DATA_FINISHED, payload};
  },
  fetchGridDataFinished: function(payload) {
    return {type: actionTypes.PROGRESS_DASHBOARDPAGE_FETCH_GRID_DATA_FINISHED, payload};
  },
  resetState: function (payload) {
    return {type: actionTypes.PROGRESS_DASHBOARDPAGE_RESET_DATA, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function() {
  return function(dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.PROGRESS_DASHBOARD);

    navigatorApi.setLoading(true)(dispatch, getState);

    api.init(dispatch, getState)().then(() => {
      navigatorApi.setLoading(false)(dispatch, getState);
    });
  }
};

jsxActions.fetchData = function(data) {
  return function(dispatch, getState) {
    api.fetchData(dispatch, getState)(data);
  }
};

jsxActions.fetchCorrelationsData = function(data) {
  return function(dispatch, getState) {
    api.fetchCorrelationsData(dispatch, getState)(data);
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.init = function(dispatch, getState) {
  return function () {
    return api.fetchHeaderData(dispatch, getState)().then(() => {
      let label = createLabelHelper('mat.header.navigator.').get('section.reports.progressDashboard');

      let topNavigatorData = {
        name: label,
        type: navigationStates.PROGRESS_DASHBOARD,
        id: -1,
        components: []
      };

      navigatorApi.setData(dispatch, getState)(topNavigatorData);
    });
  };
};

api.fetchHeaderData = function(dispatch, getState) {
  return function(data) {
    return dashboardService.fetchHeaderData().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Dashboard Header Data Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let headerData = _createHeaderData(response.data);
      dispatch(actions.fetchHeaderDataFinished(headerData));
    })
  }
};

api.fetchData = function(dispatch, getState) {
  return function (data) {
    let loggedInUser = getState().login.get('loggedInUser');
    let isDemoMode = DashboardHelper.isDemoMode(loggedInUser);

    if (isDemoMode) {
      return api.handleChartDemoData(dispatch, getState)(data);
    }
    return api.fetchChartData(dispatch, getState)(dashboardService.fetchProgressChartData, data) ;
  }
};

api.fetchChartData = function(dispatch, getState) {
  return function(method, data) {
    dispatch(actions.fetchDataStarted());

    return method(data).then((response) => {
      dispatch(actions.fetchDataFinished());
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Dashboard Chart Data Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      let _ = _createWoProgressChartData(response.data);
      dispatch(actions.fetchChartDataFinished({chartData: _.chartData, chartMetadata: _.chartMetadata, wosCount: _.wosCount}));
    })
  }
};

api.handleChartDemoData = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.fetchDataStarted());

    return dashboardService.fetchDashboardsDemoDataSettings().then((response) => {

      if (!Network.isResponseValid(response)) {
        dispatch(actions.fetchDataFinished());
        console.error('Fetch Progress dashboard chart data failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      let settings = (response.data && response.data.settings) ? response.data.settings : [];

      let isDemoDataSet = DashboardHelper.isDashboardDemoDataSet(settings, DashboardHelper.dashboardTypes.WO_PROGRESS);

      let method = isDemoDataSet ? dashboardService.fetchProgressChartDemoData : dashboardService.fetchProgressChartData;

      return api.fetchChartData(dispatch, getState)(method, data);
    })
  }
};

api.fetchCorrelationsData = function(dispatch, getState) {
  return function (data) {
    let loggedInUser = getState().login.get('loggedInUser');
    let isDemoMode = DashboardHelper.isDemoMode(loggedInUser);

    if (isDemoMode) {
      return api.handleCorrelationsGridDemoData(dispatch, getState)(data);
    }
    return api.fetchCorrelationsGridData(dispatch, getState)(dashboardService.fetchCorrelationsData, data) ;
  }
};

api.fetchCorrelationsGridData = function(dispatch, getState) {
  return function(method, data) {
    dispatch(actions.fetchDataStarted());

    return method(data).then((response) => {
      dispatch(actions.fetchDataFinished());
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Progress dashboard - Correlations grid data failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      let kpiData = _createKpiData(response.data, data);
      let gridData = _createCorrelationsGridData(response.data);
      dispatch(actions.fetchGridDataFinished({kpiData, gridData}));
    })
  }
};

api.handleCorrelationsGridDemoData = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.fetchDataStarted());

    return dashboardService.fetchDashboardsDemoDataSettings().then((response) => {

      if (!Network.isResponseValid(response)) {
        dispatch(actions.fetchDataFinished());
        console.error('Fetch Progress dashboard - Correlations grid data failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      let settings = (response.data && response.data.settings) ? response.data.settings : [];

      let isDemoDataSet = DashboardHelper.isDashboardDemoDataSet(settings, DashboardHelper.dashboardTypes.CORRELATIONS);

      let method = isDemoDataSet ? dashboardService.fetchCorrelationsDemoData : dashboardService.fetchCorrelationsData;

      return api.fetchCorrelationsGridData(dispatch, getState)(method, data);
    })
  }
};




//////////////////////////////////////////////////////////////////

function _createHeaderData(data) {
  if (!data) {
    return null;
  }
  let labels = createLabelHelper('');
  let timePeriodOptions = DashboardHelper.convertDataToOptions(data.timePeriods, labels);
  let compareToOptions = DashboardHelper.convertDataToOptions(data.comparisonPeriods, labels);
  let categoryOptions = DashboardHelper.convertDataToOptions(data.categories, labels);
  let causeOptions = DashboardHelper.convertDataToOptions(data.defectCauses, labels);
  let woStatusOptions = DashboardHelper.convertDataToOptions(data.woStatuses, labels);
  let typeOptions = _convertCategoryTypeToOptions(data.kitTypes);

  return {timePeriodOptions, compareToOptions, categoryOptions, causeOptions, typeOptions, woStatusOptions};
}

function _createKpiData(data, filterData) {
  if (!data ) {
    return null;
  }

  let {defectsRate, defectsRatePrevPeriod, kitTypeDuration} = data;
  let kpiData = {
    defectsRate,
    defectsRatePrevPeriod,
    timePeriod: filterData.timePeriod,
    comparisonPeriod: filterData.comparisonPeriod,
  };

  if (kitTypeDuration) {
    kpiData.prodDuration = round(kitTypeDuration.prodDuration / 60); //minutes to hours
    kpiData.prodDurationPrevPeriod = round(kitTypeDuration.prodDurationPrevPeriod / 60); //minutes to hours
  }

  //TEMP HERE (FOR DEBUG ONLY)-------
  // kpiData.defectsRate = 88.12345678;
  // kpiData.defectsRatePrevPeriod = 78.234;
  // kpiData.prodDuration = 34.99;
  // kpiData.prodDurationPrevPeriod = 23.45;
  //---------------------------------

  return kpiData;
}

function _createWoProgressChartData(data) {
  // data.plannedDurationByOperation = _createDummyPlannedData(); //TEMP HERE (FOR DEBUG ONLY)
  // data.workOrders = _createDummyWOsData(); //TEMP HERE (FOR DEBUG ONLY)

  let chartData = [];
  let chartMetadata = new Map();

  if (!data || !data.workOrders || !data.plannedDurationByOperation) {
    return {chartData, chartMetadata, wosCount: 0};
  }

  if (data.plannedDurationByOperation.length === 0 && data.workOrders.length === 0) {
    return {chartData, chartMetadata, wosCount: data.workOrders.length};
  }

  let plannedOps = data.plannedDurationByOperation;
  let colors = [...DashboardHelper.chartColors];
  let colorsInUse = {};

  let labels = createLabelHelper('mat.dashboard.woProgress.');

  let plannedItem = {
    woName: labels.get('chart.planned'),
    woId: 0,
    kitName: '',
    kitId: 0,
  };

  let plannedIds = [];

  plannedOps.forEach((op, index) => {
    let key = 'op-' + op.id + '-' + index;
    plannedItem[key] = round(op.duration / 60); //minutes to hours
    let color = colors.shift();
    chartMetadata.set(key, {opName: op.name, opColor: color, opLegend: 'square'});
    chartMetadata.set(key+ '-d', {opName: op.name, opColor: '#ff0000', opLegend: 'none'});
    colorsInUse[op.id] = color;
    plannedIds.push(op.id);
  });

  chartData.push(plannedItem);

  let notInSequenceFound = false;
  let delayedFound = false;
  data.workOrders.forEach((wo, woIndex) => {
    wo.kits.forEach((kit, kitIndex) => {
      let durations = kit.actualDurations;
      let obj = {
        woName: wo.name,
        woId: wo.id,
        dueDate: wo.dueDate,
        kitName: kit.name,
        kitId: kit.id,
      };

      let isOutOfOrder = false;
      let prevPlannedOpIdIndex = -1;

      durations.forEach((op, opIndex) => {
        let key = 'op-' + op.id;
        if (!op.inSequence ) {
          key = key + '-' + woIndex + '-' + kitIndex;
          let name = labels.get('chart.tooltip.operationNotInPlan', '', {operationName: op.name});
          chartMetadata.set(key, {opName: name, opColor: '#cccccc', opLegend: 'none' });
          notInSequenceFound = true;
          obj[key] = round(op.duration / 60); //minutes to hours
          isOutOfOrder = true;
          return;
        }
        if (!isOutOfOrder) {
          let curPlannedOpIdIndex = plannedIds.indexOf(op.id);
          if (prevPlannedOpIdIndex < curPlannedOpIdIndex) {
            key = key + '-' + curPlannedOpIdIndex;
            prevPlannedOpIdIndex = curPlannedOpIdIndex
          }
          else {
            isOutOfOrder = true;
          }

        }
        if (isOutOfOrder) {
          key = key + '-' + woIndex + '-' + kitIndex;
        }
        if (op.delayed) {
          key = key + '-d';
          delayedFound = true;
        }

        if (!chartMetadata.has(key)) {
          let color = op.delayed ? '#ff0000' : (colorsInUse[op.id]) ? colorsInUse[op.id] : colors.shift();
          chartMetadata.set(key, {opName: op.name, opColor: color, opLegend: 'none' });
          if (!op.delayed) {
            colorsInUse[op.id] = color;
          }
        }

        obj[key] = round(op.duration / 60); //minutes to hours
      });

      chartData.push(obj);
    });
  });

  if (notInSequenceFound) {
    chartMetadata.set('dummy_key', {opName: labels.get('chart.legend.operationNotInPlan'), opColor: '#cccccc', opLegend: 'square' });
  }
  if (delayedFound) {
    chartMetadata.set('dummy_key2', {opName: labels.get('chart.legend.lateOperation'), opColor: '#ff0000', opLegend: 'square' });
  }

  return {chartData, chartMetadata, wosCount: data.workOrders.length};
}

function _convertToGridData(items) {
  if (items) {
    items.forEach((item) => {
      item.m_defectRate = DashboardHelper.toPercentage(item.defectRate) + ' (' + DashboardHelper.toPercentage(item.defectsNumberPrevYear) + ')';
    })
  }
}

function _createCorrelationsGridData(data) {
  if (! data || !data.defectCorrelations) {
    return {
      TOOLS: List(),
      OPERATIONS: List(),
      LOCATIONS: List(),
    }
  }

  if (data.defectCorrelations.TOOLS) {
    _convertToGridData(data.defectCorrelations.TOOLS);
  }
  if (data.defectCorrelations.OPERATIONS) {
    _convertToGridData(data.defectCorrelations.OPERATIONS);
  }
  if (data.defectCorrelations.LOCATIONS) {
    _convertToGridData(data.defectCorrelations.LOCATIONS);
  }

  return {
    TOOLS: List(data.defectCorrelations.TOOLS || []),
    OPERATIONS: List(data.defectCorrelations.OPERATIONS || []),
    LOCATIONS: List(data.defectCorrelations.LOCATIONS || []),
  };
}


function _convertCategoryTypeToOptions(data) {
  if (data) {
    return data
      .map((item) => {
        return {value: item.id, label: item.name}
      })
      .sort((typeA, typeB) => {
        return typeA.label < typeB.label ? -1 : 1
      });
  }
  return [];
}

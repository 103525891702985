import Network from 'infrastructure/js/modules/network';

export function getPartActivityLog(assetId, query) {
  return Network.post(`parts/${assetId}/activities`, query);
}

export function fetchPartsByStationId(stationId, query = {}) {
  return Network.post(`stations/${stationId}/parts/items?active=true`, query);
}

export function fetchActiveParts(query = {}) {
  return Network.post('parts/items?active=true', query);
}

export function fetchArchivedParts(query = {}) {
  return Network.post('parts/items?active=false', query);
}

export function updatePart(partId, data) {
  return Network.put(`parts/${partId}`, data);
}

export function generatePartId() {
  return Network.get('parts/generateId');
}

export function generateLayerId() {
  return Network.get('parts/layers/generateId');
}

export function createPart(data, locationId) {
  return Network.post('parts', data);
}

export function getPartDetails(assetId){
  return Network.get(`parts/${assetId}`);
}

export function getPartLayers(assetId){
  return Network.get(`parts/${assetId}/layers`);
}

export function getLastLayer(assetId){
  return Network.get(`parts/${assetId}/lastLayer`);
}

export function updatePartsStatus(data){
  return Network.polling.post('parts/updateStatus', data);
}

export function reportFiberPlacementOperation(data){
  return Network.post('parts/fiber-placement', data);
}

export function addOrReplaceSpools(data){
  const {partId, layerId} = data;
  delete data.partId;
  delete data.layerId;

  return Network.put(`parts/${partId}/layers/${layerId}`, data);
}




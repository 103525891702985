import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as activePartsPageActions} from '../../actions/ActivePartsPage/activePartsPageActions';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import {gridsNames} from '../../enums/gridsNames';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as activePartsGridActions } from '../../actions/ActivePartsPage/activePartsGridActions';
import { jsxActions as moveAssetsActions }  from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as archiveAssetsActions }   from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import ActivePartsPage from '../../components/PredefinedList/ActivePartsPage/activePartsPage';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(activePartsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      activePartsGridActions: bindActionCreators(activePartsGridActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      archiveAssetsActions:     bindActionCreators(archiveAssetsActions, dispatch),
    }
  }
}

let ActivePartsPageContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.PREDEFINED_LIST_ACTIVE_PARTS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ACTIVE_PARTS) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sMoveAssetsData:  state.dialogs.get('moveAssetsDialog'),
      sArchiveAssetsData:   state.dialogs.get('archiveAssetsDialog'),
      sLoggedInUser : state.login.get('loggedInUser'),

    };
  },
  mapDispatchToProps
)(ActivePartsPage);


export default ActivePartsPageContainer;




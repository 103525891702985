import PropTypes from 'prop-types';
import DroppableWrapper from '../droppableWrapper';
import { filterTypes } from '../../../../../enums/shiftSchedulerEnums';

const GridHeadersRow = ({ actions, children, isTemplate, cornerLabel, highlighted, setHighlighted }) => {
  const onDragEnter = (e) => {
    setHighlighted({ direction: 'all', target: e.target });
  };

  const onDragLeave = (e) => {
    if (highlighted && e.target !== highlighted.target) {
      return;
    }
    setHighlighted(null);
  };

  const dropAction = (assignmentData) => {
    if (assignmentData?.shiftData?.id === 'eraser') {
      const filterData = {
        filterBy: filterTypes.ALL,
      };
      actions.removeShifts({ filterData });
      return;
    }

    actions.assignShift(assignmentData);
  };

  return (
    <div className="grid-headers-row-wrapper">
      <DroppableWrapper
        className="grid-item grid-spacer"
        action={dropAction}
        isHeader={true}
        isTemplate={isTemplate}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        setHighlighted={setHighlighted}
      >
        {cornerLabel}
      </DroppableWrapper>
      {children}
    </div>
  );
};

GridHeadersRow.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.node,
  isTemplate: PropTypes.bool,
  cornerLabel: PropTypes.string,
  highlighted: PropTypes.object,
  setHighlighted: PropTypes.func,
};

GridHeadersRow.defaultProps = {
  actions: {},
  children: '',
  isTemplate: false,
  cornerLabel: '',
  highlighted: null,
  setHighlighted: () => {},
};

export default GridHeadersRow;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Popover from 'infrastructure/js/components/popover/Popover/popover';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {capitalizeFirstLetter} from 'infrastructure/js/utils/componentUtils';

require('./alertPopover.scss');

export const AlertPopoverType = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  HELP: 'help',
  SUCCESS: 'success',
  DEFAULT: 'default',
  UNTITLED: 'untitled',
  HINT: 'hint',
}

export default class AlertPopover extends React.PureComponent {
  constructor(props){
    super(props);

    this.labels = createLabelHelper('alerts.titles.');
  }

  getIcon (popoverType) {
    switch(popoverType) {
      case AlertPopoverType.INFO:
        return 'pl pl-info-icon-blue info';
      case AlertPopoverType.HINT:
        return 'pl pl-info-icon-blue hint';
      case AlertPopoverType.HELP:
        return 'pl pl-help1 help';
      case AlertPopoverType.WARNING:
        return 'pl pl-warning-icon warning';
      case AlertPopoverType.ERROR:
        return 'pl pl-error-icon error';
      case AlertPopoverType.SUCCESS:
        return 'pl pl-check-circle-icon-blue success';
      case AlertPopoverType.DEFAULT:
        return 'pl pl-info-icon-blue default';
      case AlertPopoverType.UNTITLED:
        return '';
      default:
        console.error('AlertPopover - unknown popover type: ' + popoverType);
        return 'pl pl-info-icon-blue default';
    }
  }

  getTitle = (popoverType) => {
    if ( !popoverType || popoverType ==='default' || popoverType === 'untitled' ){
      return '';
    }
    return this.labels.get(popoverType, '') || capitalizeFirstLetter(popoverType);
  };

  getPopoverTitle = (popoverType) => {
    if(popoverType === 'untitled' ){
      return null;
    }
    return (
      <div className="alert-popover-title">
        <span className={cn('alert-icon', this.getIcon(popoverType))}/>
        <span className="alert-title-text">{this.getTitle(popoverType)} </span>
      </div>
    )
  };

  render() {
    let { className, popoverId, popoverType, popoverComponent, children, ...otherProps} = this.props;

    popoverId = popoverId || 'alertPopover' + popoverType;
    children = children ? children : <span className={cn('alert-icon ', this.getIcon(popoverType), className)}/>

    return (<Popover
      {...otherProps}
      className={cn('alert-popover ', popoverType)}
      popoverId={popoverId}
      popoverTitle={this.getPopoverTitle(popoverType)}
      popoverComponent={popoverComponent}
    >
      {children}
    </Popover>);
  }
}

AlertPopover.defaultProps = {
  popoverType: '',
  popoverId: '',
  popoverComponent: {},
};

AlertPopover.propTypes = {
  popoverType: PropTypes.string,
  popoverId: PropTypes.string,
  popoverComponent: PropTypes.any,
};

import React from 'react';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import CheckMarkCell         from '../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import WidthCell from '../../../../Common/CustomGridCells/WidthCell/widthCell';


class MaterialTypesViewGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'active', filterName: 'active', getOptions: true},
    {fieldName: 'materialName', filterName: 'materialName', getOptions: false},
  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.materialtypes.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.materialcode.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data?.businessId;
          },
        }
      },
      {
        fieldName: 'active',
        title: this.labels.get('columns.active.title'),
        filterType: filterTypes.NONE,
        filterName: 'active',
        width: 130,
        columnOptions: {
          sort: 'desc',
          cellComponent: CheckMarkCell,
          valueGetter: (params) => {
            return params.data?.active;
          },
        }
      },
      {
        fieldName: 'materialName',
        title: this.labels.get('columns.materialname.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'materialName',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data?.materialName;
          },
        }
      },
      {
        fieldName: 'width',
        title: this.labels.get('columns.width.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          sortable: false,
          cellComponent: WidthCell,
          valueGetter: (params) => {
            return {
              width: params.data.width
            };
          },
        }
      },
      {
        fieldName: 'm_defrostingTime',
        title: this.labels.get('columns.defrostingTime.title'),
        filterType: filterTypes.NONE,
        width: 170,
        columnOptions: {
          sortable: false,
          valueGetter: (params) => {
            return params.data?.m_defrostingTime;
          },
        }
      },

    ]
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_MATERIAL_TYPES}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'materialTypesViewGrid'
  }
)(MaterialTypesViewGrid);

import React, { Component } from 'react';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import {BarChart, CartesianGrid, XAxis,YAxis, Tooltip, Bar} from 'recharts';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MaterialHelper from "../../../../../utils/materialHelper";

require('./materialLenghtChart.scss');

export default class MaterialLengthChart extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.dashboard.inventory.inventoryManagement.lengthChart.');
  }

  getChartData = () => {
    if (!this.props.chartData) {
      return [];
    }

    return this.props.chartData.singleMaterialLength.map((material) => {
      let name = MaterialHelper.getMaterialFullLabel(material.materialName, material.businessId);
      if(name.length > 15){
        name = name.slice(0, 15) + '...';
      }
      return {
        shortName: name,
        lengthLabel: this.labels.get('length'),
        nameLabel: this.labels.get('name'),
        name: material.materialName,
        length: UnitHelper.serverValueToUserValue(unitTypes.LENGTH,material.totalLength, 2)
      }
    });
  };

  customTooltipOnYourLine(e) {
    if (e.active && e.payload != null && e.payload[0] != null) {
      return (<div className="custom-tooltip">
        <p>{`${e.payload[0].payload.nameLabel} ${e.payload[0].payload.name}`}</p>
        <p>{`${e.payload[0].payload.lengthLabel} ${e.payload[0].payload.length}`}</p>
      </div>);
    }
    else {
      return "";
    }
  }

  renderChart = () => {
    if(!this.props.chartData.singleMaterialLength || !this.props.chartData.singleMaterialLength.length){
      return  <div className={'empty-chart-section'}><span className={'no-results'}>{this.labels.get('noResults')}</span></div>;
    }
    let chartData = this.getChartData();

    return (
      <div className={'chart-section'}>
        <BarChart width={500} height={400} data={chartData} key={'111'} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="shortName" angle={-45}  textAnchor="end" interval={0} height={150}/>
          <YAxis />
          <Tooltip content={this.customTooltipOnYourLine}/>
         {/* <Legend  payload={[{ value:this.labels.get('legend'), type: 'square',  color:"#5DADE2", id: 'ID01' }]}/>*/}
          <Bar dataKey={'length'} fill="#5DADE2"  key={'1'}/>
        </BarChart>
      </div>
    );
  };

  render() {
    if(!this.props.chartData){
      return null;
    }

    let title = `${this.labels.get('title')} (${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`;
    if(this.props.chartData.isReachedMaxTotal){
      title = `${title} - ${this.labels.get('maxReachedTitle')}`;
    }
    return (
        <div className="length-chart">
          <div className='chart-title'>{title}</div>
          {this.renderChart()}
        </div>
    )
  }
};

import React from 'react';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import AdditionalField from '../../../Common/Layout/AdditionalField/AdditionalField';
import ToolTypeHelper from '../../../../utils/toolTypeHelper';


export default class ToolAttributes extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.editassetattributes.');
    this.state = {
      isCyclesFieldDisabled: false,
      isUtlFieldDisabled: false
    }
  }

  componentDidMount() {
    this.props.actions.fetchLocations();
    this.props.actions.fetchToolTypes();
    this.props.actions.fetchAdditionalFields('TOOL');
  }


  renderAdditionalFields = ()=>{
    if(!this.props.sData.get('additionalFields')){
      return null;
    }
    return this.props.sData.get('additionalFields').map((field, index)=> {
      return (<AdditionalField key={index} field={field} index={index}/>)
    });
  };


  onCyclesCheckboxChangeCallback = (event)=>{
    this.setState({isCyclesFieldDisabled: (event.target.checked)});
    this.props.change('isMaxValueForCycles', false);
    this.props.change('nextMaintenance', null);
  };

  onUtlCheckboxChangeCallback = (event)=>{
    this.setState({isUtlFieldDisabled: (event.target.checked)});
    this.props.change('isMaxValueForUtl', false);
    this.props.change('maintenanceUsageTime_days', '');
    this.props.change('maintenanceUsageTime_hours', '');
    this.props.change('maintenanceUsageTime_minutes', '');
  };

  onMaxValueForUtlCheckboxClick = (event)=>{
    this.setState({isUtlFieldDisabled: (event.target.checked)});
    this.props.change('headerCheckbox_maintenanceUsageTime', false);
  };
  onMaxValueForCyclesCheckboxClick = (event)=>{
    this.setState({isCyclesFieldDisabled: (event.target.checked)});
    this.props.change('headerCheckbox_nextMaintenance', false);
  };

  getToolTypesOptions = () => {
    const toolTypes = this.props.sData.get('toolTypes');
    return toolTypes.map(toolType => ({
      ...toolType,
      label: ToolTypeHelper.getToolTypeFullLabel(toolType.data.description, toolType.data.businessId)
    }))
  }

  maxLength30 = Validation.maxLength(30);

  render() {
    let locations = this.props.sData.get('locations');
    return (
      <div>

        <InputSection label={this.labels.get('location')} htmlFor="location" className="inline left-side">
          <Combobox id="location" name="location" options={locations} />
        </InputSection>

        <InputSection label={this.labels.get('sublocation')} htmlFor="subLocation" className="inline right-side">
          <TextField id="subLocation" name="subLocation" validate={this.maxLength30}/>
        </InputSection>

        <InputSection label={this.labels.get('type')} htmlFor="type" className="inline left-side">
          <Combobox id="type" name="type"  options={this.getToolTypesOptions()} />
        </InputSection>

        <InputSection label="" className="spacer no-margin inline right-side"></InputSection>

        <InputSection label={this.labels.get("utlNextMaintenance")} htmlFor="maintenanceUsageTime" className="inline left-side" onCheckboxChangeCallback={this.onUtlCheckboxChangeCallback}>
          <TimeField id="maintenanceUsageTime" name="maintenanceUsageTime" className="inline right-side" isDays={true} hoursCount={true} disabled={this.state.isUtlFieldDisabled}/>
        </InputSection>

        <InputSection htmlFor= "isMaxValueForUtl" className="inline right-side padding-top">
          <Checkbox name= "isMaxValueForUtl" id= "isMaxValueForUt" label={this.labels.get('maxValueForUtl')} onClick={this.onMaxValueForUtlCheckboxClick}/>
        </InputSection>

        <InputSection label={this.labels.get('nextmaintenance')} htmlFor="nextMaintenance" className="inline left-side" onCheckboxChangeCallback={this.onCyclesCheckboxChangeCallback}>
          <TextField id="nextMaintenance" name="nextMaintenance" className="short-textfield" normalize={Normalize.number(true, -999, 999)} disabled={this.state.isCyclesFieldDisabled} />
        </InputSection>

        <InputSection htmlFor= "isMaxValueForCycles" className="inline right-side padding-top">
          <Checkbox name= "isMaxValueForCycles" id= "isMaxValueForCycles" label={this.labels.get('maxValueForCycles')} onClick={this.onMaxValueForCyclesCheckboxClick}/>
        </InputSection>

        <div>
          {this.renderAdditionalFields()}
        </div>
      </div>

    );
  }
}




import React from 'react';
import PropTypes from 'prop-types';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import PL_FileChooser from 'infrastructure/js/components/controls/FileChooser/fileChooser';
import {List} from "immutable";

require('./createAttachmentDialog.scss');

class CreateAttachmentDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.assetpage.createAttachmentDialog.');
    this.entitiesTypes = [this.props.asset.get('objectType')];
    this.preselectedAssets = List([this.props.asset.toJS()]);

    this.state = {selectedFile: null};
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
        text:   this.dialogLabels.get('cancel'),
        action: this.onHide,
        disabled: this.props.sData.get('loading')
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.upload'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading')
        }
      ]
    };
  }

  onSubmit = () => {
    if(!this.preselectedAssets.get(0)){
      return;
    }
    if(this.props.asset.isWo){
      let asset = { type: 'WOS', ids:[this.preselectedAssets.get(0).id]};
      this.props.actions.submit(this.state.selectedFile, asset, this.props.reloadParentComponent);
      return;
    }
    let asset = { type: this.preselectedAssets.get(0).objectType + 's', ids:[this.preselectedAssets.get(0).id]};
    this.props.actions.submit(this.state.selectedFile, asset, this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get("confirmation.message.title", "", {count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop = () => {
    this.props.actions.stop();
  };

  handleSelectedFileChanged = (file) => {
    this.setState({selectedFile: file});
  };

  render() {
    let titleText = this.labels.get('header.title');

    return (
      <Dialog
        id="create-attachment-dialog"
        className="create-attachment-dialog"
        titleText={titleText}
        show={this.props.sData.get("show")}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >


        <PL_FileChooser
          placeHolder={this.labels.get('selectfileplaceholder')}
          buttonText={this.state.selectedFile ? this.dialogLabels.get('changeFile') : this.dialogLabels.get('browse')}
          accept={['.jpeg', '.pdf', '.gif', '.txt', '.doc', '.docx', '.png', '.cad', '.jpg']}
          selectedFile={this.state.selectedFile}
          onSelectedFileChanged={this.handleSelectedFileChanged} />

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'createAttachmentDialog',
  }
)(CreateAttachmentDialog);

CreateAttachmentDialog.defaultProps = {
};

CreateAttachmentDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  asset: PropTypes.object,
  sData: PropTypes.object.isRequired,

};

import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import NotificationCategoriesHelper from './notificationCategoriesHelper';

export function BuildNotification(labelKey, params, category) {
  if (NotificationCategoriesHelper.isBaseCategoryNotificationByType(category, 'DEVICE_APP')
    || NotificationCategoriesHelper.isBaseCategoryNotificationByType(category, 'ATTACHMENTS')
    || NotificationCategoriesHelper.isBaseCategoryNotificationByType(category, 'QUALIFYING_EVENTS')
    || NotificationCategoriesHelper.isBaseCategoryNotificationByType(category, 'SHIPMENT_REJECTION')
  ) {
    let labels = createLabelHelper('');
    return (
      <label>{ `${_getLabelValue(labelKey, params, labels)}` }</label>
    );
  }

  /*if(NotificationCategoriesHelper.isBaseCategoryNotificationByType(category, 'BATCH_OPERATION')){*/
    let labels = createLabelHelper('mat.wizards.notification.operationstatus.');
    return (
      <label>{ `${_getLabelValue(labelKey, params, labels)} ${_getNotificationJobDetailsToTitle(params, labels)}` }</label>
    );
  /*}*/

/*  console.error(`unsupported notification type: ${category}`);
  return null;*/
}

function _getLabelValue(labelKey, params, labels) {
  return labels.get(labelKey, '' ,params);
}

function _getNotificationJobDetailsToTitle(params, labels) {
  let stringBuilderArr = [];
  let isAllJobItemsEqualsSameStatusRes = _isAllJobItemsEqualsSameStatus(params);
  if (isAllJobItemsEqualsSameStatusRes) {
    return `- ${labels.get(isAllJobItemsEqualsSameStatusRes.key, '' ,params)}`;
  } else {
     params.filter(obj =>  obj.key !== 'totalJobItems' && obj.value !== '0') //Remove totalJobItems  - should not be filtered.
           .forEach(item => stringBuilderArr.push( ` ${item.value} ${labels.get(item.key, '' ,params)}`));

    return  `- ${stringBuilderArr.join()}`;
  }
}

function _isAllJobItemsEqualsSameStatus(params) {
  let found = params.find(obj => obj.key === 'totalJobItems');
  if (found) {
    let totalJobItemsCount = found.value;

    return params.filter(item => item.key !== 'totalJobItems' && item.key !== 'numberOfItemsWithWarnings') //Remove totalJobItems and numberOfItemsWithWarnings - should not be filtered.
      .find(obj => obj.value === totalJobItemsCount);
  }
  return false;
}

import React from 'react';
import PropTypes from 'prop-types';

import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import {numberToLetters} from '../../../utils/csvSettingsHelper';
import Label from 'infrastructure/js/components/Label/label.js';

require('./csvPropertyListItem.scss');

export default class CsvPropertyListItem extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChangeCallback = (value, oldValue) => {
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
  };

  getComponentToRender(props){
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;
    return <span className={option.data && option.data.isMandatory ? 'mark-as-mandatory' : '' }>{option.label}</span>
  };

  renderCsvColumnHeader = () => {
    let {itemRendererData, preSelectedItem} = this.props;
    if (itemRendererData && itemRendererData.showCsvColumnHeader) {
      let header = preSelectedItem ? preSelectedItem.header : null;
      if (header) {
        return <Label className="column column-2" text={header}/>;
      }
      return <div className="column column-2"/>;
    }
    return null;
  }

  render() {
    let {options, name, index, itemRendererData: { formType } } = this.props;

    return (<div className="csv-property-list-item">
              <label id={name + '.[columnName]'}
                         name={ name + '.[columnName]'}
                         className="column column-1"
              >
                {formType === "BARCODE" ? (index + 1) : numberToLetters(index+1)}
              </label>

              {this.renderCsvColumnHeader()}

              <Combobox id={ name + '.[propertyName]'}
                        name={ name + '.[propertyName]'}
                        options={options}
                        parse={Parse.comboValueOnly()}
                        format={Format.findOptionByValue(options)}
                        onChangeCallback={this.onChangeCallback}
                        className="column column-3"
                        menuPlacement="auto"
                        singleValueRenderer={this.getComponentToRender}
                        optionRenderer={this.getComponentToRender}
              />
           </div>);
  }
}

CsvPropertyListItem.defaultProps = {
  options: [],
};

CsvPropertyListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array,
  itemRendererData: PropTypes.shape({
    formType: PropTypes.string
  })
};





import { actionTypes as actionTypes } from '../../../actions/Dialogs/AboutDialog/aboutDialogActions';
import { List, Map} from 'immutable';

let defaultState = {
  show: false,
};

export default function(state = Map(defaultState), action) {
  switch(action.type) {
    case actionTypes.ABOUT_DIALOG_SHOW:
      return state.set('show', true);

    case actionTypes.ABOUT_DIALOG_HIDE:
      return Map(defaultState);

    default:
      return state;
  }
}

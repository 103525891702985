import Network from 'infrastructure/js/modules/network';
import * as batchJobsService from '../../services/BatchJobs/batchJobsService';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import * as ActivityTypeHelper from '../../utils/activityTypeHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { api as batchJobsApi } from '../BatchJobs/batchJobsActions.js';

import { api as messageDialogApi } from '../MessageDialog/messageDialogActions.js';
import { api as activityLogDialogApi } from '../ActivityLogDialog/activityLogDialogActions.js';
import { api as fetchEntitiesApi } from '../FetchEntities/fetchEntitiesActions.js';

const actionTypes = {
  FETCH_ASSETS_READY: '_FETCH_ASSETS_READY',
  SUBMIT_IN_PROGRESS: '_SUBMIT_IN_PROGRESS',
  SUBMIT_FINISHED: '_SUBMIT_FINISHED',
  SHOW: '_SHOW',
  HIDE: '_HIDE',
  SAVE_CANCEL_TOKEN: '_SAVE_CANCEL_TOKEN',
  SET_VALIDATION_WARNINGS_DATA: '_SET_VALIDATION_WARNINGS_DATA',
  HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: '_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
  TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX: '_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
};

export function getActionTypes(preffix) {
  let keys = Object.keys(actionTypes);
  let res = {};
  keys.forEach((key) => {
    res[preffix + actionTypes[key]] = preffix + actionTypes[key];
  });
  return res;
}

export function getActions(preffix) {
  return {
    fetchAssetsReady: function (payload) {
      return { type: preffix + actionTypes.FETCH_ASSETS_READY, payload: payload };
    },
    submitInProgress: function (payload) {
      return { type: preffix + actionTypes.SUBMIT_IN_PROGRESS, payload: payload };
    },
    submitFinished: function (payload) {
      return { type: preffix + actionTypes.SUBMIT_FINISHED, payload: payload };
    },
    show: function (payload) {
      return { type: preffix + actionTypes.SHOW, payload: payload };
    },
    hide: function () {
      return { type: preffix + actionTypes.HIDE };
    },
    saveCancelToken: function (payload) {
      return { type: preffix + actionTypes.SAVE_CANCEL_TOKEN, payload: payload };
    },
    setValidationWarningsData(payload) {
      return { type: preffix + actionTypes.SET_VALIDATION_WARNINGS_DATA, payload: payload };
    },
    hideIgnoreValidationWarningsCheckbox() {
      return { type: preffix + actionTypes.HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX };
    },
    toggleIgnoreValidationWarningsCheckbox(payload) {
      return {
        type: preffix + actionTypes.TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX,
        payload: payload,
      };
    },
  };
}

export function getJsxActions(api) {
  return {
    show: function (data) {
      return function (dispatch, getState) {
        api.show(dispatch, getState)(data);
      };
    },

    hide: function () {
      return function (dispatch, getState) {
        api.hide(dispatch, getState)();
      };
    },

    submit: function (data, messageDialogBuilder, reloadParentComponent) {
      return function (dispatch, getState) {
        api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
      };
    },

    submitWithProgress: function (data, messageDialogBuilder, reloadParentComponent) {
      return function (dispatch, getState) {
        api.submitWithProgress(dispatch, getState)(
          data,
          messageDialogBuilder,
          reloadParentComponent
        );
      };
    },

    fetchEntities: function (fetchConfig, callback) {
      return function (dispatch, getState) {
        fetchEntitiesApi.fetchEntities(dispatch, getState)(fetchConfig, callback, true);
      };
    },
    fetchEntitiesWithRelatedAssets: function (fetchConfig, callback) {
      return function (dispatch, getState) {
        fetchEntitiesApi.fetchEntitiesWithRelatedAssets(dispatch, getState)(
          fetchConfig,
          callback,
          true
        );
      };
    },
    stop: function () {
      return function (dispatch, getState) {
        api.stop(dispatch, getState)();
      };
    },

    hideIgnoreValidationWarningsCheckbox() {
      return function (dispatch, getState) {
        api.hideIgnoreValidationWarningsCheckbox(dispatch, getState)();
      };
    },

    toggleIgnoreValidationWarningsCheckbox(value) {
      return function (dispatch, getState) {
        api.toggleIgnoreValidationWarningsCheckbox(dispatch, getState)(value);
      };
    },
  };
}

export function getApiActions(actions, config) {
  return {
    show: function (dispatch, getState) {
      return function (data) {
        dispatch(actions.show(data));
      };
    },
    hide: function (dispatch, getState) {
      return function () {
        dispatch(actions.hide());
      };
    },

    submitWithProgress: function (dispatch, getState) {
      return function (data, messageDialogBuilder, reloadParentComponent) {
        dispatch(actions.submitInProgress());

        config
          .submitWithProgressMethod(data)
          .then((response) => {
            dispatch(actions.submitFinished(response));

            let validations = dialogHelper.getResponseValidationDetails(response);
            if (validations) {
              dispatch(actions.setValidationWarningsData(validations));
              return { success: false };
            }

            dispatch(actions.hide());

            if (!Network.isResponseValid(response)) {
              console.error(config.submitWithProgressMethod.name + ' failed ', response);
              messageDialogApi.responseError(dispatch, getState)(response);
              return { success: false };
            }

            //TODO: L check for all jobs
            let pollingRequest = 'batchJobs/JOB_EXECUTION_ID/status-with-printing';

            batchJobsApi
              .runJob(dispatch, getState)(response.data, { pollingRequest })
              .then((jobResponse) => {
                //TODO: L currently call with messageDialogBuilder = null
                return _handleSubmitResponse(dispatch, getState)(
                  jobResponse,
                  null,
                  reloadParentComponent
                );
              })
              .catch((error) => {
                console.error('runJob failed, catch error: ', error);
                return { success: false };
              });
          })
          .catch((error) => {
            console.error(config.submitWithProgressMethod.name + ' failed, catch error: ', error);
            dispatch(actions.submitFinished());
            dispatch(actions.hide());
          });
      }.bind(this);
    },

    submit: function (dispatch, getState) {
      return function (data, messageDialogBuilder, reloadParentComponent) {
        dispatch(actions.submitInProgress());

        config
          ?.submitMethod?.(data, this.saveCancelToken(dispatch, getState))
          .then((response) => {
            dispatch(actions.submitFinished(response));

            let validations = dialogHelper.getResponseValidationDetails(response);
            if (validations) {
              dispatch(actions.setValidationWarningsData(validations));
              return { success: false };
            }

            dispatch(actions.hide());

            if (!Network.isResponseValid(response)) {
              console.error(config.submitMethod.name + ' failed ', response);
              messageDialogApi.responseError(dispatch, getState)(response);
              return { success: false };
            }

            return _handleSubmitResponse(dispatch, getState)(
              response,
              messageDialogBuilder,
              reloadParentComponent
            );
          })
          .catch((error) => {
            console.error(config.submitMethod.name + ' failed, catch error: ', error);
            dispatch(actions.submitFinished());
            dispatch(actions.hide());
          });
      }.bind(this);
    },

    stop: function (dispatch, getState) {
      return function () {
        let cancelToken = getState().dialogs.getIn([config.dialogReduxStorageName, 'cancelToken']);
        batchJobsService.stop(cancelToken).then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Stop failed');
            messageDialogApi.responseError(dispatch, getState)(response);
            return { success: false };
          }
        });
      };
    },

    saveCancelToken: function (dispatch, getState) {
      return function (response) {
        if (response && response.data && response.data.jobInstanceId) {
          dispatch(actions.saveCancelToken(response.data.jobInstanceId));
        }
      };
    },

    hideIgnoreValidationWarningsCheckbox(dispatch, getState) {
      return function () {
        dispatch(actions.hideIgnoreValidationWarningsCheckbox());
      };
    },

    toggleIgnoreValidationWarningsCheckbox(dispatch, getState) {
      return function (value) {
        dispatch(actions.toggleIgnoreValidationWarningsCheckbox(value));
      };
    },
  };
}

/////////////////////////////////////////////////////////////////////////////////////

function _getTitleFromResponse(response) {
  if (!response || !response.data) {
    return '';
  }
  //canceled items do not appear in the Activity log
  let itemsNum = response.data.totalJobItems - response.data.numberOfCanceledItems;
  let date = DateTimeHelper.FormatDateObjectToDayMonth(response.data.createTime);

  return ActivityTypeHelper.ActivityJobNameToTitle(response.data.jobName, itemsNum, date);
}

function _showActivityLog(dispatch, getState) {
  return function () {
    activityLogDialogApi.show(dispatch, getState)();
    messageDialogApi.close(dispatch, getState)();
  };
}

function _handleSubmitResponse(dispatch, getState) {
  return function (response, messageDialogBuilder, reloadParentComponent) {
    if (reloadParentComponent) {
      reloadParentComponent();
    }

    if (messageDialogBuilder) {
      let messageDialogDescriptor = messageDialogBuilder(
        response.data,
        _showActivityLog(dispatch, getState)
      );

      activityLogDialogApi.setConfigData(
        dispatch,
        getState
      )({
        activityLogId: response.data.jobExecutionId,
        activityLogTitle: _getTitleFromResponse(response),
      });

      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    }

    return { success: true };
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';

import Header, { headerItemsLabels } from '../../../Common/Header/header';
import SchedulerOperationsGrid from './SchedulerOperationsGrid/schedulerOperationsGrid';
import ScheduleTaskDialog from './Dialogs/ScheduleTaskDialog/scheduleTaskDialog';
import AutoSchedulerDialog from './Dialogs/AutoSchedulerDialog/autoSchedulerDialog';
import CreateTaskDialog from './Dialogs/CreateTaskDialog/createTaskDialog';
import EditTaskAttributesDialog from '../../../Dialogs/EditTaskAttributesDialog/editTaskAttributesDialog';
import { gridsNames } from '../../../../enums/gridsNames';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';
import { getEnumValue, enumTypes } from '../../../../utils/enumHelper';

require('./schedulerOperationsView.scss');
export default class SchedulerOperationsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.statusLabels = createLabelHelper('enum.planneroperation.status.');
    this.labels = createLabelHelper('mat.scheduler.operations.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  getEditTaskDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.UNASSIGNED,
      boEntityFilter: [FetchEntitiesFilters.UNSCHEDULED_TASKS],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('TASK')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  isTaskOutOfRange = (task) => {
    const horizonStartDate = moment(this.props?.sSchedulerData?.timeDefinition?.startDate);
    const startTime = moment.unix(task?.startTime?.epochDateTime);
    return startTime.isBefore(horizonStartDate);
  };

  getHeaderItems() {
    let { actions } = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;
    let singleRowStatus = selectedRowsData.get(0) ? selectedRowsData.get(0).operationStatus : null;
    let hasCompletedOrStartedStatus = selectedRowsData.some((row) => row.operationStatus === 'COMPLETED' || row.operationStatus === 'STARTED');
    let editTaskDialogConfig = this.getEditTaskDialogConfig();

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH,
        },
        {
          id: 'goTo',
          label: '',
          icon: 'b-icon b-fa-map-marker-alt',
          action: this.goToTask,
          disabled: !(hasRows && selectedRowsData.size === 1) || singleRowStatus === 'UNASSIGNED' || this.isTaskOutOfRange(selectedRowsData.get(0)),
          tooltip: headerItemsLabels.SHOW_ON_GANTT,
          actionData: {
            task: selectedRowsData.get(0),
            schedulerInstance: this.props.sSchedulerEngineInstance,
          },
        },
        {
          id: 'autoSchedule',
          className: 'no-icon',
          label: headerItemsLabels.AUTO_SCHEDULE,
          action: this.autoSchedule,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size === 0,
          actionData: selectedRowsData,
        },
        {
          id: 'assign',
          icon: 'pl pl-scheduler-plus',
          label: '',
          tooltip: headerItemsLabels.SCHEDULE,
          action: this.assignSchedulerTask,
          disabled:
            !(hasRows && selectedRowsData.size === 1) ||
            singleRowStatus === 'SCHEDULED' ||
            singleRowStatus === 'COMPLETED' ||
            singleRowStatus === 'STARTED', //TODO: L should be in Enum
          actionData: selectedRowsData.get(0),
        },
        {
          id: 'unAssign',
          icon: 'pl pl-scheduler-minus',
          label: '',
          tooltip: headerItemsLabels.UNSCHEDULE,
          action: this.unAssign,
          disabled:
            !(
              hasRows &&
              selectedRowsData.size &&
              selectedRowsData.every((row) => {
                return !row.locked && row.operationStatus === 'SCHEDULED';
              })
            ) || hasCompletedOrStartedStatus,
          actionData: selectedRowsData,
        },

        {
          id: 'lock',
          label: '',
          icon: 'pl pl-lock',
          tooltip: headerItemsLabels.LOCK,
          action: this.lockTasks,
          disabled:
            !(hasRows && selectedRowsData.size && selectedRowsData.every((row) => row.operationStatus === 'SCHEDULED')) ||
            hasCompletedOrStartedStatus,
          actionData: selectedRowsData,
        },
        {
          id: 'unlock',
          label: '',
          icon: 'pl pl-unlock',
          tooltip: headerItemsLabels.UNLOCK,
          action: this.unlockTasks,
          disabled:
            !(hasRows && selectedRowsData.size && selectedRowsData.every((row) => row.operationStatus === 'SCHEDULED')) ||
            hasCompletedOrStartedStatus,
          actionData: selectedRowsData,
        },
      ],
      kebab: {
        menuItems: [
          {
            id: 'createTask',
            label: headerItemsLabels.CREATE_TASK,
            action: actions.header.onCreateTaskClick,
          },
          {
            id: 'deleteTask',
            label: headerItemsLabels.DELETE_TASK,
            disabled: !hasRows || selectedRowsData?.size === 0,
            action: actions.header.onDeleteTasksClick,
            actionData: selectedRowsData,
          },
          {
            id: 'editTask',
            label: headerItemsLabels.EDIT_ATTRIBUTES,
            // disabled: !(hasRows && selectedRowsData.size > 0 && selectedRowsData.every((item) => item.operationStatus === 'UNASSIGNED')),
            disabled: !(hasRows && selectedRowsData.size === 1 && selectedRowsData.get(0).operationStatus === 'UNASSIGNED'),
            action: actions.header.onMenuItemEditTaskAttributesClick,
            actionData: editTaskDialogConfig,
          },
          {
            id: 'exportTasksList',
            label: headerItemsLabels.EXPORT_TASKS_LIST,
            action: actions.header.onExportTasksListClick,
            actionData: { gridName: gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS },
          },
        ],
      },
    };
  }

  getCounters = () => {
    let data = this.props.sCounters;
    if (!data) {
      return null;
    }
    return (
      <div className="inline left-side no-margin flex-grow-2">
        <span className="title">{this.labels.get('header.title', undefined, { count: data.allTasks })}</span>

        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('ALERTED')}>
            <span className="pl pl-error-icon"></span>
          </Tooltip>
          <span className="value">{data.nonValidCount}</span>
        </div>
        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('UNASSIGNED')}>
            <span className="counter pl pl-unscheduled"></span>
          </Tooltip>
          <span className="value">{data.unscheduledCount}</span>
        </div>
        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('SCHEDULED')}>
            <span className="counter pl pl-schedule"></span>
          </Tooltip>
          <span className="value">{data.scheduledCount}</span>
        </div>
        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('STARTED')}>
            <span className="counter pl pl-started"></span>
          </Tooltip>
          <span className="value">{data.startedCount}</span>
        </div>
        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('COMPLETED')}>
            <span className="counter pl pl-completed"></span>
          </Tooltip>
          <span className="value">{data.completedCount}</span>
        </div>
      </div>
    );
  };

  autoSchedule = (tasks) => {
    if (tasks && tasks.size > 0) {
      let selectedTasksIds = tasks.map((task) => {
        return task.id;
      });
      this.props.actions.autoSchedule(selectedTasksIds.toJS());
      this.props.sSchedulerEngineInstance.selectedEvents = []; //clear selection if autoSchedule button is clicked.
    }
  };

  unAssign = (data) => {
    this.props.actions.unAssign(data);
  };

  assignSchedulerTask = (data) => {
    this.props.actions.assignSchedulerTask(data);
  };

  lockTasks = (data) => {
    this.props.actions.lockSchedulerTasks(data);
  };

  unlockTasks = (data) => {
    this.props.actions.unlockSchedulerTasks(data);
  };

  goToTask = (data) => {
    this.props.actions.goToTask(data);
  };

  resetGrid = () => {
    this.props.actions.resetGrid();
  };

  reloadPage = () => {
    const query = {};
    this.props.actions.refreshPageData(query, true);
    this.props.sSchedulerEngineInstance.selectedEvents = []; //clear selection if refresh button is clicked.
  };

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="scheduler-operations-view">
        <Header {...headerItems} leftContent={this.getCounters()} />

        <SchedulerOperationsGrid
          actions={{
            ...this.props.actions.schedulerOperationsGridActions,
            ...this.props.actions.fetchEntitiesActions,
          }}
          sSchedulerData={this.props?.sSchedulerData}
        />

        <PL_DialogWrapper
          dialogComponent={ScheduleTaskDialog}
          show={this.props.sScheduleTaskDialogData.get('show')}
          actions={this.props.actions.scheduleTaskDialogActions}
          sData={this.props.sScheduleTaskDialogData}
          sLoggedInUser={this.props.sLoggedInUser}
          sSchedulerEngineInstance={this.props.sSchedulerEngineInstance}
        />

        <PL_DialogWrapper
          dialogComponent={AutoSchedulerDialog}
          show={this.props.sAutoSchedulerDialogData.get('show')}
          actions={this.props.actions.autoSchedulerDialogActions}
          sData={this.props.sAutoSchedulerDialogData}
          sLoggedInUser={this.props.sLoggedInUser}
          sSchedulerEngineInstance={this.props.sSchedulerEngineInstance}
        />

        <PL_DialogWrapper
          dialogComponent={CreateTaskDialog}
          show={this.props.sCreateTaskDialogData.get('show')}
          actions={this.props.actions.createTaskDialogActions}
          sData={this.props.sCreateTaskDialogData}
          sLoggedInUser={this.props.sLoggedInUser}
          sSchedulerEngineInstance={this.props.sSchedulerEngineInstance}
        />
        <PL_DialogWrapper
          dialogComponent={EditTaskAttributesDialog}
          show={this.props.sEditTaskAttributesData.get('show')}
          actions={this.props.actions.editTaskAttributesActions}
          sData={this.props.sEditTaskAttributesData}
          sLoggedInUser={this.props.sLoggedInUser}
          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}

SchedulerOperationsView.propTypes = {
  sGrid: PropTypes.object,
};

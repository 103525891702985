import Network from 'infrastructure/js/modules/network';

export function getKitActivityLog(assetId, query) {
  return Network.post(`kits/${assetId}/activities`, query);
}

export function PrintKitLabel(data) {
  return Network.post(`download/label/kit`, data, {blobResponse : true});
}
export function fetchActiveKits(query = {}) {
  return Network.post('kits/items?active=true', query);
}
export function fetchArchivedKits(query = {}) {
  return Network.post('kits/items?active=false', query);
}
export function updateKit(kitId, data) {
  return Network.put(`kits/${kitId}`, data);
}
export function cancelKitReport (locationId, kitIds) {
  return Network.post(`stations/${locationId}/kits/cancel`, { kitIds });
}

export function getKitsForUnfinishedReportedCuts(stationID, showKitsInLocation, selectedCutToKit = null, query = {}){
  let url = (selectedCutToKit && selectedCutToKit.id) ?
    `stations/${stationID}/unfinishedreportedcuts/${selectedCutToKit.id}/kits` :
    `stations/${stationID}/unfinishedreportedcuts/kits`;

  //Note: Temporary workaround for SAAS-3497
  query.pageSize = 50;
  query.kitsFromCurrentLocationOnly = showKitsInLocation ;

  return Network.post(url + '?active=true', query);
}

export function getReportedCuts(){
  return Network.get('stations/allUnfinishedReportedCuts');
}

export function validateNewKitsData(stationId, data) {
  return Network.post(`stations/${stationId}/kits/validate`, data);
}


export function getKitInitialData(cutId) {
    return Network.get(`kits/getdata?reportedCutId=${cutId}`);
}

export function createNewKits(stationId, data) {
  return Network.post(`stations/${stationId}/kits`, data);
}

export function generateKitId() {
  return new Promise((resolve, reject) => {

    Network.get(`kits/generateId`)
      .then((response) => {

        if (!Network.isResponseValid(response)) {
          console.error('Generate kit id Failed.');
          reject({success: false});
        }

        resolve(response);

      });
  });
}

export function printKitsLabels(ids, station) {
  return new Promise((resolve, reject) => {
    let data = {
      "assetIds": ids,
    };

    Network.post('download/label/kit', data, {blobResponse: true})
      .then((response) => {
        response.blob().then((blobResponse) => {
          if (blobResponse.size === 0) {
            console.error('Print Kits Labels failed.');
            reject({success: false});
          }
          resolve(blobResponse);
        });
      });
  });
}

export function reportKits(kitsToReport) {
  let jsonData = kitsToReport;
  return  Network.put('kits/reportkitting', jsonData);
}

export function fetchKitsRequiredmaterials(data, locationId,cutId) {
  return  Network.post(`stations/${locationId}/unfinishedreportedcuts/${cutId}/kits/requiredmaterials`, data);
}

export function updateKittedPlies (data){
  return Network.put('kits/update-kitted-plies', data);
}

export function getKitsDataByStationId(stationID, query = {}){
  return Network.post(`stations/${stationID}/kits/items?active=true`, query);
}

export function getShippedKitsByStationId(stationID, query = {}){
  return Network.post(`stations/${stationID}/kits/items?shippedToCollaborator=true`, query);
}

export function getReceivingKitsByStationId(stationID, query = {}){
  return Network.post(`stations/${stationID}/kits/items?active=true&inTransit=true`, query);
}

export function getKitDetails(assetId){
  return Network.get(`kits/${assetId}`);
}

export function updateShelfLife(data, callback) {
  return Network.polling.post('kits/multiShelfLifeUpdate' , data, {callback});
}


export function editKitAttributes(data, callback){
  return Network.polling.put('kits/update' , data, callback);
}
export function removeRollFromKit(data){
  return Network.delete(`kits/${data.parentAssetId}/roll/${data.relatedAssetId}`);
}

export function reportCutsAndKits(stationId, data){
  return Network.post(`stations/${stationId}/reportCutsAndKits`, data);
}

import React from 'react';

import AssetAlertPopover from '../../../../../Common/Layout/AssetAlertPopover/assetAlertPopover.js';
import Link from '../../../../../Common/Layout/Link/link.js';
import {navigationStates} from '../../../../../../enums/navigationStates';

require('./rollIdCell.scss');

export default class RollIdCell extends React.PureComponent {

  render() {
    let {value} = this.props;

    if (value) {
      return (<div className="roll-id-cell">
        <Link label={value.description} id={value.id} type={navigationStates.ROLL} className="description"/>
        <AssetAlertPopover value={value}/>
      </div>);
    }
    return null;
  }
}









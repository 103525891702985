import React from 'react';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import ShipmentsGrid    from './ShipmentsGrid/shipmentsGrid.js';
import {ImportAcceptFileTypes, ImportTypes} from '../../../enums/importTypes';
const TAB_ID = 'shipments';
export default class ShipmentsView extends React.PureComponent {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.shipments.');
  }

  componentDidMount() {
    if (this.props.match.params.tabId == TAB_ID) {
      this.reload(false);
    }
  }

  componentDidUpdate(prevProps) {
    let tabId = this.props.match.params.tabId;
    if (tabId === prevProps.match.params.tabId || tabId !== TAB_ID) {
      return;
    }
    this.reload(false);
  }

  reload = (refreshAll = true) => {
    this.props.actions.reload(refreshAll);
    if (refreshAll && this.props.actions.locationPageActions && this.props.actions.locationPageActions.reload) {
      this.props.actions.locationPageActions.reload();
    }
  };

  getHeaderItems() {
    let { actions } = this.props;
    let loading = this.props.sShipmentsGridData ? this.props.sShipmentsGridData.get('loading') : true;
    let selectedRowsData = this.props.sShipmentsGridData ? this.props.sShipmentsGridData.get('selectedRowsData') : [];

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refreshAll',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reload,
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: loading,
        },
      ],
      kebab: {
        // loading: loading,
        disabled: false,
        menuItems: [
          {
            id:'importShipmentsDocument',
            label: headerItemsLabels.IMPORT_SHIPMENT_DOCUMENT,
            action: actions.headerActions.importItems,
            actionData: {
              importTypes: [ImportTypes.IMPORT_SHIPMENTS],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: this.reload,
              shouldIncludeLocation: true, //import is done NOT from a location or station , the import file locationId column is mandatory.
              showPrintLabels: false,
            }

          },


          {
            id:'confirmShipmentsStatus',
            label: headerItemsLabels.CONFIRM_SHIPMENTS_STATUS,
            action: actions.headerActions.confirmShipmentStatus,
            actionData: {
              actionData: selectedRowsData,
              parentComponent: this.reload
            },
            disabled: !selectedRowsData || selectedRowsData.size !== 1 || !this.isItemAvailable(selectedRowsData),
          },
          {
            id:'rejectShipment',
            label: headerItemsLabels.REJECT_SHIPMENT,
            action: actions.headerActions.rejectShipment,
            actionData: {
              actionData: selectedRowsData,
              parentComponent: this.reload
            },
            disabled: !selectedRowsData || selectedRowsData.size !== 1 || !this.isItemAvailable(selectedRowsData),
          },

          {
            id:'downloadShipmentsDocument',
            label: headerItemsLabels.DOWNLOAD_SHIPMENT_DOCUMENT,
            action: actions.headerActions.downloadShipmentDocument,
            disabled: !selectedRowsData || selectedRowsData.size !== 1,
            actionData: selectedRowsData
          },

        ],
      },
      title: this.labels.get('header.title'),
    };
  }

  isItemAvailable = (data) => {
    let status = data?.get?.(0)?.status;
    return status && status !== 'CLOSED';
  }

  render() {
    let headerItems = this.getHeaderItems();
    return ( <div className="location-cutting-view">
        <Header  {...headerItems}/>
        <ShipmentsGrid actions={this.props.actions} />
      </div>
    );
  }
}




import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {headerItemsLabels} from '../../../Common/Header/header';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import CreateAlertDeviceDialog from './Dialogs/CreateAlertDeviceDialog/createAlertDeviceDialog.js';
import AlertDevicesViewGrid from './AlertDevicesViewGrid/alertDevicesViewGrid';
import {RfidSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import RfidHeader  from '../Common/rfidHeader';
import {reduxForm} from 'redux-form';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';

class AlertDeviceView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.rfidsettings.alertdevices.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.confirmationLabels = createLabelHelper('mat.administration.rfidsettings.dialog.deletealertdevice.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_ALERT_DEVICES);
    this.props.actions.init(false);
  }

  getSelectedDeviceAppProfileId = () => {
    let deviceApp = this.props.sData.get('selectedDeviceApp');

    if (deviceApp && deviceApp.data && deviceApp.data.profilesIDsList && (deviceApp.data.profilesIDsList.length > 0) ) {
      return deviceApp.data.profilesIDsList[0];
    }
    return null;
  };

  handleRemoveDevices = () => {
    this.props.actions.onRemoveDevicesClick(this.getMessageDialogBuilder(), this.props.sSelectedRowsData);
  };


  getMessageDialogBuilder = () => {
    return (fnCloseDialog , callback) => {
      let title = this.confirmationLabels.get('confirmation.title', '', { count: (this.props.sSelectedRowsData? this.props.sSelectedRowsData.size : 0)});
      let type = 'warning';
      let message = this.confirmationLabels.get('confirmation.message', '', { count: (this.props.sSelectedRowsData? this.props.sSelectedRowsData.size : 0)});
      let children =  [<MessageDialog.DataRow key={'confirmMessageDataRow'} label={message} value={''}/>];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'confirm',text: this.confirmationLabels.get('confirmation.button'), action: callback , bsStyle: 'primary'}
      ];
      let className = '';
      let messageDialogDescriptor = {title, buttons, children,  className, type};
      return messageDialogDescriptor;
    };
  };

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    let deviceAppProfileId = this.getSelectedDeviceAppProfileId();


    return {
      dropdown: {
        id:'deviceAppDropDown',
        name : 'deviceAppDropDown',
        options: this.props.sData.get('deviceAppsOptions'),
        disabled: this.props.sData.get('deviceAppsOptions').length <= 1,
        changeCallback: this.props.actions.onDeviceAppChanged,
      },
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          disabled: !deviceAppProfileId,
          action: actions.header.onCreateAlertDeviceClick,
          actionData: {deviceAppProfileId: deviceAppProfileId}
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditAlertDeviceClick,
          actionData: {deviceAppProfileId: deviceAppProfileId,
                       itemToEdit: selectedRowsData.get(0)}
        },
        {
          id:'remove',
          label: headerItemsLabels.REMOVE,
          icon: 'pl pl-delete-icon',
          disabled: !(hasRows && selectedRowsData.size > 0),
          action: this.handleRemoveDevices
        }
      ],
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  renderGrid = () => {
    if(!this.props.sData.get('selectedDeviceApp')) {
      return null;
    }
    return <AlertDevicesViewGrid actions={this.props.actions.alertDevicesViewGridActions} />
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <RfidHeader {...headerItems} change = {this.props.change} selectedDeviceApp={this.props.sData.get('selectedDeviceApp')}/>
        {this.renderGrid()}

        <PL_DialogWrapper dialogComponent={CreateAlertDeviceDialog}
                          show={this.props.sCreateAlertDeviceDialogData.get('show')}
                          actions={this.props.actions.createAlertDeviceDialogActions}
                          sData={this.props.sCreateAlertDeviceDialogData}
                          // deviceApp={this.props.sData.get('selectedDeviceApp')}
                          deviceAppProfileId={this.getSelectedDeviceAppProfileId()}
                          reloadParentComponent={this.reloadPage}
        />

      </div>
    );
  }
}

export default reduxForm({
    form: 'AlertDevicesForm'
  }
)(AlertDeviceView);

AlertDeviceView.propTypes = {
  sGrid: PropTypes.object,
};

import React, {Component}   from 'react';
import {reduxForm}          from 'redux-form';
import Dialog               from 'infrastructure/js/components/Dialog/dialog';
import {createLabelHelper}  from 'infrastructure/js/utils/labelHelper';
import Tabs                 from 'infrastructure/js/components/Tabs/tabs';

import InputSection         from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Checkbox             from 'infrastructure/js/components/controls/Checkbox/checkbox';
import TextField            from 'infrastructure/js/components/controls/TextField/textField';
import DatePicker           from 'infrastructure/js/components/controls/DatePicker/datepicker';
import Normalize            from 'infrastructure/js/components/controls/controlsNormalizations';
import CommonList           from 'infrastructure/js/components/CommonList/commonList';
import Combobox             from 'infrastructure/js/components/controls/Combobox/combobox.js';

require('./createAssetTypeStepTwoDialog.scss');


export class CreateAssetTypeStepTwoDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels       = createLabelHelper('mat.administration.matsettings.wizard.createAssetType.');
    this.dialogLabels = createLabelHelper('mat.dialog.');

    let initialData = this.props.sData.get('initialData');
    this.itemToEdit = initialData && initialData.itemToEdit ? initialData.itemToEdit : null;
    this.isEditMode = !!this.itemToEdit;

    this.previousFormData = null;

    this.state = {
      selectedTab: 'propertiesTab',
      curPropItem: null,
      curActionItem: null,
    };
  }

  componentDidUpdate(prevProps){
    let data = this.props.sData.get('stepTwoData');

    if(data !== prevProps.sData.get('stepTwoData')){
      this.initFormValues();
    }
  }

  getDialogButtons() {
    return {

      left: [{
        id:'back',
        text: this.dialogLabels.get('back'),
        action: this.props.handleSubmit(this.onBack),
      }],

      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          disabled: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
        }
      ]
    };
  }

  onCancel = () => {
    let wizardActions = this.props.wizardActions;
    wizardActions.close();
  };

  onBack = (formData) => {
    this.previousFormData = formData;
    this.props.wizardActions.previous();
  };

  onSubmit = (formData) => {
    if(this.isEditMode) {
      formData.assetTypeId = this.itemToEdit.id;
    }
    this.props.actions.onWizardSubmit(formData);
  };

  initFormValues = () => {
    let stepTwoData = this.props.sData.get('stepTwoData');

    if (stepTwoData) {
      this.initPropertiesValues(stepTwoData.properties);
      this.initActionsValues(stepTwoData.actions);

      this.setState({
        selectedTab: 'propertiesTab',
        curPropItem: null,
        curActionItem: null,
      });
    }
  };

  initPropertiesValues = (properties) => {
    if (properties) {

      let propertiesData = {};

      properties.forEach((item) => {
        let prevData = this.getPreviousPropertiesDataForItem(item);

        let data = {
          isVisible: prevData ? prevData.isVisible : item.visible,
          isMandatory: prevData ? prevData.isMandatory : item.mandatory,
          isEditable: prevData ? prevData.isEditable : item.editable,
          isShowInGrid: prevData ? prevData.isShowInGrid : item.showInGrid,
        };

        this.initPropertiesValuesByItemType(data, item, prevData);

        propertiesData['id' + item.propertyDef.id] = data;
      });

      this.props.change('properties', propertiesData);
    }
  };

  initActionsValues = (actions) => {
    if (actions) {

      let actionsData = {};

      actions.forEach((item) => {
        let prevData = this.getPreviousActionsDataForItem(item);

        let data = {
          isVisible: prevData ? prevData.isVisible : item.visible,
          isPrimary: prevData ? prevData.isPrimary : item.primary,
        };
        actionsData['id' + item.actionDef.id] = data;
      });

      this.props.change('actions', actionsData);
    }
  };


  initPropertiesValuesByItemType = (data, item, prevData) => {
    if (item && item.propertyDef) {

      switch (item.propertyDef.type) {
        case 'OBJECT_REFERENCE':
          data.dataType = prevData ? prevData.dataType : item.propertyDef.dataType;
          return data;
        case 'TEXT':
          data.defaultValue = prevData ? prevData.defaultValue : item.defaultValue;
          data.maxChars = prevData ? prevData.maxChars : item.displayedChars;
          return data;
        case 'NUMBER':
          data.defaultValue = prevData ? prevData.defaultValue : item.defaultValue;
          data.minValue = prevData ? prevData.minValue : item.minValue;
          data.maxValue = prevData ? prevData.maxValue : item.maxValue;
          data.displayedDecimalDigits = prevData ? prevData.displayedDecimalDigits : item.displayedDecimalDigits;
          return data;
        case 'INTEGER':
          data.defaultValue = prevData ? prevData.defaultValue : item.defaultValue;
          data.minValue = prevData ? prevData.minValue : item.minValue;
          data.maxValue = prevData ? prevData.maxValue : item.maxValue;
          return data;
        case 'DATE_TIME':
          data.defaultValue = prevData ? prevData.defaultValue : item.defaultValue;
          return data;
        case 'BOOLEAN':
          data.defaultValue = prevData ? prevData.defaultValue : item.defaultValue;
          return data;
        default:
          console.error('Unknown Asset Type\'s property type', item.propertyDef.type);
          return data;
      }
    }
  };

  getPreviousPropertiesDataForItem = (item) => {
    if (this.previousFormData) {
      let prevDataForItem = this.previousFormData.properties['id' + item.propertyDef.id];
      return prevDataForItem;
    }
    return null;
  };

  getPreviousActionsDataForItem = (item) => {
    if (this.previousFormData) {
      let prevDataForItem = this.previousFormData.actions['id' + item.actionDef.id];
      return prevDataForItem;
    }
    return null;
  };

  onTabClick = (key) => {
    this.setState({ selectedTab: key });
  };

  onPropertyItemClickHandler = (item) => {
    this.setState({ curPropItem: item });
};
  onActionItemClickHandler = (item) => {
    this.setState({ curActionItem: item });
};

  propsItemRenderer = (item, index) => {
    let def = item.propertyDef;
    let name = def ? (def.displayName ? def.displayName  : def.name) : '' ;
    return <div>{name}</div>;
  };

  actionsItemRenderer = (item, index) => {
    let def = item.actionDef;
    let name = def ? (def.displayName ? def.displayName  : def.name) : '' ;
    return <div>{name}</div>;
  };

  renderPropertyDataByType = (propId, dataType) => {
    if (dataType === 'INTEGER' || dataType === 'NUMBER' ) {  //TODO: L dif for NUMBER
      return (
        <React.Fragment>
          <InputSection  label={this.labels.get('minValue')} htmlFor="minValue" className="inline ">
            <TextField name={this.getReduxName(propId, 'minValue')} id="minValue"  className="inline"
                       normalize={Normalize.number(false, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)}
            />
          </InputSection>
          <InputSection  label={this.labels.get('maxValue')} htmlFor="maxValue" className="inline ">
            <TextField name={this.getReduxName(propId, 'maxValue')} id="maxValue" className="inline"
                       normalize={Normalize.number(false, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)}
            />
          </InputSection>
          <InputSection  label={this.labels.get('defaultValue')} htmlFor="defaultValue" className="inline ">
            <TextField name={this.getReduxName(propId, 'defaultValue')}  id="defaultValue" className="inline"
                       normalize={Normalize.number(false, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)}
            />
          </InputSection>
          {(dataType === 'NUMBER') && <InputSection  label={this.labels.get('decimalDigits')} htmlFor="displayedDecimalDigits" className="inline ">
            <TextField name={this.getReduxName(propId, 'displayedDecimalDigits')}  id="displayedDecimalDigits" className="inline"
                       normalize={Normalize.number(true, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)}
            />
          </InputSection>}
        </React.Fragment>
      )
    }
    else if (dataType === 'TEXT') {
      return (
        <React.Fragment>
          <InputSection  label={this.labels.get('defaultValue')} htmlFor="defaultValue" className="inline ">
            <TextField name={this.getReduxName(propId, 'defaultValue')}  id="defaultValue" className="inline"/>
          </InputSection>
          <InputSection  label={this.labels.get('maxChars')} htmlFor="maxChars" className="inline ">
            <TextField name={this.getReduxName(propId, 'maxChars')}  id="maxChars" className="inline"
                       normalize={Normalize.number(false, 0, Number.MAX_SAFE_INTEGER)}
            />
          </InputSection>
        </React.Fragment>
        );
    }

    else if (dataType === 'DATE_TIME') {
      return (
        <React.Fragment>
          <InputSection  label={this.labels.get('defaultValue')} htmlFor="defaultValue" className="inline ">
            <DatePicker name={this.getReduxName(propId, 'defaultValue')}  id="defaultValue" className="inline"/>
          </InputSection>
        </React.Fragment>
      );
    }

    // else if (dataType === 'BOOLEAN') {
    //   return (
    //     <React.Fragment>
    //       <InputSection  label={this.labels.get('defaultValue')} htmlFor="defaultValue" className="inline ">
    //         <Combobox name={this.getReduxName(propId, 'defaultValue')}  id="defaultValue" className="inline"
    //                   options={[{value: 0, label: 'false'},{value: 1, label: 'true'}]}
    //
    //         />
    //       </InputSection>
    //     </React.Fragment>
    //   );
    // }
    else if (dataType === 'OBJECT_REFERENCE') {
      return (
        <React.Fragment>
          <InputSection  label={this.labels.get('referenceType')} htmlFor="dataType" className="inline ">
            <TextField name={this.getReduxName(propId, 'dataType')}  id="dataType" className="inline"/>
          </InputSection>
        </React.Fragment>
      );
    }
    return null;
  };

  getReduxName = (propId, propName) => {
    return 'properties.id' + propId + '.' + propName;
  };

  getActionReduxName = (propId, propName) => {
    return 'actions.id' + propId + '.' + propName;
  };

  renderPropertyData = () => {
    if (this.state.curPropItem) {
      let propId = this.state.curPropItem.propertyDef.id;
      return (
      <div className="property-item-details">
        <div className="property-name">{this.state.curPropItem.propertyDef.displayName}</div>
        <div className="property-type">{'TYPE: ' + this.state.curPropItem.propertyDef.type}</div>

        {/*<InputSection  label={this.state.curPropItem.propertyDef.displayName} className="inline "/>*/}
        {/*<InputSection  label={'TYPE: ' + this.state.curPropItem.propertyDef.dataType} className="inline "/>*/}

        <InputSection  htmlFor="isVisible" className="inline ">
          <Checkbox name={this.getReduxName(propId, 'isVisible')} id="isVisible"  label={this.labels.get('visible')}/>
        </InputSection>
        <InputSection  htmlFor="isMandatory" className="inline ">
          <Checkbox name={this.getReduxName(propId, 'isMandatory')} id="isMandatory"  label={this.labels.get('mandatory')}/>
        </InputSection>
        <InputSection  htmlFor="isEditable" className="inline ">
          <Checkbox name={this.getReduxName(propId, 'isEditable')} id="isEditable"  label={this.labels.get('editable')}/>
        </InputSection>
        <InputSection  htmlFor="isShowInGrid" className="inline ">
          <Checkbox name={this.getReduxName(propId, 'isShowInGrid')} id="isShowInGrid"  label={this.labels.get('gridColumn')}/>
        </InputSection>

        {this.renderPropertyDataByType(propId, this.state.curPropItem.propertyDef.type)}

      </div>
      );
    }
    return null;
  };


  renderActionData = () => {
    if (this.state.curActionItem) {
      let propId = this.state.curActionItem.actionDef.id;
      return (
        <div className="property-item-details">
          <div className="property-name">{this.state.curActionItem.actionDef.displayName}</div>

          <InputSection  htmlFor="isPrimary" className="inline ">
            <Checkbox name={this.getActionReduxName(propId, 'isPrimary')} id="isPrimary"  label={this.labels.get('primary')}/>
          </InputSection>
          <InputSection  htmlFor="isVisible" className="inline ">
            <Checkbox name={this.getActionReduxName(propId, 'isVisible')} id="isVisible"  label={this.labels.get('visible')}/>
          </InputSection>
        </div>
      );
    }
    return null;
  };


  renderPropertiesTab = () => {
    let stepTwoData = this.props.sData.get('stepTwoData');
    if (stepTwoData && stepTwoData.properties) {

      return (
        <Tabs.Tab className="" eventKey="propertiesTab" title={this.labels.get('tabs.properties')} animation={false}>
          <div className="tab-container">
            <div className='list-wrapper'>
              <CommonList className="items-column"
                          items={stepTwoData.properties}
                          itemRenderer={this.propsItemRenderer}
                          onItemClickCallback={this.onPropertyItemClickHandler}
              />
            </div>
            {this.renderPropertyData()}
          </div>
        </Tabs.Tab>
      )
    }
    return null;
  };
  renderActionsTab = () => {
    let stepTwoData = this.props.sData.get('stepTwoData');
    if (stepTwoData && stepTwoData.actions) {

      return (
        <Tabs.Tab className="" eventKey="actionsTab" title={this.labels.get('tabs.actions')} animation={false}>
          <div className="tab-container">
            <div className='list-wrapper'>
              <CommonList className="items-column"
                          items={stepTwoData.actions}
                          itemRenderer={this.actionsItemRenderer}
                          onItemClickCallback={this.onActionItemClickHandler}
              />
            </div>
            {this.renderActionData()}
          </div>
        </Tabs.Tab>
      )
    }
    return null;
  };

  render() {

    let animation = this.props.isFirstLaunch;

    let selectedTab = this.state.selectedTab;

    let loadingClass = (this.props.sData.get('loading') ? 'loading' : '');
    return (

      <Dialog
        id="create-asset-type-step-two-dialog"
        className={`create-asset-type-step-two-dialog ${loadingClass}`}
        titleText={this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create')}
        animation={animation}
        show={true}
        onCancel={this.onCancel}
        onHide={this.onCancel}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        style={this.props.style}
      >

        <Tabs activeKey={selectedTab} tabType="default" onSelect={this.onTabClick} id="content-tabs">
          {this.renderPropertiesTab()}
          {this.renderActionsTab()}
        </Tabs>

      </Dialog>
    );

  }
}

export default reduxForm({
    forceUnregisterOnUnmount: true, // When this page isn't rendered, don't validate its fields.
    destroyOnUnmount: false         // When this page isn't rendered, don't destroy the form.
  }
)(CreateAssetTypeStepTwoDialog);




import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {PL_DialogWrapper}     from 'infrastructure/js/components/Dialog/dialog';
import CompletedWorkOrderGrid from '../../Common/Grids/CommonWorkOrdersGrid/commonWorkOrdersGrid';
import EditWorkOrdersStatusDialog from  '../../Dialogs/EditWorkOrdersStatusDialog/editWorkOrdersStatusDialog';
import {gridsNames} from '../../../enums/gridsNames';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./completedWorkOrdersPage.scss');

export default class CompletedWorkOrdersPage extends Component {

  getDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.COMPLETED,
      boEntityFilter: [FetchEntitiesFilters.COMPLETED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('WO')],
      preselectedAssets: this.props.sSelectedRowsData,
      status: getEnumValue(enumTypes.WORK_ORDER_STATUS)('ACTIVE'),
    };
  };

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData} = this.props;
    let dialogConfig = this.getDialogConfig();

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        ...(PermissionManager.hasReopenWoPermissions() ? [{
          id:'reopen',
          label: headerItemsLabels.REOPEN,
          icon: 'pl pl-reopen-workorder-icon',
          counter: sSelectedRowsData.size,
          disabled: !sHasRows,
          action: actions.header.onReopenClick,
          actionData: dialogConfig
        }] : []),
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.export,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_COMPLETED_WORK_ORDER}
        }
      ],

    };
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderEditWorkOrdersStatusDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={EditWorkOrdersStatusDialog}
                        show={this.props.sEditWorkOrdersStatusData.get('show')}
                        sData={this.props.sEditWorkOrdersStatusData}
                        actions={this.props.actions.editWorkOrdersStatusActions}
                        reloadParentComponent={this.reloadPage} />
    );
  };

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className='completed-work-orders-page'>
        <Header {...headerItems}/>
        <CompletedWorkOrderGrid gridName={gridsNames.PREDEFINED_LIST_COMPLETED_WORK_ORDER}
                                actions={{...this.props.actions.completedWorkOrdersGridActions, ...this.props.actions.fetchEntitiesActions}}
                                showAlertsColumn={false}
                                showHasAssetsColumn={false}
                                showDueDateColumn={false}
                                showCompletionDateColumn={true}
                                // fetchEntityFilter={BO_EntityFilters.COMPLETED}
                                fetchEntityFilter={[FetchEntitiesFilters.COMPLETED]}
                                defaultWorkOrderType={this.props.sDefaultWorkOrderType}
        />

        { this.renderEditWorkOrdersStatusDialog() }
      </div>
    )
  }
};

CompletedWorkOrdersPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool,
  sSelectedRowsData: PropTypes.object,
};





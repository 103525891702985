export const MatSettingsMenuItemsIds = {
  NAV_ELEMENT_ASSET_TYPES: 'NAV_ELEMENT_ASSET_TYPES',
  NAV_ELEMENT_GROUP_TYPES: 'NAV_ELEMENT_GROUP_TYPES',
  NAV_ELEMENT_MATERIAL_TYPES: 'NAV_ELEMENT_MATERIAL_TYPES',
  NAV_ELEMENT_TOOLS: 'NAV_ELEMENT_TOOLS',
  NAV_ELEMENT_TOOL_CATEGORIES: 'NAV_ELEMENT_TOOL_CATEGORIES',
  NAV_ELEMENT_TOOL_TYPES: 'NAV_ELEMENT_TOOL_TYPES',
  NAV_ELEMENT_KIT_TYPES: 'NAV_ELEMENT_KIT_TYPES',
  NAV_ELEMENT_PART_TYPES: 'NAV_ELEMENT_PART_TYPES',
  NAV_ELEMENT_PROJECTS : 'NAV_ELEMENT_PROJECTS',
  NAV_ELEMENT_LOCATIONS_AND_STATIONS_MAIN : 'NAV_ELEMENT_LOCATIONS_AND_STATIONS_MAIN',
  NAV_ELEMENT_LOCATIONS_AND_STATIONS : 'NAV_ELEMENT_LOCATIONS_AND_STATIONS',
  NAV_ELEMENT_LOCATIONS_AND_STATIONS_CONFIG : 'NAV_ELEMENT_LOCATIONS_AND_STATIONS_CONFIG',
  NAV_ELEMENT_DIGITAL_TWIN : 'NAV_ELEMENT_DIGITAL_TWIN',
  NAV_ELEMENT_OPERATIONS : 'NAV_ELEMENT_OPERATIONS',
  NAV_ELEMENT_REASONS : 'NAV_ELEMENT_REASONS',
  NAV_ELEMENT_REASONS_SHELF_LIFE_UPDATE : 'NAV_ELEMENT_REASONS_SHELF_LIFE_UPDATE',
  NAV_ELEMENT_SHIFT_TYPES : 'NAV_ELEMENT_SHIFT_TYPES',
  NAV_ELEMENT_SHIFTS_TEMPLATE : 'NAV_ELEMENT_SHIFTS_TEMPLATE',
  NAV_ELEMENT_WEEKLY_SHIFTS : 'NAV_ELEMENT_WEEKLY_SHIFTS',
};

export const RfidSettingsMenuItemsIds = {
  NAV_ELEMENT_DEVICE_APPS: 'NAV_ELEMENT_DEVICE_APPS',
  NAV_ELEMENT_READERS_AND_ANTENNAS: 'NAV_ELEMENT_READERS_AND_ANTENNAS',
  NAV_ELEMENT_FILTERS: 'NAV_ELEMENT_FILTERS',
  NAV_ELEMENT_FLOOD_FILTERS: 'NAV_ELEMENT_FLOOD_FILTERS',
  NAV_ELEMENT_WORKFLOWS_FILTERS: 'NAV_ELEMENT_WORKFLOWS_FILTERS',
  NAV_ELEMENT_COMMUNICATIONS_PARAMETERS : 'NAV_ELEMENT_COMMUNICATIONS_PARAMETERS',
  NAV_ELEMENT_ALERT_DEVICES: 'NAV_ELEMENT_ALERT_DEVICES',
  NAV_ELEMENT_PRINTERS: 'NAV_ELEMENT_PRINTERS',
  NAV_ELEMENT_VISIBILITY_DEVICE:'NAV_ELEMENT_VISIBILITY_DEVICE',
  NAV_ELEMENT_ZPL_SETTINGS: 'NAV_ELEMENT_ZPL_SETTINGS',
  NAV_ELEMENT_MQTT_SETTINGS: 'NAV_ELEMENT_MQTT_SETTINGS',
  NAV_ELEMENT_MQTT_SETTINGS_BROKERS: 'NAV_ELEMENT_MQTT_SETTINGS_BROKERS',
  NAV_ELEMENT_MQTT_SETTINGS_SENSORS: 'NAV_ELEMENT_MQTT_SETTINGS_SENSORS',
  NAV_ELEMENT_AUTOMATED_WORKFLOWS: 'NAV_ELEMENT_AUTOMATED_WORKFLOWS',
  NAV_ELEMENT_AUTOMATED_WORKFLOWS_AUTOMATION_SCENARIOS: 'NAV_ELEMENT_AUTOMATED_WORKFLOWS_AUTOMATION_SCENARIOS',
  NAV_ELEMENT_AUTOMATED_WORKFLOWS_QUALIFIED_EVENTS: 'NAV_ELEMENT_AUTOMATED_WORKFLOWS_QUALIFIED_EVENTS',
  NAV_ELEMENT_AUTOMATED_WORKFLOWS_ACTION_SEQUENCE: 'NAV_ELEMENT_AUTOMATED_WORKFLOWS_ACTION_SEQUENCE',
};

export const MassOperationsMenuItemsIds = {
  NAV_ELEMENT_MASS_CREATION: 'NAV_ELEMENT_MASS_CREATION'
};

export const SettingsMenuItemsIds = {
  NAV_ELEMENT_CSV: 'NAV_ELEMENT_CSV',
  NAV_ELEMENT_CSV_SHIPMENT: 'NAV_ELEMENT_CSV_SHIPMENT',
  NAV_ELEMENT_CSV_ROLL: 'NAV_ELEMENT_CSV_ROLL',
  NAV_ELEMENT_CSV_ROLL_USAGE: 'NAV_ELEMENT_CSV_ROLL_USAGE',
  NAV_ELEMENT_CSV_SPOOL: 'NAV_ELEMENT_CSV_SPOOL',
  NAV_ELEMENT_CSV_KIT: 'NAV_ELEMENT_CSV_KIT',
  NAV_ELEMENT_CSV_GROUP: 'NAV_ELEMENT_CSV_GROUP',
  NAV_ELEMENT_CSV_TOOL : 'NAV_ELEMENT_CSV_TOOL',
  NAV_ELEMENT_CSV_WO: 'NAV_ELEMENT_CSV_WO',
  NAV_ELEMENT_CSV_TASK: 'NAV_ELEMENT_CSV_TASK',
  NAV_ELEMENT_CSV_KIT_TYPE: 'NAV_ELEMENT_CSV_KIT_TYPE',
  NAV_ELEMENT_CSV_TOOL_TYPE: 'NAV_ELEMENT_CSV_TOOL_TYPE',
  NAV_ELEMENT_CSV_GROUP_TYPE: 'NAV_ELEMENT_CSV_GROUP_TYPE',
  NAV_ELEMENT_CSV_MATERIAL_TYPE: 'NAV_ELEMENT_CSV_MATERIAL_TYPE',
  NAV_ELEMENT_CSV_PART_TYPE: 'NAV_ELEMENT_CSV_PART_TYPE',
  NAV_ELEMENT_CSV_PROJECT: 'NAV_ELEMENT_CSV_PROJECT',
  NAV_ELEMENT_CSV_OPERATION: 'NAV_ELEMENT_CSV_OPERATION',

  NAV_ELEMENT_BARCODE: 'NAV_ELEMENT_BARCODE',
  NAV_ELEMENT_BARCODE_ROLL: 'NAV_ELEMENT_BARCODE_ROLL',
  NAV_ELEMENT_BARCODE_SPOOL: 'NAV_ELEMENT_BARCODE_SPOOL',

  NAV_ELEMENT_PDF_LABEL: 'NAV_ELEMENT_PDF_LABEL',
  NAV_ELEMENT_PDF_LABEL_ROLL: 'NAV_ELEMENT_PDF_LABEL_ROLL',
  NAV_ELEMENT_PDF_LABEL_SPOOL: 'NAV_ELEMENT_PDF_LABEL_SPOOL',
  NAV_ELEMENT_PDF_LABEL_KIT: 'NAV_ELEMENT_PDF_LABEL_KIT',
  NAV_ELEMENT_PDF_LABEL_GROUP: 'NAV_ELEMENT_PDF_LABEL_GROUP',

  NAV_ELEMENT_SYSTEM_PREFERENCES: 'NAV_ELEMENT_SYSTEM_PREFERENCES',
  NAV_ELEMENT_SCHEDULING_PREFERENCES: 'NAV_ELEMENT_SCHEDULING_PREFERENCES',

  NAV_ELEMENT_ADDITIONAL_FIELDS: 'NAV_ELEMENT_ADDITIONAL_FIELDS',
  NAV_ELEMENT_ADDITIONAL_FIELDS_ROLL: 'NAV_ELEMENT_ADDITIONAL_FIELDS_ROLL',
  NAV_ELEMENT_ADDITIONAL_FIELDS_SPOOL: 'NAV_ELEMENT_ADDITIONAL_FIELDS_SPOOL',
  NAV_ELEMENT_ADDITIONAL_FIELDS_KIT: 'NAV_ELEMENT_ADDITIONAL_FIELDS_KIT',
  NAV_ELEMENT_ADDITIONAL_FIELDS_GROUP: 'NAV_ELEMENT_ADDITIONAL_FIELDS_GROUP',
  NAV_ELEMENT_ADDITIONAL_FIELDS_KIT_TYPE: 'NAV_ELEMENT_ADDITIONAL_FIELDS_KIT_TYPE',
  NAV_ELEMENT_ADDITIONAL_FIELDS_TOOL: 'NAV_ELEMENT_ADDITIONAL_FIELDS_TOOL',
  NAV_ELEMENT_ADDITIONAL_FIELDS_WO: 'NAV_ELEMENT_ADDITIONAL_FIELDS_WO',

  NAV_ELEMENT_TERMINOLOGY: 'NAV_ELEMENT_TERMINOLOGY',

  NAV_ELEMENT_ALERTS : 'NAV_ELEMENT_ALERTS',
  NAV_ELEMENT_ALERTS_MISPLACED_MATERIAL: 'NAV_ELEMENT_ALERTS_MISPLACED_MATERIAL',
  NAV_ELEMENT_ALERTS_EXPOSURE_TIME_CURE: 'NAV_ELEMENT_ALERTS_EXPOSURE_TIME_CURE',
  NAV_ELEMENT_ALERTS_EXPOSURE_TIME_BOND: 'NAV_ELEMENT_ALERTS_EXPOSURE_TIME_BOND',
  NAV_ELEMENT_ALERTS_EXPIRATION_DATE: 'NAV_ELEMENT_ALERTS_EXPIRATION_DATE',
  NAV_ELEMENT_ALERTS_RESERVED: 'NAV_ELEMENT_ALERTS_RESERVED',
  NAV_ELEMENT_ALERTS_WO_DUE_DATE: 'NAV_ELEMENT_ALERTS_WO_DUE_DATE',
  NAV_ELEMENT_ALERTS_TOOL_CYCLES: 'NAV_ELEMENT_ALERTS_TOOL_CYCLES',
  NAV_ELEMENT_ALERTS_DEFROSTING_TIME: 'NAV_ELEMENT_ALERTS_DEFROSTING_TIME',
  NAV_ELEMENT_ALERTS_TOOL_USAGE_TIME: 'NAV_ELEMENT_ALERTS_TOOL_USAGE_TIME',
  NAV_ELEMENT_ALERTS_SCHEDULER: 'NAV_ELEMENT_ALERTS_SCHEDULER',

  NAV_ELEMENT_SENSORS: 'NAV_ELEMENT_SENSORS',

  NAV_ELEMENT_LOGS: 'NAV_ELEMENT_LOGS',

  NAV_ELEMENT_ACCESS_CONTROL: "NAV_ELEMENT_ACCESS_CONTROL",
  NAV_ELEMENT_ACCESS_CONTROL_IP_WHITELIST: "NAV_ELEMENT_ACCESS_CONTROL_IP_WHITELIST",

};

export const PsManagementMenuItemsIds = {
  NAV_ELEMENT_DASHBOARDS_DEMO_DATA: 'NAV_ELEMENT_DASHBOARDS_DEMO_DATA'
};

export const AppMainMenuItemsIds = {
  NAV_ELEMENT_SEARCH: 'NAV_ELEMENT_SEARCH',
  NAV_ELEMENT_FAVORITES: 'NAV_ELEMENT_FAVORITES',
  NAV_ELEMENT_ORGS_MANAGEMENT: 'NAV_ELEMENT_ORGS_MANAGEMENT',
  NAV_ELEMENT_SCHEDULER: 'NAV_ELEMENT_SCHEDULER',
  NAV_ELEMENT_OPERATIONS_MANAGER: 'NAV_ELEMENT_OPERATIONS_MANAGER',
  NAV_ELEMENT_ASSETS_MANAGER: 'NAV_ELEMENT_ASSETS_MANAGER',
  NAV_ELEMENT_LOCATIONS_AND_STATIONS: 'NAV_ELEMENT_LOCATIONS_AND_STATIONS',
  NAV_ELEMENT_REPORTS: 'NAV_ELEMENT_REPORTS',
  NAV_ELEMENT_ADMINISTRATION: 'NAV_ELEMENT_ADMINISTRATION',
  NAV_ELEMENT_OPENMENU: 'NAV_ELEMENT_OPENMENU',
  NAV_ELEMENT_MAIN_SETTINGS: 'NAV_ELEMENT_MAIN_SETTINGS',

}

import React from 'react';
import PropTypes from 'prop-types';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from "../../../../../../../infrastructure/js/components/controls/controlsNormalizations";

require('./editPreferenceDialog.scss');

class EditPreferenceDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.settings.preferences.dialog.editPreference.');
    this.state = {orgActualValue: this.props.sData.get('itemToEdit').value};
  }

  componentDidMount() {
    let initialValues = {
      orgActualValue: this.props.sData.get('itemToEdit').value,
    };

    if (this.props.sData.get('itemToEdit').type === "BOOLEAN") {
      initialValues.orgActualValue = this.props.sData.get('itemToEdit').value === 'true' ?
        {label: this.labels.get('true'), value: 'true'} :
        {label: this.labels.get('false'), value: 'false'}
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: 'default',
          text: this.labels.get('footer.useDefault'),
          bsStyle: 'primary',
          action: this.setDefaultValue,
          disabled: this.props.sData.get('loading') || (this.state.orgActualValue === this.props.sData.get('itemToEdit').defaultValue)
        },
        {
          id: 'submit',
          text: this.labels.get('footer.edit'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.pristine || this.props.sData.get('loading')
        }
      ]
    };
  }

  setDefaultValue = () => {
    this.props.change('orgActualValue', this.props.sData.get('itemToEdit').defaultValue);
    this.setState({orgActualValue: this.props.sData.get('itemToEdit').defaultValue});

    if (this.props.sData.get('itemToEdit').type === "BOOLEAN") {
      let defaultValue = this.props.sData.get('itemToEdit').defaultValue === 'true' ?
        {label: this.labels.get('true'), value: 'true'} :
        {label: this.labels.get('false'), value: 'false'}

      this.props.change('orgActualValue', defaultValue);
      this.setState({orgActualValue: defaultValue.value});
    }
  };
  onSubmit = (data) => {
    let newData = {
      key: this.props.sData.get('itemToEdit').key,
      value: data.orgActualValue
    };

    if (this.props.sData.get('itemToEdit').type === "BOOLEAN") {
      newData.value = data.orgActualValue.value
    }

    this.props.actions.submit(newData, this.props.reloadParentComponent, this.props.isRfid);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onValueChangeCallback = (val) => {
    this.setState({orgActualValue: val});
  };

  onBooleanValueChangeCallback = (val) => {
    this.setState({orgActualValue: val ? val.value : null});
  };


  renderTextValue = () => {
    return (
      <InputSection htmlFor="orgActualValue"
                    label={this.labels.get('actualOrgValue')}
                    className="inline right-side">
        <TextField id="orgActualValue" name="orgActualValue" className="short-textfield" maxLength={100} onChangeCallback={this.onValueChangeCallback}/>
      </InputSection>
    );
  };

  renderNumberValue = () => {
    return (
      <InputSection htmlFor="orgActualValue"
                    label={this.labels.get('actualOrgValue')}
                    className="inline right-side">
        <TextField id="orgActualValue" name="orgActualValue" className="short-textfield"
                   onChangeCallback={this.onValueChangeCallback} normalize={Normalize.number()}/>
      </InputSection>
    );
  };

  renderLargeTextValue = () => {
    return (
      <InputSection htmlFor="orgActualValue"
                    label={this.labels.get('actualOrgValue')}
                    className="inline right-side">
        <TextAreaField id="orgActualValue" name="orgActualValue" className="comment-textField" maxLength='65000' onChangeCallback={this.onValueChangeCallback}/>
      </InputSection>
    );
  };

  renderBooleanValue = () => {
    return (
      <InputSection htmlFor="orgActualValue"
                    label={this.labels.get('actualOrgValue')}
                    className="inline right-side">
        <Combobox id="orgActualValue" name="orgActualValue" validate={Validation.required} onChangeCallback={this.onBooleanValueChangeCallback}
                  options={[{label: this.labels.get('true'), value: 'true'}, {
                    label: this.labels.get('false'),
                    value: 'false'
                  }]}/>
      </InputSection>
    );
  };

  renderActualOrgValue = () => {
    switch (this.props.sData.get('itemToEdit').type) {
      case "TEXT":
        return this.renderTextValue();
      case "NUMBER":
        return this.renderNumberValue();
      case "LARGE_TEXT":
        return this.renderLargeTextValue();
      case "BOOLEAN":
        return this.renderBooleanValue();
      default:
        console.error('edit preference dialog: unknown type: ' + this.props.sData.get('itemToEdit').type);
        return null;
    }
  };

  render() {
    let editItem = this.props.sData.get('itemToEdit');
    if (!editItem) {
      return null
    }
    let titleText = this.props.isRfid ? this.labels.get('header.zplTitle.edit') :this.labels.get('header.title.edit');
    return (
      <Dialog
        id="edit-preference-dialog"
        className="edit-preference-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        // onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('name')}
                      htmlFor="name"
                      className="inline left-side">
          <span>{editItem.name}</span>
        </InputSection>

        <InputSection label={this.labels.get('description')}
                      htmlFor="host"
                      className="inline right-side">
          <span>{editItem.description}</span>
        </InputSection>

        <InputSection label={this.labels.get('defaultValue')}
                      htmlFor="defaultValue"
                      className="inline left-side org-default-value">
          <span>{editItem.defaultValue}</span>
        </InputSection>

        {this.renderActualOrgValue()}
      </Dialog>
    );
  }
}

EditPreferenceDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'editPreferenceDialog',
  }
)(EditPreferenceDialog);

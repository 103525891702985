import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import { FormAdapter } from 'infrastructure/js/utils/formUtils';

import * as notificationsService from '../../services/Notifications/notificationsService';

import {navigationStates} from '../../enums/navigationStates';
import { NotificationsStatuses } from '../../../../mat/js/enums/notificationsStatuses';

import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import { api as systemApi } from '../System/systemActions';



let myState = function(getState) {
  return getState().notificationsPage;
};

let myFormAdapter  = function(dispatch, getState) {
  //return new FormAdapter(dispatch, getState, 'notificationsPpage_FitlersForm');
};

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  NOTIFICATIONSPAGE_FETCHDATA_STARTED:  "NOTIFICATIONSPAGE_FETCHDATA_STARTED",
  NOTIFICATIONSPAGE_FETCHDATA_FINISHED: "NOTIFICATIONSPAGE_FETCHDATA_FINISHED",
  NOTIFICATIONSPAGE_UNMOUNT: "NOTIFICATIONSPAGE_UNMOUNT",
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function() {
    return {type: actionTypes.NOTIFICATIONSPAGE_FETCHDATA_STARTED};
  },
  fetchDataFinished: function(payload) {
    return {type: actionTypes.NOTIFICATIONSPAGE_FETCHDATA_FINISHED, payload};
  },
  unmount: function(items) {
    return {type: actionTypes.NOTIFICATIONSPAGE_UNMOUNT};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(loadFirstTime = false) {
  return function(dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.NOTIFICATIONS);
    api.init(dispatch, getState)(loadFirstTime);
  }
};

jsxActions.onMarkItemsAsReadButtonClick = function() {
  return function(dispatch, getState) {
    api.markAllCurrentItemsAsRead(dispatch, getState)();
  }
};

jsxActions.onReadStatusIconClick = function(item) {
  return function(dispatch, getState) {
    api.toggleItemReadStatus(dispatch, getState)(item);
  }
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(loadFirstTime) {
    api.loadData(dispatch, getState)(loadFirstTime);
  }
};

api.loadData = function(dispatch, getState) {
  return function(loadFirstTime) {

    navigatorApi.setLoading(loadFirstTime)(dispatch, getState);
    Promise.all([
      api.fetchNotificationPageCounters(dispatch, getState)(),
      api.fetchData(dispatch, getState)()
    ]).then(() => {
      navigatorApi.setLoading(false)(dispatch, getState);
    });

  }
};

api.fetchData = function(dispatch, getState) {
  return function() {
    dispatch(actions.fetchDataStarted());

    let query = {
      filters: [
        {filterName: "createdDate", values: []}
      ],
      sortByProperty: "createdDate",
      sortDirection: "desc",
      pageSize: 500
    };
    // TODO: Access page Form that wraps all the filters, and populate Query Filters based on Form values.
    return notificationsService.getNotifications(query)
      .catch((errorResponse) => {return errorResponse;})
      .then((response) => {

        // Convert server times into display values in dataList.
        let serverTimeSeconds = getState().system.get("serverDatetime");
        let items = response.dataList.map((item) => {
          item.m_CreatedTime = DateTimeHelper.FormatDateObjectToRelativeTime(item.createdDate, serverTimeSeconds);
          return item;
        });
        response.dataList = items;

        dispatch(actions.fetchDataFinished(response));
      });

  }
};

api.fetchNotificationPageCounters = function(dispatch, getState) {
  return function() {

    return notificationsService.getNotificationsCount()
      .catch((errorResponse) => {return errorResponse;})
      .then((response) => {

        let topNavigatorData = {
          name: createLabelHelper('mat.reports.notificationspage.').get('title'),
          type: navigationStates.NOTIFICATIONS,
          id: 0,
          components: []
        };

        navigatorApi.setData(dispatch, getState)(topNavigatorData);
      });

  }
};


api.markAllCurrentItemsAsRead = function(dispatch, getState) {
  return function() {

    // Get currently displayed items from our state.
    let allVisibleItems = myState(getState).get('dataList');

    let allVisibleUnreadNotificationIds = allVisibleItems
      .filter((x) => (x.status === NotificationsStatuses.UNREAD))
      .map(   (x) => x.id);

    let requestData = {
      ids : allVisibleUnreadNotificationIds,
      status: NotificationsStatuses.READ
    };

    return notificationsService.markAllAsRead(requestData)
      .catch((errorResponse) => {return errorResponse;})
      .then((response) => {
        // Refresh page data.
        api.loadData(dispatch, getState)(true);
      });
  }
};

api.toggleItemReadStatus = function(dispatch, getState) {
  return function(requestData) {
    return notificationsService.changeReadStatus(requestData.id, {status: requestData.status})
      .catch((errorResponse) => {return errorResponse;})
      .then((response) => {
        // Refresh page data.
        api.loadData(dispatch, getState)(true);
      });

  }
};















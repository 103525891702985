import * as orgsService from '../../../services/Administration/organizationsService';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "../../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";
import * as orgsHelper from "../../../components/Administration/OrganizationsPage/utils/organizationsHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_ORGANIZATIONS_TAB, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    query.pageSize = 300;
    return orgsService.fetchOrganizations(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    let status = orgsHelper.getStatuses().find(item => {return (item.value === obj.status)});
    obj.m_status =  status ? status.label : '';
    let locale = orgsHelper.getLocales().find(item => {return (item.value === obj.orgPreferences.systemLocale)});
    obj.m_locale = locale ? locale.label : '';
    let timeFormat = orgsHelper.getTimeFormats().find(item => {return (item.value === obj.orgPreferences.systemTimeFormat)});
    obj.m_timeFormatLabel = timeFormat ? timeFormat.label : '';

    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import { actionTypes as activityLogDialogGridActionsTypes } from '../../actions/ActivityLogDialog/activityLogDialogGridActions';
import { List, Map, fromJS } from 'immutable';

let grid = {
  activities: null,
  filteredActivitiesCount: 0,
  filtersData: List(),
  filterState: List(),
  sortState: {filterName: 'fromDate', direction: 'desc'},
  filterConfig: [
    {fieldName: 'entityExpandedType', filterName: 'entityType'},
    {fieldName: 'entityBusinessId', filterName: 'assetBusinessId', getOptions: true},
    {fieldName: 'activityType', filterName: 'activityType', getOptions: true},
    {fieldName: 'batchJobStatus', filterName: 'batchJobStatus', getOptions: true},
    {fieldName: 'm_Time', filterName: 'fromDate'},
    {fieldName: "", filterName: "rollEntityType"},
    {fieldName: "", filterName: "kitEntityType"},
    {fieldName: "", filterName: "woEntityType"},
  ],
  loading: false,
  error: false,
  queryResultInfo: null
};

let defaultState = {
  grid: new Map(grid)

};



export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case activityLogDialogGridActionsTypes.ACTIVITYLOG_DIALOG_GRID_FETCH_DATA_IN_PROGRESS:
      state = state.setIn(['grid', 'error'], false);
      return state.setIn(['grid','loading'], true);

    case activityLogDialogGridActionsTypes.ACTIVITYLOG_DIALOG_GRID_FETCH_DATA_FINISHED:
      state =  state.setIn(['grid', 'filteredActivitiesCount'], action.payload.filteredActivitiesCount)
                    .setIn(['grid', 'filterState'], action.payload.filterModel)
                    .setIn(['grid', 'sortState'], action.payload.sortModel)
                    .setIn(['grid', 'filtersData'], List(action.payload.filtersData))
                    .setIn(['grid', 'activities'], List(action.payload.activities))
                    .setIn(['grid', 'queryResultInfo'], action.payload.queryResultInfo)
                    .setIn(['grid', 'loading'], false);
      return state;

    case activityLogDialogGridActionsTypes.ACTIVITYLOG_DIALOG_GRID_CLEAR_DATA:
      return state.set('grid', new Map(grid));

    case activityLogDialogGridActionsTypes.ACTIVITYLOG_DIALOG_GRID_FETCH_DATA_ERROR:
      state = state.setIn(['grid','loading'], false);
      return state.setIn(['grid','error'], true);

    default:
      return state;
  }
}




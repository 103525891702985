import { Map} from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';

let defaultState = {
  show: false,
  loading: false,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    default:
      return defaultDialogReducer(state, action, dialogsNames.ARCHIVE_WO_DIALOG, defaultState);
  }
}







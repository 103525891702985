import React from 'react';
import PropTypes from 'prop-types';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Format from 'infrastructure/js/components/controls/controlsFormat';


require('./createMqttSensorDialog.scss');

const qosOptions = [
  {value: 0, label: '0'},
  {value: 1, label: '1'},
  {value: 2, label: '2'},
]

class CreateMqttSensorDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.rfidsettings.dialog.createMqttSensor.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.state = {
      selectedTab: 'propertiesTab',
    };
  }

  componentDidMount() {
    let initialValues = {
      statusBm:   this.isEditMode ? this.itemToEdit.statusBm : 'ONLINE',
      statusCm:   this.isEditMode ? this.itemToEdit.statusCm : 'OFFLINE',
      statusWm:   this.isEditMode ? this.itemToEdit.statusWm : 'DISCONNECTED',
      valueQoS:   this.isEditMode && this.itemToEdit.valueQoS ? this.itemToEdit.valueQoS : 0,
      statusQoS:  this.isEditMode && this.itemToEdit.statusQoS ? this.itemToEdit.statusQoS : 0,
      commandQoS: this.isEditMode && this.itemToEdit.readCommandQoS ? this.itemToEdit.readCommandQoS : 0,
    };

    if (this.isEditMode) {
      initialValues.name = this.itemToEdit.name;
      initialValues.valueTopic = this.itemToEdit.valueTopic;
      initialValues.statusTopic = this.itemToEdit.statusTopic;
      initialValues.commandTopic = this.itemToEdit.readCommandTopic;
      initialValues.commandPayload = this.itemToEdit.readCommandPayload;
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine ||
            this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
          )
        }
      ]
    };
  }

  onSubmit = (data) => {
    let newData = {
      brokerId: this.props.broker ? this.props.broker.value : null,
      name: data.name,
      valueTopic:  data.valueTopic ? data.valueTopic : null,
      valueQoS: data.valueQoS ? data.valueQoS : 0,
      statusTopic: data.statusTopic ? data.statusTopic : null,
      statusQoS: data.statusQoS ? data.statusQoS : 0,
      statusBm: data.statusBm ? data.statusBm : null,
      statusCm: data.statusCm ? data.statusCm : null,
      statusWm: data.statusWm ? data.statusWm : null,
      readCommandTopic:  data.commandTopic ? data.commandTopic : null,
      readCommandQoS: data.commandQoS ? data.commandQoS : 0,
      readCommandPayload: data.commandPayload ? data.commandPayload : null,

      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };

    if(this.isEditMode) {
      newData.sensorId =  this.itemToEdit.id;
    }
    else {
      newData.deviceAppProfileId = this.props.broker && this.props.broker.data ? this.props.broker.data.deviceAppProfileId : null;
    }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onTabClick = (key) => {
    this.props.handleSubmit((formData) => {
      this.setState({selectedTab: key});
    })();
  };

  onQoSChangeCallback = (reduxName, newValue, oldValue ) => {
    //prevent clearing
    if ( !newValue && oldValue && oldValue.value) {
      this.props.change(reduxName, oldValue.value);
    }
  };

  parseComboValueOnly = () => {
    return (value, name) => {
      return ((value && value.value) ? value.value : 0);
    }
  };

  renderPropertiesTab = () => {
    return (
      <Tabs.Tab className="properties-tab" eventKey="propertiesTab" title={this.labels.get('tabs.properties')} animation={false}>
          <InputSection label={this.labels.get('sensorName') + '*'} htmlFor="name" className="">
            <TextField id="name"
                       name="name"
                       maxLength={30}
                       validate={Validation.required}
                       placeholder={this.labels.get('sensorName.placeholder')}
            />
          </InputSection>

          <div className="topic-section">
            <InputSection label={this.labels.get('valueTopic') + '*'} htmlFor="valueTopic" className="inline left-side">
              <TextAreaField id="valueTopic" name="valueTopic" maxLength='300'
                             validate={Validation.required}
                             placeholder={this.labels.get('topic.placeholder')}/>
            </InputSection>
            <InputSection  htmlFor="valueQoS" label={this.labels.get('qos') + '*'}   className="inline right-side">
              <Combobox name="valueQoS" id="valueQoS" options={qosOptions}
                        parse={this.parseComboValueOnly()}
                        format={Format.findOptionByValue(qosOptions)}
                        validate={Validation.required}
                        onChangeCallback={(newValue, oldValue) => this.onQoSChangeCallback('valueQoS', newValue, oldValue)}
              />
            </InputSection>
          </div>

      </Tabs.Tab>
    )
  };

  renderStatusTab = () => {
    return (
      <Tabs.Tab className="status-tab" eventKey="statusTab" title={this.labels.get('tabs.status')} animation={false}>

        <div className="topic-section">
          <InputSection label={this.labels.get('statusTopic')} htmlFor="statusTopic" className="inline left-side">
            <TextAreaField id="statusTopic" name="statusTopic" maxLength='300' placeholder={this.labels.get('topic.placeholder')}/>
          </InputSection>
          <InputSection  htmlFor="statusQoS" label={this.labels.get('qos')}   className="inline right-side">
            <Combobox name="statusQoS" id="statusQoS" options={qosOptions}
                      parse={this.parseComboValueOnly()}
                      format={Format.findOptionByValue(qosOptions)}
                      onChangeCallback={(newValue, oldValue) => this.onQoSChangeCallback('statusQoS', newValue, oldValue)}
            />
          </InputSection>
        </div>

        <InputSection label={this.labels.get('birthMessage')} htmlFor="statusBm" className="">
          <TextAreaField id="statusBm" name="statusBm" maxLength='300' placeholder={this.labels.get('message.placeholder')}/>
        </InputSection>

        <InputSection label={this.labels.get('closeMessage')} htmlFor="statusCm" className="">
          <TextAreaField id="statusCm" name="statusCm" maxLength='300' placeholder={this.labels.get('message.placeholder')}/>
        </InputSection>

        <InputSection label={this.labels.get('willMessage')} htmlFor="statusWm" className="">
          <TextAreaField id="statusWm" name="statusWm" maxLength='300' placeholder={this.labels.get('message.placeholder')}/>
        </InputSection>

      </Tabs.Tab>    )
  };

  renderCommandsTab = () => {
    return (
      <Tabs.Tab className="commands-tab" eventKey="commandsTab" title={this.labels.get('tabs.commands')} animation={false}>

        <div className="topic-section">
          <InputSection label={this.labels.get('commandTopic')} htmlFor="commandTopic" className="inline left-side">
            <TextAreaField id="commandTopic" name="commandTopic" maxLength='300' placeholder={this.labels.get('topic.placeholder')}/>
          </InputSection>
          <InputSection  htmlFor="commandQoS" label={this.labels.get('qos')}   className="inline right-side">
            <Combobox name="commandQoS" id="commandQoS" options={qosOptions}
                      parse={this.parseComboValueOnly()}
                      format={Format.findOptionByValue(qosOptions)}
                      onChangeCallback={(newValue, oldValue) => this.onQoSChangeCallback('commandQoS', newValue, oldValue)}
            />
          </InputSection>
        </div>

        <InputSection label={this.labels.get('payload')} htmlFor="commandPayload" className="">
          <TextAreaField id="commandPayload" name="commandPayload" maxLength='300' placeholder={this.labels.get('payload.placeholder')}/>
        </InputSection>

      </Tabs.Tab>
    )
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    let selectedTab = this.state.selectedTab;

    return (
      <Dialog
        id="create-mqtt-sensor-dialog"
        className="create-mqtt-sensor-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <Tabs activeKey={selectedTab} tabType="default" onSelect={this.onTabClick} id="content-tabs">
          {this.renderPropertiesTab()}
          {this.renderStatusTab()}
          {this.renderCommandsTab()}
        </Tabs>

      </Dialog>
    );
  }
}

CreateMqttSensorDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'createMqttSensorDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(CreateMqttSensorDialog);




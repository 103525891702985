import React from 'react';
import PropTypes from 'prop-types';

import {Field} from 'redux-form';
import cn from 'classnames';
import {PL_TextField} from 'infrastructure/js/components/controls/TextField/textField';

require('./filePicker.scss');


export default class PL_FilePickerField extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_FilePicker}></Field>
    );
  }
};


export class PL_FilePicker extends React.PureComponent {

  constructor(props) {
    super(props);

    let fileName = this.props.selectedFile ? this.props.selectedFile.name : '';

    this.state = {
      fileName: fileName
    }
  }

  onSelectedFileChanged = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({fileName: event.target.files[0].name});

      if (this.props.input) {
        this.props.input.onChange(event.target.files[0].name);
      }

      if (this.props.onSelectedFileChanged) {
        this.props.onSelectedFileChanged(event.target.files[0]);
      }
    }
  };

  //NOTE: it is a workaround to force the onChange event be triggered when the same file is selected again.
  onClickHandler = (e) => {
    e.target.value = '';
  }

  getAcceptTypes = (accept) => {
      return accept ? accept.join() : [];
  };

  render() {
    let {placeHolder , buttonText , accept , disabled, onSelectedFileChanged,  ...otherProps} = this.props;

    return (
      <div className="file-picker">

        <input type="file" id="fileChooser"
               disabled={disabled}
               multiple={false}
               className="input-file"
               accept={this.getAcceptTypes(accept)}
               onClick={this.onClickHandler}
               onChange={this.onSelectedFileChanged}
        />

        <div className='file-picker-selection'>
          <PL_TextField
            id="selectedFileName"
            placeholder={placeHolder}
            value={this.state.fileName}
            disabled={disabled}
            readOnly={true}
            className={cn('file-picker-text', {disabled})}
            {...otherProps}
          />
          <label className={cn('file-picker-browse-btn', {disabled})} htmlFor="fileChooser" disabled={disabled}>{buttonText || ''}</label>
        </div>
      </div>
    );
  }

}

PL_FilePicker.propTypes = {
  accept: PropTypes.array,
  disabled: PropTypes.bool,
  buttonText:PropTypes.string,
  placeHolder:PropTypes.string,
  onSelectedFileChanged: PropTypes.func
};

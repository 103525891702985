import * as boHelper from '../batchOperationDialogActionsHelper';
import assetPrintTagsService from '../../../services/Assets/assetPrintTagsService';
import { api as printApi } from '../../PrintRfidActions/printRfidActions';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('PRINT_RFID_TAGS_DIALOG'),
  PRINT_RFID_TAGS_DIALOG_SHOW: 'PRINT_RFID_TAGS_DIALOG_SHOW',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actions = {
  ...boHelper.getActions('PRINT_RFID_TAGS_DIALOG'),
  show: function (payload) {
    return { type: actionTypes.PRINT_RFID_TAGS_DIALOG_SHOW, payload: payload };
  },
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
const config = {
  submitMethod: (data) => {
    if (data.isGeneric) {
      return assetPrintTagsService.printGenericRfidTags(data);
    } else {
      return assetPrintTagsService.printRfidTagsNew(data);
    }
  },
  submitWithProgressMethod: (data) => {
    if (data.isGeneric) {
      return assetPrintTagsService.printGenericRfidTags(data);
    } else {
      const { tagsCount, printerId, assetIds } = data;
      return assetPrintTagsService.printRfidTagsNew({ tagsCount, printerId, assetIds });
    }
  },
  dialogReduxStorageName: 'printRfidTagsDialog',
};

export let api = {
  ...boHelper.getApiActions(actions, config),
  fetchPrintersReady: function (dispatch, getState) {
    return function (printers) {
      dispatch(actions.fetchPrintersReady(printers));
    };
  },
  show: function (dispatch, getState) {
    return function (assets, isGeneric, printers, selectedAsset) {
      let payload = { assets, isGeneric, printers, selectedAsset };
      dispatch(actions.show(payload));
    };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),
};

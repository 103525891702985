import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Label from 'infrastructure/js/components/Label/label';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip';
import {enumTypes, getEnumValue} from '../../../../utils/enumHelper';

require('./lastOperationCell.scss');

export default class LastOperationCell extends React.PureComponent {
  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.locationpage.workorder.grid.columns.lastoperation.');
    this.activityLabels = createLabelHelper('mat.locationpage.view.activities.grid.columns.type.filter.');
  }

  getIcon = (operation) => {
    if (!operation.operationStatus) {
      return null;
    }
    let isCompleted = (operation.operationStatus === getEnumValue(enumTypes.OPERATION_STATUS)('COMPLETE'));
    let statusIcon = isCompleted ? 'pl pl-check-circle-icon-blue' : 'pl pl-operation-in-process';
    let statusLabel = isCompleted ? this.labels.get('operation.status.completed') : this.labels.get('operation.status.inProgress');

    return <Tooltip
      placement="top"
      value={statusLabel}
      delayShow={500}>
      <span className={statusIcon}/>
    </Tooltip>
  };

  getInfo = (operation) => {
    if (!operation || !operation.m_time) {
      return '';
    }

    let location = operation.locationName ? ` - ${operation.locationName}` : '';
    return <Label text={ operation.m_time + location} />
  };


  render() {
    if (this.props.value) {
      let description = this.activityLabels.get(this.props.value.activityTypeDisplayKey);

      if (this.props.value.activityType === 'Operation Reported' && this.props.value.operationReported) {
        description = this.labels.get('operation', undefined, {type: this.props.value.operationReported});
      }

      return (
        <div className="last-operation">

          <div className="title">
            {this.getIcon(this.props.value)}
            <Label className="description" text={description} />
          </div>
          <div className="info">{this.getInfo(this.props.value)}</div>
        </div>
      )
    }
    else {
      return (
        <div className="last-operation">
        </div>
      )
    }
  }
}

LastOperationCell.propTypes = {
  value : PropTypes.object
};





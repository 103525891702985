import React from 'react';
import PropTypes from 'prop-types';

import AdminGrid from './AdminGrid/adminGrid';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

require('./adminTab.scss');


export default class AdminTab extends React.PureComponent {

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.administration.orgsmanagement.adminpanel.');
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      title: this.labels.get('header.title'),
      buttons: [
        {
          id:'rejectOgDelete',
          label: headerItemsLabels.REJECT_ORG_DELETION,
          icon: 'pl pl-operation-canceled',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onRejectOrganizationDeletionClick,
          actionData: selectedRowsData.get(0)
        },
        {
          id:'acceptOrgDelete',
          label: headerItemsLabels.ACCEPT_ORG_DELETION,
          icon: 'pl pl-check-icon-blue-hover',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onAcceptOrganizationDeletionClick,
          actionData: selectedRowsData.get(0)
        }
      ]
    };
  }

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className="admin-tab">
        <Header {...headerItems} />
        <AdminGrid actions={this.props.actions.adminTabGridActions} />
      </div>
    );

  }
}

AdminTab.propTypes = {
  actions: PropTypes.object.isRequired,
  sAdminTabGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired
};

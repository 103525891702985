import React from 'react';
import {reduxForm} from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

require('./adminGrid.scss');

class AdminGrid extends React.PureComponent{
  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.organizations.admin.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }


  createColumnsConfig = () => {
    return [
      {
        fieldName: 'orgToDeleteBusinessId',
        title: this.labels.get('columns.name.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'orgStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'requestedOn',
        title: this.labels.get('columns.requestdate.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'requestedByBusinessId',
        title: this.labels.get('columns.requester.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'comment',
        title: this.labels.get('columns.comment.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      }
    ]
  }

  render() {
    return (
      <div className="admin-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_ADMIN_TAB}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={[]}
        >
        </Grid>
      </div>
    );
  }

}

export default reduxForm({
    form: 'adminGrid'
  }
)(AdminGrid);

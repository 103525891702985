

export function TreeMap(items=[]) {

  const addToMap = (item, map) => {
    if (item.children?.length) {
      item.children.forEach((child, index) => {
        addToMap(child, map);
      });
    }
    map.set(item.id, item);
  }

  const createTreeMap = (items) => {
    const map = new Map();

    items.forEach((item, index) => {
      addToMap(item, map);
    });

    // console.log('=============map=', map);
    return map;
  }

  let treeMap = createTreeMap(items);

  this.getItem = function(itemId) {
    return treeMap.get(itemId);
  }

  this.isDescendant = function( parentId, childId) {
    if (!parentId || !childId) return false;

    let child = treeMap.get(childId);
    let safeguard = 1000;

    while(child && safeguard > 0) {
      safeguard--;

      if (parentId === child.id) {
        return true;
      }

      child = treeMap.get(child.parentId);
    }

    return false;
  }
}

import Network from 'infrastructure/js/modules/network';

export function deleteReaders(data) {
  return Network.delete(`deviceAppProfile/${data.deviceAppProfileId}/readers` , {readersIds: [data.id]});
}

export function createReader(deviceAppId , data) {
  return Network.post(`deviceAppProfile/${deviceAppId}/reader` , data);
}

export function editReader(id,data) {
  delete data.id;
  return Network.put(`reader/${id}` , data);
}

export function fetchAntennas(deviceAppProfileId) {
  return Network.get(`deviceAppProfile/${deviceAppProfileId}/antennas`);
}

export function fetchReaders() {
  return Network.get('readers');
}

export function fetchReadersByDeviceProfileId(deviceAppProfileId) {
  return Network.get(`deviceAppProfile/${deviceAppProfileId}/readers`);
}




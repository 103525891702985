import { actionTypes } from '../../../../infrastructure/js/actions/languageActions';

export const initialState = {
  locale: 'en',
  messages: {},
}

export function intlReducer(state = initialState, action) {
  if (action.type !== actionTypes.UPDATE) {
    return state
  }

  return { ...state, ...action.payload }
}

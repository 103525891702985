import React, {Component} from 'react';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import ToolMaintenanceDashboardHeader from '../Components/DashboardHeader/toolMaintenanceDashboardHeader';
import ToolsStatusChart from '../Components/ToolsStatusChart/toolsStatusChart';
import ToolsCapacityChart from '../Components/ToolsCapacityChart/toolsCapacityChart';
import CommonKPI from '../Components/CommonKPI/commonKPI';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";

require('./toolMaintenanceDashboardPage.scss');

export default class ToolMaintenanceDashboardPage extends Component {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.dashboard.toolsMaintenance.');
  }

  getChartData = () => {
    let {sData} = this.props;
    let chartsData = sData.get('chartData');
    return chartsData ? chartsData : {};
  };

  getKPIsData = () => {
    let {sData} = this.props;
    let data = sData.get('kpiData');

//'mat.dashboard.toolsMaintenance.kpi.notAvailable': 'N/A',
    if (data) {
      let kpiData = [
        {
          title: this.labels.get('kpi.mttr'),
          tooltip: this.labels.get('kpi.mttrTooltip'),
          leftData: `${Math.floor(data.mttr * 100) / 100 } ${this.labels.get('kpi.mttr.units')}`,
          className: 'cupsole red'
        },
       /* {
          title: this.labels.get('kpi.smcp'),
          leftData: `${UnitHelper.serverValueToUserValue(unitTypes.LENGTH, data.totalLength, 2)} %`,
          className: 'cupsole red'
        },*/
        {
          title: this.labels.get('kpi.capacityUtilization'),
          leftData: `${data.averageCapacity} %`,
          className: 'cupsole green'
        },
       /* {
          title: this.labels.get('kpi.oee'),
          leftData: `${UnitHelper.serverValueToUserValue(unitTypes.LENGTH, data.totalLength, 2)} %`,
          className: 'cupsole green'
        },*/
      ];
      return kpiData;
    }

    return [];
  };

  renderKPIs = () => {
    let items = this.getKPIsData();

    let KPIs = items.map((item, index) => {
      return <CommonKPI key={index} data={item} className={'gray-bg'}/>
    });

    return (
      <div className="kpis-container">
        {KPIs}
      </div>
    )
  };

  renderLoadingOverlay = (loading) => {
    if (!loading) {
      return null;
    }
    return <Overlay.Loading/>;
  };


  render() {
    let {sData} = this.props;
    let chartsData = this.getChartData();
    let loading = sData.get('loading');

    return (
      <div className="tool-maintenance-dashboard-page">
        <div>
          <ToolMaintenanceDashboardHeader form="toolMaintenanceHeader" actions={this.props.actions}/>

          {this.renderKPIs()}

          <div className={'charts-container'}>
            <ToolsCapacityChart chartData={chartsData.toolsCapacity} loading={loading}/>
            <ToolsStatusChart chartData={chartsData.toolsInLocation} loading={loading}/>
          </div>
        </div>
        {this.renderLoadingOverlay(loading)}

      </div>
    )
  }
};

let parse = {};


// Parse input string to number. Empty string results in null.
parse.toNumber = () => {
  return (value, name) => {

    if (!Number.isInteger(value) &&  value.trim() === '') {
      return null;
    }

    return +(value);
  }
};

// Convert number to string. Nulls should result in an empty string.
parse.numberToString = () => {
  return (value, name) => {

    if (value === 0) {
      return '0';
    }

    if (!value) {
      return '';
    }

    return (value + '');

  }
};



// Extract value only from Combobox value object.
// E.g:  {label: 'blabla', value: 111, data: {...}} ===> 111.
parse.comboValueOnly = () => {
  return (value, name) => {
    return ((value && value.value) || value);
  }
};

// Extract label only from Combobox value object.
// E.g:  {label: 'blabla', value: 111, data: {...}} ===> 'blabla'.
parse.comboLabelOnly = () => {
  return (value, name) => {
    return ((value && value.label) || value);
  }
};


// Extract values only from Multi Combo box value object.
// E.g:  [{label: 'blabla', value: 111, data: {...}}, {label: 'blabla2', value: 222, data: {...}}] ===> [111, 222].
parse.comboValuesOnly = () => {
  return (values, name) => {
    let res = values ? values.map((value) => {
      return ((value && value.value) || value);
    }) : [];
    return res;
  }
};


//
// Convert complex Combobox value to standard server object.
// {value: 123, label: 'AAA'} ===> {id: 123, name: 'AAA'}
//
// And support creation of new options:
// {value: 'CCC', label: 'CCC'} ===> {id: null, name: 'CCC'}
//
parse.comboValueToDomainObject = () => {
  return (value, name) => {

    if (!value || value.label === null) {
      return null;
    }

    return {
      id: (value.value !== value.label) ? value.value : null,
      name: value.label
    }

  }
};

// ...Same as "comboValueToDomainObject" but for tags.
parse.comboValueToDomainTagObject = () => {
  return (value, name) => {

    if (!value || value.label === null) {
      return null;
    }

    return {
      id: (value.value !== value.label) ? value.value : null,
      tagKey: value.label
    }

  }
};


// Used to find proper initial value from all available options, when server sent us only
// label as a value.
parse.findOptionByLabel = (label, options) => {
  return options.find(option => option.label === label);
};


// Used to find proper initial value from all available options, using label server id.
parse.findOptionById = (labelId, options) => {
  return options.find(option => option.value === labelId);
};



export default parse;







import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, { headerItemsLabels } from '../../../Common/Header/header';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import CreateMaterialTypeDialog from './Dialogs/CreateMaterialTypeDialog/createMaterialTypeDialog';
import MaterialTypesViewGrid from './MaterialTypesViewGrid/materialTypesViewGrid';
import { MatSettingsMenuItemsIds } from '../../../../enums/navigationMenuItemsIds';
import { isAllItemsActive, isAllItemsDeactive } from '../utils/matSettingsHelper';
import { gridsNames } from '../../../../enums/gridsNames';
import {ImportAcceptFileTypes, ImportTypes} from '../../../../enums/importTypes';

export default class MaterialTypesView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.materialtypes.view.');
  }

  componentDidMount() {
    // this.props.actions.init(false);
    this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_MATERIAL_TYPES);
  }

  getHeaderItems() {
    let { actions } = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: "create",
          label: headerItemsLabels.CREATE,
          icon: "pl pl-icon-add",
          action: actions.header.onCreateMaterialTypeClick
        },
        {
          id: "edit",
          label: headerItemsLabels.EDIT,
          icon: "pl pl-edit-icon",
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditMaterialTypeClick,
          actionData: selectedRowsData.get(0)
        }
      ],

      kebab: {
        menuItems: [
          {
            id: "activate",
            label: headerItemsLabels.ACTIVATE,
            action: actions.header.onActivateMaterialTypesClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsDeactive(selectedRowsData)),
            actionData: selectedRowsData
          },
          {
            id: "deactivate",
            label: headerItemsLabels.DEACTIVATE,
            action: actions.header.onDeactivateMaterialTypesClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsActive(selectedRowsData)),
            actionData: selectedRowsData
          },
          {
            id: "importMaterialTypes",
            label: headerItemsLabels.IMPORT_MATERIAL_TYPES,
            action: actions.header.importItems,
            disabled: false,
            actionData: {
              importTypes: [ImportTypes.IMPORT_MATERIAL_TYPES],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: actions.reload
            }
          },
          {
            id: "exportMaterialTypes",
            label: headerItemsLabels.EXPORT_MATERIAL_TYPES,
            action: actions.header.exportItems,
            disabled: !hasRows,
            actionData: { gridName: gridsNames.ADMINISTRATION_MAT_SETTINGS_MATERIAL_TYPES }
          },
          {
            id: "remove",
            label: headerItemsLabels.DELETE,
            action: actions.header.onRemoveMaterialTypesClick,
            disabled: (!selectedRowsData || selectedRowsData.size === 0),
            actionData: selectedRowsData
          }
        ]
      }
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get("title");
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems}/>

        <MaterialTypesViewGrid
          actions={{ ...this.props.actions.materialTypesViewGridActions, ...this.props.actions.fetchEntitiesActions }}
        />

        <PL_DialogWrapper
          dialogComponent={CreateMaterialTypeDialog}
          show={this.props.sCreateMaterialTypeDialogData.get("show")}
          actions={this.props.actions.createMaterialTypeDialogActions}
          sData={this.props.sCreateMaterialTypeDialogData}
          reloadParentComponent={this.reloadPage}
          sLoggedInUser={this.props.sLoggedInUser}
        />

      </div>
    );
  }
}

MaterialTypesView.propTypes = {
  sGrid: PropTypes.object
};


import MultiSelectFilter from '../Filters/MultiSelectFilter/multiSelectFilter';
import MultiPropertiesFilter from '../Filters/MultiPropertiesFilter/multiPropertiesFilter';
// import ValueComparerFilter from '../Filters/ValueCompareFilter/valueCompareFilter.js';
import MultiSectionsFilter from '../Filters/MultiSectionsFilter/multiSectionsFilter';
import SearchFilter from '../Filters/SearchFilter/searchFilter';
import DateSelectFilter from '../Filters/DateSelectFilter/dateSelectFilter';
import HorizonFilter from '../Filters/HorizonFilter/horizonFilter';
// import CombinedFilter from '../Filters/CombinedFilter/combinedFilter';
import { filterTypes } from 'infrastructure/js/enums/filterTypes.js';
import { List } from 'immutable';
import { createLabelHelper } from '../../../utils/labelHelper';
import DateTimeHelper from '../../../utils/dateTimeHelper';
import PermissionManager from '../../../utils/permissionManager';

const createGetDataQuery = function (params, filterConfig = [], pageSize, pageNumber = 0) {
  let query = {
    filters: params != null ? params.filterModel : List(),
    pageSize: pageSize,
    pageNumber: pageNumber,
    sortDirection: '',
    sortByProperty: '',
  };

  if (params != null) {
    if (!params.filterModel || params.filterModel.size === 0) {
      for (let i = 0; i < filterConfig.length; i++) {
        if (filterConfig[i].filterName) {
          query.filters = query.filters.push({
            filterName: filterConfig[i].filterName,
            shouldCreateFilterOptions: !!filterConfig[i].getOptions,
          });
        }
      }
    }
    if (params.sortModel && params.sortModel.filterName) {
      query.sortByProperty = params.sortModel.filterName;
      query.sortDirection = params.sortModel.direction;
    }
  }
  return query;
};

const createGetDataQueryByGridData = function (gridData) {
  if (!gridData) {
    console.error('missing grid data for creating query');
    return null;
  }

  return this.createGetDataQuery(
    {
      filterModel: gridData.get('filterState'),
      sortModel: gridData.get('sortState'),
    },
    gridData.get('filterConfig')
  );
};

const getFilterValues = function (filters, name) {
  let filter = filters.find((filter) => filter.name == name);

  if (!filter || filter.options.length == 0) {
    return [];
  }

  //return filter.options.map(option => {return [option.value]});
  return filter.options.map((option) => {
    return [option];
  });
};

// const isFilterStateActive = function (filterState, filterName) {
//   return filterState?.some((filter) => {
//     return filter.filterName === filterName && item.values;
//   });
//
// }

const addColumnWithMultiSelectFilter = (columnConfig, filtersData) => {
  let filterOptions = {
    filter: MultiSelectFilter,
    filterParams: {
      apply: true,
      filterName: columnConfig.filterName,
      filterWidth: columnConfig.filterWidth,
      filterAlignment: columnConfig.filterAlignment,
      mainPropertyName: columnConfig.fieldName,
      values: getFilterValues(filtersData, columnConfig.filterName),
      newRowsAction: 'keep',
      ...columnConfig.filterProps,
    },
  };

  return addColumn(columnConfig, filterOptions);
};

const addColumnWithMultiSelectAsyncFilter = (columnConfig, filtersData) => {
  let filterOptions = {
    filter: MultiSelectFilter,
    filterParams: {
      apply: true,
      filterName: columnConfig.filterName,
      filterAlignment: columnConfig.filterAlignment,
      mainPropertyName: columnConfig.fieldName,
      values: getFilterValues(filtersData, columnConfig.filterName),
      newRowsAction: 'keep',
      fetchConfig: columnConfig.fetchConfig,
      asyncMode: true,
      ...columnConfig.filterProps,
    },
  };

  return addColumn(columnConfig, filterOptions);
};

const addColumnWithMultiPropertiesFilter = (columnConfig) => {
  let filterOptions = {
    filter: MultiPropertiesFilter,
    filterParams: {
      filterAlignment: columnConfig.filterAlignment,
      handleSubmit: columnConfig.handleSubmit,
      change: columnConfig.change,
      apply: true,
      properties: columnConfig.propertyTypes,
      newRowsAction: 'keep',
      ...columnConfig.filterProps,
    },
  };

  return addColumn(columnConfig, filterOptions);
};

// const addColumnWithValueComparerFilter = (columnConfig, filtersData) => {
//   let filterOptions = {
//     filterFramework: ValueComparerFilter,
//     filterParams: {
//       filterName: columnConfig.filterName,
//       filterAlignment: columnConfig.filterAlignment,
//       values: getFilterValues(filtersData, columnConfig.filterName),
//       apply: true,
//       newRowsAction: 'keep',
//       label: columnConfig.filterLabel,
//       valueConverter: columnConfig.filterConverter,
//       ...columnConfig.filterProps,
//     },
//   };
//
//   return addColumn(columnConfig, filterOptions);
// };

const addColumnWithMultiSectionsFilter = (columnConfig) => {
  let filterOptions = {
    filter: MultiSectionsFilter,
    filterParams: {
      filterName: columnConfig.filterName,
      filterAlignment: columnConfig.filterAlignment,
      apply: true,
      sections: columnConfig.filterSections,
      newRowsAction: 'keep',
      ...columnConfig.filterProps,
    },
  };

  return addColumn(columnConfig, filterOptions);
};

const addColumnWithSearchFilter = (columnConfig, filtersData) => {
  let filterOptions = {
    filter: SearchFilter,
    filterParams: {
      handleSubmit: columnConfig.handleSubmit,
      filterName: columnConfig.filterName,
      filterAlignment: columnConfig.filterAlignment,
      values: getFilterValues(filtersData, columnConfig.filterName),
      apply: true,
      newRowsAction: 'keep',
      ...columnConfig.filterProps,
    },
  };

  return addColumn(columnConfig, filterOptions);
};

const addColumnWithDateSelectFilter = (columnConfig, filtersData) => {
  let filterOptions = {
    filter: DateSelectFilter,
    filterParams: {
      handleSubmit: columnConfig.handleSubmit,
      filterName: columnConfig.filterName,
      filterAlignment: columnConfig.filterAlignment,
      values: getFilterValues(filtersData, columnConfig.filterName),
      apply: true,
      isTime: columnConfig.isTime,
      label: columnConfig.filterLabel,
      newRowsAction: 'keep',
      ...columnConfig.filterProps,
    },
  };

  return addColumn(columnConfig, filterOptions);
};

const addColumnWithHorizonFilter = (columnConfig, filtersData) => {
  let filterOptions = {
    filter: HorizonFilter,
    filterParams: {
      handleSubmit: columnConfig.handleSubmit,
      filterName: columnConfig.filterName,
      filterAlignment: columnConfig.filterAlignment,
      values: getFilterValues(filtersData, columnConfig.filterName),
      apply: true,
      isTime: columnConfig.isTime,
      label: columnConfig.filterLabel,
      newRowsAction: 'keep',
      ...columnConfig.filterProps,
    },
  };
  return addColumn(columnConfig, filterOptions);
};

// const addColumnWithCombinedFilter = (columnConfig, filtersData) => {
//   let filterOptions = {
//     filterFramework: CombinedFilter,
//     filterParams: {
//       handleSubmit: columnConfig.handleSubmit,
//       filterName: columnConfig.filterName,
//       filterAlignment: columnConfig.filterAlignment,
//       values: getFilterValues(filtersData, columnConfig.filterName),
//       apply: true,
//       label: columnConfig.filterLabel,
//       newRowsAction: 'keep',
//       ...columnConfig.filterProps,
//     },
//   };
//   return addColumn(columnConfig, filterOptions);
// };

const addColumn = (columnConfig, filterOptions = null) => {
  let cellClasses = columnConfig.columnOptions?.cellClass || '';
  switch (columnConfig.columnOptions?.cellEditor) {
    case 'editableMultiCell':
      cellClasses += ' editableMultiCell';
      break;
    case 'editableCell':
      cellClasses += ' editableCell';
      break;
    default:
      break;
  }

  return {
    field: columnConfig.fieldName,
    headerName: columnConfig.title,
    width: columnConfig.width,
    filterWidth: columnConfig.filterWidth,
    filterAlignment: columnConfig.filterAlignment,
    valueFormatter: columnConfig.valueFormatter,
    filterOptions,
    sortingOrder: ['asc', 'desc'],
    menuTabs: filterOptions ? ['filterMenuTab'] : [],
    cellClass: cellClasses,
    ...columnConfig.columnOptions,
  };
};

const createColumnMetaData = (columnData, filtersData) => {
  switch (columnData.filterType) {
    case filterTypes.MULTI_SELECT:
      return addColumnWithMultiSelectFilter(columnData, filtersData);
    case filterTypes.MULTI_SELECT_ASYNC:
      return addColumnWithMultiSelectAsyncFilter(columnData, filtersData);
    case filterTypes.MULTI_PROPERTIES:
      return addColumnWithMultiPropertiesFilter(columnData);
    case filterTypes.MULTI_SECTIONS:
      return addColumnWithMultiSectionsFilter(columnData);
    case filterTypes.SEARCH:
      return addColumnWithSearchFilter(columnData, filtersData);
    case filterTypes.DATE_SELECT:
      return addColumnWithDateSelectFilter(columnData, filtersData);
    case filterTypes.HORIZON:
      return addColumnWithHorizonFilter(columnData, filtersData);
    // case filterTypes.COMBINED:         //not in use
    //   return addColumnWithCombinedFilter(columnData, filtersData);
    // case filterTypes.VALUE_COMPARE:    //not in use
    //   return addColumnWithValueComparerFilter(columnData, filtersData);
    case filterTypes.NONE:
    default:
      return addColumn(columnData);
  }
};

const createGetDataQueryForExport = function (gridData) {
  if (!gridData) {
    console.error('missing grid data for creating query');
    return null;
  }

  return this.getDataQueryForExport(
    {
      filterModel: gridData.get('filterState'),
      sortModel: gridData.get('sortState'),
    },
    gridData.get('filterConfig')
  );
};

function applyRowSelectionAsFilter(gridData, query, filterName, valueSelector = null) {
  if (!gridData || !query || !filterName) {
    console.error('missing grid data, query or filterName for application of selection as query.');
    console.error('gridData:', gridData, 'query:', query, 'filterName:', filterName);
    return query;
  }
  if (!valueSelector) {
    valueSelector = (rowData) => {
      return rowData.businessId;
    };
  }
  let values = gridData.get('selectedRowsData')?.toJS()?.map(valueSelector);
  if (!values?.length) return query;

  let filter = query.filters.find((f) => f.filterName === filterName);
  if (filter) filter.values = values;
  else query.filters.push({ filterName, values });

  return query;
}

const getDataQueryForExport = function (params, pageSize, pageNumber = 0) {
  let query = {
    filters: params != null ? params.filterModel : List(),
    pageSize: pageSize,
    pageNumber: pageNumber,
    sortDirection: '',
    sortByProperty: '',
  };

  if (query.filters && query.filters.size > 0) {
    query.filters = query.filters.map((filter) => {
      return {
        filterName: filter.filterName,
        values: filter.values,
        shouldCreateFilterOptions: false,
      };
    });
  }

  if (params != null) {
    if (params.sortModel && params.sortModel.filterName) {
      query.sortByProperty = params.sortModel.filterName;
      query.sortDirection = params.sortModel.direction;
    }
  }
  return query;
};

const getDateFormatHeaderIcon = function () {
  if (!PermissionManager.isDateFormatEnabled()) {
    return {};
  }
  return {
    headerIcon: 'info-icon-blue',
    // headerTooltip: createLabelHelper('period.date.format.tooltip').get('', undefined, {format:DateTimeHelper.getDateFormat()}),
    headerIconTooltip: createLabelHelper('period.date.format.tooltip').get('', undefined, {
      format: DateTimeHelper.getDateFormat(),
    }),
  };
};

export default {
  getDateFormatHeaderIcon,
  getDataQueryForExport,
  createGetDataQueryForExport,
  applyRowSelectionAsFilter,
  createGetDataQuery,
  createGetDataQueryByGridData,
  getFilterValues,
  createColumnMetaData,
  addColumn,
  addColumnWithMultiSelectFilter,
  addColumnWithMultiSelectAsyncFilter,
  addColumnWithMultiPropertiesFilter,
  addColumnWithMultiSectionsFilter,
  // addColumnWithValueComparerFilter,
  // isFilterStateActive,
};

import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import * as materialsService from '../../services/Materials/materialsService.js';
import PermissionManager from "infrastructure/js/utils/permissionManager";
const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ON_HAND_INVENTORY, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let headerData = getState().predefinedList.get('onHandInventoryHeader');

    _updateFilterValues(headerData, query, 'selectedLocations', 'location');
    _updateFilterValues(headerData, query, 'selectedAlertStatuses', 'assetAlert');
    _updateFilterValues(headerData, query, 'selectedAlertTypes', 'assetAlert', true);
    // set page size to 100000 since the server doesn't support paging. should be handled in the future.
    query.pageSize = 100000;
    let method = PermissionManager.isWeightSupported() ? materialsService.fetchInventoryByWeight : materialsService.fetchInventory
    return method(query);
  }
};

function _updateFilterValues(headerData, query, fieldName, filterName, shouldConcatValues = false) {
  let filter = query.filters.find(f => f.filterName === filterName);
  let selectedValues = headerData.get(fieldName);
  let selectedFilterValues = selectedValues ? selectedValues.map((value) => { return value.value }) : [];
  if (shouldConcatValues) {
    filter.values = filter.values.concat(selectedFilterValues);
  } else {
    filter.values = selectedFilterValues;
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    if (PermissionManager.isWeightSupported()) {
      obj.m_Quantity = UnitHelper.serverValueToUserValueWithLabel(unitTypes.WEIGHT, obj.quantity, 2);
    }
    else {
      obj.m_Quantity = UnitHelper.serverValueToUserValueWithLabel(
        obj.dimension === "AREA" ? unitTypes.AREA : unitTypes.LENGTH,
        obj.quantity, 2
      );
    }

    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import { Map } from 'immutable';

//Default dialog reducer - for the dialogs with the Server side error/warning validation algorithm
//prefix - is a base dialog name (for example 'CREATE_GROUP_DIALOG
export default function(state, action, prefix, defaultState) {

  switch(action.type) {
    case prefix + '_SET_BUSY':
      return state.set('loading', action.payload);

    case prefix + '_SHOW':
      return state.set('show', true);

    case prefix + '_HIDE':
      return Map(defaultState);

    case prefix + '_FETCH_DIALOG_DATA_READY':
      return state.set('dialogData', action.payload);

    // case prefix + '_SET_VALIDATION_WARNINGS_DATA':
    //   return state.set('validationMessage', action.payload)
    //               .set('hasError', !!action.payload.errors)
    //               .set('showIgnoreValidationCheckbox', !action.payload.errors)
    //               .set('isIgnoreValidationWarnings', false);

    case prefix + '_SET_VALIDATION_WARNINGS_DATA':
      return state.set('validationMessage', action.payload)
        .set('showIgnoreValidationCheckbox', action.payload.canIgnoreValidation)
        .set('hasHighSeverityWarning', action.payload.reset ? false : action.payload.hasHighSeverityWarning)
        .set('hasMediumSeverityWarning', action.payload.reset ? false : action.payload.hasMediumSeverityWarning)
        .set('hasError', !action.payload.canIgnoreValidation);

    case prefix + '_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX':
      return state.set('showIgnoreValidationCheckbox', false)
                  .set('hasHighSeverityWarning', false)
                  .set('hasMediumSeverityWarning', false)
                  .set('hasError', false)
                  .set('isIgnoreValidationWarnings', false);

    case prefix + '_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX':
      return state.set('isIgnoreValidationWarnings', action.payload);

    default:
      return state;
  }
}



import React from 'react';
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import {filesConsts as FILES_CONSTS} from '../../../../../../constants/constants';
import {ImportAcceptFileTypes, ImportTypes} from '../../../../../../enums/importTypes';
import AssetsFileUploader from 'infrastructure/js/components/controls/AssetsFileChooser/assetsFileChooser';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';

require('./importKitTypesDialog.scss');

class ImportKitTypesDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.importKitTypes.');
    this.assetTypesLabels = createLabelHelper('mat.wizards.import.assettype.');
    this.validationLabels = createLabelHelper('mat.wizards.import.files.kittypes.validation.grid.operationstatus.');

    this.state = {
      selectedFile: null,
      errors: null,
    }
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.dialogLabels.get('import'),
          bsStyle: 'primary',
          disabled: this.state.selectedFile && !this.state.errors ? this.isLoading() : true,
          loading: this.isLoading(),
          action: this.props.handleSubmit(this.onSubmit),
        },
      ],
    };
  }

  onSubmit = () => {
    this.props.actions.submit(this.state.selectedFile, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  }

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {

     if (!response) return {};

      let title = this.labels.get('confirmation.title', undefined, {count: response.totalItems});
      let className = 'import-kit-types-json-confirmation-dialog';
      let children = [];
      let buttons = [
        {id:'close', text: this.dialogLabels.get('close'), action: fnCloseDialog , bsStyle: 'primary'}
      ];

      if (response.numberOfSucceededItems) {
        children.push(<MessageDialog.StatusRow key="row1" type={'succeeded'} count={response.numberOfSucceededItems}/>);
      }
      if (response.numberOfFailedItems) {
        children.push(<MessageDialog.StatusRow key="row2" type={'failed'} count={response.numberOfFailedItems}/>);
        children.push(<MessageDialog.MessageArea key="row3" text={this.getMessages(response, 'ERROR')}/>);
      }
      if (response.numberOfItemsWithWarnings) {
        children.push(<MessageDialog.StatusRow key="row2" type={'warning'} count={response.numberOfItemsWithWarnings}/>);
        children.push(<MessageDialog.MessageArea key="row3" text={this.getMessages(response, 'WARNING')}/>);
      }

      if (response.totalItems === 0) { //handle some specific cases when the server side was not able to get the items count
        if (!response.validationMessages?.length ) { //no items, no validations
          children.push(<MessageDialog.StatusRow key="row1" type={'succeeded'} count={response.totalItems}/>);
        }
        else if (response.validationMessages?.length > 0) { //no items, but some validations
          title = this.labels.get('title');
          children.push(<MessageDialog.StatusRow key="row2" type={'failed'} count={''}/>);
          children.push(<MessageDialog.MessageArea key="row3" text={this.getMessages(response)}/>);
        }
      }

      return { title, children, type: 'error', className, buttons };
    };
  };

  getMessages = (data, severity=null) => {

    let validationMessages = data?.validationMessages?.filter((item) => {
      return severity ? (item.severity === severity) : (item.severity !== 'SUCCESS');
    });

    let messages = [];

    validationMessages?.forEach((item, index) => {
      item.operationStatus?.forEach((item, statusIndex) => {
        let message = this.validationLabels.get(item.labelKey, null, item.params);
        messages.push(<div key={`${index}-${statusIndex}`}>{message}</div>);
      });
    });

    return messages;
  }

  renderErrors = () => {
    if(this.state.errors){
      return this.renderErrorSelection(this.state.errors);
    }
    return null;
  }

  renderErrorSelection = (data) => {
    let icon = 'pl pl-warning-icon';
    let errorType = data.statusType;
    return (
      <div className="error-selection">
        <div className='error-item'>
          <span className={`error-icon ${icon} ${errorType}`}/>
          <label className='error-msg'>{data.message}</label>
        </div>
      </div>
    );
  };

  handleSelectedFileChanged = (file) => {
    if(file.size < FILES_CONSTS.FILE_SIZE_ALLOWED){ //Check if file size is smaller then 8 MB.
      this.setState({selectedFile: file, errors: null})
    } else {
      let maxSize = Math.trunc(FILES_CONSTS.FILE_SIZE_ALLOWED / FILES_CONSTS.ONE_MB);
      let data = {
        errorCode: 'UF_10003',
        message : this.labels.get('errors.filesize', undefined, {maxSize} ),
        statusType:'error'
      };
      this.setState({errors: data})
    }
  };

  downloadDomSchema = () => {
    this.props.actions.exportDomSchema()
  }

  isLoading = () => {
    return this.props.sData?.get('loading');
  }

  render() {
    return (
      <Dialog
        id="import-kit-types-dialog"
        className="import-kit-types-dialog"
        show={true}
        onHide={this.onHide}
        titleText= {this.labels.get('title')}
        footerButtons={this.getDialogButtons()}
        handleHashChange={false}
      >

        <div className={cn('upload-file') }>

          <label className="body-title">{this.labels.get('bodytitle')}</label>
          <span>
            <label className="body-comment">{this.labels.get('bodycomment.start')}</label>
            <span className="settings-link" onClick={this.downloadDomSchema}>{this.labels.get('bodycomment.schema')}</span>
            <label className="body-comment">{this.labels.get('bodycomment.end')}</label>
          </span>

          <AssetsFileUploader
            name="fileUpload"
            change={this.props.change}
            accept={[ImportAcceptFileTypes.JSON]}
            buttonText={this.state.selectedFile ? this.dialogLabels.get('changeFile') : this.dialogLabels.get('browse')}
            placeHolder={this.labels.get('selectfileplaceholder')}
            onSelectedFileChanged={this.handleSelectedFileChanged}
            options={[ {value: ImportTypes.IMPORT_KIT_TYPES, label: this.assetTypesLabels.get('kittypes')},]}
            selectedFile={this.state.selectedFile}
            loading={this.isLoading()}
          />
        </div>

        {this.renderErrors()}

      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'importKitTypesDialog'
  }
)(ImportKitTypesDialog);


ImportKitTypesDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};


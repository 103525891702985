import { Map } from 'immutable';
import { actionTypes as CreateToolTypeDialogActionsTypes } from '../../../actions/Administration/MatSettingsPage/Dialogs/createToolTypeDialogActions.js';

let defaultState = {
  show: false,
  loading: false,
  itemToEdit: null,
  dialogData: []
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreateToolTypeDialogActionsTypes.CREATE_TOOLTYPE_DIALOG_SHOW:
      state = state.set('itemToEdit', action.payload);
      return state.set('show',true);

    case CreateToolTypeDialogActionsTypes.CREATE_TOOLTYPE_DIALOG_HIDE:
      return Map(defaultState);

    case CreateToolTypeDialogActionsTypes.CREATE_TOOLTYPE_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case CreateToolTypeDialogActionsTypes.CREATE_TOOLTYPE_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);

    case CreateToolTypeDialogActionsTypes.CREATE_TOOLTYPE_DIALOG_FETCH_DATA_READY:
      return state.set("dialogData", action.payload);

    default:
      return state;
  }

}








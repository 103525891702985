import {List} from 'immutable';
import {actionTypes} from './Utils/gridActionsHelper';

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataInProgress: function (gridName) {
    return {type: actionTypes.GRID_FETCH_DATA_IN_PROGRESS, payload: {gridName}};
  },
  fetchDataError: function (gridName) {
    return {type: actionTypes.GRID_FETCH_DATA_ERROR, payload: {gridName}};
  },
  fetchDataFinished: function (payload) {
    return {type: actionTypes.GRID_FETCH_DATA_FINISHED, payload: payload};
  },
  selectedRowsChanged: function (payload) {
    return {type: actionTypes.GRID_SELECTED_ROWS_CHANGED, payload: payload};
  },
  init: function (payload) {
    return {type: actionTypes.GRID_INIT, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.onSelectedRowsChanged = function (gridName, selectedRows) {
  return function (dispatch, getState) {
    dispatch(actions.selectedRowsChanged({gridName, selectedRows}));
  }
};

jsxActions.init = function (gridName, columnsConfig, filterConfig, rows) {
  return function (dispatch, getState) {
    dispatch(actions.init({gridName, columnsConfig, filterConfig, rows}));
    api.reload(dispatch, getState)(gridName);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.reload = function (dispatch, getState) {
  return (gridName) => {

    if (!getState().grid.get(gridName)) {
      return;
    }
    let {rows} = {...getState().grid.get(gridName).toObject()};
    let payload = {
      gridName,
      filteredRowsCount: rows ? rows.size : 0,
      filterModel: List(),
      sortModel: {},
      rows: rows,
      filtersData: null,
      queryResultInfo: null
    };
    dispatch(actions.fetchDataFinished(payload));
  }
};


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as importWizardActions } from '../../../actions/Wizards/ImportNests/importNestsWizardActions.js';
import ImportNestsWizard from '../../../components/Wizards/ImportNests/importNestsWizard';
import {gridsNames} from '../../../enums/gridsNames.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(importWizardActions, dispatch)
    }
  }
}


let ImportNestsWizardContainer = connect(
  (state) => {
    return {
      importTypes: state.importNests.get('importTypes'),
      acceptFileTypes:state.importNests.get('acceptFileTypes'),
      loading:state.importNests.get('loading'),
      sErrors: state.importNests.get('errors'),
      sNestsErrors: state.importNests.get('nestsErrors'),
      sValidatedFileData: state.importNests.get('validatedFileData'),
      sFileToken:state.importNests.get('fileToken'),
      sSelectedAseetType:state.importNests.get('selectedAssetType'),
      sSelectedFile: state.importNests.get('selectedFile'),
      currentDate:state.importNests.get('currentDate'),
      parentComponent:state.importNests.get('parentComponent'),

      rollId:state.importNests.get('rollId'),
      materialBusinessId:state.importNests.get('materialBusinessId'),
      nestsItems:state.importNests.get('nestsItems'),
      selectedNests: state.grid.get(gridsNames.IMPORT_NESTS_SELECT_NESTS) ? state.grid.get(gridsNames.IMPORT_NESTS_SELECT_NESTS).get('selectedRowsData') : List(),
    };
  },
  mapDispatchToProps
)(ImportNestsWizard);

export default ImportNestsWizardContainer;



import { fetchGridData } from "../../../services/MaterialScrap/materialScrapService.js";
import { gridsNames } from "../../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import PermissionManager from "infrastructure/js/utils/permissionManager";
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_MATERIAL_SCRAP, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return fetchGridData(query);
  };
}

function _convertToRowData(data) {
  const flattenedData = [];

  data?.forEach(parentNode => { // flatten data to conform to ag-grid's tree data structure.
    parentNode.hierarchy = [parentNode.material.businessId];
    parentNode.isParent = true;
    parentNode.scrapQuantity = UnitHelper.serverValueToUserValue(unitTypes.LENGTH, parentNode.totalScrapQuantity, 2);

    flattenedData.push(parentNode);
    parentNode?.relativeEntities?.forEach(childNode => {
      childNode.hierarchy = [parentNode.material.businessId, childNode.assetBusinessId];
      childNode.scrapQuantity = UnitHelper.serverValueToUserValue(PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH, childNode.scrapQuantity, 2);
      flattenedData.push(childNode);
    });
  });
  return flattenedData;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

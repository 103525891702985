import React from 'react';
import PropTypes from 'prop-types';
import { FormWizardDialog } from 'infrastructure/js/components/Dialog/WizardDialog/wizardDialog.js';
import {createLabelHelper}  from 'infrastructure/js/utils/labelHelper';
import MessageDialog        from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import SelectResultingAssetStep from './Steps/SelectResultingAssetStep/selectResultingAssetStep';
import SelectComponentAssetsStep from './Steps/SelectComponentAssetsStep/selectComponentAssetsStep';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

require('./afpOperationWizard.scss');

export default class AfpOperationWizard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.wizards.afpOperation.');
    this.wizardLabels = createLabelHelper('mat.dialog.wizard.');
    this.dialogLabels = createLabelHelper('mat.dialog.');

    this.formComponent = FormWizardDialog('afpOperationWizard',
      {onChange:  (values, dispatch, props, previousValues ) => {
          dialogHelper.onFormChangeHandler(values, props, previousValues)}});
  }

  getSteps = () => {
    let {sData, actions} = this.props;

    return [

      {
        name: this.labels.get('step1.name'),
        component: (props) => <SelectResultingAssetStep  {...props} actions={actions} labels={this.labels} sData={sData}/>,
      },
      {
        name: this.labels.get('step2.name'),
        component: (props) => <SelectComponentAssetsStep {...props} actions={actions} labels={this.labels} sData={sData}/>,
      },
    ];
  };

  getCloseConfirmationDialog = () => {
    return (
      {
        titleText: this.wizardLabels.get('closeConfirmation.title'),
        contents:
          <div>
            <MessageDialog.DataRow key={'row1'} label={this.wizardLabels.get('closeConfirmation.line1')} value={''}/>
            <MessageDialog.DataRow key={'row2'} label={this.wizardLabels.get('closeConfirmation.line2')} value={''}/>
          </div> ,
      }
    );
  }

  onHide = () => {
    this.props.actions.hide();
  };

  onSubmit = (data, dispatch, props, nextTab, stepIndex) => {
    if (stepIndex === 0) {
      nextTab?.();
    }
    if (stepIndex === 1) {
      let newData = {
        partBusinessId: data?.resultingAsset?.assetsValue?.label,
        workOrderId: data?.workOrder?.assetsValue?.value,
        usingByAssetId: data?.usingAsset?.assetsValue?.value,
        layerBusinessId: data.layerId,
        // layerComponents: data?.spools.map((item, index) => {return {pinNumber: index, spoolBusinessId: item }}).filter(item => !!item)
        layerComponents: data?.scannedAssets.filter((item) => !!item.spoolBusinessId ),
        ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
          data.footerValidationCheckbox : false,
    };
      this.props.actions.submit(newData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
    }
  };

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {

      let title = this.labels.get('confirmation.header');
      let buttons = [
        {id:'close', text: this.dialogLabels.get('close'), action: fnCloseDialog, bsStyle: 'primary'},
      ];
      let children = [];

      if (data) {
        let rows = [
          {label: this.labels.get('confirmation.message.partId'), value: data.businessId},
        ];
        children = rows.map((item, index) => {
          return <MessageDialog.DataRow key={`confirmMessageDataRow${index}`} label={item.label} value={item.value}/>
        });
      }
      return {title, children, buttons};
    };
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'), onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  renderOverlay() {
    if ( this.props.sData.get('loading')) {
      return <Overlay.Loading />;
    }
  }

  render() {
    let {sData} = this.props;

    return (
      <>
        <this.formComponent
          id="afpOperationWizard"
          tabType='wizard-horizontal'
          className="afp-operation-wizard"
          labels={this.props.labels}
          show={sData.get('show')}
          titleText={this.labels.get('title')}
          loading={sData.get('loading')}
          steps={this.getSteps()}
          onClose={this.onHide}
          sData={sData}
          actions={this.props.actions}
          closeConfirmationDialog={this.getCloseConfirmationDialog()}
          footerValidationCheckbox={this.getFooterValidationCheckBox()}
          defaultButtons={
            {
              onSubmit: this.onSubmit,
            }
          }
        />
        {this.renderOverlay()}
      </>
    );
  }
}

AfpOperationWizard.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  reloadParentComponent : PropTypes.func,
};

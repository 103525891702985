import LoginService from 'infrastructure/js/services/LoginService.js';
import Network from 'infrastructure/js/modules/network.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  RESETPASSWORD_SUBMIT_IN_PROCESS: "RESETPASSWORD_SUBMIT_IN_PROCESS",
  RESETPASSWORD_SUBMIT_FINISHED: "RESETPASSWORD_SUBMIT_FINISHED",
  RESETPASSWORD_SUBMIT_FAILED: "RESETPASSWORD_SUBMIT_FAILED",
  RESETPASSWORD_CLEAR_DATA: "RESETPASSWORD_CLEAR_DATA"
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  resetPasswordInProcess: function() {
    return {type: actionTypes.RESETPASSWORD_SUBMIT_IN_PROCESS };
  },
  resetPasswordFinished: function(data) {
    return {type: actionTypes.RESETPASSWORD_SUBMIT_FINISHED, payload: {data} };
  },
  resetPasswordFailed: function(data) {
    return {type: actionTypes.RESETPASSWORD_SUBMIT_FAILED, payload: {data}  };
  },
  resetPasswordClearData: function() {
    return {type: actionTypes.RESETPASSWORD_CLEAR_DATA, };
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.forgotPassword = function(values) {
  return function(dispatch, getState) {
    dispatch(actions.resetPasswordInProcess());
    return LoginService.forgotPassword(values).then((response) => {
      if(!Network.isResponseValid(response)){
        dispatch(actions.resetPasswordFailed(response.data));
        return false;
      }

      dispatch(actions.resetPasswordFinished(response.data));
      return true;
    });
  }
};


jsxActions.resetPassword = function(data) {
  return function(dispatch, getState) {
    api.resetPassword(data)(dispatch, getState);
  }
};
jsxActions.clearData = function(data) {
  return function(dispatch, getState) {
    dispatch(actions.resetPasswordClearData());
  }
};




/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};
api.resetPassword = function(data) {
  return function(dispatch, getState) {
    dispatch(actions.resetPasswordInProcess());
    //server needs org id at the api
    data.orgId = '';
    return LoginService.resetPassword(data).then((response) => {
      if(!Network.isResponseValid(response)){
        dispatch(actions.resetPasswordFailed(response.applicationResponseStatus));
        return false;
      }
      dispatch(actions.resetPasswordFinished(response.data));
      return true;
    });
  }
};




/*api.[placeholder] = function(dispatch, getState) {
  return function() {
  }
};*/





import { Map , List } from 'immutable';
import { actionTypes as CommunicationParametersViewActionsType } from '../../../actions/Administration/RfidSettingsPage/communicationParametersViewActions.js';

let defaultState = {
  loading: false,
  selectedDeviceApp : null,
  deviceAppsOptions : []
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case CommunicationParametersViewActionsType.ADMINISTRATION_RF_SETTINGS_COMMUNICATION_PARAMETERS_FETCH_DEVICE_APPS_IN_PROCESS:
      return state.set('loading', true);
    case CommunicationParametersViewActionsType.ADMINISTRATION_RF_SETTINGS_COMMUNICATION_PARAMETERS_FETCH_DEVICE_APPS_READY:
      state = state.set('deviceAppsOptions', action.payload);
      return state.set('loading',false);
    case CommunicationParametersViewActionsType.ADMINISTRATION_RF_SETTINGS_COMMUNICATION_PARAMETERS_DEVICE_APP_CHANGED:
      return state.set('selectedDeviceApp', action.payload);
    case CommunicationParametersViewActionsType.ADMINISTRATION_RF_SETTINGS_COMMUNICATION_PARAMETERS_UPDATE_IN_PROGRESS:
      return state.set('loading', true);
    case CommunicationParametersViewActionsType.ADMINISTRATION_RF_SETTINGS_COMMUNICATION_PARAMETERS_UPDATE_FINISHED:
      return state.set('loading', false);
    case CommunicationParametersViewActionsType.ADMINISTRATION_RF_SETTINGS_COMMUNICATION_PARAMETERS_FETCH_DATA_IN_PROGRESS:
      return state.set('loading', true);
    case CommunicationParametersViewActionsType.ADMINISTRATION_RF_SETTINGS_COMMUNICATION_PARAMETERS_FETCH_DATA_FINISHED:
      state = state.set('loading', false);
      return state.set('communicationData', action.payload.data);
    default:
      return state;
  }

}








import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';

require('./tooltip.scss');

export default class PL_Tooltip extends React.PureComponent{
  getTooltipComponent = () => {

    return (
      <Tooltip id="tooltip">{this.props.value}</Tooltip>
    )
  }

  render (){
    //incase there is no value, dont show empty tooltip
    if(!this.props.value){
      return (this.props.children)
    }
    let {placement, ...otherProps} = this.props;
    placement = placement || "top";
    return (
      <OverlayTrigger placement={placement} overlay={this.getTooltipComponent()} {...otherProps} >
        {this.props.children}
      </OverlayTrigger>
    )
  }
}
PL_Tooltip.propTypes = {
  placement: PropTypes.string,
  value: PropTypes.any,
};

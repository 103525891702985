import React from 'react';

import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import LinkActions from '../../../Common/Layout/LinkActions/linkActions';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';
import UserRolesCell from '../UserManagementGrid/CustomCells/UserRolesCell/userRolesCell';
import {gridsNames} from '../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';

require('./userManagementGrid.scss');

class UserManagementGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'status', filterName: 'userStatus' , getOptions: true},
    {fieldName: 'username', filterName: 'userName' , getOptions: false},
    {fieldName: 'lastName', filterName: 'userLastName' , getOptions: false},
    {fieldName: 'firstName', filterName: 'userFirstName' , getOptions: false},
    {fieldName: 'roles', filterName: 'userRole' , getOptions: true}
  ]

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.usermanagement.grid.');
    this.userStatuslabels = createLabelHelper('mat.usermanagement.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'id',
        title: this.labels.get('columns.id.title'),
        width: 90,
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LinkActions,
          valueGetter: (params) => {
            return {
              data: params.data,
              label: params.data.id.toString(),
              action: this.props.actions.showEditUser,
              disabled: !params.data.m_hasEditPermissions
            };
          }
        }
      },
      {
        fieldName: 'status',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'userStatus',
        columnOptions: {
          sort: 'desc',
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.m_status;
          }
        }
      },
      {
        fieldName: 'username',
        title: this.labels.get('columns.username.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'userName',
        fetchConfig: {
          entityType: EntityPropertyTypes.USER_NAME,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.username;
          }
        }
      },
      {
        fieldName: 'lastName',
        title: this.labels.get('columns.lastname.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'userLastName',
        fetchConfig: {
          entityType: EntityPropertyTypes.USER_LAST_NAME,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.lastName;
          }
        }
      },
      {
        fieldName: 'firstName',
        title: this.labels.get('columns.firstname.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'userFirstName',
        fetchConfig: {
          entityType: EntityPropertyTypes.USER_FIRST_NAME,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.firstName;
          }
        }
      },
      {
        fieldName: 'roles',
        title: this.labels.get('columns.role.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'userRole',
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.m_roles,
              componentType: UserRolesCell
            };
          }
        }
      },
      {
        fieldName: 'email',
        title: this.labels.get('columns.email.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.email;
          }
        }
      }
    ]
  }


  getRowHeight(params) {
    let rowHeight = 64;
    if (params.data.roles.length > 1) {
      rowHeight = params.data.roles.length * 30 + 40;
    }
    return rowHeight;
  }


  render() {
    return (
      <div className="user-management-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_USER_MANAGEMENT}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: this.getRowHeight
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'UserManagementGrid'
  }
)(UserManagementGrid);

import { Map} from 'immutable';
import { actionTypes as CreateOrganizationDialogActionTypes }  from '../../../../actions/Administration/OrganizationsPage/Dialogs/createOrganizationDialogActions';

let defaultState = {
  show: false,
  loading: false,
  organization: null,
  isDuplicate: false
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreateOrganizationDialogActionTypes.DUPLICATE_ORGANIZATION_DIALOG_SHOW:
      state = state.set('isDuplicate', true);
      state = state.set('organization', action.payload);
      return state.set('show',true);
    case CreateOrganizationDialogActionTypes.CREATE_ORGANIZATION_DIALOG_SHOW:
      state = state.set('isDuplicate', false);
      state = state.set('organization', action.payload);
      return state.set('show',true);

    case CreateOrganizationDialogActionTypes.CREATE_ORGANIZATION_DIALOG_HIDE:
      return Map(defaultState);

    case CreateOrganizationDialogActionTypes.CREATE_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case CreateOrganizationDialogActionTypes.CREATE_ORGANIZATION_STATUS_SUBMIT_FINISHED:
      return state.set("loading", false);

    default:
      return state;
  }
}








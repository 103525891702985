import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as pageNotFoundPageActions } from '../../actions/PageNotFoundPage/pageNotFoundPageActions';

import PageNotFoundPage from '../../components/PageNotFoundPage/pageNotFoundPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageNotFoundPageActions: bindActionCreators(pageNotFoundPageActions, dispatch),
    }
  }
}


let PageNotFoundPageContainer = connect(
  (state) => {
  	return {
  	  sData: state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps
)(PageNotFoundPage);

export default PageNotFoundPageContainer;




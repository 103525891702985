import { actionTypes as batchJobsActionTypes  } from '../../actions/BatchJobs/batchJobsActions.js';
import {Map} from 'immutable';

let defaultState = Map({
  allRunningJobs : {},
  backgroundJobs: {}
});

export default function(state = defaultState, action) {
  let newData = {};
  switch (action.type) {
    case batchJobsActionTypes.BATCH_JOBS_ADD_JOB:
      newData = {...state.get('allRunningJobs'), [action.payload.jobInstanceId]: action.payload};
      return state.set('allRunningJobs', newData);

    case batchJobsActionTypes.BATCH_JOBS_REMOVE_JOB:
      newData = {...state.get('allRunningJobs')};
      delete newData[action.payload];
      let newData2 = {...state.get('backgroundJobs')};
      delete newData2[action.payload];

      return state.set('allRunningJobs', newData)
                  .set('backgroundJobs', newData2);

    case batchJobsActionTypes.BATCH_JOBS_UPDATE:
      newData = {...state.get('allRunningJobs'), [action.payload.jobInstanceId]: action.payload};
      return state.set('allRunningJobs', newData);

    case batchJobsActionTypes.BATCH_JOBS_BACKGROUND:
      newData = {...state.get('backgroundJobs'), [action.payload]: action.payload};
      return state.set('backgroundJobs', newData);

    default:
      return state;
  }
}

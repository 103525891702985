import React from 'react';

import ImportFilesCommonGrid from '../ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';

export default class ImportTagsGrid extends React.PureComponent {

  getColumnsWidthConfig = () => {
    return  {
      type : 80
     };
  }

  render() {
    return  <ImportFilesCommonGrid  rows={this.props.rows} assetType={ImportTypes.IMPORT_TAGS} gridName={gridsNames.IMPORT_TAGS} columnsWidthConfig={this.getColumnsWidthConfig()}/>;
  }
}

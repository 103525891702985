import Network from 'infrastructure/js/modules/network';
import { api as reportKitDialogApi }  from './reportKitDialogActions';
import { api as createKitWizardApi }  from './createKitWizardActions';
import { api as locationPageApi }  from '../locationPageActions';
import * as kitsService  from '../../../services/Kits/kitsService';
import * as stationsService  from '../../../services/Stations/stationsService';
import DateTimeHelper  from 'infrastructure/js/utils/dateTimeHelper';
import { change as reduxFormChange}   from 'redux-form';
import { api as gridApi} from './kittingGridActions';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LOCATIONPAGE_KITTINGVIEW_FETCHCHARTS_FINISHED:    "LOCATIONPAGE_KITTINGVIEW_FETCHCHARTS_FINISHED",
  CUTKITSTATION_SELECTED_CUT_TO_KIT_CHANGED:        "CUTKITSTATION_SELECTED_CUT_TO_KIT_CHANGED",
  CUTKITSTATION_SHOW_KITS_IN_LOCATION_CHANGED:        "CUTKITSTATION_SHOW_KITS_IN_LOCATION_CHANGED",
  CUTKITSTATION_KITTING_REPORTED_CUTS_FETCH_FINISHED: "CUTKITSTATION_KITTING_REPORTED_CUTS_FETCH_FINISHED",

};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchChartsFinished: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_KITTINGVIEW_FETCHCHARTS_FINISHED, payload: payload };
  },
  selectedCutToKitChanged: function(payload) {
    return {type: actionTypes.CUTKITSTATION_SELECTED_CUT_TO_KIT_CHANGED, payload: payload };
  },
  showKitsInLocationChanged: function(payload) {
    return {type: actionTypes.CUTKITSTATION_SHOW_KITS_IN_LOCATION_CHANGED, payload: payload };
  },
  reportedCutsFetchFinished: function(payload) {
    return {type: actionTypes.CUTKITSTATION_KITTING_REPORTED_CUTS_FETCH_FINISHED, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

// jsxActions.onInit = function() {
//   return function(dispatch, getState) {
//     //api.fetchCurrentLocationInitialData(dispatch, getState)();
//     locationPageApi.fetchCurrentLocationInitialData(dispatch, getState)();
//   }
// }

jsxActions.onReportKittingClick = function(data) {
  return function(dispatch, getState) {
    reportKitDialogApi.show(dispatch, getState)(data);
  }
};

jsxActions.onCreateKitsClick = function() {
  return function(dispatch, getState) {
    createKitWizardApi.openWizard(dispatch, getState)();
  }
};

jsxActions.onSelectedCutToKitChange = function(selectedDropDownOption) {
  return function(dispatch, getState) {
    // dispatch(actions.selectedCutToKitChanged(selectedDropDownOption.label));
    dispatch(actions.selectedCutToKitChanged(selectedDropDownOption.data));
    gridApi.reload(dispatch, getState)();
  }
};

jsxActions.onShowKitsInLocationChange = function(data) {
  return function(dispatch, getState) {
    dispatch(actions.showKitsInLocationChanged(data));
    gridApi.reload(dispatch, getState)();
  }
};



jsxActions.fetchReportedCuts = function() {
  return function(dispatch, getState) {
    api.fetchReportedCuts(dispatch, getState)();
  };
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(shouldFetchGrid) {
    let fetchChartsPromise = api.fetchCharts(dispatch, getState)();

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let fetchReportedCutsPromise = api.fetchReportedCuts(dispatch, getState)(locationId).then(() => {

      if (shouldFetchGrid) {
        return gridApi.reload(dispatch, getState)();
      }
      else {
        api.setCurrentCutToKitDropdownToShowAll(dispatch, getState)();
      }
    });

    return Promise.all([fetchChartsPromise, fetchReportedCutsPromise]);
  }
};

api.fetchReportedCuts = function(dispatch, getState) {
  return function() {

    return kitsService.getReportedCuts()
      .then(function(result) {
        if (!Network.isResponseValid(result)) {
          console.error("get Reported Cuts Data error");
          return [];
        }
        let serverTimeSeconds = getState().system.get("serverDatetime");
        var dropDownItemsData = result.dataList.map((obj) => {
          obj.m_CreatedDate = DateTimeHelper.FormatDateObjectToRelativeTime(obj.createdDate, serverTimeSeconds);
          return {
            label: obj.rollBusinessId,
            value: obj.id,
            data: obj,
          };
        });

        dispatch(actions.reportedCutsFetchFinished(dropDownItemsData));
      });
  }
};

api.fetchCharts = function(dispatch, getState) {
  return function() {

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    return stationsService.FetchSummaryCounts(locationId)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error("Fetch Chart Failed");
          return {success: false};
        }

        dispatch(actions.fetchChartsFinished({...response.data.data, success: true}));
      });
  }
};


api.setCurrentCutToKitDropdownByCutId = function(dispatch, getState) {
  return function(cutId) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    api.fetchReportedCuts(dispatch, getState)(locationId).then(() => {
      // Locate Cut dropdown item in the Kitting page dropdown by cutId.
      let state = getState();
      let reportedCutsDropDownItems = state.cutKitStation.get('kittingViewData').get('reportedCuts');
      let foundDropDownItem = reportedCutsDropDownItems.find((item) => {
        return (item.value === cutId);
      });

      // Select cut in the Kitting page dropdown.
      dispatch(reduxFormChange('cutKitStation_Kitting_HeaderForm', 'reportsCutDropdown', foundDropDownItem));

      // And dispatch an action that signals that the dropdown value was changed.
      // dispatch(actions.selectedCutToKitChanged(foundDropDownItem.label));
      dispatch(actions.selectedCutToKitChanged(foundDropDownItem.data));
      gridApi.reload(dispatch, getState)();
    });
  }
};

api.setCurrentCutToKitDropdownToShowAll = function(dispatch, getState) {
  return function() {

    // Locate Cut dropdown item in the Kitting page dropdown by cutId.
    let state = getState();
    let reportedCutsDropDownItems = state.cutKitStation.get('kittingViewData').get('reportedCuts');
    let showAllDropDownItem = reportedCutsDropDownItems[0];

    // Select cut in the Kitting page dropdown.
    dispatch(reduxFormChange('cutKitStation_Kitting_HeaderForm', 'reportsCutDropdown', showAllDropDownItem));

    // And dispatch an action that signals that the dropdown value was changed.
    if (showAllDropDownItem) {
      dispatch(actions.selectedCutToKitChanged(showAllDropDownItem.value));
    }

  }
};


api.getCurrentCutId = function(dispatch, getState) {
  return function() {

    let currentCutToKit = getState().cutKitStation.get('selectedCutToKit');
    if (!currentCutToKit) {
      return null;
    }

    return currentCutToKit.id;
  }
};

api.getCurrentCut = function(dispatch, getState) {
  return function() {

    let currentCutToKit = getState().cutKitStation.get('selectedCutToKit');
    if (!currentCutToKit) {
      return null;
    }

    return currentCutToKit;
  }
};







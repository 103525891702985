import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TasksGrid from './TasksGrid/tasksGrid';
import { gridsNames } from '../../../enums/gridsNames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, { headerItemsLabels } from '../../Common/Header/header';
import TasksPanel from './TasksPanel/tasksPanel';
import Button from 'infrastructure/js/components/controls/Button/button';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { PL_DatePicker } from 'infrastructure/js/components/controls/DatePicker/datepicker';

import './tasksView.scss';

function TasksView({
  sHasRows,
  actions,
  sTasksGridData,
  sSelectedRowsData,
}) {
  const [labels] = useState(() => createLabelHelper('mat.locationpage.view.tasks.'));
  const [viewDate, setViewDate] = useState(moment());

  const getHeaderItems = () => {
    const hasRows = sHasRows;
    return {
      title: '',
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions?.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          disabled: isLoading(),
        },
        {
          id: 'refreshAll',
          label: '',
          icon: 'pl pl-refresh',
          action: () => actions?.locationPageActions?.reload?.(),
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: isLoading(),
        },
      ],
      kebab: {
        menuItems: [
          {
            id: 'exportTasksList',
            label: headerItemsLabels.EXPORT_TASKS_LIST,
            action: actions.exportActions.exportTasksProgressGrid,
            disabled: !hasRows,
            actionData: { gridName: gridsNames.LOCATION_TASKS },
          },
        ],
      },
      leftContent: getDateNavigation(),
    };
  };

  const getDateNavigation = () => {
    const currentDate = DateTimeHelper.DateFormat(viewDate);
    return (
      <div className="tasks-date-nav-container">
        <span>{currentDate}</span>
        <Button
          id="tasksPrevDate"
          name="previous_day"
          className={'border-btn only-icon'}
          icon={'pl pl-arrow-left'}
          onClick={onDateChange}
          // disabled={}
        />
        <Button
          id="tasksToday"
          name="today"
          className={'border-btn no-icon'}
          onClick={onDateChange}
          // disabled={}
        >
          {labels.get('dateNavigation.today')}
        </Button>
        <PL_DatePicker
          id="tasksPickDate"
          name="tasksPickDate"
          // validate={Validation.date}
          onChangeCallback={(date) => onDatePick(date)}
          selected={viewDate}
        />
        <Button
          id="tasksNextDate"
          name="next_day"
          className={'border-btn only-icon'}
          icon={'pl pl-arrow-right'}
          onClick={onDateChange}
          // disabled={}
        />
      </div>
    );
  };

  const onDateChange = (e) => {
    switch (e.target.name) {
      case 'previous_day':
        {
          const prevDateObj = viewDate.subtract(1, 'd');
          setViewDate(prevDateObj);
          actions.changeViewDate(prevDateObj.format('DD-MM-YYYY').toString());
        }
        break;
      case 'today': {
        const dateObj = moment();
        setViewDate(dateObj);
        actions.changeViewDate(dateObj.format('DD-MM-YYYY').toString());
        break;
      }
      case 'next_day':
        {
          const nextDateObj = viewDate.add(1, 'd');
          setViewDate(nextDateObj);
          actions.changeViewDate(nextDateObj.format('DD-MM-YYYY').toString());
        }
        break;
      default:
        break;
    }
  };

  const onDatePick = (pickedDateObj) => {
    if (pickedDateObj) {
      setViewDate(pickedDateObj);
      actions.changeViewDate(pickedDateObj.format('DD-MM-YYYY').toString());
    }
  };

  const isLoading = () => {
    return sTasksGridData ? sTasksGridData.get('loading') : false;
  };

  const headerItems = getHeaderItems();

  const { tasksGridActions, fetchEntitiesActions } = actions;

  return (
    <div className="location-tasks-view">
      <Header className="tasks-view-header" {...headerItems} />
      <TasksPanel selectedRows={sSelectedRowsData} actions={{ ...actions }} />
      <TasksGrid
        actions={{
          ...tasksGridActions,
          fetchEntitiesActions,
        }}
      />
    </div>
  );
}

TasksView.propTypes = {
  sHasRows: PropTypes.bool,
  actions: PropTypes.object,
  sTasksGridData: PropTypes.object,
  sSelectedRowsData: PropTypes.object,
  sLocationDetails: PropTypes.object,
};

export default TasksView;

import { Map } from 'immutable';
import { actionTypes as overviewTabActionTypes } from '../../actions/WorkOrderPage/overviewTabActions';
import { actionTypes as workOrderPageEditorActions } from '../../actions/WorkOrderPage/workOrderPageEditorActions';

let defaultState = {
      workOrderDetails: {},

};


export default function(state = Map(defaultState), action) {

  switch(action.type) {

    case overviewTabActionTypes.WORKORDERPAGE_OVERVIEW_TAB_FETCHDATA_STARTED:
      return state.set('loading', true);

    case overviewTabActionTypes.WORKORDERPAGE_OVERVIEW_TAB_FETCHDATA_FINISHED:
      state = state.set('workOrderDetails', action.payload.workOrderDetails)
                  .set('relatedAssets', action.payload.relatedAssets)
                   .set('loading', false);

      return state;

    case workOrderPageEditorActions.WORKORDERPAGE_EDITOR_WO_UPDATE_IN_PROGRESS:
      return state.set('loading', true);

    case workOrderPageEditorActions.WORKORDERPAGE_EDITOR_WO_UPDATE_FINISHED:
      return state.set('loading', false);


    default:
      return state;

  }

}

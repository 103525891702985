import { actionTypes as changePasswordDialogActionTypes} from '../../actions/Dialogs/changePasswordDialogActions';
import {fromJS} from 'immutable';

let defaultState = {
  user : {},
  show : false,
  loading : false,
  callbackAction: null,
  dialogLabels: null,
  serviceAction : null
};

export default function(state = fromJS(defaultState), action) {
  switch (action.type) {
    case changePasswordDialogActionTypes.DIALOG_CHANGEPASSWORD_HIDE:
      return fromJS(defaultState);

    case changePasswordDialogActionTypes.DIALOG_CHANGEPASSWORD_SHOW:
      return state.set('show', true)
            .set('callbackAction', action.payload.callbackAction)
            .set('serviceAction', action.payload.serviceAction)
            .set('dialogLabels', action.payload.dialogLabels)
            .set('user', action.payload.user);


    case changePasswordDialogActionTypes.DIALOG_CHANGEPASSWORD_IN_PROCESS:
      return state.set('loading', true);

    case changePasswordDialogActionTypes.DIALOG_CHANGEPASSWORD_FINISHED:
      return state.set('loading', false);

    default:
      return state;
  }
}






import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as alertDevicesViewActions} from '../../../actions/Administration/RfidSettingsPage/alertDevicesViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as alertDevicesViewGridActions} from '../../../actions/Administration/RfidSettingsPage/alertDevicesViewGridActions';
import { jsxActions as createAlertDeviceDialogActions} from '../../../actions/Administration/RfidSettingsPage/Dialogs/createAlertDeviceDialogActions';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import AlertDevicesView from '../../../components/Administration/RfidSettingsPage/AlertDevicesView/alertDevicesView.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(alertDevicesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      alertDevicesViewGridActions: bindActionCreators(alertDevicesViewGridActions, dispatch),
      createAlertDeviceDialogActions : bindActionCreators(createAlertDeviceDialogActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let AlertDevicesViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_ALERT_DEVICES);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sData: state.administration.getIn(['rfidSettingsPage', 'alertDevicesViewData']),
      sCreateAlertDeviceDialogData: state.administration.getIn(['rfidSettingsPage', 'createAlertDeviceDialog']),
    };
  },

  mapDispatchToProps
)(AlertDevicesView);

export default AlertDevicesViewContainer;



import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Layout/Link/link.js';

require('./entityIdCell.scss');

export default function EntityIdCell(props) {

    let {value, className} = props;

    if (value) {
      return <Link label={value.label} id={value.id} type={value.type} className={className} />;
    }
    return null;
}

EntityIdCell.propTypes = {
  value: PropTypes.object,
};


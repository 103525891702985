import Network from '../modules/network.js';
import * as appHelper from "../utils/appHelper";

export const getServerDatetime = function(){
  return Network.get('orgs/get-zoned-datetime');
};

export const getServerDatetimeSync = function(){
  return Network.get('orgs/get-zoned-datetime');
};

export const getServerVersion = function(){
  return Network.get('version');
};

export const getClientVersion = function(){
  return  fetch('../../../version.txt');
};

export const getSpeedTest = function(callback){
  let serverPath = appHelper.getServerPath();
  let url = `${serverPath}healthcheck/speed-test`;

  //return Network.get('healthcheck/speed-test');
  return Network.getExternalFunc(url, callback);
};

export const getUserGuide = function () {
  return Network.post('userguide',{}, {blobResponse: true});
};

export const getApplicationStatus = function(callback){
  let url = appHelper.getApplicationStatusPath();

  if(!url){
    return Promise.resolve();
  }
  return Network.getExternalFunc(url, callback);
};

export const getServerHealthCheck = function(callback){
  let serverPath = appHelper.getServerCdnPath();
  let url = `${serverPath}healthcheck`;

  return Network.getExternalFunc(url, callback);
};

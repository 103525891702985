import React from 'react';

import {reduxForm  } from 'redux-form';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import  * as dialogHelper  from "infrastructure/js/components/Dialog/dialogHelper";
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import RollAttributes from './AssetsAttributes/rollAttributes.js';
import KitAttributes from './AssetsAttributes/kitAttributes.js';
import GroupAttributes from './AssetsAttributes/groupAttributes.js';
import ToolAttributes from './AssetsAttributes/toolAttributes.js';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import * as additionalFieldsHelper from "../../Common/Helpers/AdditionalFieldsHelper";
import isEmpty from 'lodash-es/isEmpty';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import SpoolAttributes from "./AssetsAttributes/spoolAttributes";
require('./editAssetAttributesDialog.scss');

class EditAssetAttributes extends React.PureComponent {

  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.dialogs.editassetattributes.');

    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();

    this.state = {
      selectedAssetType: this.entitiesTypes[0],
      selectedAssets: []
    };

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.props.sData.get('boEntityFilter')
    };
  }

  getDialogButtons() {
    let cancelTokenEnabled = !!this.props.sData.get("cancelToken") ;
    return {
      left: [{
        id:'cancel',
        text: this.props.sData.get('loading') ? this.dialogLabels.get('stop') : this.dialogLabels.get("cancel"),
        action: this.props.sData.get('loading') ? this.onStop : this.onHide,
        enabledOnLoading: cancelTokenEnabled

      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.updateassets'),
          bsStyle: "primary",
          loading: this.props.sData.get('loading'),
          action:  this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') &&
              !this.props.sData.get('isIgnoreValidationWarnings') )
        }
      ]
    };
  }

  onSubmit = (data) => {
    let row = data['entities-multi-select'];
    let assetsIds = (row && row.assetsValue) ? row.assetsValue.map((asset) => {return asset.value}) : [];

    let updateData;

    switch (this.state.selectedAssetType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        updateData = this.getRollUpdatedData(updateData, assetsIds, data);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        updateData = this.getSpoolUpdatedData(updateData, assetsIds, data);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        updateData = this.getKitUpdatedData(updateData, assetsIds, data);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        updateData = this.getGroupUpdatedData(updateData, assetsIds, data);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        updateData = this.getToolUpdatedData(updateData, assetsIds, data);
        break;
      default:
        console.error("error submitting assetType: " + this.state.selectedAssetType);
        return;
    }

    updateData.ignoreValidationWarnings = (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
      data.footerValidationCheckbox : false;

    this.props.actions.submit(
      {updateData, assetsType: this.state.selectedAssetType},
      this.getMessageDialogBuilder(),
      this.props.reloadParentComponent
    );
  };

  getToolUpdatedData(updateData, assetsIds, data) {
    updateData = { toolIds: assetsIds };
    if (data.location) {
      updateData.locationId = data.location.value;
    }
    if (data.subLocation) {
      updateData.subLocation = data.subLocation;
    }

    if (data.type) {
      updateData.toolTypeBusinessId = data.type.data ? data.type.data.businessId : data.type.label
    }

    if (data.nextMaintenance !== undefined) {
      updateData.currentCyclesNumber = data.nextMaintenance;
    }
    if(data.headerCheckbox_nextMaintenance){
      updateData.currentCyclesNumber = null;
    }
    if (data.maintenanceUsageTime_days !== undefined ||
        data.maintenanceUsageTime_hours ||
        data.maintenanceUsageTime_minutes) {
      updateData.maintenanceUsageTime = DateTimeHelper.ConvertDaysHoursMinutesToMinutes(data.maintenanceUsageTime_days, data.maintenanceUsageTime_hours, data.maintenanceUsageTime_minutes);
    }
    if(data.headerCheckbox_maintenanceUsageTime){
      updateData.maintenanceUsageTime = null;
    }
    if(data.isMaxValueForUtl){
       updateData.useMaxValueForUtl = true;
    }
    if(data.isMaxValueForCycles){
      updateData.useMaxValueForCycles = true;
    }

    this.handleAdditionalFields(updateData, data);

    return updateData;
  }

  getKitUpdatedData(updateData, assetsIds, data) {
    updateData = { kitsIds: assetsIds };

    if (data.bag) {
      updateData.bag = data.bag;
    }
    if (data.maxStorageTemp !== undefined) {
      updateData.maxStorageTemp = UnitHelper.userValueToServerValue(unitTypes.TEMPERATURE, data.maxStorageTemp, 3);
    }
    if (data.location) {
      updateData.locationId = data.location.value;
    }
    if (data.subLocation) {
      updateData.subLocation = data.subLocation;
    }

    this.handleAdditionalFields(updateData,data);

    return updateData;
  }

  getGroupUpdatedData(updateData, assetsIds, data) {
    updateData = { groupsIds: assetsIds };

    if (data.groupType) {
      updateData.groupTypeBusinessId = data.groupType.data?.businessId;
    }

    if (data.maxStorageTemp !== undefined) {
      updateData.maxStorageTemp = UnitHelper.userValueToServerValue(unitTypes.TEMPERATURE, data.maxStorageTemp, 3);
    }
    if (data.location) {
      updateData.locationId = data.location.value;
    }
    if (data.subLocation) {
      updateData.subLocation = data.subLocation;
    }

    this.handleAdditionalFields(updateData, data);

    return updateData;
  }

  getRollUpdatedData(updateData, assetsIds, data) {
    updateData = { rollsIds: assetsIds };

    if (data.material) {
      updateData.materialId = data.material.value;
    }
    if (data.lot) {
      updateData.lot = this.convertNewOption(data.lot);
    }
    if (data.length) {
      updateData.length = UnitHelper.userValueToServerValue(unitTypes.LENGTH, data.length, 2);
    }
    if (data.weight) {
      updateData.weight = UnitHelper.userValueToServerValue(unitTypes.WEIGHT, data.weight, 2);
    }
    if (data.width) {
      updateData.width = UnitHelper.userValueToServerValue(unitTypes.WIDTH, data.width, 2);
    }
    if (data.manufacturer) {
      updateData.manufacturer = this.convertNewOption(data.manufacturer);
    }
    if (data.dateOfManufacturer) {
      updateData.dateManufacturer = DateTimeHelper.ConvertFromDate(data.dateOfManufacturer);
    }
    if (data.maxStorageTemp !== undefined) {
      updateData.maxStorageTemp = UnitHelper.userValueToServerValue(unitTypes.TEMPERATURE, data.maxStorageTemp, 3);
    }
    if (data.location) {
      updateData.locationId = data.location.value;
    }
    if (data.subLocation) {
      updateData.subLocation = data.subLocation;
    }
    if (data.project) {
      updateData.projectId = data.project.value;
    }
    if (data.inspectionStatus) {
      updateData.inspectionStatus = data.inspectionStatus.value;
    }

    this.handleAdditionalFields(updateData, data);

    return updateData;
  }

  getSpoolUpdatedData(updateData, assetsIds, data) {
    updateData = { spoolsIds: assetsIds };

    if (data.material) {
      updateData.materialId = data.material.value;
    }
    if (data.lot) {
      updateData.lot = this.convertNewOption(data.lot);
    }
    if (data.length) {
      updateData.length = UnitHelper.userValueToServerValue(unitTypes.LENGTH, data.length, 2);
    }
    if (data.weight) {
      updateData.weight = UnitHelper.userValueToServerValue(unitTypes.WEIGHT, data.weight, 2);
    }
    if (data.width) {
      updateData.width = UnitHelper.userValueToServerValue(unitTypes.WIDTH, data.width, 2);
    }
    if (data.manufacturer) {
      updateData.manufacturer = this.convertNewOption(data.manufacturer);
    }
    if (data.dateOfManufacturer) {
      updateData.dateManufacturer = DateTimeHelper.ConvertFromDate(data.dateOfManufacturer);
    }
    if (data.maxStorageTemp !== undefined) {
      updateData.maxStorageTemp = UnitHelper.userValueToServerValue(unitTypes.TEMPERATURE, data.maxStorageTemp, 3);
    }
    if (data.location) {
      updateData.locationId = data.location.value;
    }
    if (data.subLocation) {
      updateData.subLocation = data.subLocation;
    }
    if (data.project) {
      updateData.projectId = data.project.value;
    }
    if (data.inspectionStatus) {
      updateData.inspectionStatus = data.inspectionStatus.value;
    }

    this.handleAdditionalFields(updateData, data);

    return updateData;
  }

  handleAdditionalFields = (updateData, data) => {
    if(!isEmpty(this.props.sData.get('additionalFields'))){
      let fields = additionalFieldsHelper.convertToAdditionalFields(data['additionalFields'], this.props.sData.get('additionalFields'));
      if (fields && fields.length > 0) {
        updateData.additionalFieldsValues = fields;
      }
    }
  }

  convertNewOption = (data) => {
    if (!data || data.label == null) {
      return null;
    }

    return {
      id: (data.value !== data.label) ? data.value : null,
      name: data.label
    }
  }

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get("confirmation.message.title", "", { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  onSelectedAssetTypeChanged = (value) => {
    this.setState({selectedAssetType: value});
  };

  onSelectedAssetsChanged = (value) => {
    this.setState({selectedAssets: value});
  };

  renderAssetAttributes =() => {
    switch (this.state.selectedAssetType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        return <RollAttributes {...this.props}/>;
     case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        return <SpoolAttributes {...this.props}/>;
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        return <KitAttributes {...this.props}/>;
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        return <GroupAttributes {...this.props}/>;
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        return <ToolAttributes {...this.props}/>;
      default:
        return null;
    }
  };

  renderAssetsSelection = () => {
    let {sData} = this.props;
    let allAssets = sData.get("assets");
    let preselectedAssets = sData.get('preselectedAssets');
    let editLengthOnly = sData ? sData.get('editLengthOnly') : false;
    let focusProps = (editLengthOnly && preselectedAssets && preselectedAssets.size > 0) ? {} : {autoFocus: true};

    return <div className="select-assets">
      <InputSection label={this.labels.get("selectassets.text")} className="no-margin" />

      <EntitiesMultiSelect id="entities-multi-select"
                             name="entities-multi-select"
                             entitiesTypes={this.entitiesTypes}
                             validate={Validation.required}
                             preSelectedEntities={preselectedAssets}
                             fetchConfig={this.fetchConfig}
                             onTypeChangeCallback={this.onSelectedAssetTypeChanged}
                             onValueChangeCallback={this.onSelectedAssetsChanged}
                             disableTypeChange={true}
                             dropdownMenuClassName="entities-multi-select-edit-attributes-dialog"
                             {...focusProps}
                             { ...this.props }/>

    </div>
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {

    let bodyClassName = isEmpty(this.props.sData.get('additionalFields')) ? '' : 'modal-body-overflow';
    return (
      <Dialog
        id="edit-attributes-dialog"
        className="edit-attributes-dialog"
        bodyClassName={bodyClassName}
        show={!!this.props.sData.get("show")}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get("header.title")}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        {this.renderAssetsSelection()}

        {this.renderAssetAttributes()}

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'editAssetAttributes',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(EditAssetAttributes);



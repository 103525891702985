import React from 'react';

import PropTypes from 'prop-types';
import {createLabelHelper} from '../../../utils/labelHelper';
import Button from 'infrastructure/js/components/controls/Button/button';

require('./pagination.scss');

export default class Pagination extends React.PureComponent{
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('grid.');
  }

  onPreviousPageClick = () => {
    let info = this.props.paginationData;
    if (this.props.onPreviousPageClick) {
      this.props.onPreviousPageClick(info);
    }
  };

  onNextPageClick = () => {
    let info = this.props.paginationData;
    if (this.props.onNextPageClick) {
      this.props.onNextPageClick(info);
    }
  };

  render() {
    if (!this.props.paginationData) {
      return null;
    }

    let info = this.props.paginationData;
    let firstRow = 0;
    let lastRow = 0;
    if (info.numberOfElements >  0) {
      firstRow = info.size * info.number + 1;
      lastRow = firstRow + info.numberOfElements - 1;
    }
    return <div className="pl-pagination-panel">
      <Button id='gridPreviousPageBtn'
              className="border-btn"
              icon="pl pl-arrow-left"
              onClick={this.onPreviousPageClick}
              disabled={info.first}/>
      <span>{this.labels.get("pageinfo", undefined, {first: firstRow, last: lastRow, total: info.totalElements})}</span>
      <Button id='gridNextPageBtn' className="border-btn"
              icon="pl pl-arrow-right"
              onClick={this.onNextPageClick}
              disabled={info.last}/>
    </div>;
  }

}

// Pagination.defaultProps = {
// };
//
// Pagination.propTypes = {
//   //id: PropTypes.string.isRequired,
//   //label: PropTypes.string,
//   paginationData: PropTypes.object.isRequired(),
// };

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues} from 'redux-form';

import {jsxActions} from '../../actions/Login/loginActions.js';
import {jsxActions as messageDialogActions}  from '../../actions/MessageDialog/messageDialogActions.js';
import { withRouter } from 'react-router-dom'

import LoginPage from 'infrastructure/js/components/LoginPage/loginPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(jsxActions, dispatch),
      messageDialogActions:   bindActionCreators(messageDialogActions, dispatch),
    },
  }
}

let LoginPageContainer = reduxForm({
    form: 'loginPage'
  }
)(LoginPage);

LoginPageContainer = connect(
  (state)=>{
    return {
      formValues: getFormValues('loginPage')(state),
      sData: state.login
    }
  },
  mapDispatchToProps
)(LoginPageContainer);

export default withRouter(LoginPageContainer);

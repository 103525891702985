import UserService from '../../services/UserManagement/UserService.js';
import { jsxActions as loginActions } from '../Login/loginActions.js';
import { api as userDialogApi } from '../Dialogs/userManagementDialogActions.js';
import { api as changePasswordDialogApi } from '../Dialogs/changePasswordDialogActions.js';

export let jsxActions = {};

jsxActions.showEditUser = function (user) {
  return function (dispatch, getState) {
    userDialogApi.show(user, UserService.selfUpdate)(dispatch, getState);
  };
};

jsxActions.showChangePassword = function (user, dialogLabels) {
  return function (dispatch, getState) {
    changePasswordDialogApi.show(user, null, UserService.changeMyPassword, dialogLabels)(dispatch, getState);
  };
};

jsxActions.logout = function () {
  return function (dispatch, getState) {
    loginActions.onDoLogout(false)(dispatch, getState);
  };
};

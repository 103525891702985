import React from 'react';
import PropTypes from 'prop-types';

import {List, Map} from 'immutable';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField.js';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {DefaultWorkOrderTypes} from '../../../../../utils/workOrderHelper';
import AssetsToAssetsSection from '../sections/AssetsToAssetsSection/assetsToAssetsSection';
import AssetsToAssetsWithConsumptionSection from '../sections/AssetsToAssetsWithConsumptionSection/assetsToAssetsWithConsumptionSection';
import AssetsWithConnectionSection from '../sections/AssetsWithConnectionSection/assetsWithConnectionSection';
import AssetsWithoutConnectionSection from '../sections/AssetsWithoutConnectionSection/assetsWithoutConnectionSection';
import AssetsDemoldingSection from '../sections/AssetsDemoldingSection/assetsDemoldingSection';
import AssetsCuringSection from '../sections/AssetsCuringSection/assetsCuringSection';
import AssetsMergeSection from '../sections/AssetsMergeSection/assetsMergeSection';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import EntitiesMultiSelect from "../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect";
import {EntityPropertyTypes} from "../../../../../enums/entityPropertyTypes";
import {isAFPStation} from '../../../../../utils/locationHelper';

require('./reportOperationItem.scss');


export default class ReportOperationItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.locationpage.view.workorder.reportoperationdialog.');

    this.operationStatusOptions = this.props.operationStatusOptions;

    this.operationType = this.props.operationType;

    // this.entitiesTypes = this.props.entitiesTypes;

    this.hasPreSelectedWo = !!this.props.workOrder;

    this.state = {
                   curWorkOrder: this.props.workOrder,
                   isOperationStatusComplete: true,
                   isAssetsAttachmentActive: !!this.props.workOrder,
                   dummyKey: 0, //dummy key to force the component remount
                 };

    this.woFetchConfig = {
      entityType: EntityPropertyTypes.WO_BUSINESS_ID,
      // filter: BO_EntityFilters.ACTIVE,
      filter: [FetchEntitiesFilters.ACTIVE],
      action: this.props.actions.fetchEntities,
    };
  }

  getReduxFieldName = (fieldName) => {
    return this.props.name + '.' + fieldName;
  };

  getPreselectedEntities = () => {
   return this.props.workOrder ? List([this.props.workOrder]) : List([]);
  };

  componentDidMount() {
    this.props.change(this.getReduxFieldName('operationStatus'), this.operationStatusOptions[1]);
    this.props.change(this.getReduxFieldName('woComplete'), false);
    this.props.change(this.getReduxFieldName('comments'),'');

    if (this.props.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS') ||
        this.props.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP')) {
      this.props.change(this.getReduxFieldName('markAsConsumed'), true);
    }
  }

  /////////////////////////////////////////////////////////////////////////
  // Handlers
  /////////////////////////////////////////////////////////////////////////

  onWoComboboxChangeHandler = (newValue, oldValue) => {

    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    let newState = {
      isAssetsAttachmentActive: true,
      curWorkOrder: newValue ? newValue.data : null,
    };

    if (this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITHOUT_ASSETS_CONNECTION') ||
        this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('CURING')  ||
        this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING')  ||
        // this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('MERGE')  ||   //TODO: L must be here????????
        this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('UNCURING')) {

      newState.dummyKey = (this.state.dummyKey === 0 ? 1 : 0);
    }

    this.setState(newState);
    this.props.actions.resetAssetsForWo();
  };


  //clear work Order Complete checkbox after every change in operation status
  onOperationStatusChangeHandler = ( newValue, oldValue) => {
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    this.props.change( this.getReduxFieldName('woComplete'), false );

    this.setState({isOperationStatusComplete: (newValue && newValue.value ===  this.operationStatusOptions[1].value)});

  };

  attachAssetsFromWoHandler = () => {

    if (this.state.curWorkOrder) {

      this.props.actions.fetchAssetsForWo(this.state.curWorkOrder.id, this.getFetchEntityFilters());

      this.setState({isAssetsAttachmentActive: false});
    }
  };

  getWoRelatedAssetsData = () => {

    if (this.state.curWorkOrder) {
      let fetchAssetsData = this.props.sData.get('fetchAssetsData');
      let woId = this.state.curWorkOrder.id;
      let woRelatedAssets = fetchAssetsData.get(woId);
      //Filter out the WO's related rolls for the operations that do not support ROLL entity
      if (!this.props.entitiesTypes.includes('ROLL')) {
        woRelatedAssets = woRelatedAssets?.filter(item => item.objectType !== 'ROLL');
      }

      if (woRelatedAssets && woRelatedAssets.size > 0) {
        return woRelatedAssets;
      }
    }
    return List();
  };

  getFetchEntityFilters = () => {
    if (this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('UNCURING')) {
      return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.CURED, FetchEntitiesFilters.NOT_CONTAINED];
    }
    if (this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('CURING')) {
      return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NOT_CURED, FetchEntitiesFilters.NOT_CONTAINED];
    }
    if (this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING') && isAFPStation(this.props.sLocationDetails)) {
      return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.NOT_IN_PROGRESS, FetchEntitiesFilters.HAS_ATTACHED_ASSET];
    }
    return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NOT_CONTAINED];
  };

  getFetchConfig = () => {

    let fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.getFetchEntityFilters()
    };

    if (this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITH_ASSETS_CONNECTION') ||
        this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITHOUT_ASSETS_CONNECTION')||
        this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('MERGE')||
        this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS') ||
        this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS_WITH_ROLL_CONSUMPTION')
       ) {

      let selectedWorkOrder = this.state.curWorkOrder;

      fetchConfig.searchBy = {
        roll: [{
          objectName: 'isUnrestrictedByInspection',
          values: [true]
        }]
      };

      if (selectedWorkOrder && selectedWorkOrder.project) {
        fetchConfig.searchBy.roll.push({
          objectName: 'projectId',
          values: [selectedWorkOrder.project.id]
        });
      }

      if (this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS_WITH_ROLL_CONSUMPTION') ||
          this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('MERGE')) {

        if (selectedWorkOrder && selectedWorkOrder.workOrderType === DefaultWorkOrderTypes.KITTYPE_ORIENTED && !!selectedWorkOrder.kitType) {
          fetchConfig.searchBy.kit = [
            {
              objectName: 'kitTypeId',
              values: [selectedWorkOrder.kitType.id]
            }
          ]
        }
      }
    }

    return fetchConfig;
  };

  //////////////////////////////////////////////////////////////////////////////////////
  // Renderers
  /////////////////////////////////////////////////////////////////////////////////////

  renderWoComplete = () => {
    if (PermissionManager.hasMarkWoCompletePermissions()) {
      return (<div className="right">
        <Checkbox
          id={this.getReduxFieldName('woComplete')}
          name={this.getReduxFieldName('woComplete')}
          label={this.labels.get('woiscomplete')}
          disabled={!this.state.isOperationStatusComplete}
        />
      </div>)
    }
    return null;
  };

  getAttachAssetsLink() {
    return (
      <a className="attach-assets-from-wo"
         onClick={this.attachAssetsFromWoHandler}
         style={{visibility: (this.state.isAssetsAttachmentActive ? "visible" : "hidden")}}
      >
        {this.labels.get('attachassetsfromwo')}
      </a>
    );
}

  renderAttachAssetsFromWoLink = () => {
    switch(this.operationType) {
      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITHOUT_ASSETS_CONNECTION'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('CURING'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('UNCURING'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('MERGE'):
        return this.getAttachAssetsLink();
      case getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING'):
        return isAFPStation(this.props.sLocationDetails) ? null : this.getAttachAssetsLink();
      }
      return null;
  };

  getAssetsSectionByOperationType = () => {
    switch(this.operationType) {
      case getEnumValue(enumTypes.OPERATION_TYPE)('CURING'):
        return AssetsCuringSection;
      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITHOUT_ASSETS_CONNECTION'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('UNCURING'):
        return AssetsWithoutConnectionSection;
      case getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING'):
        return AssetsDemoldingSection;
      case getEnumValue(enumTypes.OPERATION_TYPE)('GENERAL_OPERATION_WITH_ASSETS_CONNECTION'):
        return AssetsWithConnectionSection;
      case getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS'):
      case getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP'):
        return AssetsToAssetsSection;
      case getEnumValue(enumTypes.OPERATION_TYPE)('MERGE'):
        return AssetsMergeSection;
      case getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS_WITH_ROLL_CONSUMPTION'):
        return AssetsToAssetsWithConsumptionSection;
      default:
        console.error('Unknown operation type: ' + this.operationType);
        return null;
    }
  };

  getAssetsSectionProps = () => {
    let fetchConfig = this.getFetchConfig();

    let preselectedAssets = this.getWoRelatedAssetsData();

    let propsObj = {
      ...this.props,
      fetchConfig,
      labels: this.labels,
      workOrder: this.state.curWorkOrder,
      entitiesTypes: this.props.entitiesTypes,
      preselectedAssets,
    };

    if (this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('ADD_ASSETS_TO_ASSETS')) {
      propsObj.disableMarkAsConsumed = false;
    }
    if (this.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP')) {
      propsObj.disableMarkAsConsumed = true;
    }

    return propsObj;
  };


  renderAssetsSection = () => {
    let AssetsSection = this.getAssetsSectionByOperationType();
    if (!AssetsSection) {
      return null;
    }
    let props = this.getAssetsSectionProps();

    return (
      <div className="assets-section">
               <AssetsSection {...props} />
      </div>
    )
  };


  render = () => {

    let {name, labels,  operationStatusOptions, fetchConfig, ...otherProps} = this.props;

    return (
        <div className="report-operation-item">
        <hr/>
          <div className="left">
            <label>{this.labels.get('wo')+'*'}</label>

            <EntitiesMultiSelect
              id={this.getReduxFieldName('woCombobox')}
              name={this.getReduxFieldName('woCombobox') }
              validate={Validation.required}
              isDisabled={this.hasPreSelectedWo}
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('WO')]}
              fetchConfig={this.woFetchConfig}
              autoFocus={!this.hasPreSelectedWo}
              onValueChangeCallback={this.onWoComboboxChangeHandler}
              preSelectedEntities={this.getPreselectedEntities()}
              disableOnPreselect={this.hasPreSelectedWo}
              change={this.props.change}
              isMulti={false}
              showEntitiesTypes={false}
            />
          </div>

          <div className="middle">
            <label>{this.labels.get('operationstatus')+'*'}</label>
            <Dropdown
              id={this.getReduxFieldName('operationStatus')}
              name={this.getReduxFieldName('operationStatus')}
              options={operationStatusOptions}
              onChangeCallback={this.onOperationStatusChangeHandler}
              validate={Validation.required}>
            </Dropdown>
          </div>

          {this.renderWoComplete()}

          {this.renderAttachAssetsFromWoLink()}

          {this.renderAssetsSection()}

          <div className="comments-section">
            <label>{this.labels.get('comments')}</label>
            <TextAreaField id={this.getReduxFieldName('comments')}
                           name={this.getReduxFieldName('comments')}
            />
          </div>

        </div>
      )
  };

}

ReportOperationItem.defaultProps = {
  entitiesTypes: [],
};

ReportOperationItem.propTypes = {
  name: PropTypes.string.isRequired,
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  entitiesTypes: PropTypes.array.isRequired,
  operationType: PropTypes.string.isRequired,
  itemIndex: PropTypes.number.isRequired,
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as toolMaintenanceDashboardPageActions } from '../../../actions/Reports/toolMaintenanceDashboardPageActions.js';
import ToolMaintenanceDashboardPage from '../../../components/Reports/Dashboard/ToolMaintenanceDashboardPage/toolMaintenanceDashboardPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(toolMaintenanceDashboardPageActions, dispatch),
    }
  }
}

let ToolMaintenanceDashboardPageContainer = connect(

  (state) => {
  	return {
      sData: state.toolMaintenanceDashboardPage,
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps
)(ToolMaintenanceDashboardPage);


export default ToolMaintenanceDashboardPageContainer;




import React, {Component} from 'react';
import PropTypes from 'prop-types';


require('./linkActions.scss');

export default class LinkActions extends React.PureComponent {

  render() {

    let {value} = this.props;

    if (!value.label || !value.action || !value.data) {
      return null;
    }

    if (value.disabled) {
      return <span>{value.label}</span>;
    }

    return <a className='pl-link-action' onClick={() => {value.action(value.data)}}>{value.label}</a>;}
}

LinkActions.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
};

LinkActions.defaultProps = {
  label: '',
};


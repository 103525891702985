import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import {navigationStates} from '../../../../../enums/navigationStates';
import MultiRowsCell from '../../../../../../../infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
export default class ReadersAndAntennasViewGrid extends React.PureComponent{

  filterConfig = [  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.readersandantennas.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }
  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.readername.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'host',
        title: this.labels.get('columns.readerip.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'readerType',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      },
      {
        fieldName: 'name',
        title: this.labels.get('columns.antennaname.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.antennas.map(antenna =>  (antenna.name)),
              componentType: LabelWithTooltipCell
            };
          }
        }
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.antennas.map(antenna =>  ({ id: antenna.locationResponseModel.id, type: navigationStates.LOCATION, label: antenna.locationResponseModel.name})),
              componentType: EntityIdCell
            };
          }
        }
      },
      {
        fieldName: 'port',
        title: this.labels.get('columns.port.title'),
        filterType: filterTypes.NONE,
        width: 70,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.antennas.map(antenna =>  ( antenna.port)),
              componentType: LabelWithTooltipCell
            };
          }
        }
      },
      {
        fieldName: 'tx',
        title: this.labels.get('columns.tx.title'),
        filterType: filterTypes.NONE,
        width: 75,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.antennas.map(antenna =>  (antenna.tx)),
              componentType: LabelWithTooltipCell
            };
          }
        }
      },
      {
        fieldName: 'rx',
        title: this.labels.get('columns.rx.title'),
        filterType: filterTypes.NONE,
        width: 75,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.antennas.map(antenna =>  ( antenna.rx)),
              componentType: LabelWithTooltipCell
            };
          }
        }
      },
    ]
  };

  getRowHeight(params) {
    let rowHeight =  64;
    if (params.data.antennas && params.data.antennas.length > 1) {
      rowHeight = params.data.antennas.length* 30 + 40;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_RFID_SETTINGS_READERS_AND_ANTENNAS}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}


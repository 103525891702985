import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as headerSettingsActions} from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import { gridsNames} from '../../../../enums/gridsNames';
import PreferencesView from '../../../../components/Administration/SettingsPage/Preferences/preferencesView';
import {jsxActions as preferencesViewActions} from "../../../../actions/Administration/SettingsPage/preferenceViewActions";
import {jsxActions as preferencesViewGridActions} from "../../../../actions/Administration/SettingsPage/preferenceGridViewActions";
import {jsxActions as editPreferenceDialogActions} from "../../../../actions/Administration/SettingsPage/Dialogs/editPreferenceDialogActions";
import { jsxActions as rfidSettingsPageActions} from '../../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(preferencesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      preferencesViewGridActions: bindActionCreators(preferencesViewGridActions, dispatch),
      editPreferenceDialogActions : bindActionCreators(editPreferenceDialogActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch),
      rfidSettingsPageActions: bindActionCreators(rfidSettingsPageActions, dispatch),
    }
  }
}

let SystemPreferencesViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_SETTINGS_PREFERENCES);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sEditPreferenceDialogData: state.administration.getIn(['settingsPage', 'editPreferenceDialog']),
      sData: state.administration.getIn(['settingsPage', 'preferencesView']),
    };
  },

  mapDispatchToProps
)(PreferencesView);

export default SystemPreferencesViewContainer;

import React from 'react';

import Label from 'infrastructure/js/components/Label/label.js';


export default class LabelWithStyleCell extends React.PureComponent {

  render() {
    let {value} = this.props;
    let label = (value && value.label) ? value.label : '';
    let style = (value && value.style) ? value.style : null;

    return (<div style={style}>
      <Label text={label} />
    </div>);
  }
}

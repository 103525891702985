import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash-es/isEqual';
import EntitiesMultiSelect from '../EntitiesMultiSelect/entitiesMultiSelect'
import Label from "../../../../../../infrastructure/js/components/Label/label";
import {EntityPropertyTypes} from "../../../../enums/entityPropertyTypes";

require('./entitiesMultiSelectHidden.scss');

export  default  class EntitiesMultiSelectHidden extends React.PureComponent {
  constructor(props){
    super(props)

    this.state = {
      currentValue: 'Select...',
      editMode: false,
      isDebugMode: false,
      dummyKey: ''
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.fetchConfig.filter,this.props.fetchConfig.filter)) {
      this.setState({
        dummyKey: new Date(),
      });
    }

    if(nextProps.selectedItem && !isEqual(nextProps.selectedItem, this.props.selectedItem)){
      this.setState({
        currentValue: nextProps.selectedItem.label,
      });
    }
  }

  editModeON = () => {
    this.setState({editMode: true});
  };

  editModeOFF = () => {
    this.setState({editMode: false});
  };

  onLabelClick = () => {
    if (this.props.editable) {
      this.editModeON();
    }
  };

  onValueChangeCallback = (value, oldValue) => {

    if (this.props.onValueChangeCallback) {
      this.props.onValueChangeCallback(value, oldValue);
    }

    this.setState({
      currentValue: value.label,
      editMode: false
    })
  };

  render(){
    const { currentValue, editMode, isDebugMode } = this.state

    return (
      <div className={'entities-multi-select-hidden'} >

        {!editMode && !isDebugMode ?
          <div onClick={this.onLabelClick} className={`label-wrapper ${this.props.editable ? 'editable' : ''}`}>
            <Label key={this.state.dummyKey} text={currentValue}/>
          </div> : null
        }

        {editMode || isDebugMode ?
          <span className={'inner-component-wrap'}>
            <EntitiesMultiSelect {...this.props}
                                  key={this.state.dummyKey}
                                 onValueChangeCallback={this.onValueChangeCallback}
                                 autoFocus={true}
                                 openMenuOnFocus={true}
                                 onBlur={this.editModeOFF}
            />
          </span> : null
        }
      </div>
    )
  }
}

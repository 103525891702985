import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as toolCategoriesViewActions} from '../../../actions/Administration/MatSettingsPage/toolCategoriesViewActions'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as toolCategoriesViewGridActions} from '../../../actions/Administration/MatSettingsPage/toolCategoriesViewGridActions';
import { jsxActions as createToolCategoryDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createToolCategoryDialogActions.js';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import ToolCategoriesView from '../../../components/Administration/MatSettingsPage/Tools/ToolCategoriesView/toolCategoriesView';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(toolCategoriesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      toolCategoriesViewGridActions: bindActionCreators(toolCategoriesViewGridActions, dispatch),
      createToolCategoryDialogActions : bindActionCreators(createToolCategoryDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }

  }
}

let ToolCategoriesViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_CATEGORIES) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_CATEGORIES) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateToolCategoryDialogData: state.administration.getIn(['matSettingsPage', 'createToolCategoryDialog']),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(ToolCategoriesView);


export default ToolCategoriesViewContainer;




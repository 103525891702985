import React from 'react';
import {reduxForm} from 'redux-form';
import RollIdCell from './CustomCells/RollIdCell/rollIdCell.js';
import LengthCell from '../../../Common/CustomGridCells/LengthCell/lengthCell.js';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import MaterialHelper from '../../../../utils/materialHelper';
import WeightCell from '../../../Common/CustomGridCells/WeightCell/weightCell';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DefrostingLabelCell from '../../../Common/CustomGridCells/DefrostingLabelCell/defrostingLabelCell';

require('./cuttingGrid.scss');


class CuttingGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'rollBusinessId', getOptions: false},
    {fieldName: 'material.businessId', filterName: 'rollMaterial', getOptions: false},
    {fieldName: 'lot', filterName: 'rollLot', getOptions: false},
    {fieldName: 'defrostingStatus', filterName: 'defrostingStatus', getOptions: true},
    {fieldName: 'lengthLeft', filterName: 'rollLength'},
    {fieldName: 'weight', filterName: 'rollWeight'},
  ];

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.grid.');
    this.allLabels = createLabelHelper('');
    this.filterDefrostinglabels = createLabelHelper('mat.filter.defrosting.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let config = [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.rollId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'rollBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.ROLL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: RollIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              description: params.data.businessId,
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              defrostingTimeLeft: params.data.defrostingTimeLeft
            };
          }
        }
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'rollMaterial',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_ROLL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        width: 330,
        columnOptions: {
          sort: 'asc',
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'defrostingStatus',
        title: this.labels.get('columns.defrosting.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterSections: this.getDefrostingColumnFilterSections(),
        filterName: 'defrostingStatus',
        columnOptions: {
          cellComponent: DefrostingLabelCell,
          valueGetter: (params) => {
            return {
              status: params.data.defrostingStatus,
              statusLabel: params.data.defrostingStatusDisplayKey ? this.allLabels.get(params.data.defrostingStatusDisplayKey) : '',
              defrostingTimeLeft: params.data.defrostingTimeLeft
            }
          }
        }
      },

      {
        fieldName: 'lot',
        title: this.labels.get('columns.lot.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        fetchConfig: {
          entityType: EntityPropertyTypes.LOT_NAME,
          filter: [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_ROLL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities,
        },
        filterName: 'rollLot',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.lot || '';
          }
        }
      },
    ];

    let lengthOrWeight = this.getLengthOrWeightColumn();
    config.splice(4, 0, lengthOrWeight);
    return config;
  }

  getLengthOrWeightColumn = () => {

    if(PermissionManager.isWeightSupported()){
      return {
        fieldName: 'weight',
        title: this.labels.get('columns.currentWeight.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: WeightCell,
          valueGetter: (params) => {
            return {weight: params.data.weight};
          }
        }
      }
    }

    return {
      fieldName: 'lengthLeft',
      title: this.labels.get('columns.currentLength.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        cellComponent: LengthCell,
        valueGetter: (params) => {
          return {length: params.data.lengthLeft};
        }
      }
    }
  };

  getDefrostingColumnFilterSections() {
    return [
      {
        properties: [
          {name: 'BEING_DEFROSTED', label: this.filterDefrostinglabels.get('beingDefrosted'), value: false},
          {name: 'DEFROSTED', label:  this.filterDefrostinglabels.get('defrosted'), value: false}
        ]
      },
    ];
  }

  render() {
    return (
      <div className="cutting-grid">
        <Grid gridName={gridsNames.LOCATION_CUTTING}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
          >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'AssetsView_cuttingGrid'
  }
)(CuttingGrid);





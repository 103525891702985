import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";

export function convertToAdditionalFields(additionalFieldsValues, additionalFieldsDefinitions){
  if(!additionalFieldsValues || !additionalFieldsDefinitions){
    return [];
  }
  let fields = [];
  additionalFieldsDefinitions.forEach((field) => {
    let fieldValue = additionalFieldsValues[field.name];
    if (fieldValue !== undefined) {
      let value = (field.type.type === 'DATE_PICKER') ? DateTimeHelper.ConvertFromDate(fieldValue) : (fieldValue || fieldValue === 0) ? fieldValue : null;
      fields.push({
        fieldId: field.id,
        fieldType: field.type.type,
        value
      });
    }
  });
  return fields
}

export function getAdditionalFieldValue(type, fieldValue){
  switch (type) {
    case 'TEXT':
    case 'LARGE_TEXT':
      return fieldValue && fieldValue.trim().length > 0 ? fieldValue : null;
    case 'DATE_PICKER':
      return fieldValue ? fieldValue : null;
    case 'NUMERIC':
      return isNaN(fieldValue) ? null : fieldValue;
    default:
      console.log(`Additional fields - unsupported type: ${type}`);
      return null;
  }
}

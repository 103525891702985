import React from 'react';
import PropTypes from 'prop-types';

import Button from 'infrastructure/js/components/controls/Button/button';
import {FieldArray} from 'redux-form';
import classNames from 'classnames';

require('./addRemoveList.scss');

export default class AddRemoveList extends React.PureComponent {

  constructor(props) {
    super(props);

    this.isViewOnly = this.props.isViewOnly || false;

    this.state = {items: this.props.preSelectedItems ? [...this.props.preSelectedItems] : []};
  }

  componentWillUnmount() {
    if (this.fields && !this.props.keepDataOnUnmount) {
      this.fields.removeAll();
    }
  }

  renderRemoveButton = (index, fields) => {
    if (this.isViewOnly) {
      return null;
    }

    if (fields.get(index) && fields.get(index).disableRemove) {
      return null;
    } else {
      return (
        <span className="pl pl-x-input-close remove-btn" onClick={() => { this.removeButtonClickHandler(index, fields); }} onKeyPress={this.ignoreAll}/>
      );
    }

  };

  removeButtonClickHandler = (index, fields) => {
    let newItems = [...this.state.items];
    newItems.splice(index, 1);
    this.setState({items: newItems}, () => {
        fields.remove(index);
      }
    );

    if (this.props.onRemoveCallback) {
      this.props.onRemoveCallback(index);
    }
  };

  ignoreAll = (event) => {
      event.preventDefault();
  }

  renderAddButton = (fields) => {

    if (this.isViewOnly) {
      return null;
    }

    let disabled = this.props.addButtonDisabled || (this.props.maxItemsToRender && fields.length >= this.props.maxItemsToRender);

    return (
      <Button id="add-row-btn" disabled={disabled} className="add-row" onKeyPress={this.ignoreAll} onClick={() => { this.addButtonClickHandler(fields); }}>
        <span className="pl pl-icon-add"/> {this.props.addButtonLabel}
      </Button>
    );
  };

  addButtonClickHandler = (fields) => {
    let defaultItem = this.props.defaultItem ? this.props.defaultItem : {};
    fields.push(defaultItem);

    this.setState({items: [...this.state.items, defaultItem]});

    if (this.props.onAddCallback) {
      this.props.onAddCallback();
    }
  };

  renderRows = ({fields, meta: {error}}) => {
    this.fields = fields;

    return (
      //<div className="add-remove-list-container">
      <div className={classNames('add-remove-list-container', this.props.className)}>
        <div className={classNames('add-remove-list', {'list-overflow': (fields.length > 5)})}>
          {fields.map((fieldName, index) => {
              let data = {
                item: this.state.items[index],
                value: fields.get(index),
                name: fieldName,
                index: index,
                fieldsData: fields.getAll()
              };
              return (
                <div className="list-item" key={'list-item' + index}>
                  {this.props.itemRenderer(data)}

                  {this.renderRemoveButton(index, fields)}
                </div>);
            }
          )}
        </div>
        {this.renderAddButton(fields)}
      </div>
    )
  };

  render() {
    return (
      <FieldArray name={this.props.name} validate={this.props.validate} component={this.renderRows}/>
    );
  }
}

AddRemoveList.defaultProps = {
  addButtonDisabled: false,
  isViewOnly: false,
  maxItemsToRender: null
};

AddRemoveList.propTypes = {
  preSelectedItems: PropTypes.array,
  itemRenderer: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool,
  addButtonDisabled: PropTypes.bool,
  maxItemsToRender: PropTypes.number,
  keepDataOnUnmount: PropTypes.bool
};

import React, {Component} from 'react';
import Button from '../../controls/Button/button';


require('./registerLayout.scss');

//Images
const logoImg = require('images/login-logo.png');

export class RegisterLayout extends Component{
  render() {
    return (
      <div className={this.props.formClass + ' register-layout'}>
        <div className="register-page-img-bg" tabIndex="1000"></div>
        <div className="register-body">
          <div className="register-top-gradient"></div>
          <div className="register-body-inline">
            <div className="logo"><img src={logoImg} alt="login logo"/></div>

            <form className="form" onSubmit={this.props.actionButtonOnclick}>
              {this.props.children}

              <div className='footer'>
                 <Button id='registerSubmit' bsStyle="primary" type="submit" loading={this.props.loading} onlyLoading={true} tabIndex="10">{this.props.actionButtonText}</Button>
              </div>
            </form>



            {this.props.footerComponent}
            </div>
        </div>
      </div>
    );
  }
}




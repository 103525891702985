import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize  from 'infrastructure/js/components/controls/controlsNormalizations';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import {getEnumValue, enumTypes, getLookupOptions} from '../../../../../utils/enumHelper';
import AddRemoveList from '../../../../Common/Controls/AddRemoveList/addRemoveList';
import OperationListItem from '../../../../Common/Controls/AddRemoveList/AddRemoveListItem/OperationListItem/operationListItem';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Header, {headerItemsLabels} from '../../../../Common/Header/header';
require('./stationSettingsForm.scss');

const maxLength30 = Validation.maxLength(30);

const validateOverTimeMin = (value, allValues) => {
  const minValue = {
    _hours: 0,
    _minutes: 1
  }
  const currentValue = {
    _hours: allValues.overTime_hours,
    _minutes: allValues.overTime_minutes
  }
  return Validation.timeField.min(minValue)(currentValue, allValues)
};

const validateOverTimeRequired = (value, allValues) => {
  return Validation.timeRequired('overTime')(value, allValues);
};

const StationSettingsForm  = ({
                                initialize,
                                pristine,
                                handleSubmit,
                                actions,
                                change,
                                itemToEdit,
                                sData,
                                onSave,
                                onDelete,
                              }) => {

  const labels = createLabelHelper('mat.administration.matsettings.digitalTwin.station.');

  const [stationType, setStationType] = useState(null);
  const [primaryOperations, setPrimaryOperations] = useState([]);
  const [secondaryOperations, setSecondaryOperations] = useState([]);
  const [optionsInUse, setOptionsInUse] = useState([]);
  const [currentPrimaryOperationsIds, setCurrentPrimaryOperationsIds] = useState([]);
  const [currentPreselectedOperations, setCurrentPreselectedOperations] = useState([]);
  const [isAvailableOverTime, setIsAvailableOverTime] = useState(itemToEdit?.isOvertimeEnabled || false);
  const [isResource, setIsResource] = useState(itemToEdit?.isResource || false);
  const [isJointStartTimeDisabled, setIsJointStartTimeDisabled] = useState(false);


  const typeToOpsMap = useMemo(() => {    
    return new Map([
      [ getEnumValue(enumTypes.LOCATION_TYPE)('CUT'), [getEnumValue(enumTypes.OPERATION_TYPE)('CUT')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('KIT'), [getEnumValue(enumTypes.OPERATION_TYPE)('KIT')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('CUT_KIT'), [getEnumValue(enumTypes.OPERATION_TYPE)('CUT'), getEnumValue(enumTypes.OPERATION_TYPE)('KIT')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('CURING'), [getEnumValue(enumTypes.OPERATION_TYPE)('CURING')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('GENERAL'), [] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('LAYUP'), [getEnumValue(enumTypes.OPERATION_TYPE)('LAYUP')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('DEMOLDING'), [getEnumValue(enumTypes.OPERATION_TYPE)('DEMOLDING')] ],
      [ getEnumValue(enumTypes.LOCATION_TYPE)('AFP'), [getEnumValue(enumTypes.OPERATION_TYPE)('AFP')] ],
    ]);
  },[]);

  const stationTypes = useMemo(() => getLookupOptions(enumTypes.LOCATION_TYPE, 'STATION')
                      .filter((obj) => {return obj?.value !== getEnumValue(enumTypes.LOCATION_TYPE)('PHANTOM')}), []);

  const automationTypes = useMemo(() => getLookupOptions(enumTypes.AUTOMATION_TYPE), []);

  useEffect(() => {
    initFormValues();
  }, [initFormValues, itemToEdit]);

  const getAllOperations = useCallback(() => {
    return sData?.operations ?? [];
  }, [sData?.operations]);

  const getAllPrimaryOperations = useCallback(() => {
    return sData?.allPrimaryOperations ?? [];
  }, [sData?.allPrimaryOperations]);

  const initFormValues = useCallback(() => {
    let initialValues = {
      active: true,
      stationType: getInitialStationType(),
      isResource: false,
      capacity: null,
      overTime_minutes: '',
      overTime_hours:'',
      automationType: getEnumValue(enumTypes.AUTOMATION_TYPE)('NONE'),
    };

    if (isEditMode()) {
      initialValues.active = itemToEdit.active;
      initialValues.stationName = itemToEdit.name;
      initialValues.automationType = itemToEdit.automationType;
      initialValues.capacity = itemToEdit.capacity;
      initialValues.isResource = itemToEdit.isResource;
      initialValues.jointStartTime = itemToEdit.jointStartTime;
      initialValues.overtimeEnabled = itemToEdit.isOvertimeEnabled;
      if(itemToEdit.maxOvertimeValue){
        initialValues.overTime_minutes = itemToEdit.maxOvertimeValue % 60 | 0;
        initialValues.overTime_hours = itemToEdit.maxOvertimeValue / 60 | 0;
      }
      initialValues.primaryOperations = getInitialPrimaryOperations(itemToEdit.operations);
      initialValues.secondaryOperations = getInitialSecondaryOperations(itemToEdit.operations);
    }
    initialize(initialValues);

    initOperations(itemToEdit);
  }, [getInitialPrimaryOperations, getInitialSecondaryOperations, getInitialStationType, initOperations, initialize, isEditMode, itemToEdit])

  const isEditMode = useCallback(() => {
    return itemToEdit?.id;
  }, [itemToEdit?.id]);

  const initOperations = useCallback((itemToEdit) => {
    let idsInUse = [];
    let secondaryInitialOps = [];

    let primaryOpsIds = getPrimaryOperationsIdsByStationType(getInitialStationType()) || [];

    //all operations without primary operations (for the current station type)
    let secondaryOps = getAllOperations().filter((o) => {
      return primaryOpsIds.indexOf(o.value) < 0 && o.data?.operationType !== getEnumValue(enumTypes.OPERATION_TYPE)('AFP');
    } );

    let primaryOps = getAllPrimaryOperations().filter((operation) => {
      return primaryOpsIds.indexOf(operation.value) >= 0;
    } ) || [];


    if (isEditMode() && itemToEdit.operations) {

      //current station's operations without primary operations
      secondaryInitialOps = itemToEdit.operations
        .filter((operation) => {return primaryOpsIds.indexOf(operation.id) < 0; })
        .sort((op1, op2) => {return op1.position < op2.position ? -1 : 1})

      secondaryInitialOps.forEach((item) => {
        idsInUse.push(item.id);
      } );
    }

      setOptionsInUse(idsInUse);
      setCurrentPrimaryOperationsIds(primaryOpsIds);
      setPrimaryOperations(primaryOps);
      setCurrentPreselectedOperations(secondaryInitialOps);
      setSecondaryOperations(secondaryOps);
      setStationType(getInitialStationType());
      setIsAvailableOverTime(itemToEdit?.isOvertimeEnabled);
      setIsResource(itemToEdit?.isResource);

  }, [getAllOperations, getAllPrimaryOperations, getInitialStationType, getPrimaryOperationsIdsByStationType, isEditMode])

  const getInitialStationType = useCallback(() => {
    if (isEditMode()) {
      return itemToEdit.locationType;
    }
    //by default the 'General' type is selected
    let found = stationTypes.find((item) => {return item.value === getEnumValue(enumTypes.LOCATION_TYPE)('GENERAL') });
    return found ? found.value : null;
  }, [isEditMode, itemToEdit.locationType, stationTypes]);

  const getInitialPrimaryOperations = useCallback((operations) => {
    if (operations) {

      let primaryOpsIds = getPrimaryOperationsIdsByStationType(getInitialStationType());

      let res = [];
      primaryOpsIds.forEach((po) => {
        let found  =  operations.find((o) => {
          return o.id === po;
        });
        if (found) {
          res.push({ operation: found.id})
        }
      });
      return res;
    }
    return null;
  }, [getInitialStationType, getPrimaryOperationsIdsByStationType]);

  const getInitialSecondaryOperations = useCallback((operations) => {
    if (operations) {

     let primaryOps =  getPrimaryOperationsIdsByStationType(getInitialStationType());
     let res = operations
       .filter((operation) => {return primaryOps.indexOf(operation.id) < 0})
       .sort((op1, op2) => {return op1.position < op2.position ? -1 : 1})
       .map((operation) => {return ({ operation: operation.id, }); });
      return res;
    }
    return [];
  }, [getInitialStationType, getPrimaryOperationsIdsByStationType]);

  const getOperationTypeById = (operationId) => {
    let operation = getAllOperations().find((op) => {
      return op.value === operationId
    });
    return operation ? operation.data.operationType : null;
  };

  const onSubmit = (data) => {
    let operations = [];

    //up to 2 operations will be shown as buttons (primary), others - as a kebab menu items.
    //Cut, Kit operations will be shown as Tabs
    let operationsAsButtons = 0;
    let operationPosition = 0;

    if (data.primaryOperations) {
      data.primaryOperations.forEach((op) => {
        let type = getOperationTypeById(op.operation);
        if (type !== getEnumValue(enumTypes.OPERATION_TYPE)('KIT') && type !== getEnumValue(enumTypes.OPERATION_TYPE)('CUT')) {
          operationsAsButtons++;
        }
        operations.push({operationId: op.operation, isPrimary: true, position: ++operationPosition})
      })
    }

    if (data.secondaryOperations) {
      data.secondaryOperations.forEach((op) => {
        let type = getOperationTypeById(op.operation);
        if (type === getEnumValue(enumTypes.OPERATION_TYPE)('KIT') || type === getEnumValue(enumTypes.OPERATION_TYPE)('CUT')) {
          operations.push({operationId: op.operation, isPrimary: true, position: ++operationPosition})
        }
        else {
          operationsAsButtons++;
          operations.push({operationId: op.operation, isPrimary: (operationsAsButtons < 3), position: ++operationPosition})
        }
      })
    }
    let newData = {
      automationType: data.automationType,
      name: data.stationName,
      locationType: data.stationType,
      stationOperations: operations,
      isResource: data.isResource,
      jointStartTime: data.jointStartTime,
      overtimeEnabled:  data.overtimeEnabled,
      maxOvertimeValue: DateTimeHelper.ConvertHoursMinutesToMinutes(data.overTime_hours, data.overTime_minutes),
      capacity: data.capacity ? data.capacity : null,
      parentId: itemToEdit.parentId,
      prevSiblingId: null,
      nextSiblingId: null,
    };

    if (isEditMode()) {
      newData.id = itemToEdit.id;
      newData.parentId = null;  // do not send parentId on Edit (Server side request)
    }

    actions?.viewActions?.submit(newData, isEditMode(), itemToEdit.isStation,  false)
      .then((response) => {
        if (response && response.success) {
          onSave?.(response.data);
        }
      });
  };

  const onDeleteHandler = () => {
    actions?.viewActions?.delete(itemToEdit.id).then((response) => {
      if (response && response.success) {
        onDelete?.();
      }
    });
  }

  const getPrimaryOperationsIdsByStationType = useCallback((stationType) => {
    let primaryOps = typeToOpsMap.get(stationType);
    let primaryOperationsIds = getAllOperations()
      .filter((op) => {return primaryOps?.indexOf(op.data.operationType) >= 0 })
      .map((op) => {return op.value});

    return primaryOperationsIds;
  }, [getAllOperations, typeToOpsMap]);

  const onAutomationTypeChange = (newValue, oldValue) => {
    if ( !newValue && oldValue && oldValue.value) {
      //prevent clearing
      change('automationType', oldValue.value);
    }
  }

  const onStationTypeChange = (newValue, oldValue) => {

    //prevent clearing the 'length' field when there was no change
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    let newStationType = newValue ? newValue.value : null;

    let primaryOperationsIds = getPrimaryOperationsIdsByStationType(newStationType);

    let secondaryOps = getAllOperations().filter((o) => {
      return primaryOperationsIds.indexOf(o.value) < 0 && o.data?.operationType !== getEnumValue(enumTypes.OPERATION_TYPE)('AFP');
    } );

    let curPrimaryOps = getAllPrimaryOperations().filter((operation) => {
      return primaryOperationsIds.indexOf(operation.value) >= 0;
    } );

    let primaryOps = typeToOpsMap.get(newStationType);
    let res = primaryOps?.map((po) => {
      let found  =  curPrimaryOps.find((o) => {
        return o.data.operationType === po});

      return found ? { operation: found.data.id } : null;
    });

    change('primaryOperations', res);
    change('secondaryOperations', []);


    setOptionsInUse([]);
    setCurrentPrimaryOperationsIds(primaryOperationsIds);
    setPrimaryOperations(curPrimaryOps);
    setSecondaryOperations(secondaryOps);
    setStationType(newStationType);
  };

  //----------------------

  const getOptionsInUse = (itemIndex) => {

    let idsInUse = [...optionsInUse];

    //exclude the current item's selected option form this list
    if (idsInUse.length > itemIndex) {
      let itemId = idsInUse[itemIndex];
      if (itemId) {
        idsInUse = idsInUse.filter((id) => {
          return id !== itemId;
        });
      }
    }
    return idsInUse;
  };

  const getAvailableOptions = (itemIndex) => {
    let allOptions = secondaryOperations;
    let optionsInUse2 = getOptionsInUse(itemIndex);   //????????

    return allOptions.filter((option) => {
      return optionsInUse2.indexOf(option.value) < 0;
    }).map(op => {
      if(op.data.operationType === getEnumValue(enumTypes.OPERATION_TYPE)('CUT')){
        return {
          ...op,
          label: `${op.data.displayName} (Primary tab)`
        }
      }
      return op;
    });
  };

  const onPrimaryOpChangeCallback = (value, oldValue, index) => {
    let idsInUse = [...currentPrimaryOperationsIds];

    if (oldValue) { //no oldValue on first change
      idsInUse[index] = null;
    }

    if (value) { //no value when clear the selection
      idsInUse[index] = value.value;
    }

    setCurrentPrimaryOperationsIds(idsInUse);
  };

  const onChangeCallback = (value, oldValue, index) => {
    let idsInUse = [...optionsInUse];

    if (oldValue) { //no oldValue on first change
      idsInUse[index] = null;
    }

    if (value) { //no value when clear the selection
      idsInUse[index] = value.value;
    }

    setOptionsInUse(idsInUse);
  };

  const onRemoveCallback = (index) => {
    let idsInUse = [...optionsInUse];

    if (index > -1) {
      idsInUse.splice(index, 1);
    }

    setOptionsInUse(idsInUse);
  };

  const onAddCallback = () => {
    let idsInUse = [...optionsInUse];

    idsInUse.push(null); //hold index in optionsInUse for the new added item

    setOptionsInUse(idsInUse);
  };


  //-----------------------

  const listItemRenderer = (data) => {
    return (
      <OperationListItem
        id={data.name}
        name={data.name}
        index={data.index}
        item={data.item}
        onChangeCallback={onChangeCallback}
        options={getAvailableOptions(data.index)}
        stationType={stationType}
        getOperationTypeById={getOperationTypeById}
        labels={labels}
      />
    )
  };

  const renderPrimaryOperations = () => {
    if (stationType === getEnumValue(enumTypes.LOCATION_TYPE)('GENERAL')) {
      return null;
    }
    let primeOps = typeToOpsMap.get(stationType);

    //todo - replace operationName with operationType after lookup service is in
    let res = primeOps?.map((op, index)=> {

      const options = primaryOperations.filter((o) => {
        return o.data.operationType === op
      }).map(primaryOp => {
        if(stationType === getEnumValue(enumTypes.LOCATION_TYPE)('CUT_KIT') ||
           stationType === getEnumValue(enumTypes.LOCATION_TYPE)('CUT') ||
           stationType === getEnumValue(enumTypes.LOCATION_TYPE)('KIT') ) {
          return {
            ...primaryOp,
            label: `${primaryOp.data.displayName} (Primary tab)`
          }
        }
        return primaryOp;
      })

      return (
        <OperationListItem name={'primaryOperations.[' + index + ']'}
                           id={'primaryOperations.[' + index + ']'}
                           key={'primaryOperation'+ index}
                           index={index}
                           options={options}
                           onChangeCallback={onPrimaryOpChangeCallback}
        />
      )

    });

    return <div className="primary-operations-list">{res}</div>
  };

  const onIsResourceCheckboxClick = (event)=>{
    setIsResource(event.target.checked);
  };

  const renderOperationSection = () => {
    return(
      <div>
        <div className="list-operations-title input-section">
          <label className="column">{labels.get('operations')}</label>
        </div>

        {renderPrimaryOperations()}

        <AddRemoveList name='secondaryOperations'
                       id='secondaryOperations'
                       itemRenderer={listItemRenderer}
                       preSelectedItems={isEditMode() ? currentPreselectedOperations : []}
                       onRemoveCallback={onRemoveCallback}
                       onAddCallback={onAddCallback}
                       addButtonLabel={labels.get('addoperation')}
        />
      </div>
    )
  };
  const allowOverTimeClick = (event) => {
    setIsAvailableOverTime(event?.target?.checked);
    change('overTime_hours', '');
    change('overTime_minutes', '');
  };

  const renderSchedulerDataSection = () => {
    if (PermissionManager.getOrgPreferences().schedulerEnabled) {
      return (
        <>
        <div className='scheduler-data-section'>
              <InputSection label={labels.get('capacity')} htmlFor="capacity" >
                <TextField id="capacity" name="capacity" className="short-textfield"
                           normalize={Normalize.number(true, 1, 100)}
                />
              </InputSection>
              <InputSection htmlFor="isResource" label={' '}>
                <Checkbox name="isResource" id="isResource"  label={labels.get('isResource')} onClick={onIsResourceCheckboxClick} />
              </InputSection>
              <InputSection htmlFor="jointStartTime" label={' '}>
                {isResource && <Checkbox name="jointStartTime" id="jointStartTime"  label={labels.get('jointStartTimes')} disabled={isJointStartTimeDisabled} />}
              </InputSection>
          </div>


          <div className='allow-time-section'>
            <InputSection htmlFor="overtimeEnabled" label={' '}>
              <Checkbox onClick={allowOverTimeClick} name="overtimeEnabled" id="overtimeEnabled"  label={labels.get('allowOverTime')}  />
            </InputSection>
            <InputSection label={' '} htmlFor="overTime" >
              <TimeField id="overTime" name="overTime"
                         disabled={!isAvailableOverTime}
                         validate={isAvailableOverTime ? [validateOverTimeRequired, validateOverTimeMin] : undefined}
                         hoursCount={true} maxHoursLength={2}/>
            </InputSection>

            <InputSection label={' '}  />
          </div>
        </>);
    }
    return null;
  };

  useEffect(() => {
    const operations = getAllOperations();
    const operationIdsInUse = [...optionsInUse, ...currentPrimaryOperationsIds];
  
    // check if any operation with manHours === true exists in operations that are in use.
    const operationWithManhoursSelected = operations.some(operation => {
      return operation?.data?.manHours && operationIdsInUse.includes(operation?.data?.id)
    });
  
    if(operationWithManhoursSelected){
      change('jointStartTime', false);
    }
      
    setIsJointStartTimeDisabled(operationWithManhoursSelected)

  }, [currentPrimaryOperationsIds, getAllOperations, optionsInUse]);

  const getHeaderItems = () => {
    return {
      title: isEditMode() ? labels.get('header.title.edit') : labels.get('header.title.create'),
      buttons: [
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: pristine,
          action: handleSubmit(onSubmit),
        },
        ...(isEditMode() ? [
          {
            id: 'delete',
            label: headerItemsLabels.DELETE,
            className: 'no-icon',
            action: onDeleteHandler
          }]: []),
      ]
    }
  };


    let headerItems = getHeaderItems();

    return (
      <div className='station-settings-form'>

        <Header {...headerItems}/>

        <div className="station-section">
          <InputSection label={labels.get('stationname')+'*'} htmlFor="stationName" className="">
            <TextField id="stationName" name="stationName" className="short-textfield"
                       maxLength={30}
                       validate={[Validation.required, maxLength30]}
            />
          </InputSection>

          <InputSection label={labels.get('stationtype')+'*'} htmlFor="stationType" className="">
            <Combobox id="stationType" name="stationType"
                      options={stationTypes}
                      onChangeCallback={onStationTypeChange}
                      parse={Parse.comboValueOnly()}
                      format={Format.findOptionByValue(stationTypes)}
                      validate={Validation.dropdown.required}
            />
          </InputSection>

          <InputSection label={labels.get('applyAutomation')} htmlFor="automationType" className="">
            <Dropdown id="automationType" name="automationType"
                      onChangeCallback={onAutomationTypeChange}
                      parse={Parse.comboValueOnly()}
                      format={Format.findOptionByValue(automationTypes)}
                      placeholder={'Select Automation'}
                      options={automationTypes}/>
          </InputSection>
        </div>

        {renderSchedulerDataSection()}

        {renderOperationSection()}

      </div>
    );
}

StationSettingsForm.propTypes = {
  itemToEdit : PropTypes.object,
  sData: PropTypes.object,
  actions : PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  change: PropTypes.func,
  pristine: PropTypes.bool,

  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

StationSettingsForm.defaultProps = {
  itemToEdit: null,
  sData : {},
  handleSubmit: () => {
    console.error('handleSubmit is missing!');
  },
  initialize: () => {
    console.error('initialize is missing!');
  },
  change: () => {
    console.error('change is missing!');
  },
  pristine: true,
  onSave: () => {},
  onDelete: () => {},
};

export default reduxForm({
    form: 'stationSettingsForm',
  }
)(StationSettingsForm);








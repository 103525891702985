
const charCodeOfA = 'A'.charCodeAt(0);
const alphabetLength = 'Z'.charCodeAt(0) - charCodeOfA + 1;

export function numberToLetters(nNum) {
  if (nNum <= alphabetLength) {
    return convertNumberToLetter(nNum);
  }
  else {
    let firstNumber = Math.floor((nNum - 1) / alphabetLength);
    let firstLetter = convertNumberToLetter(firstNumber);

    let secondNumber = (nNum % alphabetLength) || alphabetLength;
    let secondLetter = convertNumberToLetter(secondNumber);

    return firstLetter + secondLetter;
  }
}

function convertNumberToLetter(nNum) {
  let charCode = charCodeOfA + nNum - 1;
  return String.fromCharCode(charCode);
}

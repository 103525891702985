import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as psManagementPageActions }  from '../../../actions/Administration/PsManagementPage/psManagementPageActions.js';
import PsManagementPage from '../../../components/Administration/PsManagementPage/psManagementPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(psManagementPageActions, dispatch)
  }
}

let PsManagementPageContainer = connect(
  (state) => {
  	return {
      sData: state.administration.get('psManagementPage'),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(PsManagementPage);


export default PsManagementPageContainer;




import React from 'react';
import {reduxForm} from 'redux-form';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {gridsNames} from '../../../../enums/gridsNames';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import MoreInfoCell from '../../../Common/CustomGridCells/MoreInfoCell/moreInfoCell.js';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {navigationStates} from '../../../../enums/navigationStates';
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import GridHelper from '../../../../../../infrastructure/js/components/Grid/Utils/gridHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
require('./archivedKitsGrid.scss');

class ArchivedKitsGrid extends React.PureComponent {

  filterConfig = [
      {fieldName: 'assetStatus', filterName: 'assetStatus', getOptions: true},
      {fieldName: 'kitType.businessId', filterName: 'kitType', getOptions: true},
      {fieldName: 'businessId', filterName: 'kitBusinessId', getOptions: false},
      {fieldName: 'moreInfo'},
      {fieldName: 'alertStatus', filterName: 'assetAlert'},
      {fieldName: '', filterName: 'kitWorkOrder'},
      {fieldName: '', filterName: 'kitPart'},
      {fieldName: '', filterName: 'kitProject'},
      {fieldName: 'lastStatusChangeDate.epochDateTime', filterName: 'lastStatusChangeDate'}
    ];

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.filterLabels = createLabelHelper('mat.grid.columns.moreInfo.filter.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.DUE_DATE, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          sort: 'desc',
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              dueDateLeft: params.data.dueDateLeft
            };
          }
        },
      },
      {
        fieldName: 'assetStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetStatus',
        valueFormatter: (params) => {
          return (params.data.assetStatusLabel || '');
        },
        columnOptions:{
          valueGetter: (params) => {
            return (params.data.assetStatus || '');
          },
        }
      },
      {
        fieldName: 'lastStatusChangeDate.epochDateTime',
        title: this.labels.get('columns.archivingDate.title'),
        filterType: filterTypes.NONE,
        valueFormatter: (params) => {
          return (params.data && params.data.m_archivingDate ? params.data.m_archivingDate : '');
        },
        columnOptions:{
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon()),
          valueGetter: (params) => {
            return params.data.lastStatusChangeDate?.epochDateTime;
          },
        }
      },
      {
        fieldName: 'kitType.businessId',
        title: this.labels.get('columns.kitType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.kitType?.businessId || '';
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.kitId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'kitBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.KIT_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ARCHIVED],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
                id: params.data.id,
                type: navigationStates.KIT,
                label:  params.data.businessId
              };
          }
        }
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.moreInfo.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        filterAlignment: filterAlignmentTypes.RIGHT,
        propertyTypes: this.getMoreInfoProperties(),
        columnOptions: {
          cellComponent: MoreInfoCell,
          sortable: false,
          valueGetter: (params) => {
            return {
              part: params.data.partType ? params.data.partType.businessId : null,
              project: params.data.project ? params.data.project.businessId : null,
              workOrder: params.data.workOrder ? params.data.workOrder.businessId : null,
              workOrderId: params.data.workOrder ? params.data.workOrder.id : null
            };
          }
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change,
      }
    ]
  }

  getMoreInfoProperties() {
    return [
      {value: 'kitWorkOrder', label: this.filterLabels.get('workOrder') || 'Work Order'},
      {value: 'kitPart', label: this.filterLabels.get('partType') || 'Part Type'},
      {value: 'kitProject', label: this.filterLabels.get('project') || 'Project'},

    ];
  }

  getRowHeight() {
    return 128;
  }

  render() {
    return (
      <div className="archive-kits-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ARCHIVE_KITS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}

              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'ArchiveKitsGrid'
  }
)(ArchivedKitsGrid);

import { api as navigatorApi } from '../../AppTopbar/navigatorActions.js';
import {navigationStates} from '../../../enums/navigationStates';
import { api as organizationGridApi} from './demoOrganizationsGridActions.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

export let jsxActions = {};

jsxActions.init = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    if (shouldRefreshGrid) {
      organizationGridApi.reload(dispatch, getState)();
    } 
    api.setTopNavigator(dispatch, getState)();
  }
};


export let api = {};

api.setTopNavigator = function(dispatch, getState) {
  return function() {

    let topNavigatorData = {
      name: createLabelHelper('mat.header.navigator.administration.').get('demoOrgsManagement'),
      type: navigationStates.DEMO_ORG_MANAGEMENT,
      id: 0,
      components: []
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);

  };
};


import React from 'react';
import {isKit, isRoll, isTool, isGroup, isSpool, isPart} from '../../../../utils/assetHelper';
import RollDetails from './RollDetails/rollDetails';
import KitDetails  from './KitDetails/kitDetails';
import ToolDetails  from './ToolDetails/toolDetails';
import GroupDetails  from './GroupDetails/groupDetails';
import SpoolDetails from './SpoolDetails/spoolDetails';
import PartDetails from './PartDetails/partDetails';

require('./assetDetails.scss');

export default class AssetDetails extends React.PureComponent {
  componentWillMount() {
    this.props.actions.assetEditorActions.fetchAllDataForEditor(this.props.assetType.toUpperCase());
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps?.assetType || prevProps.assetType !== this.props.assetType) this.props.actions.assetEditorActions.fetchAllDataForEditor(this.props.assetType.toUpperCase());
  }

  renderRollDetails(asset) {
    if (asset && isRoll(asset.objectType)) {
      return <RollDetails asset={asset} actions={this.props.actions} assetEditorData={this.props.assetEditorData} loggedInUser={this.props.loggedInUser} />;
    }
    return null;
  }

  renderSpoolDetails(asset) {
    if (asset && isSpool(asset.objectType)) {
      return <SpoolDetails asset={asset} actions={this.props.actions} assetEditorData={this.props.assetEditorData} loggedInUser={this.props.loggedInUser} />;
    }
    return null;
  }

  renderKitDetails(asset) {
    if (asset && isKit(asset.objectType)) {
      return <KitDetails asset={asset} actions={this.props.actions} assetEditorData={this.props.assetEditorData} />;
    }
    return null;
  }
  renderPartDetails(asset) {
    if (asset && isPart(asset.objectType)) {
      return (
        <PartDetails  asset={asset} actions={this.props.actions} assetEditorData={this.props.assetEditorData} />
      );
    }
    return null;
  }
  renderToolDetails(asset) {
    if (asset && isTool(asset.objectType)) {
      return <ToolDetails asset={asset} actions={this.props.actions} assetEditorData={this.props.assetEditorData} />;
    }
    return null;
  }

  renderGroupDetails(asset) {
    if (asset && isGroup(asset.objectType)) {
      return <GroupDetails asset={asset} actions={this.props.actions} assetEditorData={this.props.assetEditorData} />;
    }
    return null;
  }

  render() {
    let { asset } = this.props;
    if (!asset || !asset.id) {
      return null;
    }

    return (
      <div className="asset-details">
        {/*<div className="asset-details-section">*/}
        {this.renderRollDetails(asset)}
        {this.renderSpoolDetails(asset)}
        {this.renderKitDetails(asset)}
        {this.renderPartDetails(asset)}
        {this.renderToolDetails(asset)}
        {this.renderGroupDetails(asset)}
        {/*</div>*/}
      </div>
    );
  }
}

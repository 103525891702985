import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as importWizardActions } from '../../../actions/Wizards/ImportFiles/importFilesWizardActions';
import ImportFilesWizard from '../../../components/Wizards/ImportFiles/importFilesWizard';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(importWizardActions, dispatch)
    }
  }
}


let ImportFilesWizardContainer = connect(
  (state) => {
    return {
      importTypes: state.importFiles.get('importTypes'),
      acceptFileTypes:state.importFiles.get('acceptFileTypes'),
      shouldIncludeLocation:state.importFiles.get('shouldIncludeLocation'),
      showPrintLabels:state.importFiles.get('showPrintLabels'),
      loading:state.importFiles.get('loading'),
      sErrors: state.importFiles.get('errors'),
      sPrintLabelsErrors: state.importFiles.get('printLabelsErrors'),
      sValidatedFileData: state.importFiles.get('validatedFileData'),
      sFileToken:state.importFiles.get('fileToken'),
      sSelectedAseetType:state.importFiles.get('selectedAssetType'),
      sSelectedFile: state.importFiles.get('selectedFile'),
      currentDate:state.importFiles.get('currentDate'),
      parentComponent:state.importFiles.get('parentComponent')
    };
  },
  mapDispatchToProps
)(ImportFilesWizard);

export default ImportFilesWizardContainer;



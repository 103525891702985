import {Map} from 'immutable';
import { actionTypes as securityMessageDialogActionsTypes } from '../../../actions/Dialogs/securityMessageDialogActions.js';

let defaultState = {
  show : false,
  loading : false,
  userData: null,
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {

    case securityMessageDialogActionsTypes.SECURITY_MESSAGE_DIALOG_HIDE:
      return Map(defaultState);

    case securityMessageDialogActionsTypes.SECURITY_MESSAGE_DIALOG_SHOW:
      state = state.set('userData', action.payload);
      state = state.set('show', true);
    return state;

    default:
      return state;
  }
}






import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import { jsxActions as commonPdfLabelSettingsViewActions } from '../../../../actions/Administration/SettingsPage/commonPdfLabelSettingsViewActions';
import { jsxActions as settingsPageActions } from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import GroupPdfLabelSettingsView from '../../../../components/Administration/SettingsPage/PdfLabelSettings/GroupPdfLabelSettingsView/groupPdfLabelSettingsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonPdfLabelSettingsViewActions, dispatch),
      settingsPageActions: bindActionCreators(settingsPageActions, dispatch),
    },
  };
}

let GroupPdfLabelSettingsViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonPdfLabelSettingsView']),
      isDirty: isDirty('pdfLabelSettingsForm')(state),
    };
  },

  mapDispatchToProps
)(GroupPdfLabelSettingsView);

export default GroupPdfLabelSettingsViewContainer;

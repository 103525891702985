import { Map, List } from 'immutable';

import { actionTypes as CancelKitReportDialogActionTypes }  from '../../actions/LocationPage/KittingView/cancelKitReportDialogActions';


let defaultState = {
  show: false,
  loading: false,
  kitIds: List()
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    // Show dialog.
    case CancelKitReportDialogActionTypes.CUTKITSTATION_CANCELKITREPORTDIALOG_SHOW:
      state = state.set('kitIds', List(action.payload));
      return state.set('show', true);

    // Hide dialog.
    case CancelKitReportDialogActionTypes.CUTKITSTATION_CANCELKITREPORTDIALOG_HIDE:
      return state.set('show', false);

    // Submit to server started.
    case CancelKitReportDialogActionTypes.CUTKITSTATION_CANCELKITREPORTDIALOG_CANCEL_KIT_REPORT_IN_PROGRESS:
      state = state.set('loading', true);
      return state;


    // Submit to server finished.
    case CancelKitReportDialogActionTypes.CUTKITSTATION_CANCELKITREPORTDIALOG_CANCEL_KIT_REPORT_FINISHED:
      state = state.set('loading', false);
      state = state.set('show', false);
      return state;


    default:
      return state;
  }
}




import React from 'react';
import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import { navigationStates } from '../../../../enums/navigationStates';
import { filterTypes } from 'infrastructure/js/enums/filterTypes.js';
import { gridsNames } from '../../../../enums/gridsNames';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';
import { EntityPropertyTypes } from '../../../../enums/entityPropertyTypes';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import { filterAlignmentTypes } from 'infrastructure/js/enums/filterAlignmentTypes';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';

require('./activePartsGrid.scss');

class ActivePartsGrid extends React.PureComponent {
  filterConfig = [
    { fieldName: 'businessId', filterName: 'partBusinessId', getOptions: false },
    { fieldName: 'partType.businessId', filterName: 'partTypeBusinessId', getOptions: true },
    { fieldName: 'partStatus', filterName: 'partStatus', getOptions: true },
    { fieldName: 'location', filterName: 'assetLocationName', getOptions: true },
    { fieldName: 'workOrder.businessId', filterName: 'woBusinessId', getOptions: true },
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.allLabels = createLabelHelper('');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'partType.businessId',
        title: this.labels.get('columns.partType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'partTypeBusinessId',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.partId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'partBusinessId',
        // width: 170,
        fetchConfig: {
          entityType: EntityPropertyTypes.PART_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE],
          action: this.props.actions.fetchEntities,
        },
        columnOptions: {
          resizable: true,
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              type: navigationStates.PART,
              label: params.data.businessId,
            };
          },
        },
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetLocationName',
        // width: 170,
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.locationId,
              type: navigationStates.LOCATION,
              label: params.data.locationName,
            };
          },
        },
      },
      {
        fieldName: 'partStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'partStatus',
        // width: 100,
        columnOptions: {
          valueGetter: (params) => {
            return params.data.partStatus ? this.allLabels.get(params.data.partStatusDisplayKey) : '';
          },
        },
      },
      {
        fieldName: 'workOrder.businessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'woBusinessId',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
    ];
  };

  render() {
    return (
      <div className="active-parts-grid">
        <Grid
          gridName={gridsNames.PREDEFINED_LIST_ACTIVE_PARTS}
          columnsConfig={this.columnsConfig}
          actions={this.props.actions}
          filterConfig={this.filterConfig}
        ></Grid>
      </div>
    );
  }
}

export default reduxForm({
  form: 'ActivePartsGridForm',
})(ActivePartsGrid);

import LanguageService from 'infrastructure/js/services/LanguageService.js';

// //todo: convert to new convention.
// export function UpdateLocalization(localizeObj) {
//   localStorage["Messages"] = JSON.stringify( localizeObj);
//   return updateIntl(localizeObj);
// }
//
// export let api = {};
//
// api.updateLocalization = UpdateLocalization;





/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  UPDATE: '@@intl/UPDATE',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  updateIntl: function ({ locale, formats, messages }) {
    return { type: actionTypes.UPDATE, payload: { locale, formats, messages } }
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.updateIntlForPreviewMode = function(dispatch, getState) {
  return function() {

    let previewMode =  sessionStorage.getItem('previewMode');
    let localeId =  sessionStorage.getItem('localeId');

    if (previewMode && localeId) {

      LanguageService.getAllLabelsForPreview({localeId}).then((response) => {
        let messagesObject = {locale: 'en-US', messages: response.map};
        dispatch(actions.updateIntl(messagesObject));
      });


      //Async API
      // LanguageService.getAllLabelsForPreview({localeId}).then((response) => {
      //   if (response && response.success) {
      //     let messagesObject = {locale: 'en', messages: response.map};
      //     dispatch(updateIntl(messagesObject));
      //   }
      // });
    }
  }
};


api.updateIntlFromLocalStorage = function(dispatch, getState) {
  return function() {

    /////////////////////////////////////////////////////////////
    // This one is called from main app index constructor,
    // so there's no need to reload the page.
    //
    // Required for page refreshes, because labels are fetched
    // from the server only at Login.
    //

    if (!(localStorage["Messages"])) {
      return;
    }

    // Load from local storage.
    let messagesObjectFromLocalStorage = JSON.parse(localStorage["Messages"]);

    // Update React-Intl (redux store)
    dispatch(actions.updateIntl(messagesObjectFromLocalStorage));

  }
};

api.updateLocalization = function(dispatch, getState) {
  return function(localizeObj) {

    // Update local storage.
    let newMessagesObjectJson = JSON.stringify(localizeObj);
    localStorage["Messages"] = newMessagesObjectJson;

    // Update React-Intl (redux store)
    dispatch(actions.updateIntl(localizeObj));

  }
};

// api.updateLocalizationWithSmartReload = function(dispatch, getState) {
//   return function(localizeObj) {
//
//     ////////////////////////////////////////////////
//     // This one is a consequence of successful
//     // labels retrieval from server.
//     //
//     // Because the labels call is asynchronous, and lots of components
//     // are constructed and mounted before we get data from server,
//     // we are required to force refresh the whole app for the
//     // labels to take place properly.
//     //
//
//
//     try {
//
//       // Detect if the messages changed.
//       let oldMessagesObjectJson = localStorage["Messages"];
//       let newMessagesObject = localizeObj;
//       let newMessagesObjectJson = JSON.stringify(newMessagesObject);
//
//       // Deep compare.
//       let areDifferent = (oldMessagesObjectJson !== newMessagesObjectJson);
//
//
//       // Update local storage.
//       localStorage["Messages"] = newMessagesObjectJson;
//
//       // Update React-Intl (redux store)
//       dispatch(updateIntl(localizeObj));
//
//
//       // Reload window to force refresh all labels.
//       if (areDifferent) {
//         window.setTimeout(() => {
//           window.location.reload();
//         }, 50);
//       }
//
//     }
//     catch (ex) {
//       console.error('Failed to reload page.', ex);
//     }
//
//   }
// };




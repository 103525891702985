import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm, initialize} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';

require('./createGroupTypeDialog.scss');

class CreateGroupTypeDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.creategrouptype.');

    this.itemToEdit = props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;
  }

  componentDidMount() {
    let initialValues = {active: true};

    if (this.isEditMode) {
      initialValues = {
        active: this.itemToEdit.active,
        groupCode: this.itemToEdit.businessId,
        groupName: {value: this.itemToEdit.id, label: this.itemToEdit.description, data: this.itemToEdit},
      };
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  getGroupTypes() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.groupTypes ? dialogData.groupTypes : [];
  }


  onSubmit = (data) => {
    let newData = {
      businessId: data.groupCode,
      description: data.groupName?.label || '',
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    }
    else{
      newData.active = data.active;
    }

    this.props.actions.submit(newData, null, this.props.reloadParentComponent, {isEditMode: this.isEditMode});
  };

  onHide =() => {
    this.props.actions.hide();
  };

  maxLength30 = Validation.maxLength(30);
  maxLengthDropDown = Validation.dropdown.maxLength(50);

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-part-type-dialog"
        className="create-part-type-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection htmlFor="active" className="inline two-columns no-margin">
          <Checkbox name="active" id="active"  label={this.labels.get('active')} disabled={this.isEditMode}/>
        </InputSection>

        <InputSection label={this.labels.get('groupCode')+'*'} htmlFor="groupCode" className="inline left-side">
          <TextField id="groupCode" name="groupCode" className="short-textfield"
                     validate={[Validation.required, this.maxLength30]}
          />
        </InputSection>

        <InputSection label={this.labels.get('groupName')+'*'} htmlFor="groupName" className="inline right-side">
          <Combobox id="groupName" name="groupName" options={this.getGroupTypes()}
                    allowNewOption={true}
                    //parse={Parse.comboValueOnly()}
                    //format={Format.findOptionByValue(this.getParts())}
                    validate={[Validation.dropdown.required, this.maxLengthDropDown]}
          />
        </InputSection>
      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'createGroupTypeDialog',
  }
)(CreateGroupTypeDialog);

CreateGroupTypeDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};




import React, {Component} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Input from '../Input/input';
import MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import PL_ComboBox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import PL_ControlledComboBox from '../Combobox/controlledCombobox';

require('./multiSelectFieldAsync.scss');

export default class MultiSelectFieldAsync extends React.PureComponent {

  constructor(props) {
    super(props);

    this.callback = null;

    this.state = {isLoading: false};
  }

  componentDidMount() {
    if (this.props.useSimpleInput && this.props.simpleInputAutoLoad) {
      this.doFetch('');
    }
  }


  debounce = (func, wait, immediate) => {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  startFetch = (config, callback) => {
    this.props.fetchConfig.action(config, callback);

    if (this.props.onFetchStartCallback) {
      this.props.onFetchStartCallback();
    }
  };

  fetchOptions = this.debounce(this.startFetch, 600);


  onSimpleInputChangeHandler = (e) => {
    this.doFetch(e.target.value);
    if (this.props.onInputChangeCallback) {
      this.props.onInputChangeCallback(e); //???
    }
  };

  onKeyPressHandler = (e) => {
    if (this.props.onKeyPressCallback) {
      this.props.onKeyPressCallback(e);
    }
  };

  onKeyDownHandler = (e) => {
    if (this.props.onKeyDownCallback) {
      this.props.onKeyDownCallback(e);
    }
  };

  onInputChangeHandler = (val) => {
    if (this.props.onInputChangeCallback) {
      this.props.onInputChangeCallback(val);
    }
  };

  loadOptionsHandler = (input, callback) => {
    this.doFetch(input, callback);
  };

  doFetch = (input, callback) => {
    if (this.props.useSimpleInput) {
      this.setState({isLoading: true});
    }

    this.callback = callback;
    this.callbackId = new Date().getTime();

    let config = {
      input: input,
      entityType: this.props.fetchConfig.entityType,
      entityFilter: this.props.fetchConfig.filter,
      searchBy: this.props.fetchConfig.searchBy,
      maxPageSize: this.props.fetchConfig.maxPageSize,
      callbackId: this.callbackId
    };

    this.fetchOptions(config, this.fetchCompleteCallback);
  };


  fetchCompleteCallback = (fetchedData) => {
    if (this.callbackId !== fetchedData.callbackId) {
      return;
    }

    if (this.callback) {
      // do not pass the fetched options into combobox callback when hasCustomMenuRenderer is true;
      // pass them to the parent component (by onFetchCompleteCallback)
      if (this.props.hasCustomMenuRenderer) {
        //complete fetching
        this.callback(null, {options: []});
      }
      else {
        if (fetchedData && fetchedData.options) {
          // this.callback(null, {options: fetchedData.options});
          this.callback(this.filterOptions(fetchedData.options));
        }
      }
    }

    if (this.props.useSimpleInput) {
      this.setState({isLoading: false});
    }

    if (this.props.onFetchCompleteCallback) {
      this.props.onFetchCompleteCallback(fetchedData);
    }
  };

  filterOptions = (options) => {
    let { idsInUse } = this.props;
    if (idsInUse) {
      options = options.filter((option) => { return !idsInUse.includes(option.value); })
    }
    return options;
  }

  getLoading(){
    if(this.props.useSimpleInput && this.state.isLoading ){
      return (<i className="loading fa fa-spinner fa-spin fa-fw" aria-hidden="true" />);
    }
    return null;
  }

   render() {
     if (this.props.useSimpleInput) {
       return (
         <div className="simple-input">
           <Input
             {...this.props}
             onChange={this.onSimpleInputChangeHandler}
             onKeyPress={this.onKeyPressHandler }
             value={this.props.value}
             classIcon="search"
             type='text'
           />
          {this.getLoading()}
         </div>
       )
     }

    let InnerComponent = this.props.isMulti ? MultiSelectField :
      (undefined === this.props.selectedValue) ? PL_ComboBox : PL_ControlledComboBox;
      //undefined = unprovided, null = empty
    let closeMenuOnSelect = !this.props.isMulti;
    return (
      <InnerComponent
        cache={false}
        //onBlurResetsInput={true} //removed
        {...this.props}
        loadOptions={this.loadOptionsHandler}
        asyncMode={true}
        closeMenuOnSelect={closeMenuOnSelect}
        onSelectResetsInput={false}
        onInputChange={this.onInputChangeHandler}
        onKeyDown={this.onKeyDownHandler}
        // do not show the options dropdown menu of combobox when hasCustomMenuRenderer is true;
        // parent component will renderer its custom options dropdown menu.
        //dropdownMenuClassName={classNames({'hide-select-menu-outer': this.props.hasCustomMenuRenderer})}
        className={classNames({'hide-select-menu-outer': this.props.hasCustomMenuRenderer}, this.props.className)}

      />
    );
  }
}

MultiSelectFieldAsync.defaultProps = {
  hasCustomMenuRenderer: false,
  useSimpleInput: false,
  simpleInputAutoLoad: true,
};
MultiSelectFieldAsync.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fetchConfig: PropTypes.object.isRequired,
  onFetchCompleteCallback: PropTypes.func,
  hasCustomMenuRenderer: PropTypes.bool,
  useSimpleInput: PropTypes.bool,
  simpleInputAutoLoad: PropTypes.bool,
  idsInUse: PropTypes.arrayOf(PropTypes.number), // only used if !hasCustomMenuRenderer
};


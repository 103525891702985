import { Map } from 'immutable';

import { default as topNavigatorReducer } from './topNavigatorReducer';
import { default as notificationsMenuReducer } from '../Notifications/notificationsReducer';

let defaultState = Map({
  data: Map({
    // AppTopbar common data goes here.
  })
});



export default function(state = defaultState, action) {

  state = state.set('topNavigatorData', topNavigatorReducer(state.get('topNavigatorData'), action));
  state = state.set('notificationsData', notificationsMenuReducer(state.get('notificationsData'), action));


  switch(action.type) {

    default:
      return state;

  }

}

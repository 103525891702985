import React from 'react';

import { Prompt } from 'react-router-dom';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {headerItemsLabels} from '../../../../Common/Header/header';
import {reduxForm} from 'redux-form';
import RfidHeader from '../../Common/rfidHeader';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import {RfidSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import Overlay from 'infrastructure/js/components/Overlay/overlay';


require('./workflowsFiltersView.scss');

class WorkflowsFiltersView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
    };
    this.labels = createLabelHelper('mat.administration.rfidsettings.filtersparameters.workflows.view.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_WORKFLOWS_FILTERS);
    this.props.actions.init();
    this.initFormValues();
  }

  // componentWillMount() { //moved to componentDidMount()
  //   this.props.actions.init();
  // }

  componentDidUpdate(prevProps) {
    let data = this.props.sData.get('filtersData');
    // allows to update values returning from async process and prevent init data while typing
    if (data !== prevProps.sData.get('filtersData')) {
      this.initFormValues();
    }
  }

  initFormValues = () => {
    let data = this.props.sData.get('filtersData');
    if (!data) {
      return;
    }
    let initialValue = {
      automatedWorkflowsAlertFilterSec: data.automatedWorkflowsAlertFilterSec,
      enableCheckbox: data.enabled,
      deviceAppDropDown: this.props.sData.get('selectedDeviceApp'),
      id: data.id
    };
    // this.state.isEnabled = data.enabled;
    this.setState({isEnabled: data.enabled});
    this.props.initialize(initialValue);
  }

  getHeaderItems() {
    return {
      dropdown: {
        name: 'deviceAppDropDown',
        id: 'deviceAppDropDown',
        options: this.props.sData.get('deviceAppsOptions'),
        disabled: this.props.sData.get('deviceAppsOptions').length <= 1,
        changeCallback: this.props.actions.onDeviceAppChanged,
      },
      buttons: [
        {
          id:'save',
          className: 'no-icon',
          label: headerItemsLabels.SAVE,
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.props.pristine || !this.props.sData.get('selectedDeviceApp'))
        }
      ],
    };
  }

  onSubmit = (data) => {
    let query = {
      automatedWorkflowsAlertFilterSec: data.automatedWorkflowsAlertFilterSec,
      enabled: data.enableCheckbox,
      id: data.id
    };
    this.props.actions.header.onSaveWorkflowsFiltersClick(query)
  }

  renderLoadingOverlay = () => {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

  onWorkflowFiltersCheckboxClick = () => {
    this.setState({isEnabled: !this.state.isEnabled});
  }

  renderPrompt = () => {
    return (
      <Prompt
        when={!this.props.pristine}
        message={location =>
          this.navigationLabels.get('datanotsaved.text')
        }
      />
    );
  }

  renderWorkflowFiltersSection(isDisabled) {
    return (
      <React.Fragment>
        <div className="filters-view-enable-section">
          <label htmlFor="enableCheckbox">
            <Checkbox name="enableCheckbox" id="enableCheckbox" onClick={this.onWorkflowFiltersCheckboxClick}
                      disabled={isDisabled}/>
            <span>{this.labels.get('enableWorkflowFilter')}</span>
          </label>
        </div>
        <div className="filters-view-parameters-section">

          {<InputSection label={this.labels.get('automatedWorkflowsAlertFilter') + '*'}
                         htmlFor="automatedWorkflowsAlertFilterSec"
                         className="inline" forceLabelOverflow>
            <TextField id="automatedWorkflowsAlertFilterSec" name="automatedWorkflowsAlertFilterSec"
                       validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}
                       className="short-textfield" disabled={isDisabled || !this.state.isEnabled}/>
          </InputSection>}
        </div>
      </React.Fragment>
    );
  }


  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    let isDisabledField = !this.props.sData.get('selectedDeviceApp');
    return (
      <div className="administration-view filters-parameters-view">
        {this.renderPrompt()}
        <div className="title">{title}</div>

        {this.renderLoadingOverlay()}

        <RfidHeader {...headerItems} change={this.props.change} selectedDeviceApp={this.props.sData.get('selectedDeviceApp')}/>

        {this.renderWorkflowFiltersSection(isDisabledField)}
      </div>
    );
  }
}

export default reduxForm({
    form: 'FiltersParametersForm'
  }
)(WorkflowsFiltersView);

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as dashboardService from '../../../../services/Reports/dashboardService.js';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
   EDIT_DASHBOARD_DEMO_DATA_DIALOG_SHOW:               ' EDIT_DASHBOARD_DEMO_DATA_DIALOG_SHOW',
   EDIT_DASHBOARD_DEMO_DATA_DIALOG_HIDE:               ' EDIT_DASHBOARD_DEMO_DATA_DIALOG_HIDE',
   EDIT_DASHBOARD_DEMO_DATA_DIALOG_SUBMIT_IN_PROGRESS: ' EDIT_DASHBOARD_DEMO_DATA_DIALOG_SUBMIT_IN_PROGRESS',
   EDIT_DASHBOARD_DEMO_DATA_DIALOG_SUBMIT_FINISHED:    ' EDIT_DASHBOARD_DEMO_DATA_DIALOG_SUBMIT_FINISHED',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.EDIT_DASHBOARD_DEMO_DATA_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.EDIT_DASHBOARD_DEMO_DATA_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.EDIT_DASHBOARD_DEMO_DATA_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.EDIT_DASHBOARD_DEMO_DATA_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.show = function(item) {
  return function(dispatch, getState) {
    api.show(dispatch, getState)(item);
  };
};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submitUpload = function(file, data, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submitUpload(dispatch, getState)(file, data, reloadParentComponent);
  };
};

jsxActions.submitEdit = function(data, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submitEdit(dispatch, getState)(data, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {

    dispatch(actions.show(data));
  }
};


api.submitUpload = function(dispatch, getState) {
  return function(file, data, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    return dashboardService.uploadDashboardDemoData(file, data).then((response) => {
        dispatch(actions.submitFinished());
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Failed to upload dashboard demo data', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        let labelKey = 'mat.administration.psmanagement.dashboardsDemoData.view.uploadDashboardData.confirmation.success';
        messageDialogApi.showSuccess(dispatch, getState)(labelKey);

        if (reloadParentComponent) {
          reloadParentComponent();
        }

        return {success: true};
      });
  };
};

api.submitEdit = function(dispatch, getState) {
  return function(data, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    return dashboardService.editDashboardDemoDataSettings(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Failed to edit dashboard demo data settings', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let labelKey = 'mat.administration.psmanagement.dashboardsDemoData.view.editDashboardData.confirmation.success';
      messageDialogApi.showSuccess(dispatch, getState)(labelKey);

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      return {success: true};
    });
  };
};


import { Map } from 'immutable';
import { actionTypes } from '../../../actions/Administration/SettingsPage/commonBarcodeSettingsActions';

const defaultState = {
  loading: false,
  formData: null,
}
export default function commonBarcodeSettingsReducer(state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.COMMON_BARCODE_SETTINGS_PAGE_SET_BUSY:
      return state.set('loading', action.payload);

    case actionTypes.COMMON_BARCODE_SETTINGS_PAGE_FETCH_DATA_READY:
      return state.set('formData', action.payload);

    case actionTypes.COMMON_BARCODE_SETTINGS_PAGE_RESET_DATA:
      return Map(defaultState);
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {PL_Checkbox} from '../../../../controls/Checkbox/checkbox';


//Style
require('./selectAllCheckbox.scss');

export default class PL_SelectAllCheckbox extends React.PureComponent {

  render() {
    return (
      <div  className="selectAll">
        <PL_Checkbox id={"selectAll"}  checked={this.props.isChecked} onChange={this.props.onClick}/>
        <label className="selectAllLabel pointer" htmlFor={"selectAll"}>{this.props.title || 'SELECT ALL'}</label>
      </div>);
  }
}

PL_SelectAllCheckbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  title: PropTypes.string
};



import React from 'react';

import ImportFilesCommonGrid from '../ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';

export default class ImportNestsMultiRollGrid extends React.PureComponent {

  render() {
    return  <ImportFilesCommonGrid  rows={this.props.rows}
                                    assetType={ImportTypes.IMPORT_NESTS_MULTI_ROLLS}
                                    gridName={gridsNames.IMPORT_NESTS_MULTI_ROLLS}
                                    showWorkOrderColumn={true}
                                    showNumberOfPliesColumn={false}
    />;
  }
}

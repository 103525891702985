import React from 'react';

import ImportFilesCommonGrid from '../ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';

export default class ImportToolsGrid extends React.PureComponent {

  render() {
    return  <ImportFilesCommonGrid  rows={this.props.rows} assetType={ImportTypes.IMPORT_TOOLS}
                                    gridName={gridsNames.IMPORT_TOOLS}
            />;
  }
}

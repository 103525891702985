import React from 'react';

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })

    this.props?.onError?.({error, errorInfo})
  }


  render() {
    if (this.state.error) {
      if(this.props.FallbackComponent){
        return <this.props.FallbackComponent errorInfo={this.state} />
      }
      return null;
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
// import {api as gridApi} from "../preferenceGridViewActions";
import * as terminologyService from '../../../../services/Administration/terminologyService';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  EDIT_TERMINOLOGY_DIALOG_SHOW:               'EDIT_TERMINOLOGY_DIALOG_SHOW',
  EDIT_TERMINOLOGY_DIALOG_HIDE:               'EDIT_TERMINOLOGY_DIALOG_HIDE',
  EDIT_TERMINOLOGY_DIALOG_SUBMIT_IN_PROGRESS: 'EDIT_TERMINOLOGY_DIALOG_SUBMIT_IN_PROGRESS',
  EDIT_TERMINOLOGY_DIALOG_SUBMIT_FINISHED:    'EDIT_TERMINOLOGY_DIALOG_SUBMIT_FINISHED',
  EDIT_TERMINOLOGY_DIALOG_FETCH_DATA_READY:   'EDIT_TERMINOLOGY_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.EDIT_TERMINOLOGY_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.EDIT_TERMINOLOGY_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.EDIT_TERMINOLOGY_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.EDIT_TERMINOLOGY_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function(payload) {
    return {type: actionTypes.EDIT_TERMINOLOGY_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    dispatch(actions.show(data));
  }
};

api.submit = function(dispatch, getState) {
  return function(data, reloadParentComponent) {
    dispatch(actions.submitInProgress());

    // data.orgId = getState().login.get('loggedInUser').loggedInOrg.id;
    terminologyService.editTerminologyLabels(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Edit PM Terminology failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      // gridApi.reload(dispatch, getState)();
      if (reloadParentComponent) {
        reloadParentComponent();
      }
    });
  };
};


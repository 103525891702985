import { api as activeToolsGridApi} from './activeToolsGridActions';
import { api as activeToolsScheduleGridApi} from './activeToolsScheduleGridActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {gridsNames} from '../../enums/gridsNames';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


jsxActions.reload = function() {
  return function(dispatch, getState) {
    activeToolsGridApi.reload(dispatch, getState)();
    if (PermissionManager.isToolsSchedulingEnabled()) {
      activeToolsScheduleGridApi.reload(dispatch, getState)();
    }
  }
};

jsxActions.clearFilters = function (data) {
  return function (dispatch, getState) {
    if (data.gridName === gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS ) {
      activeToolsGridApi.clearFilters(dispatch, getState)();
    }
    else if (data.gridName === gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS_SCHEDULE) {
      activeToolsScheduleGridApi.clearFilters(dispatch, getState)();
    }
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideNavigator from '../../components/SideNavigator/sideNavigator';
import { jsxActions as navigatorActions } from '../../actions/SideNavigator/sideNavigatorActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(navigatorActions, dispatch),
    },
  };
}

export default connect((state) => {
  return {
    sData: state.sideNavigator,
    topNavigatorData: state.appTopbar.get('topNavigatorData'),
    sLoggedInUser: state.login.get('loggedInUser'), //pass logged in user for default location data.
    featureFlags: state.system.get('featureFlags'),
  };
}, mapDispatchToProps)(SideNavigator);

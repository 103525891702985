import { actionTypes } from '../../actions/AuthenticationPageActions/AuthenticationPageActions';
import {Map} from 'immutable';

let defaultState = {
  loading : false,
  errorMessage: '',
  hasError: false

};

export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_PAGE_CLEAR_DATA:
      return Map(defaultState);

    case actionTypes.AUTHENTICATION_PAGE_SUBMIT_IN_PROCESS:
    return state.set('loading', true);

    case actionTypes.AUTHENTICATION_PAGE_SUBMIT_FINISHED:
      return Map(defaultState);

    case actionTypes.AUTHENTICATION_PAGE_SENDING_SMS_FINISHED:
      return Map(defaultState);

    case actionTypes.AUTHENTICATION_PAGE_SENDING_SMS_IN_PROCESS:
      return state.set('loading', true);

    case actionTypes.AUTHENTICATION_PAGE_SUBMIT_FAILED:
      state = state.set('hasError', true)
      state = state.set('errorMessage', action.payload);
      return state.set('loading', false);

    default:
      return state;
  }
}

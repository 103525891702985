import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as alertDevicesService from '../../../../services/Rfid/alertDevicesService';

import * as readersAndAntennasService from '../../../../services/Rfid/readersAndAntennasService';
import * as deviceAppsService from '../../../../services/Rfid/deviceAppsService';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_ALERT_DEVICE_DIALOG_SHOW:               'CREATE_ALERT_DEVICE_DIALOG_SHOW',
  CREATE_ALERT_DEVICE_DIALOG_HIDE:               'CREATE_ALERT_DEVICE_DIALOG_HIDE',
  CREATE_ALERT_DEVICE_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_ALERT_DEVICE_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_ALERT_DEVICE_DIALOG_SUBMIT_FINISHED:    'CREATE_ALERT_DEVICE_DIALOG_SUBMIT_FINISHED',
  CREATE_ALERT_DEVICE_DIALOG_FETCH_DATA_READY:   'CREATE_ALERT_DEVICE_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_ALERT_DEVICE_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_ALERT_DEVICE_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_ALERT_DEVICE_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_ALERT_DEVICE_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function(payload) {
    return {type: actionTypes.CREATE_ALERT_DEVICE_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};
//todo add fetch by device id

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, isEditMode, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
  };
};

jsxActions.showAlert = function(alertMessageDialogBuilder) {
  return function(dispatch, getState) {
    api.showAlert(dispatch, getState)(alertMessageDialogBuilder);
  };
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.remove = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.showAlert = function(dispatch, getState) {
  return function(alertMessageDialogBuilder) {
    if (alertMessageDialogBuilder) {
      let messageDialogDescriptor = alertMessageDialogBuilder(
        messageDialogApi.close(dispatch, getState)
      );
      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    }
  }
};




api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data) {

    api.fetchDialogData(dispatch, getState)(data.deviceAppProfileId).then((response) => {
      dispatch(actions.show(data.itemToEdit));
    });
  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function(deviceAppProfileId) {

      let promise1 = readersAndAntennasService.fetchAntennas(deviceAppProfileId);
      let promise2 = alertDevicesService.fetchBusinessRules();
      let promise3 = readersAndAntennasService.fetchReaders();
      // let promise3 = alertDevicesService.fetchBusinessRules();

      return Promise.all([promise1, promise2, promise3]).then((allResults) => {

        let antennas = allResults[0].dataList;
        let businessRules = _createBusinessRulesData(allResults[1].data);
        let readers = allResults[2].dataList;

        let dialogData = {
          antennas: antennas.map((obj) => {return {value: obj.id, label: obj.name, data: obj}}),
          businessRules: businessRules.map((obj) => {return {value: obj.name, label: obj.name, data: obj}}),
          readers: readers.map((obj) => {return {value: obj.businessId, label: obj.name, data: obj}}),

        };

        dispatch(actions.fetchDilaogDataReady(dialogData));
        return dialogData;
      });
  }
};

api.submit = function(dispatch, getState) {
  return function(data, isEditMode, reloadParentComponent) {

    let method = isEditMode ? alertDevicesService.editAlertDevice : alertDevicesService.createAlertDevice;

    dispatch(actions.submitInProgress());

    return method(data).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Create/Edit alert device failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }
    });
  };
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _createBusinessRulesData(data) {

  let allRules = Object.values(data.descriptorsMap);
  if (!allRules || allRules.length < 1) {
    return []; //?????
  }
  let allSupportedRules = allRules.filter((item) => {
    return (item.type === 'Integer' || item.type === 'String' || item.type === 'Enum');
  });

  let allSigns = data.allowedRuleTypesByPropertyType;

  let res = allSupportedRules.map((rule) => {

    let ruleSigns = allSigns[rule.type];
    if (ruleSigns) {
      let signsOptions = Object.keys(ruleSigns).map((key) => {
        return (
          {
            value: key,
            label: ruleSigns[key],
          })
      });

      let enumValuesOptions = null;
      if (rule.type === 'Enum' && !!rule.values) {
        enumValuesOptions = rule.values.map((value) => {
          return {
            value: value,
            label: value,
          }
        });
      }

      let newRule = {
        name: rule.name,
        type: rule.type,
        signs: signsOptions,
        enumValues: enumValuesOptions,
      };
      return newRule;
    }
  });

  return res;
}

import Network from 'infrastructure/js/modules/network';
import * as toolsService from '../../../services/Tools/toolsService';
import * as tagsService from '../../../services/Tags/tagsService';
import * as alertService from '../../../services/Alerts/settingsAlertsService';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions.js';
import { api as locationPageApi } from '../locationPageActions.js';
import * as additionalFieldsService from '../../../services/Administration/additionalFieldsService';
import * as additionalFieldsHelper from '../../../components/Common/Helpers/AdditionalFieldsHelper';
import { getEnumValue, enumTypes } from '../../../utils/enumHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ASSETSVIEW_CREATE_TOOL_SUBMIT_IN_PROGRESS: 'ASSETSVIEW_CREATE_TOOL_SUBMIT_IN_PROGRESS',
  ASSETSVIEW_CREATE_TOOL_SUBMIT_FINISHED: 'ASSETSVIEW_CREATE_TOOL_SUBMIT_FINISHED',
  ASSETSVIEW_CREATE_TOOL_SHOW_DIALOG: 'ASSETSVIEW_CREATE_TOOL_SHOW_DIALOG',
  ASSETSVIEW_CREATE_TOOL_HIDE_DIALOG: 'ASSETSVIEW_CREATE_TOOL_HIDE_DIALOG',
  ASSETSVIEW_CREATE_TOOL_GENERATED_ID: 'ASSETSVIEW_CREATE_TOOL_GENERATED_ID',
  ASSETSVIEW_CREATE_TOOL_FETCH_DATA_READY: 'ASSETSVIEW_CREATE_TOOL_FETCH_DATA_READY',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  createToolInProgress: function (payload) {
    return { type: actionTypes.ASSETSVIEW_CREATE_TOOL_SUBMIT_IN_PROGRESS, payload: payload };
  },
  createToolFinished: function (payload) {
    return { type: actionTypes.ASSETSVIEW_CREATE_TOOL_SUBMIT_FINISHED, payload: payload };
  },
  show: function (payload) {
    return { type: actionTypes.ASSETSVIEW_CREATE_TOOL_SHOW_DIALOG, payload: payload };
  },
  hide: function () {
    return { type: actionTypes.ASSETSVIEW_CREATE_TOOL_HIDE_DIALOG };
  },
  generateToolId: function (payload) {
    return { type: actionTypes.ASSETSVIEW_CREATE_TOOL_GENERATED_ID, payload: payload };
  },
  fetchDialogDataReady: function (payload) {
    return { type: actionTypes.ASSETSVIEW_CREATE_TOOL_FETCH_DATA_READY, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchDialogData = function () {
  return function (dispatch, getState) {
    api.fetchDialogData(dispatch, getState)();
  };
};

jsxActions.generateToolId = function () {
  return function (dispatch, getState) {
    return api.generateToolId(dispatch, getState)();
  };
};

jsxActions.submit = function (data, messageDialogBuilder) {
  return function (dispatch, getState) {
    api.submit(dispatch, getState)(data, messageDialogBuilder);
  };
};

jsxActions.show = function () {
  return function (dispatch, getState) {
    api.show(dispatch, getState)();
  };
};
jsxActions.hide = function () {
  return function (dispatch, getState) {
    dispatch(actions.hide());
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.show = function (dispatch, getState) {
  return function () {
    dispatch(actions.show());
  };
};

api.fetchDialogData = function (dispatch, getState) {
  return function () {
    let fetchTagsPromise = tagsService.fetchTags();
    let toolTypesPromise = toolsService.fetchToolTypes();
    let fetchAdditionalFieldsPromise = additionalFieldsService.fetchAdditionalFields({
      entityType: getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'),
    });

    return Promise.all([fetchTagsPromise, toolTypesPromise, fetchAdditionalFieldsPromise]).then(
      (allResults) => {
        if (
          !Network.isResponseValid(allResults[0]) ||
          !Network.isResponseValid(allResults[1]) ||
          !Network.isResponseValid(allResults[2])
        ) {
          console.error('Fetch Tool Data Failed');
          messageDialogApi.responseError(dispatch, getState)(null);
          return { success: false };
        }

        let tags = allResults[0];
        let toolTypes = allResults[1];
        let additionalFields = allResults[2];

        let dialogData = {
          tags: tags,
          toolTypes: toolTypes,
          additionalFields: additionalFields,
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return dialogData;
      }
    );
  };
};

api.generateToolId = function (dispatch, getState) {
  return function () {
    return toolsService.generateToolId().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Generate Tool Id Failed');
        return { success: false };
      }
      return response.data;
      dispatch(actions.generateToolId(response.data));
    });
  };
};

api.submit = function (dispatch, getState) {
  return function (data, messageDialogBuilder) {
    dispatch(actions.createToolInProgress());

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let additionalFields = additionalFieldsHelper.convertToAdditionalFields(
      data['additionalFields'],
      data.additionalFieldsDefinitions
    );
    let toolData = {
      businessId: data.toolId,
      tags: data.tags ? data.tags.map(({ value, label }) => ({ id: value, tagKey: label })) : [],
      locationId: locationId,
      maxNumOfCycles: data.cycleCount === 0 ? 0 : data.cycleCount ? data.cycleCount : null,
      toolType: convertNewOption(data.toolType),
      additionalFieldsValues: additionalFields,
      maxManufactureUsageTime: data.maintenanceUsageTime,
    };

    toolsService.createTool(toolData).then((response) => {
      dispatch(actions.createToolFinished(response));
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Create Tool Failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }
      locationPageApi.reload(dispatch, getState)();

      let messageDialogDescriptor = messageDialogBuilder(
        { ...data },
        messageDialogApi.close(dispatch, getState)
      );
      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    });
  };
};

function convertNewOption(data) {
  if (!data || data.label == null) {
    return null;
  }

  return {
    id: data.value !== data.label ? data.value : null,
    businessId: data.label,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Tooltip from '../../tooltip/tooltip'
import Label from '../../Label/label';

require('./defaultHeader.scss');

// Header component to be used as default for all the columns.
export default class DefaultHeader extends React.PureComponent {

  constructor(props) {
    super(props);

    this.menuButtonRef = React.createRef();

    this.state = {
      sorted: this.getSortDirection(),
      filtered: this.props.column.isFilterActive()
    }
  }

  componentDidMount() {
    this.props.column.addEventListener('sortChanged', this.onSortChanged);
    this.props.column.addEventListener('filterChanged', this.onFilterChanged);
  }

  componentWillUnmount() {
    this.props.column.removeEventListener('sortChanged', this.onSortChanged);
    this.props.column.removeEventListener('filterChanged', this.onFilterChanged);
  }

  onSortRequested = (event) => {
    let sortDirection = this.props.column.isSortDescending() ? 'asc' : 'desc';

    this.props.setSort (sortDirection, event.shiftKey);
  };

  onSortChanged = () =>{
      this.setState({
        sorted: this.getSortDirection()
      })
  }

  getSortDirection = ()=> {
    let direction;
    if (this.props.column.isSortAscending()){
      direction = 'asc'
    } else if (this.props.column.isSortDescending()){
      direction = 'desc'
    } else {
      direction = ''
    }

    return direction;
  }

  onFilterChanged = () =>{
      this.setState({
        filtered: this.props.column.isFilterActive()
      })
  }

  onMenuClick (){
    this.props.showColumnMenu(this.menuButtonRef?.current);
  }

  renderHeaderLabel(handleSort) {
    if (this.props.displayName) {
      return (
        <div className={cn('custom-header-label') }>
        <div  key="customHeaderLabel" onClick={handleSort} style={this.props.headerTextStyle}>
          <Label text={this.props.displayName} tooltip={this.props.headerTooltip || this.props.displayName}/>
        </div>
        </div>);
    }
    return null;
  }

  renderIcon(handleSort) {
    let icon = null;
    if (this.props.headerIcon) {
      icon = <div key='icon' className="custom-header-icon" onClick={handleSort}>
                <i className={"pl pl-" + this.props.headerIcon}/>
              </div>
    }
    if (this.props.headerIconTooltip || this.props.headerTooltip) {
      icon =
      <Tooltip
        placement="top"
        value={this.props.headerIconTooltip || this.props.headerTooltip}
        delayShow={500}>
        <div>
          {icon}
        </div>
      </Tooltip>
    }
    return icon;
  }

  renderSort(handleSort) {
    let sortElements = [];
    if (this.props.enableSorting) {
      let downArrowClass = "custom-sort-down-label " + (this.state.sorted === 'desc' ? " active" : "");
      let upArrowClass = "custom-sort-up-label " + (this.state.sorted === 'asc' ? " active" : "");

      sortElements = <div className={'sort-container'}>
                      <div className={downArrowClass} key='downArrowClass' onClick={handleSort}><i
                        className="pl pl-sorted-arrow-down"/>
                      </div>
                      <div className={upArrowClass} key='upArrowClass' onClick={handleSort}><i
                        className="pl pl-sorted-arrow-up"/>
                      </div>
                    </div>
    }
    return sortElements;
  }

  renderFilter() {
    let menuButton = null;
    if (this.props.enableMenu) {
      menuButton = <div ref={this.menuButtonRef} key='menuButton'
                        className={cn('custom-header-menu-button', {activated : this.state.filtered})}
                        onClick={this.onMenuClick.bind(this)}><i className={'pl pl-filter'}/></div>
    }
    return menuButton;
  }

  render() {
    let handleSort = this.props.enableSorting ? this.onSortRequested : undefined;

    return (
    <div className={cn('default-header') }>
      {this.renderHeaderLabel(handleSort)}
      {this.renderIcon(handleSort)}
      {this.renderSort(handleSort)}
      {this.renderFilter()}
    </div>)
  }


}

DefaultHeader.propTypes = {
  params: PropTypes.object
};

import { Map } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';
import { actionTypes as actionTypes } from '../../../actions/Administration/MatSettingsPage/Dialogs/shiftSettingsDialogActions.js';

let defaultState = {
  show: false,
  loading: false,
  itemToEdit: null,
  dialogData: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.SHIFT_SETTINGS_DIALOG_SHOW:
      state = state.set('itemToEdit', action.payload);
      return state.set('show',true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.SHIFT_SETTINGS_DIALOG, defaultState);
  }

}








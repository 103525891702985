import PropTypes from 'prop-types';

const defaultGetIterationKey = (item) => {
  if (typeof item === 'object') {
    return item.id;
  }
  return item;
};

const ItemsList = ({ items, item: Item, getIterationKey, onRemoveItem }) => {
  return (
    <div className="items-list">
      {items.map((item) => (
        <Item key={getIterationKey(item)} onRemoveItem={onRemoveItem} item={item} />
      ))}
    </div>
  );
};

ItemsList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  getIterationKey: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

ItemsList.defaultProps = {
  items: [],
  item: '',
  getIterationKey: defaultGetIterationKey,
  onRemoveItem: () => console.error('ItemsList: no remove item method was provided!'),
};

export default ItemsList;

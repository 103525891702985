import Network from 'infrastructure/js/modules/network';


export function createAlertDevice(data){
  return Network.post('impinjDisco', data);
}

export function editAlertDevice(data) {
  let id = data.id;
  delete data.id;
  return Network.put(`impinjDisco/${id}`, data);
}

export function removeAlertDevices(data) {
  return Network.delete('impinjDiscos' , data);
}

export function fetchAlertDevices(id) {
  return Network.get(`deviceAppProfile/${id}/impinjDisco`);
}

export function fetchBusinessRules() {
  return Network.get('businessRule/propertiesAndOperations');
}

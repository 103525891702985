import { Map } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';
import { actionTypes as actionTypes } from '../../../actions/Administration/RfidSettingsPage/Dialogs/createMqttBrokerDialogActions.js';


let defaultState = {
  show: false,
  loading: false,
  itemToEdit: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.CREATE_MQTT_BROKER_DIALOG_SHOW:
      return state.set('itemToEdit', action.payload)
                  .set('show', true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.CREATE_MQTT_BROKER_DIALOG, defaultState);
  }

}








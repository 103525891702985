import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as toolsService from '../../../services/Tools/toolsService.js';
import * as tagsService from '../../../services/Tags/tagsService.js';
import { api as headerActionsApi } from '../Header/headerActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SCANNEDTOOL_PAGE_SUBMIT_IN_PROGRESS: 'SCANNEDTOOL_PAGE_SUBMIT_IN_PROGRESS',
  SCANNEDTOOL_PAGE_SUBMIT_FINISHED: 'SCANNEDTOOL_PAGE_SUBMIT_FINISHED',
  SCANNEDTOOL_PAGE_SUBMIT_FAILED: 'SCANNEDTOOL_PAGE_SUBMIT_FAILED',
  SCANNEDTOOL_PAGE_CLEAR: 'SCANNEDTOOL_PAGE_CLEAR',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  subminInProgress: function () {
    return { type: actionTypes.SCANNEDTOOL_PAGE_SUBMIT_IN_PROGRESS };
  },
  submitFinished: function (payload) {
    return { type: actionTypes.SCANNEDTOOL_PAGE_SUBMIT_FINISHED, payload: payload };
  },
  submitFailed: function (payload) {
    return { type: actionTypes.SCANNEDTOOL_PAGE_SUBMIT_FAILED, payload: payload };
  },
  clear: function (payload) {
    return { type: actionTypes.SCANNEDTOOL_PAGE_CLEAR, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.submit = function (toolId, data, callback, errorCallback, tagsTrackedNature) {
  return function (dispatch, getState) {
    api.submit(dispatch, getState)(toolId, data, callback, errorCallback, tagsTrackedNature);
  };
};

jsxActions.clearError = function () {
  return function (dispatch, getState) {
    dispatch(actions.clear());
  };
};
jsxActions.clear = function () {
  return function (dispatch, getState) {
    headerActionsApi.clearAllScans(dispatch, getState)();
    dispatch(actions.clear());
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.submit = function (dispatch, getState) {
  return function (toolId, tagKeys, callback, errorCallback, tagsTrackedNature) {
    dispatch(actions.subminInProgress());

    //backwards compatibility:
    const keys = typeof tagKeys === 'string' ? [tagKeys] : tagKeys.map(({ tagKey }) => tagKey);

    //If no tags were selected or all tags were removed => update tool with no tags.
    if (!tagKeys || tagKeys.length === 0) {
      return api.updateTool(dispatch, getState)(toolId, { tags: null }, callback);
    }

    //Fire multiple requests:
    //Check if tag exists => if not, create the tag.
    Promise.all(
      keys.map(async (tagKey) => {
        const responseCheck = await tagsService.getTagByKey(tagKey);
        const isValidTag = Network.isResponseValid(responseCheck);
        if (isValidTag) {
          const { trackedNature } = responseCheck.data;
          return {
            tag: responseCheck.data,
            success: !trackedNature || trackedNature === tagsTrackedNature,
          };
        } else {
          const responseCreate = await tagsService.createTag({ tagKey });
          const isTagCreated = Network.isResponseValid(responseCreate);
          if (isTagCreated) {
            return { tag: responseCreate.data, success: true };
          }
          return { tag: { tagKey }, success: false };
        }
      })
    ).then((tagsToUpdate) => {
      const validTags = tagsToUpdate.filter(({ success }) => !!success).map(({ tag }) => tag);
      const InvalidTags = tagsToUpdate.filter(({ success }) => !success);
      if (InvalidTags.length) {
        _handleFailure({}, dispatch, InvalidTags);
        errorCallback(InvalidTags);
      } else {
        return api.updateTool(dispatch, getState)(toolId, { tags: validTags }, callback);
      }
    });
  };
};

api.updateTool = function (dispatch, getState) {
  return function (toolId, data, callback) {
    return toolsService.updateTool(toolId, data).then((response) => {
      if (!Network.isResponseValid(response)) {
        _handleFailure(response, dispatch);
        return;
      }

      dispatch(actions.submitFinished(response.data));

      if (callback) {
        setTimeout(() => {
          callback();
        }, 3000);
      }
    });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _handleFailure(response, dispatch, invalidTags) {
  console.error('Failed to save changes', response);
  const GENERIC_ERROR_MESSAGE = createLabelHelper('mat.mobile.scannedToolPage.').get(
    'result.tagNotSaved'
  );
  let message = !invalidTags ? GENERIC_ERROR_MESSAGE : `Failed to save ${invalidTags.length} tags`;
  dispatch(actions.submitFailed(message));
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {List} from 'immutable';
import { jsxActions as headerActions} from '../../../actions/Header/headerActions';
import { jsxActions as fetchEntitiesActions }      from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as assetsViewActions }        from '../../../actions/LocationPage/AssetsView/assetsViewActions';
import { jsxActions as assetsKitsGridActions }        from '../../../actions/LocationPage/AssetsView/assetsKitsGridActions';
import {gridsNames} from '../../../enums/gridsNames';
import KitsView from '../../../components/LocationPage/AssetsView/KitsView/kitsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(assetsViewActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      headerActions:        bindActionCreators(headerActions, dispatch),
      assetsKitsGridActions:        bindActionCreators(assetsKitsGridActions, dispatch),
    }
  }
}

let KitsViewContainer = connect(
  (state) => {
    let sGrid = state.grid.get(gridsNames.LOCATION_ASSET_KITS) ? state.grid.get(gridsNames.LOCATION_ASSET_KITS) : null;
    return {
      sGrid:                  sGrid,
      sHasRows:               sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData:      sGrid ? sGrid.get('selectedRowsData') : List(),
      sData:                  state.locationPage.getIn(['assetsView', 'kitsView']),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),
      sLoggedInUser :         state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(KitsView);

export default KitsViewContainer;





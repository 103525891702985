import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';

import './OptionsList.scss';

const getNoOptions = () => {
  return <div className="options-list-message">{'No options'}</div>;
};

const getAsyncLoadingMessage = () => {
  return <div className="options-list-message">{'searching...'}</div>;
};

const OptionsList = ({
  options,
  onOptionClick,
  loader,
  onInputChange,
  optionsTranslator,
  selectedItems,
  asyncFilter,
}) => {
  const [inputValue, setInputValue] = useState('');

  const computedOptions = useMemo(() => {
    const newOptions = optionsTranslator(options);
    const keys = selectedItems.map(({ value }) => value);
    return newOptions.filter(({ value }) => !keys.includes(value));
  }, [options, optionsTranslator, selectedItems]);

  const filteredOptions = useMemo(() => {
    if (inputValue.length === 0 && asyncFilter) {
      return [];
    }

    if (asyncFilter) {
      return computedOptions;
    }
    return inputValue?.length > 2
      ? computedOptions.filter(({ label }) =>
          label.toLowerCase().includes(inputValue.toLowerCase())
        )
      : computedOptions;
  }, [computedOptions, inputValue, asyncFilter]);

  const handleOptionClick = (event, value) => {
    event.stopPropagation();
    onOptionClick(value);
  };

  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
    if (asyncFilter) {
      asyncFilter(value);
    }
    onInputChange(value);
  };

  const getListItems = () => {
    return (
      <div className="options-list-items">
        {filteredOptions.map(({ value, label }) => (
          <div className="options-item" key={value} onClick={(e) => handleOptionClick(e, value)}>
            {label}
          </div>
        ))}
      </div>
    );
  };

  if (!options.length && !asyncFilter) {
    return <div className="options-list options-list-message">{'No options'}</div>;
  }

  return (
    <div className="options-list">
      <div className="options-list-search">
        {options?.length || asyncFilter ? (
          <input
            className="options-list-input"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={asyncFilter ? 'Type to search...' : 'search'}
          />
        ) : null}
      </div>
      {loader
        ? getAsyncLoadingMessage()
        : options?.length
        ? getListItems()
        : asyncFilter
        ? null
        : getNoOptions()}
    </div>
  );
};

OptionsList.propTypes = {
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
  loader: PropTypes.bool,
  onInputChange: PropTypes.func,
  optionsTranslator: PropTypes.func,
  selectedItems: PropTypes.array,
  asyncFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

OptionsList.defaultProps = {
  options: [],
  onOptionClick: () => {
    console.warn('no onOptionClick method!');
  },
  onInputChange: () => false,
  loader: false,
  optionsTranslator: (options) => options,
  selectedItems: [],
  asyncFilter: null,
};

export default OptionsList;

import React from 'react';
import { FormattedMessage } from 'react-intl';


// GLOBAL VARIABLE !
window.reactIntlGlobal = null;


export function initLabelHelper(reactIntl) {

  /////////////////////////////////////////////////////////
  // This gets called at each app main index.js render().
  // index.js is connected to real up-to-date reactIntl object
  // which it gets via props from IntlProvider container.
  //
  // We store it here in global variable so the latest version
  // is accessible for us.
  //
  // IntlProvider container is affected by 'updateIntl' action creator from 'react-intl-redux'.
  //
  //


  window.reactIntlGlobal = reactIntl;
  // console.log('REACT INTL GLOBAL INITIALIZED.');
}



export function createLabelHelper(baseNamespace = '') {
  let obj = {};

  // obj.reactIntl = window.reactIntlGlobal;
  obj.reactIntl = function() {
    return window.reactIntlGlobal;
  };


  obj.baseNamespace = baseNamespace;

  // Resolves absolute label key by its subKey.
  obj.getFullKey = function(subKey) {
    return obj.baseNamespace + subKey;
  };

  // Returns label value by its relative subKey.
  obj.get = function(subKey, defaultMessage = 'xxx', values = undefined) {
    let fullKey = obj.getFullKey(subKey);
    if(_isShowAllLabelsAsKeys()){
      return fullKey;
    }
    if(_isShowLabelKeyAsDefaultLabel()){
      defaultMessage = fullKey;
    }

    const stringExists = !!obj.reactIntl().messages[fullKey];

    // Convert params Array to params Map (simple object).
    if (values && Array.isArray(values)) {
      let asObject = {};
      values.forEach((x) => {
        asObject[x.key] = x.value;
      });
      values = asObject;
    }

    if(stringExists){
      return obj.reactIntl().formatMessage({
        id: fullKey,
        defaultMessage
      }, values);
    }
    return defaultMessage;
  };

  //Handel received actions
  obj.handelAction = function (action) {
    action();
  };

  // Creates new object with labels that share provided subNamespace.
  obj.createSubset = function(subNamespace = '') {
    return getLabelsSubset(obj.reactIntl().messages, (obj.baseNamespace + subNamespace));
  };

  return obj;
}



//////////////////////////////////////////////////////////////////
// Extracts all labels by provided namespace.
// This namespace is stripped from the returned labels keys.
function getLabelsSubset(allLabels, namespace) {
  let result = {};

  let allOriginalKeys = Object.keys(allLabels);
  allOriginalKeys.forEach((originalKey) => {
    if (originalKey.indexOf(namespace) === 0) {
      let newKey = originalKey.slice(namespace.length);
      result[newKey] = allLabels[originalKey];
    }
  });

  return result;
}

function _isShowAllLabelsAsKeys(){
  let loggedInUser = JSON.parse(localStorage['loggedInUser'] || null);
  return loggedInUser?.loggedInOrg?.orgPreferences?.showAllLabelsAsKeys;
}
function _isShowLabelKeyAsDefaultLabel(){
  let loggedInUser = JSON.parse(localStorage['loggedInUser'] || null);
  return loggedInUser?.loggedInOrg?.orgPreferences?.showLabelKeyAsDefaultLabel;
}






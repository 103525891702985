import { actionTypes } from '../../actions/ResetPassword/resetPasswordActions';
import {Map} from 'immutable';

let defaultState = {
  loading : false,
  errorMessage: '',
  isSuccess: false
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.RESETPASSWORD_CLEAR_DATA:
      return Map(defaultState);
    case actionTypes.RESETPASSWORD_SUBMIT_IN_PROCESS:
      return state.set('loading', true);

    case actionTypes.RESETPASSWORD_SUBMIT_FINISHED:
      state = state.set('errorMessage', action?.payload?.data?.message);
      state = state.set('isSuccess', true);
      state = state.set('loading', false);
      return state;

    case actionTypes.RESETPASSWORD_SUBMIT_FAILED:
      state = state.set('isSuccess', false);
      state = state.set('errorMessage', action?.payload?.data?.message);
      return state.set('loading', false);

    default:
      return state;
  }
}

import React from "react";
import PropTypes from "prop-types";

import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import { reduxForm } from "redux-form";
import Validation from "infrastructure/js/components/controls/controlsValidations.js";
import * as dialogHelper from "infrastructure/js/components/Dialog/dialogHelper";
import * as orgsHelper from "../../utils/organizationsHelper";
import Dialog from "infrastructure/js/components/Dialog/dialog";
import InputSection from "infrastructure/js/components/Dialog/InputSection/inputSection";
import TextField from "infrastructure/js/components/controls/TextField/textField";
import Combobox from "infrastructure/js/components/controls/Combobox/combobox.js";
import AlertPopover from "infrastructure/js/components/popover/AlertPopover/alertPopover.js";
import PermissionManager from "infrastructure/js/utils/permissionManager";
import { enumToDropdownOptions, enumTypes } from "../../../../../utils/enumHelper";

require("./createOrganizationDialog.scss");

class CreateOrganizationDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper("mat.dialog.");
    this.labels = createLabelHelper("mat.administration.dialog.createorganization.");

    this.currentOrganization = this.props.sData.get("organization");
    this.isEditMode = !!this.currentOrganization;
    this.isDuplicateMode = this.props.sData.get("isDuplicate");
  }

  componentWillMount() {
    this.props.change("woOrientedType", this.getDefaultWoTypeOption());
  }

  getDefaultWoTypeOption = () => {
    if (this.props.sLoggedInUser) {
      let defaultWoType = PermissionManager.getOrgPreferences().systemDefaultWorkOrderType;
      if (defaultWoType) {
        let found = orgsHelper.getWoOrientedOptions().find((item) => {
          return (item.value === defaultWoType);
        });

        return found ? found : null;
      }
    }
    return null;
  };

  getDialogButtons() {
    return {
      left: [
        {
          id: "cancel",
          text: this.dialogLabels.get("cancel"),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: "submit",
          text: (this.isEditMode && !this.isDuplicateMode) ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: "primary",
          loading: this.props.sData.get("loading"),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get("loading")
        }
      ]
    };
  }

  onSubmit = (data) => {

    let newData = {
      status: data.status.value,
      dateFormat: data.dateFormat.value,
      timeFormat: data.timeFormat.value,
      timeZone: data.timeZone.value,
      localeCode: data.locale.value,
      temperatureUnits: data.temperatureUnits.value,
      lengthUnits: data.lengthUnits.value,
      widthUnits: data.widthUnits.value,
      areaUnits: data.areaUnits.value,
      weightUnits: data.weightUnits.value,
      workOrderType: data.woOrientedType.value
    };

    if (!this.isEditMode || this.isDuplicateMode) {
      newData.orgName = data.orgName;
      newData.orgBusinessId = data.orgName;
      newData.orgAdminEmail = "";
      newData.orgParentId = this.isDuplicateMode ? this.currentOrganization.parentId : 5; // mandatory parent org, for 'Plataine' org orgParentId = 5
    }

    if (this.isDuplicateMode) {
      newData.sourceOrgName = this.currentOrganization.name;
    }

    let organizationId = this.isEditMode || this.isDuplicateMode ? this.currentOrganization.id : null;
    this.props.actions.submit(newData, organizationId, this.isDuplicateMode, this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get("confirmation.message.title", "", { count: response.totalJobItems });
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onEntered = () => {
    this.initData();
  };

  selectOptionRedux = (options, name, value) => {
    let selectedOption = options.filter(option => {return option.value === value; });
    if (selectedOption && selectedOption.length > 0) {
      this.props.change(name, selectedOption[0]);
    }
  };

  initData = () => {
    let orgPreferences = this.currentOrganization ? this.currentOrganization.orgPreferences : null;
    if (this.isDuplicateMode) {
      this.setCommonOptions(orgPreferences);
    }

    else {
      if (this.isEditMode) {
        this.props.change("orgName", this.currentOrganization.name);
        this.setCommonOptions(orgPreferences);
      }
    }
  };

  setCommonOptions = (orgPreferences) => {
    this.selectOptionRedux(orgsHelper.getStatuses(), "status", this.currentOrganization.status);
    this.selectOptionRedux(orgsHelper.getLocales(), "locale", orgPreferences.systemLocale);
    this.selectOptionRedux(orgsHelper.getDateFormats(), "dateFormat", orgPreferences.systemDateFormat);
    this.selectOptionRedux(orgsHelper.getTimeFormats(), "timeFormat", orgPreferences.systemTimeFormat);
    this.selectOptionRedux(orgsHelper.getTimeZones(), "timeZone", orgPreferences.systemTimeZone);
    this.selectOptionRedux(enumToDropdownOptions(enumTypes.TEMPERATURE_UNITS, this.labels, "units.temperature."), "temperatureUnits", orgPreferences.systemTemperatureUnits);
    this.selectOptionRedux(enumToDropdownOptions(enumTypes.LENGTH_UNITS, this.labels, "units.length."), "lengthUnits", orgPreferences.systemLengthUnits);
    this.selectOptionRedux(enumToDropdownOptions(enumTypes.WIDTH_UNITS, this.labels, "units.width."), "widthUnits", orgPreferences.systemWidthUnits);
    this.selectOptionRedux(enumToDropdownOptions(enumTypes.AREA_UNITS, this.labels, "units.area."), "areaUnits", orgPreferences.systemAreaUnits);
    this.selectOptionRedux(enumToDropdownOptions(enumTypes.WEIGHT_UNITS, this.labels, "units.weight."), "weightUnits", orgPreferences.systemWeightUnits);
    this.selectOptionRedux(orgsHelper.getWoOrientedOptions(), "woOrientedType", orgPreferences.systemDefaultWorkOrderType);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  getValueComponentToRender = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;

    let isCurrentStatusProd = (this.isEditMode && !this.isDuplicateMode) && (this.currentOrganization ? this.currentOrganization.status === "PRODUCTION" : false);
    if (isCurrentStatusProd || option.value !== "PRODUCTION") {
      return <span>{option.label}</span>;
    }
    return <div key={option.label}>
      <span>{option.label}</span>
      <AlertPopover
        defaultOverlayShown={true}
        popoverType={"warning"}
        popoverComponent={<div>{this.labels.get("statusProduction")}</div>}
      />
    </div>;

  };

  renderNote = () => {
    return this.isDuplicateMode ? <div
      className="edit-note">{this.labels.get("note.text", undefined, { orgName: this.currentOrganization.name })}</div> : null;
  };

  getTitle = () => {
    if (this.isDuplicateMode) {
      return `${this.labels.get("header.title.create")} - Clone '${this.currentOrganization.name}'`;
    }
    return this.isEditMode ? this.labels.get("header.title.edit") : this.labels.get("header.title.create");
  };

  maxLength20 = Validation.maxLength(20);

  render() {
    let titleText = this.getTitle();
    let isStatusDisabled = (this.isEditMode && !this.isDuplicateMode) && (this.currentOrganization ? this.currentOrganization.status === "PRODUCTION" : false);
    return (
      <Dialog
        id="create-organization-dialog"
        className="create-organization-dialog"
        titleText={titleText}
        show={this.props.sData.get("show")}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}>

        <InputSection label={this.labels.get("orgname") + "*"} htmlFor="orgName" className="inline left-side no-margin">
          <TextField id="orgName" name="orgName" className="short-textfield"
                     disabled={this.isEditMode && !this.isDuplicateMode}
                     validate={[Validation.required, this.maxLength20, Validation.orgNameFormat]}/>
        </InputSection>

        <InputSection label={this.labels.get("dateformat") + "*"} htmlFor="dateFormat"
                      className="inline right-side no-margin">
          <Combobox id="dateFormat" name="dateFormat" options={orgsHelper.getDateFormats()}
                    validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get("status") + "*"} htmlFor="status" className="inline left-side">
          <Combobox id="status" name="status" options={orgsHelper.getStatuses()} validate={Validation.required}
                    isDisabled={isStatusDisabled} singleValueRenderer={this.getValueComponentToRender}/>
        </InputSection>

        <InputSection label={this.labels.get("timeformat") + "*"} htmlFor="timeFormat" className="inline right-side">
          <Combobox id="timeFormat" name="timeFormat" options={orgsHelper.getTimeFormats()}
                    validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get("locale") + "*"} htmlFor="locale" className="inline left-side">
          <Combobox id="locale" name="locale" options={orgsHelper.getLocales()} validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get("timezone") + "*"} htmlFor="timeZone" className="inline right-side">
          <Combobox id="timeZone" name="timeZone" options={orgsHelper.getTimeZones()} validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get("woOrientedType") + "*"} htmlFor="woOrientedType"
                      className="inline left-side">
          <Combobox id="woOrientedType" name="woOrientedType" options={orgsHelper.getWoOrientedOptions()}
                    validate={Validation.required} isDisabled={this.isEditMode && !this.isDuplicateMode}/>
        </InputSection>

        <InputSection label={this.labels.get("units.temperature") + "*"} htmlFor="unit" className="inline right-side">
          <Combobox id="temperatureUnits" name="temperatureUnits"
                    options={enumToDropdownOptions(enumTypes.TEMPERATURE_UNITS, this.labels, "units.temperature.")}
                    validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get("units.length") + "*"} htmlFor="woOrientedType"
                      className="inline left-side">
          <Combobox id="lengthUnits" name="lengthUnits"
                    options={enumToDropdownOptions(enumTypes.LENGTH_UNITS, this.labels, "units.length.")}
                    validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get("units.width") + "*"} htmlFor="unit" className="inline right-side">
          <Combobox id="widthUnits" name="widthUnits"
                    options={enumToDropdownOptions(enumTypes.WIDTH_UNITS, this.labels, "units.width.")}
                    validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get("units.area") + "*"} htmlFor="woOrientedType" className="inline left-side">
          <Combobox id="areaUnits" name="areaUnits"
                    options={enumToDropdownOptions(enumTypes.AREA_UNITS, this.labels, "units.area.")}
                    validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get("units.weight") + "*"} htmlFor="unit" className="inline right-side">
          <Combobox id="weightUnits" name="weightUnits"
                    options={enumToDropdownOptions(enumTypes.WEIGHT_UNITS, this.labels, "units.weight.")}
                    validate={Validation.required}/>
        </InputSection>

        {this.renderNote()}
      </Dialog>
    );
  }
}

export default reduxForm({
    form: "createOrganizationDialog"
  }
)(CreateOrganizationDialog);

CreateOrganizationDialog.defaultProps = {};
CreateOrganizationDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from 'infrastructure/js/components/controls/Button/button';


require('./form.scss');

export default class Form extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onClick = (buttonObj) => {
    buttonObj.action();
  };


  renderFormButtons(formButtons) {
    if (!formButtons) {
      return null;
    }
    return ( formButtons.map((buttonObj)=> {
        return (
          <Button        id={'id_' + buttonObj.text}
                         key={'id_' + buttonObj.text}
                         bsStyle={buttonObj.bsStyle}
                         onClick={() => this.onClick(buttonObj)}
                         loading={buttonObj.loading}
                         style={buttonObj.style}
                         disabled={buttonObj.disabled}
                         className={buttonObj.className ? `inline ${buttonObj.className}` : 'inline'}
                         icon={buttonObj.icon} >
              {buttonObj.text}
          </Button>
        );
      })
    );
  }

  renderFormCustomButton = (formCustomButton) => {
    if (!formCustomButton) {
      return null;
    }
    return (<div className="form-custom-button">{formCustomButton}</div>);
};

  renderButtons(){
    let {formButtons, formCustomButton} = this.props;

    return (
      <div className="form-buttons">
        {this.renderFormCustomButton(formCustomButton)}
        {this.renderFormButtons(formButtons)}
      </div>);
  }

  render() {
    let { children, className, sData} = this.props;
    //let classNames = className ? ('admin-form ' + className) : ('admin-form');
    let overlayClassName = sData ? (sData.get('loading') ? 'active' : '') : '';

    return (
       <div className={`admin-form ${className}`}>
         {this.renderButtons()}
         {children}
         <div className={`form-overlay ${overlayClassName}`}></div>
      </div>
    );
  }
}

Form.defaultProps = {
};

Form.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any.isRequired,
};




import React from 'react';

import Dialog from 'infrastructure/js/components/Dialog/dialog';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Link from '../../Common/Layout/Link/link';
import { connect } from 'react-redux';

require('./aboutDialog.scss');

class AboutDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.about.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  onHide =() => {
    this.props.actions.hide();
  };

  createFooterButtons() {
    return {
      right: [
        {
          id:'ok',
          text: this.labels.get('button.ok'),
          bsStyle: 'primary',
          action: this.onHide,
        }
      ]
    };
  };

  render() {
    let show = this.props.sData.get('show');
    if (!show) {
      return null;
    }
    return (
      <Dialog id="aboutDialog"
              className='about-dialog'
              show={this.props.show}
              sData={this.props.sData}
              onHide={this.onHide}
              titleText={this.labels.get('title')}
              footerButtons={this.createFooterButtons()}
      >

        <div>{this.labels.get('text1')}</div>
        <div className='top-margin'>
          {this.labels.get('text2')}
          <a  href='https://www.plataine.com/patents' target="_blank"> {this.labels.get('link')}</a>
        </div>

        <div className='top-margin'>{this.labels.get('footer', undefined, { Year: (new Date().getFullYear()), Version: this.props.sServerVersion})}</div>

      </Dialog>
    );
  }
}

export default connect(
  (state) => {
    return {
      sServerVersion:  state.system.get('serverVersion'),
    };
  },
  (dispatch) => { return {}}
)(AboutDialog);



import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as operationsService from '../../../../services/Operations/operationsService';
import * as daHelper from '../../../Dialogs/dialogActionsHelper';
import {dialogsNames} from '../../../../enums/dialogsNames';


const config = {
  getSubmitMethod: (submitConfigData) => {
    return submitConfigData && submitConfigData.isEditMode ?
      operationsService.editOperation : operationsService.createOperation;
  }
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_OPERATION_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_OPERATION_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  fetchDialogData(dispatch, getState) {
    return function() {
      return operationsService.getOperationTypes().then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Operation Types', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        let dialogData = {
          operationTypes: response.dataList.map((obj) => {return {value: obj.id, label: obj.type, data: obj}}),
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return {success: true};
      });
    }
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};


import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import { api as activityLogDialogApi }  from '../../ActivityLogDialog/activityLogDialogActions.js';
import { api as locationPageApi } from '../../LocationPage/locationPageActions.js';
import * as boHelper              from '../batchOperationDialogActionsHelper';
import * as locationsService from '../../../services/Locations/locationsService'
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('BRINGASSETS_DIALOG'),
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('BRINGASSETS_DIALOG'),
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
const config = {
  submitMethod: locationsService.bringAssets,
  dialogReduxStorageName: 'bringAssetsDialog',
  submitWithProgressMethod: locationsService.startBringAssets
};

export let api = {
  ...boHelper.getApiActions(actions, config),
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api)
};



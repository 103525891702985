import React from 'react';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import LabelWithStyleCell from '../../../../Common/CustomGridCells/LabelWithStyleCell/labelWithStyleCell';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./terminologyViewGrid.scss');

export default class TerminologyViewGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: "labelKey", filterName: "key", getOptions: false},
    {fieldName: "defaultValue", filterName: "defaultValue", getOptions: false},
    {fieldName: "actualOrgValue", filterName: "actualOrgValue", getOptions: false},
    {fieldName: "unPublishedValue", filterName: "unpublishedValue", getOptions: false},
  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.terminology.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'labelKey',
        title: this.labels.get('columns.key.title'),
        filterType: filterTypes.SEARCH,
        filterName: 'key',
        width: 260,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'defaultValue',
        title: this.labels.get('columns.defaultValue.title'),
        filterType: filterTypes.SEARCH,
        filterName: 'defaultValue',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
      }
      },
      {
        fieldName: 'actualOrgValue',
        title: this.labels.get('columns.actualOrgValue.title'),
        filterType: filterTypes.SEARCH,
        filterName: 'actualOrgValue',
        width: 220,
        columnOptions: {
          cellComponent: LabelWithStyleCell,
          valueGetter: (params) => {
            return {
              label: params.data.actualOrgValue,
              style: (params.data.defaultValue !== params.data.actualOrgValue) ? {'fontWeight': 'bold'} : null,
            };
          },
        }
      },
      {
        fieldName: 'unPublishedValue',
        title: this.labels.get('columns.replacement.title'),
        width: 210,
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'unpublishedValue',
        filterAlignment: filterAlignmentTypes.RIGHT,
        filterSections: this.getFilterSections(),
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          headerComponentParams: {
            headerIcon: 'info-icon-blue',
            headerTooltip: this.labels.get('columns.replacement.tooltip'),
          }
        }
      }

    ]
  };

  getFilterSections = () => {
    let item = {name: 'unpublishedOnly', label: this.labels.get('columns.replacement.filter'), value: false};
    return [{properties: [item]}];
  };


  getRowHeight = (params) => {
    return 64;
  };

  render() {
    return (
      <div className="pm-terminology-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_SETTINGS_TERMINOLOGY}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}



import { Map } from 'immutable';
import { actionTypes as massOperationsPageActionTypes } from '../../../actions/Administration/MassOperationsPage/massOperationsPageActions.js';
import {MassOperationsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';

let defaultState = {
  loading: false,
  selectedMenuItemId : MassOperationsMenuItemsIds.NAV_ELEMENT_MASS_CREATION
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case massOperationsPageActionTypes.MASSOPERATIONS_PAGE_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);
    case massOperationsPageActionTypes.MASSOPERATIONS_PAGE_SUBMIT_FINISHED:
      return state.set('loading', false);
    case massOperationsPageActionTypes.MASSOPERATIONS_PAGE_SELECTED_MENU_ITEM_CHANGED:
      return state.set('selectedMenuItemId', action.payload);
    default:
      return state;
  }
}













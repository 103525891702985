import React from 'react';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';

import {api as wizardApi} from 'infrastructure/js/components/Wizard/wizardActions';
import importNestsService from '../../../services/ImportNests/importNestsService.js';
import { api as messageDialogApi }      from '../../MessageDialog/messageDialogActions';
import { api as locationPageApi } from '../../LocationPage/locationPageActions';
import Network from 'infrastructure/js/modules/network';
import {gridsNames} from '../../../enums/gridsNames.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as ImportResponseHelper from '../Common/ImportResponseHelper';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import { api as exportApi }      from '../../Export/exportActions.js';
import {isAllAssetsUnrestricted} from '../../../utils/assetsHelper';
import {isAssetRestricted} from '../../../utils/assetHelper';
import importFilesService from "../../../services/ImportFiles/importFilesService";
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

let importNestsWizardApi = wizardApi.createWizardSpecificAdapter('IMPORT_NESTS_ANY_ROLLS_WIZARD');

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  IMPORT_NESTS_ANY_ROLLS_SAVE_START_PARAMETERS: 'IMPORT_NESTS_ANY_ROLLS_SAVE_START_PARAMETERS',
  IMPORT_NESTS_ANY_ROLLS_CLEAR_PARAMETERS :'IMPORT_NESTS_ANY_ROLLS_CLEAR_PARAMETERS',
  IMPORT_NESTS_ANY_ROLLS_HAS_ERRORS: 'IMPORT_NESTS_ANY_ROLLS_HAS_ERRORS',
  IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_IN_PROGRESS: 'IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_IN_PROGRESS',
  IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_FINISHED: 'IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_FINISHED',
  IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_FAILED: 'IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_FAILED',
  IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_IN_PROGRESS: 'IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_IN_PROGRESS',
  IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_FINISHED: 'IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_FINISHED',
  IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_FAILED: 'IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_FAILED',
  IMPORT_NESTS_ANY_ROLLS_HAS_SELECTED_NESTS_ERRORS: 'IMPORT_NESTS_ANY_ROLLS_HAS_SELECTED_NESTS_ERRORS',
  IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_IN_PROGRESS: 'IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_IN_PROGRESS',
  IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_FINISHED: 'IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_FINISHED',
  IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_FAILED: 'IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_FAILED',
  IMPORT_NESTS_ANY_ROLLS_SELECTED_FILE_CHANGED: 'IMPORT_NESTS_ANY_ROLLS_SELECTED_FILE_CHANGED',
  IMPORT_NESTS_ANY_ROLLS_SELECTED_ASSET_TYPE_CHANGED: 'IMPORT_NESTS_ANY_ROLLS_SELECTED_ASSET_TYPE_CHANGED',
  IMPORT_NESTS_ANY_ROLLS_ON_ROWS_CHANGED: 'IMPORT_NESTS_ANY_ROLLS_ON_ROWS_CHANGED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  saveStartParameters: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_SAVE_START_PARAMETERS, payload: payload};
  },
  clearParameters: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_CLEAR_PARAMETERS, payload: payload};
  },
  showErrors: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_HAS_ERRORS, payload: payload};
  },
  showSelectedNestsErrors: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_HAS_SELECTED_NESTS_ERRORS, payload: payload};
  },
  uploadFileInProgress: function () {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_IN_PROGRESS};
  },
  uploadFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_FINISHED, payload: payload};
  },
  uploadFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_UPLOAD_FILE_FAILED, payload: payload};
  },
  validateFileInProgress: function () {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_IN_PROGRESS};
  },
  validateFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_FINISHED, payload: payload};
  },
  validateFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_VALIDATE_FILE_FAILED, payload: payload};
  },
  importFileInProgress: function () {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_IN_PROGRESS};
  },
  importFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_FINISHED, payload: payload};
  },
  importFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_IMPORT_FILE_FAILED, payload: payload};
  },
  onSelectedAssetTypeChanged: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_SELECTED_ASSET_TYPE_CHANGED, payload: payload};
  },
  selectedFileChanged: function (payload) {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_SELECTED_FILE_CHANGED, payload: payload};
  },
  onRowsChanged: function () {
    return {type: actionTypes.IMPORT_NESTS_ANY_ROLLS_ON_ROWS_CHANGED};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.openWizard = function (config, assets=[]) {
  return function (dispatch, getState) {
    api.openImportNestsAnyRollsWizard(dispatch, getState)(config, assets);
  }
};

jsxActions.onWizardClose = function () {
  return function (dispatch, getState) {
    dispatch(actions.clearParameters());
  }
};

jsxActions.uploadFile = function (file, assetType) {
  return function (dispatch, getState) {
    api.uploadFile(dispatch, getState)(file, assetType);
  }
};

jsxActions.validateFile = function (file, rollIds, nestItems) {
  return function (dispatch, getState) {
    api.validateFile(dispatch, getState)(file, rollIds, nestItems);
  }
};

jsxActions.exportValidationReport = function (jobExecutionId) {
  return function (dispatch, getState) {
    api.exportValidationReport(dispatch, getState)(jobExecutionId);
  }
};


jsxActions.onRowsChanged = function () {
  return function (dispatch, getState) {
    api.onRowsChanged(dispatch, getState)();
  }
};

jsxActions.importFile = function (data, messageDialogBuilder, reloadParentComponent) {
  return function (dispatch, getState) {
    api.importFile(dispatch, getState)( data, messageDialogBuilder, reloadParentComponent);
  }
};

jsxActions.hasErrors = function (data) {
  return function (dispatch, getState) {
    api.showErrors(dispatch, getState)(data);
  }
};

jsxActions.onSelectedAssetTypeChanged = function (data) {
  return function (dispatch, getState) {
    api.selectedAssetTypeChanged(dispatch, getState)(data);
  }
};

jsxActions.onSelectedFileChanged = function (file) {
  return function (dispatch, getState) {
    dispatch(actions.selectedFileChanged(file));
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.selectedAssetTypeChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.onSelectedAssetTypeChanged(data));
  }
};

api.onRowsChanged = function (dispatch, getState) {
  return function (nestItems) {
    dispatch(actions.onRowsChanged());
  }
};

api.openImportNestsAnyRollsWizard = function (dispatch, getState) {
  return function (config, assets) {

    importFilesService.checkCutAndKitExistence().then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return;
      }

      let error = response?.applicationResponseStatus?.errors?.subErrors[0];
      if(error){
        let messageDialogDescriptor = dialogHelper.getMessageDialogDescriptorByErrorCode(error.id);
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        return;
      }

      if (!isAllAssetsUnrestricted(assets)) {
        let labels = createLabelHelper('mat.wizards.import.files.reportedCutsAndKitsMultiRolls.validation.');
        let title = labels.get('inspectionStatus.error');
        let type = 'error';
        let className = 'oneBackground';

        let messageDialogDescriptor = {title, type, className};

        if (assets && assets.length > 1) {
          let dialogLabels = createLabelHelper('mat.dialog.');

          className = '';
          title = labels.get('inspectionStatus.multiple.header');

          let assetsIds = assets.filter((item) => {
            return (isAssetRestricted(item));
          });
          assetsIds = assetsIds.map((item) => {
            return (labels.get('inspectionStatus.multiple.roll') + ' ' + item.businessId);
          });

          let children = [];
          children.push(<MessageDialog.MessageRow key="row1" text={labels.get('inspectionStatus.multiple.error')}/>);
          children.push(<MessageDialog.MessageArea key="row2" text={assetsIds.join(', ')}/>);

          let buttons = [{id: 'close', text: dialogLabels.get('close'), action: messageDialogApi.close(dispatch, getState), bsStyle: 'primary'}];
          messageDialogDescriptor = {title, type:'error', children, className, buttons};
        }

        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        return;
      }

      dispatch(actions.saveStartParameters(config));
      importNestsWizardApi.start(dispatch, getState)();
    });
  }
};

api.showErrors = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.showErrors(data));
  }
};

api.uploadFile = function (dispatch, getState) {
  return function (file, assetType) {
    dispatch(actions.uploadFileInProgress());

    let promiseUploadFile = importFilesService.uploadFile(file)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.uploadFileFailed(response));
          dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Upload File Failed',  response);
          return null;
        }

        let locationId =  locationPageApi.getLocationId(dispatch, getState)();
        let selectedRollsId = getState().grid.get(gridsNames.LOCATION_CUTTING).get("selectedRowsData").map((selectedRoll) =>{return selectedRoll.id});

        importNestsService.importNestsAnyRollsFetchItems(response.data.data, selectedRollsId, locationId)
          .then((res) => {
            if (!Network.isResponseValid(res)) {
              dispatch(actions.uploadFileFailed(res));
              dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(res)));
              console.error('Fetch Items Failed' +  ' ' + res);
              return null;
            }
            res.data.plan = res.data?.cutAndKitItemsGroupedByNest[0]?.cutsAndKitsItems[0]?.plan;
            res.data.cutAndKitItemsGroupedByNest.map((obj) => {
              obj.lengthCut = UnitHelper.serverValueToUserValue(unitTypes.LENGTH, obj.lengthCut, 2);
              obj.isRowDisabled = obj.hasErrorProperties;
              obj.lengthCuts = [];
              obj.rollsForRobustCutting.map((item)=>{
                let lengthCutVal = obj.rollsForRobustCutting.length === 1 ? obj.lengthCut : null;
                obj.lengthCuts.push(lengthCutVal);
              })

            });
            dispatch(actions.uploadFileFinished(res.data));
            importNestsWizardApi.gotoStep(dispatch, getState)('step_2');
            return res.data;
          });

      });
    return promiseUploadFile;
  }
};

api.validateFile = function (dispatch, getState) {
  return function (token, rollIds, nestItems) {
    dispatch(actions.validateFileInProgress());

    let updatedNests = [];
    nestItems.map((nest) => {

      nest.rollsForRobustCutting.map((item, index)=>{
        let curLength = nest.lengthCuts[index];
        item.lengthCut =  curLength === null || curLength === '' ? null : UnitHelper.userValueToServerValue(unitTypes.LENGTH,curLength, 2);
      });
      let updatedNest = {...nest, lengthCut: UnitHelper.userValueToServerValue(unitTypes.LENGTH,nest.lengthCut, 2)};
      delete updatedNest.isRowDisabled;
      delete updatedNest.lengthCuts;
      updatedNests.push(updatedNest);
    });

    let queryData = {
      rollIds,
      locationId: locationPageApi.getLocationId(dispatch, getState)(),
      cutAndKitItemsGroupedByNest: updatedNests,
    };
    importNestsService.importNestsAnyRollsValidateFile(queryData)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.validateFileFailed());
          dispatch(actions.showSelectedNestsErrors(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Validate File Failed', response);
          return null;
        }

        if (response.data.generalError || response.data.jobStatus !== 'COMPLETED') {
          dispatch(actions.validateFileFailed());
          _handleValidationError(dispatch, getState)(response);
          return null;
        }

        let validationJobId = response.data.jobExecutionId;
        importNestsService.importNestsAnyRollsGetValidationInfo( {validationJobId} )
          .then((response2) => {
            if (!Network.isResponseValid(response2)) {
              dispatch(actions.validateFileFailed());
              dispatch(actions.showSelectedNestsErrors(ImportResponseHelper.NormalizeResponseError(response2)));
              console.error('Get Validation info failed', response2);
              return null;
            }

            dispatch(actions.validateFileFinished({data: response2.data, token, validationJobId}));
            importNestsWizardApi.gotoStep(dispatch, getState)('step_3');
          });
      });
  }
};

api.exportValidationReport = function (dispatch, getState) {
  return function () {
    let validationJobId = getState().importNestsAnyRolls.get('validationJobId');

    return exportApi.exportValidationReportOfImportNestsWithAnyRoll(dispatch, getState)(validationJobId);
  }
};

api.importFile = function (dispatch, getState) {
  return function (data, messageDialogBuilder, reloadParentComponent) {
    dispatch(actions.importFileInProgress());

    let jobExecutionId = getState().importNestsAnyRolls.get('validationJobId');

    importNestsService.importNestsAnyRollsImportFile({jobId: jobExecutionId})
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.importFileFailed(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Import File Failed' + ' '  + response);
          return null;
        }
        dispatch(actions.importFileFinished(response.data));

        importNestsWizardApi.close(dispatch, getState)();

        if (reloadParentComponent) {
          reloadParentComponent(dispatch, getState);
        }

        if (messageDialogBuilder) {
          // the actionType is used for getting the relevant message (bug 3626)
          response.data.actionType = "createOrUpdate";
          let messageDialogDescriptor = messageDialogBuilder(
            response.data
          );
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }
      });
  }
};


///////////////////////////////////////////////////////////////////

function _handleValidationError (dispatch, getState) {
  return function (response) {
    if (response.data.generalError) {
      let generalErrorLabels = createLabelHelper('mat.wizards.import.files.reportedCutsAndKitsMultiRolls.validation.grid.operationstatus.');
      let responseData = response.data;

      switch (responseData.generalError.labelType) {
        case 'LENGTH_CM':
          dispatch(actions.showSelectedNestsErrors({
            message: generalErrorLabels.get(
              responseData.generalError.labelKey,
              undefined,
              {
                nestsLength: UnitHelper.serverValueToUserValue(unitTypes.LENGTH,responseData.generalError.params.nestsLength, 2),
                rollLength: UnitHelper.serverValueToUserValue(unitTypes.LENGTH,responseData.generalError.params.rollLength, 2)
              })
          }));
          return;
        case 'STRING':
          dispatch(actions.showSelectedNestsErrors({
            message: generalErrorLabels.get(responseData.generalError.labelKey, undefined, responseData.generalError.params)
          }));
          return;

        default:
          console.error('ImportNestsValidateFile unknown generalError labelType: ' + responseData.generalError.labelType);
      }
    }

    if (response.data.jobStatus !== 'COMPLETED') {
      console.error('Validate File Failed', response);
      messageDialogApi.showError(dispatch, getState)();
    }
  }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import Header, {headerItemsLabels} from '../Common/Header/header';
import FullScreenTabsSeparator from '../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';
import OverviewTabContainer from '../../containers/WorkOrderPage/overviewTabContainer.js';
import ActivityLogTabContainer from '../../containers/WorkOrderPage/activityLogTabContainer.js';

import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import ArchiveWorkOrderDialog from './Dialogs/ArchiveWorkOrderDialog/archiveWorkOrderDialog';
import ActivateWorkOrderDialog from './Dialogs/ActivateWorkOrderDialog/activateWorkOrderDialog';
import AttachmentTabContainer from '../../containers/AssetPage/attachmentTabContainer.js';
import CreateAttachmentDialog from '../Dialogs/CreateAttachmentDialog/createAttachmentDialog';
import EditAttachmentDialog from '../Dialogs/EditAttachmentDialog/editAttachmentDialog';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {isWorkOrderArchived} from './utils/workOrderHelper';
import {gridsNames} from '../../enums/gridsNames';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {List} from "immutable";
import {api as workOrderPageApi} from "../../actions/WorkOrderPage/workOrderPageActions";
import {isAssetActivated} from "../../utils/assetHelper";

require('./workOrderPage.scss');

const TabKeys = {
  OVERVIEW: 'overview',
  ACTIVITY_LOG: 'activityLog',
  ATTACHMENT: 'attachment',
};

export default class WorkOrderPage extends Component{
  constructor(props){
    super(props);

    this.labels = createLabelHelper('mat.workorderpage.topbar.');
  }

  componentDidMount() {
    let workOrderId = this.props.match.params.workorderId;
    this.props.actions.workOrderPageActions.init(false , workOrderId);
  }

  componentWillUnmount() {
    this.props.actions.workOrderPageActions.unmount();
  }

  componentDidUpdate(prevProps) {
    let oldId = prevProps.match.params.workorderId;
    let newId = this.props.match.params.workorderId;
    if (newId !== oldId) {
      this.props.actions.workOrderPageActions.init(false ,newId );
    }

    let newTabId = this.props.match.params.tabId;

    //Check if tab changed and resize if needed.
    if(prevProps.match.params.tabId !== newTabId){
      window.dispatchEvent(new Event('resize'));
    }

    if (newTabId && !(Object.values(TabKeys).includes(newTabId))) {
      window.location.hash = '#/PageNotFound';
    }

  }

  getHeaderItems() {
    let {actions} = this.props;

    let items = {
      buttons: [
      ],

      kebab: {
        disabled: !PermissionManager.hasEditAttributesPermissions(),
        menuItems: [
          {
            id:'archive',
            label: headerItemsLabels.ARCHIVE,
            disabled: isWorkOrderArchived(this.props.workOrder.get('workOrderStatus')),
            action: actions.header.onMenuItemArchiveWOClick,
          },
        ]
      },
    };

    if (isWorkOrderArchived(this.props.workOrder.get('workOrderStatus'))) {
      items.buttons.push({
        id:'activate',
        label: headerItemsLabels.ACTIVATE,
        icon: 'pl pl-activate',
        action: actions.header.onActivateWOClick,
      })
    }

    let selectedTab = this.getSelectedTab();
    if (selectedTab === TabKeys.ACTIVITY_LOG) {
      items.buttons.push({
        id:'export',
        icon: 'pl pl-export-icon',
        tooltip: headerItemsLabels.EXPORT,
        action: actions.exportActions.export,
        actionData: {locationId : this.props.workOrder.get('id'), gridName: gridsNames.WO_PAGE_ACTIVITY_LOG}
      })
    }

    if (selectedTab === TabKeys.ATTACHMENT) {

      let attachmentTabGrid = this.props.attachmentTabGrid;
      let hasRows = attachmentTabGrid && attachmentTabGrid.get('rows') ? attachmentTabGrid.get('rows').size > 0 : false;
      let selectedRowsData = attachmentTabGrid ? attachmentTabGrid.get('selectedRowsData') : List();
      let isDeleted = selectedRowsData && selectedRowsData.size && selectedRowsData.get(0).isDeleted;
      items.kebab.menuItems = [
        {
          id: 'downloadAttachment',
          label: headerItemsLabels.DOWLOAD,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.header.onMenuItemDownloadAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
          }
        },
        {
          id: 'editAttachment',
          label: headerItemsLabels.EDIT_CATEGORY,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.header.onMenuItemEditAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
            reloadParentComponent: this.reloadAssetPage
          }
        },
        {
          id: 'deleteAttachment',
          label: headerItemsLabels.DELETE,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.header.onMenuItemDeleteAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
            reloadParentComponent: this.reloadAssetPage
          }
        }
      ];

      items.buttons = [];
      items.buttons.push({
        id:'upload',
        disabled: isWorkOrderArchived(this.props.workOrder.get('workOrderStatus')),
        label: headerItemsLabels.UPLOAD,
        action: actions.header.onUploadFileClicked,
        className: 'no-icon',
        actionData: {
          assetId: this.props.workOrder.get('id'),
          assetType: 'WO',
          reloadParentComponent: this.reloadAssetPage
        }
      })
    }

    return items;
  }

  onTabClick = (tabKey) => {
    if (tabKey) {
      let workorderId = this.props.match.params.workorderId;
      let newHash = `#/WorkOrder/${workorderId}/${tabKey}`;
      if (newHash !== window.location.hash)
        window.location.hash = newHash;
    }
  };

  getSelectedTab = () => {
    let tabId = this.props.match.params.tabId;
    return (tabId ? tabId : TabKeys.OVERVIEW);
  };

  renderTabs = () => {
    let selectedTab = this.getSelectedTab();

    return (
      <Tabs id='topbar-tabs' activeKey={selectedTab} tabType='page' onSelect={this.onTabClick}>
        {this.renderOverviewTab()}
        {this.renderAttachmentTab()}
        {this.renderActivityLogTab()}
        {/*{this.renderRelatedAssetsTab()}*/}
      </Tabs>
    );
  };

  renderOverviewTab = () => {
    return (
      <Tabs.Tab className="overview-tab" eventKey={TabKeys.OVERVIEW} title={this.labels.get('tabs.overview')} animation={false}>
        <OverviewTabContainer/>
      </Tabs.Tab>
    )
  };

  renderAttachmentTab = () => {
    if(!this.props.workOrder || !this.props.workOrder.get('id')) {
      return;
    }
    return (
      <Tabs.Tab className="activity-log-tab" eventKey={TabKeys.ATTACHMENT} title={this.labels.get('tabs.attachments')} animation={false}>
        <AttachmentTabContainer match={this.props.match}/>
      </Tabs.Tab>
    )
  };


  renderActivityLogTab = () => {

    if(!this.props.workOrder || !this.props.workOrder.get('id')) {
      return;
    }

    return (
      <Tabs.Tab className="activity-log-tab" eventKey={TabKeys.ACTIVITY_LOG} title={this.labels.get('tabs.activitylog')} animation={false}>
        <ActivityLogTabContainer match={this.props.match}/>
      </Tabs.Tab>
    )
  };

  // renderRelatedAssetsTab = () => {
  //   return (
  //     <Tabs.Tab eventKey={'relatedAssets'} title={this.labels.get('tabs.relatedassets')} animation={false}>
  //       <RelatedAssetsTabContainer/>
  //     </Tabs.Tab>
  //   )
  // };


  reloadAssetPage = () => {
    this.props.actions.workOrderPageActions.init(true , this.props.workOrder.get('id'));
  };

  renderArchiveWorkOrderDialog = (workOrder) => {
    return (
      <PL_DialogWrapper dialogComponent={ArchiveWorkOrderDialog}
                        workOrder={workOrder}
                        relatedAssets = {this.props.relatedAssets || []}
                        show={this.props.sArchiveWorkOrderDialogData.get('show')}
                        actions={this.props.actions.archiveWorkOrderDialogActions}
                        sData={this.props.sArchiveWorkOrderDialogData}
                        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  renderActivateWorkOrderDialog = (workOrder) => {
    return (
      <PL_DialogWrapper dialogComponent={ActivateWorkOrderDialog}
                        workOrder={workOrder}
                        show={this.props.sActivateWorkOrderDialogData.get('show')}
                        actions={this.props.actions.activateWorkOrderDialogActions}
                        sData={this.props.sActivateWorkOrderDialogData}
                        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  renderCreateAttachmentDialog = (workOrder) => {
    workOrder.isWo = true;
    return (
      <PL_DialogWrapper dialogComponent={CreateAttachmentDialog}
                        asset={workOrder}
                        show={this.props.sCreateAttachmentDialogData.get('show')}
                        actions={this.props.actions.createAttachmentDialogActions}
                        sData={this.props.sCreateAttachmentDialogData}
                        reloadParentComponent={this.reloadAssetPage}/>
    );
  };

  renderEditAttachmentDialog = (workOrder) => {
    workOrder.isWo = true;
    return (
      <PL_DialogWrapper dialogComponent={EditAttachmentDialog}
                        asset={workOrder}
                        show={this.props.sEditAttachmentDialogData.get('show')}
                        actions={this.props.actions.editAttachmentDialogActions}
                        sData={this.props.sEditAttachmentDialogData}
                        preselectedData={this.props.attachmentTabGrid}
                        reloadParentComponent={this.reloadAssetPage}/>
    );
  };


  render() {
    let {workOrder} = this.props;
    let headerItems = this.getHeaderItems();

    return (
      <div>
        <div className='workorder-page'>
          <Header {...headerItems}/>
          <FullScreenTabsSeparator />
          {this.renderTabs()}
        </div>
        { this.renderArchiveWorkOrderDialog(workOrder) }
        { this.renderActivateWorkOrderDialog(workOrder) }
        { this.renderCreateAttachmentDialog(workOrder) }
        { this.renderEditAttachmentDialog(workOrder) }
      </div>
    );
  }
}

WorkOrderPage.propTypes = {
  workOrder : PropTypes.object.isRequired,
  actions : PropTypes.object.isRequired,
};

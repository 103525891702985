import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { gridsNames } from '../../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import { FetchEntitiesFilters } from '../../../../../enums/fetchEntitiesFilters';
import { EntityPropertyTypes } from '../../../../../enums/entityPropertyTypes';

require('./organizationsGrid.scss');

class OrganizationsGrid extends React.PureComponent {
  filterConfig = [{ fieldName: 'name', filterName: 'orgBusinessId', getOptions: false }];

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.organizations.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.name.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'orgBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.ORGS,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities,
        },
        columnOptions: {
          sort: 'asc',
          valueGetter: (params) => {
            return params.data.name;
          },
        },
      },
      {
        fieldName: 'status',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.NONE,
        width: 130,
        columnOptions: {
          sortable: false,
          valueGetter: (params) => {
            return params.data.m_status;
          },
        },
      },
      {
        fieldName: 'locale',
        title: this.labels.get('columns.locale.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          valueGetter: (params) => {
            return params.data.m_locale;
          },
        },
      },
      {
        fieldName: 'orgPreferences.systemDateFormat',
        title: this.labels.get('columns.dateformat.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          sortable: false,
          valueGetter: (params) => {
            return params.data.orgPreferences?.systemDateFormat;
          },
        },
      },
      {
        fieldName: 'orgPreferences.systemTimeFormat',
        title: this.labels.get('columns.timeformat.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          sortable: false,
          valueGetter: (params) => {
            return params.data.m_timeFormatLabel;
          },
        },
      },
      {
        fieldName: 'orgPreferences.systemTimeZone',
        title: this.labels.get('columns.timezone.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          valueGetter: (params) => {
            return params.data.orgPreferences?.systemTimeZone;
          },
        },
      },
    ];
  };

  render() {
    return (
      <div className="organizations-grid">
        <Grid
          gridName={gridsNames.ADMINISTRATION_ORGANIZATIONS_TAB}
          columnsConfig={this.columnsConfig}
          actions={this.props.actions}
          filterConfig={this.filterConfig}
        />
      </div>
    );
  }
}

OrganizationsGrid.propTypes = {
  actions: PropTypes.object,
};

OrganizationsGrid.defaultProps = {
  actions: {},
};

export default reduxForm({
  form: 'organizationsGrid',
})(OrganizationsGrid);

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as productionDashboardPageActions }    from '../../../actions/Reports/productionDashboardPageActions.js';
import ProductionDashboardPage from '../../../components/Reports/Dashboard/ProductionDashboardPage/productionDashboardPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(productionDashboardPageActions, dispatch),
    }
  }
}

let ProductionDashboardPageContainer = connect(


  (state) => {

  	return {
      sData: state.productionDashboardPage,
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps
)(ProductionDashboardPage);


export default ProductionDashboardPageContainer;




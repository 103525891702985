import React from 'react';
import PropTypes from 'prop-types';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Label from 'infrastructure/js/components/Label/label.js';
import cn from 'classnames';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';

require('./toolTypeKitTypeListItem.scss');

export default class ToolTypeKitTypeListItem extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onItemChangeCallback = (value, oldValue) => {
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
  };

  getComponentToRender = (props) => {
    return props && props.data ? <Label text={props.data.label} /> : null;
  };

  render() {
    const { options, name  } = this.props;

    return (
      <div className={cn('tool-type-kit-type-list-item')}>
          <Combobox
            id={name + '.[kitTypeName]'}
            name={name + '.[kitTypeName]'}
            options={options}
            parse={Parse.comboValueOnly()}
            format={Format.findOptionByValue(options)}
            onChangeCallback={this.onItemChangeCallback}
            className=""
            validate={Validation.required}
            singleValueRenderer={this.getComponentToRender}
            optionRenderer={this.getComponentToRender}
          />
        <TextField id={ name + '.[capacity]'}
                   name={ name + '.[capacity]'}
                   className="short-textfield"
                   normalize={Normalize.number(true, 1, 99999)}
                   validate={Validation.required}
        />
      </div>
    );
  }
}

ToolTypeKitTypeListItem.defaultProps = {
  options: [],
};

ToolTypeKitTypeListItem.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
};

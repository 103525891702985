import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as kitTypesService from '../../../../services/KitTypes/kitTypesService.js';
import * as daHelper from '../../../Dialogs/dialogActionsHelper';
import {dialogsNames} from '../../../../enums/dialogsNames';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import {api as exportApi} from '../../../Export/exportActions';


const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.IMPORT_KIT_TYPES_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.IMPORT_KIT_TYPES_DIALOG),

};

export let api = {
  ...daHelper.getApiActions(actions, config),

  submit(dispatch, getState) {
    return function (file, messageDialogBuilder, reloadParentComponent) {
      dispatch(actions.setBusy(true));

      kitTypesService.importKitTypesJson(file).then((response) => {
        dispatch(actions.setBusy(false));
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Import kit types (JSON) failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (messageDialogBuilder) {
          let messageDialogDescriptor = messageDialogBuilder(
            response.data,
            messageDialogApi.close(dispatch, getState)
          );
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }
        return {success: true};
      });
    };
  }
}

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  exportDomSchema() {
    return function (dispatch, getState) {
      exportApi.exportKitTypesDomSchema(dispatch, getState)();
    }
  }
};

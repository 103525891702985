import { api as gridApi} from './groupTypesViewGridActions';

const actions = {
};

export let jsxActions = {};

jsxActions.init = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    api.load(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function(dispatch, getState) {
  return function(shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }
  }
};

api.reload = function(dispatch, getState) {
  return function() {
    gridApi.reload(dispatch, getState)();
  }
};

// export const jsxActions = {
//   init(shouldRefreshGrid) {
//     return function(dispatch, getState) {
//       api.load(dispatch, getState)(shouldRefreshGrid);
//     }
//   }
// };

// export let api = {
//   load(dispatch, getState) {
//     return function(shouldRefreshGrid) {
//       if (shouldRefreshGrid) {
//         gridApi.reload(dispatch, getState)();
//       }
//     }
//   }
// };

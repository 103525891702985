import React from 'react';

import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import Tooltip from "../../../../../infrastructure/js/components/tooltip/tooltip";
require('./detailsRow.scss');

export default class DetailsRow extends React.PureComponent {

  getTooltipIcon = (props) => {
    if(!props.tooltip){
      return null;
    }
    return (
      <AlertPopover
        popoverType={'default'}
        popoverComponent={<div>{props.tooltip}</div>}
      >
      </AlertPopover>
    );
  };

  render() {
    if (this.props.type) {
      return this.props.children;
    }
    let label = this.props.label;
    let isTooltipNeeded = label && label.length > 18 || (label && label.length > 14 && this.props.tooltip);
    if(this.props.tooltip && label && label.length > 14){
      //incase we want to show an icon, we need to avoid the overflow
      label = label.slice(0, 14) + '...';
    }
    return (<div className="details-row">
        <Tooltip placement='bottom' value={isTooltipNeeded ? this.props.label : null}>
        <span className="label">
          {label}
          {this.getTooltipIcon(this.props)}
        </span>
        </Tooltip>
        <div className="value">
          {this.props.children}
        </div>
        {this.props.button}
      </div>
    );
  }
}




import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const getHashUrl = (basePageUrl) => {
  let url = basePageUrl;
  if (!url.startsWith('#/')) {
    if (url.startsWith('/')) {
      url = '#' + url;
    } else {
      url = '#/' + url;
    }
  }

  if (!url.endsWith('/')) {
    url = url + '/';
  }
  return url;
};

const refreshView = () => {
  window.dispatchEvent(new Event('resize'));
};

/**
 * @description This Hook is for simplifying the usage of tabs with hash url.
 * The hook returns 2 functions and 1 object:
 * 1. a function to get the current tab key from url.
 * 2. a function to set the current tab key in the url.
 * 3. all url params as object.
 *
 * In addition, the hook will monitor changes to the url and set the initialTabKey if unknown tabKey was added manually
 * @param {string} basePageUrl the base url of the page that contains the tabs.
 * @param {Array} tabsList array of tab KEYS. All the tabs should be included in this Array.
 * @param {string} initialTabKey the key of the tab that should be opened when the page is first loaded.
 * @returns {Object} returns the url params and 2 functions:
 *
 * getTabKeyFromURL:
 * @returns {string} the key from the URL.
 *
 * setTabKeyInURL:
 * @param {string} key (optional) the key to set in the URL. if not provided, initialTabKey will be set instead.
 * @returns {void}
 * The hook should be used like this:
 *
 * const {getTabKeyFromURL,setTabKeyInURL} = useTabsWithUrl(basePageUrl, tabsList)
 */
const useTabsWithUrl = (basePageUrl, tabsList, initialTabKey, tabNameParamKey = 'tabName') => {
  const baseURL = useMemo(() => getHashUrl(basePageUrl), [basePageUrl]);
  const params = useParams();
  const tabIdFromParams = params[tabNameParamKey];

  const getTabKeyFromURL = useCallback(() => {
    const key = tabIdFromParams ?? window.location.hash.split('/').pop();
    return tabsList.includes(key) ? key : initialTabKey;
  }, [tabsList, initialTabKey, tabIdFromParams]);

  const setTabKeyInURL = useCallback(
    (key) => {
      window.location.hash = `${baseURL}${key ?? initialTabKey}`;
      setTimeout(() => {
        refreshView();
      }, 500);
    },
    [baseURL, initialTabKey]
  );

  if (!tabsList) {
    console.error('useTabsWithUrl: tabsList was not provided!');
    return {};
  }

  if (!basePageUrl) {
    console.error('useTabsWithUrl: basePageUrl was not provided!');
    return {};
  }

  if (!Array.isArray(tabsList)) {
    console.error('useTabsWithUrl: tabsList is not an array!');
    return {};
  }

  //If there is no tabKey param in the Route - this will prevent the user from typing unknown tabKey into the url.
  if (!tabIdFromParams && window.location.hash.includes(baseURL)) {
    window.location.hash = `${baseURL}${getTabKeyFromURL()}`;
  }

  return { getTabKeyFromURL, setTabKeyInURL, params };
};

export default useTabsWithUrl;

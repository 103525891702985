import React from 'react';

import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import ScanHeader from '../Common/ScanHeader/scanHeader';
import classNames from 'classnames';

require('./mainPage.scss');

const tempImg = require('images/scan-illustration.png');

export default class MainPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pageLabels = createLabelHelper('mat.mobile.mainPage.');
  }

  getHeaderItems() {
    let leftButton = null;
    let middleButton = { id: 'middleButton', label: this.pageLabels.get('header.title') };
    let rightButton = null;

    return {
      leftButton: leftButton,
      middleButton: middleButton,
      rightButton: rightButton,
    };
  }

  getMenuItems() {
    return [
      {
        id: 'moveAssetBtn',
        label: this.pageLabels.get('buttons.button.moveAssets'),
        path: '/ScanAsset',
      },
      {
        id: 'attachTagBtn',
        label: this.pageLabels.get('buttons.button.attachTag'),
        path: '/ScanTool',
      },
      {
        id: 'reportProgressBtn',
        label: this.pageLabels.get('buttons.button.reportProgress'),
        path: '/scanWorkOrder',
      },
    ];
  }

  onMenuButtonClick = (button) => {
    let path = button.path;
    this.props.history.push(path);
  };

  createMenuItems = (items) => {
    let menuItems = items.map((item, index) => {
      return (
        <div id={item.id} key={index} className="border-btn main-menu-item" onClick={() => this.onMenuButtonClick(item)}>
          {item.label ? item.label : ''}
        </div>
      );
    });

    return menuItems;
  };

  render() {
    let headerItems = this.getHeaderItems();
    let mainMenuItems = this.createMenuItems(this.getMenuItems());

    return (
      <div className="main-page">
        <ScanHeader {...headerItems} />

        <div className="main-menu">{mainMenuItems}</div>
      </div>
    );
  }
}

MainPage.propTypes = {};

MainPage.defaultProps = {};

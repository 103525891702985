import React, { Component } from 'react';
import Popover from 'infrastructure/js/components/popover/Popover/popover';
import { NotificationsStatuses } from '../../../../../mat/js/enums/notificationsStatuses';
import * as NotificationHelper from '../../../utils/notificationHelper';

require('./notificationItem.scss');


export default class NotificationsItem extends Component {

  constructor (props) {
    super(props);

    this.state = {hoverStatus: null};
  }

  // componentWillMount () { //moved to constructor
  //   this.setState({hoverStatus: null});
  // }

  /* FOR BATCH CATEGORY MESSAGES */
  // renderHeaderLine = (item) => {
  //   return (
  //     <div className="message-header">
  //       {item.header}
  //     </div>
  //   );
  // };
  //
  //
  // renderMessageLines = (item) => {
  //   if (!item.lines) {
  //     return null;
  //   }
  //
  //   return item.lines.map((line, index) => {
  //     return this.renderSingleMessageLine(line, index);
  //   });
  //
  // };

  renderSingleMessageLine = (line, index) => {
    return (
          <div className="message-line" key={'notification-item-line' + index}>
            {/*<div className="line-icon">*/}
              {/*<i className="pl pl-check-circle-icon-blue"></i>*/}
            {/*</div>*/}
            <div className="line-text">
              {NotificationHelper.BuildNotification(this.props.item.labelKey, this.props.item.paramsSet, this.props.item.category)}
            </div>
            {/*<div className="line-info-icon">*/}
              {/*(i)*/}
            {/*</div>*/}
            {/*<div className="line-action-link">*/}
              {/*Redo Import &gt;*/}
            {/*</div>*/}
          </div>
    );

  };

  renderReadStatusCell = (item) => {

    return (
        <div className="status-cell">
          {this.getIconPopOver(item)}
        </div>

    );
  };

  getIcon = (status) => {
    let icon = '';
    switch (status) {
      case NotificationsStatuses.UNREAD:
        icon = 'pl status-icon pl-notification-new-dot unread';
        break;
      case NotificationsStatuses.READ:
        icon = '';
        break;
      case NotificationsStatuses.UNREAD_HOVER:
        icon = 'pl pl-notification-radio-selected';
        break;
      case NotificationsStatuses.READ_HOVER:
        icon = 'pl pl-notification-radio-button';
        break;
      default:
        console.error('NotificationsPageItemStatusCell - wrong status value: ' + status);
    }

    return icon;
  }

  getPopOverMessage = (status) => {
    let message = '';
    switch (status) {
      case NotificationsStatuses.UNREAD:
        message = this.labels.get('item.markasread');
        break;
      case NotificationsStatuses.READ:
        message = this.labels.get('item.markasunread');
        break;
      default:
        console.error('NotificationsPageItemPopOverMessage - wrong status value: ' + status);
    }

    return message;
  }

  handelIconClick = () => {
    let data = {
      id: this.props.item.id
    };

    switch (this.state.hoverStatus) {
      case NotificationsStatuses.UNREAD_HOVER:
        data.status = NotificationsStatuses.READ;
        break;
      case NotificationsStatuses.READ_HOVER:
        data.status = NotificationsStatuses.UNREAD;
        break;
      default:
        console.error('NotificationsPageItemHandelIconClick - wrong status value: ' + status);
    }

    this.props.actions.onReadStatusIconClick(data);

  }

  getPopoverComponent = (value) => {
    return <div>{value}</div>;
  };

  getIconPopOver(data) {

    if(!data) {
      return;
    }

    // In case that the row is hovered , use the status from the hoverStatus (seated by the setStatusOnHover function).
    let status = this.state.hoverStatus ? this.state.hoverStatus : this.props.item.status;

    let icon = this.getIcon(status);
    let message = this.getPopOverMessage(data.status);

    return (<Popover
      popoverId='notifications-page-popover'
      className='notifications-page-item-popover'
      popoverTitle={''}
      popoverComponent={this.getPopoverComponent(message)}
    >
      <label onClick={this.handelIconClick.bind(this)} className={`${icon}`}></label>
    </Popover>);
  }

  setStatusOnHover = (status) => {
    switch (status) {
      case NotificationsStatuses.UNREAD:
        this.setState({ hoverStatus: NotificationsStatuses.UNREAD_HOVER});
        break;
      case NotificationsStatuses.READ:
        this.setState({ hoverStatus: NotificationsStatuses.READ_HOVER});
        break;
      default:
        console.error('NotificationsPageItemSetStatusOnHover - wrong status value: ' + status);
    }
  }

  // Change the local state according to the item state on hover.
  // This state change displays an icon that represents the action that can be performed on hover according to status.
  handelOnMouseHover = () => {
    this.setStatusOnHover(this.props.item.status);
  }

  // Change the local state to the item's original status on leave.
  handelOnMouseLeave = () => {
    this.setState({ hoverStatus: null});
  }


  renderMessageCell = (item) => {

    // SPECIAL TREATMENT FOR BATCH OPERATION NOTIFICATION TYPE. (IN FUTURE).
    // It features a header and multiple message lines, each with its own icon.
    /*if (item.category === 'BATCH_OPERATION') {
      return (
          <div className="message-cell">
            {/!*{this.renderHeaderLine(item)}*!/}
            {/!*{this.renderMessageLines(item)}*!/}
            {this.renderSingleMessageLine(item)}
          </div>
      );
    }*/


    // REGULAR MESSAGE LINE RENDER.
    return (
        <div className="message-cell">
          {this.renderSingleMessageLine(item)}
        </div>
    );

  };




  render() {

    this.labels = this.props.labels;

    let item = this.props.item;
    let isUnreadClass = (this.props.item.status ===  NotificationsStatuses.UNREAD) ? " unread-notification" : "";

    return (
      <div className={'page-notifications-item' + isUnreadClass} onMouseOver={this.handelOnMouseHover} onMouseLeave={this.handelOnMouseLeave}>

        <div className='page-notifications-row'>

          {this.renderReadStatusCell(item)}

          <div className="time-cell">
            {item.m_CreatedTime}
          </div>

          {/*message-cell*/}
          {this.renderMessageCell(item)}

        </div>

      </div>
    )
  }

};












import cn from 'classnames';
import PropTypes from 'prop-types';

require('./checkboxCell.scss');

export default function CheckboxCell({ data }) {
  return (
    <label className={cn('checkbox', data?.isRowDisabled ? 'disabled' : '')}>
      <i className="fa fa-check"/>
    </label>
  );
}

CheckboxCell.propTypes = {
  data: PropTypes.object,
};

import {api as systemApi} from '../../System/systemActions';
import {navigationStates} from '../../../enums/navigationStates';
import {api as gridApi} from './materialScrapGridActions';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {api as navigatorApi} from '../../AppTopbar/navigatorActions';

export let actionTypes = {

};

let actions = {

}

export let api = {

}

export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid) {
  return function (dispatch, getState) {

    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.MATERIAL_SCRAP_VIEW);

    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }

    let topNavigatorData = {
      name: createLabelHelper('mat.header.navigator.predefinedlists.').get('materialScrap'),
      type: navigationStates.MATERIAL_SCRAP_VIEW,
      id: -1,
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);
  }
};

const filterName = {
  AssetAlert: 'assetAlert'
};

const assetAlertFilterValues = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

const getAssetAlertFilter = function (filter) {
  return filter.filterName === filterName.AssetAlert;
};

const setAssetAlertFilterValues = function (filter) {
  return filter.values && filter.values.length > 0 ? filter.values : filter.values = [assetAlertFilterValues.INFO, assetAlertFilterValues.WARNING, assetAlertFilterValues.ERROR];
};

const pushAssetAlertFilter = function (query) {
  return query.filters.push({
    filterName: filterName.AssetAlert,
    shouldCreateFilterOptions: false,
    values: [assetAlertFilterValues.INFO, assetAlertFilterValues.WARNING, assetAlertFilterValues.ERROR]
  });
};

const getAssetAlertFilteredQuery = function getAssetAlertFilteredQuery(query) {

  let assetAlertFilter = query.filters.find((filter) => {
    return getAssetAlertFilter(filter);
  });

  if (!assetAlertFilter)
    query.filters = pushAssetAlertFilter(query);
  else
    setAssetAlertFilterValues(assetAlertFilter);

  return query;
};

export default {getAssetAlertFilteredQuery};

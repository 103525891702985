import {IsJsonString} from 'infrastructure/js/utils/JSON.js';

let loggedInUser = localStorage['loggedInUser'];
if (!(loggedInUser = IsJsonString(loggedInUser))) {
  loggedInUser = undefined;
}

export const TOOL_TYPES = {
  PREV_TOOL_ID: 'PREV_TOOL_ID',
};

const LabelFormatTypes = {
  BusinessIdFirst:'BUSINESS_ID_FIRST',
  NameFirst:'NAME_FIRST'
};

let toolTypeHelperInstance = function () {
  let obj = {};
  //default value
  let _formatType = LabelFormatTypes.NameFirst;

  obj.init = function (toolTypeFieldOrder){
    if(!toolTypeFieldOrder){
    return;
    }
    _formatType = toolTypeFieldOrder;
  }
  if (loggedInUser && loggedInUser.loggedInOrg.orgPreferences) {
    obj.init(loggedInUser.loggedInOrg.orgPreferences['toolTypeFieldOrder']);
  }

  obj.getToolTypeFullLabel = function (name , code) {
    let firstLabel = this.getToolTypeFirstLabel(name, code);
    let firstParam = firstLabel ? firstLabel : '';
    let secondLabel = this.getToolTypeSecondLabel(name, code);
    let secondParam = secondLabel ? (firstLabel ? `(${secondLabel})` : secondLabel) : '';
    return `${firstParam} ${secondParam}`;
  };

  obj.getToolTypeFirstLabel = function (name , code) {
    return _formatType === LabelFormatTypes.NameFirst ? name: code;
  }

  obj.getToolTypeSecondLabel = function (name , code) {
    return _formatType === LabelFormatTypes.NameFirst ? code: name;
  }

  obj.getToolTypeAvailableLabel = function (name , code) {
    return name ? name : code;
  };

  obj.convertToToolTypeItem = function(toolType){
    return { value: toolType.id, label:this.getToolTypeFullLabel(toolType.toolName,toolType.businessId), data: toolType };
  }

  return obj;
}();

export default toolTypeHelperInstance;

import { Map, List } from 'immutable';
import { actionTypes as changeLocationPageActionTypes } from '../../../actions/Mobile/ChangeLocationPage/changeLocationPageActions.js';

let defaultState = {
  locations: null,
  loading: false,
  error : null,
  resultData : null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case changeLocationPageActionTypes.CHANGELOCATION_PAGE_SUBMIT_IN_PROGRESS:
      state = state.set('loading', true)
                   .set('resultData', null)
                   .set('error', null);
      return state;
    case changeLocationPageActionTypes.CHANGELOCATION_PAGE_SUBMIT_FINISHED:
      state = state.set('loading', false)
                   .set('resultData', action.payload)
                   .set('error', null);
      return state;
    case changeLocationPageActionTypes.CHANGELOCATION_PAGE_SUBMIT_FAILED:
      state = state.set('loading', false)
                   .set('error', action.payload);
      return state;
    case changeLocationPageActionTypes.CHANGELOCATION_PAGE_FETCH_LOCATIONS_READY:
      return state.set('locations', action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.name} }));

    default:
      return state;
  }
}













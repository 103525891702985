import React, {Component} from 'react';

import PL_Wizard  from 'infrastructure/js/components/Wizard/wizard';
import EditWeightDialog from './EditWeightDialog/editWeightDialog';
import DisplayWeightStep from './DisplayWeightDialog/displayWeightStep';
import WeightValidationStep from './WeightValidationStep/weightValidationStep';

require('./weightWizard.scss');

export default class WeightWizard extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  getSteps = ()=> {
    return {
      'step_1': <EditWeightDialog /> ,
      'step_2': <DisplayWeightStep />,
      'step_3': <WeightValidationStep />
    };
  };

  render() {
    return (
      <PL_Wizard wizardName="WEIGHT_WIZARD" steps={this.getSteps()} {...this.props} form="wizard_form_WEIGHT_WIZARD"  />
    );
  }

}


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import { jsxActions as commonPdfLabelSettingsViewActions} from '../../../../actions/Administration/SettingsPage/commonPdfLabelSettingsViewActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import KitPdfLabelSettingsView from '../../../../components/Administration/SettingsPage/PdfLabelSettings/KitPdfLabelSettingsView/kitPdfLabelSettingsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonPdfLabelSettingsViewActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch)
    }
  }
}

let KitPdfLabelSettingsViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonPdfLabelSettingsView']),
      isDirty: isDirty('pdfLabelSettingsForm')(state),
    };
  },

  mapDispatchToProps
)(KitPdfLabelSettingsView);

export default KitPdfLabelSettingsViewContainer;




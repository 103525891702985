
import * as AppHelper from 'infrastructure/js/utils/appHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  NETWORK_OFFLINE_MODE_ON: "NETWORK_OFFLINE_MODE_ON",
  NETWORK_OFFLINE_MODE_OFF:   "NETWORK_OFFLINE_MODE_OFF",
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  OfflineModeOn: function(payload) {
    return {type: actionTypes.NETWORK_OFFLINE_MODE_ON, payload };
  },
  OfflineModeOff: function() {
    return {type: actionTypes.NETWORK_OFFLINE_MODE_OFF };
  },
};


export let api = {};

api.OfflineModeOn = (serverHealthCheck) => (dispatch, getState) =>{
  if(getState().system.getIn(['indicatorBarData', 'offlineMode']) === false){
    let intervalID = setInterval(serverHealthCheck, 5000);
    dispatch(actions.OfflineModeOn(intervalID));
  }
};

api.OfflineModeOff = () => (dispatch, getState) =>{
  if(getState().system.getIn(['indicatorBarData', 'offlineMode']) === true){
    let intervalID = getState().system.getIn(['indicatorBarData', 'offlineModeIntervalId'])
    if (!!intervalID) {
      clearInterval(intervalID);
    }
    dispatch(actions.OfflineModeOff());
    if (!AppHelper.isMobileApp()) {
      window.location.reload();
    }
  }
};









import { api as locationPageApi } from '../locationPageActions.js';
import * as toolsService from '../../../services/Tools/toolsService';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from '../../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from '../../../../../infrastructure/js/utils/dateTimeHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_ASSET_TOOLS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    return toolsService.getToolsDataByStationId(locationId,query);
  }
}

function _convertToRowData(rows, serverTimeSeconds){
  let items = rows.map((obj) => {
    obj.cyclesCount = (obj.maxNumOfCycles !== null) ? (obj.maxNumOfCycles - (obj.maxNumOfCycles - obj.currentCyclesNumber)) : null;
    obj.m_maintenanceUsageTime = DateTimeHelper.ConvertMinutesToDaysHoursMinutes(obj.maintenanceUsageTime);
    if (obj.lastOperation) {
      obj.lastOperation.m_time =
        obj.lastOperation.createdDate ? DateTimeHelper.FormatDateObjectToRelativeTime(obj.lastOperation.createdDate, serverTimeSeconds) : null;
    }
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

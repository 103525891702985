import { Map } from 'immutable';
import { actionTypes } from '../../../actions/Administration/MatSettingsPage/weeklyShiftsViewActions';

let defaultState = {
  weekStartDate: null
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.WEEKLY_SHIFTS_WEEK_START_DATE_CHANGED:
      return state.set('weekStartDate', action.payload);

    default:
      return state
  }

}

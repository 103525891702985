import { api as loginActions } from "../actions/Login/loginActions";
import PermissionManager from "infrastructure/js/utils/permissionManager";
import { isPresenterUser } from "./userHelper";
import { IsJsonString } from "infrastructure/js/utils/JSON";
//check every half minute if user is idle
window.setInterval(CheckIdleTime, 1000 * 30);

export function checkInactivityTime() {
  // DOM Events
  window.onload = resetTimer;
  document.onkeypress = resetTimer;
  document.onclick = resetTimer;
}

function CheckIdleTime() {
  //returns user object or false
  let loggedInUser = IsJsonString(localStorage["loggedInUser"]);

  if (!loggedInUser) {
    return; //already logged out
  }

  if (isPresenterUser(loggedInUser)) {
    return;
  }

  let passedTimeInMilliSec = Date.now() - Number(localStorage.getItem("lastInteraction"));

  //in seconds
  let sessionTimeOut = PermissionManager.getOrgPreferences().maxInactiveIntervalSeconds;
  if (!sessionTimeOut || isNaN(sessionTimeOut)) {
    sessionTimeOut = 7200;
  }

  let threshold = 1000 * sessionTimeOut;
  if (threshold < passedTimeInMilliSec) {
    loginActions.logout(true)();
  }
}

function resetTimer() {
  localStorage.setItem("lastInteraction", Date.now().toString());
}

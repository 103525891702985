import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as importWizardActions } from '../../../actions/Wizards/ImportNestsWithRoll/importNestsWithRollWizardActions.js';
import importNestsWithRollWizard from '../../../components/Wizards/ImportNestsWithRoll/importNestsWithRollWizard';
import {gridsNames} from '../../../enums/gridsNames.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(importWizardActions, dispatch)
    }
  }
}


let ImportNestsWithRollWizardContainer = connect(

  (state) => {
    return {
      importTypes: state.importNestsWithRoll.get('importTypes'),
      acceptFileTypes:state.importNestsWithRoll.get('acceptFileTypes'),
      loading:state.importNestsWithRoll.get('loading'),
      sErrors: state.importNestsWithRoll.get('errors'),
      sNestsErrors: state.importNestsWithRoll.get('nestsErrors'),
      sValidatedFileData: state.importNestsWithRoll.get('validatedFileData'),
      sFileToken:state.importNestsWithRoll.get('fileToken'),
      sSelectedAseetType:state.importNestsWithRoll.get('selectedAssetType'),
      sSelectedFile: state.importNestsWithRoll.get('selectedFile'),
      currentDate:state.importNestsWithRoll.get('currentDate'),
      parentComponent:state.importNestsWithRoll.get('parentComponent'),

      rollId:state.importNestsWithRoll.get('rollId'),
      materialBusinessId:state.importNestsWithRoll.get('materialBusinessId'),
      nestsItems: state.importNestsWithRoll.get('nestsItems'),
      selectedNests: state.grid.get(gridsNames.IMPORT_NESTS_WITH_ROLL_SELECT_NESTS) ? state.grid.get(gridsNames.IMPORT_NESTS_WITH_ROLL_SELECT_NESTS).get('selectedRowsData') : List(),
      selectedNestsStepTwo: state.grid.get(gridsNames.IMPORT_NESTS_WITH_ROLL_SELECT_NESTS_STEP_TWO) ? state.grid.get(gridsNames.IMPORT_NESTS_WITH_ROLL_SELECT_NESTS_STEP_TWO).get('selectedRowsData') : List(),
    };
  },
  mapDispatchToProps
)(importNestsWithRollWizard);

export default ImportNestsWithRollWizardContainer;



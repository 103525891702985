import React, {Component} from 'react';
import {reduxForm} from 'redux-form';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import ScanHeader from '../Common/ScanHeader/scanHeader';
import ComboBox from 'infrastructure/js/components/controls/Combobox/combobox';
import classNames from 'classnames';

require('./changeLocationPage.scss');

class ChangeLocationPage extends React.PureComponent {
  constructor(props) {
    super(props);

    let scannedAssets = this.props.sData.get('scannedAssets');
    if (!scannedAssets || scannedAssets.size === 0) {
      this.props.history.push('/ScanAsset');
    }
    this.allAssetsLocationId = this.getAllAssetsLocationId(scannedAssets);

    this.labels = createLabelHelper('mat.mobile.scan.');
    this.pageLabels = createLabelHelper('mat.mobile.changeLocationPage.');

    this.state = {
      selectedLocation: null,
      menuIsOpen: false
    }
  }

  componentDidMount() {
    this.props.actions.init();
  }

  // If all assets have the same location id - returns it
  getAllAssetsLocationId = (scannedAssets) => {
    if (!scannedAssets || scannedAssets.size === 0) {
      return null;
    }

    let locationIds = [];
    scannedAssets.forEach((asset) => {
      locationIds.push(asset.locationId);
    });

    let locationId  = locationIds[0];
    let filtered = locationIds.filter((id) => {return id === locationId});

    return (locationIds.length === filtered.length) ? locationId : null;
  };

  onSelectLocationHandler = (value, oldValue) => {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      this.setState({selectedLocation:  null});
      return;
    }
    this.setState({selectedLocation:  value});
  };

  onKeyDownHandler = (e) => {
    if (e.keyCode === 13 && !this.state.menuIsOpen) {
      this.props.handleSubmit(this.onSubmit)();
    }
  };
  onMenuOpenHandler = () => {
    this.setState({menuIsOpen: true});
  };

  onMenuCloseHandler = () => {
    this.setState({menuIsOpen: false});
  };

  getLocations = () => {
    let locations = this.props.sPageData.get('locations');
    if (locations && this.allAssetsLocationId) {
      locations = locations.filter((item) => {return item.value !== this.allAssetsLocationId});
    }
    return locations ? locations : [];
  };

  onSubmit = (data) => {
    let assetsIds = this.props.sData.get('scannedAssets').map((asset) => {
      return asset.id});

    let newData = {
      assetIds : assetsIds.toJS(),
      destination: data.location.value,
      ignoreValidationWarnings: true
    };

    this.props.actions.submit(newData, data.location.label, this.onSubmitCallback );
  };

  onSubmitCallback = () => {
    let path = '/ChangeLocationResult';
    this.props.history.push(path);
  };

  getHeaderItems() {
    let scannedAssets = this.props.sData.get('scannedAssets');

    return {
      leftButton: {
        id: 'leftButton',
        icon: 'pl pl-delete-icon',
        action: this.props.actions.header.onRemoveAllScans,
        actionData: {data: this.props.history, withConfirmation: true},
      },
      rightButton: {
        id: 'rightButton',
        icon: 'pl pl-icon-add',
        action: this.props.actions.header.onNewScan,
        actionData: this.props.history,
      },
      middleButton: {
        id: 'middleButton',
        label: this.labels.get('header.buttons.button.scanned', undefined, {assetsCount: scannedAssets.size}),
        action: this.props.actions.header.onShowAllScans,
        actionData: this.props.history,
      }
    };
  };

  render() {

    let headerItems = this.getHeaderItems();
    let errorText = this.props.sPageData.get('error');
    let loading = this.props.sPageData.get('loading');

    //var isTouch = ( !!window.ontouchstart || (navigator.msMaxTouchPoints > 0));

    return (
      <div className="change-location-page">
        <ScanHeader {...headerItems}/>

        <div className="content">
          <div className="location-input">
            <label>{this.pageLabels.get('input.label')}</label>
            <ComboBox
              id="change-location"
              name="location"
              className="select-location"
              onKeyDown={this.onKeyDownHandler}
              onMenuOpen={this.onMenuOpenHandler}
              onMenuClose ={this.onMenuCloseHandler}
              autoFocus
              options={this.getLocations()}
              placeholder={this.pageLabels.get('input.placeholder')}
              // blurInputOnSelect={isTouch}
              blurInputOnSelect={false}
              onChangeCallback={this.onSelectLocationHandler}
            />
            <div className="barcode-input-error">
              <label>{errorText}</label>
            </div>
          </div>

          <Button bsStyle="primary" className="change-location-btn"
                  id='change-location-btn'
                  disabled={!this.state.selectedLocation || loading }
                  loading={loading}
                  onClick={this.props.handleSubmit(this.onSubmit)}>
            {this.pageLabels.get('button.changeLocation')}
          </Button>

          {/*<div className="location-image">*/}
            {/*<span  className="pl pl-header-icon-location" />*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }
}

export default reduxForm({
    form: 'changeLocationPage',
  }
)(ChangeLocationPage);


ChangeLocationPage.propTypes = {
};

ChangeLocationPage.defaultProps = {
};


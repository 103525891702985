import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';

require('./additionalFieldListItem.scss');

export default class AdditionalFieldListItem extends React.PureComponent {

  getComponentToRender(props){
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;
    return  <span>{option.label}</span>
  };

  render() {
    let {options, name} = this.props;
    return (<div className="additional-field-list-item">

      <TextField id={name + '.[displayName]'}
                 name={name + '.[displayName]'}
                 className="short-textfield"
                 validate={Validation.required}
                 maxLength={40}/>
      <Combobox
        id={ name + '.[type].[type]'}
        name={ name + '.[type].[type]'}
        options={options}
        parse={Parse.comboValueOnly()}
        format={Format.findOptionByValue(options)}
        validate={Validation.dropdown.required}
        menuPlacement="auto"
      />
    </div>);
  }
}

AdditionalFieldListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

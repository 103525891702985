import { Map } from 'immutable';

import {default as importAttributesUpdateWizardReducer} from './ImportAttributesUpdateWizard/importAttributesUpdateWizardReducer';
import {default as importAssetsViaBarcodeWizardReducer} from './ImportAssetsViaBarcodeWizard/importAssetsViaBarcodeWizardReducer';
import {default as afpOperationWizardReducer} from './AfpOperationWizard/afpOperationWizardReducer';

let defaultState = {

};

export default function(state = Map(defaultState), action) {

  state = state.set('importAttributesUpdateWizard', importAttributesUpdateWizardReducer(state.get('importAttributesUpdateWizard'), action));
  state = state.set('importAssetsViaBarcodeWizard', importAssetsViaBarcodeWizardReducer(state.get('importAssetsViaBarcodeWizard'), action));
  state = state.set('afpOperationWizard', afpOperationWizardReducer(state.get('afpOperationWizard'), action));

  return state;

}







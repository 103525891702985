import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

export function createJobTitle(jobName, numberOfItems, labels) {

  switch(jobName) {
    case 'importRollsJob':
    case 'importKitsJob':
    case 'importWorkOrdersJob':
    case 'importToolsJob':
    case 'importTagsJob':
    case 'importKitTypesJob':
    case 'importCutAndKitsJob':
    case 'importRollsFromBarcodeScansJob':
      return labels.get('title.importItemsJob', '', {count: numberOfItems});

    case 'moveAssetsJob':
      return labels.get('title.moveAssetsJob', '', {count: numberOfItems});

    case 'updateAssetsStatusJob_active':
    case 'updateAssetsStatusJob_completed':
    case 'updateAssetsStatusJob_in_progress':
      return labels.get('title.updateAssetsStatusJob.activate', '', {count: numberOfItems});

    case 'updateAssetsStatusJob_consumed':
    case 'updateAssetsStatusJob_disqualified':
    case 'updateAssetsStatusJob_shipped':
    case 'updateAssetsStatusJob_canceled':
    case 'updateAssetsStatusJob_retired':
      return labels.get('title.updateAssetsStatusJob.archive', '', {count: numberOfItems});

    case 'updateWorkOrdersStatusJob_completed':
      return labels.get('title.updateWorkOrdersStatusJob.completed', '', {count: numberOfItems});

    case 'updateWorkOrdersStatusJob_disqualified':
    case 'updateWorkOrdersStatusJob_canceled':
      return labels.get('title.updateWorkOrdersStatusJob.archive', '', {count: numberOfItems});

    case 'updateWorkOrdersStatusJob_active':
    case 'updateWorkOrdersStatusJob_in_progress':
      return labels.get('title.updateWorkOrdersStatusJob.activate', '', {count: numberOfItems});

    case 'updateRollsJob':
    case 'updateKitsJob':
    case 'updateToolsJob':
      return labels.get('title.updateAssetJob', '', {count: numberOfItems});

    case 'updateWorkOrdersJob':
      return labels.get('title.updateWorkOrdersJob', '', {count: numberOfItems});

    case 'updateShelfLifeJob':
      return labels.get('title.updateShelfLifeJob', '', {count: numberOfItems});

    case 'createHooksJob':
      return labels.get('title.createHooksJob', '', {count: numberOfItems});

    case 'printTagsJob':
    case 'monitorPrintAssetsTagsJob':
    case 'monitorPrintGenericTagsJob':
      return labels.get('title.printTagsJob', '', {count: numberOfItems});

    case 'updateImportedRollsJob':
      return labels.get('title.updateImportedRollsJob', '', {count: numberOfItems});
  }
  return '';
}

export function createJobSubTitle(processedJobItems, totalJobItems, labels) {
  return labels.get('subtitle.processedOfTotal', '', {processedJobItems, totalJobItems});
}




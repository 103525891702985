import {List, Map} from 'immutable';
import { actionTypes as kittingViewActionTypes }      from '../../actions/LocationPage/KittingView/kittingViewActions';

let defaultState = {
  reportedCuts: List(),
 };

export default function(state = new Map(defaultState), action) {
  switch (action.type) {

    case kittingViewActionTypes.CUTKITSTATION_KITTING_REPORTED_CUTS_FETCH_FINISHED:
      return state.set("reportedCuts", action.payload);

    default:
      return state;

  }
}






import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedWizardDialog } from 'infrastructure/js/components/Dialog/WizardDialog/wizardDialog';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import ScanAssetsStep from './Steps/ScanAssetsStep/scanAssetsStep';
import AssetsValidateStep from '../Common/Steps/AssetsValidateStep/assetsValidateStep';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import {ImportTypes} from '../../../enums/importTypes';

import './ImportAssetsViaBarcodeWizard.scss';

function mapStateToProps(state) {
  return {
    sData: state.wizards.get('importAssetsViaBarcodeWizard')
  };
}

export default class ImportAssetsViaBarcodeWizard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = this.getLabelsByImportType();
    this.validationLabels = createLabelHelper('mat.wizards.importViaBarcode.');
    this.wizardLabels = createLabelHelper('mat.dialog.wizard.');
    this.assetType = this.props.sData?.get('importType');

    this.steps = [
      {
        name: this.labels.get('scanAssetsStep.name'),
        component: (dialogProps) =>
          <ScanAssetsStep
            {...dialogProps}
            labels={this.labels}
            onBarcodeScanned={this.onBarcodeScanned}
          />
      },
      {
        name: this.labels.get('validateAssetsStep.name'),
        component: (props) =>
          <AssetsValidateStep
            {...props}
            validatedFileData={this.props.sData?.get('validatedFileData')}
            operationStatusLabels={this.validationLabels}
            labels={this.labels}
            assetType={this.assetType}
          />,
      },
    ];
    this.formComponent = ConnectedWizardDialog(mapStateToProps);
  }

  getLabelsByImportType = () => {
    let importType = this.props.sData?.get('importType');
    switch(importType){
      case ImportTypes.IMPORT_ROLLS:
        return createLabelHelper('mat.wizards.importViaBarcode.rolls.');
      case ImportTypes.IMPORT_SPOOLS:
        return createLabelHelper('mat.wizards.importViaBarcode.spools.');
      default:
        console.log('getLabelsByImportType(): unknown import type: ', this.props.importType);
        return null;
    }
  }

  onBarcodeScanned = (scanData) => {
    this.props.actions.scanBarcode(scanData, this.assetType);
  }


  onSubmit = (nextStep, currentStep) => {
    switch (currentStep) {
      case 0: {
        this.props.actions.submitScanAssetsStep(nextStep, this.assetType);
        return;
      }
    }
  };

  onFinish = () => {
    this.props.actions.confirmImport(this.assetType, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get('confirmation.message.title', 'Confirmation', { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title, false);
    };
  };

  getCloseConfirmationDialog = () => {
    return (
      {
        titleText: this.wizardLabels.get('closeConfirmation.title'),
        contents:
          <div>
            <MessageDialog.DataRow key={'row1'} label={this.wizardLabels.get('closeConfirmation.line1')} value={''}/>
            <MessageDialog.DataRow key={'row2'} label={this.wizardLabels.get('closeConfirmation.line2')} value={''}/>
          </div> ,
      }
    );
  }

  render() {
    return <this.formComponent
      show={this.props.sData.get('show')}
      id="importAssetsViaBarcodeWizard"
      tabType="wizard-horizontal"
      steps={this.steps}
      titleText={this.labels.get('title')}
      closeConfirmationDialog={this.getCloseConfirmationDialog()}
      defaultButtons={{
        onNextButtonClick: this.onSubmit,
        onFinishButtonClick: this.onFinish
      }}
      onClose={this.props.actions.hide}
      loading={this.props.sData.get('loading')}
    />;
  }
}

ImportAssetsViaBarcodeWizard.propTypes = {
  sData: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import ItemsList from './ItemsList/itemsList';

const defaultGetIterationKey = (item) => {
  if (typeof item === 'object') {
    return item.id;
  }
  return item;
};

const ScanMultiItems = ({
  value,
  item: Item,
  placeholder,
  onRemoveItem,
  onChange,
  getIterationKey,
  getInvalidItems,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState([]);
  const [error, setError] = useState('');

  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
  };

  const { invalidItems, computedItems } = useMemo(() => {
    const { computedItems, invalidItems } = getInvalidItems(items);
    return { invalidItems, computedItems };
  }, [items, getInvalidItems]);

  useEffect(() => {
    const numOfInvalidItems = invalidItems.length;
    const message =
      numOfInvalidItems > 0
        ? `${numOfInvalidItems} item${numOfInvalidItems > 1 ? 's' : ''} ${
            numOfInvalidItems > 1 ? 'are' : 'is'
          } invalid.`
        : '';
    setError(message);
  }, [invalidItems]);

  useEffect(() => {
    if (Array.isArray(value)) {
      setItems(value);
    }
  }, [value]);

  const handleAddOnClick = () => {
    const trimmedValue = inputValue.trim();
    const isInList = items.some(({ TagKey }) => TagKey === trimmedValue);
    if (!isInList) {
      const newItems = [...items, { tagKey: trimmedValue, id: Math.random() }];
      setItems(newItems);
      onChange(newItems);
      setInputValue('');
      setError('');
    } else {
      setError('Item is already exist!');
    }
  };

  const handleRemoveItem = (removedItem) => {
    const id = getIterationKey(removedItem);

    if (onRemoveItem) {
      onRemoveItem(removedItem);
    } else {
      const newItems = items.filter((item) => getIterationKey(item) !== id);
      setItems(newItems);
      onChange(newItems);
    }
  };

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      handleAddOnClick();
    }
  };

  return (
    <div className="scan-input-container">
      <div className="barcode-input">
        <input
          className="form-control"
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {inputValue ? (
          <button type="button" className="multi-barcode-input-add" onClick={handleAddOnClick}>
            +
          </button>
        ) : null}
      </div>
      <div className="multi-barcode-error">{error}</div>
      {value?.length ? (
        <ItemsList items={computedItems} item={Item} onRemoveItem={handleRemoveItem} />
      ) : null}
    </div>
  );
};

ScanMultiItems.propTypes = {
  value: PropTypes.array,
  item: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  placeholder: PropTypes.string,
  onRemoveItem: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onChange: PropTypes.func,
  getIterationKey: PropTypes.func,
  getInvalidItems: PropTypes.func,
};

ScanMultiItems.defaultProps = {
  value: [],
  item: null,
  placeholder: '',
  onRemoveItem: false,
  onChange: () => false,
  getIterationKey: defaultGetIterationKey,
  getInvalidItems: (items) => ({
    computedItems: items,
    invalidItems: [],
  }),
};

export default ScanMultiItems;

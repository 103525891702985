import { actionTypes as activityLogDialogActionTypes } from '../../actions/ActivityLogDialog/activityLogDialogActions';
import { default as activityLogDialogGridReducer } from './activityLogDialogGridReducer';
import { Map } from 'immutable';

let defaultState = Map({
  id: 'activityLogDialog',
  activityLogTitle : '',
  show : false,
  data : Map({}),
});


export default function(state = Map(defaultState), action) {

  state = state.set('gridData', activityLogDialogGridReducer(state.get('gridData'), action));
  switch (action.type) {

    case activityLogDialogActionTypes.ACTIVITYLOG_DIALOG_SHOW:
      return state.set("show", true);

    case activityLogDialogActionTypes.ACTIVITYLOG_DIALOG_HIDE:
      return Map(defaultState);
      // state = state.set("title", '');
      // state = state.set("data", Map({}));
      // return state.set("show", false);

    case activityLogDialogActionTypes.ACTIVITYLOG_DIALOG_SET_CONFIG_DATA:
      state = state.setIn(["data",'activityLogTitle'], action.payload.activityLogTitle);
      return state.setIn(["data",'activityLogId'], action.payload.activityLogId);
    default:
      return state;
  }
}


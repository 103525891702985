import React from "react";

import { reduxForm } from "redux-form";
import { filterTypes } from "infrastructure/js/enums/filterTypes";
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import { gridsNames } from "../../../../enums/gridsNames";
import Grid from "infrastructure/js/components/Grid/gridWrapper";
import RelatedEntitiesCell from "./relatedEntitiesCell";
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import PropTypes from "prop-types";
import "./materialScrapGrid.scss";

class MaterialScrapGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: "material", filterName: "material", getOptions: true},
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper("mat.materialScrap.grid.");
    this.columnsConfig = this.createColumnsConfig();
    this.autoGroupColumnDef = {
      fieldName: this.labels.get('columns.material.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'material',
      columnOptions:{
        filterValueGetter: ({ data }) => data?.material?.businessId || data?.assetBusinessId,
        cellRendererParams: { suppressCount: true },
        valueGetter: ({ data }) => {
          return data.isParent ? data.material.businessId : null;
        },

      }
    }
  }

  createColumnsConfig = () => {
    return [
        {
            title: this.labels.get('columns.amount.title'),
            columnOptions: {
                valueGetter: (params) => {
                    return this.labels.get('columns.amount.value', 'XXX', {
                      amount: params.data.scrapQuantity,
                      unit: UnitHelper.getLabelForUnitType(PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH)
                    });
                },
            },
        },
        {
            title: this.labels.get('columns.relatedEntities.title'),
            columnOptions: {
                cellComponent: RelatedEntitiesCell,
                valueGetter: ({ data }) => {
                    return {
                      isParent: data.isParent,
                      businessId: data?.assetBusinessId,
                      assetType: data?.assetType,
                      assetId: data?.assetId,
                      entities: {
                        rolls: data.relatedRollsCount,
                        kits: data.relatedKitsCount,
                        workOrders: data.relatedWorkOrdersCount
                      },
                    };
                },
            },
        },
    ];
  };

  render() {
    return (
        <div className='material-scrap-grid'>
            <Grid
                gridName={gridsNames.PREDEFINED_MATERIAL_SCRAP}
                columnsConfig={this.columnsConfig}
                actions={this.props.actions}
                filterConfig={this.filterConfig}
                gridProps={{
                    checkboxSelection: false,
                    treeData: true,
                    getDataPath: (data) => {
                        return data.hierarchy;
                    },
                    autoGroupColumnDef: this.autoGroupColumnDef,
                    animateRows: true,
                }}
            />
        </div>
    );
  }
}

export default reduxForm({
    form: "MaterialScrapGrid"
  }
)(MaterialScrapGrid);

MaterialScrapGrid.propTypes = {
  actions: PropTypes.object.isRequired,
};

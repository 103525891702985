import React from 'react';
import { components } from 'react-select';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Label from 'infrastructure/js/components/Label/label';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';

import './EntityRangeListItem.scss';

export default class EntityRangeListItem extends React.Component {
  constructor(props) {
    super(props);

    this.minRange = !isNaN(props.minRange) ? props.minRange : 1;
    this.maxRange = !isNaN(props.maxRange) ? props.maxRange : 1000;
  }

  onEntityChange = (value, oldValue) => {
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
  };

  getMenuListComponent = (props) => {
    const { children } = props;
    return (
      <components.MenuList {...props}>
        {React.Children.count(children) < 20 ? (
          children
        ) : (
          <>
            {React.Children.toArray(children).slice(0, 20)}
            <Label className="more-options" text="There are more results. Refine your search." />
          </>
        )}
      </components.MenuList>
    );
  };

  validateRangeMin = (value, allValues) => {
    const { name, index } = this.props;
    const { minRange } = this;
    const parsedName = name.split('[')[0];
    if (Validation.range(minRange, allValues[parsedName]?.[index]?.maxRange)(value)) {
      return 'Value must be smaller than Maximum';
    }
  };

  validateRangeMax = (value, allValues) => {
    const { name, index } = this.props;
    const { maxRange } = this;
    const parsedName = name.split('[')[0];
    if (Validation.range(allValues[parsedName]?.[index]?.minRange, maxRange)(value)) {
      return 'Value must be larger than Minimum';
    }
  };

  render() {
    const { options, name, index } = this.props;
    const { minRange, maxRange } = this;

    return (
      <div className="entity-range-list-item">
        <div className="column">
          <Combobox
            id={name + '.[objectId]'}
            name={name + '.[objectId]'}
            options={options}
            parse={Parse.comboValueOnly()}
            format={Format.findOptionByValue(options)}
            onChangeCallback={this.onEntityChange}
            className=""
            validate={Validation.required}
            menuListRenderer={this.getMenuListComponent}
          />
        </div>
        <div className="column">
          <TextField
            id={name + '.[minRange]'}
            name={name + '.[minRange]'}
            className="short-textfield"
            validate={[Validation.required, this.validateRangeMin]}
            normalize={Normalize.number(true, minRange, maxRange)}
          />
        </div>
        <div className="column">
          <TextField
            id={name + '.[maxRange]'}
            name={name + '.[maxRange]'}
            className="short-textfield"
            validate={[Validation.required, this.validateRangeMax]}
            normalize={Normalize.number(true, minRange, maxRange)}
          />
        </div>
      </div>
    );
  }
}

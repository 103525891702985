
let localStorageValue = localStorage["enumsData"];
let enums = localStorageValue ? JSON.parse(localStorageValue) : null;

export const enumTypes =  {
  ALERT_TYPE:"ALERT_TYPE",
  ASSET_STATUS:"ASSET_STATUS",
  WORK_ORDER_STATUS:"WORK_ORDER_STATUS",
  OBJECT_TYPE:"OBJECT_TYPE",
  IMPORT_TYPE:"IMPORT_TYPE",
  INSPECTION_STATUS:"INSPECTION_STATUS",
  CAPACITY_STATE:"CAPACITY_STATE",
  PRINCIPAL_STATUS: "PRINCIPAL_STATUS",
  DASHBOARD_TIME_PERIOD:"DASHBOARD_TIME_PERIOD",
  COMPARISON_PERIOD:"COMPARISON_PERIOD",
  DASHBOARD_WORK_ORDER_STATUS:"DASHBOARD_WORK_ORDER_STATUS",
  DASHBOARD_CATEGORY:"DASHBOARD_CATEGORY",
  DEFECT_CAUSE: "DEFECT_CAUSE",
  PRINCIPAL_STATUS_ACTION: "PRINCIPAL_STATUS_ACTION",
  NOTIFICATION_STATUS: "NOTIFICATION_STATUS",
  USER_ROLE: "USER_ROLE",
  OPERATION_TYPE: "OPERATION_TYPE",
  OPERATION_STATUS: "OPERATION_STATUS",
  TASK_STATUS: "TASK_STATUS",
  IMPERIAL_SYSTEM_UNITS: 'IMPERIAL_SYSTEM_UNITS',
  INVENTORY_DASHBOARD_GENERAL_TIME_PERIOD: 'INVENTORY_DASHBOARD_GENERAL_TIME_PERIOD',
  INVENTORY_DASHBOARD_TIME_PERIOD: 'INVENTORY_DASHBOARD_TIME_PERIOD',
  PRIORITY_TYPE: 'PRIORITY_TYPE',
  TELEMETRY_UOM_TEMPERATURE: 'TELEMETRY_UOM_TEMPERATURE',
  TELEMETRY_UOM_HUMIDITY: 'TELEMETRY_UOM_HUMIDITY',
  TELEMETRY_SENSOR_TYPE: 'TELEMETRY_SENSOR_TYPE',
  TELEMETRY_UOM: 'TELEMETRY_UOM',
  AUTOMATION_TYPE: 'AUTOMATION_TYPE',
  LOCATION_TYPE: 'LOCATION_TYPE',
  TOOL_USABILITY_TYPE: 'TOOL_USABILITY_TYPE',
  TEMPERATURE_UNITS: "TEMPERATURE_UNITS",
  LENGTH_UNITS: "LENGTH_UNITS",
  WIDTH_UNITS: "WIDTH_UNITS",
  AREA_UNITS: "AREA_UNITS",
  WEIGHT_UNITS: "WEIGHT_UNITS",
  HOME_PAGE_TYPE: 'HOME_PAGE_TYPE'
};

// hierarchyLevel - first level equals 1 (only one parent: TOP LEVEL)
export function getLookupOptions(type, parent = undefined, hierarchyLevel = undefined) {
  let localStorageValue = localStorage["enumsData"];
  enums = localStorageValue ? JSON.parse(localStorageValue) : null;
  if (!enums) {
    console.error("enumsHelper: no enums found");
  }
  let results = enums.lookups[type] || [];

  if (parent) {
    results = results.filter(lookup => {
      return lookup.parents.includes(parent);
    });
  }

  if (hierarchyLevel != undefined) {
    results = results.filter(lookup => {
      return lookup.parents.length === hierarchyLevel;
    });
  }
  return results;
}

export function getEnumValue(type) {
  return (value) => {
    if (!enums) {
      let localStorageValue = localStorage["enumsData"];
      enums = localStorageValue ? JSON.parse(localStorageValue) : null;
      if (!enums) {
        console.error("enumsHelper: enum didn't found");
        return {};
      }
    }
    let match = enums.enums[type];
    if (!match) {
      console.error("enumsHelper: enum type didnt found " + type);
      return {};
    }

    if (!match[value]) {
      console.error(`enumsHelper: enum value didnt found ${type} -> ${value}`);
      return null;
    }

    return match[value];
  }
}

export function getEnumLabel(type) {
  return (value) => {
    if (!enums) {
      let localStorageValue = localStorage["enumsData"];
      enums = localStorageValue ? JSON.parse(localStorageValue) : null;
      if (!enums) {
        console.error("enumsHelper: enum didn't found");
        return "";
      }
    }
    let match = enums.lookups[type];
    if (!match) {
      console.error("enumsHelper: enum type didnt found " + type);
      return "";
    }

    let found = match.find(lookup => lookup.value == value);

    if (!found) {
      console.error(`enumsHelper: enum value didnt found ${type} -> ${value}`);
      return "";
    }

    return found.label;
  }
}

export function enumToDropdownOptions(enumType, labelHelper, labelPrefix = "") {
  if (!enums) {
    let localStorageValue = localStorage["enumsData"];
    enums = localStorageValue ? JSON.parse(localStorageValue) : null;
  }
  if (!enums?.enums?.hasOwnProperty(enumType)) {
    console.error(`missing enums or enum type ${enumType}`, enums);
    return [];
  }
  let res = [];
  let keys = Object.keys(enums.enums[enumType]);
  for (let i in keys) {
    let value = keys[i];
    res.push({ label: labelHelper.get(labelPrefix ? labelPrefix + value : value), value })
  }
  return res;
}


import React from 'react';
import PropTypes from 'prop-types';

import Validation       from 'infrastructure/js/components/controls/controlsValidations';
import Normalize        from 'infrastructure/js/components/controls/controlsNormalizations';
import Parse            from 'infrastructure/js/components/controls/controlsParse';

import EditInPlace from '../../controls/EditInPlace/editInPlace';
import {PL_TextField} from '../../controls/TextField/textField';

export default class NumericFieldEditor extends React.PureComponent {


  render() {
    let {name, value, displayValue, isInteger, min, max, validate, handleSubmit, editable, alert, className} = this.props;
    value = (value === undefined || value === null) ? '' : value;
    displayValue = (displayValue === undefined) ? value : displayValue;
    isInteger = (isInteger === undefined) ? false : isInteger;
    return (
      <EditInPlace

        id={this.props.id || `${name}-input`}
        name={name}
        formName={`form_EditInPlace_${name}`}
        serverValue={Parse.numberToString()(value)} // set initial value as text.
        serverDisplayValue={displayValue}
        sendData={handleSubmit}
        alert={alert}
        unparse={Parse.toNumber()} // text value to number.
        normalize={Normalize.number(isInteger, min, max)}
        validate={validate}
        editable={editable}
        withAdditionalComponent={this.props.withAdditionalComponent}
      >
        <PL_TextField id={`${this.props.id}-input`} className={className} />
      </EditInPlace>
    );
  }
}

NumericFieldEditor.defaultProps = {
  min: 0,
  max: 99999
};

NumericFieldEditor.propTypes = {
  id : PropTypes.string.isRequired,
  name : PropTypes.string.isRequired,
  value : PropTypes.number,
  min : PropTypes.number,
  max : PropTypes.number,
  displayValue: PropTypes.string,
  alert: PropTypes.object
};


import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import AddRemoveList from '../../../../../Common/Controls/AddRemoveList/addRemoveList';
import Header, {headerItemsLabels} from '../../../../../Common/Header/header';
import AdditionalFieldListItem from '../AdditionalFieldListItem/additionalFieldListItem';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
import PermissionManager from "../../../../../../../../infrastructure/js/utils/permissionManager";

require('./commonAdditionalFieldsForm.scss');

class CommonAdditionalFieldsForm extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;
  }

  componentDidMount() {
    this.initFormValues();
  }

  initFormValues = () => {
    let initialValues = this.getInitialValues();
    this.props.initialize(initialValues);
  };

  getInitialValues = () => {
    if (!this.props || !this.props.formData.get('fields')) {
      return null;
    }

    let initialValues = {};
    initialValues.additionalFields = this.props.formData.get('fields');
    return initialValues;
  };

  getHeaderItems() {
    return {
      buttons: [
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: this.props.pristine,
          action: this.props.handleSubmit(this.onSubmit),
        },
      ],
    };
  }

  onSubmit = (data) => {
    this.props.actions.submit(data, this.props.entityType, this.getMessageDialogBuilder())
      .then((response) => {
        if (response && response.success) { //Initialize form values and reset pristine.
          this.initFormValues();
        }
      });
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.props.labels.get('confirmation.success', '', {entityType: this.getEntityName()});
      let className = 'oneBackground';
      let messageDialogDescriptor = {title, className};
      return messageDialogDescriptor;
    };
  };

  getEntityName = () => {
    let {entityType} = this.props;
    switch (entityType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        return this.labels.get('confirmation.roll');
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        return this.labels.get('confirmation.kit');
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        return this.labels.get('confirmation.tool');
      case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
        return this.labels.get('confirmation.workOrder');
    }
    console.error('getEntityName(): unknown entity type ' + entityType);
    return '';
  };

  listItemRenderer = (data) => {
    //todo-ran - check why after add and move to other type the fields stays
    if (!data.item) {
      return null
    }
    return (
      <AdditionalFieldListItem
        id={data.item.id}
        name={data.name}
        index={data.index}
        item={data.item}
        labels={this.labels}
        options={this.props.formData.get('fieldTypes')}
        {...this.props}
      />
    )
  };

  render() {
    let {formData, sLoggedInUser} = this.props;
    if (!formData || !formData.get('fieldTypes')) {
      return null;
    }
    let headerItems = this.getHeaderItems();
    let defaultMaxFields = 18;
    return (
      <div className='common-additional-fields-form'>
        <Header {...headerItems}/>

        <div className="additional-fields-columns-header">
          <label className="column">{this.labels.get('list.column.fieldName')}</label>
          <label className="column">{this.labels.get('list.column.type')}</label>
        </div>

        <AddRemoveList name='additionalFields'
                       className='additional-fields-add-remove-list'
                       itemRenderer={this.listItemRenderer}
                       preSelectedItems={formData.get('fields') ? formData.get('fields') : []}
                       maxItemsToRender={PermissionManager.getOrgPreferences().additionalFieldsNum ? PermissionManager.getOrgPreferences().additionalFieldsNum : defaultMaxFields}
                       defaultItem={{id: null, type: {type: 'TEXT'}}}
                       addButtonLabel={this.labels.get('list.button.add')}
        />
      </div>
    );
  }
}

export default reduxForm({
    form: 'commonAdditionalFieldsForm'
  }
)(CommonAdditionalFieldsForm);

CommonAdditionalFieldsForm.propTypes = {
  actions: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
};

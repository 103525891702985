import Network from 'infrastructure/js/modules/network';

export function fetchAllData() {
  return Network.get('reports/locations-2d-mapping-report');
}

export function fetchActiveData() {
  return Network.get('reports/locations-2d-mapping-report?isActive=true');
}
export function fetchWoCount() {
  return Network.get('reports/locations-2d-mapping-report/workorders-count');
}
export function fetchAlerts() {
  return Network.get('reports/locations-2d-mapping-report/alert-severity');
}

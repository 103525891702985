import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import {reduxForm} from 'redux-form';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';

require('./activateWorkOrderDialog.scss');

class ActivateWorkOrderDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.workorderpage.activateworkorderdialog.');
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
        text: this.dialogLabels.get('cancel'),
        action: this.props.actions.hide
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.activate'),
          bsStyle: 'primary',
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
                    this.props.sData.get('hasError') ||
                    (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))

        }
      ]
    };
  }
  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {

      let title = this.labels.get("confirmation.header");
      let className = "oneBackground activate-workorder-confirm-message";
      let children = <MessageDialog.DataRow label={this.labels.get('confirmation.message')} value=''/>;

      let messageDialogDescriptor = {title, children, className};
      return messageDialogDescriptor;
    };
  };

  onSubmit = (data) => {
    let newData = {
      status: getEnumValue(enumTypes.WORK_ORDER_STATUS)('ACTIVE'),
      comment: data.comment ? data.comment : '',
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
      workOrderId: this.props.workOrder.get('id'),

    };
    // this.props.actions.updateWorkOrderStatus(newData, this.props.workOrder.get('id'), this.getMessageDialogBuilder());
    this.props.actions.submit(newData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  }

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {

    return (
      <Dialog
        id="activate-workorder-dialog"
        className="activate-workorder-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <div className="activate-workorder-confirmation">{this.labels.get('confirmation.text')}</div>

        <InputSection label={this.labels.get('comment')} htmlFor="comments" className="right-side full-width">
          <TextAreaField id="comment-textField" name="comment" className="comment-textField" />
        </InputSection>

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'activateWorkOrderDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(ActivateWorkOrderDialog);

ActivateWorkOrderDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  workOrder : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,

};

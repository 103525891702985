import React from 'react';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import OperationStatusCell from './CustomCells/OperationStatusCell/operationStatusCell.js';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

require('./importFilesCommonGrid.scss');

class ImportFilesCommonGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.grid.');
    this.operationStatusLabel = props.operationStatusLabel ? props.operationStatusLabel : createLabelHelper(`mat.wizards.import.files.${this.props.assetType}.validation.grid.operationstatus.`);
    this.entityTypeLabels =  props.entityTypeLabels ? props.entityTypeLabels : createLabelHelper(`mat.wizards.import.files.${this.props.assetType}.`);
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let columnsConfig =  [
      {
        fieldName: 'type',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.NONE,
        width:  !this.props.columnsWidthConfig.type ? 200 : this.props.columnsWidthConfig.type,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return this.entityTypeValueGetter(params);
          }
        }
      }
    ];

    if (this.props.showBusinessIdColumn) {
      let businessIdColumn = {
        fieldName: 'businessId',
        title: this.labels.get('columns.id.title'),
        filterType: filterTypes.NONE,
        width: 180,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.businessId;
          }
        }
      };
      columnsConfig.push(businessIdColumn);
    }

    if (this.props.showOperationNameColumn) {
      let operationNameColumn = {
        fieldName: 'operationName',
        title: this.labels.get('columns.operationName.title'),
        filterType: filterTypes.NONE,
        width: 200,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      };
      columnsConfig.push(operationNameColumn);
    }

    if (this.props.showOperationDurationColumn) {
      let operationDurationColumn = {
        fieldName: 'duration',
        title: this.labels.get('columns.operationDuration.title'),
        filterType: filterTypes.NONE,
        width: 200,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          sortable: false,
          valueGetter: (params) => {
            return DateTimeHelper.ConvertMinutesToHoursMinutes(params.data.duration);
          }
        }
      };
      columnsConfig.push(operationDurationColumn);
    }

    if (this.props.showWorkOrderColumn) {
      let workOrderColumn = {
        fieldName: 'woBusinessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false
        }
      };
      columnsConfig.push(workOrderColumn);
    }

    if (this.props.showNumberOfPliesColumn) {
      let numberOfPliesColumn = {
        fieldName: 'numOfPlies',
        title: this.labels.get('columns.plies.title'),
        width: 100,
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false
        }
      };
      columnsConfig.push(numberOfPliesColumn);
    }

    if (this.props.showProjectColumn) {
      let projectColumn = {
        fieldName: 'projectBusinessId',
        title: this.labels.get('columns.project.title'),
        width: 200,
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
        }
      };
      columnsConfig.push(projectColumn);
    }

    let operationStatusColumn =
      {
        fieldName: 'operationStatus',
        title: this.labels.get('columns.operationStatus.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: OperationStatusCell,
          sort: 'desc',
          sortable: false,
          valueGetter: (params) => {
            return {
              severity: params.data.severity,
              operationStatus: params.data.m_operationStatus
            };
          }
        }
      };
      columnsConfig.push(operationStatusColumn);

    return columnsConfig;
  };

  entityTypeValueGetter(params) {
    if (!params.data.typeDisplay) {
      return 'NOT_FOUND';
    }

    let text = this.entityTypeLabels.get(params.data.typeDisplay.labelKey, undefined, params.data.typeDisplay.params);
    return text;
  };

  convertToRowData = (rows) => {
    let items = rows.map((obj) => {
      obj.m_operationStatus = obj.operationStatus.map((operation)=> {
        return this.operationStatusLabel.get(operation.labelKey, '' ,operation.params);
      });
      return obj;
    });
    return items;
  };


  render() {
    return (
      <div className="import-files-common-grid">
        <Grid gridName={this.props.gridName}
              columnsConfig={this.columnsConfig}
              rows={this.convertToRowData(this.props.rows)}
              isStatic = {true}
              gridProps={
                {
                  checkboxSelection: false
                }
              }
        >
        </Grid>
      </div>
    );
  }

}

export default reduxForm({
    form: 'ImportFilesCommonGrid'
  }
)(ImportFilesCommonGrid);

ImportFilesCommonGrid.defaultProps = {
  showWorkOrderColumn: false,
  showNumberOfPliesColumn: false,
  showOperationNameColumn: false,
  showOperationDurationColumn: false,
  showBusinessIdColumn: true,
  showProjectColumn: false,

  columnsWidthConfig :{}
};

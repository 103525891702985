import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as deviceAppsService from '../../../../services/Rfid/deviceAppsService.js';
import {api as gridApi} from "../deviceAppsViewGridActions";
import moment from "moment/moment";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_DEVICE_APP_DIALOG_SHOW:               'CREATE_DEVICE_APP_DIALOG_SHOW',
  CREATE_DEVICE_APP_DIALOG_HIDE:               'CREATE_DEVICE_APP_DIALOG_HIDE',
  CREATE_DEVICE_APP_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_DEVICE_APP_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_DEVICE_APP_DIALOG_SUBMIT_FINISHED:    'CREATE_DEVICE_APP_DIALOG_SUBMIT_FINISHED',
  CREATE_DEVICE_APP_DIALOG_FETCH_DATA_READY:   'CREATE_DEVICE_APP_DIALOG_FETCH_DATA_READY',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_DEVICE_APP_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_DEVICE_APP_DIALOG_HIDE, payload: payload };
  },
  submitInProgress: function(payload) {
    return {type: actionTypes.CREATE_DEVICE_APP_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.CREATE_DEVICE_APP_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  fetchDilaogDataReady: function(payload) {
    return {type: actionTypes.CREATE_DEVICE_APP_DIALOG_FETCH_DATA_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

jsxActions.submit = function(data, isEditMode, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    dispatch(actions.show(data));
  }
};

function getDeviceAppQuery(data) {
  let fromTimeHours = data.timeWeekdayAndHourFrom ? moment(data.timeWeekdayAndHourFrom).get("hours") : null;
  let fromTimeMinutes = data.timeWeekdayAndHourFrom ? moment(data.timeWeekdayAndHourFrom).get("minutes") : null;
  let fromDay = data.dayOfWeekWeekdayAndHourFrom ? data.dayOfWeekWeekdayAndHourFrom.value : null;
  let toTimeHours = data.timeWeekdayAndHourTo ? moment(data.timeWeekdayAndHourTo).get("hours") : null;
  let toTimeMinutes = data.timeWeekdayAndHourTo ? moment(data.timeWeekdayAndHourTo).get("minutes") : null;
  let toDay = data.dayOfWeekWeekdayAndHourTo ? data.dayOfWeekWeekdayAndHourTo.value : null;
  let updateWindowSlot = {
    from: {
      dayName: fromDay,
      dayTime: [fromTimeHours, fromTimeMinutes]
    },
    to: {
      dayName: toDay,
      dayTime: [toTimeHours, toTimeMinutes]
    }
  };
  let query = {
    deviceName: data.deviceAppName,
    id: data.id,
    updateWindowSlot: updateWindowSlot
  };
  return query;
}

api.submit = function(dispatch, getState) {
  return function(data, isEditMode, reloadParentComponent) {
    dispatch(actions.submitInProgress());
    let query = getDeviceAppQuery(data);
    let method = isEditMode ? deviceAppsService.editDeviceApp : deviceAppsService.createDeviceApp;

    method(query).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Create/Edit device failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      gridApi.reload(dispatch, getState)();
    });
  };
};



import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper.js';
import {gridsNames} from '../../../enums/gridsNames.js';
import * as rollsService from '../../../services/Rolls/rollsService';
import { api as locationPageApi } from '../locationPageActions.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_CUTTING, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();

    return rollsService.getRollsDataByStationId(locationId, query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let rolls = rows.map((obj) => {
    let data = obj;
    if(data.expirationDate){
      data.m_ExpirationDaysLeft =  DateTimeHelper.GetDifferenceInDays(data.expirationDate.value, serverTimeSeconds);
    }
    return data;
  });

  return rolls;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

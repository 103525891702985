import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import * as daHelper from '../dialogActionsHelper';
import {dialogsNames} from '../../../enums/dialogsNames';
import * as partsService from '../../../services/Parts/partsService';
import {api as afpOperationWizardApi} from '../../Wizards/AfpOperationWizard/afpOperationWizardActions';


const config = {
  getSubmitMethod: () => partsService.addOrReplaceSpools,
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.ADD_REPLACE_SPOOLS_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.ADD_REPLACE_SPOOLS_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(data) {

      return partsService.getLastLayer(data?.id).then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Failed to get the last layer data for: ' + data?.businessId , response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return null;
        }
        data.lastLayer = response?.data;

        dispatch(actions.show(data));
        return {success: true};
      });
    }
  },
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
  fetchScannedAsset(config, callback) {
    return function(dispatch, getState) {
      return afpOperationWizardApi.fetchScannedAsset(dispatch, getState)(config, callback);
    }
  },
  showConfirmation(config) {
    return function(dispatch, getState) {
      return afpOperationWizardApi.showConfirmation(dispatch, getState)( config);
    }
  },
  showError(config) {
    return function(dispatch, getState) {
      return afpOperationWizardApi.showError(dispatch, getState)(config);
    }
  }
};


import { useState } from 'react';
import ProptTypes from 'prop-types';
import ButtonSelect from 'infrastructure/js/components/controls/ButtonSelect/buttonSelect';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { enumTypes, getEnumValue } from '../../../../utils/enumHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { getSystemColors } from 'infrastructure/js/utils/colorHelper';
import TaskStarted from 'infrastructure/js/components/DynamicIcons/TaskStarted';
import TaskCompleted from 'infrastructure/js/components/DynamicIcons/TaskCompleted';

import './tasksPanel.scss';

function TasksPanel({ selectedRows, actions: { bulkProgressReport } }) {
  const [labels] = useState(() => createLabelHelper('mat.locationpage.view.tasks.panel.'));

  const { started, completed } = getSystemColors();

  const MarkAsStartedButtonIcon = <TaskStarted color={started} />;
  const MarkAsCompletedButtonIcon = <TaskCompleted color={completed} />;

  const startedOptions = [
    {
      value: 2,
      label: labels.get('buttons.startedAsPlanned'),
      status: getEnumValue(enumTypes.TASK_STATUS)('STARTED'),
      isReportAsPlanned: true,
    },
  ];

  const completedOptions = [
    {
      value: 2,
      label: labels.get('buttons.completedAsPlanned'),
      status: getEnumValue(enumTypes.TASK_STATUS)('COMPLETED'),
      isReportAsPlanned: true,
    },
  ];

  const taskCounterText = labels.get('numOfSelectedTasks', '', {
    count: selectedRows?.size || 0,
  });

  const onButtonClick = (data) => {
    const queryData = {
      ...data,
      selectedRows,
    };
    bulkProgressReport(queryData, getMessageDialogBuilder());
  };

  const getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = labels.get('updateStatus.confirmation.message.title', '', { count: response.totalJobItems });
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  return (
    <div className="tasks-view-panel">
      <div className="action-buttons">
        <ButtonSelect
          className={'mark-started-select-button'}
          options={startedOptions}
          buttonData={{
            label: labels.get('buttons.markAsStarted'),
            status: getEnumValue(enumTypes.TASK_STATUS)('STARTED'),
            isReportAsPlanned: false,
          }}
          icon={MarkAsStartedButtonIcon}
          onButtonClick={onButtonClick}
          onSelect={onButtonClick}
          disabled={!selectedRows?.size}
        />
        <ButtonSelect
          className={'mark-completed-select-button'}
          options={completedOptions}
          buttonData={{
            label: labels.get('buttons.markAsCompleted'),
            status: getEnumValue(enumTypes.TASK_STATUS)('COMPLETED'),
            isReportAsPlanned: false,
          }}
          icon={MarkAsCompletedButtonIcon}
          onButtonClick={onButtonClick}
          onSelect={onButtonClick}
          disabled={!selectedRows?.size}
        />
      </div>
      <div className="task-counter">{taskCounterText}</div>
    </div>
  );
}

TasksPanel.propTypes = {
  selectedRows: ProptTypes.object,
  actions: ProptTypes.object,
};

export default TasksPanel;

import Network from 'infrastructure/js/modules/network';

export function fetchKitTypes(query={}) {
  return Network.post('kittype/items', query);
}

export function fetchAllKitTypes() {
  return Network.get('kittypes');
}

export function fetchActiveKitTypes() {
  return Network.get('kittypes?isActive=true');
}

export function createKitType(data) {
  return Network.post('kittypes', data);
}

export function setKitTypeOperations(data) {
  return Network.post('kittypes/operations', data);
}

export function editKitType (data) {
  let id = data.id;
  delete data.id;
  return Network.put(`kittypes/${id}`, data);
}

export function activateKitTypes(data) {
  return Network.post('kittypes/activate', data);
}

export function deactivateKitTypes(data) {
  return Network.polling.post('kittypes/deactivate', data);
}

export function removeKitTypes(ids) {
  return Network.delete('kittypes/', {kitTypeIds: ids.ids});
}

export function importKitTypesJson(file) {
  return Network.postFile('batch/import/kittypes/jsonplain/upload',file);
}




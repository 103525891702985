import React from 'react';

import WorkOrderDetails from './WorkOrderDetails/workOrderDetails';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import {isWorkOrderArchived} from '../utils/workOrderHelper';

require('./overviewTab.scss');

export default class OverviewTab extends React.PureComponent {
  getLoadingOverlay() {
    if (!this.props.loading) {
      return null;
    }
    return <Overlay.Loading />;
  }

  render() {
    if (!this.props.workOrderDetails) {
      return null;
    }

    let workOrderDetailsProps = {
      workOrder: this.props.workOrderDetails,
      defaultWorkOrderType: this.props.sDefaultWorkOrderType,
      workOrderPageEditorData: this.props.sWorkOrderPageEditorData.toJS(),
      loggedInUser:this.props.sLoggedInUser,
      actions: {
        workOrderPageEditorActions: this.props.actions.workOrderPageEditorActions
      }
    };

    return (
      <div className={"wo-overview-view " + ((workOrderDetailsProps.workOrder && isWorkOrderArchived(workOrderDetailsProps.workOrder.workOrderStatus)) ? "wo-archived" : "wo-active")}>
        <WorkOrderDetails {...workOrderDetailsProps} />
        {this.getLoadingOverlay()}
      </div>
    );

  }
}




import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import {formValueSelector, reduxForm} from 'redux-form';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import Label from 'infrastructure/js/components/Label/label';
import MultiEntitiesMultiSelect from '../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import DropDown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import DateRangePopover from 'infrastructure/js/components/popover/DateRangePopover/DateRangePopover'
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import {connect} from "react-redux";
import {isRoll, isKit, isSpool} from '../../../utils/assetHelper';
import {getDateFormatIcon} from "infrastructure/js/components/controls/controlsUtils";
import PermissionManager from "../../../../../infrastructure/js/utils/permissionManager";
import moment from 'moment';
import {enumTypes, getEnumValue} from "../../../utils/enumHelper";

require('./shelfLifeUpdateDialog.scss');

// main wizard component
class ShelfLifeUpdate extends React.PureComponent {

  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.updateshelflifedialog.');
    this.entityLabels = createLabelHelper('mat.entity.type.');
    // when the currentAsset is injected from outside through the props,
    // the dialog runs in NOT batch operation mode
    this.isBatchOperation = !this.props.currentAsset;
    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();

    this.loggedInUser = PermissionManager.getLoggedInUser();

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.props.sData.get('boEntityFilter')
    };

    this.currentAsset = this.getCurrentAsset();
    this.isSingleAssetToEdit = this.isSingleAsset();

    //to distinguish between ROLL & KIT assets inside this dialog and its actions
    this.assetType = this.getAssetType();

    this.isExposureByRange = PermissionManager.getOrgPreferences().updateShelfLifeEtlCureByDates;

    this.state =
      { currentETLCure: null,
        currentETLBond: null,
        currentExpirationDate: null,
        ETLhoursValue: null,
        ETLminutesValue: null,
        ETLoperation: this.getOperation()[0].value,
        isEtlBond: false,
        isEtlCure: false,
        isUpdateResultingAssetsSelected: false,
        isETLByRange: this.isExposureByRange
      };
  }

  getDialogButtons() {
    let cancelTokenEnabled = !!this.props.sData.get('cancelToken') ;
    let isDisabled = this.isSubmitDisabled() ||
      this.props.sData.get('loading') ||
      this.props.sData.get('hasError') ||
      (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'));

    return {
      left: [{
        id:'cancel',
        text:   (this.isBatchOperation && this.props.sData.get('loading')) ? this.dialogLabels.get('stop') : this.dialogLabels.get('cancel'),
        action: (this.isBatchOperation && this.props.sData.get('loading')) ? this.onStop : this.onHide,
        enabledOnLoading: this.isBatchOperation && cancelTokenEnabled
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.updateshelflife'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: isDisabled
        }
      ]
    };
  }

  isSubmitDisabled = () => {

    if(this.initialExpirationDate !== this.state.currentExpirationDate){
      return false;
    }
    //initial data has changed and one of the checkbox is selected
    return !(this.initialETLBond !== this.state.currentETLBond &&
             this.initialETLCure !== this.state.currentETLCure &&
            (this.state.isEtlBond || this.state.isEtlCure));
  };


  getAssetType = () => {
    if (this.isBatchOperation && this.entitiesTypes && this.entitiesTypes.length === 1) {
      return this.entitiesTypes[0];
    }
    if (!this.isBatchOperation && this.currentAsset) {
      return this.currentAsset.objectType;
    }
    //should never get here
    console.error('ShelfLifeUpdateDialog(): failed to get asset type');
    return null;
  };

  isSingleAsset = () => {
    let preselectedAssets = this.props.preselectedAssets ? this.props.preselectedAssets : this.props.sData.get('preselectedAssets');

    return (!this.isBatchOperation || (preselectedAssets.size === 1));
  };

  getCurrentAsset = () => {
    if (!this.isBatchOperation) {
      return this.props.currentAsset;
    }

    let preselectedAssets = this.props.preselectedAssets ? this.props.preselectedAssets : this.props.sData.get('preselectedAssets');
    if (preselectedAssets.size === 1) {
      return preselectedAssets.get(0);
    }
    else {
      return {
        exposureTimeLeftBond: {
          state: '',
          value: null
        },
        exposureTimeLeftCure: {
          state: '',
          value: null
        },
        expirationDate: {
          value: null
        }
      };
    }
  };

  initData = () => {
    // reset form and data
    let {expirationDate, exposureTimeLeftBond, exposureTimeLeftCure} = this.currentAsset;
    this.initialExpirationDate = expirationDate ? DateTimeHelper.FormatDateObjectToDayMonth(expirationDate.value) : '';
    this.initialETLBond = exposureTimeLeftBond ? Math.floor(exposureTimeLeftBond.value) : 0;
    this.initialETLCure = exposureTimeLeftCure ? Math.floor(exposureTimeLeftCure.value) : 0;

    let defaultOperation = this.getOperation()[0];

    let initialState = {
      currentETLCure: this.initialETLCure,
      currentETLBond: this.initialETLBond,
      currentExpirationDate: this.initialExpirationDate,
      ETLhoursValue: null,
      ETLminutesValue: null,
      ETLoperation: defaultOperation.value,
      isEtlBond: (exposureTimeLeftBond && exposureTimeLeftBond.value !== null),
      //If ETL BOND =n/a & ETL CURE =n/a, by default only the ETL cure check box will be selected
      isEtlCure: ((exposureTimeLeftCure && exposureTimeLeftCure.value !== null) ||
                 (!exposureTimeLeftBond  || exposureTimeLeftBond.value === null))
    };

    this.setState({...initialState}, () => {
      this.props.change ('ETLoperation', defaultOperation);
      this.props.change ('isEtlBond', this.state.isEtlBond);
      this.props.change ('isEtlCure', this.state.isEtlCure);
    });
  };

  onSubmit = (data) => {
    //finalData -  to send to the server, data - is used for UI purposes
    let finalData = {
      reason : data.reason ?  data.reason.label : '',
      notes : data.notes,
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };

    data.isExpirationDateChanged = this.initialExpirationDate !== this.state.currentExpirationDate;
    if (data.isExpirationDateChanged) {
      finalData.expirationDate = DateTimeHelper.ConvertFromDate(data.expirationDate);
    }
    if (this.getAssetType() !== getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')) {  // it is currently not supported for Spools
      finalData.isUpdateResultingAssetsSelected = this.state.isUpdateResultingAssetsSelected;
    }

    data.isEtlValueChanged =  this.initialETLBond !== this.state.currentETLBond ||  this.initialETLCure !== this.state.currentETLCure ;
    if (data.isEtlValueChanged ) {
      let newEtlValue =  DateTimeHelper.ConvertHoursMinutesToMinutes(this.state.ETLhoursValue, this.state.ETLminutesValue);
      data.ETLvalue = newEtlValue;
      finalData.exposure = (data.ETLoperation.value != "add") ? 0 - newEtlValue : newEtlValue;
      finalData.isEtlBondSelected = data.isEtlBond;
      finalData.isEtlCureSelected = data.isEtlCure;
    }


    let assetsIds = [];
    if (this.isBatchOperation) {
      let allRows = data['entities-multi-select-ex'];
      allRows.map((row) => {
        if (row && row.assetsValue) {
          row.assetsValue.map((asset) => {
            assetsIds.push(asset.value);
          });
        }
      });
    }
    else {
      assetsIds.push(this.props.currentAsset.id);
    }

    if (isRoll(this.assetType)) {
      finalData.rollsIds = assetsIds;
    }
    else if (isSpool(this.assetType)){
      finalData.spoolsIds = assetsIds;
    }
    else if (isKit(this.assetType)){
      finalData.kitsIds = assetsIds;
    }

    //to use different Server APIs in the action
    finalData.assetType = this.assetType;

    this.props.actions.submit(finalData, this.getMessageDialogBuilder(), this.props.reloadParentComponent)
  };

  getMessageDialogBuilder = () => {
    return (response, fnShowDetails) => {
      if (this.isBatchOperation) {
        let title = this.labels.get("confirmation.message.title", "", {count: response.totalJobItems});
        return dialogHelper.BuildDialogDescriptorForBO(response, fnShowDetails, title);
      }

      return {title: this.labels.get('confirmation.header'), className: 'oneBackground'};
    };
  };

  getReasons =(index) => {
    return this.props.sData.get("reasons");
  };
  getOperation =(index) => {
    return [
      {value: "add", label: this.labels.get("add")},
      {value: "sub", label: this.labels.get("sub")}
    ];
  };

  updateCurrentETL = () => {
    let currentETLBond = this.initialETLBond;
    let currentETLCure = this.initialETLCure;
    let ETLvalue = DateTimeHelper.ConvertHoursMinutesToMinutes(this.state.ETLhoursValue, this.state.ETLminutesValue);
    currentETLBond = (this.state.ETLoperation === "add" ) ? currentETLBond + ETLvalue : currentETLBond - ETLvalue;
    currentETLCure = (this.state.ETLoperation === "add" ) ? currentETLCure + ETLvalue : currentETLCure - ETLvalue;
    this.setState({
      currentETLBond: currentETLBond,
      currentETLCure: currentETLCure
    });
  };

  onChangeExpirationDate = (val) => {
    let newValue = DateTimeHelper.FormatDateToDayMonth(val);
    this.setState({currentExpirationDate: newValue});
  };

  onChangeETLoperation = (val) => {
    this.setState({ETLoperation: val.value}, this.updateCurrentETL);
  };
  onChangeHoursETLvalue = (val) => {
    this.setState({ETLhoursValue: val}, this.updateCurrentETL);
  };
  onChangeMinutesETLvalue = (val) => {
    this.setState({ETLminutesValue: val}, this.updateCurrentETL);
  };

  componentDidMount() {
    this.props.actions.fetchReasons();
    if(this.isExposureByRange){
      let initialValues = {headerCheckbox_ETLoperation: true};
      this.props.initialize(initialValues);
    }
  }

  onEntered =() => {
    this.initData();
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  renderAssetsMultiSelect = () => {
    if (!this.isBatchOperation) {
      return null;
    }

    let preselectedAssets = this.props.sData.get('preselectedAssets');
    let disableOnPreselect = (preselectedAssets && preselectedAssets.size === 1);

    return (
      <InputSection label={ this.labels.get('selectassets.text') } className="no-margin">
        <MultiEntitiesMultiSelect id="entities-multi-select-ex"
                               name="entities-multi-select-ex"
                               entitiesTypes={this.entitiesTypes}
                               validate={Validation.required}
                               preSelectedEntities={preselectedAssets}
                               fetchConfig={this.fetchConfig}
                               disableOnPreselect={disableOnPreselect}
                               dropdownMenuClassName="entities-multi-select-shelflifeupdate-dialog"
                               autoFocus={true && !disableOnPreselect}
                               { ...this.props }/>
      </InputSection>
    );
  };


  onEtlBondCheckboxClick = () => {
    this.setState({isEtlBond: !this.state.isEtlBond});
  };
  onEtlCureCheckboxClick = () => {
    this.setState({isEtlCure: !this.state.isEtlCure});
  };

  renderEtlBondData = () =>{
    if (this.isSingleAssetToEdit && this.state.isEtlBond) {
      return this.renderEtlData(this.state.currentETLBond, this.initialETLBond);
    }
    return null;
  };

  renderEtlCureData = () =>{
    if (this.isSingleAssetToEdit && this.state.isEtlCure) {
      return this.renderEtlData(this.state.currentETLCure, this.initialETLCure);
    }
    return null;
  };

  renderEtlBond = () => {
    if (PermissionManager.isEtlBondEnabled()) {
      return (<InputSection htmlFor="isEtlBond" className="no-margin">
        <Checkbox name="isEtlBond" id="isEtlBond" label={this.labels.get("etlBond")}
                  onClick={this.onEtlBondCheckboxClick}/>
        {this.renderEtlBondData()}
      </InputSection>)
    }
  }


  renderEtlData = (current, initial) => {
    return <div className="updated-data">
      <div className="ETL-text init-etl">
        {DateTimeHelper.ConvertMinutesToHoursMinutesShort(initial)}
        </div>
      <div className={classNames('pl pl-move-arrow-right', {'hidden': current === initial}) }/>
      <div className={classNames('ETL-text curr-etl',{'hidden': current === initial})}>
        {DateTimeHelper.ConvertMinutesToHoursMinutesShort(current)}
      </div>
    </div>
  };

  getAssetExtraData = (currentAsset) => {
    if (currentAsset) {
      if (isRoll(currentAsset.objectType)) {
        return currentAsset.material ? currentAsset.material.businessId : '';
      }
      if (isKit(currentAsset.objectType)) {
        return currentAsset.kitType ? currentAsset.kitType.businessId : '';
      }
    }
    return '';
  };

  onUpdateAffectsResultingAssetsCheckboxChange = (event) =>{
    this.setState({isUpdateResultingAssetsSelected: (event.target.checked)});
  };

  renderCurrentAssetSelect = () => {
    if (this.isBatchOperation) {
      return null;
    }
    let {currentAsset} = this.props;

    if (currentAsset) {
      return (
        <div className="row-details">
          <Label className="row-details-title" text={this.labels.get('update')}/>
          <Label className="row-details-asset" text={currentAsset.businessId ? currentAsset.businessId : ''}/>
          <Label className="row-details-extra-data" text={this.getAssetExtraData(currentAsset)}/>
          <hr/>
        </div>
      )
    }
    return null;
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  handleDateRangeDoneClick = () =>{
    this.props.touch('rangeStartInput')
    this.props.touch('rangeEndInput')
  }

  calculateETLRangeDuration = ({startDate, endDate}) => {
    if(startDate?.isValid() && endDate?.isValid()){
      const duration = moment.duration(endDate.diff(startDate))

      const hours = parseInt(duration.asHours())
      const minutes = parseInt(duration.asMinutes()%60)

      if(hours < 0 || minutes < 0)
        return;

      this.props.change('timeCount_hours', hours)
      this.props.change('timeCount_minutes', minutes)
      this.onChangeHoursETLvalue(hours)
      this.onChangeMinutesETLvalue(minutes)
      this.props.touch('timeCount')
    }
  }

  onETLCheckboxChanged = (event) => {
    this.setState({isETLByRange: (event.target.checked)});

  }

  render() {
    let initialDate = this.currentAsset.expirationDate && this.currentAsset.expirationDate.value ?
                      DateTimeHelper.ConvertToDate(this.currentAsset.expirationDate.value).startOf('day') : null;

    if(!this.props.orgTime){
      return null
    }
    let cureClass = PermissionManager.isEtlBondEnabled() ? 'no-margin' : 'margin-top';

    return (
      <Dialog
        id="shelf-life-update-dialog"
        className="shelf-life-update-dialog"
        show={this.props.sData.get("show") || false}
        onHide={this.onHide}
        onEntered={this.onEntered}
        sData={this.props.sData}
        titleText={this.labels.get("header.title")}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        enforceFocus={false}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        {this.renderAssetsMultiSelect()}

        {this.renderCurrentAssetSelect()}

        <InputSection label={this.labels.get("expirationDate")}
                      htmlFor="expirationDate"
                      className="inline left-side"
                      labelExtraComponent={getDateFormatIcon()}
                      onCheckboxChangeCallback={(PermissionManager.getOrgPreferences().shelfLifeUpdateAffectsResultingAssetsEnabled && isRoll(this.assetType)) ?
                        this.onUpdateAffectsResultingAssetsCheckboxChange : null}
                      checkBoxLabel={this.labels.get('updateResultingAssets')} >
          <DatePicker
            id="expirationDate"
            name="expirationDate"
            popperPlacement="auto"
            selected={this.state.currentExpirationDate}
            initialDate={initialDate}
            onChangeCallback={this.onChangeExpirationDate}/>
        </InputSection>

        <InputSection label={this.labels.get("reasonsforupdate")} htmlFor="reason" className="inline right-side">
          <DropDown
            id="reason"
            name="reason"
            className="reasons"
            options={this.getReasons()}
          />
        </InputSection>

        <InputSection
          label={this.labels.get("exposuretimeleft")}
          htmlFor="ETLoperation"
          className={`inline left-side ${this.state.isETLByRange ? 'etl-by-range' : ''}`}
          onCheckboxChangeCallback={this.isExposureByRange ? this.onETLCheckboxChanged : null}
          checkBoxLabel={this.labels.get('exposuretimeleft.timeRangeCheckBox')}>
          <DropDown
            id="ETLoperation"
            name="ETLoperation"
            className="ETL-operation"
            options={this.getOperation()}
            onChangeCallback={this.onChangeETLoperation}
          />

          <div className='etl-time-wrapper'>
            <TimeField
              id="exposureTimeLeftInput"
              name="timeCount"
              hoursCount={true}
              maxHoursLength={3}
              onChangeCallbackHours={this.onChangeHoursETLvalue}
              onChangeCallbackMinutes={this.onChangeMinutesETLvalue}
            />

            {this.state.isETLByRange && <DateRangePopover
              rangeStartLabel={this.labels.get('exposuretimeleft.dateRangeStartLabel')}
              rangeEndLabel={this.labels.get('exposuretimeleft.dateRangeEndLabel')}
              hideOnClickOutside={false}
              rangeStartInputProps={{
                dateFormat: DateTimeHelper.getDateTimeFormat(),
                placeholderText: DateTimeHelper.getDateTimeFormat(),

              }}
              rangeStartPickerProps={{
                showTimeSelect: true,
                timeIntervals: 1
              }}
              rangeEndInputProps={{
                dateFormat: DateTimeHelper.getDateTimeFormat(),
                placeholderText: DateTimeHelper.getDateTimeFormat(),

              }}
              rangeEndPickerProps={{
                showTimeSelect: true,
                timeIntervals: 1
              }}
              onExit={this.calculateETLRangeDuration}
              onDoneClick={this.handleDateRangeDoneClick}
              hideOnClickOutside={true}
            />}
          </div>

          <TextField
            id="ETLvalue"
            name="ETLvalue"
            className="hidden"
          />
        </InputSection>

        <div className="etl-section">
          {this.renderEtlBond()}
          <InputSection htmlFor="isEtlCure" className={cureClass}>
            <Checkbox name="isEtlCure" id="isEtlCure" label={this.labels.get("etlCure")} onClick={this.onEtlCureCheckboxClick}/>
            {this.renderEtlCureData()}
          </InputSection>
        </div>

        <InputSection label={this.labels.get("notes")} htmlFor="notes" className="full-width">
          <TextAreaField
            id="notes"
            name="notes"
            className="comment-textField"
          />
        </InputSection>
      </Dialog>
    );
  }

}

ShelfLifeUpdate.defaultProps = {
};

ShelfLifeUpdate.propTypes = {
  actions : PropTypes.object.isRequired,
  //asset : PropTypes.object,
  sData : PropTypes.object.isRequired,
};

let ShelfLifeUpdateContainer = connect( state => {
  return { orgTime : state.system.get("serverDatetime")}
})(ShelfLifeUpdate);

export default reduxForm({
    form: 'shelfLifeUpdateDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(ShelfLifeUpdateContainer);

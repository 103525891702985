import {api as gridApi} from './mqttBrokersSettingsViewGridActions';
import Network from 'infrastructure/js/modules/network';
import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import * as mqttBrokersService from '../../../services/Rfid/mqttBrokersService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_RF_SETTINGS_BROKERS_FETCH_DEVICE_APPS_IN_PROCESS: 'ADMINISTRATION_RF_SETTINGS_BROKERS_FETCH_DEVICE_APPS_IN_PROCESS',
  ADMINISTRATION_RF_SETTINGS_BROKERS_FETCH_DEVICE_APPS_READY: 'ADMINISTRATION_RF_SETTINGS_BROKERS_FETCH_DEVICE_APPS_READY',
  ADMINISTRATION_RF_SETTINGS_BROKERS_DEVICE_APP_CHANGED: 'ADMINISTRATION_RF_SETTINGS_BROKERS_DEVICE_APP_CHANGED',
  ADMINISTRATION_RF_SETTINGS_BROKERS_UNMOUNT: 'ADMINISTRATION_RF_SETTINGS_BROKERS_UNMOUNT',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  fetchDeviceAppsInProgress: function () {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_BROKERS_FETCH_DEVICE_APPS_IN_PROCESS};
  },
  fetchDeviceAppsFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_BROKERS_FETCH_DEVICE_APPS_READY, payload};
  },
  deviceAppChanged: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_BROKERS_DEVICE_APP_CHANGED, payload};
  },
  unmount: function(items) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_BROKERS_UNMOUNT};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid) {
  return function (dispatch, getState) {
    api.load(dispatch, getState)(shouldRefreshGrid);
  }
};
jsxActions.onDeviceAppChanged = function (data) {
  return function (dispatch, getState) {
    api.deviceAppChanged(dispatch, getState)(data);
  }
};

jsxActions.onRemoveBrokersClick = function(messageDialogBuilder , selectedBrokersIds) {
  return function(dispatch, getState) {
    api.openRemoveBrokersConfirmationDialog(dispatch, getState)(messageDialogBuilder , selectedBrokersIds);
  }
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function (dispatch, getState) {
  return function (shouldRefreshGrid) {

    dispatch(actions.fetchDeviceAppsInProgress());

    return deviceAppsService.fetchDeviceApps().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('FETCH_DEVICE_APPS failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let deviceAppsOptions = response.dataList.map((obj) => {
        return {value: obj.id, label: obj.username}
      });

      if (shouldRefreshGrid) {
        gridApi.reload(dispatch, getState)();
      }

      dispatch(actions.fetchDeviceAppsFinished(deviceAppsOptions));
    });
  }
};

api.deviceAppChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.deviceAppChanged(data));
    gridApi.reload(dispatch, getState)();
  }
};

api.openRemoveBrokersConfirmationDialog = function(dispatch, getState) {
  return function(messageDialogBuilder , selectedBrokersIds) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.removeBrokers(dispatch, getState)(selectedBrokersIds)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

  }
};

api.removeBrokers = function(dispatch, getState) {
  return function(selectedBrokersIds) {

    messageDialogApi.close(dispatch, getState)();

    mqttBrokersService.deleteMqttBrokers(selectedBrokersIds).then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('Failed to remove Mqtt brokers', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success : false};
      }

      api.load(dispatch, getState)(true);
    });
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

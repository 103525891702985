import React from 'react';
import Label from 'infrastructure/js/components/Label/label.js';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import {round} from '../../../../../utils/assetHelper';

require('./nestCell.scss');

export default class NestCell extends React.PureComponent {

  constructor(props){
    super(props);
  }

  getErrorIcon = () => {
    if (!this.props.value.errorMessage) {
      return null;
    }

    return (<AlertPopover
      popoverType={'error'}
      popoverComponent={<span>{this.props.value.errorMessage}</span>}
    />);
  };

  getLength = (length) => {
    return round(length, 2);
  };


  render() {
    let {value} = this.props;
    return (
      <div>
        <div className={'nest-cell-container label-cell'}>
          {this.getErrorIcon()}
          <Label text={(this.props.value && this.props.value.value) ? this.props.value.value.toString() : ''} className="error-message"/>
        </div>
        <div>{'Length: ' + this.getLength(value.length)}</div>
        <div style={{fontWeight: 'bold'}}>{'Remaining: ' + this.getLength(value.remaining)}</div>
    </div>);
  }
}










import React from 'react';

import {createLabelHelper} from "../../../../../../infrastructure/js/utils/labelHelper";
import Tooltip from "../../../../../../infrastructure/js/components/tooltip/tooltip";
import Label from 'infrastructure/js/components/Label/label.js';

require('./taskStatusCell.scss');

export default class TaskStatusCell extends React.PureComponent {

  constructor (props){
    super(props);
    this.labels = createLabelHelper('enum.planneroperation.');
  }

  getIcon(value){
    if (value) {
      let icon = '';

      switch (value) {
        case 'SCHEDULED':
          icon = 'pl-schedule';
          break;
        case 'UNASSIGNED':
          icon = 'pl-unscheduled';
          break;
        case 'STARTED':
          icon = 'pl-started';
          break;
        case 'COMPLETED':
          icon = 'pl-completed';
      }
      return <span className={`icon pl ${icon}`}/>;
    }
    return null;
  };

  getDescription = (value) => {
    if (value ) {
      let description = '';

      switch (value) {
        case 'SCHEDULED':
          description = this.labels.get('status.SCHEDULED');
          break;
        case 'UNASSIGNED':
          description = this.labels.get('status.UNASSIGNED');
          break;
        case 'STARTED':
          description = this.labels.get('status.STARTED');
          break;
        case 'COMPLETED':
          description = this.labels.get('status.COMPLETED');
      }
      return <span className="description">{description}</span>;
    }

    return null;
  };

  render() {
    let {value} = this.props;

    return (
      <div className="task-status-cell">
        <span>{this.getIcon(value)}</span>
        <Label className="description" text={this.getDescription(value)} />
      </div>
    )
  }
}










import { Map } from 'immutable';
import { actionTypes as RfidSettingsPageActions } from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { default as createDeviceAppDialogReducer } from './createDeviceAppDialogReducer';
import { default as createPrinterDialogReducer } from './createPrinterDialogReducer';
import { default as createVisibleDeviceDialogReducer } from './createVisibilityDeviceDialogReducer';
import { default as createAlertDeviceDialogReducer } from './createAlertDeviceDialogReducer';
import { default as readersAndAntennasViewReducer } from './readersAndAntennasViewReducer';
import { default as createReaderDialogReducer } from './createReaderDialogReducer';
import { default as createMqttBrokerDialogReducer } from './createMqttBrokerDialogReducer';
import { default as createMqttSensorDialogReducer } from './createMqttSensorDialogReducer';
import { default as createAutomationScenarioDialogReducer } from './createAutomationScenarioDialogReducer';

import { default as alertDevicesViewReducer } from './alertDevicesViewReducer';
import { default as printersViewReducer } from './printersViewReducer';
import { default as communicationParametersReducer } from './communicationParametersReducer';
import { default as filtersParametersReducer } from './filtersParametersReducer';
import { default as workflowsFiltersReducer } from './workflowsFiltersReducer';
import { default as visibilityDevicesReducer } from './visibilityDevicesViewReducer';
import { default as mqttBrokersSettingsViewReducer } from './mqttBrokersSettingsViewReducer';
import { default as mqttSensorsSettingsViewReducer } from './mqttSensorsSettingsViewReducer';
import { default as automationScenariosViewReducer } from './automationScenariosViewReducer';

import {RfidSettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';

let defaultState = {
  selectedMenuItemId : RfidSettingsMenuItemsIds.NAV_ELEMENT_DEVICE_APPS
};

export default function(state = Map(defaultState), action) {
  state = state.set('createDeviceAppDialog', createDeviceAppDialogReducer(state.get('createDeviceAppDialog'), action));
  state = state.set('createPrinterDialog', createPrinterDialogReducer(state.get('createPrinterDialog'), action));
  state = state.set('createVisibleDeviceDialog', createVisibleDeviceDialogReducer(state.get('createVisibleDeviceDialog'), action));
  state = state.set('createAlertDeviceDialog', createAlertDeviceDialogReducer(state.get('createAlertDeviceDialog'), action));
  state = state.set('createReaderDialog', createReaderDialogReducer(state.get('createReaderDialog'), action));
  state = state.set('createMqttBrokerDialog', createMqttBrokerDialogReducer(state.get('createMqttBrokerDialog'), action));
  state = state.set('createMqttSensorDialog', createMqttSensorDialogReducer(state.get('createMqttSensorDialog'), action));
  state = state.set('createAutomationScenarioDialog', createAutomationScenarioDialogReducer(state.get('createAutomationScenarioDialog'), action));

  state = state.set('readersAndAntennasViewData', readersAndAntennasViewReducer(state.get('readersAndAntennasViewData'), action));
  state = state.set('alertDevicesViewData', alertDevicesViewReducer(state.get('alertDevicesViewData'), action));
  state = state.set('printersViewData', printersViewReducer(state.get('printersViewData'), action));
  state = state.set('communicationParametersViewData', communicationParametersReducer(state.get('communicationParametersViewData'), action));
  state = state.set('filtersParametersViewData', filtersParametersReducer(state.get('filtersParametersViewData'), action));
  state = state.set('workflowsFiltersViewData', workflowsFiltersReducer(state.get('workflowsFiltersViewData'), action));
  state = state.set('visibilityDevicesViewData', visibilityDevicesReducer(state.get('visibilityDevicesViewData'), action));
  state = state.set('mqttBrokersSettingsViewData', mqttBrokersSettingsViewReducer(state.get('mqttBrokersSettingsViewData'), action));
  state = state.set('mqttSensorsSettingsViewData', mqttSensorsSettingsViewReducer(state.get('mqttSensorsSettingsViewData'), action));
  state = state.set('automationScenariosViewData', automationScenariosViewReducer(state.get('automationScenariosViewData'), action));

  switch (action.type) {

    case RfidSettingsPageActions.ADMINISTRATION_RFID_SETTINGS_SELECTED_MENU_ITEM_CHANGED:
      return state.set('selectedMenuItemId', action.payload);

    default:
      return state;
  }
}




import React from 'react';
import PropTypes from 'prop-types';
import {PL_Checkbox} from '../../../../controls/Checkbox/checkbox';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip';


//Style
require('./filterTableRow.scss');

export default class PL_FilterTableRow extends React.PureComponent {

  render() {
    return (
      <Tooltip placement='top' value={this.props.rowData.valueDisplay}>
      <tr className="filterTableRow">
        <td className="checkbox-cell">
          <PL_Checkbox id={this.props.rowData.id + "checkbox"}
                    checked={this.props.rowData.isChecked}
                    onChange={this.props.onRowClick}></PL_Checkbox>
        </td>
        <td className="main-label">
          <label className="pointer" htmlFor={this.props.rowData.id + "checkbox"}>{this.props.rowData.valueDisplay}</label>
        </td>
        <td className="additional-data-label">
          <label className="pointer" htmlFor={this.props.rowData.id + "checkbox"}>{this.props.rowData.additionalDataDisplay}</label>
        </td>
      </tr>
      </Tooltip>);
  }
}

PL_FilterTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
};





import Network from 'infrastructure/js/modules/network';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

import * as schedulerService from '../../services/Scheduler/schedulerService';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as navigatorApi } from '../AppTopbar/navigatorActions';
import { navigationStates } from '../../enums/navigationStates';
import { api as systemApi } from '../System/systemActions.js';
import * as schedulerHelper from '../../components/Common/Helpers/SchedulerHelper';
import { api as gridApi } from './schedulerOperationsGridActions';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import { api as scheduleTaskDialogApi } from './scheduleTaskDialogActions';
import { api as autoSchedulerDialogApi } from './autoSchedulerDialogActions';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import * as stationsService from '../../services/Stations/stationsService';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SCHEDULER_FETCH_SCHEDULER_DATA_FINISHED: 'SCHEDULER_FETCH_SCHEDULER_DATA_FINISHED',
  SCHEDULER_PAGE_RESET_DATA: 'SCHEDULER_PAGE_RESET_DATA',
  SCHEDULER_SET_LOADING: 'SCHEDULER_SET_LOADING',
  SCHEDULER_FETCH_SCHEDULER_COUNTERS_FINISHED: 'SCHEDULER_FETCH_SCHEDULER_COUNTERS_FINISHED',
  SCHEDULER_ADD_BACKTRACK_LIST_ITEM: 'SCHEDULER_ADD_BACKTRACK_LIST_ITEM',
  SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM: 'SCHEDULER_UPDATE_BACKTRACK_LIST_ITEM',
  SCHEDULER_CLEAR_BACKTRACK_LIST: 'SCHEDULER_CLEAR_BACKTRACK_LIST',
  SCHEDULER_CLEAR_BACKTRACK_REDO_LIST: 'SCHEDULER_CLEAR_BACKTRACK_REDO_LIST',
  SCHEDULER_STORE_MOVING_START_DATE: 'SCHEDULER_STORE_MOVING_START_DATE',
};
////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actions = {
  fetchSchedulerDataFinished: function (payload) {
    return { type: actionTypes.SCHEDULER_FETCH_SCHEDULER_DATA_FINISHED, payload: payload };
  },
  fetchSchedulerCountersFinished: function (payload) {
    return { type: actionTypes.SCHEDULER_FETCH_SCHEDULER_COUNTERS_FINISHED, payload: payload };
  },
  resetState: function (payload) {
    return { type: actionTypes.SCHEDULER_PAGE_RESET_DATA, payload: payload };
  },
  setLoading: function (payload) {
    return { type: actionTypes.SCHEDULER_SET_LOADING, payload: payload };
  },
  addItemToBacktrackList(payload) {
    return { type: actionTypes.SCHEDULER_ADD_BACKTRACK_LIST_ITEM, payload };
  },
  clearBacktrackList() {
    return { type: actionTypes.SCHEDULER_CLEAR_BACKTRACK_LIST };
  },
  clearBacktrackRedoList() {
    return { type: actionTypes.SCHEDULER_CLEAR_BACKTRACK_REDO_LIST };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (isFirstLoading = false) {
  return function (dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.SCHEDULER);

    if (isFirstLoading) {
      navigatorApi.setLoading(true)(dispatch, getState);
    }

    api
      .init(dispatch, getState)()
      .then(() => {
        if (isFirstLoading) {
          navigatorApi.setLoading(false)(dispatch, getState);
        }
      });
  };
};

jsxActions.unmount = function () {
  return function (dispatch) {
    dispatch(actions.resetState());
  };
};

jsxActions.scrollToNow = function (schedulerEngineInstance) {
  return function () {
    if (schedulerEngineInstance) {
      let scrollOptions = {
        block: 'start',
        edgeOffset: 50, // px.
      };
      schedulerEngineInstance?.scrollToNow?.(scrollOptions);
    }
  };
};

jsxActions.autoSchedule = function (data) {
  return function (dispatch, getState) {
    autoSchedulerDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.reloadTasks = function () {
  return function (dispatch, getState) {
    api.reloadTasks(dispatch, getState)();
  };
};

jsxActions.assignSchedulerTask = function (data) {
  return function (dispatch, getState) {
    scheduleTaskDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.goToTask = function (data) {
  return function (dispatch, getState) {
    api.goToTask(dispatch, getState)(data);
  };
};

jsxActions.unAssign = function (tasks) {
  return function (dispatch, getState) {
    api.unAssign(dispatch, getState)(tasks);
  };
};

jsxActions.lockSchedulerTasks = function (data) {
  return function (dispatch, getState) {
    api.lock(dispatch, getState)(data);
  };
};

jsxActions.unlockSchedulerTasks = function (data) {
  return function (dispatch, getState) {
    api.unlock(dispatch, getState)(data);
  };
};

jsxActions.refreshPageData = function (query, runSideEffect) {
  return function (dispatch, getState) {
    api.refreshPageData(dispatch, getState)(query, runSideEffect);
  };
};

jsxActions.clearFilters = function (query, runSideEffect) {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.refreshPageData = function (dispatch, getState) {
  return function (query, runSideEffect) {
    gridApi.reload(dispatch, getState)(runSideEffect);
    return api.fetchData(dispatch, getState)(query, runSideEffect);
  };
};

api.fetchCounters = function (dispatch) {
  return function () {
    return schedulerService
      .fetchSchedulerCounters()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Scheduler counters Failed');
          return;
        }
        dispatch(actions.fetchSchedulerCountersFinished(response.data));
      })
      .catch((err) => {
        console.error('Fetch Scheduler counters failed ', err);
        return { success: false };
      });
  };
};

api.goToTask = function () {
  return function (data) {
    let scrollOptions = {
      block: 'start',
      edgeOffset: 50,
      animate: true,
      highlight: true,
      focus: true,
    };

    const event = data?.schedulerInstance?.eventStore?.getById?.(data.task.id);
    if (event) {
      data?.schedulerInstance?.scrollEventIntoView?.(event, scrollOptions);
    }
  };
};

api.unAssign = function (dispatch, getState) {
  return function (selectedOperations, backtrackTime = null) {
    dispatch(actions.setLoading(true));
    let query = {
      tasksAssignmentsIds: selectedOperations.map((operation) => {
        return operation.id;
      }),
      originalTimestamp: backtrackTime,
    };

    return schedulerService
      .unAssign(query)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('scheduler - unAssign Failed');
          dispatch(actions.setLoading(false));
          return response;
        }

        let errorCode = response?.applicationResponseStatus?.warnings?.mainWarning?.id;
        if (errorCode) {
          let desc = _getUnAssignMessageDialogBuilder(response.applicationResponseStatus.warnings.mainWarning);
          messageDialogApi.open(dispatch, getState)(desc);
          dispatch(actions.setLoading(false));
          return api.refreshPageData(dispatch, getState)();
        }

        messageDialogApi.showSuccess(dispatch, getState)('mat.scheduler.operations.view.delete.confirmation.success.message');
        if (!backtrackTime && selectedOperations.length === 1) {
          api.addItemToBacktrackList(dispatch)('unAssign', [selectedOperations]);
        }
        api.refreshPageData(dispatch, getState)();
        return response;
      })
      .catch((err) => {
        console.error('Fetch Scheduler data failed ', err);
        messageDialogApi.responseError(dispatch, getState)();
        return { success: false };
      });
  };
};

api.init = function (dispatch, getState) {
  return function () {
    return api
      .fetchData(dispatch, getState)()
      .then(() => {
        let label = createLabelHelper('mat.header.navigator.predefinedlists.').get('scheduler');

        let topNavigatorData = {
          name: label,
          type: navigationStates.SCHEDULER,
          id: -1,
          components: [],
        };
        navigatorApi.setData(dispatch, getState)(topNavigatorData);
      });
  };
};

api.fetchData = function (dispatch, getState) {
  return function (query, runSideEffect) {
    //Block attempt to fetch the data if refresh was clicked and query is empty.
    if (runSideEffect && !query.filters) {
      return null;
    }

    dispatch(actions.setLoading(true));

    api.fetchCounters(dispatch, getState)();

    let promise1 = schedulerService.fetchSchedulerData(query);
    let promise2 = stationsService.getAllStations();

    return Promise.all([promise1, promise2])
      .then((allResults) => {
        dispatch(actions.setLoading(false));

        let invalidResponse = allResults.find((result) => {
          return !Network.isResponseValid(result);
        });
        if (invalidResponse) {
          messageDialogApi.responseError(dispatch, getState)(invalidResponse);
          console.error('Fetch Scheduler data failed', invalidResponse);
          return { success: false };
        }

        let schedulerData = allResults?.[0].data;
        let allStations = allResults?.[1].dataList?.map((s) => {
          return {
            resourceId: s.id,
            operationsIds: s.operations?.map((o) => o.id),
          };
        });

        let data = schedulerHelper.convertToSchedulerData(schedulerData);
        data.allResources = allStations;
        dispatch(actions.fetchSchedulerDataFinished(data));

        return allResults?.[0];
      })
      .catch((err) => {
        console.error('Fetch Scheduler data failed ', err);
        messageDialogApi.responseError(dispatch, getState)();
        return { success: false };
      });
  };
};

api.reloadTasks = function (dispatch, getState) {
  return function () {
    gridApi.reload(dispatch, getState)();
    api.fetchCounters(dispatch, getState)();
  };
};

api.scrollToDate = function () {
  return function (data, schedulerEngineInstance) {
    if (!schedulerEngineInstance) {
      return;
    }

    if (data && data.events && data.events.length > 0) {
      let earliestDate = schedulerHelper.getEarliestEventDate(data.events);

      if (earliestDate) {
        let scrollOptions = {
          block: 'start',
          edgeOffset: 50,
          animate: true,
        };

        schedulerEngineInstance?.scrollToDate?.(earliestDate, scrollOptions);
      }
    }
  };
};

api.lock = function (dispatch, getState) {
  return function (data, backtrackTime = null) {
    dispatch(actions.setLoading(true));

    let query = {
      tasksAssignmentsIds: getOperationsIds(data),
      originalTimestamp: backtrackTime,
    };

    return schedulerService
      .lock(query)
      .then((response) => {
        dispatch(actions.setLoading(false));

        if (!Network.isResponseValid(response)) {
          console.error('scheduler - lock failed', response);

          return response;
        }
        if (!backtrackTime) {
          api.addItemToBacktrackList(dispatch)('lock', [data]);
        }
        api.refreshPageData(dispatch, getState)();
        return response;
      })
      .catch((err) => {
        console.error('scheduler - lock failed ', err);
        // messageDialogApi.responseError(dispatch, getState)();
        return { success: false };
      });
  };
};

api.unlock = function (dispatch, getState) {
  return function (data, backtrackTime = null) {
    dispatch(actions.setLoading(true));

    let query = {
      tasksAssignmentsIds: getOperationsIds(data),
      originalTimestamp: backtrackTime,
    };

    return schedulerService
      .unlock(query)
      .then((response) => {
        dispatch(actions.setLoading(false));

        if (!Network.isResponseValid(response)) {
          console.error('scheduler - unlock failed', response);
          return response;
        }
        if (!backtrackTime) {
          api.addItemToBacktrackList(dispatch)('unlock', [data]);
        }
        api.refreshPageData(dispatch, getState)();
        return response;
      })
      .catch((err) => {
        console.error('scheduler - unlock failed ', err);
        // messageDialogApi.responseError(dispatch, getState)();
        return { success: false };
      });
  };
};

api.addItemToBacktrackList = function (dispatch) {
  return function (actionType, params) {
    const time = DateTimeHelper.ConvertFromDate(new Date());
    const action = _getBacktrackAction(actionType, params, time);
    api.clearBacktrackRedoList(dispatch);
    dispatch(actions.addItemToBacktrackList(action));
  };
};

api.clearBacktrackList = function (dispatch) {
  dispatch(actions.clearBacktrackList());
};

api.clearBacktrackRedoList = function (dispatch) {
  dispatch(actions.clearBacktrackRedoList());
};

///////////////////////////////////////////////////////////////////////////
function getOperationsIds(operations) {
  return operations
    ? operations.map((op) => {
        return op.id;
      })
    : [];
}

function _getUnAssignMessageDialogBuilder(warning) {
  let title = createLabelHelper('').get('mat.scheduler.operations.view.warning.title');
  let message = createLabelHelper('').get(warning.id, undefined, warning.params || undefined);
  let children = [<MessageDialog.MessageArea key={'confirmMessageDataRow'} text={message} />];
  let className = 'scheduler-page-message-dialog';
  let buttons = [];
  let messageDialogDescriptor = { title, buttons, children, className, type: 'error' };
  return messageDialogDescriptor;
}

const _getBacktrackAction = (actionType, params, time) => {
  const item = {
    id: Math.floor(Math.random() * 1000),
    time,
  };

  if (actionType === 'lock') {
    item.undo = {
      action: 'unlock',
      params,
    };
    item.redo = {
      action: 'lock',
      params,
    };
  }

  if (actionType === 'unlock') {
    item.undo = {
      action: 'lock',
      params,
    };
    item.redo = {
      action: 'unlock',
      params,
    };
  }

  if (actionType === 'assign') {
    const [taskId, mainTaskId, data] = params;
    item.undo = {
      action: 'unAssign',
      params: [[{ id: taskId }]],
    };
    item.redo = {
      action: 'assign',
      params: [mainTaskId, data],
    };
  }

  if (actionType === 'unAssign') {
    const [event] = params[0];
    const { mainTaskId, resourceId, startDate, toolId = null } = event;
    const assignParams = { resourceId, startTime: DateTimeHelper.ConvertFromDate(startDate), toolId };
    item.undo = {
      action: 'assign',
      params: [`${mainTaskId}`, assignParams],
    };
    item.redo = {
      action: 'unAssign',
      params,
    };
  }

  if (actionType === 'move') {
    const [task] = params;

    const undoTask = { ...task, forceStartDate: task.originalDate };
    const redoTask = { ...task, forceStartDate: task.context.startDate };

    item.undo = {
      action: 'move',
      params: [undoTask],
    };
    item.redo = {
      action: 'move',
      params: [redoTask],
    };
  }

  if (actionType === 'reportTaskProgress') {
    const [data] = params;

    item.undo = {
      action: 'reportTaskProgress',
      params: [{ ...data, isRollbackToScheduled: true }],
    };
    item.redo = {
      action: 'reportTaskProgress',
      params: [data],
    };
  }

  return item;
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { navigationStates } from '../../../enums/navigationStates';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Label from 'infrastructure/js/components/Label/label.js';
import { getHomePagePathByType, getPathByType } from '../../../utils/navigationHelper';

import './logoSection.scss';
import logoImg from 'images/plataine-logo.png';

export default class LogoSection extends Component {
  renderOrganizationDataForPS = () => {
    if (PermissionManager.hasOrganizationDataForPSPermissions()) {
      return null;
    }
    let orgData = this.props.sData?.loggedInOrg?.name || '';
    return <Label text={orgData} className="org-label" />;
  };
  
  getHomePagePath() {
    const defaultHomePage = getPathByType(navigationStates.MANAGER_REPORT);
    const homePageType = this.props.sHomePageData.get('type');
    const homePageId = this.props.sHomePageData.get('id');

    if(!homePageType){
      return defaultHomePage;
    }
    
    return getHomePagePathByType(homePageType, homePageId) || defaultHomePage;
  }

  render() {
    let homePagePath = this.getHomePagePath();
    return (
      <Link to={homePagePath}>
        <div className="logo-section">
          <img className="plataine-logo" src={logoImg} alt="Plataine Logo" />
          <label className="logo-label">TPO</label>
          {this.renderOrganizationDataForPS()}
        </div>
      </Link>
    );
  }
}


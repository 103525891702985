import React from 'react';
import PropTypes from 'prop-types';
import Normalize from '../../controls/controlsNormalizations';
import {PL_TextField} from 'infrastructure/js/components/controls/TextField/textField';

require('./editableCell.scss');

export default class EditableCell extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {value: this.props.value.value}
  }

  // grid api function
  afterGuiAttached() {
    let input = document.getElementById(this.props.column.colId + this.props.rowIndex);
    if (!input) {
      return;
    }
    input.focus();
  };

// grid api function returns the value to the column cell
  getValue() {
    return this.state.value;
  }

  onBlur = () => {
    //grids api, stop editing invokes the get value func on blur.
    this.props.api.stopEditing();
  }

  onChange = (value) => {
    if(this.props.inputType === 'numeric'){
      this.setState({value: Normalize.number(this.props.isInt, 0, this.props.maxLength)(value, this.state.value)})
      return;
    }
    this.setState({value: value})
  };

  render() {
    let maxLengthString = this.props.inputType === 'string' ? this.props.maxLength : null;
    return (
      <PL_TextField id={this.props.column.colId + this.props.rowIndex}
                    name={this.props.column.colId + this.props.rowIndex}
                    className="inline"
                    onChangeCallback={(value) => this.onChange(value)}
                    onBlur={this.onBlur}
                    maxLength={maxLengthString}
                    value={this.state.value}
                    passive={true}/>
    )
  }
}


EditableCell.defaultProps = {
  inputType: 'string',
  isInt: false
};

EditableCell.propTypes = {
  inputType: PropTypes.string,
  maxLength: PropTypes.number,
  isInt:PropTypes.bool
};

import React, {Component} from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import classNames from 'classnames';
import ScanHeader from '../Common/ScanHeader/scanHeader';
import ScanBottom from '../Common/ScanBottom/scanBottom';
import ScanDetails from '../Common/ScanDetails/scanDetails';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import MaterialHelper from '../../../utils/materialHelper';
import * as AssetDetailsHelper from '../../../components/AssetPage/OverviewTab/AssetDetails/utils/assetDetailsHelper';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./scannedAssetPage.scss');

export default class ScannedAssetPage extends React.PureComponent {

  constructor(props) {
    super(props);

    let scannedAssets = this.props.sData.get('scannedAssets');
    if (!scannedAssets || scannedAssets.size === 0) {
      this.props.history.push('/ScanAsset');
    }
    this.labels = createLabelHelper('mat.mobile.scan.');
    this.pageLabels = createLabelHelper('mat.mobile.scannedAssetDetails.');
  }

  onBottomClick = () => {
    let path = '/ChangeLocation';
    this.props.history.push(path);
  };

  getAlertIcon = (asset, alertType) => {
    return AssetDetailsHelper.getIcon(asset, alertType)
  };


  getHeaderItems() {
    let scannedAssets = this.props.sData.get('scannedAssets');

    return {
      leftButton: {
        id: 'leftButton',
        icon: (scannedAssets.size === 1 ? 'pl pl-arrow-left' : 'pl pl-delete-icon'),
        action: this.props.actions.header.onRemoveAllScans,
        actionData: {data: this.props.history, withConfirmation: scannedAssets.size > 1},
      },
      rightButton: {
        id: 'rightButton',
        icon: 'pl pl-icon-add',
        action: this.props.actions.header.onNewScan,
        actionData: this.props.history,
      },
      middleButton: {
        id: 'middleButton',
        label: this.labels.get('header.buttons.button.scanned', undefined, {assetsCount: scannedAssets.size}),
        action: this.props.actions.header.onShowAllScans,
        actionData: this.props.history,
      }
    };
  };

  getBottomItems() {
    let scannedAssets = this.props.sData.get('scannedAssets');

    return {
      button: {
        id: 'changeLocationButton',
        action: this.onBottomClick,
        label: this.labels.get('bottom.button.changeLocation', undefined, {count: scannedAssets.size}),
      },
    };
  };

  renderEtlBond = (exposureTimeLeftBond, currentAsset)=>{
    if (PermissionManager.isEtlBondEnabled()) {
      return ( <div>
        <label>{this.pageLabels.get('etlBond')}</label>
        <label>{exposureTimeLeftBond} {this.getAlertIcon(currentAsset, getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'))}</label>
      </div>)
    }
  }



  getDetailsItems = (currentAsset) => {

    if (!currentAsset) {
      return [];
    }
    if (currentAsset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')) {
      return this.getRollDetails(currentAsset)
    }
    else if (currentAsset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('KIT')) {
      return this.getKitDetails(currentAsset)
    }
    else if (currentAsset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')) {
      return this.getToolDetails(currentAsset)
    }
    else if (currentAsset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')) {
      return this.getGroupDetails(currentAsset)
    }
    return null;
  }

  getKitDetails = (currentAsset) => {

    let expirationDate = currentAsset.expirationDate ? DateTimeHelper.FormatDateObjectToDayMonth(currentAsset.expirationDate.value) : '';
    let exposureTimeLeftBond = (currentAsset.exposureTimeLeftBond && currentAsset.exposureTimeLeftBond.value !== null) ?
      DateTimeHelper.ConvertMinutesToHoursMinutes(currentAsset.exposureTimeLeftBond.value) : '';
    let exposureTimeLeftCure = (currentAsset.exposureTimeLeftCure && currentAsset.exposureTimeLeftCure.value !== null) ?
      DateTimeHelper.ConvertMinutesToHoursMinutes(currentAsset.exposureTimeLeftCure.value) : '';
   let workOrderDueDate = currentAsset.workOrder?.dueDate ? DateTimeHelper.FormatDateObjectToDayMonth(currentAsset.workOrder.dueDate) : '';

    return (
      [
        {
          label: this.pageLabels.get('id', '', {assetType: currentAsset.objectType}),
          body: <label>{currentAsset.businessId}</label>
        },
        {
          label: this.pageLabels.get('type'),
          body: <label>{currentAsset.kitType.businessId}</label>
        },
        {
          bodyOnly: true,
          body: (<div className="section two-columns" key="etl">
            {this.renderEtlBond(exposureTimeLeftBond, currentAsset)}
            <div>
              <label>{this.pageLabels.get('etlCure')}</label>
              <label>{exposureTimeLeftCure} {this.getAlertIcon(currentAsset, getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'))}</label>
            </div>
          </div>)
        },
        {
          label: this.pageLabels.get('expiration'),
          body: <label>{expirationDate} {this.getAlertIcon(currentAsset, getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'))}</label>
        },
        {
          label: this.pageLabels.get('location'),
          body: <label>{currentAsset.location.name}</label>
        },
        {
          // label: this.pageLabels.get('length'),
          label: 'WORK ORDER',
          body: <label>{currentAsset.workOrder?.businessId}</label>
        },
        {
          // label: this.pageLabels.get('width'),
          label: 'Work Order Due Date',
          body: <label>{workOrderDueDate}</label>
        },
        ]
    )
  }

  getRollDetails = (currentAsset) => {

    //TODO: L should be in util function (also for ScannedAssetListItem)
    let lengthLeft = currentAsset.lengthLeft ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, currentAsset.lengthLeft, 2): 0;
    let m_Length = `${lengthLeft} ${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)}` ;
    let width = currentAsset.widthInCentimeter ? UnitHelper.serverValueToUserValue(unitTypes.WIDTH, currentAsset.widthInCentimeter, 2): 0;
    let m_Width = `${width} ${UnitHelper.getLabelForUnitType(unitTypes.WIDTH)}` ;
    let expirationDate = currentAsset.expirationDate ? DateTimeHelper.FormatDateObjectToDayMonth(currentAsset.expirationDate.value) : '';
    let exposureTimeLeftBond = (currentAsset.exposureTimeLeftBond && currentAsset.exposureTimeLeftBond.value !== null) ?
      DateTimeHelper.ConvertMinutesToHoursMinutes(currentAsset.exposureTimeLeftBond.value) : '';
    let exposureTimeLeftCure = (currentAsset.exposureTimeLeftCure && currentAsset.exposureTimeLeftCure.value !== null) ?
      DateTimeHelper.ConvertMinutesToHoursMinutes(currentAsset.exposureTimeLeftCure.value) : '';
    let materialDetails = MaterialHelper.getMaterialFullLabel(currentAsset.material.materialName, currentAsset.material.businessId);

    return (
      [
        {
          label: this.pageLabels.get('id', '', {assetType: currentAsset.objectType}),
          body: <label>{currentAsset.businessId}</label>
        },
        {
          label: this.pageLabels.get('type'),
          body: <label>{materialDetails}</label>
        },
        {
          bodyOnly: true,
          body: (<div className="section two-columns" key="etl">
            {this.renderEtlBond(exposureTimeLeftBond, currentAsset)}
            <div>
              <label>{this.pageLabels.get('etlCure')}</label>
              <label>{exposureTimeLeftCure} {this.getAlertIcon(currentAsset, getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'))}</label>
             </div>
          </div>)
        },
        {
          label: this.pageLabels.get('expiration'),
          body: <label>{expirationDate} {this.getAlertIcon(currentAsset, getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'))}</label>
        },
        {
          label: this.pageLabels.get('location'),
          body: <label>{currentAsset.location.name}</label>
        },
        {
          label: this.pageLabels.get('length'),
          body: <label>{m_Length}</label>
        },
        {
          label: this.pageLabels.get('width'),
          body: <label>{m_Width}</label>
        },
      ]
    )
  };

  getToolDetails = (currentAsset) => {
    return (
      [
        {
          label: this.pageLabels.get('id', '', {assetType: currentAsset.objectType}),
          body: <label>{currentAsset.businessId || ''}</label>
        },
        {
          label: this.pageLabels.get('type'),
          body: <label>{currentAsset.toolType?.businessId || ''}</label>
        },
        {
          label: this.pageLabels.get('location'),
          body: <label>{currentAsset.location?.name || ''}</label>
        },
      ]
    )
  }

  getGroupDetails = (currentAsset) => {
    return (
      [
        {
          label: this.pageLabels.get('id', '', {assetType: currentAsset.objectType}),
          body: <label>{currentAsset.businessId || ''}</label>
        },
        {
          label: this.pageLabels.get('type'),
          body: <label>{currentAsset.groupType?.businessId || ''}</label>
        },
        {
          label: this.pageLabels.get('location'),
          body: <label>{currentAsset.location?.name || ''}</label>
        },
      ]
    )
  }


  render() {

    let scannedAssets = this.props.sData.get('scannedAssets');
    let asset = scannedAssets.get(scannedAssets.size-1);

    let headerItems = this.getHeaderItems();
    let bottomItems = this.getBottomItems();
    let detailsItems = this.getDetailsItems(asset);

    return (
      <div className="scanned-asset-page">
        <ScanHeader {...headerItems}/>
        <ScanDetails details={detailsItems}/>
        <ScanBottom {...bottomItems}/>

      </div>
    )
  }
}


ScannedAssetPage.propTypes = {
};

ScannedAssetPage.defaultProps = {
};


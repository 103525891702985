import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as archivedToolsPageActions} from '../../actions/ArchivedToolsPage/archivedToolsPageActions';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import {gridsNames} from '../../enums/gridsNames';
import { jsxActions as activateAssetsDialogActions }    from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';
import { jsxActions as moveAssetsActions }        from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as archivedToolsGridActions } from '../../actions/ArchivedToolsPage/archivedToolsGridActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import ArchivedToolsPage from '../../components/PredefinedList/ArchivedToolsPage/archivedToolsPage';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(archivedToolsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      archivedToolsGridActions: bindActionCreators(archivedToolsGridActions, dispatch),
      activateAssetsDialogActions:  bindActionCreators(activateAssetsDialogActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let ArchivedToolsPageContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVED_TOOLS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVED_TOOLS) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sActivateAssetsDialogData: state.dialogs.get('activateAssetsDialog'),
      sMoveAssetsData:     state.dialogs.get('moveAssetsDialog'),
    };
  },
  mapDispatchToProps
)(ArchivedToolsPage);


export default ArchivedToolsPageContainer;




import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

require('./splitPanel.scss');

const draggingOverlayStyle = {'position': 'fixed', 'top': 0, 'bottom':0, 'left':0, 'right':0} ;

class SplitPanel extends Component {
  constructor(props) {
    super(props);

    const childCount = React.Children.count(props.children);

    this.state = {
      isDragging: false,
      isSplitterDown: false,
      firstPaneSize: (props.firstPaneInitialSize ? props.firstPaneInitialSize : null),
      firstPaneSizeWithUnits: (props.firstPaneInitialSize ? props.firstPaneInitialSize + 'px' : 0),
    };
  }

  componentDidMount() {
    this.calculateFirstPanelSize();
  }

  calculateFirstPanelSize = () => {
    if (this.compRef && this.state.firstPaneSize === null) {
      let compRect = this.compRef.getBoundingClientRect();
      let compSize = this.isVertical() ? compRect.height : compRect.width;
      this.setState(
        {
          firstPaneSize: Math.floor(compSize/2),
          firstPaneSizeWithUnits: compSize === 0 ? '50%' : Math.floor(compSize/2) + 'px',
        });
    }
  }

  componentWillUnmount() {
    this.unregisterEvents();
  }

  unFocus = () => {
    if (document.selection) {
      document.selection.empty();
    } else {
      try {
        window.getSelection().removeAllRanges();
      } catch (e) {}
    }
  };

  isVertical = () => {
    return this.props.direction === 'vertical';
  };

  onDragStart = (e) => {
    e.preventDefault();
    this.setState({ isSplitterDown: true});
    this.unFocus();
    this.registerEvents();
  };

  onDrag = (e) => {

    if (this.state.isSplitterDown) {
      this.setState({ isDragging: true, isSplitterDown: false});
    }

    let compRect = this.compRef.getBoundingClientRect();
    let firstPaneRect = this.firstPaneRef.getBoundingClientRect();
    let splitterRect = this.splitterRef.getBoundingClientRect();

    let firstPaneSize = this.isVertical() ? (e.clientY - firstPaneRect.top) : (e.clientX - firstPaneRect.left);
    let compSize = this.isVertical() ? compRect.bottom : compRect.right;
    let splitterSize = this.isVertical() ? splitterRect.height : splitterRect.width;

    let clientProp = this.isVertical() ? 'clientY' : 'clientX';

    if (firstPaneSize < 0) {
      firstPaneSize = 0;
    }
    else if (e[clientProp] > (compSize - splitterSize)) {
      firstPaneSize = compSize - splitterSize;
    }

    if (this.state.firstPaneSize !== firstPaneSize ) {

      this.setState({
        firstPaneSize: firstPaneSize ,
        firstPaneSizeWithUnits: firstPaneSize + 'px',
      });
    }
  };

  onDragStop = () => {
    this.setState({ isDragging: false, isSplitterDown: false });
    this.unregisterEvents();
  };

  registerEvents() {
    document.addEventListener('mouseup', this.onDragStop);
    document.addEventListener('mouseleave', this.onDragStop);
    document.addEventListener('mousemove', this.onDrag);
  }

  unregisterEvents() {
    document.removeEventListener('mouseup', this.onDragStop);
    document.removeEventListener('mouseleave', this.onDragStop);
    document.removeEventListener('mousemove', this.onDrag);
  }

  setFirstPaneRef = (r) => {
    this.firstPaneRef = r || this.firstPaneRef;
  };

  setCompRef = (r) => {
    this.compRef = r || this.compRef;
  };

  setSplitterRef = (r) => {
    this.splitterRef = r || this.splitterRef;
  };

  render() {
    const { className, children, direction, firstPaneInitialSize, firstPaneMaxOverflowSize, ...other } = this.props;
    const childCount = React.Children.count(children);
    const cssProp = (direction === 'vertical') ? 'height' : 'width';
    const itemStyle = { [cssProp]: this.state.firstPaneSizeWithUnits };

    let isOverflowDisabled = firstPaneMaxOverflowSize ? (this.state.firstPaneSize > firstPaneMaxOverflowSize && this.state.firstPaneSize < 600): false;

    if (childCount === 2) {
      return (
        <div className={cn('split-panel', className, direction, {'isDragging': this.state.isDragging})}
             ref={this.setCompRef}
             {...other}
        >
          <div className={cn('top split-pane', {'no-overflow': isOverflowDisabled})} style={itemStyle} ref={this.setFirstPaneRef}>
            {children[0]}
          </div>
          <div className={cn('splitter', direction, {'isDragging': this.state.isDragging})}
               ref={this.setSplitterRef}
               onMouseDown={this.onDragStart}
          />
          <div className="bottom split-pane">
            {children[1]}
          </div>
          <div className="dragging-overlay" style={this.state.isDragging ? draggingOverlayStyle : {}}/>
        </div>
      );
    }
    return null;
  }
}

SplitPanel.Direction = { Vertical: 'vertical', Horizontal: 'horizontal' };

SplitPanel.defaultProps = {
  direction: SplitPanel.Direction.Vertical,
  firstPaneMaxOverflowSize: 0,
};

SplitPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  direction: PropTypes.oneOf([SplitPanel.Direction.Vertical, SplitPanel.Direction.Horizontal]),
  firstPaneInitialSize: PropTypes.number, //initial top pane size
  firstPaneMaxOverflowSize: PropTypes.number, //workaround - it enables to turn off the first pane's overflow
};

export default SplitPanel;


import { Map , List } from 'immutable';
import { actionTypes as VisibilityDevicesViewActionsType } from '../../../actions/Administration/RfidSettingsPage/visibilityDevicesViewActions';

let defaultState = {
  loading: false,
  selectedDeviceApp : null,
  deviceAppsOptions : []
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case VisibilityDevicesViewActionsType.ADMINISTRATION_RF_SETTINGS_VISIBILITY_DEVICES_FETCH_DEVICE_APPS_IN_PROCESS:
      return state.set('loading', true);

    case VisibilityDevicesViewActionsType.ADMINISTRATION_RF_SETTINGS_VISIBILITY_DEVICES_FETCH_DEVICE_APPS_READY:
      state = state.set('deviceAppsOptions', action.payload);
      return state.set('loading',false);

    case VisibilityDevicesViewActionsType.ADMINISTRATION_RF_SETTINGS_VISIBILITY_DEVICES_DEVICE_APP_CHANGED:
      return state.set('selectedDeviceApp', action.payload);

    default:
      return state;
  }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import ActiveToolsGrid  from './ActiveToolsGrid/activeToolsGrid';
import {gridsNames} from '../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {PL_DialogWrapper}     from 'infrastructure/js/components/Dialog/dialog';
import ArchiveAssetsDialog    from '../../Dialogs/ArchiveAssetsDialog/archiveAssetsDialog';
import MoveAssetsDialog       from '../../Dialogs/MoveAssetsDialog/moveAssetsDialog';
import EditAssetAttributesDialog from '../../Dialogs/EditAssetAttributesDialog/editAssetAttributesDialog';
import ReportMaintenanceDialog from '../../Dialogs/ReportMaintenanceDialog/reportMaintenanceDialog';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import TabTitle from 'infrastructure/js/components/Tabs/tabTitle';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import {Tab} from 'react-bootstrap';
import ActiveToolsScheduleGrid from './ActiveToolsScheduleGrid/activeToolsScheduleGrid';
import FullScreenTabsSeparator from '../../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {ImportAcceptFileTypes, ImportTypes} from '../../../enums/importTypes';

require('./activeToolsPage.scss');

const TabKeys = {
  ACTIVE_TOOLS: 'currentStatus',
  SCHEDULED_TOOLS: 'schedule',
};

export default class ActiveToolsPage extends Component {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.activeTools.page.');
  }

  componentDidUpdate(prevProps) {
    let newTabId = this.props.match.params.tabId;

    if(prevProps.match.params.tabId !== newTabId){
      window.dispatchEvent(new Event('resize'));
    }
    if (newTabId && !(Object.values(TabKeys).includes(newTabId))) {
      window.location.hash = '#/PageNotFound';
    }
  }

  onTabClick = (tabId) => {
    if (tabId) {

      let newHash = `#/PredefinedViews/tools/active/${tabId}`;
      if (newHash !== window.location.hash) {
        window.location.hash = newHash;
      }
    }
  };

  getSelectedTab = () => {
    let tabId = this.props.match.params.tabId;
    return (tabId ? tabId : TabKeys.ACTIVE_TOOLS);
  };

  getTabTitles = () => {
    let activeTools = <TabTitle title={this.labels.get('tabs.currentStatus')} />;
    let scheduledTools = <TabTitle title={this.labels.get('tabs.schedule')}  />;

    return {
      activeTools,
      scheduledTools,
    };
  };


  getDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ACTIVE,
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')],
      preselectedAssets: this.props.sSelectedRowsData,
      parentComponent: this.props.actions.reload
    };
  };

  getMoveAssetsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NOT_CONTAINED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')],
      preselectedAssets: this.props.sSelectedRowsData,
      parentComponent: this.props.actions.reload
    };
  };


  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData} = this.props;
    let dialogConfig = this.getDialogConfig();

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS}
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        {
          id:'reportMaintenance',
          label: headerItemsLabels.REPORT_MAINTENANCE,
          icon: 'pl pl-report-maintenance',
          disabled: !sHasRows,
          action: actions.header.onReportMaintenanceClick,
          actionData: dialogConfig
        },
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.export,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS}
        },
      ],

      kebab: {

        menuItems: [
          ...(PermissionManager.hasArchiveAssetsPermissions() ? [{
            id:'archive',
            label: headerItemsLabels.ARCHIVE,
            action: actions.header.onMenuItemArchiveClick,
            actionData: dialogConfig,
            disabled: !sHasRows
          }] : []),
          {
            id:'moveTo',
            label: headerItemsLabels.MOVE_TO,
            action: actions.header.onMenuItemMoveToClick,
            actionData: this.getMoveAssetsDialogConfig(),
            disabled: !sHasRows
          },
          ...(PermissionManager.hasImportAssetsPermissions() ? [
          {
            id:'importTools',
            label: headerItemsLabels.IMPORT_TOOLS,
            action: actions.header.importItems,
            actionData: {
              importTypes: [ImportTypes.IMPORT_TOOLS],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: actions.reload,
              shouldIncludeLocation: true, //import is done NOT from a location or station , the import file locationId column is mandatory.
              showPrintLabels: false,
            }
          }] : []),
          ...(PermissionManager.hasImportAssetsPermissions() ? [{
            id:'importTags',
            label: headerItemsLabels.IMPORT_TAGS,
            action: actions.header.importItems,
            actionData: {
              importTypes: [ImportTypes.IMPORT_TAGS],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: actions.reload,
            }
          }] : []),
          ...(PermissionManager.hasEditAttributesPermissions() ? [{
            id:'editAttributes',
            label: headerItemsLabels.EDIT_ATTRIBUTES,
            action: actions.header.onMenuItemEditAssetAttributesClick,
            actionData: dialogConfig,
            disabled: !sHasRows
          }] : []),
        ]
      }
    };
  }
  getScheduledToolsHeaderItems() {
    let {actions} = this.props;

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS_SCHEDULE}
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
      ],

      kebab: {
        disabled: true,
        menuItems: [],
      }
    };
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderArchiveAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={ArchiveAssetsDialog}
                        show={this.props.sArchiveAssetsData.get('show')}
                        sData={this.props.sArchiveAssetsData}
                        actions={this.props.actions.archiveAssetsActions}
                        reloadParentComponent={this.reloadPage} />
    );
  };

  renderMoveAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={MoveAssetsDialog}
                        show={this.props.sMoveAssetsData.get('show')}
                        actions={this.props.actions.moveAssetsActions}
                        sData={this.props.sMoveAssetsData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderEditAssetAttributesDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={EditAssetAttributesDialog}
                        show={this.props.sEditAssetAttributesData.get('show')}
                        actions={this.props.actions.editAssetAttributesActions}
                        sData={this.props.sEditAssetAttributesData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderReportMaintenanceDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={ReportMaintenanceDialog}
                        show={this.props.sReportMaintenanceDialogActionsData.get('show')}
                        actions={this.props.actions.reportMaintenanceDialogActions}
                        sData={this.props.sReportMaintenanceDialogActionsData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderActiveTools = () => {
    let headerItems = this.getHeaderItems();
    return (<div className="active-tools-page">
        <Header {...headerItems}/>
        <ActiveToolsGrid actions={{...this.props.actions.activeToolsGridActions, ...this.props.actions.fetchEntitiesActions}} />

        { this.renderArchiveAssetsDialog() }
        { this.renderMoveAssetsDialog() }
        { this.renderEditAssetAttributesDialog() }
        { this.renderReportMaintenanceDialog() }
      </div>
    );
  }

  renderActiveAndScheduledTools = () => {

    let selectedTab = this.getSelectedTab();
    let activeToolsHeaderItems = this.getHeaderItems();
    let scheduledToolsHeaderItems = this.getScheduledToolsHeaderItems();
    let tabTitles = this.getTabTitles();

    return (<div className="active-tools-page">
        <Tabs activeKey={selectedTab} tabType="content" onSelect={this.onTabClick} id="content-tabs">
          <Tab eventKey={TabKeys.ACTIVE_TOOLS} animation={false} title={tabTitles.activeTools} tabClassName="wide">

            <Header {...activeToolsHeaderItems}/>
            <ActiveToolsGrid actions={{...this.props.actions.activeToolsGridActions, ...this.props.actions.fetchEntitiesActions}} />

            { this.renderArchiveAssetsDialog() }
            { this.renderMoveAssetsDialog() }
            { this.renderEditAssetAttributesDialog() }
            { this.renderReportMaintenanceDialog() }
          </Tab>
          <Tab eventKey={TabKeys.SCHEDULED_TOOLS} animation={false} title={tabTitles.scheduledTools} tabClassName="wide">

            <Header {...scheduledToolsHeaderItems}/>

            <ActiveToolsScheduleGrid  actions={{...this.props.actions.activeToolsScheduleGridActions, ...this.props.actions.fetchEntitiesActions}}/>
          </Tab>

        </Tabs>
      </div>
    );
  }

  render() {
    return (PermissionManager.isToolsSchedulingEnabled() ?
                    this.renderActiveAndScheduledTools() : this.renderActiveTools());
  }
};

ActiveToolsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
};

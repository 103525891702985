import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Validation from "../../../../../infrastructure/js/components/controls/controlsValidations";
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Button from 'infrastructure/js/components/controls/Button/button';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import {reduxForm} from "redux-form";
import Form from '../Common/Form/form';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper'
import {connect} from "react-redux";

require('./billingPage.scss');

class BillingPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.billing.');
    this.monthsOptions = DateTimeHelper.getMonthsOptions();
    this.yearsOptions = [];
  }

  componentDidMount() {
    this.props.actions.init(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.sData.get('loading') || !this.props.pristine) {
      return
    }
    let orgTime = DateTimeHelper.ConvertToDate({epochDateTime: this.props.orgTime});
    if(!orgTime){
      return
    }

    let currentYear = orgTime.year();
    // get the month (0-11)
    let prevMonth = orgTime.month();
    if(prevMonth === 0){
      prevMonth = 12;
      currentYear--;
    }

    let matchedYear = this.yearsOptions.find(a => a.value == currentYear);
    let matchedMonth = this.monthsOptions.find(a => a.value === prevMonth);

    let loggedInOrgId = this.props.sLoggedInUser.loggedInOrg.id;
    let matchedOrg = this.props.sData.get("orgs").find(a => a.id === loggedInOrgId);

    let initialValues = {
      billingOrg: matchedOrg ? matchedOrg : this.props.sData.get("orgs")[0],
      billingMonth: matchedMonth ? matchedMonth : this.monthsOptions[0],
      billingYear: matchedYear ? matchedYear : this.yearsOptions[0],
    };

    this.props.initialize(initialValues);
  }

  getYearsOptions() {
    //2018 -> current year
    let orgTime = DateTimeHelper.ConvertToDate({epochDateTime: this.props.orgTime});
    let currentYear = orgTime.year();
    let yearsOptions = [];
    for (let year = 2018; year <= currentYear; year++) {
      yearsOptions.push({label: year, value: year})
    }
    return yearsOptions;
  }

  onSubmit = (data) => {
    let newData = {
      orgId: data.billingOrg.value,
      orgName: data.billingOrg.label,
      orgStatus: data.billingOrg.data.status,
      month: data.billingMonth.value,
      monthName: data.billingMonth.label,
      year: data.billingYear.value
    };

    this.props.actions.fetchBillingInfo(newData);
  };

  renderSelectionSection() {
    return (
      <div className="billing-params-section">
        <InputSection label={this.labels.get('organization')} htmlFor="billingOrg" className="inline no-margin">
          <Combobox id="billingOrg" name="billingOrg" options={this.props.sData.get("orgs")}
                    validate={Validation.required} className="short-textfield"/>
        </InputSection>

        <InputSection label={this.labels.get('month')} htmlFor="billingMonth" className="inline no-margin">
          <Combobox id="billingMonth" name="billingMonth" options={this.monthsOptions} validate={Validation.required}
                    className="short-textfield"/>
        </InputSection>

        <InputSection label={this.labels.get('year')} htmlFor="billingYear" className="inline no-margin">
          <Combobox id="billingYear" name="billingYear" options={this.yearsOptions} validate={Validation.required}
                    className="short-textfield"/>
        </InputSection>

        <Button id='submit' bsStyle="primary" onClick={this.props.handleSubmit(this.onSubmit)}>Select</Button>
      </div>
    );
  }

  renderInfoSection() {
    let info = this.props.sData.get("info");
    if (!info) {
      return null;
    }

    return (
      <div className="billing-info-section">
        <label>{this.labels.get('info.organization')}<span>{info.orgName}</span></label>
        <label>{this.labels.get('info.organizationStatus')}<span>{info.orgStatus}</span></label>
        <div className="period-section">
          <label>{this.labels.get('info.month')}<span>{info.month}</span></label>
          <label>{this.labels.get('info.year')}<span>{info.year}</span></label>
        </div>

        <div className="assets-section">
          <label>{this.labels.get('info.rolls')}<span>{info.maxDailyActiveRolls}</span></label>
          <label>{this.labels.get('info.kits')}<span>{info.maxDailyActiveKits}</span></label>
          <label>{this.labels.get('info.tools')}<span>{info.maxDailyActiveTools}</span></label>
        </div>
      </div>
    );
  }

  getLoadingOverlay() {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }


  render() {
    if(!this.props.orgTime){
      return null
    }
    if(this.yearsOptions.length === 0){
      this.yearsOptions = this.getYearsOptions();
    }


    return <Form className="billing-page">
      {this.renderSelectionSection()}
      {this.getLoadingOverlay()}
      {this.renderInfoSection()}
    </Form>
  }
};

let BillingPageContainer = connect( state => {
  return { orgTime : state.system.get("serverDatetime")}
})(BillingPage);

export default reduxForm({
    form: 'BillingPage',
  }
)(BillingPageContainer);

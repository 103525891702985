import Network from 'infrastructure/js/modules/network';

var defaultLabelsMap;

if (__DEV__) {

  if (!__DOCKER__) {
    (async function () {
      const labelsMap = await import('../../../../plataine-saas/plataine-property-service/src/main/resources/labels_mat_default');
      const errorLabelsMap = await import('../../../../plataine-saas/plataine-property-service/src/main/resources/errors_labels_mat_default');

      // error labels are parsed differently from regular labels
      const parsedErrorLabels = Object.keys(errorLabelsMap.errors).reduce((acc, currentKey) => {
        const newKey = currentKey.split('.')[3];
        acc[newKey] = errorLabelsMap.errors[currentKey];
        return acc;
      }, {})

      defaultLabelsMap = {...labelsMap.labels, ...parsedErrorLabels}
    })()
  }

}

const getCurrentUserLanguage = function () {

  /* #1 =>  USE LOCAL LABELS FILE */
  if (__DEV__) {
    if (!__DOCKER__) {
      let processedLabels = processMacros(defaultLabelsMap)
      return Promise.resolve({
        map: processedLabels
      })
    }
  }

  /* #2 =>  REAL LABELS FROM SERVER */
  //return Network.get('allLabels?serviceId=4');
  let timestampParam = '&t=' + (new Date()).getTime();
  return Network.get('allLabels?' + timestampParam);

  /* #3 =>  GENERATE BROKEN TEST LABELS FOR DEBUG */
  // Can be used to detect missed parts in the UI.
  //return getBrokenLabels();

};

const getAllLabelsForPreview = function (data) {
  return Network.post('label/getAllLabelsForPreview', data);
  //Async API
  // let timestampParam = 't=' + (new Date()).getTime();
  // return Network.post('label/getAllLabelsForPreview?' + timestampParam, data);
};

const getBrokenLabels = function () {
  let brokenLabels = {};
  for (var key in defaultLabelsMap) {
    if (defaultLabelsMap.hasOwnProperty(key)) {
      //brokenLabels[key] = 'xxx';
      brokenLabels[key] = 'z' + defaultLabelsMap[key];
    }
  }

  return new Promise((resolve, reject) => {
    resolve({
      map: brokenLabels
    });
  });

};


////////////////////////////////////////
// Example:
//
// "dic.car.pascals": "Pony"
// +
// "mat.myawesomelabel": "My Awesome little $$dic.car.pascals$$!"
// =
// "mat.myawesomelabel": "My Awesome little Pony!"
//
// Returns new object with processed labels.
//
function processMacros(rawlabels) {

  let escapeRegExp = function (str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  };
  let replaceAll = function (str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  };


  let result = {};

  let allKeys = Object.keys(defaultLabelsMap);

  // Extract Dictionary.
  let dictionary = {};
  for (const key of allKeys) {
    if (key.startsWith('dic.')) {
      dictionary['$$' + key + '$$'] = defaultLabelsMap[key];
    }
  }

  // Iterate over Dictionary entries, and replace all macro tokens in all labels.
  for (const labelKey of allKeys) {
    if (labelKey.startsWith('dic.')) {
      continue; // we don't process dictionary entries.
    }

    let processedLabel = defaultLabelsMap[labelKey];
    for (const dicKey of Object.keys(dictionary)) {
      processedLabel = replaceAll(processedLabel, dicKey, dictionary[dicKey]);
    }

    result[labelKey] = processedLabel;
  }

  return result;
}


export default {getCurrentUserLanguage, getAllLabelsForPreview};









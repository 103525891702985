import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as filtersParametersViewActions} from '../../../actions/Administration/RfidSettingsPage/filtersParametersViewActions.js';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import FiltersParametersView from '../../../components/Administration/RfidSettingsPage/FiltersParametersView/FloodFiltersView/filtersParametersView.js';
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(filtersParametersViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let filtersParametersViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['rfidSettingsPage', 'filtersParametersViewData']),
    };
  },

  mapDispatchToProps
)(FiltersParametersView);

export default filtersParametersViewContainer;

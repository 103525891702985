import PropTypes from 'prop-types';
import classNames from 'classnames';

import './mobileModal.scss';
import { useEffect, useState } from 'react';

const MobileModal = ({ header, children, footer, show }) => {
  const [overlayClass, setOverlayClass] = useState('');
  const modalClass = show ? 'open' : 'close';

  useEffect(() => {
    if (show) {
      setOverlayClass('open');
    } else {
      setTimeout(() => {
        setOverlayClass('');
      }, 250);
    }
  }, [show]);

  return (
    <div className={classNames(overlayClass, 'mobile-modal-overlay')}>
      <div className={classNames(modalClass, 'mobile-modal')}>
        <div className="mobile-modal-header">{header}</div>
        <div className="mobile-modal-content">{children}</div>
        <div className="mobile-modal-footer">{footer}</div>
      </div>
    </div>
  );
};

MobileModal.propTypes = {
  show: PropTypes.bool,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
};

MobileModal.defaultProps = {
  show: false,
  header: 'Basic Modal Header',
  footer: null,
  children: null,
};

export default MobileModal;

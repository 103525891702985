import Network from "../../../../infrastructure/js/modules/network";

export function stop(jobInstanceId) {
  return Network.put(`batchJobs/stopJob/${jobInstanceId}`);
}

export function getJobActivityLog( activityLogId, query = {}) {
  return Network.post(`batchJobs/${activityLogId}/activities`, query);
}

export function getJobAssetsDetailsStatus( jobExecutionId) {
  // return Network.post(`batchJobs/${activityLogId}/activities`, query);
  return Network.get(`batchJobs/${jobExecutionId}/status/assetsdetails`);
}

export function pollingResult( jobExecutionId, options) {
  return Network.polling.pollingResult(jobExecutionId, options);

}

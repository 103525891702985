import React from 'react';

import { Prompt } from 'react-router-dom';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {headerItemsLabels} from '../../../../Common/Header/header';
import {reduxForm} from 'redux-form';
import RfidHeader from '../../Common/rfidHeader';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import {RfidSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import Overlay from 'infrastructure/js/components/Overlay/overlay';


require('./filtersParametersView.scss');

class FiltersParametersView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
    };
    this.labels = createLabelHelper('mat.administration.rfidsettings.filtersparameters.view.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_FLOOD_FILTERS);
    this.props.actions.init();
    this.initFormValues();
  }

  // componentWillMount() { moved to componentDidMount()
  //   this.props.actions.init();
  // }

  componentDidUpdate(prevProps) {
    let data = this.props.sData.get('filtersData');
    // allows to update values returning from async process and prevent init data while typing
    if (data !== prevProps.sData.get('filtersData')) {
      this.initFormValues();
    }
  }

  initFormValues = () => {
    let data = this.props.sData.get('filtersData');
    if (!data) {
      return;
    }
    let initialValue = {
      immediateDetectionSequenceLength: data.immediateDetectionSequenceLength,
      lastSeenDetectionWindow: data.silenceCheckEveryMSec,
      minimalLastSeenEventAge: data.silenceRequiredLengthMSec,
      lastSeenSequenceLength: data.silenceRequiredReadSequence,
      pingPongTimeForAlertSec: data.pingPongTimeForAlertSec,
      enableCheckbox: data.enabled,
      deviceAppDropDown: this.props.sData.get('selectedDeviceApp'),
      id: data.id
    };
    // this.state.isEnabled = data.enabled;
    this.setState({isEnabled: data.enabled});
    this.props.initialize(initialValue);
  }

  getHeaderItems() {
    return {
      dropdown: {
        name: 'deviceAppDropDown',
        id: 'deviceAppDropDown',
        options: this.props.sData.get('deviceAppsOptions'),
        disabled: this.props.sData.get('deviceAppsOptions').length <= 1,
        changeCallback: this.props.actions.onDeviceAppChanged,
      },
      buttons: [
        {
          id:'save',
          className: 'no-icon',
          label: headerItemsLabels.SAVE,
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.props.pristine || !this.props.sData.get('selectedDeviceApp'))
        }
      ],
    };
  }

  onSubmit = (data) => {
    let query = {
      deviceAppProfileId: data.deviceAppProfileId,
      immediateDetectionSequenceLength: data.immediateDetectionSequenceLength,
      silenceCheckEveryMSec: data.lastSeenDetectionWindow,
      silenceRequiredLengthMSec: data.minimalLastSeenEventAge,
      silenceRequiredReadSequence: data.lastSeenSequenceLength,
      pingPongTimeForAlertSec: data.pingPongTimeForAlertSec,
      enabled: data.enableCheckbox,
      id: data.id
    };
    this.props.actions.header.onSaveFiltersParametersClick(query)
  }

  renderLoadingOverlay = () => {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

  renderEnableParametersSection(isDisabled) {
    return (
      <div className="filters-view-enable-section">
        <label htmlFor="enableCheckbox">
          <Checkbox name="enableCheckbox" id="enableCheckbox" value={false} onClick={this.onCheckboxClick} disabled={isDisabled}/>
          <span>{this.labels.get('enablefloodfilter')}</span>
        </label>
      </div>
    );
  }

  onCheckboxClick = () => {
    this.setState({isEnabled: !this.state.isEnabled});
  }

  renderPrompt = () => {
    return (
      <Prompt
        when={!this.props.pristine}
        message={location =>
          this.navigationLabels.get('datanotsaved.text')
        }
      />
    );
  }

  renderParametersSection(isDisabled) {
    return (
      <div className="filters-view-parameters-section">
        <InputSection label={this.labels.get('immediatedetectionsequencelength')+'*'}
                      htmlFor="immediateDetectionSequenceLength" className="inline" forceLabelOverflow>
          <TextField id="immediateDetectionSequenceLength" name="immediateDetectionSequenceLength"
                     validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}
                     className="short-textfield" disabled={isDisabled || !this.state.isEnabled}/>
        </InputSection>
        <InputSection label={this.labels.get('lastseensequencelength')+'*'}
                      htmlFor="lastSeenSequenceLength" className="inline" forceLabelOverflow>
          <TextField id="lastSeenSequenceLength" name="lastSeenSequenceLength"
                     validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}
                     className="short-textfield" disabled={isDisabled || !this.state.isEnabled}/>
        </InputSection>
        <InputSection label={this.labels.get('lastseendetectionwindow')+'*'}
                      htmlFor="lastSeenDetectionWindow" className="inline" forceLabelOverflow>
          <TextField id="lastSeenDetectionWindow" name="lastSeenDetectionWindow"
                     validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}
                     className="short-textfield" disabled={isDisabled || !this.state.isEnabled}/>
        </InputSection>
        <InputSection label={this.labels.get('pingpongalertinterval')+'*'}
                      htmlFor="pingPongTimeForAlertSec" className="inline" forceLabelOverflow>
          <TextField id="pingPongTimeForAlertSec" name="pingPongTimeForAlertSec"
                     validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}
                     className="short-textfield" disabled={isDisabled || !this.state.isEnabled}/>
        </InputSection>
        <InputSection label={this.labels.get('minimallastseeneventage')+'*'}
                      htmlFor="minimalLastSeenEventAge" className="inline" forceLabelOverflow>
          <TextField id="minimalLastSeenEventAge" name="minimalLastSeenEventAge"
                     validate={Validation.required} normalize={Normalize.number(true, 0, 99999999)}
                     className="short-textfield" disabled={!this.state.isEnabled}/>
        </InputSection>
      </div>
    );
  }

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    let isDisabledField = !this.props.sData.get('selectedDeviceApp');
    return (
      <div className="administration-view filters-parameters-view">
        {this.renderPrompt()}
        <div className="title">{title}</div>
        {this.renderLoadingOverlay()}
        <RfidHeader {...headerItems} change={this.props.change} selectedDeviceApp={this.props.sData.get('selectedDeviceApp')}/>
        {this.renderEnableParametersSection(isDisabledField)}
        {this.renderParametersSection(isDisabledField)}
      </div>
    );
  }
}

export default reduxForm({
    form: 'FiltersParametersForm'
  }
)(FiltersParametersView);

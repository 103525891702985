import Network from 'infrastructure/js/modules/network';

export function getSpoolActivityLog(assetId, query) {
  return Network.post(`spools/${assetId}/activities`, query);
}
//
// export function PrintRollLabel(data) {
//   return Network.post(`download/label/roll`, data, {blobResponse : true});
// }
//

export function fetchSpools(query = {}) {
  return Network.post('spools/items?active=true', query);
}

export function fetchArchivedSpools(query = {}) {
  return Network.post('spools/items?active=false', query);
}
//
// export function createSubRolls(data) {
//   let rollId = data.parentRollId;
//   delete data.parentRollId;
//   return Network.post(`rolls/${rollId}/subrolls`, data);
// }
// export function createSubRollsByWeight( data) {
//   let rollId = data.parentRollId;
//   delete data.parentRollId;
//   return Network.post(`rolls/${rollId}/subrolls-by-weight`, data);
// }
//
// export function generateSubRollId(parentRollId) {
//   return Network.get(`rolls/${parentRollId}/generateSubRollId`);
// }
//
export function updateSpool(spoolId, data) {
  return Network.put(`spools/${spoolId}`, data);
}

export function generateSpoolId() {
  return Network.get('spools/generateId');
}

export function createSpool(data, locationId) {
  return Network.post('spools', data);
}

export function getSpoolsDataByStationId(stationID, query = {}, isAll = false) {
  if (isAll)  return Network.post(`stations/${stationID}/spools/items`, query);
  return Network.post(`stations/${stationID}/spools/items?active=true`, query);
}

export function getShippedSpoolsByStationId(stationID, query = {}) {
  return Network.post(`stations/${stationID}/spools/items?shippedToCollaborator=true`, query);
}

export function getReceivingSpoolsByStationId(stationID, query = {}) {
  return Network.post(`stations/${stationID}/spools/items?active=true&inTransit=true`, query);
}
//
// export function cancelCutReport(locationId, reportedCutId, addConsumedValuesBack) {
//   return Network.post(`stations/${locationId}/not-kitted-reported-cuts/${reportedCutId}/cancel`, { addConsumedValuesBack });
// }
//
// export function reportCuts(locationId, cutsToReport) {
//   return Network.post(`stations/${locationId}/multiCuts`, cutsToReport);
// }
// export function reportCutsByWeight(locationId, cutsToReport) {
//   return Network.post(`stations/${locationId}/multiCuts-by-weight`, cutsToReport);
// }
//
export function getSpoolDetails(assetId){
  return Network.get(`spools/${assetId}`);
}
//
export function updateShelfLife(data, callback) {
  return Network.polling.post('spools/multiShelfLifeUpdate' , data, {callback});
}
//
export function editSpoolAttributes(data, callback){
  return Network.polling.put('spools/update' , data, callback);
}


// export function getRollsBySmartSelection(data) {
//   //Add default sorting by multiple columns. this should be removed when multiple columns sort will be enabled
//   if (data.sortByProperty === ""){
//     data.sortByProperty = "rollEtlDateFilter asc, rollExpirationDateFilter asc, rollLength asc";
//   }
//   return Network.post('rolls/pickup/items', data);
// }
//
// export function getMSSInitialLocations() {
//   return Network.get('rolls/pickup/items/getLocationsFilterSelection');
// }
//
// export function getRollByBusinessId(data){
//   return Network.post('rolls/search', data, {ignore404NotFound : true});
// }
// export function addToPickList(ids = [], pickDateFrom, pickDateTo){
//   return Network.post('rolls/pickup/allocate', { ids, pickDateFrom, pickDateTo });
// }
// export function fetchPickList(query = {}){
//   return Network.post('rolls/picklist/items', query);
// }
// export function removeFromPickList(ids = []){
//   return Network.post('rolls/pickup/deallocate', {ids: ids});
// }
//
// export function fetchInspectionStatuses(cutId) {
//   return Network.get('rolls/getRollInspectionStatuses');
// }

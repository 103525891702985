import React, { Component } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DashboardHeader from '../Components/DashboardHeader/dashboardHeader';
import ProductionChart from '../Components/ProductionChart/productionChart';
import CommonKPI from '../Components/CommonKPI/commonKPI';
import * as DashboardHelper from '../../utils/dashboardHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';

require('./productionDashboardPage.scss');

export default class ProductionDashboardPage extends Component {

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.dashboard.');

    this.state = {filterData: null};
  }

  componentDidMount() {
    this.props.actions.init();
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  getChartData = () => {
    let {sData} = this.props;
    let chartData = sData.get('chartData');
    return chartData ? chartData : [];
  };

  getKPIsData = () => {
    let {sData} = this.props;
    let data = sData.get('kpiData');

    if (data) {
      let kpiData = [
        {
          title: this.labels.get('kpi.defectsNumber'),
          leftData: data.defectsNumber,
          leftDataClassName: DashboardHelper.getKpiDataClassName(data.defectsNumber, data.defectsNumberLastYear),
          icon: DashboardHelper.getKpiIcon(data.defectsNumber, data.defectsNumberLastYear),
          iconClassName: data.defectsNumber > data.defectsNumberLastYear ? 'red' : 'green',
          rightData: data.defectsNumberLastYear,
          timeFrame: DashboardHelper.getKpiTimeFrame(data, this.labels),
        },
        {
          title: this.labels.get('kpi.defectsRate'),
          leftData: DashboardHelper.toPercentage(data.defectsRate),
          leftDataClassName: (data.defectsRate > data.defectsRateLastYear ? 'red' : data.defectsRate < data.defectsRateLastYear ? 'green' : ''),
          icon: DashboardHelper.getKpiIcon(data.defectsRate, data.defectsRateLastYear),
          iconClassName: data.defectsRate > data.defectsRateLastYear ? 'red' : 'green',
          rightData: DashboardHelper.toPercentage(data.defectsRateLastYear),
          timeFrame: DashboardHelper.getKpiTimeFrame(data, this.labels),
        },
        {
          title: this.labels.get('kpi.totalProducedKits'),
          leftData: data.producedCounter,
          icon: DashboardHelper.getKpiIcon(data.producedCounter, data.producedCounterLastYear),
          rightData: data.producedCounterLastYear,
          timeFrame: DashboardHelper.getKpiTimeFrame(data, this.labels),
        },
      ];
      return kpiData;
    }

    return [];
  };

  renderKPIs = () => {

    let items = this.getKPIsData();

    let KPIs = items.map((item, index) => {
      return <CommonKPI key={index} data={item} />
    });

    return (
      <div className="kpis-container">
        {KPIs}
      </div>
    )
  };

  renderLoadingOverlay = (loading) => {
    if (!loading) {
      return null;
    }
    return <Overlay.Loading />;
  };

  renderNoRowsOverlay = (loading) => {
    let {sData} = this.props;
    let chartData = sData.get('chartData');

    if (!loading && chartData && chartData.length === 0) {
      return (
        <div className="production-overlay">
          <Overlay.Label text={this.labels.get('noRows')} />
        </div>);
    }
    return null;
  };

  onSubmitCallback = (data) => {
    let newData = {
      category: data.category.value,
      timePeriod: data.timePeriod.value,
      comparisonPeriod: data.compareTo.value
    };

    let filterData = {
      timePeriod: data.timePeriod.value,
      comparisonPeriod: data.compareTo.value
    };

    this.setState({filterData});

    this.props.actions.fetchData(newData);
  };

  renderComment = () => {
    let text = this.labels.get('bottom.comment');
    return (
      <div className="bottom-comment">{text}</div>
    )
  };


  render() {
    let {sData} = this.props;
    let headerData = sData.get('headerData');
    let chartData = sData.get('chartData');
    let kpiData = sData.get('kpiData');
    let loading = sData.get('loading');

    return (
      <div className="production-dashboard-page">

        {!!headerData && <DashboardHeader form="dashboardHeader"
                                        headerData={headerData}
                                        showTypeFilter={false}
                                        showCauseFilter={false}
                                        showWoStatusFilter={false}
                                        onSubmitCallback={this.onSubmitCallback}
        />}

        {!!kpiData && this.renderKPIs()}

        {!!(chartData && chartData.length > 0) && <ProductionChart chartData={this.getChartData()}  filterData={this.state.filterData} loading={loading}/>}

        {!!(chartData && chartData.length > 0) && this.renderComment()}

        {this.renderLoadingOverlay(loading)}

        {this.renderNoRowsOverlay(loading)}
      </div>
    )
  }
};









import Network from 'infrastructure/js/modules/network';

export function fetchDeviceApps(query) {
  // return Network.post('users/deviceApps');
  return Network.post('users/deviceAppsWithProfiles');
}

export function createDeviceApp(data){
  let query = {
    username: data.deviceName,
    updateWindowSlot: data.updateWindowSlot
  };
  return Network.post('users/createDeviceApp', query);
}

export function editDeviceApp(data) {
  let query = {userName: data.deviceName, updateWindowSlot:data.updateWindowSlot};
  return Network.put(`users/deviceApps/${data.id}`, query);
}

export function getDeviceProfile(data) {
  return Network.post('deviceApp/getDeviceProfile' , data);
}

export function getDeviceAppReaders(id) {
  return Network.get(`deviceAppProfile/${id}/readers`);
}

export function getWeight() {
  return Network.polling.post('telemetry/readSensor/' , {sensorName: "DEFAULT"}, {pollingRequest: 'batchJobs/JOB_EXECUTION_ID/status-with-printing'});
}

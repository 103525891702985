import React, { Component } from 'react';
import {reduxForm} from 'redux-form';
import {LineChart, CartesianGrid, XAxis,YAxis, Tooltip, Line, Legend} from 'recharts';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MaterialHelper from "../../../../../utils/materialHelper";
import * as DashboardHelper from '../../../utils/dashboardHelper.js';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import {MATERIAL_USE_CHART_MAX_MATERIAL_TYPES} from '../../../../../actions/Reports/inventoryDashboardPageActions.js';
import Label from 'infrastructure/js/components/Label/label.js';
import {enumTypes, getLookupOptions, getEnumValue, getEnumLabel} from '../../../../../utils/enumHelper';
import UnitHelper, { unitTypes } from "../../../../../../../infrastructure/js/utils/uomHelper";

require('./materialUsageChart.scss');

export class UsageMaterialChart extends Component {



  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.dashboard.inventory.usageManagement.materialUsageChart.');

    this.materialUsagePeriodOptions = getLookupOptions(enumTypes.INVENTORY_DASHBOARD_GENERAL_TIME_PERIOD, undefined, 1);
    this.colors = [...DashboardHelper.chartColors];
  }

  componentDidMount() {
    let initialValues = {
      period: {
        value: getEnumValue(enumTypes.INVENTORY_DASHBOARD_GENERAL_TIME_PERIOD)('LAST_WEEK'),
        label: getEnumLabel(enumTypes.INVENTORY_DASHBOARD_GENERAL_TIME_PERIOD)('LAST_WEEK')
      }
    };

    this.props.initialize(initialValues);
  }

  getChartData = () => {
    if (!this.props.chartData) {
      return [];
    }

    let values = [];
    this.props.chartData.materialUsageDataResponseModel.forEach((materialData, materialIndex) => {
      materialData.materialUsageLengthForPeriodList.forEach((periodData, periodIndex) => {
        if (!values[periodIndex]) {
          values[periodIndex] = {
            period: createLabelHelper('').get(periodData.subPeriodPlataineLabel.labelKey, undefined, periodData.subPeriodPlataineLabel.params),
            periodLabel: this.labels.get('period'),
          }
        }

        values[periodIndex]["material" + materialIndex] = UnitHelper.serverValueToUserValue(unitTypes.LENGTH, periodData.usedLength, 2);
      });
    });

    return values;
  }

  customTooltipOnYourLine = (e) => {
    if (e.active && e.payload != null && e.payload[0] != null) {
      return (<div className="custom-tooltip">
        <p>{`${e.payload[0].payload.periodLabel} ${e.payload[0].payload.period}`}</p>
        {
          this.props.chartData.materialUsageDataResponseModel.map((materialData, materialIndex) => {
            return (<p key={materialData.materialName}>{`${MaterialHelper.getMaterialFullLabel(materialData.materialName, materialData.materialBusinessId)}: ${e.payload[0].payload['material'+ materialIndex]}`}</p>);
          })
        }
      </div>);
    }
    else {
      return "";
    }
  }

  renderChart = () => {
    if(!this.props.chartData.materialUsageDataResponseModel || !this.props.chartData.materialUsageDataResponseModel.length){
      return  <div className={'empty-chart-section'}><span className={'no-results'}>{this.labels.get('noResults')}</span></div>;
    }
    let chartData = this.getChartData();

    let lines = [];
    this.props.chartData.materialUsageDataResponseModel.every((material, index) => {
      if (index === MATERIAL_USE_CHART_MAX_MATERIAL_TYPES) {
        return false;
      }

      lines.push(<Line key={material.materialBusinessId} name={MaterialHelper.getMaterialFullLabel(material.materialName, material.materialBusinessId)} dataKey={"material" + index}  type="monotone" stroke={this.colors[(index % this.colors.length)]} />)

      return true;
    });

    return (
      <div className={'chart-section'}>
        <LineChart width={617} height={400} data={chartData} key={'999'} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period" angle={-45}  textAnchor="end" interval={0} height={150}/>
          <YAxis />
          <Tooltip content={this.customTooltipOnYourLine}/>
          <Legend />
          {lines}
        </LineChart>
      </div>
    );
  };

  onPeriodChange = (period) => {
    if (this.props.onChangeAction) {
      this.props.onChangeAction((period && period.value) ? period.value : null);
    }
  }

  render() {
    if(!this.props.chartData){
      return null;
    }
``
    let title = `${this.labels.get('title')} (${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`;
    if(this.props.chartData.materialUsageDataResponseModel.length > MATERIAL_USE_CHART_MAX_MATERIAL_TYPES){
      title = `${title} - ${this.labels.get('maxReachedTitle', undefined, { maxValue: MATERIAL_USE_CHART_MAX_MATERIAL_TYPES })}`;
    }
    return (
        <div className="usage-material-chart">
          <div className="chart-header">
            <Label className='chart-title' text={title} tooltip={title}/>
            <Combobox className="period-select"
                      id="period"
                      name="period"
                      options={this.materialUsagePeriodOptions}
                      validate={Validation.required}
                      onChangeCallback={this.onPeriodChange}
            />
          </div>
          {this.renderChart()}
        </div>
    )
  }
};

export default reduxForm({
    form: 'UsageMaterialChart'
  }
)(UsageMaterialChart);

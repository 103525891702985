import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from 'infrastructure/js/components/controls/Button/button';
import classNames from 'classnames';

require('./scanBottom.scss');

export default class ScanBottom extends React.PureComponent {

  onClick = (button) => {
    if (button.action) {
      button.action(button.actionData);
    }
  };


  createButton = (button, className2) => {
    if (!button) {
      return null;
    }
    return (<Button
      id='submit'
      bsStyle="primary"
      className={classNames(className2, button.label ? '' : 'only-icon', button.className)}
      icon={button.icon}
      // loading={button.loading}
      onClick={() => this.onClick(button)}
      disabled={button.disabled || button.loading}
    >
      {button.label ? button.label : ''}
    </Button>);
  };



  render() {
    let button = this.createButton(this.props.button, 'single-btn');
    if (!button) {
      return null;
    }
    return (

      <div className={classNames('scan-bottom', this.props.className)}>
        {button}
      </div>
    );
  };
}
ScanBottom.defaultProps = {
  button: {},
};

ScanBottom.propTypes = {
  button: PropTypes.object,
};



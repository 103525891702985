import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import * as workOrdersService from '../../services/WorkOrders/workOrdersService'
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  WORKORDERPAGE_OVERVIEW_TAB_FETCHDATA_STARTED:  'WORKORDERPAGE_OVERVIEW_TAB_FETCHDATA_STARTED',
  WORKORDERPAGE_OVERVIEW_TAB_FETCHDATA_FINISHED: 'WORKORDERPAGE_OVERVIEW_TAB_FETCHDATA_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function() {
    return {type: actionTypes.WORKORDERPAGE_OVERVIEW_TAB_FETCHDATA_STARTED};
  },
  fetchDataFinished: function(payload) {
    return {type: actionTypes.WORKORDERPAGE_OVERVIEW_TAB_FETCHDATA_FINISHED, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(workOrderId) {
  return function(dispatch, getState) {
    api.init(dispatch, getState)(workOrderId);
  }
};

jsxActions.reload = function(workOrderId) {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)(workOrderId);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.init = function(dispatch, getState) {
  return function(workOrderId) {
    return api.fetchData(dispatch, getState)(workOrderId);
  }
};

api.reload = function(dispatch, getState) {
  return function(workOrderId) {
    return api.fetchData(dispatch, getState)(workOrderId);
  }
};

api.fetchData = function(dispatch, getState) {
  return function(workOrderId) {
    dispatch(actions.fetchDataStarted());

    let promise1 = workOrdersService.fetchWorkOrderById(workOrderId);
    let promise2 = workOrdersService.fetchRelatedAssetWorkOrderById(workOrderId);

    return Promise.all([promise1, promise2]).then((allResults) => {

      let workOrder = allResults[0].data;

      let workOrderData = {
        workOrderDetails: _convertServerItemToUIData(workOrder, getState ),
        relatedAssets: allResults[1].dataList,
      };

      dispatch(actions.fetchDataFinished(workOrderData));
      return workOrder;
    });
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _convertServerItemToUIData(workOrder, getState) {
  let serverTimeSeconds = getState().system.get('serverDatetime');
  if (!(workOrder.dueDate === undefined || workOrder.dueDate === null)) {
    let diff = DateTimeHelper.GetDifferenceInDays(workOrder.dueDate, serverTimeSeconds);
    if (diff < 0) {
      workOrder.dueDateExpired = true;
    }
  }

  return workOrder;
}





import React from 'react';

import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {RfidSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {headerItemsLabels} from '../../../../Common/Header/header';
import MqttBrokersSettingsViewGrid from './MqttBrokersSettingsViewGrid/mqttBrokersSettingsViewGrid';
import RfidHeader from '../../Common/rfidHeader';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import CreateMqttBrokerDialog from './Dialogs/CreateMqttBrokerDialog/createMqttBrokerDialog';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';


require('./mqttBrokersSettingsView.scss');

class MqttBrokersSettingsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.mqtt.brokers.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_MQTT_SETTINGS_BROKERS);
    this.props.actions.init(false);
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  handleRemoveBrokers = () => {
    let selectedBrokersIds = this.props.sSelectedRowsData.map((item) => {
      return item.id;
    });

    this.props.actions.onRemoveBrokersClick(this.getMessageDialogBuilder(), selectedBrokersIds);
  }

  getMessageDialogBuilder = () => {
    return (fnCloseDialog, callback) => {
      let title = this.labels.get('removeBrokers.confirmation.title');
      // let type = 'warning';
      let message = this.labels.get('removeBrokers.confirmation.message');
      let children =  [<MessageDialog.DataRow key={'confirmMessageDataRow'} label={message} value={''}/>];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'remove',text: this.dialogLabels.get('remove'), action: callback , bsStyle: 'primary'}
      ];
      // let className = 'remove-printers-confirm-message';
      let messageDialogDescriptor = {title, buttons, children,  className: '', type: 'warning'};
      return messageDialogDescriptor;
    };
  };

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;
    return {
      dropdown: {
        name : 'deviceAppDropDown',
        id : 'deviceAppDropDown',
        options: this.props.sData.get('deviceAppsOptions'),
        disabled: this.props.sData.get('deviceAppsOptions').length <= 1,
        changeCallback: this.props.actions.onDeviceAppChanged,
      },
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreateMqttBrokerClick,
          disabled: this.props.sData.get('deviceAppsOptions').length === 0
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditMqttBrokerClick,
          actionData: selectedRowsData.get(0)
        },
        {
          id:'remove',
          label: headerItemsLabels.REMOVE,
          icon: 'pl pl-delete-icon',
          disabled: !(hasRows && selectedRowsData.size >= 1),
          action: this.handleRemoveBrokers
        },
      ],
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  renderGrid = () => {
    if(!this.props.sData.get('selectedDeviceApp')) {
      return null;
    }
    return <MqttBrokersSettingsViewGrid actions={this.props.actions.mqttBrokersSettingsViewGridActions} />

  }


  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();

    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <RfidHeader {...headerItems} change = {this.props.change} selectedDeviceApp={this.props.sData.get('selectedDeviceApp')}/>
        {this.renderGrid()}

        <PL_DialogWrapper dialogComponent={CreateMqttBrokerDialog}
                          show={this.props.sCreateMqttBrokerDialogData.get('show')}
                          actions={this.props.actions.createMqttBrokerDialogActions}
                          sData={this.props.sCreateMqttBrokerDialogData}
                          deviceApp={this.props.sData.get('selectedDeviceApp')}
                          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}

export default reduxForm({
    form: 'mqttBrokersForm'
  }
)(MqttBrokersSettingsView);

MqttBrokersSettingsView.propTypes = {
  sGrid: PropTypes.object,
};

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../../../MessageDialog/messageDialogActions.js';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import * as daHelper from '../../../Dialogs/dialogActionsHelper';
import { dialogsNames } from '../../../../enums/dialogsNames';
import * as shiftsService from '../../../../services/Shifts/shiftsService';
import { updateTemplateShiftHumanCapacity } from '../../../../services/Shifts/shiftsService';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.SHIFT_SETTINGS_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.SHIFT_SETTINGS_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  submit(dispatch, getState) {
    return function ({ dataToSubmit, reloadParentComponent, isTemplate, isMultiSelectedShifts }) {
      dispatch(actions.setBusy(true));

      const submitMethod = _getSubmitMethod(isTemplate, isMultiSelectedShifts);

      if (isMultiSelectedShifts && !isTemplate) {
        const workDate = dataToSubmit.date || getState().administration.getIn(['matSettingsPage', 'weeklyShifts', 'weekStartDate']);
        dataToSubmit.workDate = DateTimeHelper.ConvertFromDate(workDate);
      }

      return submitMethod(dataToSubmit)
        .then((response) => {
          dispatch(actions.setBusy(false));

          if (!Network.isResponseValid(response)) {
            console.error('Update shift failed.');
            messageDialogApi.responseError(dispatch, getState)(response);
            return { success: false };
          }

          const assignmentErrors = response?.data?.failedTemplateRequests ?? response?.data?.failedShiftRequests;
          if (assignmentErrors?.length > 0) {
            messageDialogApi.open(dispatch, getState)(_assignMessageDialogBuilder(assignmentErrors));
          }

          const validations = Network.hasValidationWarnings(response);
          if (validations?.warnings) {
            const messageDialogDescriptor = _warningMessageDialogDescriptor(validations);
            if (messageDialogDescriptor) {
              messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
            }
          }

          dispatch(actions.hide());

          if (reloadParentComponent) {
            reloadParentComponent();
          }

          return { success: true };
        })
        .catch((err) => {
          console.error('Update shift failed.', err);
          messageDialogApi.responseError(dispatch, getState)();
        });
    };
  },
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  submit(data, isEditMode, reloadParentComponent) {
    return function (dispatch, getState) {
      return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
    };
  },
};

function _warningMessageDialogDescriptor(warnings) {
  let validations = dialogHelper.getValidationArea(warnings);

  if (validations && validations.length > 0) {
    let messages = [];
    validations.forEach((v) => {
      if (v && v.messages) {
        v.messages.forEach((m) => {
          messages.push(m);
        });
      }
    });

    if (messages.length > 0) {
      let title = messages[0] ? messages[0].message : 'Invalid Input';
      let type = 'warning';
      let className = 'oneBackground';

      return { title, type, className };
    }
  }
  return null;
}

function _assignMessageDialogBuilder(errorsArray) {
  const dialogLabels = createLabelHelper('mat.administration.matsettings.weeklyShiftsTemplate.alert.failedAssignments.');

  const children = errorsArray.map((errorData, i) => {
    const label = dialogLabels.get('messageRow', '', errorData);
    return <MessageDialog.MessageRow key={i} text={label} />;
  });

  return { title: dialogLabels.get('title'), type: 'warning', className: '', children };
}

function _getSubmitMethod(isTemplate, isMultiSelectedShifts) {
  return isTemplate ? updateTemplateShiftHumanCapacity : shiftsService.updateWeeklyShift;
}

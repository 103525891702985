import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import * as AssetHelper from '../../utils/assetHelper';
import {navigationStates} from '../../enums/navigationStates';
import { api as overviewTabApi } from './overviewTabActions';
import { api as gridApi} from './activityLogTabGridActions';
import { api as attachmentGridApi} from './attachmentTabGridActions';
import { api as navigationApi } from '../AppTopbar/navigatorActions';
import { api as systemApi } from '../System/systemActions';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MaterialHelper from '../../utils/materialHelper';
import * as assetService from '../../services/Assets/assetService';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ASSETPAGE_FETCHDATA_FINISHED: 'ASSETPAGE_FETCHDATA_FINISHED',
  ASSETPAGE_CLEAR_DATA: 'ASSETPAGE_CLEAR_DATA',
  ASSETPAGE_UNMOUNT: 'ASSETPAGE_UNMOUNT',
  ASSETPAGE_ATTACHMENT_DELETED_ITEMS: 'ASSETPAGE_ATTACHMENT_DELETED_ITEMS'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataFinished: function(payload) {
    return {type: actionTypes.ASSETPAGE_FETCHDATA_FINISHED, payload: payload};
  },
  clearData: function(payload) {
    return {type: actionTypes.ASSETPAGE_CLEAR_DATA, payload: payload};
  },
  unmount: function(items) {
    return {type: actionTypes.ASSETPAGE_UNMOUNT};
  },
  setAttachmentDeletedFilter: function(data) {
    return {type: actionTypes.ASSETPAGE_ATTACHMENT_DELETED_ITEMS, payload: data};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.updateAttachmentDeletedItems = function(data){
  return function (dispatch, getState) {
    dispatch(actions.setAttachmentDeletedFilter(data));
    return attachmentGridApi.reload(dispatch, getState)();
  }
};

jsxActions.init = function (shouldRefreshGrid, assetType, assetId) {
  return function (dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.ASSET_PAGE);

    if (shouldRefreshGrid) {
       gridApi.reload(dispatch, getState)();
    }
    else {
      dispatch(actions.clearData());
    }
    let isNewAsset = !shouldRefreshGrid;
    api.fetchData(dispatch, getState)(assetType, assetId, isNewAsset);
  }
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};

jsxActions.onConfirmAssetLocationClick = function (data) {
  return function (dispatch, getState) {
    api.confirmLocation(dispatch, getState)(data)
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.reload = function(dispatch, getState) {
  return function(assetType, assetId , shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }
    api.fetchData(dispatch, getState)(assetType , assetId);
  }
};

api.fetchData = function(dispatch, getState) {
  return function(assetType , assetId, isNewAsset=false) {
    navigationApi.setLoading(isNewAsset)(dispatch, getState);

    Promise.all([
      overviewTabApi.init(dispatch, getState)(assetType, assetId),
      attachmentGridApi.reload(dispatch, getState)()
    ]).then((allResults) => {
      let assetDetails = allResults[0];

      let topNavigatorData = _createAssetNavigatorData(assetDetails);
      navigatorApi.setData(dispatch, getState)(topNavigatorData);

      dispatch(actions.fetchDataFinished(assetDetails));
      navigationApi.setLoading(false)(dispatch, getState);
    });
  }
};

api.confirmLocation = function (dispatch, getState){
  return function ({assetId, objectType, reloadParentComponent}){
    
    assetService.confirmAssetLocation(assetId).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Confirm Location failed.');
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }
      
      messageDialogApi.open(dispatch, getState)(_confirmLocationMessageDialogDescriptor(objectType));

      if (reloadParentComponent) {
        reloadParentComponent();
      }
      
      return { success: true };
    })
      .catch((err) => {
        console.error('Confirm Location failed.', err);
        messageDialogApi.responseError(dispatch, getState)();
      });
  }
}

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _createAssetNavigatorData(asset) {
  let subname = '';
  let type = '';
  if (AssetHelper.isRoll(asset.objectType)) {
    subname = ( asset.material) ? MaterialHelper.getMaterialAvailableLabel(asset.material.materialName, asset.material.businessId) : '';
    type = navigationStates.ROLL;
  }
  else if (AssetHelper.isSpool(asset.objectType)) {
    subname = ( asset.material) ? MaterialHelper.getMaterialAvailableLabel(asset.material.materialName, asset.material.businessId) : '';
    type = navigationStates.SPOOL;
  }
  else if (AssetHelper.isKit(asset.objectType)) {
    subname = asset.kitType ? asset.kitType.businessId : '';
    type = navigationStates.KIT;
  }
  else if (AssetHelper.isPart(asset.objectType)) {
    subname = asset.partType ? asset.partType.businessId : '';
    type = navigationStates.PART;
  }
  else if (AssetHelper.isTool(asset.objectType)) {
    subname = asset.toolType ? asset.toolType.businessId : '';
    type = navigationStates.TOOL;
  }
  else if (AssetHelper.isGroup(asset.objectType)) {
    subname = asset?.groupType?.businessId || '';
    type = navigationStates.GROUP;
  }
  else {
    console.error('AssetPageActions: Unknown asset type: ' + asset.objectType);
  }

  let status = AssetHelper.isAssetArchived(asset.assetStatus) ? asset.assetStatus : '';
  let entityTypeLabel = createLabelHelper('mat.entity.type.').get(asset.objectType.toLowerCase()); //TODO: L Tool - handle it

  let topNavigatorData = {
    name: (entityTypeLabel ? entityTypeLabel.toUpperCase() : '') + ' ' + asset.businessId,
    subname: subname,
    status: status,
    type: type,
    id: asset.id,
    components: []
  };

  return topNavigatorData;
}

function _confirmLocationMessageDialogDescriptor(objectType) {
  const dialogLabels = createLabelHelper('mat.operatins.confirmation.confirmLocation.');
  const title = AssetHelper.isGroup(objectType) ? dialogLabels.get('group.message') : dialogLabels.get('asset.message')
  const type = 'warning';
  const className = 'oneBackground';

  return { title, className, type };
}



import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import {reduxForm} from 'redux-form';
import Combobox from "../../../../../../../infrastructure/js/components/controls/Combobox/combobox";

require('./dashboardHeader.scss');

class ToolMaintenanceDashboardHeader extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {};
    this.labels = createLabelHelper('mat.dashboard.toolsMaintenance.filter.');

    this.state = {
      periods: [
        {label: this.labels.get('timeFrame.lastWeek'), value: 'LAST_WEEK'},
        {label: this.labels.get('timeFrame.lastFourWeeks'), value: 'LAST_FOUR_CALENDAR_WEEKS'},
        {label: this.labels.get('timeFrame.lastThreeMonths'), value: 'LAST_THREE_MONTHS'}
      ]
    };
  }

  componentDidMount() {
    this.initializeValues();
    this.props.actions.init(true);
  }

  initializeValues = () => {
    let initialValues = {timeFrame: this.state.periods[0]};
    this.props.initialize(initialValues);
  };

  onSubmit = (filter) => {
    this.props.actions.fetchData(filter);
  };

  handleTimeFrameOnChange = (data) => {
    this.onSubmit(data.value)
  };

  render() {
    return (
      <div className="dashboard-header">
        <InputSection label={this.labels.get('timeFrame')} htmlFor="timeFrame" className="inline">
          <Combobox id="timeFrame" name="timeFrame" options={this.state.periods} onChangeCallback={this.handleTimeFrameOnChange}/>
        </InputSection>
      </div>
    );
  }
}

export default reduxForm({
  }
)(ToolMaintenanceDashboardHeader);



import { actionTypes as createKitWizardActionTypes } from '../../actions/LocationPage/KittingView/createKitWizardActions';
import { kitDisplayModes } from '../../actions/LocationPage/KittingView/createKitWizardActions';

import { Map, List,  fromJS } from 'immutable';
import {actionTypes as ActivateAssetsDialogActionTypes} from "../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions";

let defaultState = {
  loading: false,

  initialData: null,

  selectedCut: null,

  startParameters: {
    shouldRefreshPageOnCancel: false
  },
};


export default function(state = fromJS(defaultState), action) {
  switch(action.type) {

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_FETCH_INITIAL_DATA_STARTED:
      return state.set('loading', true);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_FETCH_INITIAL_DATA_READY:
      return state.set('initialData', action.payload)
        .set('loading', false);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_SAVE_START_PARAMETERS:
      return state.set('startParameters', action.payload);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_SELECTED_CUT_CHANGED:
      return state.set('selectedCut', action.payload);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_SAVE_NEW_KITS_FOR_SUBMIT_DATA:
      return state.set('newKitsForSubmit', action.payload);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_SUBMIT_CREATE_KITS_STARTED:
      return state.set('loading', true);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_SUBMIT_CREATE_KITS_FINISHED:
      return state.set('loading', false);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_RESET:
      return fromJS(defaultState); // Reset state completely.

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_SET_VALIDATION_WARNINGS_DATA:
      return state.set('validationMessage', action.payload.reset ? {} : action.payload)
          .set('hasError', action.payload.reset ? false : !action.payload.canIgnoreValidation)
          .set('showIgnoreValidationCheckbox',  action.payload.reset ? false : action.payload.canIgnoreValidation)
          .set('hasHighSeverityWarning', action.payload.reset ? false : action.payload.hasHighSeverityWarning)
          .set('hasMediumSeverityWarning', action.payload.reset ? false : action.payload.hasMediumSeverityWarning)
          .set('isIgnoreValidationWarnings', false);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('showIgnoreValidationCheckbox', false)
        .set('hasHighSeverityWarning', false)
        .set('hasMediumSeverityWarning', false)
        .set('hasError', false)
        .set('isIgnoreValidationWarnings', false);

    case createKitWizardActionTypes.CUTKITSTATION_CREATEKITSWIZARD_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
      return state.set('isIgnoreValidationWarnings', action.payload);

    default:
      return state;
  }
}






////////////////////////////////
// HELPERS


// let updateInitialWizardData = function(state, payload) {
//
//   state =  state
//     .setIn(["selectOptions", "projects"],    List(payload.projectsData.dataList.map(   (obj)=> { return { value: obj.id, label: obj.businessId, data: obj } })))
//     .setIn(["selectOptions", "parts"],       List(payload.partsData.dataList.map(      (obj)=> { return { value: obj.id, label: obj.businessId, data: obj } })))
//     .setIn(["selectOptions", "workorders"],  List(payload.workOrderData.dataList.map(  (obj)=> { return { value: obj.id, label: obj.businessId, data: obj } })))
//     .setIn(["selectOptions", "materials"],   List(payload.materialsData.dataList.map(  (obj)=> { return { value: obj.id, label: obj.businessId, data: obj } })))
//     .setIn(["selectOptions", "kitTypes"],    List(payload.kitTypesData.dataList.map(   (obj)=> { return { value: obj.id, label: obj.businessId, data: obj } })));
//
//   state = updateKitTypesForKit(state, {
//     kitKey: 1,
//     kitTypesOptions: List(payload.kitTypesData.dataList.map(   (obj)=> { return { value: obj.id, label: obj.businessId, data: obj } }))
//   });
//
//   return state;
// };




let addKitToList = function(state, payload) {
  let newKitsList   = state.get('newKitsList');
  let kitKeyCounter = state.get('kitKeyCounter');

  let nextKitKey    = ++kitKeyCounter;
  newKitsList       = newKitsList.push(buildDefaultKitDataItem(nextKitKey));

  state = state.set('newKitsList', newKitsList);
  state = state.set('kitKeyCounter', kitKeyCounter);
  return state;
};


let updateKitItemDisplay = function(state, payload) {
  let newKitsList   = state.get('newKitsList');
  let foundIndex    = newKitsList.findKey(x => x.get('kitKey') === payload.kitKey);

  newKitsList = newKitsList.update(foundIndex, (x) => {
    return x.set('display', payload.display);
  });

  return state.set('newKitsList', newKitsList);
};




let updateKitTypesForKit = function(state, payload) {
  let newKitsList   = state.get('newKitsList');
  let foundIndex    = newKitsList.findKey(x => x.get('kitKey') === payload.kitKey);

  newKitsList = newKitsList.update(foundIndex, (x) => {
    return x.setIn(['selectOptions', 'kitTypes'], payload.kitTypesOptions);
  });

  return state.set('newKitsList', newKitsList);
};


// let updateFreeTagsForKit = function(state, payload) {
//
//   let kitKey = payload.kitKey;
//   let tagsForKitOptions = List(payload.tagsList.map((x)=> { return { value: x.id, label: x.tagKey, data: x } }));
//
//
//   let newKitsList   = state.get('newKitsList');
//   let foundIndex    = newKitsList.findKey(x => x.get('kitKey') === kitKey);
//
//   newKitsList = newKitsList.update(foundIndex, (x) => {
//     return x.setIn(['selectOptions', 'freeTags'], tagsForKitOptions);
//   });
//
//   return state.set('newKitsList', newKitsList);
// };


let updateKitTypeMaterialsForKit = function(state, payload) {
  let newKitsList   = state.get('newKitsList');
  let foundIndex    = newKitsList.findKey(x => x.get('kitKey') === payload.kitKey);

  newKitsList = newKitsList.update(foundIndex, (x) => {
    return x.setIn(['selectOptions', 'kitTypeMaterials'],
      payload.kitTypeMaterials);
  });

  return state.set('newKitsList', newKitsList);
};

let updateSubstitutableMaterialsForKit = function(state, payload) {
  let newKitsList   = state.get('newKitsList');
  let foundIndex    = newKitsList.findKey(x => x.get('kitKey') === payload.kitKey);

  newKitsList = newKitsList.update(foundIndex, (x) => {
    return x.setIn(['selectOptions', 'substitutableMaterials'],
      payload.substitutableMaterialsOptions);
  });

  return state.set('newKitsList', newKitsList);
};














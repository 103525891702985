import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {List} from 'immutable';
import {jsxActions as headerActions} from '../../../actions/Header/headerActions';
import { jsxActions as fetchEntitiesActions }      from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as assetsViewActions }        from '../../../actions/LocationPage/AssetsView/assetsViewActions';
import { jsxActions as assetsSpoolsGridActions }        from '../../../actions/LocationPage/AssetsView/assetsSpoolsGridActions';
import {jsxActions as createSpoolActions} from '../../../actions/LocationPage/AssetsView/createSpoolActions';
import {gridsNames} from '../../../enums/gridsNames';
import SpoolsView from '../../../components/LocationPage/AssetsView/SpoolsView/spoolsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(assetsViewActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      headerActions:        bindActionCreators(headerActions, dispatch),
      assetsSpoolsGridActions:        bindActionCreators(assetsSpoolsGridActions, dispatch),
      createSpoolActions:        bindActionCreators(createSpoolActions, dispatch),
    }
  }
}

let SpoolsViewContainer = connect(
  (state) => {
    let sGrid = state.grid.get(gridsNames.LOCATION_ASSET_SPOOLS) ? state.grid.get(gridsNames.LOCATION_ASSET_SPOOLS) : null;
    return {
      sGrid:                  sGrid,
      sHasRows:               sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData:      sGrid ? sGrid.get('selectedRowsData') : List(),
      sData:                  state.locationPage.getIn(['assetsView', 'spoolsView']),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),
      sLoggedInUser :         state.login.get('loggedInUser'),
      sDataCreateSpool:        state.locationPage.get('createSpoolData'),
    };
  },
  mapDispatchToProps
)(SpoolsView);

export default SpoolsViewContainer;





import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as alertedGroupsPageActions} from '../../actions/AlertedGroupsPage/alertedGroupsPageActions'
import {gridsNames} from '../../enums/gridsNames';

import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as shelfLifeUpdateActions } from '../../actions/Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialogActions';
import { jsxActions as alertedGroupsGridActions } from '../../actions/AlertedGroupsPage/alertedGroupsGridActions';
import { jsxActions as archiveAssetsActions }   from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import { jsxActions as moveAssetsActions }      from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as editAssetAttributesActions }      from '../../actions/Dialogs/EditAssetAttributesDialog/editAssetAttributesDialogActions.js';
import { jsxActions as printPdfLabelsActions }  from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';

import AlertedGroupsPage from '../../components/PredefinedList/AlertedGroupsPage/alertedGroupsPage';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(alertedGroupsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      shelfLifeUpdateActions:   bindActionCreators(shelfLifeUpdateActions, dispatch),
      alertedGroupsGridActions: bindActionCreators(alertedGroupsGridActions, dispatch),
      archiveAssetsActions:     bindActionCreators(archiveAssetsActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      editAssetAttributesActions: bindActionCreators(editAssetAttributesActions, dispatch),
      printPdfLabelsActions:      bindActionCreators(printPdfLabelsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let AlertedGroupsContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.PREDEFINED_LIST_ALERTED_GROUPS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ALERTED_GROUPS) : null;
    return {
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sShelfLifeUpdateData: state.dialogs.get('shelfLifeUpdateDialog'),
      sArchiveAssetsData:   state.dialogs.get('archiveAssetsDialog'),
      sMoveAssetsData:      state.dialogs.get('moveAssetsDialog'),
      sEditAttributesData: state.dialogs.get('editAttributesDialog'),
      sEditAssetAttributesData: state.dialogs.get('editAssetAttributesDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(AlertedGroupsPage);


export default AlertedGroupsContainer;

import React from 'react';
import CommonCsvSettingsView from '../Components/CommonCsvSettingsView/commonCsvSettingsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
require('./projectCsvSettingsView.scss');

export default  class ProjectCsvSettingsView extends React.PureComponent {

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_CSV_PROJECT);
    this.props.actions.load('PROJECT');
  }

  render() {
    return (
        <CommonCsvSettingsView
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          // entityType={ getEnumValue(enumTypes.OBJECT_TYPE)('PROJECT')}
                          entityType={ 'PROJECT'}
        />
    );
  }
}


import * as kitsService from '../../../services/Kits/kitsService';
import * as tagsService from '../../../services/Tags/tagsService';
import { api as kittingViewApi } from './kittingViewActions.js';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions.js';
import { api as locationPageApi } from '../locationPageActions.js';
import { api as cancelKitReportDialogApi } from './cancelKitReportDialogActions.js';
import { api as wizardApi } from 'infrastructure/js/components/Wizard/wizardActions';
import MaterialHelper from '../../../utils/materialHelper';
import Network from 'infrastructure/js/modules/network';
import * as additionalFieldsService from '../../../services/Administration/additionalFieldsService';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { getEnumValue, enumTypes } from '../../../utils/enumHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { api as printRfidTagsApi } from '../../PrintRfidActions/printRfidActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';

let createKitWizard_WIZARD_Api = wizardApi.createWizardSpecificAdapter('CREATE_KIT_WIAZRD');

let myState = function (getState) {
  return getState().cutKitStation.get('createKitWizard');
};

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CUTKITSTATION_CREATEKITSWIZARD_FETCH_INITIAL_DATA_STARTED:
    'CUTKITSTATION_CREATEKITSWIZARD_FETCH_INITIAL_DATA_STARTED',
  CUTKITSTATION_CREATEKITSWIZARD_FETCH_INITIAL_DATA_READY:
    'CUTKITSTATION_CREATEKITSWIZARD_FETCH_INITIAL_DATA_READY',
  CUTKITSTATION_CREATEKITSWIZARD_SAVE_START_PARAMETERS:
    'CUTKITSTATION_CREATEKITSWIZARD_SAVE_START_PARAMETERS',
  CUTKITSTATION_CREATEKITSWIZARD_SAVE_NEW_KITS_FOR_SUBMIT_DATA:
    'CUTKITSTATION_CREATEKITSWIZARD_SAVE_NEW_KITS_FOR_SUBMIT_DATA',
  CUTKITSTATION_CREATEKITSWIZARD_SUBMIT_CREATE_KITS_STARTED:
    'CUTKITSTATION_CREATEKITSWIZARD_SUBMIT_CREATE_KITS_STARTED',
  CUTKITSTATION_CREATEKITSWIZARD_SUBMIT_CREATE_KITS_FINISHED:
    'CUTKITSTATION_CREATEKITSWIZARD_SUBMIT_CREATE_KITS_FINISHED',
  CUTKITSTATION_CREATEKITSWIZARD_RESET: 'CUTKITSTATION_CREATEKITSWIZARD_RESET',
  CUTKITSTATION_CREATEKITSWIZARD_SELECTED_CUT_CHANGED:
    'CUTKITSTATION_CREATEKITSWIZARD_SELECTED_CUT_CHANGED',

  CUTKITSTATION_CREATEKITSWIZARD_SET_VALIDATION_WARNINGS_DATA:
    'CUTKITSTATION_CREATEKITSWIZARD_SET_VALIDATION_WARNINGS_DATA',
  CUTKITSTATION_CREATEKITSWIZARD_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
    'CUTKITSTATION_CREATEKITSWIZARD_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
  CUTKITSTATION_CREATEKITSWIZARD_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX:
    'CUTKITSTATION_CREATEKITSWIZARD_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchInitialDataStarted: function (payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_FETCH_INITIAL_DATA_STARTED,
      payload: payload,
    };
  },

  fetchInitialDataReady: function (payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_FETCH_INITIAL_DATA_READY,
      payload: payload,
    };
  },

  saveStartParameters: function (payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_SAVE_START_PARAMETERS,
      payload: payload,
    };
  },

  saveNewKitsForSubmitData: function (payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_SAVE_NEW_KITS_FOR_SUBMIT_DATA,
      payload: payload,
    };
  },

  submitKitsStarted: function (payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_SUBMIT_CREATE_KITS_STARTED,
      payload: payload,
    };
  },
  submitKitsFinished: function (payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_SUBMIT_CREATE_KITS_FINISHED,
      payload: payload,
    };
  },

  reset: function (payload) {
    return { type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_RESET, payload: payload };
  },

  setValidationWarningsData(payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_SET_VALIDATION_WARNINGS_DATA,
      payload: payload,
    };
  },
  hideIgnoreValidationWarningsCheckbox(payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_HIDE_IGNORE_VALIDATION_WARNINGS_CHECKBOX,
      payload: payload,
    };
  },
  toggleIgnoreValidationWarningsCheckbox(payload) {
    return {
      type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_TOGGLE_IGNORE_VALIDATION_WARNINGS_CHECKBOX,
      payload: payload,
    };
  },
  selectedCutChanged(payload){
    return {type: actionTypes.CUTKITSTATION_CREATEKITSWIZARD_SELECTED_CUT_CHANGED, payload}
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.generateKitId = function () {
  return function (dispatch, getState) {
    return api.generateKitId(dispatch, getState)();
  };
};

jsxActions.onCancel = function (messageDialogBuilder) {
  return function (dispatch, getState) {
    createKitWizard_WIZARD_Api.close(dispatch, getState)();

    let wizardStartParameters = myState(getState).get('startParameters');

    // It's true if ReportKitting dialog said so.
    if (wizardStartParameters.shouldRefreshPageOnCancel) {
      locationPageApi.reload(dispatch, getState)();
    }

    // Destroy wizard sData.
    dispatch(actions.reset());

    // We have some data from Report Kitting dialog, need to display it.
    if (wizardStartParameters.existingKitsKittedPlies) {
      let messageDialogDescriptor = messageDialogBuilder(
        {
          // createdKits: [],
          updatedKits: wizardStartParameters.existingKitsKittedPlies.map((kit) => {
            return kit.kitId;
          }),
        },
        messageDialogApi.close(dispatch, getState)
      );

      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    }
  };
};

jsxActions.onWizardSubmit = function (formData, currentCut, messageDialogBuilder) {
  return function (dispatch, getState) {
    return api.onWizardSubmit(dispatch, getState)(formData, currentCut, messageDialogBuilder);
  };
};

jsxActions.hideIgnoreValidationWarningsCheckbox = function (value) {
  return function (dispatch, getState) {
    api.hideIgnoreValidationWarningsCheckbox(dispatch, getState)(value);
  };
};

jsxActions.toggleIgnoreValidationWarningsCheckbox = function (value) {
  return function (dispatch, getState) {
    api.toggleIgnoreValidationWarningsCheckbox(dispatch, getState)(value);
  };
};

jsxActions.saveNewKitsForSubmitData = function (formData, currentCut, callback) {
  return function (dispatch, getState) {
    return api.saveNewKitsForSubmitData(dispatch, getState)(formData, currentCut, callback);
  };
};

jsxActions.onCancelKitReportClick = function (kitIds) {
  return function (dispatch, getState) {
    cancelKitReportDialogApi.openDialog(dispatch, getState)(kitIds);
  };
};

jsxActions.onPrintRfidTagsClick = function (kitIds) {
  return function (dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    printRfidTagsApi.printRfidTags(dispatch, getState)(kitIds, locationId, true);
  };
};

jsxActions.onSelectedCutToKitChange = function (cutData) {
  return function (dispatch, getState) {
    api.onCutSelected(dispatch, getState)(cutData)
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.generateKitId = function (dispatch, getState) {
  return function () {
    return kitsService.generateKitId().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Generate Kit Id Failed');
        return { success: false };
      }
      return response.data;
    });
  };
};

api.hideIgnoreValidationWarningsCheckbox = function (dispatch, getState) {
  return function () {
    dispatch(actions.hideIgnoreValidationWarningsCheckbox());
  };
};

api.toggleIgnoreValidationWarningsCheckbox = function (dispatch, getState) {
  return function (value) {
    dispatch(actions.toggleIgnoreValidationWarningsCheckbox(value));
  };
};

api.saveNewKitsForSubmitData = function (dispatch, getState) {
  return function (formData, currentCut, callback) {
    let newKitsForSubmit = formData.kits;

    if (formData.ignoreValidationWarnings) {
      //reset validation errors/warnings (to hide the Validation area when Back is clicked)
      dispatch(actions.setValidationWarningsData({ reset: true }));

      dispatch(actions.saveNewKitsForSubmitData(newKitsForSubmit));

      if (callback) {
        callback();
      }
      return { success: true };
    }

    let locationId = locationPageApi.getLocationId(dispatch, getState)();

    let data = newKitsForSubmit.map((kit) => {
      return _convertToKitData(kit, currentCut, locationId);
    });

    kitsService.validateNewKitsData(locationId, data).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('New kits validation failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      let validations = dialogHelper.getResponseValidationDetails(response);
      if (validations) {
        dispatch(actions.setValidationWarningsData(validations));
        return { success: false };
      }

      dispatch(actions.saveNewKitsForSubmitData(newKitsForSubmit));

      if (callback) {
        callback();
      }
    });
  };
};

api.fetchInitialWizardData = function (dispatch, getState) {
  return function (currentCutId) {
    dispatch(actions.fetchInitialDataStarted());
    let promise1 = kitsService.getKitInitialData(currentCutId);
    let promise2 = tagsService.fetchTags();
    let promise3 = additionalFieldsService.fetchAdditionalFields({
      entityType: getEnumValue(enumTypes.OBJECT_TYPE)('KIT'),
    });

    return Promise.all([promise1, promise2, promise3]).then((allResults) => {
      if (
        !Network.isResponseValid(allResults[0]) ||
        !Network.isResponseValid(allResults[1]) ||
        !Network.isResponseValid(allResults[2])
      ) {
        console.error('Get kit initial data failed.');
        messageDialogApi.responseError(dispatch, getState)(null);
        return { success: false };
      }

      let projects = allResults[0].data.projectsData || [];
      let parts = allResults[0].data.partsData || [];
      let workorders = allResults[0].data.workOrderData || [];
      let materials = allResults[0].data.materialsData || [];
      let kitTypes = allResults[0].data.kitTypesData || [];
      let freeTags = allResults[1] || [];
      let additionalFields = allResults[2] ? allResults[2].dataList : [];

      let initialData = {
        projects: projects.dataList.map((obj) => {
          return { value: obj.id, label: obj.businessId, data: obj };
        }),
        parts: parts.dataList.map((obj) => {
          return { value: obj.id, label: obj.businessId, data: obj };
        }),
        workorders: workorders.dataList.map((obj) => {
          return { value: obj.id, label: obj.businessId, data: obj };
        }),
        materials: materials.dataList.map((obj) => {
          return {
            value: obj.id,
            label: MaterialHelper.getMaterialFullLabel(obj.materialName, obj.businessId),
            data: obj,
          };
        }),
        kitTypes: kitTypes.dataList.map((obj) => {
          return { value: obj.id, label: obj.businessId, data: obj };
        }),
        freeTags: freeTags.dataList.map((obj) => {
          return { value: obj.id, label: obj.tagKey, data: obj };
        }),
        additionalFields: additionalFields,
      };

      dispatch(actions.fetchInitialDataReady(initialData));
      return initialData;
    });
  };
};

api.openWizard = function (dispatch, getState) {
  return function (options, allowCutSelection = false) {
    if(allowCutSelection){
      const startParameters = {
        shouldRefreshPageOnCancel: false,
        allowCutSelection,
        ...options,
      };

      dispatch(actions.saveStartParameters(startParameters));

      createKitWizard_WIZARD_Api.start(dispatch, getState)();

    } else {
      // Fetch wizard initial data. (Async.)
      let currentCutId = kittingViewApi.getCurrentCutId(dispatch, getState)();
      api
        .fetchInitialWizardData(
          dispatch,
          getState
        )(currentCutId)
        .then((response) => {
          // Save wizard start parameters.
          let startParameters = {
            shouldRefreshPageOnCancel: false,
            ...options,
          };
          dispatch(actions.saveStartParameters(startParameters));

          // Open wizard.
          createKitWizard_WIZARD_Api.start(dispatch, getState)();
        });
    }
  };
};

api.closeWizard = function (dispatch, getState) {
  return function () {
    createKitWizard_WIZARD_Api.close(dispatch, getState)();
    dispatch(actions.submitKitsFinished());
    dispatch(actions.reset());
  };
};

api.printKitIds = function (dispatch, getState) {
  return function (data) {
    kitsService
      .printKitsLabels(data.kitIds, data.station)
      .catch((errorResponse) => {
        return errorResponse;
      })
      .then((response) => {
        var myURL = window.URL || window.webkitURL;
        var file = new Blob([response], { type: 'application/pdf' });
        var fileURL = myURL.createObjectURL(file);
        window.open(fileURL, '_blank');
      });
  };
};

api.onWizardSubmit = function (dispatch, getState) {
  return function (formData, currentCut, messageDialogBuilder) {
    let newKitsForSubmit = myState(getState).get('newKitsForSubmit');
    if (!newKitsForSubmit) {
      return;
    }

    let printKitLabel =
      !formData || formData.printKitLabel === undefined ? false : formData.printKitLabel;

    let locationId = locationPageApi.getLocationId(dispatch, getState)();

    dispatch(actions.submitKitsStarted());

    let kits = newKitsForSubmit.map((kit) => {
      return _convertToKitData(kit, currentCut, locationId, formData.additionalFields);
    });
    let locationDetails = locationPageApi.getLocationDetails(dispatch, getState)();

    let newData = {
      operationId: _getOperationIdByType(
        getEnumValue(enumTypes.OPERATION_TYPE)('KIT'),
        locationDetails?.operations
      ),
      kits,
    };

    kitsService.createNewKits(locationId, newData).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('New kits creation failed');
        api.closeWizard(dispatch, getState)();
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      let createdKitsIds = [];
      let failedKits = [];
      response.dataList.forEach((item) => {
        if (
          item &&
          item.applicationResponseStatus &&
          item.applicationResponseStatus.statusType === 'OK'
        ) {
          createdKitsIds.push(item.data.id);
        } else {
          failedKits.push(item);
        }
      });

      if (printKitLabel && createdKitsIds.length > 0) {
        api.printKitIds(dispatch, getState)({ kitIds: createdKitsIds, station: locationId });
      }

      let wizardStartParameters = myState(getState).get('startParameters');
      let updatedKits = wizardStartParameters.existingKitsKittedPlies
        ? wizardStartParameters.existingKitsKittedPlies.map((kit) => {
            return kit.kitId;
          })
        : null;

      api.closeWizard(dispatch, getState)();
      kittingViewApi.setCurrentCutToKitDropdownToShowAll(dispatch, getState)();

      const activeTabOnSubmit = wizardStartParameters.activeTabOnSubmit || 'kitting';
      locationPageApi.activateTab(dispatch, getState)(activeTabOnSubmit);
      locationPageApi.reload(dispatch, getState)();

      let dialogData = { createdKits: createdKitsIds, failedKits, updatedKits };

      let messageDialogDescriptor = messageDialogBuilder(
        dialogData,
        messageDialogApi.close(dispatch, getState)
      );

      messageDialogDescriptor.className = 'kitting-operation-confirm-dialog';

      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    });
  };
};

api.onCutSelected = function (dispatch, getState){
  return function (cutData){
    const currentCutId = cutData.id;
    api.fetchInitialWizardData(dispatch, getState)(currentCutId)
      .then((response) => {
        dispatch(actions.selectedCutChanged(cutData))
      });
  }
}

////////////////////////////////////////////////////////////////////////////////////

function _convertToKitData(kit, currentCut, locationId, additionalFields) {
  // let { kit, currentCut, locationId } = params;
  //////////////////////////////
  // Create new kits
  let data = {
    reportCutId: currentCut.id,
    businessId: kit.kitId,
    partType: {
      id: kit.partId ? kit.partId.value : null,
      businessId: kit.partId ? kit.partId.label : null,
    },
    project: {
      id: kit.projectId ? kit.projectId.value : null,
      businessId: kit.projectId ? kit.projectId.label : null,
    },
    assetStatus: 'ACTIVE',
  };

  if (kit.workOrder) {
    data.workOrder = {
      id: kit.workOrder.value === kit.workOrder.label ? null : kit.workOrder.value, //pass null for a new WO
      businessId: kit.workOrder.label,
    };
  }

  if (kit.kitType) {
    data.kitType = {
      id: kit.kitType.value === kit.kitType.label ? null : kit.kitType.value, //pass null for a new kitType
      businessId: kit.kitType.label,
    };
  }

  if (kit.tag) {
    data.tag = {
      id: kit.tag.data.id,
      tagKey: kit.tag.data.tagKey,
    };
  }

  if (kit.tags) {
    data.tags = kit.tags.map(({ value, label }) => ({ id: value, tagKey: label }));
  }

  if (kit.materials) {
    data.kittedMaterials = [];

    kit.materials.forEach((m) => {
      let material = {
        mainMaterialId: m.requiredMaterial.data.mainMaterialId,
        substituteMaterialId: m.requiredMaterial.data.substituteMaterialId,
        quantityExpectedPlies: m.quantityExpectedPlies,
        quantityKittedPlies: m.quantityKittedPlies || 0,
        materialUsedAmount: m.amount,
        ...(PermissionManager.hasScrapViewPermissions() ? { materialScrapAmount: m.scrap } : {}),
      };
      data.kittedMaterials.push(material);
    });

    if (kit.newKitTypeMaterials) {
      kit.newKitTypeMaterials.forEach((m) => {
        let material = {
          mainMaterialId: m.requiredMaterial.data.id,
          substituteMaterialId: null,
          quantityExpectedPlies: m.quantityExpectedPlies,
          quantityKittedPlies: m.quantityKittedPlies || 0,
        };
        data.kittedMaterials.push(material);
      });
    }
    if (additionalFields && additionalFields.length > 0) {
      data.additionalFieldsValues = _getAdditionalFields(additionalFields, kit);
    }
  }
  return data;
}

function _getAdditionalFields(additionalFields, kit) {
  let fields = additionalFields.map((field) => {
    let fieldValue = kit[field.name];
    let value =
      field.type.type === 'DATE_PICKER'
        ? DateTimeHelper.ConvertFromDate(fieldValue)
        : fieldValue
        ? fieldValue
        : null;
    return {
      fieldId: field.id,
      fieldType: field.type.type,
      value: value,
    };
  });
  return fields.filter((field) => field.value);
}

function _getOperationIdByType(operationType, operations) {
  let found = operations?.find?.((op) => {
    return op.operationType?.name === operationType;
  });
  return found?.id || null;
}

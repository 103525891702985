import { api as gridApi} from './archivedSpoolsGridActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    api.init(dispatch, getState)();
  }
};

jsxActions.reload = function () {
  return function (dispatch, getState) {
    gridApi.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

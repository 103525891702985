import { Map } from 'immutable';
import { actionTypes as CreateSensorDialogActions } from '../../../actions/Administration/SettingsPage/Dialogs/createSensorDialogActions';

let defaultState = {
  show: false,
  loading: false,
  itemToEdit: null,
  dialogData: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreateSensorDialogActions.CREATE_SENSOR_DIALOG_SHOW:
      state = state.set('itemToEdit', action.payload);
      return state.set('show',true);

    case CreateSensorDialogActions.CREATE_SENSOR_DIALOG_HIDE:
      return Map(defaultState);

    case CreateSensorDialogActions.CREATE_SENSOR_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case CreateSensorDialogActions.CREATE_SENSOR_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);

    case CreateSensorDialogActions.CREATE_SENSOR_DIALOG_FETCH_DATA_READY:
      return state.set("dialogData", action.payload);

    default:
      return state;
  }

}








import * as boHelper from '../batchOperationDialogActionsHelper';
import * as operationsService from '../../../services/Operations/operationsService';

const config = {
  submitMethod: operationsService.editTasksAttributes,
  dialogReduxStorageName: 'editTaskAttributesDialog'
};

/////////////////////////////////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('EDITTASKATTRIBUTES_DIALOG'),
};

/////////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('EDITTASKATTRIBUTES_DIALOG'),
};

/////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {
  ...boHelper.getApiActions(actions, config),
};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),
};



import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { makeLabel } from "infrastructure/js/components/Label/label";

require('./iconCell.scss');

export default class IconCell extends React.PureComponent {

  render() {
    let {value} = this.props;
    if (!value) {
      return null;
    }

    let iconClass = value.iconClass ? value.iconClass : '';
    return (
      <div
        className={cn('icon-cell pl ',{[iconClass]: value.showIcon})}
        onClick={this.props.value.onClick}
      >
        {value.text ? makeLabel(value.text) : null}
      </div>
    )
  }
}

IconCell.propTypes = {
  value : PropTypes.shape({
    iconClass: PropTypes.string,
    showIcon: PropTypes.bool,
    onClick: PropTypes.func, //(event) => void;
    text: PropTypes.string,
  }),
};





import {api as gridApi} from './alertDevicesViewGridActions';
import Network from 'infrastructure/js/modules/network';
import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import * as alertDevicesService from '../../../services/Rfid/alertDevicesService';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_FETCH_DEVICE_APPS_IN_PROCESS: 'ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_FETCH_DEVICE_APPS_IN_PROCESS',
  ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_FETCH_DEVICE_APPS_READY: 'ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_FETCH_DEVICE_APPS_READY',
  ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_DEVICE_APP_CHANGED: 'ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_DEVICE_APP_CHANGED'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  fetchDeviceAppsInProgress: function () {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_FETCH_DEVICE_APPS_IN_PROCESS};
  },
  fetchDeviceAppsFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_FETCH_DEVICE_APPS_READY, payload};
  },
  deviceAppChanged: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_ALERT_DEVICES_DEVICE_APP_CHANGED, payload};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid) {
  return function (dispatch, getState) {

    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }
    api.load(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.onDeviceAppChanged = function (data) {
  return function (dispatch, getState) {
    api.deviceAppChanged(dispatch, getState)(data);
  }
};


jsxActions.onRemoveDevicesClick = function(messageDialogBuilder , selectedItems) {
  return function(dispatch, getState) {
    api.openRemoveDevicesConfirmationDialog(dispatch, getState)(messageDialogBuilder, selectedItems);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function (dispatch, getState) {
  return function () {

    dispatch(actions.fetchDeviceAppsInProgress());

    return deviceAppsService.fetchDeviceApps().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('FETCH_DEVICE_APPS failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let deviceAppsOptions = response.dataList.map((obj) => {
        return {value: obj.id, label: obj.username, data: obj}
      });

      dispatch(actions.fetchDeviceAppsFinished(deviceAppsOptions));
    });
  }
};
api.deviceAppChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.deviceAppChanged(data));
    if (data) {
      gridApi.reload(dispatch, getState)();
    }
  }
};

api.openRemoveDevicesConfirmationDialog = function(dispatch, getState) {
  return function(messageDialogBuilder , selectedItems) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.removeDevices(dispatch, getState)(selectedItems)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

  }
};

api.removeDevices = function(dispatch, getState) {
  return function(selectedItems) {

    messageDialogApi.close(dispatch, getState)();

    let ids = selectedItems.map((item) => {return item.id});
    let newData = {impinjDiscoIds: ids};

    alertDevicesService.removeAlertDevices(newData).then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('Remove Alert Devices Failed');

        messageDialogApi.responseError(dispatch, getState)(response);
        return {success : false};
      }

      gridApi.reload(dispatch, getState)();
    });

  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

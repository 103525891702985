import React from 'react';
import PropTypes from 'prop-types';

import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import EditInPlace from '../../controls/EditInPlace/editInPlace';
import PL_TimeField from 'infrastructure/js/components/controls/TimeField/timeFieldNew';

export default class TimeFieldEditor extends React.PureComponent {


  render() {
    let {name, id, value, displayValue, onEditEnd, maxHours, editable, alert, statusIcons,handleSubmit} = this.props;
    displayValue = (displayValue === undefined) ? value : displayValue;
    return (

     <EditInPlace
        id={id}
        name={name}
        formName={`form_EditInPlace_${name}`}
        serverValue={value}
        serverDisplayValue={this.props.isDays ? DateTimeHelper.ConvertMinutesToDaysHoursMinutes(displayValue) : DateTimeHelper.ConvertMinutesToHoursMinutes(displayValue)}
        sendData={handleSubmit}
        editable={editable}
        alert={alert}
        statusIcons={statusIcons}
        isCustomBlurBehavior={true}
      >
        <PL_TimeField name={name} isDays={true} maxHours={maxHours} />
      </EditInPlace>
    );
  }
}
TimeFieldEditor.defaultProps = {
  //value: ''
};
TimeFieldEditor.propTypes = {
  name : PropTypes.string.isRequired,
  id : PropTypes.string.isRequired,
  //value : PropTypes.string,
};


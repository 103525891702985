import {List, Map} from 'immutable';
import { actionTypes as actionTypes } from '../../../actions/Wizards/AfpOperationWizard/afpOperationWizardActions';
import defaultDialogReducer from '../../defaultDialogReducer';

let defaultState = {
  loading: false,
  show: false,
  preselectedAssets: List(),
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case actionTypes.AFP_OPERATION_WIZARD_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ? List(action.payload.preselectedAssets) : List())
        .set('show', true);

    default:
      return defaultDialogReducer(state, action, 'AFP_OPERATION_WIZARD', defaultState);
  }
}

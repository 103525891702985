import React from 'react';

import CommonCsvSettingsView from '../Components/CommonCsvSettingsView/commonCsvSettingsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
require('./rollUsageCsvSettingsView.scss');

export default  class RollUsageCsvSettingsView extends React.PureComponent {

  componentDidMount() {
   this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_CSV_ROLL_USAGE);

   this.props.actions.load('ROLL_USAGE');
  }

  render() {
    return (
        <CommonCsvSettingsView
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          entityType={'ROLL_USAGE'}
                          importEnabled={false}
        />
    );
  }
}


import {useEffect} from 'react';

export default function useDeleteKeyPress(handler){
  useEffect(() => {
    const listener = (event) => {
      if(event.key === 'Delete'){
        handler()
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };

    // passed in handler is a new function on every render, that will cause this effect
    // callback/cleanup to run every render. to optimize wrap handler in useCallback before
    // passing it into this hook.
  }, [handler]);
}

import Network from 'infrastructure/js/modules/network';
import * as boHelper              from '../batchOperationDialogActionsHelper';
import * as projectsService from '../../../services/Projects/projectsService';
import * as partTypesService from '../../../services/PartTypes/partTypesService';
import * as kitTypesService from '../../../services/KitTypes/kitTypesService';
import * as workOrdersService from '../../../services/WorkOrders/workOrdersService';
import * as customerOrdersService from '../../../services/CustomerOrders/customerOrdersService';
import * as additionalFieldsService from "../../../services/Administration/additionalFieldsService";
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
const config = {
  submitMethod: workOrdersService.editWoAttributes,
  dialogReduxStorageName: 'editWoAttributesDialog'
};

/////////////////////////////////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('EDITWOATTRIBUTES_DIALOG'),
  EDITWOATTRIBUTES_DIALOG_FETCH_CUSTOMERORDERS_READY: 'EDITWOATTRIBUTES_DIALOG_FETCH_CUSTOMERORDERS_READY',
  EDITWOATTRIBUTES_DIALOG_FETCH_PARTS_READY: 'EDITWOATTRIBUTES_DIALOG_FETCH_PARTS_READY',
  EDITWOATTRIBUTES_DIALOG_FETCH_KIT_TYPES_READY: 'EDITWOATTRIBUTES_DIALOG_FETCH_KIT_TYPES_READY',
  EDITWOATTRIBUTES_DIALOG_FETCH_PROJECTS_READY: 'EDITWOATTRIBUTES_DIALOG_FETCH_PROJECTS_READY',
  EDITWOATTRIBUTES_DIALOG_FETCH_ADDITIONAL_FIELDS_READY: 'EDITWOATTRIBUTES_DIALOG_FETCH_ADDITIONAL_FIELDS_READY',
};

/////////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('EDITWOATTRIBUTES_DIALOG'),
  fetchCustomerOrdersReady : function(payload) {
    return {type: actionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_CUSTOMERORDERS_READY, payload: payload };
  },
  fetchPartsReady : function(payload) {
    return {type: actionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_PARTS_READY, payload: payload };
  },
  fetchKitTypesReady : function(payload) {
    return {type: actionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_KIT_TYPES_READY, payload: payload };
  },
  fetchProjectsReady : function(payload) {
    return {type: actionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_PROJECTS_READY, payload: payload };
  },
  fetchAdditionalFieldsReady: function(payload) {
    return {type: actionTypes.EDITWOATTRIBUTES_DIALOG_FETCH_ADDITIONAL_FIELDS_READY, payload: payload };
  },
};

/////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {
  ...boHelper.getApiActions(actions, config),
  fetchCustomerOrders: function (dispatch, getState) {
    return function () {
      customerOrdersService.fetchCustomerOrders()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Customer Orders Failed");
            return {success: false};
          }
          dispatch(actions.fetchCustomerOrdersReady(response));
        });
    };
  },
  fetchProjects: function (dispatch, getState) {
    return function () {
      projectsService.fetchActiveProjects()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Projects Failed");
            return {success: false};
          }
          dispatch(actions.fetchProjectsReady(response));
        });
    };
  },
  fetchParts: function (dispatch, getState) {
    return function () {
      partTypesService.fetchAllParts()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error("Fetch Parts Failed");
            return {success: false};
          }
          dispatch(actions.fetchPartsReady(response));
        });
    };
  },
  fetchKitTypes: function (dispatch, getState) {
    return function () {
      kitTypesService.fetchActiveKitTypes()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Fetch Kit Types Failed');
            return {success: false};
          }
          dispatch(actions.fetchKitTypesReady(response));
        });
    };
  },
  fetchAdditionalFields: function(dispatch, getState) {
    return function(entityType) {
      additionalFieldsService.fetchAdditionalFields({entityType: getEnumValue(enumTypes.OBJECT_TYPE)('WO')})
        .then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error("Fetch additional Fields Failed");
            return {success: false};
          }
          dispatch(actions.fetchAdditionalFieldsReady(response));
        });
    };
  },

};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),
  fetchCustomerOrders: function () {
    return function (dispatch, getState) {
      api.fetchCustomerOrders(dispatch, getState)();
    };
  },
  fetchProjects: function () {
    return function (dispatch, getState) {
      api.fetchProjects(dispatch, getState)();
    };
  },
  fetchParts: function () {
    return function (dispatch, getState) {
      api.fetchParts(dispatch, getState)();
    };
  },
  fetchKitTypes: function () {
    return function (dispatch, getState) {
      api.fetchKitTypes(dispatch, getState)();
    };
  },
  fetchAdditionalFields: function (entityType) {
    return function (dispatch, getState) {
      api.fetchAdditionalFields(dispatch, getState)(entityType);
    };
  },
};



import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

const getHumanCapacityString = (humanCapacity, shiftHumanCapacity, isPureHTML) => {
  return `${isPureHTML ? '<br/>' : ''}${humanCapacity} out of ${shiftHumanCapacity} workers`;
};

export function formatShiftSegment(segmentData, isPureHTML) {
  const {
    plannedStartDateSegment,
    plannedEndDateSegment,
    humanCapacity = '',
    shiftHumanCapacity = '',
  } = segmentData;

  const startTime = DateTimeHelper.ConvertToDate(plannedStartDateSegment);
  const endTime = DateTimeHelper.ConvertToDate(plannedEndDateSegment);
  const isSameDay = startTime.isSame(endTime, 'day');
  const timeRangeStr = `${startTime.format('HH:mm')}-${endTime.format('HH:mm')}`;

  return `${timeRangeStr}${!isSameDay ? '(+1)' : ''}, ${getHumanCapacityString(
    humanCapacity,
    shiftHumanCapacity,
    isPureHTML
  )}`;
}

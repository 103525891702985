import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as assetTypesViewActions} from '../../../actions/Administration/MatSettingsPage/assetTypesViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as assetTypesViewGridActions} from '../../../actions/Administration/MatSettingsPage/assetTypesViewGridActions';
import { jsxActions as createAssetTypeWizardActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createAssetTypeWizardActions.js';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import AssetTypesView from '../../../components/Administration/MatSettingsPage/AssetTypesView/assetTypesView';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(assetTypesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      assetTypesViewGridActions: bindActionCreators(assetTypesViewGridActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch),
      createAssetTypeWizardActions : bindActionCreators(createAssetTypeWizardActions, dispatch),

    }

  }
}

let AssetTypesViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_ASSET_TYPES) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_ASSET_TYPES) : null;
  	return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateAssetTypeWizard:  state.administration.getIn(['matSettingsPage', 'createAssetTypeWizard']),
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },

  mapDispatchToProps
)(AssetTypesView);


export default AssetTypesViewContainer;




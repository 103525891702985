import React, {Component} from 'react';

import {concatClassNames} from 'infrastructure/js/utils/componentUtils.js';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import {reduxForm} from 'redux-form';
import {round} from '../../../../utils/assetHelper';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import MaterialHelper from '../../../../utils/materialHelper';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import PermissionManager from "infrastructure/js/utils/permissionManager";

require('./cancelCutReportDialog.scss');

class CancelCutReportDialog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.cutting.cancelcutreportdialog.');

  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
        text: this.labels.get('footer.no'),
        action: this.props.actions.onCancel
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.apply'),
          bsStyle: "primary",
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit)
        }
      ]
    };
  }

  getMessageDialogBuilder = () => {
    return () => {
      return {title: this.labels.get('confirmation.header'), className: "oneBackground"};
    };
  };

  onSubmit = (data) => {
    this.props.actions.cancelCutReport(this.props.sData.get("currentCut"), data.addLengthCheckbox === true, this.getMessageDialogBuilder());
  }

  onEntered =() => {
    this.initData();
  }

  // Sets the focus on the dialog and closes outside overlay and make it true by default
  setCheckboxToTrue() {
    let checkboxElement = document.getElementById('addLengthCheckbox');
    checkboxElement.click();
  }

  initData =() => {
    this.setCheckboxToTrue();
  };

  render() {
    let {sData } = this.props;
    let currentCut = sData.get("currentCut");

    let lengthCut = currentCut && currentCut.lengthCut ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, currentCut.lengthCut, 2) : 0;
    if(PermissionManager.isWeightSupported()){
      lengthCut = currentCut && UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, currentCut.weightLeft || 0, 2);
    }

    return (
      <Dialog
        id="cancel-cut-report-dialog"
        className="cancelCutReportDialog"
        titleText={this.labels.get('header.title')}
        show={sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.props.actions.onCancel}
        sData={sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
          <span className='cancel-report-cut-body-text show'> {this.labels.get('cancelreportcut')} </span>
          <Checkbox
            name="addLengthCheckbox"
            id="addLengthCheckbox"
            label={this.labels.get('addlengthtoroll', undefined,
                                    { length: lengthCut,
                                      measurment: UnitHelper.getLabelForUnitType(PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH),
                                      rollId: currentCut.rollBusinessId,
                                      material: currentCut.material ? MaterialHelper.getMaterialAvailableLabel(currentCut.material.materialName, currentCut.material.businessId) : ''
                                    })}
          />
      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'cancelCutReportDialog',
  }
)(CancelCutReportDialog);

import Network from 'infrastructure/js/modules/network';
import { jsxActions as createOrganizationDialogApi } from './createOrganizationDialogActions';
import { actions as createOrganizationDialogActions } from './createOrganizationDialogActions';
import * as orgsService from '../../../../services/Administration/organizationsService.js';
import { api as messageDialogApi } from '../../../MessageDialog/messageDialogActions.js';

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...createOrganizationDialogApi,
};

jsxActions.submit = function (data, organizationId, isDuplicate, reloadParentComponent) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, organizationId, isDuplicate, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.submit = function (dispatch, getState) {
  return function (data, organizationId, isDuplicate, reloadParentComponent) {
    dispatch(createOrganizationDialogActions.submitInProgress());

    orgsService
      .editDemoOrganization(data, organizationId)
      .then((response) => {
        dispatch(createOrganizationDialogActions.submitFinished());
        dispatch(createOrganizationDialogActions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Edit demo organization failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return { success: false };
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }
        return { success: true };
      })
      .catch((error) => {
        dispatch(createOrganizationDialogActions.submitFinished());
        console.error('Edit demo organization failed', error);
        return { success: false };
      });
  };
};

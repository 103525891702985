import React, {Component} from 'react';
import TextField from '../controls/TextField/textField';
import InputSection from '../Dialog/InputSection/inputSection';
import Validation from '../controls/controlsValidations';
import {createLabelHelper} from '../../utils/labelHelper';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {jsxActions as changePasswordActions} from "../../../../mat/js/actions/ChangePassword/changePasswordActions";
import PermissionManager from '../../utils/permissionManager';

class ChangePassword extends Component {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.changepassword.');
    this.loggedInUser = PermissionManager.getLoggedInUser();
  }

  componentDidMount(){
      this.props.actions.init()
  }

  getOldPasswordField = () => {
    if(!this.props.loggedinUser && !this.props.user){
      return true;
    }
    if (this.props.loggedinUser.id === this.props.user.id) {
      return (
        <InputSection label={this.labels.get('oldpassword')+'*'} className="no-margin">
          <TextField id="OldPasswordInput" name="oldPassword" type="password" autoFocus validate={Validation.required}/>
        </InputSection>
      )
    } else
      return null;
  };


  getPasswordHelpIcon = () => {
    return (
      <AlertPopover
        popoverType={'help'}
        popoverComponent={<div>{this.props.passwordTooltip}</div>}
      >
      </AlertPopover>
    );
  };

  getNewPasswordLabel = () => this.loggedInUser ? this.labels.get('newpassword') : 'New Password'
  getConfirmPasswordLabel = () => this.loggedInUser ? this.labels.get('confirmpassword') : 'Confirm Password'

  passwordValidation = Validation.password(this.props.passwordConfig);

  render() {
    return (
      <div>

        {this.getOldPasswordField()}

        <InputSection label={this.getNewPasswordLabel()+'*'}
                      htmlFor="PasswordInput"
                      labelExtraComponent={this.getPasswordHelpIcon()}>
          <TextField autoComplete="new-password" id="PasswordInput" name="password" type="password" maxLength={20}
                     validate={this.passwordValidation}
          />
        </InputSection>

        <InputSection label={this.getConfirmPasswordLabel()+'*'}
                      htmlFor="ConfirmPasswordInput">
          <TextField autoComplete="new-password" id="ConfirmPasswordInput" name="confirmPassword" type="password" maxLength={20}
                     validate={Validation.confirmPassword}
          />
        </InputSection>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(changePasswordActions, dispatch),
    }

  }
}


let ChangePasswordContainer = connect(
  (state) => {
    return {
      passwordTooltip: state.changePassword.get("passwordTooltip"),
      passwordConfig: state.changePassword.get("passwordConfig")
    };
  },
  mapDispatchToProps
)(ChangePassword);


export default ChangePasswordContainer;

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as archivedGroupsPageActions} from '../../actions/ArchivedGroupsPage/archivedGroupsPageActions.js'
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as archivedGroupsGridActions } from '../../actions/ArchivedGroupsPage/archivedGroupsGridActions';
import { jsxActions as activateAssetsDialogActions }    from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';
import { jsxActions as moveAssetsActions }        from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as printPdfLabelsActions }    from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

import ArchivedGroupsPage from '../../components/PredefinedList/ArchivedGroupsPage/archivedGroupsPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(archivedGroupsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      archivedGroupsGridActions: bindActionCreators(archivedGroupsGridActions, dispatch),
      activateAssetsDialogActions:  bindActionCreators(activateAssetsDialogActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      printPdfLabelsActions:      bindActionCreators(printPdfLabelsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let ArchivedGroupsContainer =  connect (
  (state) => {
    let grid = state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_GROUPS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_GROUPS) : null;
    return {
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sActivateAssetsDialogData: state.dialogs.get('activateAssetsDialog'),
      sMoveAssetsData:     state.dialogs.get('moveAssetsDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
    };
  },
  mapDispatchToProps
)(ArchivedGroupsPage);


export default ArchivedGroupsContainer;

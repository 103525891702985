import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {gridsNames} from '../../enums/gridsNames';
import { List } from 'immutable';

import { jsxActions as fetchEntitiesActions }       from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as cuttingGridActions }       from '../../actions/LocationPage/CuttingView/cuttingGridActions';
import { jsxActions as cuttingViewActions }       from '../../actions/LocationPage/CuttingView/cuttingViewActions.js';
import { jsxActions as reportCutDialogActions }   from '../../actions/LocationPage/CuttingView/reportCutDialogActions.js';
import { jsxActions as createSubRollsDialogActions }   from '../../actions/LocationPage/CuttingView/createSubRollsDialogActions.js';
import { jsxActions as cancelCutReportDialogActions }   from '../../actions/LocationPage/CuttingView/cancelCutReportDialogActions.js';
import { jsxActions as headerActions }   from '../../actions/Header/headerActions';
import { jsxActions as locationPageActions }      from '../../actions/LocationPage/locationPageActions';

import CuttingView from '../../components/LocationPage/CuttingView/cuttingView.js';

function mapDispatchToProps(dispatch) {

  return {
    actions: {
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      cuttingGridActions:  bindActionCreators(cuttingGridActions, dispatch),
      cuttingViewActions:    bindActionCreators(cuttingViewActions, dispatch),
      reportCutDialog:  bindActionCreators(reportCutDialogActions, dispatch),
      createSubRollsDialog:  bindActionCreators(createSubRollsDialogActions, dispatch),
      headerActions: bindActionCreators(headerActions, dispatch),
      cancelCutReportDialog:  bindActionCreators(cancelCutReportDialogActions, dispatch),
      locationPageActions:       bindActionCreators(locationPageActions, dispatch)
    }
  }
}

let CuttingViewContainer = connect(

  (state) => {
    let cuttingGrid = state.grid.get(gridsNames.LOCATION_CUTTING);// ? state.grid.get(gridsNames.LOCATION_CUTTING) : null;
    return {
      sCuttingGridData:  cuttingGrid,
      sHasRows: cuttingGrid && cuttingGrid.get('rows') ? cuttingGrid.get('rows').size > 0 : false,
      sSelectedRowsData: cuttingGrid ? cuttingGrid.get('selectedRowsData') : List(),
      sReportCutDialog:  state.cutKitStation.get('reportCutDialog'),
      sCreateSubRollsData:  state.cutKitStation.get('createSubRollsDialog'),
      sCancelCutReportDialog:  state.cutKitStation.get('cancelCutReportDialog'),
      sLocationOperations:       state.locationPage.getIn(['data', 'locationDetails']).operations,
      sLocationType:       state.locationPage.getIn(['data', 'locationDetails']).locationType,
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },

  mapDispatchToProps
)(CuttingView);

export default CuttingViewContainer;





import React from 'react';
import { api as navigatorApi } from '../../AppTopbar/navigatorActions';
import {navigationStates} from '../../../enums/navigationStates';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as gridApi} from './userManagementGridActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    navigatorApi.setLoading(true)(dispatch, getState);

    api.init(dispatch, getState)();

    navigatorApi.setLoading(false)(dispatch, getState);
  }
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.reload()(dispatch, getState);
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.init = function(dispatch, getState) {
  return function() {
    const id = -1;
    const type = navigationStates.USER_MANAGEMENT;
    const name = createLabelHelper('mat.header.navigator.administration.').get('usermanagement');

    navigatorApi.setData(dispatch, getState)({id, type, name});
  }
}

api.reload = function() {
  return function(dispatch, getState) {
    gridApi.reload(dispatch, getState)();
  }
};


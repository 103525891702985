import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Button from 'infrastructure/js/components/controls/Button/button';
import { reduxForm } from 'redux-form';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import Label from 'infrastructure/js/components/Label/label.js';
import MaterialHelper from '../../../../utils/materialHelper';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';
import DateRangePicker from 'infrastructure/js/components/controls/DateRangePicker/dateRangePicker.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import './smartSelectionHeader.scss';
import Overlay from 'infrastructure/js/components/Overlay/overlay.js';
import UomHelper from 'infrastructure/js/utils/uomHelper';
import MultiValueWithTextInput from 'infrastructure/js/components/MultiItemWithTextInput/multiValueWithTextInput';

/**
 * Verifies the change in the required material value.
 *
 * @param {string} value The new value of the required material.
 * @param {number} maxValue The maximum allowed value of the required material.
 * @returns {Object} An object with the modified value and an error flag.
 */
const verifyRequiredMaterialChange = (value, maxValue) => {
  // If the value is empty, return an object with the modified value set to an empty string and the error flag set to false.
  if (!value) {
    return { modifiedValue: '', error: false };
  }

  // Remove the first decimal point from the value (more then one is not allowed).
  const cleanValue = value.replace('.', '');

  // If the value is not a number, return an object with the modified value set to an empty string and the error flag set to true.
  if (isNaN(cleanValue)) {
    return { modifiedValue: '', error: true };
  }

  // Convert the value to a number. this allows comparison and also trims leading zeros.
  const numValue = parseFloat(value);

  // If the value is greater than the maximum value, return an object with the modified value set to an empty string and the error flag set to true.
  if (numValue > maxValue) {
    return { modifiedValue: '', error: true };
  }

  // Check if the string contains a decimal point.
  if (value.includes('.')) {
    // Split the string into two parts: the part before the decimal point and the part after the decimal point.
    const [beforeDecPoint, afterDecPoint] = value.split('.');

    // Check if the part after the decimal point is longer than two digits.
    // Truncate the part after the decimal point to two digits.
    const newAfterDecPoint = afterDecPoint && afterDecPoint.length > 2 ? afterDecPoint.slice(0, 2) : afterDecPoint;

    //check if the number before the the decimal point was deleted and set it to zero.
    const newBeforeDecPoint = beforeDecPoint ? parseInt(beforeDecPoint) : 0;

    // Return an object with the modified value and an error flag of `false`.
    return { modifiedValue: `${newBeforeDecPoint}.${newAfterDecPoint}`, error: false };
  }

  // Otherwise, return an object with the modified value set to the value and the error flag set to false.
  return { modifiedValue: numValue, error: false };
};

class SmartSelectionHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.pickDateRangeEnabled = PermissionManager.getOrgPreferences().smartSelectionPickDatesRangeEnabled;
    this.state = {
      isWoSelected: false,
      isSelectedMaterial: false,
      selectedStartDate: null,
      selectedEndDate: null,
    };
    this.labels = createLabelHelper('mat.smartSelect.header.');
    this.requiredMaterialHolder = {};
  }

  componentDidMount() {
    this.props.actions.init().then((data) => {
      this.props.change('selectLocation', data.initialLocations);
    });
  }

  getComponentToRender = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;

    let materialLabel = MaterialHelper.getMaterialFullLabel(option.data.displayMaterial.materialName, option.data.displayMaterial.businessId);
    if (!isEmpty(option.data.mainMaterials)) {
      let tooltipPrefix = this.labels.get('substituteFor');
      let tooltipContent = option.data.mainMaterials
        .map((main) => {
          return main.businessId;
        })
        .join(', ');

      return (
        <div>
          <Tooltip placement="top" value={tooltipPrefix + tooltipContent} delayShow={500}>
            <span className="pl pl-substitute-material-mark" />
          </Tooltip>
          <span className="label-text">{materialLabel}</span>
        </div>
      );
    }

    return <Label text={materialLabel} tooltip={materialLabel} />;
  };

  multiValueRenderer = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;
    if (option && !option.label) {
      return null;
    }

    return (
      <div className="select-row select-value-row">
        <Label text={option.label} />
      </div>
    );
  };

  /**
   * @function SelectMaterialMultiValue
   * @description Renders a MultiValueWithTextInput for the selectMaterial + requiredMaterial combination.
   * @param {object} props - The props for the component.
   * @returns {JSX.Element} - The rendered component.
   */
  SelectMaterialMultiValue = (props) => {
    // Get the required material length from the props.
    const requiredMaterialLength = props?.data?.data?.requiredMaterialLength ?? 0;

    // Get the material id from the props.
    const { businessId } = props?.data?.data?.displayMaterial;

    // Get the maximum value for the unit type.
    const maxValueForUnitType = UomHelper.getMaxValueForUnitType(PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH);

    // Calculate the required material length in meters.
    const requiredMaterialLengthInMeters =
      maxValueForUnitType > requiredMaterialLength / 100 ? requiredMaterialLength / 100 : parseInt(maxValueForUnitType);

    // Create the input props object. This props will be passed directly to the input inside MultiValueWithTextInput.
    const inputProps = {
      min: 0,
      max: maxValueForUnitType,
    };

    // If the material is not already in the required material holder, add it.
    if (!this.requiredMaterialHolder[businessId]) {
      this.updateMaterialRequiredState(businessId, requiredMaterialLengthInMeters);
    }

    return (
      <MultiValueWithTextInput
        onInputChange={this.onMaterialRequiredChange}
        inputValue={requiredMaterialLengthInMeters}
        inputType="text"
        inputWidth={maxValueForUnitType.length}
        otherInputProps={inputProps}
        inputOnChangeModifier={(value) => verifyRequiredMaterialChange(value, maxValueForUnitType)}
        {...props}
      />
    );
  };

  /**
   * Updates the required material state when the value of the input changes.
   *
   * @param {number} value The new value of the input.
   * @param {object} item The item that was changed.
   * @returns {void}
   */
  onMaterialRequiredChange = (value, item) => {
    // Get the material id from the item.
    const { businessId } = item.data.data.displayMaterial ?? {};

    // Update the required material state.
    this.updateMaterialRequiredState(businessId, value);
  };

  /**
   * Updates the requiredMaterial state.
   *
   * @param {string} name The name of the material. (optional)
   * @param {number} amount The amount of the material. (optional)
   * @returns {void}
   */
  updateMaterialRequiredState = (id, amount) => {
    // Get the change function from the props.
    const { change } = this.props;

    // If the id is provided, update the required material holder.
    if (id) {
      this.requiredMaterialHolder[id] = amount;
    }
    // Convert the requiredMaterialHolder object into an array.
    const saveValue = Object.entries(this.requiredMaterialHolder).map(([key, value]) => ({ businessId: key, amount: value }));

    // Change the required material state.
    change('requiredMaterial', saveValue);
  };

  /**
   * Gets the select material label.
   *
   * @returns {ReactElement} The select material label.
   */
  getSelectMaterialLabel = () => {
    return (
      <div className="select-material-label">
        <span>{this.labels.get('selectMaterial') + '*'}</span>
        <span>{this.labels.get('required') + '*'}</span>
      </div>
    );
  };

  optionRenderer = (props) => {
    let option = props.data;
    return (
      <div className={`select-row select-option-row${option.data && option.data.isShouldMarkedBold ? ' bold' : ''}`}>
        <Label text={option.label} />
      </div>
    );
  };

  onDateChange = (startDate, endDate) => {
    this.clearDataAfterPickDate();
    if ((!startDate || !startDate._isValid) && (!endDate || !endDate._isValid)) {
      this.setState(
        //state
        {
          selectedStartDate: null,
          selectedEndDate: null,
        },
        //callback
        () => {
          if (!this.state.selectedStartDate && !this.state.selectedEndDate) {
            this.props.change('destinations', null);
          }
        }
      );
      return;
    }
    this.setState(
      //state
      {
        selectedStartDate: startDate || null,
        selectedEndDate: endDate || null,
      },
      //callback
      this.fetchWos
    );
  };

  clearDataAfterPickDate = () => {
    this.props.change('selectMaterial', null);
    this.onMaterialChange(null);
    this.props.change('requiredMaterial', null);
    this.props.change('selectWo', []);
    this.onWoChange([]);
  };

  fetchWos = () => {
    this.props.actions.fetchWos(this.state.selectedStartDate, this.state.selectedEndDate);
  };

  onSubmit = (data) => {
    this.props.actions.hideGrid();
    // setTimeout prevents fetchGridData action to be batched with hideGrid action
    // resulting in hide grid action being ignored by redux.
    setTimeout(() => this.props.actions.fetchGridData(data));
  };

  onMaterialChange = (value) => {
    this.setState({ isSelectedMaterial: value?.length > 0 });
    if (!value || !this.state.isWoSelected) {
      return;
    }
    let newValue;
    if (PermissionManager.isWeightSupported()) {
      newValue = value.data ? value.data.requiredMaterialWeight : null;
    } else {
      newValue = value.data ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, value.data.requiredMaterialLength, 2) : null;
    }

    this.props.change('requiredMaterial', newValue);
    this.updateMaterialRequiredState();
  };

  onWoChange = (newValue) => {
    this.props.change('selectMaterial', null);
    this.onMaterialChange(null);
    this.props.change('requiredMaterial', null);
    // [] will get all materials
    let ids = newValue.map((wo) => {
      return wo.value;
    });
    this.setState({ isWoSelected: newValue && ids.length > 0 });
    this.props.actions.fetchWosMaterials(ids);
  };

  render() {
    let { sData } = this.props;
    let quantityLabel = UnitHelper.getLabelForUnitType(PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH);

    let pickDateLabel = this.pickDateRangeEnabled ? this.labels.get('woDateRange') : this.labels.get('woDate');
    let isWoSelectionDisabled = !(this.state.selectedStartDate || this.state.selectedEndDate) || sData.get('loadingWOs');
    return (
      <div className="smart-selection-params-section">
        <InputSection label={pickDateLabel} htmlFor="woPickDate" className="inline">
          {this.pickDateRangeEnabled ? (
            <DateRangePicker
              onDateChange={this.onDateChange}
              includeDates={sData.get('pickDateOptions')}
              selectedStartDate={this.state.selectedStartDate}
              selectedEndDate={this.state.selectedEndDate}
              fromName="woPickDateFrom"
              toName="woPickDateTo"
              renderAsPopover
            />
          ) : (
            <DatePicker
              id="woPickDate"
              name="woPickDate"
              validate={Validation.date}
              onChangeCallback={(date) => this.onDateChange(date, date)}
              includeDates={sData.get('pickDateOptions')}
            />
          )}
        </InputSection>

        <InputSection label={this.labels.get('selectWo')} htmlFor="selectWo" className="inline">
          {sData.get('loadingWOs') ? <Overlay.Loading className={'wo-loading-overlay'} /> : null}
          <PL_MultiSelectField
            name="selectWo"
            id="selectWo"
            options={sData.get('workOrderOptions')}
            isSearchable={true}
            allowNewOption={false}
            onChangeCallback={this.onWoChange}
            isDisabled={isWoSelectionDisabled}
            optionRenderer={this.optionRenderer}
            multiValueRenderer={this.multiValueRenderer}
            className="multi-select-field"
            closeMenuOnSelect={false}
          />
        </InputSection>

        <InputSection label={this.getSelectMaterialLabel()} htmlFor="selectMaterial" className="inline double">
          <PL_MultiSelectField
            name="selectMaterial"
            id="selectMaterial"
            options={sData.get('materialOptions')}
            isSearchable={true}
            allowNewOption={false}
            onChangeCallback={this.onMaterialChange}
            optionRenderer={this.getComponentToRender}
            multiValueOverride={this.SelectMaterialMultiValue}
            className="multi-select-field"
            closeMenuOnSelect={false}
          />
        </InputSection>

        {/* <InputSection label={`${this.labels.get('requiredMaterial')} (${quantityLabel})`} htmlFor="requiredMaterial" className="inline">
          <TextField
            id="requiredMaterial"
            name="requiredMaterial"
            className="short-textfield"
            normalize={Normalize.normalizeFloat(0, maxValueForUnitType)}
            placeholder={this.labels.get('quantity')}
            disabled={!this.state.isSelectedMaterial}
          />
        </InputSection> */}

        <InputSection label={this.labels.get('location')} htmlFor="selectLocation" className="inline">
          <PL_MultiSelectField
            id="selectLocation"
            name="selectLocation"
            options={sData.get('locationOptions')}
            isSearchable={true}
            allowNewOption={false}
            className="multi-select-field"
            closeMenuOnSelect={false}
          />
        </InputSection>

        <Button id="smartSearch" bsStyle="primary" disabled={!this.state.isSelectedMaterial} onClick={this.props.handleSubmit(this.onSubmit)}>
          {this.labels.get('smartSearch')}
        </Button>
      </div>
    );
  }
}

export default reduxForm({
  form: 'SmartSelectionHeader',
})(SmartSelectionHeader);

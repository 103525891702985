
/////////////////////////////////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ABOUT_DIALOG_SHOW: 'ABOUT_DIALOG_SHOW',
  ABOUT_DIALOG_HIDE: 'ABOUT_DIALOG_HIDE',
};

/////////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function() {
    return {type: actionTypes.ABOUT_DIALOG_SHOW};
  },
  hide: function() {
    return {type: actionTypes.ABOUT_DIALOG_HIDE};
  },
};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
};

jsxActions.show = function() {
  return function(dispatch, getState) {
    api.show(dispatch, getState)();
  };
};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function() {
    dispatch(actions.show());
  }
};







import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as openWorkOrdersPageActions} from '../../actions/OpenWorkOrdersPage/openWorkOrdersPageActions.js'
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as createWorkOrderDialogActions } from '../../actions/Dialogs/CreateWorkOrderDialog/createWorkOrderDialogActions';
import {jsxActions as editWoAttributesActions }      from '../../actions/Dialogs/EditWoAttributesDialog/editWoAttributesDialogActions.js';
import {jsxActions as openWorkOrdersGridActions }      from '../../actions/OpenWorkOrdersPage/openWorkOrdersGridActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import OpenWorkOrdersPage from '../../components/PredefinedList/OpenWorkOrdersPage/openWorkOrdersPage';
import { jsxActions as editWorkOrdersStatusDialogActions }    from '../../actions/Dialogs/EditWorkOrdersStatusDialog/editWorkOrdersStatusDialogActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import { jsxActions as importAttributesUpdateWizardActions } from '../../actions/Wizards/ImportAttributesUpdateWizard/importAttributesUpdateWizardActions';
import {gridsNames} from '../../enums/gridsNames';
import {getDefaultWorkOrderType}  from '../../utils/workOrderHelper';
import PermissionManager from '../../../../infrastructure/js/utils/permissionManager';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(openWorkOrdersPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      editWorkOrdersStatusActions:  bindActionCreators(editWorkOrdersStatusDialogActions, dispatch),
      openWorkOrdersGridActions:  bindActionCreators(openWorkOrdersGridActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      editWoAttributesActions:      bindActionCreators(editWoAttributesActions, dispatch),
      createWorkOrderDialogActions:      bindActionCreators(createWorkOrderDialogActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch),
      importAttributesUpdateWizardActions: bindActionCreators(importAttributesUpdateWizardActions, dispatch),
    }
  }
}

let OpenWorkOrderContainer =  connect (
  (state) => {
    let openWorkOrderGrid = state.grid.get(gridsNames.PREDEFINED_LIST_OPEN_WORK_ORDER) ? state.grid.get(gridsNames.PREDEFINED_LIST_OPEN_WORK_ORDER) : null;
    return {
      sOpenWorkOrderGrid:  openWorkOrderGrid,
      sHasRows: openWorkOrderGrid && openWorkOrderGrid.get('rows') ? openWorkOrderGrid.get('rows').size > 0 : false,
      sSelectedRowsData: openWorkOrderGrid ? openWorkOrderGrid.get('selectedRowsData') : List(),
      sEditWorkOrdersStatusData: state.dialogs.get('editWorkOrdersStatusDialog'),
      sEditWoAttributesData: state.dialogs.get('editWoAttributesDialog'),
      sCreateWorkOrderDialogData: state.dialogs.get('createWorkOrderDialog'),
      sDefaultWorkOrderType: getDefaultWorkOrderType(PermissionManager.getOrgPreferences()),
      sLoggedInUser: state.login.get('loggedInUser'),
      sImportAttributesUpdateWizardData: state.wizards.get('importAttributesUpdateWizard')
    };
  },
  mapDispatchToProps
)(OpenWorkOrdersPage);


export default OpenWorkOrderContainer;

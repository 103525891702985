let format = {};

// Formats the Redux store 'value' ('123') to a combobox option ({value: '123', label: 'ABC'}) from the 'options'
format.findOptionByValue = (options) => {
    return (value) => {
      let found =  options.find(option => option.value === value);
      return found === undefined ? null : found;
    }
};


// Formats the Redux-form store 'values' ([12, 13]) to Multi Combo box options ([{value: '123', label: 'ABC'}]) (from the 'options'
format.findOptionsByValues = (options) => {
  return (values) => {

    let res = values ? values.map((value) => {
      let found =  options.find(option => option.value === value);
      return found === undefined ? null : found;
    }) : [];

    return res;
  }
};

export default format;







import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as materialTypesViewActions} from '../../../actions/Administration/MatSettingsPage/materialTypesViewActions.js'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as materialTypesViewGridActions} from '../../../actions/Administration/MatSettingsPage/materialTypesViewGridActions';
import { jsxActions as createMaterialTypeDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createMaterialTypeDialogActions.js';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import MaterialTypesView from '../../../components/Administration/MatSettingsPage/MaterialTypesView/materialTypesView.js';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(materialTypesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      materialTypesViewGridActions: bindActionCreators(materialTypesViewGridActions, dispatch),
      createMaterialTypeDialogActions : bindActionCreators(createMaterialTypeDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }

  }
}

let MaterialTypesViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_MATERIAL_TYPES) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_MATERIAL_TYPES) : null;
  	return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateMaterialTypeDialogData: state.administration.getIn(['matSettingsPage', 'createMaterialTypeDialog']),
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },

  mapDispatchToProps
)(MaterialTypesView);


export default MaterialTypesViewContainer;




import React, {Component} from 'react';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Page from '../Common/Page/page';
import PageNotFoundPageContainer from '../../../containers/PageNotFoundPage/pageNotFoundPageContainer.js';
import DeviceAppsViewContainer from '../../../containers/Administration/RfidSettingsPage/deviceAppsViewContainer';
import ReadersAndAntennasViewContainer from '../../../containers/Administration/RfidSettingsPage/readersAndAntennasViewContainer';
import PrintersViewContainer from '../../../containers/Administration/RfidSettingsPage/printersViewContainer';
import AlertDevicesViewContainer from '../../../containers/Administration/RfidSettingsPage/alertDevicesViewContainer';
import CommunicationParametersViewContainer from '../../../containers/Administration/RfidSettingsPage/communicationParametersViewContainer';
import FiltersParametersViewContainer from '../../../containers/Administration/RfidSettingsPage/filtersParametersViewContainer';
import VisibilityDevicesViewContainer from '../../../containers/Administration/RfidSettingsPage/visibilityDevicesViewContainer';
import ZplSettingsViewContainer from '../../../containers/Administration/RfidSettingsPage/zplSettingsViewContainer';
import {RfidSettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import MqttBrokersSettingsViewContainer from '../../../containers/Administration/RfidSettingsPage/mqttBrokersSettingsViewContainer';
import MqttSensorsSettingsViewContainer from '../../../containers/Administration/RfidSettingsPage/mqttSensorsSettingsViewContainer';
import AutomationScenariosViewContainer  from '../../../containers/Administration/RfidSettingsPage/automationScenariosViewContainer';
import QualifiedEventsViewContainer  from '../../../containers/Administration/RfidSettingsPage/qualifiedEventsViewContainer';
import ActionSequenceViewContainer  from '../../../containers/Administration/RfidSettingsPage/actionSequenceViewContainer';
import WorkflowsFiltersViewContainer from '../../../containers/Administration/RfidSettingsPage/workflowsFiltersViewContainer';
import {getRoutesFor, getSideNavigatorMenuItems} from '../../../utils/routerHelper';
require('./rfidSettingsPage.scss');

export default class RfidSettingsPage extends Component {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.rfidsettings.sidenavigator.');
    this.isAuthorizedUser = PermissionManager.hasRfidTabPermissions();
    this.navigationItems = this.getNavigationItems();
  }

  componentDidMount() {
    this.props.actions.init(true);
  }


  isExpanded(str) {
    return window.location.href.includes(str);
  }


  getNavigationItems() {
    let items = [];
    if(this.isAuthorizedUser) {
      items = [
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_DEVICE_APPS,
          label: this.labels.get('menuitem.deviceapps'),
          link: '/Administration/rfidSettings/deviceApps',
          component: DeviceAppsViewContainer
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_READERS_AND_ANTENNAS,
          label: this.labels.get('menuitem.readersandantennas'),
          link: '/Administration/rfidSettings/readersAndAntennas',
          component: ReadersAndAntennasViewContainer
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_FILTERS,
          label: this.labels.get('menuitem.filters'),
          expanded: this.isExpanded('/Administration/rfidSettings/filtersParameters'),
          items: [
            {
              id: RfidSettingsMenuItemsIds.NAV_ELEMENT_FLOOD_FILTERS,
              label: this.labels.get('menuitem.filters.flood'),
              link: '/Administration/rfidSettings/filtersParameters/flood',
              component: FiltersParametersViewContainer
            },
            {
              id: RfidSettingsMenuItemsIds.NAV_ELEMENT_WORKFLOWS_FILTERS,
              label: this.labels.get('menuitem.filters.workflows'),
              link: '/Administration/rfidSettings/filtersParameters/workflows',
              component: WorkflowsFiltersViewContainer
            },
          ]
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_COMMUNICATIONS_PARAMETERS,
          label: this.labels.get('menuitem.communicationsparameters'),
          link: '/Administration/rfidSettings/communicationParameters',
          component: CommunicationParametersViewContainer
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_ALERT_DEVICES,
          label: this.labels.get('menuitem.alertdevices'),
          link: '/Administration/rfidSettings/alertDevices',
          component: AlertDevicesViewContainer
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_PRINTERS,
          label: this.labels.get('menuitem.printers'),
          link: '/Administration/rfidSettings/printers',
          component: PrintersViewContainer
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_VISIBILITY_DEVICE,
          label: this.labels.get('menuitem.visibilityDevices'),
          link: '/Administration/rfidSettings/visibilityDevices',
          component: VisibilityDevicesViewContainer
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_ZPL_SETTINGS,
          label: this.labels.get('menuitem.zplSettings'),
          link: '/Administration/rfidSettings/zplSettings',
          component: ZplSettingsViewContainer
        },
      ];

    if (PermissionManager.getOrgPreferences().sensorLoggingEnabled) {

      let mqttSettings = {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_MQTT_SETTINGS,
          label: this.labels.get('menuitem.mqttSettings'),
          expanded: this.isExpanded('/Administration/rfidSettings/mqttSettings'),
          items: [
            {
              id: RfidSettingsMenuItemsIds.NAV_ELEMENT_MQTT_SETTINGS_BROKERS,
              label: this.labels.get('menuitem.mqttSettings.children.brokers'),
              link: '/Administration/rfidSettings/mqttSettings/brokers',
              component: MqttBrokersSettingsViewContainer
            },
            {
              id: RfidSettingsMenuItemsIds.NAV_ELEMENT_MQTT_SETTINGS_SENSORS,
              label: this.labels.get('menuitem.mqttSettings.children.sensors'),
              link: '/Administration/rfidSettings/mqttSettings/sensors',
              component: MqttSensorsSettingsViewContainer
            },
          ]
        };
      items.push(mqttSettings);
    }

    // if (__DEV__) {
    let automatedWorkflows = {
      id: RfidSettingsMenuItemsIds.NAV_ELEMENT_AUTOMATED_WORKFLOWS,
      label: this.labels.get('menuitem.automatedWorkflows'),
      expanded: this.isExpanded('/Administration/rfidSettings/automatedWorkflows'),
      items: [
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_AUTOMATED_WORKFLOWS_AUTOMATION_SCENARIOS,
          label: this.labels.get('menuitem.automatedWorkflows.children.scenarios'),
          link: '/Administration/rfidSettings/automatedWorkflows/automationScenarios',
          component: AutomationScenariosViewContainer
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_AUTOMATED_WORKFLOWS_QUALIFIED_EVENTS,
          label: this.labels.get('menuitem.automatedWorkflows.children.events'),
          link: '/Administration/rfidSettings/automatedWorkflows/qualifiedEvents',
          component: QualifiedEventsViewContainer
        },
        {
          id: RfidSettingsMenuItemsIds.NAV_ELEMENT_AUTOMATED_WORKFLOWS_ACTION_SEQUENCE,
          label: this.labels.get('menuitem.automatedWorkflows.children.sequences'),
          link: '/Administration/rfidSettings/automatedWorkflows/actionSequences',
          component: ActionSequenceViewContainer
        },
      ]
    };

      items.push(automatedWorkflows);
    }

    return items;
  }

  getDefaultRouteConfig() {
    return {
      key: `${this.navigationItems?.[0]?.id}_default`,
      path: '/Administration/rfidSettings',
      redirectPath: this.navigationItems?.[0]?.items?.[0]?.link || this.navigationItems?.[0]?.link,
      routeNotFoundComponent: PageNotFoundPageContainer
    }
  }
  render() {
    return (<Page className="rfid-settings-page"
                  selectedMenuItemId={this.props.sData.get('selectedMenuItemId')}
                  sideNavigatorMenuItems={getSideNavigatorMenuItems(this.navigationItems)}
                  actions={this.props.actions}
                  routes={getRoutesFor(this.navigationItems, this.getDefaultRouteConfig())}/>)
  }
};

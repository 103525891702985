import { Map } from 'immutable';
import { actionTypes as CreatePrinterDialogActionsType } from '../../../actions/Administration/RfidSettingsPage/Dialogs/createPrinterDialogActions.js';

let defaultState = {
  show: false,
  loading: false,
  itemToEdit: null,
  dialogData: null
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreatePrinterDialogActionsType.CREATE_PRINTER_DIALOG_SHOW:
      state = state.set('itemToEdit', action.payload);
      return state.set('show',true);

    case CreatePrinterDialogActionsType.CREATE_PRINTER_DIALOG_HIDE:
      return Map(defaultState);

    case CreatePrinterDialogActionsType.CREATE_PRINTER_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case CreatePrinterDialogActionsType.CREATE_PRINTER_DIALOG_SUBMIT_FINISHED:
      return state.set('loading', false);

    case CreatePrinterDialogActionsType.CREATE_PRINTER_DIALOG_FETCH_DATA_READY:
      return state.set('dialogData', action.payload);

    default:
      return state;
  }

}








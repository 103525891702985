import * as kitsService from '../../../../services/Kits/kitsService.js';
import { api as messageDialogApi } from '../../../MessageDialog/messageDialogActions.js'
import Network from "infrastructure/js/modules/network";

export let actionTypes = {
  CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_ADD_WO: 'CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_ADD_WO',
  CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_SELECT_WO: 'CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_SELECT_WO',
  CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_REMOVE_WO: 'CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_REMOVE_WO',
  CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_STARTED: 'CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_STARTED',
  CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_FINISHED: 'CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_FINISHED',
  CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_FAILED: 'CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_FAILED',
};

const actions = {
  addWO() {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_ADD_WO };
  },
  selectWO(workOrder, index) {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_SELECT_WO, payload: { workOrder, index } };
  },
  removeWO(index) {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_REMOVE_WO, payload: index };
  },
  generateKitIdStarted(name) {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_STARTED, payload: name };
  },
  generateKitIdFinished() {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_FINISHED };
  },
  generateKitIdFailed() {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_KIT_TO_WORK_ORDERS_GENERATE_KIT_ID_FAILED };
  }
};

export let jsxActions = {
  addWO() {
    return (dispatch, getState) => {
      api.addWO(dispatch, getState)();
    }
  },
  selectWO(workOrder, index) {
    return (dispatch, getState) => {
      api.selectWO(dispatch, getState)(workOrder, index);
    }
  },
  removeWO(index) {
    return (dispatch, getState) => {
      api.removeWO(dispatch, getState)(index);
    }
  },
  generateKitID(name, callback) {
    return (dispatch, getState) => {
      api.generateKitID(dispatch, getState)(name, callback)
    }
  }
};

export let api = {
  addWO(dispatch, getState) {
    return () => {
      dispatch(actions.addWO());
    }
  },
  selectWO(dispatch, getState) {
    return (workOrder, index) => {
      dispatch(actions.selectWO(workOrder, index));
    }
  },
  removeWO(dispatch, getState) {
    return (index) => {
      dispatch(actions.removeWO(index));
    }
  },
  generateKitID(dispatch, getState) {
    return (name, callback) => { //name: string, callback: (name: string, kitId: string) => void;
      dispatch(actions.generateKitIdStarted(name));
      kitsService.generateKitId().then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error(response);
          messageDialogApi.responseError(dispatch, getState)(response);
          dispatch(actions.generateKitIdFailed());
        }
        else {
          dispatch(actions.generateKitIdFinished())
          if (callback)
            callback(name, response.data?.data || '');
        }
      }).catch((err) => {
        console.error(err);
        messageDialogApi.responseError(dispatch, getState)(err);
        dispatch(actions.generateKitIdFailed());
      });
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import {PL_Checkbox} from '../../controls/Checkbox/checkbox';

//Style
require('./checkboxHeader.scss');

export default class CheckboxHeader extends React.PureComponent {

  onChange = (value) => {
    if (value.target.checked) {
      this.props.onChecked();
    } else {
      this.props.onUnchecked();
    }
  }

  render() {
    return (
      <PL_Checkbox className='checkbox-header'
        id={this.props.gridName + "CheckboxHeader"}
        onChange={this.onChange}
        value={this.props.getValue()}>
      </PL_Checkbox>);
  }
}

CheckboxHeader.propTypes = {
  gridName: PropTypes.string.isRequired,
  onChecked: PropTypes.func,
  onUnchecked: PropTypes.func,
  getValue: PropTypes.func.isRequired,
};





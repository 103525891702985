
import React from 'react';
import PropTypes from 'prop-types';


import EditInPlace from '../../controls/EditInPlace/editInPlace';
import {PL_TextAreaField} from '../../controls/TextAreaField/textAreaField';

export default class TextAreaFieldEditor extends React.PureComponent {


  render() {
    let {name, id, value, displayValue, handleSubmit, validate, editable, maxLength} = this.props;
    displayValue = (displayValue === undefined) ? value : displayValue;
    return (
      <EditInPlace
        id={id}
        name={name}
        formName={`form_EditInPlace_${name}`}
        serverValue={value}
        serverDisplayValue={displayValue}
        sendData={handleSubmit}
        validate={validate}
        editable={editable}
      >
        <PL_TextAreaField id={`${id}-input`} maxLength={maxLength} />
      </EditInPlace>
    );
  }
}
TextAreaFieldEditor.defaultProps = {
  value: '',
};
TextAreaFieldEditor.propTypes = {
  id : PropTypes.string.isRequired,
  name : PropTypes.string.isRequired,
  value : PropTypes.string,
};


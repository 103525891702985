import Network from '../modules/network.js';

const login = function(user){
  return Network.post('users/doLogin', {username:user.email, password:user.password, rememberMe : user.rememberMe, org : user.orgID});
};

const checkLogin = function(){
  return Network.post('users/checkLogin');
};

const noEmailRegistration = function(query){
  return Network.post('users/noEmailRegistration', query);
};

const logout = function(){
  return Network.post('users/logout', {});
};

const createUserRegistration = function(email){
  return Network.post('users/sendInvitation', {email});
};

const forgotPassword = function(values){
  return Network.post('users/resetPasswordRequest', values);
};

const changePassword = function(values){
  delete(values.confirmPassword);
  return Network.put('users/resetPassword', values);
};

const resetPassword = function(values){
  return Network.post('users/resetPasswordRequest', values);
};

const acceptUserAgreement = function(){
  return Network.post('users/acceptUserAgreement');
};
const sendSmsCode = function(){
  return Network.post('mfa/sendSmsCode');
};
const submitSmsCode = function(code){
  return Network.post('mfa/submitSmsCode', code);
};

const changeExpiredPassword = function(values){
  return Network.post('users/updateMyPassword', values);
};
const fetchPasswordConfig = function(values){
  return Network.post('users/getPasswordPattern', values);
};


export default {login, logout , noEmailRegistration ,createUserRegistration, forgotPassword, checkLogin, changePassword, resetPassword, acceptUserAgreement, sendSmsCode, submitSmsCode, changeExpiredPassword, fetchPasswordConfig};

import Label from 'infrastructure/js/components/Label/label';
import Link from '../../../../Common/Layout/Link/link';
import { formatShiftSegment } from '../../../../Common/Helpers/ShiftsHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

function getRows(rowsData) {
    const rows = [];
    for (let i = 0; i < rowsData.length; i += 4) {
        const row = rowsData.slice(i, i + 4);
        rows.push(row);
    }

    return rows;
}

function Row({ rowData }) {
    const cells = rowData.map((cell, i) => {
        return (
            <div key={i} className={'more-info-row-cell'}>
                <p className='title'>{cell.title}:</p>
                {cell.isLink ? (
                    <Link label={cell.value.toString()} id={cell.id} type={cell.type} />
                ) : (
                    <Label text={cell.value.toString()} />
                )}
            </div>
        );
    });

    return <div>{cells}</div>;
}

export default function SchedulerOperationDetail({ data }) {
    const { extendedDetails } = data;
    if (!extendedDetails) {
        return null;
    }

    const rows = getRows(extendedDetails?.rows);

    return (
        <div className='more-info-row'>
            <div>
                {rows.map((rowData, i) => (
                    <Row key={i} rowData={rowData} />
                ))}
            </div>

            { PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled &&
                <div className={'shifts-cell'}>
                    <p className='title'>Shifts:</p>
                    {extendedDetails?.splitAssignments?.map((segmentData, i) => {
                        const segmentStr = formatShiftSegment(segmentData);
                        return <Label key={i} text={segmentStr} />;
                    })}
                </div>
            }
        </div>
    );
}

import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import UnitHelper from 'infrastructure/js/utils/uomHelper';
require('../weightWizard.scss');

class DisplayWeightStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.assetpage.weightWizard.');
  }


  getDialogButtons() {

    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.onHide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.done'),
          bsStyle: 'primary',
          loading: this.props.sData ? this.props.sData.get('loading') : false,
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData ? this.props.sData.get('loading') : false
        }
      ]
    };
  }

  onSubmit = () => {
    this.onHide();
    this.props.actions.assetEditorActions.updateRoll(this.props.rollId, {weight: this.props.weight});
  };

  onHide = () => {
    this.props.actions.onWizardClose();
    let wizardActions = this.props.wizardActions;
    wizardActions.close();
  };

  render() {
    let titleText = this.labels.get('header.title');
    let animation = this.props.isFirstLaunch;
    return (
      <Dialog
        animation={animation}
        id="display-weight"
        className="weight-wizard"
        titleText={titleText}
        show={true}
        onHide={this.onHide}
        handleHashChange={false}
        footerButtons={this.getDialogButtons()}>

        <label className="body-title">{this.labels.get('secondStep.mainHeader')}</label>
        <div className="weight">
          <span className="label">{this.props.weight + ' g'}</span>
        </div>


      </Dialog>
    );
  }
}
export default reduxForm({
    form: 'displayWeightDialog',
  }
)(DisplayWeightStep);



import Network from 'infrastructure/js/modules/network';

export function fetchAdditionalFieldsTypes() {
  return Network.get('additionalFields/types');
}

export function fetchAdditionalFields(entityType) {
  return Network.post('additionalFields', entityType);
}

export function setAdditionalFields(data) {
  return Network.post(`additionalFields/save`, data);
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NotificationsCenter from './notificationsCenter';
import { jsxActions as notificationsActions } from '../../../actions/Notifications/notificationsActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(notificationsActions, dispatch),
    },
  };
}

export default connect((state) => {
  return {
    data: state.appTopbar.get('notificationsData'),
    batchJobsData: state.batchJobs,
    featureFlags : state.system.get('featureFlags')
  };
}, mapDispatchToProps)(NotificationsCenter);

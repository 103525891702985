import { Map, List } from 'immutable';
import {dialogsNames} from '../../../enums/dialogsNames';
import defaultDialogReducer from '../../defaultDialogReducer';
import { actionTypes as actionTypes } from '../../../actions/Dialogs/AddToPickListDialog/addToPickListDialogActions.js';


let defaultState = {
  show: false,
  loading: false,
  dialogData: null,
  pickListAssets: List(),
};

export default function(state = Map(defaultState), action) {

  switch(action.type) {

    case actionTypes.ADD_TO_PICK_LIST_DIALOG_SHOW:
      return state.set('show', true)
        .set('pickListAssets', action.payload ? action.payload.map((item) => item?.id) : null);


    default:
      return defaultDialogReducer(state, action, dialogsNames.ADD_TO_PICK_LIST_DIALOG, defaultState);
  }
}



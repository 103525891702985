import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as importWizardActions } from '../../../actions/Wizards/ImportNestsMultiRolls/importNestsMultiRollsWizardActions.js';
import importNestsMultiRollsWizard from '../../../components/Wizards/ImportNestsMultiRolls/importNestsMultiRollsWizard';
import {gridsNames} from '../../../enums/gridsNames.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(importWizardActions, dispatch)
    }
  }
}


let ImportNestsMultiRollsWizardContainer = connect(

  (state) => {
    return {
      importTypes: state.importNestsMultiRolls.get('importTypes'),
      acceptFileTypes:state.importNestsMultiRolls.get('acceptFileTypes'),
      loading:state.importNestsMultiRolls.get('loading'),
      sErrors: state.importNestsMultiRolls.get('errors'),
      sNestsErrors: state.importNestsMultiRolls.get('nestsErrors'),
      sValidatedFileData: state.importNestsMultiRolls.get('validatedFileData'),
      sFileToken:state.importNestsMultiRolls.get('fileToken'),
      sSelectedAseetType:state.importNestsMultiRolls.get('selectedAssetType'),
      sSelectedFile: state.importNestsMultiRolls.get('selectedFile'),
      currentDate:state.importNestsMultiRolls.get('currentDate'),
      parentComponent:state.importNestsMultiRolls.get('parentComponent'),
      rollIds:state.importNestsMultiRolls.get('rollIds'),
      materialBusinessId:state.importNestsMultiRolls.get('materialBusinessId'),
      nestsItems: state.importNestsMultiRolls.get('nestsItems'),
      selectedNests: state.grid.get(gridsNames.IMPORT_NESTS_MULTI_ROLLS_SELECT_NESTS) ? state.grid.get(gridsNames.IMPORT_NESTS_MULTI_ROLLS_SELECT_NESTS).get('selectedRowsData') : List(),
      plan:state.importNestsMultiRolls.get('plan'),
    };
  },
  mapDispatchToProps
)(importNestsMultiRollsWizard);

export default ImportNestsMultiRollsWizardContainer;



import React from 'react';
import {reduxForm} from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../enums/gridsNames.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import ActivityTypeCell from '../../Common/CustomGridCells/ActivityTypeCell/activityTypeCell';
import LabelWithTooltipCell from '../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import ActivityDetailsCell from '../../Common/CustomGridCells/ActivityDetailsCell/activityDetailsCell';
import StatusCell from '../../Common/CustomGridCells/StatusCell/statusCell';

require('./activityLogDialogGrid.scss');

class ActivityLogDialogGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'entityExpandedType', filterName: 'entityType'},
    {fieldName: 'entityBusinessId', filterName: 'assetBusinessId', getOptions: true},
    {fieldName: 'activityType', filterName: 'activityType', getOptions: true},
    {fieldName: 'batchJobStatus', filterName: 'batchJobStatus', getOptions: true},
    {fieldName: 'm_Time', filterName: 'fromDate'},
    {fieldName: '', filterName: 'rollEntityType'},
    {fieldName: '', filterName: 'spoolEntityType'},
    {fieldName: '', filterName: 'kitEntityType'},
    {fieldName: '', filterName: 'toolEntityType'},
    {fieldName: '', filterName: 'woEntityType'},
    {fieldName: '', filterName: 'taskEntityType'},
    {fieldName: '', filterName: 'partEntityType'},
  ]

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.expandedLabels = createLabelHelper('mat.activitylogdialog.grid.');
    this.entityLabels = createLabelHelper('mat.entity.type.');
    this.allLabels = createLabelHelper('');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let columnsConfig = [
      {
        fieldName: 'entityExpandedType',
        title: this.labels.get('columns.entity.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        propertyTypes: this.getEntityTypeFilterProperties(),
        width: 220,
        columnOptions: {
          valueGetter: (params) => {
            return this.entityTypeValueGetter(params);
          },
          cellComponent: LabelWithTooltipCell,
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change
      },
      {
        fieldName: 'entityBusinessId',
        title: this.labels.get('columns.id.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetBusinessId',
        width: 180,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'activityType',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'activityType',
        width: 220,
        columnOptions: {
          cellComponent: ActivityTypeCell,
          valueGetter: (params) => {
            return { activityType: params.data.activityType, activityTypeDisplayKey: params.data.activityTypeDisplayKey };
          }
        },
      },
      {
        fieldName: 'batchJobStatus',
        title: this.labels.get('columns.operationStatus.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'batchJobStatus',
        width: 220,
        columnOptions: {
          sort: 'asc',
          valueGetter: (params) => {
            return { status: params.data.batchJobStatus.toLowerCase(),
                     description: this.allLabels.get(params.data.batchJobStatusDisplayKey)
            };
          },
          cellComponent: StatusCell,
        },
      },
      {
        fieldName: 'details',
        title: this.labels.get('columns.details.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ActivityDetailsCell,
          valueGetter: (params) => {
            return this.activityDetailsValueGetter(params);
          },
          sortable: false
        }
      },
      {
        fieldName: 'm_Time',
        title: this.labels.get('columns.time.title'),
        filterType: filterTypes.NONE,
        width: 130,
      },
    ];
    return columnsConfig;
  };

  getEntityTypeFilterProperties() {
    return [
      {value: 'rollEntityType',   label: (this.entityLabels.get('roll') || 'Roll')},
      {value: 'spoolEntityType',   label: (this.entityLabels.get('spool') || 'Spool')},
      {value: 'kitEntityType',    label: (this.entityLabels.get('kit') || 'Kit')},
      {value: 'toolEntityType',    label: (this.entityLabels.get('tool') || 'Tool')},
      {value: 'woEntityType',     label: (this.entityLabels.get('workorder') || 'Work Order')},
      {value: 'taskEntityType',     label: (this.entityLabels.get('task') || 'Task')},
      {value: 'partEntityType',     label: (this.entityLabels.get('part') || 'Part')},
    ];
  }

  entityTypeValueGetter(params) {
    if (!params.data.entityExpandedTypeDisplay) {
      // return {text: 'NOT_FOUND', description: ''};
      return 'NOT_FOUND';
    }

    let text = this.expandedLabels.get(params.data.entityExpandedTypeDisplay.labelKey, undefined, params.data.entityExpandedTypeDisplay.params);
    // return {text, description: ''};
    return text;
  };

  activityDetailsValueGetter(params) {
    if (!params.data.details) {
      return { key: 'NOT_FOUND', value: '' };
    }
    let primaryActivity = params.data.details.find(item => item.primary === true);
    if (!primaryActivity) {
      return { key: 'NOT_FOUND', value: '' };
    }
    return { key: primaryActivity.key, value: primaryActivity.value, type: primaryActivity.valueType, isLocalizedKey: primaryActivity.keyIsReadyForDisplay };
  }

  render() {
    return (
      <div className="activity-log-dialog-grid">
        <Grid gridName={gridsNames.BO_ACTIVITY_LOG}
              columnsConfig={this.columnsConfig}
              filterConfig={this.filterConfig}
              actions={this.props.actions}

              gridProps={
                {
                  checkboxSelection: false
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'activityLogDialogGrid'
  }
)(ActivityLogDialogGrid);

import { Map } from 'immutable';
import { actionTypes as deleteDemoOrganizationDialogActions } from '../../../../actions/Administration/OrganizationsPage/Dialogs/deleteDemoOrganizationDialogActions';

let defaultState = {
  show: false,
  loading: false,
  deletionData: null,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case deleteDemoOrganizationDialogActions.DELETE_DEMO_ORGANIZATION_DIALOG_SHOW:
      state = state.set('deletionData', action.payload.deletionData);
      return state.set('show', true);

    case deleteDemoOrganizationDialogActions.DELETE_DEMO_ORGANIZATION_DIALOG_HIDE:
      return Map(defaultState);

    case deleteDemoOrganizationDialogActions.DELETE_DEMO_ORGANIZATION_STATUS_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case deleteDemoOrganizationDialogActions.DELETE_DEMO_ORGANIZATION_STATUS_SUBMIT_FINISHED:
      return state.set('loading', false);

    default:
      return state;
  }
}

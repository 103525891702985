import React from 'react';

import CommonAlertsView from '../Components/CommonAlertsView/commonAlertsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

/*require('./exposureTimeView.scss');*/

export default class ToolUsageTimeView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.toolUsageTime.view.');
  }

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_TOOL_USAGE_TIME);

    this.props.actions.load(getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'));
  }

  render() {
    return (
      <div className="exposure-time-view">
        <CommonAlertsView labels={this.labels}
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          alertType={getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME')}
        />
      </div>
    );
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import * as ActivityTypeHelper from '../../../../utils/activityTypeHelper';
import Label from 'infrastructure/js/components/Label/label.js';

require('./activityTypeCell.scss');

export default function ActivityTypeCell(props) {

    let activityDetails = ActivityTypeHelper.GetActivityTypeDetails(props.value?.activityType, props.value?.activityTypeDisplayKey);

    return (<div className="activity-type">
        <span className={'icon ' + activityDetails.icon}> </span>
        <Label className="activity-details-description" text={activityDetails.description} />
      </div>);
}

ActivityTypeCell.propTypes = {
  value: PropTypes.object.isRequired,
};





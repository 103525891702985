import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';

require('./createPrinterDialog.scss');

class CreatePrinterDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.rfidsettings.dialog.createprinter.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.locationsOptions = this.props.sData.get('dialogData').locations;
  }

  componentDidMount() {
    let initialValues = {};

    if (this.isEditMode) {
      this.selectLocationsOptionRedux(this.createLocationsOptions(this.itemToEdit.zebraPrinterLocations));
      initialValues = {
        name: this.itemToEdit.name,
        host: this.itemToEdit.host,
        port : this.itemToEdit.port,
        default: this.itemToEdit.default,
        locations: this.createLocationsOptions(this.itemToEdit.zebraPrinterLocations)
      };
    } else {
      initialValues.port = 9100;
    }

    this.props.initialize(initialValues);
  }

  selectLocationsOptionRedux = (options) => {
    this.props.change('locations', options);
  };

  createLocationsOptions = (locations) => {
    return locations ?  locations.map((location) => {return {label: location.name, value: location.id, data: location}}) : [];
  };

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.labels.get('footer.edit') : this.labels.get('footer.create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  onSubmit = (data) => {

    let newData = {
      deviceAppProfileId: this.props.deviceApp.label,
      name: data.name,
      host:  data.host,
      port:  data.port,
      isDefault: data.default,
      zebraPrinterLocations : data.locations.map((item) => { return  {businessId : item.data.businessId} })
    };

    if(this.isEditMode) {
      newData.businessId = this.itemToEdit.businessId;
      newData.id =  this.itemToEdit.id;
    }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide =() => {
    this.props.actions.hide();
  };

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    return (
      <Dialog
        id="create-printer-dialog"
        className="create-printer-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('locations')+'*'} htmlFor='locations' className="no-margin full-width">
          <PL_MultiSelectField name='locations'
                               id='locations'
                               options={this.locationsOptions}
                               validate={[Validation.required]}
                               placeholder={this.labels.get('locations.inputclue')}
          />
        </InputSection>


        <InputSection label={this.labels.get('printername')+'*'}
                      htmlFor="name"
                      className="inline left-side">
          <TextField id="name"
                     name="name"
                     className="short-textfield"
                     maxLength={30}
                     validate={[Validation.required]}/>
        </InputSection>

        <InputSection label={this.labels.get('host')+'*'}
                      htmlFor="host"
                      className="inline right-side">
          <TextField id="host"
                     name="host"
                     className="short-textfield"
                     validate={[Validation.required , Validation.ipFormat]}
                     normalize={Normalize.numberAndDot()}/>
        </InputSection>

        <InputSection label={this.labels.get('port')}
                      htmlFor="port"
                      className="inline left-side">
          <TextField id="port"
                     name="port"
                     className="short-textfield"
                     validate={[Validation.required]}
                     normalize={Normalize.number()}
                     maxLength={5}
          />
        </InputSection>

        <InputSection htmlFor="default"
                      className="inline right-side padding-top">
          <Checkbox name="default"
                    id="default"
                    label={this.labels.get('defaultprinter')}/>
        </InputSection>

      </Dialog>
    );
  }
}

CreatePrinterDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'createPrinterDialog',
  }
)(CreatePrinterDialog);




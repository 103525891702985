import React from "react";
import PL_TextField from "infrastructure/js/components/controls/TextField/textField";
import Dialog from "infrastructure/js/components/Dialog/dialog";
import Combobox from "infrastructure/js/components/controls/Combobox/combobox.js";
import Overlay from "infrastructure/js/components/Overlay/overlay";
import Label from "infrastructure/js/components/Label/label.js";
import InputSection from "infrastructure/js/components/Dialog/InputSection/inputSection";
import { reduxForm } from "redux-form";
import Normalize from "infrastructure/js/components/controls/controlsNormalizations.js";
import * as CutLengthHelper from "../../../../utils/cutLengthHelper";
import { generateFormNames, convertOptionsToServerData, toNumberOrNull } from "infrastructure/js/utils/componentUtils.js";
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import * as dialogHelper from "infrastructure/js/components/Dialog/dialogHelper";
import MessageDialog from "infrastructure/js/components/Dialog/MessageDialog/messageDialog.js";
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import MaterialHelper from "../../../../utils/materialHelper";
import { getEnumValue, enumTypes } from "../../../../utils/enumHelper";
import EntitiesMultiSelect from "../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js";
import { EntityPropertyTypes } from "../../../../enums/entityPropertyTypes";
import { FetchEntitiesFilters } from "../../../../enums/fetchEntitiesFilters";
import PermissionManager from "infrastructure/js/utils/permissionManager";
import {List} from 'immutable';
import Validation from 'infrastructure/js/components/controls/controlsValidations';

require("./reportCutDialog.scss");

class ReportCutDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper("mat.locationpage.view.cutting.reportcutdialog.");
    this.dialogLabels = createLabelHelper("mat.dialog.");
  }

  createDialogFooterButtons() {
    //let isSubmitDisabled = (!this.props.valid);
    let isLoading = this.props.sData.get("loading");

    let buttonOK = {
      id: "submit",
      text: this.labels.get("footer.submit"),
      bsStyle: "primary",
      //disabled: isSubmitDisabled,
      loading: isLoading,
      action: this.props.handleSubmit(this.onSubmit),
      disabled: this.props.sData.get("loading") ||
        this.props.sData.get("hasError") ||
        (this.props.sData.get("showIgnoreValidationCheckbox") &&
          !this.props.sData.get("isIgnoreValidationWarnings"))
    };

    let buttonCancel = {
      id: "cancel",
      text: this.dialogLabels.get('cancel'),
      action: this.props.actions.hide
    };

    return { left: [buttonCancel], right: [buttonOK] };
  }

  onSubmit = (formData) => {
    let formItems = this.submitedData = formData.dialogItems;
    let stateItems = this.props.sData.get("preselectedAssets");

    let serverReadyItems = [];
    let rollsIdsData = [];
    formItems.forEach((formItem, index) => {
      if (!formItem) {
        return;
      }
      let item = {
        // Find Roll Id in the state data collection by matching array index from Forms collection.
        rollId: stateItems.get(index).rollId,
        nest: convertOptionsToServerData(formItem.cutNests),
        quantityPlies: toNumberOrNull(formItem.numberOfPlies),
        note: formItem.notes,
        workOrders: convertOptionsToServerData(this.getAffectedWos(formItem))
      };

      if (PermissionManager.isWeightSupported()) {
        item.weightLeft = UnitHelper.userValueToServerValue(unitTypes.WEIGHT, formItem.weight || 0, 2);
      }
      else {
        item.lengthCut = formItem.cutLength ? (UnitHelper.userValueToServerValue(unitTypes.LENGTH, formItem.cutLength, 2)) : 0;
      }
      serverReadyItems.push(item);
      //used to get rollBusinessId by rollId for Confirmation dialog
      rollsIdsData.push({
        rollId: stateItems.get(index).rollId,
        rollBusinessId: stateItems.get(index).rollBusinessId
      });
    });

    let newData = {
      cutsToReport: serverReadyItems,
      rollsIdsData: rollsIdsData,
      ignoreValidationWarnings: this.props.sData.get("showIgnoreValidationCheckbox") && formData.footerValidationCheckbox ?
        formData.footerValidationCheckbox : false
    };

    this.props.actions.submit(newData, this.getMessageDialogBuilder());
  };

  onHide = () => {
    this.props.actions.hide();
  };

  getAffectedWos = (formItem) => {
    return formItem.affectedWos.assetsValue ? formItem.affectedWos.assetsValue : null;
  };

  getMessageDialogBuilder = () => {
    return (allResponses, fnCloseDialog, fnGoKit) => {
      if (allResponses.length === 1) {
        let response = allResponses[0];

        // Success - single item dialog.
        if (this.isResponseValid(response)) {
          return this.buildDialogDescriptorForSingleItemSuccess(response, fnGoKit, fnCloseDialog);
        }
      }
      return this.buildDialogDescriptorForMultipleItems(allResponses, fnCloseDialog);
    };
  };

  isResponseValid = (response) => {
    return (response && response.applicationResponseStatus && (response.applicationResponseStatus.statusType === "OK"));
  };

  buildDialogDescriptorForMultipleItems = (allResponses, fnCloseDialog) => {

    let succeededIds = [];
    let failedIds = [];

    allResponses.forEach((response) => {
      if (this.isResponseValid(response)) {
        succeededIds.push(response.data.rollBusinessId);
      }
      else {
        failedIds.push(response.data.rollBusinessId);
      }
    });

    let totalCount = succeededIds.length + failedIds.length;
    let className = "oneBackground";
    let operationName = this.labels.get("operation.name");
    let title = "";

    if (succeededIds.length === totalCount) { //succeeded only
      title = this.labels.get("multiconfirm.header", undefined, {
        count: totalCount,
        operationName,
        status: "succeeded"
      });
      return { title, type: "success", className };
    }
    else {
      if (failedIds.length === totalCount) { //failed only
        title = this.labels.get("multiconfirm.header", undefined, {
          count: totalCount,
          operationName,
          status: "failed"
        });
        return { title, type: "error", className };
      }
      else {  //succeeded & failed
        className = "report-operation-multiple-confirmation-dialog";
        title = this.labels.get("multiconfirm.header", undefined, {
          count: totalCount,
          operationName,
          status: "reported"
        });
        let buttons = [{
          id: "close",
          text: this.dialogLabels.get("close"),
          action: fnCloseDialog,
          bsStyle: "primary"
        }];

        let children = [];

        children.push(<MessageDialog.StatusRow key="row1" type={"succeeded"} count={succeededIds.length}/>);
        children.push(<MessageDialog.MessageArea key="row2" text={succeededIds.join(", ")}/>);

        children.push(<MessageDialog.StatusRow key="row3" type={"failed"} count={failedIds.length}/>);
        children.push(<MessageDialog.MessageArea key="row4" text={failedIds.join(", ")}/>);

        return { title, children, className, buttons, showTitleIcon: false };
      }
    }

    return {};
  };


  hasKitOperation = (operations) => {
    if (operations) {
      let found = operations.find((item) => {
        return (item.operationType && item.operationType.name === getEnumValue(enumTypes.OPERATION_TYPE)("KIT"));
      });

      return !!found;
    }
    return false;
  };

  buildDialogDescriptorForSingleItemSuccess = (response, fnGoKit, fnCloseDialog) => {

    let cutId = response.data.id;
    let title = this.labels.get("confirmation.title");
    let buttons;

    //When the station has kit operation.
    if (this.hasKitOperation(this.props.sLocationOperations)) {
      buttons = [
        {
          id: "cancel",
          text: this.labels.get("confirmation.cancelreport"),
          action: () => {
            fnCloseDialog();
            return this.props.actions.onCancelCutReportClick(response.data);
          }
        },
        {
          id: "kitThis",
          text: this.labels.get("confirmation.kithis"),
          bsStyle: "primary",
          action: () => {
            return fnGoKit(cutId);
          }
        }
      ];
    }
    else { //if not, add close button.
      buttons = [
        {
          id: "close",
          text: this.labels.get("confirmation.close"),
          bsStyle: "primary",
          action: () => {
            return fnCloseDialog();
          }
        }
      ];
    }
    let children = this.buildConfirmDialogItem(response, 0, fnGoKit);

    let messageDialogDescriptor = { title, children, buttons };
    return messageDialogDescriptor;

  };


  buildConfirmDialogItem = (response, index) => {
    let rollBusinessId = response.data.rollBusinessId;
    let material = response.data.material;
    let materialLabel = material ? MaterialHelper.getMaterialFullLabel(material.materialName, material.businessId) : "";

    let rollValue = `${materialLabel}, ${rollBusinessId}`;

    let workorderValue = "";
    let affectedWos = this.getAffectedWos(this.submitedData[0]);
    if (affectedWos) {
      workorderValue = affectedWos.map((woItem, woItemIndex) => {
        let isLastItem = ((woItemIndex + 1) >= affectedWos.length);
        let woItemSeparator = (isLastItem) ? ("") : (", ");
        return (<span key={woItemIndex} href="javascript:void(0)">{woItem.label + woItemSeparator}</span>);
      });
    }

    let lengthOrWeightItem = PermissionManager.isWeightSupported() ?
      {
        label: this.labels.get("confirmation.weightUsed"),
        value: `${this.submitedData[0].weight} ${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)}`
      } :
      {
        label: this.labels.get("confirmation.lengthcut"),
        value: `${this.submitedData[0].cutLength} ${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)}`
      };

    let rows = [
      { label: this.labels.get("confirmation.roll"), value: rollValue },
      { label: this.labels.get("confirmation.workorder"), value: workorderValue },
      lengthOrWeightItem,
      { label: this.labels.get("confirmation.cutid"), value: response.data.id }
    ];

    return rows.map((item, index) => {
      return <MessageDialog.DataRow id={`reportKitDataRow${index}`} key={`confirmMessageDataRow${index}`}
                                    label={item.label} value={item.value}/>;
    });
  };


  renderContentItems = (sData) => {
    let dataItems = sData.get("preselectedAssets");
    let dialogData = sData.get("dialogData");
    if (dataItems && dataItems.size === 0 || !dialogData) {
      return <Overlay.Loading/>;
    }

    let nestsOptions = dialogData.nestsOptions ? dialogData.nestsOptions : [];

    let contentItems = dataItems.map((item, index) => {
      return <RenderSingleDialogContentItem key={index} index={index} item={item}
                                            nestsOptions={nestsOptions}
                                            {...this.props}
      />;
    });

    return (
      <div className="dialog-content-wrapper">
        {contentItems}
      </div>);
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get("showIgnoreValidationCheckbox") ?
      { label: this.dialogLabels.get("ignorewarnings"), onChange: this.onFooterValidationChange } : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {
    let show = this.props.sData.get("show");
    let dataItems = this.props.sData.get("preselectedAssets");
    let bodyClassName = (dataItems.size > 2) ? "modal-body-overflow" : "";

    return (
      <div>
        <Dialog
          className="report-cuts-dialog"
          bodyClassName={bodyClassName}
          sData={this.props.sData}
          show={show}
          onHide={this.onHide}
          titleText={this.labels.get("header.title")}
          footerValidationCheckbox={this.getFooterValidationCheckBox()}
          footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
          footerButtons={this.createDialogFooterButtons()}
          onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
        >
          {this.renderContentItems(this.props.sData)}

        </Dialog>
      </div>
    );
  }

}

class RenderSingleDialogContentItem extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper("mat.locationpage.view.cutting.reportcutdialog.");

    let { item } = this.props;
    this.state = { weight: UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, item.weight, 2), calcWeight: 0 };
    this.fetchConfig = {
      entityType: EntityPropertyTypes.WO_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE],
      action: this.props.fetchEntitiesActions.fetchEntities
    };

    this.fetchRollsConfig = {
      entityType: EntityPropertyTypes.ROLL_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      action: this.props.fetchEntitiesActions.fetchEntities,
      searchBy: {
        roll: [{
          objectName: "kitTypeId",
          values: [item?.kitTypeId]
        }]
      }
    };


    if (item && item.rollProjectId) {
      this.fetchConfig.searchBy = {
        wo: [{
          objectName: "projectId",
          values: [item.rollProjectId]
        }]
      };
    }
  }

  onWeightChanged = (event, newVal, oldVal) => {
    if (isNaN(+event.target.value)) {
      return;
    }
    let newValue = (+event.target.value);
    if (newValue > this.state.weight) {
      return;
    }
    let calcWeight = Normalize.normalizeFloat(0, parseFloat(this.state.weight))(event.target.value, oldVal);
    this.setState({ calcWeight });
  };

  onRollChanged = (newValue, oldValue) => {
    if(newValue?.value !== oldValue?.value){
      this.props.actions.onRollChanged(newValue?.data)
    }
  }

  renderLengthOrWeight = (formNames, item, index) => {
    let weight = this.state.weight;
    if (PermissionManager.isWeightSupported()) {
      return (<InputSection label={this.labels.get("weightLeft") + "*"} htmlFor={formNames.weight.id}
                            className="inline small two-columns">
        <PL_TextField name={formNames.weight.name} id={formNames.weight.id}
                      normalize={Normalize.normalizeFloat(0, weight)}
                      autoFocus={index === 0} onChange={this.onWeightChanged}
                      placeholder={`${weight} ${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)}`}
        />
        <div
          className="explanation">{this.labels.get("consumeWeight")}: <br/>{`${UnitHelper.round(this.state.weight - this.state.calcWeight, 2)} ${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)}`}
        </div>
      </InputSection>);
    }

    let rollLength = item.rollLengthLeft ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, item.rollLengthLeft, 2) : 0;
    let rollLengthDisplay = `${rollLength} ${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)}`;

    return (<InputSection label={this.labels.get("lengthcut") + "*"} htmlFor={formNames.cutLength.id}
                          className="inline small two-columns">
      <PL_TextField name={formNames.cutLength.name} id={formNames.cutLength.id}
                    validate={CutLengthHelper.validateFloat}
                    normalize={CutLengthHelper.normalizeFloat(0, parseFloat(rollLength))}
                    autoFocus={index === 0}
      />
      <div className="explanation">{this.labels.get("rolllength")}: <br/>{rollLengthDisplay}</div>
    </InputSection>);
  };
  getPreselectedWos = () => {
    return List(this.props.item.affectedWos)
  }

  render() {
    let { index, item, nestsOptions, allowRollSelection, ...others } = this.props;

    // Create object with pre-fabricated "names" and "id"s for all possible controls of the current item.
    let formNames = generateFormNames(item, "dialogItems", "ReportCutDialog-", index);
    let rollTitle = this.labels.get("title.roll", undefined, { rollId: item.rollBusinessId });
    let materialLabel = MaterialHelper.getMaterialFullLabel(item.rollMaterialName, item.rollMaterial);

    return (
      <div className="dialog-item-section">

        {!this.props.sData.get('allowRollSelection') &&
          <div className="title">
            <Label text={materialLabel}/>
            <Label text={rollTitle} tooltip={item.rollBusinessId}/>
          </div>
        }

        {this.props.sData.get('allowRollSelection') &&
          <InputSection label={this.labels.get("selectRoll") + '*'} htmlFor={formNames.rollId.id}>
            <EntitiesMultiSelect
              name={formNames.rollId.name}
              id={formNames.rollId.id}
              isMulti={false}
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)("ROLL")]}
              fetchConfig={this.fetchRollsConfig}
              showEntitiesTypes={false}
              onValueChangeCallback={this.onRollChanged}
              validate={Validation.required}
              {...others}
            />
          </InputSection>
        }

        {this.renderLengthOrWeight(formNames, item, index)}

        <InputSection label={this.labels.get("affectedwos")} htmlFor={formNames.affectedWos.id} className="inline big">
          <EntitiesMultiSelect
            name={formNames.affectedWos.name}
            id={formNames.affectedWos.id}
            entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)("WO")]}
            fetchConfig={this.fetchConfig}
            showEntitiesTypes={false}
            allowNewOption={PermissionManager.hasCreateAssetsPermissions()}
            preSelectedEntities={this.getPreselectedWos()}
            disableOnPreselect={true}
            {...others}
          />

        </InputSection>

        <InputSection label={this.labels.get("numberofplies")} htmlFor={formNames.numberOfPlies.id}
                      className="inline small two-columns">
          <PL_TextField name={formNames.numberOfPlies.name} id={formNames.numberOfPlies.id} placeholder=""
                        normalize={Normalize.number(true, 1, 9999)}/>
        </InputSection>

        <InputSection label={this.labels.get("cutnests")} htmlFor={formNames.cutNests.id} className="inline big">
          <Combobox id={formNames.cutNests.id} name={formNames.cutNests.name} options={nestsOptions}
                    allowNewOption={true}/>
        </InputSection>

        <InputSection label="" htmlFor={formNames.notes.id}>
          <PL_TextField iconClass="pl pl-note-icon-blue" name={formNames.notes.name} id={formNames.notes.id}
                        placeholder={this.labels.get("addnote.inputclue")}/>
        </InputSection>
      </div>
    );
  }
}

export default ReportCutDialog = reduxForm({
    form: "reportCutDialog",
    onChange: (values, dispatch, props, previousValues) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    }
  }
)(ReportCutDialog);

import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { jsxActions } from '../../actions/Dialogs/changePasswordDialogActions.js';

import ChangePasswordDialog from '../../components/Dialogs/ChangePasswordDialog/changePasswordDialog.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(jsxActions, dispatch),
    },
  }
}

let fieldsArr = ['password', 'confirmPassword', 'oldPassword'];
let ChangePasswordDialogContainer = reduxForm({
  form: 'changePassword',
  fields: fieldsArr
})(ChangePasswordDialog);



const selector = formValueSelector('changePassword');
ChangePasswordDialogContainer = connect(
  state => {
    return {
      formValues: selector(state, ...fieldsArr),
      sData : state.changePasswordDialog,
      currentUser: state.login.get("loggedInUser")
    };
  },
  mapDispatchToProps

)(ChangePasswordDialogContainer);

export default ChangePasswordDialogContainer;


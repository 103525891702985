import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import TextField, {PL_TextField} from 'infrastructure/js/components/controls/TextField/textField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';


//var React = require('react');
require('./timeField.scss');




export default class PL_TimeFieldNew extends React.PureComponent {

  constructor(props) {
    super(props);

    self.isLabelClick = false;


    this.value = {
      hours: '',
      minutes: '',
      days: '',

      hoursBackup: '',
      minutesBackup: '',
      daysBackup: '',


      init: function(isDays, valueMinutes) {
        let timeComponents = isDays ? this.minutesToDaysComponents(valueMinutes): this.minutesToComponents(valueMinutes);
        this.hours    = timeComponents.hours;
        this.minutes  = timeComponents.minutes;
        this.days  = timeComponents.days;

        this.saveBackup();

        return timeComponents;
      },

      saveBackup: function() {
        this.daysBackup = this.days;
        this.hoursBackup = this.hours;
        this.minutesBackup = this.minutes;
      },

      getTotalMinutes: function() {
        return ((+this.days) * 1440)  + ((+this.hours) * 60) + (+this.minutes);
      },

      minutesToComponents: function(valueMinutes) {
        let hours     = Math.floor(valueMinutes / 60);
        let minutes   = (valueMinutes % 60);

        return {hours, minutes};
      },

      minutesToDaysComponents: function(valueMinutes) {
        let days = Math.floor(valueMinutes / 1440);
        let hours = Math.floor(valueMinutes / 60) % 24;
        let minutes = Math.floor(valueMinutes) % 60;
        return {days, hours, minutes};
      }
    };
  }

  findDaysDOMInput = () => {
    let component = ReactDOM.findDOMNode(this.daysComponent);
    if (component) {
      let foundChildInputs = component.getElementsByTagName('INPUT');
      if (foundChildInputs.length > 0) {
        return foundChildInputs[0];
      }
    }

    return null;
  };

  findHoursDOMInput = () => {
    let component = ReactDOM.findDOMNode(this.hoursComponent);
    if (component) {
      let foundChildInputs = component.getElementsByTagName('INPUT');
      if (foundChildInputs.length > 0) {
        return foundChildInputs[0];
      }
    }

    return null;
  };

  findMinutesDOMInput = () => {
    let component = ReactDOM.findDOMNode(this.minutesComponent);
    if (component) {
      let foundChildInputs = component.getElementsByTagName('INPUT');
      if (foundChildInputs.length > 0) {
        return foundChildInputs[0];
      }
    }

    return null;
  };


  onDaysClick = () => {
    let domDaysInput = this.findDaysDOMInput();
    if (domDaysInput) {
      domDaysInput.setSelectionRange(0, domDaysInput.value.length);
    }
  };

  onHoursClick = () => {
    let domHoursInput = this.findHoursDOMInput();
    if (domHoursInput) {
      domHoursInput.setSelectionRange(0, domHoursInput.value.length);
    }
  };

  onMinutesClick = () => {
    let domMinutesInput = this.findMinutesDOMInput();
    if (domMinutesInput) {
      domMinutesInput.setSelectionRange(0, domMinutesInput.value.length);
    }
  };

  onChangeDays = (val) => {
    // Normalize.
    this.value.days = Normalize.number(true, 0, 999)(val, this.value.daysBackup);
    // Auto-jump to hours control upon filling up the hours.
    let daysValueLength = (this.value.days + '').length;

    if (daysValueLength >= 3) {
      let findHoursDOMInput = this.findHoursDOMInput();
      findHoursDOMInput.focus();
      findHoursDOMInput.setSelectionRange(0, findHoursDOMInput.value.length);
    }
    // Trigger our control changed event.
    this.onChange();
  };


  onChangeHours = (val) => {

    // Normalize.
    let maxHours = this.props.maxHours || 23;
    let normalizedHours = Normalize.number(true, 0, maxHours)(val, this.value.hoursBackup);

    this.value.hours = normalizedHours;


    // Auto-jump to minutes control upon filling up the hours.
    let maxHoursLength    = (maxHours + '').length;
    let hoursValueLength  = (this.value.hours + '').length;
    if ((hoursValueLength >= maxHoursLength) || ((this.value.hours * 10) > maxHours)) {
      let domMinutesInput = this.findMinutesDOMInput();
      domMinutesInput.focus();
      domMinutesInput.setSelectionRange(0, domMinutesInput.value.length);
    }


    // Trigger our control changed event.
    this.onChange();
  };

  onChangeMinutes = (val) => {

    // Normalize.
    let normalizedMinutes = Normalize.number(true, 0, 59)(val, this.value.minutesBackup);

    // Update internal value.
    this.value.minutes = normalizedMinutes;


    // Trigger our control changed event.
    this.onChange();
  };


  onChange = () => {

    this.value.saveBackup(); // Store values for later use by Normalize.

    let currentValue = this.value.getTotalMinutes();

    this.props.input.onChange(currentValue);
  };



  handleInnerInputsBlur = (e) => {

    // Decide if we really lost focus by checking if any of our internal inputs still has focus.

    let domDaysElement   = this.findDaysDOMInput();
    let domHoursElement   = this.findHoursDOMInput();
    let domMinutesElement = this.findMinutesDOMInput();

    // Need to give some time to DOM to decide which element is focused now.
    let self = this;
    setTimeout(function() {
      let docFocusedElement = document.activeElement;

      // Compare references.
      if ((domDaysElement === docFocusedElement) ||(domHoursElement === docFocusedElement) || (domMinutesElement === docFocusedElement)) {
        // We are still focused on some of our inputs.
        self.isLabelClick = false;
        return;
      }
      else {

        if (self.isLabelClick) {
          // There's still chance that label was clicked slowly and inner input havent received focus yet.
          // (The input will get focused when user releases mouse button).
          self.isLabelClick = false;
          return;
        }

        // We really lost focus!
        self.props.input.onBlur();  // Signal blur to redux-form.
        self.props.onCustomBlur(e); // Signal Blur to Editor.
      }

    }, 20);

  };


  handleLabelMouseDown = () => {
    this.isLabelClick = true;
  };


  renderDaysComponent = (days) => {
    return this.props.isDays ?
      (
        <React.Fragment>
          <PL_TextField
            id='days'
            passive={true}
            value={days || ''}
            className="inner days"
            autoFocus="autoFocus"
            placeholder={'ddd'}
            onChangeCallback={this.onChangeDays}
            onClick={this.onDaysClick}
            onBlur={this.handleInnerInputsBlur}
            ref={(component) => { this.daysComponent = component; }}
          />
          <span className="time-separator">:</span>
        </React.Fragment>) : null;
  };


  renderHoursComponent = (hours) => {

    let maxHours = this.props.maxHours || 23;
    let placeholder = 'h'.repeat((maxHours + '').length);

    return (
      <PL_TextField
        id="hours"
        passive={true}
        value={hours || ''}
        className="inner hours"
        placeholder={placeholder}
        onChangeCallback={this.onChangeHours}
        onClick={this.onHoursClick}
        onBlur={this.handleInnerInputsBlur}
        ref={(component) => { this.hoursComponent = component; }}
      />
    );
  };

  renderMinutesComponent = (minutes) => {
    return (
        <PL_TextField
          id="minutes"
          passive={true}
          value={minutes || ''}
          className="inner minutes"
          placeholder="mm"
          onChangeCallback={this.onChangeMinutes}
          onClick={this.onMinutesClick}
          onBlur={this.handleInnerInputsBlur}
          ref={(component) => { this.minutesComponent = component; }}
        />
    );
  };


  render() {
    // Re-init value from props each time we are rendered.
    let timeComponents = this.value.init(this.props.isDays, this.props.input.value);
    return (
      <label className="time-value-wrapper" id={this.props.id} onMouseDown={this.handleLabelMouseDown}>
        {this.renderDaysComponent(timeComponents.days)}
        {this.renderHoursComponent(timeComponents.hours)}
        <span className="time-separator">:</span>
        {this.renderMinutesComponent(timeComponents.minutes)}
      </label>
    );
  }

}

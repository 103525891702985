import React, { Component } from 'react';
import classNames from 'classnames';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';

require('./commonKPI.scss');

export default class CommonKPI extends Component {

  render() {
    let {title, tooltip, leftData, leftDataClassName, icon, iconClassName, rightData, timeFrame} = this.props.data;
    return (
      <Tooltip value={tooltip}>
        <div className={classNames('common-kpi', this.props.className, this.props.data ? this.props.data.className : '')}>
          <div className="title">{title}</div>

          <div className="content">
            <div className="item left-side">
              <span className={leftDataClassName}>{leftData}</span>
              <span className={iconClassName}><i className={icon} ></i></span>
            </div>
            <div className="item right-side">
              <span>{rightData}</span>
              <div  className="time-frame">{timeFrame}</div>
            </div>
          </div>
        </div>
      </Tooltip>
    )
  }
};



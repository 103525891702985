import {createContext, useState, useContext, useMemo} from 'react';

const TreeItemDragContext = createContext();

export function TreeItemDragProvider({children}){
  const [dragged, setDragged] = useState(null);

  const contextValue = useMemo(() => {return {dragged, setDragged}}, [dragged]);

  return (
    <TreeItemDragContext.Provider value={contextValue}>
      {children}
    </TreeItemDragContext.Provider>
  );
}

export function useTreeItemDragContext() {
  const context = useContext(TreeItemDragContext);
  if (context === undefined) {
    throw new Error('useTreeItemDragContext must be used within an TreeItemDragProvider')
  }
  return context;
}

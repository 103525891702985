import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Link, routerShape} from "react-router-dom";
import {LoginInput, LoginLayout} from '../LoginLayout/loginLayout';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
require('./authenticationPage.scss');

export default class authenticationPage extends Component{
  constructor(props){
    super(props);
    this.state = { errorMessage:''}
  }
  // componentWillMount() {
  componentDidMount() {
    this.props.actions.sendSms();
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  sendSms= ()=>{
    this.props.change('code', null);
    this.props.actions.sendSms()
  }

  getLoadingOverlay() {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }

  _submit = (formData)=> {
    this.props.actions.submit(formData);
  };

  getFooter(){
    return <div className="footer footer-section">
      <Link to='/Login' className="pointer" >Go back to login page</Link>
      <button className="send-code-btn" onClick={() => this.sendSms()}>Send a new code</button>
      <span>Having a problem? contact support</span>
    </div>;
  }

  getHasError = () => {
    if (this.props.sData.get('hasError')) {
      return ' has-error';
    }
    return '';
  };

  render() {
    return (
      <LoginLayout  formClass={'authentication-page' + this.getHasError()} actionButtonText="SUBMIT" actionButtonOnclick={this.props.handleSubmit(this._submit)} footerComponent={this.getFooter()} loading={this.props.sData.get('loading')}>
        {this.getLoadingOverlay()}
        <div>A verification code has been sent to your phone via text message</div>
        <LoginInput label="VERIFICATION CODE" name="code" id="code" type="password" validate={Validation.required}/>
        <div className="login-error">{this.props.sData.get('errorMessage')}</div>
      </LoginLayout>
    );
  }
}

authenticationPage.propTypes = {
  initialize: PropTypes.func.isRequired,
  loginPath : PropTypes.string,
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,

};

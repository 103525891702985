import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { jsxActions as ipWhitelistViewActions }  from '../../../../actions/Administration/SettingsPage/AccessControl/ipWhitelistViewActions';
import ipWhitelistView from "../../../../components/Administration/SettingsPage/AccessControl/IPWhitelistPage/ipWhitelistView";
import { jsxActions as settingsPageActions } from "../../../../actions/Administration/SettingsPage/settingsPageActions";

export default connect(
  function mapStateToProps(state) {
    return {
      sData: state.administration.getIn(['settingsPage', 'ipWhitelistView'])
    }
  },
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(ipWhitelistViewActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch),
    }
  }
)(ipWhitelistView);

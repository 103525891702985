import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AboutDialog from '../../components/Dialogs/AboutDialog/aboutDialog';
import {jsxActions as aboutDialogActions}  from '../../actions/Dialogs/AboutDialog/aboutDialogActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(aboutDialogActions, dispatch),
  }
}

export default connect(
  (state)=>{
    return {
      show : state.dialogs.getIn(['aboutDialog','show']),
      sData: state.dialogs.get('aboutDialog'),
    }
  },
  mapDispatchToProps
)(AboutDialog);


import { Link, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import HomeOutlineIconButton from '../../../assets/svg/home-outline.svg';
import FavStarIconButton from '../../../assets/svg/fav-star-outline.svg';
import Label from 'infrastructure/js/components/Label/label';
import { navigationStates } from '../../enums/navigationStates';
import { useLocation } from 'react-router-dom';

import './sideNavAccordionDetail.scss';

export default function SideNavAccordionDetail({ items, actions, isFavoritesFull }) {
  const { closeMenu, saveFavorite, deleteFavorite, setHomePage } = actions;

  const { pathname } = useLocation();

  const toggleFavorite = (itemData) => () => {
    if (itemData.isFavorite) {
      deleteFavorite(itemData);
    } else {
      saveFavorite(itemData);
    }
  };

  const handleHomeClick = (itemData) => () => {
    if (itemData.isHomePage) return;
    setHomePage(itemData);
  };

  const showItemActions = saveFavorite && deleteFavorite;

  const accordionDetailItems = items.map((item) => {
    let uid = _generateItemUid(item);
    const active = matchPath(pathname, item.link)

    return (
      <div key={item.label} className={cn('accordion-detail-item', { active })}>
        <Link to={item.link} onClick={closeMenu} data-uid={uid}>
          {item.icon && <item.icon className="accordion-detail-item-icon" />}
          <Label className="accordion-detail-label" text={item.label} tooltip={item.label} />
        </Link>
        {showItemActions && (
          <div className="accordion-detail-item-actions">
            <HomeOutlineIconButton className={cn('icon-button', { active: item.isHomePage })} onClick={handleHomeClick(item)} />
            {(!isFavoritesFull || item.isFavorite) && (
              <FavStarIconButton className={cn('icon-button', { active: item.isFavorite })} onClick={toggleFavorite(item)} />
            )}
          </div>
        )}
      </div>
    );
  });

  return <div className="side-nav-accordion-detail">{accordionDetailItems}</div>;
}

const _generateItemUid = (item) => {
  // Handle Settings menus. The entityType is absent, but we can get useful identifier from id.
  if (!item.entityType) {
    if (typeof item.id === 'string' || item.id instanceof String) {
      return item.id;
    }
    return '';
  }

  // Normal menu items have useful identifier in entityType.
  let uid = item.entityType;

  // Locations and Stations are distinguished by their entity entityId.
  if (item.entityType === navigationStates.STATION || item.entityType === navigationStates.LOCATION) {
    uid = 'LOCATION_ID__' + item.entityId;
  }

  // Prefix favorites with FAV__ so we don't have duplicates.
  if (item.isFavoriteMenu) {
    uid = 'FAV__' + uid;
  }

  return uid;
};

SideNavAccordionDetail.propTypes = {
  items: PropTypes.array,
  actions: PropTypes.object,
  isFavoritesFull: PropTypes.bool,
};

SideNavAccordionDetail.defaultProps = {
  items: [],
  actions: undefined,
  isFavoritesFull: undefined,
};

import Network from 'infrastructure/js/modules/network';
import * as pickListsService from '../../services/PickLists/pickListsService';
import * as locationsService from "../../services/Locations/locationsService";
import {api as pickListPageApi} from "../PickListPage/pickListPageActions";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  PICKLIST_PAGE_EDITOR_FETCH_DESTINATIONS_READY:        'PICKLIST_PAGE_EDITOR_FETCH_DESTINATIONS_READY',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {

  fetchDestinationsReady: function(payload) {
    return {type: actionTypes.PICKLIST_PAGE_EDITOR_FETCH_DESTINATIONS_READY, payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


jsxActions.fetchAllDataForEditor = function () {
  return function (dispatch, getState) {
    api.fetchAllDataForEditor(dispatch, getState)();
  };
};

jsxActions.updatePickList = function (pickListId, data) {
  return function (dispatch, getState) {

    pickListsService.updatePickList(pickListId, data).then((response) => {
      // Reload all editor support data.
      api.fetchAllDataForEditor(dispatch, getState)();

      // Command Asset page to reload everything.
      pickListPageApi.reload(dispatch, getState)(pickListId, true);
    });
  };
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchDestinations = function(dispatch, getState) {
  return function() {
    locationsService.fetchActiveLocations()
      .then((response) => {
        if(!Network.isResponseValid(response)) {
          console.error('Fetch locations failed');
          return {success: false};
        }
        dispatch(actions.fetchDestinationsReady(response));
      });
  };
};

api.fetchAllDataForEditor = function (dispatch, getState) {
  return function () {
    api.fetchDestinations(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS




import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import AppTopbar from '../../components/AppTopbar/appTopbar.js';

import { jsxActions as navigatorActions }        from '../../actions/AppTopbar/navigatorActions.js';
import { jsxActions as topSearchActions }         from '../../actions/AppTopbar/topSearchActions.js';
import { jsxActions as userMenuActions }         from '../../actions/AppTopbar/userMenuActions.js';
import { jsxActions as helpMenuActions }         from '../../actions/AppTopbar/helpMenuActions.js';
import { jsxActions as systemActions }         from '../../actions/System/systemActions';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      topNavigator:      bindActionCreators(navigatorActions, dispatch),
      topSearch:         bindActionCreators(topSearchActions, dispatch),
      userMenu:          bindActionCreators(userMenuActions, dispatch),
      helpMenu:          bindActionCreators(helpMenuActions, dispatch),
      system:            bindActionCreators(systemActions, dispatch)
    }
  }
}


export default connect(
  (state) => {
    return {
      sData:            state.appTopbar.get('data'),
      topNavigatorData: state.appTopbar.get('topNavigatorData'),
      userMenuData:     state.appTopbar.get('userMenuData'),
      sServerVersion : state.system.get('serverVersion'),
      sLoggedInUser : state.login.get('loggedInUser'), //pass logged in user for default location data.
      featureFlags : state.system.get('featureFlags')
    }
  },
  mapDispatchToProps
)(AppTopbar);









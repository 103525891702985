import { Map, List } from 'immutable';
import defaultDialogReducer from '../defaultDialogReducer';
import {dialogsNames} from '../../enums/dialogsNames';
import { actionTypes as actionTypes }  from '../../actions/LocationPage/CuttingView/reportCutDialogActions';


let defaultState = {
  show: false,
  loading: false,
  preselectedAssets: List(),
  dialogData: null,
  allowRollSelection: false
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.REPORT_CUT_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ?
                        action.payload.preselectedAssets.map((item) => _convertRollDataToDialogItem(item)) : List())
                  .set('allowRollSelection', action.payload.allowRollSelection)
                  .set('show', true);

    case actionTypes.REPORT_CUT_DIALOG_ROLL_CHANGED:
      return state.set('preselectedAssets', List([_convertRollDataToDialogItem(action?.payload || {})]))
                  .set('show', true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.REPORT_CUT_DIALOG, defaultState);
  }
}

////////////////////////////////////////////////////
function _convertRollDataToDialogItem(item) {
  return {
    // ROLL DATA FROM GRID.
    rollId:         item.id,
    rollBusinessId: item.businessId,
    rollLengthLeft: item.lengthLeft,
    weight: item.weight ? item.weight : 0,
    rollMaterial:   item.material?.businessId,
    rollMaterialName:   item.material?.materialName,
    rollProjectId:    item.project ? item.project.id : null,
    affectedWos: item?.affectedWos || [],
    kitTypeId: item?.kitTypeId,

    // FORM INPUT PLACEHOLDERS.
    cutLength:      null,
    numberOfPlies:  null,
    cutNests:       [],
    notes:          null
  };
}







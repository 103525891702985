import * as rollsService from '../../services/Rolls/rollsService';
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import * as AlertHelper from "../../utils/alertHelper";
const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ARCHIVE_ROLLS, _fetchDataAction, _convertToRowData);
import {getEnumLabel, enumTypes} from '../../utils/enumHelper.js';

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return rollsService.fetchArchivedRolls(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    obj.m_ExpirationDaysLeft = obj.expirationDate ? DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds) : null;
    obj.lengthLeft = obj.lengthLeft ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, obj.lengthLeft, 2) : 0;
    obj.m_lengthLeft = `${obj.lengthLeft} ${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)}` ;
    obj.weight = obj.weight ? UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, obj.weight) : 0;
    obj.m_weight = `${obj.weight} ${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)}`;
    obj.m_archivingDate = DateTimeHelper.FormatDateObjectToDayMonth(obj.lastStatusChangeDate);
    obj.assetStatusLabel = getEnumLabel(enumTypes.ASSET_STATUS)(obj.assetStatus);
    obj.alerts = obj.alerts.map((alert)=> {
      let expirationDaysLeft = (obj.expirationDate && obj.expirationDate.value ? DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds) : '');
      let alertDetails = {
        expirationDate: obj.expirationDate,
        expirationDaysLeft: expirationDaysLeft,
        exposureTimeLeftBond: obj.exposureTimeLeftBond,
        exposureTimeLeftCure: obj.exposureTimeLeftCure,
        dueDateMinutesLeft: obj.dueDateMinutesLeft
      };
      alert.m_value = AlertHelper.GetAlertTypeMessageTitle(alert, alertDetails);
      return alert;
    });
    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import {Field} from 'redux-form';
import PropTypes from "prop-types";
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import ValidationComponent from 'infrastructure/js/components/controls/ValidationComponent/validationComponent.js'

//var React = require('react');
require('./timeField.scss');

export default class PL_TimeFieldForm extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_TimeField}/>
    );
  }
};


 export class PL_TimeField extends React.PureComponent{

   constructor(props) {
     super(props);

     this.labels = createLabelHelper("mat.controls.timefield.");

//TODO: make sure repeating the label makes sense in chinese, and make sure that if the input is a number in chinese it is translated to normal digits

     let maxHours = 23;
     this.placeholder = this.labels.get('placeholders.hours').repeat(2);

     if (this.props.hoursCount) {
       let maxHoursLength = this.props.maxHoursLength || 2;
       maxHours = this.props.isDays ? 23 : Math.pow(10, maxHoursLength) - 1;
       this.placeholder = this.labels.get('placeholders.hours').repeat(maxHoursLength);

     this.normalizeDays = Normalize.number(true, 0, 999);
     this.normalizeHours = Normalize.number(true, 0, maxHours);
     this.normalizeMinutes = Normalize.number(true, 0, 59);
     this.normalizeSeconds = Normalize.number(true, 0, 59);

     this.state =
       {
         daysValue: null,
         hoursValue: null,
         minutesValue: null,
         secondsValue: null
       };
     }
   }

   onChangeDays = (val) => {
     if (val.toString().length == 3) {
       let hoursParent = ReactDOM.findDOMNode(this.hours);

       if (hoursParent) {
         let hoursInputs = hoursParent.getElementsByTagName("input");
         if (hoursInputs && hoursInputs.length > 0) {
           let minutes = hoursInputs[0];
           minutes.focus();
         }
       }
     }

     if(this.props.onChangeCallbackDays){
       val = this.normalizeDays(val, this.state.daysValue);
       this.props.onChangeCallbackDays(val);
     }
     this.setState({daysValue: val});
   }

   onChangeHours = (val) => {
     let maxHoursLength = this.props.maxHoursLength || 2;
     if (val.toString().length == maxHoursLength ) {
       let minutesParent = ReactDOM.findDOMNode(this.minutes);
       if (minutesParent) {
         let minutesInputs = minutesParent.getElementsByTagName("input");
         if (minutesInputs && minutesInputs.length > 0) {
           let minutes = minutesInputs[0];
           minutes.focus();
         }
       }
     }

     if(this.props.onChangeCallbackHours){
       val = this.normalizeHours(val, this.state.hoursValue);
       this.props.onChangeCallbackHours(val);
     }
     this.setState({hoursValue: val});
   };

   onChangeSeconds = (val) => {

     if(this.props.onChangeCallbackSeconds){
       val = this.normalizeSeconds(val, this.state.secondsValue);
       this.props.onChangeCallbackSeconds(val);
     }

     this.setState({secondsValue: val});
   };

   onChangeMinutes = (val) => {

     if(this.props.onChangeCallbackMinutes){
       val = this.normalizeMinutes(val, this.state.minutesValue);
       this.props.onChangeCallbackMinutes(val);
     }

     this.setState({minutesValue: val});
   };


   formatSecondsHandler = (val) => {
     if (val !== undefined && val !== '' && val < 10) {
       return '0' + val;
     }
     return val === undefined ? '' : val;
   };

   formatMinutesHandler= (val) => {
     return (val || val === 0 ) ? (val < 10 ? '0' + val : val ) : '';
   };

   setHoursRef = (r) => {
     this.hours = r || this.hours;
   };

   setMinutesRef = (r) => {
     this.minutes = r || this.minutes;
   };
   setSecondsRef = (r) => {
     this.seconds = r || this.seconds;
   };

   validate = (value, allValues, props, name)=>{

   }

   renderDaysComponent = () => {
     return this.props.isDays ?
       (
         <React.Fragment>
           <TextField
             id={`days-${this.props.id}_days`}
             name={this.props.input.name + '_days'}
             className="inner days"
             placeholder={this.labels.get('placeholders.days')}
             normalize={this.normalizeDays}
             onChangeCallback={this.onChangeDays}
             disabled={this.props.disabled}
           />
           <span className="time-separator">:</span>
         </React.Fragment>) : null;
   };

   renderHoursComponent =()=> {
     if (!this.props.isHours) {
       return null;
     }

     return (  <React.Fragment>
       <TextField
       id={`left-${this.props.id}_hours`}
       name={this.props.input.name + '_hours'}
       className="inner hours"
       placeholder={this.placeholder}
       normalize={this.normalizeHours}
       onChangeCallback={this.onChangeHours}
       disabled={this.props.disabled}
       ref={this.setHoursRef}
     />
       <span className="time-separator">:</span>
     </React.Fragment>);
   };

   renderMinutesComponent =()=> {
     return ( <TextField
       id={`${this.props.id}_minutes`}
       name={this.props.input.name + '_minutes'}
       className="inner minutes"
       placeholder={this.labels.get('placeholders.minutes')}
       normalize={this.normalizeMinutes}
       onChangeCallback={this.onChangeMinutes}
       format={this.formatMinutesHandler}
       disabled={this.props.disabled}
       ref={this.setMinutesRef}
       validate={this.validate}
     />);
   };

   renderSecondsComponent = () => {
     if (!this.props.isSeconds) {
       return null;
     }
     return (
       <React.Fragment>
         <span className="time-separator">:</span>
         <TextField
           id={`${this.props.id}_seconds`}
           name={this.props.input.name + '_seconds'}
           className="inner minutes"
           placeholder={this.labels.get('placeholders.seconds')}
           normalize={this.normalizeSeconds}
           onChangeCallback={this.onChangeSeconds}
           format={this.formatSecondsHandler}
           disabled={this.props.disabled}
           ref={this.setSecondsRef}
           validate={this.validate}
         />
       </React.Fragment>);
   };

   render()
   {
     let className =this.props.disabled ? "time-value-wrapper disabled" : "time-value-wrapper";

    return (
      <div className={className} id={this.props.id} disabled={true}>
        {this.renderDaysComponent()}
        {this.renderHoursComponent()}

        {this.renderMinutesComponent()}
        {this.renderSecondsComponent()}
        <ValidationComponent className ="validation-component" {...this.props.meta} />
      </div>
    );
  }
}
PL_TimeField.defaultProps = {
  isDays: false,
  isSeconds: false,
  isHours: true
};

PL_TimeField.propTypes = {
  id: PropTypes.string.isRequired,
  isDays: PropTypes.bool,
  isSeconds: PropTypes.bool,
  isHours:PropTypes.bool,
  hoursCount: PropTypes.bool,
  maxHoursLength: PropTypes.number, //number of digits in hour field
  disabled: PropTypes.bool,
  onChangeCallbackDays: PropTypes.func, //(days: int) => void;
  onChangeCallbackHours: PropTypes.func, //(hours: int) => void;
  onChangeCallbackMinutes: PropTypes.func, //(minutes: int) => void;
  onChangeCallbackSeconds: PropTypes.func, //(seconds: int) => void;
};

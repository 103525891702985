import { api as gridApi} from './sensorsGridViewActions';
import * as logsService from "../../../services/Logs/logsService";
import {api as messageDialogApi} from "../../MessageDialog/messageDialogActions";
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    api.load(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.activate = function (dispatch, getState) {
  return function (logs) {
    let targetIds = logs.map((item) => item.id);
    logsService.activateSensorsLog(targetIds).then(()=>{

      gridApi.reload(dispatch, getState)();
    });
  }
};

api.deactivate = function (dispatch, getState) {
  return function (logs) {
    let targetIds = logs.map((item) => item.id);
    logsService.deactivateSensorsLog(targetIds).then(()=>{
      gridApi.reload(dispatch, getState)();
    });
  }
};




api.load = function(dispatch, getState) {
  return function(shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }
  }
};

api.delete = function (dispatch, getState) {
  return function (logs) {
    let targetIds = logs.map((item) => item.id);
    logsService.deleteSensorsLog(targetIds).then(()=>{
      messageDialogApi.showSuccess(dispatch, getState)('mat.administration.settings.sensors.dialog.createSensor.confirm.delete');
      gridApi.reload(dispatch, getState)();
    });
  }
};


api.reload = function(dispatch, getState) {
  return function() {
    gridApi.reload(dispatch, getState)();
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

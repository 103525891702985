import React from 'react';
import PropTypes from 'prop-types';

import EntitiesMultiSelect from '../../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';

require('./assetsWithConnectionSection.scss');


export default class AssetsWithConnectionSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;

    this.hasPreSelectedWo = !!this.props.workOrder;

    // this.state = {
    //   toKey: 0,
    //   addKey: 0,
    // };
  }

  // componentWillReceiveProps(nextProps) { //moved to render(): key & componentDidUpdate()
  //   if (nextProps.workOrder !== this.props.workOrder) {
  //     this.props.change(this.getReduxFieldName('add-asset-selection') + '.assetsValue', null);
  //     this.props.change(this.getReduxFieldName('to-asset-selection') + '.assetsValue', null);
  //     this.setState({
  //       toKey: this.state.toKey === 0 ? 1 : 0,
  //       addKey: this.state.addKey === 0 ? 1 : 0,
  //     });
  //   }
  // }
  //


  componentDidUpdate(prevProps, prevState) {
    if (this.props.workOrder !== prevProps.workOrder) {
      this.props.change(this.getReduxFieldName('add-asset-selection') + '.assetsValue', null);
      this.props.change(this.getReduxFieldName('to-asset-selection') + '.assetsValue', null);
    }
  }

  getReduxFieldName = (fieldName) => {
    return this.props.name + '.' + fieldName;
  };

  render = () => {
    let {name, entitiesTypes, fetchConfig, itemIndex, ...otherProps} = this.props;
    let focusProps = (itemIndex === 0 && this.hasPreSelectedWo) ? {autoFocus: true} : {};

    return <div className="add-to-asset-container">
      <div className="add-to-asset-section">
        <label className="add-to-asset-label">{this.labels.get('add')}</label>

        <EntitiesMultiSelect
          // key={this.state.addKey}
          key={this.props.workOrder?.businessId || '' }
          id={this.getReduxFieldName('add-asset-selection')}
          name={this.getReduxFieldName('add-asset-selection')}
          className="add-to-asset-selection"
          entitiesTypes={entitiesTypes}
          validate={Validation.required}
          dropdownMenuClassName='multi-select-field-workorder-dialog'
          fetchConfig={fetchConfig}
          {...focusProps}
          { ...otherProps }
        />
      </div>

      <label className="add-to-asset-arrow">
        <i className="pl pl-move-arrow-right"/>
      </label>

      <div className="add-to-asset-section">
        <label className="add-to-asset-label">{this.labels.get('to')}</label>
        <EntitiesMultiSelect
          // key={this.state.toKey}
          key={this.props.workOrder?.businessId || '' }
          id={this.getReduxFieldName('to-asset-selection')}
          name={this.getReduxFieldName('to-asset-selection')}
          className="add-to-asset-selection"
          entitiesTypes={entitiesTypes}
          validate={Validation.required}
          dropdownMenuAttachment='right'
          dropdownMenuClassName='multi-select-field-workorder-dialog'
          fetchConfig={fetchConfig}
          { ...otherProps }
        />
      </div>

    </div>

  };
}

AssetsWithConnectionSection.defaultProps = {
  entitiesTypes: [],
};

AssetsWithConnectionSection.propTypes = {
  workOrder : PropTypes.object,
  entitiesTypes: PropTypes.array,
  fetchConfig: PropTypes.object.isRequired,
};





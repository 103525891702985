import React from 'react';
import PropTypes from 'prop-types';

import MultiEntitiesMultiSelect from '../../../../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect.js';

require('./assetsWithoutConnectionSection.scss');


export default class AssetsWithoutConnectionSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;

    this.hasPreSelectedWo = !!this.props.workOrder;

    // this.state = {
    //   dummyKey: 0,
    // };
  }

  // componentWillReceiveProps(nextProps) {  //moved to render(): key
  //   if (nextProps.workOrder != this.props.workOrder) {
  //     this.setState({
  //       dummyKey: this.state.dummyKey === 0 ? 1 : 0,
  //     });
  //   }
  // }

  render = () => {
    let {name, fetchConfig, preselectedAssets, entitiesTypes, itemIndex, ...otherProps} = this.props;
    let focusProps = (itemIndex === 0 && this.hasPreSelectedWo) ? {autoFocus: true} : {};

    return(
      <div className="selection-rows">
        <label>{this.labels.get('relatedassets')}</label>
        <MultiEntitiesMultiSelect
          id={`${name}.reportedAssets`}
          name={`${name}.reportedAssets`}
          entitiesTypes={entitiesTypes}
          // key={this.state.dummyKey}
          key={this.props.workOrder?.businessId || '' }
          preSelectedEntities={preselectedAssets}
          fetchConfig={fetchConfig}
          disableOnPreselect={false}
          {...focusProps}
          { ...otherProps }/>
      </div>
    )

  };
}

AssetsWithoutConnectionSection.defaultProps = {
  entitiesTypes: [],
};

AssetsWithoutConnectionSection.propTypes = {
  workOrder : PropTypes.object,
  entitiesTypes: PropTypes.array,
  fetchConfig: PropTypes.object.isRequired,
};





import { Map } from 'immutable';

import { actionTypes as systemActionTypes } from '../../actions/System/systemActions.js';
import { actionTypes as networkActionTypes } from 'infrastructure/js/actions/networkActions';

let defaultState = {
  serverDatetime: localStorage.getItem('serverDateTime') ?? 0,
  serverVersion: '',
  clientVersion: '',
  indicatorBarData: Map({
    offlineMode: false,
    offlineModeIntervalId: null,
    versionMismatchMode: false,
    previewMode: false,
    maintenanceMode: null,
  }),
  loading: false,
  featureFlags: { ALL: true },
  currentPageName: null,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case systemActionTypes.SYSTEM_SET_SERVER_DATETIME:
      localStorage.setItem('serverDateTime', action.datetime);
      return state.set('serverDatetime', action.datetime);

    case systemActionTypes.SYSTEM_SET_SERVER_VERSION:
      return state.set('serverVersion', action.version);

    case systemActionTypes.SYSTEM_SET_CLIENT_VERSION:
      return state.set('clientVersion', action.version);

    case systemActionTypes.SYSTEM_LOADING_MODE:
      return state.set('loading', action.payload);

    case networkActionTypes.NETWORK_OFFLINE_MODE_ON:
      state = state.setIn(['indicatorBarData', 'offlineMode'], true);
      state = state.setIn(['indicatorBarData', 'offlineModeIntervalId'], action.payload);
      return state;
    case networkActionTypes.NETWORK_OFFLINE_MODE_OFF:
      state = state.setIn(['indicatorBarData', 'offlineMode'], false);
      state = state.setIn(['indicatorBarData', 'offlineModeIntervalId'], null);
      return state;

    case systemActionTypes.SYSTEM_VERSION_MISMATCH_MODE_ON:
      return state.setIn(['indicatorBarData', 'versionMismatchMode'], true);

    case systemActionTypes.SYSTEM_PREVIEW_MODE_ON:
      return state.setIn(['indicatorBarData', 'previewMode'], true);

    case systemActionTypes.SYSTEM_MAINTENANCE_MODE:
      state = state.setIn(['indicatorBarData', 'maintenanceMode'], action.payload.mode);
      return state;

    case systemActionTypes.SYSTEM_SET_FEATURE_FLAGS:
      return state.set('featureFlags', action.payload);

    case systemActionTypes.SYSTEM_SET_CURRENT_PAGE_NAME:
      return state.set('currentPageName', action.payload);

    default:
      return state;
  }
}

import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import * as visibilityDevicesService from '../../../services/Rfid/visibilityDevicesService';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_RFID_SETTINGS_VISIBILITY_DEVICES, _fetchDataAction);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let deviceBusinessId =  getState().administration.getIn(['rfidSettingsPage', 'visibilityDevicesViewData' , 'selectedDeviceApp']);
    if (!deviceBusinessId || !deviceBusinessId.label) {
      return Promise.resolve([]);
    }

    let queryData = {
      deviceOrgBusinessId :getState().login.get('loggedInUser').loggedInOrg.businessId,
      deviceBusinessId: deviceBusinessId.label
    };

    return deviceAppsService.getDeviceProfile(queryData).then((res) => {
      return visibilityDevicesService.fetchDevices(res.data.profileId);
    });
  }
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;


import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as assetService from '../../../services/Assets/assetService.js';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SCANASSET_PAGE_SUBMIT_IN_PROGRESS:  'SCANASSET_PAGE_SUBMIT_IN_PROGRESS',
  SCANASSET_PAGE_SUBMIT_FINISHED: 'SCANASSET_PAGE_SUBMIT_FINISHED',
  SCANASSET_PAGE_SUBMIT_FAILED: 'SCANASSET_PAGE_SUBMIT_FAILED',
  SCANASSET_PAGE_CLEAR: 'SCANASSET_PAGE_CLEAR',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  subminInProgress: function() {
    return {type: actionTypes.SCANASSET_PAGE_SUBMIT_IN_PROGRESS};
  },
  submitFinished: function(payload) {
    return {type: actionTypes.SCANASSET_PAGE_SUBMIT_FINISHED, payload: payload};
  },
  submitFailed: function(payload) {
    return {type: actionTypes.SCANASSET_PAGE_SUBMIT_FAILED, payload: payload};
  },
  clear: function(payload) {
    return {type: actionTypes.SCANASSET_PAGE_CLEAR, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.submit = function(assetBusinessId, callback) {
  return function(dispatch, getState) {
    api.submit(dispatch, getState)(assetBusinessId, callback);
  }
};

jsxActions.clear = function() {
  return function(dispatch, getState) {
    dispatch(actions.clear());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.submit = function(dispatch, getState) {
  return function(assetBusinessId, callback) {

    dispatch(actions.subminInProgress());

    // return rollService.getRollByBusinessId({businessId: assetBusinessId} )
    return assetService.getAssetDetails({assetBusinessId} )
      .then ((response) => {

        if (!Network.isResponseValid(response)) {
          console.error('Fetch asset details by businessId failed');
          let message = createLabelHelper('mat.mobile.scanAssetPage.').get('result.barcodeNotFound');
          dispatch(actions.submitFailed(message));

          return {success: false};
        }

        // dispatch(actions.submitFailed('Roll barcode not found'));
        // return {success: false};
        // let payload = response.data && response.dataList.length > 0 ? response.dataList[0] : null;
        dispatch(actions.submitFinished(response.data));

        if (callback) {
          callback(response.data.id);
        }

      });
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS







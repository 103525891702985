import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import * as partsService from '../../../services/Parts/partsService';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';
import {api as locationPageApi} from '../../LocationPage/locationPageActions';
import * as assetService from '../../../services/Assets/assetService';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import * as daHelper from '../../Dialogs/dialogActionsHelper';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes('AFP_OPERATION_WIZARD'),
};

const actions = {
  ...daHelper.getActions('AFP_OPERATION_WIZARD'),
};

export let api = {
  ...daHelper.getApiActions(actions, config),
  fetchScannedAsset(dispatch, getState) {
    return function (config) {
      return assetService.getAssetByScannedTag({correlationId: config?.correlationId})
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Failed to fetch scanned asset. ', response);
            messageDialogApi.responseError(dispatch, getState)(response);
            return null;
          }

          let fetchedData = {
            callbackId: config?.callbackId,
            data: response?.data
          }
          return fetchedData;
        })
        .catch(err => {
          console.error('Failed to get scanned asset', err);
          return null;
        })
        .finally(() => {
          dispatch(actions.setBusy(false));
        })
    }
  },

  showConfirmation(dispatch, getState) {
    return function(config) {

      let confirmationCallback = config.submitHandler;
      delete config.submitHandler;
      let confirmationCallbackData = config.spoolBusinessId;

      let labels = createLabelHelper('mat.wizards.afpOperation.validation.');

      let confirmationConfig = {
        title: labels.get('confirmation.title'),
        message: labels.get('confirmation.message', '', config),
        cancelHandler: messageDialogApi.close(dispatch, getState),
        submitButtonLabel: 'OK',
        submitHandler: () => api.handleConfirmation(dispatch, getState)(confirmationCallback, confirmationCallbackData)
      }

      messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
    }
  },

  handleConfirmation(dispatch, getState) {
    return function (confirmationCallback, confirmationCallbackData) {
      messageDialogApi.close(dispatch, getState)();

      confirmationCallback?.(confirmationCallbackData);
    }
  },

  showError(dispatch, getState) {
    return function(config) {
      messageDialogApi.showError(dispatch, getState)('mat.wizards.afpOperation.validation.error.message', config);
    }
  },

  submit(dispatch, getState) {
    return function(data, messageDialogBuilder, reloadParentComponent) {

      let operations = getState().locationPage.get('workOrderViewData')?.get('operations');
      let operation = operations?.find(op => op.operationType.name === getEnumValue(enumTypes.OPERATION_TYPE)('AFP')) || null;

      data.operationId = operation?.id || null;
      data.locationId = locationPageApi.getLocationId(dispatch, getState)();

      dispatch(actions.setBusy(true));
      return partsService.reportFiberPlacementOperation(data).then((response) => {

        dispatch(actions.setBusy(false));

        let validations = dialogHelper.getResponseValidationDetails(response);
        if (validations) {
          dispatch(actions.setValidationWarningsData(validations));
          return {success: false};
        }
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Report Fiber Placement operation failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return null;
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }

        if (messageDialogBuilder) {
          let messageDialogDescriptor = messageDialogBuilder(
            response.data,
            messageDialogApi.close(dispatch, getState)
          );
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }
      });
    }
  },
}

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  generatePartId() {
    return function(dispatch, getState) {
      return partsService.generatePartId()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Failed to generate Part id', response);
            return null;
          }
          return response;
        })
        .catch(err => {
          console.error('Failed to generate Part id', err);
          return null;
        })
    }
  },

  generateLayerId() {
    return function(dispatch, getState) {
      return partsService.generateLayerId()
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Failed to generate Layer id', response);
            return null;
          }
          return response;
        })
        .catch(err => {
          console.error('Failed to generate Layer id', err);
          return null;
        })
    }
  },

  showConfirmation(config) {
    return function(dispatch, getState) {
      return api.showConfirmation(dispatch, getState)(config);
    }
  },

  showError(config) {
    return function(dispatch, getState) {
      return api.showError(dispatch, getState)(config);
    }
  },

  getAssetByTid(tid) {
    return function(dispatch, getState) {
      dispatch(actions.setBusy(true));

      return assetService.getAssetByTid(tid)
        .then((response) => {
          if (!Network.isResponseValid(response)) {
            console.error('Failed to get asset by tid: ' + tid, response);
            messageDialogApi.responseError(dispatch, getState)(response);
            return null;
          }
          return response;
        })
        .catch(err => {
          console.error('Failed to get asset by tid', err);
          return null;
        })
        .finally(() => {
          dispatch(actions.setBusy(false));
        })
    }
  },

  fetchScannedAsset(config, callback) {
    return function(dispatch, getState) {
      return api.fetchScannedAsset(dispatch, getState)(config, callback);
    }
  },

  getAssetData(assetId, callbackId) {
    return function(dispatch, getState) {
      dispatch(actions.setBusy(true));

      let promise1 = partsService.getPartLayers(assetId);
      let promise2 = assetService.getAssetEquipmentAssets(assetId);
      return Promise.all([promise1, promise2]).then((allResults) => {
        let isResultValid = allResults.every((result) => {
          return Network.isResponseValid(result);
        });
        if (!isResultValid) {
          console.error('Failed to get part data.', response);
          return null;
        }
        let layers = allResults[0].dataList;
        let quipmentAssets = allResults[1].dataList;

        let data = {
          layers: layers,
          equipmentAsset: quipmentAssets?.length > 0 ? quipmentAssets[0] : null,
          callbackId: callbackId
        }
        return data;
      })
        .catch(err => {
          console.error('Failed to generate Layer id', err);
          return null;
        })
        .finally(() => {
          dispatch(actions.setBusy(false));
        })
    }
  },
}

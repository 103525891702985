import React from 'react';
import PropTypes from 'prop-types';

import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';


require('./natureListItem.scss');

export default class NatureListItem extends React.PureComponent {

  onChangeCallback = (value, oldValue) => {
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
  };

  render() {
    let {options, name} = this.props;

    return (<div className="nature-list-item">
      <Combobox id={ name + '.[nature]'}
                name={ name + '.[nature]'}
                validate={Validation.dropdown.required}
                options={options}
                parse={Parse.comboValueOnly()}
                format={Format.findOptionByValue(options)}
                onChangeCallback={this.onChangeCallback}
                isDisabled={this.props.disabled}
      />
      </div>);
  }
}

NatureListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};





import React from 'react';
import {reduxForm} from 'redux-form';

import Form from '../../../../Common/Form/form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';


class GroupTabForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.formLabels = createLabelHelper('mat.administration.settings.alerts.form.');

    this.state = {
      isEnableAlerts : false
    };
  }

  componentDidMount (){
    this.initFormValues();
    this.setStateStartValues();
  }

  componentWillUnmount(){
    this.props.actions.setStartParameters();
  }

  setStateStartValues = () => {
    this.setState({isEnableAlerts: !this.props.sData.enabled});
  };

  isEtlAlert = (alertType) => {
    return (alertType === getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND') || alertType === getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'));
  };

  initFormValues = () => {

    let data = this.props.sData;

    //todo
    let initialValue = {
      containerEnableAlertsCheckbox: data.enabled,
      containerDefaultValuesWarning: this.isEtlAlert(this.props.alertType) ? data.warningThreshold / 60 : data.warningThreshold, // convert to hours
      containerDefaultValuesError : this.isEtlAlert(this.props.alertType) ? data.errorThreshold / 60 : data.errorThreshold, // convert to hours
    };

    this.props.initialize(initialValue);
  }

  onCheckboxClick = () => {
    this.setState({isEnableAlerts: !this.state.isEnableAlerts});
  }

  onSubmit = (data) => {
//todo
    let newData = {
      id: this.props.sData.id,
      enabled: data.containerEnableAlertsCheckbox,
      warningThreshold: this.isEtlAlert(this.props.alertType) ? data.containerDefaultValuesWarning * 60 : data.containerDefaultValuesWarning, //convert to minutes
      errorThreshold: this.isEtlAlert(this.props.alertType) ? data.containerDefaultValuesError * 60 : data.containerDefaultValuesError, //convert to minutes
    };

    this.props.actions.update(newData, this.props.alertType, this.getMessageDialogBuilder()).then((res) => {
      if (res.success) {
        this.initFormValues(); //Initialize form values and reset pristine.
      }
    });


  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.formLabels.get('confirmation.success');
      let className = 'oneBackground';
      let messageDialogDescriptor = {title, className};
      return messageDialogDescriptor;
    };
  };

  getFormButtons() {
    return (
      [
        {
          text: this.formLabels.get('buttons.save'),
          className: 'common-alerts-form-button-save border-btn btn',
          action: this.props.handleSubmit(this.onSubmit),
          disabled:this.props.pristine
        }
      ]
    );
  }

  renderGroupEnableAlertsSection() {
    let containerLabel = this.props.labels.get('group');
    return (
      <div className="common-alerts-form-enable-alerts-section" >
        <label htmlFor="containerEnableAlertsCheckbox" >
          <Checkbox name="containerEnableAlertsCheckbox" id="containerEnableAlertsCheckbox" value={false} onClick={this.onCheckboxClick} />
          <span>{this.props.labels.get('checkbox.title' ,'',{assetType: containerLabel})}</span>
        </label>
      </div>
    );
  }

  renderGroupDefaultValuesSection() {
    return (
      <div className='common-alerts-form-default-values-container'>

        <div className="common-alerts-form-default-values-header">
          <label className='common-alerts-form-default-values-title'>{this.props.labels.get('defaultvalues.section.header')}</label>
        </div>

        <div className="common-alerts-form-default-values-section">
          <InputSection label={this.formLabels.get('defaultvalues.title.warning')} htmlFor="containerDefaultValuesWarning" className="inline left-side">
            <TextField id="containerDefaultValuesWarning" name="containerDefaultValuesWarning" disabled={this.state.isEnableAlerts}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 9999)} />
            <span className='additional-info'>{this.props.labels.get('defaultvalues.additionalinfo.title')}</span>
          </InputSection>

          <InputSection label={this.formLabels.get('defaultvalues.title.error')} htmlFor="containerDefaultValuesError" className="inline right-side">
            <TextField id="containerDefaultValuesError" name="containerDefaultValuesError" disabled={this.state.isEnableAlerts}
                       validate={Validation.required} normalize={Normalize.number(true, 0, 9999)} />
            <span className='additional-info'>{this.props.labels.get('defaultvalues.additionalinfo.title')}</span>
          </InputSection>
        </div>

      </div>
    );
  }

  render() {
    return (
      <Form  className="common-alerts-form" formButtons={this.getFormButtons()}>
        {this.renderGroupEnableAlertsSection()}
        {this.renderGroupDefaultValuesSection()}
      </Form>
    );
  }
}

export default reduxForm({
    form: 'groupTabForm'
  }
)(GroupTabForm);

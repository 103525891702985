import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Normalize from '../../../../../infrastructure/js/components/controls/controlsNormalizations';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import * as BatchJobHelper from '../../../utils/batchJobHelper';

require('./printRfidTagsDialog.scss');

const getObjectValue = (item) => {
  return item?.get ? item.toObject() : item;
};

class PrintRfidTagsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.printRfidTagsDialog.');
    this.batchJobLabels = createLabelHelper('mat.batchJob.');

    const selectedAsset = getObjectValue(this.props.sData.get('selectedAsset') ?? props.asset);

    this.asset = selectedAsset ? {} : null;

    if (selectedAsset) {
      this.asset.id = selectedAsset['id'];
      this.asset.tags = selectedAsset['tags'];
      this.asset.name = selectedAsset['businessId'];
      this.asset.numberOfTags = Array.isArray(this.asset.tags) ? this.asset.tags.length : 0;
    }
  }

  componentDidMount() {
    if (this.props.sData.get('printers') && this.props.sData.get('printers').length > 0) {
      let initialValues = {
        printer: this.props.sData.get('printers')[0],
      };
      this.props.initialize(initialValues);
    }
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.onHide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.labels.get('footer.print'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading'),
        },
      ],
    };
  }

  onSubmit = (data) => {
    let newData = { printerId: data.printer ? data.printer.value : null };
    if (!this.props.sData.get('isGeneric')) {
      //print with assets
      newData.assetIds = this.props.sData.get('assets');
    } else {
      //generic print
      newData.tagsCount = data.numberOfTags;
      newData.isGeneric = true;
    }

    if (this.asset) {
      newData.assetIds = [this.asset.id];
      newData.isGeneric = false;
    }

    // this.props.actions.submit(newData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
    this.props.actions.submitWithProgress(
      newData,
      this.getMessageDialogBuilder(),
      this.props.reloadParentComponent
    );
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = BatchJobHelper.createJobTitle(
        response.jobName,
        response.totalJobItems,
        this.batchJobLabels
      );
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title, false);
    };
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onStop = () => {
    this.props.actions.stop();
  };

  renderAssetInformation = () => {
    if (!this.asset) return null;
    const { name, numberOfTags } = this.asset;

    return (
      <div className="asset-information">
        <strong>{name}</strong> has <strong>{numberOfTags}</strong> tags attached to it. You can
        print <strong>{10 - numberOfTags}</strong> more tags.
      </div>
    );
  };

  renderNumberOfTags = () => {
    if (!this.props.sData.get('isGeneric') && !this.asset) {
      return null;
    }

    const maxNumber = this.asset ? 10 - this.asset.numberOfTags : 999;

    return (
      <InputSection
        label={!this.asset ? this.labels.get('numberOfTags') : 'NUMBER OF TAGS'}
        htmlFor="numberOfTags"
        className="inline left-side"
      >
        <TextField
          id="numberOfTags"
          name="numberOfTags"
          className="number-of-tags"
          validate={Validation.required}
          normalize={Normalize.number(true, 1, maxNumber)}
        />
      </InputSection>
    );
  };

  render() {
    return (
      <Dialog
        id="printRfidTagsDialog"
        className="printRfidTagsDialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        {this.renderAssetInformation()}

        {this.renderNumberOfTags()}

        <InputSection
          label={this.labels.get('printer')}
          htmlFor="printer"
          className="inline right-side"
        >
          <Dropdown
            id="printer-dropdown"
            name="printer"
            placeholder={this.labels.get('printer.placeholder')}
            validate={Validation.dropdown.required}
            options={this.props.sData.get('printers')}
            isDisabled={
              (this.props.sData.get('printers') && this.props.sData.get('printers').length === 1) ||
              this.props.sData.get('loading')
            }
          />
        </InputSection>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'printRfidTagsDialog',
})(PrintRfidTagsDialog);

PrintRfidTagsDialog.defaultProps = {
  asset: null,
};

PrintRfidTagsDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  asset: PropTypes.object,
};

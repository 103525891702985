import React from 'react';

import PropTypes from 'prop-types';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../../enums/gridsNames';
require('./correlationsGrid.scss');

export default class CorrelationsGrid extends React.PureComponent {

  filterConfig = [
  ];

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.dashboard.correlations.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  getFirstColumnTitle = () => {
    if (this.props.gridName === gridsNames.DASHBOARD_CORRELATIONS_TOOLS) {
      return this.labels.get('tools.columns.name.title');
    }
    if (this.props.gridName === gridsNames.DASHBOARD_CORRELATIONS_LOCATIONS) {
      return this.labels.get('locations.columns.name.title');
    }
    if (this.props.gridName === gridsNames.DASHBOARD_CORRELATIONS_OPERATIONS) {
      return this.labels.get('operations.columns.name.title');
    }
    return '';
  };

  getLastColumnTitle = () => {
    if (this.props.gridName === gridsNames.DASHBOARD_CORRELATIONS_TOOLS) {
      return this.labels.get('tools.columns.totalProduced.title');
    }
    if (this.props.gridName === gridsNames.DASHBOARD_CORRELATIONS_LOCATIONS) {
      return this.labels.get('locations.columns.totalProduced.title');
    }
    if (this.props.gridName === gridsNames.DASHBOARD_CORRELATIONS_OPERATIONS) {
      return this.labels.get('operations.columns.totalProduced.title');
    }
    return '';
  };

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        // title: this.labels.get('tools.columns.name.title'),
        title: this.getFirstColumnTitle(),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false
        },
      },
      {
        fieldName: 'defectsNumber',
        title: this.labels.get('tools.columns.defectsNumber.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false
        },
      },
      {
        fieldName: 'm_defectRate',
        title: this.labels.get('tools.columns.defectsRate.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false
        },
      },
      {
        fieldName: 'totalProduced',
        width: 350,
        title: this.getLastColumnTitle(),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false
        }
      }
    ];
  };

  render() {
    return (
      <div className="cause-grid">
        <Grid gridName={this.props.gridName}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              rows={this.props.rows}
              isStatic={true}
              gridProps={
                {
                  checkboxSelection: false,
                }
              }
          >
        </Grid>
      </div>
    );
  }
}


CorrelationsGrid.defaultProps = {
  gridName: gridsNames.DASHBOARD_CORRELATIONS_TOOLS
};

CorrelationsGrid.propTypes = {
  gridName: PropTypes.string.isRequired,
};







import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import AssetsFileUploader from 'infrastructure/js/components/controls/AssetsFileChooser/assetsFileChooser';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import {ImportTypes} from '../../../../enums/importTypes';
import {filesConsts as FILES_CONSTS} from '../../../../constants/constants';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {Link} from 'react-router-dom';

require('./uploadFileStep.scss');

export class UploadFileStep extends React.PureComponent {

  constructor(props) {
    super(props);

    let importTypes = props.importTypes;
    let importType = importTypes && importTypes.length > 1 ? 'files' : 'files.' + importTypes;

    this.labels = createLabelHelper(`mat.wizards.import.${importType}.`);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.assetTypesLabels = createLabelHelper('mat.wizards.import.assettype.');
    let preselectedType = importTypes && importTypes.length === 1 ? importTypes[0] : null;
    if (preselectedType) {
      this.props.actions.onSelectedAssetTypeChanged(preselectedType);
    }

    this.assetTypes = [
      {value: ImportTypes.IMPORT_ROLLS, label: this.assetTypesLabels.get('rolls')},
      {value: ImportTypes.IMPORT_SPOOLS, label: this.assetTypesLabels.get('spools')},
      {value: ImportTypes.IMPORT_SHIPMENTS, label: this.assetTypesLabels.get('shipments')},
      {value: ImportTypes.IMPORT_KITS, label:this.assetTypesLabels.get('kits')},
      {value: ImportTypes.IMPORT_GROUPS, label:this.assetTypesLabels.get('groups')},
      {value: ImportTypes.IMPORT_WO, label: this.assetTypesLabels.get('wos')},
      {value: ImportTypes.IMPORT_TOOLS, label: this.assetTypesLabels.get('tools')},
      {value: ImportTypes.IMPORT_TAGS, label: this.assetTypesLabels.get('tags')},
      {value: ImportTypes.IMPORT_KIT_TYPES, label: this.assetTypesLabels.get('kittypes')},
      {value: ImportTypes.IMPORT_TOOL_TYPES, label: this.assetTypesLabels.get('tooltypes')},
      {value: ImportTypes.IMPORT_MATERIAL_TYPES, label: this.assetTypesLabels.get('materialtypes')},
      {value: ImportTypes.IMPORT_GROUP_TYPES, label: this.assetTypesLabels.get('grouptypes')},
      {value: ImportTypes.IMPORT_PART_TYPES, label: this.assetTypesLabels.get('parttypes')},
      {value: ImportTypes.IMPORT_PROJECTS, label: this.assetTypesLabels.get('projects')},
      {value: ImportTypes.IMPORT_OPERATION_SEQUENCE, label: this.assetTypesLabels.get('operationSequence')},
      {value: ImportTypes.IMPORT_NESTS, label: this.assetTypesLabels.get('cutplan')},
      {value: ImportTypes.IMPORT_NESTS_WITH_ROLL, label: this.assetTypesLabels.get('cutplan')},
      {value: ImportTypes.IMPORT_NESTS_MULTI_ROLLS, label: this.assetTypesLabels.get('cutplan')}
    ].filter(item => {
      return preselectedType ? item.value === preselectedType : true;
    });
  }

  goOn = () => {
    this.props.actions.uploadFile(this.props.sSelectedFile ,this.props.sSelectedAseetType ,this.props.shouldIncludeLocation)
  };

  goBack = () => {
    this.props.actions.onWizardClose();
    let wizardActions = this.props.wizardActions;
    wizardActions.close();
  };

  onHide = () => {
    this.props.actions.onWizardClose();
    let wizardActions = this.props.wizardActions;
    wizardActions.close();
  };

  getDialogButtons() {
    let buttonOK = {
      id:'submit',
      text: this.labels.get('footer.validate'),
      bsStyle: 'primary',
      disabled: this.props.sSelectedFile && !this.props.sErrors ? this.props.loading : true,
      loading: this.props.loading,
      action: this.props.handleSubmit(this.goOn)
    };

    let buttonCancel = {
      id:'cancel',
      text: this.dialogLabels.get('cancel'),
      action: this.goBack
    };

    return { left: [buttonCancel], right: [buttonOK] };
  }

  renderErrors = () => {
    if(this.props.sErrors){
       return this.renderErrorSelection(this.props.sErrors);
    }
    return null;
  }


  renderErrorSelection = (data) => {
     let icon = 'pl pl-warning-icon';
     let errorType = data.statusType;
      return (
        <div className="error-selection">
          <div className='error-item'>
              <span className={`error-icon ${icon} ${errorType}`}/>
              <label className='error-msg'>{data.message}</label>
          </div>
        </div>
      );
  };

  handleSelectedAssetTypeChanged = (assetType) => {
      this.props.actions.onSelectedAssetTypeChanged(assetType.value);
  };

  handleSelectedFileChanged = (file) => {
    if(file.size < FILES_CONSTS.FILE_SIZE_ALLOWED){ //Check if file size is smaller then 8 MB.
      this.props.actions.onSelectedFileChanged(file);
      this.props.actions.hasErrors(null);
    } else {
      let maxSize = Math.trunc(FILES_CONSTS.FILE_SIZE_ALLOWED / FILES_CONSTS.ONE_MB);
      let data = {
          errorCode: 'UF_10003',
          message: createLabelHelper('mat.wizards.import.files.errors.').get('filesize', undefined, {maxSize}),
          statusType:'error'
      };
      this.props.actions.hasErrors(data);
    }
  };

  renderNote = () => {
    if (this.getImportType() === ImportTypes.IMPORT_WO && PermissionManager.isDateFormatEnabled()) {
        return (<label>{createLabelHelper('period.date.format.tooltip').get('', undefined, {format: DateTimeHelper.getDateFormat()})}</label>)
    }

    if (this.getImportType() === ImportTypes.IMPORT_ROLLS || this.getImportType() === ImportTypes.IMPORT_SPOOLS) {
      if(PermissionManager.isDateFormatEnabled()){
        return <div>
          <label>{this.labels.get('bodyNote')}</label>
          <div>{createLabelHelper('period.date.format.tooltip').get('', undefined, {format: DateTimeHelper.getDateFormat()})}</div>
        </div>
      }
      return <label>{this.labels.get('bodyNote')}</label>
    }

    if (this.getImportType() === ImportTypes.IMPORT_MATERIAL_TYPES ||
        this.getImportType() === ImportTypes.IMPORT_PART_TYPES ||
        this.getImportType() === ImportTypes.IMPORT_PROJECTS
    ) {
      return <label className='material-types-body-note'>{this.labels.get('bodyNote')}</label>
    }

    return null;
  };

  getImportType = () => {
    let {importTypes} = this.props;
    return importTypes && importTypes.length > 0 ? importTypes[0] : '';
  }

  getCsvSettingsLinkByImportType = () => {
    if (this.getImportType() === ImportTypes.IMPORT_WO) {
      return '/Administration/settings/csv/workOrder'
    }
    return null;
  }

  renderCsvSettingsLink = () => {
    let scvSettingsLink = this.getCsvSettingsLinkByImportType();
    if (scvSettingsLink) {
      return (
        <Link to={scvSettingsLink} className="settings-link">{this.labels.get('viewSettings') || 'View csv settings'}</Link>
      )
    }
    return null;
  }

  render() {
    let animation = this.props.isFirstLaunch;
    let { importTypes, ...otherProps } = this.props;

    return (
      <Dialog
        id="upload-files-dialog"
        className="upload-files-dialog"
        animation={animation}
        show={true}
        onHide={this.onHide}
        titleText= {this.labels.get('title')}
        footerButtons={this.getDialogButtons()}
        handleHashChange={false}
      >

        <div className={cn('upload-file', {'import-type-wide': this.getImportType() === ImportTypes.IMPORT_OPERATION_SEQUENCE}) }>

          {this.props.bodyTopSection}

          <label className="body-title">{this.labels.get('bodytitle')}</label>
          <span>
            <label className="body-comment">{this.labels.get('bodycomment')}</label>
            {this.renderCsvSettingsLink()}
          </span>

          <AssetsFileUploader
            name="fileUpload"
            { ...otherProps }
            accept={this.props.acceptFileTypes}
            buttonText={this.props.sSelectedFile ? this.dialogLabels.get('changeFile') : this.dialogLabels.get('browse')}
            placeHolder={this.labels.get('selectfileplaceholder')}
            onSelectedFileChanged={this.handleSelectedFileChanged}
            onDropDownChange={this.handleSelectedAssetTypeChanged}
            options={this.assetTypes}
            selectedFile={this.props.sSelectedFile}
          />

          {this.renderNote()}

        </div>

        {this.renderErrors()}

      </Dialog>
    );
  }
}

export default reduxForm({
    forceUnregisterOnUnmount: true,
    destroyOnUnmount: false

  }
)(UploadFileStep);

UploadFileStep.propTypes = {
  importTypes:PropTypes.array,
  acceptFileTypes:PropTypes.array
};




import * as assetService from '../../services/Assets/assetService';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';

// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.printLabels = function (dispatch, getState) {
  return function (selectedAssetsIds, assetType) {

    assetService.PrintAssetLabel(assetType, selectedAssetsIds)
      .then((response) => {
        response.blob().then((blobResponse) => {
          if (blobResponse.size === 0) {
            console.error('Print Asset Label failed');
            return {success: false};
          }

          var myURL = window.URL || window.webkitURL;
          var file = new Blob([blobResponse], {type: 'application/pdf'});
          var fileURL = myURL.createObjectURL(file);
          window.open(fileURL, '_blank');
        });
      });

  };
};
api.printMultiAssetsLabels = function (dispatch, getState) {
  return function (assetsIds) {

    return assetService.printMultiAssetsLabels(assetsIds)
      .then((response) => {
        response.blob().then((blobResponse) => {
          if (blobResponse.size === 0) {
            console.error('Print multi assets label failed');
            messageDialogApi.responseError(dispatch, getState)();
            return {success: false};
          }

          let myURL = window.URL || window.webkitURL;
          let file = new Blob([blobResponse], {type: 'application/pdf'});
          let fileURL = myURL.createObjectURL(file);
          window.open(fileURL, '_blank');
        });
      })
      .catch(err => {
        console.error('printMultiAssetsLabels(): catch ', err);
        messageDialogApi.responseError(dispatch, getState)();
        return {success: false};
      })
  };
};

import * as locationService from '../../services/Locations/locationsService'
import {api as onHandInventoryGridActions} from "./onHandInventoryGridActions.js";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_FETCH_DATA_STARTED: "PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_FETCH_DATA_STARTED",
  PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_FETCH_DATA_FINISHED: "PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_FETCH_DATA_FINISHED",
  PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_UPDATE_HEADER_DATA: "PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_UPDATE_HEADER_DATA",
  PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_CLEAR_HEADER_DATA: "PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_CLEAR_HEADER_DATA",
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function() {
    return {type: actionTypes.PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_FETCH_DATA_STARTED};
  },
  fetchDataFinished: function(payload) {
    return {type: actionTypes.PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_FETCH_DATA_FINISHED, payload};
  },
  updateHeaderData:function (payload) {
    return {type: actionTypes.PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_UPDATE_HEADER_DATA, payload};
  },
  clearHeaderData:function (payload) {
    return {type: actionTypes.PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_CLEAR_HEADER_DATA};
  }

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid) {
  return function (dispatch, getState) {
    return api.init(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.unmount = function (shouldRefreshGrid) {
  return function (dispatch, getState) {
    dispatch(actions.clearHeaderData());
  }
};

jsxActions.fetchGridData = function (data) {
  return function (dispatch, getState) {
    dispatch(actions.updateHeaderData(data));
    return onHandInventoryGridActions.reload(dispatch, getState)(true);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function (dispatch, getState) {
  return function (shouldRefreshGrid) {
    dispatch(actions.fetchDataStarted());

    return locationService.fetchActiveLocations().then((response) => {
      let locationOptions = response.dataList.map((location)=>{
          return {value: location.id, label: location.name}
      });

      dispatch(actions.fetchDataFinished({locationOptions}));
    });
  }
}

import classNames from 'classnames';
import PropTypes from 'prop-types';

const TagItem = ({ item: { id, tagKey, valid }, onRemoveItem }) => {
  const handleRemoveTag = () => {
    onRemoveItem(id);
  };
  return (
    <div className={classNames('tag-item', { invalid: !valid })}>
      <div className="tag-item-name">
        {!valid ? <span className="icon pl pl-error-icon"></span> : ''}
        {tagKey}
      </div>
      <button className="tag-item-remove" type="button" onClick={handleRemoveTag}>
        x
      </button>
    </div>
  );
};

TagItem.propTypes = {
  item: PropTypes.object,
  onRemoveItem: PropTypes.func,
};

TagItem.defaultProps = {
  item: {},
  onRemoveItem: () => console.error('TagItem: no onRemoveItem method was provided.'),
};

export default TagItem;

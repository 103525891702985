import React from 'react';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {navigationStates} from '../../../../../enums/navigationStates';
import {scheduleAlertStatus, scheduleAlertType, getAlertColumnFilterSections} from '../../../../../utils/alertFilterSectionHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
// import MasterDetailRow from 'infrastructure/js/components/Grid/MasterDetailRow/MasterDetailRow';
import SchedulerOperationDetail from './schedulerOperationDetail';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import TaskAlertPopoverCell  from '../../../../Common/CustomGridCells/TaskAlertPopoverCell/taskAlertPopoverCell';
import TaskStatusCell       from '../../../../Common/CustomGridCells/TaskStatusCell/taskStatusCell';
import DraggableCell        from '../../../../Common/CustomGridCells/DraggableCell/draggableCell';
import IconCell             from '../../../../Common/CustomGridCells/IconCell/iconCell';
import EntityIdCell         from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import LabelWithStyleCell from '../../../../Common/CustomGridCells/LabelWithStyleCell/labelWithStyleCell';
import {getQuantityInfo} from '../../../../Common/Helpers/SchedulerHelper';
import moment from 'moment'
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./schedulerOperationsGrid.scss');

class SchedulerOperationsGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: "valid", filterName: "scheduleAlert"},
    {fieldName: "operationStatus", filterName: "operationStatus", getOptions: true},
    {fieldName: "plannedStartTime.epochDateTime", filterName: "plannedStartTime", getOptions: false},
    {fieldName: "workOrder.name", filterName: "operationWorkOrder", getOptions: false},
    {fieldName: "name", filterName: "name", getOptions: false},
    {fieldName: "operation.name", filterName: "operationType", getOptions: true},
    {fieldName: "dueDate.epochDateTime", filterName: "operationDueDate", getOptions: true},
    {fieldName: "locked", filterName: "operationLocked", getOptions: true},
    {fieldName: "m_priority", filterName: "priority", getOptions: true},
    {fieldName: "kitType.businessId", filterName: "kitTypeBusinessId", getOptions: false},
    {fieldName: "quantity", filterName: "quantity"},
  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.scheduler.operations.grid.');
    this.filterLabels = createLabelHelper('mat.filter.');
    this.columnsConfig = this.createColumnsConfig();
  }

  getFormattedDate = (data) => {
    return ((data) ?  DateTimeHelper.DateFormat(data * 1000) : '')
  }

  getDraggableColumn = () => {
    return {
      fieldName: '',
      title: '',
      filterType: filterTypes.NONE,
      width: 15,
      columnOptions: {
        resizable: false,
        cellComponent: DraggableCell,
        cellStyle: {paddingLeft: '3px'},
        valueGetter: (params) => {
          return {
            dataTransferDisplayValue: params.data.name,
            dataTransferTaskId: params.data.id,
            dataTransferToolId: params.data.plannedTool?.id ?? null,
            isDraggable: (params.data.operationStatus === 'UNASSIGNED')
          };
        }
      }
    }
  };

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'id',
        title: '',
        filterType: filterTypes.NONE,
        width: 20,
        columnOptions: {
          resizable: false,
          cellRenderer: 'agGroupCellRenderer',
          sortable: false,
          cellStyle: {borderRight: 'none'},
          headerClass: 'group-invisible-header',
          valueGetter: (params) => { return ''; }
        }
      },
      {
        fieldName: 'name',
        title: this.labels.get('columns.name.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'name',
        width: 180,
        fetchConfig: {
          entityType: EntityPropertyTypes.SCHEDULER_TASK_NAME,
          // filter: BO_EntityFilters.ALL,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities,
          maxPageSize: 200,
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'kitType.businessId',
        title: 'Kit Type',
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'kitTypeBusinessId',
        width: 100,
        fetchConfig: {
          entityType: EntityPropertyTypes.SCHEDULER_TASK_KIT_TYPE_BUSINESS_ID,
          // filter: BO_EntityFilters.ALL,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'valid',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'scheduleAlert',
        filterSections: getAlertColumnFilterSections(
          [scheduleAlertStatus.NO_ALERT,
                  scheduleAlertStatus.LOW_LEVEL_WARNING,
                  scheduleAlertStatus.HIGH_LEVEL_WARNING],
          [scheduleAlertType.DUE_DATE_CONFLICT,
                scheduleAlertType.DEPENDENCY_CONFLICT,
                scheduleAlertType.STATION_AVAILABILITY_CONFLICT,
                scheduleAlertType.TOOL_AVAILABILITY_CONFLICT,
                scheduleAlertType.PASSED_SCHEDULE]),
        width: 120,
        columnOptions: {
          cellComponent: TaskAlertPopoverCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.validationMessages,
            };
          }
        },
      },
      {
        fieldName: 'operationStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'operationStatus',
        filterWidth: 300,
        width: 135,
        columnOptions: {
          cellComponent: TaskStatusCell,
        }
      },
      {
        fieldName: 'workOrder.name',
        title: this.labels.get('columns.wo.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'operationWorkOrder',
        fetchConfig: {
          entityType: EntityPropertyTypes.SCHEDULER_TASK_WO_BUSINESS_ID,
          // filter: BO_EntityFilters.ALL_TASKS,
          filter: [FetchEntitiesFilters.ALL_TASKS],
          action: this.props.actions.fetchEntities,
          maxPageSize: 200,
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return params.data.workOrder ?
             {
              id: params.data.workOrder.id,
              label: params.data.workOrder.businessId,
              type: navigationStates.WORKORDER
            } : null
          }
        }
      },
      {
        fieldName: 'quantity',
        title: this.labels.get('columns.quantity.title'),
        filterType: filterTypes.NONE,
        // filterName: 'kitTypeBusinessId',      //TODO: L TOOL CAPACITY - handle with Server API
        width: 140,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({data}) => {
            return  getQuantityInfo(data.quantity, data.workOrderQuantity);
          }
        },
      },
      {
        fieldName: 'm_priority',
        title: this.labels.get('columns.priority.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'priority',
        width: 150,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.m_priority ? params.data.m_priority.label : '';
          }
        }
      },
      {
        fieldName: 'dueDate.epochDateTime',
        title: this.labels.get('columns.dueDate.title'),
        filterType: filterTypes.HORIZON,
        filterName: 'operationDueDate',
        filterLabel: this.filterLabels.get('date.before'),
        valueFormatter: (params) => {
          return this.getFormattedDate(params.data.dueDate.epochDateTime)
        },
        width: 150,
        columnOptions: {
          cellComponent: LabelWithStyleCell,
          valueGetter: (params) => {
            const dueDate = params.data?.dueDate?.epochDateTime || 0;
            const endTime = params.data?.endTime?.epochDateTime || 0;
            return {
              label: this.getFormattedDate(dueDate),
              style: (dueDate > 0 && endTime > 0 && endTime > dueDate) ? {'color': '#d9385c'} : null,
            }
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
       },
      {
       fieldName: 'operation.name',
       title: this.labels.get('columns.type.title'),
       filterType: filterTypes.MULTI_SELECT,
       filterName: 'operationType',
         width: 115,
       columnOptions: {
         cellComponent: LabelWithTooltipCell,
       }
      },
      {
        fieldName: 'plannedStartTime.epochDateTime',
        title: this.labels.get('columns.startTime.title'),
        filterType: filterTypes.DATE_SELECT,
        filterAlignment: filterAlignmentTypes.RIGHT,
        filterName: 'plannedStartTime',
        filterLabel: this.filterLabels.get('date.from'),
        isTime: true,
        valueFormatter: (params) => {
          return this.getFormattedDate(params.data?.plannedStartTime?.epochDateTime)
        },
        width: 150,
        columnOptions: {
          cellComponent: LabelWithStyleCell,
          valueGetter: (params) => {
            const plannedStartTime = moment.unix(params.data?.plannedStartTime?.epochDateTime)
            const horizonStartDate = moment(this.props?.sSchedulerData?.timeDefinition?.startDate)
            const isTaskOutOfRange = plannedStartTime.isBefore(horizonStartDate)
            return {
              label: this.getFormattedDate(params.data?.plannedStartTime?.epochDateTime),
              style: isTaskOutOfRange ? {'color': '#d9385c'} : null,
            }
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        },
      },
      ...(PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled ? [{
        fieldName: 'humanCapacity',
        title: this.labels.get('columns.plannedHumanCapacity.title'),
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({data}) => {
            const { splitAssignments, operationStatus } = data;

            if(splitAssignments?.length) {
              const humanCapacity = splitAssignments[0]?.humanCapacity;
              const shiftHumanCapacity = splitAssignments[0]?.shiftHumanCapacity ?? '';

              return `${humanCapacity}(${shiftHumanCapacity})${splitAssignments?.length > 1 ? '...' : ''}`
            }
            else if (operationStatus === 'SCHEDULED') {
              return 'N/A';
            }

            return '';
          }
        }
      }] : []),
      {
        fieldName: 'workOrderDescription',
        title: this.labels.get('columns.woDescription.title'),
        filterType: filterTypes.NONE,
        width: 115,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'split',
        title: '',
        filterType: filterTypes.NONE,
        width: 60,
        columnOptions: {
          sortable: false,
          cellComponent: IconCell,
          valueGetter: (params) => {
            return { iconClass: 'pl-split-task-icon',
              showIcon: !!params.data.split,
            };
          },
          headerComponentParams : {
            headerIcon: 'split-task-icon',
            headerTooltip: this.labels.get('columns.split.tooltip')
          }
        }
      },
      {
        fieldName: 'locked',
        title: '',
        filterType: filterTypes.NONE,
        width: 60,
        columnOptions: {
          resizable: false,
          cellComponent: IconCell,
          valueGetter: (params) => {
            return { iconClass: 'pl-lock',
                     showIcon: !!params.data.locked,
            };
          },
          headerComponentParams : {
            headerIcon: 'lock',
            headerTooltip: this.labels.get('columns.locked.tooltip')
          }
        }
      }
    ]
  };

  getRowHeight = (params) => {

    let rowHeight = 64;
    if (params?.node?.detail && params?.data?.extendedDetails) {
      let rowCount = Math.ceil(params.data.extendedDetails.rows.length / 4);
      return rowCount * 55;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="scheduler-operations-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  masterDetailRowComponent: SchedulerOperationDetail,
                  draggableColumn: this.getDraggableColumn,
                  isMasterDetail:true,
                  domLayout: 'autoHeight',
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'SchedulerOperationsGrid'
  }
)(SchedulerOperationsGrid);


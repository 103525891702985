import * as massCreationService from '../../../services/Administration/massCreationService.js';
import * as moHelper              from './massOperationsPageActionsHelper';
import { api as navigatorApi } from '../../AppTopbar/navigatorActions.js';
import {navigationStates} from '../../../enums/navigationStates';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...moHelper.getActionTypes('MASSOPERATIONS_PAGE'),
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...moHelper.getActions('MASSOPERATIONS_PAGE'),
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
const config = {
  submitMethod: massCreationService.createHooks,
  reduxStorageName: 'massOperationsPage'
};

export let api = {
  ...moHelper.getApiActions(actions, config),

  load: function(dispatch, getState) {
    return function (isNewLoad) {
      navigatorApi.setLoading(isNewLoad)(dispatch, getState);

      let topNavigatorData = {
        name: createLabelHelper('mat.header.navigator.administration.').get('massoperations.short'),
        type: navigationStates.MASS_OPERATIONS,
        id: 0,
        components: []
      };

      navigatorApi.setLoading(false)(dispatch, getState);
      navigatorApi.setData(dispatch, getState)(topNavigatorData);
    }
  }
};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...moHelper.getJsxActions(api),

  init: function () {
    return function (dispatch, getState) {
      api.load(dispatch, getState)(true);
    };
  },
};



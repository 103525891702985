import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as headerActions }  from '../../../actions/Mobile/Header/headerActions.js';
import ChangeLocationResultPage from '../../../components/Mobile/ChangeLocationResultPage/changeLocationResultPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      header: bindActionCreators(headerActions, dispatch),
    }
  }
}

let ChangeLocationResultPageContainer = connect(
  (state) => {
    return {
      sData: state.mobile.get('data'),
      sPageData: state.mobile.get('changeLocationPage'),
    };
  },
  mapDispatchToProps
)(ChangeLocationResultPage);

export default ChangeLocationResultPageContainer;





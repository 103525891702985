import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import AdditionalField from '../../../Common/Layout/AdditionalField/AdditionalField';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import Label from 'infrastructure/js/components/Label/label.js'
import MaterialHelper from '../../../../utils/materialHelper';
// import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {getDateFormatIcon} from 'infrastructure/js/components/controls/controlsUtils';

export default class SpoolAttributes extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.editassetattributes.');
  }

  componentDidMount() {
    this.props.actions.fetchMaterials();
    this.props.actions.fetchLocations();
    this.props.actions.fetchLots();
    this.props.actions.fetchManufacturers();
    this.props.actions.fetchProjects();
    this.props.actions.fetchInspectionTypes();
    this.props.actions.fetchAdditionalFields('SPOOL');
  }

  getComponentToRender = (props) => {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;

    let materialLabel = MaterialHelper.getMaterialFullLabel(option.data.materialName, option.data.businessId);

    return (<Label text={materialLabel} />);
  };

  getLabelExtraComponent = (value) => {
    return (
      <span className="label-extra-component">{value}</span>
    )
  }

  renderAdditionalFields = ()=>{
    if(!this.props.sData.get('additionalFields')){
      return null;
    }
    return this.props.sData.get('additionalFields').map((field, index)=> {
      return (<AdditionalField key={index} field={field} index={index}/>)
    });
  };

  renderLengthOrWeightOnly = (preselectedAssets, focusProps)=>{
      return (
        <div>
          <InputSection
            label={this.labels.get('length')}
            labelExtraComponent={this.getLabelExtraComponent(`(${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`) }
            htmlFor="length" className="inline left-side">
            <TextField id="length" name="length" className="short-textfield"
                       {...focusProps}
                       normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.LENGTH))}/>
          </InputSection>
        </div>
      )
  };

  renderMeasurementsSection = () => {
    return (
      <div className="measurements-section">
        <InputSection
                      label={this.labels.get('length')}
                      labelExtraComponent={this.getLabelExtraComponent(`(${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`) }
                      htmlFor="length"
                      className="inline">
          <TextField id="length" name="length" className="short-textfield"
                     normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.LENGTH))}/>
        </InputSection>
        <InputSection
                      label={this.labels.get('width')}
                      labelExtraComponent={this.getLabelExtraComponent(`(${UnitHelper.getLabelForUnitType(unitTypes.WIDTH)})`) }
                      htmlFor="width" className="inline">
          <TextField id="width" name="width" className="short-textfield"
                     normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.WIDTH))}/>
        </InputSection>
      </div>)
  }

  renderWeightField = () => {
    return (
      <div className="measurements-section">
        <InputSection
          label={this.labels.get('weight')}
          labelExtraComponent={this.getLabelExtraComponent(`(${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)})`) }
          htmlFor="weight"
          className="inline right-side">
          <TextField id="weight" name="weight" className="short-textfield"
                     normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.WEIGHT))}/>
        </InputSection>
      </div>)
  }

  render() {

    let {sData} = this.props;
    let editLengthOnly = sData ? sData.get('editLengthOnly') : false;

    if (editLengthOnly) {
      let preselectedAssets = this.props.sData.get('preselectedAssets');
      let focusProps = (preselectedAssets && preselectedAssets.size > 0) ? {autoFocus: true} : {};
      return this.renderLengthOrWeightOnly(preselectedAssets, focusProps);
    }

    return (
      <div>

        <InputSection label={this.labels.get("location")} htmlFor="location" className="inline left-side">
          <Combobox id="location" name="location" options={sData.get("locations")} />
        </InputSection>

        <InputSection label={this.labels.get("sublocation")} htmlFor="subLocation" className="inline right-side">
          <TextField id="subLocation" name="subLocation" />
        </InputSection>

        {this.renderMeasurementsSection()}

        {this.renderWeightField()}

        <InputSection label={this.labels.get("lot")} htmlFor="lot" className="inline left-side">
          <Combobox id="lot" name="lot" allowNewOption={true} options={sData.get("lots")} />
        </InputSection>

        <InputSection label={this.labels.get("material")} htmlFor="material" className="inline right-side">
          <Combobox id="material" name="material" options={sData.get("materials")}
                    singleValueRenderer={this.getComponentToRender}
                    optionRenderer={this.getComponentToRender}
          />
        </InputSection>

        <InputSection label={this.labels.get("manufacturer")} htmlFor="manufacturer" className="inline left-side">
          <Combobox id="manufacturer" name="manufacturer" options={sData.get("manufacturers")} allowNewOption={true} />
        </InputSection>


        <InputSection label={this.labels.get("dateofmanufacture")} htmlFor="dateOfManufacturer" className="inline right-side" labelExtraComponent={getDateFormatIcon()}>
          <DatePicker id="dateOfManufacturer" name="dateOfManufacturer" validate={Validation.date} />
        </InputSection>
        <InputSection label={this.labels.get("maxstoragetemp", undefined, {units:UnitHelper.getLabelForUnitType(unitTypes.TEMPERATURE)})} htmlFor="maxStorageTemp" className="inline left-side">
          <TextField id="maxStorageTemp" name="maxStorageTemp" className="short-textfield" normalize={Normalize.number(true,UnitHelper.getMinValueForUnitType(unitTypes.TEMPERATURE, 0), UnitHelper.getMaxValueForUnitType(unitTypes.TEMPERATURE, 0))}/>
        </InputSection>

        <InputSection label={this.labels.get("project")} htmlFor="project" className="inline right-side">
          <Combobox id="project" name="project" options={sData.get("projects")} />
        </InputSection>

        <InputSection label={this.labels.get("inspectionStatus")} htmlFor="inspectionStatus" className="inline left-side">
          <Combobox id="inspectionStatus" name="inspectionStatus" options={sData.get("inspectionStatuses")} />
        </InputSection>
        <div>
          {this.renderAdditionalFields()}
        </div>
      </div>

    );
  }
}

SpoolAttributes.propTypes = {
  sData: PropTypes.object.isRequired,
};



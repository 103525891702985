import React from 'react';
import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { navigationStates } from '../../../../enums/navigationStates';
import { filterTypes } from 'infrastructure/js/enums/filterTypes.js';
import { gridsNames } from '../../../../enums/gridsNames';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';

require('./pickListsGrid.scss');

class PickListsGrid extends React.PureComponent {

  filterConfig = [
    { fieldName: 'businessId', filterName: 'businessId', getOptions: false },
    { fieldName: 'status', filterName: 'status', getOptions: true },
    { fieldName: 'destination.name', filterName: 'destination', getOptions: true },
    { fieldName: 'dueDate.epochDateTime', filterName: 'dueDate', getOptions: false },
    { fieldName: 'createdDate.epochDateTime', filterName: 'createdDate', getOptions: false },
    { fieldName: 'createdByUser', filterName: 'createdByUser', getOptions: true },
  ]

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.pickDateRangeEnabled = PermissionManager.getOrgPreferences().smartSelectionPickDatesRangeEnabled;

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.pickListId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.PICK_LIST_BUSINESS_ID,
          // filter: BO_EntityFilters.ON_LOCATION,
          filter: [],
          action: this.props.actions.fetchEntities
        },
        width: 200,
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              type: navigationStates.PICK_LIST,
              label: params.data.businessId
            };
          }
        }
      },
      {
        fieldName: 'status',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'status',
        columnOptions: {
          // sortable: false,
          cellComponent: LabelWithTooltipCell,
          // sort: 'asc'
        }
      },

      {
        fieldName: 'destination.name',
        title: this.labels.get('columns.destination.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'destination',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.destination?.id,
              type: navigationStates.LOCATION,
              label: params.data.destination?.name
            };
          }
        }
      },
      {
        fieldName: 'dueDate.epochDateTime',
        title: this.labels.get('columns.dueDate.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'dueDate',
        columnOptions: {
            cellComponent: LabelWithTooltipCell,
            valueGetter: (params) => {
              return params.data.dueDate ? DateTimeHelper.FormatDateObjectToDayMonth(params.data.dueDate) : '';
            },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'createdDate.epochDateTime',
        title: this.labels.get('columns.creationDate.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'createdDate',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.createdDate ? DateTimeHelper.FormatDateObjectToDayMonth(params.data.createdDate) : '';
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'createdByUser',
        title: this.labels.get('columns.createdBy.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'createdByUser',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },

    ]
  }

  render() {
    return (
      <div className="pick-lists-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_PICK_LISTS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'pickListsGrid'
  }
)(PickListsGrid);



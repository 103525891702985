import { actionTypes as onHandInventoryHeaderActionTypes } from '../../../actions/OnHandInventory/onHandInventoryHeaderActions.js';
import {Map} from 'immutable';

let defaultState = {
  loading: false,
  locationOptions : [],

  selectedLocations : [],
  selectedAlertStatuses : [],
  selectedAlertTypes : [],
};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case onHandInventoryHeaderActionTypes.PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_FETCH_DATA_STARTED:
      return state.set('loading', true);
    case onHandInventoryHeaderActionTypes.PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_FETCH_DATA_FINISHED:
      state = state.set('locationOptions', action.payload.locationOptions);
      state = state.set('loading', false);
      return state;
    case onHandInventoryHeaderActionTypes.PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_UPDATE_HEADER_DATA:
      state = state.set('selectedLocations', action.payload.locations);
      state = state.set('selectedAlertStatuses', action.payload.alertStatuses);
      state = state.set('selectedAlertTypes', action.payload.alertTypes);
      return state;
    case onHandInventoryHeaderActionTypes.PREDEFINED_LIST_ON_HAND_INVENTORY_HEADER_CLEAR_HEADER_DATA:
      return Map(defaultState);
    default:
      return state;
  }
}

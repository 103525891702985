import Network from 'infrastructure/js/modules/network';


export function fetchPdfLabelPropertiesOptions(data) {
  return Network.post('pdfSettings/properties/options', data);
}

export function fetchPdfLabelSettings(data) {
  return Network.post('pdfSettings/settings', data);
}

export function setPdfLabelSettings(data) {
  return Network.post('pdfSettings/settings/save', data);
}

export function preview(data) {
  return Network.post('download/preview', data, {blobResponse : true});
}

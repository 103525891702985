import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as pickListPageActions} from '../../actions/PickListPage/pickListPageActions';
import {jsxActions as pickListGridActions} from '../../actions/PickListPage/pickListGridActions';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

import PickListPage from '../../components/PickListPage/pickListPage';
import {jsxActions as moveAssetsActions} from "../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions";
import {jsxActions as fetchEntitiesActions} from '../../actions/FetchEntities/fetchEntitiesActions';
import { jsxActions as pickListPageEditorActions } from '../../actions/PickListPage/pickListPageEditorActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(pickListPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      exportActions: bindActionCreators(exportActions, dispatch),
      pickListGridActions: bindActionCreators(pickListGridActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      pickListPageEditorActions:      bindActionCreators(pickListPageEditorActions, dispatch),
    }
  }
}

let PickListPageContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.PICK_LIST_PAGE) ? state.grid.get(gridsNames.PICK_LIST_PAGE) : null;
    return {
      pickList:  state.pickListPage.getIn(['data', 'pickList']),
      sPickListEditorData:     state.pickListPage.getIn(['data', 'pickListEditorData']),
      loading:     state.pickListPage.get('loading'),
      // loading:     true,
      sPickListGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sMoveAssetsData:  state.dialogs.get('moveAssetsDialog'),
    };
  },
  mapDispatchToProps
)(PickListPage);


export default PickListPageContainer;




import importFileService from '../../../services/ImportFiles/importFilesService';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from "../../MessageDialog/messageDialogActions";

export const actionTypes = {
  EDIT_ATTACHMENT_SHOW: "EDIT_ATTACHMENT_SHOW",
  EDIT_ATTACHMENT_HIDE: "EDIT_ATTACHMENT_HIDE",
  EDIT_ATTACHMENT_IN_PROCESS: "EDIT_ATTACHMENT_IN_PROCESS",
  EDIT_ATTACHMENT_FINISHED: "EDIT_ATTACHMENT_FINISHED"
};

const actions = {
  show: function (payload) {
    return {type: actionTypes.EDIT_ATTACHMENT_SHOW, payload};
  },
  hide: function () {
    return {type: actionTypes.EDIT_ATTACHMENT_HIDE};
  },
  submitInProcess: function () {
    return {type: actionTypes.EDIT_ATTACHMENT_IN_PROCESS};
  },
  submitFinished: function () {
    return {type: actionTypes.EDIT_ATTACHMENT_FINISHED};
  }
};


export let jsxActions = {};

jsxActions.submit = function (id, category, reloadParentComponent) {
  return function (dispatch, getState) {
    dispatch(actions.submitInProcess());

    importFileService.editAttachment(id, category).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('edit attachment failed');
        messageDialogApi.responseError(dispatch, getState)(response);
      }
      if (reloadParentComponent) {
        reloadParentComponent()
      }
    });
  }
};

jsxActions.hide = function () {
  return function (dispatch, getState) {
    dispatch(actions.hide());
  }
};

export let api = {};

api.show = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.show(data));
  }
};


import { Map, List } from 'immutable';
import {dialogsNames} from '../../enums/dialogsNames';
import defaultDialogReducer from '../defaultDialogReducer';
import { actionTypes as actionTypes }  from '../../actions/LocationPage/CuttingView/createSubRollsDialogActions';


let defaultState = {
  show: false,
  loading: false,
  preselectedAssets: List(),
  dialogData: null,
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.CREATE_SUB_ROLLS_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ?
                        action.payload.preselectedAssets.map((item) => _convertRollDataToDialogItem(item)) : List())
                  .set('show', true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.CREATE_SUB_ROLLS_DIALOG, defaultState);
  }
}

//////////////////////////////////////////////////////////////////////////
function _convertRollDataToDialogItem(item) {
  return {
    id:         item.id,
    businessId: item.businessId,
    lengthLeft: item.lengthLeft,
    weight: item.weight,
    materialBusinessId:   item.material.businessId,
    materialName:   item.material.materialName,
    projectId:  item.project ? item.project.id : null,
  };
}







import moment from 'moment';
import MomentDuration from 'moment-duration-format';
import { IsJsonString } from 'infrastructure/js/utils/JSON.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import 'moment/locale/de';
import 'moment/locale/zh-cn';

let loggedInUser = localStorage['loggedInUser'];
if (!(loggedInUser = IsJsonString(loggedInUser))) {
  loggedInUser = undefined;
}

const dayNameToNumber = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

let dateTimeInstance = (function () {
  let obj = {};

  let _locale = 'en';
  let _dateFormat = 'DD/MM/YYYY';
  let _timeFormat = 'HH:mm';
  let _hourFormat = 'H:mm';
  let _minutesFormat = 'mm:ss';
  let _systemTimeZone = null;
  let _firstDayOfWeek = 0;
  let _dateTimeFormat = _dateFormat + ' ' + _timeFormat;
  let _userOrgLocale = 'en';

  obj.init = function (preferences, userOrgPreferences) {
    _locale = preferences.systemLocale;
    moment.locale(_locale);
    _dateFormat = preferences.systemDateFormat;
    _systemTimeZone = preferences.systemTimeZone;
    _timeFormat = preferences.systemTimeFormat;
    _dateTimeFormat = _dateFormat + ' ' + _timeFormat;
    _firstDayOfWeek = moment.localeData().firstDayOfWeek();
    _userOrgLocale = userOrgPreferences.systemLocale;
  };

  if (loggedInUser) {
    obj.init(loggedInUser.loggedInOrg.orgPreferences, loggedInUser.userOrg.orgPreferences);
  }

  obj.getLocale = function () {
    return _locale;
  };
  obj.getDateFormat = function () {
    return _dateFormat;
  };
  obj.getDateTimeFormat = function () {
    return _dateTimeFormat;
  };
  obj.getTimeFormat = function () {
    return _timeFormat;
  };
  obj.getUserOrgLocale = function () {
    return _userOrgLocale;
  };

  // Format "2017-03-01T13:52:14" => 01/03/2017
  obj.FormatDateObjectToDayMonth = function (dateObject) {
    if (!dateObject || (!dateObject.epochDateTime && 0 !== dateObject.epochDateTime)) {
      return '';
    }
    let date = moment.utc(1000 * dateObject.epochDateTime);

    return obj.FormatDateToDayMonth(date);
  };

  // Format "2017-03-01T13:52:14" => 01/03/2017
  obj.FormatDateToDayMonth = function (date) {
    if (!date) {
      return '';
    }

    return date.format(_dateFormat);
  };

  obj.FormatDateObjectsToDayMonthRange = function (from, to, includeSpaces = true) {
    let unlimitedString = window.reactIntlGlobal.formatMessage({ id: 'timeFrame.unlimited', defaultMessage: 'xxx' });
    let separator = includeSpaces ? ' - ' : '-';
    return `${(from?.epochDateTime && obj.FormatDateObjectToDayMonth(from)) || unlimitedString}${separator}${
      (to?.epochDateTime && obj.FormatDateObjectToDayMonth(to)) || unlimitedString
    }`;
  };

  // Format 100 minutes => 1:40 Hours
  obj.ConvertMinutesToHoursMinutes = function (minutes) {
    if (minutes == null || minutes == undefined) {
      return 'N/A';
    }

    return createLabelHelper('measurements.').get('timeinhours', undefined, {
      time: moment.duration(minutes, 'minutes').format(_hourFormat, { trim: false }),
    });
  };

  obj.ConvertSecondsToMinutesSeconds = function (seconds) {
    if (seconds == null || seconds == undefined) {
      return 'N/A';
    }
    return createLabelHelper('measurements.').get('timeinMinutes', undefined, {
      time: moment.duration(seconds, 'seconds').format(_minutesFormat, { trim: false }),
    });
  };

  obj.ConvertMinutesToDaysHoursMinutes = function (minutes) {
    if (minutes == null || minutes == undefined) {
      return '';
    }

    return createLabelHelper('measurements.').get('timeInDays', undefined, {
      time: moment.duration(minutes, 'minutes').format('dd:HH:mm', { trim: false }),
    });
  };

  obj.ConvertMinutesToDaysHoursMinutesObject = function (minutes) {
    const timeObj = {
      days: 0,
      hours: 0,
      minutes: 0,
    };

    if (minutes == null || minutes == undefined) {
      return timeObj;
    }

    const duration = moment.duration(minutes, 'minutes');

    timeObj.days = Math.floor(duration.asDays());
    timeObj.hours = duration.hours();
    timeObj.minutes = duration.minutes();

    return timeObj;
  };

  // Format 100 minutes => 1:40 H
  obj.ConvertMinutesToHoursMinutesShort = function (minutes) {
    if (minutes == null || minutes == undefined) {
      return 'N/A';
    }

    return createLabelHelper('measurements.').get('timeinhoursshort', undefined, {
      time: moment.duration(minutes, 'minutes').format(_hourFormat, { trim: false }),
    });
  };

  obj.ConvertMinutesToHoursMinutesObject = (minutes) => {
    let res = {
      hours: 0,
      minutes: 0,
    };

    if (!minutes)
      //includes minutes === 0
      return res;

    if (typeof minutes !== 'number') {
      console.error('DateTimeHelper.ConvertMinutesToHoursMinutesObject must be given a number.');
    }

    res.hours = Math.floor(minutes / 60);
    res.minutes = Math.floor(minutes % 60);
    return res;
  };

  // current day (a day is from 00:00 to 23:59) will only have the time of activity.
  // before that day will have the number of days that passecread : “X days ago”
  // more than a week ago a time and a date will be presented: “dd/mm/yyyy”
  obj.FormatDateToShortRelativeTime = function (unixDate, unixServerTime) {
    return obj.FormatDateToRelativeTime(unixDate, unixServerTime, true);
  };

  // for date object: { epochDateTime: number }
  obj.FormatDateObjectToRelativeTime = function (dateObject, unixServerTime, isShort = false) {
    if (!dateObject) {
      return '';
    }

    return obj.FormatDateToRelativeTime(dateObject.epochDateTime, unixServerTime, isShort);
  };

  // current day (a day is from 00:00 to 23:59) will only have the time of activity.
  // before that day will have the time + the number of days that passed : “X days ago | hh:mm”
  // more than a week ago a time and a date will be presented: “dd/mm/yyyy | hh:mm”
  obj.FormatDateToRelativeTime = function (unixDate, unixServerTime, isShort = false) {
    if ((!unixDate && 0 !== unixDate) || (!unixServerTime && 0 !== unixServerTime)) {
      return '';
    }

    /*var serverDate = Moment.utc(1000 * unixServerTime);
    let date = Moment.utc(1000 * unixDate);
    if (!date || !serverDate) {
      return "";
    }

    return date.from(serverDate);*/
    var startOfServerDate = moment.utc(1000 * unixServerTime).startOf('day');
    let startOfDate = moment.utc(1000 * unixDate).startOf('day');
    if (!startOfDate || !startOfServerDate) {
      return '';
    }

    let hour = moment.utc(1000 * unixDate).format(_timeFormat);
    if (startOfDate.isSame(startOfServerDate, 'day')) {
      return hour;
    }

    var duration = moment.duration(startOfServerDate.diff(startOfDate));
    /*var difInDays = duration.asDays();

    if (difInDays > 0 && difInDays < 7) {
      return difInDays + ' days ago' + (isShort ? "" : (" | " + hour));
    }*/

    let dateFormat = isShort ? _dateFormat : _dateTimeFormat;
    return moment.utc(1000 * unixDate).format(dateFormat);
  };

  // get diff from two dates
  obj.GetDifferenceInDays = function (dateObject, serverTimeSeconds) {
    if (!dateObject || !serverTimeSeconds) {
      return '';
    }

    var serverDate = moment.utc(1000 * serverTimeSeconds).startOf('day');
    let date = moment.utc(1000 * dateObject.epochDateTime).startOf('day');
    if (!date || !serverDate) {
      return '';
    }

    var duration = moment.duration(date.diff(serverDate));
    var difInDays = duration.asDays();

    return difInDays;
  };

  //todo: fix to lowercase here and in all calls - linter thinks this is a Component.
  obj.ConvertToDate = function (dateObject) {
    if (!dateObject || (!dateObject.epochDateTime && 0 !== dateObject.epochDateTime)) {
      return null;
    }
    return moment.utc(1000 * dateObject.epochDateTime);
  };

  //todo: fix to lowercase here and in all calls - linter thinks this is a Component.
  obj.ConvertFromDate = function (date) {
    if (!date) {
      return null;
    }

    //check if date is not a moment object
    if (!moment.isMoment(date)) {
      const momentDate = moment(date);
      if (momentDate.isValid()) {
        date = momentDate.utcOffset(0, true);
      } else {
        return null;
      }
    }

    return { epochDateTime: date.unix(), systemTimeZone: _systemTimeZone };
  };

  obj.DateTimeFormat = function (date) {
    if (!date) {
      return '';
    }

    return moment.utc(date).format(_dateTimeFormat);
  };

  obj.DateFormat = function (date) {
    if (!date) {
      return '';
    }

    return moment.utc(date).format(_dateFormat);
  };

  /**
   * @name TimeFormat
   * @description Format a date object into a string in the system time format.
   *
   * @param {Date} date The date object to format.
   * @returns {string} The formatted date string.
   *
   * @example
   * const formattedDate = TimeFormat(new Date());
   * console.log(formattedDate); // "15:30"
   */
  obj.TimeFormat = function (date) {
    if (!date) {
      return '';
    }

    return moment(date).format(_timeFormat);
  };

  obj.ConvertDaysToMinutes = function (days) {
    return isNaN(days) ? 0 : days * 24 * 60;
  };

  obj.ConvertDaysHoursMinutesToMinutes = function (days, hours, minutes) {
    let daysValue = parseFloat(days);
    let hoursValue = parseFloat(hours);
    let minutesValue = parseFloat(minutes);

    if (isNaN(daysValue) && isNaN(hoursValue) && isNaN(minutesValue)) {
      return null;
    }

    if (isNaN(daysValue)) {
      daysValue = 0;
    }
    if (isNaN(hoursValue)) {
      hoursValue = 0;
    }
    if (isNaN(minutesValue)) {
      minutesValue = 0;
    }

    return daysValue * 1440 + hoursValue * 60 + minutesValue;
  };

  obj.ConvertHoursMinutesToMinutes = function (hours, minutes) {
    let ETLhoursValue = parseFloat(hours);
    let ETLminutesValue = parseFloat(minutes);

    if (isNaN(ETLhoursValue) && isNaN(ETLminutesValue)) {
      return null;
    }

    if (isNaN(ETLhoursValue)) {
      ETLhoursValue = 0;
    }
    if (isNaN(ETLminutesValue)) {
      ETLminutesValue = 0;
    }

    return ETLhoursValue * 60 + ETLminutesValue;
  };

  obj.ConvertDaysToRelativeTime = function (days) {
    let now = moment();
    let withAddedDays = moment().add(days, 'days');
    return now.to(withAddedDays);
  };
  obj.getMonthsOptions = function () {
    this.monthLabels = createLabelHelper('period.month.');
    return [
      { label: this.monthLabels.get('january'), value: 1 },
      { label: this.monthLabels.get('february'), value: 2 },
      { label: this.monthLabels.get('march'), value: 3 },
      { label: this.monthLabels.get('april'), value: 4 },
      { label: this.monthLabels.get('may'), value: 5 },
      { label: this.monthLabels.get('june'), value: 6 },
      { label: this.monthLabels.get('july'), value: 7 },
      { label: this.monthLabels.get('august'), value: 8 },
      { label: this.monthLabels.get('september'), value: 9 },
      { label: this.monthLabels.get('october'), value: 10 },
      { label: this.monthLabels.get('november'), value: 11 },
      { label: this.monthLabels.get('december'), value: 12 },
    ];
  };

  /**
   * Get the days of the week as Combobox options.
   *
   * @returns {Array<{label: string, value: string}>} The days of the week options.
   */
  obj.getDaysOfWeekOptions = function () {
    this.dayLabels = createLabelHelper('period.day.');
    let daysOfWeek = [
      { label: this.dayLabels.get('sunday'), value: 'SUNDAY' },
      { label: this.dayLabels.get('monday'), value: 'MONDAY' },
      { label: this.dayLabels.get('tuesday'), value: 'TUESDAY' },
      { label: this.dayLabels.get('wednesday'), value: 'WEDNESDAY' },
      { label: this.dayLabels.get('thursday'), value: 'THURSDAY' },
      { label: this.dayLabels.get('friday'), value: 'FRIDAY' },
      { label: this.dayLabels.get('saturday'), value: 'SATURDAY' },
    ];

    let futureDays = daysOfWeek.slice(0, _firstDayOfWeek);
    let pastDays = daysOfWeek.slice(_firstDayOfWeek);
    return futureDays.concat(pastDays);
  };

  /**
   * Get the first day of the week.
   *
   * @returns {Date} The first day of the week as JS Date.
   */
  obj.getFirstDayOfTheWeek = function () {
    return moment().startOf('week').toDate();
  };

  /**
   * This method returns the JS date object that represent the day that was passed into it in the current week.
   *
   * @param {string|number} dayNameOrIndex - the name/index of the day that we wish to get.
   * @param {Date} minDate - (optional) send this if you want the return date to be before the passed date.
   * @return {Date} the calculated js Date Object.
   * @example
   * getDateByWeekDay('SUNDAY')
   * getDateByWeekDay(0)
   */
  obj.getDateByWeekDay = function (dayNameOrIndex, minDate) {
    const day = typeof dayNameOrIndex === 'string' ? dayNameToNumber[dayNameOrIndex] : dayNameOrIndex;
    const calculatedDate = moment().weekday(day);
    if (minDate) {
      const minimumDate = moment(minDate);
      if (minimumDate.isBefore(calculatedDate)) {
        calculatedDate.subtract(1, 'weeks');
      }
    }
    return calculatedDate.toDate();
  };

  return obj;
})();
export default dateTimeInstance;

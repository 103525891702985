import React, { Component } from 'react';
import {PieChart, Pie, Cell, Label} from 'recharts';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import AlertPopover, {AlertPopoverType} from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
require('./alertedRollsChart.scss');

export default class AlertedRollsChart extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.dashboard.inventory.inventoryManagement.');
  }

  getExpiredChartData = (percentageValue) => {
    return [{ name: '', value: (100 - percentageValue) }, { name: 'percentageValue', value: +percentageValue }];
  };

  getExposedChartData = (percentageValue) => {
    return [{ name: '', value: (100 - percentageValue) }, { name: 'percentageValue', value: +percentageValue }];
  };

  getIconPopOver = (tooltip)=> {
    return (<AlertPopover popoverType={AlertPopoverType.HINT}
                          popoverComponent={this.getPopoverComponent(tooltip)}
    />);
  };

  getPopoverComponent(tooltip) {
    return <div>{tooltip}</div>;
  }

  renderChart = (title, chartData, footer, percentageValue, tooltip) => {

    const COLORS = ['#A9A9B0', '#E1151F'];
    return (
      <div className={'chart-section'}>
        <div className='chart-title'>
          {title}
          {this.getIconPopOver(tooltip)}
        </div>
        <PieChart width={300} height={200}>
          <Pie
            dataKey="value"
            data={chartData}
            cx={150}
            cy={70}
            innerRadius={50}
            outerRadius={60}
            fill="#8884d8"
            paddingAngle={2}>
            <Label  value={`${percentageValue}%`} position="center" fontSize='27px' />
            {
              chartData.map((entry, index) => <Cell key={index} fill={COLORS[index]}/>)
            }
          </Pie>
        </PieChart>
        <div className='chart-footer'>{footer}</div>
      </div>
    );
  };

  getFooter = (rolLength, totalLength) => {
    let meterOrFeetLabel = UnitHelper.getLabelForUnitType(unitTypes.LENGTH);
    return `${UnitHelper.serverValueToUserValue(unitTypes.LENGTH, rolLength, 2)} ${meterOrFeetLabel}
     ${this.labels.get('outOf.title')} ${UnitHelper.serverValueToUserValue(unitTypes.LENGTH, totalLength, 2)} ${meterOrFeetLabel}`;
  };

  render() {
    let chartData = this.props.chartData;
    if(!chartData){
      return null;
    }
    let percentageLengthExpiredRolls = (Math.round(chartData.percentageLengthExpiredRolls *100) /100).toFixed(2);
    let percentageLengthExposedRolls = (Math.round(chartData.percentageLengthExposedRolls *100) /100).toFixed(2);
    if(!chartData.percentageLengthExpiredRolls){
      percentageLengthExpiredRolls = 0;
    }
    if(!chartData.percentageLengthExposedRolls){
      percentageLengthExposedRolls = 0;
    }
    let expiredData = this.getExpiredChartData(percentageLengthExpiredRolls);
    let exposedData = this.getExposedChartData(percentageLengthExposedRolls);
    let expiredFooter = this.getFooter(chartData.totalLengthExpiredRolls,chartData.totalLength );
    let exposedFooter = this.getFooter(chartData.totalLengthExposedRolls,chartData.totalLength );

    return (
        <div className="rolls-status-chart">
          {this.renderChart(this.labels.get('expiredRollsChart.title'), expiredData, expiredFooter, percentageLengthExpiredRolls, this.labels.get('expiredRollsChart.tooltip')  )}
          <div className="separator"/>
          {this.renderChart(this.labels.get('overExposedRollsChart.title'), exposedData, exposedFooter, percentageLengthExposedRolls, this.labels.get('overExposedRollsChart.tooltip') )}
        </div>
    )
  }
};

import React, {Component} from 'react';

import cn from 'classnames';
import {createLabelHelper} from "infrastructure/js/utils/labelHelper";
import PropTypes from "prop-types";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'
import {Field} from 'redux-form';

require('./phoneField.scss');

export default class PL_PhoneFieldForm extends React.PureComponent {
  render() {
    return (
      <Field {...this.props} component={PL_PhoneField}></Field>
    );
  }
};

export class PL_PhoneField extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper("mat.controls.phonefield.");
  }


  onChange = (data) => {
    this.props.input.onChange('+' + data);
  };

  render() {
    let { className, placeholder, disabled} = this.props;
    return (
      <div id={this.props.id} className={cn('PL-phoneField', className, {'disabled': disabled})}>
        <PhoneInput
          country={'us'}
          enableAreaCodes={true}
          countryCodeEditable={true}
          enableSearch={true}
          disableSearchIcon={true}
          value={this.props.input.value ? this.props.input.value : null}
          className="phone-field-number"
          placeholder={placeholder ? placeholder : this.labels.get('placeholder')}
          disabled={disabled}
          onChange={this.onChange}
        />
      </div>)
  }
}

PL_PhoneFieldForm.defaultProps = {};

PL_PhoneFieldForm.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

import {fetchGoals, runSimulation} from '../../services/Scheduler/plannerService';
import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';

// /////////////////////////////////////////
// // ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
    SCHEDULER_ANALYTIC_MODE_FETCH_DATA_FINISHED: 'SCHEDULER_ANALYTIC_MODE_FETCH_DATA_FINISHED',
    SCHEDULER_ANALYTIC_MODE_LOADING: 'SCHEDULER_ANALYTIC_MODE_LOADING',
};

// ////////////////////////////////////////////////////////////////
// // PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
    fetchSchedulerAnalyticModeDataFinished: function (payload) {
        return { type: actionTypes.SCHEDULER_ANALYTIC_MODE_FETCH_DATA_FINISHED, payload: payload };
    },
    setLoading: function (payload) {
        return { type: actionTypes.SCHEDULER_ANALYTIC_MODE_LOADING, payload: payload };
    },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

export let jsxActions = {};

jsxActions.init = function () {
    return function (dispatch, getState) {
        api.fetchData(dispatch, getState)();
    };
};

jsxActions.runSimulation = function(goals) {
  return function(dispatch, getState) {
    api.runSimulation(dispatch, getState)(goals);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchData = function (dispatch, getState) {
    return function () {
        dispatch(actions.setLoading(true));

        return fetchGoals()
            .then((response) => {
                dispatch(actions.setLoading(false));

                if (!Network.isResponseValid(response)) {
                    console.error('Failed to get analytic mode initial data', response);
                    messageDialogApi.responseError(dispatch, getState)(response);
                    return { success: false };
                }

                const schedulingGoals = response?.data?.schedulingGoals?.map((goal) => ({
                    label: goal.name,
                    value: goal.id,
                    data: goal,
                }));

                dispatch(actions.fetchSchedulerAnalyticModeDataFinished(schedulingGoals));
            })
            .catch((err) => {
                console.error('Fetch Scheduler analytic mode data failed ', err);
                messageDialogApi.responseError(dispatch, getState)();
                return { success: false };
            });
    };
};

api.runSimulation = function (dispatch, getState){
  return function(goals) {
    dispatch(actions.setLoading(true));

    return runSimulation({goals})
      .then((response) => {
        dispatch(actions.setLoading(false));

        if (!Network.isResponseValid(response)) {
          console.error('Failed to run simulation: ', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return { success: false };
        }

        const {statusType, message} = response.applicationResponseStatus
        let messageDialogDescriptor = {title: message, className: 'oneBackground', type: statusType.toLowerCase()};
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

      })
      .catch((err) => {
        console.error('Failed to run simulation ', err);
        messageDialogApi.responseError(dispatch, getState)();
        return { success: false };
      });
  };
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as assetsViewActions }        from '../../../actions/LocationPage/AssetsView/assetsViewActions';
import { jsxActions as headerActions} from '../../../actions/Header/headerActions';
import { jsxActions as importFilesWizardActions }      from '../../../actions/Wizards/ImportFiles/importFilesWizardActions.js';
import { jsxActions as fetchEntitiesActions }      from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as locationPageActions }      from '../../../actions/LocationPage/locationPageActions';
import { jsxActions as importAssetsViaBarcodeWizardActions } from '../../../actions/Wizards/ImportAssetsViaBarcodeWizard/importAssetsViaBarcodeWizardActions.js';
import AssetsView from '../../../components/LocationPage/AssetsView/assetsView';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(assetsViewActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      headerActions:        bindActionCreators(headerActions, dispatch),
      importFilesWizardActions:      bindActionCreators(importFilesWizardActions, dispatch),
      locationPageActions:       bindActionCreators(locationPageActions, dispatch),
      importAssetsViaBarcodeWizardActions: bindActionCreators(importAssetsViaBarcodeWizardActions, dispatch),
    }
  }
}


let AssetsViewContainer = connect(
  (state) => {
    return {
      sData:                  state.locationPage.get('assetsViewData'),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),
      sImportAssetsViaBarcodeWizardData: state.wizards.get('importAssetsViaBarcodeWizard'),
    };
  },
  mapDispatchToProps
)(AssetsView);

export default AssetsViewContainer;





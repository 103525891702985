import {List, Map} from 'immutable';
import { actionTypes as CreateWorkOrderDialogActionTypes }  from '../../../actions/Dialogs/CreateWorkOrderDialog/createWorkOrderDialogActions';


let defaultState = {
  show: false,
  loading: false,
  dialogData:{},
  partsOptions:[],
  projectOptions:[],
  selectedProject:null,
  selectedPart:null,

 /* dialogData : Map({
    partsOptions: [],
  }),*/
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case CreateWorkOrderDialogActionTypes.CREATE_WORK_ORDER_DIALOG_SHOW:
      return state.set('show',true);

    case CreateWorkOrderDialogActionTypes.CREATE_WORK_ORDER_DIALOG_HIDE:
      // return state.set('show',false);
      return Map(defaultState);

    case CreateWorkOrderDialogActionTypes.CREATE_WORK_ORDER_FETCH_INITIAL_DATA_READY:
      state = state.set('dialogData', action.payload);
      state = state.set('partsOptions', action.payload.partsOptions);
      state = state.set('projectOptions', action.payload.projectOptions);
      return state;

    case CreateWorkOrderDialogActionTypes.CREATE_WORK_ORDER_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);

    case CreateWorkOrderDialogActionTypes.CREATE_WORK_ORDER_SUBMIT_FINISHED:
      return state.set("loading", false);

    case CreateWorkOrderDialogActionTypes.CREATE_WORK_ORDER_FETCH_PARTS_READY:
      state = state.set('partsOptions', action.payload.partsOptions);
      state = state.set('selectedPart', action.payload.selectedPart);
      //state = state.setIn(['dialogData', 'partsOptions'],[]);

     return state;
    case CreateWorkOrderDialogActionTypes.CREATE_WORK_ORDER_FETCH_PROJECTS_READY:
      state = state.set('projectOptions', action.payload.projectOptions);
      state = state.set('selectedProject', action.payload.selectedProject);
      return state;


    default:
      return state;
  }

}

/********************************************************/
// Helper functions.








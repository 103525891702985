import React from 'react';

import CommonAlertsView from '../Components/CommonAlertsView/commonAlertsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./exposureTimeBondView.scss');

export default class ExposureTimeBondView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.exposuretime.bond.view.');
  }

  componentDidMount() {
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_EXPOSURE_TIME_BOND);

    this.props.actions.load(getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'));
  }

  render() {
    return (
      <div className="exposure-time-view">
        <CommonAlertsView labels={this.labels}
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          alertType={getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND')}
        />
      </div>
    );
  }
}


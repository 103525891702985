import React from 'react';
import PropTypes from 'prop-types';
import Normalize from '../../controls/controlsNormalizations';
import {PL_TextField} from 'infrastructure/js/components/controls/TextField/textField';
import cn from 'classnames';

require('./numericEditableMultiCell.scss');

export default class NumericEditableMultiCell extends React.PureComponent {

  constructor(props) {
    super(props);
    this.inputRefs = this.props.value.dataList.map(() => React.createRef());
    this.state = {values: this.props.value?.dataList?.map((item) => {return item.value || ''}),
                  lastActiveIndex: -1,
    }
  }

  // grid api function
  afterGuiAttached(){
    const clickedCellIndex = this.props?.context?.clickedCellIndex
      if(clickedCellIndex >= 0 && this.inputRefs[clickedCellIndex]){
        this.inputRefs[clickedCellIndex].focus()
      }
  }

// grid api function
  getValue() {
    return this.state;
  }

  onBlur = () => {
    //grids api
    this.props.shouldStopEditingOnBlur && this.props.stopEditing();
  };

  onChange = (value, index) => {
      value = value?.trim?.();
      value =  Normalize.number(this.props.isInt, 0, this.props.maxValue)(value, this.state.values[index]);
      if (this.state.values[index] === null && value === null) {
        value = '';
      }
    // }

    let valuesCopy = [...this.state.values];
    valuesCopy[index] = value;
    this.setState({values: valuesCopy, lastActiveIndex: index})
  };

  render() {
    if (this.props.value.dataList) {
      return (<div className="numeric-multi-cell-grid-editor">
        {this.props.value.dataList.map((item, index) => {
          return (<div className={cn('editable-cell', this.props.isMulti ? 'multi-row-cell' : '')} key={index}>

              <PL_TextField id={this.props.column.colId + this.props.rowIndex + 'index' + index}
                         name={this.props.column.colId + this.props.rowIndex + 'index' + index}
                         className="inline"
                         onChangeCallback={(value) => this.onChange(value, index)}
                         onBlur={this.onBlur}
                         value={this.state.values[index]}
                         passive={true}
                         inputRef={ref => this.inputRefs[index] = ref}
              />
            </div>
          )
        })}
      </div>);
    }
  }
}

NumericEditableMultiCell.defaultProps = {
  isInt: false,
  shouldStopEditingOnBlur: true
};

NumericEditableMultiCell.propTypes = {
  maxValue: PropTypes.number,
  isInt:PropTypes.bool
};

import React from 'react';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import MultiEntitiesMultiSelect from '../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import ComboBox from 'infrastructure/js/components/controls/Combobox/combobox';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import  * as dialogHelper  from "infrastructure/js/components/Dialog/dialogHelper";
import {navigationStates} from '../../../enums/navigationStates';
import * as Link from '../../Common/Layout/Link/link';

require('./moveAssetsDialog.scss');

class MoveAssetsDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.dialogLabels = createLabelHelper('mat.dialog.');
        this.labels = createLabelHelper('mat.locationpage.view.assets.moveassetsdialog.');

        this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();

        this.fetchConfig = {
            action: this.props.actions.fetchEntities,
            filter: this.props.sData.get('boEntityFilter'),
        };
    }

    getDialogButtons() {
        let cancelTokenEnabled = !!this.props.sData.get('cancelToken');
        return {
            left: [
                {
                    id: 'cancel',
                    text: this.props.sData.get('loading')
                        ? this.dialogLabels.get('stop')
                        : this.dialogLabels.get('cancel'),
                    action: this.props.sData.get('loading') ? this.onStop : this.onHide,
                    enabledOnLoading: cancelTokenEnabled,
                },
            ],
            right: [
                {
                    id: 'submit',
                    text: this.labels.get('footer.move'),
                    bsStyle: 'primary',
                    loading: this.props.sData.get('loading'),
                    action: this.props.handleSubmit(this.onSubmit),
                    disabled:
                        this.props.sData.get('loading') ||
                        this.props.sData.get('hasError') ||
                        (this.props.sData.get('showIgnoreValidationCheckbox') &&
                            !this.props.sData.get('isIgnoreValidationWarnings')),
                },
            ],
        };
    }

    onSubmit = (data) => {
        let allRows = data['entities-multi-select-ex'];
        let assetsIds = [];
        allRows.map((row) => {
            if (row && row.assetsValue) {
                row.assetsValue.map((asset) => {
                    assetsIds.push(asset.value);
                });
            }
        });
        let newData = {
            assetIds: assetsIds,
            destination: data.destination.value,
            ignoreValidationWarnings:
                this.props.sData.get('showIgnoreValidationCheckbox') &&
                data.footerValidationCheckbox
                    ? data.footerValidationCheckbox
                    : false,
        };

        this.props.actions.submit(
            newData,
            this.getMessageDialogBuilder(data.destination),
            this.props.reloadParentComponent
        );
        // this.props.actions.submitWithProgress(newData, this.getMessageDialogBuilder(data.destination), this.props.reloadParentComponent);
    };

    getMessageDialogBuilder = (destination) => {
        return (response, fnCloseDialog) => {
            let linkLocationData = {
                placeholder: '{LINK_PLACEHOLDER}',
                data: {
                    name: destination.data.name,
                    id: destination.data.id,
                    type: destination.data.isStation
                        ? navigationStates.STATION
                        : navigationStates.LOCATION,
                    className: 'move-assets-confirmation-link',
                },
                action: this.props.actions.closeMessageDialog,
            };

            let message = this.labels.get('confirmation.message.title', '', {
                count: response.totalJobItems,
                location: linkLocationData.placeholder,
            });
            let title = Link.convertPlaceholderToLinkComponent(message, linkLocationData);
            return dialogHelper.BuildDialogDescriptorForBO(
                response,
                fnCloseDialog,
                title,
                destination
            );
        };
    };

    getDestinations = () => {
        return this.props.sData.get('destinations');
    };

    onEntered = () => {
        this.props.actions.fetchLocations({ isLocationBound: this.props.isLocationBound });
    };

    onHide = () => {
        this.props.actions.hide();
    };

    onStop = () => {
        this.props.actions.stop();
    };

    getFooterValidationCheckBox = () => {
        return this.props.sData.get('showIgnoreValidationCheckbox')
            ? {
                  label: this.dialogLabels.get('ignorewarnings'),
                  onChange: this.onFooterValidationChange,
              }
            : null;
    };

    onFooterValidationChange = (value) => {
        this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
    };

    handleEnterKeyPress = e => {
      if(e.key === 'Enter') {
        this.props.handleSubmit(this.onSubmit)();
      }
    }

    render() {
        let preselectedAssets = this.props.sData.get('preselectedAssets');

        return (
            <Dialog
                id='move-asset-dialog'
                className='move-asset-dialog'
                show={this.props.sData.get('show') || false}
                onEntered={this.onEntered}
                onHide={this.onHide}
                sData={this.props.sData}
                titleText={this.labels.get('header.title')}
                footerValidationCheckbox={this.getFooterValidationCheckBox()}
                footerInformationIcon={dialogHelper.getFooterInformationIcon(
                    this.props.sData,
                    this.dialogLabels
                )}
                footerButtons={this.getDialogButtons()}
                onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
            >
                <div className='select-assets'>
                    <InputSection
                        label={this.labels.get('selectassets.text')}
                        className='no-margin'
                    />

                    <MultiEntitiesMultiSelect
                        name='entities-multi-select-ex'
                        entitiesTypes={this.entitiesTypes}
                        validate={Validation.required}
                        preSelectedEntities={preselectedAssets}
                        fetchConfig={this.fetchConfig}
                        autoFocus={true}
                        {...this.props}
                    />
                </div>

                <div className='select-destination'>
                    <InputSection
                        label={this.labels.get('destination') + '*'}
                        htmlFor='destination'
                    >
                        <ComboBox
                            id='move-to-destination-combobox'
                            name='destination'
                            options={this.getDestinations()}
                            validate={Validation.required}
                            placeholder={this.labels.get('destination.inputclue')}
                        />
                    </InputSection>
                </div>
            </Dialog>
        );
    }
}

export default reduxForm({
    form: 'moveAssetsDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(MoveAssetsDialog);


import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

export function NormalizeResponseError(responseError) {

  let labelHelper = createLabelHelper('mat.wizards.import.files.errors.');
  let message;
  let statusType;
  let errorCode;
  if(!responseError?.applicationResponseStatus?.errors?.mainError?.errorKey){
    message = labelHelper.get('failedreadfile');
    statusType = labelHelper.get('errorstatus');
    errorCode = 0;
  }
  else{
    message = createLabelHelper(responseError.applicationResponseStatus.errors.mainError.errorKey).get('', undefined, responseError.applicationResponseStatus.errors.mainError.params || undefined);
    statusType = responseError.applicationResponseStatus.statusType.toLowerCase();
    errorCode = responseError.applicationResponseStatus.errors.mainError.errorKey;
  }

  return {
    message,
    statusType:statusType,
    errorCode:errorCode
  };
};

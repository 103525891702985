import {connect} from 'react-redux';

import { fromJS } from 'immutable';

import { bindActionCreators } from 'redux';
import { jsxActions as wizardActions } from './wizardActions';



function buildMapDispatchToProps(wizardName) {

  // Construct wizard-specific jsxActions adapter.
  let namedWizardActions = wizardActions.createWizardSpecificAdapter(wizardName);

  return function (dispatch) {
    return {
      wizardActions:   bindActionCreators(namedWizardActions, dispatch)
    }
  };

}



import { PL_Wizard_Prerenderer } from './wizard';


export function buildWizardContainer(wizardName) {

  return connect(
    state => {
      return {
        sWizardData: state.wizard.get(wizardName) || fromJS({})
      };
    },
    buildMapDispatchToProps(wizardName)

  )(PL_Wizard_Prerenderer);

}











import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import CheckboxCell from './CheckboxCell/checkboxCell';
import CheckboxHeader from './CheckboxHeader/checkboxHeader';
import DefaultHeader from './DefaultHeader/defaultHeader.js';
import { List, Map } from 'immutable';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';

ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule, SetFilterModule, MenuModule, RowGroupingModule]);

import ErrorBoundary from '../../../../mat/js/components/ErrorBoundary/errorBoundary';
import 'node_modules/@ag-grid-community/styles/ag-grid.css';

import EditableCell from './EditableCell/editableCell';
import EditableMultiCell from './EditableMultiCell/editableMultiCell';

//Set ag-grid enterprise license.
import { LicenseManager } from '@ag-grid-enterprise/core';
import NumericEditableMultiCell from './NumericEditableMultiCell/numericEditableMultiCell';
LicenseManager.setLicenseKey(
  'CompanyName=PLATAINE LTD,LicensedApplication=TPO,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-037137,SupportServicesEnd=1_February_2024_[v2]_MTcwNjc0NTYwMDAwMA==1fc1df6af8d9b2ab94fda89b62d635d2'
);

//Style
require('./grid.scss');

export default class PL_Grid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onGridReady = this.onGridReady.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.areAllRowsSelected = false;
    this.isInitialUpdate = true;
    this.labels = createLabelHelper('grid.');
    this.gridOptions = {
      headerHeight: this.props.headerHeight ?? 46,
      getRowHeight: this.props.getRowHeight,
      getRowClass: this.props.getRowClass,
      onGridReady: this.onGridReady,
      rowSelection: 'multiple',
      domLayout: props.domLayout || 'normal',
      suppressRowClickSelection: true,
      singleClickEdit: true,
      onSelectionChanged: this.onSelectionChanged.bind(this),
      // enableServerSideSorting: true,
      // enableServerSideFilter: true,
      suppressMovableColumns: true,
      suppressMenuHide: false,
      suppressContextMenu: true,
      // enableColResize: true,
      isRowMaster: (rowData) => {
        return rowData.isRowMaster;
      },
      components: {
        masterDetailRowComponent: this.props.masterDetailRowComponent,
        editableCell: EditableCell,
        editableMultiCell: EditableMultiCell,
        numericEditableMultiCell: NumericEditableMultiCell,
      },
      detailCellRenderer: 'masterDetailRowComponent',
      // rowModelType: 'normal',
      overlayNoRowsTemplate: '<span></span>',
      overlayLoadingTemplate: '<span></span>',
      isRowSelectable: (rowData) => {
        return !rowData?.data?.isRowDisabled;
      },
      defaultColDef: {
        headerComponent: DefaultHeader,
        headerComponentParams: {},
      },
      suppressMultiSort: true,
      onRowDataUpdated: this.onRowDataChanged.bind(this),
      onSortChanged: this.onSortChanged.bind(this),
      treeData: this.props.treeData,
      getDataPath: this.props.getDataPath,
      animateRows: this.props.animateRows,
      suppressClickEdit: this.props.suppressClickEdit,
      detailCellRendererParams: this.props.detailCellRendererParams,
      keepDetailRows: this.props.keepDetailRows,
      onRowGroupOpened: this.props.onRowGroupOpened,
      getRowId: this.props.getRowId,
    };
  }

  // componentWillMount() {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.props.unmount) {
      this.props.unmount(this.props.gridName);
    }
  }

  // in onGridReady, store the api for later use
  onGridReady(params) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.handleResize();

    if (this.props.shouldPreSelectRows) {
      this.api.forEachNode(function (node) {
        if (node?.data?.selected) {
          node.setSelected(true);
        }
      });
    }

    this.props.setRef?.(this);
  }

  componentDidUpdate(previousProps) {
    // Clear all rows selection after selectedRowsData returns with zero value. fixed the bug that grid didn't update the selection if grid's data wasn't changed.
    if (previousProps.selectedRowsData.size > 0 && this.props.selectedRowsData.size === 0 && this.api) {
      this.api.deselectAll();
    }
    if (this.isInitialUpdate) {
      this.handleResize();
      this.isInitialUpdate = false;
    }
  }

  handleResize() {
    if (this.api && this.isVisibleOnScreen()) {
      setTimeout(() => {
        this.api.sizeColumnsToFit();
      }, 0);
    }
  }

  // Workaround - to avoid the ag-grid console warning:
  // ag-Grid: tried to call sizeColumnsToFit() but the grid is coming back with zero width, maybe the grid is not visible yet on the screen?
  isVisibleOnScreen = () => {
    if (this.agGridRef) {
      let agGridNode = ReactDOM.findDOMNode(this.agGridRef);
      if (agGridNode) {
        let rect = agGridNode.getBoundingClientRect();
        if (rect) {
          return rect.width !== 0;
        }
      }
    }
    return false;
  };

  refresh = () => {
    this.getRowsData({ filterModel: this.props.filterState, sortModel: this.props.sortState });
  };

  onFilterChanged = (data) => {
    let updatedFilters = this.getUpdatedFilters(this.props.filterState, data);
    this.getRowsData({ filterModel: updatedFilters, sortModel: this.props.sortState });
  };

  onSortChanged = () => {
    let sortModel = this.columnApi.getColumnState().find((e) => e.sortIndex !== null);
    let colId = sortModel.colId;
    let filterName = this.props.filterConfig.find((type) => {
      return type.fieldName === colId;
    }).filterName;
    this.getRowsData({
      filterModel: this.props.filterState,
      sortModel: { filterName, direction: sortModel.sort },
    });
  };

  getRowsData = (params, pageNumber = 0) => {
    let query = GridHelper.createGetDataQuery(params, this.props.filterConfig, this.props.pageSize, pageNumber);
    this.props.fetchData(this.props.gridName, query, true);
  };

  getUpdatedFilters = (filters, changedFilters) => {
    if (!filters) {
      return List();
    }
    if (!changedFilters || changedFilters.length === 0) {
      return filters;
    }

    changedFilters.map((changedFilter) => {
      if (changedFilter.filterName) {
        let changedFilterIndex = filters.findIndex((filter) => {
          return filter.filterName === changedFilter.filterName;
        });
        if (changedFilterIndex != -1) {
          filters = filters.update(changedFilterIndex, (filter) => {
            filter.values = changedFilter.values;
            return filter;
          });
        }
      }
    });

    return filters;
  };

  initColumnsMetaData() {
    if (this.props.columnsConfig.length == 0 || !this.props.filtersData) {
      return;
    }

    let newFiltersString = JSON.stringify(this.props.filtersData);
    if (newFiltersString === this.filtersString) {
      return;
    }
    this.filtersString = newFiltersString;

    let columnsMetadata = [];

    if (this.props.draggableColumn) {
      columnsMetadata.push(this.createColumnMetadata(this.props.draggableColumn(), this.props.filtersData));
    }
    if (this.props.checkboxSelection) {
      this.addCheckboxColumn(columnsMetadata);
    }

    // add metadata for predeclared columns
    this.props.columnsConfig.map((columnData) => {
      columnsMetadata.push(this.createColumnMetadata(columnData, this.props.filtersData));
    });

    this.columnsMetaData = columnsMetadata;
    this.autoGroupColumnMetaData = this.props.autoGroupColumnDef && this.createColumnMetadata(this.props.autoGroupColumnDef, this.props.filtersData);
  }

  addCheckboxColumn = (columnsMetadata) => {
    var checkboxColumnData = {
      fieldName: '',
      title: '',
      width: this.props?.checkboxColumnWidth ?? 60,

      columnOptions: {
        pinned: this.props?.checkboxColumnPinned || null,
        suppressSizeToFit: true,
        // suppressResize: true,
        resizable: false,
        sortable: false,
        filter: false,
        checkboxSelection: true,
        cellRenderer: CheckboxCell,
        headerComponent: CheckboxHeader,
        headerComponentParams: {
          gridName: this.props.gridName,
          getValue: () => {
            return this.areAllRowsSelected;
          },
          onChecked: () => this.api.selectAll(),
          onUnchecked: () => this.api.deselectAll(),
        },
        cellClass: 'checkbox-style-wrapper',
      },
    };
    columnsMetadata.push(this.createColumnMetadata(checkboxColumnData));
  };

  cancelAgGridClientSideSorting() {
    return 0;
  }

  //TODO - move all logic to a single method
  // create metadata for single column
  createColumnMetadata(columnData, filtersData) {
    let { width, minWidth, filterOptions, ...options } = GridHelper.createColumnMetaData(columnData, filtersData);
    if (!filterOptions) {
      // filterOptions = {suppressFilter: true};
      filterOptions = {};
    }

    let columnMetadata = {
      onFilterChanged: this.onFilterChanged,
      suppressSizeToFit: !!width,
      resizable: true,
      sortable: true,
      comparator: this.cancelAgGridClientSideSorting, //we have Server side sorting
      // filter: !!filterOptions,
      ...(width == null ? {} : { width }),
      // ...(minWidth == null) ? {minWidth: width && width < 80 ? width : 80} : {minWidth},
      ...(minWidth == null ? { minWidth: width && width < 100 ? width : 100 } : { minWidth }),
      ...filterOptions,
      ...options,
    };

    if (columnData.columnOptions && columnData.columnOptions.cellComponent) {
      columnMetadata.cellRenderer = columnData.columnOptions.cellComponent;
    }
    return columnMetadata;
  }

  onRowDataChanged(event) {
    this.areAllRowsSelected = false;
    if (this.api) {
      this.api.refreshHeader();
      this.props?.onRowDataChanged?.(this.api);
    }
  }

  onSelectionChanged(event) {
    if (!this.props.onSelectedRowsChanged) {
      return;
    }
    let selectedRows = this.api.getSelectedRows();
    this.updateCheckboxHeader(selectedRows);
    this.props.onSelectedRowsChanged(this.props.gridName, selectedRows);
  }

  updateCheckboxHeader = (selectedRows) => {
    if (selectedRows.length === 0) {
      this.areAllRowsSelected = false;
    } else {
      // update 'areAllRowsSelected' for Selection column header re-render
      this.areAllRowsSelected = true;
      this.api.forEachNode((node) => {
        if (!node.isSelected() && !node.data.isRowDisabled) {
          this.areAllRowsSelected = false;
        }
      });
    }

    this.api.refreshHeader();
  };

  getGrid() {
    if (this.props.columnsConfig.length === 0) {
      return null;
    }

    this.initColumnsMetaData();

    return (
      <AgGridReact
        ref={(x) => {
          this.agGridRef = x;
        }}
        gridOptions={this.gridOptions}
        masterDetail={this.props.isMasterDetail}
        isRowMaster={this.gridOptions.isRowMaster}
        animateRows={this.props.isMasterDetail}
        rowData={this.props.rows}
        columnDefs={this.columnsMetaData}
        context={{ componentParent: this }}
        // enableFilter={true}
        onColumnResized={this.onColumnResized.bind(this)}
        components={this.props.components}
        autoGroupColumnDef={this.autoGroupColumnMetaData}
      />
    );
  }

  getLoadingOverlay() {
    if (!this.props.loading) {
      return null;
    }
    return <Overlay.Loading />;
  }

  getErrorOverlay() {
    if (!this.props.error) {
      return null;
    }
    return <Overlay.Error text={this.labels.get('error')} buttonLabel={this.labels.get('error.button')} onClickCallback={this.refresh} />;
  }

  getNoRowsOverlay() {
    if (this.props.loading || (this.props.rows && this.props.rows.size > 0)) {
      return null;
    }
    return <Overlay.Label text={this.labels.get('norows')} />;
  }

  onPreviousPageClick = () => {
    let info = this.props.queryResultInfo;
    this.getRowsData({ filterModel: this.props.filterState, sortModel: this.props.sortState }, info.number - 1);
  };
  onColumnResized(params) {
    /* if (params.source === 'uiColumnDragged' && params.finished) {
    //  this.api.sizeColumnsToFit();
    }*/
  }
  onNextPageClick = () => {
    let info = this.props.queryResultInfo;
    this.getRowsData({ filterModel: this.props.filterState, sortModel: this.props.sortState }, info.number + 1);
  };

  getPaginationPanel = () => {
    if (!this.props.queryResultInfo) {
      return null;
    }

    let info = this.props.queryResultInfo;
    let firstRow = 0;
    let lastRow = 0;
    if (info.numberOfElements > 0) {
      firstRow = info.size * info.number + 1;
      lastRow = firstRow + info.numberOfElements - 1;
    }
    return (
      <div className="pagination-panel">
        <Button id="gridPreviousPageBtn" className="border-btn" icon="pl pl-arrow-left" onClick={this.onPreviousPageClick} disabled={info.first} />
        <span>
          {this.labels.get('pageinfo', undefined, {
            first: firstRow,
            last: lastRow,
            total: info.totalElements,
          })}
        </span>
        <Button id="gridNextPageBtn" className="border-btn" icon="pl pl-arrow-right" onClick={this.onNextPageClick} disabled={info.last} />
      </div>
    );
  };

  render() {
    return (
      <ErrorBoundary>
        <div className="pl-grid">
          {this.getNoRowsOverlay()}
          {this.getErrorOverlay()}
          {this.getLoadingOverlay()}
          {this.getGrid()}
          {this.getPaginationPanel()}
        </div>
      </ErrorBoundary>
    );
  }
}

PL_Grid.propTypes = {
  gridName: PropTypes.string.isRequired,
  fetchData: PropTypes.func,
  columns: PropTypes.array,
  getRowHeight: PropTypes.func,
  pageSize: PropTypes.number,
  onSelectedRowsChanged: PropTypes.func,
  checkboxSelection: PropTypes.bool,
  draggableColumn: PropTypes.func,
  rows: PropTypes.object,
  filterConfig: PropTypes.array,
  filterState: PropTypes.object,
  sortState: PropTypes.object,
  loading: PropTypes.bool,
  enableColResize: PropTypes.bool,
  shouldPreSelectRows: PropTypes.bool,
  onRowDataChanged: PropTypes.func,
  setRef: PropTypes.func, //(grid) => void; sets a reference to the grid object so you can call grid.api
};

PL_Grid.defaultProps = {
  getRowHeight: () => {
    return 64;
  },
  pageSize: 100,
  checkboxSelection: true,
  draggableColumn: null,
  rows: {},
  filterConfig: [],
  filterState: List(),
  sortState: {},
  enableColResize: false,
};

import * as assetService from '../../services/Assets/assetService.js';
import * as AssetHelper from '../../utils/assetHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import * as rollsService from '../../services/Rolls/rollsService';
import * as spoolsService from '../../services/Spools/spoolsService';
import * as kitsService from '../../services/Kits/kitsService';
import * as partsService from '../../services/Parts/partsService';
import * as toolsService from '../../services/Tools/toolsService';
import * as groupsService from '../../services/Groups/groupsService';
import { getEnumValue, enumTypes } from '../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { isPart } from '../../utils/assetHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  OVERVIEW_TAB_FETCHDATA_STARTED: 'OVERVIEW_TAB_FETCHDATA_STARTED',
  OVERVIEW_TAB_FETCHDATA_FINISHED: 'OVERVIEW_TAB_FETCHDATA_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function () {
    return { type: actionTypes.OVERVIEW_TAB_FETCHDATA_STARTED };
  },
  fetchDataFinished: function (payload) {
    return { type: actionTypes.OVERVIEW_TAB_FETCHDATA_FINISHED, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (assetType, assetId) {
  return function (dispatch, getState) {
    api.init(dispatch, getState)(assetType, assetId);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function (dispatch, getState) {
  return function (assetType, assetId) {
    return api.fetchData(dispatch, getState)(assetType, assetId);
  };
};

api.fetchData = function (dispatch, getState) {
  return function (assetType, assetId) {
    dispatch(actions.fetchDataStarted());
    let promise1;
    switch (assetType.toUpperCase()) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        promise1 = rollsService.getRollDetails(assetId);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        promise1 = spoolsService.getSpoolDetails(assetId);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        promise1 = kitsService.getKitDetails(assetId);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('PART'):
        promise1 = partsService.getPartDetails(assetId);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        promise1 = groupsService.getGroupDetails(assetId);
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        promise1 = toolsService.getToolDetails(assetId);
        break;
      default:
        console.error('unhandled asset type: ' + assetType);
        break;
    }

    let promise2 = isPart(assetType) ? partsService.getPartLayers(assetId) : assetService.getAssetComponentAssets(assetId);
    let promise3 = assetService.getAssetResultingAssets(assetId);
    let promise4 = assetService.getAssetEquipmentAssets(assetId);

    return Promise.all([promise1, promise2, promise3, promise4])
      .then((allResults) => {
        let assetDetails = allResults[0].data;
        var componentAssets = allResults[1].dataList;
        var resultingAssets = allResults[2].dataList;
        var equipmentAssets = allResults[3].dataList;

        let assetData = {
          assetDetails: _convertAssetToRowsData(assetDetails, getState),
          componentAssets: _convertComponentAssetToRowsData(componentAssets, assetDetails.objectType),
          resultingAssets,
          equipmentAssets,
        };

        dispatch(actions.fetchDataFinished(assetData));
        return assetDetails;
      })
      .catch((error) => {
        console.error('Asset details fetch failed.', error);
      });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _convertAssetToRowsData(asset, getState) {
  let assetType = asset.objectType;
  //todo remove round and add percision 0
  // Common augmentations.
  if (asset.maxStorageTemp === null || asset.maxStorageTemp === undefined) {
    asset.m_MaxStorageTemp = '';
  } else {
    asset.maxStorageTemp = UnitHelper.serverValueToUserValue(unitTypes.TEMPERATURE, asset.maxStorageTemp || 0, 0);
    asset.m_MaxStorageTemp = `${asset.maxStorageTemp} ${UnitHelper.getLabelForUnitType(unitTypes.TEMPERATURE)}`;
  }

  let serverTimeSeconds = getState().system.get('serverDatetime');
  asset.expirationDaysLeft = asset.expirationDate ? DateTimeHelper.GetDifferenceInDays(asset.expirationDate.value, serverTimeSeconds) : '';

  // Roll / Spool specific.
  if (AssetHelper.isRoll(assetType) || AssetHelper.isSpool(assetType)) {
    asset.lengthLeft = asset.lengthLeft ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, asset.lengthLeft || 0, 2) : 0;
    asset.m_Length = `${asset.lengthLeft} ${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)}`;

    asset.widthInCentimeter = UnitHelper.serverValueToUserValue(unitTypes.WIDTH, asset.widthInCentimeter || 0, 2);
    asset.m_Width = `${asset.widthInCentimeter} ${UnitHelper.getLabelForUnitType(unitTypes.WIDTH)}`;

    asset.weight = UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, asset.weight || 0, 2);
    asset.m_Weight = `${asset.weight} ${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)}`;

    asset.m_Area = UnitHelper.serverValueToUserValueWithLabel(unitTypes.AREA, asset.areaCubicCentimeter || 0, 2);
  }

  // Kit specific.
  if (AssetHelper.isKit(assetType)) {
    asset.dueDateLeft = DateTimeHelper.GetDifferenceInDays(
      asset.workOrder && asset.workOrder.dueDate ? asset.workOrder.dueDate : 0,
      serverTimeSeconds
    );
    asset.kittedMaterials.forEach((material) => {
      material.materialUsedAmount = UnitHelper.serverValueToUserValue(
        PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH,
        material.materialUsedAmount,
        2
      );
    });
  }

  return asset;
}

function _convertComponentAssetToRowsData(componentAssets, assetType) {
  if (AssetHelper.isKit(assetType)) {
    componentAssets.forEach((asset) => {
      asset.materialUsedAmount = UnitHelper.serverValueToUserValue(
        PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH,
        asset.materialUsedAmount,
        2
      );
    });
  }
  // else if (AssetHelper.isPart(assetType)) {
  //   componentAssets.forEach(asset => {
  //     console.log(asset);  //TODO: L AFP ????
  //   });
  // }
  return componentAssets;
}

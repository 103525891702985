import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header, { headerItemsLabels } from '../../Common/Header/header';
import PickListsGrid from './PickListsGrid/pickListsGrid';
import { FetchEntitiesFilters } from '../../../enums/fetchEntitiesFilters';
import { enumTypes, getEnumValue } from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./pickListsPage.scss');

export default class PickListsPage extends Component {
  componentDidMount() {
    if (PermissionManager.hasPredefinedViewPickListPermissions()) {
      this.props.actions.init();
    }
  }

  getMoveAssetsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NOT_CONTAINED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  getHeaderItems() {
    let { actions, loading } = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          disabled: loading,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
      ],
      kebab: {
        // disabled: !selectedRowsData || selectedRowsData.size === 0 ,
        menuItems: [
          {
            id: 'deletePickList',
            label: headerItemsLabels.REMOVE,
            action: actions.deletePickList,
            disabled: !(hasRows && selectedRowsData.size === 1),
            actionData: selectedRowsData?.get(0),
          },
        ],
      },
    };
    return items;
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="pick-lists-page">
        <Header {...headerItems} />

        <PickListsGrid actions={{ ...this.props.actions.pickListsGridActions, ...this.props.actions.fetchEntitiesActions }} />
      </div>
    );
  }
}

PickListsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
};

import React from 'react';

import Header from '../../../Common/Header/header';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import PL_ComboBox from 'infrastructure/js/components/controls/Combobox/combobox.js'
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

require('./rfidHeader.scss');

export default class RfidHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.rfidsettings.');
    this.initDeviceApps();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.selectedDeviceApp){
      this.initDeviceApps();
    }
  }

  initDeviceApps() {
    let options = this.props.dropdown ? this.props.dropdown.options : [];
    if (options.length > 0) {
      this.props.change('deviceAppDropDown', options[0]);

      this.props.dropdown.changeCallback(options[0]);
    }
  };

  createDeviceAppDropDown = (deviceAppData) => {
    if (!deviceAppData) {
      return null;
    };
    deviceAppData.titleText = this.labels.get('header.dropdown.title');
    return (
      <InputSection label={deviceAppData.titleText} htmlFor={deviceAppData.name} className='inline left-side no-margin flex-grow-2'>
        <PL_ComboBox
                  name={deviceAppData.name}
                  id={deviceAppData.name}
                  options={deviceAppData.options}
                  placeholder={this.labels.get('header.notSelected')}
                  isSearchable={false}
                  onChangeCallback={deviceAppData.changeCallback}
                  isDisabled={deviceAppData.disabled}
                  className="rfid-header-combobox"
        >
        </PL_ComboBox>
      </InputSection>
    );
  }

  render() {
    let dropdown = this.createDeviceAppDropDown(this.props.dropdown);

    return (
      <Header {...this.props} className="rfid-header" leftContent={dropdown} />
    );
  };
}


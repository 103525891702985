import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';

require('./navigatorComponent.scss');


export default class NavigatorComponent extends Component {


  getAlertLabel = () => {
    let alertCount = this.props.alertCount;
    if (!alertCount) {
      return null;
    }

    return (
      <span className="alert-label">{alertCount}</span>
    );

  }


  render() {
    return (
        <div className="navigation-component">
          <div>

            <label className="count">
              {this.getAlertLabel()}
              {this.props.count}
            </label>

            <label className="name">
              <FormattedMessage id={this.props.name} defaultMessage="x_assets" />
            </label>

          </div>
        </div>
    );
  }
}

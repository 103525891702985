import { actionTypes as batchJobProgressDialogActionTypes } from '../../../actions/Dialogs/BatchJobProgressDialog/batchJobProgressDialogActions.js';
import { Map } from 'immutable';

let defaultState = {
  show: false,
  jobExecutionId: null,
  jobInstanceId: null,
};


export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case batchJobProgressDialogActionTypes.BATCH_JOB_PROGRESS_DIALOG_SHOW:
      return state.set('jobExecutionId', action.payload.jobExecutionId )
                  .set('jobInstanceId', action.payload.jobInstanceId)
                  .set('show', true);
    case batchJobProgressDialogActionTypes.BATCH_JOB_PROGRESS_DIALOG_HIDE:
       return Map(defaultState);

    default:
      return state;
  }

}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserManagementDialog from '../../components/Dialogs/UserManagementDialog/userManagementDialog.js';
import {jsxActions as userManagementDialogActions}  from '../../actions/Dialogs/userManagementDialogActions';
import {userModes} from '../../enums/userModes';

function mapDispatchToProps(dispatch) {
  return {
    actions:         bindActionCreators(userManagementDialogActions, dispatch),
  }
}

export default connect(
  (state)=>{
    return {
      show : state.dialogs.getIn(['userManagementDialog','show']),
      sData: state.dialogs.get('userManagementDialog'),
      userManagementMode: state.dialogs.getIn(['userManagementDialog','user']).id ? userModes.USER_EDIT : userModes.USER_CREATE,
      isLoggedInUser: state.login.get('loggedInUser').id === state.dialogs.getIn(['userManagementDialog','user']).id,
      loggedInUserData : state.login.get('loggedInUser'),
      featureFlags: state.system.get('featureFlags'),
      //reloadParentComponent={this.props.actions.reload //How to reload???????
    }
  },
  mapDispatchToProps
)(UserManagementDialog);


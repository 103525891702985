import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SecurityMessageDialog from 'infrastructure/js/components/SecurityMessageDialog/securityMessageDialog';
import {jsxActions as securityMessageDialogActions}  from '../../actions/Dialogs/securityMessageDialogActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(securityMessageDialogActions, dispatch),
  }
}

export default connect(
  (state)=>{
    return {
      show : state.dialogs.getIn(['securityMessageDialog','show']),
      sData: state.dialogs.get('securityMessageDialog'),
      loggedInUserData : state.dialogs.getIn(['securityMessageDialog','userData']),
    }
  },
  mapDispatchToProps
)(SecurityMessageDialog);

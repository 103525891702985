import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import PickListDetails from './PickListDetails/pickListDetails';
import PickListAssets from "./PickListAssets/pickListAssets";

require('./pickListPage.scss');

export default class PickListPage extends Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
    if (PermissionManager.hasPredefinedViewPickListPermissions() || PermissionManager.hasLocationPickListTabPermissions()) {
      let pickListId = this.props.match.params.pickListId;
      this.props.actions.init(pickListId, false);
    }
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  componentDidUpdate(prevProps) {
    let oldId = prevProps.match.params.pickListId;
    let newId = this.props.match.params.pickListId;
    if (newId !== oldId) {
      this.props.actions.init(newId, true);
    }

    // let newTabId = this.props.match.params.tabId;
    //
    // //Check if tab changed and resize if needed.
    // if(prevProps.match.params.tabId !== newTabId){
    //   window.dispatchEvent(new Event('resize'));
    // }
    //
    // if (newTabId && !(Object.values(TabKeys).includes(newTabId))) {
    //   window.location.hash = '#/PageNotFound';
    // }
  }

  // getMoveAssetsDialogConfig = () => {
  //   return {
  //     boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NOT_CONTAINED],
  //     entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')],
  //     preselectedAssets: this.props.sSelectedRowsData
  //   };
  // };
  //
  // getHeaderItems() {
  //   let {actions, sHasRows, sSelectedRowsData} = this.props;
  //
  //   let items = {
  //     buttons: [
  //       {
  //         id:'moveTo',
  //         label: headerItemsLabels.MOVE_TO,
  //         icon: 'pl pl-move-assets',
  //         action: actions.header.onMenuItemMoveToClick,
  //         actionData: this.getMoveAssetsDialogConfig()
  //       },
  //       {
  //         id:'remove',
  //         icon: 'pl pl-remove-from-picklist',
  //         label: headerItemsLabels.REMOVE_FROM_PICK_LIST,
  //         counter: sSelectedRowsData.size,
  //         disabled: !sHasRows || !sSelectedRowsData || sSelectedRowsData.size === 0,
  //         action: actions.header.onRemoveFromPickListClick,
  //         actionData: sSelectedRowsData
  //       },
  //       {
  //         id:'export',
  //         icon: 'pl pl-export-icon',
  //         tooltip: headerItemsLabels.EXPORT,
  //         disabled: !sHasRows,
  //         action: actions.header.onExportPickListClick,
  //         actionData: {gridName: gridsNames.PICK_LIST_PAGE}
  //       }
  //     ],
  //   };
  //   return items;
  //
  // }

  // reloadPage = () => {
  //   this.props.actions.reload();
  // };

  // renderMoveAssetsDialog = () => {
  //   if (PermissionManager.hasLocationPickListTabPermissions()) {
  //     return null;
  //   }
  //   return (
  //     <PL_DialogWrapper dialogComponent={MoveAssetsDialog}
  //                       show={this.props.sMoveAssetsData.get('show')}
  //                       actions={this.props.actions.moveAssetsActions}
  //                       sData={this.props.sMoveAssetsData}
  //                       reloadParentComponent={this.reloadPage}/>
  //   );
  // };

  reloadAssetPage = () => {
    // this.props.actions.init(true , this.props.pickList.get('id'));  //TODO: L PL ?????????
  };

  render() {
    if (!this.props.pickList?.id) {
      return null;
    }
    return (
      <div className="pick-list-page">
        <PickListDetails
          pickList={this.props.pickList}
          pickListEditorData={this.props.sPickListEditorData?.toJS() || []}
          loading={this.props.loading}
          actions={this.props.actions.pickListPageEditorActions}
        />

        <PickListAssets {...this.props} />
      </div>
    );
  }
};


PickListPage.propTypes = {
  // pickList : PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  sPickListGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
};

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { List } from 'immutable';
import {jsxActions as completedWorkOrdersPageActions} from '../../actions/CompletedWorkOrdersPage/completedWorkOrdersPageActions.js'
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import {jsxActions as completedWorkOrdersGridActions }      from '../../actions/CompletedWorkOrdersPage/completedWorkOrdersGridActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as editWorkOrdersStatusDialogActions }    from '../../actions/Dialogs/EditWorkOrdersStatusDialog/editWorkOrdersStatusDialogActions';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import CompletedWorkOrdersPage from '../../components/PredefinedList/CompletedWorkOrdersPage/completedWorkOrdersPage';
import {gridsNames} from '../../enums/gridsNames';
import {getDefaultWorkOrderType}  from '../../utils/workOrderHelper';
import PermissionManager from "../../../../infrastructure/js/utils/permissionManager";


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(completedWorkOrdersPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      editWorkOrdersStatusActions:  bindActionCreators(editWorkOrdersStatusDialogActions, dispatch),
      completedWorkOrdersGridActions:  bindActionCreators(completedWorkOrdersGridActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let CompletedWorkOrderContainer =  connect (
  (state) => {
    let completedWorkOrderGrid = state.grid.get(gridsNames.PREDEFINED_LIST_COMPLETED_WORK_ORDER) ? state.grid.get(gridsNames.PREDEFINED_LIST_COMPLETED_WORK_ORDER) : null;
    return {
      sCompletedWorkOrderGrid:  completedWorkOrderGrid,
      sHasRows: completedWorkOrderGrid && completedWorkOrderGrid.get('rows') ? completedWorkOrderGrid.get('rows').size > 0 : false,
      sSelectedRowsData: completedWorkOrderGrid ? completedWorkOrderGrid.get('selectedRowsData') : List(),
      sEditWorkOrdersStatusData: state.dialogs.get('editWorkOrdersStatusDialog'),
      sDefaultWorkOrderType: getDefaultWorkOrderType(PermissionManager.getOrgPreferences())
    };
  },
  mapDispatchToProps
)(CompletedWorkOrdersPage);

export default CompletedWorkOrderContainer;

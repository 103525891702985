import React, {Component} from 'react';


require('./scanDetails.scss');

export default class ScanDetails extends React.PureComponent {

  render() {

    let {details} = this.props;
    if (!details) {
      return null;
    }

    let detailsItems = details.map((item, index) => {
      if (item.bodyOnly) {
        return item.body;
      }
      return (
        <div className="section" key={index}>
          <label>{item.label}</label>
          {item.body}
        </div>
      )
    });

    return (
      <div className="scan-details-section">
        {detailsItems}
      </div>
    );
  }
}

ScanDetails.propTypes = {
};

ScanDetails.defaultProps = {
};


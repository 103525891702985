import React from 'react';
import { getEditorDataConverter } from 'infrastructure/js/utils/editorHelper.js';
import DetailsColumn from '../../../../Common/DetailsComponents/detailsColumn.js';
import Link from '../../../../Common/Layout/Link/link';
import { navigationStates } from '../../../../../enums/navigationStates.js';
import { isAssetActivated } from '../../../../../utils/assetHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import TextFieldEditor from 'infrastructure/js/components/editors/TextFieldEditor/textFieldEditor';
import NumericFieldEditor from 'infrastructure/js/components/editors/NumericFieldEditor/numericFieldEditor';
import ComboBoxEditor from 'infrastructure/js/components/editors/ComboBoxEditor/comboBoxEditor';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import * as AssetDetailsHelper from '../utils/assetDetailsHelper';
import TimeFieldEditor from '../../../../../../../infrastructure/js/components/editors/TimeFieldEditor/timeFieldEditor';
import { enumTypes, getEnumValue } from '../../../../../utils/enumHelper';
import ToolTypeHelper from '../../../../../utils/toolTypeHelper';
import TagsAsyncEditor from '../../../Common/TagsAsyncEditor.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./toolDetails.scss');

export default class ToolDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.assetpage.overview.details.');
    this.toolStatusLabels = createLabelHelper('enum.tool.status.');
  }

  convertEditorDataToServerData = getEditorDataConverter('tool');

  handleAdditionalFieldSubmit = (editorSubmitData, valueType, id) => {
    if (!editorSubmitData) {
      return;
    }

    let newData = AssetDetailsHelper.getAdditionalFieldValue(valueType, id, editorSubmitData);
    this.props.actions.assetEditorActions.updateTool(this.props.asset.id, newData);
  };

  handleAssetEditInPlaceSubmit = (editorSubmitData) => {
    if (!editorSubmitData) {
      return;
    }

    let serverDataValue = this.convertEditorDataToServerData(editorSubmitData);
    if (serverDataValue === undefined) {
      return;
    }
    let newData = {};
    newData[editorSubmitData.fieldName] = serverDataValue;

    //If location changed, erase subLocation, send empty value.
    if (
      editorSubmitData.fieldName === 'locationId' &&
      this.props.asset.location.id !== serverDataValue
    ) {
      newData['subLocation'] = null;
    }

    this.props.actions.assetEditorActions.updateTool(this.props.asset.id, newData);
  };

  renderLocationLink = (asset) => {
    return (
      <Link
        label={asset.locationName}
        id={asset.locationId}
        type={navigationStates.LOCATION}
        className="link-details"
      />
    );
  };

  maxLength30 = Validation.maxLength(50);

  renderSublocationEditor = (asset) => {
    return (
      <TextFieldEditor
        id="subLocation"
        name="subLocation"
        value={asset.subLocation}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
        validate={this.maxLength30}
      />
    );
  };

  renderTagEditor = (asset) => {
    //Convert server object value to singleLineMultiSelect object.

    return (
      <TagsAsyncEditor
        id="tags"
        name="tags"
        limit={10}
        asset={asset}
        onSubmit={this.handleAssetEditInPlaceSubmit}
        disabled={!isAssetActivated(asset.assetStatus)}
        parse={(data, name) => ({
          fieldName: name,
          tags: data.map(({ value }) => ({
            id: value,
          })),
        })}
      />
    );
  };

  renderCategory = (asset) => {
    return (
      <TextFieldEditor
        id="toolCategory"
        name="toolCategory"
        value={asset.toolType?.toolCategory?.businessId || ''}
        handleSubmit={undefined}
        editable={false}
      />
    );
  };

  renderTypeEditor = (asset) => {
    const toolTypes = this.props.assetEditorData.optionsAssetToolTypes.map((toolType) => ({
      ...toolType,
      label: ToolTypeHelper.getToolTypeFullLabel(
        toolType.data.description,
        toolType.data.businessId
      ),
    }));

    return (
      <ComboBoxEditor
        id="toolTypeBusinessId"
        name="toolTypeBusinessId"
        value={Parse.findOptionById(asset.toolType.id, toolTypes)}
        displayValue={
          asset.toolType
            ? ToolTypeHelper.getToolTypeFullLabel(
                asset.toolType.description,
                asset.toolType.businessId
              )
            : ''
        }
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        options={toolTypes}
        editable={isAssetActivated(asset.assetStatus)}
        validate={Validation.required}
      />
    );
  };

  renderNextMaintenanceEditor = (asset) => {
    return (
      <NumericFieldEditor
        id="currentCyclesNumber"
        name="currentCyclesNumber"
        value={asset.currentCyclesNumber}
        alert={AssetDetailsHelper.getIcon(
          asset,
          getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT')
        )}
        isInteger={true}
        min={-9999}
        max={9999}
        displayValue={
          asset.currentCyclesNumber !== null && asset.currentCyclesNumber !== undefined
            ? asset.currentCyclesNumber + ' ' + this.labels.get('cycles')
            : ''
        }
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderUtlEditor = (asset) => {
    return (
      <TimeFieldEditor
        id="maintenanceUsageTime"
        name="maintenanceUsageTime"
        value={asset.maintenanceUsageTime ? asset.maintenanceUsageTime : 0}
        alert={AssetDetailsHelper.getIcon(
          asset,
          getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME')
        )}
        isDays={true}
        displayValue={asset.maintenanceUsageTime}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  getAssetDetails(asset, labels) {
    let index0 = [
      {
        label: this.labels.get('location'),
        body: this.renderLocationLink(asset),
      },
      {
        label: this.labels.get('sublocation'),
        body: this.renderSublocationEditor(asset),
      },
      {
        label: this.labels.get('tag'),
        body: this.renderTagEditor(asset),
      },
      {
        label: '',
        body: null,
      },
      {
        type: 'hr',
        body: <hr />,
      },
    ];

    let index1 = [
      {
        label: this.labels.get('type'),
        body: this.renderTypeEditor(asset),
      },
      {
        label: this.labels.get('toolCategory'),
        body: this.renderCategory(asset),
      },
      {
        label: '',
        body: null,
      },
      {
        label: '',
        body: null,
      },
      {
        type: 'hr',
        body: <hr />,
      },
    ];
    let index2 = [
      {
        label: this.labels.get('nextMaintenance'),
        body: this.renderNextMaintenanceEditor(asset),
      },
      {
        label: this.labels.get('maxCyclesCount'),
        body: (
          <span id="maxCyclesCount">
            {asset.maxNumOfCycles ? asset.maxNumOfCycles + ' ' + this.labels.get('cycles') : ''}
          </span>
        ),
      },
      {
        label: this.labels.get('utl'),
        body: this.renderUtlEditor(asset),
      },
      {
        label: this.labels.get('maxUsageTime'),
        body: (
          <span id="maxUsageTime">
            {asset.maxManufactureUsageTime
              ? DateTimeHelper.ConvertMinutesToDaysHoursMinutes(asset.maxManufactureUsageTime)
              : ''}
          </span>
        ),
      },
      {
        type: 'hr',
        body: <hr />,
      },
      {
        label: this.labels.get('progressStatus'),
        body: (
          <span id="progress-status-input">
            {asset.toolStatusDisplayKey && asset.toolStatus
              ? this.toolStatusLabels.get(asset.toolStatus)
              : ''}
          </span>
        ),
      },
      {
        label: this.labels.get('lastoperation'),
        body: (
          <span id="last-operation-input">
            {asset.lastProductionOperation ? asset.lastProductionOperation.operationReported : ''}
          </span>
        ),
      },
    ];
    let result = [index0, index1, index2];

    let additionalFields = this.props.assetEditorData.additionalFields
      ? this.props.assetEditorData.additionalFields.toolAdditionalFields
      : [];
    if (additionalFields && additionalFields.length > 0) {
      index2.push({ type: 'hr', body: <hr /> });
    }
    AssetDetailsHelper.addAdditionalFields(
      result,
      asset,
      this.handleAdditionalFieldSubmit,
      additionalFields
    );
    return result;
  }

  render() {
    let { asset } = this.props;
    if (!asset || !asset.id) {
      return null;
    }

    let columnsData = this.getAssetDetails(asset, this.labels);

    let columns = columnsData.map((column, index) => {
      return <DetailsColumn key={'tool' + index} rows={column} />;
    });

    return <div className="asset-details-section">{columns}</div>;
  }
}

import React from 'react';
import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Form from '../../../../Common/Form/form';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import EntitiesMultiSelect from '../../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import { enumTypes, getEnumValue } from '../../../../../../utils/enumHelper';
import { FetchEntitiesFilters } from '../../../../../../enums/fetchEntitiesFilters';
import { List } from 'immutable';
import { EntityPropertyTypes } from '../../../../../../enums/entityPropertyTypes';
import Label from 'infrastructure/js/components/Label/label.js';

import './misplacedMaterialForm.scss';

class MisplacedMaterialForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formLabels = createLabelHelper('mat.administration.settings.alerts.form.');

    this.locationsFetchConfig = {
      entityType: EntityPropertyTypes.LOCATION_NAME,
      filter: [FetchEntitiesFilters.ACTIVE],
      action: this.props.actions.fetchEntitiesActions.fetchEntities,
    };

    this.state = {
      alertsDisabled: false,
    };
  }

  componentDidMount() {
    this.setState({ alertsDisabled: !this.props.sData.enabled });
    this.initFormValues();
  }

  componentWillUnmount() {
    this.props.actions.setStartParameters();
  }

  initFormValues = () => {
    let data = this.props.sData;
    const locations = data.locations || [];

    let initialValue = {
      alertOnMisplaced: data.enabled,
      alertOnlyIfInTodayPicklist: data.enabledOnlyIfInTodayPicklist,
      warningThreshold: data.warningThreshold,
      recipients: data.recipients?.join(),
      locations: {
        assetsType: {value: getEnumValue(enumTypes.OBJECT_TYPE)('LOCATION'), label: 'Locations'},
        assetsValue: locations?.map(location => ({
          value: location.id,
          label: location.name,
          data: location
        }))
      }
    };

    this.props.initialize(initialValue);
  };

  onSubmit = (data) => {
    let newData = {
      id: this.props.sData.id,
      enabled: data.alertOnMisplaced,
      warningThreshold: data.warningThreshold,
      errorThreshold : 0,
      additionalProperties: {
        locations: data?.locations?.assetsValue?.map(({ value }) => value),
        recipients: data.recipients,
        // TODO: will be enabled in the future
        // enabledOnlyIfInTodayPicklist: data.alertOnlyIfInTodayPicklist,
      },
    };

    this.props.actions
      .update(newData, this.props.alertType, this.getMessageDialogBuilder())
      .then((res) => {
        if (res.success) {
          this.initFormValues(); //Initialize form values and reset pristine.
        }
      });
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.formLabels.get('confirmation.success');
      let className = 'oneBackground';
      return { title, className };
    };
  };
  getFormButtons() {
    return [
      {
        text: this.formLabels.get('buttons.save'),
        className: 'common-alerts-form-button-save border-btn btn',
        action: this.props.handleSubmit(this.onSubmit),
        disabled: this.props.pristine,
      },
    ];
  }

  getPreselectedLocations = () => {
    return this.props.sData.locations ? List(this.props.sData.locations) : List([]);
  };

  recipientsValidation = (value = '') => {
    const emailsArr = value.replace(/ /g, '').split(',');
    let validationMessage = undefined;

    emailsArr.forEach((email) => {
      const message = Validation.email(email);
      if (message) {
        validationMessage = this.formLabels.get('validationMessage.email');
      }
    });

    return validationMessage;
  };

  onEnableAlertsClick = () => {
    this.setState({ alertsDisabled: !this.state.alertsDisabled });
  };

  multiSelectElementRenderer = (isOption) => function renderer({data}) {
    if (!data?.label) {
      return null;
    }

    return (
        <div className={`select-row ${isOption ? 'select-option-row' : 'select-value-row'}`}>
          <Label text={ data.label} />
        </div>
    );
  }

  render() {
    return (
      <Form
        className="common-alerts-form misplaced-material-form"
        formButtons={this.getFormButtons()}
      >
        <InputSection htmlFor="alertOnMisplaced">
          <label htmlFor="alertOnMisplaced">
            <Checkbox
              name="alertOnMisplaced"
              id="alertOnMisplaced"
              onClick={this.onEnableAlertsClick}
              value={false}
            />
            <span>{this.props.labels.get('checkbox.alertOnMisplaced.title')}</span>
          </label>
        </InputSection>

        {/* TODO: will be enabled in the future */}
        {/* <InputSection htmlFor="alertOnlyIfInTodayPicklist">
          <label htmlFor="alertOnlyIfInTodayPicklist">
            <Checkbox
              name="alertOnlyIfInTodayPicklist"
              id="alertOnlyIfInTodayPicklist"
              disabled={this.state.alertsDisabled}
            />
            <span>{this.props.labels.get('checkbox.alertOnlyIfInTodayPicklist.title')}</span>
          </label>
        </InputSection> */}

        <InputSection
          htmlFor="warningThreshold"
          className="inline left-side"
          label={this.props.labels.get('warningThreshold.title')}
          forceLabelOverflow={true}
        >
          <TextField
            id="warningThreshold"
            name="warningThreshold"
            normalize={Normalize.number(true, 0, 9999)}
            disabled={this.state.alertsDisabled}
          />
          <span className="additional-info">
            {this.props.labels.get('warningThreshold.additionalinfo')}
          </span>
        </InputSection>

        <InputSection
          htmlFor="locations"
          className="inline left-side"
          label={this.props.labels.get('locationsToMonitor.title')}
        >
          <EntitiesMultiSelect
            id="locations"
            name="locations"
            autoFocus={false}
            entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('LOCATION')]}
            fetchConfig={this.locationsFetchConfig}
            change={this.props.change}
            showEntitiesTypes={false}
            allowNewOption={false}
            isDisabled={this.state.alertsDisabled}
            optionRenderer={this.multiSelectElementRenderer(true)}
            multiValueRenderer={this.multiSelectElementRenderer()}
          />
        </InputSection>

        <InputSection htmlFor="recipients" label={this.props.labels.get('receipients.title')}>
          <TextAreaField
            id="recipients"
            name="recipients"
            validate={this.recipientsValidation}
            disabled={this.state.alertsDisabled}
          />
        </InputSection>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'misplacedMaterialForm',
})(MisplacedMaterialForm);

import React, { Component } from "react";
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import { reduxForm } from "redux-form";
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import AfpCreel from '../../Wizards/AfpOperationWizard/SubComponents/AfpCreel/afpCreel';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

require('./addReplaceSpoolsDialog.scss');

class AddReplaceSpoolsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.afplabels = createLabelHelper('mat.wizards.afpOperation.');
    this.labels = createLabelHelper('mat.dialogs.addReplaceSpoolsDialog.');

    this.itemToEdit = props.sData.get('itemToEdit');
    this.scannedAssets = this.getLastLayerAssets(this.itemToEdit);

  }

  getLastLayerAssets = (itemToEdit) => {
    // const res = itemToEdit?.lastLayer?.layerComponents?.map(item => {
    //   return {
    //     pinNumber: item.pinNumber,
    //     assetId: item?.spool?.id,
    //     assetBusinessId: item?.spool?.businessId,
    //   }
    // } )
    let res = [];
    itemToEdit?.lastLayer?.layerComponents?.forEach(item => {
      res[item.pinNumber] = item.spool?.businessId || ''
    } )
    return res;
  }

  componentDidMount() {
    let initialData = {
      partId: this.itemToEdit?.businessId,
      layerId: this.itemToEdit?.lastLayer?.businessId,
    }

    this.props.initialize(initialData);
  }

  getDialogButtons() {
    return {
      left: [{
        id: "cancel",
        text: this.dialogLabels.get("cancel"),
        action: this.onHide,
      }],
      right: [
        {
          id: "submit",
          // disabled: this.state.isWeightLoading,
          text: this.labels.get("footer.performOperation"),
          bsStyle: "primary",
          loading: this.props.sData.get("loading"),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))

        }
      ]
    };
  }

  onSubmit = (data) => {
    let submitData = {
      partId: this.itemToEdit.id,
      layerId: this.itemToEdit.lastLayer.id,
      layerComponents: data.scannedAssets?.filter(item => !!item.spoolBusinessId),
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,

    };
    this.props.actions.submit(submitData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      return {title: this.labels.get('confirmation.message.title'), className: 'oneBackground'};
    };
  };

  onHide = () => {
    this.props.actions.hide();
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  render() {
    return (
      <Dialog
        id="add-replace-spools-dialog"
        className="add-replace-spools-dialog"
        show={this.props.sData.get("show")}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get("header.title")}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('partId')}  className="inline left-side no-margin">
          <TextField id="partId" name="partId"
                     disabled={true }
          />
        </InputSection>

        <InputSection label={this.labels.get('layerId')}  className="inline right-side no-margin">
          <TextField id="layerId" name="layerId"
                     disabled={true }
          />
        </InputSection>


        <InputSection label={this.labels.get('selectAssets')} className="">
          <AfpCreel labels={this.afplabels}
                    actions={this.props.actions}
                    change={this.props.change}
                    scannedAssets={this.scannedAssets}
          />
        </InputSection>

      </Dialog>
    );
  }

}

export default reduxForm({
    form: 'addReplaceSpoolsDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(AddReplaceSpoolsDialog);

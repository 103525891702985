import { Map } from 'immutable';
import { actionTypes as schedulingPreferencesViewActions } from '../../../actions/Administration/SettingsPage/schedulingPreferencesViewActions';

let defaultState = {
  loading : false,
  formData : null,
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case schedulingPreferencesViewActions.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case schedulingPreferencesViewActions.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_FINISHED:
      return state.set('loading', false);

    case schedulingPreferencesViewActions.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_SUBMIT_FAILED:
      return state.set('loading', false);

    case schedulingPreferencesViewActions.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_FETCH_DATA_IN_PROGRESS:
      return state.set('loading', true);

    case schedulingPreferencesViewActions.ADMINISTRATION_SETTINGS_SCHEDULING_PREFERENCES_VIEW_FETCH_DATA_FINISHED:
      state = state.set('formData', action?.payload);
      state = state.set('loading', false);
      return state;

    default:
      return state;
  }
}













import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as toolsService from '../../../../services/Tools/toolsService.js';
import * as daHelper from '../../../Dialogs/dialogActionsHelper.js';
import {dialogsNames} from '../../../../enums/dialogsNames.js';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import {api as exportApi} from '../../../Export/exportActions.js';


const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.IMPORT_TOOL_TYPES_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.IMPORT_TOOL_TYPES_DIALOG),

};

export let api = {
  ...daHelper.getApiActions(actions, config),

  submit(dispatch, getState) {
    return function (file, messageDialogBuilder, reloadParentComponent) {
      dispatch(actions.setBusy(true));

      toolsService.importToolTypesJson(file).then((response) => {
        dispatch(actions.setBusy(false));
        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Import tool types (JSON) failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (messageDialogBuilder) {
          let messageDialogDescriptor = messageDialogBuilder(
            response.data,
            messageDialogApi.close(dispatch, getState)
          );
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }

        if (reloadParentComponent) {
          reloadParentComponent();
        }
        return {success: true};
      });
    };
  }
}

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  exportDomSchema() {
    return function (dispatch, getState) {
      exportApi.exportToolTypesDomSchema(dispatch, getState)();
    }
  }
};

import Network from 'infrastructure/js/modules/network';
import * as shiftsService from '../../../services/Shifts/shiftsService';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions';
import { api as shiftSettingsDialogApi } from '../../../actions/Administration/MatSettingsPage/Dialogs/shiftSettingsDialogActions';
import { fetchAllResources } from '../../../services/Scheduler/schedulerService';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { filterTypes } from '../../../enums/shiftSchedulerEnums';
import { getSortedWeekdays, convertTimeStringToDate } from '../../../components/Administration/Common/ShiftsScheduler/shiftsSchedulerHelpers';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

export const actionTypes = {
  SHIFTS_SCHEDULER_FETCH_DATA_IN_PROGRESS: 'SHIFTS_SCHEDULER_FETCH_DATA_IN_PROGRESS',
  SHIFTS_SCHEDULER_FETCH_DATA_FINISHED: 'SHIFTS_SCHEDULER_FETCH_DATA_FINISHED',
  SHIFTS_SCHEDULER_UPDATE_DATA_IN_PROGRESS: 'SHIFTS_SCHEDULER_UPDATE_DATA_IN_PROGRESS',
  SHIFTS_SCHEDULER_UPDATE_DATA_FINISHED: 'SHIFTS_SCHEDULER_UPDATE_DATA_FINISHED',
  SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_IN_PROGRESS: 'SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_IN_PROGRESS',
  SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_FINISHED: 'SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_FINISHED',
  SHIFTS_SCHEDULER_UNMOUNT: 'SHIFTS_SCHEDULER_UNMOUNT',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataInProgress: function () {
    return { type: actionTypes.SHIFTS_SCHEDULER_FETCH_DATA_IN_PROGRESS };
  },
  fetchDataFinished: function (payload) {
    return { type: actionTypes.SHIFTS_SCHEDULER_FETCH_DATA_FINISHED, payload };
  },
  updateDataInProgress: function () {
    return { type: actionTypes.SHIFTS_SCHEDULER_UPDATE_DATA_IN_PROGRESS };
  },
  updateDataFinished: function (payload) {
    return { type: actionTypes.SHIFTS_SCHEDULER_UPDATE_DATA_FINISHED, payload };
  },
  fetchShiftTypesInProgress: function () {
    return { type: actionTypes.SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_IN_PROGRESS };
  },
  fetchShiftTypesFinished: function (payload) {
    return { type: actionTypes.SHIFTS_SCHEDULER_FETCH_SHIFT_TYPES_FINISHED, payload };
  },
  unmount: function () {
    return { type: actionTypes.SHIFTS_SCHEDULER_UNMOUNT };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchData = function () {
  return function (dispatch, getState) {
    return api.fetchData(dispatch, getState)();
  };
};

jsxActions.assignShift = function (shiftAssignData) {
  return function (dispatch, getState) {
    return api.assignShift(dispatch, getState)(shiftAssignData);
  };
};

jsxActions.removeShifts = function ({ assignments, filterData }) {
  return function (dispatch, getState) {
    const _assignments = assignments || getState().administration.getIn(['matSettingsPage', 'shiftsScheduler', 'shiftsSchedulerData']);
    const assignmentsToDelete = _getAssignmentsToDelete(_assignments, filterData);

    if (!assignmentsToDelete?.items?.length) {
      return;
    }

    if (assignmentsToDelete?.items?.length > 1) {
      return api.openRemoveShiftsConfirmationDialog(dispatch, getState)(assignmentsToDelete);
    }

    return api.removeShifts(dispatch, getState)(assignmentsToDelete);
  };
};

jsxActions.unmount = function () {
  return function (dispatch) {
    dispatch(actions.unmount());
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function (dispatch, getState) {
  return function () {
    api.fetchShiftTypesData(dispatch, getState)();
    api.fetchShiftsSchedulerData(dispatch, getState)();
  };
};

api.fetchShiftTypesData = function (dispatch, getState) {
  return function () {
    return shiftsService
      .fetchShiftTypesItems()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch shift types data failed.', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return false;
        }

        dispatch(actions.fetchShiftTypesFinished(response.dataList));
      })
      .catch((err) => {
        console.error('Fetch shift types data failed.', err);
        messageDialogApi.responseError(dispatch, getState)();
      });
  };
};

api.fetchShiftsSchedulerData = function (dispatch, getState) {
  return function () {
    dispatch(actions.fetchDataInProgress());

    const resourcesPromise = fetchAllResources();
    const templatePromise = shiftsService.fetchWeeklyShiftsTemplate();

    return Promise.all([resourcesPromise, templatePromise])
      .then((allResults) => {
        const invalidResponse = allResults.find((response) => {
          return !Network.isResponseValid(response);
        });

        if (invalidResponse) {
          console.error('Failed to Fetch weekly shifts template.', invalidResponse);
          messageDialogApi.responseError(dispatch, getState)(invalidResponse);
          dispatch(actions.fetchDataFinished());
          return { success: false };
        }

        const resourcesData = allResults[0]?.dataList;
        const templateData = allResults[1]?.dataList;
        const rowsData = _convertToRowsData(resourcesData, templateData);
        dispatch(actions.fetchDataFinished(rowsData));
      })
      .catch((err) => {
        console.error('Failed to Fetch weekly shifts template.', err);
        messageDialogApi.responseError(dispatch, getState)();
        dispatch(actions.fetchDataFinished());
      });
  };
};

api.assignShift = function (dispatch, getState) {
  return function ({ shiftData, resourceData, day, isTemplate }) {
    dispatch(actions.updateDataInProgress());

    const queryData = {
      templateId: shiftData?.templateId,
      shiftTypeId: shiftData.id,
      resourceId: resourceData?.id,
      day,
    };

    return shiftsService
      .createTemplateShift(queryData)
      .then((response) => {
        dispatch(actions.updateDataFinished());

        if (!Network.isResponseValid(response)) {
          console.error('Assign shift failed.', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return false;
        }

        const assignmentErrors = response?.data?.failedTemplateRequests;
        const assignmentsSucceeded = response?.data?.succeededTemplates;
        const isHumanCapacityEnabled = PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled;

        if (assignmentsSucceeded?.length > 0 && isHumanCapacityEnabled) {
          const shiftSettingsDialogData = {
            shiftData,
            resourceData,
            day,
            isTemplate,
            shiftAssignmentIds: assignmentsSucceeded.map(({ templateId }) => templateId),
          };
          shiftSettingsDialogApi.show(dispatch, getState)(shiftSettingsDialogData);
        }

        if (assignmentErrors?.length > 0) {
          messageDialogApi.open(dispatch, getState)(_assignMessageDialogBuilder(assignmentErrors));
        }

        const validations = Network.hasValidationWarnings(response);
        if (validations?.warnings) {
          const messageDialogDescriptor = _warningMessageDialogDescriptor(validations);
          if (messageDialogDescriptor) {
            messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          }
        }

        api.fetchShiftsSchedulerData(dispatch, getState)();
      })
      .catch((err) => {
        console.error('Assign shift failed.', err);
        messageDialogApi.responseError(dispatch, getState)();
        dispatch(actions.updateDataFinished());
      });
  };
};

api.removeShifts = function (dispatch, getState) {
  return function (assignmentsToDelete) {
    dispatch(actions.updateDataInProgress());

    return shiftsService
      .deleteTemplateShifts(assignmentsToDelete)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.updateDataFinished());
          console.error('Delete shift failed.', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return false;
        }

        const warnings = Network.hasValidationWarnings(response);
        if (warnings) {
          const messageDialogDescriptor = _warningMessageDialogDescriptor(warnings);
          if (messageDialogDescriptor) {
            messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          }
        }

        return api.fetchShiftsSchedulerData(dispatch, getState)();
      })
      .catch((err) => {
        console.error('Delete shift failed.', err);
        messageDialogApi.responseError(dispatch, getState)();
        dispatch(actions.updateDataFinished());
      });
  };
};

api.openRemoveShiftsConfirmationDialog = function (dispatch, getState) {
  return function (data) {
    const labels = createLabelHelper('mat.administration.matsettings.shiftsScheduler.remove.confirmation.');

    const dialogLabels = createLabelHelper('mat.dialog.');

    const children = [<MessageDialog.MessageRow key={0} text={labels.get('message')} />];

    let buttons = [
      {
        id: 'cancel',
        text: dialogLabels.get('cancel'),
        action: messageDialogApi.close(dispatch, getState),
        bsStyle: 'default',
      },
      {
        id: 'remove',
        text: labels.get('confirmRemove'),
        action: () => {
          messageDialogApi.close(dispatch, getState)();
          api.removeShifts(dispatch, getState)(data);
        },
        bsStyle: 'primary',
      },
    ];

    const confirmationConfig = {
      title: labels.get('title'),
      type: 'warning',
      className: '',
      children,
      buttons,
    };

    messageDialogApi.open(dispatch, getState)(confirmationConfig);
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function _convertToRowsData(resources, templateData) {
  const sortedWeekdays = getSortedWeekdays();
  return (
    resources?.map((resource) => {
      const resourceAssignmentsPerDay = templateData?.find((r) => resource.id === r.resourceId)?.assignments || [];

      // Collect shifts that extend to the next week
      const lastDayAssignments = resourceAssignmentsPerDay.find((assignments) => assignments.day === sortedWeekdays[sortedWeekdays.length - 1].value);
      const shiftsExtendingToNextWeek = lastDayAssignments?.shifts
        ?.filter((shift) => {
          const shiftStartTime = convertTimeStringToDate(shift.startTime);
          const shiftEndTime = convertTimeStringToDate(shift.endTime);
          return shiftStartTime >= shiftEndTime;
        })
        .map((shift) => ({ ...shift, isShiftFromLastWeek: true }));

      // Update the first day of the week with shifts that extend to next week.
      if (shiftsExtendingToNextWeek?.length > 0) {
        const firstDayAssignments = resourceAssignmentsPerDay.find((assignments) => assignments.day === sortedWeekdays[0].value);

        if (firstDayAssignments) {
          firstDayAssignments.shifts = firstDayAssignments?.shifts
            ? [...firstDayAssignments.shifts, ...shiftsExtendingToNextWeek]
            : shiftsExtendingToNextWeek;
        } else {
          resourceAssignmentsPerDay.push({
            day: sortedWeekdays[0].value,
            shifts: shiftsExtendingToNextWeek,
          });
        }
      }

      resource.assignments = resourceAssignmentsPerDay;

      return resource;
    }) || []
  );
}

function _getAssignmentsToDelete(assignments, filterData) {
  switch (filterData.filterBy) {
    case filterTypes.ALL: {
      const flatAssignmentsData = _flattenShiftSchedulerData(assignments);
      return _convertAssignmentsToDeleteQueryData(flatAssignmentsData);
    }
    case filterTypes.SELECTED:
      return _convertAssignmentsToDeleteQueryData(assignments);
    case filterTypes.DAY:
    case filterTypes.RESOURCE:
    case filterTypes.DAY_AND_RESOURCE: {
      const filterFunc = _assignmentsFilterFunc(filterData);
      const flatAssignmentsData = _flattenShiftSchedulerData(assignments);
      const filteredAssignments = flatAssignmentsData.filter(filterFunc);
      return _convertAssignmentsToDeleteQueryData(filteredAssignments);
    }
    default:
      return [];
  }
}

function _assignmentsFilterFunc({ filterBy, day, resourceId }) {
  switch (filterBy) {
    case filterTypes.DAY:
      return (assignment) => assignment.shiftData.templateDto.day === day;
    case filterTypes.RESOURCE:
      return (assignment) => assignment.resourceData.id === resourceId;
    case filterTypes.DAY_AND_RESOURCE:
      return (assignment) => assignment.shiftData.templateDto.day === day && assignment.resourceData.id === resourceId;
  }
}

function _convertAssignmentsToDeleteQueryData(assignments) {
  const items = assignments.map((item) => ({
    shiftTypeId: item.shiftData.id,
    resourceId: item.resourceData?.id,
    day: item.shiftData.templateDto.day,
  }));

  return { items };
}

function _flattenShiftSchedulerData(assignmentsData) {
  return assignmentsData.flatMap((resource) => {
    const { assignments, ...resourceData } = resource;
    return assignments.flatMap((assignment) => {
      const { shifts, day } = assignment;
      return shifts.map((shift) => {
        return {
          resourceData,
          shiftData: { ...shift, day },
        };
      });
    });
  });
}

function _assignMessageDialogBuilder(errorsArray) {
  const dialogLabels = createLabelHelper('mat.administration.matsettings.weeklyShiftsTemplate.alert.failedAssignments.');

  const children = errorsArray.map((errorData, i) => {
    const label = dialogLabels.get('messageRow', '', errorData);
    return <MessageDialog.MessageRow key={i} text={label} />;
  });

  return { title: dialogLabels.get('title'), type: 'warning', className: '', children };
}

function _warningMessageDialogDescriptor(warnings) {
  let validations = dialogHelper.getValidationArea(warnings);

  if (validations && validations.length > 0) {
    let messages = [];
    validations.forEach((v) => {
      if (v && v.messages) {
        v.messages.forEach((m) => {
          messages.push(m);
        });
      }
    });

    if (messages.length > 0) {
      let title = messages[0] ? messages[0].message : 'Invalid Input';
      let type = 'warning';
      let className = 'oneBackground';

      return { title, type, className };
    }
  }
  return null;
}

import { Map } from 'immutable';
import { actionTypes as sideNavigatorActionTypes } from '../../actions/SideNavigator/sideNavigatorActions';

//check if we already load the current location, handle refresh scenario.
let currentLocation = {
  name: '',
  id: '',
  type: '',
  location: '',
  components: [],
};

let defaultState = {
  currentLocation: Map(currentLocation),
  show: false,
  locationsAndStations: [],
  favorites: [],
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case sideNavigatorActionTypes.SIDE_NAVIGATOR_FETCH_LOCATIONS_FINISHED:
      return state.set('locationsAndStations', action.payload);

    case sideNavigatorActionTypes.SIDE_NAVIGATOR_FETCH_FAVORITES_FINISHED:
      return state.set('favorites', action.payload);

    case sideNavigatorActionTypes.SIDE_NAVIGATOR_FAVORITE_SAVED:
      return state.set('favorites', [...state.get('favorites'), action.payload]);

    case sideNavigatorActionTypes.SIDE_NAVIGATOR_FAVORITE_DELETED: {
      const { entityId, entityType } = action.payload;
      const favorites = state.get('favorites').filter((favorite) => {
        return !(favorite.entityId === entityId && favorite.entityType === entityType);
      });

      return state.set('favorites', favorites);
    }

    default:
      return state;
  }
}

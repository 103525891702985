import { Component } from 'react';
import PropTypes from 'prop-types';
import Scheduler from 'infrastructure/js/components/Scheduler/schedulerDynamicImport';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import SchedulerOperationsView from './SchedulerOperationsView/schedulerOperationsView';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { getTAskAlertCategoryLabel } from '../../../utils/taskAlertHelper';
import SchedulerAnalyticModeContainer from './SchedulerAnalyticModePanel/SchedulerAnalyticModeContainer';
import Collapse from 'infrastructure/js/components/Collapse/Collapse';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import ToolTypeHelper from '../../../utils/toolTypeHelper';
import ErrorBoundary from '../../ErrorBoundary/schedulerErrorBoundary';
import { formatShiftSegment } from '../../Common/Helpers/ShiftsHelper';
import {getQuantityInfo} from '../../Common/Helpers/SchedulerHelper';

require('./schedulerPage.scss');

export default class SchedulerPage extends Component {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.scheduler.page.');
    this.analyticModeLabels = createLabelHelper('mat.scheduler.analyticMode.');
    this.statusLabels = createLabelHelper('enum.planneroperation.status.');
    this.scrollToNowOnInit = true;

    this.allLabels = createLabelHelper('');
    this.state = {
      analyticModeCollapsed: false,
      errorBoundaryKey: 0,
    };
  }

  componentDidMount() {
    this.props.actions.init(true);
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  setSchedulerEngineInstance = (scheduler) => {
    this.scheduler = scheduler;

    if (this.scrollToNowOnInit && scheduler) {
      this.props.actions.scrollToNow(scheduler);
      this.scrollToNowOnInit = false;
    }
  };

  getSchedulerEngineInstance = () => {
    return this.scheduler;
  };

  getLockedPart = (data) => {
    let isLocked = data?.eventRecord?.data?.locked;
    if (!isLocked) {
      return '';
    }
    return `<div class="lock-section"><span class="pl pl-square-regular"></span>${this.labels.get('task.tooltip.locked')}</div>`;
  };

  getAlertPart = (data) => {
    let alerts = data?.eventRecord?.data?.validationMessages;
    if (!alerts || !alerts.length) {
      return '';
    }

    let alertsHigh = alerts.filter((a) => {
      return a.severity === 'HIGH';
    });
    let alertsLow = alerts.filter((a) => {
      return a.severity !== 'HIGH';
    });

    let alertsHighSummary = alertsHigh?.length
      ? `<div><span class="pl pl-error-icon"><label>${this.labels.get('task.tooltip.alert.highLevel', undefined, {
          amount: alertsHigh.length,
        })}</label></span></div>`
      : '';
    let alertsLowSummary = alertsLow?.length
      ? `<div><span class="pl pl-warning-icon"><label>${this.labels.get('task.tooltip.alert.lowLevel', undefined, {
          amount: alertsLow.length,
        })}</label></span></div>`
      : '';

    let alertsHighDetails = alertsHigh?.length ? this.getAlertsBySeverity(alertsHigh, 'HIGH') : '';
    let alertsLowDetails = alertsLow?.length ? this.getAlertsBySeverity(alertsLow, 'LOW') : '';

    return `<div class="alert-section">
                <div id="eventTooltipAlertsSummary">
                    <button onclick="
                      const alertDetailsElement = document.getElementById('eventTooltipAlertsDetails');
                      const alertSummaryElement = document.getElementById('eventTooltipAlertsSummary');
                      const alertSummaryBodyElement = alertSummaryElement.getElementsByClassName('alert-summary-body')[0];
                      const arrowUp = alertSummaryElement.getElementsByClassName('pl-arrow-up')[0];
                      const arrowDown = alertSummaryElement.getElementsByClassName('pl-arrow-down')[0];

                      const isShown = alertDetailsElement.dataset.visible === 'false' ? true : false;
                      alertSummaryElement.classList.toggle('summary-collapsed');
                      alertDetailsElement.dataset.visible = isShown.toString();
                      alertDetailsElement.style.display = isShown ? 'block' : 'none';
                      arrowUp.style.display = isShown ? 'block' : 'none';
                      arrowDown.style.display = isShown ? 'none' : 'block';
                      alertSummaryBodyElement.style.display = isShown ? 'none' : 'block';
                    ">
                      <span class="pl pl-arrow-down"></span>
                      <span class="pl pl-arrow-up" style="display: none;"></span>
                    </button>
                    <div class="alert-summary-body" data-visible="true">
                      ${alertsHighSummary}
                      ${alertsLowSummary}
                    </div>
                </div>
                <div id="eventTooltipAlertsDetails" data-visible="false">
                    ${alertsHighDetails}
                    ${alertsLowDetails}
                </div>
              </div>`;
  };

  getAlertsBySeverity = (alerts, severity) => {
    if (alerts && alerts.length > 0) {
      let messages = alerts.map((alert) => {
        return `<div>
        <span class="category">${getTAskAlertCategoryLabel(alert.category) + ':'}</span>
        <span>${this.allLabels.get(alert.id, undefined, alert.params ? alert.params : undefined)}</span>
      </div>`;
      });

      let icon = severity === 'HIGH' ? 'alert-icon pl pl-error-icon error' : 'alert-icon pl pl-warning-icon warning';
      return `<div>
                <div class="${icon}"></div>
                ${messages.join('')}
              </div>`;
    }
  };

  getSplitTaskPart = (data) => {
    let isSplit = data?.eventRecord?.data?.split;
    if (isSplit) {
      return `<div class="split-task-section"><span class="pl pl-split-task-icon"></span>${this.labels.get('task.tooltip.splitTask')}</div>`;
    }
    return '';
  };

  getTooltipSectionIfExists = (value, title) => {
    if (!value) {
      return '';
    }
    return `<dt>${title}</dt>
                  <dd title="${value}">${value}</dd>`;
  };

  getTooltipSectionShifts = (shiftSegments, title) => {
    if (!shiftSegments || !shiftSegments.length) {
      return '';
    }

    const shiftSegmentsElements = shiftSegments.map((segment) => `<dd>${formatShiftSegment(segment, true)}</dd>`).join('');
    return `<dt>${title}</dt> ${shiftSegmentsElements}`;
  };

  getTooltipTemplate = (data) => {
    let schedulerData = data.eventRecord.data;
    let woName = data?.eventRecord?.data?.wo?.name;
    let woDescription = data?.eventRecord?.data?.wo?.description;
    let quantity = getQuantityInfo(data?.eventRecord?.data?.quantity, data?.eventRecord?.data?.workOrderQuantity);
    let kitTypeName = data?.eventRecord?.data?.kitType?.name;
    let kitTypeDescription = data?.eventRecord?.data?.kitType?.description;
    let materialName = data?.eventRecord?.data?.materialName;
    let plannedStartTime = schedulerData.plannedStartDisplayDate;
    let plannedEndTime = schedulerData.plannedEndDisplayDate;
    let actualStartTime = schedulerData.actualStartDisplayDate;
    let actualEndTime = schedulerData.actualEndDisplayDate;
    let estimatedStartTime = schedulerData.estimatedStartDisplayDate;
    let estimatedEndTime = schedulerData.estimatedEndDisplayDate;
    let plannedDuration = schedulerData.taskDuration ? DateTimeHelper.ConvertMinutesToHoursMinutes(schedulerData.taskDuration) : '';
    let actualDuration = schedulerData.actualDuration ? DateTimeHelper.ConvertMinutesToHoursMinutes(schedulerData.actualDuration) : '';
    let plannedResource = schedulerData.plannedResource ? schedulerData.plannedResource.name : '';
    let actualResource = schedulerData.actualResource ? schedulerData.actualResource.name : '';
    let status = schedulerData.operationStatus ? this.statusLabels.get(schedulerData.operationStatus) : '';
    let priority = schedulerData.priority ? schedulerData.priority : '';
    let recipe = schedulerData.recipe ? schedulerData.recipe : '';
    let compatibleToolType = schedulerData.compatibleToolType
      ? ToolTypeHelper.getToolTypeFullLabel(schedulerData.compatibleToolType.name, schedulerData.compatibleToolType.businessId)
      : '';
    let plannedToolId = schedulerData.plannedTool ? schedulerData.plannedTool.businessId : '';
    if (plannedToolId) {
      plannedToolId = plannedToolId + ' ' + getQuantityInfo(data?.eventRecord?.data?.quantity, data?.eventRecord?.data?.toolCapacity);
    }
    let actualToolId = schedulerData.actualTool ? schedulerData.actualTool.businessId : '';
    let splitAssignments = schedulerData?.splitAssignments;

    return `<div class="event-info-template">
              ${this.getAlertPart(data)}
              ${this.getLockedPart(data)}
              ${this.getSplitTaskPart(data)}
                <div class="tooltip-container">
                  <div class="tooltip-section">
                    ${this.getTooltipSectionIfExists(woName, this.labels.get('task.tooltip.wo'))}
                    ${this.getTooltipSectionIfExists(woDescription, this.labels.get('task.tooltip.woDescription'))}
                    ${this.getTooltipSectionIfExists(quantity, this.labels.get('task.tooltip.quantity'))}
                    ${this.getTooltipSectionIfExists(compatibleToolType, this.labels.get('task.tooltip.compatibleToolType'))}
                    ${this.getTooltipSectionIfExists(actualStartTime, this.labels.get('task.tooltip.startTime'))}
                    ${this.getTooltipSectionIfExists(actualEndTime, this.labels.get('task.tooltip.endTime'))}
                    ${this.getTooltipSectionIfExists(estimatedStartTime, this.labels.get('task.tooltip.estimatedStartTime'))}
                    ${this.getTooltipSectionIfExists(estimatedEndTime, this.labels.get('task.tooltip.estimatedEndTime'))}
                    ${this.getTooltipSectionIfExists(actualDuration, this.labels.get('task.tooltip.duration'))}
                    ${this.getTooltipSectionIfExists(actualResource, this.labels.get('task.tooltip.actualResource'))}
                    ${this.getTooltipSectionIfExists(status, this.labels.get('task.tooltip.status'))}
                    ${this.getTooltipSectionIfExists(priority, this.labels.get('task.tooltip.priority'))}
                     ${this.getTooltipSectionIfExists(recipe, this.labels.get('task.tooltip.recipe'))}
                    ${this.getTooltipSectionIfExists(materialName, this.labels.get('task.tooltip.material'))}
                    ${
                      PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled
                        ? this.getTooltipSectionShifts(splitAssignments, this.labels.get('task.tooltip.shifts'))
                        : ''
                    }
                   </div>

                   <div class="tooltip-section">
                     ${this.getTooltipSectionIfExists(kitTypeName, this.labels.get('task.tooltip.kitType'))}
                     ${this.getTooltipSectionIfExists(kitTypeDescription, this.labels.get('task.tooltip.kitTypeDescription'))}
                     ${this.getTooltipSectionIfExists(plannedStartTime, this.labels.get('task.tooltip.plannedStartTime'))}
                     ${this.getTooltipSectionIfExists(plannedEndTime, this.labels.get('task.tooltip.plannedEndTime'))}
                     ${this.getTooltipSectionIfExists(plannedDuration, this.labels.get('task.tooltip.plannedDuration'))}
                     ${this.getTooltipSectionIfExists(plannedResource, this.labels.get('task.tooltip.plannedResource'))}
                     ${this.getTooltipSectionIfExists(plannedToolId, this.labels.get('task.tooltip.plannedToolId'))}
                     ${this.getTooltipSectionIfExists(actualToolId, this.labels.get('task.tooltip.toolId'))}
                   </div>
                </div>
            </div>`;
  };

  getSchedulerData() {
    let schedulerData = this.props.sData.get('schedulerData');
    if (!schedulerData) {
      return null;
    }

    const eventsInDependencyLinesPath = new Set();

    let selectedWos = [
      ...new Set(
        this.props.sData.get('selectedGanttTasks').map((selectedTask) => {
          return selectedTask.wo ? selectedTask.wo.id : null;
        })
      ),
    ];

    schedulerData.selectedDependencies = schedulerData.dependencies.map((dependency) => {
      const isDependencyVisible = selectedWos.some((selectedWo) => dependency.relatedWos.includes(selectedWo));

      if (isDependencyVisible) {
        const { from, to } = dependency;
        eventsInDependencyLinesPath.add(from);
        eventsInDependencyLinesPath.add(to);
      }

      return {
        ...dependency,
        cls: isDependencyVisible ? 'visible-dependency' : 'hidden-dependency',
      };
    });

    return schedulerData;
  }

  getLoadingOverlay() {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }

  renderSchedulerAnalyticMode = () => {
    if (PermissionManager.hasSchedulerAnalyticModePermissions()) {
      return (
        <>
          <Collapse title={this.analyticModeLabels.get('collapseTitle')} onCollapseChange={this.handleCollapseChange}>
            <SchedulerAnalyticModeContainer />
          </Collapse>
          <div className="panel-divider"></div>
        </>
      );
    }
    return null;
  };

  handleCollapseChange = (collapseState) => {
    this.setState({ analyticModeCollapsed: collapseState });
  };

  getFirstPaneOverflowSize = () => {
    if (PermissionManager.hasSchedulerAnalyticModePermissions()) {
      return this.state.analyticModeCollapsed ? 146 : 202;
    }

    return 105;
  };

  resetErrorBoundary = () => {
    this.scrollToNowOnInit = true;
    this.setState({ errorBoundaryKey: this.state.errorBoundaryKey ? 0 : 1 });
  };

  render() {
    return (
      <div className="scheduler-page">
        <div className="scheduler-gantt-wrapper">
          <ErrorBoundary key={this.state.errorBoundaryKey} onError={this.resetErrorBoundary}>
            <Scheduler
              setSchedulerEngineInstance={this.setSchedulerEngineInstance}
              data={this.getSchedulerData()}
              actions={this.props.actions.schedulerGanttActions}
              refreshPage={this.props.actions.refreshPageData}
              loading={this.props.sData.get('loading')}
              selectedTasks={this.props.sData.get('selectedGanttTasks')}
              getTooltipTemplate={this.getTooltipTemplate}
              onEventChange={this.props.actions.schedulerGanttActions.onEventChange}
              onTaskMove={this.props.actions.schedulerGanttActions.onTaskMove}
              onTaskDelete={this.props.actions.schedulerGanttActions.onTaskDelete}
              onSelectionChanged={this.props.actions.schedulerGanttActions.onSelectionChanged}
              onTaskDrop={this.props.actions.schedulerGanttActions.onTaskDrop}
              onTaskLock={this.props.actions.schedulerGanttActions.onTaskLock}
              onTaskUnlock={this.props.actions.schedulerGanttActions.onTaskUnlock}
              backtrackList={this.props.sData.get('backtrackList')}
            />
          </ErrorBoundary>
          {this.getLoadingOverlay()}
        </div>

        <div className="scheduler-grid-wrapper">
          {this.renderSchedulerAnalyticMode()}

          <SchedulerOperationsView
            actions={this.props.actions}
            sSelectedRowsData={this.props.sSelectedRowsData}
            sScheduleTaskDialogData={this.props.sScheduleTaskDialogData}
            sAutoSchedulerDialogData={this.props.sAutoSchedulerDialogData}
            sCreateTaskDialogData={this.props.sCreateTaskDialogData}
            sEditTaskAttributesData={this.props.sEditTaskAttributesData}
            sHasRows={this.props.sHasRows}
            sCounters={this.props.sData.get('counters')}
            sSchedulerEngineInstance={this.getSchedulerEngineInstance()}
            sSchedulerData={this.props.sData.get('schedulerData')}
          />
        </div>
      </div>
    );
  }
}

SchedulerPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object,
};

SchedulerPage.defaultProps = {
  sData: {},
};

import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell';
import ValueWithStateCell from '../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {navigationStates} from '../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import MaterialHelper from '../../../../utils/materialHelper';
import MoreInfoCell from './MoreInfoCell/moreInfoCell';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {getAssetEtl} from '../../../../utils/assetHelper';
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./activeSpoolsGrid.scss');

class ActiveSpoolsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'spoolBusinessId', getOptions: false},
    {fieldName: 'material.businessId', filterName: 'spoolMaterial', getOptions: false},
    {fieldName: 'location', filterName: 'assetLocationName', getOptions: true},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: '', filterName: 'spoolLotLike'},
    {fieldName: '', filterName: 'spoolManufacturerLike'},
  ]

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.filterLabels = createLabelHelper('mat.grid.columns.moreInfo.filter.');
    this.etlLabels = createLabelHelper('enum.etl.type.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.INFO, alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.RESERVED, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          resizable: true,
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '', alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              locationName: params.data.locationName,
              pickListBusinessId: params.data.pickListBusinessId,
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'spoolMaterial',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_SPOOL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          resizable: true,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.spoolId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'spoolBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.SPOOL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          resizable: true,
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              type: navigationStates.SPOOL,
              label: params.data.businessId
            };
          }
        }
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetLocationName',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.locationId,
              type: navigationStates.LOCATION,
              label: params.data.locationName
            };
          }
        }
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.moreInfo.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        filterAlignment: filterAlignmentTypes.RIGHT,
        propertyTypes: this.getMoreInfoProperties(),
        columnOptions: {
          cellComponent: MoreInfoCell,
          sortable: false,
          valueGetter: (params) => {
            return {
              lot: params.data.lot,
              lengthLeft: params.data.m_lengthLeftLabel,
              weight: params.data.m_weightLabel,
              manufacturer: params.data.manufacturerModel ? params.data.manufacturerModel.name : ''
            };
          }
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change,
      },
    ]
  }

  getMoreInfoProperties() {
    return [
      {value: 'spoolLotLike', label: this.filterLabels.get('lot') || 'Lot'},
      {value: 'spoolManufacturerLike', label: this.filterLabels.get('manufacturer') || 'Manufacturer'},
    ];
  }


  render() {
    return (
      <div className="active-spools-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ACTIVE_SPOOLS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: () => {return 130;}
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'ActiveSpoolsGridForm'
  }
)(ActiveSpoolsGrid);



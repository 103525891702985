import { api as navigatorApi } from '../../AppTopbar/navigatorActions.js';
import {navigationStates} from '../../../enums/navigationStates';
import { api as adminGridApi} from './adminTabGridActions';
import { api as organizationGridApi} from './organizationsTabGridActions';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';


export const actionTypes = {
  ORGANIZATIONSPAGE_TAB_SELECTED: 'ORGANIZATIONSPAGE_TAB_SELECTED'
};

const actions = {
  tabSelected: function(payload) {
    return {type: actionTypes.ORGANIZATIONSPAGE_TAB_SELECTED, payload: payload };
  }
};

export let jsxActions = {};

jsxActions.onTabClick = function(tabKey) {
  return function(dispatch, getState) {
    api.activateTab(dispatch, getState)(tabKey);
  }
};

jsxActions.init = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    if (shouldRefreshGrid) {
      organizationGridApi.reload(dispatch, getState)();
      adminGridApi.reload(dispatch, getState)();
    } else {
      navigatorApi.setLoading(true)(dispatch, getState);
    }

    navigatorApi.setLoading(false)(dispatch, getState);
    api.setTopNavigator(dispatch, getState)(shouldRefreshGrid);
  }
};


export let api = {};

api.activateTab = function(dispatch, getState) {
  return function(tabKey) {
    dispatch(actions.tabSelected(tabKey));
  }
};


api.setTopNavigator = function(dispatch, getState) {
  return function() {

    let topNavigatorData = {
      name: createLabelHelper('mat.header.navigator.administration.').get('orgsmanagement'),
      type: navigationStates.ORG_MANAGEMENT,
      id: 0,
      components: []
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);

  };
};


import React from 'react';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import RelatedAssetsCell from '../../../../Common/CustomGridCells/RelatedAssetsCell/relatedAssetsCell.js';
import ValueWithStateCell from '../../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell.js';
import MoreInfoCell from '../../../../Common/CustomGridCells/MoreInfoCell/moreInfoCell.js';
import {navigationStates} from '../../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {getAssetEtl} from '../../../../../utils/assetHelper';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../../utils/alertFilterSectionHelper';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import {enumTypes, getEnumLabel, getEnumValue} from '../../../../../utils/enumHelper';
import {isShippingOrReceivingLocation} from '../../../../../utils/locationHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./kitsGrid.scss');

class KitsGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'status', filterName: 'kitStatus'},
    {fieldName: 'kitType.businessId', filterName: 'kitType', getOptions: true},
    {fieldName: 'businessId', filterName: 'kitBusinessId', getOptions: false},
    {fieldName: 'moreInfo'},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: '', filterName: 'kitWorkOrder'},
    {fieldName: '', filterName: 'kitPart'},
    {fieldName: '', filterName: 'kitProject'},
  ]

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.filterLabels = createLabelHelper('mat.grid.columns.moreInfo.filter.');
    this.etlLabels = createLabelHelper('enum.etl.type.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let customColumn = this.getColumnByLocationType();
    let isShippingOrReceivingLoc = isShippingOrReceivingLocation(this.props.locationDetails);

    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.DUE_DATE, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '', alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              dueDateLeft: params.data.dueDateLeft,
              dueDateMinutesLeft: params.data.dueDateMinutesLeft
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'kitType.businessId',
        title: this.labels.get('columns.kitType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.kitType?.businessId || ''
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.kitId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'kitBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.KIT_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: isShippingOrReceivingLoc ? LabelWithTooltipCell : EntityIdCell,
          valueGetter: (params) => {
            return isShippingOrReceivingLoc ? params.data.businessId :
            {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.KIT
            };
          }
        }
      },
      {...customColumn},
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.moreInfo.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        propertyTypes: this.getMoreInfoProperties(),
        filterAlignment: filterAlignmentTypes.RIGHT,
        width: 260,
        columnOptions: {
          cellComponent: MoreInfoCell,
          sortable: false,
          valueGetter: (params) => { return {
            part: params.data.partType ? params.data.partType.businessId : null,
            project: params.data.project ? params.data.project.businessId : null,
            workOrder: params.data.workOrder ? params.data.workOrder.businessId : null,
            workOrderId: params.data.workOrder ? params.data.workOrder.id : null
          };}
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change,
      },
    ]
  }

  getColumnByLocationType = () => {

    let {locationDetails} = this.props;

    if (locationDetails) {
      if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('RECEIVING_AREA')) {
        return {
          fieldName: 'assetStatus',
          title: this.labels.get('columns.status.title'),
          filterType: filterTypes.NONE,
          width: 100,
          columnOptions: {
            sortable: false,
            valueGetter: (params) => {
              return params.data.assetStatus ? getEnumLabel(enumTypes.ASSET_STATUS)(params.data.assetStatus)  : '';
            },
          }
        }
      }
    }

    return ( {
      fieldName: 'relatedAssetData',
      title: '',
      filterType: filterTypes.NONE,
      width: 70,
      columnOptions: {
        sortable: false,
        resizable: false,
        cellComponent: RelatedAssetsCell,
        valueGetter: (params) => {
          return {
            count: params.data.relatedAssetsCounter,
            cellAction: this.props.cellAction,
            assetId: params.data.id,
            componentAssetsTitle: this.labels.get('columns.componentAssets.cell.title'),
            resultingAssetsTitle: this.labels.get('columns.resultingAssets.cell.title')
          };
        },
        headerComponentParams : {
          headerIcon: 'component-assets',
          headerTooltip: this.labels.get('columns.componentAssets.cell.title'),
        }
      }
    } );
  }

  getMoreInfoProperties() {
    return [
      {value: 'kitWorkOrder',  label: this.filterLabels.get('workOrder') || 'Work Order'},
      {value: 'kitPart',     label: this.filterLabels.get('partType') || 'Part Type'},
      {value: 'kitProject',   label: this.filterLabels.get('project') || 'Project'},
    ];
  }

  render() {
    return (
      <div className="kits-grid">
        <Grid gridName={gridsNames.LOCATION_ASSET_KITS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: () => {return 130;}
                }
              }
        />
      </div>
    );
  }
}

export default reduxForm({
    form: 'AssetsView_KitsGrid'
  }
)(KitsGrid);



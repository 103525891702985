import * as groupsService from '../../services/Groups/groupsService';
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import * as AlertHelper from "../../utils/alertHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import {getEnumLabel, enumTypes} from '../../utils/enumHelper.js';

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ARCHIVE_GROUPS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return groupsService.fetchArchivedGroups(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    obj.m_ExpirationDaysLeft = obj.expirationDate ? DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds) : null;
    obj.m_archivingDate = DateTimeHelper.FormatDateObjectToDayMonth(obj.lastStatusChangeDate);
    obj.dueDateLeft = DateTimeHelper.GetDifferenceInDays(obj.workOrder && obj.workOrder.dueDate ? obj.workOrder.dueDate : 0, serverTimeSeconds);
    obj.assetStatusLabel = getEnumLabel(enumTypes.ASSET_STATUS)(obj.assetStatus);
    obj.alerts = obj.alerts.map((alert)=> {
      let expirationDaysLeft = (obj.expirationDate && obj.expirationDate.value ? DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds) : '');
      let alertDetails = {
        expirationDate: obj.expirationDate,
        expirationDaysLeft: expirationDaysLeft,
        exposureTimeLeftBond: obj.exposureTimeLeftBond,
        exposureTimeLeftCure: obj.exposureTimeLeftCure,
        woDueDaysLeft: obj.dueDateLeft,
        dueDateMinutesLeft: obj.dueDateMinutesLeft
      };

      alert.m_value = AlertHelper.GetAlertTypeMessageTitle(alert, alertDetails);
      return alert;
    });

    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import { Map } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';
import { actionTypes as actionTypes } from '../../../actions/Administration/MatSettingsPage/Dialogs/importKitTypesDialogActions.js';

let defaultState = {
  loading: false,
  show: false,
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.IMPORT_KIT_TYPES_DIALOG_SHOW:
      return state.set('show', true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.IMPORT_KIT_TYPES_DIALOG, defaultState);
  }

}













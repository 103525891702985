import React from 'react';
import Network from 'infrastructure/js/modules/network';
import userService from '../../../services/UserManagement/UserService';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import  { api as userManagementPageApi } from './userManagementPageActions';
import {UserAvailableActionsTypes} from '../../../enums/userAvailableActionsTypes';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as systemApi } from '../../System/systemActions.js';


export let jsxActions = {};

jsxActions.changeUsersStatus = function(queryData) {
  return function(dispatch, getState) {
    api.changeUsersStatus(queryData)(dispatch, getState);
  }
};

export let api = {};

api.changeUsersStatus = function(queryData) {
  return function(dispatch, getState) {

    systemApi.setLoading(true)(dispatch, getState);
    let method =  getState().system.get('featureFlags').ORGANIZATIONS ? userService.changeSystemUsersStatus : userService.changeUsersStatus;

    return method(queryData).then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Change Users Status Failed');
          messageDialogApi.responseError(dispatch, getState)(response);
          systemApi.setLoading(false)(dispatch, getState);
          return {success: false};
        }

        userManagementPageApi.reload()(dispatch, getState);

        let prevStatus = response.data ?  response.data.status : '';

        let messageDialogBuilder = utility.GetMessageDialogBuilderByType (queryData.newStatus , prevStatus);

        let messageDialogDescriptor = messageDialogBuilder(
          response.data,
          messageDialogApi.close(dispatch, getState)
        );

        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

        return {success: true};
      })
      .finally(() => {
        systemApi.setLoading(false)(dispatch, getState);
      })
  }
};

let utility = {};

utility.GetMessageDialogBuilderByType = (newStatus , prevStatus) => {
  return (data, fnCloseDialog) => {

    let label = '';
    let children;

    if(newStatus === UserAvailableActionsTypes.UNARCHIVE) {
      if(prevStatus.name === 'PREACTIVE') {label = 'preactive'}
      else if(prevStatus.name === 'ARCHIVED') {label = 'archived'}
      else if(prevStatus.name === 'ACTIVE') {label = 'active'}
      else if(prevStatus.name === 'LOCKED') {label = 'locked'}
    } else if (newStatus === UserAvailableActionsTypes.ARCHIVE) {
      label = 'archived';
    } else if (newStatus === UserAvailableActionsTypes.UNLOCK) {
      label = 'unlock';
    } else {
      console.error('unhandled user status type: ' + newStatus);
    }

    let changeUsersStatusLabels = createLabelHelper('mat.dialogs.usermanagement.confirmation.changestatus.');

    let rows = [
      {label: changeUsersStatusLabels.get(`${label}.primary`, '' , {UserName : data.username}), value: ''},
      {label: changeUsersStatusLabels.get(`${label}.secondary`), value: ''}
    ];

    if (data) {
      children = rows.map((item, index) => {
        return <MessageDialog.DataRow key={`confirmMessageDataRow${index}`} label={item.label} value={item.value}/>
      });
    }

    let title = changeUsersStatusLabels.get('header', '' , {UserName : data.username});
    let buttons = [{id:'close',text: 'close', action: fnCloseDialog, bsStyle: 'primary'}];
    let className = 'user-management-confirm-message';
    let messageDialogDescriptor = {title, children, buttons, className};
    return messageDialogDescriptor;
  }};

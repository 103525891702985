import * as orgsService from '../../../services/Administration/organizationsService';
import * as orgsHelper from "../../../components/Administration/OrganizationsPage/utils/organizationsHelper";
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "../../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_ADMIN_TAB, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return orgsService.fetchDeletionRequests();
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    let status = orgsHelper.getStatuses().find(item => {return (item.value === obj.orgStatus)});
    obj.m_status =  status ? status.label : '';
    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

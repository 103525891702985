import React, {Component} from 'react';

import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import {reduxForm} from 'redux-form';

import Dialog from 'infrastructure/js/components/Dialog/dialog';

require('./cancelKitReportDialog.scss');

class CancelKitReportDialog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.kitIdsToCancel = this.props.sData.get('kitIds') ? this.props.sData.get('kitIds').toJS() : [];

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.kitting.cancelkitreportdialog.');
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
        text: this.labels.get('footer.no'),
        action: this.props.actions.onCancel
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.cancelreport'),
          bsStyle: "primary",
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit)
        }
      ]
    };
  }

  getMessageDialogBuilder = () => {
    return () => {
      return {title: this.labels.get('confirmation.header', '', {count: this.kitIdsToCancel.length}), className: 'oneBackground'};
    };
  };

  onSubmit = (data) => {
    this.props.actions.cancelKitReport(this.kitIdsToCancel, this.getMessageDialogBuilder());
  };

  onEntered =() => {
  };

  render() {
    let {sData} = this.props;

    return (
      <Dialog
        id="cancel-kit-report-dialog"
        className="cancelKitReportDialog"
        titleText={this.labels.get('header.title', '', {count: this.kitIdsToCancel.length})}
        show={sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.props.actions.onCancel}
        sData={sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <span className='cancel-report-kit-body-text'>{this.labels.get('cancelkitreport')}</span>
      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'cancelKitReportDialog',
  }
)(CancelKitReportDialog);

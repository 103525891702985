import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as visibilityDevicesViewActions} from '../../../actions/Administration/RfidSettingsPage/visibilityDevicesViewActions'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as visibilityDevicesViewGridActions} from '../../../actions/Administration/RfidSettingsPage/visibilityDevicesViewGridActions';
import { jsxActions as createVisibleDeviceDialogActions} from '../../../actions/Administration/RfidSettingsPage/Dialogs/createVisibleDeviceDialogActions';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import VisibilityDevicesView from '../../../components/Administration/RfidSettingsPage/VisibilityDevicesView/visibilityDevicesView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(visibilityDevicesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      visibilityDevicesViewGridActions: bindActionCreators(visibilityDevicesViewGridActions, dispatch),
      createVisibleDeviceDialogActions : bindActionCreators(createVisibleDeviceDialogActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let VisibilityDevicesViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_VISIBILITY_DEVICES);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateVisibleDeviceDialogData: state.administration.getIn(['rfidSettingsPage', 'createVisibleDeviceDialog']),
      sData: state.administration.getIn(['rfidSettingsPage', 'visibilityDevicesViewData']),
    };
  },

  mapDispatchToProps
)(VisibilityDevicesView);

export default VisibilityDevicesViewContainer;



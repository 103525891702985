import PropTypes from 'prop-types';

import Button from 'infrastructure/js/components/controls/Button/button';

import { getSystemColors } from 'infrastructure/js/utils/colorHelper';
import { getPatternStyle } from 'infrastructure/js/components/DynamicIcons/TaskStarted';

import './taskButton.scss';

const getTaskStyle = (task) => {
  const colors = getSystemColors();

  const type = task.operationStatus.toLowerCase();
  const color = colors[type];

  if (!color) {
    console.error(`TaskButton: unknown system color for type ${type}`);
    return { backgroundColor: colors.scheduled };
  }

  if (type === 'started') {
    return getPatternStyle(color).react;
  }

  return { backgroundColor: color };
};

const TaskButton = ({ onClick, task }) => {
  const handleClick = () => {
    onClick(task);
  };

  const style = getTaskStyle(task);
  const { name } = task.task;

  return (
    <div className="task-button-wrapper">
      <Button id={'button-' + name} style={style} onClick={handleClick}>
        {name}
      </Button>
    </div>
  );
};

TaskButton.propTypes = {
  onClick: PropTypes.func,
  task: PropTypes.object,
};

TaskButton.defaultProps = {
  onClick: (task) => console.error('TaskButton: no onClick was provided', task),
  task: null,
};

export default TaskButton;

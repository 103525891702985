import React from 'react';
import PropTypes from 'prop-types';
import {formValueSelector, reduxForm} from 'redux-form';

import AddRemoveListExt from '../../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import PdfLabelPropertyListItem from '../PdfLabelPropertyListItem/pdfLabelPropertyListItem';
import Header, {headerItemsLabels} from '../../../../../Common/Header/header';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
import {connect} from 'react-redux';
require('./commonPdfLabelSettingsForm.scss');

class CommonPdfLabelSettingsForm extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;

    this.state = {listLength: 0}
  }

  componentDidMount() {
    this.initFormValues();
  }

  initFormValues = () => {
    let initialValues = this.getInitialValues(this.props.formData);
    this.props.initialize(initialValues);
  };

  getInitialValues = (items) => {
    if (items && items.pdfData) {

      let initialValues = {};

      if (items.pdfData.settings) {
        let pdfLabelsList = items.pdfData.settings.map((item) => {
          return {
            propertyName: (item.property ? item.property.id : item.additionalField ? item.additionalField.id : null),
            fontSize: item.fontFormat,
            fontBold: item.isBold,
            printBarcode: item.printBarcode,
            propertyType: item?.property?.propertyName
          }
        });
        initialValues.pdfLabelsList = pdfLabelsList;
      }

      return initialValues;
    }
    return null;
  };

  getHeaderItems() {
    return {
      buttons: [
        {
          id:'preview',
          label: headerItemsLabels.PREVIEW,
          className: 'no-icon',
          disabled: !this.props.pristine || this.state.listLength === 0,
          action: this.props.handleSubmit(this.onPreview),
        },
        {
          id:'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: this.props.pristine || this.state.listLength === 0,
          action: this.props.handleSubmit(this.onSubmit),
        },

      ],
    };
  }

  onPreview = (data) => {
    this.props.actions.preview(this.props.entityType);
  };


  onSubmit = (data) => {
    let newData = {
      settings: this.convertToSettings(data.pdfLabelsList),

    };

    this.props.actions.submit(newData, this.props.entityType, this.getMessageDialogBuilder())
      .then((response) => {
      if (response && response.success) { //Initialize form values and reset pristine.
        this.initFormValues();
      }
    });
  };

  convertToSettings = (pdfLabelsList) => {
    let allOptions = this.props.formData.pdfOptions;
    let res = pdfLabelsList.map((item, index) => {
      let option = allOptions.find((op) => {return op.data.id === item.propertyName});
      return {
          optionId: item.propertyName,
          fontFormat: item.fontSize,
          isBold: !!item.fontBold,
          printBarcode: !!item.printBarcode,
          isAdditionalField: (option && option.data ? option.data.isAdditionalField : false),
          columnChar: '' + (index + 1),
          columnIndex: index + 1,
        };
    });

    return res;
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.props.labels.get('confirmation.success' ,'', {entityType: this.getEntityName()});
      let className = 'oneBackground';
      let messageDialogDescriptor = {title, className};
      return messageDialogDescriptor;
    };
  };

  onListLengthChangedCallback = (length) => {
    this.setState({listLength: length});
  };

  getEntityName = () => {
    let {entityType} = this.props;
    switch (entityType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        return this.labels.get('confirmation.roll');
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        return this.labels.get('confirmation.spool');
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        return this.labels.get('confirmation.kit');
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        return this.labels.get('confirmation.group');
    }
    console.error('getEntityName(): unknown entity type ' + entityType);
    return '';
  };

  getPdfLabelPropertyListItem = (itemProps) => {
    const isBusinessID = this.props.fieldsValues[itemProps.index].propertyType === 'businessId';
    return <PdfLabelPropertyListItem {...itemProps} isBusinessID={isBusinessID}/>
  }

  render() {

    let {formData} = this.props;
    if (!formData) {
      return null;
    }

    let allOptions = formData.pdfOptions;
    let settings = formData.pdfData.settings;
    let preSelectedItemsIds = settings ? settings.map((item) => {
      return (item.property ? item.property.id : item.additionalField ? item.additionalField.id : null);
    }) : [];

    let headerItems = this.getHeaderItems();


    return (
      <div className='common-pdf-label-settings-form'>
          <Header {...headerItems}/>

          <div className="properties-list-header ">
            <label className="column">{this.labels.get('list.column.property')}</label>
            <label className="column column-2">{this.labels.get('list.column.fontFormat')}</label>
          </div>

          <AddRemoveListExt
                            {...this.props}
                            name={'pdfLabelsList'}
                            itemRendererComponent={this.getPdfLabelPropertyListItem}
                            itemRendererOptions={allOptions}
                            preSelectedItemsIds={preSelectedItemsIds}
                            addButtonLabel={this.labels.get('list.button.add')}
                            onListLengthChangedCallback={this.onListLengthChangedCallback}
                            defaultItem={{fontSize: 9}}
          />

      </div>
    );
  }
}

CommonPdfLabelSettingsForm = reduxForm({
    form: 'pdfLabelSettingsForm',
  }
)(CommonPdfLabelSettingsForm);

const selector = formValueSelector('pdfLabelSettingsForm');

export default CommonPdfLabelSettingsForm = connect( state => {
  return {  fieldsValues: selector(state, 'pdfLabelsList') }
})( CommonPdfLabelSettingsForm );

CommonPdfLabelSettingsForm.propTypes = {
  actions : PropTypes.object.isRequired,
  formData : PropTypes.object.isRequired,
};

//The function return relevant props, check if need to add the redux form props.

import React from "react";
import {createLabelHelper} from "../../utils/labelHelper";
import DateTimeHelper from '../../utils/dateTimeHelper'
import PermissionManager from "../../utils/permissionManager";
import AlertPopover, {AlertPopoverType} from '../popover/AlertPopover/alertPopover';

export function getPropsWithReduxForm(props){
  let propsToUse = {};

  // Detect if props are in Redux-Forms format.
  let isReduxForms = !!(props.input && props.meta);
  if (isReduxForms) {
    let {input, meta, onChangeCallback, validate, ...otherProps} = props;
    return {
      ...input,
      ...otherProps,
    };
  }
  else {
    // Regular props format.
    return props;
  }
}

export function getDateFormatIcon(){
  if(!PermissionManager.isDateFormatEnabled()){
    return null;
  }
  return (
    <AlertPopover
      popoverType={AlertPopoverType.HINT}
      popoverComponent={<div>{createLabelHelper('period.date.format.tooltip').get('', undefined, {format:DateTimeHelper.getDateFormat()})}</div>}
    >
    </AlertPopover>
  );
}

export function getDateFormatPlaceholder(){
  if(!PermissionManager.isDateFormatEnabled()){
    return '';
  }
  return (createLabelHelper('period.date.format.placeholder').get('', undefined, {format:DateTimeHelper.getDateFormat()}));
}




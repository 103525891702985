import React from 'react';

import PropTypes from 'prop-types';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import * as DashboardHelper from '../../../utils/dashboardHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import {reduxForm} from 'redux-form';
import {enumTypes, getLookupOptions} from '../../../../../utils/enumHelper';
require('./dashboardHeader.scss');

class DashboardHeader extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {};
    this.labels = createLabelHelper('mat.dashboard.header.');

    this.submitter = this.props.handleSubmit(this.onSubmit);

    this.state = {isDailyTimePeriod: false};
  }

  componentDidMount() {

    let initialData = this.props.initialData;

    let initialValues = {
      category: DashboardHelper.getOptionByValue(this.getCategoryOptions(), DashboardHelper.categories.KIT_TYPE),
    };

    if (this.props.showTypeFilter) {
      let typeOptions = this.getTypeOptions();
      let firstOptionValue = typeOptions && typeOptions.length > 0 ? typeOptions[0].value : -1;
      // initialValues.categoryType = DashboardHelper.getOptionByValue(this.getTypeOptions(), initialData ? initialData.itemId : -1);
      initialValues.categoryType = DashboardHelper.getOptionByValue(this.getTypeOptions(), initialData ? initialData.itemId : firstOptionValue);
    }
    if (this.props.showTimePeriodFilter) {
      initialValues.timePeriod = DashboardHelper.getOptionByValue(this.getTimePeriodOptions(), initialData ? initialData.timePeriod : DashboardHelper.timePeriods.THIS_MONTH);
    }
    if (this.props.showCompareToFilter) {
      initialValues.compareTo = DashboardHelper.getOptionByValue(this.getCompareToOptions(), initialData ? initialData.comparisonPeriod : DashboardHelper.comparisonPeriods.ANNUAL_AVERAGE);
    }
    if (this.props.showCauseFilter) {
      initialValues.cause = DashboardHelper.getOptionByValue(this.getCauseOptions(), DashboardHelper.causeTypes.TOOLS);
    }
    if (this.props.showWoStatusFilter) {
      initialValues.woStatus = DashboardHelper.getOptionByValue(this.getWoStatusOptions(), DashboardHelper.woStatuses.ACTIVE);
    }

    this.props.initialize(initialValues);

    setTimeout(() => {this.submitter()}, 0);
  }

  onSubmit = (data) => {
    if (this.props.onSubmitCallback) {
      this.props.onSubmitCallback(data);
    }
  };

  getTimePeriodOptions = () => {  //TODO: L do it in constructor
    let timePeriodOptions = getLookupOptions(enumTypes.DASHBOARD_TIME_PERIOD);
    let options = [];
    options.push({value: 'Daily', label: this.labels.get('timePeriod.daily'), isDisabled: true});
    options.push(DashboardHelper.getOptionByValue(timePeriodOptions, DashboardHelper.timePeriods.TODAY));
    options.push(DashboardHelper.getOptionByValue(timePeriodOptions, DashboardHelper.timePeriods.YESTERDAY));

    options.push({value: 'Monthly', label: this.labels.get('timePeriod.monthly'), isDisabled: true});
    options.push(DashboardHelper.getOptionByValue(timePeriodOptions, DashboardHelper.timePeriods.THIS_MONTH));
    options.push(DashboardHelper.getOptionByValue(timePeriodOptions, DashboardHelper.timePeriods.LAST_MONTH));
    options.push(DashboardHelper.getOptionByValue(timePeriodOptions, DashboardHelper.timePeriods.FULL_QUARTER));
    options.push(DashboardHelper.getOptionByValue(timePeriodOptions, DashboardHelper.timePeriods.HALF_YEAR));
    options.push(DashboardHelper.getOptionByValue(timePeriodOptions, DashboardHelper.timePeriods.FULL_YEAR));

    return options;
  };


  getCompareToOptions = () => {
    let compareOptions = getLookupOptions(enumTypes.COMPARISON_PERIOD);
    let options = [];
    options.push(DashboardHelper.getOptionByValue(compareOptions, DashboardHelper.comparisonPeriods.ANNUAL_AVERAGE));
    let opt = DashboardHelper.getOptionByValue(compareOptions, DashboardHelper.comparisonPeriods.SAME_PERIOD_LAST_YEAR);
    opt.isDisabled = (opt && this.state.isDailyTimePeriod) ? true : false;
    options.push(opt);
    return options;
  };

  getCategoryOptions = () => {
    let categories = getLookupOptions(enumTypes.DASHBOARD_CATEGORY);
    //Note: currently only Kit Type is enabled
    if (categories) {
      categories.forEach((item) => {
        if (item.value !== DashboardHelper.categories.KIT_TYPE) {
          item.isDisabled = true;
        }
      });
    }
    return categories;
  };

  getTypeOptions = () => {
    let headerData = this.props.headerData;
    return headerData && headerData.typeOptions ? headerData.typeOptions : [];
  };

  getCauseOptions = () => {
    return getLookupOptions(enumTypes.DEFECT_CAUSE);
  };

  getWoStatusOptions = () => {
    return getLookupOptions(enumTypes.DASHBOARD_WORK_ORDER_STATUS);
  };

  onChangeCallback = (newValue, oldValue) => {
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    setTimeout(() => {this.submitter()}, 0);
  };

  onCauseChangeCallback = (newValue, oldValue) => {
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    //prevent clearing
    if ( !newValue && oldValue && oldValue.value) {
      this.props.change('cause', oldValue);
      return;
    }
    if (this.props.onCauseChangeCallback) {
      this.props.onCauseChangeCallback(newValue);
    }
  };

  onTimePeriodChangeCallback = (newValue, oldValue) => {
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    if (newValue && (newValue.value === DashboardHelper.timePeriods.TODAY || newValue.value === DashboardHelper.timePeriods.YESTERDAY)) {
      this.props.change('compareTo', DashboardHelper.getOptionByValue(getLookupOptions(enumTypes.COMPARISON_PERIOD), DashboardHelper.comparisonPeriods.ANNUAL_AVERAGE));
      this.setState({isDailyTimePeriod: true});
    }
    else {
      this.setState({isDailyTimePeriod: false});
    }
    setTimeout(() => {this.submitter()}, 0);
  };

  getComponentToRender = (props)=> {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;

    return <span className={option.isDisabled ? 'option-title' : 'option-sub-item'}>{option.label}</span>
  };

  render() {
    return (
      <div className="dashboard-header">

        <InputSection label={this.labels.get('category')+'*'} htmlFor="category" className="inline">
          <Combobox id="category" name="category"
                    options={this.getCategoryOptions()}
                    validate={Validation.dropdown.required}
                    onChangeCallback={this.onChangeCallback}
          />
        </InputSection>

        {this.props.showTypeFilter && <InputSection label={this.labels.get('categoryType')+'*'} htmlFor="categoryType" className="inline">
          <Combobox id="categoryType" name="categoryType"
                    options={this.getTypeOptions()}
                    validate={Validation.dropdown.required}
                    onChangeCallback={this.onChangeCallback}
          />
        </InputSection>}

        {this.props.showTimePeriodFilter && <InputSection label={this.labels.get('timePeriod')+'*'} htmlFor="timePeriod" className="inline">
          <Combobox id="timePeriod" name="timePeriod" options={this.getTimePeriodOptions()}
                    validate={Validation.dropdown.required}
                    onChangeCallback={this.onTimePeriodChangeCallback}
                    optionRenderer={this.getComponentToRender}
          />
        </InputSection>}

        {this.props.showCompareToFilter && <InputSection label={this.labels.get('compareTo')+'*'} htmlFor="compareTo" className="inline">
          <Combobox id="compareTo" name="compareTo" options={this.getCompareToOptions()}
                    validate={Validation.dropdown.required}
                    onChangeCallback={this.onChangeCallback}
          />
        </InputSection>}

        {this.props.showCauseFilter && <InputSection label={this.labels.get('cause')+'*'} htmlFor="cause" className="inline">
          <Combobox id="cause" name="cause" options={this.getCauseOptions()}
                    //isDisabled={true}
                     validate={Validation.dropdown.required}
                    onChangeCallback={this.onCauseChangeCallback}
          />
        </InputSection>}

        {this.props.showWoStatusFilter && <InputSection label={this.labels.get('woStatus') +'*'} htmlFor="woStatus" className="inline">
          <Combobox id="woStatus" name="woStatus" options={this.getWoStatusOptions()}
                    validate={Validation.dropdown.required}
                    onChangeCallback={this.onChangeCallback}
          />
        </InputSection>}

      </div>
    );
  }
}

export default reduxForm({
  }
)(DashboardHeader);

DashboardHeader.defaultProps = {
  showTypeFilter: true,
  showTimePeriodFilter: true,
  showCompareToFilter: true,
  showCauseFilter: true,
  showWoStatusFilter: true,
};

DashboardHeader.propTypes = {
  showTypeFilter: PropTypes.bool,
  showTimePeriodFilter: PropTypes.bool,
  showCompareToFilter: PropTypes.bool,
  showCauseFilter: PropTypes.bool,
  showWoStatusFilter: PropTypes.bool,
};

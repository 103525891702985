import Network from 'infrastructure/js/modules/network';

const fetchItems = function (fileName, rollId, locationId) {
  let data = {fileName, rollId, locationId};
  return Network.post('selectNests/reportedCutsAndKits', data);
};

const validateFile = function (data) {
  return Network.polling.post('batch/validate/reportCutAndKits', data);
};

const getValidationInfo = function (data) {
  return Network.post('validation/reportedCutsAndKits', data);
};

const importFile = function (data) {
  let options = {pollingRequest: 'batchJobs/JOB_EXECUTION_ID/status-with-printing'};
  return Network.polling.post('batch/import/reportCutAndCreateKits', data, options);
};

const startImportFile = function (data) { //used by the batch operation with Progress bar
  return Network.post('batch/import/reportCutAndCreateKits', data);
};

const importNestsWithRollFetchItems = function (fileName, locationId) {
  let data = {fileName, locationId};
  return Network.post('selectNests/importCutPlanWithRoll ', data);
};
const importNestsWithRollValidateFile = function (data) {
  return Network.polling.post('batch/validate/importCutPlanWithRoll', data);
};
const importNestsWithRollGetValidationInfo = function (data) {
  return Network.post('validation/importCutPlanWithRoll', data);
};
const importNestsWithRollImportFile = function (data) {
  let options = {pollingRequest: 'batchJobs/JOB_EXECUTION_ID/status-with-printing'};
  return Network.polling.post('batch/import/cutPlanWithRoll ', data, options);
};



const importNestsMultiRollsFetchItems = function (fileName, rollIds, locationId) {
  let data = {fileName, rollIds, locationId};
  return Network.post('selectNests/reportedCutsAndKitsMultiRolls ', data);
};
const importNestsMultiRollsValidateFile = function (data) {
  return Network.polling.post('batch/validate/reportedCutsAndKitsMultiRolls', data);
};
const importNestsMultiRollsGetValidationInfo = function (data) {
  return Network.post('validation/reportedCutsAndKitsMultiRolls', data);
};
const importNestsMultiRollsImportFile = function (data) {
  let options = {pollingRequest: 'batchJobs/JOB_EXECUTION_ID/status-with-printing'};
  return Network.polling.post('batch/import/reportCutAndCreateKitsMultiRolls', data, options);
};

const importNestsAnyRollsFetchItems = function (fileName, rollIds, locationId) {
  let data = {fileName, rollIds, locationId};
  return Network.post('selectNests/reportedCutsAndKitsMultiRollsRobustCutting ', data);
};

const importNestsAnyRollsValidateFile = function (data) {
  return Network.polling.post('batch/validate/reportedCutsAndKitsMultiRollsRobustCutting', data);
};

const importNestsAnyRollsGetValidationInfo = function (data) {
  return Network.post('validation/reportedCutsAndKitsMultiRollsRobustCutting', data);
};

const importNestsAnyRollsImportFile = function (data) {
  let options = {pollingRequest: 'batchJobs/JOB_EXECUTION_ID/status-with-printing'};
  return Network.polling.post('batch/import/reportCutAndCreateKitsMultiRollsRobustCutting', data, options);
};



export default {fetchItems, validateFile , getValidationInfo, importFile, startImportFile,
                importNestsWithRollFetchItems, importNestsWithRollValidateFile, importNestsWithRollGetValidationInfo, importNestsWithRollImportFile,
                importNestsMultiRollsFetchItems, importNestsMultiRollsValidateFile, importNestsMultiRollsGetValidationInfo, importNestsMultiRollsImportFile,
                importNestsAnyRollsFetchItems, importNestsAnyRollsValidateFile, importNestsAnyRollsGetValidationInfo, importNestsAnyRollsImportFile
};

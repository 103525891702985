import React from 'react';

import PL_ComboBox from 'infrastructure/js/components/controls/Combobox/combobox.js'
import PropTypes from "prop-types";

require('./dropdown.scss');

//todo: missing proptypes validation
export default class PL_Dropdown extends React.PureComponent {
  render() {
    return (
      <PL_ComboBox className="pl-dropdown"
                   isSearchable={false}
                   {...this.props}
      />
    )
  }
}
PL_Dropdown.propTypes = {
  id:PropTypes.string.isRequired,
};

import { Map } from 'immutable';
import { actionTypes as scanToolPageActionTypes } from '../../../actions/Mobile/ScanToolPage/scanToolPageActions.js';

let defaultState = {
  loading: false,
  error : null
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case scanToolPageActionTypes.SCANTOOL_PAGE_SUBMIT_IN_PROGRESS:
      state = state.set('loading', true)
        .set('error', null);
      return state;
    case scanToolPageActionTypes.SCANTOOL_PAGE_SUBMIT_FINISHED:
      state = state.set('loading', false)
        .set('error', null);
      return state;
    case scanToolPageActionTypes.SCANTOOL_PAGE_SUBMIT_FAILED:
      state = state.set('loading', false)
        .set('error', action.payload);
      return state;
    case scanToolPageActionTypes.SCANTOOL_PAGE_CLEAR:
      return Map(defaultState);



    default:
      return state;
  }
}













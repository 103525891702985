import Network from 'infrastructure/js/modules/network';

export function fetchAssetTypes(query={}) {
  return Network.post('maf/assets/types/items', query);
}

export function fetchAllParents() {
  return Network.get('maf/assets/types');
}

export function fetchAllNatures() {
  return Network.get('maf/natures/defs');
}

export function fetchAllIcons() {
  return new Promise((resolve, reject) => {
    resolve({
      applicationResponseStatus: {
        statusType: 'OK'
      },
      dataList: [
        { value: 'roll-icon-blue', label: 'pl pl-roll-icon-blue'},
        { value: 'kit2-icon', label: 'pl pl-kit2-icon'},
        { value: 'tool-full-icon', label: 'pl pl-tool-full-icon'},
        { value: 'header-icon-location', label: 'pl pl-header-icon-location'},
        { value: 'station-icon', label: 'pl pl-station-icon'},
        { value: 'notification-line', label: 'pl pl-notification-line'},
        { value: 'chart', label: 'pl pl-chart'},
        { value: 'header-icon-roll', label: 'pl pl-header-icon-roll'},
        { value: 'header-icon-work_order', label: 'pl pl-header-icon-work_order'},
        { value: 'user', label: 'pl pl-user'},
        { value: 'menu-billing', label: 'pl pl-menu-billing'},
        { value: 'rfid-settings', label: 'pl pl-rfid-settings'}
      ]
    })
  });
}

export function fetchActionsDefs() {
  return Network.get('maf/natures/defs/actions/defs');
}

export function getAssetType(assetTypeId) {
  return Network.get(`maf/assets/types/${assetTypeId}`);
}


export function fetchPropsAndActionsByNaturesIds(data) {
  return Network.post('maf/assets/types/settings', data);
}

export function createAssetType(data) {
  return Network.put('maf/assets/types', data);
}

export function removeAssetType(data) {
  let assetTypeId = data.ids[0];
  return Network.delete(`maf/assets/types/${assetTypeId}`);
}

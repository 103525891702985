import {api as shiftsSchedulerApi} from './shiftsSchedulerActions'
import {api as shiftSettingsDialogApi} from './Dialogs/shiftSettingsDialogActions'
import PermissionManager from 'infrastructure/js/utils/permissionManager';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

export const actionTypes = {
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (){
  return function (dispatch, getState){
    return shiftsSchedulerApi.init(dispatch, getState)()
  }
}

jsxActions.showShiftSettingsDialog = function (data){
  return function (dispatch, getState){
    const isHumanCapacityEnabled = PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled;

    if (data.isTemplate && !isHumanCapacityEnabled) {
        return;
    }

    return shiftSettingsDialogApi.show(dispatch, getState)(data)
  }
}

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

import { fromJS } from 'immutable';
import { List , Map } from 'immutable';

import { default as overviewTabReducer } from './overviewTabReducer';
import { default as editKitMaterialsDialogReducer } from './editKitMaterialsDialogReducer';
import { default as weightWizardReducer } from './weightWizardReducer';

import { actionTypes as assetPageActionTypes }    from '../../actions/AssetPage/assetPageActions.js';
import { actionTypes as assetPageEditorActions }  from '../../actions/AssetPage/assetPageEditorActions.js';
import MaterialHelper from '../../utils/materialHelper';

let defaultState = {
  data: {
    asset: Map({
      id: null,
      assetStatus: null,
      objectType: null,
      locationId: null
    }),
    assetEditorData: {
      optionsAssetMaterials: [],
      optionsAssetLots: [],
      optionsAssetManufacturers: [],
      optionsAssetLocations: [],
      optionsAssetTags: [],
      optionsAssetToolTypes: [],
      optionsAssetWorkOrders: [],
      optionsAssetProjects: [],
      optionsAssetInspectionStatuses: [],
      additionalFields: {},
    },
    assetEditorOperationsData: {
      assetTagsOperation: {
        loading: false
      }
    }
  },
  hideDeleted: true
};


export default function(state = fromJS(defaultState), action) {

  state = state.set('overviewTab',  overviewTabReducer(state.get('overviewTab'), action));
  state = state.set('editKitMaterialsDialog',  editKitMaterialsDialogReducer(state.get('editKitMaterialsDialog'), action));
  state = state.set('weightWizard',  weightWizardReducer(state.get('weightWizard'), action));

  let currentAssetId = state.getIn(['data','asset','id']);

  switch(action.type) {

    case assetPageActionTypes.ASSETPAGE_FETCHDATA_FINISHED:
      state = state.setIn(['data', 'asset'], Map(action.payload));
      return state;

    case assetPageActionTypes.ASSETPAGE_CLEAR_DATA:
      state = state.setIn(['data', 'asset', 'id'], null)
                   .setIn(['data', 'asset', 'assetStatus'], null)
                   .setIn(['data', 'asset', 'objectType'], null);

      return state;

    case assetPageActionTypes.ASSETPAGE_UNMOUNT:
      return fromJS(defaultState);

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_MATERIALS_READY:
      return state.setIn(['data', 'assetEditorData', 'optionsAssetMaterials'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label:MaterialHelper.getMaterialFullLabel(obj.materialName,obj.businessId),  data: obj } })
      );

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_LOTS_READY:
      return state.setIn(['data', 'assetEditorData', 'optionsAssetLots'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.name } })
      );

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_MANUFACTURERS_READY:
      return state.setIn(['data', 'assetEditorData', 'optionsAssetManufacturers'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.name} })
      );

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_PROJECTS_READY:
      return state.setIn(['data', 'assetEditorData', 'optionsAssetProjects'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId} })
      );

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_INSPECTION_STATUSES_READY:
      return state.setIn(['data', 'assetEditorData', 'optionsAssetInspectionStatuses'],
        action.payload
      );

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_TAGS_READY:
      return state.setIn(['data', 'assetEditorData', 'optionsAssetTags'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.tagKey} })
      );
      return state;

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_PRINT_TAGS_FAILED:
      return state.setIn(['data', 'assetEditorOperationsData', 'assetTagsOperation', 'loading'], false);

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_PRINT_TAGS_IN_PROGRESS:
      return state.setIn(['data', 'assetEditorOperationsData', 'assetTagsOperation', 'loading'], true);

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_PRINT_TAGS_FINISHED:
      return state.setIn(['data', 'assetEditorOperationsData', 'assetTagsOperation', 'loading'], false);

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_LOCATIONS_READY:
      return state.setIn(['data', 'assetEditorData', 'optionsAssetLocations'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.name} })
      );

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_TOOL_TYPES_READY:
      return state.setIn(['data', 'assetEditorData', 'optionsAssetToolTypes'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId, data: obj} })
      );
    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_WORKORDERS_READY:
      state = state.setIn(['data', 'assetEditorData', 'optionsAssetWorkOrders'],
        action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId} })
      );
      return state;

    case assetPageEditorActions.ASSETPAGE_ASSETEDITOR_FETCH_ADDITIONAL_FIELDS_READY:
      return state.setIn(['data', 'assetEditorData', 'additionalFields'], action.payload);

    case assetPageActionTypes.ASSETPAGE_ATTACHMENT_DELETED_ITEMS:
      return state.set('hideDeleted',action.payload);

    default:
      return state;

  }

}










import React from 'react';
import PropTypes from 'prop-types';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';

require('./editTerminologyDialog.scss');

class EditTerminologyDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.settings.terminology.dialog.edit.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.state = {unPublishedValue: this.itemToEdit.actualOrgValue};
  }

  componentDidMount() {
    let initialValues = {
      unPublishedValue: this.itemToEdit.unPublishedValue,
    };

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: 'submit',
          text: this.labels.get('footer.update'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.pristine || this.props.sData.get('loading')
        }
      ]
    };
  }

  setDefaultValue = () => {
    this.props.change('unPublishedValue', this.itemToEdit.defaultValue);
    this.setState({unPublishedValue: this.itemToEdit.defaultValue});
  };
  setActualOrgValue = () => {
    this.props.change('unPublishedValue', this.itemToEdit.actualOrgValue);
    this.setState({unPublishedValue: this.itemToEdit.actualOrgValue});
  };



  onSubmit = (data) => {
    let newData = {
      localeId: this.props.localeId,
      labelsRequests: [
        {
          labelKey: this.itemToEdit.labelKey,
          unPublishedValue: data.unPublishedValue
        }
      ],
    };

    this.props.actions.submit(newData, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onChangeCallback = (val) => {
    this.setState({unPublishedValue: val});
  };



  render() {
    let editItem = this.itemToEdit;
    if (!editItem) {
      return null
    }
    let titleText = this.labels.get('header.title');
    return (
      <Dialog
        id="edit-pm-terminology-dialog"
        className="edit-pm-terminology-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('key')}
                      htmlFor="key"
                      className="full-width no-margin">
          <span className="key-value">{editItem.labelKey}</span>
        </InputSection>

        <div className="value-container">
          <InputSection label={this.labels.get('defaultValue')}
                        htmlFor="defaultValue"
                        >
            <span className="org-default-value">{editItem.defaultValue}</span>
          </InputSection>

          <InputSection htmlFor="orgActualValue" label={this.labels.get('actualOrgValue')}>
            <span className="org-default-value">{editItem.actualOrgValue}</span>
          </InputSection>
        </div>

        <InputSection htmlFor="orgActualValue" label={this.labels.get('replacement')}>
          <TextAreaField id="unPublishedValue" name="unPublishedValue"  onChangeCallback={this.onChangeCallback} />
        </InputSection>

        <div className="value-container">
          <InputSection className="no-margin">
            <a onClick={this.setDefaultValue}>{this.labels.get('enterDefaultValue')}</a>
          </InputSection>
          <InputSection className="no-margin">
            <a onClick={this.setActualOrgValue}>{this.labels.get('enterActualOrgValue')}</a>
          </InputSection>
        </div>

      </Dialog>
    );
  }
}

EditTerminologyDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'editTerminologyDialog',
  }
)(EditTerminologyDialog);

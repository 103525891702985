export function ConvertKitsDataToDialogItems(kits, currentCut) {
  let dialogItems = [];
  kits.forEach((kit, index) => {
    // Find currently chosen material.
    let currentMaterial = kit.materialOptions.find((kittedMaterial) => {
      return (kittedMaterial.mainMaterial && kittedMaterial.mainMaterial.id === currentCut.material.id);
    });
    if (!currentMaterial) {
      currentMaterial = kit.materialOptions.find((kittedMaterial) => {
        return (kittedMaterial.isSubstituteMaterial);
      });
    }
    if (!currentMaterial) {
      currentMaterial = kit.materialOptions[0];
    }
    dialogItems.push({
      totalRequiredPlies: kit.totalExpectedPlies,
      totalExistingPlies: kit.totalKittedPlies,
      missingPliesOfThisMaterial: Math.max((currentMaterial.quantityExpectedPlies - currentMaterial.quantityKittedPlies), 0),
      thisMaterialId: currentMaterial.id,
      mainMaterialId: currentMaterial.mainMaterial ? currentMaterial.mainMaterial.id : 0,
      substituteMaterialId: currentMaterial.isSubstituteMaterial ? currentMaterial.id : currentMaterial.mainMaterialId,
      kitType: kit.kitTypeBusinessId,
      kitId: kit.kitBusinessId,
      kitIdKey: kit.kitId,
      project: kit.projectBusinessId,
      workOrder: kit.workOrderBusinessId,
      pliesToAdd: null,
      materialOptions: kit.materialOptions,
      operationValidation: kit.operationValidation
    });
  });

  return dialogItems;
}

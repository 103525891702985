import React from 'react';
import PropTypes from 'prop-types';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import  {headerItemsLabels} from '../../../Common/Header/header';
import RfidHeader  from '../Common/rfidHeader';
import ReadersAndAntennasViewGrid from './ReadersAndAntennasViewGrid/readersAndAntennasViewGrid';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import CreateReaderDialog from './Dialogs/CreateReaderDialog/createReaderDialog';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import {RfidSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';

require('./readersAndAntennasView.scss');

class ReadersAndAntennasView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.rfidsettings.readersandantennas.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }
  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_READERS_AND_ANTENNAS);
    this.props.actions.init(false);
  }

  handleRemoveReader = () => {
    this.props.actions.onRemoveReaderClick(this.getMessageDialogBuilder(), this.props.sSelectedRowsData.get(0));
  }

  getMessageDialogBuilder = () => {
    return (fnCloseDialog , callback ) => {
      let title = this.labels.get('operation.removereader.confirmation.title');
      let type = 'warning';
      let message = this.labels.get('operation.removereader.confirmation.message');
      let children =  [<MessageDialog.DataRow key={`confirmMessageDataRow`} label={message} value={''}/>];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'remove',text: this.labels.get('operation.removereader.confirmation.button.remove'), action: callback  , bsStyle: 'primary'}
      ];
      let className = 'remove-reader_confirm-message';
      let messageDialogDescriptor = {title, buttons, children,  className, type};
      return messageDialogDescriptor;
    };
  };

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;
    return {
      dropdown: {
        name : 'deviceAppDropDown',
        id : 'deviceAppDropDown',
        options: this.props.sData.get('deviceAppsOptions'),
        disabled: this.props.sData.get('deviceAppsOptions').length <= 1,
        changeCallback: this.props.actions.onDeviceAppChanged,
      },
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          disabled: this.props.sData.get('deviceAppsOptions').length === 0,
          action: actions.header.onCreateReaderClick
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditReaderClick,
          actionData: selectedRowsData.get(0)
        },
        {
          id:'remove',
          label: headerItemsLabels.REMOVE,
          icon: 'pl pl-delete-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: this.handleRemoveReader
        }
      ],
      kebab: {}
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  renderGrid = () => {
    if(!this.props.sData.get('selectedDeviceApp')) {
      return null;
    }

    return <ReadersAndAntennasViewGrid actions={this.props.actions.readersAndAntennasViewGridActions} />;
  }

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();

    return (
      <div className="administration-view">
        <div className="title">{title}</div>
        <RfidHeader {...headerItems} change = {this.props.change} selectedDeviceApp={this.props.sData.get('selectedDeviceApp')}/>

        {this.renderGrid()}

        <PL_DialogWrapper dialogComponent={CreateReaderDialog}
                          show={this.props.sCreateReaderDialogData.get('show')}
                          actions={this.props.actions.createReaderDialogActions}
                          sData={this.props.sCreateReaderDialogData}
                          deviceApp={this.props.sData.get('selectedDeviceApp')}
                          reloadParentComponent={this.reloadPage}
        />

      </div>
    );
  }
}

export default reduxForm({
    form: 'ReadersAndAntennasForm'
  }
)(ReadersAndAntennasView);

ReadersAndAntennasView.propTypes = {
  sGrid: PropTypes.object,
};


import * as groupsService from '../../services/Groups/groupsService';
import * as AlertHelper from "../../utils/alertHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import assetAlertFilterHelper from "../../utils/assetAlertFilterHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ALERTED_GROUPS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    query = assetAlertFilterHelper.getAssetAlertFilteredQuery(query);
    return groupsService.fetchActiveGroups(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    obj.alerts = obj.alerts.map((alert)=> {
      let expirationDaysLeft = (obj.expirationDate && obj.expirationDate.value ? DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds) : '');
      let woDueDaysLeft = (obj.workOrder && obj.workOrder.dueDate) ? DateTimeHelper.GetDifferenceInDays(obj.workOrder.dueDate, serverTimeSeconds) : '';

      let alertDetails = {
        expirationDate: obj.expirationDate,
        expirationDaysLeft: expirationDaysLeft,
        exposureTimeLeftBond: obj.exposureTimeLeftBond,
        exposureTimeLeftCure: obj.exposureTimeLeftCure,
        woDueDaysLeft: woDueDaysLeft,
        dueDateMinutesLeft: obj.dueDateMinutesLeft
      };
      alert.m_value = AlertHelper.GetAlertTypeMessageTitle(alert, alertDetails);
      return alert;
    })
    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import React, { Component } from 'react';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import classNames from 'classnames';

import './allocatedAmountCell.scss';

export default class AllocatedAmountCell extends Component {
    constructor(props) {
        super(props);
    }

    getIcon(isSuccess) {
        return isSuccess ? 'pl pl-check-circle-icon-blue' : 'pl pl-info-icon-blue';
    }
    getBorder(isSuccess) {
        return isSuccess ? 'success' : 'warning';
    }

    render() {
        let isWeight = PermissionManager.isWeightSupported();
        let { requiredAmount, selectedAmount } = this.props.value;

        selectedAmount = UnitHelper.serverValueToUserValue(
            isWeight ? unitTypes.WEIGHT : unitTypes.LENGTH,
            selectedAmount,
            2
        ) ?? 0;

        let unitLabel = UnitHelper.getLabelForUnitType(
            isWeight ? unitTypes.WEIGHT : unitTypes.LENGTH
        );
        let fullLabel = requiredAmount
            ? `${selectedAmount}/${requiredAmount} ${unitLabel}`
            : `${selectedAmount} ${unitLabel}`;
        let isSuccess = selectedAmount >= requiredAmount;
        let icon = requiredAmount ? this.getIcon(isSuccess) : '';
        let border = requiredAmount ? this.getBorder(isSuccess) : '';

        return (
            <div className={classNames('quantity', border)}>
                <span className={icon} />
                <span>{fullLabel}</span>
            </div>
        );
    }
}

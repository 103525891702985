import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "../../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_RFID_SETTINGS_DEVICE_APPS, _fetchDataAction);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return deviceAppsService.fetchDeviceApps(query);
  }
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import React from 'react';
import RelatedAssets from './RelatedAssets/relatedAssets';
import AssetDetails from './AssetDetails/assetDetails';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import {isAssetArchived} from '../../../utils/assetHelper.js';

require('./overviewTab.scss');

export default class OverviewTab extends React.PureComponent {
  getLoadingOverlay() {
    if (!this.props.loading && !this.props.sAssetEditorOperationsData.getIn(['assetTagsOperation', 'loading'])) {
      return null;
    }
    return <Overlay.Loading />;
  }

  renderRelatedAssets() {
    return (
      <RelatedAssets
        resultingAssets={this.props.resultingAssets}
        componentAssets={this.props.componentAssets}
        equipmentAssets={this.props.equipmentAssets}
        parentAsset = {this.props.assetDetails}
        actions = {this.props.actions}
        loggedInUser = {this.props.sLoggedInUser}
      />
    )
  }

  render() {
    if (!this.props.assetDetails) {
      return null;
    }


    let assetDetailsProps = {
      asset: this.props.assetDetails,
      assetType: this.props.assetType,
      assetEditorData: this.props.sAssetEditorData.toJS(),
      loggedInUser:this.props.sLoggedInUser,
      actions: {
        assetEditorActions: this.props.actions.assetEditorActions,
        editKitMaterialsDialogActions: this.props.actions.editKitMaterialsDialogActions,
        weightWizardActions: this.props.actions.weightWizardActions,

      }
    };

    return (
      <div className={"asset-overview-view " + ((assetDetailsProps.asset && isAssetArchived(assetDetailsProps.asset.assetStatus)) ? "asset-archived" : "asset-active")}>
        {/*<AssetDetails asset={this.props.assetDetails} />*/}
        <AssetDetails {...assetDetailsProps} />

        {this.renderRelatedAssets()}

        {this.getLoadingOverlay()}
      </div>
    );

  }
}




import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {navigationStates} from '../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import LastOperationCell from '../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {alertStatus, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import ToolMoreInfoCell from '../../../Common/CustomGridCells/ToolMoreInfoCell/toolMoreInfoCell';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell';
import ToolTypeHelper from '../../../../utils/toolTypeHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./activeToolsGrid.scss');

class ActiveToolsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'assetAlert', filterName: 'assetAlert'},
    {fieldName: 'maxNumOfCycles', filterName: ''},
    {fieldName: 'currentCyclesNumber', filterName: 'currentCycleNumber'},
    {fieldName: 'toolType.businessId', filterName: 'toolType', getOptions: true},
    {fieldName: 'businessId', filterName: 'toolBusinessId', getOptions: false},
    { fieldName: 'toolType.toolCategory.businessId', filterName: 'toolCategoryBusinessId', getOptions: true },
    {fieldName: 'location', filterName: 'assetLocationName', getOptions: true},
    {fieldName: 'toolStatus', filterName: 'toolStatus', getOptions: true},
    {fieldName: 'lastOperation', filterName: 'toolLastOperationType', getOptions: true}
  ]

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.toolStatusLabels = createLabelHelper('enum.tool.status.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'assetAlert',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.NO_ALERT,alertStatus.WARNING,alertStatus.ERROR]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              cyclesCount: params.data.currentCyclesNumber,
              toolUsageTime: params.data.m_maintenanceUsageTime
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'toolStatus',
        title: this.labels.get('columns.status.title'),
        width: 140,
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolStatus',
        columnOptions: {
          valueGetter: (params) => {
            return params.data.toolStatus ? this.toolStatusLabels.get(params.data.toolStatus) : '';
          }
        }
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName:  'toolLastOperationType',
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams : {headerTextStyle: {marginLeft: '0px'}},
          valueGetter: (params) => {
            return params.data.lastOperation;
          }
        }
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.nextMaintenance.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ToolMoreInfoCell,
          sortable: false,
          valueGetter: (params) => {
            return {
              utl:params.data.m_maintenanceUsageTime,
              cyclesCount: params.data.currentCyclesNumber
            };
          }
        }
      },

      {
        fieldName: 'toolType.businessId',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({data}) => {
            return ToolTypeHelper.getToolTypeFullLabel(data.toolType.description, data.toolType.businessId);
          }
        }
      },

      {
        fieldName: 'businessId',
        title: this.labels.get('columns.toolId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'toolBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.TOOL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.TOOL
            };
          }
        }
      },
      {
        fieldName: 'toolType.toolCategory.businessId',
        title: this.labels.get('columns.toolCategory.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolCategoryBusinessId',
        columnOptions: {
          valueGetter: ({data}) => {
            return data.toolType?.toolCategory?.businessId || '';
          }
        }
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetLocationName',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.locationId,
              type: navigationStates.LOCATION,
              label: params.data.locationName
            };
          }
        }
      }
    ]
  }


  getRowHeight() {
    return 100;
  }

  render() {
      return (
        <div className="active-tools-grid">
          <Grid gridName={gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS}
                columnsConfig={this.columnsConfig}
                filterConfig={this.filterConfig}
                actions={this.props.actions}
                gridProps={
                  {
                    getRowHeight: this.getRowHeight
                  }
                }
          >
          </Grid>
        </div>
      );
    }
 }

export default reduxForm({
    form: 'activeToolsGrid'
  }
)(ActiveToolsGrid);

import Network from '../../../../infrastructure/js/modules/network';
import { api as printRfidTagsApi } from '../Dialogs/PrintRfidTagsDialog/printRfidTagsDialogActions';
import { api as systemApi } from '../System/systemActions';
import * as printerService from '../../services/Rfid/printersService';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import assetPrintTagsService from '../../services/Assets/assetPrintTagsService';
import * as printRfidTagsHelper from '../../components/LocationPage/Helpers/printRfidTagsHelper';
import { api as batchJobsApi } from '../BatchJobs/batchJobsActions.js';
import { api as assetPageApi } from '../AssetPage/assetPageActions';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.printEmptyRfidTags = function (dispatch, getState) {
  return function (locationId) {
    systemApi.setLoading(true)(dispatch, getState);
    printerService.fetchPrintersByLocation(locationId).then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }
      printRfidTagsApi.show(dispatch, getState)([], true, response.dataList);
    });

    systemApi.setLoading(false)(dispatch, getState);
  };
};

api.openPrintRfidTagsDialog = function (dispatch, getState) {
  return function (locationId, selectedAsset) {
    systemApi.setLoading(true)(dispatch, getState);
    printerService.fetchPrintersByLocation(locationId).then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }
      printRfidTagsApi.show(dispatch, getState)([], true, response.dataList, selectedAsset);
    });

    systemApi.setLoading(false)(dispatch, getState);
  };
};

api.printRfidTags = function (dispatch, getState) {
  return function (assetsIds, locationId, isPollingRequest = false) {
    systemApi.setLoading(true)(dispatch, getState);

    let promise;
    if (locationId) {
      promise = printerService.fetchPrintersByLocation(locationId);
    } else {
      let query = { assetsIds: assetsIds };
      promise = printerService.fetchPrintersByAssests(query);
    }

    return promise.then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        systemApi.setLoading(false)(dispatch, getState);
        return { success: false };
      }

      if (response.dataList.length === 0) {
        systemApi.setLoading(false)(dispatch, getState);
        return api.printTags(dispatch, getState)(assetsIds, null);
      }
      if (response.dataList.length === 1) {
        systemApi.setLoading(false)(dispatch, getState);
        return api.printTags(dispatch, getState)(assetsIds, response.dataList[0].id);
      }
      if (response.dataList.length > 1) {
        printRfidTagsApi.show(dispatch, getState)(
          assetsIds,
          false,
          response.dataList,
          isPollingRequest
        );
      }
      systemApi.setLoading(false)(dispatch, getState);
    });
  };
};

//todo - ran - this func is temp for the issue on the asset page only, should be removed after progressbar is completed
api.printRfidTagsWithPolling = function (dispatch, getState) {
  return function (assetsIds, locationId, type, businessId, messageBuilder) {
    systemApi.setLoading(true)(dispatch, getState);
    return printerService.fetchPrintersByLocation(locationId).then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        systemApi.setLoading(false)(dispatch, getState);
        return { success: false };
      }

      if (response.dataList.length === 0) {
        systemApi.setLoading(false)(dispatch, getState);
        return api.printTagsWithPolling(dispatch, getState)(
          assetsIds,
          null,
          type,
          businessId,
          messageBuilder
        );
      }
      systemApi.setLoading(false)(dispatch, getState);
      return api.printTagsWithPolling(dispatch, getState)(
        assetsIds,
        response.dataList[0].id,
        type,
        businessId,
        messageBuilder
      );
    });
  };
};

//todo - ran - this func is temp for the issue on the asset page only, should be removed after progressbar is completed
api.printTagsWithPolling = function (dispatch, getState) {
  return function (assetsIds, printerId, type, businessId, messageDialogBuilder) {
    systemApi.setLoading(true)(dispatch, getState);
    let queryData = {
      printerId: printerId ? printerId : null,
      assetIds: assetsIds,
    };

    assetPrintTagsService.printRfidTagsNewPolling(queryData).then((response) => {
      systemApi.setLoading(false)(dispatch, getState);
      switch (response.data.jobStatus) {
        case 'COMPLETED':
          return assetPageApi.reload(dispatch, getState)(type, assetsIds[0], true);
        case 'FAILED':
          let messageDialogDescriptor = messageDialogBuilder(
            utility.NormalizePrinterPollingResponseError(type, businessId)
          );
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          break;
        default:
          console.error('error in printTagsPrinterPollingRequestFinished');
      }
    });
  };
};

//todo - ran - this func is temp for the issue on the asset page only, should be removed after progressbar is completed
api.printTagsPrinterPollingRequestFinished = function (dispatch, getState) {
  return function (res, messageDialogBuilder) {
    switch (res.data.printStatus) {
      case 'PRINT_SUCCESS':
        dispatch(actions.printTagsFinished());
        assetPageApi.reload(dispatch, getState)(res.data.assetType, res.data.assetId, true);
        break;
      case 'PRINT_FAILED':
        dispatch(actions.printTagsFailed());
        let messageDialogDescriptor = messageDialogBuilder(
          utility.NormalizePrinterPollingResponseError(res)
        );
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        break;
      default:
        console.error('error in printTagsPrinterPollingRequestFinished');
    }
  };
};

api.printTags = function (dispatch, getState) {
  return function (assetsIds, printerId) {
    let queryData = {
      printerId: printerId ? printerId : null,
      assetIds: assetsIds,
    };

    assetPrintTagsService.printRfidTagsNew(queryData).then((response) => {
      // let messageDialogBuilder = printRfidTagsHelper.getMessageDialogBuilder();
      if (!Network.isResponseValid(response)) {
        // let responseErrorDescriptor = messageDialogBuilder(
        //   utility.NormalizeResponseError(response)
        // );
        // return messageDialogApi.open(dispatch, getState)(responseErrorDescriptor);
        console.error('Failed to print RFID tags', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      let pollingRequest = 'batchJobs/JOB_EXECUTION_ID/status-with-printing';

      batchJobsApi
        .runJob(dispatch, getState)(response.data, { pollingRequest })
        .then((jobResponse) => {
          return { success: true };
        })
        .catch((error) => {
          console.error('printTags.runJob failed, catch error: ', error);
          return { success: false };
        });
    });
  };
};

let utility = {};

utility.NormalizeResponseError = function (response) {
  let message;
  let statusType;
  let errorCode;

  if (
    !response ||
    !response.applicationResponseStatus ||
    !response.applicationResponseStatus.message
  ) {
    message = 'Print Tags Failed';
  } else {
    message = response.applicationResponseStatus.message;
    statusType = response.applicationResponseStatus.statusType.toLowerCase();
    errorCode = response.applicationResponseStatus.errorCode;
  }

  return {
    message,
    statusType: statusType,
    errorCode: errorCode,
  };
};

utility.NormalizePrinterPollingResponseError = function (type, id) {
  return {
    message: 'Failed To Print Tags On Asset ' + type + ' ' + id,
    statusType: 'error',
    errorCode: '0',
  };
};

import React, {Component} from 'react';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import CircularProgressBar from 'infrastructure/js/components/CircularProgressBar/circularProgressBar';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as BatchJobHelper  from '../../../utils/batchJobHelper';
require('./batchJobProgressDialog.scss');

export default class BatchJobProgressDialog extends Component {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.batchJob.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  getDialogButtons() {
    return {
      // left: [
      //   {
      //     id:'stop',
      //     text: this.dialogLabels.get('stop'),
      //     action: this.stopHandler,
      //     // disabled: this.props.sData.get('loading')
      //   }
      //
      // ],
      right: [
        {
          id:'notify',
          text: this.labels.get('progressDialog.buttons.runInBackground'),
          bsStyle: 'primary',
          action: this.runInBackgroundHandler,
        }
      ]
    };
  }

  stopHandler = () => {
    let {sData} = this.props;
    let jobInstanceId = sData.get('jobInstanceId');

    if (jobInstanceId) {
      this.props.actions.stop(jobInstanceId);
    }
  };

  onHide = () => {
    //TODO: L handle navigation case
  }

  runInBackgroundHandler = () => {
    let {sData} = this.props;
    let jobInstanceId = sData.get('jobInstanceId');

    if (jobInstanceId) {
      this.props.actions.runInBackground(jobInstanceId);
    }

    this.props.actions.hide();
  };


  render() {

    if (!this.props.show) {
      return null;
    }

    let {sData , boData} = this.props;
    let jobInstanceId = sData.get('jobInstanceId');

    if (!boData || !boData.get('allRunningJobs') || !boData.get('allRunningJobs')[jobInstanceId]) {
      return null;
    }

    let jobData = boData.get('allRunningJobs')[jobInstanceId];
    let title = BatchJobHelper.createJobTitle(jobData.jobName, jobData.totalJobItems, this.labels);
    let processedJobItems = jobData.numberOfSucceededItems + jobData.numberOfFailedItems + jobData.numberOfItemsWithWarnings;
    let bottomLabel = BatchJobHelper.createJobSubTitle(processedJobItems, jobData.totalJobItems, this.labels);
    let percentage = jobData.totalJobItems ? Math.floor(processedJobItems * 100 / jobData.totalJobItems) : 0;

    return (
      <Dialog id="batchJobProgressDialog"
              className='batch-job--progress-dialog'
              show={this.props.show}
              onHide={this.onHide}
              titleText={title}
              footerButtons={this.getDialogButtons()}
              animation={false}
      >
          <div className="top-label">{this.labels.get('progressDialog.labels.top')}</div>

          <div className="time-counter">{jobData.jobRunningDuration}</div>

          <CircularProgressBar radius={150} strokeWidth={14} percentage={percentage}/>

          <div className="bottom-label">{bottomLabel}</div>
      </Dialog>
    );
  }
}

import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../../../MessageDialog/messageDialogActions.js';
import * as deviceAppsService from '../../../../services/Rfid/deviceAppsService';
import * as mqttBrokersService from '../../../../services/Rfid/mqttBrokersService.js';
import * as daHelper                from '../../../Dialogs/dialogActionsHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import {dialogsNames} from '../../../../enums/dialogsNames';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_MQTT_BROKER_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_MQTT_BROKER_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  submit(dispatch, getState) {
    return function (data, isEditMode, reloadParentComponent) {
      dispatch(actions.setBusy(true));

      let deviceProfileQueryData = { //Use for getting deviceProfile data
        deviceOrgBusinessId: getState().login.get('loggedInUser').loggedInOrg.businessId,
        deviceBusinessId: data.deviceAppProfileId
      }

      return deviceAppsService.getDeviceProfile(deviceProfileQueryData).then((res) => {
        if (!Network.isResponseValid(res)) {
          dispatch(actions.hide());
          console.error('Create/Edit mqtt broker failed', res);
          messageDialogApi.responseError(dispatch, getState)(res);
          return {success: false};
        }

        let method = isEditMode ? mqttBrokersService.editMqttBroker : mqttBrokersService.createMqttBroker;

        let id = isEditMode ? data.id : res.data.profileId;
        delete data.deviceAppProfileId;
        delete data.id;

        method(id, data).then((response) => {
          dispatch(actions.setBusy(false));

          let validations = dialogHelper.getResponseValidationDetails(response);
          if (validations) {
            dispatch(actions.setValidationWarningsData(validations));
            return {success: false};
          }

          dispatch(actions.hide());

          if (!Network.isResponseValid(response)) {
            console.error('Create/Edit mqtt broker failed', response);
            messageDialogApi.responseError(dispatch, getState)(response);
            return {success: false};
          }

          if (reloadParentComponent) {
            reloadParentComponent();
          }
        });
      });
    };
  }
}

export let jsxActions = {
  ...daHelper.getJsxActions(api),
  submit(data, isEditMode, reloadParentComponent) {
    return function(dispatch, getState) {
      return api.submit(dispatch, getState)(data, isEditMode, reloadParentComponent);
    };
  }
}

import React, {Component, createElement} from 'react';
import ReactDOM from 'react-dom';

import {Field} from 'redux-form';
import Button from 'infrastructure/js/components/controls/Button/button';

import {reduxForm} from 'redux-form';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip'
import Label from 'infrastructure/js/components/Label/label.js';
import PropTypes from "prop-types";
import PL_DropdownButton from "../DropdownButton/dropdownButton";

//Style
require('./editInPlace.scss');



///////////////////////////////////////////////////////////////////////////
//
// EditInPlace
//
//
// id                 ===> Anything useful for QA element targeting.
// name               ===> Defines the name of the submit object's value property. E.g: name="totalTimeLeft" ===> sendData parameter will be: {totalTimeLeft: 35}.
// formName           ===> Name for the generated Redux-Form.
// serverValue        ===> Initial Value.
// serverDisplayValue ===> Label text.
//
// sendData           ===> Function that handles editor submit event. The function will receive an object in this format:
// {
// 	name: value
// }
// ...where the name is the name we defined in the EditInPlace props, and value is the React-Form Field value, or result of its unparse.
//
//
//
// parse              ===> How to convert Input value for storage inside Redux Form. Built in function into redux-form.
// unparse            ===> How to convert Redux Form value to server submit value. Custom function, not coming from Redux-Form.
// Note that this will not change the Name of the parameter inside the submit object, only the value will get converted.
//
//
// alert              ===> Single element to render inside label. (alert icon, E.g. alert={this.getAlert(asset, alertTypes.ExposureTime)} ).
// statusIcons        ===> Single element or an array of elements to render inside the label, in addition to the alert icon. (E.g. statusIcons={this.getInFreezer(asset)} )
//
//









export default class PL_EditInPlace extends React.PureComponent {


  constructor(props) {
    super(props);

    this.state = {

      editMode: false,

      ////////////////////////////////////
      // DEV: Extra behavior settings
      isDebugMode:          false,  // True ===> Disable labels, render static editor.
      isCancelOnBlur:       false   // True ===> Will revert to original value on Blur. False ===> Will Save new value on Blur.

    };


  }


  editModeON = () => {
    this.setState({editMode: true});
  };
  editModeOFF = () => {
    this.setState({editMode: false});
  };


  onLabelClick = () => {
    if (this.props.editable) {
      this.editModeON();
    }

  };

  onEditorEditComplete = () => {
    this.editModeOFF();
  };




  renderLabelStatusIcons = (statusIcons) => {

    // Handle array of status icons, or single icon, or nothing.

    if (!statusIcons) {
      return null;
    }

    // Make it into array if it's not already.
    if (!Array.isArray(statusIcons)) {
      statusIcons = [statusIcons];
    }

    return statusIcons.map((x, index) => {
      return (<span className="editor-label-status-icon" key={index}>{x}</span>);
    });

  };

  renderlabel = () => {

    if (this.state.isDebugMode) {
      return null;
    }
    if (this.state.editMode) {
      return null;
    }

    let serverDisplayValue = <Label text={this.props.serverDisplayValue}/>;

    return (
        <label onClick={this.onLabelClick} className={this.props.editable ? 'editable' : ''}>
          {serverDisplayValue}
          {this.props.alert}
          {this.renderLabelStatusIcons(this.props.statusIcons)}
        </label>
    );
  };

  renderEditor = () => {

    if (!this.state.editMode && !this.state.isDebugMode) {
      return null;
    }

    let {serverDisplayValue, ...editorProps} = this.props;

    editorProps.onEditComplete = this.onEditorEditComplete;
    editorProps.isCancelOnBlur = this.state.isCancelOnBlur;

    return (
        <EditorFormWrap {...editorProps}>
          {this.props.children}
        </EditorFormWrap>
    );
  };



  render() {
    let className = "edit-in-place";
    if(this.props.withAdditionalComponent){
      className += ' button'
    }
    return (
      <div className={className} id={this.props.id} onBlur={this.onEditInPlaceBlur}>
        {this.renderlabel()}
        {this.renderEditor()}
      </div>
    );
  }
}


PL_EditInPlace.defaultProps = {
  editable: true
};

function EditorFormWrap(props) {

  let {formName, serverValue, ...cleanProps} = props;

  // Prepare form initialValues.
  let initialValues = {};
  initialValues[props.name] = serverValue;

  // Save props for editor fields in organized manner for easier propagation.
  let editorProps = {...cleanProps};
  cleanProps.editorProps = editorProps;


  let reduxFormClass = reduxForm({
    form: formName,
    initialValues
  })(Editor);

  return React.createElement(
    reduxFormClass,
    {...cleanProps}
  );

}


class Editor extends React.PureComponent {

  constructor(props) {
    super(props);

    this.isCustomBlurBehavior = this.props.isCustomBlurBehavior;
    this.isCancelling         = false;
    this.isSaving             = false;
  }


  onCancelButtonMouseDown = () => {
    this.isCancelling = true;
    this.cancel();
  };

  onSaveButtonMouseDown = () => {
    this.isSaving = true;
    this.save();
  };



  onKeyDown = (e) => {
    if (e.key === 'Enter')  { this.save(); }
    if (e.key === 'Escape') { this.cancel(); }
  };


  onEditorBlur = (e) => {
    if (!this.isCustomBlurBehavior) {
      this.handleBlur();
    }
  };

  handleEditorCustomBlur = (e) => {
    this.handleBlur();
  };


  handleBlur = () => {

    if (this.isCancelling) {
      return;
    }

    if (this.isSaving) {
      return;
    }


    let isCancelOnBlur = this.props.editorProps.isCancelOnBlur;
    if (isCancelOnBlur) {
      this.cancel();
      return;
    }


    // Default.
    this.save();

  };


  cancel = () => {
    // this.props.reset(); // Reset form.
    this.props.editorProps.onEditComplete(); // Signal to hide editor.
  };

  save = () => {

    this.props.handleSubmit((formData) => {

      if (!this.props.pristine) {

        // Convert value to server format.
        if (this.props.unparse) {
          formData[this.props.name] = this.props.unparse(formData[this.props.name]);
        }
        formData.fieldName = this.props.name;
        this.props.sendData(formData);
      }

      this.props.editorProps.onEditComplete(); // Signal to hide editor.
    })();


  };


  renderEditorButtons = () => {

    return (
        <div className="buttons-container">
          <div>
            <Button className="btn-cancel pl pl-x-input-close"
                    name="cancelButton"
                    id="cancelButton"
                    onMouseDown={this.onCancelButtonMouseDown}
            />
            <Button className="btn-save pl pl-check-icon-blue-hover"
                    disabled={this.props.invalid}
                    id='save'
                    onMouseDown={this.onSaveButtonMouseDown}
            />
          </div>
        </div>
    );
  };

  render() {

    let {sendData, ...fieldProps} = this.props.editorProps;

    if (this.isCustomBlurBehavior) {
      fieldProps.onCustomBlur = this.handleEditorCustomBlur;
    }

    return (
      <div onKeyDown={this.onKeyDown} onBlur={this.onEditorBlur}>
        {this.renderEditorButtons()}
        <EditorInputsFieldWrap {...fieldProps} />
      </div>
    );
  }
}





class EditorInputsFieldWrap extends React.PureComponent {

  render() {
    return (
      <Field {...this.props} component={EditorInputsRenderer}></Field>
    );
  }
}

class EditorInputsRenderer extends React.PureComponent {


  render() {
    let childrenProps = {input: this.props.input, meta: this.props.meta};



    // Autofocus.
    childrenProps.autoFocus = true;

    // Custom Blur Behavior.
    if (this.props.onCustomBlur) {
      childrenProps.onCustomBlur = this.props.onCustomBlur;
    }

    // Add additional fieldProps to children.
    let childrenWithProps = React.Children.map(this.props.children,
      (child) => React.cloneElement(child, childrenProps)
    );

    return (
        <span className="inner-component-wrap">
          {childrenWithProps}
        </span>
    );

  }

}
PL_EditInPlace.propTypes = {
  id: PropTypes.string.isRequired,
};





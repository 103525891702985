import React, {Component} from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import classNames from 'classnames';
import ScanBottom from '../Common/ScanBottom/scanBottom';
import ScannedAssetListItem from '../ScannedAssetListItem/scannedAssetListItem';

require('./changeLocationResultPage.scss');

export default class ChangeLocationResultPage extends React.PureComponent {

  constructor(props) {
    super(props);

    let resultData = this.props.sPageData.get('resultData');
    if (!resultData ) {
      this.props.history.push('/ScanAsset');
    }

    this.labels = createLabelHelper('mat.mobile.scan.');
    this.pageLabels = createLabelHelper('mat.mobile.changeLocationResultPage.');
  }


  getHeaderItems() {
    let resultData = this.props.sPageData.get('resultData');
    if (!resultData || resultData.assetsDetails.size === 0) {
      return null;
    }

    let details = resultData.assetsDetails.toJS();
    let location = resultData.locationName;

    let failed =  details.filter((item) => {return item.batchJobStatus !== 'SUCCEEDED'});

    if (failed.length === 0) { //all succeeded
      return (
        <div>
          <span className="pl pl-mark-complete">{this.pageLabels.get('result.succeededToMove', undefined, {count: details.length})}</span>
          <span className="location-name">{location}</span>
        </div>
      );
    }
    else if (failed.length === details.length) { //all failed
      return (
      <div>
        <span className="pl pl-disabled">{this.pageLabels.get('result.failedToMove', undefined, {count: details.length})}</span>
        <span className="location-name">{location}</span>
      </div>
      );
    }
    return (
      <div>
        <span className="pl pl-mark-complete">{this.pageLabels.get('result.succeededToMove', undefined, {count: (details.length - failed.length)})}</span>
        <span className="location-name">{location}</span>
        <span className="pl pl-disabled">{this.pageLabels.get('result.failed', undefined, {count: failed.length})}</span>
      </div>
    );
  };

  getBottomItems() {
    return {
      button: {
        label: this.labels.get('bottom.button.newScan'),
        action: this.props.actions.header.onNewScan,
        actionData: this.props.history,
      },
    };
  };


  render() {
    let {sPageData} = this.props;
    if (!sPageData) {
      return null;
    }

    let resultData = sPageData.get('resultData');
    if (!resultData || !resultData.assetsDetails) {
      return null;
    }

    let assets = resultData.assetsDetails.toJS();

    let assetsList = assets.map((asset, index) => {
      return (
        <div className="list-item" key={asset.id}>
          <span className={ asset.batchJobStatus === 'SUCCEEDED' ? 'pl pl-mark-complete result-icon result-success' : 'pl pl-disabled result-icon result-failed'} />
          <ScannedAssetListItem asset={asset} />
        </div>
      );
    });

    let headerItems = this.getHeaderItems();
    let bottomItems = this.getBottomItems();

    return (
      <div className="change-location-result-page">
        <div className="result-header">
          {headerItems}
        </div>

        <div className="result-assets-list">
          {assetsList}
        </div>

        <ScanBottom {...bottomItems}/>

      </div>
    )
  }
}


ChangeLocationResultPage.propTypes = {
};

ChangeLocationResultPage.defaultProps = {
};


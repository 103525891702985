import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
require('./editKitMaterialListItem.scss');

export default class EditKitMaterialListItem extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  onChangeCallback = (value, oldValue) => {
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
  };

  render() {
    let {options, name} = this.props;

    return (<div className="kit-material-list-item">
      <Combobox id={ name + '.[materialName]'}
                name={ name + '.[materialName]'}
                validate={Validation.dropdown.required}
                options={options}
                isDisabled={true}
                onChangeCallback={this.onChangeCallback}

      />
      <TextField id={ name + '.[quantity]'}
                 name={ name + '.[quantity]'}
                 className="short-textfield" normalize={Normalize.number(true, 1, 999)}
                 disabled={true}
                 validate={Validation.required }

      />
      <TextField id={ name + '.[kitted]'}
                 name={ name + '.[kitted]'}
                 className="short-textfield"
                 normalize={Normalize.number(true, 0, 999)}
                 validate={Validation.number.required }

      />
      </div>);
  }
}

EditKitMaterialListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};





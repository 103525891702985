import { Map } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';
import { actionTypes as actionTypes } from '../../../actions/Administration/MatSettingsPage/Dialogs/createKitTypeDialogActions.js';

let defaultState = {
  loading: false,
  show: false,
  itemToEdit: null,
  dialogData: null,
  // additionalFields:[]
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.CREATE_KIT_TYPE_DIALOG_SHOW:
      return state.set('itemToEdit', action.payload)
        .set('show', true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.CREATE_KIT_TYPE_DIALOG, defaultState);
  }

  // switch (action.type) {
  //   case CreateKitTypeDialogActionsType.CREATE_KITTYPE_DIALOG_SHOW:
  //     state = state.set('itemToEdit', action.payload);
  //     return state.set('show',true);
  //
  //   case CreateKitTypeDialogActionsType.CREATE_KITTYPE_DIALOG_HIDE:
  //     return Map(defaultState);
  //
  //   case CreateKitTypeDialogActionsType.CREATE_KITTYPE_DIALOG_SUBMIT_IN_PROGRESS:
  //     return state.set("loading", true);
  //
  //   case CreateKitTypeDialogActionsType.CREATE_KITTYPE_DIALOG_SUBMIT_FINISHED:
  //     return state.set("loading", false);
  //
  //   case CreateKitTypeDialogActionsType.CREATE_KITTYPE_DIALOG_FETCH_DATA_READY:
  //     return state.set("dialogData", action.payload);
  //   default:
  //     return state;
  // }
}













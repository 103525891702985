import PropTypes from 'prop-types';

export default function DraggableShift({ children, shiftData }) {
  const { id, color, name } = shiftData;

  const dragStart = (e) => {
    e.dataTransfer.setData('data', JSON.stringify(shiftData));
    const dragImage = createDragImage();
    document.body.appendChild(dragImage);
    const centerX = 14;
    const bottomY = dragImage.offsetHeight / 2;
    e.dataTransfer.setDragImage(dragImage, centerX, bottomY);
  };

  const dragEnd = () => {
    document.getElementById('shift-drag-image').remove();
  };

  const createDragImage = () => {
    let node = document.createElement('div');
    let dragHandleElement = document.createElement('span');
    let nameElement = document.createElement('span');

    dragHandleElement.className = 'pl pl-draggable';
    nameElement.innerText = name;
    node.appendChild(dragHandleElement);
    node.appendChild(nameElement);

    node.id = 'shift-drag-image';
    node.className = `shift-drag-image ${id === 'eraser' ? 'eraser' : ''}`;
    node.style.backgroundColor = color;

    return node;
  };

  const dragOver = (e) => e.preventDefault();
  const dragEnter = (e) => e.preventDefault();
  const drop = (e) => e.preventDefault();

  return (
    <div id={id} draggable="true" onDragStart={dragStart} onDragOver={dragOver} onDragEnter={dragEnter} onDrop={drop} onDragEnd={dragEnd}>
      {children}
    </div>
  );
}

DraggableShift.propTypes = {
  children: PropTypes.node,
  shiftData: PropTypes.object,
};

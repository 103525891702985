import { Map } from 'immutable';
import { actionTypes as actionTypes } from '../../../actions/Administration/MatSettingsPage/digitalTwinViewActions';

let defaultState = {
  treeData: null,
  viewData: null,
  loading: false,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.DIGITAL_TWIN_FETCH_TREE_DATA_FINISHED:
      return state.set('treeData', action.payload);

    case actionTypes.DIGITAL_TWIN_FETCH_OPERATIONS_FINISHED:
      return state.set('viewData', action.payload);

    case actionTypes.DIGITAL_TWIN_SET_LOADING:
      return state.set('loading', action.payload);

      case actionTypes.DIGITAL_TWIN_UNMOUNT:
      return Map(defaultState)

    default:
      return state;
  }
}













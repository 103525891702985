import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';

import ActiveRollsContainer from '../../../containers/ActiveRollsPage/activeRollsContainer.js';
import AlertedRollsContainer from '../../../containers/AlertedRollsPage/alertedRollsContainer.js';
import ArchivedRollsContainer from '../../../containers/ArchivedRollsPage/archivedRollsContainer.js';

import './rollsPage.scss';

const tabsLabels = createLabelHelper('mat.common.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  ACTIVE: 'active',
  ALERTED: 'alerted',
  ARCHIVED: 'archived',
};

const RollsPage = () => {
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl(
    'PredefinedViews/rawMaterials/rolls',
    Object.values(tabKeys),
    'active',
    'materialStatus'
  );

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  return (
    <div className="rolls-page">
      <Tabs id="rolls-tabs" tabType="content" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('active')} eventKey={tabKeys.ACTIVE}>
          <ActiveRollsContainer />
        </Tab>
        <Tab title={tabsLabels.get('alerted')} eventKey={tabKeys.ALERTED}>
          <AlertedRollsContainer />
        </Tab>
        <Tab title={tabsLabels.get('archived')} eventKey={tabKeys.ARCHIVED}>
          <ArchivedRollsContainer />
        </Tab>
      </Tabs>
    </div>
  );
};

export default RollsPage;

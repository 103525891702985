import React, { Component } from 'react';

import { reduxForm } from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import Button from 'infrastructure/js/components/controls/Button/button';
import CreateKitForm from '../CreateKitForm/createKitForm';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { EntityPropertyTypes } from '../../../../../enums/entityPropertyTypes';
import { FetchEntitiesFilters } from '../../../../../enums/fetchEntitiesFilters';
import { getEnumValue, enumTypes } from '../../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from '../../../../../../../infrastructure/js/utils/uomHelper';
import uomHelper from '../../../../../../../infrastructure/js/utils/uomHelper';
import MaterialHelper from '../../../../../utils/materialHelper';
import Label from 'infrastructure/js/components/Label/label';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import EntitiesMultiSelect from '../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';

require('./createKitsDialog.scss');

export class CreateKitsDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.kitting.createkitwizard.');
    this.confirmationLabels = createLabelHelper('mat.locationpage.view.kitting.kittingOperation.confirmation.');
    this.dialogLabels = createLabelHelper('mat.dialog.');

    let initialData = this.props.sData.get('initialData');
    this.allTagsOptions = initialData ? initialData.freeTags : [];

    this.newKitFormReduxBaseName = 'newKitForm';

    this.initialAmount = PermissionManager.isWeightSupported()
      ? UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, this.props.currentCut.weightLeft, 2)
      : UnitHelper.serverValueToUserValue(unitTypes.LENGTH, this.props.currentCut.lengthCut - this.getReportedMaterialAmount());

    this.fetchCutsConfig = {
      entityType: EntityPropertyTypes.CUT_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ON_LOCATION],
      action: props.actions.fetchEntities,
      searchBy: {
        cut: [
          {
            objectName: 'kitTypeId',
            values: [this.props.sData.get('startParameters')?.kitTypeId],
          },
        ],
      },
    };

    this.state = {
      newKitsListSize: 1,
      selectedTabIndex: 1,
      newKitFormReduxCounter: 1,
      newKitFormReduxNames: [this.newKitFormReduxBaseName + '1'],
      tagsInUse: [],
      remainingAmount: this.initialAmount,
    };
  }

  getReportedMaterialAmount() {
    const startParameters = this.props.sData.get('startParameters');
    return startParameters?.existingKitsKittedPlies?.reduce((accumulator, currentPly) => accumulator + currentPly.materialUsedAmount, 0) || 0;
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.handleCancel,
        },
      ],

      right: [
        {
          id: 'handleNextButton',
          text: this.dialogLabels.get('next'),
          bsStyle: 'primary',
          action: this.handleNext,
          disabled:
            this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings')),
        },
      ],
    };
  }

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {
      return dialogHelper.BuildDialogDescriptorForKitOperations(data, this.confirmationLabels, fnCloseDialog, this.onCancelKitReportClick);
    };
  };

  getCurrentCut = () => {
    const cutSelectionAllowed = this.props.sData.get('startParameters')?.allowCutSelection;
    if (cutSelectionAllowed) {
      return this.props.sData.get('selectedCut');
    }

    return this.props.currentCut;
  };

  onCancelKitReportClick = (reportedKits, fnCloseDialog) => {
    this.props.actions.onCancelKitReportClick(reportedKits);
    fnCloseDialog();
  };

  selectKitTabHandler = (tabKey, e) => {
    this.props.handleSubmit((formData) => {
      this.setState({
        selectedTabIndex: tabKey,
      });
    })();
  };

  addKitClickHandler = () => {
    this.props.handleSubmit((formData) => {
      const consumedAmounts = this.state.newKitFormReduxNames.map(
        (formName) => formData[formName].materials.find((material) => material.isDefaultMaterial).amount
      );
      const consumedAmount = consumedAmounts.reduce((accumulator, currentAmount) => accumulator + currentAmount, 0);
      const newRemainingAmount = this.initialAmount - consumedAmount > 0 ? this.initialAmount - consumedAmount : 0;

      this.setState({
        newKitsListSize: this.state.newKitsListSize + 1,
        selectedTabIndex: this.state.newKitsListSize + 1,
        newKitFormReduxCounter: this.state.newKitFormReduxCounter + 1,
        newKitFormReduxNames: [...this.state.newKitFormReduxNames, this.newKitFormReduxBaseName + (this.state.newKitFormReduxCounter + 1)],
        tagsInUse: [...this.state.tagsInUse, null],
        remainingAmount: newRemainingAmount,
      });
    })();
  };

  deleteKitClickHandler = (tabKey, e) => {
    e.stopPropagation();
    e.preventDefault();

    let idsInUse = [...this.state.tagsInUse];
    idsInUse.splice(tabKey - 1, 1);

    let formNames = [...this.state.newKitFormReduxNames];
    let deletedName = formNames.splice(tabKey - 1, 1);
    this.props.clearFields(false, false, deletedName);

    this.setState({
      newKitsListSize: this.state.newKitsListSize - 1,
      selectedTabIndex: this.state.selectedTabIndex > 1 ? this.state.selectedTabIndex - 1 : 1,
      newKitFormReduxNames: formNames,
      tagsInUse: idsInUse,
    });
  };

  /*   getOptionsInUse = (itemIndex) => {
    let idsInUse = [...this.state.tagsInUse];

    //exclude the current item's selected option form this list
    if (idsInUse.length > itemIndex) {
      let itemId = idsInUse[itemIndex];
      if (itemId) {
        idsInUse = idsInUse.filter((id) => {
          return id !== itemId;
        });
      }
    }
    return idsInUse;
  };

  getAvailableTagsOptions = (itemIndex) => {
    let allOptions = this.allTagsOptions;
    let optionsInUse = this.getOptionsInUse(itemIndex);

    return allOptions.filter((option) => {
      return optionsInUse.indexOf(option.value) < 0;
    });
  }; */

  handleCancel = () => {
    this.props.actions.onCancel(this.getMessageDialogBuilder());
  };

  handleNext = () => {
    this.props.handleSubmit((data) => {
      let newKitsForSubmit = [];
      data.additionalFields = this.props.sData.get('initialData') ? this.props.sData.get('initialData').additionalFields : [];
      this.state.newKitFormReduxNames.forEach((kitFormName, index) => {
        if (data[kitFormName]) {
          newKitsForSubmit.push(data[kitFormName]);
        }
      });

      let convertKitMaterialsUnits = function convertKitMaterialsUnits(kit) {
        return {
          ...kit,
          materials:
            kit.materials?.map((material) => {
              let res = { ...material };
              if (material.amount) {
                res.amount = PermissionManager.isWeightSupported()
                  ? uomHelper.userValueToServerValue(unitTypes.WEIGHT, material.amount, 2)
                  : uomHelper.userValueToServerValue(unitTypes.LENGTH, material.amount, 2);
              }
              if (material.scrap) {
                res.scrap = PermissionManager.isWeightSupported()
                  ? uomHelper.userValueToServerValue(unitTypes.WEIGHT, material.scrap, 2)
                  : uomHelper.userValueToServerValue(unitTypes.LENGTH, material.scrap, 2);
              }
              return res;
            }) || [],
        };
      };

      let newData = {
        kits: newKitsForSubmit.map(convertKitMaterialsUnits),
      };

      newData.ignoreValidationWarnings =
        this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox ? data.footerValidationCheckbox : false;

      this.props.actions.saveNewKitsForSubmitData(newData, this.getCurrentCut(), this.props.wizardActions.next);
      //Note: workaround - to unselect the Validation check box when Back is clicked
      setTimeout(() => {
        this.props.change('footerValidationCheckbox', false);
      }, 0);
    })();
  };

  renderKitTabs() {
    let kitsTabsElementsArray = [];

    let kitsListSize = this.state.newKitsListSize;

    for (let i = 0; i < this.state.newKitsListSize; i++) {
      let tabIndex = i + 1;
      let activeKitTabKey = this.state.selectedTabIndex;

      // Delete tab button is available only for active tab, unless it's the only tab.
      let deleteButton =
        kitsListSize > 1 && +activeKitTabKey === tabIndex ? (
          <span
            className="kit-tab-delete-button"
            onClick={(e) => {
              this.deleteKitClickHandler(tabIndex, e);
            }}
          >
            X
          </span>
        ) : null;

      let tabTitle = (
        <div>
          <span>{this.labels.get('page1.tabs.title') + ' ' + tabIndex}</span>
          {deleteButton}
        </div>
      );

      kitsTabsElementsArray.push(
        <Tabs.Tab key={'new-kit-tab-' + this.state.newKitFormReduxNames[i]} eventKey={tabIndex} title={tabTitle}>
          <CreateKitForm
            preselctedWorkOrder={this.getPreselectedWoData()}
            currentCut={this.getCurrentCut()}
            remainingAmount={this.state.remainingAmount}
            name={this.state.newKitFormReduxNames[i]}
            sData={this.props.sData}
            allTagsOptions={this.allTagsOptions}
            {...this.props}
          />
        </Tabs.Tab>
      );
    }
    return kitsTabsElementsArray;
  }

  getPreselectedWoData = () => {
    const preselectedWo = this.props.sData.get('startParameters')?.workOrder;
    if (preselectedWo) {
      const initialData = this.props.sData.get('initialData');
      return initialData ? initialData?.workorders?.filter((wo) => wo.value === preselectedWo?.value)[0] : preselectedWo;
    }
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox')
      ? { label: this.dialogLabels.get('ignorewarnings'), onChange: this.onFooterValidationChange }
      : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  getReportedCutsOptions = () => {
    const startParameters = this.props.sData.get('startParameters');
    const reportedCuts = startParameters?.reportedCuts?.length ? startParameters.reportedCuts.filter((cut) => cut.value !== 'showAll') : [];

    return reportedCuts;
  };

  onCutChange = (newValue, oldValue) => {
    this.props.actions.onSelectedCutToKitChange(newValue?.data);
  };

  cutSelectRenderer = (props) => {
    if (!props || !props.data) {
      return null;
    }

    let item = props.data;

    let materialLabel = MaterialHelper.getMaterialFullLabel(item.data.material.materialName, item.data.material.businessId);

    return (
      <div className="cut-data-row">
        <Label className="material" text={materialLabel} />
        <Label className="roll-business-id" text={item.data.rollBusinessId} />
        <Label className="cut-id" text={`Cut ID ${item.data.id}`} />
        <Label className="time" text={item.data.m_CreatedDate} />
      </div>
    );
  };

  render() {
    const currentCut = this.getCurrentCut();
    let titleInfo = currentCut ? currentCut.material.businessId + ', Roll: ' + currentCut.rollBusinessId : '';
    let footerCenterText = this.labels.get('page1.footer.status') + ': ' + this.state.newKitsListSize;

    return (
      <Dialog
        id="create-new-kit-wizard"
        className="create-kits-dialog"
        show={true}
        onHide={this.handleCancel}
        sData={this.props.sData}
        titleText={this.labels.get('page1.header.title')}
        moreInfoTextHeader={titleInfo}
        footerCenterText={footerCenterText}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        style={this.props.style}
      >
        {this.props.sData.get('loading') && <Overlay.Loading />}

        {this.props.sData.get('startParameters')?.allowCutSelection && (
          <div className="cut-select">
            <span className="cut-select-label">{this.labels.get('page1.selectCut') + '*'}</span>
            <EntitiesMultiSelect
              name="cutsDropdown"
              id="cutsDropdown"
              isMulti={false}
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('REPORTED_CUT')]}
              fetchConfig={this.fetchCutsConfig}
              showEntitiesTypes={false}
              onValueChangeCallback={this.onCutChange}
              validate={Validation.required}
              singleValueRenderer={this.cutSelectRenderer}
              optionRenderer={this.cutSelectRenderer}
              {...this.props}
            />
          </div>
        )}

        <div className="new-kit-button">
          <Button icon="pl pl-icon-add" id="add-new-kit-tab" className="small-btn with-icon" bsStyle="default" onClick={this.addKitClickHandler}>
            {this.labels.get('page1.tabs.buttons.newkit')}
          </Button>
        </div>

        <Tabs id="create-new-kit-wizard-tabs" tabType="default" activeKey={this.state.selectedTabIndex} onSelect={this.selectKitTabHandler}>
          {this.renderKitTabs()}
        </Tabs>
      </Dialog>
    );
  }
}

export default reduxForm({
  forceUnregisterOnUnmount: true, // When this page isn't rendered, don't validate its fields.
  destroyOnUnmount: false, // When this page isn't rendered, don't destroy the form.
  onChange: (values, dispatch, props, previousValues) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
})(CreateKitsDialog);

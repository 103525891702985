import PermissionManager from 'infrastructure/js/utils/permissionManager';

export const categories = {
  PART: 'PART',
  KIT_TYPE: 'KIT_TYPE',
};

export const timePeriods = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  FULL_QUARTER: 'FULL_QUARTER',
  HALF_YEAR: 'HALF_YEAR',
  FULL_YEAR: 'FULL_YEAR',
};

export const comparisonPeriods = {
  ANNUAL_AVERAGE: 'ANNUAL_AVERAGE',
  SAME_PERIOD_LAST_YEAR: 'SAME_PERIOD_LAST_YEAR',
};

export const woStatuses = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
};

export const causeTypes = {
 TOOLS: 'TOOLS',
 OPERATIONS: 'OPERATIONS',
 LOCATIONS: 'LOCATIONS',
};

export const dashboardTypes = {
  PRODUCTION: 'PRODUCTION',
  WO_PROGRESS: 'WO_PROGRESS',
  CORRELATIONS: 'CORRELATIONS',
  TOOL_MAINTENANCE: 'TOOL_MAINTENANCE',
  INVENTORY_USAGE: 'INVENTORY_USAGE',
  INVENTORY: 'INVENTORY',
};

export const chartColors = [
  '#4B47BB',
  '#42A3F7',
  '#A4D9FF',
  '#82C67C',
  '#E8D519',
  '#EB8103',
  '#8884d8',
  '#CC31C9',
  '#367a87',
  '#4ea5b5',
  '#49c5db',
  '#E183C7',
  '#7CE1C0',
  '#7d2853',
  '#e89430',
  '#d9385c',
  '#b3b3b3',
  '#E1DE28',
  '#49CC63',
  '#F6F97E',
];

export function convertDataToOptions(data, labels) {
  if (data && data.items) {
    return data.items.map((item) => {
      return {value: item.name, label: labels.get(item.labelKey)}
    });
  }
  return [];
}

export function toPercentage(value, precision=2) {
  var multiplier = Math.pow(10, precision || 0);
  return (Math.round(value * multiplier) / multiplier) + '%';
}

export function getOptionByValue (options, value) {
  return options.find(option => option.value === value);
}

export function getKpiIcon(value, timeFrameValue) {
  return (value > timeFrameValue) ? 'fa fa-long-arrow-up' : (value < timeFrameValue) ? 'fa fa-long-arrow-down' : '';
}

export function getKpiTimeFrame(data, labels) {
  if ((data.timePeriod === timePeriods.TODAY) ||
    (data.timePeriod === timePeriods.YESTERDAY)) {
    return labels.get('kpi.averageDay');
  }
  if (data.comparisonPeriod === comparisonPeriods.ANNUAL_AVERAGE) {
    return labels.get('kpi.averageMonth'); //'Average month';
  }
  else if ((data.comparisonPeriod === comparisonPeriods.SAME_PERIOD_LAST_YEAR)){
    return labels.get('kpi.inPreviousYear');  //'In previous year';
  }
  return '';
}

export function getKpiDataClassName (value, timeFrameValue) {
  if (value === timeFrameValue) {
    return '';
  }
  if (value > timeFrameValue) {
    if (value - timeFrameValue > timeFrameValue * 0.2) {
      return 'red'
    }
  }
  return 'green';
}

export function isDemoMode(loggedInUser) {
  if (loggedInUser) {
    let orgStatus = loggedInUser?.loggedInOrg?.status;
    return (orgStatus === 'DEMO' && (PermissionManager.hasPSManagementTabPermissions()));
  }
  return false;
}

export function isDashboardDemoDataSet(settings, dashboardType) {
  if (settings) {
    let found = settings.find((item) => item.dashboardType === dashboardType);
    return (found && (found.isDefault || found.isCustom));
  }
  return false;
}


import React from 'react';
import PropTypes from 'prop-types';

import {Field} from 'redux-form';

require('./toggleButton.scss');

export default class PL_ToggleButtonForm extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_ToggleButton}></Field>
    );
  }
};

export class PL_ToggleButton extends React.PureComponent{

  onChange = (data) => {
    this.props.input.onChange(data);
    if(this.props.onChangeCallback){
      this.props.onChangeCallback(data.target.checked);
    }
  };

  render(){
    let {input = {}, meta, onChangeCallback, children, ...otherProps} = this.props;

    //Handle scenario when redux-form not wrapped component.
    let onChange = (!this.props.input) ? this.props.onChange : this.onChange;
    let value = (!this.props.input) ? this.props.value : input.value;

    return (
      <div className="pl-toggle-button">
        <div className="toggle-button-input">
          <input type="checkbox"  onChange={onChange} checked={value} {...otherProps} />
          {React.Children.map(children, child => <label htmlFor={otherProps.id}>{child}</label>)}
        </div>
      </div>
    );
  }
}

PL_ToggleButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

import { Map } from 'immutable';
import { actionTypes as navigatorActionTypes } from '../../actions/AppTopbar/navigatorActions';
import { actionTypes as systemActionTypes } from '../../actions/System/systemActions';
import { navigationStates } from '../../enums/navigationStates';
//check if we already load the current location, handle refresh scenario.
let currentLocation = {
  name: '',
  id: '',
  type: '',
  location: '',
  components: [],
};

const homePage = {
    type: '',
    id: '',
}

let defaultState = {
  currentLocation: Map(currentLocation),
  show: false,
  locationsAndStations: [],
  homePage: Map(homePage),
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case navigatorActionTypes.TOPNAVIGATOR_SHOW:
      return state.set('show', true);

    case navigatorActionTypes.TOPNAVIGATOR_HIDE:
      return state.set('show', false);

    case navigatorActionTypes.TOPNAVIGATOR_FETCH_LOCATIONS_FINISHED:
      return state.set('locationsAndStations', action.payload);

    case navigatorActionTypes.TOPNAVIGATOR_SET_HOME_PAGE:
      state = state.setIn(['homePage', 'type'], action.payload.type);
      state = state.setIn(['homePage', 'id'], action.payload.id);
      return state;

    case navigatorActionTypes.TOPNAVIGATOR_SETDATA: {
      let topNavigatorData = action.payload;
      //let {locationDetailsResult, stationAssetResult} = action.payload;
      state = state.setIn(['currentLocation', 'name'], topNavigatorData.name);
      state = state.setIn(['currentLocation', 'subname'], topNavigatorData.subname);
      state = state.setIn(['currentLocation', 'status'], topNavigatorData.status);
      state = state.setIn(['currentLocation', 'id'], topNavigatorData.id);
      state = state.setIn(['currentLocation', 'type'], topNavigatorData.type);
      state = state.setIn(['currentLocation', 'components'], topNavigatorData.components);
      return state;
    }

    case navigatorActionTypes.TOPNAVIGATOR_LOADING_MODE_CHANGED:
      if (action.payload === true) {
        state = state.setIn(['currentLocation', 'name'], '');
        state = state.setIn(['currentLocation', 'subname'], '');
        state = state.setIn(['currentLocation', 'status'], '');
        state = state.setIn(['currentLocation', 'id'], '');
        state = state.setIn(['currentLocation', 'type'], navigationStates.LOADING);
        state = state.setIn(['currentLocation', 'components'], []);
      }
      return state;
    default:
      return state;
  }
}

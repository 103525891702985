import dateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

import logo from '../../../../assets/images/login-logo.png';

const { TimeFormat } = dateTimeHelper;

const getPDFHeader = () => {
  const time = TimeFormat(new Date());
  return `
<div style="display:flex;justify-content:space-between;padding:30px;">
<img src='${logo}' />
<span>${time}</span>
</div>`;
};

export default getPDFHeader;

import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';

import TextField from 'infrastructure/js/components/controls/TextField/textField.js';
import PasswordField from '../PasswordField/passwordField';
import './editPasswordField.scss';


//Note: This uses internal redux-form Fields and must be used inside a redux form.
export default class EditPasswordField extends React.PureComponent {

  constructor(props) {
    super(props);

    this.innerName = `${this.props.name}_innerField`;

    this.state = {
      editing: !props.originalValue,
      originalValue: props.originalValue,
      currentValue: ''
    }

    this.pwFieldRef = null;
    this.setEditModeOnChange = this.state.editing;
  }

  componentDidMount() {
    if (this.props.setRef)
      this.props.setRef(this);
  }

  componentWillUnmount() {
    if (this.props.setRef)
      this.props.setRef(undefined);
  }

  isEditing = () => {
    return this.state.editing;
  }

  renderButton = (button) => {
    if (!button.render)
      return null;
    return (
      <Button disabled={button.disabled}
        id={this.props.id + button.id}
        className={'edit-password-field-button btn-primary'}
        onClick={button.onClick}
      >
        <span className={button.icon} />
        {button.text}
      </Button>
    );
  }


  onEditButtonClick = () => {
    this.setState({ editing: true, currentValue: '' },
      () => {
        this.props.change(this.innerName, '');
        if (this.props.onChangeMode)
          this.props.onChangeMode(true);
      }
    );
  }

  onConfirmButtonClick = () => {
    this.setState(
      { editing: false, originalValue: this.state.currentValue },
      () => {
        if (this.props.onChange)
          this.props.onChange(this.state.currentValue);
        this.props.change(this.props.name, this.state.currentValue);
        if (this.props.onChangeMode)
          this.props.onChangeMode(false);
      }
    );
  }

  onCancelButtonClick = () => {
    this.setEditModeOnChange = false;
    this.setState(
      { editing: false },
      () => {
        this.props.change(this.innerName, '');
        if (this.props.onChangeMode)
          this.props.onChangeMode(false);
      }
    );
  }

  onPWFieldChange = (value) => {
    this.setState({ currentValue: value });
    this.props.touch(this.innerName);
    if (this.setEditModeOnChange && this.props.onChangeMode) {
      this.props.onChangeMode(!!value);
    }
  }

  renderField = () => {
    let { name, change, touch, validate, id, onChange, onChangeMode, enableReveal, disableEditing, originalValue, setRef, ...props } = this.props;
    let buttonsAmountClassName = this.state.editing && this.state.originalValue ?
      'with-two-buttons'
      : 'with-one-button';

    return this.state.editing ?
      <PasswordField
        {...props}
        id={id}
        enableReveal={enableReveal}
        validate={validate}
        containerClassName={`edit-password-field-input edit-password-input-width ${buttonsAmountClassName}`}
        name={this.innerName}
        onChangeCallback={this.onPWFieldChange}
        setRef={(r) => { this.pwFieldRef = r; }}
      />
      :
      <TextField
        {...props}
        id={id}
        className={classnames(props.className, `edit-password-field-input edit-password-input-width ${buttonsAmountClassName}`)}
        name={name}
        type='password'
        disabled
      />;
  }

  isValueErrored = () => {
    if (this.props.validate) {
      switch (typeof(this.props.validate))
      {
        case 'function': {
          return !!this.props.validate;
        }
        case 'object': { //array
          for (let i in this.props.validate)
            if (this.props.validate[i](this.state.currentValue))
              return true;
          return false;
        }
        default:
          throw 'Validate can only be function or array';
      }
    }
    return false;
  }

  render() {
    let { editing, originalValue } = this.state;

    const editButton = {
        render: !editing,
        onClick: this.onEditButtonClick,
        id: '_edit',
        icon: 'pl pl-edit-icon',
        disable: this.props.disableEditing
      },
      confirmButton = {
        render: editing,
        onClick: this.onConfirmButtonClick,
        id: '_confirm',
        icon: 'fa fa-check',
        disabled: this.isValueErrored()
      },
      cancelButton = {
        render: editing && originalValue,
        onClick: this.onCancelButtonClick,
        id: '_cancel',
        icon: 'fa fa-times'
      };

    return (
      <div>
        {this.renderField()}
        {this.renderButton(editButton)}
        {this.renderButton(cancelButton)}
        {this.renderButton(confirmButton)}
      </div>
    )
  }
}

EditPasswordField.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired, //name of reduxForm field
  originalValue: PropTypes.string, //password value at time of initialization of the field, or null/undefined if none exist
  change: PropTypes.func.isRequired, //the reduxForm props.change function; this component handles form changes internally
  touch: PropTypes.func.isRequired, //the reduxForm props.change function
  onChange: PropTypes.func, //(value: string) => void; called on value confirm
  onChangeMode: PropTypes.func, //(isEditMode: bool) => void; called when switching between view mode and edit mode
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]), //password input field validation method(s)
  enableReveal: PropTypes.bool, //whether to show the eye icon to reveal password value
  disableEditing: PropTypes.bool, //disables changing the password
  setRef: PropTypes.func, //(ref: EditPasswordField) => void; gives an outside reference to this class. Useful for calling the isEditing method
}

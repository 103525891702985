import {api as gridApi} from './printersViewGridActions';
import Network from 'infrastructure/js/modules/network';
import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import * as printersService from '../../../services/Rfid/printersService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_RF_SETTINGS_PRINTERS_FETCH_DEVICE_APPS_IN_PROCESS: 'ADMINISTRATION_RF_SETTINGS_PRINTERS_FETCH_DEVICE_APPS_IN_PROCESS',
  ADMINISTRATION_RF_SETTINGS_PRINTERS_FETCH_DEVICE_APPS_READY: 'ADMINISTRATION_RF_SETTINGS_PRINTERS_FETCH_DEVICE_APPS_READY',
  ADMINISTRATION_RF_SETTINGS_PRINTERS_DEVICE_APP_CHANGED: 'ADMINISTRATION_RF_SETTINGS_PRINTERS_DEVICE_APP_CHANGED'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  fetchDeviceAppsInProgress: function () {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_PRINTERS_FETCH_DEVICE_APPS_IN_PROCESS};
  },
  fetchDeviceAppsFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_PRINTERS_FETCH_DEVICE_APPS_READY, payload};
  },
  deviceAppChanged: function (payload) {
    return {type: actionTypes.ADMINISTRATION_RF_SETTINGS_PRINTERS_DEVICE_APP_CHANGED, payload};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (shouldRefreshGrid) {
  return function (dispatch, getState) {
    api.load(dispatch, getState)(shouldRefreshGrid);
  }
};
jsxActions.onDeviceAppChanged = function (data) {
  return function (dispatch, getState) {
    api.deviceAppChanged(dispatch, getState)(data);
  }
};

jsxActions.onRemovePrintersClick = function(messageDialogBuilder , selectedPrintersIds) {
  return function(dispatch, getState) {
    api.openRemovePrintersConfirmationDialog(dispatch, getState)(messageDialogBuilder , selectedPrintersIds);
  }
};

jsxActions.removePrinters = function(data) {
  return function(dispatch, getState) {
    api.removePrinters(dispatch, getState)(data);
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.load = function (dispatch, getState) {
  return function (shouldRefreshGrid) {

    dispatch(actions.fetchDeviceAppsInProgress());

    return deviceAppsService.fetchDeviceApps().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('FETCH_DEVICE_APPS failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      let deviceAppsOptions = response.dataList.map((obj) => {
        return {value: obj.id, label: obj.username}
      });

      if (shouldRefreshGrid) {
        gridApi.reload(dispatch, getState)();
      }

      dispatch(actions.fetchDeviceAppsFinished(deviceAppsOptions));
    });
  }
};

api.deviceAppChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.deviceAppChanged(data));
    gridApi.reload(dispatch, getState)();
  }
};

api.openRemovePrintersConfirmationDialog = function(dispatch, getState) {
  return function(messageDialogBuilder , selectedPrintersIds) {

    let messageDialogDescriptor = messageDialogBuilder(
      messageDialogApi.close(dispatch, getState),
      () => api.removePrinters(dispatch, getState)(selectedPrintersIds)
    );

    messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

  }
};

api.removePrinters = function(dispatch, getState) {
  return function(selectedPrintersIds) {

    messageDialogApi.close(dispatch, getState)();

    printersService.deletePrinters(selectedPrintersIds).then((response) => {
      if(!Network.isResponseValid(response)) {
        console.error('Remove Printers Failed');

        messageDialogApi.responseError(dispatch, getState)(response);
        return {success : false};
      }
    });

    api.load(dispatch, getState)(true);
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as navigatorApi } from '../../AppTopbar/navigatorActions.js';
import {navigationStates} from '../../../enums/navigationStates';
import Network from "../../../../../infrastructure/js/modules/network";
import * as organizationService from "../../../services/Administration/organizationsService";
import {api as messageDialogApi} from "../../MessageDialog/messageDialogActions";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_BILLING_FETCH_DATA_STARTED: "ADMINISTRATION_BILLING_FETCH_DATA_STARTED",
  ADMINISTRATION_BILLING_FETCH_DATA_FINISHED: "ADMINISTRATION_BILLING_FETCH_DATA_FINISHED",
  ADMINISTRATION_BILLING_FETCH_BILLING_INFO_IN_PROGRESS: "ADMINISTRATION_BILLING_FETCH_BILLING_INFO_IN_PROGRESS",
  ADMINISTRATION_BILLING_FETCH_BILLING_INFO_IN_FINISHED: "ADMINISTRATION_BILLING_FETCH_BILLING_INFO_IN_FINISHED"
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function() {
    return {type: actionTypes.ADMINISTRATION_BILLING_FETCH_DATA_STARTED};
  },
  fetchDataFinished: function(orgs) {
    return {type: actionTypes.ADMINISTRATION_BILLING_FETCH_DATA_FINISHED, orgs};
  },
  fetchBillingInfoInProgress: function () {
    return {type: actionTypes.ADMINISTRATION_BILLING_FETCH_BILLING_INFO_IN_PROGRESS};
  },
  fetchBillingInfoFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_BILLING_FETCH_BILLING_INFO_IN_FINISHED, payload};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function(loadFirstTime) {
  return function(dispatch, getState) {
    api.init(dispatch, getState)(loadFirstTime);
  }
};

jsxActions.fetchBillingInfo = function(data) {
  return function(dispatch, getState) {
    return api.fetchBillingInfo(dispatch, getState)(data);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchBillingInfo = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.fetchBillingInfoInProgress());

    let query = {
      orgId: data.orgId,
      month: data.month,
      year: data.year,
    };

    organizationService.fetchBillingForOrg(query).then((response) => {

      if (!Network.isResponseValid(response)) {
        console.error('fetch billing failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        dispatch(actions.fetchBillingInfoFinished(response.data));
        return {success: false};
      }
      response.data.orgName = data.orgName;
      response.data.orgStatus = data.orgStatus;
      response.data.year = data.year;
      response.data.month = data.monthName;
      dispatch(actions.fetchBillingInfoFinished(response.data));
      return {success: true};
    });
  };
};

api.init = function(dispatch, getState) {
  return function() {
    let topNavigatorData = {
      name: createLabelHelper('mat.header.navigator.administration.').get('billing'),
      type: navigationStates.BILLING,
      id: 0,
      components: []
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);
    api.fetchOrgs(dispatch, getState)()
  }
};

api.fetchOrgs = function(dispatch, getState) {
  return function() {

    dispatch(actions.fetchDataStarted());
    return organizationService.fetchCurrentOrgAndChildren()
      .then((response) => {

        if (!Network.isResponseValid(response)) {
          console.error("Billing - Fetch orgs Data Failed");
          dispatch(actions.fetchDataFinished([]));
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }
        let orgs = response.dataList.map((org)=>{
          return {label: org.name, value: org.id, data: org};
        });
        dispatch(actions.fetchDataFinished(orgs));
      });
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import {navigationStates} from "../../../../../enums/navigationStates";
require('./devicePurposeCell.scss');

export default class DevicePurposeCell extends React.PureComponent {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.rfidsettings.dialog.createVisibleDevice.');

  }

  renderIsAppMonitor=(data)=>{
    if(!data.isAppMonitor){
      return;
    }
    return data.isAppMonitor ? (<div className="app-monitor">
                                  <span>{this.labels.get("appMonitor")}</span>
                                  <label>{`${this.labels.get("port")} ${data.appMonitorPort}`}</label>
                                  <label>{`${data.appMonitorIntervalMsec} ${this.labels.get("msec")}`}</label>
                               </div>)
                            : null

  };
  renderIsCloudMonitor=(isCloudMonitor)=>{
    return isCloudMonitor ? <div><label>{this.labels.get("cloudMonitor")}</label></div> : null
  };
  renderIsHumidityMonitor=(isHumidityMonitor)=>{
    return isHumidityMonitor ? <div><label>{this.labels.get("humidity")}</label> </div> : null
  };
  renderIsRfidMonitor=(isRfidMonitor)=>{
    return isRfidMonitor ? <div> <label>{this.labels.get("rfidMonitor")}</label></div>  : null
  };
  renderIsTemperatureMonitor=(isTemperatureMonitor)=>{
    return isTemperatureMonitor ? <div> <label>{this.labels.get("temperature")}</label></div> : null
  };


  render() {
    let data = this.props.value;
    if (!data) {
      return '';
    }

    return (<div className="device-purpose-cell">
      {this.renderIsAppMonitor(data)}
      {this.renderIsCloudMonitor(data.isCloudMonitor)}
      {this.renderIsHumidityMonitor(data.isHumidityMonitor)}
      {this.renderIsRfidMonitor(data.isRfidMonitor)}
      {this.renderIsTemperatureMonitor(data.isTemperatureMonitor)}
    </div>);
  }
}

DevicePurposeCell.propTypes = {
  value : PropTypes.object
};





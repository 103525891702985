import { api as locationPageApi } from '../locationPageActions.js';
import * as kitsService from '../../../services/Kits/kitsService';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_KITTING, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let selectedCutToKit = getState().cutKitStation.get('selectedCutToKit');
    let showKitsInLocation = getState().cutKitStation.get('showKitsInLocation');
    return kitsService.getKitsForUnfinishedReportedCuts(locationId, showKitsInLocation, selectedCutToKit, query);
  }
}


function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    let item = obj;
    return {
      id: item.id,
      status: {
        totalKittedPlies: item.totalKittedPlies,
        totalPlies: item.totalPlies,
        description: item.totalKittedPlies + "/" + item.totalPlies,
        capacityState: item.capacityState,

      },
      kitType: item.kitType.businessId,
      materials: item.kittedMaterials ? item.kittedMaterials.map((material)=> {

        let m_ExpirationDaysLeft = '';
        if(material.expirationDate && material.expirationDate.value){
          m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(material.expirationDate.value, serverTimeSeconds);
        }

        return {
          expectedQuantity: material.quantityExpectedPlies,
          hasWarning: material.hasWarning,
          mainMaterialId: material.mainMaterial.id,
          materialIdKey: material.isSubstituteMaterial ? material.substituteMaterial.id : material.mainMaterial.id,
          materialId:  material.isSubstituteMaterial ? material.substituteMaterial.businessId : material.mainMaterial.businessId,
          materialName: material.isSubstituteMaterial ? material.substituteMaterial.materialName : material.mainMaterial.materialName,
          isSubstituteMaterial: material.isSubstituteMaterial,
          quantity: material.quantityKittedPlies,
          isCurrentCut: material.currentlySelected,
          isAllMaterialKitted: material.quantityKittedPlies >= material.quantityExpectedPlies,
          isUncut: material.hasYetTobeCut,
          alerts: material.alerts,
          expirationDate: material.expirationDate,
          expirationDaysLeft: m_ExpirationDaysLeft,
          exposureTimeLeftBond: material.exposureTimeLeftBond,
          exposureTimeLeftCure: material.exposureTimeLeftCure,
        }}): [],
      moreInfo: {
        kitIdKey: item.id,

        kitId: item.businessId,
        project: item.project ? item.project.businessId : "",
        workOrder: item.workOrder ? item.workOrder.businessId : "",
        workOrderId: item.workOrder ? item.workOrder.id : null,
        locationName: item.locationName ? item.locationName : "",
      },
    };
  });

  return items;
};


export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;








import { actionTypes as smartSelectionHeaderActionTypes } from '../../../actions/SmartSelection/smartSelectionHeaderActions';
import {Map} from 'immutable';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

let defaultState = {
  isGridVisible: false,
  loading: false,
  workOrderOptions : [],
  locationOptions : [],
  materialOptions : [],
  pickDateOptions : [],

};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case smartSelectionHeaderActionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_DATA_STARTED:
      state = state.set('isGridVisible', false);
      state = state.set('loading', true);
      return state;

    case smartSelectionHeaderActionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_DATA_FINISHED:
      state = state.set('locationOptions', action.payload.locationOptions)
                    .set('materialOptions', action.payload.materialOptions)
                    .set('pickDateOptions', action.payload.pickDateOptions)
                    .set('projectOptions', action.payload.projectOptions)
                    .set('loading', false);
      return state;
    case smartSelectionHeaderActionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_WO_STARTED:
      return state.set('loading', true).set('loadingWOs', true);
    case smartSelectionHeaderActionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_WO_FINISHED:
      return state.set('workOrderOptions',  action.payload.wos)
        .set('loading', false)
        .set('loadingWOs', false);
    case smartSelectionHeaderActionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_MATERIALS_STARTED:
      return state.set('loading', true);

    case smartSelectionHeaderActionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_FETCH_MATERIALS_FINISHED:
      state = state.set('materialOptions', action.payload.materials);
      state = state.set('loading', false);
      return state;
    case smartSelectionHeaderActionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_UPDATE_HEADER_DATA:
      return state.set('isGridVisible', true)
        .set('selectLocation', action.payload.selectLocation)
        .set('selectMaterial', action.payload.selectMaterial)
        .set('selectWo', action.payload.selectWo)
        .set('selectProject', action.payload.selectProject)
      //there is no need to perform preference check because
      //woPickDate will be undefined if smart selection date range is enabled in org preferences,
      //and if it is not enabled, both woPickDateFrom and To will be undefined
        .set('selectWoPickDate', DateTimeHelper.ConvertFromDate(action.payload.woPickDate)?.epochDateTime || null)
        .set('selectWoPickDateFrom', DateTimeHelper.ConvertFromDate(action.payload.woPickDateFrom)?.epochDateTime || null)
        .set('selectWoPickDateTo', DateTimeHelper.ConvertFromDate(action.payload.woPickDateTo)?.epochDateTime || null)
        .set('requiredMaterial', action.payload.requiredMaterial);
    case smartSelectionHeaderActionTypes.PREDEFINED_LIST_SMART_SELECTION_HEADER_HIDE_GRID:
      return state.set('isGridVisible', false)
      default:
      return state;
  }
}

import { api as aboutDialogApi } from '../Dialogs/AboutDialog/aboutDialogActions';
import * as systemService from 'infrastructure/js/services/systemService';

export let jsxActions = {};

jsxActions.help = function () {
  return function (dispatch, getState) {
    let url = 'https://plataine.zendesk.com/';
    let win = window.open(url, '_blank');
    win.focus();
    return true;
  };
};

jsxActions.showUserGuide = function () {
  return function (dispatch, getState) {
    return api.showUserGuide()(dispatch, getState);
  };
};

jsxActions.showAboutTpo = function () {
  return function (dispatch, getState) {
    aboutDialogApi.show(dispatch, getState)();
  };
};

export let api = {};

api.showUserGuide = function () {
  return function (dispatch, getState) {
    systemService.getUserGuide().then((response) => {
      response.blob().then((blobResponse) => {
        if (blobResponse.size === 0) {
          console.error('show user guide failed');
          return { success: false };
        }

        var myURL = window.URL || window.webkitURL;
        var file = new Blob([blobResponse], { type: 'application/pdf' });
        var fileURL = myURL.createObjectURL(file);
        window.open(fileURL, '_blank');
      });
    });
  };
};

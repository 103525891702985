
import { destroy as formDestroy } from 'redux-form';





/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  WIZARD_INITSTEPS: 'WIZARD_INITSTEPS',
  WIZARD_START:     'WIZARD_START',
  WIZARD_CLOSE:     'WIZARD_CLOSE',
  WIZARD_GOTOSTEP:  'WIZARD_GOTOSTEP',

  WIZARD_KILLEMALL: 'WIZARD_KILLEMALL',

};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {

  initSteps: function(payload) {
    return {type: actionTypes.WIZARD_INITSTEPS, payload: payload};
  },
  start: function(payload) {
    return {type: actionTypes.WIZARD_START, payload: payload};
  },
  close: function(payload) {
    return {type: actionTypes.WIZARD_CLOSE, payload: payload};
  },
  gotoStep: function(payload) {
    return {type: actionTypes.WIZARD_GOTOSTEP, payload: payload};
  },

  killEmAll: function(payload) {
    return {type: actionTypes.WIZARD_KILLEMALL, payload: payload};
  },

};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};



jsxActions.initSteps = function(wizardName, stepsInfo) {
  return function(dispatch, getState) {
    api.initSteps(dispatch, getState)(wizardName, stepsInfo);
  }
};

jsxActions.start = function(wizardName) {
  return function(dispatch, getState) {
    api.start(dispatch, getState)(wizardName);
  }
};

jsxActions.close = function(wizardName) {
  return function(dispatch, getState) {
    api.close(dispatch, getState)(wizardName);
  }
};

jsxActions.gotoStep = function(wizardName, stepKey) {
  return function(dispatch, getState) {
    api.gotoStep(dispatch, getState)(wizardName, stepKey);
  }
};

jsxActions.next = function(wizardName) {
  return function(dispatch, getState) {
    api.next(dispatch, getState)(wizardName);
  }
};

jsxActions.previous = function(wizardName) {
  return function(dispatch, getState) {
    api.previous(dispatch, getState)(wizardName);
  }
};



// CONVENIENCE ADAPTER FOR JSX ACTIONS.
jsxActions.createWizardSpecificAdapter = (wizardName) => {
  let adapter = {};

  adapter.initSteps = (stepsInfo) => { return jsxActions.initSteps(wizardName, stepsInfo); };
  adapter.start     = () => { return jsxActions.start(wizardName); };
  adapter.close     = () => { return jsxActions.close(wizardName); };
  adapter.gotoStep  = (stepKey) => { return jsxActions.gotoStep(wizardName, stepKey); };
  adapter.next      = () => { return jsxActions.next(wizardName); };
  adapter.previous  = () => { return jsxActions.previous(wizardName); };


  return adapter;
};



/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.initSteps = function(dispatch, getState) {
  return function(wizardName, stepsInfo) {

    if (!wizardName) {
      return null;
    }

    let stepsData = buildStepsDataObject(stepsInfo);

    // Artificial delay/yield to enable proper autofocusing of wizard form fields.
    setTimeout(function() {
      dispatch(actions.initSteps({wizardName, stepsData}));
    }, 0);

  }
};

api.start = function(dispatch, getState) {
  return function(wizardName) {

    if (!wizardName) {
      return null;
    }

    // Artificial delay/yield to enable proper autofocusing of wizard form fields.
    setTimeout(function() {
      dispatch(actions.start({wizardName}));
    }, 0);

  }
};

api.close = function(dispatch, getState) {
  return function(wizardName) {

    if (!wizardName) {
      return null;
    }

    // Destroy forms belonging to this wizard.
    destroyWizardForms(dispatch, getState)(wizardName);

    // Destroy wizard state, which effectively closes it.
    dispatch(actions.close({wizardName}));

  }
};

api.gotoStep = function(dispatch, getState) {
  return function(wizardName, stepKey) {

    if (!wizardName) {
      return null;
    }

    // Do nothing if next step key is not found.
    if (!stepKey) {
      return;
    }

    // Artificial delay/yield to enable proper autofocusing of wizard form fields.
    setTimeout(function() {
      dispatch(actions.gotoStep({wizardName, stepKey}));
    }, 0);

  }
};

// Destroy all existing wizards. (close all wizards, erase all wizard forms.).
api.killEmAll = function(dispatch, getState) {
  return function() {
    destroyAllWizardForms(dispatch, getState)();
    dispatch(actions.killEmAll());
  }
};

api.next = function(dispatch, getState) {
  return function(wizardName) {

    if (!wizardName) {
      return null;
    }

    let wizardData = getState().wizard.get(wizardName);
    let nextPreviousStepsKeys = getNextPreviousStepsKeys(wizardData);

    api.gotoStep(dispatch, getState)(wizardName, nextPreviousStepsKeys.nextStepKey);

  }
};

api.previous = function(dispatch, getState) {
  return function(wizardName) {

    if (!wizardName) {
      return null;
    }

    let wizardData = getState().wizard.get(wizardName);
    let nextPreviousStepsKeys = getNextPreviousStepsKeys(wizardData);

    api.gotoStep(dispatch, getState)(wizardName, nextPreviousStepsKeys.previousStepKey);

  }
};



// CONVENIENCE ADAPTER FOR API.
api.createWizardSpecificAdapter = function(wizardName) {

  if (!wizardName) {
    return null;
  }


  let adapter = {};

  adapter.initSteps = (dispatch, getState) => {
    return (stepsInfo) => {
      return api.initSteps(dispatch, getState)(wizardName, stepsInfo);
    };
  };

  adapter.start = (dispatch, getState) => {
    return () => {
      return api.start(dispatch, getState)(wizardName);
    };
  };

  adapter.close = (dispatch, getState) => {
    return () => {
      return api.close(dispatch, getState)(wizardName);
    };
  };

  adapter.gotoStep = (dispatch, getState) => {
    return (stepKey) => {
      return api.gotoStep(dispatch, getState)(wizardName, stepKey);
    };
  };

  adapter.next = (dispatch, getState) => {
    return () => {
      return api.next(dispatch, getState)(wizardName);
    };
  };

  adapter.previous = (dispatch, getState) => {
    return () => {
      return api.previous(dispatch, getState)(wizardName);
    };
  };


  adapter.killEmAll = (dispatch, getState) => {
    return (stepKey) => {
      return api.killEmAll(dispatch, getState)();
    };
  };

  return adapter;
};





/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

let destroyWizardForms = function(dispatch, getState) {
  return function(wizardName) {

    //////////////////////////////////////////
    // Destroy all existing wizard forms.
    //  - by form names stored in stepsData.
    //

    let state = getState();
    if (!state.form) {
      return;
    }

    let wizardData = state.wizard.get(wizardName);
    if (!wizardData) {
      return;
    }

    let stepsData = wizardData.get('stepsData'); // NOTE: stepsData object is not immutable.

    // Find used form names by this wizard.
    let formNames = stepsData.map((step) => { return step.formName; });

    // Collect uniques only.
    let uniqueFormNames = Array.from(new Set(formNames));

    // Destroy them.
    uniqueFormNames.forEach((formName) => {
      dispatch(formDestroy(formName)); // redux-form action-creators API.
    });

  }
};


let destroyAllWizardForms = function(dispatch, getState) {
  return function() {

    //////////////////////////////////////////
    // Destroy all existing wizard forms.

    let state = getState();
    if (!state.form) {
      return;
    }

    // All wizard_form_ 's will be destroyed.
    let formSearchPrefix = 'wizard_form_';

    let allFormNames = Object.keys(state.form);

    allFormNames.forEach((formName) => {
      if (formName.indexOf(formSearchPrefix) === 0) {
        dispatch(formDestroy(formName)); // redux-form action-creators API.
      }
    });

  }
};


let buildStepsDataObject = function(stepsInfo) {

  //////////////////////////////////////////////////
  // Convert stepsInfo we got from UI to stepsData
  // with only useful data for storage.

  let { steps, defaultFormName } = stepsInfo;

  let stepsKeys = Object.keys(steps);
  let stepsDataArray = stepsKeys.map((x) => {
    return {
      key: x,
      formName: steps[x].props.form || defaultFormName
    };
  });

  return stepsDataArray;
};


let getNextPreviousStepsKeys = function(wizardData) {

  ////////////////////////////////////
  // RULES:
  //
  // *** NEXT
  // - When current step is undefined, next step is second element (at index 1).
  // - When resulting step index is out of bounds, return null.
  //
  // *** PREVIOUS
  // - When current step is undefined, previous step is null.
  // - When resulting step index is out of bounds, return null.
  //


  let result = { nextStepKey: null, previousStepKey: null};


  // GATHER INFO ABOUT CURRENT STEP

  let currentStepKey  = wizardData.get('currentStep');
  let stepsData       = wizardData.get('stepsData'); // NOTE: stepsData object is not immutable.

  // -1 when not found.
  let currentStepIndex = stepsData.findIndex(step => (step.key === currentStepKey));



  // DETERMINE INDEXES OF NEXT AND PREVIOUS PAGES.

  let nextStepIndex     = -1;
  let previousStepIndex = -1;

  // When we are on 1st page of freshly opened wizard. (no currentStepKey).
  if (!currentStepKey) {
    nextStepIndex = 1; // second page.
    // and no previous.
  }
  else if (currentStepIndex !== -1) {
    nextStepIndex     = (currentStepIndex + 1);
    previousStepIndex = (currentStepIndex - 1);
  }
  else {
    return result; // Bad step key provided as input. Return result with nulls.
  }




  // GET RESULTING STEP KEYS BY FOUND INDEXES.

  if ((nextStepIndex >= 0) && (nextStepIndex < stepsData.length)) {
    result.nextStepKey = stepsData[nextStepIndex].key;
  }

  if ((previousStepIndex >= 0) && (previousStepIndex < stepsData.length)) {
    result.previousStepKey = stepsData[previousStepIndex].key;
  }


  return result;

};











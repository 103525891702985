import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import DashboardTab from '../../../components/Administration/OrganizationsPage/DashboardTab/dashboardTab.js';
import { gridsNames } from '../../../enums/gridsNames';
import { jsxActions as dashboardTabGridActions } from '../../../actions/Administration/OrganizationsPage/dashboardTabGridActions';
import { jsxActions as dashboardTabActions } from '../../../actions/Administration/OrganizationsPage/dashboardTabActions';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      dashboardTabGridActions: bindActionCreators(dashboardTabGridActions, dispatch),
      dashboardTabActions: bindActionCreators(dashboardTabActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
    },
  };
}

let DashboardTabContainer = connect((state) => {
  let grid = state.grid.get(gridsNames.ADMINISTRATION_ORGS_DASHBOARD)
    ? state.grid.get(gridsNames.ADMINISTRATION_ORGS_DASHBOARD)
    : null;
  return {
    sDashboardGrid: grid,
    sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
    sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
  };
}, mapDispatchToProps)(DashboardTab);

export default DashboardTabContainer;

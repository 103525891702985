import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';
import * as automatedWorkflowsService from '../../../services/Rfid/automatedWorkflowsService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_RFID_SETTINGS_ACTION_SEQUENCE, _fetchDataAction);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    // let selectedDeviceApp =  getState().administration.getIn(['rfidSettingsPage', 'actionSequenceViewData', 'selectedDeviceApp']);
    //
    // if (!selectedDeviceApp || !selectedDeviceApp.label) {
    //   return Promise.resolve([]);
    // }
    //
    // let queryData = {
    //   deviceOrgBusinessId :getState().login.get('loggedInUser').loggedInOrg.businessId,
    //   deviceBusinessId: selectedDeviceApp.label
    // }
    //
    // return deviceAppsService.getDeviceProfile(queryData).then((res) => {
      return automatedWorkflowsService.fetchActionSequence();
    // });
  }
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

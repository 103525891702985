import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { gridsNames} from '../../../enums/gridsNames';
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { jsxActions as automationScenariosViewActions} from '../../../actions/Administration/RfidSettingsPage/automationScenariosViewActions';
import { jsxActions as automationScenariosViewGridActions} from '../../../actions/Administration/RfidSettingsPage/automationScenariosViewGridActions';
import { jsxActions as createAutomationScenarioDialogActions} from '../../../actions/Administration/RfidSettingsPage/Dialogs/createAutomationScenarioDialogActions';
import AutomationScenariosView from '../../../components/Administration/RfidSettingsPage/AutomatedWorkflowsView/AutomationScenariosView/automationScenariosView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(automationScenariosViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      automationScenariosViewGridActions: bindActionCreators(automationScenariosViewGridActions, dispatch),
      createAutomationScenarioDialogActions : bindActionCreators(createAutomationScenarioDialogActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let AutomationScenariosViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_AUTOMATION_SCENARIOS);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sData: state.administration.getIn(['rfidSettingsPage', 'automationScenariosViewData']),
      sCreateAutomationScenarioDialogData: state.administration.getIn(['rfidSettingsPage', 'createAutomationScenarioDialog']),
    };
  },

  mapDispatchToProps
)(AutomationScenariosView);

export default AutomationScenariosViewContainer;



import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as archivedSpoolsPageActions }    from '../../actions/ArchivedSpoolsPage/archivedSpoolsPageActions.js';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as activateAssetsDialogActions }    from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';

import { jsxActions as archivedSpoolsGridActions }    from '../../actions/ArchivedSpoolsPage/archivedSpoolsGridActions';
import { jsxActions as moveAssetsActions }        from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as printPdfLabelsActions }      from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

import ArchivedSpoolsPage from '../../components/PredefinedList/ArchivedSpoolsPage/archivedSpoolsPage';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(archivedSpoolsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      archivedSpoolsGridActions: bindActionCreators(archivedSpoolsGridActions, dispatch),
      activateAssetsDialogActions:  bindActionCreators(activateAssetsDialogActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      printPdfLabelsActions:      bindActionCreators(printPdfLabelsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let ArchivedSpoolsPageContainer = connect(
  (state) => {
    let sGrid = state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_SPOOLS) ? state.grid.get(gridsNames.PREDEFINED_LIST_ARCHIVE_SPOOLS) : null;
  	return {
      sGrid:  sGrid,
      sHasRows: sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData: sGrid ? sGrid.get('selectedRowsData') : List(),
      sActivateAssetsDialogData: state.dialogs.get('activateAssetsDialog'),
      sMoveAssetsData:     state.dialogs.get('moveAssetsDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
    };
  },
  mapDispatchToProps
)(ArchivedSpoolsPage);


export default ArchivedSpoolsPageContainer;

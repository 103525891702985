import { actionTypes as billingPageActionTypes } from '../../../actions/Administration/BillingPage/billingPageActions';
import {Map} from 'immutable';

let defaultState = {
  loading: false,
  orgs : [],
  info: null
};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case billingPageActionTypes.ADMINISTRATION_BILLING_FETCH_DATA_STARTED:
      return state.set('loading', true);
    case billingPageActionTypes.ADMINISTRATION_BILLING_FETCH_DATA_FINISHED:
      return state.set('orgs', action.orgs).set('loading', false);
    case billingPageActionTypes.ADMINISTRATION_BILLING_FETCH_BILLING_INFO_IN_PROGRESS:
      return state.set('loading', true);
    case billingPageActionTypes.ADMINISTRATION_BILLING_FETCH_BILLING_INFO_IN_FINISHED:
      return state.set('info', action.payload).set('loading', false);
    default:
      return state;
  }
}

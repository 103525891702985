import React from 'react';
import PropTypes from 'prop-types';

import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";


export default class WidthCell extends React.PureComponent {

  getWidth = (value) => {
    return UnitHelper.serverValueToUserValueWithLabel(unitTypes.WIDTH, value, 2);
  };

  render() {
    let { value } = this.props;
    let width = value && value.hasOwnProperty('width') ? value.width : value; //use props.value.width if exists, otherwise use props.value
    if (width === null || width === undefined) {
      return null;
    }
    return (<div>
      <span>{this.getWidth(width)}</span>
    </div>);
  }
}

WidthCell.propTypes = {
  value: PropTypes.object,
};

import {useMemo} from 'react';
import PropTypes from 'prop-types';
import TreeItem from './TreeItem/treeItem.js';
import {ElementSelectionProvider} from 'infrastructure/js/contexts/elementSelectionContext';
import {TreeItemDragProvider} from 'infrastructure/js/components/Tree/treeItemDragContext';
import {TreeMap} from 'infrastructure/js/components/Tree/TreeMap/treeMap';

import './tree.scss';

const Tree = ({ treeData, onSelect, onDrop, selectedItem, defaultExpandAll, itemIconsRenderer, itemActionsRenderer }) => {

  const treeMap = useMemo(() => new TreeMap(treeData), [treeData]);

  return (
    <TreeItemDragProvider>
    <ElementSelectionProvider>
      <div className='tree'>
        {treeData?.map((item) => <TreeItem key={item.id} level={0} treeMap={treeMap}
                                           item={item}
                                           onSelect={onSelect}
                                           onDrop={onDrop}
                                           selectedItem={selectedItem}
                                           defaultExpandAll={defaultExpandAll}
                                           itemActionsRenderer={itemActionsRenderer}
                                           itemIconsRenderer={itemIconsRenderer}
        />)}
      </div>
    </ElementSelectionProvider>
    </TreeItemDragProvider>
  );
}

Tree.propTypes = {
  treeData: PropTypes.array,
  onSelect: PropTypes.func,
  onDrop: PropTypes.func,
  selectedItem: PropTypes.object,
  defaultExpandAll: PropTypes.bool,
  itemIconsRenderer: PropTypes.func,
  itemActionsRenderer: PropTypes.func,
}

Tree.defaultProps = {
  treeData: [],
  onSelect: () => {},
  onDrop: () => {},
  selectedItem: undefined,
  defaultExpandAll: true,
  itemIconsRenderer: null,
  itemActionsRenderer: null,
}

export default Tree;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActivityLogTab from '../../components/AssetPage/ActivityLogTab/activityLogTab.js';
import {gridsNames} from '../../enums/gridsNames';
import { jsxActions as activityLogGridActions } from '../../actions/AssetPage/activityLogTabGridActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      activityLogGridActions: bindActionCreators(activityLogGridActions, dispatch)
    }
  }
}

let ActivityLogTabContainer = connect(
  (state) => {
    let activityLogTabGrid = state.grid.get(gridsNames.ASSET_PAGE_ACTIVITY_LOG) ? state.grid.get(gridsNames.ASSET_PAGE_ACTIVITY_LOG) : null;
    return {
      sActivityLogTabGrid:  activityLogTabGrid
    };
  },
  mapDispatchToProps
)(ActivityLogTab);

export default ActivityLogTabContainer;





import { actionTypes as actionsTypes } from '../../../actions/LocationPage/AssetsView/createToolActions.js';

import { Map } from 'immutable';

let defaultState = {
  show: false,
  loading: false,
  tags: [],
  toolTypes:[],
  additionalFields:[]
};

export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case actionsTypes.ASSETSVIEW_CREATE_TOOL_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);
    case actionsTypes.ASSETSVIEW_CREATE_TOOL_SUBMIT_FINISHED:
      return state.set("loading", false);
    case actionsTypes.ASSETSVIEW_CREATE_TOOL_SHOW_DIALOG:
      return state.set("show", true);
    case actionsTypes.ASSETSVIEW_CREATE_TOOL_HIDE_DIALOG:
      return state.set("show", false);
    case actionsTypes.ASSETSVIEW_CREATE_TOOL_FETCH_DATA_READY:
      state = state.setIn(["tags"], action.payload.tags.dataList.map((obj)=> { return { value: obj.id, label: obj.tagKey} }));
      state = state.set('toolTypes', action.payload.toolTypes.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId, data: obj } }));
      state = state.setIn(["additionalFields"], action.payload.additionalFields.dataList);
      return state;
    default:
      return state;
  }
}



import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as ValueTypeConverter from '../../Helpers/valueTypeConverter';
import Label from 'infrastructure/js/components/Label/label.js';

require('./activityDetailsCell.scss');

export default function ActivityDetailsCell(props) {

  const labels = createLabelHelper('mat.activitydetails.');

  let details = props.value;
  if (details) {
    let value = ValueTypeConverter.getValueByType(details.value, details.type);

    return (<div className="activity-details">
      <div className="activity-details-item">
        <label className="title">{details.isLocalizedKey ? details.key : labels.get(details.key)}</label>
        <Label className="description" text={value ? value.toString() : ''}/>
      </div>
    </div>);
  }
  return null;
}

ActivityDetailsCell.propTypes = {
  value: PropTypes.object.isRequired,
};


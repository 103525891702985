import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {PL_DialogWrapper}     from 'infrastructure/js/components/Dialog/dialog';
import ArchiveAssetsDialog    from '../../Dialogs/ArchiveAssetsDialog/archiveAssetsDialog';
import MoveAssetsDialog       from '../../Dialogs/MoveAssetsDialog/moveAssetsDialog';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import ActivePartsGrid from './ActivePartsGrid/activePartsGrid';

require('./activePartsPage.scss');

export default class ActivePartsPage extends Component {

  getDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ACTIVE,
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('PART')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getMoveAssetsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('PART')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData} = this.props;
    let dialogConfig = this.getDialogConfig();

    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
      ],

      kebab: {
        menuItems: [
          {
            id:'moveTo',
            label: headerItemsLabels.MOVE_TO,
            action: actions.header.onMenuItemMoveToClick,
            actionData: this.getMoveAssetsDialogConfig(),
            disabled: !sHasRows,
          },
          ...(PermissionManager.hasArchiveAssetsPermissions() ? [{
            id:'archive',
            label: headerItemsLabels.ARCHIVE,
            action: actions.header.onMenuItemArchiveClick,
            actionData: dialogConfig,
            disabled: !sHasRows,
          }] : []),
        ]
      }
    };

    return items;
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderMoveAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={MoveAssetsDialog}
                        show={this.props.sMoveAssetsData.get('show')}
                        actions={this.props.actions.moveAssetsActions}
                        sData={this.props.sMoveAssetsData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderArchiveAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={ArchiveAssetsDialog}
                        show={this.props.sArchiveAssetsData.get('show')}
                        sData={this.props.sArchiveAssetsData}
                        actions={this.props.actions.archiveAssetsActions}
                        reloadParentComponent={this.reloadPage} />
    );
  };

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="active-parts-page">
        <Header  {...headerItems}/>

        <ActivePartsGrid actions={{...this.props.actions.activePartsGridActions, ...this.props.actions.fetchEntitiesActions}} />
        { this.renderMoveAssetsDialog() }
        { this.renderArchiveAssetsDialog() }
      </div>
    );
  }
}


ActivePartsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
};












import { Map , List } from 'immutable';
import { actionTypes as PrinterViewActionsType } from '../../../actions/Administration/RfidSettingsPage/printersViewActions';

let defaultState = {
  loading: false,
  selectedDeviceApp : null,
  deviceAppsOptions : []
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case PrinterViewActionsType.ADMINISTRATION_RF_SETTINGS_PRINTERS_FETCH_DEVICE_APPS_IN_PROCESS:
      return state.set('loading', true);

    case PrinterViewActionsType.ADMINISTRATION_RF_SETTINGS_PRINTERS_FETCH_DEVICE_APPS_READY:
      state = state.set('deviceAppsOptions', action.payload);
      return state.set('loading',false);

    case PrinterViewActionsType.ADMINISTRATION_RF_SETTINGS_PRINTERS_DEVICE_APP_CHANGED:
      return state.set('selectedDeviceApp', action.payload);

    default:
      return state;
  }
}








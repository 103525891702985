import React, { Component } from 'react';
import KittingGrid      from './KittingGrid/kittingGrid.js';
import KittingHeader    from './Header/header.js';
import ReportKitDialog  from './ReportKitDialog/reportKitDialog';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import CancelKitReportDialog from './CancelKitReportDialog/cancelKitReportDialog.js';

require('./kittingView.scss');

export default class KittingView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.locationpage.view.kitting.');
  }

  render() {
    let kittingHeaderActions = {
      onSelectedCutToKitChange: this.props.actions.cutKitStation.onSelectedCutToKitChange,
      onShowKitsInLocationChange: this.props.actions.cutKitStation.onShowKitsInLocationChange,
      onReportKittingClick:     this.props.actions.cutKitStation.onReportKittingClick,
      onCreateKitsClick:        this.props.actions.cutKitStation.onCreateKitsClick,
      importFilesWizardActions: this.props.actions.importFilesWizardActions,
      fetchReportedCuts:        this.props.actions.cutKitStation.fetchReportedCuts,
      clearFilters:             this.props.actions.cutKitStation.clearFilters,
      reloadParentComponent:    this.props.actions.locationPageActions.reload
    };

    let kits = this.props.sKittingGridData ? this.props.sKittingGridData.get("rows") : null;
    let loading = this.props.sKittingGridData ? this.props.sKittingGridData.get('loading') : true;
    let isLocationActive = this.props.sLocationDetails && this.props.sLocationDetails.isActive;

    return (

      <div className="location-kitting-view">
        <KittingHeader kittingGridData  = {this.props.sKittingGridData}
                       kittingViewData  = {this.props.sKittingViewData}
                       selectedCutToKit = {this.props.sSelectedCutToKit}
                       actions          = {kittingHeaderActions}
                       loading          = {loading}
                       isLocationActive = {isLocationActive}
        />

        <div className="create-kit-button-section">
          <Button id='kittingViewCreateKitBtn'
                  className="overlay-button border-btn"
                  icon="pl pl-create-kit"
                  onClick={kittingHeaderActions.onCreateKitsClick}
                  disabled={loading || !this.props.sSelectedCutToKit || !this.props.sSelectedCutToKit.material || !isLocationActive}
                  style={{display: (!kits || kits.size === 0) ? "block" : "none"}}>{this.labels.get('grid.buttons.createkits')}
          </Button>
        </div>

        <KittingGrid actions={this.props.actions.kittingGridActions} />

        <PL_DialogWrapper dialogComponent={ReportKitDialog}
                          show={this.props.sReportKitDialog.get('show')}
                          sData={this.props.sReportKitDialog}
                          actions={this.props.actions.reportKitDialog}
                          currentCut={this.props.sSelectedCutToKit}
                          sLoggedInUser={this.props.sLoggedInUser}
        />
        <PL_DialogWrapper dialogComponent={CancelKitReportDialog}
                          show={this.props.sCancelKitReportDialog.get('show')}
                          sData={this.props.sCancelKitReportDialog}
                          actions={this.props.actions.cancelKitReportDialog} />

      </div>

    );


  }
}

import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import CreateDeviceAppDialog from './Dialogs/CreateDeviceAppDialog/createDeviceAppDialog.js';
import DeviceAppsViewGrid from './DeviceAppsViewGrid/deviceAppsViewGrid';
import {RfidSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';

export default class DeviceAppsView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.rfidsettings.deviceapps.view.');
  }

  componentDidMount() {
    this.props.actions.init(false);
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_DEVICE_APPS);
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    return {
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreateDeviceAppClick,
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditDeviceAppClick,
          actionData: selectedRowsData.get(0)
        }
      ],
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems}/>

        <DeviceAppsViewGrid actions={this.props.actions.deviceAppsViewGridActions} />

        <PL_DialogWrapper dialogComponent={CreateDeviceAppDialog}
                          show={this.props.sCreateDeviceAppDialogData.get('show')}
                          actions={this.props.actions.createDeviceAppDialogActions}
                          sData={this.props.sCreateDeviceAppDialogData}
                          reloadParentComponent={this.reloadPage}
        />

      </div>
    );
  }
}

DeviceAppsView.propTypes = {
  sGrid: PropTypes.object,
};


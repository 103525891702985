import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';

import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import * as AssetHelper from '../../../../utils/assetHelper';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';

require('./archiveWorkOrderDialog.scss');

class ArchiveWorkOrderDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.workorderpage.archiveworkorderdialog.');
    this.statuslabels = createLabelHelper('mat.archive.status.');
    this.archiveStatuses = [ getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED'),
                             getEnumValue(enumTypes.WORK_ORDER_STATUS)('DISQUALIFIED'),
                             getEnumValue(enumTypes.WORK_ORDER_STATUS)('CANCELED')];
    this.state =
      { currentStatus: null,
        options: []
      };
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
        text: this.dialogLabels.get('cancel'),
        action: this.props.actions.hide
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.archive'),
          bsStyle: 'primary',
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading')
        }
      ]
    };
  }

  getArchivedStatuses =() => {
    return this.archiveStatuses.map((status) => {
      return {value: status, label: this.statuslabels.get(status.toLowerCase())}
    });
  };

  onSubmit = (data) => {
    let newData = {
      status: data.status ? data.status.value : '',
      comment: data.comment ? data.comment : '',
      affectedAssetIds: data.affectedAssets.map((asset) => {return asset.value;}),
      workOrderId: this.props.workOrder.get('id'),
    };

    // this.props.actions.updateWorkOrderStatus(newData, this.props.workOrder.get('id'));
    this.props.actions.submit(newData, null, this.props.reloadParentComponent);
  };

  onChangeStatus = (status) => {
    let newStatus = status ? status.value : null;
    this.setState({currentStatus: newStatus}, this.updateAffectedAssets);
  };

  updateAffectedAssets = () => {

    let affectedAssets = [];

    if (this.state.currentStatus) {
      // let clearable = (this.state.currentStatus === workOrderStatusTypes.COMPLETED ) ? false : true;

      //related ACTIVE assets that are not ROLLs
      affectedAssets = this.props.relatedAssets.filter((asset) => {
        return ( !AssetHelper.isRoll(asset.objectType) );
      }).map((asset) => {
        return ( {
            value: asset.id,
            label: asset.businessId,
            title: asset.businessId,
            data: asset,
            // clearableValue: clearable
            // isClearable: clearable
          }
        );
      });
    }

    this.props.change( 'affectedAssets', affectedAssets );
    this.setState({options: affectedAssets});
  };

  onHide = () => {
    this.props.actions.hide();
  };

  getMarkedAssetsLabel = () => {
    if (this.state.currentStatus) {
      switch (this.state.currentStatus) {
        case getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED')  :
          return this.labels.get('mark.assets.completed');
        case getEnumValue(enumTypes.WORK_ORDER_STATUS)('DISQUALIFIED'):
          return this.labels.get('mark.assets.disqualified');
        case getEnumValue(enumTypes.WORK_ORDER_STATUS)('CANCELED'):
          return this.labels.get('mark.assets.canceled');
        default:
          console.error('ArchiveWorkOrderDialog: Unknown status : ' + this.state.currentStatus);
          return '';
      }
    }
    return '';
  };

  renderMarkedAssets() {
    let label = this.getMarkedAssetsLabel();

    let placeholder = this.state.options.length > 0 ? this.labels.get('mark.assets.placeholder.select') :
                                                      this.labels.get('mark.assets.placeholder.noassets');

    let isDisabled = (this.state.currentStatus === getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED')) || (this.state.options.length === 0);
    let clearable = (this.state.currentStatus === getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED')) ? false : true;

    return (
      <InputSection label={label} htmlFor="affectedAssets" className="right-side full-width marked-assets-section">
        <PL_MultiSelectField  id="affectedAssets" name="affectedAssets"
                              //clearable={false}
                              isSearchable={false}
                              options={this.state.options}
                              placeholder={placeholder}
                              isMultiValueClearable={clearable}
                              isDisabled={isDisabled}
        >
        </PL_MultiSelectField>
      </InputSection>
    );

  }

  render() {

    return (
      <Dialog
        id="archive-workorder-dialog"
        className="archive-workorder-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >


        <InputSection label={this.labels.get('substatus')} htmlFor="status" className="left-side no-margin full-width">
          <Dropdown  id="substatus-dropdown" name="status"
                     placeholder={this.labels.get('substatus.placeholder')}
                     validate={Validation.dropdown.required}
                     options={this.getArchivedStatuses()}
                     onChangeCallback={this.onChangeStatus}>
          </Dropdown>
        </InputSection>

        {this.renderMarkedAssets()}

        <InputSection label={this.labels.get('comment')} htmlFor="comment" className="right-side full-width">
          <TextAreaField id="comment-textField" name="comment" className="comment-textField" />
        </InputSection>

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'archiveWorkOrderDialog',
  }
)(ArchiveWorkOrderDialog);

ArchiveWorkOrderDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  workOrder : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  relatedAssets : PropTypes.array.isRequired,
};

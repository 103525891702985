import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import Header, {headerItemsLabels} from '../Common/Header/header';
import {List} from 'immutable';
import {gridsNames} from '../../enums/gridsNames';
import {isAssetArchived, isRoll, isKit, isTool, isAssetActivated, isGroup, isSpool, isPart, isAssetInTransition } from '../../utils/assetHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import FullScreenTabsSeparator from '../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';

import ArchiveAssetsDialog from '../Dialogs/ArchiveAssetsDialog/archiveAssetsDialog';
import CreateAttachmentDialog from '../Dialogs/CreateAttachmentDialog/createAttachmentDialog';
import EditAttachmentDialog from '../Dialogs/EditAttachmentDialog/editAttachmentDialog';
import ActivateAssetsDialog from '../Dialogs/ActivateAssetsDialog/activateAssetsDialog.js';
import ShelfLifeUpdateDialog from '../Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialog.js';
import PrintRfidTagsDialog from '../Dialogs/PrintRfidTagsDialog/printRfidTagsDialog.js';
import EditKitMaterialsDialog from './Dialogs/EditKitMaterialsDialog/editKitMaterialsDialog.js';
import WeightWizardContainer from '../../containers/Wizards/WeightWizard/weightWizardContainer';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import OverviewTabContainer from '../../containers/AssetPage/overviewTabContainer.js';
import RelatedAssetsTabContainer from '../../containers/AssetPage/relatedAssetsTabContainer.js';
import AttachmentTabContainer from '../../containers/AssetPage/attachmentTabContainer.js';
import ActivityLogTabContainer from '../../containers/AssetPage/activityLogTabContainer.js';
import ReportMaintenanceDialog from '../Dialogs/ReportMaintenanceDialog/reportMaintenanceDialog';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { getEnumValue, enumTypes } from '../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./assetPage.scss');

const TabKeys = {
  OVERVIEW: 'overview',
  ACTIVITY_LOG: 'activityLog',
  ATTACHMENT: 'attachment',
};

export default class AssetPage extends Component {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.assetpage.topbar.');
    this.operationsLabels = createLabelHelper('mat.operatins.confirmation.');
  }

  componentDidMount() {
    let assetId = this.props.match.params.assetId;
    let assetType = this.props.match.params.assetType;

    this.props.actions.assetPageActions.init(false, assetType, assetId);
  }

  componentWillUnmount() {
    this.props.actions.assetPageActions.unmount();
  }

  componentDidUpdate(prevProps) {
    let oldAssetId = prevProps.match.params.assetId;
    let oldAssetType = prevProps.match.params.assetType;
    let newAssetId = this.props.match.params.assetId;
    let newAssetType = this.props.match.params.assetType;
    if (newAssetId !== oldAssetId || newAssetType !== oldAssetType) {
      this.props.actions.assetPageActions.init(false, newAssetType, newAssetId);
    }

    let newTabId = this.props.match.params.tabId;

    //Check if tab changed and resize if needed.
    if (prevProps.match.params.tabId !== newTabId) {
      window.dispatchEvent(new Event('resize'));
    }

    if (newTabId && !Object.values(TabKeys).includes(newTabId)) {
      window.location.hash = '#/PageNotFound';
    }
  }

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.operationsLabels.get('printtag.error.title');
      return dialogHelper.BuildDialogDescriptorForPrintTag(response, fnCloseDialog, title);
    };
  };

  handlePrintTagClick = () => {
    let { actions, asset } = this.props;
    //todo - ran - remove builder after progress bar is done
    actions.header.onPrintTagsWithDialogClick(asset);
  };

  getHeaderItems() {
    let { actions } = this.props;

    if (
      this.props.asset.get('objectType') === null ||
      this.props.asset.get('assetStatus').toUpperCase() ===
        getEnumValue(enumTypes.ASSET_STATUS)('PHANTOM')
    ) {
      return {};
    }

    let selectedTab = this.getSelectedTab();

    let items = {
      buttons: [],

      kebab: {
        disabled: isAssetInTransition(this.props.asset.get('assetStatus')),
        menuItems: [
          ...(PermissionManager.hasArchiveAssetsPermissions()
            ? [
                {
                  id: 'archive',
                  label: headerItemsLabels.ARCHIVE,
                  disabled: isAssetArchived(this.props.asset.get('assetStatus')),
                  action: actions.header.onMenuItemArchiveClick,
                },
              ]
            : []),
        ],
      },
    };

    if (isRoll(this.props.asset.get('objectType'))) {
      items.kebab.menuItems.unshift({
        id: 'exportRollUsage',
        label: headerItemsLabels.EXPORT_ROLL_USAGE,
        action: actions.header.onMenuItemExportRollUsageClick,
        actionData: List([
          {
            id: this.props.asset.get('id'),
            objectType: this.props.asset.get('objectType'),
          },
        ]),
      });
    }

    // if(isSpool(this.props.asset.get('objectType'))){   //TODO: L SPOOLS not relevant for phase 1
    //   items.kebab.menuItems.unshift({
    //     id:'exportSpoolUsage',
    //     label: headerItemsLabels.EXPORT_SPOOL_USAGE,
    //     action: actions.header.onMenuItemExportSpoolUsageClick,
    //     actionData:  List([{
    //       id : this.props.asset.get('id'),
    //       objectType : this.props.asset.get('objectType'),
    //     }])
    //   })
    // }

    if (isTool(this.props.asset.get('objectType'))) {
      items.buttons.push({
        id: 'reportMaintenance',
        label: headerItemsLabels.REPORT_MAINTENANCE,
        icon: 'pl pl-report-maintenance',
        disabled: false,
        action: actions.header.onReportMaintenanceClick,
      });
    }

    if ( !isTool(this.props.asset.get('objectType')) &&
         !isPart(this.props.asset.get('objectType'))) {
      items.buttons.push({
        id: 'print',
        icon: 'pl pl-print',
        tooltip: headerItemsLabels.PRINT_RFID_TAG,
        disabled: isAssetArchived(this.props.asset.get('assetStatus')) || isAssetInTransition(this.props.asset.get('assetStatus')),
        action:  this.handlePrintTagClick.bind(this)
      })
    }

    if (isKit(this.props.asset.get('objectType'))) {
      items.kebab.menuItems.unshift({
        id: 'exportKitReport',
        label: headerItemsLabels.EXPORT_KIT_REPORT,
        actionData: List([{ id: this.props.asset.get('id') }]),
        action: actions.header.onExportKitReportClick,
      });
    }

    if (isGroup(this.props.asset.get('objectType'))) {
      items.kebab.menuItems.unshift({
        id: 'exportGroupReport',
        label: headerItemsLabels.EXPORT_GROUP_REPORT,
        actionData: List([{ id: this.props.asset.get('id') }]),
        action: actions.header.onExportContainerReportClick,
      });
    }

    if ( !isTool(this.props.asset.get('objectType')) &&
         !isPart(this.props.asset.get('objectType'))
    ) {
      items.kebab.menuItems.unshift({
        id: 'print',
        label: headerItemsLabels.PRINT_LABEL,
        action: actions.header.onAssetPrintPdfLabelClick,
        actionData: [this.props.asset.get('id')],
      });
    }

    if (
      PermissionManager.hasUpdateShelfLifePermissions() &&
      (isRoll(this.props.asset.get('objectType')) ||
        isKit(this.props.asset.get('objectType')) ||
        isSpool(this.props.asset.get('objectType')))
    ) {
      items.kebab.menuItems.unshift({
        id: 'updateShelfLife',
        label: headerItemsLabels.UPDATE_SELF_LIFE,
        icon: 'pl pl-hourglass-icon',
        disabled:
          isAssetArchived(this.props.asset.get('assetStatus')) ||
          (isKit(this.props.asset.get('objectType')) && this.props.asset.get('isCured')),
        action: actions.header.onUpdateShelfLifeClick,
      });
    }

    if (
      isAssetArchived(this.props.asset.get('assetStatus')) &&
      PermissionManager.hasActivateAssetsPermissions()
    ) {
      items.buttons.push({
        id: 'activate',
        label: headerItemsLabels.ACTIVATE,
        icon: 'pl pl-activate',
        action: actions.header.onActivateClick,
      });
    }

    if (
      isKit(this.props.asset.get('objectType')) &&
      PermissionManager.getOrgPreferences().kitsRollsReportEnabled
    ) {
      items.kebab.menuItems.push({
        id: 'exportKitRollsReport',
        label: headerItemsLabels.EXPORT_KIT_ROLLS_REPORT,
        actionData: List([{ id: this.props.asset.get('id') }]),
        action: actions.header.onExportKitRollsReportClick,
      });
    }

    if( isRoll(this.props.asset.get('objectType'))
      || isSpool(this.props.asset.get('objectType'))
      || isGroup(this.props.asset.get('objectType'))){
      items.kebab.menuItems.push({
        id:'confirmLocation',
        label: headerItemsLabels.CONFIRM_LOCATION,
        disabled: this.isConfirmLocationDisabled(),
        action:  actions.assetPageActions.onConfirmAssetLocationClick,
        actionData: {
          assetId: this.props.asset.get('id'),
          objectType: this.props.asset.get('objectType'),
          reloadParentComponent: this.reloadAssetPage,
        }
      })
    }


    if (selectedTab === TabKeys.ACTIVITY_LOG) {
      items.buttons.unshift({
        id: 'clearFilters',
        label: '',
        icon: 'pl pl-filter-clear',
        action: actions.assetPageActions.clearFilters,
        tooltip: headerItemsLabels.CLEAR_FILTERS,
      });

      items.buttons.push({
        id: 'export',
        icon: 'pl pl-export-icon',
        tooltip: headerItemsLabels.EXPORT,
        action: actions.exportActions.export,
        actionData: {
          assetId: this.props.asset.get('id'),
          assetType: this.props.asset.get('objectType'),
          gridName: gridsNames.ASSET_PAGE_ACTIVITY_LOG,
        },
      });
    }

    if (selectedTab === TabKeys.ATTACHMENT) {
      let attachmentTabGrid = this.props.attachmentTabGrid;
      let hasRows =
        attachmentTabGrid && attachmentTabGrid.get('rows')
          ? attachmentTabGrid.get('rows').size > 0
          : false;
      let selectedRowsData = attachmentTabGrid ? attachmentTabGrid.get('selectedRowsData') : List();
      let isDeleted =
        selectedRowsData && selectedRowsData.size && selectedRowsData.get(0).isDeleted;

      items.kebab.menuItems = [
        {
          id: 'downloadAttachment',
          label: headerItemsLabels.DOWLOAD,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.header.onMenuItemDownloadAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
          },
        },
        {
          id: 'editAttachment',
          label: headerItemsLabels.EDIT_CATEGORY,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.header.onMenuItemEditAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
            reloadParentComponent: this.reloadAssetPage,
          },
        },
        {
          id: 'deleteAttachment',
          label: headerItemsLabels.DELETE,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.header.onMenuItemDeleteAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
            reloadParentComponent: this.reloadAssetPage,
          },
        },
      ];

      items.buttons = [];
      items.buttons.push({
        id: 'upload',
        label: headerItemsLabels.UPLOAD,
        disabled: !isAssetActivated(this.props.asset.get('assetStatus')),
        action: actions.header.onUploadFileClicked,
        className: 'no-icon',
        actionData: {
          assetId: this.props.asset.get('id'),
          assetType: this.props.asset.get('objectType'),
          reloadParentComponent: this.reloadAssetPage,
        },
      });
    }

    return items;
  }

  isConfirmLocationDisabled = () => {
    const alerts = this.props.asset.get('alerts');
    const misplacedMaterialAlertExists = alerts.some(alert => {
      return alert.alertType === getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL')
    });

    return !misplacedMaterialAlertExists;
  }

  onTabClick = (tabKey) => {
    if (tabKey) {
      let assetId = this.props.match.params.assetId;
      let assetType = this.props.match.params.assetType;
      let newHash = `#/Asset/${assetType}/${assetId}/${tabKey}`;
      if (newHash !== window.location.hash) window.location.hash = newHash;
    }
  };

  getSelectedTab = () => {
    return this.props.match.params.tabId || TabKeys.OVERVIEW;
  };

  renderTabs = () => {
    let selectedTab = this.getSelectedTab();

    return (
      <Tabs id="topbar-tabs" activeKey={selectedTab} tabType="page" onSelect={this.onTabClick}>
        {this.renderOverviewTab()}
        {this.renderAttachmentTab()}
        {this.renderActivityLogTab()}
        {/*{this.renderRelatedAssetsTab()}*/}
      </Tabs>
    );
  };

  renderOverviewTab = () => {
    let assetType = this.props.match.params.assetType;

    return (
      <Tabs.Tab
        className="overview-tab"
        eventKey={TabKeys.OVERVIEW}
        title={this.labels.get('tabs.overview')}
        animation={false}
      >
        <OverviewTabContainer assetType={assetType} />
      </Tabs.Tab>
    );
  };

  renderAttachmentTab = () => {
    if (!this.props.asset || !this.props.asset.get('id') || !this.props.asset.get('objectType')) {
      return;
    }
    if (isGroup(this.props.asset.get('objectType'))) {
      //TODO: L CONT currently not supported
      return null;
    }
    return (
      <Tabs.Tab
        className="activity-log-tab"
        eventKey={TabKeys.ATTACHMENT}
        title={this.labels.get('tabs.attachments')}
        animation={false}
      >
        <AttachmentTabContainer match={this.props.match} />
      </Tabs.Tab>
    );
  };

  renderActivityLogTab = () => {
    if (!this.props.asset || !this.props.asset.get('id') || !this.props.asset.get('objectType')) {
      return;
    }

    return (
      <Tabs.Tab
        className="activity-log-tab"
        eventKey={TabKeys.ACTIVITY_LOG}
        title={this.labels.get('tabs.activitylog')}
        animation={false}
      >
        <ActivityLogTabContainer match={this.props.match} />
      </Tabs.Tab>
    );
  };

  renderRelatedAssetsTab = () => {
    return (
      <Tabs.Tab
        eventKey={'relatedAssets'}
        title={this.labels.get('tabs.relatedassets')}
        animation={false}
      >
        <RelatedAssetsTabContainer />
      </Tabs.Tab>
    );
  };

  renderReportMaintenanceDialog = (asset) => {
    return (
      <PL_DialogWrapper
        dialogComponent={ReportMaintenanceDialog}
        asset={asset}
        show={this.props.sReportMaintenanceDialogActionsData.get('show')}
        actions={this.props.actions.reportMaintenanceDialogActions}
        sData={this.props.sReportMaintenanceDialogActionsData}
        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  renderCreateAttachmentDialog = (asset) => {
    return (
      <PL_DialogWrapper
        dialogComponent={CreateAttachmentDialog}
        asset={asset}
        show={this.props.sCreateAttachmentDialogData.get('show')}
        actions={this.props.actions.createAttachmentDialogActions}
        sData={this.props.sCreateAttachmentDialogData}
        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  renderEditAttachmentDialog = (asset) => {
    return (
      <PL_DialogWrapper
        dialogComponent={EditAttachmentDialog}
        asset={asset}
        show={this.props.sEditAttachmentDialogData.get('show')}
        actions={this.props.actions.editAttachmentDialogActions}
        sData={this.props.sEditAttachmentDialogData}
        preselectedData={this.props.attachmentTabGrid}
        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  renderArchiveAssetsDialog = (asset) => {
    return (
      <PL_DialogWrapper
        dialogComponent={ArchiveAssetsDialog}
        asset={asset}
        show={this.props.sArchiveAssetsDialogData.get('show')}
        sData={this.props.sArchiveAssetsDialogData}
        actions={this.props.actions.archiveAssetsDialogActions}
        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  renderActivateAssetsDialog = (asset) => {
    return (
      <PL_DialogWrapper
        dialogComponent={ActivateAssetsDialog}
        asset={asset}
        show={this.props.sActivateAssetsDialogData.get('show')}
        sData={this.props.sActivateAssetsDialogData}
        actions={this.props.actions.activateAssetsDialogActions}
        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  reloadAssetPage = () => {
    this.props.actions.assetPageActions.init(
      true,
      this.props.assetDetails.objectType.toLowerCase(),
      this.props.assetDetails.id
    );
  };

  renderShelfLifeUpdateDialog = () => {
    return (
      <PL_DialogWrapper
        dialogComponent={ShelfLifeUpdateDialog}
        show={this.props.sShelfLifeUpdateDialogData.get('show')}
        actions={this.props.actions.shelfLifeUpdateDialogActions}
        sData={this.props.sShelfLifeUpdateDialogData}
        currentAsset={this.props.assetDetails}
        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  renderPrintRfidTagsDialog = (asset) => {
    return (
      <PL_DialogWrapper
        dialogComponent={PrintRfidTagsDialog}
        show={this.props.sPrintRfidTagsDialogData.get('show')}
        actions={this.props.actions.printRfidTagsActions}
        sData={this.props.sPrintRfidTagsDialogData}
        currentAsset={this.props.assetDetails}
        reloadParentComponent={this.reloadAssetPage}
        asset={asset}
      />
    );
  };

  renderEditKitMaterialsDialog = () => {
    return (
      <PL_DialogWrapper
        dialogComponent={EditKitMaterialsDialog}
        show={this.props.sEditKitMaterialsDialogData.get('show')}
        actions={this.props.actions.editKitMaterialsDialogActions}
        sData={this.props.sEditKitMaterialsDialogData}
        //currentAsset={this.props.assetDetails}
        reloadParentComponent={this.reloadAssetPage}
      />
    );
  };

  render() {
    let { asset } = this.props;
    let headerItems = this.getHeaderItems();

    return (
      <div>
        <div className="asset-page">
          <Header {...headerItems} />
          <FullScreenTabsSeparator />
          {this.renderTabs()}
        </div>
        {this.renderArchiveAssetsDialog(asset)}
        {this.renderActivateAssetsDialog(asset)}
        {this.renderReportMaintenanceDialog(asset)}
        {this.renderCreateAttachmentDialog(asset)}
        {this.renderEditAttachmentDialog(asset)}
        {this.renderShelfLifeUpdateDialog()}
        {this.renderEditKitMaterialsDialog()}
        {this.renderPrintRfidTagsDialog()}
        <WeightWizardContainer />
      </div>
    );
  }
}

AssetPage.propTypes = {
  asset: PropTypes.object.isRequired,
};

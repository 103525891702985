import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../../../Common/Layout/Link/link';
import Label from 'infrastructure/js/components/Label/label';
import {navigationStates} from '../../../../../../enums/navigationStates';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./moreInfoCell.scss');

export default class MoreInfoCell extends React.PureComponent {
constructor(props) {
  super(props);

  this.labels = createLabelHelper('mat.grid.cells.moreInfo.');
}

  render() {
    let info = this.props.value;
    if (!info) {
      return '';
    }

    return (<div className="kitting-more-info-custom-cell">
      <label>{this.labels.get('kitId')}<Link label={info.kitId} id={info.kitIdKey} type={navigationStates.KIT}/></label>
      <label>{this.labels.get('project')}<Label text={info.project}/></label>
      <label>{this.labels.get('workOrder')}<Link label={info.workOrder} id={info.workOrderId} type={navigationStates.WORKORDER}/></label>
      <label>{this.labels.get('location')}<Label text={info.locationName}/></label>
    </div>);
  }
}

MoreInfoCell.propTypes = {
  value : PropTypes.object
};





import { actionTypes as notificationsPageActionTypes } from '../../actions/NotificationsPage/notificationsPageActions.js';
import {Map} from 'immutable';


let defaultState = {
  loading: false,
  dataList: [],
  queryResultInfo: {}
};


export default function (state = Map(defaultState), action) {
  switch (action.type) {

    case notificationsPageActionTypes.NOTIFICATIONSPAGE_FETCHDATA_STARTED:
      return state.set('loading', true);

    case notificationsPageActionTypes.NOTIFICATIONSPAGE_FETCHDATA_FINISHED:
      return state
        .set('dataList',        action.payload.dataList)
        .set('queryResultInfo', action.payload.queryResultInfo)
        .set('loading',         false);

    case notificationsPageActionTypes.NOTIFICATIONSPAGE_UNMOUNT:
      return Map(defaultState);

    default:
      return state;

  }
}



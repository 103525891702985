import { fromJS, List } from 'immutable';

import { default as scanAssetPageReducer } from './ScanAssetPage/scanAssetPageReducer.js';
import { default as scanToolPageReducer } from './ScanToolPage/scanToolPageReducer.js';
import { default as changeLocationPageReducer } from './ChangeLocationPage/changeLocationPageReducer.js';
import { default as scannedToolPageReducer } from './ScannedToolPage/scannedToolPageReducer.js';

import { actionTypes as scanAssetPageActionTypes } from '../../actions/Mobile/ScanAssetPage/scanAssetPageActions.js';
import { actionTypes as scanToolPageActionTypes } from '../../actions/Mobile/ScanToolPage/scanToolPageActions.js';
import { actionTypes as headerActions } from '../../actions/Mobile/Header/headerActions.js';

import scanWorkOrderPageReducer from './ScanWorkOrderPage/scanWorkOrderPageReducer.js';

let defaultState = {
  data: {
    scannedAssets: List(),
    scannedTool: null,
  },
};

export default function (state = fromJS(defaultState), action) {
  state = state.set('scanAssetPage', scanAssetPageReducer(state.get('scanAssetPage'), action));
  state = state.set('scanToolPage', scanToolPageReducer(state.get('scanToolPage'), action));
  state = state.set('changeLocationPage', changeLocationPageReducer(state.get('changeLocationPage'), action));
  state = state.set('scannedToolPage', scannedToolPageReducer(state.get('scannedToolPage'), action));
  state = state.set('scanWorkOrderPage', scanWorkOrderPageReducer(state.get('scanWorkOrderPage'), action));

  switch (action.type) {
    case scanAssetPageActionTypes.SCANASSET_PAGE_SUBMIT_FINISHED:
      state = state.updateIn(['data', 'scannedAssets'], (scannedAssets) =>
        scannedAssets.filter((asset) => asset.id !== action.payload.id).push(action.payload)
      );
      return state;

    case scanToolPageActionTypes.SCANTOOL_PAGE_SUBMIT_FINISHED:
      return state.setIn(['data', 'scannedTool'], action.payload);

    // case changeLocationPageActionTypes.CHANGELOCATION_PAGE_SUBMIT_FINISHED:
    //   return state.setIn(['data', 'scannedAssets'], List());
    case headerActions.SCAN_HEADER_REMOVE_ALL_SCANS:
      state = state.setIn(['data', 'scannedAssets'], List()).setIn(['data', 'scannedTool'], null);
      return state;
    case headerActions.SCAN_HEADER_REMOVE_SCAN_AT:
      return state.updateIn(['data', 'scannedAssets'], (scannedAssets) => scannedAssets.splice(action.payload, 1));
    default:
      return state;
  }
}

import { actionTypes as editAssetAttributesActionTypes } from '../../../actions/Dialogs/EditAssetAttributesDialog/editAssetAttributesDialogActions.js';
import MaterialHelper from '../../../utils/materialHelper.js';

//import {mapToSearchableMultiSelectAssetData} from '../../../utils/assetHelper';
import { Map, List } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';

let defaultState = {
  show: false,
  assets : Map(),
  materials: [],
  lots: [],
  toolTypes: [],
  groupTypes: [],
  manufacturers: [],
  locations: [],
  projects: [],
  inspectionStatuses: [],

  preselectedAssets: List(),
  entitiesTypes: List(),
  boEntityFilter: '',
  cancelToken: '',
  loading: false,
  additionalFields:[],
  editLengthOnly: false,
};


export default function(state = Map(defaultState), action) {

  switch(action.type) {
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_ASSETS_READY:
      // return state.setIn(["assets", action.payload.assetType], action.payload.dataList.map(mapToSearchableMultiSelectAssetData) );
      return state.setIn(["assets", action.payload.assetType.toLowerCase()], action.payload.dataList );
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_SUBMIT_IN_PROGRESS:
      return state.set("loading", true);
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_SUBMIT_FINISHED:
      return state.set("loading", false);
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ? List(action.payload.preselectedAssets) : List())
                  .set('entitiesTypes', action.payload && action.payload.entitiesTypes ? List(action.payload.entitiesTypes) : List())
                  .set('boEntityFilter', action.payload && action.payload.boEntityFilter ? action.payload.boEntityFilter : '')
                  .set('editLengthOnly', action.payload && action.payload.editLengthOnly ? action.payload.editLengthOnly : false)
                  .set('show', true);
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_HIDE:
       return Map(defaultState);

    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_SAVE_CANCEL_TOKEN:
      return state.set("cancelToken", action.payload);

    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_MATERIALS_READY:
      return state.setIn(["materials"], action.payload.dataList.map((obj)=> { return MaterialHelper.convertToMaterialItem(obj) }));
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_LOTS_READY:
      return state.setIn(["lots"], action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.name } }));
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_TOOL_TYPES_READY:
      return state.set('toolTypes', action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId, data: obj } }));
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_GROUP_TYPES_READY:
      return state.set('groupTypes', action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId, data: obj } }));
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_MANUFACTURERS_READY:
      return state.setIn(["manufacturers"], action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.name} }));
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_LOCATIONS_READY:
      return state.set('locations', action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.name} }));
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_PROJECTS_READY:
      return state.set('projects', action.payload.dataList.map((obj)=> { return { value: obj.id, label: obj.businessId} }));
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_INSPECTION_STATUSES_READY:
      return state.set('inspectionStatuses', action.payload);
    case editAssetAttributesActionTypes.EDITASSETATTRIBUTES_DIALOG_FETCH_ADDITIONAL_FIELDS_READY:
      return state.set('additionalFields', action.payload.dataList);

    default:
      return defaultDialogReducer(state, action, dialogsNames.EDITASSETATTRIBUTES_DIALOG, defaultState);
  }
}



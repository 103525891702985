import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import { jsxActions as commonBarcodeSettingsActions } from '../../../../actions/Administration/SettingsPage/commonBarcodeSettingsActions';
import { jsxActions as settingsPageActions } from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import RollBarcodeSettingsView from '../../../../components/Administration/SettingsPage/BarcodeSettings/RollBarcodeSettingsView/rollBarcodeSettingsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonBarcodeSettingsActions, dispatch),
      settingsPageActions: bindActionCreators(settingsPageActions, dispatch)
    }
  };
}

let RollBarcodeSettingsContainer = connect(
    (state) => {
      return {
        // isDirty: isDirty(formName)(state),
        // sData: state.administration?.getIn(['settingsPage', 'commonBarcodeSettingsView'])
        sData: state.administration.getIn(['settingsPage', 'commonBarcodeSettingsView']),
        isDirty: isDirty('barcodeSettingsForm')(state),
      }
    },
    mapDispatchToProps
  )(RollBarcodeSettingsView);

export default RollBarcodeSettingsContainer;

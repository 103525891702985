import React from 'react';

import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import PhoneField from 'infrastructure/js/components/controls/PhoneField/phoneField';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import {userModes} from '../../../enums/userModes';
import * as UserHelper from '../../../utils/userHelper';
import ChangePassword from 'infrastructure/js/components/ChangePassword/changePassword';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';


require('./userManagementDialog.scss');

class UserManagementDialog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.usermanagement.');
    this.rolesLabels = createLabelHelper('mat.usermanagement.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  onEntered = () => {
    this.allRoles = this.createRolesOptions(this.props.sData.get('sRoleData'));

    this.allLocations = this.props.sData.get('sLocationsData');

    if ( this.props.userManagementMode === userModes.USER_EDIT ) {
      let user = this.props.sData.get('user');
      let initialValue = {
        user_name: user.username,
        user_email: user.email,
        user_first_name : user.firstName,
        user_last_name: user.lastName,
        user_phone: user.phone,
        user_roles: this.getPreselectedRoles(),

      };
      this.props.initialize(initialValue);
    }

    this.selectRolesOptionRedux(this.getPreselectedRoles());
  };

  onHide =() => {
    this.props.destroy();
    this.props.actions.hide();
  };

  createRolesOptions = (roles) => {
    return roles ?  roles.map((op) => {return {label: this.rolesLabels.get(op.labelKey), value: op.name, data: op}}) : [];
  };

  getPreselectedRoles = () => {
    let preselectedRoles = [];
    if (this.props.userManagementMode === userModes.USER_EDIT) {
      preselectedRoles = this.props.sData.get('user').roles;
    }

    return this.createRolesOptions(preselectedRoles);
  };

  selectRolesOptionRedux = (options) => {
      this.props.change('user_roles', options);
  };

  isLoggedInUser = () => {
    return this.props.isLoggedInUser;
  };

  isAdmin = () => {
    return UserHelper.isAdmin(this.props.loggedInUserData);
  };

  isEmailFieldAutoFocus = () => {
    if (this.isAdmin())
      return 'autoFocus';
    else
      return null;
  };

  isFirstNameFieldAutoFocus = () => {
    if (this.isLoggedInUser())
      return 'autoFocus';
    else
      return null;
  };




  handleSubmit = (data) => {
    let {user_name, user_email, user_first_name, user_last_name, user_phone,confirmPassword} = data;
    let values = {
      email: user_email,
      firstName: user_first_name,
      lastName: user_last_name,
      phone: user_phone,
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };

    if (!this.isLoggedInUser()) {
      let selectedRoles =  data.user_roles.map((item) => {
        return item.value;
      });

      values.roles = selectedRoles;
    }

    if (this.props.userManagementMode === userModes.USER_EDIT) {
      let user = this.props.sData.get('user');
      values.id = user.id;
    }
    else if (this.props.userManagementMode === userModes.USER_CREATE) {
      values.username = user_name;
      values.password = confirmPassword;
    }



    let messageDialogBuilder = (this.props.userManagementMode === userModes.USER_CREATE) ?
      this.getMessageDialogBuilder() : null;

    this.props.actions.onSubmit(values, messageDialogBuilder, this.isLoggedInUser());
  };

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {

      let children;

      let rows = [
        {label: this.labels.get('confirmation.message.username'), value: data.username},
        {label: this.labels.get('confirmation.message.organization'), value: data.userOrg ? data.userOrg.name : '' }
      ];

      if (data) {
        children = rows.map((item, index) => {
          return <MessageDialog.DataRow key={`confirmMessageDataRow${index}`} label={item.label} value={item.value}/>
        });
      }

      let title = this.labels.get('confirmation.message.header');
      let buttons = [{id:'close',text: this.dialogLabels.get('close'), action: fnCloseDialog, bsStyle: 'primary'}];
      let className = 'user-management-confirm-message';
      let messageDialogDescriptor = {title, children, buttons, className};
      return messageDialogDescriptor;
    };
  };



  createFooterButtons() {
    return {
      left: [{
        id:'cancel',
        text: this.dialogLabels.get('cancel'),
        action: this.onHide
      }],
      right: [
        {
          id:'submit',
          text: this.getPrimaryButtonText(),
          bsStyle: 'primary',
          action: this.props.handleSubmit(this.handleSubmit),
          loading: this.props.sData.get('loading'),
          disabled: ( (this.props.userManagementMode === userModes.USER_EDIT && this.props.pristine) ||
                       this.props.sData.get('loading') ||
                       this.props.sData.get('hasError') ||
                       (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
                    )
        }
      ]
    };
  };

  getTitleText = () => {
   return this.props.userManagementMode ===  userModes.USER_EDIT ? this.labels.get('title') : this.labels.get('create.title');
  };

  getPrimaryButtonText = () => {
    return this.props.userManagementMode ===  userModes.USER_EDIT ? this.dialogLabels.get('save') : this.dialogLabels.get('create');
  };

  renderRoleField = () => {
    if (this.isLoggedInUser()) {
      return null;
    }

    return (
      <InputSection label={this.labels.get('role') + '*'} htmlFor='user_roles' className="inline right-side">
        <PL_MultiSelectField name='user_roles'
                             id='user_roles'
                             options={this.allRoles}
                             allowNewOption={false}
                             placeholder={this.labels.get('role.inputclue')}
                             validate={Validation.dropdown.required}
        />
      </InputSection>
    )

  };

  renderEditFields = () => {
    return (
      <div>
        <InputSection label={this.labels.get('username')+'*'} htmlFor="user_name" className='inline left-side no-margin' >
          <TextField id="UsernameInput" name="user_name" disabled={true}/>
        </InputSection>

        <InputSection label={this.labels.get('email')} htmlFor="user_email" className="inline right-side no-margin">
          <TextField id="EmailInput" name="user_email" autoFocus={this.isEmailFieldAutoFocus()}
                     validate={Validation.emailNotRequired}/>
        </InputSection>

        <InputSection label={this.labels.get('firstname')+'*'} htmlFor="FirstNameInput" className="inline left-side">
          <TextField id="FirstNameInput" name="user_first_name" autoFocus={this.isFirstNameFieldAutoFocus()} maxLength={30} validate={Validation.required} />
        </InputSection>

        <InputSection label={this.labels.get('lastname')+'*'} htmlFor="LastNameInput" className="inline right-side">
          <TextField id="LastNameInput" name="user_last_name" maxLength={30} validate={Validation.required} />
        </InputSection>

        <InputSection label={this.labels.get('phone')} htmlFor="PhoneInput" className="inline left-side">
          <PhoneField id='PhoneInput' name='user_phone' />
        </InputSection>

        {this.renderRoleField()}
      </div>
    );
  };

  renderCreateFields = () => {
    return (
      <div>
        <InputSection label={this.labels.get('username')+'*'} htmlFor="user_name" className="inline left-side no-margin">
          <TextField id="UsernameInput" name="user_name" autoFocus validate={Validation.required} normalize={Normalize.stringAndNumber(true)} maxLength={30}/>
        </InputSection>

        <InputSection label={this.labels.get('email')} htmlFor="user_email" className="inline right-side no-margin">
          <TextField id="EmailInput" name="user_email" validate={Validation.emailNotRequired}/>
        </InputSection>

        <InputSection label={this.labels.get('firstname')+'*'} htmlFor="FirstNameInput" className="inline left-side">
          <TextField id="FirstNameInput" name="user_first_name" maxLength={30}
                     validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get('lastname')+'*'} htmlFor="LastNameInput" className="inline right-side">
          <TextField id="LastNameInput" name="user_last_name" maxLength={30}  validate={Validation.required}/>
        </InputSection>


        <InputSection label={this.labels.get('phone')} htmlFor="PhoneInput" className="inline left-side">
          <PhoneField id='PhoneInput' name='user_phone' />
        </InputSection>


        {this.renderRoleField()}

        <ChangePassword />
      </div>
    );
  };

  renderFields = () => {
    switch(this.props.userManagementMode) {
      case userModes.USER_EDIT:
        return this.renderEditFields();
      case userModes.USER_CREATE:
        return this.renderCreateFields();
      default:
        console.error('invalid userMode: ' + this.props.userManagementMode);
        break;
    }
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {
    let show = this.props.sData.get('show');
    if (!show) {
      return null;
    }
    return (
      <Dialog
        id="UserManagementDialog"
        className='user-management-dialog'
        show={this.props.show}
        sData={this.props.sData}
        onHide={this.onHide}
        titleText={this.getTitleText()}
        onEntered={this.onEntered}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.createFooterButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.handleSubmit)}
      >

      {this.renderFields()}

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'userManagementDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(UserManagementDialog);



import React from 'react';
import {reduxForm} from 'redux-form';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import Form from '../../Common/Form/form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import {MassOperationsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./massCreationView.scss');


class MassCreationView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.massoperations.form.');
    this.entityLabels = createLabelHelper('mat.administration.massoperations.form.entity.');

    this.entitiesOptions = [{label: this.entityLabels.get('hooks'), value: 'hooks'}];

    this.state = {currentEntity: this.entitiesOptions[0]};

    this.props.change( 'massEntity', this.entitiesOptions[0] );
  }

  // componentWillMount() { //moved to constructor
  //   this.props.change( 'massEntity', this.entitiesOptions[0] );
  // }

  componentDidMount() {
    this.props.actions.onSelectedMenuItemChanged(MassOperationsMenuItemsIds.NAV_ELEMENT_MASS_CREATION);
  }

  getFormButtons() {
    let loading = this.props.sData.get('loading');
    let icon = loading ? <i className='fa fa-spinner fa-spin fa-fw' aria-hidden="true"></i> : 'pl pl-added';

    return (
      [
        {
          text: this.labels.get('createhooks.footer.create'),
          className: 'border-btn btn',
          icon:   'pl pl-added',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
        }
      ]
    );
  }

  //NOTE: Currently is always checked for Hooks
  getFormCustomButton() {
    return (
      <div className="checkbox-section" >
        <label htmlFor="printLabels"  >
          <Checkbox name="printLabels" id="printLabels"
            // label={this.labels.get('createhooks.footer.printlabels')}
                    checked={true} disabled/>
          <span>{this.labels.get('createhooks.footer.printlabels')}</span>
        </label>
      </div>
    );
  }

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let totalJobItems = response.printedCounter + response.pendingCounter + response.failedCounter;
      let title = this.labels.get('createhooks.confirmation.message.title', '', { count: totalJobItems});
      return dialogHelper.BuildDialogDescriptorForMassOps(response, fnCloseDialog, title);
    };
  };

  onChangeCallback = (value) => {
    this.setState({currentEntity: value});
  };

  renderEntityAttributes = () => {
    return (
      <div className="hook-attributes-section">
        <InputSection label={this.labels.get('createhooks.quantity')+'*'} htmlFor="quantity" className="inline left-side">
          <TextField id="quantity" name="quantity"
                     validate={Validation.required}
                     normalize={Normalize.number(true, 0, 9999)}/>
        </InputSection>

        <InputSection label={this.labels.get('createhooks.maxcyclecount')+'*'} htmlFor="maxCycleCount" className="inline right-side">
          <TextField id="maxCycleCount" name="maxCycleCount"
                     validate={Validation.required}
                     normalize={Normalize.number(true, 0, 9999)}/>
        </InputSection>
      </div>
    );
  };


  onSubmit = (data) => {

    let hasDefaultLocation = PermissionManager.getOrgPreferences().defaultLocationId;
    let locationID = hasDefaultLocation ?PermissionManager.getOrgPreferences().defaultLocationId : '';

    if (!locationID) {
      console.error('Hooks Mass creatioh - defaultLocationId is missing');
      return;
    }

    let newData = {numberOfHooks: data.quantity,
      maxNumOfCycles: data.maxCycleCount,
      locationId: locationID,
      usedFor: 'Paint Hook' };


    this.props.actions.submit(newData, this.getMessageDialogBuilder());
  };

  render() {
    let title = this.labels.get('title');
    return (
      <div className="mass-creation-view">
        <div className="title">{title}</div>
        <Form className="mass-creation-form"
              sData={this.props.sData}
              formButtons={this.getFormButtons()}
              formCustomButton={this.getFormCustomButton()}
        >
          <InputSection label={this.labels.get('selectentity.text')} htmlFor="massEntity" className="inline ">
            <Combobox id="massEntity" name="massEntity" options={this.entitiesOptions} disabled/>
            {/*<Combobox id="massEntity" name="massEntity" options={this.entitiesOptions}*/}
            {/*onChangeCallback={this.onChangeCallback}/>*/}
          </InputSection>

          {this.renderEntityAttributes()}

        </Form>
      </div>
    );
  }
}

export default reduxForm({
    form: 'massCreationForm',
  }
)(MassCreationView);


import LoginService from 'infrastructure/js/services/LoginService.js';
import Network from 'infrastructure/js/modules/network.js';
import LanguageService from 'infrastructure/js/services/LanguageService.js';
import {api as localizationActionsApi} from 'infrastructure/js/actions/languageActions.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import * as LocationService from '../../services/Locations/locationsService';
import uomHelper from 'infrastructure/js/utils/uomHelper.js';
import MaterialHelper from '../../utils/materialHelper';
import ToolTypeHelper from '../../utils/toolTypeHelper';
import NotificationCategoriesHelper from '../../utils/notificationCategoriesHelper';
import {api as enumsApi} from '../Enums/enumsActions';
import {api as systemApi} from '../System/systemActions';
import {api as  securityMessageDialogApi} from '../Dialogs/securityMessageDialogActions';
import {isIntegrationUser} from "../../utils/userHelper";
import userService from "../../services/UserManagement/UserService";
import * as AppHelper from 'infrastructure/js/utils/appHelper';
import { actions as changePasswordActions } from '../ChangePassword/changePasswordActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager'
import { getHomePagePathByType, getPathByType} from '../../utils/navigationHelper';
import { navigationStates } from '../../enums/navigationStates';
import { api as navigatorActionsApi } from '../AppTopbar/navigatorActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LOGIN_LOGIN_IN_PROCESS: "LOGIN_LOGIN_IN_PROCESS",
  LOGIN_LOGIN_FINISHED: "LOGIN_LOGIN_FINISED",
  LOGIN_LOGIN_FAILED : "LOGIN_LOGIN_FAILED",
  LOGIN_UPDATE_LOGGEDIN_USER_DATA : "LOGIN_UPDATE_LOGGEDIN_USER_DATA",
  LOGIN_UPDATE_LOGGEDIN_USER_ORG_PREFERENCES: 'LOGIN_UPDATE_LOGGEDIN_USER_ORG_PREFERENCES',
  LOGIN_FETCH_LOCATIONS_FINISHED: 'LOGIN_FETCH_LOCATIONS_FINISHED',
  LOGIN_PRE_LOGIN_FINISHED: 'LOGIN_PRE_LOGIN_FINISHED',
  LOGIN_UPDATE_USER_EXPIRED: 'LOGIN_UPDATE_USER_EXPIRED',
  LOGIN_UPDATE_PASSWORD_CONFIG: 'LOGIN_UPDATE_PASSWORD_CONFIG'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
 const actions = {
  loginInProcess: function() {
    return {type: actionTypes.LOGIN_LOGIN_IN_PROCESS };
  },
  loginFailed: function(message) {
    return {type: actionTypes.LOGIN_LOGIN_FAILED , payload: message};
  },
  loginFinished: function(user) {
    return {type: actionTypes.LOGIN_LOGIN_FINISHED, payload: {user} };
  },
  updateLoggedInUserData: function(user) {
    return {type: actionTypes.LOGIN_UPDATE_LOGGEDIN_USER_DATA, payload: {user} };
  },
  loginFetchLocationsFinished: function(payload) {
    return {type: actionTypes.LOGIN_FETCH_LOCATIONS_FINISHED ,payload: payload };
  },
  updateLoggedInUserOrgPreferences: function(preferences){
    return {type: actionTypes.LOGIN_UPDATE_LOGGEDIN_USER_ORG_PREFERENCES, payload: {preferences} };
  },
  preLoginFinished: function() {
    return {type: actionTypes.LOGIN_PRE_LOGIN_FINISHED};
  },
  updateUserPasswordExpired: function (payload) {
    return {type: actionTypes.LOGIN_UPDATE_USER_EXPIRED, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.login = function(values) {

  return function(dispatch, getState) {
    dispatch(actions.loginInProcess());
    return LoginService.login(values).then((response) => {

      let errorMessage = 'The login details you provided are incorrect.';
      if(!Network.isResponseValid(response)){

        if(response && response.applicationResponseStatus){
          errorMessage =  response.applicationResponseStatus.message;
        }
        return dispatch(actions.loginFailed(errorMessage));
      }

      if(isIntegrationUser(response.data)){
        return dispatch(actions.loginFailed(errorMessage));
      }

      if(response.data.status.name === 'CREDENTIALS_EXPIRED'){

        LoginService.fetchPasswordConfig({orgId:response.data.loggedInOrg.id}).then((passwordConfigResponse) => {
          _updatePasswordConfigData(dispatch, passwordConfigResponse.data);

          //in this case we need the labels for the validations
          return LanguageService.getCurrentUserLanguage().then((languageResponse) => {
            let messagesObject = {locale: 'en', messages: languageResponse.map};
            localizationActionsApi.updateLocalization(dispatch, getState)(messagesObject);

            dispatch(actions.updateUserPasswordExpired(response.data.loggedInOrg.id));
            dispatch(actions.preLoginFinished());
            return window.location.hash = '#/ChangePassword';
          });
        });
        return;
      }
      //server does the logic if the page should be displayed
      if(response.data.mfaSmsEnabled){
       dispatch(actions.preLoginFinished());
       return window.location.hash = '#/Authenticate';
      }
      if(response.data.loggedInOrg.orgPreferences.userAgreementMessageEnabled){
        return api.showSecurityMessageDialog(response.data)(dispatch, getState)
      }
      api.executePostLoginActions(response.data)(dispatch, getState);
    });
  }
};

jsxActions.navigateToDefaultLocation = function(status, entityId, defaultEntityType) {
  return function(dispatch, getState) {
    api.navigateToDefaultLocation(status, entityId, defaultEntityType)(dispatch, getState);
  };
};

jsxActions.onDoLogout = function(shouldReloadPage = true) {
  return function(dispatch, getState) {
    api.logout(shouldReloadPage)(dispatch, getState);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.showSecurityMessageDialog = function(user) {
  return function(dispatch, getState) {
    return securityMessageDialogApi.show(dispatch, getState)(user);
  }
};

api.executePostLoginActions = function(userData){
  return function(dispatch, getState){
    systemApi.initServerDatetime()(dispatch, getState);

    const getCurrentUserLanguagePromise = LanguageService.getCurrentUserLanguage();
    const fetchPasswordConfigPromise = LoginService.fetchPasswordConfig({orgId:userData.loggedInOrg.id});

    Promise.all([getCurrentUserLanguagePromise, fetchPasswordConfigPromise]).then(allResults => {
      let messagesObject = {locale: 'en', messages: allResults[0]?.map};
      localizationActionsApi.updateLocalization(dispatch, getState)(messagesObject);

      _updatePasswordConfigData(dispatch, allResults[1]?.data);

      enumsApi.getEnums().then(() => {
        _initializeHelpers(userData);
        let promiseFetchLocations = null;
        //If the user's status is 'password_change_required' fetch Locations in order to populate 'Home Page Details'
        //combobox in registration  page.
        if(userData.status.name === 'PREACTIVE'){
          promiseFetchLocations =  LocationService.fetchActiveLocations(userData).then((responseLocations) => {
            let locations = {
              dataList: responseLocations.dataList
            };
            dispatch(actions.loginFinished(userData));
            dispatch(actions.loginFetchLocationsFinished(locations));
          });
        } else {
          dispatch(actions.loginFinished(userData));
        }

        localStorage.setItem("orgID", userData.loggedInOrg.name);

        const {defaultEntityType, defaultEntityId} = userData.userPreferences;
        navigatorActionsApi.setHomePage(dispatch, getState)(defaultEntityType, defaultEntityId)

        if(promiseFetchLocations){
          return promiseFetchLocations.then((response) => {
            api.navigateToDefaultLocation(userData.status.name, defaultEntityId, defaultEntityType)(dispatch, getState);
          })
        }
        else {
          api.navigateToDefaultLocation(userData.status.name, defaultEntityId, defaultEntityType)(dispatch, getState);
        }
      });
    });
  }
};

api.navigateToDefaultLocation = function(status, defaultEntityId, defaultEntityType) {
  return function(dispatch, getState) {
    let path = '#/ManagerReport';
    if(status === 'PREACTIVE'){
      path = '#/Register';
    }
    else if (AppHelper.isMobileApp()) {
      path = '#/Mobile';
    }
    else if (PermissionManager.hasAccessToMultipleOrgs()){
      path = '#' + getPathByType(navigationStates.MANAGER_REPORT);
    } else {
      const defaultHomePage = getPathByType(navigationStates.MANAGER_REPORT);
      const homePagePath = getHomePagePathByType(defaultEntityType, defaultEntityId) || defaultHomePage;
      path = '#' + homePagePath;
    }
    window.location = path;
  }
};

api.loginFinished = function(user) {
  return function(dispatch, getState) {
    dispatch(actions.loginFinished(user));
  }
};

api.updateLoggedInUserOrgPreferences = function(){
  return function(dispatch, getState) {
    return userService.getCurrentUserProfile().then((response) => {
      if(!Network.isResponseValid(response)){
        console.error('getting current user profile failed');
        return {success:false};
      }

      _initHelpersWithPreferences(response.data);
      dispatch(actions.updateLoggedInUserOrgPreferences(response.data.loggedInOrg.orgPreferences));
    });
  }
};
api.updateLoggedInUserData = function(user) {
  return function(dispatch, getState) {
    dispatch(actions.updateLoggedInUserData(user));
  }
};

api.logout = function(shouldReloadPage) {
  return function(dispatch, getState) {

    LoginService.logout()
      .then((response) => {

        if(!shouldReloadPage){
          systemApi.clearAllData()(dispatch, getState);
        }

        window.location.hash = '/Login';
        if(shouldReloadPage) {
          window.location.reload();
        }
      });
  };
};

function _updatePasswordConfigData(dispatch, config) {
  let tooltip = config.tooltipPrefix + ' ';
  tooltip += config.tooltipItems.join(', ');
  dispatch(changePasswordActions.updatePasswordConfig({passwordTooltip:tooltip, passwordConfig:config}));
}

function _initializeHelpers(userData){
  DateTimeHelper.init(userData.loggedInOrg.orgPreferences, userData.userOrg.orgPreferences);
  uomHelper.setSystemUnits(userData.loggedInOrg.orgPreferences);
  NotificationCategoriesHelper.init();
  _initHelpersWithPreferences(userData);
}

//after modifications on preferences - init relevant helpers
function _initHelpersWithPreferences(user) {
  PermissionManager.init(user);
  MaterialHelper.init(user?.loggedInOrg?.orgPreferences?.propertyDisplayMap ? user.loggedInOrg.orgPreferences.propertyDisplayMap?.['materialFieldOrder'] : 'NAME_FIRST');
  ToolTypeHelper.init(user?.loggedInOrg?.orgPreferences?.toolTypeFieldOrder ? user.loggedInOrg.orgPreferences.toolTypeFieldOrder : 'NAME_FIRST');
}
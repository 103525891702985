import { fromJS } from 'immutable';

import { actionTypes as wizardActionTypes }    from './wizardActions';


let defaultState = {

};

let defaultNamedWizardState = {
  isActive: false,
  currentStep: null
};



export default function(state = fromJS(defaultState), action) {

  // Handle special action that doesn't require wizard name.
  if (action.type === wizardActionTypes.WIZARD_KILLEMALL) {
    return fromJS(defaultState);
  }


  // Detect if the action bears wizardName.
  let wizardName = action.payload && action.payload.wizardName;

  // Ignore all actions that don't have wizardName.
  if (!wizardName) {
    return state;
  }

  // Prepare default named wizard state for current action if needed.
  if (!state.get(wizardName)) {
    state = state.set(wizardName, fromJS(defaultNamedWizardState));
  }



  switch(action.type) {

    case wizardActionTypes.WIZARD_INITSTEPS:
      return state.setIn([wizardName, 'stepsData'], action.payload.stepsData);

    case wizardActionTypes.WIZARD_START:
      return state.setIn([wizardName, 'isActive'], true);

    case wizardActionTypes.WIZARD_CLOSE:
      return state.delete(wizardName);

    case wizardActionTypes.WIZARD_GOTOSTEP:
      state = state.setIn([wizardName, 'isActive'], true); // Support opening wizard on any step.
      state = state.setIn([wizardName, 'currentStep'], action.payload.stepKey);
      return state;



    default:
      return state;

  }


}
















import React from 'react';
import PropTypes from 'prop-types';


import Dialog from 'infrastructure/js/components/Dialog/dialog';
import ActivityLogDialogGrid from './ActivityLogDialogGrid/activityLogDialogGrid';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

//Style
require('./activityLogDialog.scss');



export default class ActivityLogDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
  }


  getDialogButtons() {
    return {
      left: [],
      right: [
        {
          id:'cancelBtn',
          text: this.dialogLabels.get('close'),
          bsStyle: 'primary',
          //loading: this.props.sData.get('loading'),
          action: this.onHide
        }
      ]
    };
  }

  onEntered = () => {
  };
  onHide = () => {
    this.props.actions.hide();
  };

  render() {
    let { show, data, gridData, activityLogGridActions } = this.props;
    if (!show) {
      return null;
    }
    let title = data.get('activityLogTitle');

    return (
      <Dialog
        id="activity-log-dialog"
        className="activity-log-dialog"
        show={show}
        onEntered={this.onEntered}
        onHide={this.onHide}
        titleText={title}
        footerButtons={this.getDialogButtons()}
      >
        <ActivityLogDialogGrid actions={activityLogGridActions} />

      </Dialog>
    )
  }
}

ActivityLogDialog.propTypes = {
  id : PropTypes.string,
  // type : PropTypes.string.isRequired,
  // children : PropTypes.any.isRequired,
  show : PropTypes.bool.isRequired,
  titleText : PropTypes.string,
  buttons : PropTypes.array,
  //onHide : PropTypes.func.isRequired,
  onEntered : PropTypes.func,
  onExited : PropTypes.func
};





import React from 'react';

import { getEditorDataConverter } from 'infrastructure/js/utils/editorHelper.js';

import DetailsColumn from '../../../../Common/DetailsComponents/detailsColumn.js';
import Link from '../../../../Common/Layout/Link/link';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { getEnumValue, enumTypes } from '../../../../../utils/enumHelper';
import { navigationStates } from '../../../../../enums/navigationStates.js';
import { isAssetActivated } from '../../../../../utils/assetHelper';
import * as AssetDetailsHelper from '../utils/assetDetailsHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import TextFieldEditor from 'infrastructure/js/components/editors/TextFieldEditor/textFieldEditor';
import NumericFieldEditor from 'infrastructure/js/components/editors/NumericFieldEditor/numericFieldEditor';
import ComboBoxEditor from 'infrastructure/js/components/editors/ComboBoxEditor/comboBoxEditor';
import KitMaterialsDetails from '../KitMaterialsDetails/kitMaterialsDetails';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import TagsAsyncEditor from '../../../Common/TagsAsyncEditor.js';

require('./kitDetails.scss');

export default class AssetDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.assetpage.overview.details.');
    this.kitStagelabels = createLabelHelper('enum.kitstage.');
    this.dateFormatTooltip = PermissionManager.isDateFormatEnabled()
      ? createLabelHelper('period.date.format.tooltip').get('', undefined, {
          format: DateTimeHelper.getDateFormat(),
        })
      : '';
  }

  convertEditorDataToServerData = getEditorDataConverter('kit');

  /*  convertEditorDataToServerData = (data) => {
    let name = data.fieldName;
    let obj = data[data.fieldName];
    let value = {};
    switch (name) {
      case 'locationId' :
        value = obj.value;
        break;
      case 'subLocation' :
        value = obj || null;
        break;
      case 'tag' :
        if (obj === null || Array.isArray(obj)) {
          value = null;
        } else {
          value.id = obj.value
        }
        break;
      case 'maxStorageTemp' :
        value = UnitHelper.userValueToServerValue(unitTypes.TEMPERATURE, obj, 3);
        break;
      case 'bag' :
        value = !obj ?  null : obj;
        break;
      case 'workOrders' :
        if (obj === null) {
          value = null;
        }
        else if (obj.value === obj.label) {
          value[obj.label] = null;
        }
        else {
          value[obj.label] = obj.value;
        }
        break;
      default:
        console.error('KitDetails: Unknown field name: ' + name);
        return undefined;
    }
    return value;
  }; */

  handleAdditionalFieldSubmit = (editorSubmitData, valueType, id) => {
    if (!editorSubmitData) {
      return;
    }

    let newData = AssetDetailsHelper.getAdditionalFieldValue(valueType, id, editorSubmitData);
    this.props.actions.assetEditorActions.updateKit(this.props.asset.id, newData);
  };

  handleAssetEditInPlaceSubmit = (editorSubmitData) => {
    if (!editorSubmitData) {
      return;
    }

    let serverDataValue = this.convertEditorDataToServerData(editorSubmitData);
    if (serverDataValue === undefined) {
      return;
    }
    let newData = {};
    newData[editorSubmitData.fieldName] = serverDataValue;

    //If location changed, erase subLocation, send empty value.
    if (
      editorSubmitData.fieldName === 'locationId' &&
      this.props.asset.location.id !== serverDataValue
    ) {
      newData['subLocation'] = null;
    }

    this.props.actions.assetEditorActions.updateKit(this.props.asset.id, newData);
  };

  renderLocationLink = (asset) => {
    return (
      <Link
        label={asset.locationName}
        id={asset.locationId}
        type={navigationStates.LOCATION}
        className="link-details"
      />
    );
  };

  renderSublocationEditor = (asset) => {
    return (
      <TextFieldEditor
        id="subLocation"
        name="subLocation"
        value={asset.subLocation}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
        validate={Validation.maxLength(50)}
      />
    );
  };

  renderTagEditor = (asset) => {
    //Convert server object value to singleLineMultiSelect object.

    return (
      <TagsAsyncEditor
        id="tags"
        name="tags"
        limit={10}
        asset={asset}
        onSubmit={this.handleAssetEditInPlaceSubmit}
        disabled={!isAssetActivated(asset.assetStatus)}
        parse={(data, name) => ({
          fieldName: name,
          tags: data.map(({ value }) => ({
            id: value,
          })),
        })}
      />
    );
  };

  renderAssetMaterials = (asset) => {
    return (
      <KitMaterialsDetails
        asset={asset}
        actions={this.props.actions.editKitMaterialsDialogActions}
      />
    );
  };

  renderAssetWorkOrder = (asset) => {
    if (asset.workOrder) {
      let project = asset.workOrder.project ? ` (${asset.workOrder.project.businessId})` : '';
      return (
        <Link
          label={`${asset.workOrder.businessId}${project}`}
          id={asset.workOrder.id}
          type={navigationStates.WORKORDER}
          className="link-details"
        />
      );
    }
    return (
      <ComboBoxEditor
        id="workOrders"
        name="workOrders"
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        options={this.props.assetEditorData.optionsAssetWorkOrders}
        allowNewOption={PermissionManager.hasCreateAssetsPermissions()}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderWorkOrderDueDate = (asset) => {
    if (asset.workOrder && asset.workOrder.dueDate) {
      return (
        <div>
          <span>{DateTimeHelper.FormatDateObjectToDayMonth(asset.workOrder.dueDate)}</span>
          {AssetDetailsHelper.getAlertPopover(
            asset,
            getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE')
          )}
        </div>
      );
    }
    return <span></span>;
  };

  renderExpirationDate = (asset) => {
    return asset.expirationDate ? (
      <div>
        <span>
          {asset.expirationDate
            ? DateTimeHelper.FormatDateObjectToDayMonth(asset.expirationDate.value)
            : ''}
        </span>
        {AssetDetailsHelper.getAlertPopover(
          asset,
          getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE')
        )}
      </div>
    ) : (
      this.renderUnavailableLabel()
    );
  };

  renderExposureTimeLeft = (asset, exposureTimeLeftType, alertType) => {
    if (!exposureTimeLeftType || exposureTimeLeftType.value === null) {
      return this.renderUnavailableLabel();
    }
    let exposureTimeLeft = DateTimeHelper.ConvertMinutesToHoursMinutes(exposureTimeLeftType.value);
    return (
      <div>
        <span>{exposureTimeLeft}</span>
        {AssetDetailsHelper.getAlertPopover(asset, getEnumValue(enumTypes.ALERT_TYPE)(alertType))}
        {AssetDetailsHelper.getInFreezer(asset, this.labels.get('infreezer'))}
      </div>
    );
  };

  renderMaxExposureTime(maxExposureTime) {
    if (maxExposureTime === null) {
      return this.renderUnavailableLabel();
    }
    return <span>{DateTimeHelper.ConvertMinutesToHoursMinutes(maxExposureTime)}</span>;
  }

  renderBondByDate = (value, showNaOnEmptyValue = false) => {
    if (!value && showNaOnEmptyValue) {
      return this.renderUnavailableLabel();
    }

    return <span>{value ? DateTimeHelper.DateTimeFormat(value.epochDateTime * 1000) : ''}</span>;
  };

  renderCureByDate = (value) => {
    return <span>{value ? DateTimeHelper.DateTimeFormat(value.epochDateTime * 1000) : ''}</span>;
  };

  renderMaxStorageTemperatureEditor = (asset) => {
    return (
      <NumericFieldEditor
        id="maxStorageTemp"
        name="maxStorageTemp"
        value={asset.maxStorageTemp}
        isInteger={true}
        min={UnitHelper.getMinValueForUnitType(unitTypes.TEMPERATURE, 0)}
        max={UnitHelper.getMaxValueForUnitType(unitTypes.TEMPERATURE, 0)}
        displayValue={asset.m_MaxStorageTemp || this.labels.get('notavailable')}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderBagEditor = (asset) => {
    return (
      <TextFieldEditor
        id="bag"
        name="bag"
        value={asset.bag}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };
  renderUnavailableLabel() {
    return <span>{this.labels.get('notavailable')}</span>;
  }
  getAssetDetails(asset, labels) {
    let index0 = [
      {
        label: this.labels.get('location'),
        body: this.renderLocationLink(asset),
      },
      {
        label: this.labels.get('sublocation'),
        body: this.renderSublocationEditor(asset),
      },
      {
        label: this.labels.get('tag'),
        body: this.renderTagEditor(asset),
      },
      {
        label: '',
        body: null,
      },
      {
        type: 'hr',
        body: <hr />,
      },
      {
        label: this.labels.get('createdat'),
        body: (
          <span id="create-date-input">
            {DateTimeHelper.FormatDateObjectToDayMonth(asset.createdDate)}
          </span>
        ),
        tooltip: this.dateFormatTooltip,
      },
      {
        label: this.labels.get('createdby'),
        body: <span id="create-by-input">{asset.createdByUser}</span>,
      },
      {
        type: 'assetMaterials',
        body: this.renderAssetMaterials(asset),
      },
    ];

    let index1 = [
      {
        label: this.labels.get('part'),
        body: <span id="part-input">{asset.partType ? asset.partType.businessId : ''}</span>,
      },
      {
        label: this.labels.get('wo'),
        body: this.renderAssetWorkOrder(asset),
      },
      {
        label: this.labels.get('woduedate'),
        body: this.renderWorkOrderDueDate(asset),
        tooltip: this.dateFormatTooltip,
      },
      {
        label: this.labels.get('status'),
        body: (
          <span id="kit-stage-input">
            {asset.stage ? this.kitStagelabels.get(asset.stage) : ''}
          </span>
        ),
      },
      {
        type: 'hr',
        body: <hr />,
      },
      {
        label: this.labels.get('bag'),
        body: this.renderBagEditor(asset),
      },
      {
        label: this.labels.get('curing.status'),
        body: (
          <span id="kit-curing.status">{asset.isCured ? this.labels.get('curing.cured') : ''}</span>
        ),
      },
    ];
    let index2 = [
      {
        label: this.labels.get('expirationdate'),
        body: this.renderExpirationDate(asset),
        tooltip: this.dateFormatTooltip,
      },
      ...(PermissionManager.isEtlBondEnabled()
        ? [
            {
              label: this.labels.get('exposureTimeLeftBond'),
              body: this.renderExposureTimeLeft(
                asset,
                asset.exposureTimeLeftBond,
                getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND')
              ),
            },
          ]
        : []),

      {
        label: this.labels.get('exposureTimeLeftCure'),
        body: this.renderExposureTimeLeft(
          asset,
          asset.exposureTimeLeftCure,
          getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE')
        ),
      },
      {
        label: '',
        body: null,
      },
      {
        type: 'hr',
        body: <hr />,
      },
      ...(PermissionManager.isEtlBondEnabled()
        ? [
            {
              label: this.labels.get('bondbydate'),
              body: this.renderBondByDate(asset.bondByDate, asset.isLayedUp),
              tooltip: this.dateFormatTooltip,
            },
          ]
        : []),
      {
        label: this.labels.get('cureByDate'),
        body: this.renderCureByDate(asset.cureByDate),
        tooltip: this.dateFormatTooltip,
      },
      ...(PermissionManager.isEtlBondEnabled()
        ? [
            {
              label: this.labels.get('maxExposureTimeBond'),
              body: this.renderMaxExposureTime(asset.maxExposureTimeBond),
            },
          ]
        : []),
      {
        label: this.labels.get('maxExposureTimeCure'),
        body: this.renderMaxExposureTime(asset.maxExposureTimeCure),
      },
      {
        label: this.labels.get('maxstoragetemp'),
        body: this.renderMaxStorageTemperatureEditor(asset),
      },
    ];

    let result = [index0, index1, index2];

    let additionalFields = this.props.assetEditorData.additionalFields
      ? this.props.assetEditorData.additionalFields.kitAdditionalFields
      : [];
    let kitTypeFields =
      this.props?.assetEditorData?.additionalFields?.kitTypeAdditionalFields || [];
    let kitTypeWithValues = [];

    if (asset.additionalFieldsValues) {
      kitTypeFields.map((field) => {
        let match = asset.additionalFieldsValues.find((x) => x.field.id === field.id);
        if (match) {
          kitTypeWithValues.push(match.field);
        }
      });
    }

    additionalFields = Array.isArray(additionalFields)
      ? additionalFields.concat(kitTypeWithValues)
      : [];
    if (additionalFields && additionalFields.length > 0) {
      index0.push({ type: 'hr', body: <hr /> });
      index1.push(
        { label: '', body: null },
        { label: '', body: null },
        { label: '', body: null },
        { label: '', body: null },
        { label: '', body: null },
        { type: 'hr', body: <hr /> }
      );
      index2.push(
        { label: '', body: null },
        { label: '', body: null },
        { type: 'hr', body: <hr /> }
      );
    }

    AssetDetailsHelper.addAdditionalFields(
      result,
      asset,
      this.handleAdditionalFieldSubmit,
      additionalFields
    );
    return result;
  }

  render() {
    let { asset } = this.props;
    if (!asset || !asset.id) {
      return null;
    }

    let columnsData = this.getAssetDetails(asset, this.labels);

    let columns = columnsData.map((column, index) => {
      return <DetailsColumn key={'kit' + index} rows={column} />;
    });

    return <div className="kit-details asset-details-section ">{columns}</div>;
  }
}

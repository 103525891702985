import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import { Prompt } from 'react-router-dom';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import {Tab} from 'react-bootstrap';
import TabTitle from 'infrastructure/js/components/Tabs/tabTitle';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import RollTabForm from '../RollTabForm/rollTabForm';
import SpoolTabForm from '../SpoolTabForm/spoolTabForm';
import KitTabForm from '../KitTabForm/kitTabForm';
import GroupTabForm from '../GroupTabForm/groupTabForm';
import ToolTabForm from '../ToolTabForm/toolTabForm';
import TaskTabForm from '../TaskTabForm/taskTabForm';
import MisplacedMaterialForm from '../MisplacedMaterialForm/misplacedMaterialForm';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./commonAlertsView.scss');

class CommonAlertsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = this.props.labels;
    this.entityLabels = createLabelHelper('mat.entity.type.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
    this.state = {
      ready: false
    };
  }

  componentDidMount () {
   this.selectTabByAlertType();
  }

  selectTabByAlertType(){
    let alertType = this.props.alertType;
    switch (alertType) {
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
      case getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME'):
      case getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'):
        this.onTabClick('roll');
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
        this.onTabClick('kit');
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT'):
      case getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'):
        this.onTabClick('tool');
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('PASSED_SCHEDULE'):
        this.onTabClick('task');
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'):
        this.onTabClick('misplacedMaterial');
        break;
    }
  }

  onTabClick = (key) => {
    this.props.actions.onTabClick(key);
  };


  getTabTitles = () => {
    let roll = <TabTitle  title={this.entityLabels.get('roll')} className ="pl-header-icon-roll"/>;
    let spool = <TabTitle title={this.entityLabels.get('spool')} className ="pl-header-icon-spool"/>;
    let kit = <TabTitle   title={this.entityLabels.get('kit')} className ="pl-header-icon-kit"/>;
    let group = <TabTitle title={this.entityLabels.get('group')} className ="pl-header-icon-container"/>;
    let tool = <TabTitle  title={this.entityLabels.get('tool')} className ="pl-menu-active-tool"/>;
    let task = <TabTitle  title={this.entityLabels.get('task')} className ="pl-scheduled"/>;   //TODO: L replace icon

    return {
      roll,
      spool,
      kit,
      group,
      tool,
      task
    };
  };

  getLoadingOverlay() {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  }

  renderHeader() {
    let title = this.labels.get('title');
    return (
      <div className="common-alerts-view-header">
        <label className='common-alerts-view-title'>{title}</label>
      </div>
    );
  }


  renderRollForm = (data) => {
    if(!data) {
      return null;
    }

    return (
      <RollTabForm actions={this.props.actions}
                   sData={data}
                   labels={this.labels}
                   alertType={this.props.alertType}
                   alertTypes={this.alertTypes}
      />
    );
  };

  renderSpoolForm = (data) => {
    if(!data) {
      return null;
    }

    return (
      <SpoolTabForm actions={this.props.actions}
                   sData={data}
                   labels={this.labels}
                   alertType={this.props.alertType}
                   alertTypes={this.alertTypes}
      />
    );
  };

  renderKitForm = (data) => {
    if(!data) {
      return null;
    }

    return (
      <KitTabForm actions={this.props.actions}
                  sData={data}
                  labels={this.labels}
                  alertType={this.props.alertType}
                  alertTypes={this.alertTypes}
      />
    );
  };

  renderGroupForm = (data) => {
    if(!data) {
      return null;
    }

    return (
      <GroupTabForm actions={this.props.actions}
                  sData={data}
                  labels={this.labels}
                  alertType={this.props.alertType}
                  alertTypes={this.alertTypes}
      />
    );
  };

  renderToolForm = (data) => {
    if(!data) {
      return null;
    }

    return (
      <ToolTabForm actions={this.props.actions}
                  sData={data}
                  labels={this.labels}
                  alertType={this.props.alertType}
                  alertTypes={this.alertTypes}
                   alertMaxValue = {this.props.alertType === getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME') ? 999 : 9999 }
      />
    );
  };

  renderTaskForm = (data) => {
    if(!data) {
      return null;
    }

    return (
      <TaskTabForm actions={this.props.actions}
                  sData={data}
                  labels={this.labels}
                  alertType={this.props.alertType}
                  alertTypes={this.alertTypes}
      />
    );
  };

  renderMisplacedMaterialForm = (data) => {
    if(!data) {
      return null;
    }

    return (
      <MisplacedMaterialForm actions={this.props.actions}
                  sData={data}
                  labels={this.labels}
                  alertType={this.props.alertType}
                  alertTypes={this.alertTypes}
      />
    );
  };

  renderPrompt = () => {

    return (
      <Prompt
        when={this.props.isDirty}
        message={location =>
          this.navigationLabels.get('datanotsaved.text')
        }
      />
    );
  };

  renderRollTab = (alertType, tabTitles) => {
    return (
      <Tab key='rollTab' eventKey={'roll'} animation={false} title={tabTitles.roll}>
        {this.renderRollForm(this.props.sData.get('rollData'))}
      </Tab>
    );
  };

  renderSpoolTab = (alertType, tabTitles) => {
    return (
      <Tab key='spoolTab' eventKey={'spool'} animation={false} title={tabTitles.spool}>
        {this.renderSpoolForm(this.props.sData.get('spoolData'))}
      </Tab>
    );
  };

  renderKitTab = (alertType, tabTitles) => {
    return (
      <Tab key='kitTab' eventKey={'kit'} animation={false} title={tabTitles.kit}>
        {this.renderKitForm(this.props.sData.get('kitData'))}
      </Tab>
    );
  };

  renderGroupTab = (alertType, tabTitles) => {
    return (
      <Tab key='groupTab' eventKey={'group'} animation={false} title={tabTitles.group}>
        {this.renderGroupForm(this.props.sData.get('containerData'))}
      </Tab>
    );
  };

  renderToolTab = (alertType, tabTitles) => {
    return (
      <Tab key='toolTab' eventKey={'tool'} animation={false} title={tabTitles.tool}>
        {this.renderToolForm(this.props.sData.get('toolData'))}
      </Tab>
    );
  };

  renderTaskTab = (alertType, tabTitles) => {
    return (
      <Tab key='taskTab' eventKey={'task'} animation={false} title={tabTitles.task}>
        {this.renderTaskForm(this.props.sData.get('taskData'))}
      </Tab>
    );
  };

  renderMisplacedMaterialTab = (alertType, tabTitles) => {
    return (
      <Tab key='misplacedMaterialTab' eventKey={'misplacedMaterial'} animation={false}>
        {this.renderMisplacedMaterialForm(this.props.sData.get('misplacedMaterialData'))}
      </Tab>
    );
  };

  renderTabs = (alertType, tabTitles) => {
    switch (alertType) {
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
        const tabsArr = [
          this.renderRollTab(alertType, tabTitles),
          this.renderSpoolTab(alertType, tabTitles),
          this.renderKitTab(alertType, tabTitles),
        ];

        if(PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled){
          tabsArr.push(this.renderGroupTab(alertType, tabTitles))
        }
        return tabsArr;
      case getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'):
        return [this.renderRollTab(alertType, tabTitles), this.renderSpoolTab(alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
        return [this.renderKitTab(alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT'):
      case getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'):
        return [this.renderToolTab(alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME'):
        return [this.renderRollTab(alertType, tabTitles), this.renderSpoolTab(alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('PASSED_SCHEDULE'):
        return [this.renderTaskTab(alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'):
        return [this.renderMisplacedMaterialTab(alertType, tabTitles)];
      default:
        console.error('alerts: tab - alert type not exist '  +  alertType);
        return null;
    }
  };

  render() {
    let selectedTab = this.props.sData.get('selectedContentTab');
    let alertType = this.props.alertType;
    if(!selectedTab){
      this.selectTabByAlertType();
    }

    let tabTitles = this.getTabTitles();

    return (

      <div className='common-alerts-view-container'>
          {this.renderPrompt()}
          {this.getLoadingOverlay()}
          {this.renderHeader()}

          <div className="top-common-alerts-view-tabs-section">
            <Tabs activeKey={selectedTab} tabType="content" onSelect={this.onTabClick} id="content-tabs">
              {this.renderTabs(alertType, tabTitles)}
            </Tabs>
          </div>
        </div>
    );
  }
}

export default reduxForm({
    form: 'commonAlertsView'
  }
)(CommonAlertsView);

CommonAlertsView.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  isDirty : PropTypes.bool.isRequired
};





import { api as locationPageApi } from '../locationPageActions.js';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import * as groupsService from '../../../services/Groups/groupsService';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';


const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_ASSET_GROUPS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query, abortController) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let locationType = locationPageApi.getLocationType(dispatch, getState)();
     if (locationType === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA') ) {
      return groupsService.getGroupsDataByStationId(locationId, query, abortController, true);
    }
    return groupsService.getGroupsDataByStationId(locationId, query, abortController);
  }
}

function _convertToRowData(rows, serverTimeSeconds){
  let items = rows.map((obj, index) => {
    if(obj.expirationDate) {
      obj.m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds);
    }
    obj.dueDateLeft = DateTimeHelper.GetDifferenceInDays(obj.workOrder && obj.workOrder.dueDate ? obj.workOrder.dueDate : 0, serverTimeSeconds);
    return obj;
  });

  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;




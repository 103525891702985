import React from 'react';


require('./statusCell.scss');

export default class StatusCell extends React.PureComponent {

  getIcon(value){
    if (!value || !value.status) {
      return null;
    }
    let icon = '';
    switch (value.status) {
      case 'succeeded':
        icon = 'pl-check-circle-icon-blue';
        break;
      case 'failed':
        icon = 'pl-error-icon';
        break;
      case 'warning':
        icon = 'pl-warning-icon';
        break;
      case 'canceled':
        icon = 'pl-operation-canceled';
        break;
      default:
        console.error('StatusCell.getHeaderIcon(): unknown status type '+ value.status);
    }
    return <span className={`icon pl ${icon}`}/>;
  };

  getDescription = (value) => {
    if (!value || !value.description) {
      return null;
    }
    return <span className="description">{value.description}</span>;
  };

  render() {
    let {value} = this.props;

    return (<div className="status-cell">
      {this.getIcon(value)}
      {this.getDescription(value)}
    </div>);
  }
}










import { fetchRolls } from '../../../../services/Rolls/rollsService';
import { fetchWorkOrders } from '../../../../services/WorkOrders/workOrdersService';
import { fetchActiveKits, reportCutsAndKits } from '../../../../services/Kits/kitsService.js';
import { fetchAllKitTypes } from '../../../../services/KitTypes/kitTypesService';
import { api as locationPageApi } from '../../locationPageActions.js';
import { api as messageDialogApi } from '../../../MessageDialog/messageDialogActions.js';
import Network from 'infrastructure/js/modules/network';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import * as daHelper from '../../../Dialogs/dialogActionsHelper';
import { dialogsNames } from '../../../../enums/dialogsNames';
import {api as printRfidTagsApi} from '../../../PrintRfidActions/printRfidActions';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CUT_AND_KIT_DIALOG),
  CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_STARTED: 'CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_STARTED',
  CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_FINISHED: 'CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_FINISHED',
  CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_FAILED: 'CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_FAILED',
  CUT_AND_KIT_DIALOG_SUBMIT_ROLLS_TO_CUT: 'CUT_AND_KIT_DIALOG_SUBMIT_ROLLS_TO_CUT',
  CUT_AND_KIT_DIALOG_SUBMIT_WIZARD_CONFIRMATION_SHOW: 'CUT_AND_KIT_DIALOG_SUBMIT_WIZARD_CONFIRMATION_SHOW',
  CUT_AND_KIT_DIALOG_SUBMIT_WIZARD_CONFIRMATION_HIDE: 'CUT_AND_KIT_DIALOG_SUBMIT_WIZARD_CONFIRMATION_HIDE',
};

const actions = {
  ...daHelper.getActions(dialogsNames.CUT_AND_KIT_DIALOG),
  fetchDialogDataStarted() {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_STARTED };
  },
  fetchDialogDataFinished(payload) {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_FINISHED, payload };
  },
  fetchDialogDataFailed() {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_FETCH_DIALOG_DATA_FAILED };
  },
  submitRollsToCut(payload) {
    return { type: actionTypes.CUT_AND_KIT_DIALOG_SUBMIT_ROLLS_TO_CUT, payload } ;
  },
  showSubmitWizardConfirmationDialog(payload){
    return { type: actionTypes.CUT_AND_KIT_DIALOG_SUBMIT_WIZARD_CONFIRMATION_SHOW, payload } ;
  },
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(data = null) {
      dispatch(actions.show(data));
    }
  },

  fetchDialogData(dispatch, getState) {
    return function () {
      dispatch(actions.fetchDialogDataStarted());
      let data = {
        pageSize: 10000000,
        active: true
      };
      return Promise.all([fetchRolls(data), fetchWorkOrders(), fetchActiveKits(data), fetchAllKitTypes()]).then((responseList) => {
        for (let i in responseList) {
          if (!Network.isResponseValid(responseList[i])) {
            console.error('Fetch Cut & Kit data failed');
            dispatch(actions.fetchDialogDataFailed());
            messageDialogApi.responseError(dispatch, getState)(responseList[i]);
            return { success: false };
          }
        }
        let [rollsResponse, wosResponse, kitsResponse, kitTypesResponse] = responseList;
        let currentLocation = locationPageApi.getLocationId(dispatch, getState)();
        let objToOption = (obj) => { return { label: obj.businessId, value: obj.id, data: obj } };

        let rolls = rollsResponse.dataList.map(objToOption);
        let rollsInLocation = rollsResponse.dataList.filter((roll) => { return currentLocation === roll.locationId; }).map(objToOption);
        let allWOs = wosResponse.dataList.filter((wo) => wo.workOrderStatus === 'ACTIVE').map(objToOption);
        let kits = kitsResponse.dataList.map(objToOption);
        let kitTypes = kitTypesResponse.dataList.map(objToOption);

        dispatch(actions.fetchDialogDataFinished({ rolls, rollsInLocation, allWOs, kits, kitTypes }));
        return { success: true };
      });
    };
  },

  submitRollsToCut(dispatch, getState) {
    return (dialogData, change) => {
      //register changes in rolls and
    }
  },

  submit(dispatch, getState){
    return function(data) {

      dispatch(actions.setBusy(true));
      const stationId = locationPageApi.getLocationId(dispatch, getState)();
      reportCutsAndKits(stationId, data).then(response => {

        if (!Network.isResponseValid(response)) {
          console.error('Report Cut And Kit Failed');
          messageDialogApi.responseError(dispatch, getState)(response);
          dispatch(actions.setBusy(false));
          return {success: false};
        }

        let validations = dialogHelper.getResponseValidationDetails(response);
        if (validations){
          dispatch(actions.setValidationWarningsData(validations));
          dispatch(actions.setBusy(false));
          return {success: false};
        }

        // Refresh everything.
        locationPageApi.reload(dispatch, getState)().then((promiseObjects) => {
          dispatch(actions.showSubmitWizardConfirmationDialog({...response.data, show: true}))
          dispatch(actions.setBusy(false));
        });

      })

    }
  },

  onHideSubmitWizardConfirmationDialog(dispatch, getState){
    return function (){
      dispatch(actions.showSubmitWizardConfirmationDialog(false))
    }
  },

  openCancelWizardConfirmationDialog(dispatch, getState) {
    return function(messageDialogBuilder) {

      let messageDialogDescriptor = messageDialogBuilder(
        messageDialogApi.close(dispatch, getState)
      );

      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    }
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  fetchDialogData() {
    return (dispatch, getState) => api.fetchDialogData(dispatch, getState)();
  },

  //params:
  //dialogData received in handleSubmit
  //redux-form change prop
  submitRollsToCut(dialogData, change) {
    return (dispatch, getState) => api.submitRollsToCut(dispatch, getState)(dialogData, change);
  },
    onCancelCutAndKitWizardClick(messageDialogBuilder) {
    return function(dispatch, getState) {
      api.openCancelWizardConfirmationDialog(dispatch, getState)(messageDialogBuilder);
    }
  },

  onHideSubmitWizardConfirmation(){
    return (dispatch, getState) => api.onHideSubmitWizardConfirmationDialog(dispatch, getState)();
  },

  onPrintRfidTagsClick(kitIds) {
    return (dispatch, getState) => {
      const locationId = locationPageApi.getLocationId(dispatch, getState)();
      printRfidTagsApi.printRfidTags(dispatch, getState)(kitIds, locationId, true);
    }
  }
};

import Network from 'infrastructure/js/modules/network';

export function printToolLabel(data) {
  return Network.post('download/label/tool', data, {blobResponse : true});
}

export function fetchActiveTools(query = {}) {
  return Network.post('tools/items?active=true', query);
}

export function fetchActiveToolsSchedule(query = {}) {
  return Network.post('tools/planner-operations/items', query);
}

export function getToolsDataByStationId(stationID, query = {}){
  return Network.post(`stations/${stationID}/tools/items?active=true`, query);
}

export function fetchArchivedTools(query = {}) {
  return Network.post('tools/items?active=false', query);
}

export function updateTool(toolId, data) {
  return Network.put(`tools/${toolId}`, data);
}

export function getToolDetails(assetId){
  return Network.get(`tools/${assetId}`);
}

export function editToolAttributes(data, callback){
  return Network.polling.put('tools/update' , data, callback);
}

export function reportMaintenance(data, callback) {
  return Network.polling.post('tools/multiReportMaintenance' , data, {callback});
}

export function createTool(data) {
  return Network.post('tools/', data);
}
export function generateToolId() {
  return Network.get('tools/generateId');
}

export function getToolByBusinessId(businessId){
  return Network.get(`tools/search/${businessId}`, {}, {ignore404NotFound : true});
}

// Tool Types
export function fetchToolTypes() {
  return Network.get('toolTypes');
}

export function fetchToolTypesMasterData(query) {
  return Network.post('toolTypes/items', query);
}

export function createToolType(data) {
  return Network.post('toolTypes', data);
}

export function updateToolType(data) {
  return Network.post('toolTypes/update', {toolTypes:[data]});
}

export function activateToolTypes(data) {
  return Network.post('toolTypes/activate', data);
}

export function deactivateToolTypes(data) {
  return Network.post('toolTypes/deactivate', data);
}

export function removeToolTypes(ids) {
  return Network.delete('toolTypes/', {toolTypeIds: ids.ids});
}

export function getToolsByToolType(toolTypeId) {
  return Network.get(`tools/type/${toolTypeId}`);
}

export function getToolsByKitType( data ) {
  return Network.post(`tools/kittype/${data?.kitTypeId}/task/${data?.taskAssignmentId}`, data);
}

export function importToolTypesJson(file) {
  return Network.postFile('batch/import/tooltypes/jsonplain/upload',file);
}

// Tool Categories
export function fetchToolCategories(query) {
  return Network.post('tool-categories/items', query);
}

export function createToolCategory(data) {
  return Network.post('tool-categories', data);
}

export function editToolCategory(data) {
  let id = data.id;
  delete data.id;
  return Network.put(`tool-categories/${id}`, data);
}

export function activateToolCategories(data) {
  return Network.post('tool-categories/activate', data);
}

export function deactivateToolCategories(data) {
  return Network.post('tool-categories/deactivate', data);
}

export function removeToolCategories(ids) {
  return Network.delete('tool-categories', {ids: ids.ids});
}

export function getActiveToolCategories() {
  return Network.get('tool-categories?isActive=true');
}



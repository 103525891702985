import React, { Component } from 'react';
import {reduxForm} from 'redux-form';
import {BarChart, CartesianGrid, XAxis,YAxis, Tooltip, Bar, Legend} from 'recharts';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Label from 'infrastructure/js/components/Label/label.js';
import {enumTypes, getLookupOptions, getEnumValue, getEnumLabel} from '../../../../../utils/enumHelper';

require('./usageTimeChart.scss');

export class UsageTimeChart extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.dashboard.inventory.usageManagement.timeFromReceivingToConsumption.');

    this.materialUsagePeriodOptions = getLookupOptions(enumTypes.INVENTORY_DASHBOARD_TIME_PERIOD, undefined, 1);
  }

  componentDidMount() {
    let initialValues = {
      period: {
        value: getEnumValue(enumTypes.INVENTORY_DASHBOARD_TIME_PERIOD)('IN_WEEKS'),
        label: getEnumLabel(enumTypes.INVENTORY_DASHBOARD_TIME_PERIOD)('IN_WEEKS')
      }
    };
    this.props.initialize(initialValues);
  }

  getChartData = () => {
    if (!this.props.chartData) {
      return [];
    }

    return this.props.chartData.subPeriodResponseModel.map((item) => {
      let periodName = createLabelHelper('').get(item.subPeriodLabelKey);

      return {
        rollsLabel: this.labels.get('rolls'),
        periodLabel: this.labels.get('period'),
        period: periodName,
        shortPeriod: periodName.length <= 15 ? periodName : (periodName.slice(0, 15) + '...'),
        rolls: item.totalRollsInPeriod
      }
    });
  };

  customTooltipOnYourLine(e) {
    if (e.active && e.payload != null && e.payload[0] != null) {
      return (<div className="custom-tooltip">
        <p>{`${e.payload[0].payload.periodLabel} ${e.payload[0].payload.period}`}</p>
        <p>{`${e.payload[0].payload.rollsLabel} ${e.payload[0].payload.rolls}`}</p>
      </div>);
    }
    else {
      return "";
    }
  }

  renderChart = () => {
    if(!this.props.chartData.subPeriodResponseModel || !this.props.chartData.subPeriodResponseModel.length){
      return  <div className={'empty-chart-section'}><span className={'no-results'}>{this.labels.get('noResults')}</span></div>;
    }
    let chartData = this.getChartData();

    return (
      <div className={'chart-section'}>
        <BarChart width={617} height={400} data={chartData} key={'333'} >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="shortPeriod" angle={-45}  textAnchor="end" interval={0} height={150}/>
          <YAxis />
          <Tooltip content={this.customTooltipOnYourLine}/>
         {/* <Legend  payload={[{ value:this.labels.get('legend'), type: 'square',  color:"#5DADE2", id: 'ID01' }]}/>*/}
          <Legend/>
          <Bar name={this.labels.get('rollsLegendLabel')} dataKey={'rolls'} fill="#5DADE2"  key={'1'}/>
        </BarChart>
      </div>
    );
  };

  onPeriodChange = (period) => {
    if (this.props.onChangeAction) {
      this.props.onChangeAction((period && period.value) ? period.value : null);
    }
  }

  render() {
    if(!this.props.chartData){
      return null;
    }

    return (
      <div className="consumption-chart">
        <div className="chart-header">
          <Label className='chart-title' text={this.labels.get('title')} />
          <Combobox className="period-select"
                    id="period"
                    name="period"
                    options={this.materialUsagePeriodOptions}
                    validate={Validation.required}
                    onChangeCallback={this.onPeriodChange}
          />
        </div>

        {this.renderChart()}
      </div>
    )
  }
};

export default reduxForm({
    form: 'UsageTimeChart'
  }
)(UsageTimeChart);

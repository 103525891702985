import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import CheckMarkCell from '../../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';

export default class AutomationScenariosViewGrid extends React.PureComponent{

  filterConfig = [];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.automationScenatios.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.name.title'),
        filterType: filterTypes.NONE,
        width: 200,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'antennas',
        title: this.labels.get('columns.antennas.title'),
        filterType: filterTypes.NONE,
        width: 200,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: this.getAntennas(params),
              componentType: LabelWithTooltipCell
            };
          }
        }
      },
      {
        fieldName: 'active',
        title: this.labels.get('columns.active.title'),
        filterType: filterTypes.NONE,
        width: 100,
        columnOptions: {
          sortable: false,
          cellComponent: CheckMarkCell,
        }
      },
      {
        fieldName: 'qualifiedEvent.name',
        title: this.labels.get('columns.qualifiedEvent.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'actionSequence.name',
        title: this.labels.get('columns.actionSequence.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
    ]
  };

  getAntennas = (params) => {
    if (params && params.data && params.data.antennas) {
      return params.data.antennas.map(item => item.name);
    }
    return [];
  }

  getRowHeight(params) {
    let rowHeight =  64;
    let cellRowsNumber = params.data.antennas ? params.data.antennas.length : 0;
    if (cellRowsNumber > 1) {
      rowHeight = cellRowsNumber * 30 + 40;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_RFID_SETTINGS_AUTOMATION_SCENARIOS}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}


import React, { useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { enumTypes, getEnumValue } from '../../../../utils/enumHelper';

import './tickingTimeCell.scss';

const TickingTimeCell = ({ value, data, updateRowData }) => {
    const { loading, hours = 0, minutes = 0, isNegativeDuration } = value;

    useEffect(() => {
        let intervalID;
        if (data.operationStatus === getEnumValue(enumTypes.TASK_STATUS)('STARTED')) {
            const initialUpdateTimeStamp = moment();

            intervalID = setInterval(() => {
                const diff = moment().diff(
                    data?.updateTimeStamp || initialUpdateTimeStamp,
                    'seconds'
                );

                if (diff >= 60) {
                    const newTimeLeft = data?.timeLeft - 1;
                    updateRowData({
                        ...data,
                        timeLeft: newTimeLeft,
                        executionProgressPercents: calculateProgressPercent(
                            newTimeLeft,
                            data?.duration
                        ),
                        updateTimeStamp: moment(),
                    });
                }
            }, 1000);
        }

        return () => {
            intervalID && clearInterval(intervalID);
        };
    }, []);

    const calculateProgressPercent = (timeLeft, duration) => {
        if (timeLeft > duration) {
            return 0;
        }

        if (duration > 0) {
            const newProgress = Math.round(100 - (timeLeft * 100) / duration);
            return newProgress < 100 ? newProgress : 99;
        }
        return 99;
    };

    const formatTime = (timeValue) => {
        return timeValue.toString().length === 1 ? '0' + timeValue : timeValue;
    };

    const formattedHours = formatTime(hours);
    const formattedMinutes = formatTime(minutes);

    return (
        <div className={cn('ticking-time-cell', isNegativeDuration ? 'negative-time-left' : '')}>
            {isNegativeDuration && '-'}
            {formattedHours}
            <span className='blinker'>:</span>
            {formattedMinutes}
            {loading && <i className='fa fa-spinner fa-spin fa-fw' aria-hidden='true' />}
        </div>
    );
};

export default TickingTimeCell;

import PropTypes from 'prop-types';
import Tree from 'infrastructure/js/components/Tree/tree';
import './digitalTwinViewTree.scss';

const DigitalTwinViewTree = ({ treeData, onSelect, onDrop, selectedItem, defaultExpandAll, itemActions}) => {

  const getItemActionsRenderer = (item) => {
    return item?.isSection ?
      <>
        <span className={'pl pl-section-sm'}
              onClick={() => itemActions?.createItem?.({parentId: item.id, isSection: true, isStation: false})} />
        <span className={'pl pl-station-sm'}
              onClick={() => itemActions?.createItem?.({parentId: item.id, isSection: false, isStation: true})} />
        <span className={'pl pl-location-sm'}
              onClick={() => itemActions?.createItem?.({parentId: item.id, isSection: false, isStation: false})} />
      </> : null;
  }

  const getItemIconsRenderer = (item) => {
    if (item.isSection) {
      return item.expanded ? <div className='pl pl-arrow-down branch-icon'/> : <div className='pl pl-arrow-right branch-icon'/>;
    }
    return item.isStation ? <div className='pl pl-station-sm leaf-icon'/> : <div className='pl pl-location-sm leaf-icon'/>
  }

  return (
      <Tree  treeData={treeData}
             onSelect={onSelect}
             onDrop={onDrop}
             selectedItem={selectedItem}
             defaultExpandAll={defaultExpandAll}
             itemIconsRenderer={getItemIconsRenderer}
             itemActionsRenderer={getItemActionsRenderer}
      />
  );
}

DigitalTwinViewTree.propTypes = {
  treeData: PropTypes.array,
  onSelect: PropTypes.func,
  onDrop: PropTypes.func,
  selectedItem: PropTypes.object,
  defaultExpandAll: PropTypes.bool,
  itemActions: PropTypes.object,
}

DigitalTwinViewTree.defaultProps = {
  treeData: [],
  onSelect: () => {},
  onDrop: () => {},
  selectedItem: undefined,
  defaultExpandAll: true,
  itemActions: null,
}

export default DigitalTwinViewTree;

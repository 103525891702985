import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { gridsNames} from '../../../enums/gridsNames';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { jsxActions as qualifiedEventsViewGridActions} from '../../../actions/Administration/RfidSettingsPage/qualifiedEventsViewGridActions';
import QualifiedEventsView from '../../../components/Administration/RfidSettingsPage/AutomatedWorkflowsView/QualifiedEventsView/qualifiedEventsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      qualifiedEventsViewGridActions: bindActionCreators(qualifiedEventsViewGridActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let QualifiedEventsViewContainer = connect(
  (state) => {
    let gridData = state.grid.get(gridsNames.ADMINISTRATION_RFID_SETTINGS_QUALIFIED_EVENTS);
    let grid = gridData ? gridData : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sData: state.administration.getIn(['rfidSettingsPage', 'qualifiedEventsViewData']),
    };
  },

  mapDispatchToProps
)(QualifiedEventsView);

export default QualifiedEventsViewContainer;



import * as workOrdersService from '../../../services/WorkOrders/workOrdersService';
import * as daHelper from '../../Dialogs/dialogActionsHelper';
import {dialogsNames} from "../../../enums/dialogsNames";

const config = {
  getSubmitMethod: () => workOrdersService.updateWorkOrderStatus,
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.ARCHIVE_WO_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.ARCHIVE_WO_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
// export const actionTypes = {
//   ARCHIVE_WORKORDER_DIALOG_SHOW: 'ARCHIVE_WORKORDER_DIALOG_SHOW',
//   ARCHIVE_WORKORDER_DIALOG_HIDE: 'ARCHIVE_WORKORDER_DIALOG_HIDE',
//   ARCHIVE_WORKORDER_DIALOG_SUBMIT_IN_PROGRESS: 'ARCHIVE_WORKORDER_DIALOG_SUBMIT_IN_PROGRESS',
//   ARCHIVE_WORKORDER_DIALOG_SUBMIT_FINISHED: 'ARCHIVE_WORKORDER_DIALOG_SUBMIT_FINISHED',
// };


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
// const actions = {
//   show: function(payload) {
//     return {type: actionTypes.ARCHIVE_WORKORDER_DIALOG_SHOW, payload: payload };
//   },
//   hide: function(payload) {
//     return {type: actionTypes.ARCHIVE_WORKORDER_DIALOG_HIDE, payload: payload };
//   },
//   updateWorkOrderStatusInProgress: function(payload) {
//     return {type: actionTypes.ARCHIVE_WORKORDER_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
//   },
//   updateWorkOrderStatusFinished: function(payload) {
//     return {type: actionTypes.ARCHIVE_WORKORDER_DIALOG_SUBMIT_FINISHED, payload: payload };
//   },
// };


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
// export let jsxActions = {};
//
// jsxActions.onCancel = function() {
//   return function(dispatch, getState) {
//     api.closeDialog(dispatch, getState)();
//   };
// };
//
//
// jsxActions.updateWorkOrderStatus = function(data, workOrderId, messageDialogBuilder) {
//   return function(dispatch, getState) {
//     return api.updateWorkOrderStatus(dispatch, getState)(data, workOrderId, messageDialogBuilder);
//   };
// };

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
// export let api = {};

// api.closeDialog = function(dispatch, getState) {
//   return function() {
//     dispatch(actions.hide());
//   }
// };
//
// api.show = function(dispatch, getState) {
//   return function() {
//     dispatch(actions.show());
//   }
// };

// api.updateWorkOrderStatus = function(dispatch, getState) {
//   return function(data, workOrderId) {
//     dispatch(actions.updateWorkOrderStatusInProgress());
//
//     workOrdersService.updateWorkOrderStatus(data, workOrderId)
//       .then((response) => {
//         dispatch(actions.updateWorkOrderStatusFinished());
//         dispatch(actions.hide());
//
//         if (!Network.isResponseValid(response)) {
//           console.error('WorkOrder status update failed');
//           messageDialogApi.responseError(dispatch, getState)(response);
//           return {success: false};
//         }
//
//         workOrderPageApi.reload(dispatch, getState)(true, workOrderId);
//
//       });
//   };
// };


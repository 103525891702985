import { useEffect, useMemo } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { SettingsMenuItemsIds } from '../../../../../enums/navigationMenuItemsIds';
import { enumTypes, getEnumValue } from '../../../../../utils/enumHelper';
import CommonPdfLabelSettingsView from '../Components/CommonPdfLabelSettingsView/commonPdfLabelSettingsView';
import './groupPdfLabelSettingsView.scss';

export default function GroupPdfLabelSettingsView({ actions, sData, isDirty }) {
  const labels = useMemo(() => createLabelHelper('mat.administration.matsettings.expirationdate.view.'), []);

  useEffect(() => {
    actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_PDF_LABEL_GROUP);
    actions.load(getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'));
  }, []);

  return (
    <CommonPdfLabelSettingsView
      labels={labels}
      actions={actions}
      sData={sData}
      isDirty={isDirty}
      entityType={getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')}
    />
  );
}

import React, {Component} from 'react';

import Button from 'infrastructure/js/components/controls/Button/button';
import TextField from 'infrastructure/js/components/controls/TextField/textField';

//Style
require('./generateButton.scss');

export default class PL_Generate_Button extends React.PureComponent{

  render() {
    let {onClick, buttonText = 'Generate', ...props} = this.props;
    return (
      <div className="input-with-generate-button">
        <TextField {...props} />
        <Button id={this.props.id + '-button'}  bsStyle="default" onClick={onClick} className={props.className + " generateButton border-btn"} >{buttonText}</Button>
      </div>

    );
  }
}

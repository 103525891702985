import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import PartsGrid from './PartsGrid/partsGrid';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import AddReplaceSpoolsDialog from '../../../Dialogs/AddReplaceSpoolsDialog/addReplaceSpoolsDialog';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import EditAssetsStatusDialog from '../../../Dialogs/EditAssetsStatusDialog/editAssetsStatusDialog';
import {getLocationAssetTypes} from '../../../../utils/locationHelper';
import {gridsNames} from '../../../../enums/gridsNames';

require('./partsView.scss');

export default class PartsView extends React.PureComponent {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.assets.');
  }

  getDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IN_PROGRESS],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('PART')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getMoveAssetsDialogConfig = () => {
    let {sSelectedRowsData, sLocationDetails} = this.props;
    return {
      boEntityFilter: [
        FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.ON_LOCATION,
        FetchEntitiesFilters.NOT_IN_PROGRESS
      ],
      entitiesTypes: sSelectedRowsData?.size > 0 ? [getEnumValue(enumTypes.OBJECT_TYPE)('PART')] :
        getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('PART')),
      preselectedAssets: sSelectedRowsData,
    };
  };

  getBringAssetsDialogConfig = () => {
    let {sLocationDetails} = this.props;
    return {
      boEntityFilter: [
        FetchEntitiesFilters.ACTIVE,
        FetchEntitiesFilters.EXCEPT_LOCATION,
        FetchEntitiesFilters.NOT_IN_PROGRESS],
      entitiesTypes: getLocationAssetTypes(sLocationDetails, getEnumValue(enumTypes.OBJECT_TYPE)('PART')),
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getFiberPlacementOperationConfig = () => {
    return {
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  onRefreshAll = () => {
    this.props.actions?.reloadParentComponent();
  }

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData, sLocationDetails, loading} = this.props;
    let dialogConfig = this.getDialogConfig();
    let isLocationActive = sLocationDetails?.isActive;
    let items = {
      title: this.labels.get('title.parts'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          actionData: {gridName: gridsNames.LOCATION_ASSET_PARTS}
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.onRefreshAll,
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: loading || !isLocationActive,
        },
        {
          id:'fiberPlacement',
          className: 'no-icon',
          label: headerItemsLabels.FIBER_PLACEMENT,
          action: actions.headerActions.onFiberPlacementOperationClick,
          actionData: this.getFiberPlacementOperationConfig(sSelectedRowsData),
          disabled: loading || !isLocationActive || !this.isAfpOperationAvailable(sSelectedRowsData),
        },
        {
          id:'addReplaceSpools',
          className: 'no-icon',
          label: headerItemsLabels.ADD_REPLACE_SPOOLS,
          action: actions.headerActions.onAddReplaceSpoolsClick,
          actionData: sSelectedRowsData?.get(0),
          disabled: loading || !isLocationActive || !this.isAddReplaceOperationAvailable(sSelectedRowsData),
        },
        {
          id:'moveTo',
          label: headerItemsLabels.MOVE_TO,
          icon: 'pl pl-move-assets',
          counter: sSelectedRowsData.size,
          action: actions.headerActions.onMenuItemMoveToClick,
          actionData: this.getMoveAssetsDialogConfig(sSelectedRowsData),
          disabled: loading || !isLocationActive || !this.isMoveOperationAvailable(sSelectedRowsData),
        },
        {
          id:'bringHere',
          label: headerItemsLabels.BRING_HERE,
          icon: 'pl pl-bring-assets',
          action: actions.headerActions.onMenuItemBringHereClick,
          actionData: this.getBringAssetsDialogConfig(),
          disabled: loading || !isLocationActive,
        },
      ],

      kebab: {
        disabled: loading || !isLocationActive,
        menuItems: [
          ...(PermissionManager.hasArchiveAssetsPermissions() ? [{
            id:'archive',
            label: headerItemsLabels.ARCHIVE,
            action: actions.headerActions.onMenuItemArchiveClick,
            actionData: dialogConfig,
            disabled: !sHasRows,
          }] : []),
          {
            id:'markComplete',
            label: headerItemsLabels.MARK_COMPLETE,
            // counter: sSelectedRowsData.size,
            disabled: !sHasRows || !isLocationActive || !this.isCompleteOperationAvailable(sSelectedRowsData),
            action: actions.headerActions.onEditAssetsStatusClick,
            actionData: dialogConfig
          }
        ]
      }
    };

    return items;
  }

  isAfpOperationAvailable = (data) => {
    return (data?.size === 0) ? true : (data?.size === 1) ? (data.get(0)?.partStatus === 'IN_PROGRESS') : false;
}
  isAddReplaceOperationAvailable = (data) => {
    return (data?.size === 1) ? (data.get(0)?.partStatus === 'IN_PROGRESS') : false;
  }
  isMoveOperationAvailable = (data) => {
    return data?.every(item => item.partStatus === 'COMPLETED');
  }

  isCompleteOperationAvailable = (data) => {
    return data?.every(item => item.partStatus !== 'COMPLETED');
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="parts-view">
        <Header  {...headerItems}/>

        <PartsGrid actions={{...this.props.actions.assetsPartsGridActions, ...this.props.actions.fetchEntitiesActions}}
                   locationDetails = {this.props.sLocationDetails}
                   cellAction={this.props.actions.getAssetRelatedLayers}
        />


        <PL_DialogWrapper dialogComponent={AddReplaceSpoolsDialog}
                          show={this.props.sAddReplaceSpoolsDialogData.get('show')}
                          actions={this.props.actions.addReplaceSpoolsActions}
                          sData={this.props.sAddReplaceSpoolsDialogData}
                          reloadParentComponent={this.onRefreshAll}
        />

          <PL_DialogWrapper dialogComponent={EditAssetsStatusDialog}
                            show={this.props.sEditAssetsStatusDialogData.get('show')}
                            actions={this.props.actions.editAssetsStatusActions}
                            sData={this.props.sEditAssetsStatusDialogData}
                            reloadParentComponent={this.onRefreshAll} />



      </div>
    );
  }
}


PartsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
  sLocationDetails: PropTypes.object.isRequired,
};












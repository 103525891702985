import {IsJsonString} from 'infrastructure/js/utils/JSON.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

let loggedInUser = localStorage['loggedInUser'];
if (!(loggedInUser = IsJsonString(loggedInUser))) {
  loggedInUser = undefined;
}

const LabelFormatTypes = {
  BusinessIdFirst:'BUSINESS_ID_FIRST',
  NameFirst:'NAME_FIRST'
};
let materialHelperInstance = function () {
  let obj = {};
  //default value
  let _formatType = LabelFormatTypes.NameFirst;

  obj.init = function (materialFieldOrder){
    if(!materialFieldOrder){
    return;
    }
    _formatType = materialFieldOrder;
  }
  if (loggedInUser && loggedInUser.loggedInOrg.orgPreferences.propertyDisplayMap) {
    obj.init(loggedInUser.loggedInOrg.orgPreferences.propertyDisplayMap['materialFieldOrder']);
  }

  obj.getMaterialFullLabel = function (name , code) {
    let firstLabel = this.getMaterialFirstLabel(name, code);
    let firstParam = firstLabel ? firstLabel : '';
    let secondLabel = this.getMaterialSecondLabel(name, code);
    let secondParam = secondLabel ? (firstLabel ? `(${secondLabel})` : secondLabel) : '';
    return `${firstParam} ${secondParam}`;
  };

  obj.getMaterialFirstLabel = function (name , code) {
    return _formatType === LabelFormatTypes.NameFirst ? name: code;
  }

  obj.getMaterialSecondLabel = function (name , code) {
    return _formatType === LabelFormatTypes.NameFirst ? code: name;
  }

  obj.getMaterialAvailableLabel = function (name , code) {
    return name ? name : code;
  };

  obj.convertToMaterialItem = function(material){
    return { value: material.id, label:this.getMaterialFullLabel(material.materialName,material.businessId), data: material };
  }

  obj.relativeMaterialUsageCalculation = function (amount, ratio, kitCount = 1){
    const kitRelativeMaterialUsageCalculationType = PermissionManager.getOrgPreferences().kitRelativeMaterialUsageCalculationType.toLowerCase();
    if(kitRelativeMaterialUsageCalculationType === 'equally'){
      return amount / kitCount
    }
    if(kitRelativeMaterialUsageCalculationType === 'ratio'){
      return amount * ratio
    }
    return 0
  }

  return obj;
}();

export default materialHelperInstance;

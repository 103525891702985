import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import {jsxActions as commonAlertsViewActions} from '../../../../actions/Administration/SettingsPage/commonAlertsViewActions';
import { jsxActions as headerSettingsActions} from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions.js';
import ToolCyclesView from "../../../../components/Administration/SettingsPage/Alerts/ToolCyclesView/toolCyclesView";

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonAlertsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch)
    }
  }
}

let ToolCyclesViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonAlertsView']),
      isDirty: isDirty('ToolTabForm')(state)
    };
  },

  mapDispatchToProps
)(ToolCyclesView);

export default ToolCyclesViewContainer;




import { Map } from 'immutable';

import { default as chartsReducer } from './chartsReducer';
import { default as assetsViewReducer } from './assetsViewReducer';
import { default as createRollReducer } from './AssetsView/createRollReducer';
import { default as createSpoolReducer } from './AssetsView/createSpoolReducer';
import { default as addToGroupReducer } from './AssetsView/addToGroupReducer';
import { default as createToolReducer } from './AssetsView/createToolReducer';
import { default as workOrderViewReducer } from './WorkOrderView/workOrderViewReducer';
import { default as printRfidTagsReducer } from '../Dialogs/PrintRfidTagsDialog/printRfidTagsDialogReducer';
import { default as workOrderDialogReducer } from './WorkOrderView/workOrderDialogReducer';
import { default as tasksViewReducer } from './TasksView/tasksViewReducer';
import { actionTypes as locationPageActionTypes } from '../../actions/LocationPage/locationPageActions.js';


let defaultState = {
  data: Map({
  })
};


export default function(state = Map(defaultState), action) {

  if (action.type === locationPageActionTypes.LOCATIONPAGE_LOCATION_CHANGED){
    state = Map(defaultState);
  }

  state = state.set("chartsData",           chartsReducer(state.get('chartsData'), action));
  state = state.set("assetsViewData",       assetsViewReducer(state.get('assetsViewData'), action));
  state = state.set("createRollData",       createRollReducer(state.get('createRollData'), action));
  state = state.set("createSpoolData",      createSpoolReducer(state.get('createSpoolData'), action));
  state = state.set('addToGroupData',       addToGroupReducer(state.get('addToGroupData'), action));
  state = state.set("createToolData",       createToolReducer(state.get('createToolData'), action));
  state = state.set("workOrderViewData",    workOrderViewReducer(state.get('workOrderViewData'), action));
  state = state.set("workOrderDialogData",  workOrderDialogReducer(state.get('workOrderDialogData'), action));
  state = state.set("tasksViewData",  tasksViewReducer(state.get('tasksViewData'), action));


  switch(action.type) {

    case locationPageActionTypes.LOCATIONPAGE_LOCATION_DETAILS_FETCH_READY:
      return state.setIn(["data", "locationDetails"], action.payload);

    case locationPageActionTypes.LOCATIONPAGE_LOCATION_SET_LOCATION_ID:
      return state.setIn(["data", "locationDetails"], {id:action.payload});

    default:
      return state;

  }

}










import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../controls/Button/button';


//Style
require('./filterFooter.scss');

export default class PL_FilterFooter extends React.PureComponent {

  render() {
    let {onCancel, cancelText = "Cancel", selectedCount, showSelectedCount, onOk, okText = "Ok", okDisabled, statusText = "Selected"} = this.props;
    return (
      <div className="filter-footer">
        <Button id='cancelButton' className="cancelButton" bsStyle="default" onClick={onCancel}>
          {cancelText}
        </Button>
        <label className="selectedCount" style={{display: showSelectedCount ? 'inline-block' : 'none'}}>
          {selectedCount} {statusText}
        </label>
        <Button id='okButton' className="filterButton" bsStyle="primary" onClick={onOk} disabled={okDisabled}>
          {okText}
        </Button>
      </div>);
  }
}

PL_FilterFooter.propTypes = {
  showSelectedCount: PropTypes.bool,
  selectedCount: PropTypes.number,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  statusText: PropTypes.string,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  okDisabled: PropTypes.bool
};



export const filterTypes = {
  NONE: 'NONE',
  MULTI_SELECT: 'MULTI_SELECT',
  MULTI_SELECT_ASYNC: 'MULTI_SELECT_ASYNC',
  MULTI_PROPERTIES: 'MULTI_PROPERTIES',
  MULTI_SECTIONS: 'MULTI_SECTIONS',
  SEARCH: 'SEARCH',
  DATE_SELECT: 'DATE_SELECT',
  HORIZON: 'HORIZON',
  // COMBINED: 'COMBINED',             //not in use
  //NUMBER_COMPARE: 'NUMBER_COMPARE',  //not in use
  // VALUE_COMPARE: 'VALUE_COMPARE',   //not in use
};


import { Map } from 'immutable';
import { actionTypes } from '../../../actions/Administration/MatSettingsPage/shiftTypesViewActions';

let defaultState = {
  loading: false
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case actionTypes.WORK_HOURS_SETTINGS_LOADING:
      return state.set('loading', action.payload);

    default:
      return state
  }

}








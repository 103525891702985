import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import AlertedRollsGrid from './AlertedRollsGrid/alertedRollsGrid';
import {gridsNames} from '../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {PL_DialogWrapper}     from 'infrastructure/js/components/Dialog/dialog';
import ShelfLifeUpdateDialog  from '../../Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialog';
import ArchiveAssetsDialog    from '../../Dialogs/ArchiveAssetsDialog/archiveAssetsDialog';
import MoveAssetsDialog       from '../../Dialogs/MoveAssetsDialog/moveAssetsDialog';
import EditAssetAttributesDialog       from '../../Dialogs/EditAssetAttributesDialog/editAssetAttributesDialog';
import PrintPdfLabelsDialog       from '../../Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialog';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import ImportAttributesUpdateWizard from '../../Wizards/ImportAttributesUpdate/importAttributesUpdateWizard';

require('./alertedRollsPage.scss');

export default class AlertedRollsPage extends Component {

  constructor(props) {
    super(props);

  }


  getDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ALERTED,
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ALERTED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getMoveAssetsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ALERTED, FetchEntitiesFilters.NOT_CONTAINED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getPrintPdfLabelsDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ALL,
      boEntityFilter: [FetchEntitiesFilters.ALL],
      preselectedAssets: this.props.sSelectedRowsData,
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'), getEnumValue(enumTypes.OBJECT_TYPE)('KIT')]
    };
  };

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData} = this.props;
    let dialogConfig = this.getDialogConfig();

    let items = {
      buttons: [
        // {        //TODO: L Filter - currently no supported for the Alerted grids
        //   id: 'clearFilters',
        //   label: '',
        //   icon: 'pl pl-filter-clear',
        //   action: actions.clearFilters,
        //   tooltip: headerItemsLabels.CLEAR_FILTERS,
        // },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.export,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_ALERTED_ROLLS}
        }
      ],

      kebab: {
        menuItems: [
          ...(PermissionManager.hasArchiveAssetsPermissions() ? [ {
            id:'archive',
            label: headerItemsLabels.ARCHIVE,
            action: actions.header.onMenuItemArchiveClick,
            actionData: dialogConfig,
            disabled: !sHasRows,
          }] : []),
          {
            id:'moveTo',
            label: headerItemsLabels.MOVE_TO,
            action: actions.header.onMenuItemMoveToClick,
            actionData: this.getMoveAssetsDialogConfig(),
            disabled: !sHasRows,
          },
          {
            id:'printLabel',
            label: headerItemsLabels.PRINT_LABEL,
            action: actions.header.onMenuItemPrintLabelClick,
            actionData:  this.getPrintPdfLabelsDialogConfig(),
            disabled: !sHasRows ,
          },
          ...(PermissionManager.hasEditAttributesPermissions() ? [ {
            id:'editAttributes',
            label: headerItemsLabels.EDIT_ATTRIBUTES,
            action: actions.header.onMenuItemEditAssetAttributesClick,
            actionData: dialogConfig,
            disabled: !sHasRows,
          }] : []),
          ...(PermissionManager.hasEditAttributesPermissions() ? [{
            id:'importAttributesUpdate',
            label: headerItemsLabels.IMPORT_ATTRIBUTES_UPDATE,
            action: actions.header.onMenuItemImportAttributesUpdateClick,
            actionData: {importType: getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_ROLL')}
          }] : []),
        ]
      }
    };

    if (PermissionManager.hasUpdateShelfLifePermissions()) {
      items.buttons.splice(1, 0, {
        id:'updateShelfLife',
        label: headerItemsLabels.UPDATE_SELF_LIFE,
        icon: 'pl pl-hourglass-icon',
        counter: sSelectedRowsData.size,
        disabled: !sHasRows,
        action: actions.header.onUpdateShelfLifeClick,
        actionData: dialogConfig
      })
    }

    return items;

  }

  reloadPage = () => {
    this.props.actions.reload();
  };


  renderShelfLifeUpdateDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={ShelfLifeUpdateDialog}
                        show={this.props.sShelfLifeUpdateData.get('show')}
                        actions={this.props.actions.shelfLifeUpdateActions}
                        sData={this.props.sShelfLifeUpdateData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderArchiveAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={ArchiveAssetsDialog}
                        show={this.props.sArchiveAssetsData.get('show')}
                        sData={this.props.sArchiveAssetsData}
                        actions={this.props.actions.archiveAssetsActions}
                        reloadParentComponent={this.reloadPage} />
    );
  };

  renderMoveAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={MoveAssetsDialog}
                        show={this.props.sMoveAssetsData.get('show')}
                        actions={this.props.actions.moveAssetsActions}
                        sData={this.props.sMoveAssetsData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderEditAssetAttributesDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={EditAssetAttributesDialog}
                        show={this.props.sEditAssetAttributesData.get('show')}
                        actions={this.props.actions.editAssetAttributesActions}
                        sData={this.props.sEditAssetAttributesData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderPrintPdfLabelsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={PrintPdfLabelsDialog}
                        show={this.props.sPrintPdfLabelsData.get('show')}
                        actions={this.props.actions.printPdfLabelsActions}
                        sData={this.props.sPrintPdfLabelsData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderImportAttributesUpdateWizardDialog = () => {
    let sData = this.props.sImportAttributesUpdateWizardData;

    if (sData && sData.get('show')) {
      return (
        <ImportAttributesUpdateWizard
          importType={getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_ROLL')}
          actions={this.props.actions.importAttributesUpdateWizardActions}
          sData={sData}
          reloadParentComponent={this.reloadPage}/>
      );
    }
    return null;
  };

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="alerted-rolls-page">
        <Header  {...headerItems}/>
        <AlertedRollsGrid actions={{...this.props.actions.alertedRollsGridActions, ...this.props.actions.fetchEntitiesActions}}/>

        { this.renderShelfLifeUpdateDialog() }
        { this.renderArchiveAssetsDialog() }
        { this.renderMoveAssetsDialog() }
        { this.renderEditAssetAttributesDialog() }
        { this.renderPrintPdfLabelsDialog() }
        { this.renderImportAttributesUpdateWizardDialog() }

      </div>
    );
  }
};


AlertedRollsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sAlertedRollsGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
};












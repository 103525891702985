import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../../Common/Layout/Link/link.js';
import {getAssetIconComponent} from '../../../../../../utils/assetHelper.js';

require('./assetCell.scss');

export default class AssetCell extends React.PureComponent {

  render() {
    let {value} = this.props;

    if (!value || !value.id) {
      return null;
    }

    return (
      <div className="asset-cell">
        {getAssetIconComponent(value.assetType)}
        <Link className="entity-id" label={value.label} id={value.id} type={value.type}/>
      </div>
    );
  }
}

AssetCell.propTypes = {
  value: PropTypes.object,
};



import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as workOrderViewActions }        from '../../actions/LocationPage/WorkOrderView/workOrderViewActions';
import { jsxActions as workOrderGridActions }       from '../../actions/LocationPage/WorkOrderView/workOrderGridActions';
import { jsxActions as workOrderDialogActions }        from '../../actions/LocationPage/WorkOrderView/workOrderDialogActions';
import { jsxActions as createWorkOrderDialogActions }        from '../../actions/Dialogs/CreateWorkOrderDialog/createWorkOrderDialogActions';
import { jsxActions as editWoAttributesActions }      from '../../actions/Dialogs/EditWoAttributesDialog/editWoAttributesDialogActions.js';
import { jsxActions as editWorkOrdersStatusActions }   from '../../actions/Dialogs/EditWorkOrdersStatusDialog/editWorkOrdersStatusDialogActions';
import { jsxActions as importFilesWizardActions }      from '../../actions/Wizards/ImportFiles/importFilesWizardActions.js';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';
import { jsxActions as locationPageActions }          from '../../actions/LocationPage/locationPageActions';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as headerActions }   from '../../actions/Header/headerActions';
import {getDefaultWorkOrderType}  from '../../utils/workOrderHelper';
import {gridsNames} from '../../enums/gridsNames';
import WorkOrderView from '../../components/LocationPage/WorkOrderView/workOrderView.js';
import PermissionManager from '../../../../infrastructure/js/utils/permissionManager';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(workOrderViewActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      workOrderGridActions:  bindActionCreators(workOrderGridActions, dispatch),
      workOrderDialogActions: bindActionCreators(workOrderDialogActions, dispatch),
      createWorkOrderDialogActions: bindActionCreators(createWorkOrderDialogActions, dispatch),
      editWorkOrdersStatusActions:   bindActionCreators(editWorkOrdersStatusActions, dispatch),
      editWoAttributesActions:      bindActionCreators(editWoAttributesActions, dispatch),
      importFilesWizardActions:      bindActionCreators(importFilesWizardActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch),
      locationPageActions:       bindActionCreators(locationPageActions, dispatch),
      headerActions: bindActionCreators(headerActions, dispatch),
    }
  }
}

let WorkOrderViewContainer = connect(
  (state) => {
    let workOrderGrid = state.grid.get(gridsNames.LOCATION_WORK_ORDER) ? state.grid.get(gridsNames.LOCATION_WORK_ORDER) : null;
  	return {
      sWorkOrderGridData: workOrderGrid,
      sHasRows: workOrderGrid && workOrderGrid.get('rows') ? workOrderGrid.get('rows').size > 0 : false,
      sSelectedRowsData: workOrderGrid ? workOrderGrid.get('selectedRowsData') : List(),
      sWorkOrderViewData: state.locationPage.get('workOrderViewData'),
      sWorkOrderDialogData: state.locationPage.get('workOrderDialogData'),
      sCreateWorkOrderDialogData: state.dialogs.get('createWorkOrderDialog'),
      sEditWorkOrdersStatusData:  state.dialogs.get('editWorkOrdersStatusDialog'),
      sEditWoAttributesData: state.dialogs.get('editWoAttributesDialog'),
      sDefaultWorkOrderType: getDefaultWorkOrderType(PermissionManager.getOrgPreferences()),
      sLoggedInUser: state.login.get('loggedInUser'),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),
    };
  },
  mapDispatchToProps
)(WorkOrderView);

export default WorkOrderViewContainer;



import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import { jsxActions as commonCsvSettingsViewActions} from '../../../../actions/Administration/SettingsPage/commonCsvSettingsViewActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import { jsxActions as exportActions} from '../../../../actions/Export/exportActions';
import PartTypeCsvSettingsView from '../../../../components/Administration/SettingsPage/CsvSettings/PartTypeCsvSettingsView/partTypeCsvSettingsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonCsvSettingsViewActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch),
      exportActions: bindActionCreators(exportActions, dispatch),
    }
  }
}

let PartTypeCsvSettingsViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonCsvSettingsView']),
      isDirty: isDirty('cssSettingsImportForm')(state) || isDirty('cssSettingsExportForm')(state),
    };
  },

  mapDispatchToProps
)(PartTypeCsvSettingsView);

export default PartTypeCsvSettingsViewContainer;




import React from 'react';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import { Panel } from 'react-bootstrap';
import classNames from 'classnames';
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import {connect} from "react-redux";

import './userMenu.scss';

class UserMenu extends React.PureComponent{

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.header.usermenu.');
    this.dialogLabels = createLabelHelper('mat.dialogs.changepassword.');
  }

  renderCopyrights = () => {
    let orgTime = DateTimeHelper.ConvertToDate({epochDateTime: this.props.orgTime });
    if(!orgTime){
      return;
    }
    return (
      <div>
        <div className='copyrights'><span className='special-character' >&copy; </span> <span className='info'>{orgTime.year()} {this.labels.get('copyrights')}</span></div>
      </div>
    );
  }

  renderMyAccount = () => {
    if(!this.props.featureFlags.ALL){return;}

    return {header: this.labels.get('myaccount'),action: this.actionMyAccount};
  }

  renderChangePassword = () => {
    if(!this.props.featureFlags.ALL){return;}

    return {header: this.labels.get('changepassword'),action: this.actionChangePassword};
  }

  getUserMenuElements(){
    return [
      this.renderMyAccount(),
      this.renderChangePassword(),
      {
        header: this.labels.get('logout'),
        action: this.logOut,
      },
      {
        header: this.renderCopyrights(),
        className : 'copyrights'
      },
    ];
  }

  actionMyAccount=()=>{
    let loggedInUser = JSON.parse(localStorage['loggedInUser']);
    this.props.actions.showEditUser(loggedInUser);
  }
  actionChangePassword=()=>{
    let loggedInUser = JSON.parse(localStorage['loggedInUser']);
    this.props.actions.showChangePassword(loggedInUser, this.dialogLabels);
  }

  logOut=()=>{
    this.props.actions.logout();
  }

  doAction =(action) => {
    action();
    this.props.actions?.onItemClick?.();
  }

  renderNavigationElements(){
    let elements = new Array();

    const orgName = this.props.sData?.loggedInOrg?.name;
    elements.push(
    <div key={orgName} className='org-name'>
      <span>{orgName}</span>
    </div>)

    this.getUserMenuElements().map((element, index)=>{
      if(element) {
         elements.push(
           <div className={classNames('user-menu-item', element.className)}
                key={"user-menu-element-"+index}
                onClick={()=>{this.doAction(element.action || (()=>{}))}}
           >{element.header}</div>);
      }
    });

    return elements;
  }

  render() {
    return (
      <Panel className="user-menu" collapsible expanded={this.props.open} >
        {this.renderNavigationElements()}
      </Panel>);
  }
}

export default connect( state => {
  return { orgTime : state.system.get("serverDatetime")}
})(UserMenu);

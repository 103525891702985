import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as changeLocationPageActions } from '../../../actions/Mobile/ChangeLocationPage/changeLocationPageActions';
import { jsxActions as headerActions }  from '../../../actions/Mobile/Header/headerActions.js';

import ChangeLocationPage from '../../../components/Mobile/ChangeLocationPage/changeLocationPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(changeLocationPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
    }
  }
}

let ChangeLocationPageContainer = connect(
  (state) => {
    return {
      sData: state.mobile.get('data'),
      sPageData: state.mobile.get('changeLocationPage'),
    };
  },
  mapDispatchToProps
)(ChangeLocationPage);

export default ChangeLocationPageContainer;





import Network from 'infrastructure/js/modules/network';

export function fetchOperations(query) {
  return Network.post('planner/planner-operations/items', query);
}

export function fetchResources(operationId) {
  return Network.get(`resources?operationId=${operationId}`);
}

export function fetchAllResources() {
  return Network.get('resources');
}

export function fetchSchedulerData(query) {
  return Network.post('planner/schedule', query);
}
export function autoSchedule(query) {
  return Network.post('planner/auto-schedule', query);
}

export function generateOperations() {
  return Network.put('planner/generate/planner-operations');
}

export function moveTask(operationId, query) {
  return Network.post(`planner/planner-operations/${operationId}/move`, query);
}

export function unAssign(query) {
  return Network.post('planner/planner-operations/unassign', query);
}

export function assignTask(operationId, data) {
  return Network.post(`planner/planner-operations/${operationId}/assign`, data);
}

export function lock(data) {
  return Network.post('planner/planner-operations/lock', data);
}

export function unlock(data) {
  return Network.post('planner/planner-operations/unlock', data);
}

export function createNoneOperationalTask(data) {
  return Network.post('planner/tasks/non-operational', data);
}
export function deleteNoneOperationalTask(id) {
  return Network.delete(`planner/tasks/non-operational/${id}/delete`);
}

export function deleteNoneOperationalTasks(data) {
  return Network.delete('planner/tasks/non-operational', data);
}

export function fetchSchedulerCounters() {
  return Network.get('planner/planner-operations/taskscount');
}

export function fetchSchedulingGoals() {
  return Network.get('scheduling-factor/items');
}

export function setSchedulingGoals(data) {
  return Network.post('scheduling-factor/items', data);
}

export function fetchSchedulingPreferences(data) {
  return Network.post('planner/preferences', data);
}

export function setSchedulingPreferences(data) {
  return Network.put('planner/preferences', data);
}

export function fetchSchedulingRuleByName(data) {
  return Network.post('plannerRule', data);
}

export function setSchedulingRule(data) {
  return Network.put(`plannerRule/${data.id}`, data);
}

export function fetchTasksCounters({ locationId, payload }) {
  return Network.post(`stations/${locationId}/planner-operations/count`, payload);
}

export const resetColor = (colorType) => {
  return Network.put('planner/preference/reset', { key: `generalsetting.orgpreferences.scheduler.task.status.color.${colorType}` });
};

export const updateColor = (colorType, value) => {
  return Network.put('planner/preferences', { key: `generalsetting.orgpreferences.scheduler.task.status.color.${colorType}`, value });
};

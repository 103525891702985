import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import RelatedAssetsCell from '../../../../Common/CustomGridCells/RelatedAssetsCell/relatedAssetsCell.js';
import ValueWithStateCell from '../../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell.js';
import MoreInfoCell from '../../../../Common/CustomGridCells/MoreInfoCell/moreInfoCell.js';
import {navigationStates} from '../../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {getAssetEtl} from '../../../../../utils/assetHelper';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../../utils/alertFilterSectionHelper';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import WeightCell from '../../../../Common/CustomGridCells/WeightCell/weightCell';
import LengthCell from '../../../../Common/CustomGridCells/LengthCell/lengthCell';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
import {isTransitArea} from '../../../../../utils/locationHelper';

require('./groupsGrid.scss');

class GroupsGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'availability', filterName: 'availabilityStatus', getOptions: true},
    {fieldName: 'assetStatus', filterName: 'assetStatus', getOptions: true},

    {fieldName: 'businessId', filterName: 'groupBusinessId', getOptions: false},
    {fieldName: 'groupType.businessId', filterName: 'groupType', getOptions: true},
    {fieldName: 'shipmentBusinessId', filterName: 'shipmentBusinessId', getOptions: false},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: 'moreInfo'},
    {fieldName: '', filterName: 'groupWorkOrder'},
    {fieldName: '', filterName: 'groupPart'},
    {fieldName: '', filterName: 'groupProject'},
  ]

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.filterLabels = createLabelHelper('mat.grid.columns.moreInfo.filter.');
    this.etlLabels = createLabelHelper('enum.etl.type.');
    this.shipmentslabels = createLabelHelper('mat.locationpage.view.shipments.columns.');
    let isTransitAreaLoc = isTransitArea(props.locationDetails);
    this.columnsConfig = isTransitAreaLoc ? this.createShipmentsColumnsConfig() : this.createColumnsConfig();
  }

  getLengthOrWeight = () => {

    if(PermissionManager.isWeightSupported()){
      return {
        fieldName: 'weight',
        title: this.labels.get('columns.weight.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: WeightCell,
          valueGetter: (params) => {
            return {
              weight: params.data.weight
            };
          },
        }
      }
    }

    return {
      fieldName: 'lengthLeft',
      title: this.labels.get('columns.length.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        cellComponent: LengthCell,
        valueGetter: (params) => {
          return {
            length: params.data.lengthLeft
          };
        },
      }
    }
  };

  createShipmentsColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.groupId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'groupBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.GROUP_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.GROUP
            };
          }
        }
      },
      {
        fieldName: 'assetStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetStatus',
        width: 150,
        columnOptions: {
          valueGetter: (params) => {
            const  status = params.data.assetStatus.toLowerCase();
            return  status.charAt(0).toUpperCase() + status.slice(1);

          },
        }
      },
      {
        fieldName: 'shipmentBusinessId',
        title: this.shipmentslabels.get('shipmentId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'shipmentBusinessId',
        fetchConfig: {
          entityType:  EntityPropertyTypes.SHIPMENT_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.shipmentInfo?.businessId
          }
        }
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      }
    ]
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.DUE_DATE, alertType.MISPLACED, alertType.DEFROSTING ]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: "", alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              dueDateLeft: params.data.dueDateLeft,
              dueDateMinutesLeft: params.data.dueDateMinutesLeft,
              locationName: params.data.locationName,
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'availability',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'availabilityStatus',
        width: 150,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return this.getAvailabilityLabel(params.data.availability)
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.groupId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'groupBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.GROUP_BUSINESS_ID,
          filter:  [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.GROUP
            };
          }
        }
      },
      {
        fieldName: 'groupType.businessId',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'groupType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.groupType.businessId
          }
        }
      },
      {
        fieldName: 'relatedAssetData',
        title: '',
        filterType: filterTypes.NONE,
        width: 70,
        columnOptions: {
          sortable: false,
          resizable: false,
          cellComponent: RelatedAssetsCell,
          valueGetter: (params) => {
            return {
              count: params.data.relatedAssetsCounter,
              cellAction: this.props.cellAction,
              assetId: params.data.id,
              componentAssetsTitle: this.labels.get('columns.componentAssets.cell.title'),
              resultingAssetsTitle: this.labels.get('columns.resultingAssets.cell.title')
            };
          },
          headerComponentParams : {
            headerIcon: 'component-assets',
            headerTooltip: this.labels.get('columns.componentAssets.cell.title'),
          }
        }
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.moreInfo.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        filterAlignment: filterAlignmentTypes.RIGHT,
        propertyTypes: this.getMoreInfoProperties(),
        columnOptions: {
          cellComponent: MoreInfoCell,
          sortable: false,
          valueGetter: (params) => { return {
            part: params.data.partType ? params.data.partType.businessId : null,
            project: params.data.project ? params.data.project.businessId : null,
            workOrder: params.data.workOrder ? params.data.workOrder.businessId : null,
            workOrderId: params.data.workOrder ? params.data.workOrder.id : null
          };}
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change,
      },

    ]
  }


  getAvailabilityLabel(availability) {
    if (availability === 'AVAILABLE') {
      return this.labels.get('columns.status.filter.available');
    }
    else if (availability === 'OCCUPIED') {
      return this.labels.get('columns.status.filter.occupied');
    }
    return '';
  }

  getMoreInfoProperties() {
    return [
      {value: 'groupWorkOrder',  label: this.filterLabels.get('workOrder') || 'WO'},
      {value: 'groupPart',     label: this.filterLabels.get('partType') || 'PART TYPE'},
      {value: 'groupProject',   label: this.filterLabels.get('project') || 'PROJECT'},
    ];
  }

  render() {

    return (
      <div className="groups-grid">
        <Grid gridName={gridsNames.LOCATION_ASSET_GROUPS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: () => {return isTransitArea(this.props.locationDetails) ? 64 : 130;}
                }
              }
        />
      </div>
    );
  }
}

export default reduxForm({
    form: 'assetsViewGroupsGrid'
  }
)(GroupsGrid);

GroupsGrid.propTypes = {
  locationDetails: PropTypes.object.isRequired,
};


//this line is a vodoo, do not remove.
import { api as locationPageApi } from '../LocationPage/locationPageActions.js';

import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import * as batchJobsService from '../../services/BatchJobs/batchJobsService';
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.BO_ACTIVITY_LOG, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let activityLogId = getState().activityLogDialog.getIn(['data','activityLogId']);
    return batchJobsService.getJobActivityLog( activityLogId, query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let activities = rows.map((obj) => {
    obj.m_Time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.time, serverTimeSeconds);
    return obj;
  });
  return activities;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import LoginService from 'infrastructure/js/services/LoginService';
import {api as loginApi} from '../Login/loginActions';
import userService from "../../services/UserManagement/UserService";
import Network from "../../../../infrastructure/js/modules/network";


// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  AUTHENTICATION_PAGE_SUBMIT_IN_PROCESS: 'AUTHENTICATION_PAGE_SUBMIT_IN_PROCESS',
  AUTHENTICATION_PAGE_SUBMIT_FINISHED: 'AUTHENTICATION_PAGE_SUBMIT_FINISHED',
  AUTHENTICATION_PAGE_SUBMIT_FAILED: 'AUTHENTICATION_PAGE_SUBMIT_FAILED',
  AUTHENTICATION_PAGE_CLEAR_DATA: 'AUTHENTICATION_PAGE_CLEAR_DATA',
  AUTHENTICATION_PAGE_SENDING_SMS_FINISHED: 'AUTHENTICATION_PAGE_SENDING_SMS_FINISHED',
  AUTHENTICATION_PAGE_SENDING_SMS_IN_PROCESS: 'AUTHENTICATION_PAGE_SENDING_SMS_IN_PROCESS'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  submitInProcess: function() {
    return {type: actionTypes.AUTHENTICATION_PAGE_SUBMIT_IN_PROCESS };
  },
  submitFinished: function(payload) {
    return {type: actionTypes.AUTHENTICATION_PAGE_SUBMIT_FINISHED ,payload: payload };
  },
  authenticateFailed: function(payload) {
    return {type: actionTypes.AUTHENTICATION_PAGE_SUBMIT_FAILED ,payload: payload };
  },
  sendingSmsInProcess: function() {
    return {type: actionTypes.AUTHENTICATION_PAGE_SENDING_SMS_IN_PROCESS};
  },
  sendingSmsFinished: function() {
    return {type: actionTypes.AUTHENTICATION_PAGE_SENDING_SMS_FINISHED };
  },

  unmount: function() {
    return {type: actionTypes.AUTHENTICATION_PAGE_CLEAR_DATA};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};
jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};

jsxActions.sendSms = function () {
  return function (dispatch, getState) {
   api.sendSms(dispatch, getState)()
  }
};

jsxActions.submit = function (data) {
  return function (dispatch, getState) {
    api.submitSmsCode(dispatch, getState)(data)
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.sendSms = function(dispatch, getState) {
  return function() {
    dispatch(actions.sendingSmsInProcess());
    LoginService.sendSmsCode().then((response) => {
      if(!Network.isResponseValid(response)){
        dispatch(actions.authenticateFailed(response.applicationResponseStatus.message));
        console.error('sending sms failed');
        return;
      }
      dispatch(actions.sendingSmsFinished());
    });
  }
};

api.submitSmsCode = function(dispatch, getState) {
  return function(data) {

    dispatch(actions.submitInProcess());
    LoginService.submitSmsCode(data).then((response) => {
      if(!Network.isResponseValid(response)){
        dispatch(actions.authenticateFailed(response.applicationResponseStatus.message));
        console.error('submit sms failed');
        return;
      }
      userService.getCurrentUserProfile().then((response) => {
        if (!Network.isResponseValid(response) || !response.data) {
          console.error('getting current user profile failed');
          dispatch(actions.submitFinished());
          return;
        }
        if (response.data.loggedInOrg.orgPreferences.userAgreementMessageEnabled) {
          loginApi.showSecurityMessageDialog(response.data)(dispatch, getState)
        }
        else {
          loginApi.executePostLoginActions(response.data)(dispatch, getState);
        }
        dispatch(actions.submitFinished());
      });
    });
  }
};





import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Page from '../Common/Page/page';
import MassOperationsViewContainer from '../../../containers/Administration/MassOperationsPage/massCreationViewContainer';
import {MassOperationsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';

require('./massOperationsPage.scss');

export default class MassOperationsPage extends Component {

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.sidenavigator.');
  }

  componentDidMount() {
    this.props.actions.init();
    if(window.location.href.indexOf('massOperations') < 0){
      window.location.href = '#/Administration/massOperations';
    }

    // if(window.location.href.indexOf('massCreation') < 0){
    //   window.location.href = '#/Administration/massOperations/massCreation';
    // }
  }

  getNavigationItems(props, labels) {
    return [
      {
        id : MassOperationsMenuItemsIds.NAV_ELEMENT_MASS_CREATION,
        label: this.labels.get('item.masscreation'),
        link: '/Administration/massOperations/massCreation',
      },
      // {
      //   label: this.labels.get('item.import'),
      //   //link: getPathByType(navigationStates.ARCHIVED_ROLLS),
      //   items: []
      // },
    ];
  }

  getRoutes () {
    let routesArr = [
      <Route key="massOperationsRouteKey" exact path='/Administration/massOperations' component={MassOperationsViewContainer}/>,
      <Route key="massOperationsRouteKey2" path='/Administration/massOperations/massCreation' component={MassOperationsViewContainer}/>,
    ];
    return routesArr;

  }

  render() {
    return (<Page className="mass-operations-page"
                  selectedMenuItemId={this.props.sData.get('selectedMenuItemId')}
                  sideNavigatorMenuItems={this.getNavigationItems()}
                  actions={this.props.actions}
                  routes={this.getRoutes()}
      >
      </Page>
    )
  }
};












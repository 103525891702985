import { api as locationPageApi } from '../locationPageActions.js';
import * as workOrdersService from '../../../services/WorkOrders/workOrdersService'
import {getDefaultWorkOrderType}  from '../../../utils/workOrderHelper';
import {gridsNames} from "../../../enums/gridsNames";
import * as gridActionsHelper from "../../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper";
import WoGridHelper from 'infrastructure/js/utils/woGridHelpers';
import PermissionManager from "../../../../../infrastructure/js/utils/permissionManager";

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_WORK_ORDER, _fetchDataAction, WoGridHelper._convertServerItemsToGridRowsData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let defaultWorkOrderType = getDefaultWorkOrderType(PermissionManager.getOrgPreferences());
    return workOrdersService.fetchWorkOrdersByStationId(locationId, defaultWorkOrderType , query);
  }
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;

import { Map } from 'immutable';
import { actionTypes as MqttBrokersSettingsViewActionsType } from '../../../actions/Administration/RfidSettingsPage/mqttBrokersSettingsViewActions';

let defaultState = {
  loading: false,
  selectedDeviceApp : null,
  deviceAppsOptions : []
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case MqttBrokersSettingsViewActionsType.ADMINISTRATION_RF_SETTINGS_BROKERS_FETCH_DEVICE_APPS_IN_PROCESS:
      return state.set('loading', true);

    case MqttBrokersSettingsViewActionsType.ADMINISTRATION_RF_SETTINGS_BROKERS_FETCH_DEVICE_APPS_READY:
      state = state.set('deviceAppsOptions', action.payload);
      return state.set('loading',false);

    case MqttBrokersSettingsViewActionsType.ADMINISTRATION_RF_SETTINGS_BROKERS_DEVICE_APP_CHANGED:
      return state.set('selectedDeviceApp', action.payload);

    case MqttBrokersSettingsViewActionsType.ADMINISTRATION_RF_SETTINGS_BROKERS_UNMOUNT:
      return Map(defaultState);

    default:
      return state;
  }
}








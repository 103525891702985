import React from 'react';
import PropTypes from 'prop-types';
import AssetsValidateGrid from '../../Grids/AssetsValidateGrid/assetsValidateGrid';

require('./assetsValidateStep.scss');

export default class AssetsValidateStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.props.registerOnEnter(() => {
      this.props.setNextButtonEnabled(this.isNextButtonEnabled());
    });

    this.props.registerOnExit(() => {
    })
  }

  isNextButtonEnabled = () => {
    let {validatedFileData} = this.props;
    return (validatedFileData && (validatedFileData.successItems + validatedFileData.warningItems > 0) );
  }


  renderValidationInfo = (validatedFileData) => {
    let errorIcon = 'pl pl-canceled-icon';
    let warningIcon = 'pl pl-warning-icon';
    let successIcon  = 'pl pl-check-circle-icon-blue blue-color';
    let successItems = validatedFileData.successItems;
    let warningItems = validatedFileData.warningItems;
    let errorItems = validatedFileData.errorItems;


    return (
      <div className='validation-info-section'>
        {successItems > 0 ?
          <div className='validation-info-item'>
            <span className={`validation-info-icon ${successIcon}`}/>
            <div className='validation-info-msg'>{this.props.labels.get('fileValidateStep.updated.succeeded', undefined, {count: successItems})}</div>
          </div> : null}
        {warningItems > 0 ?
          <div className='validation-info-item'>
            <span className={`import-validation-icon ${warningIcon} warning`}/>
            <div className='validation-info-msg'>{this.props.labels.get('fileValidateStep.updated.warning', undefined, {count: warningItems})}</div>
          </div> : null}
        {errorItems > 0 ?
          <div className='validation-info-item'>
            <span className={`import-validation-icon ${errorIcon} `}/>
            <div className='validation-info-msg'>{this.props.labels.get('fileValidateStep.updated.failed', undefined, {count: errorItems})}</div>
          </div> : null}
      </div>
    );
  }


  render() {

    let {validatedFileData} = this.props;
    if(validatedFileData) {
      return (
        <div className="file-validate-step">

          {this.renderValidationInfo(validatedFileData)}

          <AssetsValidateGrid
            assetType={this.props.assetType}
            rows={validatedFileData.logContentItems}
            operationStatusLabels={this.props.operationStatusLabels}
          />

        </div>
      );
    }
    return null;
  }
}

AssetsValidateStep.propTypes = {
  validatedFileData: PropTypes.object,
  operationStatusLabels: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};


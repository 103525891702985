import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as workflowFiltersViewActions} from '../../../actions/Administration/RfidSettingsPage/workflowFiltersViewActions.js';
import { jsxActions as rfidSettingsPageActions} from '../../../actions/Administration/RfidSettingsPage/rfidSettingsPageActions';
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import WorkflowsFiltersView from '../../../components/Administration/RfidSettingsPage/FiltersParametersView/WorkflowsFiltersView/workflowsFiltersView';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(workflowFiltersViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      rfidSettingsPageActions : bindActionCreators(rfidSettingsPageActions, dispatch)
    }
  }
}

let WorkflowsFiltersViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['rfidSettingsPage', 'workflowsFiltersViewData']),
    };
  },

  mapDispatchToProps
)(WorkflowsFiltersView);

export default WorkflowsFiltersViewContainer;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import OrganizationsTab from '../../../components/Administration/OrganizationsPage/OrganizationsTab/organizationsTab.js';
import { gridsNames } from '../../../enums/gridsNames';
import { jsxActions as headerActions } from '../../../actions/Header/headerActions.js';
import { jsxActions as organizationsTabGridActions } from '../../../actions/Administration/OrganizationsPage/organizationsTabGridActions';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      header: bindActionCreators(headerActions, dispatch),
      organizationsTabGridActions: bindActionCreators(organizationsTabGridActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
    },
  };
}

let OrganizationsTabContainer = connect((state) => {
  let organizationsTabGrid = state.grid.get(gridsNames.ADMINISTRATION_ORGANIZATIONS_TAB)
    ? state.grid.get(gridsNames.ADMINISTRATION_ORGANIZATIONS_TAB)
    : null;
  return {
    sOrganizationsTabGrid: organizationsTabGrid,
    sHasRows:
      organizationsTabGrid && organizationsTabGrid.get('rows')
        ? organizationsTabGrid.get('rows').size > 0
        : false,
    sSelectedRowsData: organizationsTabGrid ? organizationsTabGrid.get('selectedRowsData') : List(),
  };
}, mapDispatchToProps)(OrganizationsTab);

export default OrganizationsTabContainer;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { jsxActions as scanToolPageActions } from '../../../actions/Mobile/ScanToolPage/scanToolPageActions';
import { jsxActions as headerActions }  from '../../../actions/Mobile/Header/headerActions.js';
import ScanToolPage from '../../../components/Mobile/ScanToolPage/scanToolPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
       ...bindActionCreators(scanToolPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
    }
  }
}

let ScanToolPageContainer = connect(
  (state) => {
    return {
      sData: state.mobile.get('data'),
      sPageData: state.mobile.get('scanToolPage'),
    };
  },
  mapDispatchToProps
)(ScanToolPage);

export default ScanToolPageContainer;




